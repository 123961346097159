import { Box, Paper, Grid, Button } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setEditEanCode, setEanCodeInput, setEanCodeList, setEanCodeDeleteData, deleteEanCodeList } from "../../../../store/slices/generalProduct";
import { warningModal, setEanCodeModal } from "../../../../store/slices/modals";
import { DataTableClientSide } from '../../../../components/shared/data-table/DataTableClientSide';
import AddIcon from '@mui/icons-material/Add';
import AddEditEanCode from './eancode/AddEditEanCode';
import axios from "../../../../services/utility/axios";
import axios_product from '../../../../services/utility/axios';
import SnackBarDialog from '../../../../components/shared/SnackBarDialog';
import PageLoader from '../../../../components/shared/PageLoader';

const IconMenuLink = ({ params, onDelete }) => {
    const { row } = params;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { showAddEditModal, general_product_fields } = useSelector((state) => {
        return {
            showAddEditModal: state.modalActions.hideAddEditModal,
            editTabData: state.generalProduct.editTabData,
            general_product_fields: state.generalProduct
        }
    });
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'edit') {
            dispatch(setEditEanCode(row))
            dispatch(setEanCodeInput(row.eanCode))
            dispatch(setEanCodeModal(true))
        } else if (action === 'delete') {
            if (onDelete) {
                onDelete(row)
            }
        }
        setAnchorEl(null);
    };

    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'edit')}>Edit</MenuItem>
            <MenuItem onClick={(e) => handleClose(e, 'delete')}>Delete</MenuItem>
        </Menu>

    </>)
}

const defaultValues = {
    price: '',
    brand_owner_name: "",
    defaultGrid: {
        eanActionButtons: true,
        paginationMode: 'client',
    },
    addEanCodes: false,
    loader: false
};
export default function Eancode({ onShow }) {
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })
    const [state, setState] = useState(defaultValues);
    const [columns, setColumns] = useState([]);

    const allColumns = [
        {
            headerName: "Id",
            field: "id",
            flex: 1,
            hide: true
        },
        {
            headerName: "EAN Code",
            field: "eanCode",
            flex: 1,
        },
        {
            headerName: "Created Date",
            field: "createdDate",
            flex: 1,
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} onDelete={deleteEanCode} />;
            },
        }
    ];
    const { getEanCode, showEanCodeModal, general_product_fields, handleWarning } = useSelector((state) => {
        return {
            showEanCodeModal: state.modalActions.eanCodeModal,
            getEanCode: state.generalProduct.product_eancode,
            general_product_fields: state.generalProduct,
            handleWarning: state.modalActions.closeWaringAddProduct
        }
    });
    const dispatch = useDispatch();

    const allEanCodes = async () => {
        try { 
            setState({ ...state, loader: true })
            setTimeout(() => {
                setColumns(allColumns)
            }, 100);
            const id = (general_product_fields.hasEditData || general_product_fields.editTabData) ? (general_product_fields.hasEditData.id || general_product_fields.editTabData.productId) : null
            const { data } = await axios.get(`products/${id}/additionalEAN`);
            if (data) {
                setState({ ...state, loader: false })
                dispatch(setEanCodeList(data))
            }
        } catch (error) {
            console.log(general_product_fields)
            setState({ ...state, loader: false })
            const { response: { data, status } } = error;
            setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: data.message ? "warning" : "error" })
        }
    }
    useEffect(() => {
        allEanCodes();
    }, []);

    const addEanCodeModal = (event) => {
        dispatch(setEanCodeModal(true))
    }
    const hideAddEditEanModal = (event) => {
        dispatch(setEanCodeModal(event))
    }

    const deleteEanCode = (row) => {
        dispatch(deleteEanCodeList(row))
        dispatch(setEanCodeDeleteData({
            key: 'eancodeOkDelete',
            value: true
        }))

        dispatch(warningModal({
            yesHide: !handleWarning
        }))
    }

    useEffect(() => {
        if (general_product_fields.eancodeConfirmDelete) {
            deleteSingleRow()
        }
    }, [general_product_fields.eancodeConfirmDelete])


    const deleteSingleRow = async () => {
        if (general_product_fields.deleteEanCodeList !== null) {  
            try {
                setState({ ...state, loader: true })
                const id = (general_product_fields.hasEditData || general_product_fields.editTabData) ? (general_product_fields.hasEditData.id || general_product_fields.editTabData.productId) : null
                const { data } = await axios_product.delete(`products/${id}/additionalEAN/${general_product_fields.deleteEanCodeList.id}`);
                if (data.success) {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
                    const filteredRows = getEanCode.filter(product => {
                        if (product.id !== general_product_fields.deleteEanCodeList.id) {
                            return product;
                        }
                    })
                    dispatch(setEanCodeList(filteredRows))
                    dispatch(deleteEanCodeList(null))
                    eanCodeDeleteRowDispatch()
                    setState({ ...state, loader: false })
                }

            } catch (error) {
                const { response: { data, status } } = error;

                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                }
                setState({...state, loader: false})
                dispatch(deleteEanCodeList(null))
                eanCodeDeleteRowDispatch()
            }
        }
    }

    const eanCodeDeleteRowDispatch = () => {
        dispatch(setEanCodeDeleteData({
            key: 'eancodeOkDelete',
            value: false
        }))
        dispatch(setEanCodeDeleteData({
            key: 'eancodeConfirmDelete',
            value: false
        }))
    }

    return (
        <>
            {state.loader && <PageLoader />}
            <Box sx={{ flexGrow: 1, paddingX: 2 }} >
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5 } }}>
                    <div className='table-actionable'>
                        <Button variant="contained" onClick={(e) => addEanCodeModal(e)} startIcon={<AddIcon />}>
                            Add EAN Code
                        </Button>
                    </div>
                </Box>
                <DataTableClientSide
                    columns={columns ? columns : []}
                    defaultGrid={state.defaultGrid}
                    rows={getEanCode ? getEanCode : []}
                >
                </DataTableClientSide>
                {showEanCodeModal && (
                    <>
                        <AddEditEanCode size="medium_overlay"
                            onShow={showEanCodeModal}
                            onHide={(e, reason) => hideAddEditEanModal(e, reason)}
                        />
                    </>
                )}
                {/* <Grid container sx={{ marginTop: '20px' }}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Item>
                            <Grid container direction="row" alignItems="center" justifyContent="center">
                                <LoadingButton
                                    onClick={handleSubmit}
                                    loading={state.loader}
                                    loadingPosition="center"
                                    endIcon=""
                                    variant="contained"
                                    type="submit" sx={{ marginRight: '15px' }}
                                    disabled={state.formValid}
                                >
                                    Save
                                </LoadingButton>
                                <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                            </Grid>
                        </Item>
                    </Grid>
                </Grid> */}
            </Box>
            {snackBar.show && <SnackBarDialog
                onShow={snackBar.show}
                text={snackBar.text}
                type={snackBar.type}
                onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
            />}
        </>
    );
}
