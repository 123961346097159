import { Container, Box, Modal, Button, Grid, Checkbox, Popper, Grow, Paper, ClickAwayListener, MenuList, ButtonGroup } from '@mui/material'
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TableFilters } from "../../components/shared/data-table/TableFilters";
import { useEffect, useState } from "react";
import { InputText } from "../../components/shared/form/InputText";
import { AutoComplete } from "../../components/shared/form/AutoComplete";
import { } from '@mui/material';
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange, filterResetFlagChange } from "../../store/slices/externalFilter";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import axios_member from '../../services/utility/axios-member';
import { warningModal, handleAddEditModal } from "../../store/slices/modals";
import WarningModal from "../../components/shared/modals/WarningModal";
import { setRowPage, resetDataGrid, setPageNo } from '../../store/slices/dataGrid';
import { useLayoutEffect } from "react";
import PageLoader from "../../components/shared/PageLoader";
import SnackBarDialog from "../../components/shared/SnackBarDialog";
import { DataTableMember } from "../../components/shared/data-table/DataTableMember";
import { setHasEditData, hasTabEdit, organisationTypeInput, setDeleteList, setOrganisationTypeDeleteData, resetorganisationType } from "../../store/slices/organisationType";
import AddEditOrganisationTypeDetail from "./AddEditOrganisationType/AddEditOrganisationTypeDetail";
import { DataTableOrganisationType } from '../../components/shared/data-table/DataTableOrganisationType';

const IconMenuLink = ({ params, onDelete }) => {
    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { showAddEditModal, organisation_type_fields } = useSelector((state) => {
        return {
            showAddEditModal: state.modalActions.hideAddEditModal,
            editTabData: state.organisationTypes.editTabData,
            organisation_type_fields: state.organisationTypes
        }
    });
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'edit_aggregation_group') {
            dispatch(handleAddEditModal({
                showAddEdit: !showAddEditModal
            }))
            dispatch(hasTabEdit({ editTab: false }))
            setEditPDClData(row);
        } else if (action === 'delete_aggregation_group') {
            onDelete(row)
        }
        setAnchorEl(null);
    };
    const setEditPDClData = async (row) => {

        dispatch(setHasEditData(row))
        dispatch(organisationTypeInput({
            key: 'organisation_type_name',
            value: row.name
        }))
    }
    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'edit_aggregation_group')}>Edit</MenuItem>
            <MenuItem onClick={(e) => handleClose(e, 'delete_aggregation_group')}>Delete</MenuItem>
        </Menu>
    </>)
}
function OrganisationType() {

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const columns = [
        {
            headerName: "ID",
            field: "id",
            flex: 1,
            minWidth: 200
        },
        {
            headerName: "Name",
            field: "name",
            flex: 1,
            width: 170
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} onDelete={deleteOrganisationData} />;
            }
        }
    ];

    const [state, setState] = useState({
        pageLoader: false,
        openOrganisationTypeModal: false,
        defaultGrid: {
            pagetitle: "Organisation Type",
            pdcActionButton: true,
            hasExport: true,
            addEditButton: true,
            apiUrl: 'members/memberEstateGroups/filter',
            searchUrl: 'members/memberEstateGroups/filter'
        }
    });


    const { organisation_type_name, addProductwarning, showAddEditModal, organisation_type_fields } = useSelector((state) => {
        return {
            organisation_type_name: state.externalFilter.organisation_type_name,
            organisation_type_fields: state.organisationTypes,
            resetTable: state.dataGridTable,
            addProductwarning: state.modalActions.closeWaringAddProduct,
            showAddEditModal: state.modalActions.hideAddEditModal,
        }
    });

    const dispatch = useDispatch();

    const handleFilterInput = (value, id) => {
        //setState({ ...state, [id]: value });
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    };

    const selectedAutoComplete = (value, id) => {
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    }

    const filterBtn = () => {
        dispatch(resetDataGrid());
        dispatch(applyFilter({
            key: 'filterList',
            value: {
                "name": organisation_type_name
            }
        }))
        dispatch(filterFlagChange(true));
        dispatch(filterResetFlagChange(false));
        dispatch(setRowPage({ pageData: 10 }));
        dispatch(setPageNo({ page: 1 }));
    }
    const resetFilter = () => {
        if (organisation_type_name !== '') {
            dispatch(resetExternalFilter())
            dispatch(resetDataGrid());
        }
        dispatch(filterResetFlagChange(true));
    }
    const addeditOrganisationTypeModal = () => {
        dispatch(resetorganisationType());
        dispatch(handleAddEditModal({
            showAddEdit: !showAddEditModal
        }))
    }
    const hideNewOrganisationTypeModal = (event) => {
        setState({ ...state, openOrganisationTypeModal: false })
    }

    const deleteOrganisationData = (row) => {
        dispatch(setDeleteList(row))
        dispatch(setOrganisationTypeDeleteData({
            key: 'organisationTypeStartDelete',
            value: true
        }))
        dispatch(warningModal({
            yesHide: !addProductwarning
        }))
    }

    useEffect(() => {
        if (organisation_type_fields.organisationTypeConfirmDelete) {
            deleteSingleRow()
        }
    }, [organisation_type_fields.organisationTypeConfirmDelete])


    const deleteSingleRow = async () => {
        if (organisation_type_fields.deleteList !== null) {
            try {
                setState({ ...state, pageLoader: true })
                const { data } = await axios_member.delete(`members/memberEstateGroups/${organisation_type_fields.deleteList.id}`);
                if (data.success) {
                    resetSingleDeleteRowDispatch();
                    setState({ ...state, pageLoader: false });
                    setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Organisation Type Delete Successfully!", type: "success" });
                    setTimeout(() => {
                        // window.location.reload();
                        dispatch(filterFlagChange(true));
                        dispatch(filterResetFlagChange(false));
                    }, 1500);
                    //         try {
                    //             const { data } = await axios_member.get(`members/memberEstateGroups`);
                    //             if (data !== null || data !== undefined) {
                    //                 setState({ ...state, pageLoader: false })
                    //                 setSnackBar({ ...snackBar, show: true, text: "Organisation Type Delete Successfully", type: "success" })
                    //                 dispatch(setDeleteList(null))
                    //                 resetSingleDeleteRowDispatch()
                    //                 setTimeout(() => {
                    //                     window.location.reload();
                    //                 }, 2000);
                    //             }
                    //         } catch (error) {
                    //             const { response: { data, status } } = error;
                    //             if (status === 500 && data.errorType === "Business") {
                    //                 setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" });
                    //             } else {
                    //                 setSnackBar({ ...snackBar, show: true, text: "Something went wrong!", type: "error", })
                    //             }
                    //             setState({ ...state, pageLoader: false })
                    //             resetSingleDeleteRowDispatch()
                    //         }
                }
            } catch (error) {
                const { response: { data, status } } = error;
                if (status === 500 && data.errorType === "Business") {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" });
                } else {
                    setSnackBar({ ...snackBar, show: true, text: "Something went wrong!", type: "error", })
                }
                setState({ ...state, pageLoader: false })
                resetSingleDeleteRowDispatch()
            }
        }
    }

    const resetSingleDeleteRowDispatch = () => {
        dispatch(setOrganisationTypeDeleteData({
            key: 'organisationTypeStartDelete',
            value: false
        }))
        dispatch(setOrganisationTypeDeleteData({
            key: 'organisationTypeConfirmDelete',
            value: false
        }))
    }

    useEffect(() => {
        /* load first first time */
        filterBtn();
    }, []);

    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Organisation Type" />
                <Box sx={{ marginBottom: 2.5 }}>
                    <TableFilters
                        filterFields={<>
                            <Box component="div"><InputText onChange={handleFilterInput} maxWidth={50} label="Name" id="organisation_type_name" size="small" /></Box>
                        </>}
                        filterBtns={
                            <>
                                <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                <Button variant="contained" onClick={filterBtn}>Apply</Button>
                            </>}>
                    </TableFilters>
                </Box>
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -2.4 } }}>
                    {/* <div className='table-actionable'>
                        <Button variant="contained" color="secondary" size='small' startIcon={<SendIcon />}>
                            Export as Excel
                        </Button>
                    </div> */}
                    <div className='table-actionable'>
                        <Button variant="contained" size='small' onClick={(e) => addeditOrganisationTypeModal(e)} startIcon={<AddIcon />}>Add New Organisation Type</Button>
                    </div>
                </Box>
                <DataTableOrganisationType defaultGrid={state.defaultGrid} columns={columns}>

                </DataTableOrganisationType>
                {
                    showAddEditModal && (
                        <AddEditOrganisationTypeDetail
                            onShow={showAddEditModal} size="medium_overlay"
                            onHide={(e, reason) => hideNewOrganisationTypeModal(e, reason)}

                        />
                    )
                }
                {
                    addProductwarning && (
                        <WarningModal size="small_overlay" onShow={addProductwarning} />
                    )
                }
                {snackBar.show && <SnackBarDialog
                    onShow={snackBar.show}
                    text={snackBar.text}
                    type={snackBar.type}
                    onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                />}
            </Container>

        </>
    );
}

export default OrganisationType;
