import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  product_name: '',
  b_o_product_id: '',
  o_c_barcode: '',
  product_active_prices: '',
  product_category: null,
  product_brand: null,
  active_product: null,
  product_sortby: '',
  filterList: {},
  filterApplied: false,
  filterReset: false,
  brand_name: '',
  brand_owner_name: '',
  measure_name: '',
  vat_name: '',
  cbs_name: '',
  dc_name: '',
  pdc_product: null,
  pdc_supplier: null,
  pdc_distribution_channel: null,
  pdc_product_category: null,
  pdc_product_brand: null,
  pdc_supplier_product_code: '',
  supplier_centralBillingId: '',
  supplier_name: '',
  retroFinal_search_name: '',
  accounts_supplier_code: '',
  accounts_supplier: null,
  accounts_member: null,
  rdsoe_retro_discount_scheme: '',
  rdsoe_brand_owner: '',
  rdsoe_member: '',
  retro_discount_scheme_name: '',
  discount_schemes_name: '',
  ds_active: true,  
  supplier_invoice_code: '',
  supplier_invoice_name: '',
  supplier_invoice_member: null,
  supplier_invoice_distribution_channel: null,
  supplier_invoice_member_account: null,
  supplier_invoice_start_date: null,
  supplier_invoice_end_date: null,
  invoice_summary_supplier: null,
  member_name: '',
  active_member: null,
  cname: '',
  ctype: [],
  active_category: null,
  programme_name: '',
  file_name: '',
  output_type: '',
  from_date: null,
  to_date: null,
  aggregation_group_name: '',
  organisation_type_name: '',
  mgr_email_status: '',
  mgr_file_name: '',
  mgr_output_type: '',
  mgr_from_date: null,
  report_member: '',
  mgr_member_account: '',
  supplier_report_file_name: '',
  supplier_report_output_type: '',
  supplier_report_email_status: '',
  supplier_report_supplier_type: null,
  retro_discount_closed_for_billing: false,
  retro_discount_closed_for_invoices: false,
  invoice_summary_closed: null,
  accounts_supplier_status: null,
  invoice_summary_system_disputed: null,
  invoice_summary_user_disputed: null,
  invoice_summary_statement: null,
  invoice_summary_collected: false,
  invoice_summary_paid_to_supplier: false,
  r_sp_id: '',
  r_file_name: '', 
  r_sc_id: '',
  r_sc_stage: '',
  r_creation_date: null,
  failed_imports_supplier_id: null,
  failed_imports_supplier_name: null,
  failed_imports_supplier_ana: '',
  ae_central_system: '',
  ae_sub_ledger: '',
  ae_server_process: '',
  ae_finish_date: null,
  ae_exported_active: null,
  ae_cancelled_active: null,
  lvysc_name: '',
  lvysc_rate: '',
  lvysc_type: '',
  lvysc_raise_type: '',
  lvysc_charge_type: '',
  lvysc_unitofmeasure: '',
  lvysc_vatcode: '',
  lvysc_start_date: null,
  lvysc_end_date: null,
  invrpc_supplier_invcode: '',
  invrpc_supplier_invcode_list_dump: '',
  invrpc_supplier_invcode_list: null,
  invrpc_supplier_name: null,
  invrpc_brand: '',
  invrpc_brand_owner: '',
  invrpc_product: '',
  invrpc_spc: '',
  invrpc_from_date: null,
  invrpc_to_date: null,
  invrpc_member: null,
  invrpc_dc: null,
  invrpc_collected_active: false,
  invrpc_system_disputed_active: null,
  invrpc_user_disputed_active: null,
  me_sic: '',
  me_supplier: null,
  me_smac: '',
  me_invoice_date: null,
  failed_files_file_name: '',
  mie_supplier: null,
  promotion_type_name: '',
  retroFinal_from_date: null,
  retroInterim_from_date: null,
  retroInterim_search_name: '',
  edi_file_log_supplier_type: '',
  edi_file_conversiondate: null,
  edi_file_log_file_name: '',
  memberTier_name: '',
  invoiceEndDate: null
}

const productSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value
    },
    applyFilter: (state, action) => {
      const { key, value } = action.payload;
      state[key] = {...value}
    },
    resetExternalFilter: () => {
      return initialState
    },
    filterFlagChange: (state, action) => {
      state.filterApplied = action.payload
    },
    filterResetFlagChange: (state, action) => {
      state.filterReset = action.payload
    },
    setNusslReportFromDate: (state, action) => {
      state.from_date = action.payload
    },
    setMemberGroupReportFromDate: (state, action) => {
      state.mgr_from_date = action.payload
    },
    setSupplierReportFromDate: (state, action) => {
      state.from_date = action.payload
    },
    setInvoiceSummaryFromDate: (state, action) => {
      state.from_date = action.payload
    },
    setInvoiceSummaryToDate: (state, action) => {
      state.to_date = action.payload
    },
    setReportsFromDate: (state, action) => {
      state.r_creation_date = action.payload
    },
    setAccountingEntriesFinishDate: (state, action) => {
      state.ae_finish_date = action.payload
    },
    setLevySchemeStartDate: (state, action) => {
      state.lvysc_start_date = action.payload
    },
    setLevySchemeEndDate: (state, action) => {
      state.lvysc_end_date = action.payload
    },
    setInvoiceReprocessFromDate: (state, action) => {
      state.invrpc_from_date = action.payload
    },
    setInvoiceReprocessToDate: (state, action) => {
      state.invrpc_to_date = action.payload
    },
    SetRetroFinalFromDate: (state, action) => {
      state.retroFinal_from_date = action.payload
    },
    SetRetroIntermiFromDate: (state, action) => {
      state.retroInterim_from_date = action.payload
    },
    setMeinvoice: (state, action) => {
      const { key, value } = action.payload
      state[key] = value
    },
    setEdiFileLogConversionDate: (state, action) => {
      state.edi_file_conversiondate = action.payload
    },
    setInvoiceEndDate: (state, action) => {
      state.invoiceEndDate = action.payload
    }
  }
})

export const { setFilter, applyFilter, resetExternalFilter, filterFlagChange, setNusslReportFromDate, setMemberGroupReportFromDate, 
  setSupplierReportFromDate, setInvoiceSummaryFromDate, setInvoiceSummaryToDate, setReportsFromDate, setAccountingEntriesFinishDate,
  setLevySchemeStartDate, setLevySchemeEndDate, setInvoiceReprocessFromDate, setInvoiceReprocessToDate, setMeinvoice, SetRetroFinalFromDate, 
  SetRetroIntermiFromDate, setEdiFileLogConversionDate, filterResetFlagChange, setInvoiceEndDate } = productSlice.actions

export default productSlice.reducer