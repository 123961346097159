import React, { useCallback, useEffect, useRef, useState } from "react";
import { Grid, Box, Button, TextField, FormControl } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch, useSelector } from 'react-redux';
import axios from "../../../../services/utility/axios";
import { setSpecification, getIsError } from "../../../../store/slices/generalProduct";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SnackBarDialog from "../../../../components/shared/SnackBarDialog";

const Specification = () => {
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })
    const { handleWarning, general_product_fields, getSpecification, editTabData, hasEditData } = useSelector((state) => {
        return {
            handleWarning: state.modalActions.closeWaringAddProduct,
            general_product_fields: state.generalProduct,
            getSpecification: state.generalProduct.product_specification,
            editTabData: state.generalProduct.editTabData,
            hasEditData: state.generalProduct.hasEditData
        }
    });

    const [state, setState] = useState({
        addEditSpecificationModal: false,
        enableSpcification: false,
        loader: false,
        //specificationInput: getSpecification,
        defaultmode: false,
        isDesbled: true
    });

    const addEditSpecifications = () => {
        setState({ ...state, enableSpcification: getSpecification ? true : false })
    }

    const dispatch = useDispatch();

    const handleInputChange = (event) => {
        const { value, name } = event.target;
       if(value !== "") {
        dispatch(setSpecification(value))
       } else {
        dispatch(setSpecification(value))
        dispatch(getIsError({ key: 'specificationInput', value: true }))
       }
        
        //dispatch(setSpecification(value))
    }

    useEffect(() => {
        if (getSpecification === null || getSpecification === "") {
            dispatch(getIsError({ key: 'specificationInput', value: true }))
        } else {
            dispatch(getIsError({ key: 'specificationInput', value: false }))
        }
    }, [getSpecification])


    const handleSubmit = async (event) => {
        event.preventDefault();
        let push_specification = {
            "spec": getSpecification
        }
        try {
            if (getSpecification !== '') {
                setState({ ...state, loader: true })
                if (editTabData || hasEditData) {
                    const id = editTabData ? editTabData.productId : hasEditData.id;
                    // eslint-disable-next-line max-len
                     const { data } = await axios.post(`products/${id}/specification`, push_specification);
                    // // eslint-disable-next-line max-len
                    if (data.success || data.message === 'Specification updated successfully') {
                        setState({ ...state, loader: false })
                        setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
                        dispatch(setSpecification(getSpecification))
                    }
                }
            }

        } catch (error) {
            const { response: { data, status } } = error;
            setState({ ...state, loader: false })
            setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error", })
        }
    }

    return (
        <form key="text">

            <Box sx={{ flexGrow: 1, paddingX: 2 }} >
                <Box component={"div"} className="specificationBox">
                    <Box component="div" className='table-actions-buttons' sx={{ columnGap: 1.75, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 2.5 }}>
                        <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center' }, textAlign: { xs: "center" } }}>
                            <div className='table-actionable'>

                            </div>
                        </Box>
                    </Box>

                    <Box component="div" className="specificationList" sx={{ textAlign: 'center' }}>
                        <FormControl fullWidth>
                            <TextField
                                multiline
                                rows={5}
                                size="small"
                                id="specificationInput"
                                aria-describedby="specificationInput"
                                onChange={(e) => handleInputChange(e)}
                                value={getSpecification}
                                name="specificationInput"
                                inputRef={input => input && input.focus()}
                            />
                        </FormControl>
                    </Box>
                    <Box component="div" className="specificationList" sx={{ textAlign: 'center' }}>
                        <Box sx={{ marginTop: 2.5 }}>
                            <LoadingButton
                                onClick={(e) => handleSubmit(e)}
                                loading={state.loader}
                                loadingPosition="center"
                                variant="contained"
                                type="submit"
                                //disabled={(!state.specificationInput) || (state.defaultmode === false)}
                                disabled={(hasEditData || editTabData) ? general_product_fields.isError.specificationInput : (!state.specificationInput) || (state.defaultmode === false)}
                            >
                                Save Specification
                            </LoadingButton>
                        </Box>
                    </Box>
                </Box>
                {/* <Grid container direction="row" alignItems="center" justifyContent="center">
                    <LoadingButton
                        onClick={handleSubmit}
                        loading={state.loader}
                        loadingPosition="center"
                        endIcon=""
                        variant="contained"
                        type="submit" sx={{ marginRight: '15px' }}
                        disabled={state.formValid}
                    >
                        Save
                    </LoadingButton>
                    <Button disabled={state.formValid} variant="contained" onClick={handleSubmit} type="submit" sx={{ marginRight: '15px' }}>Submit</Button> 
                    <Button variant="outlined" onClick={calcelSpecification}>Cancel</Button>
                </Grid> */}
            </Box>
            {snackBar.show && <SnackBarDialog
                onShow={snackBar.show}
                text={snackBar.text}
                type={snackBar.type}
                onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
            />}
        </form>
    );
};
export default Specification;