import { Container, Box, Modal, Button, Grid, Checkbox, Popper, Grow, Paper, ClickAwayListener, MenuList, ButtonGroup, Stack, TextField, FormControl, FormHelperText, FormLabel, IconButton, Typography, Autocomplete } from '@mui/material';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TableFilters } from "../../components/shared/data-table/TableFilters";
import { useEffect, useState } from "react";
import { InputText } from "../../components/shared/form/InputText";
import { } from '@mui/material';
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange, setInvoiceSummaryFromDate, setInvoiceSummaryToDate, filterResetFlagChange, setInvoiceEndDate } from "../../store/slices/externalFilter";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import moment from "moment";
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { ThreeStateCheckbox } from "../../components/shared/form/ThreeStateCheckbox";
import { warningModal, handleAddEditModal, handleUserDisputeModal, releaseSystemDisputeModal, handleModalState } from "../../store/slices/modals";
import WarningModal from "../../components/shared/modals/WarningModal";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { setRowPage, resetDataGrid, setPageNo } from '../../store/slices/dataGrid';
import { useLayoutEffect } from "react";
import { styled } from '@mui/material/styles';
import { hasTabEdit } from "../../store/slices/generalProduct";
import { setSelectedRow } from "../../store/slices/invoiceViewDetails";
import { AutoCompleteStatic } from "../../components/shared/form/AutoCompleteStatic";
import axiosMember from '../../services/utility/axios-member';
import InvoiceDetails from './detailInvoices/InvoiceDetails';
import LinesandDiscountsTab from './detailInvoices/invoice-details-tabs/lines-discounts';
import LineDisputeLogTab from './detailInvoices/invoice-details-tabs/line-dispute-log';
import SelectedInvoiceTab from './detailInvoices/invoice-details-tabs/selected-invoice';
import SnackBarDialog from "../../components/shared/SnackBarDialog";
import UserDisputeModal from './disputes/UserDisputeModal';
import { releaseSystemDisputeStatus } from '../../store/slices/invoiceDisputes';
import { smaInput, setMemberCode } from "../../store/slices/supplierMemberAccounts";
import axiosInvoiceSummaries from '../../services/utility/axios-invoice-summaries';
import PageLoader from '../../components/shared/PageLoader';
import CommonPopUp from "../products/addEditPdc/pdc-tabs/CommonPopUp";
import UploadInvoiceModal from './UploadInvoiceModal';
import { DataTableInvoiceSummary } from '../../components/shared/data-table/DataTableInvoiceSummary';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import RefreshIcon from '@mui/icons-material/Refresh';

var disputeflag = "default";
var selected_row = null;

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(1.5),
    boxShadow: "none",
    background: "none"
}));

const IconMenuLink = ({ params }) => {
    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { showAddEditModal, invoiceSummary_fields, invoiceDetailsRow, showUserDisputeModal, releaseSysDispute } = useSelector((state) => {
        return {
            showAddEditModal: state.modalActions.hideAddEditModal,
            editTabData: state.invoiceSummarys.editTabData,
            invoiceSummary_fields: state.invoiceSummarys,
            invoiceDetailsRow: state.invoiceViewDetails,
            showUserDisputeModal: state.modalActions.hideUserDisputeModal,
            releaseSysDispute: state.modalActions.releaseSystemDispute
        }
    });
    const dispatch = useDispatch();



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'view_more') {
            dispatch(handleAddEditModal({
                showAddEdit: !showAddEditModal
            }))
            dispatch(hasTabEdit({ editTab: false })) // For showing 3 tabs inside the View More Modal
            // selectedvals = row;
            dispatch(setSelectedRow({ selectedrow: row }))
        }
        if (action === 'release_system_dispute') {
            selected_row = row;
            dispatch(releaseSystemDisputeStatus({
                key: 'startReleaseSystemDispute',
                value: true
            }));
            dispatch(releaseSystemDisputeModal({
                releasesystemdispute: !releaseSysDispute
            }));
        }
        if (action === 'set_user_dispute') {
            selected_row = row;
            disputeflag = "setuserdispute";
            dispatch(handleUserDisputeModal({
                showAddEditUserDispute: !showUserDisputeModal
            }))
        }
        if (action === 'release_user_dispute') {
            selected_row = row;
            disputeflag = "releaseuserdispute";
            dispatch(handleUserDisputeModal({
                showAddEditUserDispute: !showUserDisputeModal
            }))
        }
        setAnchorEl(null);
    };

    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.invoiceId + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.invoiceId}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'view_more')}>View More</MenuItem>
            <MenuItem disabled={row.systemDisputed === false ? true : false} onClick={(e) => handleClose(e, 'release_system_dispute')}>Release System Dispute</MenuItem>
            {
                row.userDisputed === false ? <MenuItem onClick={(e) => handleClose(e, 'set_user_dispute')}>Set User Dispute</MenuItem> : <MenuItem onClick={(e) => handleClose(e, 'release_user_dispute')}>Release User Dispute</MenuItem>
            }


        </Menu>
    </>)
}
function InvoiceSummary() {

    // eslint-disable-next-line max-len
    const { supplier_invoice_code, supplier_invoice_name, supplier_invoice_member, supplier_invoice_distribution_channel, fromDate, toDate, invoice_summary_closed,
        invoice_summary_system_disputed, invoice_summary_user_disputed, invoice_summary_statement, invoice_summary_collected, invoice_summary_paid_to_supplier,
        supplier_invoice_member_account, addProductwarning, showAddEditModal, showUserDisputeModal, release_system_dispute, releaseSysDispute, sma_container, handleUploadInvoiceModal, invoiceEndDate,
        reprocessIds } = useSelector((state) => {
            return {
                supplier_invoice_code: state.externalFilter.supplier_invoice_code,
                supplier_invoice_name: state.externalFilter.supplier_invoice_name,
                supplier_invoice_member: state.externalFilter.supplier_invoice_member,
                supplier_invoice_distribution_channel: state.externalFilter.supplier_invoice_distribution_channel,
                invoice_summary_supplier: state.externalFilter.invoice_summary_supplier,
                supplier_invoice_member_account: state.externalFilter.supplier_invoice_member_account,
                fromDate: state.externalFilter.from_date,
                toDate: state.externalFilter.to_date,
                invoice_summary_closed: state.externalFilter.invoice_summary_closed,
                invoice_summary_system_disputed: state.externalFilter.invoice_summary_system_disputed,
                invoice_summary_user_disputed: state.externalFilter.invoice_summary_user_disputed,
                invoice_summary_statement: state.externalFilter.invoice_summary_statement,
                invoice_summary_collected: state.externalFilter.invoice_summary_collected,
                invoice_summary_paid_to_supplier: state.externalFilter.invoice_summary_paid_to_supplier,
                addProductwarning: state.modalActions.closeWaringAddProduct,
                showAddEditModal: state.modalActions.hideAddEditModal,
                showUserDisputeModal: state.modalActions.hideUserDisputeModal,
                releaseSysDispute: state.modalActions.releaseSystemDispute,
                release_system_dispute: state.invoiceDisputes,
                sma_container: state.smas,
                handleUploadInvoiceModal: state.modalActions.handleUploadInvoice,
                invoiceEndDate: state.externalFilter.invoiceEndDate,
                reprocessIds: state.invoiceReprocess.reprocessIds

                // releaseUserDisputeModal
            }
        });

    const [state, setState] = useState({
        invoice_summary_closed: null,
        invoice_summary_system_disputed: null,
        invoice_summary_user_disputed: null,
        invoice_summary_statement: null,
        invoice_summary_collected: false,
        invoice_summary_paid_to_supplier: false,
        pageLoader: false,
        openinvoiceSummarysModal: false,
        memberAccountEndpoint: '',
        selectMemberflag: true,
        supplierMemberDasboadModal: false,
        handleSupplierMemberModal: '',
        selectMemberstatus: 'Please Select Member First',
        invoiceIdList: [],
        defaultGrid: {
            checkBoxSelection: true,
            pagetitle: "Invoice Summary",
            pdcActionButton: true,
            exportExcelButton: true,
            addEditButton: true,
            apiUrl: 'invoicesummary',
            searchUrl: '/invoicesummary/filter'
        }
    });



    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    });

    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const uname = accounts[0] && accounts[0].name;

    const tabs = [
        {
            label: "Lines & Discounts",
            Component: <LinesandDiscountsTab />
        },
        {
            label: "Line Dispute Log",
            Component: <LineDisputeLogTab />
        },
        {
            label: "Selected Invoice",
            Component: <SelectedInvoiceTab />
        }
    ];

    const columns = [

        {
            headerName: "Supplie Invoice Code",
            field: "supplierInvoiceCode",
            minWidth: 120
        },
        {
            headerName: "Invoice Date",
            field: "invoiceDate",

            minWidth: 120
        },
        {
            headerName: "Amount (£)",
            field: "grossTotal",
            width: 120
        },
        {
            headerName: "DC",
            field: "distributionChannel",
            width: 150
        },
        {
            headerName: "Statement Cycle Stage",
            field: "statementCycleStage",
            width: 180,
            align: 'center'
        },
        {
            headerName: "SC",
            field: "statementCycleid",
            width: 40,
            valueFormatter: ({ value }) => value !== 0 ? value : '',
            align: 'center'
        },
        {
            headerName: "Supplier",
            field: "suppliername",

            minWidth: 120
        },
        {
            headerName: "Member Account",
            field: "memberAccountName",

            minWidth: 120
        },
        {
            headerName: "SMA Code",
            field: "supplierMemberAccountCode",

            minWidth: 100
        },
        {
            headerName: "Member Outlet",
            field: "memberOutlet",
            flex: 1,
            width: 100
        },

        {
            headerName: "Delivery Date",
            field: "deliveryDate",

            minWidth: 120
        },
        {
            headerName: "User Dispute",
            field: "userDisputed",
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
            headerAlign: "center",
            align: 'center',
            minWidth: 110
        },
        {
            headerName: "System Dispute",
            field: "systemDisputed",
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
            headerAlign: "center",
            align: 'center',
            minWidth: 130
        },
        {
            headerName: "Closed",
            field: "closed",
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
            headerAlign: "center",
            align: 'center',
            width: 80
        },
        {
            headerName: "Purchasing Tier",
            field: "memberTier",
            valueGetter: (params) => params.row.memberTier ? params.row.memberTier.name : "",
            minWidth: 150
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} />;
            }
        }


    ];


    const handleStartDate = (event) => {
        const sDate = (event === null) ? null : moment(event, 'DD/MM/YYYY')
        dispatch(setInvoiceSummaryFromDate(sDate))
    };

    const handleEndDate = (event) => {
        const eDate = (event === null) ? null : moment(event, 'DD/MM/YYYY')
        dispatch(setInvoiceSummaryToDate(eDate))
    };

    const handleInvoiceEndDate = (event) => {
        const eDate = (event === null) ? null : moment(event, 'DD/MM/YYYY')
        dispatch(setInvoiceEndDate(eDate))
    }


    const dispatch = useDispatch();

    const [mbacs, setMbAcs] = useState({

        mbac_selected: null,
        mbac_options: []
    })

    useEffect(() => {

        const fetchMyAPI = async () => {
            if (release_system_dispute.confirmReleaseSystemDispute) {

                try {
                    let payLoad = null;
                    payLoad = {
                        "user": uname,
                        "invoiceId": reprocessIds.length > 0 ? reprocessIds : [selected_row.invoiceId]
                    }
                    const { data } = await axiosInvoiceSummaries.post(`invoicesummary/releaseSystemDispute`, { ...payLoad });
                    if (data.success) {
                        setState({ ...state, pageLoader: false });
                        setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Sytem dispute released successfully!", type: "success" });
                        dispatch(releaseSystemDisputeStatus({
                            key: 'confirmReleaseSystemDispute',
                            value: false
                        }))
                        setTimeout(() => {
                            // window.location.reload()
                            filterBtn()
                        }, 2500);
                    }

                }
                catch (error) {
                    const { response: { data, status } } = error;
                    if (!data) {
                        setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                    } else {
                        setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                    }
                    setState({ ...state, pageLoader: false });

                }

            }

        }
        fetchMyAPI();

    }, [release_system_dispute.confirmReleaseSystemDispute])

    useEffect(() => {
        // This Use Effect is used to clear the Lookups when the page loads.
        dispatch(setMemberCode({
            key: 'smaSupplierNameOnModal',
            value: ""
        }));
        dispatch(smaInput({
            key: 'sma_supplier_name',
            value: ""
        }));
        dispatch(setMemberCode({
            key: 'smaMemberCodeOnModal',
            value: ""
        }));
        dispatch(smaInput({
            key: 'sma_member_name',
            value: ""
        }));

    }, [])

    // useEffect(() => {
    //     const invoiceIds = reprocessIds.map((ele) => {
    //         return ele.invoiceId
    //     })
    //     setState({ ...state, invoiceIdList: invoiceIds })

    // }, [reprocessIds])


    const handleFilterInput = (value, id) => {
        //setState({ ...state, [id]: value });
        dispatch(setFilter({
            key: id,
            value: value ? value : ""
        }))

        if (value !== null) {
            if (id === 'supplier_invoice_member') {

                if (value.id) {
                    axiosMember.get(`members/${value.id}/memberAccounts`)
                        .then(function (response) {
                            // handle success
                            const { status, data } = response;
                            if (data) {
                                const unique = data;
                                setMbAcs({ ...mbacs, mbac_options: unique });
                                if (unique.length > 0) {
                                    state.memberAccountEndpoint = "members/" + value.id + "/memberAccounts";
                                    state.selectMemberflag = false;
                                }
                                else {
                                    state.selectMemberstatus = 'No Member Account present for this Member'
                                    state.selectMemberflag = true;
                                }

                            }
                        })
                        .catch(function (error) {
                            // handle error
                            console.log(error);
                        });

                }


            }

        }
        if (id === 'supplier_invoice_member' && value !== null) {
            setState({ ...state, selectMemberflag: false })
        } else {
            if (id === 'supplier_invoice_member') {
                setState({ ...state, selectMemberflag: true })
                setMbAcs({ ...mbacs, mbac_selected: null })
            }
        }
    };

    const handleFilterTextInput = (value, id) => {
        //setState({ ...state, [id]: value });
        dispatch(setFilter({
            key: id,
            value: value ? value : value.name ? value.name : ''
        }))
    };

    const handleChange = (checked, name) => {
        setState({ ...state, [name]: checked })
        dispatch(setFilter({
            key: name,
            value: checked
        }))
    };

    const selectedAutoComplete = (value, id) => {
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
        // if (id === 'supplier_invoice_member' && value) {
        //     setState({ ...state, selectMemberflag: false })
        // } else {
        //     if(id === 'supplier_invoice_member') {
        //         setTimeout(() => {
        //             setMbAcs({ ...mbacs, mbac_selected: null })
        //         }, 100);
        //         setState({ ...state, selectMemberflag: true })
        //     }
        // }
    }

    const filterBtn = () => {
        dispatch(resetDataGrid());
        var dynamicFilterObjects = {};

        if (supplier_invoice_code !== '' && supplier_invoice_code !== null) {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "supplierInvoiceCode": supplier_invoice_code
            }
        }

        if (supplier_invoice_name !== '' && supplier_invoice_name !== null) {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "supplierId": supplier_invoice_name.id,
                "suppliername": supplier_invoice_name.name,
            }
        }

        if (supplier_invoice_distribution_channel !== '' && supplier_invoice_distribution_channel !== null) {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "distributionChannelid": supplier_invoice_distribution_channel.id
            }
        }

        if (supplier_invoice_member !== '' && supplier_invoice_member !== null) {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "member": supplier_invoice_member.name
            }

            if (mbacs.mbac_selected !== '' && mbacs.mbac_selected !== null) {
                dynamicFilterObjects = {
                    ...dynamicFilterObjects,
                    "memberAccountName": mbacs.mbac_selected.name
                }
            }
        }

        if (fromDate !== '' && fromDate !== null) {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "deliveryDate": moment(fromDate).format('DD/MM/YYYY')
            }
        }

        if (toDate !== '' && toDate !== null) {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "invoiceDate": moment(toDate).format('DD/MM/YYYY')
            }
        }

        if (invoiceEndDate !== '' && invoiceEndDate !== null) {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "invoiceEndDate": moment(invoiceEndDate).format('DD/MM/YYYY')
            }
        }

        dynamicFilterObjects = {
            ...dynamicFilterObjects,
            "appearedOnStatmnt": invoice_summary_statement,
            "closed": invoice_summary_closed,
            "collectedFromMember": invoice_summary_collected,
            "paidToSupplier": invoice_summary_paid_to_supplier,
            "systemDisputed": invoice_summary_system_disputed,
            "userDisputed": invoice_summary_user_disputed,
            // "brandId": 0,
            // "brandOwnerId": 0,
            // "productId": 0,
            // "supplierMemberAccountCode": "string",
            // "supplierProductCode": "string",
        }

        if ((fromDate !== "" && fromDate !== null && moment(fromDate).format('DD/MM/YYYY') === 'Invalid date') || (toDate !== "" && toDate !== null && moment(toDate).format('DD/MM/YYYY') === 'Invalid date') || (invoiceEndDate !== "" && invoiceEndDate !== null && moment(invoiceEndDate).format('DD/MM/YYYY') === 'Invalid date')) {
            setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
        }
        else {
            dispatch(applyFilter({
                key: 'filterList',
                value: dynamicFilterObjects
            }));

            dispatch(filterFlagChange(true));
            dispatch(filterResetFlagChange(false));
            dispatch(setRowPage({ pageData: 10 }));
            dispatch(setPageNo({ page: 1 }));
        }


    }
    const resetFilter = () => {
        if ((supplier_invoice_name || supplier_invoice_code || supplier_invoice_member || supplier_invoice_distribution_channel || supplier_invoice_member_account || fromDate || toDate || invoiceEndDate) !== '') {
            dispatch(resetExternalFilter())
            dispatch(resetDataGrid());
        }
        setState({
            ...state, invoice_summary_closed: null, invoice_summary_system_disputed: null, invoice_summary_user_disputed: null, invoice_summary_statement: null,
            invoice_summary_collected: false, invoice_summary_paid_to_supplier: false, selectMemberflag: true, selectMemberstatus: 'Please Select Member First'
        });
        setMbAcs({ ...mbacs, mbac_selected: null })
        dispatch(filterResetFlagChange(true));
        dispatch(setMemberCode({
            key: 'smaSupplierNameOnModal',
            value: ""
        }));
        dispatch(smaInput({
            key: 'sma_supplier_name',
            value: ""
        }));
        dispatch(setMemberCode({
            key: 'smaMemberCodeOnModal',
            value: ""
        }));
        dispatch(smaInput({
            key: 'sma_member_name',
            value: ""
        }));
        dispatch(setFilter({
            key: 'invoice_summary_collected',
            value: null
        }))
        dispatch(setFilter({
            key: 'invoice_summary_paid_to_supplier',
            value: null
        }))
    }

    const hideInvoiceSummaryModal = (event) => {
        setState({ ...state, openinvoiceSummarysModal: false })
    }

    const userDisputeModal = (event, reason) => {
        // setState({ ...state, openinvoiceSummarysModal: false })
    }

    const resetLookups = (params, event) => {
        if (event === 'supplier') {
            dispatch(setMemberCode({
                key: 'smaSupplierNameOnModal',
                value: ""
            }));
            dispatch(smaInput({
                key: 'sma_supplier_name',
                value: ""
            }));
            dispatch(setFilter({
                key: 'supplier_invoice_name',
                value: null
            }))
        }
        if (event === 'members') {
            dispatch(setMemberCode({
                key: 'smaMemberCodeOnModal',
                value: ""
            }))
            dispatch(smaInput({
                key: 'sma_member_name',
                value: ""
            }))
            setMbAcs({ ...mbacs, mbac_options: [], mbac_selected: null });
            setState({ ...state, selectMemberflag: true });
            dispatch(setFilter({
                key: 'supplier_invoice_member',
                value: null
            }))
        }
    }

    const CommonPopUpModal = (e, type) => {
        setState({ ...state, supplierMemberDasboadModal: true, handleSupplierMemberModal: type })
    }

    const hidePriceModal = (event, reason, type) => {
        if (type === 'supplier') {
            if (reason) {
                dispatch(setFilter({
                    key: 'supplier_invoice_name',
                    value: reason ? { "id": reason.id, "name": reason.name } : null
                }))
            }
            setState({ ...state, supplierMemberDasboadModal: event === false ? event : true })
        }
        if (type === 'members') {
            if (reason) {
                dispatch(setFilter({
                    key: 'supplier_invoice_member',
                    value: reason ? { "id": reason.id, "name": reason.name, "active": reason.active } : null
                }))

                axiosMember.get(`members/${reason.id}/memberAccounts`)
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data;
                            setMbAcs({ ...mbacs, mbac_options: unique });
                            if (unique.length > 0) {
                                state.memberAccountEndpoint = "members/" + reason.id + "/memberAccounts";
                                //state.selectMemberflag = false;
                                setState({ ...state, selectMemberflag: false, supplierMemberDasboadModal: event === false ? event : true })
                            }
                            else {
                                //state.selectMemberstatus = 'No Member Account present for this Member'
                                setState({ ...state, selectMemberflag: true, selectMemberstatus: 'No Member Account present for this Member', supplierMemberDasboadModal: event === false ? event : true })
                            }

                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                        setState({ ...state, supplierMemberDasboadModal: event === false ? event : true })
                    });

            } else {
                setState({ ...state, supplierMemberDasboadModal: event === false ? event : true })
            }
        }

    }
    const uploadInvoice = () => {
        dispatch(handleModalState({
            key: 'handleUploadInvoice',
            value: true
        }))
    }

    const handleSetUserDisputes = () => {
        if (reprocessIds.length > 0) {
            disputeflag = "setuserdispute";
            dispatch(handleUserDisputeModal({
                showAddEditUserDispute: !showUserDisputeModal
            }))
        } else {
            setSnackBar({ ...snackBar, show: true, text: "Please select invoice...!", type: "error", });
        }
    }

    const handleReleaseUserDisputes = () => {
        if (reprocessIds.length > 0) {
            disputeflag = "releaseuserdispute";
            dispatch(handleUserDisputeModal({
                showAddEditUserDispute: !showUserDisputeModal
            }))
        } else {
            setSnackBar({ ...snackBar, show: true, text: "Please select invoice...!", type: "error", });
        }
    }

    const handleReleaseSystemDisputes = () => {
        if (reprocessIds.length > 0) {
            dispatch(releaseSystemDisputeStatus({
                key: 'startReleaseSystemDispute',
                value: true
            }));
            dispatch(releaseSystemDisputeModal({
                releasesystemdispute: !releaseSysDispute
            }))
        } else {
            setSnackBar({ ...snackBar, show: true, text: "Please select invoice...!", type: "error", });
        }
    }
    const onClearAutoComplete = (value, id) => {
        dispatch(setFilter({
            key: id,
            value: null
        }))
    }
    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Invoice Summary" />
                <Box sx={{ marginBottom: 2.5 }}>
                    <TableFilters
                        filterFields={<>
                            <Box component="div"><InputText onChange={handleFilterTextInput} maxWidth={50} label="Supplier Invoice Code" id="supplier_invoice_code" size="small" /></Box>
                            <Box component="div"><AutoCompleteStatic endPoint="distributionChannels/drop-down" onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} id="supplier_invoice_distribution_channel" label="Distribution Channel" name="supplier_invoice_distribution_channel" /></Box>
                            <Box component="div"><AutoCompleteStatic endPoint="/supplier/look-up" onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} onClear={onClearAutoComplete} id="supplier_invoice_name" label="Supplier Name" name="supplier_invoice_name" /></Box>
                            <Box component="div"><AutoCompleteStatic endPoint="members/look-up?pageSize=500" onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} id="supplier_invoice_member" label="Member" name="supplier_invoice_member" /></Box>
                            {/* <Box component="div">
                                <FormLabel htmlFor="supplier_invoice_member" className="fontBold">Member</FormLabel>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={11}>
                                        <Item>
                                            <Box component="div" sx={{ paddingTop: '6px' }} >
                                                <FormControl size={'small'} fullWidth>
                                                    <TextField
                                                        inputProps={{ maxLength: 100 }}
                                                        size="small"
                                                        id="supplier_invoice_member"
                                                        value={(sma_container.smaMemberCodeOnModal ? sma_container.smaMemberCodeOnModal.name : '')}
                                                        aria-describedby="supplier_invoice_member_error"
                                                        name="supplier_invoice_member"
                                                        disabled={true}
                                                    />

                                                </FormControl>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        <Item>
                                            <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0, marginRight: '5px' }} onClick={(e) => resetLookups(e, 'members')} startIcon={<ClearIcon />}></Button>
                                            <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={(e) => CommonPopUpModal(e, 'members')} startIcon={<EditIcon />}></Button>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box> */}
                            <Box component="div">
                                {/* <AutoCompleteStatic endPoint={state.memberAccountEndpoint} onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} id="supplier_invoice_member_account" label="Member Account" name="supplier_invoice_member_account" /> */}
                                <FormLabel htmlFor="supplier_invoice_member_account" className="fontBold">Member Account</FormLabel>
                                <Autocomplete
                                    value={mbacs.mbac_selected ? mbacs.mbac_selected : null}
                                    id="supplier_invoice_member_account"
                                    disabled={state.selectMemberflag}
                                    options={mbacs.mbac_options}
                                    getOptionLabel={(option) => option.name || ""}
                                    componentsProps={{
                                        paper: {
                                            sx: {
                                                fontSize: '0.85rem'
                                            }
                                        }
                                    }}
                                    onChange={(event, newValue) => {
                                        if ((event.target.value !== "" || event.target.value !== undefined) && newValue !== null) {
                                            // setProductvls({ ...productvls, pvls_selected: newValue })
                                            setMbAcs({ ...mbacs, mbac_selected: { id: newValue.id, name: newValue.name } })
                                        } else {
                                            setMbAcs({ ...mbacs, mbac_selected: null })
                                        }
                                    }}
                                    // onChange={handleFilterInput}
                                    renderInput={(params) =>
                                        <FormControl size={'small'} sx={{ paddingTop: '6px' }} fullWidth>
                                            <div className="input-group">
                                                <TextField {...params}

                                                    size="small"
                                                    id="supplier_invoice_member_account_search"
                                                    aria-describedby="supplier_invoice_member_account-error"
                                                />
                                            </div>
                                        </FormControl>

                                    }
                                />
                                {
                                    // state.selectMemberflag && <Typography sx={{ position: 'absolute', padding: '5px 2px', fontSize: '14px' }}>{state.selectMemberstatus}</Typography>

                                }
                            </Box>
                            {/* <Box component="div">
                                <FormLabel htmlFor="price_start_date" className="fontBold">Delivery Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment} >
                                    <DesktopDatePicker
                                        value={fromDate}
                                        onChange={(e) => { handleStartDate(e) }}
                                        name="from_date" label="Start Date"
                                        inputFormat="DD/MM/YYYY"
                                        // eslint-disable-next-line max-len
                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                            <Box component="div" sx={{ paddingTop: '6px' }} className='date-picker-input'>
                                                <FormControl size="small" sx={{ width: '100%' }} >
                                                    <TextField size="small" id="from_date"
                                                        ref={inputRef} {...inputProps}
                                                        placeholder=""
                                                        aria-describedby="from_date_error"
                                                        autoComplete="off"

                                                    />
                                                    <Box className='date-picker-icon'>
                                                        {InputProps?.endAdornment}
                                                    </Box>
                                                </FormControl>
                                            </Box>
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box> */}
                            <Box component="div">
                                <FormLabel htmlFor="price_start_date" className="fontBold">Invoice Start Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        value={toDate}
                                        onChange={(e) => { handleEndDate(e) }}
                                        name="to_date" label="End Date"
                                        inputFormat="DD/MM/YYYY"
                                        // eslint-disable-next-line max-len
                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                            <Box component="div" sx={{ paddingTop: '6px' }} className='date-picker-input'>
                                                <FormControl size="small" sx={{ width: '100%' }} >
                                                    <TextField size="small" id="to_date"
                                                        ref={inputRef} {...inputProps}
                                                        placeholder=""
                                                        aria-describedby="to_date_error"
                                                        autoComplete="off"
                                                    />
                                                    <Box className='date-picker-icon'>
                                                        {InputProps?.endAdornment}
                                                    </Box>
                                                </FormControl>
                                            </Box>
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>
                            <Box component="div">
                                <FormLabel htmlFor="price_end_date" className="fontBold">Invoice End Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        value={invoiceEndDate}
                                        onChange={(e) => { handleInvoiceEndDate(e) }}
                                        name="to_date" label="End Date"
                                        inputFormat="DD/MM/YYYY"
                                        // eslint-disable-next-line max-len
                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                            <Box component="div" sx={{ paddingTop: '6px' }} className='date-picker-input'>
                                                <FormControl size="small" sx={{ width: '100%' }} >
                                                    <TextField size="small" id="to_date"
                                                        ref={inputRef} {...inputProps}
                                                        placeholder=""
                                                        aria-describedby="to_date_error"
                                                        autoComplete="off"
                                                    />
                                                    <Box className='date-picker-icon'>
                                                        {InputProps?.endAdornment}
                                                    </Box>
                                                </FormControl>
                                            </Box>
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>
                            {/* <Box component="div"><AutoCompleteStatic endPoint="supplier/drop-down?dcType=7" onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} id="supplier_invoice_name" label="Supplier Name" name="supplier_invoice_name" />
                                </Box> */}


                            {/* <Box component="div"><AutoCompleteStatic endPoint="members/look-up" onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} id="supplier_invoice_member" label="Member" name="supplier_invoice_member" /></Box> */}
                            {/* <div style={{ width: '94vw', display: 'grid', alignItems: 'center', columnGap: 12, rowGap: 20, gridTemplateColumns: 'repeat(5, 1fr)', marginBottom: '20px', padding: 0 }}></div> */}
                        </>}
                        filterCheckboxes={<>
                            <Box component="div">
                                <ThreeStateCheckbox checked={state.invoice_summary_closed} onChange={handleChange} name="invoice_summary_closed" id="invoice_summary_closed" label="Closed" />
                            </Box>
                            <Box component="div">
                                <ThreeStateCheckbox checked={state.invoice_summary_system_disputed} onChange={handleChange} name="invoice_summary_system_disputed" id="invoice_summary_system_disputed" label="System Disputed" />
                            </Box>
                            <Box component="div">
                                <ThreeStateCheckbox checked={state.invoice_summary_user_disputed} onChange={handleChange} name="invoice_summary_user_disputed" id="invoice_summary_user_disputed" label="User Disputed" />
                            </Box>
                            <Box component="div">
                                <ThreeStateCheckbox checked={state.invoice_summary_statement} onChange={handleChange} name="invoice_summary_statement" id="invoice_summary_statement" label="Appeared on statement" />
                            </Box>
                            <Box component="div">
                                <ThreeStateCheckbox checked={state.invoice_summary_collected} onChange={handleChange} name="invoice_summary_collected" id="invoice_summary_collected" label="Collected" />
                            </Box>
                            <Box component="div">
                                <ThreeStateCheckbox checked={state.invoice_summary_paid_to_supplier} onChange={handleChange} name="invoice_summary_paid_to_supplier" id="invoice_summary_paid_to_supplier" label="Paid to supplier" />
                            </Box>
                        </>}
                        filterBtns={
                            <>
                                <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                <Button variant="contained" onClick={filterBtn}>Apply</Button>
                            </>}>
                    </TableFilters>
                </Box>

                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', alignItems: 'center', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -3 } }}>
                    <div className='table-actionable'>
                        <Box component={'div'} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={filterBtn}>
                            <RefreshIcon color="primary"></RefreshIcon> <span style={{ marginLeft: '5px', color: '#B253AE' }}>Reload Table</span>
                        </Box>
                    </div>
                    <div className='table-actionable'>
                        <Button variant="contained" color="tertiary" size='small' onClick={handleSetUserDisputes}>
                            Set User Disputes
                        </Button>
                    </div>

                    <div className='table-actionable'>
                        <Button variant="contained" color="primary" size='small' onClick={handleReleaseUserDisputes}>
                            Release User Disputes
                        </Button>
                    </div>
                    <div className='table-actionable'>
                        <Button variant="contained" color="secondary" size='small' onClick={handleReleaseSystemDisputes}>
                            Release System Disputes
                        </Button>
                    </div>
                    <div className='table-actionable'>
                        <Button variant="contained" size='small' onClick={uploadInvoice}>Upload Invoice</Button>
                    </div>
                </Box>
                <DataTableInvoiceSummary defaultGrid={state.defaultGrid} columns={columns}></DataTableInvoiceSummary>
                {
                    showAddEditModal && (
                        <InvoiceDetails
                            tabs={tabs}
                            onShow={showAddEditModal}
                            onHide={(e, reason) => hideInvoiceSummaryModal(e, reason)}


                        />
                    )
                }
                {
                    showUserDisputeModal && (
                        <UserDisputeModal
                            values={disputeflag}
                            onShow={showUserDisputeModal}
                            onHide={(e, reason) => userDisputeModal(e, reason)}
                            selectedrow={selected_row}

                        />
                    )
                }
                {
                    handleUploadInvoiceModal && (
                        <UploadInvoiceModal size="small_overlay" onShow={handleUploadInvoiceModal} />
                    )
                }
                {
                    releaseSysDispute && (
                        <WarningModal size="small_overlay" onShow={releaseSysDispute} />
                    )
                }
                {snackBar.show && <SnackBarDialog
                    onShow={snackBar.show}
                    text={snackBar.text}
                    type={snackBar.type}
                    onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                />}

                {
                    state.supplierMemberDasboadModal && (
                        <CommonPopUp
                            onShow={state.supplierMemberDasboadModal} size="medium_overlay"
                            supplierMemberCode={{
                                title: (state.handleSupplierMemberModal && state.handleSupplierMemberModal === 'members') ? 'Member Details' : 'Supplier Details',
                                type: state.handleSupplierMemberModal
                            }}
                            onHide={(e, reason) => hidePriceModal(e, reason, state.handleSupplierMemberModal)}
                        />
                    )
                }


            </Container>

        </>
    );
}

export default InvoiceSummary;
