import { DataTable } from "../../components/shared/data-table/DataTable";
import { Container, Paper, Box, Button, Alert, IconButton, Menu, MenuItem, Checkbox, FormControlLabel, Grid, FormHelperText, TextField, FormControl, FormLabel, Modal, Select, Typography } from '@mui/material'

import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import SendIcon from '@mui/icons-material/Send';
import { useEffect, useState } from "react";
import { read, utils } from "xlsx";
import moment from "moment"
import { hasDuplicate, isEmpty, replaceKeys } from "../../services/utility/common-utils";
import { DataTableClientSide } from "../../components/shared/data-table/DataTableClientSide";
import { GridCheckCircleIcon, GridMoreVertIcon } from "@mui/x-data-grid";
import CircleIcon from '@mui/icons-material/Circle';
import { useDispatch, useSelector } from 'react-redux';
// import { editProductUpload, fileDeleteYes, singleDeleteRow, singleDeleteYes } from "../../store/slices/fileUpload";
import { editPdcUpload, fileDeleteYes, singleDeleteRow, singleDeleteYes, pdcwrongFile } from "../../store/slices/pdcsFileUpload";
import PdcFileUploadModal from "./pdcfileUpload/PdcFileUploadModal";
import UploadFileSelectModal from "./pdcfileUpload/UploadFileSelectModal";
import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningModal from "../../components/shared/modals/WarningModal";
import { warningModal, handleFileUploadModal } from "../../store/slices/modals";
import axios_product from "../../services/utility/axios";
import axios_pdc_file_upload from "../../services/utility/axios-product-distribution";
import SnackBarDialog from "../../components/shared/SnackBarDialog";
import PageLoader from "../../components/shared/PageLoader";
import downLoadTemplate from "../../assets/images/sample-pdc-file-upload.xls"

var supplier_id = "na";
var supplier_name = "na";
var dc_id = "na";
var dc_name = "na";
var current_selection = {};
var priceflag = false;
var chargeflag = false;
var promoflag = false;

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

const IconMenuLink = ({ params, onEdit, onDelete }) => {

    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'edit') {
            if (onEdit) {
                onEdit(row);
                //console.log(row);
            }
        } else if (action === 'delete') {
            if (onDelete) {
                onDelete(row)
            }
        }
        setAnchorEl(null);
    };

    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <GridMoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'edit')}>Edit</MenuItem>
            <MenuItem onClick={(e) => handleClose(e, 'delete')}>Delete</MenuItem>
        </Menu>
    </>)
}

function PdcFileUpload() {

    const { showFileUploadModal, general_product_fields, showFileUploadWarning } = useSelector((state) => {
        return {
            showFileUploadModal: state.modalActions.hideFileUploadModal,
            showFileUploadWarning: state.modalActions.closeWaringAddProduct,
            //editTabData: state.generalProduct.editTabData,
            // general_product_fields: state.generalProduct
        }
    });

    let selectedvals = {}

    const [columns, setColumns] = useState([

        {
            headerName: "SPC",
            field: "supplierProductCode",
            flex: 1,
            minWidth: 200,
        },
        {
            headerName: "Outer Case Barcode",
            field: "outerCaseBarcode",
            width: 180
        },
        {
            headerName: "Original Price",
            field: "price",
            width: 180
        },
        {
            headerName: "Revised Price",
            field: "validPrice",
            width: 180
        },
        {
            headerName: "Charge",
            field: "charge",
            width: 180,
        }

    ]);
    const [allColumns, setAllColumns] = useState([
        // {
        //     headerName: "id",
        //     field: "id"
        // },
        {
            headerName: "Result",
            field: "result",
            width: 100
        },
        {
            headerName: "Error Message",
            field: "errorMessage",
            minWidth: 350
        },
        {
            headerName: "SPC",
            field: "supplierProductCode",
            flex: 1,
            minWidth: 200,
        },
        {
            headerName: "Outer Case Barcode",
            field: "outerCaseBarcode",
            width: 180
        },
        {
            headerName: "Product Name",
            field: "productName",
            width: 180
        },
        {
            headerName: "Original Price",
            field: "price",
            width: 180
        },
        {
            headerName: "Revised Price",
            field: "validPrice",
            width: 180
        },
        {
            headerName: "Charge",
            field: "charge",
            width: 180,
        },
        {
            headerName: "Price Start Date",
            field: "priceStartDate",
            width: 180,

        },
        {
            headerName: "Price End Date",
            field: "priceEndDate",
            width: 180,

        },
        {
            headerName: "Charge Start Date",
            field: "chargeStartDate",
            width: 180,

        },
        {
            headerName: "Charge End Date",
            field: "chargeEndDate",
            width: 180,

        },
        {
            headerName: "Product Value Levy Scheme",
            field: "productValueLevyScheme",
            width: 180,

        },
        {
            headerName: "Dist. Charge Levy Scheme",
            field: "distributionChargeLevyScheme",
            width: 180,

        },
        {
            headerName: "Unit of Measure",
            field: "unitOfMeasureName",
            width: 180,

        },
        // {
        //     headerName: "Active",
        //     field: "newProduct",
        //     renderCell: (params) => (
        //         <Checkbox
        //             checked={params.value === true}
        //             icon={<CircleIcon />}
        //             checkedIcon={<GridCheckCircleIcon />}
        //             color="chk_rounded"
        //             size='small'
        //             style={{ pointerEvents: 'none' }}
        //         />
        //     ),
        //     align: 'center'
        // },

        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} onEdit={editRow} onDelete={deleteRow} />;
            }
        },
    ]);
    const [rows, setRows] = useState([]);
    const [state, setState] = useState({
        deleteRow: false,
        deleteAllProductUpload: false,
        fileName: '',
        openNewFileUploadModal: false,
        supplier_id: 'na',
        supplier_name: 'na',
        triggerBtnsFlag: true,
        duplicateColumns: [
            {
                headerName: "SPC",
                field: "supplierProductCode",
                flex: 1,
                minWidth: 200,
            },
            {
                headerName: "Outer Case Barcode",
                field: "outerCaseBarcode",
                width: 180
            },
            {
                headerName: "Original Price",
                field: "price",
                width: 180
            },
            {
                headerName: "Price Start Date",
                field: "priceStartDate",
                width: 180
            },
            {
                headerName: "Price End Date",
                field: "priceEndDate",
                width: 180
            },
            {
                headerName: "PDC Charge",
                field: "charge",
                width: 180,
            },
            {
                headerName: "Charge Start Date",
                field: "chargeStartDate",
                width: 180,
            },
            {
                headerName: "Charge End Date",
                field: "chargeEndDate",
                width: 180,
            },
            {
                headerName: "PV Levy",
                field: "productValueLevyScheme",
                width: 180,
            }, {
                headerName: "DC Levy",
                field: "distributionChargeLevyScheme",
                width: 180,
            }, {
                headerName: "Unit of Measure",
                field: "unitOfMeasureName",
                width: 180,
            }

        ],
        defaultGrid: {
            paginationMode: 'client',
            rowHeight: 'auto',
            pagehead: 'pdcfileupload',
            pageCountSize: true
        },
        fileNameId: '',
        fuSelectedName: '',
        pageLoader: false,
        freshUpload: false,
        supplier: '',
        distributionchannel: '',
        promo: false,
        notuploadcall: false
    });
    const [isError, setIsError] = useState({
        isOcBcDuplicate: false,
        isOcBcEmpty: false,
        isSpcEmpty: false,
        isPriceEmpty: false,
        isChargeEmpty: false,
        notUploded: true
    });

    const [uploadDropdown, setUploadDropdown] = useState([]);
    const [handleEditRowModal, setHandleEditRowModal] = useState(false);
    const [handleUploadFileModal, setHandleUploadFileModal] = useState(false);
    const [uploadBtnFlag, setUploadBtnFlag] = useState(true);
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    });
    const { handleWarning, fileUpload } = useSelector((state) => {
        return {
            fileUpload: state.productFileUpload,
            handleWarning: state.modalActions.closeWaringAddProduct
        }
    });
    const dispatch = useDispatch();

    useEffect(() => {
        // console.log(isError, priceflag, chargeflag);
        if (isError.isOcBcDuplicate) {
            //excelSuccessUpload('noErrors')
            // console.log("a");
        }
        else {
            if (isError.isOcBcEmpty || isError.isSpcEmpty || isError.isPriceEmpty || isError.isChargeEmpty || priceflag || chargeflag) {
                // console.log("b");
            }
            else {
                // eslint-disable-next-line no-empty
                if (isError.notUploded) {
                    //  console.log("c");
                }
                else {
                    // console.log("d");
                    fetchMyAPI()
                }
            }

        }
    }, [isError, priceflag, chargeflag])

    // const handlEupload = async (e) => {
    //     setRows([])
    //     var input = e.target;
    //     var reader = new FileReader();

    //     reader.onload = function () {
    //         var fileData = reader.result;
    //         var wb = read(fileData, { type: 'binary', cellDates: true });
    //         const data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { header: 1, defval: "" });
    //         let finalRows = data.slice(1).map((r) => r.reduce((acc, x, i) => {
    //             acc[data[0][i]] = x;
    //             return { ...acc, id: Math.random().toString(16).slice(2) };
    //         }, {}));

    //         const defaultValues = insertDefaultValues(finalRows);
    //         setIsError({ ...isError, isOcBcDuplicate: hasDuplicate(defaultValues, "outerCaseBarCode"), isOcBcEmpty: isEmpty(defaultValues, "outerCaseBarCode"), notUploded: defaultValues.length === 0 ? true : false })
    //         setRows(defaultValues)
    //     };

    //     reader.readAsBinaryString(input.files[0]);
    //     const fname = input.files[0].name.split('.');
    //     const fExtension = fname.pop()
    //     setState({ ...state, fileName: fname.join() + "_" + Date.parse(new Date) + '.' + fExtension })
    //     setColumns(state.duplicateColumns)
    // }

    const insertDefaultValues = (rows) => {
        let defaultValues = rows;
        replaceKeys(defaultValues);
        try {
            defaultValues.forEach(function (args) {
                //console.log(args);
                // if(!args.id)
                // {
                //     args.id = '';
                // }
                // if (!args.version) {
                //     args.version = null
                // }
                // if (!args.result) {
                //     args.result = 'Success'
                // }
                // if (!args.errorMessage) {
                //     args.errorMessage = ''
                // }
                // if (!args.unitOfWork) {
                //     args.unitOfWork = args.id;
                // }

                if (args.supplierProductCode) {
                    //args.supplierProductCode = Math.floor(100000 + Math.random() * 900000);
                    args.supplierProductCode = args.supplierProductCode.toString();
                    //args.outerCaseBarCode = args.supplierProductCode;
                }
                else {
                    args.supplierProductCode = args.supplierProductCode.toString();
                    // args.outerCaseBarCode = args.supplierProductCode;
                }

                /* Outer case Zero Padding */
                if (args.outerCaseBarcode) {
                    args.outerCaseBarcode = args.outerCaseBarcode.toString().toUpperCase()
                    // if (args.outerCaseBarcode && args.outerCaseBarcode.length <= 13) {
                    //     args.outerCaseBarcode = args.outerCaseBarcode.padStart(13, '0')
                    // }
                }

                if (args.price || args.price === 0) {
                    args.priceNullValidated = true;
                    args.validPrice = args.price.toFixed(4);
                }
                else {
                    args.price = null;
                    args.priceNullValidated = false;
                    args.validPrice = null;
                    args.priceStartDate = null;
                    args.priceEndDate = null;
                    args.priceStartDateNullValidated = false;
                    args.priceEndDateNullValidated = false;
                    args.validPriceStartDate = null;
                    args.validPriceEndDate = null;


                }

                if ((args.priceStartDate) !== "") {
                    //console.log("startdate check -1 ");
                    args.priceStartDate = moment(args.priceStartDate).format('DD/MM/YYYY');
                    args.priceStartDateNullValidated = true;
                    args.validPriceStartDate = args.priceStartDate;
                }
                else {
                    args.priceStartDate = null;
                    args.priceStartDateNullValidated = false;
                    args.validPriceStartDate = null;
                    args.result = 'Failure';
                    args.errorMessage = 'Invalid Start Date';

                    if (args.price || args.price === 0) {
                        // console.log("Start Date Failure");
                        setIsError({ ...isError, isPriceEmpty: true, notUploded: true });
                        priceflag = true;
                    }
                }

                if ((args.priceEndDate) !== "") {
                    //console.log("Enddate check");
                    args.priceEndDate = moment(args.priceEndDate).format('DD/MM/YYYY');
                    args.validPriceEndDate = args.priceEndDate;
                    args.priceEndDateNullValidated = true;
                }
                else {
                    args.priceEndDate = null;
                    args.validPriceEndDate = null;
                    args.priceEndDateNullValidated = false;
                }


                if ((args.priceEndDate && (!args.price && args.price !== 0))) {
                    args.priceStartDate = null;
                    args.validPriceStartDate = null;
                    args.priceEndDate = null;
                    args.validPriceEndDate = null;
                    args.price = null;
                }
                if ((args.priceStartDate && (!args.price && args.price !==0))) {
                    args.priceStartDate = null;
                    args.validPriceStartDate = null;
                    args.priceEndDate = null;
                    args.validPriceEndDate = null;
                    args.price = null;
                }


                if (!args.alreadyExists) {
                    args.alreadyExists = false;
                }


                if (args.charge || args.charge === 0) {
                    args.chargeNullValidated = true;
                    args.validCharge = args.charge.toFixed(4);

                    if ((args.chargeStartDate) !== "") {
                        args.chargeStartDate = moment(args.chargeStartDate).format('DD/MM/YYYY');
                        args.chargeStartDateNullValidated = true;
                        args.validChargeStartDate = args.chargeStartDate;

                        if ((args.chargeEndDate) !== "") {
                            args.chargeEndDate = moment(args.chargeEndDate).format('DD/MM/YYYY');
                            args.validChargeEndDate = args.chargeEndDate;
                            args.chargeEndDateNullValidated = true;
                        }
                        else {
                            args.chargeEndDate = null;
                            args.validChargeEndDate = null;
                            args.chargeEndDateNullValidated = false;
                        }
                    }
                    else {
                        args.chargeStartDate = null;
                        args.validChargeStartDate = null;
                        args.chargeStartDateNullValidated = false;

                        args.chargeEndDate = null;
                        args.validChargeEndDate = null;
                        args.chargeEndDateNullValidated = false;

                        if (args.charge || args.charge === 0) {
                            // console.log("Charge Start Date Failure");
                            setIsError({ ...isError, isChargeEmpty: true, notUploded: true });
                            chargeflag = true;
                        }
                    }
                }
                else {
                    args.charge = null;
                    args.chargeNullValidated = false;
                    args.validCharge = null;
                    args.chargeStartDate = null;
                    args.chargeEndDate = null;
                    args.chargeStartDateNullValidated = false;
                    args.chargeEndDateNullValidated = false;
                    args.validChargeStartDate = null;
                    args.validChargeEndDate = null;
                }



                if (args.productValueLevyScheme) {

                    args.validProductValueLevyScheme = args.productValueLevyScheme;
                    args.productValueLevySchemeNullValidated = true;
                }
                else {
                    args.productValueLevyScheme = null;
                    args.validProductValueLevyScheme = null;
                    args.productValueLevySchemeNullValidated = false;

                }
                if (args.distributionChargeLevyScheme) {

                    args.validDistributionChargeLevyScheme = args.distributionChargeLevyScheme;
                    args.distributionChargeLevySchemeNullValidated = true;
                }
                else {
                    args.distributionChargeLevyScheme = null;
                    args.validDistributionChargeLevyScheme = null;
                    args.distributionChargeLevySchemeNullValidated = false;

                }
                if (!args.unitOfMeasureId) {
                    args.unitOfMeasureId = null;
                    args.validUnitOfMeasure = null;
                    args.unitOfMeasureNullValidated = false;
                }
                if (!args.unitOfMeasureName) {
                    args.unitOfMeasureName = null;
                    args.validUnitOfMeasure = null;
                    args.unitOfMeasureNullValidated = false;
                }

                // console.table(args);

            });
            return defaultValues;
        }
        catch (error) {
            console.log(error);
            return 0;
        }
    }


    const fetchMyAPI = async () => {
        try {
            // console.log(promoflag);
            setState({ ...state, pageLoader: true })
            const fileUploadDetails = {
                //"fileName": state.fileName,
                "supplier": {
                    "id": supplier_id,
                    "name": supplier_name
                },
                "distributionChannel": {
                    "id": dc_id,
                    "name": dc_name
                },
                "promo": promoflag,
                "pdcUploadProcessDTOS": rows
            }
            const { data } = await axios_pdc_file_upload.post(`pdcs/upload`, fileUploadDetails); // v2/products/upload
            if (data) {
                setState({
                    ...state,
                    pageLoader: false,
                    fileNameId: data.resultMap.result.id,
                    fileName: data.resultMap.result.fileName,
                    triggerBtnsFlag: true,
                    fuSelectedName: JSON.stringify({
                        id: data.resultMap.result.id,
                        fileName: data.resultMap.result.fileName
                    }),
                    freshUpload: true
                });

                // console.log({ data });

                // if (data.success === true) {
                //     console.log(supplier_id);
                // }

                // setUploadDropdown([...uploadDropdown, {
                //     id: data.resultMap.result.id,
                //     fileName: data.resultMap.result.fileName
                // }])

                const productActionColumns = [
                    {
                        headerName: "Result",
                        field: "result",
                        width: 200
                    },
                    {
                        headerName: "Error Message",
                        field: "errorMessage",
                        minWidth: 300
                    },
                    ...columns,
                    // {
                    //     headerName: "Active",
                    //     field: "newProduct",
                    //     renderCell: (params) => (
                    //         <Checkbox
                    //             checked={params.value === true}
                    //             icon={<CircleIcon />}
                    //             checkedIcon={<GridCheckCircleIcon />}
                    //             color="chk_rounded"
                    //             size='small'
                    //             style={{ pointerEvents: 'none' }}
                    //         />
                    //     ),
                    //     align: 'center'
                    // },
                    {
                        headerName: "Action",
                        field: "action",
                        sortable: false,
                        width: 90,
                        renderCell: (params) => {
                            return <IconMenuLink params={params} onEdit={editRow} onDelete={deleteRow} />;
                        }
                    }];
                // new array need to update
                setColumns(productActionColumns);
                //setRows(data.resultMap.result);
                setIsError({
                    ...isError,
                    isOcBcDuplicate: false,
                    isOcBcEmpty: false,
                    isSpcEmpty: false,
                    isChargeEmpty: false,
                    isPriceEmpty: false,
                    notUploded: true
                });
                priceflag = false;
                chargeflag = false;
                setSnackBar({ ...snackBar, show: true, text: "File Uploaded...!!!", type: "success" });
                setTimeout(() => {
                    setRows([])
                }, 100);
            }

        } catch (error) {
            // const { response: { data, status } } = error;
            // console.log(error, data, status );
            // if (!data) {
            //     setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
            // } else {
            //     setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
            // }
            const { response: { data, status }, message } = error;
            // console.log(message, status, data); message ? message : 
            if (status === 500) {
                setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
            }
            else if (status === 504) {
                setSnackBar({ ...snackBar, show: true, text: "File upload is in progess and should finish within 5 minutes.", type: "error", });
            } else {
                // setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", });
                setSnackBar({ ...snackBar, show: true, text: "The ECS services are down at the moment.", type: "error", });
            }
            setState({ ...state, pageLoader: false })
        }
    }


    useEffect(() => {
        fetchDropDownUpload();
    }, [])

    useEffect(() => {
        fetchDropDownUpload();
    }, [state.freshUpload])

    const fetchDropDownUpload = async () => {
        try {
            const { data } = await axios_pdc_file_upload.get(`pdcs/upload/drop-down`); // v2/products/upload/drop-down
            if (data) {
                setUploadDropdown(data);
                //console.log(data, ' dropdown');
                setUploadBtnFlag(false);
            }

        } catch (error) {
            const { response: { data, status }, message } = error;
            // console.log(message, status, data); message ? message : 
            if (status === 500) {
                setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
                if (data.message && data.message === 'No Product Distribution Import Files') {
                    setUploadBtnFlag(false);
                }
            }
            else {
                // setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
                setSnackBar({ ...snackBar, show: true, text: "The ECS services are down at the moment.", type: "error", });
            }
        }
    }

    useEffect(() => {
        if (state.freshUpload === false) {
            fetchGetFileUpload();
        }
    }, [state.fuSelectedName]);

    useEffect(() => {
        if (uploadBtnFlag === false) {
            //fetchGetFileUpload();
            // console.log("Existing File Dropdown is Ready");

        }
        else {
            // console.log("Existing File Dropdown is being Fetched!");
        }
    }, [uploadBtnFlag])

    useEffect(() => {
        setState({ ...state, freshUpload: false })
    }, [state.freshUpload]);

    const fetchGetFileUpload = async () => {
        const selectedFile = state.fuSelectedName ? JSON.parse(state.fuSelectedName) : '';

        if (selectedFile !== '') {
            try {
                setState({ ...state, pageLoader: true })
                const { data } = await axios_pdc_file_upload.get(`pdcs/upload/${selectedFile.id ? selectedFile.id : '-1'}`);
                //v2/products/upload
                setColumns([]);
                setRows([]);
                if (data) {
                    // new array need to update
                    setState({ ...state, pageLoader: false })
                    setColumns(allColumns)
                    setTimeout(() => {
                        // console.log(data)
                        setRows(data)
                    }, 300);

                }

            } catch (error) {
                // const { response: { data, status } } = error;
                // if (!data) {
                //     setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                // } else {
                //     setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                // }
                const { response: { data, status }, message } = error;
                // console.log(message, status, data); message ? message : 
                if (status === 500) {
                    setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
                } else {
                    // setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
                    setSnackBar({ ...snackBar, show: true, text: "The ECS services are down at the moment.", type: "error", });
                }
                setState({ ...state, pageLoader: false });
                setTimeout(() => {
                    setRows([])
                }, 100);
            }
        }

    }

    const editRow = (row) => {
        //    console.log(row, ' edit');
        setHandleEditRowModal(true)
        dispatch(editPdcUpload(row))
    }

    const deleteRow = (row) => {

        dispatch(singleDeleteRow(row))
        dispatch(singleDeleteYes({
            key: 'startSingleDelete',
            value: true
        }))
        dispatch(warningModal({
            yesHide: !handleWarning
        }))
    }

    useEffect(() => {
        if (fileUpload.confirmSingleDelete) {
            deleteSingleRow()
        }
    }, [fileUpload.confirmSingleDelete])

    const deleteSingleRow = async () => {
        if (fileUpload.singleDeleteRow !== null) {
            try {
                setState({ ...state, pageLoader: true })
                const { data } = await axios_pdc_file_upload.delete(`pdcs/importedPdc/${fileUpload.singleDeleteRow.id}`);
                //setColumns([])/
                if (data) {
                    setState({ ...state, pageLoader: false })
                    const filteredRows = rows.filter(product => {
                        if (product.id !== fileUpload.singleDeleteRow.id) {
                            return product;
                        }
                    })
                    setRows(filteredRows)
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
                    singleDeleteRowDispatch()
                }

            } catch (error) {
                // const { response: { data, status } } = error;
                // if (!data) {
                //     setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                // } else {
                //     setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                // }
                const { response: { data, status }, message } = error;
                // console.log(message, status, data); message ? message : 
                if (status === 500) {
                    setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
                } else {
                    // setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
                    setSnackBar({ ...snackBar, show: true, text: "The ECS services are down at the moment.", type: "error", });
                }
                singleDeleteRowDispatch()
                setState({ ...state, pageLoader: false })
            }
        }

    }

    const handleEditRow = (event) => {
        setHandleEditRowModal(event)
    };

    //For File Upload Modal

    const uploadFileModal = () => {
        dispatch(handleFileUploadModal({
            showFileUpload: !showFileUploadModal
        }));
        priceflag = false;
        chargeflag = false;
        promoflag = false;
    }

    const hideNewFileUploadModal = (event) => {
        //setState({ ...state, openNewFileUploadModal: false })
        //handlEupload(event);
        // console.log("Avyo", event.supplier_id, event.supplier_name, event);
        // console.log(event.promoflag);
        setState({ ...state, supplier_id: event.supplier_id, supplier_name: event.supplier_name, promo: event.promoflag });

        //supplier_id = event['supplier_id'];
        //supplier_name = event.supplier_name;

        ({ supplier_id, supplier_name, promoflag } = event);

        dc_id = event.distchan_id;
        dc_name = event.distchan_name;

        setRows([]);
        var input = event.rawfile.target;
        var reader = new FileReader();

        reader.onload = function () {

            var fileData = reader.result;
            var wb = read(fileData, { type: 'binary', cellDates: true });
            const data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { header: 1, defval: "" });
            let finalRows = data.slice(1).map((r) => r.reduce((acc, x, i) => {
                acc[data[0][i]] = x;
                //console.log(data[0][i], acc[data[0][i]], x);
                return { ...acc, id: Math.floor(100000000 + Math.random() * 900000000) }; // Math.random().toString(16).slice(2) 
            }, {}));
            //console.log("Final Rows: ",finalRows);
            const defaultValues = insertDefaultValues(finalRows);
            if (defaultValues !== 0) {
                setIsError({ ...isError, isOcBcDuplicate: hasDuplicate(defaultValues, "outerCaseBarcode"), isOcBcEmpty: isEmpty(defaultValues, "outerCaseBarcode"), isSpcEmpty: isEmpty(defaultValues, "supplierProductCode"), notUploded: defaultValues.length === 0 ? true : isError.isPriceEmpty === true ? true : isError.isChargeEmpty === true ? true : false })
                setRows(defaultValues);
            }
            else {
                // console.log("Wrong File");
                dispatch(pdcwrongFile({
                    key: 'wrongFileUpload',
                    value: true
                }));
                dispatch(warningModal({
                    yesHide: true
                }));
            }

        };

        reader.readAsBinaryString(input.files[0]);
        const fname = input.files[0].name.split('.');
        const fExtension = fname.pop()
        setState({ ...state, fileName: fname.join() + "_" + Date.parse(new Date) + '.' + fExtension })
        setColumns(state.duplicateColumns);

        // console.log("File: ", input.files[0].name);

        dispatch(handleFileUploadModal({
            showFileUpload: !showFileUploadModal
        }))
    }

    // const fileUploadFileSelect = (row) => {
    //     console.log(row, ' upload')
    //     setHandleUploadFileModal(true)
    //     //dispatch(editProductUpload(row))
    // }

    // const handleFileUpload = (event) => {
    //     setHandleUploadFileModal(event)
    // }

    useEffect(() => {
        if (fileUpload.confirmFileDelete) {
            deleteUploadedData()
        }
    }, [fileUpload.confirmFileDelete])

    const abandonDeleteFile = async () => {
        if (state.fuSelectedName !== "") {
            dispatch(fileDeleteYes({
                key: 'startFileDelete',
                value: true
            }))
            dispatch(warningModal({
                yesHide: !handleWarning
            }))
        } else {
            setSnackBar({ ...snackBar, show: true, text: "Select from existing uploads dropdown or Upload a new file!", type: "error" })
        }
    }

    const deleteUploadedData = async () => {
        const selectedFile = state.fuSelectedName ? JSON.parse(state.fuSelectedName) : '';
        try {
            setState({ ...state, pageLoader: true })
            const { data } = await axios_pdc_file_upload.delete(`pdcs/abandonUpload/${selectedFile.id}`); // v2/products/abandonUpload
            //setColumns([])
            if (data) {
                setState({ ...state, pageLoader: false, triggerBtnsFlag: true })
                const filteredRows = uploadDropdown.filter(product => {
                    if (product.id !== selectedFile.id) {
                        return product;
                    }
                })
                setUploadDropdown(filteredRows)
                setColumns(state.duplicateColumns)
                setTimeout(() => {
                    setRows([])
                }, 100);
                setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
                FileDeleteDispatch()
            }

        } catch (error) {
            // const { response: { data, status } } = error;
            // if (!data) {
            //     setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
            // } else {
            //     setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
            // }
            const { response: { data, status }, message } = error;
            // console.log(message, status, data); message ? message : 
            if (status === 500) {
                setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
            } else {
                // setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
                setSnackBar({ ...snackBar, show: true, text: "The ECS services are down at the moment.", type: "error", });
            }
            FileDeleteDispatch()
            setState({ ...state, pageLoader: false })
        }
    }

    const singleDeleteRowDispatch = () => {
        dispatch(singleDeleteYes({
            key: 'startSingleDelete',
            value: false
        }))
        dispatch(singleDeleteYes({
            key: 'confirmSingleDelete',
            value: false
        }))
    }
    const FileDeleteDispatch = () => {
        dispatch(fileDeleteYes({
            key: 'startFileDelete',
            value: false
        }))
        dispatch(fileDeleteYes({
            key: 'confirmFileDelete',
            value: false
        }))
    }
    const handleChange = (event) => {
        const { value, name } = event.target;
        event.preventDefault();
        setState({ ...state, [name]: value, triggerBtnsFlag: false });
        //setIsError({ ...isError, isOcBcDuplicate: false, isOcBcEmpty: false, isSpcEmpty: false, notUploded: true })
        var obj = JSON.parse(value);
        // current_selection = uploadDropdown.filter(function (item) {
        //     return item.id === value;
        // });
        //existing_select = uploadDropdown.filter()
        current_selection = JSON.stringify(uploadDropdown.find(function (x) { return x.id === obj.id; }));
        //console.log(uploadDropdown, obj.id, current_selection);
        // console.log("Handle Change:", isError);
        if (isError.isOcBcDuplicate || isError.isOcBcEmpty || isError.isSpcEmpty || isError.isPriceEmpty || isError.isChargeEmpty || priceflag || chargeflag) {
            setIsError({
                ...isError,
                isOcBcDuplicate: false,
                isOcBcEmpty: false,
                isSpcEmpty: false,
                isChargeEmpty: false,
                isPriceEmpty: false,
                notUploded: true
            });
            priceflag = false;
            chargeflag = false;
        }


    }
    const reValidateFile = async () => {


        //let currentSelection = JSON.parse(current_selection);




        //console.log(rows, state.fuSelectedName, currentSelection, predata);

        if (state.fuSelectedName !== "") {

            let obj = JSON.parse(state.fuSelectedName);
            let currentSelection = JSON.stringify(uploadDropdown.find(function (x) { return x.id === obj.id; }));
            const predata = JSON.parse(currentSelection);

            try {
                const fileUploadDetails = {
                    "id": predata.id,
                    "version": null,
                    "supplier": {
                        "id": predata.supplier.id,
                        "name": predata.supplier.name
                    },
                    "distributionChannel": {
                        "id": predata.distributionChannel.id,
                        "name": predata.distributionChannel.name
                    },
                    "promo": predata.promo,
                    "pdcUploadProcessDTOS": null
                    // "pdcUploadProcessDTOS": rows
                }

                setState({ ...state, pageLoader: true });

                const { data } = await axios_pdc_file_upload.post(`pdcs/validate`, fileUploadDetails); // v2/products/validate

                if (data) {
                    setState({ ...state, pageLoader: false });
                    //setRows(data.resultMap.result.productUploadList);

                    setSnackBar({ ...snackBar, show: true, text: "File Revalidated!", type: "success" });

                    if (data.resultMap.result != "") {
                        console.log("Data Response");
                        setRows(data.resultMap.result);
                        if (data.success === false) {
                            setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                        }

                    }
                    else {
                        console.warn("Empty Response");
                    }
                }

            }
            catch (error) {
                // console.log(error);
                // const { response: { data, status } } = error;
                // console.log(data, status );
                // if (!data) {
                //     setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                // } else {
                //     setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                // }
                const { response: { data, status }, message } = error;
                // console.log(message, status, data); message ? message : 
                if (status === 500) {
                    setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
                } else {
                    // setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
                    setSnackBar({ ...snackBar, show: true, text: "The ECS services are down at the moment.", type: "error", });
                }
                setState({ ...state, pageLoader: false })
            }

        }
        else {
            setSnackBar({ ...snackBar, show: true, text: "Select from existing uploads dropdown or Upload a new file!", type: "error" })
        }


        // if (state.fuSelectedName !== "") {
        //     const selectedFile = state.fuSelectedName ? JSON.parse(state.fuSelectedName) : '';
        //     try {
        //         setState({ ...state, pageLoader: true })
        //         const fileUploadDetails = {
        //             "id": selectedFile.id,
        //             "fileName": selectedFile.fileName
        //         }
        //         const { data } = await axios_pdc_file_upload.post(`pdcs/validate`, fileUploadDetails); // v2/products/validate
        //         if (data) {
        //             setState({ ...state, pageLoader: false })
        //             // new array need to update
        //             setRows(data.resultMap.result.productUploadList)
        //             setSnackBar({ ...snackBar, show: true, text: "File Revalidated...!!!", type: "success" })
        //         }

        //     } catch (error) {
        //         const { response: { data, status } } = error;
        //         if (!data) {
        //             setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
        //         } else {
        //             setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
        //         }
        //         setState({ ...state, pageLoader: false })
        //     }
        // } else {
        //     setSnackBar({ ...snackBar, show: true, text: "Select from existing or Upload new file...!!!", type: "error" })
        // }

    }


    const reCheckFile = async () => {


        //let currentSelection = JSON.parse(current_selection);




        //console.log(rows, state.fuSelectedName, currentSelection, predata);

        if (state.fuSelectedName !== "") {

            let obj = JSON.parse(state.fuSelectedName);
            let currentSelection = JSON.stringify(uploadDropdown.find(function (x) { return x.id === obj.id; }));
            const predata = JSON.parse(currentSelection);

            try {
                const fileUploadDetails = {
                    "id": predata.id,
                    "version": null,
                    "supplier": {
                        "id": predata.supplier.id,
                        "name": predata.supplier.name
                    },
                    "distributionChannel": {
                        "id": predata.distributionChannel.id,
                        "name": predata.distributionChannel.name
                    },
                    "promo": predata.promo,
                    "pdcUploadProcessDTOS": rows
                }

                setState({ ...state, pageLoader: true });

                const { data } = await axios_pdc_file_upload.put(`pdcs/importedFile/${predata.id}`, fileUploadDetails); // v2/products/validate

                if (data) {
                    setState({ ...state, pageLoader: false });
                    //setRows(data.resultMap.result.productUploadList);

                    console.log(data);
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" });

                    // reValidateFile();                    

                    if (data.resultMap.result !== "") {
                        console.log("Data Response");
                        setRows(data.resultMap.result);
                    }
                    else {
                        console.warn("Empty Response");
                    }


                }


            }
            catch (error) {
                // console.log(error);
                // const { response: { data, status } } = error;
                // if (!data) {
                //     setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                // } else {
                //     setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                // }
                const { response: { data, status }, message } = error;
                // console.log(message, status, data); message ? message : 
                if (status === 500) {
                    setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
                } else {
                    // setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
                    setSnackBar({ ...snackBar, show: true, text: "The ECS services are down at the moment.", type: "error", });
                }
                setState({ ...state, pageLoader: false })
            }

        }
        else {
            setSnackBar({ ...snackBar, show: true, text: "Select from existing uploads dropdown or Upload a new file!", type: "error" })
        }


    }


    const productUpdated = (data) => {
        setSnackBar({ ...snackBar, show: true, text: data.text, type: data.type });
        reValidateFile();
        //reCheckFile()

    }
    const executeFileUpload = async () => {
        const { id, fileName } = state.fuSelectedName !== "" ? JSON.parse(state.fuSelectedName) : "";
        console.log(id, fileName);
        if (id) {
            try {
                setState({ ...state, pageLoader: true, triggerBtnsFlag: true })
                const { data } = await axios_pdc_file_upload.post(`pdcs/executeUpload/${id}`); // /v2/products/executeUpload/
                console.log('execute ', data)
                if (data) {
                    setState({ ...state, pageLoader: false })
                    const filteredRows = uploadDropdown.filter(product => {
                        if (product.id !== id) {
                            return product;
                        }
                    })
                    setUploadDropdown(filteredRows)
                    setColumns(state.duplicateColumns)
                    setTimeout(() => {
                        setRows([])
                    }, 100);
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
                }

            } catch (error) {
                // const { response: { data, status } } = error;
                // console.log(error, data, status);
                // if (!data) {
                //     setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                // } else {
                //     setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                // }
                const { response: { data, status }, message } = error;
                // console.log(message, status, data); message ? message : 
                if (status === 500) {
                    setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
                } else {
                    // setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
                    setSnackBar({ ...snackBar, show: true, text: "The ECS services are down at the moment.", type: "error", });
                }
                setState({ ...state, pageLoader: false })
            }
        } else {
            setSnackBar({ ...snackBar, show: true, text: "Select from existing uploads dropdown or Upload a new file!", type: "error" })
        }
    }
    const onDownload = () => {
        const link = document.createElement("a");
        link.download = `sample-pdc-file-upload.xls`;
        link.href = downLoadTemplate;
        link.click();
    };
    const resetALL = (e) => {

        e.preventDefault();
        // console.log('Reset')

        setState({
            ...state,
            fileNameId: '',
            fileName: '',
            fuSelectedName: "",
            disableFileUpload: false,
            triggerBtnsFlag: true
        });

        setIsError({
            ...isError,
            isOcBcDuplicate: false,
            isOcBcEmpty: false,
            isSpcEmpty: false,
            isChargeEmpty: false,
            isPriceEmpty: false,
            notUploded: true
        });

        setRows([]);


        // setUploadFlag(true)
        // setTimeout(() => {
        //     setColumns([
        //         {
        //             headerName: "Name",
        //             field: "productDescription",
        //             flex: 1,
        //             minWidth: 200,
        //         },
        //         {
        //             headerName: "Brand Owner Product Id",
        //             field: "brandOwnerProductId",
        //             hide: true,
        //             minWidth: 180,
        //         },
        //         {
        //             headerName: "Outer Case Bar Code",
        //             field: "outerCaseBarCode",
        //             minWidth: 180
        //         },
        //         {
        //             headerName: "Specification",
        //             field: "specification",
        //             width: 180,
        //             hide: true
        //         },
        //         {
        //             headerName: "Case Quantity",
        //             field: "caseQuantity",
        //             width: 180,
        //             hide: true
        //         },
        //         {
        //             headerName: "EAN Code",
        //             field: "eanCode",
        //             width: 180,
        //             hide: true
        //         },
        //         {
        //             headerName: "RSP",
        //             field: "rsp",
        //             width: 180,
        //             hide: true
        //         },
        //         {
        //             headerName: "Vat Code",
        //             field: "vatCode",
        //             width: 180,
        //             hide: true
        //         },
        //         {
        //             headerName: "Export to TD",
        //             field: "exportToTd",
        //             width: 180,
        //             hide: true
        //         },
        //         {
        //             headerName: "UOM ID",
        //             field: "uomId",
        //             width: 180,
        //             hide: true
        //         },
        //         {
        //             headerName: "UOM Name",
        //             field: "uomName",
        //             width: 180,
        //             hide: true
        //         },
        //         {
        //             headerName: "Brand ID",
        //             field: "brandId",
        //             width: 180,
        //             hide: true
        //         },
        //         {
        //             headerName: "Brand name",
        //             field: "brandName",
        //             width: 180
        //         },
        //         {
        //             headerName: "Cat ID",
        //             field: "catId",
        //             width: 180,
        //             hide: true
        //         },
        //         {
        //             headerName: "Category Name",
        //             field: "catName",
        //             width: 180
        //         },
        //         {
        //             headerName: "Price (£)",
        //             field: "casePrice",
        //             width: 180
        //         },
        //         {
        //             headerName: "Start Date",
        //             field: "startDate",
        //             width: 180,
        //             hide: true
        //         },
        //         {
        //             headerName: "End Date",
        //             field: "endDate",
        //             width: 180,
        //             hide: true
        //         },
        //         {
        //             headerName: "Member Tier",
        //             field: "memberTier",
        //             valueGetter: (params) => params.row.memberTier ? params.row.memberTier.name : "",
        //             align: 'center',
        //             minWidth: 150
        //         },
        //         {
        //             headerName: "Associated Levy",
        //             field: "lsBrandOwnerId",
        //             width: 180,
        //             hide: true
        //         },
        //         {
        //             headerName: "Fair Trade",
        //             field: "fairTrade",
        //             width: 180,
        //             hide: true
        //         },
        //         {
        //             headerName: "Organic",
        //             field: "organic",
        //             width: 180,
        //             hide: true
        //         },
        //         {
        //             headerName: "Discountable for BOPA",
        //             field: "discountableForBopa",
        //             width: 180,
        //             hide: true
        //         },
        //         {
        //             headerName: "Ethical",
        //             field: "ethical",
        //             width: 180,
        //             hide: true
        //         }
        //     ])
        // }, 100);

    }
    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="PDC File Upload" />
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -3 } }}>
                    <div className="importButtons">
                        <div className='table-actionable'>
                            <Button variant="contained" component="label" size='small' id="upload_button" onClick={(e) => uploadFileModal(e)} disabled={uploadBtnFlag}>
                                Upload
                                {/* <input hidden accept=".xls,.xlsx" multiple type="file" onChange={handlEupload} /> */}
                            </Button>
                            {
                                showFileUploadModal && (
                                    <UploadFileSelectModal
                                        //tabs={tabs}
                                        onShow={showFileUploadModal}
                                        onHide={(e, reason) => hideNewFileUploadModal(e, reason)}
                                        existingUploads={uploadDropdown}
                                        onUpdate={productUpdated}
                                    // onHide={(e, reason) => hideNewProductModal(e, reason)}
                                    />
                                    // <UploadFileSelectModal
                                    //     onShow={showFileUploadModal}
                                    //     onHide={(e, reason) => handleFileUpload(e, reason)}
                                    //     fileName={JSON.parse(state.fuSelectedName).fileName}
                                    //     fileNameId={JSON.parse(state.fuSelectedName).id}
                                    //     onUpdate={productUpdated}
                                    // />
                                )
                            }
                            {
                                showFileUploadWarning && (
                                    <WarningModal size="small_overlay" onShow={showFileUploadWarning} />
                                )
                            }
                        </div>
                        <div className="excelList">
                            <FormControl fullWidth sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: { xs: 'center', sm: 'end' } }}>
                                <FormLabel htmlFor="msType" sx={{ textAlign: 'left', paddingRight: '15px' }}>Existing uploads</FormLabel>
                                <Select
                                    size="small"
                                    id="fuSelectedName"
                                    value={state.fuSelectedName}
                                    aria-describedby="fuNameError"
                                    sx={{ width: '200px' }}
                                    onChange={(e) => handleChange(e)}
                                    name="fuSelectedName"
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 48 * 4.5 + 8
                                            },
                                        },
                                    }}
                                >
                                    {uploadDropdown.map((option) => (
                                        <MenuItem value={JSON.stringify({
                                            id: option.id,
                                            fileName: option.name
                                        })}
                                            key={option.id}
                                            sx={{ fontSize: '0.85rem' }}>{option.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <Button onClick={(e) => resetALL(e)}>Clear Table</Button>
                        </div>
                    </div>
                    <div className='table-actionable'>
                        <Button variant="contained" color="secondary" size='small' onClick={onDownload}>
                            Download Template
                        </Button>
                    </div>
                    <div className='table-actionable'>
                        <Button variant="contained" color="tertiary" size='small' onClick={reValidateFile} disabled={state.triggerBtnsFlag} >
                            Revalidate
                        </Button>
                    </div>
                    <div className='table-actionable'>
                        <Button variant="contained" color="success" size='small' onClick={executeFileUpload} disabled={state.triggerBtnsFlag}>
                            Execute
                        </Button>
                    </div>
                    <div className='table-actionable'>
                        <Button variant="contained" color="warning" size='small' onClick={abandonDeleteFile} disabled={state.triggerBtnsFlag}>
                            Abandon File
                        </Button>
                    </div>

                </Box>
                <br />
                <br />
                {
                    isError.isOcBcDuplicate && <Alert severity="error">Outercase Barcode has Duplicate values, please <Typography sx={{ display: 'inline-block', textTransform: 'uppercase', fontWeight: 'bold' }}>update</Typography> and <Typography sx={{ display: 'inline-block', textTransform: 'uppercase', fontWeight: 'bold' }}>upload</Typography> the file again!</Alert>
                }
                {
                    isError.isOcBcEmpty && (<><br /><Alert severity="error">Outercase Barcode has Empty values or your file has empty rows, please <Typography sx={{ display: 'inline-block', textTransform: 'uppercase', fontWeight: 'bold' }}>update</Typography> and <Typography sx={{ display: 'inline-block', textTransform: 'uppercase', fontWeight: 'bold' }}>upload</Typography> the file again!</Alert></>)
                }
                {
                    isError.isSpcEmpty && (<><br /><Alert severity="error">Supplier Product Code has Empty values or your file has empty rows, please <Typography sx={{ display: 'inline-block', textTransform: 'uppercase', fontWeight: 'bold' }}>update</Typography> and <Typography sx={{ display: 'inline-block', textTransform: 'uppercase', fontWeight: 'bold' }}>upload</Typography> the file again!</Alert></>)
                }
                {
                    priceflag && (<><br /><Alert severity="error">Price - Start Date has Empty values, please <Typography sx={{ display: 'inline-block', textTransform: 'uppercase', fontWeight: 'bold' }}>update</Typography> and <Typography sx={{ display: 'inline-block', textTransform: 'uppercase', fontWeight: 'bold' }}>upload</Typography> the file again!</Alert></>)
                }
                {
                    chargeflag && (<><br /><Alert severity="error">Charge - Start Date has Empty values, please <Typography sx={{ display: 'inline-block', textTransform: 'uppercase', fontWeight: 'bold' }}>update</Typography> and <Typography sx={{ display: 'inline-block', textTransform: 'uppercase', fontWeight: 'bold' }}>upload</Typography> the file again!</Alert></>)
                }
                <br />
                <DataTableClientSide defaultGrid={state.defaultGrid} columns={columns ? columns : []} rows={rows ? rows : []}>

                </DataTableClientSide>
                {
                    handleEditRowModal && (
                        <PdcFileUploadModal
                            onShow={handleEditRowModal}
                            onHide={(e, reason) => handleEditRow(e, reason)}
                            fileName={JSON.parse(state.fuSelectedName).fileName}
                            fileNameId={JSON.parse(state.fuSelectedName).id}
                            onUpdate={productUpdated}
                            currentUploadSelection={current_selection}
                        />
                    )
                }
            </Container>
            {
                handleWarning && (
                    <WarningModal
                        size="small_overlay"
                        deleteRowUpload={state.deleteRowUpload}
                        deleteAllProductUpload={state.deleteAllProductUpload}
                        onShow={handleWarning} />
                )
            }
            {snackBar.show && <SnackBarDialog
                onShow={snackBar.show}
                text={snackBar.text}
                type={snackBar.type}
                onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
            />}
        </>
    );
}

export default PdcFileUpload;
