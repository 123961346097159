import { Checkbox, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl, RadioGroup, Radio, Autocomplete } from '@mui/material'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import axiosDiscountSchemes from '../../../../services/utility/axios-discount-schemes';
import axios_product from "../../../../services/utility/axios";
import axiosBrand from '../../../../services/utility/axios-brand';
import PageLoader from '../../../../components/shared/PageLoader';
import { setDiscountSchemeState, getIsError } from '../../../../store/slices/discountScheme';
import { handleModalState, setNewPriceModal } from '../../../../store/slices/modals';
import CommonPopUp from '../../../products/addEditPdc/pdc-tabs/CommonPopUp';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import { setCommonInput } from "../../../../store/slices/commonProduct";

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

function AddEditBrand({ onShow, size = '', onHide, onSubmitDate }) {
    const [state, setState] = useState({
        brandListModal: false,
        pageLoader: false,
        BrandDetail: null,
        defaultmode: false,
        formValid: true
    })

    const [included, setIncluded] = useState(true);

    const { discountScheme_container, searchProductModal } = useSelector((state) => {
        return {
            discountScheme_container: state.discountSchemes,
            searchProductModal: state.commonProducts,
        }
    });

    let brandData = null;
    if(discountScheme_container.hasEditBrand !== null) {
        brandData = {"id": discountScheme_container.hasEditBrand.brandId, "name": discountScheme_container.hasEditBrand.brandName}
    }

    const dispatch = useDispatch();

    const [brand, setbrand] = useState({
        brand_options: [],
        //BrandDetail: discountScheme_container.hasEditBrand ? discountScheme_container.hasEditBrand.brand : null
        brand_selected: discountScheme_container.hasEditBrand ? brandData: null
    })
    const [isError, setIsError] = useState({
        brand_selected: false
    })

    const handleChange = (event) => {
        if(discountScheme_container.hasEditBrand) {
            setState({ ...state, defaultmode: true })
        }
        setIncluded(event.target.checked);
    };

    useEffect(() => {
        const fetchMyAPI = async () => {
            if (brand.brand_options.length === 0) {
                axiosBrand.get('brands/look-up')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data.resultMap.result.filter((value, index, self) => self.findIndex((m) => m.name === value.name) === index);
                            setbrand({ ...brand, brand_options: unique })
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
        }
        fetchMyAPI();
    }, [])

    const [open, setOpen] = useState(onShow);

    const handleClose = () => {
        //setState({ ...state, defaultmode: false })
        dispatch(handleModalState({
            key: 'addEditBrandModal',
            value: false
        }))
        dispatch(setDiscountSchemeState({
            key: 'hasEditBrand',
            value: null
        }))
        dispatch(getIsError({ key: 'brand_selected', value: false }))
    };

    const handleSelf = () => {
        handleClose()
    }

    useEffect(() => {
        if (brand.brand_selected === null || brand.brand_selected === "") {
            setState({ ...state, formValid: true, defaultmode: false})
        } else {
            setState({ ...state, formValid: false, defaultmode: true})
        }
    }, [brand.brand_selected])

    useEffect(() => {
        const {
            brand_selected
        } = discountScheme_container.isError
        if (!brand_selected) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true })
        }
    }, [discountScheme_container.isError])

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (brand.brand_selected === null && isError.brand_selected === false) {
            setIsError({ ...isError, brand_selected: true })
        } else {
            addPayDate()
        }
    }
    const addPayDate = async () => {
        let payload = null;
        if ((discountScheme_container.hasEditData !== null || discountScheme_container.editTabData !== null) && discountScheme_container.hasEditBrand === null) {
            payload = {
                "brandId": brand.brand_selected ? brand.brand_selected.id : "",
                included,
                "brandName": brand.brand_selected ? brand.brand_selected.name : "",
            }
        } else if (discountScheme_container.hasEditBrand !== null) {
            payload = {
                "id": discountScheme_container.hasEditBrand.id,
                "brandId": brand.brand_selected ? brand.brand_selected.id : discountScheme_container.hasEditBrand.brandId,
                included,
                "brandName": brand.brand_selected ? brand.brand_selected.name : discountScheme_container.hasEditBrand.brandName,
            }
        }

        try {
            setState({ ...state, pageLoader: true })
            const { data } =  ((discountScheme_container.hasEditData !== null || discountScheme_container.editTabData !== null) && discountScheme_container.hasEditBrand === null) ? await axiosDiscountSchemes.post(`/discountSchemes/${discountScheme_container.hasEditData.id || discountScheme_container.editTabData.id}/brands`, payload) : await axiosDiscountSchemes.put(`/discountSchemes/${discountScheme_container.hasEditData.id || discountScheme_container.editTabData.id}/brands/${discountScheme_container.hasEditBrand.id}`, payload)

            if (data) {
                onSubmitDate({ text: data.message, type: "success" })
                setState({ ...state, pageLoader: false })
                handleClose()
            }

        } catch (error) {
            const { response: { data, status } } = error;
            if (!data) {
                onSubmitDate({ text: error.message, type: "error" })
            } else {
                onSubmitDate({ text: data.message, type: "error" })
            }
            setState({ ...state, pageLoader: false })
            handleClose()
        }
    }

    useEffect(() => {
        if (discountScheme_container.hasEditBrand !== null) {
            setbrand({ ...brand, brand_selected: discountScheme_container.hasEditBrand.brand })
            setIncluded(discountScheme_container.hasEditBrand.included)
        }
    }, [])


    // const hideSupplierbrandModal = (params) => {
    //     setState({ ...state, brandListModal: params === false ? params : true })
    // }
    // const CommonPopUpModal = () => {
    //     setState({...state, brandListModal: true})
    // }
    // const getBrandDetail = (brand) => {  
    //     if (brand !== '') {
    //         setState({...state, defaultmode: true, BrandDetail: brand, brandListModal: false})
    //     } 
    // }

    return (
        <>
            <Modal
                open={open}
                aria-labelledby="add-edit-price-title"
                aria-describedby="add-edit-price-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Box component="div" className="overlay" id="addEditModal">
                        {state.pageLoader && <PageLoader />}
                        <div className={`overlay-box ${size ? size : ''}`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-header text-center"> Brand
                                        <CancelIcon className="closeBtn" onClick={handleClose} />
                                    </div>
                                    <div className="overlay-content">
                                        <Grid item xs={12} md={12}>
                                            {/* <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            <FormLabel htmlFor="type" className="fontSemiBold mandatory">Brand</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Grid container alignItems="center">
                                                                <Grid item xs={12} md={8}>
                                                                    <Item>
                                                                        <Box component="div" className=''>
                                                                            <FormControl size={'small'} fullWidth>
                                                                                <TextField
                                                                                    inputProps={{ maxLength: 100 }}
                                                                                    size="small"
                                                                                    id="discountScheme_brand_name"
                                                                                    value={(searchProductModal.query ? searchProductModal.query.name : '') || (discountScheme_container.hasEditBrand ? discountScheme_container.hasEditBrand.brandName : '')}
                                                                                    aria-describedby="discountScheme_brand_name_error"
                                                                                    error={discountScheme_container.isError.discountScheme_brand_name_error}
                                                                                    name="discountScheme_brand_name"
                                                                                    disabled={true}
                                                                                />
                                                                            </FormControl>
                                                                        </Box>
                                                                    </Item>
                                                                </Grid>
                                                                <Grid item xs={12} md={4}>
                                                                    <Item>
                                                                        <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={(e) => CommonPopUpModal(e)} startIcon={<EditIcon />}></Button>
                                                                    </Item>
                                                                </Grid>
                                                            </Grid>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item> */}
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            <FormLabel htmlFor="type" className="fontSemiBold mandatory">Brand</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Autocomplete
                                                                defaultValue={brand.brand_selected ? brand.brand_selected : null}
                                                                id="discountScheme_brand_name_search"
                                                                options={brand.brand_options}
                                                                getOptionLabel={(option) => option.name || ""}
                                                                componentsProps={{
                                                                    paper: {
                                                                        sx: {
                                                                            fontSize: '0.85rem'
                                                                        }
                                                                    }
                                                                }}
                                                                onChange={(event, newValue) => {
                                                                    (isError['brand_selected'] === null || isError['brand_selected'] === "") ? setIsError({ ...isError, brand_selected: true }) : setIsError({ ...isError, brand_selected: false })
                                                                    //if (event.target.value !== "" || event.target.value !== null) {
                                                                    if (event.target.value !== undefined) {    
                                                                        setState({ ...state, defaultmode: true })
                                                                        setbrand({ ...brand, brand_selected: newValue })
                                                                        dispatch(getIsError({ key: 'brand_selected', value: false }))
                                                                    } else {
                                                                        dispatch(getIsError({ key: 'brand_selected', value: true }))
                                                                    }
                                                                }}
                                                                renderInput={(params) =>
                                                                    <FormControl size={'small'} fullWidth>
                                                                        <div className="input-group">
                                                                            <TextField {...params}
                                                                                size="small"
                                                                                id="discountScheme_brand_name"
                                                                                aria-describedby="distributionChannel-error"
                                                                                error={isError.brand_selected}
                                                                            />
                                                                            {discountScheme_container.isError.brand_selected && (<FormHelperText className='errorHelperTxt' id="distributionChannel-error">
                                                                                Please select Brand
                                                                            </FormHelperText>)}
                                                                        </div>
                                                                    </FormControl>
                                                                }
                                                            />
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            <FormLabel htmlFor="included" className="fontSemiBold">Include/Exclude</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <div className="input-group">
                                                                <Checkbox
                                                                    checked={included}
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                />
                                                            </div>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            &nbsp;
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Grid container direction="row" alignItems="center">
                                                                <Button 
                                                                    variant="contained" 
                                                                    type="submit" 
                                                                    sx={{ marginRight: '15px' }} 
                                                                    //disabled={((discountScheme_container.hasEditBrand && discountScheme_container.hasEditBrand.confirmed === true) ? true : false) || state.defaultmode === false}
                                                                    // eslint-disable-next-line max-len
                                                                    disabled={((discountScheme_container.hasEditData !== null || discountScheme_container.editTabData !== null) && discountScheme_container.hasEditBrand) ? discountScheme_container.isError.brand_selected : (state.formValid || state.defaultmode === false)}
                                                                >Save</Button>
                                                                <Button variant="outlined" onClick={handleSelf}>Cancel</Button>
                                                            </Grid>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* {
                            state.brandListModal && <CommonPopUp
                                onShow={state.brandListModal}
                                size="large_overlay"
                                brandDetails={{
                                    title: 'Brand Details'
                                }}
                                onHide={hideSupplierbrandModal}
                                memberaccount={getBrandDetail}
                            />
                        } */}
                    </Box>
                </form>

            </Modal>
        </>
    );
}

export default AddEditBrand;