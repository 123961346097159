import '../src/styles/styles.scss';
import DefaultLayout from './components/layout/Default';
import LoginLayout from './components/layout/Login';
import Routers from './router';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from './authConfig';
import { useEffect, useState, useRef, React } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router';

var logoutFlag = 0;

function App() {

  // const [state, setState] = useState('Active');
  // const [count, setCount] = useState(0);
  // const [remaining, setRemaining] = useState(0);
  // const user_name = accounts[0] && accounts[0].name;
  const [loggedIn, setLoggedIn] = useState(true);
  const { instance, accounts, inProgress } = useMsal();
  const navigate = useNavigate();
  

  const checkForInactivity = () => {
    // Get Expire Time from Local Storage

    const expireTime = localStorage.getItem("expireTime");    
    
    //If Expire Time is earlier than now, log out

    if (expireTime < Date.now()) {
      // console.log("System has been idle for more than 14 mins.", accounts.length, user_name, logoutFlag, localStorage.getItem("userLogin")); 
      if (logoutFlag === 0 && localStorage.getItem("userLogin") === "true") {
        // console.log("Logout");
        navigate('/');
        logoutFlag = 1;
        setLoggedIn(false);
        localStorage.setItem("userLogin", "false");               
        instance.logoutPopup({
          postLogoutRedirectUri: "/",
          mainWindowRedirectUri: "/" // redirects the top level app after logout
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    }
  }

  const updateExpireTime = () => {

    // Set Expire Time to 14 mins from now, for testing set to 5 mins
    const expireTime = Date.now() + 840000;

    //Set Expire Time in Local Storage
    localStorage.setItem("expireTime", expireTime);
  }

  // Use Effect to set Interval to check for inactivity

  useEffect(() => {

    // Check for inactivity every 5 seconds
    const interval = setInterval(() => {
        checkForInactivity();
    }, 5000);

    // Clear interval on unmount
    return () => clearInterval(interval);


  }, []);

  // Update Expire Time on any user activity

  useEffect(() => {

    // Set Initial Expire Time
    updateExpireTime();

    // Set Event Listeners

    window.addEventListener("click", updateExpireTime);
    window.addEventListener("keypress", updateExpireTime);
    window.addEventListener("scroll", updateExpireTime);
    window.addEventListener("mousemove", updateExpireTime);

    // Clean up
    return () => {
      window.removeEventListener("click", updateExpireTime);
      window.removeEventListener("keypress", updateExpireTime);
      window.removeEventListener("scroll", updateExpireTime);
      window.removeEventListener("mousemove", updateExpireTime);
    }

  }, []);

  // const onIdle = () => {
  //   setState('Idle');
  //   // console.log("Idle", name, accounts, accounts.length);
  //   if (accounts.length > 0) {
  //     navigate('/');
  //     setTimeout(() => {
  //       instance.logoutPopup({
  //         postLogoutRedirectUri: "/",
  //         mainWindowRedirectUri: "/" // redirects the top level app after logout
  //       });
  //     }, 1500);   
  //   }
  // }

  // const onActive = () => {
  //   setState('Active')
  //   // console.log("Active", name, accounts)
  // }

  // const onAction = () => {
  //   setCount(count + 1)
  // }

  // const { getRemainingTime } = useIdleTimer({
  //   onIdle,
  //   onActive,
  //   onAction,
  //   timeout: 840000,
  //   throttle: 500
  // })

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setRemaining(Math.ceil(getRemainingTime() / 1000))
  //   }, 500)

  //   return () => {
  //     clearInterval(interval)
  //   }
  // })

  return (
    <>
      <div className='wrapper'>
        <AuthenticatedTemplate>
          <DefaultLayout>
            <Routers />
          </DefaultLayout>
        </AuthenticatedTemplate>
      </div>
      <UnauthenticatedTemplate>
        <LoginLayout></LoginLayout>
      </UnauthenticatedTemplate>
    </>
  );
}

export default App;
