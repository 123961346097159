import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  closeWaringAddProduct: false,
  hideAddEditModal: false,
  hideAddEditBrand: false,
  hideAddEditPdcModal: false,
  eanCodeModal: false,
  hideAddEditUnitOfMeasures: false,
  hideAddEditSupplier: false,
  hideAddEditdiscountScheme: false,
  hideAddEditVat: false,
  hideAddEditDC: false,
  hideAddEditSma: false,
  hideAddEditCbs: false,
  brandDetailModal: false,
  handleBrandOwnerModal: false,
  supplierProductCodeModal: false,
  vatCodeMappingCodeModal: false,
  pdcPriceModal: false,
  hideViewMoreModal: false,
  editProductUploadModal: false,
  hideFileUploadModal: false,
  editPdcUploadModal: false,
  priceModal: false,
  invrepProductModal: false,
  hideAddEditPriceModal: false,
  hideInvoiceDetailsModal: false,
  addEditRDSchemeModal: false,
  addEditPayDateModal: false,
  addEditThresholdModal: false,
  addEditDCModal: false,
  addEditProgrammeModal: false,
  showAddEditDisputedInvoiceModal: false,
  showAddEditLevyBillingModal: false,
  showAddEdiRetroBillingModal: false,
  showAddEdiRetroFinalModal: false,
  showAddEdiRetroInterimModal: false,
  showAddEdiMemberInvoiceModal: false,
  addEditCategoriesModal: false,
  addEditMemberModal: false,
  addEditSupplierModal: false,
  AddEditProgrammesModal: false,
  AddEditProductModal: false,
  releaseSystemDispute: false,
  hideUserDisputeModal: false,
  handleEmailModal: false,
  handleUploadInvoice: false,
  deleteCategory: false
}
const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    warningModal: (state, action) => {
      const { yesHide } = action.payload;
      state.closeWaringAddProduct = yesHide
    },
    handleAddEditModal: (state, action) => {
      const { showAddEdit } = action.payload;
      state.hideAddEditModal = showAddEdit
    },
    handleAddEditPdcPriceModal: (state, action) => {
      const { showAddEditPrice } = action.payload;
      state.hideAddEditPdcModal = showAddEditPrice
    },
    setNewBrandModal: (state, action) => {
      const { showAddEdit } = action.payload;
      state.hideAddEditBrand = showAddEdit
    },
    setBrandOwnerModal: (state, action) => {
      state.handleBrandOwnerModal = action.payload;
    },
    setEanCodeModal: (state, action) => {
      state.eanCodeModal = action.payload
    },
    setSupplierProductCodeModal: (state, action) => {
      state.supplierProductCodeModal = action.payload
    },
    setVatCodeMappingCodeModal: (state, action) => {
      state.vatCodeMappingCodeModal = action.payload
    },
    setPdcPriceModal: (state, action) => {
      state.pdcPriceModal = action.payload
    },
    handleAddEditUnitOfMeasures: (state, action) => {
      const { showAddEdit } = action.payload;
      state.hideAddEditUnitOfMeasures = showAddEdit
    },
    handleAddEditSupplier: (state, action) => {
      const { showAddEdit } = action.payload;
      state.hideAddEditSupplier = showAddEdit
    },
    handleAddEditdiscountScheme: (state, action) => {
      const { showAddEdit } = action.payload;
      state.hideAddEditdiscountScheme = showAddEdit
    },
    handleAddEditVat: (state, action) => {
      const { showAddEdit } = action.payload;
      state.hideAddEditVat = showAddEdit
    },
    handleAddEditDC: (state, action) => {
      const { showAddEdit } = action.payload;
      state.hideAddEditDC = showAddEdit
    },
    handleAddEditSma: (state, action) => {
      const { showAddEdit } = action.payload;
      state.hideAddEditSma = showAddEdit
    },
    handleAddEditCbs: (state, action) => {
      const { showAddEdit } = action.payload;
      state.hideAddEditCbs = showAddEdit
    },
    setBrandDetailModal: (state, action) => {
      state.brandDetailModal = action.payload
    },
    setNewPriceModal: (state, action) => {
      state.priceModal = action.payload;
    },
    handleViewMoreModal: (state, action) => {
      const { showViewMore } = action.payload;
      state.hideViewMoreModal = showViewMore
    },
    editProductUploadModal: (state, action) => {
      state.editProductUploadModal = action.payload;
    },
    handleFileUploadModal: (state, action) => {
      const { showFileUpload } = action.payload;
      state.hideFileUploadModal = showFileUpload;
    },
    editPdcUploadModal: (state, action) => {
      state.editPdcUploadModal = action.payload;
    },
    setInvRepProductModal: (state, action) => {
      state.invrepProductModal = action.payload;
    },
    handleAddEditPriceModal: (state, action) => {
      const { showAddEditPriceModal } = action.payload;
      state.hideAddEditPriceModal = showAddEditPriceModal
    },
    handleRDSchemeModal: (state, action) => {
      const { addEditRDSchemeModal } = action.payload;
      state.addEditRDSchemeModal = addEditRDSchemeModal
    },
    handleModalState: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value
    },
    handleAddEditDisputedInvoiceModal: (state, action) => {
      const { showAddEditDisputedInvoiceModal } = action.payload;
      state.showAddEditDisputedInvoiceModal = showAddEditDisputedInvoiceModal
    },
    handleAddEditLevyBillingModal: (state, action) => {
      const { showAddEditLevyBillingModal } = action.payload;
      state.showAddEditLevyBillingModal = showAddEditLevyBillingModal
    },
    handleAddEditRetroBillingModal: (state, action) => {
      const { showAddEdiRetroBillingModal } = action.payload;
      state.showAddEdiRetroBillingModal = showAddEdiRetroBillingModal
    },
    handleAddEditRetroFinalModal: (state, action) => {
      const { showAddEdiRetroFinalModal } = action.payload;
      state.showAddEdiRetroFinalModal = showAddEdiRetroFinalModal
    },
    handleAddEditRetroInterimModal: (state, action) => {
      const { showAddEdiRetroInterimModal } = action.payload;
      state.showAddEdiRetroInterimModal = showAddEdiRetroInterimModal
    },
    releaseSystemDisputeModal: (state, action) => {
      const { releasesystemdispute} = action.payload;
      state.releaseSystemDispute = releasesystemdispute
    },
    handleUserDisputeModal: (state, action) => {
      const { showAddEditUserDispute } = action.payload;
      state.hideUserDisputeModal = showAddEditUserDispute
    },
    deleteCategoryModal: (state, action) => {
      const {deleteCategory} = action.payload;
      state.deleteCategory = deleteCategory
    },
  }
})

export const { warningModal, handleAddEditModal, handleAddEditPdcPriceModal,setNewBrandModal, 
  setEanCodeModal, setSupplierProductCodeModal, setPdcPriceModal,handleAddEditUnitOfMeasures, 
  handleAddEditVat, handleAddEditDC, handleAddEditCbs, setBrandDetailModal, setBrandOwnerModal, setNewPriceModal, 
  handleViewMoreModal, editProductUploadModal, handleAddEditSupplier, setVatCodeMappingCodeModal, 
  handleAddEditSma, handleFileUploadModal, editPdcUploadModal, handleAddEditdiscountScheme, setInvRepProductModal, 
  handleAddEditPriceModal, handleRDSchemeModal, handleModalState, handleAddEditDisputedInvoiceModal, 
  handleAddEditLevyBillingModal, handleAddEditRetroBillingModal, handleAddEditRetroFinalModal, 
  handleAddEditRetroInterimModal, releaseSystemDisputeModal, handleUserDisputeModal, deleteCategoryModal } = modalSlice.actions

export default modalSlice.reducer