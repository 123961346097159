import { Checkbox, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl, RadioGroup, Radio, Typography } from '@mui/material'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import axios from '../../../../services/utility/axios-retro-discount-schemes';
import PageLoader from '../../../../components/shared/PageLoader';
import { setRetroState } from '../../../../store/slices/retrodiscountschemes';
import { handleModalState } from '../../../../store/slices/modals';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

function AddEditThreshold({ onShow, size = '', onHide, onSubmitDate }) {
    const [state, setState] = useState({
        endQuantity: '',
        startQuantity: '',
        points: '',
        formValid: true,
        defaultmode: false,
        pageLoader: false
    })
    const [isError, setIsError] = useState({
        points: false,
        startQuantity: false,
        endQuantity: false
    })
    const [type, setType] = useState('Interim');

    const { retro_fields } = useSelector((state) => {
        return {
            retro_fields: state.retrodiscountscheme
        }
    });
    const dispatch = useDispatch();

    const [open, setOpen] = useState(onShow);

    const handleClose = () => {
        //setState({ ...state, defaultmode: false })
        dispatch(handleModalState({
            key: 'addEditThresholdModal',
            value: false
        }))
        dispatch(setRetroState({
            key: 'hasEditThreshold',
            value: null
        }))
    };

    const handleSelf = () => {
        handleClose()
    }

    const handleInputChange = (event) => {
        const { value, name } = event.target;
        if (value === "") {
            setIsError({ ...isError, [name]: true })
        } else {
            setIsError({ ...isError, [name]: false })
        }

        setState({
            ...state,
            [name]: value.slice(0,16),
            defaultmode: true
        });
    }

    useEffect(() => {
        if (retro_fields.hasEditThreshold === null) {
            if (state.startQuantity !== "" && state.points !== "" && state.endQuantity !== "") {
                setState({ ...state, formValid: false, defaultmode: true })
            } else {
                setState({ ...state, formValid: true, defaultmode: false })
            }
        }
    }, [state.startQuantity, state.points, state.endQuantity])

    
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (state.startQuantity === "" || state.startQuantity === null) {
            setIsError({ ...isError, startQuantity: true })
        } else if (state.points === "" || state.points === null) {
            setIsError({ ...isError, points: true })
        }  else if (state.endQuantity === "" || state.endQuantity === null) {
            setIsError({ ...isError, endQuantity: true })
        } else if (state.startQuantity !== "" && state.points !== "" && state.endQuantity !== "") {

            addPayDate()

        }

    }
    const addPayDate = async () => {
        let payload = null;
        const id = (retro_fields.hasEditData) ? (retro_fields.hasEditData.id) : retro_fields.editTabData.id
        if (id && retro_fields.hasEditThreshold === null) {
            payload = {
                "endQuantity": Number(state.endQuantity),
                "startQuantity": Number(state.startQuantity),
                "points": Number(state.points),
                // "retroDiscountScheme": id
            }
        } else if (retro_fields.hasEditThreshold !== null) {
            payload = {
                // "id": retro_fields.hasEditThreshold.id,
                "endQuantity": Number(state.endQuantity),
                "startQuantity": Number(state.startQuantity),
                "points": Number(state.points),
                // "retroDiscountScheme": retro_fields.hasEditThreshold.retroDiscountScheme
            }
        }

        try {
            setState({ ...state, pageLoader: true })
            // const url = ((retro_fields.hasEditData !== null || retro_fields.editTabData !== null) && retro_fields.hasEditThreshold === null) ? 'addvolumethresholds' : 'updatevolumethresholds';

            const { data } =  await axios.post(`/ImportedRetroDiscount/addVolumeThreshold/${id}`, payload);
            // const { data } = url === 'addvolumethresholds' ? await axios.post(`/retrodiscount/${id}/${url}`, payload) : await axios.put(`/retrodiscount/${id}/${url}`, payload)

            if (data) {
                onSubmitDate({ text: data.message, type: "success" })
                setState({ ...state, pageLoader: false })
                handleClose()
            }

        } catch (error) {
            const { response: { data, status } } = error;
            if (!data) {
                onSubmitDate({ text: error.message, type: "error" })
            } else {
                onSubmitDate({ text: data.message, type: "error" })
            }
            setState({ ...state, pageLoader: false })
            handleClose()
        }
    }

    useEffect(() => {
        if (retro_fields.hasEditThreshold !== null) {
            setState({
                ...state,
                endQuantity: retro_fields.hasEditThreshold.endQuantity,
                startQuantity: retro_fields.hasEditThreshold.startQuantity,
                points: retro_fields.hasEditThreshold.points,
            })
            setType(retro_fields.hasEditThreshold.type)
        }
    }, [])


    useEffect(() => {
        const inputNumber = document.querySelectorAll('.inputNumber');
        inputNumber.forEach(el => el.addEventListener('keypress', evt => {
            if (evt.which === 8) {
                return;
            }
            if (evt.which < 42 || evt.which > 60) {
                evt.preventDefault();
            }
        }));
    }, []);

    return (
        <>


            <Modal
                open={open}
                aria-labelledby="add-edit-price-title"
                aria-describedby="add-edit-price-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Box component="div" className="overlay" id="addEditModal">
                        {state.pageLoader && <PageLoader />}
                        <div className={`overlay-box ${size ? size : ''}`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-header text-center"> Volume Thresholds
                                        <CancelIcon className="closeBtn" onClick={handleClose} />
                                    </div>
                                    <div className="overlay-content">
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            <FormLabel htmlFor="startQuantity" className="fontSemiBold mandatory">Start Quantity</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <div className="input-group">
                                                                <TextField
                                                                    size="small"
                                                                    id="startQuantity"
                                                                    aria-describedby="startQuantity_error"
                                                                    onChange={(e) => handleInputChange(e)}
                                                                    value={state.startQuantity}
                                                                    name="startQuantity"
                                                                    error={isError.startQuantity}
                                                                    type="number"
                                                                    className='inputNumber'
                                                                    step="any"
                                                                    onBlur={(e) => setState({ ...state, [e.target.name]:  e.target.value === '' ? "" : (e.target.value == 0 ? 0 : Number(e.target.value).toFixed(2)) })}
                                                                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                                                />
                                                                {isError.startQuantity && (<FormHelperText className='errorHelperTxt' id="startQuantity_error">
                                                                    Please enter start quantity
                                                                </FormHelperText>)}
                                                            </div>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            <FormLabel htmlFor="endQuantity" className="fontSemiBold mandatory">End Quantity</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <div className="input-group">
                                                                <TextField
                                                                    size="small"
                                                                    id="endQuantity"
                                                                    aria-describedby="endQuantity_error"
                                                                    onChange={(e) => handleInputChange(e)}
                                                                    value={state.endQuantity}
                                                                    name="endQuantity"
                                                                    error={isError.endQuantity}
                                                                    type="number"
                                                                    className='inputNumber'
                                                                    step="any"
                                                                    inputProps={{ maxLength: 16 }}
                                                                    onBlur={(e) => setState({
                                                                        ...state, [e.target.name]:  e.target.value === '' ? "" : (e.target.value == 0 ? 0 : Number(e.target.value).toFixed(2).slice(0,16))
                                                                    })}
                                                                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                                                />
                                                                {isError.endQuantity && (<FormHelperText className='errorHelperTxt' id="points_error">
                                                                    Please enter end quantity
                                                                </FormHelperText>)}
                                                            </div>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            <FormLabel htmlFor="points" className="fontSemiBold mandatory">Points</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <div className="input-group">
                                                                <TextField
                                                                    size="small"
                                                                    id="points"
                                                                    aria-describedby="points_error"
                                                                    onChange={(e) => handleInputChange(e)}
                                                                    value={state.points}
                                                                    name="points"
                                                                    error={isError.points}
                                                                    type="number"
                                                                    className='inputNumber'
                                                                    step="any"
                                                                    onBlur={(e) => setState({
                                                                        ...state, [e.target.name]:  e.target.value === '' ? "" : (e.target.value == 0 ? 0 : Number(e.target.value).toFixed(2))
                                                                    })}
                                                                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                                                />
                                                                {isError.points && (<FormHelperText className='errorHelperTxt' id="points_error">
                                                                    Please enter points
                                                                </FormHelperText>)}
                                                            </div>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>

                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 0 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            &nbsp;
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Grid container direction="row" alignItems="center">
                                                            <Typography sx={{ fontSize: '12px', color: 'red', paddingBottom: '10px', width: '270px' }}>*Start Quantity and End Quantity cannot be same!</Typography>
                                                            </Grid>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            &nbsp;
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Grid container direction="row" alignItems="center">
                                                                <Button 
                                                                variant="contained" 
                                                                type="submit" 
                                                                sx={{ marginRight: '15px' }} 
                                                                //disabled={!retro_fields.hasEditThreshold ? state.formValid : state.defaultmode === false}
                                                                // eslint-disable-next-line max-len
                                                                disabled={((retro_fields.hasEditData !== null || retro_fields.editTabData !== null) && retro_fields.hasEditThreshold) ? (isError.startQuantity || isError.points || isError.endQuantity) : (state.formValid || state.defaultmode === false)}
                                                                >Save</Button>
                                                                <Button variant="outlined" onClick={handleSelf}>Cancel</Button>
                                                            </Grid>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </form>

            </Modal>
        </>
    );
}

export default AddEditThreshold;