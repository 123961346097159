import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editTab: true,
  hasEditPdc: null,
  editTabData: null,
  hasEditData: null,
  pdc_supplier_product_code: '',
  PdcFormValid: false,
  isError: {
    pdc_supplier_product_code: true
  }
}

const pdcProduct = createSlice({
  name: 'pdc_product',
  initialState,
  reducers: {
    brandSearch: (state, action) => {
      const { parent, key, value } = action.payload;
      state[parent][key] = value;
    },
    pdcProductInput: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
      state[key].length === 0 ? state.isError[key] = true : state.isError[key] = false
    },
    getIsError: (state, action) => {
      const { key, value } = action.payload;
      state.isError[key] = value
    },
    setAutocomplete: (state, action) => {
      const { parent, key, value } =  action.payload;
      state[parent][key] = value
    },
    hasTabEdit: (state, action) => {
      const { editTab } =  action.payload;
      state.editTab = editTab
    },
    hasTabEditData: (state, action) => {
      const { editData } =  action.payload;
      state.editTabData = editData
    },
    setHasEditData: (state, action) => {
      state.hasEditData = action.payload;
    },
    setPdcFormStatus: (state, action) => {
      state.PdcFormValid = action.payload;
    },
    resetPdc: () => {
      return initialState
    },
  }
})

export const { brandSearch, hasTabEditData, setAutocomplete, getIsError, pdcProductInput, hasTabEdit, resetPdc, setHasEditData, setPdcFormStatus } = pdcProduct.actions

export default pdcProduct.reducer