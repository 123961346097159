import { Checkbox, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl } from '@mui/material'
import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import axios_utilities from '../../../services/utility/axios-utilities';
import { getIsError, setAutocomplete, setUOMEdit, setUOMRate, setUOPList, uomInput, brandSearch } from "../../../store/slices/uomProduct";
import { LoadingButton } from '@mui/lab';
import SnackBarDialog from '../../../components/shared/SnackBarDialog';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

const defaultValues = {
    rate_value: '',
    loadSupplier: false,
    formValid: true,
    defaultmode: false,
    id: 0
};

function AddEditUnitOfMeasuresDetails({ onShow, size = '', onHide, onBrandAdded }) {

    const [loader, setLoader] = useState(false)
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })
    const [state, setState] = useState(defaultValues);

    const [open, setOpen] = useState(onShow);
    const {
        uom_container
    } = useSelector((state) => {
        return {
            uom_container: state.uomProducts
        }
    });
    useEffect(() => {
        const inputNumber = document.querySelectorAll('.inputNumber');
        inputNumber.forEach(el => el.addEventListener('keypress', evt => {
            if (evt.which === 8) {
                return;
            }
            if (evt.which < 42 || evt.which > 60) {
                evt.preventDefault();
            }
        }));
    }, []);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchMyAPI = async () => {

            if (uom_container.uom_from_to.uom_from_to_options.length === 0) {
                axios_utilities.get('unitofmeasures/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            dispatch(setAutocomplete({
                                parent: 'uom_from_to',
                                key: 'uom_from_to_options',
                                value: unique
                            }));
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
        }
        fetchMyAPI();
    }, [])


    const handleClose = () => {
        //setState({ ...state, defaultmode: false })
        if (onHide) {
            onHide(false)
            dispatch(uomInput({
                key: 'rate_value',
                value: ""
            }))
            dispatch(setAutocomplete({
                parent: 'uom_from_to',
                key: 'uom_from_to_selected',
                value: null
            }))
            dispatch(setUOMEdit(null))
        }
        dispatch(getIsError({ key: 'uom_from_to_selected', value: false }))
        dispatch(getIsError({ key: 'rate_value', value: false }))
    };
    const handleInputChange = (event) => {
        setState({ ...state, defaultmode: true })
        const { value, name } = event.target;
        dispatch(uomInput({
            key: name,
            value
        }))
    }

    const handleSelection = (event, newValue, parentname, name) => {
            setState({ ...state, defaultmode: true })
        if (event.target.value !== "" || event.target.value !== null) {
            // dispatch(setAutocomplete({
            //     parent: parentname,
            //     key: name,
            //     value: newValue
            // }))
            dispatch(brandSearch({
                parent: parentname,
                key: name,
                value: newValue
            }))
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        let addBrandOwner = null;
        if (!state.formValid) {
            addBrandOwner = {
                "rate": uom_container.rate_value,
                "fromUOM": { "id": uom_container.addNewUOMData.id },
                "toUOM": { "id": uom_container.uom_from_to.uom_from_to_selected.id }
            }
            try {
                // eslint-disable-next-line no-constant-condition
                if (uom_container.addNewUOMData && !uom_container.uof_edit_data) {
                    setLoader(true)
                    const { id } = uom_container.addNewUOMData;
                    const { data } = await axios_utilities.post(`unitofmeasures/${id}/conversions`, addBrandOwner);
                    if (data.success) {
                        const { data } = await axios_utilities.get(`unitofmeasures/${id}/conversions`);
                        if (data !== null || data !== undefined) {
                            dispatch(setUOPList(data))
                            setSnackBar({ ...snackBar, show: true, text: 'Created Unit Of Measure Conversion Successfully', type: "success", })
                            setTimeout(() => {
                                handleClose()
                            }, 1500);
                        }
                    }
                }
                else if (uom_container.uof_edit_data) {
                    setLoader(true)
                    const id_from = uom_container.uof_edit_data.fromUOM.id;
                    const id_self = uom_container.uof_edit_data.id;
                    const editConversion = {
                        "rate": uom_container.rate_value,
                        "fromUOM": uom_container.uof_edit_data.fromUOM,
                        "toUOM": uom_container.uof_edit_data.toUOM,
                        "id": uom_container.uof_edit_data.id,
                    }
                    const { data } = await axios_utilities.put(`unitofmeasures/${id_from}/conversions/${id_self}`, editConversion);
                    if (data.success) {
                        const { data } = await axios_utilities.get(`unitofmeasures/${id_from}/conversions`);
                        if (data !== null || data !== undefined) {
                            dispatch(setUOPList(data))
                            setSnackBar({ ...snackBar, show: true, text: 'Updated Unit Of Measure Conversion Successfully', type: "success" })
                            setTimeout(() => {
                                handleClose()
                            }, 1500);
                        }
                    }
                }

            } catch (error) {
                const { response: { data, status } } = error;
                setLoader(false)
                setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error" })
                // setTimeout(() => {
                //     handleClose()
                // }, 1500);
            }
        }
    }

    useEffect(() => {
        if ((uom_container.uom_from_to.uom_from_to_selected === null || uom_container.uom_from_to.uom_from_to_selected === "") || (uom_container.rate_value === null || uom_container.rate_value === "")) {
            setState({ ...state, formValid: true, defaultmode: false })
        } else {
            setState({ ...state, formValid: false, defaultmode: true })
        }
    }, [uom_container.uom_from_to.uom_from_to_selected, uom_container.rate_value])

    useEffect(() => {
        const {
            uom_from_to_selected,
            rate_value
        } = uom_container.isError
        if (!uom_from_to_selected && !rate_value) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true })
        }
    }, [uom_container.isError])

    return (
        <>
            <Modal
                open={open}
                aria-labelledby="add-edit-price-title"
                aria-describedby="add-edit-price-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Box component="div" className="overlay" id="addEditModal">
                        <div className={`overlay-box ${size ? size : ''}`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-header text-center"> Rate Details
                                        <CancelIcon className="closeBtn" onClick={handleClose} />
                                    </div>
                                    <div className="overlay-content">
                                        <Grid item xs={12} md={12}>

                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="from_to" className="fontSemiBold mandatory">TO</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Autocomplete
                                                                disabled={uom_container.uof_edit_data ? true : false}
                                                                defaultValue={uom_container.uom_from_to.uom_from_to_selected}
                                                                id="uom_from_to"
                                                                options={uom_container.uom_from_to.uom_from_to_options}
                                                                getOptionLabel={(option) => option.name || ""}
                                                                componentsProps={{
                                                                    paper: {
                                                                        sx: {
                                                                            fontSize: '0.85rem'
                                                                        }
                                                                    }
                                                                }}
                                                                onChange={(event, newValue) => handleSelection(event, newValue, 'uom_from_to', 'uom_from_to_selected')}
                                                                renderInput={(params) =>
                                                                    <FormControl size={'small'} className='min-input-width'>
                                                                        <TextField {...params}
                                                                            size="small"
                                                                            id="general_unit_measure"
                                                                            aria-describedby="general_unit_measure-error"
                                                                            error={uom_container.isError.uom_from_to_selected}
                                                                        />
                                                                        {uom_container.isError.uom_from_to_selected &&
                                                                            (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)}
                                                                    </FormControl>
                                                                }
                                                            />
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="rate" className="fontSemiBold mandatory">Rate</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>

                                                            <Box component="div" className=''>
                                                                <FormControl size="small">
                                                                    <TextField
                                                                        inputProps={{ maxLength: 50 }}
                                                                        type="number"
                                                                        size="small"
                                                                        id="rate_value"
                                                                        aria-describedby="rate_value_error"
                                                                        onChange={(e) => handleInputChange(e)}
                                                                        value={uom_container.rate_value}
                                                                        error={uom_container.isError.rate_value}
                                                                        min={1}
                                                                        name="rate_value"
                                                                        className="inputNumber"
                                                                        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                                                    />
                                                                    {uom_container.isError.rate_value && (<FormHelperText className='errorHelperTxt' id="price_start_date_error">Please enter decimal value</FormHelperText>)}
                                                                </FormControl>
                                                            </Box>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                        <Grid container sx={{ marginTop: '20px' }}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Item>
                                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                                        <LoadingButton
                                                            loading={loader}
                                                            loadingPosition="center"
                                                            endIcon=""
                                                            variant="contained"
                                                            type="submit" sx={{ marginRight: '15px' }}
                                                            //disabled={state.formValid || state.defaultmode === false}
                                                            // eslint-disable-next-line react/jsx-no-duplicate-props
                                                            disabled={uom_container.uof_edit_data ? (uom_container.isError.uom_from_to_selected || uom_container.isError.rate_value) : (state.formValid || state.defaultmode === false)}
                                                        >
                                                        Save
                                                        </LoadingButton>
                                                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        {snackBar.show && <SnackBarDialog
                                            onShow={snackBar.show}
                                            text={snackBar.text}
                                            type={snackBar.type}
                                            onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                                        />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </form>
            </Modal>
        </>
    );
}

export default AddEditUnitOfMeasuresDetails;