import { Box, Paper, Grid, Button } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CancelIcon from '@mui/icons-material/Cancel';
import PageLoader from "../../../../components/shared/PageLoader";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setChannelPromoPriceInput, setchannel_promo_priceEffectiveDate, setEditChannelPromoPrice, setchannel_promo_priceDateList, setLatestlatestEndPromoPriceDate, setNullPromoPriceStartDate, setDeletePromoPriceList, setSingleDeleteData } from "../../../../store/slices/pricePdcProduct";
import { setPdcPriceModal, warningModal, handleAddEditPdcPriceModal } from "../../../../store/slices/modals";
import { DataTableClientSide } from '../../../../components/shared/data-table/DataTableClientSide';
import AddIcon from '@mui/icons-material/Add';
import AddEditPromotionalChargeModal from './promotionalcharge/AddEditPromotionalChargeModal';
import axiosProductDistribution from '../../../../services/utility/axios-product-distribution';
import SnackBarDialog from '../../../../components/shared/SnackBarDialog';
import moment from 'moment';

const IconMenuLink = ({ params, onPromoPriceDelete }) => {
    const { row } = params;
    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { showAddEditPromotionalChargeModal, pdc_general_product_fields, channel_price_container } = useSelector((state) => {
        return {
            showAddEditPromotionalChargeModal: state.modalActions.hideAddEditPdcModal,
            editTabData: state.pricePdcProducts.editTabData,
            pdc_general_product_fields: state.pricePdcProducts,
            channel_price_container: state.pricePdcProducts
        }
    });
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'edit') {
            editChannelPromoPriceData(row)
        } else if (action === 'delete') {
            if(onPromoPriceDelete){
                onPromoPriceDelete(row)
            }
        }
        setAnchorEl(null);
    };

    // find latest start date from array
    // const maxDate = new Date(
    //     Math.max(
    //       ...channel_price_container.channel_promo_price_date.map(element => {
    //         return new Date(element.startDate);
    //       }),
    //     ),
    //   ); 
    // var  datelast = moment(maxDate).format('DD/MM/YYYY');
    //   // Find a value in an array of object (start date) 
    // let obj = channel_price_container.channel_promo_price_date.find(o => o.startDate === datelast);
    //let keys = Object.keys(channel_price_container.channel_promo_price_date);
    //let last = keys[keys.length - 1];
    //let latest_entry = channel_price_container.channel_promo_price_date[last];
    //let latestId = latest_entry ? latest_entry.id : "";



    // let latestId = '';
    // let obj = channel_price_container.channel_promo_price_date.find(o => o.endDate === null);
    // if(obj !== undefined) {
    //     latestId = obj.id;
    // } else {
    //     latestId = '';
    // }

    //let curDate = moment().format('DD/MM/YYYY');
    //let checkinBetweendates = moment(curDate).isBetween(row.startDate, row.endDate);

    const editChannelPromoPriceData = (row) => {
        dispatch(setPdcPriceModal(true))
        dispatch(setChannelPromoPriceInput(Number(row.price).toFixed(2)))
        dispatch(setEditChannelPromoPrice(row))
        //dispatch(setLatestPdcData(''))
        dispatch(setchannel_promo_priceEffectiveDate({
            key: 'channel_promo_price_start_date',
            value: row.startDate ? moment(row.startDate,'DD/MM/YYYY') : ""
        }))
        dispatch(setchannel_promo_priceEffectiveDate({
            key: 'channel_promo_price_end_date',
            value: row.endDate ? moment(row.endDate,'DD/MM/YYYY') : ""
        }))
    }

    return (<>
            <IconButton
                aria-label="more"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                id={row.id + 1}
            >
                <MoreVertIcon />
            </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'edit')}>Edit Promotional Prices</MenuItem>
            <MenuItem onClick={(e) => handleClose(e, 'delete')}>Delete Promotional Prices</MenuItem>
        </Menu>
        
    </>)
}

const defaultValues = {
    price: '',
    defaultGrid: {
        pagehead: 'PDC',
        eanActionButtons: true,
        paginationMode: 'client',
    },
    addSupplierProductCode: false
};
export default function PdcPromotionalCharge({ onShow }) {
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })
    const [state, setState] = useState(defaultValues);
    const [pageLoader, setPageLoader] = useState(false);
    const [columns, setColumns] = useState([]);

    const allColumns = [
        {
            headerName: "Id",
            field: "id",
            flex: 1,
            hide: true
        },
        {
            headerName: "Price (£)",
            field: "price",
            flex: 1,
        },
        {
            headerName: "Start Date",
            field: "startDate",
            flex: 1,
        },
        {
            headerName: "End Date",
            field: "endDate",
            flex: 1,
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params}  onPromoPriceDelete={deleteChannelPromoPrice}/>;
            },
        }
    ];
    const { getPdcPromoPrice, channel_promo_price_container, showSupplierProductCodeModal,editTabData,hasEditData, editChannelPromoPrice, handleWarning, showAddEditPriceModal } = useSelector((state) => {
        return {
            showSupplierProductCodeModal: state.modalActions.pdcPriceModal,
            channel_promo_price_container: state.pricePdcProducts,
            getPdcPromoPrice: state.pricePdcProducts.channel_promo_price_date,
            editTabData: state.pricePdcProducts.editTabData,
            editChannelPromoPrice: state.pricePdcProducts.editChannelPromoPrice,
            hasEditData: state.pricePdcProducts.hasEditData,
            handleWarning: state.modalActions.closeWaringAddProduct,
            showAddEditPriceModal: state.modalActions.hideAddEditPdcModal
        }
    });
    const dispatch = useDispatch();
    const addPromoPriceModal = (event) => {
        dispatch(setPdcPriceModal(true))
        // let latestendDate = '';
        // if(channel_promo_price_container.channel_promo_price_date.length > 0) {
        //     let keys = Object.keys(channel_promo_price_container.channel_promo_price_date);
        //     let last = keys[keys.length - 1];
        //     let latest_entry = channel_promo_price_container.channel_promo_price_date[last];
        //      latestendDate = moment(latest_entry.endDate, 'DD/MM/YYYY').add(1, 'days');
        //      dispatch(setLatestlatestEndPromoPriceDate(latestendDate))
        //      dispatch(setNullPromoPriceStartDate(''))
        //      dispatch(setchannel_promo_priceEffectiveDate({
        //         key: 'default_channel_promo_price_start_date',
        //         value: latestendDate
        //     })) 
        //  } 
    }
    const hideAddEditPromotionalChargeModal = (event) => {
        dispatch(setPdcPriceModal(event))
    }

    const allPromoPricelists = async () => {
        try { 
            setPageLoader(true)
            setTimeout(() => {
                setColumns(allColumns)
            }, 100);
            const id = editTabData ? editTabData.id : hasEditData.id;
            const { data } = await axiosProductDistribution.get(`pdcs/${id}/pdcChannelPromoPrices`);
            if (data) {
                setPageLoader(false)   
                dispatch(setchannel_promo_priceDateList(data.items))
            }
        } catch (error) {
            setPageLoader(false)
            const { response: { data, status } } = error;
            if (data.message !== "No Product Distribution Channel Promo Prices found") {
                setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: data.message ? "warning" : "error" })
            }
        }
    }
    useEffect(() => {
        allPromoPricelists();
    }, []);

    const deleteChannelPromoPrice = (row) => {
        dispatch(setDeletePromoPriceList(row))
        dispatch(setSingleDeleteData({
            key: 'pdcPromoPricestartSingleDelete',
            value: true
        }))     
        dispatch(handleAddEditPdcPriceModal({
            showAddEditPrice: true
        }))
        dispatch(warningModal({
            yesHide: !handleWarning
        }))
    }
    
    useEffect(() => {
        if (channel_promo_price_container.pdcPromoPriceconfirmSingleDelete) {
            deletePdcPromoPriceSingleRow()
        }
    }, [channel_promo_price_container.pdcPromoPriceconfirmSingleDelete])

    const deletePdcPromoPriceSingleRow = async () => {
        if (channel_promo_price_container.deletePromoPriceList !== null) {
            const id = editTabData ? editTabData.id : hasEditData.id;
            try { 
                setPageLoader(true)
                const { data } = await axiosProductDistribution.delete(`pdcs/${id}/pdcChannelPromoPrices/${channel_promo_price_container.deletePromoPriceList.id}`);
                // if (data.success) {
                //     try {
                //         const { data } = await axiosProductDistribution.get(`pdcs/${id}/pdcChannelPromoPrices`);
                //         if (data !== null || data !== undefined) {
                //             //dispatch(setSupplierProductCodeList(data))
                //             setSnackBar({ ...snackBar, show: true, text: "Channel promo price Delete Successfully!", type: "success" })
                //             dispatch(setchannel_promo_priceDateList(data.items))
                //             pdcsingleDeleteRowDispatch()
                //             // setTimeout(() => {
                //             //     window.location.reload();
                //             // }, 1500);
                //         }
                //     }
                //     catch (error) {
                //         setSnackBar({ ...snackBar, show: true, text: "Something went wrong!", type: "error", })
                //         pdcsingleDeleteRowDispatch()
                //     }
                // }
                if (data.success) {
                    setPageLoader(false)
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
                    const filteredRows = getPdcPromoPrice.filter(uniqueRow => {
                        if (uniqueRow.id !== channel_promo_price_container.deletePromoPriceList.id) {
                            return uniqueRow;
                        }
                    })
                    dispatch(setchannel_promo_priceDateList(filteredRows))
                    dispatch(setDeletePromoPriceList(null))
                    pdcsingleDeleteRowDispatch()
                    setState({ ...state, loader: false })
                }

            } catch (error) {
                setPageLoader(false)
                const { response: { data, status } } = error;
                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                }
                dispatch(setDeletePromoPriceList(null))
                pdcsingleDeleteRowDispatch()
            }
        }
    }

    const pdcsingleDeleteRowDispatch = () => {
        dispatch(setSingleDeleteData({
            key: 'pdcPromoPricestartSingleDelete',
            value: false
        }))
        dispatch(setSingleDeleteData({
            key: 'pdcPromoPriceconfirmSingleDelete',
            value: false
        }))
    }

    return (
        <>
            {pageLoader && <PageLoader />}
            <Box sx={{ flexGrow: 1, paddingX: 2 }} >
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5 } }}>
                    <div className='table-actionable'>
                        <Button variant="contained" onClick={(e) => addPromoPriceModal(e)} startIcon={<AddIcon />}>
                            Add New
                        </Button>
                    </div>
                </Box>
                <DataTableClientSide
                    columns={columns ? columns : []}
                    defaultGrid={state.defaultGrid}
                    rows={getPdcPromoPrice}
                >
                </DataTableClientSide>
                {showSupplierProductCodeModal && (
                    <>
                        <AddEditPromotionalChargeModal size="medium_overlay"
                            onShow={showSupplierProductCodeModal}
                            onHide={(e, reason) => hideAddEditPromotionalChargeModal(e, reason)}
                        />
                    </>
                )}
            </Box>
            {snackBar.show && <SnackBarDialog
            onShow={snackBar.show}
            text={snackBar.text}
            type={snackBar.type}
            onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
        />}
        </>
    );
}
