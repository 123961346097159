import { Container, Box, Modal, Button, Grid, Checkbox, Popper, Grow, Paper, ClickAwayListener, MenuList, ButtonGroup, Stack, TextField, FormControl, FormHelperText, FormLabel, Typography } from '@mui/material';
import { TableFilters } from "../../components/shared/data-table/TableFilters";
import { useEffect, useState } from "react";
import { InputText } from "../../components/shared/form/InputText";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { } from '@mui/material';
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange, setEdiFileLogConversionDate, filterResetFlagChange } from "../../store/slices/externalFilter";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import moment from "moment";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import { warningModal, handleAddEditModal } from "../../store/slices/modals";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { setRowPage, resetDataGrid, setPageNo } from '../../store/slices/dataGrid';
import { useLayoutEffect } from "react";
import axios from "../../services/utility/axios";
import { DataTableEdiFileLog } from '../../components/shared/data-table/DataTableEdiFileLog';
import SnackBarDialog from '../../components/shared/SnackBarDialog';
import { styled } from '@mui/material/styles';
import { setMemberCode } from '../../store/slices/supplierMemberAccounts';
import CommonPopUp from '../products/addEditPdc/pdc-tabs/CommonPopUp';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { AutoCompleteStatic } from '../../components/shared/form/AutoCompleteStatic';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(1.5),
    boxShadow: "none",
    background: "none"
}));

function EdiFileLog() {

    const { edi_file_log_file_name, fromDate,
        edi_file_log_supplier_type, addProductwarning, showAddEditModal, sma_container, supplier_invoice_name } = useSelector((state) => {
            return {
                edi_file_log_file_name: state.externalFilter.edi_file_log_file_name,
                fromDate: state.externalFilter.edi_file_conversiondate,
                edi_file_log_supplier_type: state.externalFilter.edi_file_log_supplier_type,
                resetTable: state.dataGridTable,
                addProductwarning: state.modalActions.closeWaringAddProduct,
                showAddEditModal: state.modalActions.hideAddEditModal,
                sma_container: state.smas,
                supplier_invoice_name: state.externalFilter.supplier_invoice_name,
            }
        });

    const [state, setState] = useState({
        active_product: false,
        openNewProductModal: false,
        supplierMemberDasboadModal: false,
        defaultGrid: {
            pagetitle: "EDI File Log",
            productActionButton: true,
            exportExcelButton: true,
            addEditButton: true,
            apiUrl: 'importedInvoiceSummaries',
            searchUrl: 'importedInvoiceSummaries/filter'
        }
    });

    const columns = [
        {
            headerName: "Id",
            field: "importedInvoiceSummaryId",
            width: 100,
        },
        {
            headerName: "CBId",
            field: "centraBillingId",
            width: 100,
        },
        {
            headerName: "Supplier Name",
            field: "supplierName",
            width: 250,
            renderCell: (params) => (
                <Typography title={params.value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: 'inherit', fontWeight: 'inherit' }}>{params.value}</Typography>
            )

        },

        {
            headerName: "ANA Number",
            field: "supplierAnaNumber",
            width: 150,
        },
        {
            headerName: "File Path",
            field: "uri",
            minWidth: 350,
            flex: 1,
            renderCell: (params) => (
                <Typography title={params.value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: 'inherit', fontWeight: 'inherit' }}>{params.value}</Typography>
            )
        },
        {
            headerName: "File Date",
            field: "fileDate",
            width: 120,

        },
        {
            headerName: "Conversion Date",
            field: "conversionDate",
            width: 150,

        },
        {
            headerName: "Reader Name",
            field: "readerName",
            width: 200,
            renderCell: (params) => (
                <Typography title={params.value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: 'inherit', fontWeight: 'inherit' }}>{params.value}</Typography>
            )
        },
        {
            headerName: "File Number",
            field: "fileNumber",
            width: 120,

        },
        {
            headerName: "File Total",
            field: "fileTotal",
            width: 120,

        },
        {
            headerName: "File Count",
            field: "fileCount",
            width: 120,

        },
        {
            headerName: "TO",
            field: "convertedTotalOverriden",
            width: 50,
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
            align: "center",
            headerAlign: "center",
        },
        {
            headerName: "CO",
            field: "convertedCountOverriden",
            width: 50,
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
            align: "center",
            headerAlign: "center",
        }
    ];



    const handleStartDate = (event) => {
        const dateValue = event === null ? null : moment(event, 'DD/MM/YYYY')
        dispatch(setEdiFileLogConversionDate(dateValue))
    };

    const dispatch = useDispatch();

    useEffect(() => {
        // This Use Effect is used to clear the Lookups when the page loads.
        dispatch(setMemberCode({
            key: 'smaSupplierNameOnModal',
            value: ""
        }));

    }, [])

    const handleFilterInput = (value, id) => {
        //setState({ ...state, [id]: value });
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    };

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false

    })

    const selectedAutoComplete = (value, id) => {
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    }

    const filterBtn = () => {
        dispatch(resetDataGrid());
        var dynamicFilterObjects = {};

        if (supplier_invoice_name !== '' && supplier_invoice_name !== null) {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "supplierName": supplier_invoice_name.name,
            }
        }
        if (fromDate !== '' && fromDate !== null) {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "conversionDate": moment(fromDate).format('DD/MM/YYYY')
            }
        }
        if (edi_file_log_file_name !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "fileName": edi_file_log_file_name
            }
        }

        if (fromDate !== "" && fromDate !== null && moment(fromDate).format('DD/MM/YYYY') === 'Invalid date') {
            setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
        }
        else {
            dispatch(applyFilter({
                key: 'filterList',
                value: dynamicFilterObjects
            }));

            dispatch(filterFlagChange(true));
            dispatch(filterResetFlagChange(false));
            dispatch(setRowPage({ pageData: 10 }));
            dispatch(setPageNo({ page: 1 }));
        }


    }
    const resetFilter = () => {
        if ((supplier_invoice_name || fromDate || edi_file_log_file_name) !== '') {
            dispatch(resetExternalFilter())
            dispatch(resetDataGrid());
        }
        setState({ ...state, active_product: false })
        dispatch(filterResetFlagChange(true));
        dispatch(setMemberCode({
            key: 'smaSupplierNameOnModal',
            value: ""
        }));
    }
    
    const addProductModal = () => {
        dispatch(handleAddEditModal({
            showAddEdit: !showAddEditModal
        }))
    }

    const hideNewProductModal = (event) => {
        setState({ ...state, openNewProductModal: false })
    }

    const resetLookups = (params, event) => {
        if (event === 'supplier') {
            dispatch(setMemberCode({
                key: 'smaSupplierNameOnModal',
                value: ""
            }))
        }
    }

    const CommonPopUpModal = (e, type) => {
        setState({ ...state, supplierMemberDasboadModal: true, handleSupplierMemberModal: type })
    }

    const handleSupplierLookUp = (event, reason, type) => {
        if (type === 'supplier') {
            if (reason) {
                dispatch(setFilter({
                    key: 'supplier_invoice_name',
                    value: reason ? { "id": reason.id, "name": reason.name } : null
                }))
            }
            setState({ ...state, supplierMemberDasboadModal: event === false ? event : true })
        }
    }

    const onClearAutoComplete = (value, id) => {
        dispatch(setFilter({
            key: id,
            value: null
        }))
    }

    return (
        <>

            <Container disableGutters={true} maxWidth={false}>

                {snackBar.show && <SnackBarDialog
                    onShow={snackBar.show}
                    text={snackBar.text}
                    type={snackBar.type}
                    onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                />}
                <BreadcrumbNavs pageTitle="EDI File Log" />
                <Box sx={{ marginBottom: 5 }}>
                    <TableFilters
                        filterFields={<>
                            <Box component="div"><AutoCompleteStatic endPoint="/supplier/look-up" onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} onClear={onClearAutoComplete} id="supplier_invoice_name" label="Supplier Name" name="supplier_invoice_name" /></Box>
                            <Box component="div">
                                <FormLabel htmlFor="edi_file_conversiondate" className="fontBold">Conversion Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        value={fromDate}
                                        onChange={(e) => { handleStartDate(e) }}
                                        name="edi_file_conversiondate" label="Conversion Date"
                                        inputFormat="DD/MM/YYYY"
                                        // eslint-disable-next-line max-len
                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                            <Box component="div" sx={{ paddingTop: '6px' }} className='date-picker-input'>
                                                <FormControl size="small" fullWidth>
                                                    <TextField size="small" id="edi_file_conversiondate"
                                                        ref={inputRef} {...inputProps}
                                                        placeholder=""
                                                        aria-describedby="edi_file_conversiondate_error"
                                                        autoComplete="off"
                                                    />
                                                    <Box className='date-picker-icon'>
                                                        {InputProps?.endAdornment}
                                                    </Box>
                                                </FormControl>
                                            </Box>
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>
                            <Box component="div"><InputText onChange={handleFilterInput} maxWidth={50} label="File Name" id="edi_file_log_file_name" size="small" /></Box>
                        </>}
                        filterCheckboxes={<>

                        </>}
                        filterBtns={
                            <>
                                <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                <Button variant="contained" onClick={filterBtn}>Apply</Button>
                            </>}>
                    </TableFilters>
                </Box>

                <DataTableEdiFileLog defaultGrid={state.defaultGrid} columns={columns}>

                </DataTableEdiFileLog>

                {
                    state.supplierMemberDasboadModal && (
                        <CommonPopUp
                            onShow={state.supplierMemberDasboadModal} size="medium_overlay"
                            supplierMemberCode={{
                                title: (state.handleSupplierMemberModal && state.handleSupplierMemberModal === 'members') ? 'Member Details' : 'Supplier Details',
                                type: state.handleSupplierMemberModal
                            }}
                            onHide={(e, reason) => handleSupplierLookUp(e, reason, state.handleSupplierMemberModal)}
                        />
                    )
                }

            </Container>

        </>
    );
}

export default EdiFileLog;
