import ReactDOM from "react-dom/client";
// Required for Redux store setup
import { Provider } from 'react-redux'
import store from './store/store';

import App from './App';
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';

import { theme } from './services/mui/theme';
import { LicenseInfo } from '@mui/x-license-pro';
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY);

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import React from "react";

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(

  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        {/* <React.StrictMode> */}
          <MsalProvider instance={msalInstance}>
            <App />
          </MsalProvider>
        {/* </React.StrictMode> */}
      </Provider>
    </ThemeProvider>
  </BrowserRouter>
);
// root.render(

//   <BrowserRouter>
//     <ThemeProvider theme={theme}>
//       <Provider store={store}>
//         <App />
//       </Provider>
//     </ThemeProvider>
//   </BrowserRouter>
// );

