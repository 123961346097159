import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editTab: true,
  hasEditProduct: null,
  editTabData: null,
  rowData: null,
  hasEditData: null,
  spcStartSingleDelete: false,
  spcConfirmSingleDelete: false,
  deleteList: null,
  pdc_gen_product: null,
  pdc_general_supplier: null,
  pdc_supplierCode: [],
  supplierprocodename: '',
  editSupplierCode: null,
  latestGeneralData: null,
  pdc_general_distribution_channel: {
    distribution_channel_options: [],
    distribution_channel_selected: null
  },
  pdc_general_unit_measure: {
    unit_measure_options: [],
    unit_measure_selected: null
  },
  pdc_general_levy_scheme: {
    levy_scheme_options: [],
    levy_scheme_selected: null
  },
  pdc_general_distribution_charge: {
    distribution_charge_options: [],
    distribution_charge_selected: null
  },
  pdc_supplier: {
    supplier_code_options: [],
    pdc_general_supplier: null,
},
  pdcgeneralFormValid: false,
  pdc_general_active_p: false,
  pdc_cur_price: '',
  pdc_cur_charge: '',
  pdc_cur_promo: '',
  isError: {
    distribution_channel_selected: false,
    supplierprocodename: false,
    pdc_gen_product: false,
    pdc_general_supplier: false
  }
}

const generalPdcProduct = createSlice({
  name: 'general_pdc_product',
  initialState,
  reducers: {
    brandSearch: (state, action) => {
      const { parent, key, value } = action.payload;
      state[parent][key] = value;
      (state[parent][key] === null || state[parent][key] === "") ? state.isError[key] = true : state.isError[key] = false
    },
    generalPdcProductInput: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
      state[key].length === 0 ? state.isError[key] = true : state.isError[key] = false
    },
    generalPdcProductCheckbox: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value
    },
    getIsError: (state, action) => {
      const { key, value } = action.payload;
      state.isError[key] = value
    },
    setAutocomplete: (state, action) => {
      const { parent, key, value } =  action.payload;
      state[parent][key] = value
    },
    hasTabEdit: (state, action) => {
      const { editTab } =  action.payload;
      state.editTab = editTab
    },
    hasTabEditData: (state, action) => {
      const { editData } =  action.payload;
      state.editTabData = editData
    },
    hasRowData: (state, action) => {
      state.rowData = action.payload;
    },
    setHasEditData: (state, action) => {
      state.hasEditData = action.payload;
    },
    setSupplierProductCode: (state, action) => {
      state.pdc_supplierCode.push(action.payload)
    },
    setSupplierProductCodeList: (state, action) => {
      state.pdc_supplierCode = action.payload
    },
    setSupplierProductCodeInput: (state, action) => {
      state.supplierprocodename = action.payload;
    },
    setEditSupplierProductCode: (state, action) => {
      state.editSupplierCode = action.payload
    },
    setLatestPdcData: (state, action) => {
      state.latestGeneralData = action.payload
    },
    setDeleteList: (state, action) => {
      state.deleteList = action.payload
    },
    setPdcGeneralFormStatus: (state, action) => {
      state.pdcgeneralFormValid = action.payload;
    },
    setPdcSpcDeleteData: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    resetPdcProduct: () => {
      return initialState
    },
  }
})

// eslint-disable-next-line max-len
export const { brandSearch, hasTabEditData, setAutocomplete, getIsError, generalPdcProductInput, generalPdcProductCheckbox, hasTabEdit, resetPdcProduct, setHasEditData, setSupplierProductCode, setSupplierProductCodeList,  setSupplierProductCodeInput, setEditSupplierProductCode, setDeleteList, setPdcGeneralFormStatus, setLatestPdcData, hasRowData, setPdcSpcDeleteData} = generalPdcProduct.actions

export default generalPdcProduct.reducer