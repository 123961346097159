import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editTab: true,
    hasEditVC: null,
    editData: null,
    hasEditData: null,
    failed_files_file_name: '',
    selectedIds: [],
    selectedIdsRows: [],
    isError: {
        failed_files_file_name: true
    }

}

const failedFile = createSlice({
    name: 'failedFile_product',
    initialState,
    reducers: {
        failedFileInput: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value;
            state[key].length === 0 ? state.isError[key] = true : state.isError[key] = false
        },
        getIsError: (state, action) => {
            const { key, value } = action.payload;
            state.isError[key] = value
        },
        hasEdit: (state, action) => {
            const { editTab } = action.payload;
            state.editTab = editTab
        },
        hasEditData: (state, action) => {
            const { editData } = action.payload;
            state.editData = editData
        },
        setHasEditData: (state, action) => {
            state.hasEditData = action.payload;
        },
        setAutocomplete: (state, action) => {
            const { parent, key, value } = action.payload;
            state[parent][key] = value
        },
        setSelectedIds: (state, action) => {
            state.selectedIds = action.payload
        },
        setSelectedIdsRows: (state, action) => {
            state.selectedIdsRows = action.payload
        },
        resetfailedFile: () => {
            return initialState
        },
    }
})

export const { failedFileInput, resetfailedFile, setAutocomplete, getIsError, hasEdit, hasEditData, setHasEditData, setSelectedIds, setSelectedIdsRows } = failedFile.actions

export default failedFile.reducer