import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editTab: true,
  hasEditPdc: null,
  editTabData: null,
  hasEditData: null,
  aggregation_group_name: '',
  aggregationGroupFormValid: false,
  isError: {
    aggregation_group_name: false
  }
}

const aggregationGroup = createSlice({
  name: 'supplier_accounts',
  initialState,
  reducers: {
    brandSearch: (state, action) => {
      const { parent, key, value } = action.payload;
      state[parent][key] = value;
    },
    aggregationGroupInput: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
      state[key].length === 0 ? state.isError[key] = true : state.isError[key] = false
    },
    getIsError: (state, action) => {
      const { key, value } = action.payload;
      state.isError[key] = value
    },
    setAutocomplete: (state, action) => {
      const { parent, key, value } =  action.payload;
      state[parent][key] = value
    },
    hasTabEdit: (state, action) => {
      const { editTab } =  action.payload;
      state.editTab = editTab
    },
    hasTabEditData: (state, action) => {
      const { editData } =  action.payload;
      state.editTabData = editData
    },
    setHasEditData: (state, action) => {
      state.hasEditData = action.payload;
    },
    setaggregationGroupFormStatus: (state, action) => {
      state.aggregationGroupFormValid = action.payload;
    },
    resetaggregationGroup: () => {
      return initialState
    },
  }
})

export const { brandSearch, hasTabEditData, setAutocomplete, getIsError, aggregationGroupInput, hasTabEdit, resetaggregationGroup, setHasEditData, setaggregationGroupFormStatus } = aggregationGroup.actions

export default aggregationGroup.reducer