
import { Link, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Drawer, IconButton, Box, Container, List, ListItem, Typography } from '@mui/material';
import { Link as NoLink } from '@mui/material';
import logo from "../../assets/images/logo.png"
import menus from "../../router/navigation-json";
import { useEffect, useState } from "react";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { makeStyles } from '@material-ui/core/styles';
import Nuslogo from "../../assets/images/nus-logo.webp";
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Logout from '@mui/icons-material/Logout';
import Tooltip from '@mui/material/Tooltip';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { SignInButton } from "../SignInButton";
import { SignOutButton } from "../SignOutButton";

const useStyles = makeStyles(() => ({
    root: {
        "&.MuiPaper-root": {
            background: "#fff",
            boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.25)",
            display: 'block',
            zIndex: '10'
        }
    }
}));

const drawerWidth = 240;

function Header() {
    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [mobileDropDown, setMobileDropDown] = useState(-1);
    const location = useLocation();
    const envName = process.env.REACT_APP_NAME || '';
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts, inProgress } = useMsal();
    const name = accounts[0] && accounts[0].name;
    let namesplit = [];

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const openMobileDropdown = (event, index) => {
        event.stopPropagation();
        setMobileDropDown(mobileDropDown === index ? -1 : index);
    }

    if (name) {
        namesplit = accounts[0].name.split(" ");
    }

    const userInitials = namesplit.map(x => {
        return x.slice(0, 1);
    });

    // console.log(namesplit, userInitials, ...userInitials)

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = (logoutType) => {
        if (logoutType === "popup") {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/" // redirects the top level app after logout
            });
        }
    }

    useEffect(() => {
        // execute on location change
        if (location.pathname.split('/')[2]) {
            let name = location.pathname.split('/')[2].split('-').join(' ');
            name = name.charAt(0).toUpperCase() + name.slice(1);
            if (name.includes('outlet exclusions')) {
                name = 'Outlet exclusions'
            } else if (name === 'Pdc file upload') {
                name = 'PDC file upload'
            } else if (name === 'Summary') {
                name = 'Invoice Summary'
            } else if (name === 'Reprocess') {
                name = 'Invoice Reprocess'
            }
            document.title = 'NUS CBM - ' + name+'-' +envName
        } else {
            let name = 'Home'
            document.title = 'NUS CBM - ' + name +'-'+envName
        }
    }, [location]);

    const drawer = (
        <Box className="mobileSlideMenu" onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
            <Box component="div" className="logo" sx={{ padding: '10px' }}>
                <NoLink href="/">
                    <img src={logo} alt="logo" className="mobile-menu-logo" id="mobile-menu-logo" />
                </NoLink>
            </Box>
            <List>
                {menus.map((menu, i) => (
                    <ListItem id={'main-mobile-menu-link' + i} key={`menu-${i}`}>
                        <Box onClick={(e) => { openMobileDropdown(e, i) }} component="div" className="mobile-menu">
                            <NoLink>{menu.link_name}</NoLink>
                            <Typography component="div" sx={{ cursor: 'pointer', lineHeight: '1' }}>
                                {
                                    mobileDropDown === i ?
                                        (<RemoveOutlinedIcon />) :
                                        (<AddOutlinedIcon />)
                                }
                            </Typography>
                        </Box>
                        {
                            menu.submenu && (
                                <Box component="div" className={`submenu ${!(mobileDropDown === i) ? "openMobileDropdown" : ""}`}>
                                    <List>
                                        {
                                            menu.submenu.map((submenu, i) => (
                                                (
                                                    <ListItem key={'submenu' + i}>
                                                        <Link className="mobileLinks" to={submenu.link_path}>
                                                            {submenu.link_name}
                                                        </Link>
                                                    </ListItem>
                                                )))
                                        }
                                    </List>
                                </Box>
                            )
                        }
                    </ListItem>
                ))}
                <ListItem><Box component="div" className="mobile-menu">{isAuthenticated ? <SignOutButton /> : <SignInButton />}</Box></ListItem>
            </List>
        </Box>
    );

    return (<>
        <AppBar className={classes.root}>
            <Container disableGutters={true} maxWidth={false}>
                <Box component="div" className="navigation" sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box component="div" className="logo" sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>
                        <NoLink href="/">
                            <img src={logo} alt="logo" className="headlogo" />
                        </NoLink>
                    </Box>
                    <Box component="div" className="menubar" sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>
                        <List>
                            {menus.map((menu, i) => (
                                <ListItem id={'main-menu-link' + i} key={menu.link_name + i} className={`${menu.submenu ? 'hasDropdown' : ''}`}>
                                    <NoLink>{menu.link_name}</NoLink>
                                    {
                                        menu.submenu && (
                                            <Box component="div" className="submenu">
                                                <List>
                                                    {
                                                        menu.submenu.map((submenu, index) => (
                                                            (
                                                                <ListItem key={submenu + index}>
                                                                    <Link to={submenu.link_path}>
                                                                        {submenu.link_name}
                                                                    </Link>
                                                                </ListItem>
                                                            )))
                                                    }
                                                </List>
                                            </Box>
                                        )
                                    }
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                    <Box component="div" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: { xs: "100%", sm: "100%", md: "100%", lg: "auto" } }}>
                        <IconButton
                            aria-label="open drawer"
                            edge={'end'}
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { lg: "none" } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Box component="div" className="profileDropdown" sx={{ mr: 0, marginLeft: 0, paddingLeft: '10px !important', display: { xs: "block", sm: "block" } }}>
                            {/* <Box className="un-profile-dropdown">JD</Box> */}
                            {isAuthenticated ?
                                <Box component="div" sx={{ display: 'block', alignItems: 'center', textAlign: 'center' }}>
                                    {/* <Typography>Welcome, {name}</Typography>
                                <List>
                                    
                                </List>
                                <SignOutButton /> */}
                                    <Tooltip title={name}>
                                        <IconButton
                                            onClick={handleClick}
                                            size="small"
                                            sx={{ ml: 0, padding: 0 }}
                                            aria-controls={open ? 'account-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                        >
                                            <Avatar sx={{ width: 40, height: 40 }}>{[...userInitials]}</Avatar>

                                        </IconButton>
                                    </Tooltip>
                                    {/* <Typography color="black" sx={{ fontSize: '14px' }}>{name}</Typography> */}
                                </Box>
                                : <SignInButton />}
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem onClick={() => handleLogout("popup")}>
                                    <ListItemIcon>
                                        <Logout fontSize="small" />
                                    </ListItemIcon>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Box>
                </Box>
            </Container>

        </AppBar>
        <Box component="nav">
            <Drawer
                variant="temporary"
                anchor={'left'}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: "block", sm: "block", md: "block", lg: "none" },
                    "& .MuiDrawer-paper": {
                        width: drawerWidth
                    }
                }}
            >
                {drawer}
            </Drawer>
        </Box>
    </>
    );
}
export default Header;
