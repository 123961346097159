import { DataTable } from "../../components/shared/data-table/DataTable";
import { Container, Box, Modal, Button, Grid, Checkbox, Popper, Grow, Paper, ClickAwayListener, MenuList, ButtonGroup, FormLabel, TextField, FormControl } from '@mui/material'
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TableFilters } from "../../components/shared/data-table/TableFilters";
import { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { InputText } from "../../components/shared/form/InputText";
import { AutoComplete } from "../../components/shared/form/AutoComplete";
import { SelectDropDown } from "../../components/shared/form/Select";
import { } from '@mui/material';
import { ThreeStateCheckbox } from "../../components/shared/form/ThreeStateCheckbox";
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange, filterResetFlagChange } from "../../store/slices/externalFilter";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import AddEditProduct from "./addEditProduct/AddEditProduct";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import General from "./addEditProduct/product-tabs/General";
import { warningModal, handleAddEditModal, handleAddEditPriceModal } from "../../store/slices/modals";
import WarningModal from "../../components/shared/modals/WarningModal";
import Price from "./addEditProduct/product-tabs/Price";
import Leavy from "./addEditProduct/product-tabs/Leavy";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { setRowPage, resetDataGrid, setPageNo } from '../../store/slices/dataGrid'
import Specification from "./addEditProduct/product-tabs/Specification";
import Eancode from "./addEditProduct/product-tabs/Eancode";
import { useLayoutEffect } from "react";
import { hasTabEdit, setHasEditData, generalProductInput, brandSearch, setSpecification, setEanCodeList, setListOfPrice, setProductId, setListOfLevy } from "../../store/slices/generalProduct";
import { setCommonInput } from "../../store/slices/commonProduct";
import axios from "../../services/utility/axios";
import { AutoCompleteStatic } from "../../components/shared/form/AutoCompleteStatic";
import AddEditPriceLeavy from "./addEditProduct/AddEditPriceLeavy";
import SnackBarDialog from "../../components/shared/SnackBarDialog";
import PageLoader from "../../components/shared/PageLoader";
import CommonPopUp from "./addEditPdc/pdc-tabs/CommonPopUp";

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(1.5),
    boxShadow: "none",
    background: "none"
}));

const IconMenuLink = ({ params, onEditPrice }) => {
    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { showAddEditModal, showAddEditPriceModal, general_product_fields } = useSelector((state) => {
        return {
            showAddEditModal: state.modalActions.hideAddEditModal,
            showAddEditPriceModal: state.modalActions.hideAddEditPriceModal,
            editTabData: state.generalProduct.editTabData,
            general_product_fields: state.generalProduct
        }
    });
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'edit_general') {
            dispatch(handleAddEditModal({
                showAddEdit: !showAddEditModal
            }))
            dispatch(hasTabEdit({ editTab: false }))
            setEditGeneralData(row);
        } else if (action === 'edit_price') {
            if (onEditPrice) {
                onEditPrice(row)
            }
        } else if (action === 'goto_pdc') {
            // console.log(row);
            localStorage.setItem('gotopdc_id', row.id);
            localStorage.setItem('gotopdc_name', row.name);
            localStorage.setItem('gotopdc_ocbc', row.outerCaseBarcode);
            localStorage.setItem('gotopdc_bopid', row.brandOwnerProductId);
            localStorage.setItem('gotopdc_flag', true);
            window.open("/products/product-distribution-channel", "_blank");
        }
        setAnchorEl(null);
    };
    const setEditGeneralData = async (row) => {
        dispatch(setHasEditData(row))
        dispatch(generalProductInput({
            key: 'general_product_name',
            value: row.name
        }))
        dispatch(generalProductInput({
            key: 'general_active_p',
            value: row.active
        }))
        dispatch(generalProductInput({
            key: 'general_export_td',
            value: row.tdExport
        }))
        dispatch(generalProductInput({
            key: 'general_brand_owner_pid',
            value: row.brandOwnerProductId
        }))
        dispatch(generalProductInput({
            key: 'general_outer_case_barcode',
            value: row.outerCaseBarcode
        }))
        dispatch(generalProductInput({
            key: 'general_ean_code',
            value: row.eanCode ? row.eanCode : ""
        }))
        dispatch(generalProductInput({
            key: 'general_rsp',
            value: row.rsp ? row.rsp : ""
        }))
        dispatch(generalProductInput({
            key: 'general_case_quantity',
            value: row.caseQuantity
        }))
        // dispatch(generalProductInput({
        //     key: 'general_brand',
        //     value: row.brand
        // }))
        dispatch(brandSearch({
            parent: 'general_brands',
            key: 'brand_selected',
            value: row.brand
        }))
        dispatch(brandSearch({
            parent: 'general_vat_code',
            key: 'vat_code_selected',
            value: row.vatCode
        }))
        dispatch(brandSearch({
            parent: 'general_unit_measure',
            key: 'measure_selected',
            value: row.unitOfMeasure
        }))
        dispatch(brandSearch({
            parent: 'general_category',
            key: 'category_selected',
            value: row.category
        }))
        dispatch(generalProductInput({
            key: 'general_fair_trade',
            value: row.fairTrade
        }))
        dispatch(generalProductInput({
            key: 'general_organic',
            value: row.organic
        }))
        dispatch(generalProductInput({
            key: 'general_ethical',
            value: row.ethical
        }))
        dispatch(brandSearch({
            parent: 'general_member_tier',
            key: 'member_tier_selected',
            value: row.memberTier
        }))
        dispatch(generalProductInput({
            key: 'general_rt',
            value: row.rt
        }))
        dispatch(generalProductInput({
            key: 'general_msc',
            value: row.msc
        }))
        dispatch(generalProductInput({
            key: 'general_rspca',
            value: row.rspca
        }))
        dispatch(generalProductInput({
            key: 'general_fsc',
            value: row.fsc
        }))
        dispatch(generalProductInput({
            key: 'general_rspo',
            value: row.rspo
        }))
        dispatch(generalProductInput({
            key: 'general_coo',
            value: row.countryOfOrigin ? row.countryOfOrigin : ""
        }))
        dispatch(setSpecification(row.specification ? row.specification : ""))
        //const { data } = await axios.get(`products/${row.id}/additionalEAN`);
        // dispatch(setListOfPrice(data))
        //dispatch(setEanCodeList(data))
    }

    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'edit_general')}>Edit General Details</MenuItem>
            <MenuItem onClick={(e) => handleClose(e, 'edit_price')}>Edit Price Details</MenuItem>      
            <MenuItem onClick={(e) => handleClose(e, 'goto_pdc')}>Go to PDC</MenuItem>      
            {/* <MenuItem onClick={() => window.open("/products/product-distribution-channel", "_blank")}>Go to PDC</MenuItem> */}
        </Menu>
    </>)
}
function Products() {

    const tabs = [
        {
            label: "General",
            Component: <General />
        },
        // {
        //     label: "Prices",
        //     Component: <Price />
        // },
        {
            label: "Specification",
            Component: <Specification />
        },
        {
            label: "Additional EAN Codes",
            Component: <Eancode />
        }
    ];
    const priceTabs = [
        {
            label: "Prices",
            Component: <Price />
        },
        {
            label: "Levy",
            Component: <Leavy />
        },
    ];
    const columns = [
        {
            headerName: "ID",
            field: "id",
            minWidth: 50,
        },
        {
            headerName: "Name",
            field: "name",
            width: 300,
            flex: 1
        },
        {
            headerName: "Brand Owner Name",
            field: "brandOwnerName",
            width: 170,
            valueGetter: (params) => params.row.brand ? params.row.brand.brandOwner : ""
        },
        {
            headerName: "Brand Owner Product Id",
            field: "brandOwnerProductId",
            width: 180
        },
        {
            headerName: "Outer Case Bar Code",
            field: "outerCaseBarcode",
        },
        {
            headerName: "Brand",
            field: "brand",
            valueGetter: ({ value }) => value ? value.name : '',
        },
        {
            headerName: "Vat Code",
            field: "vatCode",
            valueGetter: ({ value }) => value ? value.code : '',
        },
        {
            headerName: "UOM",
            field: "unitOfMeasure",
            valueGetter: ({ value }) => value ? value.name : '',
        },
        {
            headerName: "Product Category",
            field: "category",
            valueGetter: ({ value }) => value ? value.name : '',
        },
        {
            headerName: "Active",
            field: "active",
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
            align: "center",
            headerAlign: "center",
        },
        {
            headerName: "Specification",
            field: "specification",
            width: 200
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} onEditPrice={onEditPrice} />;
            },
        }
    ];
    const orderProduct = [{ text: 'Oldest', value: 'oldest' }, { text: 'Latest', value: 'latest' }];

    const [state, setState] = useState({
        pageLoader: false,
        active_product: null,
        openNewProductModal: false,
        brandListModal: false,
        defaultGrid: {
            pagetitle: "Products",
            productActionButton: true,
            hasExport: true,
            addEditButton: true,
            apiUrl: 'products',
            searchUrl: 'products'
        }
    });

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const { productName, bOProductId, oCBarcode,
        productCategory, productBrand, activeProduct, addProductwarning, showAddEditModal, showAddEditPriceModal, searchProductModal, sortProduct } = useSelector((state) => {
            return {
                productName: state.externalFilter.product_name,
                bOProductId: state.externalFilter.b_o_product_id,
                oCBarcode: state.externalFilter.o_c_barcode,
                productCategory: state.externalFilter.product_category,
                productBrand: state.externalFilter.product_brand,
                activeProduct: state.externalFilter.active_product,
                sortProduct: state.externalFilter.product_sortby,
                resetTable: state.dataGridTable,
                filterFlag: state.externalFilter.filterApplied,
                addProductwarning: state.modalActions.closeWaringAddProduct,
                showAddEditModal: state.modalActions.hideAddEditModal,
                showAddEditPriceModal: state.modalActions.hideAddEditPriceModal,
                searchProductModal: state.commonProducts,
            }
        });

    const dispatch = useDispatch();

    // const handleChange = (checked, name) => {
    //     setState({ ...state, [name]: checked })
    //     dispatch(setFilter({
    //         key: name,
    //         value: checked
    //     }))
    // };

    const handleChange = (checked, name) => {
        // console.log(checked, name, activeProduct);
        // setState({ ...state, [name]: checked });        
        state.active_product = checked;
        dispatch(setFilter({
            key: name,
            value: checked
        }));       
        // console.log(state.active_product, activeProduct); 
        setTimeout(() => {
            filterBtn();
        }, 500);
        
    };

    const handleFilterInput = (value, id) => {
        //setState({ ...state, [id]: value });
        // console.log(value, value.name);
        if(value !== null)
        {
            if(id === 'product_category')
            {
                dispatch(setFilter({
                    key: id,
                    value: value ? value : null
                }))
            }
            else if(id === 'o_c_barcode') {
                dispatch(setFilter({
                    key: id,
                    value: value ? value.toUpperCase() : ''
                }))
            }
            else
            {
                dispatch(setFilter({
                    key: id,
                    value: value ? value : ''
                }))
            }
            
        }
        else
        {
            dispatch(setFilter({
                key: id,
                value: value ? value : (id === 'product_category' ? null : '')
            }))
        }
        
    };

    const selectedAutoComplete = (value, id) => {
        // console.log(value, id);
        dispatch(setFilter({
            key: id,
            value: value ? value : null
        }))
    }

    const filterBtn = () => {
        dispatch(resetDataGrid());
        dispatch(applyFilter({
            key: 'filterList',
            value: {
                "name": productName,
                "brandOwnerProductId": bOProductId,
                "outerCaseBarcode": oCBarcode,
                "category": productCategory,
                "brand": productBrand ? productBrand : null,
                "active": state.active_product,
                "orderBy": sortProduct
            }
        }))
        dispatch(filterFlagChange(true));
        dispatch(filterResetFlagChange(false));
        dispatch(setRowPage({ pageData: 10 }));
        dispatch(setPageNo({ page: 1 }));
    }
    const resetFilter = () => {
        if ((productName || bOProductId || oCBarcode || productCategory || productBrand) !== '' || (activeProduct === false || activeProduct)) {
            dispatch(resetExternalFilter())
            dispatch(resetDataGrid());
            dispatch(setCommonInput(''));
        }
        setState({ ...state, active_product: null })
        dispatch(filterResetFlagChange(true));
        // dispatch(setFilter({
        //     key: 'product_brand',
        //     value: null
        // }))
    }
    const addProductModal = () => {
        dispatch(hasTabEdit({ editTab: true }))
        dispatch(handleAddEditModal({
            showAddEdit: !showAddEditModal
        }))
    }
    const hideNewProductModal = (event) => {
        setState({ ...state, openNewProductModal: false });
    }

    const onEditPrice = (params) => {

        getPriceList(params)

    }
    const getPriceList = async (row) => {
        const { id } = row;
        dispatch(setProductId(id))
        dispatch(handleAddEditPriceModal({
            showAddEditPriceModal: true
        }))
        dispatch(setListOfLevy([]))
        // try {
        //     setState({ ...state, pageLoader: true })
        //     dispatch(setListOfPrice([]))
        //     const { data } = await axios.get(`prices/${id}/getPrices`)
        //     const unique = data.resultMap.result.filter((value, index, self) => self.findIndex((m) => m.id === value.id) === index);
        //     if (data) {

        //         dispatch(handleAddEditPriceModal({
        //             showAddEditPriceModal: true
        //         }))
        //         dispatch(hasTabEdit({ editTab: false }))
        //         dispatch(setListOfPrice(unique))
        //         dispatch(setProductId(id))
        //         setState({ ...state, pageLoader: false })
        //     }

        // } catch (error) {
        //     const { response: { data, status } } = error;
        //     if (!data) {
        //         setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" })
        //     } else {
        //         setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "warning" })
        //     }
        //     dispatch(handleAddEditPriceModal({
        //         showAddEditPriceModal: true
        //     }))
        //     dispatch(hasTabEdit({ editTab: false }))
        //     dispatch(setProductId(id))
        //     setState({ ...state, pageLoader: false })
        // }

    }

    // const hideSupplierbrandModal = (params, reason) => {
    //     dispatch(setFilter({
    //         key: 'product_brand',
    //         value: reason ? reason : null
    //     }))
    //     setState({ ...state, brandListModal: params === false ? params : true })
    // }
    // const resetLookups = () => {
    //     dispatch(setCommonInput(''));
    //     dispatch(setFilter({
    //         key: 'product_brand',
    //         value: null
    //     }))
    // }
    // const CommonPopUpModal = () => {
    //     setState({ ...state, brandListModal: true })
    // }
    if (localStorage.getItem("gotoproduct_flag") === "true")
    {
        dispatch(resetDataGrid());
        let pid = localStorage.getItem("gotoproduct_id");
        let pname = localStorage.getItem("gotoproduct_name");
        let pocbc = localStorage.getItem("gotoproduct_ocbc");

        localStorage.setItem('gotoproduct_flag', false);
        
        dispatch(setFilter({
            key: 'o_c_barcode',
            value: pocbc
        }));

        dispatch(applyFilter({
            key: 'filterList',
            value: {
                "name": productName,
                "brandOwnerProductId": bOProductId,
                "outerCaseBarcode": pocbc,
                "category": productCategory,
                "brand": productBrand ? productBrand : null,
                "active": state.active_product,
                "orderBy": sortProduct
            }
        }))
        dispatch(filterFlagChange(true));
        dispatch(filterResetFlagChange(false));
        dispatch(setRowPage({ pageData: 10 }));
        dispatch(setPageNo({ page: 1 }));
    }

    return (
        <>

            {state.pageLoader && <PageLoader />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Products" />
                <Box sx={{ marginBottom: 2.5 }}>
                    <TableFilters
                        filterFields={<>
                            <Box component="div"><InputText onChange={handleFilterInput} maxWidth={50} label="Product Name" id="product_name" size="small" /></Box>
                            <Box component="div"><InputText onChange={handleFilterInput} maxWidth={50} label="B/O Product Id" id="b_o_product_id" size="small" /></Box>
                            <Box component="div"><InputText onChange={handleFilterInput} maxWidth={50} regex_pattern="hasHyphen" label="O/C Barcode" id="o_c_barcode" size="small" /></Box>
                            <Box component="div"><AutoCompleteStatic endPoint="/v2/categories/parent-dropdown?level=0" onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} id="product_category" label="Product Category" name="product_category" /></Box>
                            {/* <AutoComplete endPoint="categories?filter=name:" onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} id="product_category" label="Product Category" name="product_category" /> */}
                            <Box component="div"><AutoCompleteStatic endPoint="brands/look-up" onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} id="product_brand" label="Brand" name="product_brand" />
                            </Box>
                            {/* <Box component="div">
                                <FormLabel htmlFor="supplier_invoice_member_account" className="fontBold">Brand</FormLabel>
                                <Grid container alignItems="center" sx={{ paddingTop: '5px' }}>
                                    <Grid item xs={12} md={11}>
                                        <Item>
                                            <Box component="div">
                                                <FormControl size={'small'} fullWidth>
                                                    <TextField
                                                        inputProps={{ maxLength: 100 }}
                                                        size="small"
                                                        id="product_brand"
                                                        value={(productBrand ? productBrand.name : '')}
                                                        aria-describedby="product_brand_error"
                                                        name="product_brand"
                                                        disabled={true}
                                                    />

                                                </FormControl>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        <Item>
                                            <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0, marginRight: '5px' }} onClick={resetLookups} startIcon={<ClearIcon />}></Button>
                                            <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={(e) => CommonPopUpModal(e)} startIcon={<EditIcon />}></Button>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box> */}
                            <Box component="div"><SelectDropDown onChange={handleFilterInput} values={orderProduct} size="small" id="product_sortby" label="Order By" name="product_sortby" /></Box>
                        </>}
                        filterCheckboxes={<>
                            <Box component="div">
                                <ThreeStateCheckbox checked={state.active_product} onChange={handleChange} name="active_product" id="active" label="Active" />
                            </Box>
                        </>}
                        filterBtns={
                            <>
                                <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                <Button variant="contained" onClick={filterBtn}>Apply</Button>
                            </>}>
                    </TableFilters>
                </Box>
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -3 } }}>
                    {/* <div className='table-actionable'>
                        <Button variant="contained" color="secondary" size='small' startIcon={<SendIcon />}>
                            Export as Excel
                        </Button>
                    </div> */}
                    <div className='table-actionable'>
                        <Button variant="contained" size='small' onClick={(e) => addProductModal(e)} startIcon={<AddIcon />}>
                            New Product
                        </Button>
                    </div>
                </Box>
                <DataTable defaultGrid={state.defaultGrid} columns={columns}>

                </DataTable>
                {
                    showAddEditModal && (
                        <AddEditProduct tabs={tabs}
                            onShow={showAddEditModal}
                            onHide={(e, reason) => hideNewProductModal(e, reason)}

                        />
                    )
                }
                {
                    showAddEditPriceModal &&
                    <AddEditPriceLeavy tabs={priceTabs}
                        onShow={showAddEditPriceModal}
                    />
                }
                {
                    addProductwarning && (
                        <WarningModal size="small_overlay" onShow={addProductwarning} />
                    )
                }
                {snackBar.show && <SnackBarDialog
                    onShow={snackBar.show}
                    text={snackBar.text}
                    type={snackBar.type}
                    onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                />}

                {/* {
                    state.brandListModal && <CommonPopUp
                        onShow={state.brandListModal}
                        size="large_overlay"
                        brandDetails={{
                            title: 'Brand Details'
                        }}
                        //onHide={hideSupplierbrandModal}
                        onHide={(e, reason) => hideSupplierbrandModal(e, reason)}
                    //memberaccount={getBrandDetail}
                    />
                } */}

            </Container>

        </>
    );
}

export default Products;
