import { Box, Modal, Paper, FormControl, Grid, TextField, FormHelperText, FormLabel, Button, FormControlLabel, Checkbox, Autocomplete } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBrandOwnerModal, warningModal } from "../../../store/slices/modals";
import SnackBarDialog from '../../../components/shared/SnackBarDialog';
import axios_product from '../../../services/utility/axios';
import axiosBrand from '../../../services/utility/axios-brand';
import PageLoader from '../../../components/shared/PageLoader';
import { editProductUpload, editProductUploadModal } from '../../../store/slices/fileUpload';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import axios_utilities from "../../../services/utility/axios-utilities";
import axiosMember from '../../../services/utility/axios-member';
import axiosLevyScheme from '../../../services/utility/axios-levy-scheme';
import axios_categories from '../../../services/utility/axios-categories';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

export default function RetrospectiveFileUploadModal({ onShow, onHide, fileName, onUpdate, fileNameId }) {
    const { fileUpload, handleWarning } = useSelector((state) => {
        return {
            fileUpload: state.productFileUpload,
            handleWarning: state.modalActions.closeWaringAddProduct
        }
    });
    const dispatch = useDispatch();
    const [state, setState] = useState({
        productFileName: fileName,
        fileId: fileNameId,
        //general_brands: (fileUpload.editProductUpload.brandName || fileUpload.editProductUpload.general_brand_id) ? { id: fileUpload.editProductUpload.general_brand_id, name: fileUpload.editProductUpload.brandName } : null,
        general_brand_id: fileUpload.editProductUpload.brandId || "",
        brandOwnerProductId: fileUpload.editProductUpload.brandOwnerProductId || "",
        casePrice: fileUpload.editProductUpload.casePrice || "",
        general_case_quantity: fileUpload.editProductUpload.caseQuantity || "",
        general_category_id: fileUpload.editProductUpload.catId || "",
        discountableForBopa: ((fileUpload.editProductUpload.discountableForBopa === "false" || fileUpload.editProductUpload.discountableForBopa === "N") ? false : true) || false,
        general_ean_code: fileUpload.editProductUpload.eanCode || "",
        endDate: fileUpload.editProductUpload.endDate ? moment(fileUpload.editProductUpload.endDate, 'DD/MM/YYYY') : null,
        errorMessage: fileUpload.editProductUpload.errorMessage || "",
        general_ethical: ((fileUpload.editProductUpload.ethical === "false" || fileUpload.editProductUpload.ethical === "N") ? false : true) || false,
        general_export_td: ((fileUpload.editProductUpload.exportToTd === "false" || fileUpload.editProductUpload.exportToTd === "N") ? false : true) || false,
        general_fair_trade: ((fileUpload.editProductUpload.fairTrade === "false" || fileUpload.editProductUpload.fairTrade === "N") ? false : true) || false,
        id: fileUpload.editProductUpload.id || "",
        newProduct: fileUpload.editProductUpload.newProduct || "",
        general_organic: ((fileUpload.editProductUpload.organic === "false" || fileUpload.editProductUpload.organic === "N") ? false : true) || false,
        general_outer_case_barcode: fileUpload.editProductUpload.outerCaseBarCode || "",
        general_product_name: fileUpload.editProductUpload.productDescription || "",
        result: fileUpload.editProductUpload.result || "",
        general_rsp: fileUpload.editProductUpload.rsp || "",
        specification: fileUpload.editProductUpload.specification || "",
        startDate: fileUpload.editProductUpload.startDate ? moment(fileUpload.editProductUpload.startDate, 'DD/MM/YYYY') : null,
        unit_measure_id: fileUpload.editProductUpload.uomId || "",
        formValid: true,
        productGeneralBrandModal: false,
        levyScheme: fileUpload.editProductUpload && fileUpload.editProductUpload.lsBrandOwnerId ? { id: fileUpload.editProductUpload.lsBrandOwnerId, name: fileUpload.editProductUpload.lsBrandOwnerOn } : null,
        // Post GO Live --
        general_coo: fileUpload.editProductUpload.countryOfOrigin || "",
        general_rt: ((fileUpload.editProductUpload.rt === "false" || fileUpload.editProductUpload.rt === "N" || fileUpload.editProductUpload.rt === "0") ? false : true) || false,
        general_msc: ((fileUpload.editProductUpload.msc === "false" || fileUpload.editProductUpload.msc === "N" || fileUpload.editProductUpload.msc === "0") ? false : true) || false,
        general_rspca: ((fileUpload.editProductUpload.rspca === "false" || fileUpload.editProductUpload.rspca === "N" || fileUpload.editProductUpload.rspca === "0") ? false : true) || false,
        general_fsc: ((fileUpload.editProductUpload.fsc === "false" || fileUpload.editProductUpload.fsc === "N" || fileUpload.editProductUpload.fsc === "0") ? false : true) || false,
        general_rspo: ((fileUpload.editProductUpload.rspo === "false" || fileUpload.editProductUpload.rspo === "N" || fileUpload.editProductUpload.rspo === "0") ? false : true) || false,
    });

    const [isError, setIsError] = useState({
        brandOwnerProductId: false,
        general_product_name: false,
        general_outer_case_barcode: false,
        general_case_quantity: false,
        brand_selected: false,
        measure_selected: false,
        vat_code_selected: false,
        category_selected: false,
        member_tier_selected: false
    })
    const [general_brands, setGeneral_brands] = useState({
        brand_selected: (fileUpload.editProductUpload.brandName || fileUpload.editProductUpload.brandId) ? { id: fileUpload.editProductUpload.brandId, name: fileUpload.editProductUpload.brandName } : null,
        brand_options: [],
    })
    // eslint-disable-next-line max-len
    // const [general_brands, setGeneral_brands] = useState((fileUpload.editProductUpload.brandName || fileUpload.editProductUpload.general_brand_id) ? { id: fileUpload.editProductUpload.general_brand_id, name: fileUpload.editProductUpload.brandName } : null)

    const [unit_measure, setUnit_measure] = useState({
        measure_selected: (fileUpload.editProductUpload.uomName || fileUpload.editProductUpload.unit_measure_id) ? { id: fileUpload.editProductUpload.unit_measure_id, name: fileUpload.editProductUpload.uomName } : null,
        measure_options: []
    })

    const [vat_code, setVat_code] = useState({
        vat_code_selected: (fileUpload.editProductUpload.vatCode || fileUpload.editProductUpload.vatCodeId) ? { id: fileUpload.editProductUpload.vatCodeId, code: fileUpload.editProductUpload.vatCode } : null,
        vat_code_options: []
    })

    const [category, setCategory] = useState({
        category_selected: (fileUpload.editProductUpload.catName || fileUpload.editProductUpload.catId) ? { id: fileUpload.editProductUpload.catId, name: fileUpload.editProductUpload.catName } : null,
        category_options: []
    })

    const [member_tier_list, setMember_tier] = useState({
        member_tier_options: [],
        // eslint-disable-next-line max-len
        member_tier_selected: (fileUpload.editProductUpload.memberTier || fileUpload.editProductUpload.memberTierId) ? { id: fileUpload.editProductUpload.memberTierId, name: fileUpload.editProductUpload.memberTier } : null,
    })

    const [levy_list, setLevy_tier] = useState({
        levy_options: [],
        levy_selected: fileUpload.editProductUpload && fileUpload.editProductUpload.lsBrandOwnerId ? { id: fileUpload.editProductUpload.lsBrandOwnerId, name: fileUpload.editProductUpload.lsBrandOwnerOn } : null
    })

    useEffect(() => {
        const inputNumber = document.querySelectorAll('.inputNumber');
        inputNumber.forEach(el => el.addEventListener('keypress', evt => {
            if (evt.which === 8) {
                return;
            }
            if (evt.which < 42 || evt.which > 60) {
                evt.preventDefault();
            }
        }));
    }, []);

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const handleClose = () => {
        dispatch(editProductUploadModal(false))
    };

    const handleCancel = () => {
        dispatch(warningModal({
            yesHide: !handleWarning
        }))
    }

    const handleInputChange = (event) => {
        const { value, name } = event.target;
        value.length === 0 ? setIsError({ ...isError, [name]: true }) : setIsError({ ...isError, [name]: false })
        if (name === 'casePrice') {
            setState({ ...state, [name]: value.slice(0, 16) })
        } else {

            setState({ ...state, [name]: value })
        }
    }

    const handleOCBCInputChange = (event) => {
        const { value, name } = event.target;
        value.length === 0 ? setIsError({ ...isError, [name]: true }) : setIsError({ ...isError, [name]: false })
        setState({ ...state, [name]: value ? value.toUpperCase() : '' })
    }

    useEffect(() => {
        const fetchMyAPI = async () => {
            if (general_brands.brand_options.length === 0) {
                axiosBrand.get('brands/look-up')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data.resultMap.result.filter((value, index, self) => self.findIndex((m) => m.name === value.name) === index);
                            setGeneral_brands({ ...general_brands, brand_options: unique })
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
            if (vat_code.vat_code_options.length === 0) {
                axios_utilities.get('vatcodes/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            setVat_code({ ...vat_code, vat_code_options: unique })
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
            if (unit_measure.measure_options.length === 0) {
                axios_utilities.get('unitofmeasures/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            setUnit_measure({ ...unit_measure, measure_options: unique })
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
            if (category.category_options.length === 0) {
                axios_categories.get('/v2/categories/parent-dropdown?level=0')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            setCategory({ ...category, category_options: unique })
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
            if (member_tier_list.member_tier_options.length === 0) {
                axiosMember.get('/memberTier/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            setMember_tier({ ...member_tier_list, member_tier_options: unique })
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
        }
        fetchMyAPI();
    }, [])

    useEffect(() => {
        if (member_tier_list.member_tier_selected === null) {
            setIsError({ ...isError, member_tier_selected: true })
        } else {
            setIsError({ ...isError, member_tier_selected: false })
            handleLevy()
        }
    }, [member_tier_list])

    const handleLevy = async () => {
        if (member_tier_list.member_tier_selected !== null) {
            axiosLevyScheme.get(`/levySchemes/memberTier/${member_tier_list.member_tier_selected.id}/drop-down`)
                .then(function (response) {
                    // handle success
                    const { status, data } = response;
                    if (data) {
                        const unique = data
                        setLevy_tier({ ...levy_list, levy_options: unique })
                    }
                })
                .catch(function (error) {
                    // handle error
                    setLevy_tier({ ...levy_list, levy_options: [] })
                });
        }
    }

    useEffect(() => {
        const { general_product_name,
            brandOwnerProductId,
            general_outer_case_barcode,
            general_case_quantity,
            general_brands,
            vat_code_selected,
            measure_selected,
            category_selected,
            member_tier_selected
        } = isError
        if (!general_product_name && !brandOwnerProductId &&
            !general_outer_case_barcode && !general_case_quantity &&
            !general_brands && !vat_code_selected && !measure_selected && !category_selected && !member_tier_selected) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true })
        }
    }, [isError])


    // const hideproductgeneralbrandModal = (params, reason) => {
    //     if (reason) {
    //         setState({ ...state, general_brands: reason, productGeneralBrandModal: params === false ? params : true })
    //         setIsError({ ...isError, general_brands: false })
    //     } else {
    //         setState({ ...state, productGeneralBrandModal: params === false ? params : true })
    //     }
    // }
    // const resetFilter = () => {
    //     setState({ ...state, general_brands: null })
    //     setIsError({ ...isError, general_brands: true })
    // }
    // const CommonPopUpModal = () => {
    //     setState({ ...state, productGeneralBrandModal: true })
    // }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (state.general_product_name === "" && isError.general_product_name === false) {
            setIsError({ ...isError, general_product_name: true })
        } else if (state.brandOwnerProductId === "" && isError.brandOwnerProductId === false) {
            setIsError({ ...isError, brandOwnerProductId: true })
        } else if (state.general_outer_case_barcode === "" && isError.general_outer_case_barcode === false) {
            setIsError({ ...isError, general_outer_case_barcode: true })
        } else if (state.general_case_quantity === "" && isError.general_case_quantity === false) {
            setIsError({ ...isError, general_case_quantity: true })
        } else if (general_brands.brand_selected === null && isError.brand_selected === false) {
            setIsError({ ...isError, brand_selected: true })
        } else if (vat_code.vat_code_selected === null && isError.vat_code_selected === false) {
            setIsError({ ...isError, vat_code_selected: true })
        } else if (unit_measure.measure_selected === null && isError.measure_selected === false) {
            setIsError({ ...isError, measure_selected: true })
        } else if (category.category_selected === null && isError.category_selected === false) {
            setIsError({ ...isError, category_selected: true })
        } else if (member_tier_list.member_tier_selected === null && isError.member_tier_selected === false) {
            setIsError({ ...isError, member_tier_selected: true })
        } else {
            if ((state.startDate !== "" && state.startDate !== null && moment(state.startDate).format('DD/MM/YYYY') === 'Invalid date') || (state.endDate !== "" && state.endDate !== null && moment(state.endDate).format('DD/MM/YYYY') === 'Invalid date')) {
                setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
            }
            else if (state.endDate !== null && state.endDate !== "" && (state.startDate > state.endDate)) {
                setSnackBar({ ...snackBar, show: true, text: "End date is less than Start Date!", type: "error", });
            }
            else {
                try {
                    setState({ ...state, pageLoader: true })
                    const productUpdate = {
                        "fileName": state.productFileName,
                        "id": state.fileId,
                        "productUploadList": [
                            {
                                "brandId": general_brands.brand_selected ? general_brands.brand_selected.id : "",
                                "brandName": general_brands.brand_selected ? general_brands.brand_selected.name : "",
                                "casePrice": state.casePrice ? Number(state.casePrice).toFixed(2) : "",
                                "caseQuantity": state.general_case_quantity ? state.general_case_quantity : null,
                                "catId": category.category_selected ? category.category_selected.id : "",
                                "catName": category.category_selected ? category.category_selected.name : "",
                                "discountableForBopa": state.discountableForBopa,
                                "eanCode": state.general_ean_code,
                                "endDate": (state.endDate === null || state.endDate.toString() === 'Invalid date') ? null : moment(state.endDate).format('DD/MM/YYYY'),
                                "ethical": state.general_ethical,
                                "exportToTd": state.general_export_td,
                                "fairTrade": state.general_fair_trade,
                                "id": state.id,
                                "organic": state.general_organic,
                                "outerCaseBarCode": state.general_outer_case_barcode,
                                "brandOwnerProductId": state.brandOwnerProductId,
                                "productDescription": state.general_product_name,
                                "rsp": state.general_rsp,
                                "specification": state.specification,
                                "startDate": (state.startDate === null || state.startDate.toString() === 'Invalid date') ? null : moment(state.startDate).format('DD/MM/YYYY'),
                                "uomId": unit_measure.measure_selected ? unit_measure.measure_selected.id : "",
                                "uomName": unit_measure.measure_selected ? unit_measure.measure_selected.name : "",
                                "vatCode": vat_code.vat_code_selected ? vat_code.vat_code_selected.code : "",
                                "memberTier": member_tier_list.member_tier_selected ? member_tier_list.member_tier_selected.name : "",
                                "lsBrandOwnerId": levy_list.levy_selected ? levy_list.levy_selected.id : null,
                                "rt": state.general_rt,
                                "msc": state.general_msc,
                                "rspca": state.general_rspca,
                                "fsc": state.general_fsc,
                                "rspo": state.general_rspo,
                                "countryOfOrigin": state.general_coo ? state.general_coo : ""
                            }
                        ]
                    }

                    const { data } = await axios_product.put(`v2/products/importedProduct`, productUpdate);
                    if (data) {
                        setState({ ...state, pageLoader: false })
                        if (onUpdate) {
                            onUpdate({ text: "Product updated!", type: "success" })
                            dispatch(editProductUploadModal(false))
                        }
                    }
                }
                catch (error) {
                    const { response: { data, status } } = error;
                    if (!data) {
                        if (onUpdate) {
                            onUpdate({ text: error.message, type: "error" })
                            dispatch(editProductUploadModal(false))
                        }
                    } else {
                        if (onUpdate) {
                            onUpdate({ text: data.message, type: "error" })
                            dispatch(editProductUploadModal(false))
                        }
                    }
                    setState({ ...state, pageLoader: false })
                }
            }
        }
    }

    const handleSelection = (event, newValue, name) => {
        if (event.target.value !== "" || event.target.value !== null) {
            setState({ ...state, [name]: newValue })
        }
    }

    const handleCheckboxChange = (event) => {
        const { checked, name } = event.target;
        setState({ ...state, [name]: checked })
    };

    const handleStartDate = (event) => {
        setState({ ...state, startDate: event === null ? null : moment(event, 'DD/MM/YYYY'), endDate: null })
    }
    const handleEndDate = (event) => {
        setState({ ...state, endDate: event === null ? null : moment(event, 'DD/MM/YYYY') })
    }
    const autoC = useRef(null);

    const handleDecimal = (event) => {
        const { value, name } = event.target;
        const valueDecimal = value === '' ? "" : (value == 0 ? 0 : Number(value).toFixed(2))
        value.length === 0 ? setIsError({ ...isError, [name]: true }) : setIsError({ ...isError, [name]: false })
        setState({ ...state, [name]: valueDecimal })
    }

    const goTodaypsd = (event) => {
        setState({ ...state, startDate: moment() });
    }

    const goTodayped = (event) => {
        setState({ ...state, endDate: moment() });
    }

    const CustomActionbarpsd = () => {

        return (
            <Box sx={{ marginTop: '-15px', padding: '0 0 10px 0', textAlign: 'center' }}>
                <Button sx={{ fontSize: '16px' }} onClick={(e) => goTodaypsd(e)}>Today</Button>
            </Box>
        );
    };

    const CustomActionbarped = () => {

        return (
            <Box sx={{ marginTop: '-15px', padding: '0 0 10px 0', textAlign: 'center' }}>
                <Button sx={{ fontSize: '16px' }} onClick={(e) => goTodayped(e)}>Today</Button>
            </Box>
        );
    };

    return (
        <>
            <Modal
                open={fileUpload.editProductUploadModal || false}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <Box component="div" className="overlay" id="addEditModal">
                    {state.pageLoader && <PageLoader />}
                    <div className="overlay-box">
                        <div className="content">
                            <div className="overlayBody">
                                <div className="overlay-header text-center"> Edit Product Details
                                    <CancelIcon className="closeBtn" onClick={handleClose} />
                                </div>
                                <div className="overlay-content">
                                    <Box sx={{ flexGrow: 1, paddingX: 2 }} >
                                        <Grid container columnSpacing={2}>
                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.3 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="general_product_name" className="fontSemiBold mandatory">Produt Name</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={7.5}>
                                                            <Item>
                                                                <div className="input-group">
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            inputProps={{ maxLength: 200 }}
                                                                            size="small"
                                                                            id="general_product_name"
                                                                            aria-describedby="general_product_name_error"
                                                                            onChange={(e) => handleInputChange(e)}
                                                                            value={state.general_product_name}
                                                                            name="general_product_name"
                                                                            error={isError.general_product_name}
                                                                        />
                                                                        {isError.general_product_name && (<FormHelperText className='errorHelperTxt' id="general_product_name_error">
                                                                            Please enter the product name
                                                                        </FormHelperText>)}
                                                                    </FormControl>

                                                                </div>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.3 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="brandOwnerProductId" className="fontSemiBold mandatory">Brand Owner Product Id</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={7.5}>
                                                            <Item>
                                                                <div className="input-group">
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            inputProps={{ maxLength: 100 }}
                                                                            size="small"
                                                                            id="brandOwnerProductId"
                                                                            aria-describedby="brandOwnerProductId_error"
                                                                            onChange={(e) => handleInputChange(e)}
                                                                            name="brandOwnerProductId"
                                                                            value={state.brandOwnerProductId}
                                                                            error={isError.brandOwnerProductId}
                                                                        />
                                                                        {isError.brandOwnerProductId && (<FormHelperText className='errorHelperTxt' id="general_product_name_error">
                                                                            Please enter the brand owner product id
                                                                        </FormHelperText>)}
                                                                    </FormControl>
                                                                </div>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        <Grid container columnSpacing={2}>
                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.3 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="general_outer_case_barcode" className="fontSemiBold mandatory">Outer case barcode</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={7.5}>
                                                            <Item>
                                                                <div className="input-group">
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            inputProps={{ maxLength: 100 }}
                                                                            size="small"
                                                                            id="general_outer_case_barcode"
                                                                            aria-describedby="general_outer_case_barcode_error"
                                                                            onChange={(e) => handleOCBCInputChange(e)}
                                                                            value={state.general_outer_case_barcode}
                                                                            name="general_outer_case_barcode"
                                                                            error={isError.general_outer_case_barcode}
                                                                        />
                                                                        {isError.general_outer_case_barcode && (<FormHelperText className='errorHelperTxt' id="general_product_name_error">
                                                                            Please enter the brand owner product id
                                                                        </FormHelperText>)}
                                                                    </FormControl>
                                                                </div>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.3 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="specification" className="fontSemiBold">Specification</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={7.5}>
                                                            <Item>
                                                                <div className="input-group">
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            inputProps={{ maxLength: 255 }}
                                                                            size="small"
                                                                            id="specification"
                                                                            aria-describedby="specification_error"
                                                                            onChange={(e) => handleInputChange(e)}
                                                                            name="specification"
                                                                            value={state.specification}
                                                                        />
                                                                    </FormControl>
                                                                </div>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        <Grid container columnSpacing={2}>
                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.3 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="general_ean_code" className="fontSemiBold">EAN Code</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={7.5}>
                                                            <Item>
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        inputProps={{ maxLength: 50 }}
                                                                        size="small"
                                                                        id="general_ean_code"
                                                                        aria-describedby="general_ean_code_error"
                                                                        onChange={(e) => handleInputChange(e)}
                                                                        value={state.general_ean_code}
                                                                        name="general_ean_code"
                                                                    />
                                                                </FormControl>
                                                            </Item>

                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.3 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="general_rsp" className="fontSemiBold">RSP</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={7.5}>
                                                            <Item>
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        inputProps={{ maxLength: 50 }}
                                                                        type="number"
                                                                        size="small"
                                                                        id="general_rsp"
                                                                        aria-describedby="general_rsp_error"
                                                                        onChange={(e) => handleInputChange(e)}
                                                                        value={state.general_rsp}
                                                                        name="general_rsp"
                                                                        className="inputNumber"
                                                                        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                                                    />
                                                                </FormControl>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        <Grid container columnSpacing={2}>

                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.3, alignSelf: 'center' }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="general_product_name" className="fontSemiBold">&nbsp;</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={7.5}>
                                                            <Item>
                                                                <Grid container direction="row" alignItems="center">
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox size="medium"
                                                                                id="general_export_td"
                                                                                checked={state.general_export_td}
                                                                                onChange={(e) => handleCheckboxChange(e)}
                                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                                name="general_export_td"
                                                                            />}
                                                                        label="Export To TD" />
                                                                </Grid>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.3 }}>
                                                <Item>
                                                    {/* Post GO Live -- */}
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="general_coo" className="fontSemiBold">Country of Origin</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={7.5}>
                                                            <Item>
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        inputProps={{ maxLength: 200 }}
                                                                        size="small"
                                                                        id="general_coo"
                                                                        aria-describedby="general_coo_error"
                                                                        onChange={(e) => handleInputChange(e)}
                                                                        value={state.general_coo}
                                                                        name="general_coo"

                                                                    />
                                                                </FormControl>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        <Grid container columnSpacing={2}>

                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.3 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="general_unit_measure" className="fontSemiBold mandatory">Unit of Measure</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={7.5}>
                                                            <Item>
                                                                <Autocomplete
                                                                    defaultValue={unit_measure.measure_selected ? unit_measure.measure_selected : null}
                                                                    id="general_unit_measure_search"
                                                                    options={unit_measure.measure_options}
                                                                    getOptionLabel={(option) => option.name || ""}
                                                                    componentsProps={{
                                                                        paper: {
                                                                            sx: {
                                                                                fontSize: '0.85rem'
                                                                            }
                                                                        }
                                                                    }}
                                                                    onChange={(event, newValue) => {
                                                                        (isError['measure_selected'] === null || isError['measure_selected'] === "") ? setIsError({ ...isError, measure_selected: true }) : setIsError({ ...isError, measure_selected: false })
                                                                        if (event.target.value !== "" || event.target.value !== null) {
                                                                            setUnit_measure({ ...unit_measure, measure_selected: newValue })
                                                                        }
                                                                    }}
                                                                    renderInput={(params) =>
                                                                        <FormControl size={'small'} fullWidth>
                                                                            <div className="input-group">
                                                                                <TextField {...params}
                                                                                    size="small"
                                                                                    id="general_unit_measure"
                                                                                    aria-describedby="general_unit_measure-error"
                                                                                    error={isError.measure_selected}
                                                                                />
                                                                                {isError.measure_selected && (<FormHelperText className='errorHelperTxt' id="general_product_name_error">
                                                                                    Please select Unit of Measure
                                                                                </FormHelperText>)}
                                                                            </div>
                                                                        </FormControl>
                                                                    }
                                                                />
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.3 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="general_brand" className="fontSemiBold mandatory">Brand</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={7.5}>
                                                            <Item>
                                                                <Autocomplete
                                                                    defaultValue={general_brands.brand_selected ? general_brands.brand_selected : null}
                                                                    id="general_brands_search"
                                                                    options={general_brands.brand_options}
                                                                    getOptionLabel={(option) => option.name || ""}
                                                                    componentsProps={{
                                                                        paper: {
                                                                            sx: {
                                                                                fontSize: '0.85rem'
                                                                            }
                                                                        }
                                                                    }}
                                                                    onChange={(event, newValue) => {
                                                                        (isError['brand_selected'] === null || isError['brand_selected'] === "") ? setIsError({ ...isError, brand_selected: true }) : setIsError({ ...isError, brand_selected: false })
                                                                        if (event.target.value !== "" || event.target.value !== null) {
                                                                            setGeneral_brands({ ...general_brands, brand_selected: newValue })
                                                                        }
                                                                    }}
                                                                    renderInput={(params) =>
                                                                        <FormControl size={'small'} fullWidth>
                                                                            <div className="input-group">
                                                                                <TextField {...params}
                                                                                    size="small"
                                                                                    id="general_brand"
                                                                                    aria-describedby="general_brand-error"
                                                                                    error={isError.brand_selected}
                                                                                />
                                                                                {isError.brand_selected && (<FormHelperText className='errorHelperTxt' id="general_product_name_error">
                                                                                    Please select Brand
                                                                                </FormHelperText>)}
                                                                            </div>
                                                                        </FormControl>
                                                                    }

                                                                />
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                            {/* <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                                                <Item sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="general_brand" className="fontSemiBold mandatory">Brand</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Item>
                                                                <Box component="div" className=''>
                                                                    <FormControl size={'small'} fullWidth>
                                                                        <TextField
                                                                            inputProps={{ maxLength: 100 }}
                                                                            size="small"
                                                                            id="general_brand"
                                                                            value={state.general_brands ? state.general_brands.name : ''}
                                                                            aria-describedby="general_brand_error"
                                                                            name="general_brand"
                                                                            disabled={true}
                                                                            error={isError.general_brands}
                                                                        />
                                                                        {isError.general_brands &&
                                                                            (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)}
                                                                    </FormControl>
                                                                </Box>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={1.5}>
                                                            <Item>
                                                                <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0, marginRight: '5px' }} onClick={resetFilter} startIcon={<ClearIcon />}></Button>
                                                                <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={(e) => CommonPopUpModal(e)} startIcon={<EditIcon />}></Button>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid> */}
                                        </Grid>

                                        <Grid container columnSpacing={2}>
                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.3 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="general_category" className="fontSemiBold mandatory">Category</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={7.5}>
                                                            <Item>
                                                                <Autocomplete
                                                                    defaultValue={category.category_selected ? category.category_selected : null}
                                                                    id="general_category_search"
                                                                    options={category.category_options}
                                                                    getOptionLabel={(option) => option.name || ""}
                                                                    componentsProps={{
                                                                        paper: {
                                                                            sx: {
                                                                                fontSize: '0.85rem'
                                                                            }
                                                                        }
                                                                    }}
                                                                    onChange={(event, newValue) => {
                                                                        (isError['category_selected'] === null || isError['category_selected'] === "") ? setIsError({ ...isError, category_selected: true }) : setIsError({ ...isError, category_selected: false })
                                                                        if (event.target.value !== "" || event.target.value !== null) {
                                                                            setCategory({ ...category, category_selected: newValue })
                                                                        }
                                                                    }}
                                                                    renderInput={(params) =>
                                                                        <FormControl size={'small'} fullWidth>
                                                                            <div className="input-group">
                                                                                <TextField {...params}
                                                                                    size="small"
                                                                                    id="general_category"
                                                                                    aria-describedby="general_category-error"
                                                                                    error={isError.category_selected}
                                                                                />
                                                                                {isError.category_selected && (<FormHelperText className='errorHelperTxt' id="general_product_name_error">
                                                                                    Please select category
                                                                                </FormHelperText>)}
                                                                            </div>
                                                                        </FormControl>
                                                                    }
                                                                />
                                                            </Item>

                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.3 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="general_vat_code" className="fontSemiBold mandatory">Vat Code</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={7.5}>
                                                            <Item>
                                                                <Autocomplete
                                                                    defaultValue={vat_code.vat_code_selected ? vat_code.vat_code_selected : null}
                                                                    id="general_vat_code_search"
                                                                    options={vat_code.vat_code_options}
                                                                    getOptionLabel={(option) => option.code || ""}
                                                                    componentsProps={{
                                                                        paper: {
                                                                            sx: {
                                                                                fontSize: '0.85rem'
                                                                            }
                                                                        }
                                                                    }}
                                                                    onChange={(event, newValue) => {
                                                                        (isError['vat_code_selected'] === null || isError['vat_code_selected'] === "") ? setIsError({ ...isError, vat_code_selected: true }) : setIsError({ ...isError, vat_code_selected: false })
                                                                        if (event.target.value !== "" || event.target.value !== null) {
                                                                            setVat_code({ ...vat_code, vat_code_selected: newValue })
                                                                        }
                                                                    }}
                                                                    renderInput={(params) =>
                                                                        <FormControl size={'small'} fullWidth>
                                                                            <div className="input-group">
                                                                                <TextField {...params}
                                                                                    size="small"
                                                                                    id="general_vat_code"
                                                                                    aria-describedby="general_vat_code-error"
                                                                                    error={isError.vat_code_selected}
                                                                                />
                                                                                {isError.vat_code_selected && (<FormHelperText className='errorHelperTxt' id="general_product_name_error">
                                                                                    Please select category
                                                                                </FormHelperText>)}
                                                                            </div>
                                                                        </FormControl>
                                                                    }

                                                                />
                                                            </Item>

                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        <Grid container columnSpacing={2}>
                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.3 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="general_case_quantity" className="fontSemiBold  mandatory">Case Quantity</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={7.5}>
                                                            <Item>
                                                                <div className="input-group">
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            inputProps={{ maxLength: 50 }}
                                                                            type="number"
                                                                            size="small"
                                                                            id="general_case_quantity"
                                                                            aria-describedby="general_case_quantity_error"
                                                                            onChange={(e) => handleInputChange(e)}
                                                                            value={state.general_case_quantity}
                                                                            name="general_case_quantity"
                                                                            className="inputNumber"
                                                                            error={isError.general_case_quantity}
                                                                            onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                                                        />
                                                                        {isError.general_case_quantity && (<FormHelperText className='errorHelperTxt' id="general_product_name_error">
                                                                            Please select quantity
                                                                        </FormHelperText>)}
                                                                    </FormControl>
                                                                </div>
                                                            </Item>

                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.3 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="casePrice" className="fontSemiBold">Price</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={7.5}>
                                                            <Item>
                                                                <div className="input-group">
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            inputProps={{ maxLength: 100 }}
                                                                            size="small"
                                                                            id="casePrice"
                                                                            aria-describedby="casePrice_error"
                                                                            onChange={(e) => handleInputChange(e)}
                                                                            name="casePrice"
                                                                            value={state.casePrice}
                                                                            type="number"
                                                                            step="any"
                                                                            onBlur={(e) => handleDecimal(e)}
                                                                            onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                                                        />
                                                                    </FormControl>
                                                                </div>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        <Grid container columnSpacing={2}>
                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.3 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="startDate" className="fontSemiBold">Price From</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={7.5}>
                                                            <Item>
                                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                    <DesktopDatePicker
                                                                        value={state.startDate}
                                                                        components={{ ActionBar: CustomActionbarpsd }}
                                                                        onChange={(e) => { handleStartDate(e) }}
                                                                        name="startDate"
                                                                        inputFormat="DD/MM/YYYY"
                                                                        disablePast
                                                                        shouldDisableDate={(dateParam) => {
                                                                            //your_condition here
                                                                            //return true to disabled and false to enable
                                                                            const fromFormatted = moment().format('DD/MM/YYYY');
                                                                            const currentDate = moment(dateParam).format('DD/MM/YYYY');

                                                                            return fromFormatted === currentDate ? true : false;
                                                                        }}
                                                                        // eslint-disable-next-line max-len
                                                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                            <Box component="div" className='date-picker-input'>
                                                                                <FormControl size={'small'} fullWidth>
                                                                                    <TextField size="small" id="startDate"
                                                                                        ref={inputRef} {...inputProps}
                                                                                        placeholder=""
                                                                                        aria-describedby="startDate_error"
                                                                                        autoComplete="off"

                                                                                    />
                                                                                    <Box className='date-picker-icon'>
                                                                                        {InputProps?.endAdornment}
                                                                                    </Box>
                                                                                </FormControl>
                                                                            </Box>
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.3 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="endDate" className="fontSemiBold">End Date</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={7.5}>
                                                            <Item>
                                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                    <DesktopDatePicker
                                                                        value={state.endDate}
                                                                        components={{ ActionBar: CustomActionbarped }}
                                                                        onChange={(e) => { handleEndDate(e) }}
                                                                        name="endDate"
                                                                        inputFormat="DD/MM/YYYY"
                                                                        minDate={moment(state.startDate).add(1, 'days')}
                                                                        // eslint-disable-next-line max-len
                                                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                            <Box component="div" className='date-picker-input'>
                                                                                <FormControl size={'small'} fullWidth>
                                                                                    <TextField size="small" id="endDate"
                                                                                        ref={inputRef} {...inputProps}
                                                                                        placeholder=""
                                                                                        aria-describedby="endDate_error"
                                                                                        autoComplete="off"
                                                                                    />
                                                                                    <Box className='date-picker-icon'>
                                                                                        {InputProps?.endAdornment}
                                                                                    </Box>
                                                                                </FormControl>
                                                                            </Box>
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>

                                        <Grid container columnSpacing={2}>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="general_member_tier" className="fontSemiBold mandatory">Purchasing Tier</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={7.5}>
                                                            <Item>
                                                                <Autocomplete
                                                                    defaultValue={member_tier_list.member_tier_selected ? member_tier_list.member_tier_selected : null}
                                                                    id="general_member_tier_search"
                                                                    options={member_tier_list.member_tier_options}
                                                                    getOptionLabel={(option) => option.name || ""}
                                                                    componentsProps={{
                                                                        paper: {
                                                                            sx: {
                                                                                fontSize: '0.85rem'
                                                                            }
                                                                        }
                                                                    }}
                                                                    onChange={(event, newValue) => {
                                                                        if (event.target.value !== "" || event.target.value !== null) {
                                                                            setMember_tier({ ...member_tier_list, member_tier_selected: newValue })
                                                                            setState({ ...state, levyScheme: null })

                                                                            if (!(member_tier_list.member_tier_selected && newValue && (member_tier_list.member_tier_selected.name === newValue.name))) {
                                                                                if (autoC.current) {
                                                                                    // eslint-disable-next-line prefer-destructuring
                                                                                    const ele = autoC.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
                                                                                    // eslint-disable-next-line prefer-destructuring
                                                                                    const ele2 = autoC.current.getElementsByClassName('MuiOutlinedInput-input')[0];
                                                                                    if (ele) {
                                                                                        ele.click();
                                                                                        ele2.blur()

                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }}
                                                                    renderInput={(params) =>
                                                                        <FormControl size={'small'} fullWidth>
                                                                            <div className="input-group">
                                                                                <TextField {...params}
                                                                                    size="small"
                                                                                    id="general_member_tier"
                                                                                    aria-describedby="general_member_tier-error"
                                                                                    error={isError.member_tier_selected}
                                                                                />
                                                                                {isError.member_tier_selected &&
                                                                                    (<FormHelperText className='errorHelperTxt' id="general_member_tier-error"> This field is mandatory </FormHelperText>)}
                                                                            </div>
                                                                        </FormControl>
                                                                    }
                                                                />
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="general_member_tier" className="fontSemiBold">Levy Scheme (Not applicable for existing products)</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={7.5}>
                                                            <Item>
                                                                <Autocomplete
                                                                    ref={autoC}
                                                                    disabled={member_tier_list.member_tier_selected === null}
                                                                    defaultValue={fileUpload.editProductUpload && fileUpload.editProductUpload.lsBrandOwnerId ? state.levyScheme : null}
                                                                    id="general_levy_search"
                                                                    options={levy_list.levy_options}
                                                                    getOptionLabel={(option) => option.name || ""}
                                                                    componentsProps={{
                                                                        paper: {
                                                                            sx: {
                                                                                fontSize: '0.85rem'
                                                                            }
                                                                        }
                                                                    }}
                                                                    onChange={(event, newValue) => {

                                                                        if (event.target.value !== "" || event.target.value !== null) {
                                                                            setLevy_tier({ ...levy_list, levy_selected: newValue })
                                                                        }
                                                                    }}
                                                                    renderInput={(params) =>
                                                                        <FormControl size={'small'} fullWidth>
                                                                            <div className="input-group">
                                                                                <TextField {...params}
                                                                                    size="small"
                                                                                    id="general_levy"
                                                                                    aria-describedby="general_levy-error"
                                                                                />
                                                                            </div>
                                                                        </FormControl>
                                                                    }
                                                                />
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        <Grid container columnSpacing={2} sx={{ marginTop: '5px' }}>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.5} sx={{ textAlign: 'right', display: { xs: 'none', md: 'block' } }}>
                                                            <Item>
                                                                &nbsp;
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={7.5}>
                                                            <Item>
                                                                <Grid container direction="row" alignItems="center" >
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox size="medium"
                                                                                id="general_fair_trade"
                                                                                checked={state.general_fair_trade}
                                                                                onChange={(e) => handleCheckboxChange(e)}
                                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                                name="general_fair_trade"
                                                                            />}
                                                                        label="Fair Trade" />
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox size="medium"
                                                                                id="general_organic"
                                                                                checked={state.general_organic}
                                                                                onChange={(e) => handleCheckboxChange(e)}
                                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                                name="general_organic"
                                                                            />}
                                                                        label="Organic" />
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox size="medium"
                                                                                id="general_ethical"
                                                                                checked={state.general_ethical}
                                                                                onChange={(e) => handleCheckboxChange(e)}
                                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                                name="general_ethical"
                                                                            />}
                                                                        label="Ethical" />
                                                                    {/* Post GO Live -- */}
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox size="medium"
                                                                                id="general_fsc"
                                                                                checked={state.general_fsc}
                                                                                onChange={(e) => handleCheckboxChange(e)}
                                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                                name="general_fsc"
                                                                            />}
                                                                        label="FSC" />

                                                                </Grid>

                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.5} sx={{ textAlign: 'right', display: { xs: 'none', md: 'block' } }}>
                                                            <Item>
                                                                &nbsp;
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={7.5}>
                                                            <Item>
                                                                <Grid container direction="row" alignItems="center" >
                                                                    {/* <FormControlLabel
                                                                        control={
                                                                            <Checkbox size="medium"
                                                                                id="onTrade"
                                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                                name="onTrade"
                                                                                checked={state.onTrade}
                                                                                onChange={(e) => handleCheckboxChange(e)}
                                                                            />}
                                                                        label="On Trade" /> */}
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox size="medium"
                                                                                id="discountableForBopa"
                                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                                name="discountableForBopa"
                                                                                checked={state.discountableForBopa}
                                                                                onChange={(e) => handleCheckboxChange(e)}
                                                                            />}
                                                                        label="Discountable for Bopa" />
                                                                </Grid>

                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        {/* Post GO Live -- */}
                                        <Grid container columnSpacing={2} sx={{ marginTop: '5px' }}>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.5} sx={{ textAlign: 'right', display: { xs: 'none', md: 'block' } }}>
                                                            <Item>
                                                                <FormLabel htmlFor="general_rt" className="fontSemiBold">UK Assured</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={7.5}>
                                                            <Item>
                                                                <Grid container direction="row" alignItems="center" >
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox size="medium"
                                                                                id="general_rt"
                                                                                checked={state.general_rt}
                                                                                onChange={(e) => handleCheckboxChange(e)}
                                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                                name="general_rt"
                                                                            />}
                                                                        label="RT" />
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox size="medium"
                                                                                id="general_msc"
                                                                                checked={state.general_msc}
                                                                                onChange={(e) => handleCheckboxChange(e)}
                                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                                name="general_msc"
                                                                            />}
                                                                        label="MSC" />
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox size="medium"
                                                                                id="general_rspca"
                                                                                checked={state.general_rspca}
                                                                                onChange={(e) => handleCheckboxChange(e)}
                                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                                name="general_rspca"
                                                                            />}
                                                                        label="RSPCA" />

                                                                </Grid>

                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.5} sx={{ textAlign: 'right', display: { xs: 'none', md: 'block' } }}>
                                                            <Item>
                                                                <FormLabel htmlFor="general_rspo" className="fontSemiBold">Palm Oil</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={7.5}>
                                                            <Item>
                                                                <Grid container direction="row" alignItems="center" >
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox size="medium"
                                                                                id="general_rspo"
                                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                                name="general_rspo"
                                                                                checked={state.general_rspo}
                                                                                onChange={(e) => handleCheckboxChange(e)}
                                                                            />}
                                                                        label="RSPO" />
                                                                </Grid>

                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        <Grid container sx={{ marginTop: '10px' }}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Item>
                                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                                        <LoadingButton
                                                            onClick={(e) => { handleSubmit(e) }}
                                                            loading={state.loader}
                                                            loadingPosition="center"
                                                            endIcon=""
                                                            variant="contained"
                                                            type="submit" sx={{ marginRight: '15px' }}
                                                            disabled={state.formValid}
                                                        >
                                                            Save &amp; Validate
                                                        </LoadingButton>
                                                        <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div>
                    {snackBar.show && <SnackBarDialog
                        onShow={snackBar.show}
                        text={snackBar.text}
                        type={snackBar.type}
                        onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                    />}
                    {/* {
                        state.productGeneralBrandModal && <CommonPopUp
                            onShow={state.productGeneralBrandModal}
                            size="large_overlay"
                            brandDetails={{
                                title: 'Brand Details'
                            }}
                            onHide={(e, reason) => hideproductgeneralbrandModal(e, reason)}
                        />
                    } */}
                </Box>
            </Modal>
        </>
    );
}
