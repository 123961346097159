import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editTab: true,
    editData: null,
    hasEditData: null,
    sma_code: '',
    smaSupplierNameOnModal: '',
    smaMemberCodeOnModal: '',
    sma_supplier_name: '',
    sma_member_name:'',
    sma_lists: [],
    sma_supplier: {
        supplier_code_options: [],
        sma_supplier_name: null,
    },
    sma_member: {
        member_code_options: [],
        sma_member_name: null,
    },
    sma_member_account: {
        sma_member_account_options: [],
        sma_member_account_selected: null
    },
    sma_member_outlet: {
        sma_member_outlet_options: [],
        sma_member_outlet_selected: null
    },
    sma_status: true,
    sma_StartSingleDelete: false,
    sma_ConfirmSingleDelete: false,
    deleteList: null,
    isError: {
        sma_code: false,
        smaSupplierNameOnModal: true,
        smaMemberCodeOnModal: true,
        sma_supplier_name: false,
        sma_member_name: false,
        sma_member_account_selected: false,
        sma_member_outlet_selected: false
      }  
}

const sma = createSlice({
    name: 'supplierMemberAccounts',
    initialState,
    reducers: {
        brandSearch: (state, action) => {
            const { parent, key, value } = action.payload;
            state[parent][key] = value;
            (state[parent][key] === null || state[parent][key] === "") ? state.isError[key] = true : state.isError[key] = false
        },
        smaInput: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value;
            state[key].length === 0 ? state.isError[key] = true : state.isError[key] = false
        },
        getIsError: (state, action) => {
            const { key, value } = action.payload;
            state.isError[key] = value
        },
        hasEdit: (state, action) => {
            const { editTab } =  action.payload;
            state.editTab = editTab
        },
        hasEditData: (state, action) => {
            const { editData } =  action.payload;
            state.editData = editData
        },
        setHasEditData: (state, action) => {
            state.hasEditData = action.payload;
        },
        setAutocomplete: (state, action) => {
            const { parent, key, value } =  action.payload;
            state[parent][key] = value
        },
        setMemberCode: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value
        },
        setSupplierName: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value
        },
        setSmaList: (state, action) => {
            state.sma_lists = action.payload
        },
        setSmaDeleteData: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value;
        },
        setDeleteList: (state, action) => {
            state.deleteList = action.payload
        },
        resetSMA: () => {
            return initialState
        },
    }
})

export const { brandSearch, smaInput, resetSMA, setAutocomplete, getIsError, hasEdit, hasEditData, 
    setHasEditData, setMemberCode, setSupplierName, setSmaList, setSmaDeleteData, setDeleteList} = sma.actions

export default sma.reducer