
const menus = [
  {
    "link_name": "Products",
    "submenu": [
      {
        "link_name": "Products",
        "link_path": "/products/products"
      },
      {
        "link_name": "Product Distribution Channel",
        "link_path": "/products/product-distribution-channel"
      },
      {
        "link_name": "Imports Products",
        "link_path": "/products/file-upload"
      },
      {
        "link_name": "Imports PDC Prices",
        "link_path": "/products/pdc-file-upload"
      },
      {
        "link_name": "Brands",
        "link_path": "/products/brands"
      },
      {
        "link_name": "Categories",
        "link_path": "/products/categories"
      }
    ]
  },
  {
    "link_name": "Suppliers & Members",
    "submenu": [
      {
        "link_name": "Suppliers",
        "link_path": "/suppliers-and-members/suppliers"
      },
      {
        "link_name": "Members",
        "link_path": "/suppliers-and-members/members"
      },
      {
        "link_name": "Supplier Member Accounts",
        "link_path": "/suppliers-and-members/accounts"
      },
      {
        "link_name": "Aggregation Group",
        "link_path": "/suppliers-and-members/aggregation-group"
      },
      {
        "link_name": "Purchasing Tiers",
        "link_path": "/suppliers-and-members/purchasing-tiers"
      },
      {
        "link_name": "Organisation Type",
        "link_path": "/suppliers-and-members/organisation-type"
      }
    ]
  },
  {
    "link_name": "Invoices",
    "submenu": [
      {
        "link_name": "Invoice Summary",
        "link_path": "/invoices/summary"
      },
      {
        "link_name": "Invoice Reprocess",
        "link_path": "/invoices/reprocess"
      },
      {
        "link_name": "Manual Invoice Entry",
        "link_path": "/invoices/manual-entry"
      },
      {
        "link_name": "EDI File Log",
        "link_path": "/invoices/edi-file-log"
      },
      {
        "link_name": "Invoice processing - Failed files",
        "link_path": "/invoices/failed-files"
      },
      {
        "link_name": "Failed Imports",
        "link_path": "/invoices/failed-imports"
      }
    ]
  },
  {
    "link_name": "Statement Cycles",
    "submenu": [
      {
        "link_name": "Statement Cycles",
        "link_path": "/statement-cycles/statement-cycles"
      },
      {
        "link_name": "Distribution Channel",
        "link_path": "/statement-cycles/distribution-channel"
      }
    ]
  },
  {
    "link_name": "Schemes",
    "submenu": [
      {
        "link_name": "Discount Schemes",
        "link_path": "/schemes/discount-schemes"
      },
      {
        "link_name": "Retrospective Discount Schemes",
        "link_path": "/schemes/retrospective-discount-schemes"
      },
      {
        "link_name": "Retrospective Discount Schemes Outlet Exclusions",
        "link_path": "/schemes/retrospective-discount-schemes-outlet-exclusions"
      },
      {
        "link_name": "Levy Schemes",
        "link_path": "/schemes/levy-schemes"
      },
      {
        "link_name": "Programmes",
        "link_path": "/schemes/programmes"
      },
       {
         "link_name": "Scheme Upload",
         "link_path": "/schemes/file-upload"
       },
      {
        "link_name": "Promotion Type",
        "link_path": "/schemes/promotion-type"
      }
    ]
  },
  {
    "link_name": "Reports",
    "submenu": [
      {
        "link_name": "NUSSL Report",
        "link_path": "/output/nussl-report"
      },
      {
        "link_name": "Supplier Reports",
        "link_path": "/output/supplier-reports"
      },
      {
        "link_name": "Member Reports",
        "link_path": "/output/member-reports"
      },
      {
        "link_name": "Reports",
        "link_path": "/output/reports"
      },
      {
        "link_name": "Accounting Entries",
        "link_path": "/output/accounting-entries"
      },
      {
        "link_name": "Process Manager",
        "link_path": "/management/process-manager"
      }
    ]
  },
  {
    "link_name": "Management",
    "submenu": [
      {
        "link_name": "Standing Data",
        "link_path": "/management/standing-data"
      },
      {
        "link_name": "Central Billing Schemes",
        "link_path": "/management/central-billing-schemes"
      },
      {
        "link_name": "Vat codes",
        "link_path": "/management/vat-codes"
      },

      {
        "link_name": "Unit of Measure",
        "link_path": "/products/unit-of-measure"
      }
    ]
  },
  {
    "link_name": "Logs",
    "submenu": [
      // {
      //   "link_name": "Performance Logs",
      //   "link_path": "/logs/performance-logs"
      // },
      // {
      //   "link_name": "Failure Logs",
      //   "link_path": "/logs/failure-logs"
      // },
      {
        "link_name": "Server Process History",
        "link_path": "/logs/server-process-history"
      },
      // {
      //   "link_name": "Change Log",
      //   "link_path": "/logs/change-log"
      // }
    ]
  }
]
export default menus