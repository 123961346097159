import { useEffect, useLayoutEffect, useState } from "react";
import {
    Grid, Paper, Box, FormLabel, Button, Autocomplete, Typography, 
    TextField, FormHelperText, Checkbox, FormControlLabel, FormControl, Alert, Snackbar, Modal, MenuItem, IconButton, Menu, Stack, Select, Pagination, PaginationItem
} from "@mui/material";
import { styled } from '@mui/material/styles';
import { warningModal, handleAddEditModal, handleModalState } from "../../../../store/slices/modals";
import { useDispatch, useSelector } from 'react-redux';
import { hasTabEdit } from "../../../../store/slices/generalProduct";
import axios_product from "../../../../services/utility/axios";
import axios_utilities from "../../../../services/utility/axios-utilities";
import axiosRetroDiscountSchemes from "../../../../services/utility/axios-retro-discount-schemes";
import SnackBarDialog from "../../../../components/shared/SnackBarDialog";
import { autoCompleteSearch, retrodiscountschemesInput, setRetroDates, getIsError, hasTabEditData, setRetroFormStatus, setRetroState, rowDeleteData, rowDeleteYes } from "../../../../store/slices/retrodiscountschemes";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { DataTableClientSide } from "../../../../components/shared/data-table/DataTableClientSide";
import PageLoader from "../../../../components/shared/PageLoader";
import AddIcon from '@mui/icons-material/Add';
import AddEditPayDate from "../modals/AddEditPayDate";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { DataGridPremium, gridPageCountSelector, gridPageSelector, gridVisibleSortedRowIdsSelector, useGridApiContext, useGridSelector } from "@mui/x-data-grid-premium";

const IconMenuLink = ({ params, onEdit, onDelete }) => {
    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'edit') {
            if (onEdit) {
                onEdit(row)
            }
        } else if (action === 'delete') {
            if (onDelete) {
                onDelete(row)
            }
        }
        setAnchorEl(null);
    };


    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            {/* <MenuItem onClick={(e) => handleClose(e, 'edit')}>Edit</MenuItem> */}
            <MenuItem onClick={(e) => handleClose(e, 'delete')}>Delete</MenuItem>
        </Menu>
    </>)
}


const RetroPayMemberDates = () => {
    const [state, setState] = useState({
        price: '',
        pageLoader: false,
        defaultGrid: {
            paginationMode: 'client',
        },
        handlePayDateModal: false,
        isLoading: false,
        data: [],
        total: 0,
        page: 1,
        pageSize: 10,
        applyFilterCount: null
    });

    function CustomPagination() {
        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        const rowDataList = [10, 25, 50, 75, 100]

        const handleChange = (event) => {
            setState({ ...state, pageSize: event.target.value })
        };

        const paginationChange = (event, value) => {
            apiRef.current.setPage(value - 1);
            setState({ ...state, page: value })
        }

        return (
            <>
                <div className='grid-pagiation'>
                    <Box className='customRowPerPageBox' component="div">
                        <div>
                            View
                        </div>
                        <Box component="div" sx={{ marginX: '10px' }}>
                            <FormControl>
                                <Select
                                    size="small"
                                    id="row-per-page"
                                    defaultValue="choose"
                                    onChange={handleChange}
                                    value={state.pageSize}
                                    aria-describedby={'row-per-page'} >
                                    {rowDataList.map((value) => {
                                        return (
                                            <MenuItem value={value} key={value}>{value}</MenuItem>
                                        )
                                    }

                                    )}
                                </Select>

                            </FormControl>
                        </Box>
                        <div>
                            rows per page
                        </div>
                    </Box>
                    <Pagination
                        variant="outlined"
                        shape="rounded"
                        count={pageCount}
                        page={page + 1}
                        onChange={(event, value) => paginationChange(event, value)}
                        renderItem={(item) => (
                            <PaginationItem
                                components={{ previous: KeyboardArrowLeftIcon, next: KeyboardArrowRightIcon }}
                                {...item}
                            />
                        )}
                    />
                </div>
            </>
        );
    }

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })
    const columns = [
        {
            headerName: "Id",
            field: "id",
            minWidth: 200,
            flex: 1,
            hide: true
        },
        {
            headerName: "Result",
            field: "result",
            width: 80,
        },
        {
            headerName: "Error Message",
            field: "errorMessage",
            width: 400,
            flex: 1,
            // Using renderCell to remove brackets and display the error message
            renderCell: ({ value }) => {
                // Convert the array to a string, join if needed, and remove brackets
                if (Array.isArray(value)) {
                    return value.join(" ").replace(/\[|\]/g, ""); // Replace square brackets with empty strings
                }
                return ""; // Return empty if no error
            },
        },
        {
            headerName: "Type",
            field: "type",
            minWidth: 200
        },

        {
            headerName: "Due Date",
            field: "dueDate",
            minWidth: 200
        },
        // {
        //     headerName: "Run Date",
        //     field: "runDate",
        //     minWidth: 200
        // },
        // {
        //     headerName: "Confirmed",
        //     field: "confirmed",
        //     renderCell: (params) => (
        //         <Checkbox
        //             checked={params.value === true}
        //             icon={<CircleIcon />}
        //             checkedIcon={<CheckCircleIcon />}
        //             color="chk_rounded"
        //             size='small'
        //             style={{ pointerEvents: 'none' }}
        //         />
        //     ),
        //     align: 'center',
        //     minWidth: 200
        // },
        // {
        //     headerName: "Proccess Id",
        //     field: "serverProcessEntry",
        //     minWidth: 200
        // },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                const { row } = params
                if (row.confirmed === true) {
                    return ""
                }
                return <IconMenuLink params={params} onEdit={onEdit} onDelete={onDelete} />
            }
        }
    ];
    const {
        retro_fields,
        addEditPayDateModal,
        handleWarning
    } = useSelector((state) => {
        return {
            retro_fields: state.retrodiscountscheme,
            addEditPayDateModal: state.modalActions.addEditPayDateModal,
            handleWarning: state.modalActions.closeWaringAddProduct
        }
    });


    const dispatch = useDispatch();



    const onEdit = (row) => {
        dispatch(handleModalState({
            key: 'addEditPayDateModal',
            value: true
        }))
        dispatch(setRetroState({
            key: 'hasEditPayDate',
            value: row
        }))
    }

    const onDelete = (row) => {
        dispatch(rowDeleteData(row))
        dispatch(rowDeleteYes({
            key: 'starteDelete',
            value: true
        }))
        dispatch(warningModal({
            yesHide: !handleWarning
        }))
    }
    useEffect(() => {
        if (retro_fields.confirmDelete) {
            deleteRow()
        }
    }, [retro_fields.confirmDelete])

    const deleteRow = async () => {
        try {
            setState({ ...state, pageLoader: true })
            const id = (retro_fields.hasEditData) ? (retro_fields.hasEditData.id) : retro_fields.editTabData.id
            const { data } = await axiosRetroDiscountSchemes.delete(`/ImportedRetroDiscount/payMemberDate/${retro_fields.rowDelete.id}`, { });
            // const { data } = await axiosRetroDiscountSchemes.delete(`retrodiscount/${id}/deletepaymemberdates`, { data: retro_fields.rowDelete });
            if (data) {
                if ((retro_fields.hasEditData !== null || retro_fields.editTabData !== null)) {
                    setState({ ...state, isLoading: true })
                    getPayMemberDatesList(id)
                }
                setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
                deleteRowDispatch()
            }

        } catch (error) {
            const { response: { data, status } } = error;
            if (!data) {
                setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
            } else {
                setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
            }
            setState({ ...state, pageLoader: false })
            deleteRowDispatch()
        }
    }
    const deleteRowDispatch = () => {
        dispatch(rowDeleteYes({
            key: 'starteDelete',
            value: false
        }))
        dispatch(rowDeleteYes({
            key: 'confirmDelete',
            value: false
        }))
    }

    const addPayDateModal = (event) => {
        dispatch(handleModalState({
            key: 'addEditPayDateModal',
            value: true
        }))

    }

    const onSubmitDate = (params) => {
        setSnackBar({ ...snackBar, show: true, text: params.text, type: params.type })
        if (params.type === 'success') {
            const id = (retro_fields.hasEditData) ? (retro_fields.hasEditData.id) : retro_fields.editTabData.id
            if ((retro_fields.hasEditData !== null || retro_fields.editTabData !== null)) {
                setState({ ...state, isLoading: true })
                getPayMemberDatesList(id)
            }
        }
    }
    const onPageChange = (newPage) => {
        setState({ ...state, page: newPage + 1 });
    };

    const onPageSizeChange = (newPageSize) => {
        setState({ ...state, pageSize: newPageSize });
    };

    useLayoutEffect(() => {
        if ((retro_fields.hasEditData !== null || retro_fields.editTabData !== null)) {
            setState({ ...state, isLoading: true })
            const getId = retro_fields.hasEditData ? retro_fields.hasEditData : retro_fields.editTabData
            getPayMemberDatesList(getId.id)
        }
    }, [state.page, state.pageSize])

    const getPayMemberDatesList = async (id) => {
        try {

            const { data } = await axiosRetroDiscountSchemes.get(`/ImportedRetroDiscount/payMemberDate/${id}?page=${state.page}&pageSize=${state.pageSize}`);
            if (data.resultMap.result.length > 0) {
                setState({ ...state, isLoading: false, data: data.resultMap.result.reverse(), totalRows: data.resultMap.result.length, total: data.resultMap.result.length, applyFilterCount: null });
                dispatch(setRetroState({
                    key: 'payMemberDateData',
                    value: data.resultMap.result.reverse()
                }))
                // if (data.items.length === 0) {
                //     setSnackBar({ ...snackBar, show: true, text: "Pay member dates not available", type: "warning" })
                // }
            }
        } catch (error) {
            const { response: { data, status } } = error;
            setSnackBar({ ...snackBar, show: true, text: "Something went wrong!", type: "error" })
            setState({ ...state, loader: false })
        }
    }

    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Box component="div" className='table-actions-buttons' sx={{ columnGap: 1.75, display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2.5 }}>
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center' }, textAlign: { xs: "center" }, position: 'relative', marginBottom: '20px' }}>

                    <Button variant="contained" onClick={(e) => addPayDateModal(e)} startIcon={<AddIcon />}>
                        Add Date
                    </Button>
                    <Typography sx={{ fontSize: '12px', color: 'red', paddingBottom: '10px', position: 'absolute', top: '45px', width: '680px', right: '0', textAlign: 'right'}}>*Only <strong>1 interim</strong> and <strong>1 final</strong> should be added in this tab, for any additional interim dates please go to actual retro scheme.</Typography>
                </Box>
                <Box component="div" className='data-per-row-message'>
                    Showing {state.pageSize * (state.page - 1) + 1} to {((state.total < (state.pageSize * state.page)) ? state.total : (state.pageSize * state.page))} out of {state.total} entries
                </Box>
            </Box>
            <div style={{ height: '468px', width: '100%' }}>
                <DataGridPremium
                    rows={state.data}
                    columns={columns ? columns : []}
                    //autoHeight={true}
                    disableColumnMenu={true}

                    rowCount={state.total}
                    loading={state.isLoading}

                    pagination={true}
                    page={state.page - 1}
                    pageSize={state.pageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => onPageChange(newPage)}
                    onPageSizeChange={(newPageSize) => onPageSizeChange(newPageSize)}
                    components={{
                        Pagination: CustomPagination,
                        NoRowsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                                No search results were found
                            </Stack>
                        ),
                        NoResultsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                                Local filter returns no result
                            </Stack>
                        )
                    }}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? '' : 'oddRow'
                    }
                    initialState={{ pinnedColumns: { right: ['action'] } }}
                    sx={{
                        "& .MuiDataGrid-columnHeader": {
                            backgroundColor: "#F0F0F0"
                            // color: "red"
                        },
                        "& .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle": {
                            fontWeight: "600",
                            fontSize: '14px',
                            color: "#15191E"
                        },
                        "& .MuiDataGrid-row.oddRow": {
                            backgroundColor: "#FBFBFB"
                            // color: "red"
                        },
                        "& .MuiDataGrid-row.Mui-hovered, .MuiDataGrid-row.Mui-selected, .MuiDataGrid-row.Mui-selected.Mui-hovered": {
                            backgroundColor: "#FFF5E1"
                            // color: "red"
                        },
                        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
                            borderRight: `1px solid #E6E6E6`,
                            borderBottom: `1px solid #E6E6E6`,
                        },
                        '& .MuiDataGrid-cell': {
                            fontWeight: '500',
                        },
                    }}
                />
            </div>
            {addEditPayDateModal && (
                <>
                    <AddEditPayDate size="medium_overlay"
                        onShow={addEditPayDateModal}
                        onSubmitDate={onSubmitDate}
                        tableData={state.data}
                    />
                </>
            )}
            {snackBar.show && <SnackBarDialog
                onShow={snackBar.show}
                text={snackBar.text}
                type={snackBar.type}
                onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
            />}
        </>
    );
};
export default RetroPayMemberDates;