import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editTab: true,
    hasEditcbs: null,
    editData: null,
    hasEditData: null,
    scheme_name: '',
    notification_email: '',
    inv_bal_tol: '',
    bal_vat_invoices: false,
    print_invoices: false,
    pricing_tolerance: '',
    cbs_invalid_product: '',
    cbs_discount_product: '',
    cbs_system: '',
    cbs_department: '',
    cbs_cost_centre: '',
    cbs_ledger: '',
    cbs_account: '',
    cbs_reto_System: '',
    cbs_reto_department: '',
    cbs_reto_cost_centre: '',
    cbs_reto_ledger: '',
    cbs_reto_account: '',
    cbs_invoice_system: '',
    cbs_invoice_department: '',
    cbs_invoice_cost_centre: '',
    cbs_invoice_ledger: '',
    cbs_invoice_account: '',
    cbs_pay_supplier_system: '',
    cbs_pay_supplier_department: '',
    cbs_pay_supplier_cost_centre: '',
    cbs_pay_supplier_ledger: '',
    cbs_pay_supplier_account: '',
    cbs_levy_system: '',
    cbs_levy_department: '',
    cbs_levy_cost_centre: '',
    cbs_levy_ledger: '',
    cbs_levy_account: '',
    cbs_discounts_System: '',
    cbs_discounts_department: '',
    cbs_discounts_cost_centre: '',
    cbs_discounts_ledger: '',
    cbs_discounts_account: '',
    cbs_billing_retros_system: '',
    cbs_billing_retros_department: '',
    cbs_billing_retros_cost_centre: '',
    cbs_billing_retros_ledger: '',
    cbs_billing_retros_account: '',
    cbs_vat_code: {
        cbs_vat_code_options: [],
        cbs_vat_code_selected: null,
    },
    deleteList: null,
    cbsStartSingleDelete: false,
    cbsConfirmSingleDelete: false,
    isError: {
        scheme_name: false,
        notification_email: false,
        inv_bal_tol: false,
        bal_vat_invoices: false,
        print_invoices: false,
        pricing_tolerance: false,
        cbs_system: false,
        cbs_department: false,
        cbs_cost_centre: false,
        cbs_ledger: false,
        cbs_account: false,
        cbs_reto_System: false,
        cbs_reto_department: false,
        cbs_reto_cost_centre: false,
        cbs_reto_ledger: false,
        cbs_reto_account: false,
        cbs_invoice_system: false,
        cbs_invoice_department: false,
        cbs_invoice_cost_centre: false,
        cbs_invoice_ledger: false,
        cbs_invoice_account: false,
        cbs_pay_supplier_system: false,
        cbs_pay_supplier_department: false,
        cbs_pay_supplier_cost_centre: false,
        cbs_pay_supplier_ledger: false,
        cbs_pay_supplier_account: false,
        cbs_levy_system: false,
        cbs_levy_department: false,
        cbs_levy_cost_centre: false,
        cbs_levy_ledger: false,
        cbs_levy_account: false,
        cbs_discounts_System: false,
        cbs_discounts_department: false,
        cbs_discounts_cost_centre: false,
        cbs_discounts_ledger: false,
        cbs_discounts_account: false,
        cbs_billing_retros_system: false,
        cbs_billing_retros_department: false,
        cbs_billing_retros_cost_centre: false,
        cbs_billing_retros_ledger: false,
        cbs_billing_retros_account: false,
        cbs_vat_search: false
      }
   
}

const cbsProduct = createSlice({
    name: 'cbs_product',
    initialState,
    reducers: {
        cbsvatcodeSearch: (state, action) => {
            const { parent, key, value } = action.payload;
            state[parent][key] = value;
          },
        cbsProductInput: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value;
            state[key].length === 0 ? state.isError[key] = true : state.isError[key] = false
        },
        getIsError: (state, action) => {
            const { key, value } = action.payload;
            state.isError[key] = value
        },
        hasEdit: (state, action) => {
            const { editTab } =  action.payload;
            state.editTab = editTab
        },
        hasEditData: (state, action) => {
            const { editData } =  action.payload;
            state.editData = editData
        },
        setHasEditData: (state, action) => {
            state.hasEditData = action.payload;
        },
        setcbsName: (state, action) => {
            state.cbs_code = action.payload;
        },
        setSinglecbs: (state, action) => {
            state.rate_value = action.payload;
        },
        setcbsList: (state, action) => {
            state.externalcode_value = action.payload
        },
        setDescription: (state, action) => {
            state.desctiption_value = action.payload
        },
        setAutocomplete: (state, action) => {
            const { parent, key, value } =  action.payload;
            state[parent][key] = value
        },
        setDeleteList: (state, action) => {
            state.deleteList = action.payload
        },
        setcbsDeleteData: (state, action) => {
        const { key, value } = action.payload;
        state[key] = value;
        },
        resetcbs: () => {
            return initialState
        },
    }
})

// eslint-disable-next-line max-len
export const { cbsvatcodeSearch, cbsProductInput, resetBrand, setcbsName, setSinglecbs, setcbsList, setDescription, resetcbs, setAutocomplete, getIsError, hasEdit, hasEditData, setHasEditData, setDeleteList, setcbsDeleteData} = cbsProduct.actions

export default cbsProduct.reducer