import { Checkbox, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl, RadioGroup, Radio, Autocomplete } from '@mui/material'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import axios_programmes from '../../../../services/utility/axios-programmes';
import axiosDiscountSchemes from '../../../../services/utility/axios-discount-schemes';
import PageLoader from '../../../../components/shared/PageLoader';
import { setDiscountSchemeState, getIsError } from '../../../../store/slices/discountScheme';
import { handleModalState } from '../../../../store/slices/modals';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

function AddEditProgramme({ onShow, size = '', onHide, onSubmitDate }) {
    const [state, setState] = useState({
        dueDate: '',
        payDateDescription: '',
        onTrade: false,
        discountabl_bopa: false,
        defaultmode: false,
        formValid: true,
        pageLoader: false
    })

    const { discountScheme_container } = useSelector((state) => {
        return {
            discountScheme_container: state.discountSchemes,
        }
    });

    let programmeData = null;
    if(discountScheme_container.hasEditProgramme !== null) {
        programmeData = {"id": discountScheme_container.hasEditProgramme.programmeId, "name": discountScheme_container.hasEditProgramme.programmeName}
    }
    
    const [included, setIncluded] = useState(true);
    const [dcProgramme, setProgramme] = useState({
        programme_options: [],
        programme_selected: discountScheme_container.hasEditProgramme ? programmeData: null
    })

    const [isError, setIsError] = useState({
        programme_selected: false
    })

    const handleChange = (event) => {
        if(discountScheme_container.hasEditProgramme) {
            setState({ ...state, defaultmode: true })
        }
        setIncluded(event.target.checked);
    };

    useEffect(() => {
        const fetchMyAPI = async () => {
            if (dcProgramme.programme_options.length === 0) {
                axios_programmes.get('programmes/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            setProgramme({ ...dcProgramme, programme_options: unique })
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
        }
        fetchMyAPI();
    }, [])
    
    const dispatch = useDispatch();

    const [open, setOpen] = useState(onShow);

    const handleClose = () => {
        //setState({ ...state, defaultmode: true })
        dispatch(handleModalState({
            key: 'addEditProgrammeModal',
            value: false
        }))
        dispatch(setDiscountSchemeState({
            key: 'hasEditProgramme',
            value: null
        }))
        dispatch(getIsError({ key: 'programme_selected', value: false }))
    };

    const handleSelf = () => {
        handleClose()
    }

    useEffect(() => {
        if (dcProgramme.programme_selected === null || dcProgramme.programme_selected === "") {
            setState({ ...state, formValid: true, defaultmode: false})
        } else {
            setState({ ...state, formValid: false, defaultmode: true})
        }
    }, [dcProgramme.programme_selected])

    useEffect(() => {
        const {
            programme_selected
        } = discountScheme_container.isError
        if (!programme_selected) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true })
        }
    }, [discountScheme_container.isError])

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (dcProgramme.programme_selected === null && isError.programme_selected === false) {
            setIsError({ ...isError, programme_selected: true })
        } else {
            addPayDate()
        }
    }

    const addPayDate = async () => {
        let payload = null;
        if ((discountScheme_container.hasEditData !== null || discountScheme_container.editTabData !== null) && discountScheme_container.hasEditProgramme === null) {
            payload = {
                "programmeId": dcProgramme.programme_selected ? dcProgramme.programme_selected.id : "",
                included,
                "programmeName": dcProgramme.programme_selected ? dcProgramme.programme_selected.name : "",
            }
        } else if (discountScheme_container.hasEditProgramme !== null) {
            payload = {
                "id": discountScheme_container.hasEditProgramme.id,
                "programmeId": dcProgramme.programme_selected ? dcProgramme.programme_selected.id : discountScheme_container.hasEditProgramme.programmeId,
                included,
                "programmeName": dcProgramme.programme_selected ? dcProgramme.programme_selected.name : discountScheme_container.hasEditProgramme.programmeName
            }
        }

        try {
            setState({ ...state, pageLoader: true })
           // const url = ((discountScheme_container.hasEditData !== null || discountScheme_container.editTabData !== null) && discountScheme_container.hasEditProgramme === null) ? 'adddcProgrammes' : 'updatedcProgrammes';

            const { data } =  ((discountScheme_container.hasEditData !== null || discountScheme_container.editTabData !== null) && discountScheme_container.hasEditProgramme === null) ? await axiosDiscountSchemes.post(`/discountSchemes/${discountScheme_container.hasEditData.id || discountScheme_container.editTabData.id}/programmes`, payload) : await axiosDiscountSchemes.put(`/discountSchemes/${discountScheme_container.hasEditData.id || discountScheme_container.editTabData.id}/programmes/${discountScheme_container.hasEditProgramme.id}`, payload)

            if (data) {
                onSubmitDate({ text: data.message, type: "success" })
                setState({ ...state, pageLoader: false })
                handleClose()
            }

        } catch (error) {
            const { response: { data, status } } = error;
            if (!data) {
                onSubmitDate({ text: error.message, type: "error" })
            } else {
                onSubmitDate({ text: data.message, type: "error" })
            }
            setState({ ...state, pageLoader: false })
            handleClose()
        }
    }

    useEffect(() => {
        if (discountScheme_container.hasEditProgramme !== null) {
            setProgramme({ ...dcProgramme, programme_selected: discountScheme_container.hasEditProgramme.dcProgramme })
            setIncluded(discountScheme_container.hasEditProgramme.included)
        }
    }, [])


    useEffect(() => {
        const inputNumber = document.querySelectorAll('.inputNumber');
        inputNumber.forEach(el => el.addEventListener('keypress', evt => {
            if (evt.which === 8) {
                return;
            }
            if (evt.which < 42 || evt.which > 60) {
                evt.preventDefault();
            }
        }));
    }, []);

   
    return (
        <>
            <Modal
                open={open}
                aria-labelledby="add-edit-price-title"
                aria-describedby="add-edit-price-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Box component="div" className="overlay" id="addEditModal">
                        {state.pageLoader && <PageLoader />}
                        <div className={`overlay-box ${size ? size : ''}`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-header text-center"> Programmes
                                        <CancelIcon className="closeBtn" onClick={handleClose} />
                                    </div>
                                    <div className="overlay-content">
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            <FormLabel htmlFor="type" className="fontSemiBold mandatory">Programme</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Autocomplete
                                                                defaultValue={dcProgramme.programme_selected ? dcProgramme.programme_selected : null}
                                                                id="dcProgramme_search"
                                                                options={dcProgramme.programme_options}
                                                                getOptionLabel={(option) => option.name || ""}
                                                                componentsProps={{
                                                                    paper: {
                                                                        sx: {
                                                                            fontSize: '0.85rem'
                                                                        }
                                                                    }
                                                                }}
                                                                onChange={(event, newValue) => {
                                                                    (isError['programme_selected'] === null || isError['programme_selected'] === "") ? setIsError({ ...isError, programme_selected: true }) : setIsError({ ...isError, programme_selected: false })
                                                                    if (event.target.value !== undefined) {
                                                                        setState({ ...state, defaultmode: true })
                                                                        setProgramme({ ...dcProgramme, programme_selected: newValue })
                                                                        dispatch(getIsError({ key: 'programme_selected', value: false }))
                                                                    } else {
                                                                        dispatch(getIsError({ key: 'programme_selected', value: true }))
                                                                    }
                                                                }}
                                                                renderInput={(params) =>
                                                                    <FormControl size={'small'} fullWidth>
                                                                        <div className="input-group">
                                                                            <TextField {...params}
                                                                                size="small"
                                                                                id="dcProgramme"
                                                                                aria-describedby="dcProgramme-error"
                                                                                error={isError.programme_selected}
                                                                            />
                                                                            {discountScheme_container.isError.programme_selected && (<FormHelperText className='errorHelperTxt' id="dcProgramme-error">
                                                                                Please select Programmes
                                                                            </FormHelperText>)}
                                                                        </div>
                                                                    </FormControl>
                                                                }
                                                            />
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            <FormLabel htmlFor="included" className="fontSemiBold">Include/Exclude</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <div className="input-group">
                                                                <Checkbox
                                                                    checked={included}
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                />
                                                            </div>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            &nbsp;
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Grid container direction="row" alignItems="center">
                                                                <Button 
                                                                    variant="contained" 
                                                                    type="submit" sx={{ marginRight: '15px' }} 
                                                                // eslint-disable-next-line max-len
                                                                    //disabled={((discountScheme_container.hasEditProgramme && discountScheme_container.hasEditProgramme.confirmed === true) ? true : false) || state.defaultmode === false}
                                                                    // eslint-disable-next-line max-len
                                                                    disabled={((discountScheme_container.hasEditData !== null || discountScheme_container.editTabData !== null) && discountScheme_container.hasEditProgramme) ? discountScheme_container.isError.programme_selected : (state.formValid || state.defaultmode === false)}
                                                                >Save</Button>
                                                                <Button variant="outlined" onClick={handleSelf}>Cancel</Button>
                                                            </Grid>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </form>

            </Modal>
        </>
    );
}

export default AddEditProgramme;