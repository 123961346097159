import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Typography, Box, Modal, Paper, FormControl, Grid, TextField, FormHelperText, FormLabel, Button, Checkbox, Select, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import axios_product from '../../../services/utility/axios';
import axios_brand from '../../../services/utility/axios-brand';
import axios_member from '../../../services/utility/axios-member';
import axios_supplier from '../../../services/utility/axios-supplier';
import axiosLevyScheme from '../../../services/utility/axios-levy-scheme';
import PageLoader from "../PageLoader";
import { useDispatch, useSelector } from 'react-redux';
import { setCommonInput, resetcommonProduct } from "../../../store/slices/commonProduct";
import { DataTableClientSide } from '../data-table/DataTableClientSide';
import { setExistingInvoice, setSupplierCode, spcValueSeter } from '../../../store/slices/manualInvoice';
import { setMemberCode, setSupplierName } from "../../../store/slices/supplierMemberAccounts";
import axiosProductDistribution from '../../../services/utility/axios-product-distribution';
import axiosAccountingEntries from '../../../services/utility/axios-accounting-entries';
import moment from 'moment';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { SelectDropDown } from './Select';
import SnackBarDialog from '../SnackBarDialog';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

const IconMenuLink = ({ params, onSelect }) => {
    const { row } = params;

    const selectProducts = (row) => {
        if (onSelect) {
            onSelect(row)
        }
    }

    return (<>
        <Typography align='center'>
            <Button variant='text' size='large' title="Apply" onClick={() => { selectProducts(row) }} startIcon={<CheckCircleIcon />}></Button>
        </Typography>

    </>)
}

function KeyPressSearchComponent({ hidePriceModalAfterProduct, supplierMemberCode, outercaseBarCode, brandDetails, multipleFilter, serverProcessFilter, levyDetails }) {
    const [state, setState] = useState({
        defaultGrid: {
            paginationMode: 'client',
            keyPressSearch: true
        }
    });

    let [columns, setColumns] = useState([
        {
            headerName: "ID",
            field: "id",
            hide: true
        }])
    useEffect(() => {
        if (supplierMemberCode) {
            if (supplierMemberCode.title === 'SPC') {
                setColumns([
                    {
                        headerName: "Supplier Product Code",
                        field: "supplierProductCode",
                        flex: 1,
                        minWidth: 200
                    },
                    {
                        headerName: "Product Name",
                        field: "productName",
                        flex: 1,
                        minWidth: 200
                    },
                    {
                        headerName: "Apply",
                        field: "action",
                        sortable: false,
                        width: 90,
                        renderCell: (params) => {
                            return <IconMenuLink params={params} onSelect={onSelectName} />;
                        },
                    }])
                // eslint-disable-next-line no-dupe-else-if
            } else if (supplierMemberCode.title === 'Member Details' && supplierMemberCode.type === 'members') {
                setColumns([
                    {
                        headerName: "Id",
                        field: "id",
                        flex: 1,
                        minWidth: 200,
                        hide: true
                    },
                    {
                        headerName: "Name",
                        field: "name",
                        flex: 1,
                        minWidth: 200
                    },
                    {
                        headerName: "Apply",
                        field: "action",
                        sortable: false,
                        width: 90,
                        renderCell: (params) => {
                            return <IconMenuLink params={params} onSelect={onSelectName} />;
                        },
                    }])
            } else if (supplierMemberCode.title === 'Supplier Details' && supplierMemberCode.type === 'supplier') {
                setColumns([
                    {
                        headerName: "Id",
                        field: "id",
                        flex: 1,
                        minWidth: 200,
                        hide: true
                    },
                    {
                        headerName: "Name",
                        field: "name",
                        flex: 1,
                        minWidth: 200
                    },
                    {
                        headerName: "Central Billing Id",
                        field: "centralBillingId",
                        flex: 1,
                        minWidth: 200
                    },
                    {
                        headerName: "Apply",
                        field: "action",
                        sortable: false,
                        width: 90,
                        renderCell: (params) => {
                            return <IconMenuLink params={params} onSelect={onSelectName} />;
                        },
                    }])
            }
            else {
                setColumns([
                    {
                        headerName: "ID",
                        field: "id",
                        hide: true
                    },
                    {
                        headerName: "SMA Code",
                        field: "smaCode",
                        flex: 1,
                        minWidth: 150
                    },
                    {
                        headerName: "Supplier Name",
                        field: "supplierName",
                        flex: 1,
                        minWidth: 150
                    },
                    {
                        headerName: "Member Name",
                        field: "memberName",
                        flex: 1,
                        minWidth: 150
                    },
                    {
                        headerName: "Member Outlet Name",
                        field: "memberOutLetName",
                        flex: 1,
                        minWidth: 150
                    },
                    {
                        headerName: "Purchasing Tier",
                        field: "memberTier",
                        valueGetter: (params) => params.row.memberTier ? params.row.memberTier.name : "",
                        minWidth: 150
                    },
                    {
                        headerName: "Apply",
                        field: "action",
                        sortable: false,
                        width: 90,
                        renderCell: (params) => {
                            return <IconMenuLink params={params} onSelect={onSelectName} />;
                        },
                    }])
            }

        }
        else if (outercaseBarCode) {
            setColumns([
                {
                    headerName: "Product Name",
                    field: "name",
                    flex: 1,
                    minWidth: 200
                },
                {
                    headerName: "Outercase Barcode",
                    field: "outerCaseBarcode",
                    flex: 1,
                    minWidth: 200
                },
                {
                    headerName: "Apply",
                    field: "action",
                    sortable: false,
                    width: 90,
                    renderCell: (params) => {
                        return <IconMenuLink params={params} onSelect={onSelectName} />;
                    },
                }
            ])
        }
        else {
            if (brandDetails) {
                setColumns([
                    {
                        headerName: "Name",
                        field: "name",
                        flex: 1,
                        minWidth: 200
                    },
                    {
                        headerName: "Apply",
                        field: "action",
                        sortable: false,
                        width: 90,
                        renderCell: (params) => {
                            return <IconMenuLink params={params} onSelect={onSelectName} />;
                        },
                    }
                ])
            } else if (levyDetails) {
                setColumns([
                    {
                        headerName: "Name",
                        field: "name",
                        flex: 1,
                        minWidth: 200
                    },
                    {
                        headerName: "Purchasing Tier",
                        field: "memberTier",
                        flex: 1,
                        minWidth: 200,
                        valueGetter: (params) => params.row.memberTier ? params.row.memberTier.name : "",
                    },
                    {
                        headerName: "Apply",
                        field: "action",
                        sortable: false,
                        width: 90,
                        renderCell: (params) => {
                            return <IconMenuLink params={params} onSelect={onSelectName} />;
                        },
                    }
                ])
            } else {
                if (serverProcessFilter === true) {
                    setColumns([
                        {
                            headerName: "Id",
                            field: "id",
                            flex: 1,
                            minWidth: 200
                        },
                        {
                            headerName: "Start Date",
                            field: "startDate",
                            flex: 1,
                            minWidth: 100
                        },
                        {
                            headerName: "Class",
                            field: "clazz",
                            flex: 1,
                            minWidth: 100,
                        },
                        {
                            headerName: "Apply",
                            field: "action",
                            sortable: false,
                            width: 90,
                            renderCell: (params) => {
                                return <IconMenuLink params={params} onSelect={onSelectName} />;
                            },
                        }
                    ])
                } else {
                    setColumns([
                        {
                            headerName: "Name",
                            field: "name",
                            flex: 1,
                            minWidth: 200
                        },
                        {
                            headerName: "BrandOwnerProductId",
                            field: "brandOwnerProductId",
                            flex: 1,
                            minWidth: 100
                        },
                        {
                            headerName: "OuterCaseBarcode",
                            field: "outerCaseBarcode",
                            flex: 1,
                            minWidth: 100
                        },
                        {
                            headerName: "Apply",
                            field: "action",
                            sortable: false,
                            width: 90,
                            renderCell: (params) => {
                                return <IconMenuLink params={params} onSelect={onSelectName} />;
                            },
                        }
                    ])
                }
            }
        }
    }, [])
    const [rows, setRows] = useState([])
    const [query, setQuery] = useState(outercaseBarCode ? outercaseBarCode.ocbc : "")
    const [productName, setproductName] = useState('')
    const [outerCaseBarcode, setouterCaseBarcode] = useState('')
    const [brandOwnerProductId, setbrandOwnerProductId] = useState('')
    const [pageLoader, setPageLoader] = useState(false)
    const [serverProcessField, setServerProcessField] = useState({
        id: '',
        startDate: null,
        clazz: ''
    })
    const {
        manualInvoiceEntry
    } = useSelector((state) => {
        return {
            manualInvoiceEntry: state.invoiceManualEntry
        }
    });
    const dispatch = useDispatch();

    const ClazzNames = [
        { text: 'BillDiscountsExecutable', value: 'BillDiscountsExecutable' },
        { text: 'DisputedInvoiceExecutable', value: 'DisputedInvoiceExecutable' },
        { text: 'LevyBillingExecutable', value: 'LevyBillingExecutable' },
        { text: 'MemberCollectionExecutable', value: 'MemberCollectionExecutable' },
        { text: 'MemberInvoicePrintExecutable', value: 'MemberInvoicePrintExecutable' },
        { text: 'MemberStatementExecutable', value: 'MemberStatementExecutable' },
        { text: 'ReconciliationExecutable', value: 'ReconciliationExecutable' },
        { text: 'RemittanceExecutable', value: 'RemittanceExecutable' },
        { text: 'RetroBillingExecutable', value: 'RetroBillingExecutable' },
        { text: 'RetroFinalExecutable', value: 'RetroFinalExecutable' },
        { text: 'RetroInterimExecutable', value: 'RetroInterimExecutable' }
    ];

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const handleProductsData = async (event) => {
        try {
            if (serverProcessFilter === true) {
                if (serverProcessField.id !== '' || serverProcessField.startDate !== null || serverProcessField.clazz !== '') {
                    if (serverProcessField.startDate !== "" && serverProcessField.startDate !== null && moment(serverProcessField.startDate).format('DD/MM/YYYY') === 'Invalid date') {
                        setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
                    }
                    else
                    {
                        setPageLoader(true)
                        let baseURL = axiosAccountingEntries;
                        const url = 'accountingEntryGroups/serverProcessEntry/look-up';
                        let serverPayload = {
                            "id": serverProcessField.id ? serverProcessField.id : null,
                            "startDate": serverProcessField.startDate ? moment(serverProcessField.startDate).format('DD/MM/YYYY')+' 00:00:00' : null,
                            "clazz": serverProcessField.clazz ? serverProcessField.clazz : null
                        }
                        const { data } = await baseURL.post(`${url}`, serverPayload)
                        if (data.items) {
                            setPageLoader(false)
                            setTimeout(() => {
                                setRows(data.items)
                            }, 150);
                        }
                    }

                }
            }
            if (multipleFilter === true) {
                if (outerCaseBarcode.length > 0 || brandOwnerProductId.length > 0 || productName.length > 0) {
                    setPageLoader(true)
                    let baseURL = axios_product;
                    const url = 'v2/products/look-up';
                    const { data } = await baseURL.get(`${url}?outerCaseBarcode=${outerCaseBarcode ? outerCaseBarcode : ''}&brandOwnerProductId=${brandOwnerProductId ? brandOwnerProductId : ''}&name=${productName ? productName : ''}`)
                    if (data.resultMap.result) {
                        setPageLoader(false)
                        setTimeout(() => {
                            setRows(data.resultMap.result)
                        }, 150);
                    }
                }
            }

        } catch (error) {
            const { response: { data, status } } = error;
            setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error" })
            setPageLoader(false)
            //hidePriceModalAfterProduct()
        }

    }

    const clearProductData = () => {
        setproductName('')
        setouterCaseBarcode('')
        setbrandOwnerProductId('')
        setRows('')
        setServerProcessField({
            ...serverProcessField,
            id: '',
            startDate: null,
            clazz: ''
        })
        //hidePriceModalAfterProduct()
    }

    const onSelectName = (row) => {
        if (hidePriceModalAfterProduct) {
            if (supplierMemberCode) {
                if (supplierMemberCode.fromDashboard) {
                    dispatch(setSupplierCode({
                        key: 'mieSupplierCodeOnDashboard',
                        value: row
                    }))
                    hidePriceModalAfterProduct(row)
                }
                if (supplierMemberCode.fromModal) {
                    dispatch(setSupplierCode({
                        key: 'mieSupplierCodeOnModal',
                        value: row
                    }))
                    hidePriceModalAfterProduct()
                }
                if (supplierMemberCode.title === 'SPC') {
                    dispatch(spcValueSeter(row))
                    hidePriceModalAfterProduct(row)
                }
                if (supplierMemberCode.title === 'Member Details' && supplierMemberCode.type === 'members') {
                    dispatch(setMemberCode({
                        key: 'smaMemberCodeOnModal',
                        value: row
                    }))
                    hidePriceModalAfterProduct(row)
                }
                if (supplierMemberCode.title === 'Supplier Details' && supplierMemberCode.type === 'supplier') {
                    dispatch(setSupplierName({
                        key: 'smaSupplierNameOnModal',
                        value: row
                    }))
                    hidePriceModalAfterProduct(row)
                }
            }
            else if (outercaseBarCode) {
                //dispatch(setCommonInput(row));
                hidePriceModalAfterProduct(row);
            }
            else {
                dispatch(setCommonInput(row));
                hidePriceModalAfterProduct(row)
            }
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (supplierMemberCode ? query.length > 0 : query.length > 2) {
                    setPageLoader(true)
                    let baseURL = null;
                    if (supplierMemberCode) {
                        if (supplierMemberCode.title === "SPC") {
                            baseURL = axiosProductDistribution;
                        }
                        // eslint-disable-next-line no-dupe-else-if
                        else if (supplierMemberCode.title === "Member Details" && supplierMemberCode.type === 'members') {
                            baseURL = axios_member;
                        }
                        else if (supplierMemberCode.title === "Supplier Details" && supplierMemberCode.type === 'supplier') {
                            baseURL = axios_supplier;
                        } else {
                            baseURL = axios_member
                        }
                    } else if(brandDetails) {
                        baseURL = axios_brand
                    } else {
                        baseURL = axios_product
                    }
                    //const baseURL = supplierMemberCode.title === "SPC" ? axiosProductDistribution : supplierMemberCode.title === "Supplier Member Account" ? axios_member : axios_product;
                    const url = supplierMemberCode ? supplierMemberCode.apiUrl : outercaseBarCode ? outercaseBarCode.apiUrl : brandDetails ? brandDetails.apiUrl : 'v2/products/look-up';
                    const { data } = outercaseBarCode ? await baseURL.get(`${url}?outerCaseBarcode=${query}`) : await baseURL.get(`${url}${(supplierMemberCode && supplierMemberCode.title === 'Member Details') ? '&' : '?' }name=${query}`)
                    if (supplierMemberCode) {
                        if (supplierMemberCode.title === "SPC") {
                            if (data.resultMap.result) {
                                setPageLoader(false)
                                setTimeout(() => {
                                    setRows(data.resultMap.result)
                                }, 150);
                            }
                        }
                        else if (supplierMemberCode.title === "Member Details" && supplierMemberCode.type === 'members') {
                            if (data.items) {
                                setPageLoader(false)
                                setTimeout(() => {
                                    setRows(data.items)
                                }, 150);
                            }
                        }
                        else if (supplierMemberCode.title === "Supplier Details" && supplierMemberCode.type === 'supplier') {
                            if (data) {
                                setPageLoader(false)
                                setTimeout(() => {
                                    setRows(data)
                                }, 150);
                            }
                        } else {
                            setPageLoader(false)
                            setTimeout(() => {
                                setRows(data.resultMap.result)
                            }, 150);
                        }
                    }
                    else if (outercaseBarCode) {
                        if (data.resultMap.result) {
                            setPageLoader(false)
                            setTimeout(() => {
                                setRows(data.resultMap.result)
                            }, 150);
                        }
                        else {
                            setPageLoader(false)
                        }
                    }
                    else {
                        if (data.resultMap.result) {
                            setPageLoader(false)
                            setTimeout(() => {
                                setRows(data.resultMap.result)
                            }, 150);
                        }
                    }
                }
            } catch (error) {
                const { response: { data, status } } = error;
                setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error" })
                if (outercaseBarCode) {
                    if (!data) {
                        setPageLoader(false)
                        //hidePriceModalAfterProduct()
                    } else {
                        setPageLoader(false)
                        //hidePriceModalAfterProduct()
                    }
                }
                else {
                    if (!data) {
                        setPageLoader(false)
                        //hidePriceModalAfterProduct()
                    } else {
                        setPageLoader(false)
                        //hidePriceModalAfterProduct()
                    }
                }

            }
        }

        fetchData()
    }, [query])

    useEffect(() => {
        const fetchData = async () => {
            try {
                let baseURL = null;
                if (supplierMemberCode) {
                    setPageLoader(true)
                    if (supplierMemberCode.title === "SPC") {
                        baseURL = axiosProductDistribution;
                    }
                    // eslint-disable-next-line no-dupe-else-if
                    else if (supplierMemberCode.title === "Member Details" && supplierMemberCode.type === 'members') {
                        baseURL = axios_member;
                    }
                    else if (supplierMemberCode.title === "Supplier Details" && supplierMemberCode.type === 'supplier') {
                        baseURL = axios_supplier;
                    } else {
                        baseURL = axios_member
                    }
                }
                else if (levyDetails) {
                    setPageLoader(true)
                    baseURL = axiosLevyScheme
                } else if(brandDetails) {
                    setPageLoader(true)
                    baseURL = axios_brand
                } else {
                    setPageLoader(true)
                    baseURL = axios_product
                }
                //const baseURL = supplierMemberCode.title === "SPC" ? axiosProductDistribution : supplierMemberCode.title === "Supplier Member Account" ? axios_member : axios_product;

                const url = supplierMemberCode ? supplierMemberCode.apiUrl : outercaseBarCode ? outercaseBarCode.apiUrl : brandDetails ? brandDetails.apiUrl : levyDetails ? levyDetails.apiUrl : 'v2/products/look-up'
                const { data } = outercaseBarCode ? await baseURL.get(`${url}?outerCaseBarcode=${query}`) : (supplierMemberCode && (supplierMemberCode.title === 'Supplier Details' || supplierMemberCode.title === 'Member Details' || supplierMemberCode.title === 'Supplier Member Account')) ? await baseURL.get(`${url}`) : (supplierMemberCode && (supplierMemberCode.title === "SPC")) ? await baseURL.get(`${url}?name=`) : levyDetails ? await baseURL.get(`${url}`) : ""
                if (supplierMemberCode) {
                    if (supplierMemberCode.title === "SPC") {
                        if (data.resultMap.result) {
                            setPageLoader(false)
                            setTimeout(() => {
                                setRows(data.resultMap.result)
                            }, 150);
                        }
                    }
                    else if (supplierMemberCode.title === "Member Details" && supplierMemberCode.type === 'members') {
                        if (data.items) {
                            setPageLoader(false)
                            setTimeout(() => {
                                setRows(data.items)
                            }, 150);
                        }
                    }
                    else if (supplierMemberCode.title === "Supplier Details" && supplierMemberCode.type === 'supplier') {
                        if (data) {
                            setPageLoader(false)
                            setTimeout(() => {
                                setRows(data)
                            }, 150);
                        }
                    }
                    else if (supplierMemberCode.title === "Supplier Member Account") {
                        if (data.resultMap.result) {
                            setPageLoader(false)
                            setTimeout(() => {
                                setRows(data.resultMap.result)
                            }, 150);
                        }
                    }
                }
                else if (outercaseBarCode) {
                    if (data.resultMap.result) {
                        setPageLoader(false)
                        setTimeout(() => {
                            setRows(data.resultMap.result)
                        }, 150);
                    }
                    else {
                        setPageLoader(false)
                    }
                } else if (brandDetails) {
                    if (data.resultMap.result) {
                        setPageLoader(false)
                        setTimeout(() => {
                            setRows(data.resultMap.result)
                        }, 150);
                    }
                } else if (levyDetails) {
                    if (data.resultMap.result) {
                        setPageLoader(false)
                        setTimeout(() => {
                            setRows(data.resultMap.result)
                        }, 150);
                    }
                }
                else {

                    setPageLoader(false)
                }
            } catch (error) {
                const { response: { data, status } } = error;
                if (outercaseBarCode) {
                    if (!data) {
                        setPageLoader(false)
                        //hidePriceModalAfterProduct()
                    } else {
                        setPageLoader(false)
                        //hidePriceModalAfterProduct()
                    }
                }
                else {
                    if (!data) {
                        setPageLoader(false)
                        // hidePriceModalAfterProduct()
                    } else {
                        setPageLoader(false)
                        // hidePriceModalAfterProduct()
                    }
                }

            }
        }

        fetchData()
    }, []);

    const handleChange = (event) => {
        setServerProcessField({ ...serverProcessField, clazz: event.target.value });
    };

    return (

        <div className="overlay-content">
            {pageLoader && <PageLoader />}
            { snackBar.show && <SnackBarDialog 
            onShow={snackBar.show} 
            text={snackBar.text} 
            type={snackBar.type} 
            onHide={(e) => {setSnackBar({...snackBar, show: e})}}
            />}
            <Box sx={{ flexGrow: 1, paddingX: 2 }} >

                {/* {levyDetails !== undefined ? "" : (multipleFilter === false && serverProcessFilter === false) ? ( */}
                {(multipleFilter === false && serverProcessFilter === false) ? (
                    <div className='BrandOwnerNameBx'>
                        <Grid container alignItems="center">
                            <Grid item>
                                <Item sx={{ paddingLeft: 0 }}>
                                    <FormLabel htmlFor="query" className="fontSemiBold mandatory">Name</FormLabel>
                                </Item>
                            </Grid>
                            <Grid item>
                                <Item>
                                    <div className="input-group">
                                        <FormControl fullWidth>
                                            <TextField
                                                inputProps={{ maxLength: 100 }}
                                                size="small"
                                                id="query"
                                                placeholder="Search..."
                                                value={query}
                                                name="query"
                                                onChange={event => setQuery(event.target.value)}
                                            />
                                        </FormControl>
                                    </div>
                                </Item>
                            </Grid>
                        </Grid>
                    </div>
                ) : serverProcessFilter === true ? (
                    <div className='BrandOwnerNameBx'>
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} md={4} sx={{ marginBottom: 2.5 }}>
                                <Grid item sx={{ marginBottom: 1 }}>
                                    <Item>
                                        <FormLabel htmlFor="query" className="fontSemiBold">Id</FormLabel>
                                    </Item>
                                </Grid>
                                <Grid item>
                                    <Item>
                                        <div className="input-group">
                                            <FormControl fullWidth>
                                                <TextField
                                                    inputProps={{ maxLength: 100 }}
                                                    size="small"
                                                    id="productName"
                                                    placeholder="Search..."
                                                    value={serverProcessField.id}
                                                    name="productName"
                                                    onChange={event => setServerProcessField({ ...serverProcessField, id: event.target.value })}
                                                />
                                            </FormControl>
                                        </div>
                                    </Item>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} sx={{ marginBottom: 2.5 }}>
                                <Grid item sx={{ marginBottom: 1 }}>
                                    <Item>
                                        <FormLabel htmlFor="query" className="fontSemiBold">Start Date</FormLabel>
                                    </Item>
                                </Grid>
                                <Grid item>
                                    <Item>
                                        <div className="input-group">
                                            <FormControl fullWidth>
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <DesktopDatePicker
                                                        value={serverProcessField.startDate}
                                                        onChange={(event) => { setServerProcessField({ ...serverProcessField, startDate: event === null ? null : moment(event, 'DD/MM/YYYY') }) }}
                                                        name="invrpc_from_date" label="Start Date"
                                                        inputFormat="DD/MM/YYYY"
                                                        // eslint-disable-next-line max-len
                                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                                            <Box component="div" sx={{ paddingTop: '0px' }} className='date-picker-input'>
                                                                <FormControl size="small" sx={{ width: '100%' }} >
                                                                    <TextField size="small" id="invrpc_from_date"
                                                                        ref={inputRef} {...inputProps}
                                                                        placeholder=""
                                                                        aria-describedby="invrpc_from_date_error"
                                                                        autoComplete="off"
                                                                    />
                                                                    <Box className='date-picker-icon'>
                                                                        {InputProps?.endAdornment}
                                                                    </Box>
                                                                </FormControl>
                                                            </Box>
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </FormControl>
                                        </div>
                                    </Item>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} sx={{ marginBottom: 2.5 }}>
                                <Grid item sx={{ marginBottom: 1 }}>
                                    <Item>
                                        <FormLabel htmlFor="query" className="fontSemiBold">Class</FormLabel>
                                    </Item>
                                </Grid>
                                <Grid item>
                                    <Item>
                                        <div className="input-group">
                                            <FormControl size="small" fullWidth>
                                                <Select
                                                    size="small"
                                                    id="outerCaseBarcode"
                                                    defaultValue=""
                                                    onChange={(e) => handleChange(e)}
                                                    value={serverProcessField.clazz}
                                                    aria-describedby="msTypeError"
                                                    aria-label="controlled"
                                                    name="outerCaseBarcode"
                                                >
                                                    {ClazzNames.map((option) => (
                                                        <MenuItem value={option.value} key={option.value} sx={{ fontSize: '0.85rem' }}>{option.text}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12}>
                                <Item>
                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                        <Button variant="contained" sx={{ marginRight: '15px' }} onClick={handleProductsData}>Submit</Button>
                                        <Button variant="outlined" onClick={clearProductData}>Reset</Button>
                                    </Grid>
                                </Item>
                            </Grid>
                        </Grid>
                    </div>
                ) : (
                    <div className='BrandOwnerNameBx'>
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} md={4} sx={{ marginBottom: 2.5 }}>
                                <Grid item sx={{ marginBottom: 1 }}>
                                    <Item>
                                        <FormLabel htmlFor="query" className="fontSemiBold">Name</FormLabel>
                                    </Item>
                                </Grid>
                                <Grid item>
                                    <Item>
                                        <div className="input-group">
                                            <FormControl fullWidth>
                                                <TextField
                                                    inputProps={{ maxLength: 100 }}
                                                    size="small"
                                                    id="productName"
                                                    placeholder="Search..."
                                                    value={productName}
                                                    name="productName"
                                                    onChange={event => setproductName(event.target.value)}
                                                />
                                            </FormControl>
                                        </div>
                                    </Item>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} sx={{ marginBottom: 2.5 }}>
                                <Grid item sx={{ marginBottom: 1 }}>
                                    <Item>
                                        <FormLabel htmlFor="query" className="fontSemiBold">BrandOwnerProductId</FormLabel>
                                    </Item>
                                </Grid>
                                <Grid item>
                                    <Item>
                                        <div className="input-group">
                                            <FormControl fullWidth>
                                                <TextField
                                                    inputProps={{ maxLength: 100 }}
                                                    size="small"
                                                    id="brandOwnerProductId"
                                                    placeholder="Search..."
                                                    value={brandOwnerProductId}
                                                    name="brandOwnerProductId"
                                                    onChange={event => setbrandOwnerProductId(event.target.value)}
                                                />
                                            </FormControl>
                                        </div>
                                    </Item>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} sx={{ marginBottom: 2.5 }}>
                                <Grid item sx={{ marginBottom: 1 }}>
                                    <Item>
                                        <FormLabel htmlFor="query" className="fontSemiBold">OuterCaseBarcode</FormLabel>
                                    </Item>
                                </Grid>
                                <Grid item>
                                    <Item>
                                        <div className="input-group">
                                            <FormControl fullWidth>
                                                <TextField
                                                    inputProps={{ maxLength: 100 }}
                                                    size="small"
                                                    id="outerCaseBarcode"
                                                    placeholder="Search..."
                                                    value={outerCaseBarcode}
                                                    name="outerCaseBarcode"
                                                    onChange={event => setouterCaseBarcode(event.target.value)}
                                                />
                                            </FormControl>
                                        </div>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12}>
                                <Item>
                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                        <Button variant="contained" sx={{ marginRight: '15px' }} onClick={handleProductsData}>Submit</Button>
                                        <Button variant="outlined" onClick={clearProductData}>Reset</Button>
                                    </Grid>
                                </Item>
                            </Grid>
                        </Grid>
                    </div>
                )}


            </Box>

            <DataTableClientSide
                defaultGrid={state.defaultGrid}
                columns={columns ? columns : []}
                rows={rows ? rows : []}
            >
            </DataTableClientSide>
        </div>
    );
}

export default KeyPressSearchComponent;