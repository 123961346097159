import { Container, Box, Modal, Button, Grid, Checkbox, Popper, Grow, Paper, ClickAwayListener, MenuList, ButtonGroup } from '@mui/material'
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TableFilters } from "../../components/shared/data-table/TableFilters";
import { useEffect, useState } from "react";
import { InputText } from "../../components/shared/form/InputText";
import { AutoComplete } from "../../components/shared/form/AutoComplete";
import { } from '@mui/material';
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange, filterResetFlagChange } from "../../store/slices/externalFilter";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import AddIcon from '@mui/icons-material/Add';
import { ThreeStateCheckbox } from "../../components/shared/form/ThreeStateCheckbox";
import { warningModal, handleAddEditModal, handleRDSchemeModal } from "../../store/slices/modals";
import WarningModal from "../../components/shared/modals/WarningModal";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { setRowPage, resetDataGrid, setPageNo } from '../../store/slices/dataGrid';
import { useLayoutEffect } from "react";
import { DataTableRetroDiscountSchemesDashboard } from "../../components/shared/data-table/DataTableRetroDiscountSchemesDashboard";
import RetroGeneral from './retrospective-discount-schemes/tabs/RetroGeneral';
import AddEditRetrospective from './retrospective-discount-schemes/AddEditRetrospective';
import { setHasEditData, retrodiscountschemesInput, setRetroDates, autoCompleteSearch, setRetroState, setDeleteList } from '../../store/slices/retrodiscountschemes';
import { hasTabEdit } from '../../store/slices/generalProduct';
import axiosRetroDiscountSchemes from "../../services/utility/axios-retro-discount-schemes";
import SnackBarDialog from "../../components/shared/SnackBarDialog";
import RetroPayMemberDates from './retrospective-discount-schemes/tabs/RetroPayMemberDates';
import RetroVolumeThreshold from './retrospective-discount-schemes/tabs/RetroVolumeThreshold';
import RetroDistributionChannel from './retrospective-discount-schemes/tabs/RetroDistributionChannel';
import RetroCategories from './retrospective-discount-schemes/tabs/RetroCategories';
import RetroMember from './retrospective-discount-schemes/tabs/RetroMembers';
import RetroSuppliers from './retrospective-discount-schemes/tabs/RetroSuppliers';
import RetroProgrammes from './retrospective-discount-schemes/tabs/RetroProgrammes';
import RetroProduct from './retrospective-discount-schemes/tabs/RetroProduct';
import PageLoader from "../../components/shared/PageLoader";

const IconMenuLink = ({ params, onDelete }) => {
    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { addEditRDSchemeModal, retrodiscountscheme_fields } = useSelector((state) => {
        return {
            addEditRDSchemeModal: state.modalActions.addEditRDSchemeModal,
            editTabData: state.retrodiscountscheme.editTabData,
            retrodiscountscheme_fields: state.retrodiscountscheme
        }
    });
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'edit_rd_scheme') {
            dispatch(handleRDSchemeModal({
                addEditRDSchemeModal: true
            }))
            dispatch(hasTabEdit({ editTab: false }))
            setEditRDSData(row);
        } else if (action === 'delete') {
            onDelete(row)
        }
        setAnchorEl(null);
    };
    const setEditRDSData = (row) => {
        dispatch(setHasEditData(row));
        dispatch(retrodiscountschemesInput({
            key: 'retro_name',
            value: row.name
        }))
        dispatch(setRetroDates({
            key: 'startDate',
            value: row.startDate ? moment(row.startDate,'DD/MM/YYYY') : ""
        }))
        dispatch(setRetroDates({
            key: 'endDate',
            value: row.endDate ? moment(row.endDate,'DD/MM/YYYY') : ""
        }))
        dispatch(retrodiscountschemesInput({
            key: 'retro_interim_unit',
            value: row.interimValuePerUnit
        }))
        dispatch(retrodiscountschemesInput({
            key: 'retro_final_unit',
            value: row.finalValuePerUnit
        }))
        dispatch(retrodiscountschemesInput({
            key: 'closeForInvoice',
            value: row.closedForInvoices
        }))
        dispatch(retrodiscountschemesInput({
            key: 'closeForBilling',
            value: row.closedForBilling
        }))
        dispatch(retrodiscountschemesInput({
            key: 'generateDetailedReport',
            value: row.detailedReport
        }))
        dispatch(retrodiscountschemesInput({
            key: 'aggregationGroups',
            value: row.applyAggregationGroups
        }))

        dispatch(autoCompleteSearch({
            parent: 'general_vat_code',
            key: 'vat_code_selected',
            value: row.vatCode
        }))
        dispatch(autoCompleteSearch({
            parent: 'general_unit_measure',
            key: 'measure_selected',
            value: row.unitOfMeasure
        }))
        dispatch(autoCompleteSearch({
            parent: 'centralBillingScheme',
            key: 'billingScheme_selected',
            value: row.centralBillingScheme
        }))
        if(row.promotionTypeId)
        {
            dispatch(autoCompleteSearch({
                parent: 'promotionalType',
                key: 'promotionalType_selected',
                value: { "id": row.promotionTypeId, "name": row.promotionTypeName, "label": row.promotionTypeLabel }
            }));
        }
        else
        {
            dispatch(autoCompleteSearch({
                parent: 'promotionalType',
                key: 'promotionalType_selected',
                value: null
            }));
        }
    }
    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'edit_rd_scheme')}>Edit</MenuItem>
            {/* <MenuItem onClick={(e) => handleClose(e, 'delete')}>Delete</MenuItem> */}
        </Menu>
    </>)
}
function RetrospectiveDiscountSchemes() {
    
    const [state, setState] = useState({
        retro_discount_closed_for_invoices: false,
        pageLoader: false,
        retro_discount_closed_for_billing: false,
        openretrodiscountschemeModal: false,
        defaultGrid: {
            pagetitle: "Retro Discount Schemes",
            pdcActionButton: true,
            hasExport: true,
            addEditButton: true,
            apiUrl: 'retrodiscount/filter',
            searchUrl: 'retrodiscount/filter'
        }
    });

    const { retro_discount_scheme_name, retro_discount_closed_for_billing, retro_discount_closed_for_invoices, addProductwarning, addEditRDSchemeModal, retrodiscountscheme_fields } = useSelector((state) => {
        return {
            retro_discount_scheme_name: state.externalFilter.retro_discount_scheme_name,
            retro_discount_closed_for_billing: state.externalFilter.retro_discount_closed_for_billing,
            retro_discount_closed_for_invoices: state.externalFilter.retro_discount_closed_for_invoices,
            addProductwarning: state.modalActions.closeWaringAddProduct,
            addEditRDSchemeModal: state.modalActions.addEditRDSchemeModal,
            retrodiscountscheme_fields: state.retrodiscountscheme
        }
    });
    const dispatch = useDispatch();
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })
    
    const tabs = [
        {
            label: "General",
            Component: <RetroGeneral />
        },
        {
            label: "Categories",
            Component: <RetroCategories />
        },
        {
            label: "Distribution Channel",
            Component: <RetroDistributionChannel />
        },
        {
            label: "Members",
            Component: <RetroMember />
        },
        {
            label: "Programmes",
            Component: <RetroProgrammes />
        },
        {
            label: "Product",
            Component: <RetroProduct />
        },
        {
            label: "Suppliers",
            Component: <RetroSuppliers />
        },
        
        {
            label: "Volume Thresholds",
            Component: <RetroVolumeThreshold />
        },
        {
            label: "Pay Member Dates",
            Component: <RetroPayMemberDates />
        },
    ];
    const columns = [
        {
            headerName: "ID",
            field: "id",
            flex: 1,
            minWidth: 90
        },
        {
            headerName: "Name",
            field: "name",
            flex: 1,
            minWidth: 405
        },
        {
            headerName: "Start Date",
            field: "startDate",
            width: 130
        },
        {
            headerName: "End Date",
            field: "endDate",
            width: 130
        },
        {
            headerName: "Cat",
            field: "countcategories",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Checkbox
                    checked={params.row.countcategories > 0 ? true : false}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
        },
        {
            headerName: "DC",
            field: "countdistributionChannels",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Checkbox
                    checked={params.row.countdistributionChannels > 0 ? true : false}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
        },
        {
            headerName: "Mbr",
            field: "countmembers",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Checkbox
                    checked={params.row.countmembers > 0 ? true : false}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
        },
        {
            headerName: "Prog",
            field: "countprogrammes",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Checkbox
                    checked={params.row.countprogrammes > 0 ? true : false}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
        },
        {
            headerName: "Prod",
            field: "countproducts",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Checkbox
                    checked={params.row.countproducts > 0 ? true : false}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
        },
        {
            headerName: "Sup",
            field: "countsuppliers",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Checkbox
                    checked={params.row.countsuppliers > 0 ? true : false}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
        },
        {
            headerName: "VT",
            field: "countretroVolumeThresholds",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Checkbox
                    checked={params.row.countretroVolumeThresholds > 0 ? true : false}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
        },
        {
            headerName: "Dates",
            field: "countpayMemberDates",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Checkbox
                    checked={params.row.countpayMemberDates > 0 ? true : false}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
        },
        {
            headerName: "Closed for invoices",
            field: "closedForInvoices",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
        },
        {
            headerName: "Closed for billing",
            field: "closedForBilling",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} onDelete={deleteRetroDiscountSchemeData} />;
            }
        }
    ];



    

    const handleFilterInput = (value, id) => {
        //setState({ ...state, [id]: value });
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    };

    const handleChange = (checked, name) => {
        setState({ ...state, [name]: checked })
        dispatch(setFilter({
            key: name,
            value: checked
        }))
    };

    const selectedAutoComplete = (value, id) => {
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    }

    const filterBtn = () => {
        dispatch(resetDataGrid());
        dispatch(applyFilter({
            key: 'filterList',
            value: {
                "name": retro_discount_scheme_name,
                "closedForBilling": retro_discount_closed_for_billing,
                "closedForInvoices": retro_discount_closed_for_invoices
            }
        }))
        dispatch(filterFlagChange(true));
        dispatch(filterResetFlagChange(false));
        dispatch(setRowPage({ pageData: 10 }));
        dispatch(setPageNo({ page: 1 }));
    }
    const resetFilter = () => {
        if (retro_discount_scheme_name !== '' || (retro_discount_closed_for_billing != false) || (retro_discount_closed_for_invoices != false)) {
            dispatch(resetExternalFilter())
            dispatch(resetDataGrid());
        }
        setState({ ...state, retro_discount_closed_for_billing: false, retro_discount_closed_for_invoices: false });
        dispatch(filterResetFlagChange(true));
    }
    const addRDSchemeModal = () => {
        dispatch(hasTabEdit({ editTab: true }))
        dispatch(handleRDSchemeModal({
            addEditRDSchemeModal: true
        }))
    }
    // const hideNewPdcProductModal = (event) => {
    //     setState({ ...state, openretrodiscountschemeModal: false })
    // } 

    const deleteRetroDiscountSchemeData = (row) => {
        dispatch(setDeleteList(row))
        dispatch(setRetroState({
            key: 'retroDiscountSchemeStartSingleDelete',
            value: true
        }))
        dispatch(warningModal({
            yesHide: !addProductwarning
        }))
    }

    useEffect(() => {
        if (retrodiscountscheme_fields.retroDiscountSchemeConfirmSingleDelete) {
            deleteSingleRow()
        }
    }, [retrodiscountscheme_fields.retroDiscountSchemeConfirmSingleDelete])


    const deleteSingleRow = async () => {
        if (retrodiscountscheme_fields.deleteList !== null) {
            try {
                setState({ ...state, pageLoader: true })
                const { data } = await axiosRetroDiscountSchemes.delete(`retrodiscount/${retrodiscountscheme_fields.deleteList.id}/removeretrodiscountscheme`);
                if (data.success) {
                    setState({ ...state, pageLoader: false });
                    dispatch(setDeleteList(null));
                    resetSingleDeleteRowDispatch();
                    setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Retrospective Discount Scheme Delete Successfully", type: "success" });
                    setTimeout(() => {
                        dispatch(filterFlagChange(true));
                        dispatch(filterResetFlagChange(false));
                    }, 1500);
                    // try {
                    //     const { data } = await axiosRetroDiscountSchemes.post(`retrodiscount/filter`, {});
                    //     if (data !== null || data !== undefined) {
                    //         setState({ ...state, pageLoader: false })
                    //         setSnackBar({ ...snackBar, show: true, text: "Retrospective Discount Scheme Delete Successfully", type: "success" })
                    //         dispatch(setDeleteList(null))
                    //         resetSingleDeleteRowDispatch()
                    //         setTimeout(() => {
                    //             window.location.reload();
                    //         }, 2000);
                    //     }
                    // } catch (error) {
                    //     const { response: { data, status } } = error;
                    //     if (status === 500 && data.errorType === "Business") {
                    //         setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" });
                    //     } else {
                    //         setSnackBar({ ...snackBar, show: true, text: "Something went wrong!", type: "error", })
                    //     }
                    //     setState({ ...state, pageLoader: false })
                    //     resetSingleDeleteRowDispatch()
                    // }
                }
            } catch (error) {
                const { response: { data, status } } = error;
                if (status === 500 && data.errorType === "Business") {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" });
                } else {
                    setSnackBar({ ...snackBar, show: true, text: "Something went wrong!", type: "error", })
                }
                setState({ ...state, pageLoader: false })
                resetSingleDeleteRowDispatch()
            }
        }
    }

    const resetSingleDeleteRowDispatch = () => {
        dispatch(setRetroState({
            key: 'retroDiscountSchemeStartSingleDelete',
            value: false
        }))
        dispatch(setRetroState({
            key: 'retroDiscountSchemeConfirmSingleDelete',
            value: false
        }))
    }

    useEffect(() => {
        /* load first first time */
        filterBtn();
    }, []);

    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Retro Discount Schemes" />
                <Box sx={{ marginBottom: 2.5 }}>
                    <TableFilters
                        filterFields={<>
                            <Box component="div"><InputText onChange={handleFilterInput} maxWidth={50} label="Name" id="retro_discount_scheme_name" size="small" /></Box>
                        </>}
                        filterCheckboxes={<>
                            <Box component="div">
                                <ThreeStateCheckbox checked={state.retro_discount_closed_for_billing} onChange={handleChange} name="retro_discount_closed_for_billing" id="retro_discount_closed_for_billing" label="Closed for billing" />
                            </Box>
                            <Box component="div">
                                <ThreeStateCheckbox checked={state.retro_discount_closed_for_invoices} onChange={handleChange} name="retro_discount_closed_for_invoices" id="retro_discount_closed_for_invoices" label="Closed for invoices" />
                            </Box>
                        </>}
                        filterBtns={
                            <>
                                <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                <Button variant="contained" onClick={filterBtn}>Apply</Button>
                            </>}>
                    </TableFilters>
                </Box>
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -3 } }}>
                    <div className='table-actionable'>
                        <Button variant="contained" size='small' onClick={(e) => addRDSchemeModal(e)} startIcon={<AddIcon />}>
                            New Retro Scheme
                        </Button>
                    </div>
                </Box>
                <DataTableRetroDiscountSchemesDashboard defaultGrid={state.defaultGrid} columns={columns}>

                </DataTableRetroDiscountSchemesDashboard>
                {
                    addEditRDSchemeModal && (
                        <AddEditRetrospective tabs={tabs}
                            onShow={addEditRDSchemeModal}

                        />
                    )
                }
                {
                    addProductwarning && (
                        <WarningModal size="small_overlay" onShow={addProductwarning} />
                    )
                }
                {snackBar.show && <SnackBarDialog
                    onShow={snackBar.show}
                    text={snackBar.text}
                    type={snackBar.type}
                    onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                />}
            </Container>

        </>
    );
}

export default RetrospectiveDiscountSchemes;
