import { Checkbox, MenuItem, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl, Autocomplete } from '@mui/material'
import { useEffect, useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useDispatch, useSelector } from 'react-redux';
import { warningModal, handleAddEditdiscountScheme } from "../../../store/slices/modals";
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import moment from "moment";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import axiosDiscountSchemes from '../../../services/utility/axios-discount-schemes';
import { setSelectedFilter, setdiscountSchemeEffectiveDate, discountSchemeInput, getIsError, setDiscountSchemeDeleteData, setHasEditData, setAutocomplete } from "../../../store/slices/discountScheme";
import SnackBarDialog from "../../../components/shared/SnackBarDialog";
import axiosMember from '../../../services/utility/axios-member';
import { filterFlagChange, filterResetFlagChange } from '../../../store/slices/externalFilter';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

const defaultValues = {
    loader: false,
    formValid: true,
    defaultmode: false,
    newdiscountSchemesGenData: [],
};

function DcGeneral({ onShow, size = '', onHide }) {

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const [isError, isSetError] = useState({
        bopaError: false,
    })

    const [state, setState] = useState(defaultValues);
    const [otherTabsOpen, setOtherTabsOpen] = useState(false);
    const [latestEndDate, setLatestEndDate] = useState();
    const [selectedSupplier, setselectedSupplier] = useState(null);
    const [open, setOpen] = useState(onShow);
    const { handleWarning, discountScheme_container } = useSelector((state) => {
        return {
            handleWarning: state.modalActions.closeWaringAddProduct,
            discountScheme_container: state.discountSchemes
        }
    });

    const dispatch = useDispatch();

    const handleClose = () => {
        //setState({ ...state, defaultmode: false })

        // dispatch(setAutocomplete({
        //     parent: 'member_tier_list',
        //     key: 'member_tier_selected',
        //     value: null
        // }))
        // dispatch(setAutocomplete({
        //     parent: 'member_tier_list',
        //     key: 'member_tier_options',
        //     value: []
        // }))

        if (onHide) {
            dispatch(setdiscountSchemeEffectiveDate({
                key: 'discountScheme_start_date',
                value: ""
            }))
            dispatch(setdiscountSchemeEffectiveDate({
                key: 'discountScheme_end_date',
                value: ""
            }))
            onHide(false)
        }
        setLatestEndDate('')
        dispatch(warningModal({
            yesHide: !handleWarning
        }))
    };
    useEffect(() => {
        const fetchMyAPI = async () => {
            if (discountScheme_container.member_tier_list.member_tier_options.length === 0) {
                axiosMember.get('/memberTier/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            dispatch(setAutocomplete({
                                parent: 'member_tier_list',
                                key: 'member_tier_options',
                                value: unique
                            }));
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
        }
        fetchMyAPI();
    }, [])
    const handleStartDate = (event) => {
        if (event === null) {
            dispatch(setdiscountSchemeEffectiveDate({
                key: 'discountScheme_start_date',
                value: null
            }))
            dispatch(getIsError({ key: 'discountScheme_start_date', value: true }))
        } else {
            dispatch(setdiscountSchemeEffectiveDate({
                key: 'discountScheme_start_date',
                value: moment(event, 'DD/MM/YYYY')
            }))
            dispatch(setdiscountSchemeEffectiveDate({
                key: 'discountScheme_end_date',
                value: null
            }))
        }
        if (discountScheme_container.hasEditData) {
            setState({ ...state, defaultmode: true })
        }
        setLatestEndDate((event === null) ? null : moment(event).add(1, 'days').format('DD/MM/YYYY'))
    }
    const handleEndDate = (event) => {
        dispatch(setdiscountSchemeEffectiveDate({
            key: 'discountScheme_end_date',
            value: (event === null) ? null : moment(event, 'DD/MM/YYYY')
        }))
        if (discountScheme_container.hasEditData) {
            setState({ ...state, defaultmode: true })
        }
    }
    const handleInputChange = (event) => {
        const { value, name } = event.target;
        if(name === 'special_price_value') {
            dispatch(discountSchemeInput({
                key: name,
                value: value.slice(0, 16)
            }))
        } else {
            dispatch(discountSchemeInput({
                key: name,
                value
            }))
        }
        
        if (discountScheme_container.hasEditData) {
            setState({ ...state, defaultmode: true })
        }
    }

    const handleSelection = (event, id) => {
        let val = event.target.value;
        if (val !== "" || val !== null) {
            dispatch(setSelectedFilter({
                key: id,
                value: val
            }))
            if (discountScheme_container.hasEditData) {
                setState({ ...state, defaultmode: true })
            }
        }
    }

    useEffect(() => {
        const inputNumber = document.querySelectorAll('.inputNumber');
        inputNumber.forEach(el => el.addEventListener('keypress', evt => {
            if (evt.which === 8) {
                return;
            }
            if (evt.which < 42 || evt.which > 60) {
                evt.preventDefault();
            }
        }));
    }, []);

    useEffect(() => {
        if (moment(discountScheme_container.discountScheme_start_date).isBefore(moment(), "day")) {
            isSetError({ ...isError, bopaError: true })
        } else {
            isSetError({ ...isError, bopaError: false })
        }
    }, [discountScheme_container.discountScheme_start_date])

    // useEffect(() => {
    //     if (discountScheme_container.add_edit_discountScheme_name === null || discountScheme_container.add_edit_discountScheme_name === "") {
    //         dispatch(getIsError({ key: 'add_edit_discountScheme_name', value: true }))
    //     } else {
    //         dispatch(getIsError({ key: 'add_edit_discountScheme_name', value: false }))
    //     }
    //     if (discountScheme_container.discountScheme_start_date === null || discountScheme_container.discountScheme_start_date === 'Invalid date' || discountScheme_container.discountScheme_start_date === "") {
    //         dispatch(getIsError({ key: 'discountScheme_start_date', value: true }))
    //     } else {
    //         dispatch(getIsError({ key: 'discountScheme_start_date', value: false }))
    //     }
    //     if (discountScheme_container.dc_bill_to === null || discountScheme_container.dc_bill_to === '') {
    //         dispatch(getIsError({ key: 'dc_bill_to', value: true }))
    //     } else {
    //         dispatch(getIsError({ key: 'dc_bill_to', value: false }))
    //     }
    //     if (discountScheme_container.dc_benefit === null || discountScheme_container.dc_benefit === '') {
    //         dispatch(getIsError({ key: 'dc_benefit', value: true }))
    //     } else {
    //         dispatch(getIsError({ key: 'dc_benefit', value: false }))
    //         if((discountScheme_container.dc_benefit === 'SpecialPrice' && discountScheme_container.special_price_value === '') || (discountScheme_container.dc_benefit === 'SpecialPrice' && discountScheme_container.special_price_value === null)) {
    //             dispatch(getIsError({ key: 'special_price_value', value: true }))
    //         } else {
    //             dispatch(getIsError({ key: 'special_price_value', value: false }))
    //         }
    //     }
    //     if (discountScheme_container.member_tier_list.member_tier_selected === null || discountScheme_container.member_tier_list.member_tier_selected === '') {
    //         dispatch(getIsError({ key: 'member_tier_selected', value: true }))
    //     } else {
    //         dispatch(getIsError({ key: 'member_tier_selected', value: false }))
    //     }

    //     if (discountScheme_container.member_tier_list.member_tier_selected === null || discountScheme_container.member_tier_list.member_tier_selected === '') {
    //         dispatch(getIsError({ key: 'member_tier_selected', value: true }))
    //     } else {
    //         dispatch(getIsError({ key: 'member_tier_selected', value: false }))
    //     }

    // }, [discountScheme_container])


    useEffect(() => {
        const { add_edit_discountScheme_name,
            discountScheme_start_date,
            dc_benefit,
            dc_bill_to,
            member_tier_selected,
            special_price_value
        } = discountScheme_container;
        if ((add_edit_discountScheme_name === "") || (discountScheme_start_date === "" || discountScheme_start_date === null) || (dc_benefit === "" || dc_benefit === null) || (dc_bill_to === "" || dc_bill_to === null) || (member_tier_selected === "" || member_tier_selected === null)) {
            setState({ ...state, formValid: true, defaultmode: false })
        }
        else {
            if (discountScheme_container.dc_benefit === 'SpecialPrice' && (special_price_value === "" || special_price_value === null)) {
                setState({ ...state, formValid: true, defaultmode: false })
            } else {
                setState({ ...state, formValid: false, defaultmode: true })
            }
        }
    }, [discountScheme_container]);

    useEffect(() => {
        const {
            add_edit_discountScheme_name,
            discountScheme_start_date,
            dc_benefit,
            dc_bill_to,
            member_tier_selected,
            special_price_value
        } = discountScheme_container.isError
        if (!add_edit_discountScheme_name && !discountScheme_start_date && !dc_benefit && !dc_bill_to && !member_tier_selected && !special_price_value) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true })
        }
    }, [discountScheme_container.isError])

    const handleSubmit = async (event) => {
        event.preventDefault();
        let discountSchemesGenData = null;
        if (!state.formValid) {
            if (moment(discountScheme_container.discountScheme_start_date).format('DD/MM/YYYY') === 'Invalid date' || (discountScheme_container.discountScheme_end_date !== "" && discountScheme_container.discountScheme_end_date !== null && moment(discountScheme_container.discountScheme_end_date).format('DD/MM/YYYY') === 'Invalid date')) {
                setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
            }
            else if (discountScheme_container.discountScheme_end_date !== null && discountScheme_container.discountScheme_end_date !== "" && (discountScheme_container.discountScheme_start_date > discountScheme_container.discountScheme_end_date)) {
                setSnackBar({ ...snackBar, show: true, text: "End date is less than Start Date!", type: "error", });
            }
            else {
                discountSchemesGenData = {
                    "id": discountScheme_container.hasEditData ? discountScheme_container.hasEditData.id : null,
                    "name": discountScheme_container.add_edit_discountScheme_name,
                    "startDate": discountScheme_container.discountScheme_start_date ? moment(discountScheme_container.discountScheme_start_date).format('DD/MM/YYYY') : null,
                    "endDate": discountScheme_container.discountScheme_end_date ? moment(discountScheme_container.discountScheme_end_date).format('DD/MM/YYYY') : null,
                    "billTo": discountScheme_container.dc_bill_to,
                    "benefit": discountScheme_container.dc_benefit,
                    "memberTier": discountScheme_container.member_tier_list.member_tier_selected ? discountScheme_container.member_tier_list.member_tier_selected : "",
                    "specialPrice": discountScheme_container.dc_benefit === 'SpecialPrice' ? Number(discountScheme_container.special_price_value).toFixed(2) : null
                }
                try {
                    setState({ ...state, loader: true })
                    const { data, status } = discountScheme_container.hasEditData ? await axiosDiscountSchemes.put(`discountSchemes/${discountScheme_container.hasEditData.id}`, { ...discountSchemesGenData }) : await axiosDiscountSchemes.post(`discountSchemes`, { ...discountSchemesGenData });
                    if (data.success === true && data.resultMap) {
                        setSnackBar({ ...snackBar, show: true, text: `Discount Schemes Details ${discountScheme_container.hasEditData ? 'Edited' : 'Added'} Successfully!`, type: "success", })
                        
                        dispatch(setHasEditData(data.resultMap.result))
                        // if (!discountScheme_container.hasEditData) {
                        //     setOtherTabsOpen(true)
                        // } else {
                        //     setTimeout(() => {
                        //         window.location.reload();
                        //     }, 1500);
                        // }
                        setTimeout(() => {
                            setState({ ...state, loader: false, formValid: true, newdiscountSchemesGenData: data.resultMap.result })
                            dispatch(handleAddEditdiscountScheme({
                                showAddEdit: true
                            }));
                            //dispatch(filterFlagChange(true));
                            //dispatch(filterResetFlagChange(false));
                        }, 1500);   
                        
                    }
                } catch (error) {
                    setState({ ...state, loader: false })
                    const { response: { data, status } } = error;
                    setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error", })
                    // setTimeout(() => {
                    //     handleClose()
                    // }, 3000);
                }
            }
        }
    };

    const handleOtherTabsOpen = () => {
        dispatch(setHasEditData(state.newdiscountSchemesGenData))
        setOtherTabsOpen(false)
    }
    const handleOtherTabsClose = () => {
        setOtherTabsOpen(false)
        setTimeout(() => {
            dispatch(handleAddEditdiscountScheme({
                showAddEdit: false
            }))
            window.location.reload();
        }, 1500);
    }

    const handleAutoComplete = (event, newValue, parentname, name) => {
        if (event.target.value !== "" || event.target.value !== null) {
            dispatch(setAutocomplete({
                parent: parentname,
                key: name,
                value: newValue
            }))
        }
        setState({ ...state, defaultmode: true })
    }

    const handleDecimal = (event) => {
        const valueDecimal = event.target.value === '' ? "" : (event.target.value == 0 ? 0 : Number(event.target.value).toFixed(2))
        
        dispatch(discountSchemeInput({
            key: event.target.name,
            value: valueDecimal
        }))
    }

    return (
        <>
            <form onSubmit={(e) => handleSubmit(e)}>
                <Grid item xs={12} md={12}>
                    <Item sx={{ marginBottom: 2.5 }}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} md={4.5} className="formLabelCntr">
                                <Item>
                                    <FormLabel htmlFor="add_edit_discountScheme_name" className="fontSemiBold mandatory">Name</FormLabel>
                                </Item>
                            </Grid>
                            <Grid item xs={12} md={5.15}>
                                <Item>
                                    <Box component="div" className=''>
                                        <FormControl size="small" fullWidth>
                                            <TextField
                                                inputProps={{ maxLength: 50 }}
                                                type="text"
                                                size="small"
                                                id="add_edit_discountScheme_name"
                                                aria-describedby="add_edit_discountScheme_name_error"
                                                onChange={(e) => handleInputChange(e)}
                                                value={discountScheme_container.add_edit_discountScheme_name}
                                                error={discountScheme_container.isError.add_edit_discountScheme_name}
                                                min={1}
                                                name="add_edit_discountScheme_name"
                                            //onBlur={(e) => checkQunique(e, 'isAggregationGroupNameValid?name')}
                                            />
                                            {discountScheme_container.isError.add_edit_discountScheme_name && (<FormHelperText className='errorHelperTxt' id="add_edit_discountScheme_name_error">This field is mandatory</FormHelperText>)}
                                        </FormControl>
                                    </Box>
                                </Item>
                            </Grid>
                        </Grid>
                    </Item>
                    <Item sx={{ marginBottom: 2.5 }}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} md={4.5} className="formLabelCntr">
                                <Item>
                                    <FormLabel htmlFor="discountScheme_start_date" className="fontSemiBold mandatory">Start Date</FormLabel>
                                </Item>
                            </Grid>
                            <Grid item xs={12} md={5.15}>
                                <Item>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DesktopDatePicker
                                            value={discountScheme_container.discountScheme_start_date}
                                            disabled={discountScheme_container.hasEditData !== null ? true : false}
                                            onChange={(e) => { handleStartDate(e) }}
                                            name="discountScheme_start_date"
                                            inputFormat="DD/MM/YYYY"
                                            // eslint-disable-next-line max-len
                                            renderInput={({ inputRef, inputProps, InputProps }) => (
                                                <Box component="div" className='date-picker-input'>
                                                    <FormControl size={'small'} fullWidth>
                                                        <TextField size="small" id="discountScheme_start_date"
                                                            ref={inputRef} {...inputProps}
                                                            placeholder=""
                                                            aria-describedby="discountScheme_start_date_error"
                                                            error={discountScheme_container.isError.discountScheme_start_date}
                                                            autoComplete="off"
                                                        />
                                                        {discountScheme_container.isError.discountScheme_start_date && (<FormHelperText className='errorHelperTxt' id="discountScheme_start_date_error">Start date is missing</FormHelperText>)}
                                                        <Box className='date-picker-icon'>
                                                            {InputProps?.endAdornment}
                                                        </Box>
                                                    </FormControl>
                                                </Box>
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Item>
                            </Grid>
                        </Grid>
                    </Item>
                    <Item sx={{ marginBottom: 2.5 }}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} md={4.5} className="formLabelCntr">
                                <Item>
                                    <FormLabel htmlFor="discountScheme_end_date" className="fontSemiBold">End Date</FormLabel>
                                </Item>
                            </Grid>
                            <Grid item xs={12} md={5.15}>
                                <Item>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DesktopDatePicker
                                            value={discountScheme_container.discountScheme_end_date}
                                            onChange={(e) => { handleEndDate(e) }}
                                            name="discountScheme_end_date"
                                            disabled={(discountScheme_container.discountScheme_start_date === null || discountScheme_container.discountScheme_start_date === '') ? true : false}
                                            minDate={latestEndDate ? moment(latestEndDate, 'DD/MM/YYYY') : moment(discountScheme_container.discountScheme_start_date, 'DD/MM/YYYY').add(1, 'days')}
                                            inputFormat="DD/MM/YYYY"
                                            // eslint-disable-next-line max-len
                                            renderInput={({ inputRef, inputProps, InputProps }) => (
                                                <Box component="div" className='date-picker-input'>
                                                    <FormControl size={'small'} fullWidth>
                                                        <TextField size="small" id="discountScheme_end_date"
                                                            ref={inputRef} {...inputProps}
                                                            placeholder=""
                                                            aria-describedby="discountScheme_end_date_error"
                                                            autoComplete="off"
                                                        />
                                                        {isError.endDate && (<FormHelperText id="discountScheme_end_date_error">Error Here</FormHelperText>)}
                                                        <Box className='date-picker-icon'>
                                                            {InputProps?.endAdornment}
                                                        </Box>
                                                    </FormControl>
                                                </Box>
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Item>
                            </Grid>
                        </Grid>
                    </Item>
                    <Item sx={{ marginBottom: 2.5 }}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} md={4.5} className="formLabelCntr">
                                <Item>
                                    <FormLabel htmlFor="dc_bill_to" className="fontSemiBold mandatory">Bill To</FormLabel>
                                </Item>
                            </Grid>
                            <Grid item xs={12} md={5.15}>
                                <Item >
                                    <Box component="div" className='' >
                                        <FormControl size={'small'} fullWidth>
                                            <Select value={discountScheme_container.dc_bill_to} onChange={(event) => handleSelection(event, "dc_bill_to")} id="dc_bill_to">
                                                <MenuItem style={{ fontSize: 14 }} value={"BrandOwner"}>Brand Owner</MenuItem>
                                                <MenuItem style={{ fontSize: 14 }} value={"Supplier"}>Supplier</MenuItem>
                                            </Select>
                                            {discountScheme_container.isError.dc_bill_to && (<FormHelperText className='errorHelperTxt' id="dc_bill_to_error">This field is mandatory</FormHelperText>)}
                                        </FormControl>
                                    </Box>
                                </Item>
                            </Grid>
                        </Grid>
                    </Item>
                    <Item sx={{ marginBottom: 2.5 }}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} md={4.5} className="formLabelCntr">
                                <Item>
                                    <FormLabel htmlFor="general_member_tier" className="fontSemiBold mandatory">Purchasing Tier</FormLabel>
                                </Item>
                            </Grid>
                            <Grid item xs={12} md={7.5}>
                                <Item>
                                    <Autocomplete
                                        defaultValue={discountScheme_container.member_tier_list.member_tier_selected}
                                        id="general_member_tier_search"
                                        options={discountScheme_container.member_tier_list.member_tier_options}
                                        getOptionLabel={(option) => option.name || ""}
                                        componentsProps={{
                                            paper: {
                                                sx: {
                                                    fontSize: '0.85rem'
                                                }
                                            }
                                        }}
                                        onChange={(event, newValue) => handleAutoComplete(event, newValue, 'member_tier_list', 'member_tier_selected')}
                                        renderInput={(params) =>
                                            <FormControl size={'small'} fullWidth sx={{ maxWidth: { lg: "68%", sm: '100%' } }}>
                                                <div className="input-group">
                                                    <TextField {...params}
                                                        size="small"
                                                        id="general_member_tier"
                                                        aria-describedby="general_member_tier-error"
                                                        error={discountScheme_container.isError.member_tier_selected}
                                                    />
                                                    {discountScheme_container.isError.member_tier_selected &&
                                                        (<FormHelperText className='errorHelperTxt' id="general_member_tier-error"> This field is mandatory </FormHelperText>)}
                                                </div>
                                            </FormControl>
                                        }
                                    />
                                </Item>
                            </Grid>
                        </Grid>
                    </Item>
                    <Item sx={{ marginBottom: 2.5 }}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} md={4.5} className="formLabelCntr">
                                <Item>
                                    <FormLabel htmlFor="add_edit_discountScheme_name" className="fontSemiBold mandatory">Benefit</FormLabel>
                                </Item>
                            </Grid>
                            <Grid item xs={12} md={5.15}>
                                <Item >
                                    <Box component="div" className='' >
                                        <FormControl size={'small'} fullWidth>
                                            <Select value={discountScheme_container.dc_benefit} onChange={(event) => handleSelection(event, "dc_benefit")} id="dc_benefit">
                                                <MenuItem style={{ fontSize: 14 }} value={"MatchBrandOwnerPrice"}>MatchBrandOwnerPrice</MenuItem>
                                                <MenuItem style={{ fontSize: 14 }} value={"SpecialPrice"}>Special Price</MenuItem>
                                                <MenuItem style={{ fontSize: 14 }} value={"FreeStock"}>FreeStock</MenuItem>
                                            </Select>
                                            {discountScheme_container.isError.dc_benefit && (<FormHelperText className='errorHelperTxt' id="dc_benefit_error">This field is mandatory</FormHelperText>)}
                                        </FormControl>
                                    </Box>
                                </Item>
                            </Grid>
                        </Grid>
                    </Item>
                    <Item sx={{ marginBottom: 2.5 }} style={{ display: discountScheme_container.dc_benefit === 'SpecialPrice' ? 'block' : 'none' }}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} md={4.5} className="formLabelCntr">
                                <Item>

                                </Item>
                            </Grid>
                            <Grid item xs={12} md={5.15}>
                                <Item>
                                    <Box component="div" className=''>
                                        <FormControl size="small" fullWidth>
                                            <TextField
                                                inputProps={{ maxLength: 50 }}
                                                type="number"
                                                size="small"
                                                id="special_price_value"
                                                aria-describedby="special_price_value_error"
                                                onChange={(e) => handleInputChange(e)}
                                                value={discountScheme_container.special_price_value}
                                                error={discountScheme_container.isError.special_price_value}
                                                min={1}
                                                name="special_price_value"
                                                className="inputNumber"
                                                disabled={discountScheme_container.dc_benefit === 'SpecialPrice' ? false : true}
                                                step="any"
                                                onBlur={(e) => handleDecimal(e) }
                                                onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                            />
                                            {((discountScheme_container.dc_benefit === 'SpecialPrice') && discountScheme_container.isError.special_price_value) && (<FormHelperText className='errorHelperTxt' id="special_price_value_error">This field is mandatory</FormHelperText>)}
                                        </FormControl>
                                    </Box>
                                </Item>
                            </Grid>
                        </Grid>
                    </Item>
                </Grid>
                <Grid container sx={{ marginTop: '20px' }}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Item>
                            <Grid container direction="row" alignItems="center" justifyContent="center">
                                <LoadingButton
                                    loading={state.loader}
                                    loadingPosition="center"
                                    endIcon=""
                                    variant="contained"
                                    type="submit" sx={{ marginRight: '15px' }}
                                    //disabled={state.formValid || state.defaultmode === false}
                                    // eslint-disable-next-line max-len
                                    disabled={discountScheme_container.hasEditData ? (discountScheme_container.isError.add_edit_discountScheme_name || discountScheme_container.isError.discountScheme_start_date || discountScheme_container.isError.dc_benefit || discountScheme_container.isError.dc_bill_to || discountScheme_container.isError.member_tier_selected) : (state.formValid || state.defaultmode === false)}
                                >Save General Details</LoadingButton>
                                <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                            </Grid>
                        </Item>
                    </Grid>
                </Grid>
                <Modal
                    open={otherTabsOpen}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                    keepMounted
                    onClose={(_, reason) => {
                        if (reason !== "backdropClick") {
                            handleOtherTabsClose();
                        }
                    }}
                >
                    <Box component="div" className="overlay" id="addEditModal">
                        <div className={`overlay-box small_overlay`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-content">
                                        <Box component="div" sx={{ textAlign: 'center', padding: 1.5 }}>
                                            <p className="fontMedium">Would you like to add other details ?</p>
                                            <Grid sx={{ paddingTop: 2 }} container direction="row" alignItems="center" justifyContent="center">
                                                <Button variant="contained" type="submit" sx={{ marginRight: '15px' }} onClick={handleOtherTabsOpen}>Yes</Button>
                                                <Button variant="outlined" onClick={handleOtherTabsClose}>No</Button>
                                            </Grid>
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </form>
            {snackBar.show && <SnackBarDialog
                onShow={snackBar.show}
                text={snackBar.text}
                type={snackBar.type}
                onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
            />}
        </>
    );
}

export default DcGeneral;