import { Checkbox, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl } from '@mui/material'
import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { warningModal, handleAddEditLevyBillingModal } from "../../../store/slices/modals";
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";
import LoadingButton from '@mui/lab/LoadingButton';
import axios_process_manager from '../../../services/utility/axios-process-manager';
import axios_utilities from "../../../services/utility/axios-utilities";
import { setAutocomplete, brandSearch, getIsError, setprocessManagerEffectiveDate, processManagerInput, resetprocessManager } from "../../../store/slices/processManager";
import SnackBarDialog from "../../../components/shared/SnackBarDialog";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

const defaultValues = {
    loader: false,
    formValid: true,
    defaultmode: false,
    newaggregationgroupData: [],
};

function LevyBillingModal({ onShow, size = '', onHide }) {
    
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const [state, setState] = useState(defaultValues);
    const [open, setOpen] = useState(onShow);
    const { handleWarning, processManager_container, showAddEditLevyBillingModal } = useSelector((state) => {
        return {
            handleWarning: state.modalActions.closeWaringAddProduct,
            processManager_container: state.processManagers,
            showAddEditLevyBillingModal: state.modalActions.showAddEditLevyBillingModal
        }
    });

    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const uname = accounts[0] && accounts[0].name;

    useEffect(() => {
        const fetchMyAPI = async () => {
            if (processManager_container.centralBillingScheme.billingScheme_options.length === 0) {
                axios_utilities.get('centralBillingScheme/drop-down').then(function (response) {
                    // handle success
                    const { status, data } = response;
                    if (data) {
                        const unique = data.filter((value, index, self) => self.findIndex((m) => m.name === value.name) === index);
                        dispatch(setAutocomplete({
                            parent: 'centralBillingScheme',
                            key: 'billingScheme_options',
                            value: unique
                        }));
                    }
                })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
        }
        fetchMyAPI();
    }, [])
    
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(handleAddEditLevyBillingModal({
            showAddEditLevyBillingModal: false
        }))
    };

    const handleCancel = () => {
        dispatch(resetprocessManager())
        dispatch(warningModal({
            yesHide: !handleWarning
        }))
    };
    
    const handleInputChange = (event) => {
        const { value, name } = event.target;
        dispatch(processManagerInput({
            key: name,
            value
        }))
    }

    const handleStartDate = (event) => {
        if (event === null) {
            dispatch(setprocessManagerEffectiveDate({
                key: 'levyBilling_cut_off_date',
                value: null
            }))
            dispatch(getIsError({ key: 'levyBilling_cut_off_date', value: true }))
        } else { 
            dispatch(setprocessManagerEffectiveDate({
                key: 'levyBilling_cut_off_date',
                value: moment(event,'DD/MM/YYYY')
            }))
        }
    }

    const handleSelection = (event, newValue, parentname, name) => {
        if (event.target.value !== "" || event.target.value !== null) {
            dispatch(brandSearch({
                parent: parentname,
                key: name,
                value: newValue
            }))
        }
    }

    useEffect(() => {
        if ((processManager_container.levyBilling_cut_off_date === null || processManager_container.levyBilling_cut_off_date === "") || (processManager_container.centralBillingScheme.billingScheme_selected === null || processManager_container.centralBillingScheme.billingScheme_selected === "")) {
            setState({ ...state, formValid: true, defaultmode: false })
        } else {
            setState({ ...state, formValid: false, defaultmode: true })
        }
    }, [processManager_container.levyBilling_cut_off_date, processManager_container.centralBillingScheme.billingScheme_selected])

    useEffect(() => {
        const { 
            levyBilling_cut_off_date, billingScheme_selected
        } = processManager_container.isError
        if (!levyBilling_cut_off_date && !billingScheme_selected) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true })
        }
    }, [processManager_container.isError])

    const handleSubmit = async (event) => {
        event.preventDefault();
        let executableData = null;
        if (!state.formValid) {
            if (moment(processManager_container.levyBilling_cut_off_date).format('DD/MM/YYYY') === 'Invalid date') {
                setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
            }
            else
            {
                executableData = {
                    "executable":"LevyBillingExecutable",
                    "user": uname,
                    "processExecutionDTO": {
                        "cutOffDate": processManager_container.levyBilling_cut_off_date ? moment(processManager_container.levyBilling_cut_off_date).format('DD/MM/YYYY') : null,
                        "additionalInfo": processManager_container.levyBilling_terms ? processManager_container.levyBilling_terms : null,
                        "centralBillingSchemeId": processManager_container.centralBillingScheme.billingScheme_selected.id
                    }
                }
                try {
                    setState({ ...state, loader: true })
                    const { data, status } =  await axios_process_manager.post(`sqs/processmanager`, { ...executableData });
                    if (data.isSuccess === true ) {
                        setState({ ...state, loader: false }) 
                        setSnackBar({...snackBar, show:true, text: data.message, type: "success", })
                        setTimeout(() => {
                            dispatch(handleAddEditLevyBillingModal({
                                showAddEditLevyBillingModal: false
                            }))
                        }, 1500);
                    }
                } catch (error) {
                    setState({ ...state, loader: false }) 
                    const { response: { data, status } } = error;
                    setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error", })
                }
            }
            
        }
    };

    
    return (
        <>
            { snackBar.show && <SnackBarDialog 
            onShow={snackBar.show} 
            text={snackBar.text} 
            type={snackBar.type} 
            onHide={(e) => {setSnackBar({...snackBar, show: e})}}
            />}

            <Modal
                open={open}
                aria-labelledby="add-edit-price-title"
                aria-describedby="add-edit-price-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Box component="div" className="overlay" id="addEditModal">
                        <div className={`overlay-box ${size ? size : ''}`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-header text-center"> Levy Billing Details
                                        <CancelIcon className="closeBtn" onClick={handleClose} />
                                    </div>
                                    <div className="overlay-content">
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="price_start_date" className="fontSemiBold mandatory">Cut-off Date</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Item>
                                                        <Box component="div" className=''>
                                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                <DesktopDatePicker
                                                                    value={processManager_container.levyBilling_cut_off_date}
                                                                    onChange={(e) => { handleStartDate(e) }}
                                                                    name="price_start_date"
                                                                    inputFormat="DD/MM/YYYY"
                                                                    // eslint-disable-next-line max-len
                                                                    renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                        <Box component="div" className='date-picker-input'>
                                                                            <FormControl size={'small'} fullWidth>
                                                                                <TextField size="small" id="price_start_date"
                                                                                    ref={inputRef} {...inputProps}
                                                                                    placeholder=""
                                                                                    aria-describedby="price_start_date_error"
                                                                                    error={processManager_container.isError.levyBilling_cut_off_date}
                                                                                    autoComplete="off"
                                                                                />
                                                                                {processManager_container.isError.levyBilling_cut_off_date && (<FormHelperText className='errorHelperTxt' id="price_start_date_error">Date is missing</FormHelperText>)}
                                                                                <Box className='date-picker-icon'>
                                                                                    {InputProps?.endAdornment}
                                                                                </Box>
                                                                            </FormControl>
                                                                        </Box>
                                                                    )}
                                                                />
                                                            </LocalizationProvider>
                                                            </Box>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="levyBilling_terms" className="fontSemiBold">Additional terms & conditions</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Item>
                                                            <Box component="div" className=''>
                                                                <FormControl size={'small'} fullWidth>
                                                                    <TextField
                                                                        inputProps={{ maxLength: 100 }}
                                                                        size="small"
                                                                        id="levyBilling_terms"
                                                                        onChange={(e) => handleInputChange(e)}
                                                                        value={processManager_container.levyBilling_terms}
                                                                        aria-describedby="levyBilling_terms_error"
                                                                        name="levyBilling_terms"
                                                                    />
                                                                </FormControl>
                                                            </Box>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="centralBillingScheme" className="fontSemiBold mandatory">Central Billing Scheme</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Item>
                                                            <Autocomplete
                                                                // eslint-disable-next-line max-len
                                                                defaultValue={processManager_container.centralBillingScheme.billingScheme_selected || (processManager_container.hasEditData ? processManager_container.hasEditData.centralBillingScheme : null)}
                                                                id="centralBillingScheme_search"
                                                                options={processManager_container.centralBillingScheme.billingScheme_options}
                                                                getOptionLabel={(option) => option.name || ""}
                                                                componentsProps={{
                                                                    paper: {
                                                                        sx: {
                                                                            fontSize: '0.85rem'
                                                                        }
                                                                    }
                                                                }}
                                                                onChange={(event, newValue) => handleSelection(event, newValue, 'centralBillingScheme', 'billingScheme_selected')}
                                                                renderInput={(params) =>
                                                                    <FormControl size={'small'} fullWidth>
                                                                        <div className="input-group">
                                                                            <TextField {...params}
                                                                                size="small"
                                                                                id="centralBillingScheme"
                                                                                aria-describedby="centralBillingScheme-error"
                                                                                error={processManager_container.isError.billingScheme_selected}
                                                                            />
                                                                            {processManager_container.isError.billingScheme_selected &&
                                                                                (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)}
                                                                        </div>
                                                                    </FormControl>
                                                                }

                                                            />
                                                        </Item>

                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                        <Grid container sx={{ marginTop: '20px' }}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Item>
                                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                                        <LoadingButton
                                                                loading={state.loader}
                                                                loadingPosition="center"
                                                                endIcon=""
                                                                variant="contained"
                                                                type="submit" sx={{ marginRight: '15px' }}
                                                                disabled={state.formValid || state.defaultmode === false}
                                                            >Save</LoadingButton>
                                                        <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </form>
            </Modal>
        </>
    );
}

export default LevyBillingModal;