import { Box, Modal, Tabs, Tab } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { warningModal, handleAddEditModal } from "../../../store/slices/modals";
import { resetlevyScheme } from "../../../store/slices/levyScheme";
import LevySchemeGeneral from "./levySchemeGeneral"; 
import LevyschemeDistributionChannel from "./levyschemeDistributionChannel";
import LevyschemeSupplier from "./levyschemeSupplier";
import LevyschemeProducts from "./levyschemeProducts";
import { filterFlagChange, filterResetFlagChange } from '../../../store/slices/externalFilter';

function TabPanel(props) {
    const { children, value, index, id, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`${id}-tab-pannel-${index}`}
            aria-labelledby={`${id}-tab-${index}`}
            {...other}
            className="tabs-pannel"
        >
            {value === index && (
                <>{children}</>
            )}
            {/* {children} */}
        </div>
    );
}

export default function AddEditLevyScheme({ onShow, size = '' }) {

    const { handleWarning, levyscheme_container } = useSelector((state) => {
        return {
            handleWarning: state.modalActions.closeWaringAddProduct,
            levyscheme_container: state.levySchemes
        }
    });
    
    const dispatch = useDispatch();
    const handleClose = () => {
        // dispatch(warningModal({
        //     yesHide: !handleWarning
        // }))
        dispatch(handleAddEditModal({
            showAddEdit: false
        }));
        dispatch(filterFlagChange(true));
        dispatch(filterResetFlagChange(false));
        dispatch(resetlevyScheme());
    };

    const [value, setValue] = useState(0);
    function handleChange(event, newValue) {
        setValue(newValue);
      }

    return (
        <>
            <Modal
                open={onShow || false}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <Box component="div" className="overlay" id="addEditModal">
                    <div className={`overlay-box large-size ${size ? size : ''}`}>
                        <div className="content">
                            <div className="overlayBody">
                                <div className="overlay-header text-center"> Levy Scheme Details
                                    <CancelIcon className="closeBtn" onClick={handleClose} />
                                </div>
                                <div className="overlay-content">
                                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            variant="scrollable"
                                            scrollButtons={false}
                                            aria-label="scrollable prevent tabs example"
                                        >
                                                <Tab className="tabs-pannel" label="General" sx={{ minWidth: 'auto', paddingX: { xs: '5px', md: '0.85rem' }, marginLeft: '15px', fontWeight: '700', textTransform: 'capitalize' }} />
                                                <Tab className="tabs-pannel" disabled={!levyscheme_container.hasEditData} label="Products" sx={{ minWidth: 'auto', paddingX: { xs: '5px', md: '0.85rem' }, marginLeft: '15px', fontWeight: '700', textTransform: 'capitalize'}} />
                                                <Tab className="tabs-pannel" disabled={!levyscheme_container.hasEditData} label="Suppliers" sx={{ minWidth: 'auto', paddingX: { xs: '5px', md: '0.85rem' }, marginLeft: '15px', fontWeight: '700', textTransform: 'capitalize' }} />
                                                <Tab className="tabs-pannel" disabled={!levyscheme_container.hasEditData} label="Distribution Channel" sx={{ minWidth: 'auto', paddingX: { xs: '5px', md: '0.85rem' }, marginLeft: '15px', fontWeight: '700', textTransform: 'capitalize' }} />
                                        </Tabs>
                                    </Box>
                                    <TabPanel value={value} index={0}>
                                        <LevySchemeGeneral />
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <LevyschemeProducts />
                                    </TabPanel>
                                    <TabPanel value={value} index={2}>
                                        <LevyschemeSupplier />
                                    </TabPanel>
                                    <TabPanel value={value} index={3}>
                                        <LevyschemeDistributionChannel />
                                    </TabPanel>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}
