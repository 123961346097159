import { Checkbox, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl } from '@mui/material'
import { useEffect, useState } from 'react';
import { warningModal } from "../../../../../store/slices/modals";
import { setLatestPdcData } from "../../../../../store/slices/generalPdcProduct";
import { setchannel_chargeEffectiveDate, setEditChannelCharge, getIsError, setchannel_chargeDateList, setChannelChargeInput } from "../../../../../store/slices/pricePdcProduct";
import { useDispatch, useSelector } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import axiosProductDistribution from '../../../../../services/utility/axios-product-distribution';
import LoadingButton from '@mui/lab/LoadingButton';
import SnackBarDialog from '../../../../../components/shared/SnackBarDialog';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

const defaultValues = {
    startDate: null,
    endDate: null,
    loadSupplier: false,
    formValid: true,
    defaultmode: false,
    id: 0
};

function AddEditChargeModal({ onShow, size = '', onHide }) {

    const [state, setState] = useState(defaultValues);
    //const [nullEndDateRows, setNulEndDateRows] = useState([]);
    const [isError, isSetError] = useState({
        bopaError: false,
    })
    const [loader, setLoader] = useState(false)
    const [open, setOpen] = useState(onShow);
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const {
        channel_charge_container,
        editTabData,
        editChannelCharge,
        hasEditData,
        latest_pdc_gen_id,
        handleWarning
    } = useSelector((state) => {
        return {
            channel_charge_container: state.pricePdcProducts,
            editTabData: state.pricePdcProducts.editTabData,
            editChannelCharge: state.pricePdcProducts.editChannelCharge,
            hasEditData: state.pricePdcProducts.hasEditData,
            latest_pdc_gen_id: state.generalPdcProducts.latestGeneralData,
            handleWarning: state.modalActions.closeWaringAddProduct
        }
    })

    const dispatch = useDispatch();

    const handleClose = () => {
        if (onHide) {
            dispatch(setchannel_chargeEffectiveDate({
                key: 'channel_charge_start_date',
                value: null
            }))
            dispatch(setchannel_chargeEffectiveDate({
                key: 'channel_charge_end_date',
                value: null
            }))
            onHide(false)
        }
        dispatch(setChannelChargeInput(''))
        dispatch(setEditChannelCharge(null))
        dispatch(getIsError({ key: 'channel_charge_p_c', value: false }))
        dispatch(getIsError({ key: 'channel_charge_start_date', value: false }))
        //setState({ ...state, defaultmode: false })
        // dispatch(warningModal({
        //     yesHide: !handleWarning
        // }))
    };

    const handleStartDate = (event) => {
        // if(channel_charge_container.editChannelCharge === '' || channel_charge_container.editChannelCharge === null) {
        //     dispatch(setchannel_chargeEffectiveDate({
        //         key: 'channel_charge_start_date',
        //         value: moment(event,'DD/MM/YYYY')
        //     }))
        // }
        if (event === null) {
            dispatch(setchannel_chargeEffectiveDate({
                key: 'channel_charge_start_date',
                value: null
            }));
            dispatch(getIsError({ key: 'channel_charge_start_date', value: true }))
        }
        else {
            dispatch(setchannel_chargeEffectiveDate({
                key: 'channel_charge_start_date',
                value: moment(event, 'DD/MM/YYYY')
            }))
            dispatch(setchannel_chargeEffectiveDate({
                key: 'channel_charge_end_date',
                value: null
            }))
        }
        if (editChannelCharge) {
            setState({ ...state, defaultmode: true })
        }
    }
    const handleEndDate = (event) => {
        if (event === null) {
            dispatch(setchannel_chargeEffectiveDate({
                key: 'channel_charge_end_date',
                value: null
            }))
        } else {
            dispatch(setchannel_chargeEffectiveDate({
                key: 'channel_charge_end_date',
                value: moment(event, 'DD/MM/YYYY')
            }))
        }
        setState({ ...state, defaultmode: true })
    }

    const handleInputChange = (event) => {
        const { value, name } = event.target;
        if(name === 'channel_charge_p_c'){
            dispatch(setChannelChargeInput(value.slice(0, 16)))
        } else {
            dispatch(setChannelChargeInput(value))
        }
        setState({ ...state, defaultmode: true })
    }

    useEffect(() => {
        if (moment(channel_charge_container.channel_charge_start_date).isBefore(moment(), "day")) {
            isSetError({ ...isError, bopaError: true })
        } else {
            isSetError({ ...isError, bopaError: false })
        }
    }, [channel_charge_container.channel_charge_start_date])

    useEffect(() => {
        if ((channel_charge_container.channel_charge_p_c === null || channel_charge_container.channel_charge_p_c === "") || (channel_charge_container.channel_charge_start_date === null || channel_charge_container.channel_charge_start_date === 'Invalid date' || channel_charge_container.channel_charge_start_date === "")) {
            setState({ ...state, formValid: true, defaultmode: false })
        } else {
            setState({ ...state, formValid: false, defaultmode: true })
        }
    }, [channel_charge_container.channel_charge_p_c, channel_charge_container.channel_charge_start_date])

    useEffect(() => {
        const {
            channel_charge_p_c,
            channel_charge_start_date
        } = channel_charge_container.isError
        if (!channel_charge_p_c && !channel_charge_start_date) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true })
        }

    }, [channel_charge_container.isError])

    // useEffect(() => {
    //     let obj = channel_charge_container.channel_charge_date.find(o => o.endDate === null) ? channel_charge_container.channel_charge_date.find(o => o.endDate === null) : null;
    //     setNulEndDateRows(obj || null)
    // }, [channel_charge_container])

    const handleSubmit = async (event) => {
        event.preventDefault();
        let addeditChannelCharge = null;
        let editPreviousNullData = null;
        var id = '';
        if (!state.formValid) {
            if (moment(channel_charge_container.channel_charge_start_date).format('DD/MM/YYYY') === 'Invalid date' || (channel_charge_container.channel_charge_end_date !== "" && channel_charge_container.channel_charge_end_date !== null && moment(channel_charge_container.channel_charge_end_date).format('DD/MM/YYYY') === 'Invalid date')) {
                setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
            }
            else if (channel_charge_container.channel_charge_end_date !== null && channel_charge_container.channel_charge_end_date !== "" && (channel_charge_container.channel_charge_start_date > channel_charge_container.channel_charge_end_date)) {
                setSnackBar({ ...snackBar, show: true, text: "End date is less than Start Date!", type: "error", });
            }
            else {
                addeditChannelCharge = {
                    "charge": Number(channel_charge_container.channel_charge_p_c).toFixed(2),
                    "startDate": channel_charge_container.channel_charge_start_date ? moment(channel_charge_container.channel_charge_start_date).format('DD/MM/YYYY') : null,
                    "endDate": channel_charge_container.channel_charge_end_date ? moment(channel_charge_container.channel_charge_end_date).format('DD/MM/YYYY') : null
                }

                try {

                    if (!editChannelCharge) {
                        setLoader(true)
                        if (hasEditData) {
                            id = editTabData ? editTabData.id : hasEditData.id;
                        } else {
                            id = latest_pdc_gen_id;
                        }

                        //previous null end date update
                        // try {
                        //     if (nullEndDateRows !== null) {
                        //         editPreviousNullData = {
                        //             "charge": nullEndDateRows.charge,
                        //             "startDate": nullEndDateRows.startDate ? moment(nullEndDateRows.startDate).format('DD/MM/YYYY') : null,
                        //             "endDate": channel_charge_container.channel_charge_start_date ? moment(channel_charge_container.channel_charge_start_date).subtract(1, 'days').format('DD/MM/YYYY') : null
                        //         }
                        //         const { objdata } = await axiosProductDistribution.put(`pdcs/${id}/pdcChannelCharges/${nullEndDateRows.id}`, { ...editPreviousNullData })
                        //     }
                        // } catch (error) {
                        //     //handle error
                        // }

                        const { data } = await axiosProductDistribution.post(`pdcs/${id}/pdcChannelCharges`, { ...addeditChannelCharge });
                        if (data.success) {
                            setLoader(false)
                            setSnackBar({ ...snackBar, show: true, text: "Channel charge added successfully", type: "success", })
                            const { data } = await axiosProductDistribution.get(`pdcs/${id}/pdcChannelCharges`);
                            if (data !== null || data !== undefined) {
                                dispatch(setchannel_chargeDateList(data.items))
                                dispatch(setLatestPdcData(id))
                            }
                            setTimeout(() => {
                                handleClose()
                                //window.location.reload();
                            }, 1000);
                        }
                    }
                    else {
                        setLoader(true)
                        if (hasEditData) {
                            id = editTabData ? editTabData.id : hasEditData.id;
                        } else {
                            id = latest_pdc_gen_id;
                        }
                        const { data } = await axiosProductDistribution.put(`pdcs/${id}/pdcChannelCharges/${editChannelCharge.id}`, { ...addeditChannelCharge })
                        if (data.success) {
                            setLoader(false)
                            const { data } = await axiosProductDistribution.get(`pdcs/${id}/pdcChannelCharges`);
                            if (data !== null || data !== undefined) {
                                dispatch(setchannel_chargeDateList(data.items))
                                dispatch(setLatestPdcData(id))
                                setSnackBar({ ...snackBar, show: true, text: "Channel charge updated successfully", type: "success", })
                                setTimeout(() => {
                                    handleClose()
                                    //window.location.reload();
                                }, 1000);
                            }
                        }
                    }
                } catch (error) {
                    setLoader(false)
                    const { response: { data, status } } = error;
                    setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error" });
                    // setTimeout(() => {
                    //     handleClose()
                    // }, 3000);
                }
            }
        }
    }

    const handleDecimal = (event) => {
        const valueDecimal = event.target.value === '' ? "" : (event.target.value == 0 ? 0 : Number(event.target.value).toFixed(2))
        dispatch(setChannelChargeInput(valueDecimal))
    }

    const goTodaypsd = (event) => {       
        dispatch(setchannel_chargeEffectiveDate({
            key: 'channel_charge_start_date',
            value: moment()
        }))
    }

    const goTodayped = (event) => {        
        dispatch(setchannel_chargeEffectiveDate({
            key: 'channel_charge_end_date',
            value: moment()
        }))
    }

    const CustomActionbarpsd = () => {

        return (
            <Box sx={{ marginTop: '-15px', padding: '0 0 10px 0', textAlign: 'center' }}>
                <Button sx={{ fontSize: '16px' }} onClick={(e) => goTodaypsd(e)}>Today</Button>
            </Box>
        );
    };

    const CustomActionbarped = () => {

        return (
            <Box sx={{ marginTop: '-15px', padding: '0 0 10px 0', textAlign: 'center' }}>
                <Button sx={{ fontSize: '16px' }} onClick={(e) => goTodayped(e)}>Today</Button>
            </Box>
        );
    };

    
    return (
        <>
            <Modal
                open={open}
                aria-labelledby="add-edit-price-title"
                aria-describedby="add-edit-price-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Box component="div" className="overlay" id="addEditModal">
                        <div className={`overlay-box ${size ? size : ''}`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-header text-center"> Charge Details
                                        <CancelIcon className="closeBtn" onClick={handleClose} />
                                    </div>
                                    <div className="overlay-content">
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="price_start_date" className="fontSemiBold mandatory">Start Date</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={5.15}>
                                                        <Item>
                                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                <DesktopDatePicker
                                                                    value={channel_charge_container.channel_charge_start_date}
                                                                    disabled={channel_charge_container.editChannelCharge ? true : false}
                                                                    components={{ ActionBar: CustomActionbarpsd }}
                                                                    onChange={(e) => { handleStartDate(e) }}
                                                                    name="price_start_date"
                                                                    inputFormat="DD/MM/YYYY"
                                                                    // eslint-disable-next-line max-len
                                                                    renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                        <Box component="div" className='date-picker-input'>
                                                                            <FormControl size={'small'} fullWidth>
                                                                                <TextField size="small" id="price_start_date"
                                                                                    ref={inputRef} {...inputProps}
                                                                                    placeholder=""
                                                                                    aria-describedby="price_start_date_error"
                                                                                    error={channel_charge_container.isError.channel_charge_start_date}
                                                                                    autoComplete="off"
                                                                                />
                                                                                {channel_charge_container.isError.channel_charge_start_date && (<FormHelperText className='errorHelperTxt' id="price_start_date_error">Start date is missing</FormHelperText>)}
                                                                                <Box className='date-picker-icon'>
                                                                                    {InputProps?.endAdornment}
                                                                                </Box>
                                                                            </FormControl>
                                                                        </Box>
                                                                    )}
                                                                />
                                                            </LocalizationProvider>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="price_end_date" className="fontSemiBold">End Date</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={5.15}>
                                                        <Item>
                                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                <DesktopDatePicker
                                                                    defaultCalendarMonth={channel_charge_container.channel_charge_start_date ? moment(channel_charge_container.channel_charge_start_date, "DD/MM/YYYY").add(1, 'days') : null}
                                                                    value={channel_charge_container.channel_charge_end_date}
                                                                    components={{ ActionBar: CustomActionbarped }}
                                                                    onChange={(e) => { handleEndDate(e) }}
                                                                    name="price_end_date"
                                                                    minDate={moment(channel_charge_container.channel_charge_start_date, "DD/MM/YYYY").add(1, 'days')}
                                                                    inputFormat="DD/MM/YYYY"
                                                                    // eslint-disable-next-line max-len
                                                                    renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                        <Box component="div" className='date-picker-input'>
                                                                            <FormControl size={'small'} fullWidth>
                                                                                <TextField size="small" id="price_end_date"
                                                                                    ref={inputRef} {...inputProps}
                                                                                    placeholder=""
                                                                                    aria-describedby="price_end_date_error"
                                                                                    autoComplete="off"
                                                                                />
                                                                                <Box className='date-picker-icon'>
                                                                                    {InputProps?.endAdornment}
                                                                                </Box>
                                                                            </FormControl>
                                                                        </Box>
                                                                    )}
                                                                />
                                                            </LocalizationProvider>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="brands_owner_search" className="fontSemiBold mandatory">P or C</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={5.15}>
                                                        <Item>
                                                            <div className="input-group">
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        inputProps={{ maxLength: 100 }}
                                                                        size="small"
                                                                        id="channel_charge_p_c"
                                                                        aria-describedby="channel_charge_p_c_error"
                                                                        onChange={(e) => handleInputChange(e)}
                                                                        value={channel_charge_container['channel_charge_p_c']}
                                                                        error={channel_charge_container.isError.channel_charge_p_c}
                                                                        name="channel_charge_p_c"
                                                                        type="number"
                                                                        className="inputNumber"
                                                                        onBlur={(e) => handleDecimal(e) }
                                                                        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                                                    />
                                                                    {channel_charge_container.isError.channel_charge_p_c && (<FormHelperText className='errorHelperTxt' id="channel_charge_p_c_error">
                                                                        Please enter numeric value
                                                                    </FormHelperText>)}
                                                                </FormControl>
                                                            </div>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                        <Grid container sx={{ marginTop: '20px' }}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Item>
                                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                                        <LoadingButton
                                                            loading={loader}
                                                            loadingPosition="center"
                                                            endIcon=""
                                                            variant="contained"
                                                            type="submit" sx={{ marginRight: '15px' }}
                                                            //disabled={state.formValid || state.defaultmode === false}
                                                            // eslint-disable-next-line max-len
                                                            disabled={editChannelCharge ? (channel_charge_container.isError.channel_charge_p_c || channel_charge_container.isError.channel_charge_start_date) : (state.formValid || state.defaultmode === false)}
                                                        >Save</LoadingButton>
                                                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                    {snackBar.show && <SnackBarDialog
                        onShow={snackBar.show}
                        text={snackBar.text}
                        type={snackBar.type}
                        onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                    />}
                </form>

            </Modal>
        </>
    );
}

export default AddEditChargeModal;