import { Container, Box, Modal, Button, Grid, Checkbox, Popper, Grow, Paper, ClickAwayListener, MenuList, ButtonGroup, FormLabel, FormControl, TextField, FormControlLabel, Autocomplete, FormHelperText } from '@mui/material'
import IconButton from "@mui/material/IconButton";
import LoadingButton from '@mui/lab/LoadingButton';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useEffect, useState } from "react";
import { } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { useLayoutEffect } from "react";
import { hasTabEdit, setHasEditData, generalProductInput, brandSearch, setSpecification, setEanCodeList, memberInput, getIsError, resetMemberOutlets, setMemberAccountEdit, updateNextBtnStatus, setHasMemberOutletEditData, setMemberOutletEdit, memberBasicInput, resetMember, setAutocomplete, setMemberDate } from "../../../store/slices/memberBclValues";
import PageLoader from "../../../components/shared/PageLoader";
import { DataTableMember } from "../../../components/shared/data-table/DataTableMember";
import { DataTableClientSide } from '../../../components/shared/data-table/DataTableClientSide';
import Typography from '@mui/material/Typography';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import axiosMember from '../../../services/utility/axios-member';
import { Routes, Route, useNavigate } from 'react-router-dom';
import SnackBarDialog from "../../../components/shared/SnackBarDialog";
import Backdrop from '@mui/material/Backdrop';
import { filterFlagChange } from '../../../store/slices/externalFilter';
import moment from 'moment';
import refreshLambda from '../../../services/utility/refresh-lambda';
import PageLoaderStyle2 from '../../../components/shared/PageLoaderStyle2';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';


const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    marginBottom: '50px'
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'revert',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {

    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(4),
    borderTop: '1px solid rgba(0, 0, 0, .125)',

}));

var c_member_accountid = 0;

const MemberAccounts = () => {

    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { showAddEditModal, general_product_fields, member_fields } = useSelector((state) => {
        return {
            showAddEditModal: state.modalActions.hideAddEditModal,
            editTabData: state.generalProduct.editTabData,
            general_product_fields: state.generalProduct,
            member_fields: state.memberBclEntry

        }
    });

    const [state, setState] = useState({
        active_member: true,
        openNewProductModal: false,
        loader: false,
        pageLoader: false,
        // member_fields.editMemberAccountsTab ? true : false
        location: 'Member Accounts',
        locationlabel: "Member Outlet",
        member_id: member_fields.member_id || 0,
        member_accountid: member_fields.member_accountid || 0,
        formValid: false,
        defaultmode: false,
        pageLoader2: false,
        defaultGrid: {
            productActionButton: true,
            hasExport: true,
            addEditButton: true,
            // apiUrl: `/members/${member_fields.member_id ? member_fields.member_id : 0}/memberAccounts/${member_fields.member_accountid ? member_fields.member_accountid : 0}/memberOutlets`,
            // searchUrl: 'members/filter'
        }
    });

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
        vertical: '',
        horizontal: '',
        duration: 0
    });

    const [rows, setRows] = useState([]);
    const columns = [
        {
            headerName: "Member Outlet",
            field: "name",
            flex: 1.5,
            minWidth: 200,
        },
        {
            headerName: "Programmes",
            field: "memberProgrammes",
            minWidth: 250,
            valueGetter: (params) => params.row.memberProgrammes ? params.row.memberProgrammes.map(items => { return " " + items.programme.name }) : "None"
        },
        {
            headerName: "Purchasing Tier",
            field: "memberTier",
            flex: 1.5,
            valueGetter: (params) => params.row.memberTier ? params.row.memberTier.name : "",
            align: 'left',
            minWidth: 200
        },
        {
            headerName: "End Date",
            field: "endDate",
            minWidth: 90
        },
        {
            headerName: "Active",
            field: "active",
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
            headerAlign: "center",
            align: 'center',
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} />;
            },
        }
    ];

    useEffect(() => {
        // console.log("Error Values: ", member_fields.isError, state.isError);
        const { member_account_name, member_account_cbi, member_account_ledger } = member_fields.isError;
        // console.log("error fields", member_account_name, member_account_cbi, member_account_ledger, member_fields.member_account_name, member_fields.member_account_cbi, member_fields.member_account_ledger);
        // if (member_account_name === false && member_account_cbi === false && member_account_ledger === false && member_fields.member_account_name !== "" && member_fields.member_account_cbi !== "" && member_fields.member_account_ledger !== "") 
        // if (member_fields.member_account_name !== "" && member_account_name === false && member_fields.member_account_cbi !== "" && member_account_cbi === false && member_fields.member_account_ledger !== "" && member_account_ledger === false) 
        if (member_account_name === false && member_account_cbi === false && member_account_ledger === false) {
            // console.log("if")
            setState({ ...state, formValid: false })
        } else {
            // console.log("else", member_fields.isError)
            setState({ ...state, formValid: true })
        }
    }, [member_fields.isError])


    const handleChange = (panel) => (event, newExpanded) => {
        // event.preventDefault();
        event.stopPropagation();
        setExpanded(newExpanded ? panel : false);

    };

    const IconMenuLink = ({ params }) => {

        const { row } = params;
        const [anchorEl, setAnchorEl] = useState(null);
        const open = Boolean(anchorEl);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = (event, action) => {
            event.preventDefault();
            if (action === 'edit_member') {
                // dispatch(handleAddEditModal({
                //     showAddEdit: !showAddEditModal
                // }))
                // dispatch(hasTabEdit({ editTab: false }))
                setEditMemberOutletData(row);

            }
            setAnchorEl(null);
        };
        const setEditMemberOutletData = async (row) => {

            setState({ ...state, pageLoader: true });

            dispatch(setHasMemberOutletEditData(row));

            dispatch(memberBasicInput({
                key: 'member_outletid',
                value: row.id
            }));

            dispatch(memberBasicInput({
                key: 'member_outlet_name',
                value: row.name
            }));

            dispatch(memberBasicInput({
                key: 'member_outlet_oo',
                value: row.orwellOutlet
            }));

            dispatch(setAutocomplete({
                parent: 'member_tier',
                key: 'member_tier_selected',
                value: row.memberTier ? row.memberTier : null
            }))

            dispatch(setMemberDate({
                key: 'member_outlet_enddate',
                value: row.endDate ? moment(row.endDate, 'DD/MM/YYYY') : null
            }))

            dispatch(setMemberOutletEdit({ editMemberOutletsTab: true, member_outletid: row.id }));
            dispatch(updateNextBtnStatus({ key: 'disable_mpBtn', value: false }));


            try {


                const { data } = await axiosMember.get(`members/${member_fields.member_id}/memberAccounts/${member_fields.member_accountid}`);

                if (data) {

                    dispatch(memberBasicInput({
                        key: 'member_accountid',
                        value: data.id
                    }));

                    dispatch(memberBasicInput({
                        key: 'member_account_name',
                        value: data.name
                    }));

                    dispatch(memberBasicInput({
                        key: 'member_account_active',
                        value: data.active
                    }));

                    dispatch(memberBasicInput({
                        key: 'member_account_master',
                        value: data.master
                    }));

                    dispatch(memberBasicInput({
                        key: 'member_account_cbi',
                        value: data.centralBillingId
                    }));

                    dispatch(memberBasicInput({
                        key: 'member_account_ledger',
                        value: data.ledgerCode
                    }));

                    dispatch(memberBasicInput({
                        key: 'member_account_cname',
                        value: data.contactName
                    }));

                    dispatch(memberBasicInput({
                        key: 'member_account_email',
                        value: data.email
                    }));

                    dispatch(memberBasicInput({
                        key: 'member_account_addl1',
                        value: data.address1
                    }));

                    dispatch(memberBasicInput({
                        key: 'member_account_addl2',
                        value: data.address2
                    }));

                    dispatch(memberBasicInput({
                        key: 'member_account_addl3',
                        value: data.address3
                    }));

                    dispatch(memberBasicInput({
                        key: 'member_account_town',
                        value: data.town
                    }));

                    dispatch(memberBasicInput({
                        key: 'member_account_county',
                        value: data.county
                    }));

                    dispatch(memberBasicInput({
                        key: 'member_account_country',
                        value: data.country
                    }));

                    dispatch(memberBasicInput({
                        key: 'member_account_postcode',
                        value: data.postCode
                    }));

                    dispatch(filterFlagChange(true));
                    navigate('/suppliers-and-members/members/member-outlets');
                }
                else {
                    setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" });
                    setState({ ...state, pageLoader: false });
                }

            }
            catch (error) {
                const { response: { data, status }, message } = error;
                if (status === 500) {
                    setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
                } else {
                    setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
                }
                setState({ ...state, pageLoader: false });
            }



        }
        return (<>
            <IconButton
                aria-label="more"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                id={row.id + 1}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id={row.id}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                className="actionMenuBx"
            >
                <MenuItem onClick={(e) => handleClose(e, 'edit_member')}>Edit</MenuItem>
            </Menu>
        </>)
    }


    useEffect(() => {
        // console.log("Blank UseEffect");
        // console.log(c_member_accountid, member_fields)
        const fetchMyAPI = async () => {
            if (member_fields.editMemberAccountsTab && member_fields.member_accountid !== 0) {

                try {
                    setState({ ...state, pageLoader: true });
                    const { data } = await axiosMember.get(`members/${member_fields.member_id}/memberAccounts/${member_fields.member_accountid}/memberOutlets`);

                    if (data) {
                        setRows(data);
                        setState({ ...state, pageLoader: false });

                    }

                } catch (error) {
                    // eslint-disable-next-line max-len
                    const { response: { data, status }, message } = error;
                    // console.log(message, status, data);
                    setOpen(!open);
                    if (data.message !== "No MemberOutlets found") {
                        setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "No member outlet records found!", type: "error", });
                    }
                    setState({ ...state, pageLoader: false });
                    setRows([]);
                }


                // setState({ ...state, pageLoader: false });
            }
            else {
                setState({ ...state, pageLoader: false });
                setRows([]);
                c_member_accountid = 0;
            }



        }
        if (member_fields.editMembersTab === false && member_fields.hasMemberEditData === null && member_fields.member_id === 0) {
            // console.log(member_fields);
            navigate('/suppliers-and-members/members');
            // console.log("Page is Reloaded");
        }
        else {
            fetchMyAPI();
        }

        setState({ ...state, pageLoader: false });
        // console.log("Page Load: ", member_fields);
    }, [])






    // useEffect(() => {
    //     // console.log("change fields");
    //     const { member_account_name,
    //         member_account_cbi,
    //         member_account_ledger
    //     } = member_fields;

    //     if (member_fields.memberAccountsTab !== true) {
    //         // console.log(member_estate_group['estate_group_selected']);
    //         if (member_account_name === "" || member_account_cbi === "" || member_account_ledger === "") {
    //             setState({ ...state, formValid: true })
    //         }
    //         else {
    //             setState({ ...state, formValid: false })
    //         }
    //     }

    // }, [member_fields.member_account_name, member_fields.member_account_cbi, member_fields.member_account_ledger]);

    // useEffect(() => {
    //     // console.log("change fields");

    //     if (member_fields.member_account_name === "" && member_fields.isError.member_account_name === false) {
    //         setState({ ...state, formValid: true })
    //     } else if (member_fields.member_account_cbi === "" && member_fields.isError.member_account_cbi === false) {
    //         setState({ ...state, formValid: true })
    //     } else if (member_fields.member_account_ledger === "" && member_fields.isError.member_account_ledger === false) {
    //         setState({ ...state, formValid: true })
    //     } else {
    //         setState({ ...state, formValid: false })
    //     }

    // }, [member_fields]);

    const handleInputChange = (event) => {
        // setState({ ...state, defaultmode: true })
        event.preventDefault();
        const { value, name } = event.target;

        if (name === 'member_account_cbi' || name === 'member_account_ledger' || name === 'member_account_name') {
            dispatch(memberInput({
                key: name,
                value
            }))
        }
        else {
            dispatch(memberBasicInput({
                key: name,
                value
            }))
        }

    }

    const handleCheckboxChange = (event) => {
        const { checked, name } = event.target;
        // setState({ ...state, defaultmode: true })
        dispatch(memberBasicInput({
            key: name,
            value: checked
        }))
    };

    const handleCancel = (event) => {
        dispatch(filterFlagChange(true));
        navigate('/suppliers-and-members/members/member-details');
    }
    const handleDashboard = (event) => {
        localStorage.setItem("memberfilterflag", "true");
        dispatch(filterFlagChange(true));
        navigate('/suppliers-and-members/members');
    }



    const handleSubmit = async (event) => {

        event.preventDefault();

        if (member_fields.member_account_name === "" && member_fields.isError.member_account_name === false) {
            dispatch(getIsError({ key: 'member_account_name', value: true }))
        } else if (member_fields.member_account_cbi === "" && member_fields.isError.member_account_cbi === false) {
            dispatch(getIsError({ key: 'member_account_cbi', value: true }))
        } else if (member_fields.member_account_ledger === "" && member_fields.isError.member_account_ledger === false) {
            dispatch(getIsError({ key: 'member_account_ledger', value: true }))
        } else {
            let memberData = null;
            if (!state.formValid && member_fields.member_id !== 0) {
                memberData = {

                    "name": member_fields.member_account_name,
                    "active": member_fields.member_account_active,
                    "master": member_fields.member_account_master,
                    "centralBillingId": member_fields.member_account_cbi,
                    "ledgerCode": member_fields.member_account_ledger,
                    "contactName": member_fields.member_account_cname,
                    "email": member_fields.member_account_email,
                    "address1": member_fields.member_account_addl1,
                    "address2": member_fields.member_account_addl2,
                    "address3": member_fields.member_account_addl3,
                    "country": member_fields.member_account_country,
                    "county": member_fields.member_account_county,
                    "postCode": member_fields.member_account_postcode,
                    "town": member_fields.member_account_town
                }
                try {
                    setState({ ...state, loader: true });
                    // console.log(member_fields, c_member_accountid, member_fields.hasMemberAccountsEditData);
                    const { data, status } = member_fields.hasMemberAccountsEditData ? await axiosMember.put(`members/${member_fields.member_id}/memberAccounts/${member_fields.hasMemberAccountsEditData.id}/`, { ...memberData }) :
                        c_member_accountid !== 0 ? await axiosMember.put(`members/${member_fields.member_id}/memberAccounts/${c_member_accountid}/`, { ...memberData }) :
                            await axiosMember.post(`members/${member_fields.member_id}/memberAccounts`, { ...memberData });
                    // || member_fields.member_accountid !== 0
                    if (data.success === true) {
                        if (data.resultMap) {
                            setOpen(!open);
                            setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Member account added successfully!", type: "success", });
                            setState({ ...state, loader: false, formValid: false, member_accountid: data.resultMap.memberAccountID, defaultmode: false });
                            dispatch(setMemberAccountEdit({ editMemberAccountsTab: true, member_accountid: data.resultMap.memberAccountID }));
                            dispatch(updateNextBtnStatus({ key: 'disable_moBtn', value: false, defaultmode: false }));
                            // console.log(member_fields, state.member_accountid, data.resultMap.memberAccountID, c_member_accountid);
                            c_member_accountid = data.resultMap.memberAccountID;
                            // console.log(c_member_accountid);
                        }
                        else {
                            if (data.message) {
                                setState({ ...state, loader: false, formValid: false });
                                dispatch(updateNextBtnStatus({ key: 'disable_moBtn', value: false }));
                                setOpen(!open);
                                setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Member account edited successfully!", type: "success", });

                            }
                        }

                    }
                    else {
                        setOpen(!open);
                        setSnackBar({ ...snackBar, show: true, text: "Something went wrong! Member data missing, please go one step back.", type: "error", });
                    }
                } catch (error) {
                    const { response: { data, status } } = error;
                    // dispatch(setMemberAccountEdit({ editMemberAccountsTab: false }))
                    // dispatch(hasTabEdit({ editTab: true }))
                    setOpen(!open);
                    setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error" })
                    setState({ ...state, loader: false, formValid: false })
                }
            }
            else {
                setOpen(!open);
                setSnackBar({ ...snackBar, show: true, text: "Something went wrong! Member data missing, please go one step back.", type: "error", });
            }
        }



    }

    const handleBClick = (event, location) => {
        event.preventDefault();
        switch (location) {
            case "Member":
                dispatch(resetMember());
                dispatch(filterFlagChange(true));
                navigate('/suppliers-and-members/members');
                break
            case "Member Details":
                navigate('/suppliers-and-members/members/member-details');
                break
            case "Member Accounts":
                navigate('/suppliers-and-members/members/member-accounts');
                break
            case "Member Outlets":
                navigate('/suppliers-and-members/members/member-outlets');
                break
            default:
                navigate('/suppliers-and-members/members');
        }
    }

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" >
            Suppliers & Members
        </Link>,
        <Link underline="hover" key="2" color="inherit" onClick={(e) => handleBClick(e, 'Member')} >
            Members
        </Link>,
        <Link underline="hover" key="3" color="inherit" onClick={(e) => handleBClick(e, 'Member Details')} >
            Account Details
        </Link>,
        <Typography key="4" color="text.primary">
            Member Accounts
        </Typography>
    ];

    const handleBackdropClose = () => {
        setOpen(false);
    };

    const [expanded, setExpanded] = React.useState('panel1');

    const goNextPage = async () => {

        try {
            dispatch(resetMemberOutlets());
            dispatch(setHasMemberOutletEditData(null));
            setState({ ...state, pageLoader: true });
            const { data } = await axiosMember.get(`members/${member_fields.member_id}/memberAccounts/${member_fields.member_accountid}`);

            if (data) {

                dispatch(memberBasicInput({
                    key: 'member_accountid',
                    value: data.id
                }));

                dispatch(memberBasicInput({
                    key: 'member_account_name',
                    value: data.name
                }));

                dispatch(memberBasicInput({
                    key: 'member_account_active',
                    value: data.active
                }));

                dispatch(memberBasicInput({
                    key: 'member_account_master',
                    value: data.master
                }));

                dispatch(memberBasicInput({
                    key: 'member_account_cbi',
                    value: data.centralBillingId
                }));

                dispatch(memberBasicInput({
                    key: 'member_account_ledger',
                    value: data.ledgerCode
                }));

                dispatch(memberBasicInput({
                    key: 'member_account_cname',
                    value: data.contactName
                }));

                dispatch(memberBasicInput({
                    key: 'member_account_email',
                    value: data.email
                }));

                dispatch(memberBasicInput({
                    key: 'member_account_addl1',
                    value: data.address1
                }));

                dispatch(memberBasicInput({
                    key: 'member_account_addl2',
                    value: data.address2
                }));

                dispatch(memberBasicInput({
                    key: 'member_account_addl3',
                    value: data.address3
                }));

                dispatch(memberBasicInput({
                    key: 'member_account_town',
                    value: data.town
                }));

                dispatch(memberBasicInput({
                    key: 'member_account_county',
                    value: data.county
                }));

                dispatch(memberBasicInput({
                    key: 'member_account_country',
                    value: data.country
                }));

                dispatch(memberBasicInput({
                    key: 'member_account_postcode',
                    value: data.postCode
                }));

                dispatch(filterFlagChange(true));
                navigate('/suppliers-and-members/members/member-outlets');
            }
            else {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" });
                setState({ ...state, pageLoader: false });
            }

        }
        catch (error) {
            const { response: { data, status }, message } = error;
            if (status === 500) {
                setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
            } else {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
            }
            setState({ ...state, pageLoader: false });
        }

    }
    const lambdaWarmer = () => {
        onReprocessReverse()
    }
    const onReprocessReverse = async () => {
        try {
            setState({ ...state, pageLoader2: true });
            const { data } = await refreshLambda.get('/warmup');

            if (data) {
                setSnackBar({ ...snackBar, vertical: 'top', duration: 6000, horizontal: 'center', show: true, text: "System is warming up, please check after 9 minutes", type: "info" })
            }
            setState({ ...state, pageLoader2: false });
        } catch (error) {
            const { response: { data, status } } = error;
            if (status === 500) {
                setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
            } else {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
            }
            setState({ ...state, pageLoader2: false });
        }
    }
    return (


        <Container disableGutters={true} maxWidth={false} >
            {state.pageLoader2 && <PageLoaderStyle2 />}
            <Box component="div" className='breadcrumbNavs'>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <Breadcrumbs component="div" separator="›" aria-label="breadcrumb">
                            {breadcrumbs}
                        </Breadcrumbs>
                        <div className='pageHeading'>
                            <h1>{member_fields.hasMemberAccountsEditData ? member_fields.hasMemberAccountsEditData.name : "Member Accounts"}</h1>
                        </div>
                    </div>
                    <Box className='lambdaRefreshBtn'>
                        <Box component={'a'} sx={{ display: 'flex', alignItems: 'center' }} onClick={lambdaWarmer}>
                            <LocalFireDepartmentIcon color="primary" /> <span style={{ marginLeft: '5px', color: '#B253AE' }}>Warm System</span>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography sx={{ fontWeight: "600", fontSize: '14px', color: "#15191E" }}>{member_fields.hasMemberAccountsEditData ? member_fields.hasMemberEditData ? "Edit Member Account of " + member_fields.hasMemberEditData.name : "Edit Member Account" : "Add New Member Account"}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <form onSubmit={handleSubmit}>
                        <Box sx={{ flexGrow: 1, paddingX: 2 }} >
                            <Grid container columnSpacing={2} sx={{ paddingLeft: '32px', paddingRight: '18px' }}>
                                <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                                    <Item>
                                        <Grid container alignItems="center">
                                            <Grid item xs={12} md={6} className="formLabelCntr">
                                                <Item>
                                                    <FormLabel htmlFor="member_account_name" className="fontSemiBold mandatory">Member Account Name</FormLabel>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Item>
                                                    <div className="input-group">
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                inputProps={{ maxLength: 200 }}
                                                                size="small"
                                                                id="member_account_name"
                                                                aria-describedby="member_account_name_error"
                                                                onChange={(e) => handleInputChange(e)}
                                                                value={member_fields.member_account_name}
                                                                error={member_fields.isError.member_account_name}
                                                                name="member_account_name"

                                                            />
                                                            {member_fields.isError.member_account_name && (<FormHelperText className='errorHelperTxt' id="member_account_name_error">
                                                                Please enter Member Account Name
                                                            </FormHelperText>)}
                                                        </FormControl>

                                                    </div>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={6} sx={{ marginBottom: 2.5 }}>
                                    <Item>
                                        <Grid container alignItems="center">
                                            <Grid item xs={12} md={2.5} className="formLabelCntr">
                                                <Item>

                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Item sx={{ paddingLeft: '0' }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox size="medium"
                                                                id="member_account_active"
                                                                checked={member_fields.member_account_active}
                                                                onChange={(e) => handleCheckboxChange(e)}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                name="member_account_active"
                                                            />}
                                                        label="Active" />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox size="medium"
                                                                id="member_account_master"
                                                                checked={member_fields.member_account_master}
                                                                onChange={(e) => handleCheckboxChange(e)}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                name="member_account_master"
                                                            />}
                                                        label="Master" />
                                                </Item>
                                            </Grid>
                                        </Grid>

                                    </Item>
                                </Grid>

                            </Grid>
                            <Grid container columnSpacing={2} sx={{ paddingLeft: '20px', paddingRight: '20px' }}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Item>
                                        <Grid container alignItems="center">
                                            <Grid item xs={12} md={6} sx={{ marginBottom: 2.5 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={6} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="member_account_cbi" className="fontSemiBold mandatory">Central Billing ID</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Item>
                                                                <div className="input-group">
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            inputProps={{ maxLength: 200 }}
                                                                            size="small"
                                                                            id="member_account_cbi"
                                                                            aria-describedby="member_account_cbi_error"
                                                                            onChange={(e) => handleInputChange(e)}
                                                                            value={member_fields.member_account_cbi}
                                                                            error={member_fields.isError.member_account_cbi}
                                                                            name="member_account_cbi"

                                                                        />
                                                                        {member_fields.isError.member_account_cbi && (<FormHelperText className='errorHelperTxt' id="member_account_cbi_error">
                                                                            Please enter Central Billing ID
                                                                        </FormHelperText>)}
                                                                    </FormControl>

                                                                </div>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} md={6} sx={{ marginBottom: 2.5 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={2.5} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="member_account_ledger" className="fontSemiBold mandatory">Ledger</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Item>
                                                                <div className="input-group">
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            inputProps={{ maxLength: 200 }}
                                                                            size="small"
                                                                            id="member_account_ledger"
                                                                            aria-describedby="member_account_ledger_error"
                                                                            onChange={(e) => handleInputChange(e)}
                                                                            value={member_fields.member_account_ledger}
                                                                            error={member_fields.isError.member_account_ledger}
                                                                            name="member_account_ledger"

                                                                        />
                                                                        {member_fields.isError.member_account_ledger && (<FormHelperText className='errorHelperTxt' id="member_account_ledger_error">
                                                                            Please enter Ledger Value
                                                                        </FormHelperText>)}
                                                                    </FormControl>

                                                                </div>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>

                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Item>
                                </Grid>
                            </Grid>
                            <Grid container columnSpacing={2} sx={{ paddingLeft: '20px', paddingRight: '20px' }}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Item>
                                        <Grid container alignItems="center">
                                            <Grid item xs={12} md={6} sx={{ marginBottom: 2.5 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={6} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="member_account_cname" className="fontSemiBold">Contact Name</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Item>
                                                                <div className="input-group">
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            inputProps={{ maxLength: 200 }}
                                                                            size="small"
                                                                            id="member_account_cname"
                                                                            aria-describedby="member_account_cname_error"
                                                                            onChange={(e) => handleInputChange(e)}
                                                                            value={member_fields['member_account_cname'] || ''}
                                                                            name="member_account_cname"

                                                                        />

                                                                    </FormControl>

                                                                </div>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} md={6} sx={{ marginBottom: 2.5 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={2.5} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="member_account_email" className="fontSemiBold ">Email</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Item>
                                                                <div className="input-group">
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            inputProps={{ maxLength: 200 }}
                                                                            size="small"
                                                                            id="member_account_email"
                                                                            aria-describedby="member_account_email_error"
                                                                            onChange={(e) => handleInputChange(e)}
                                                                            value={member_fields['member_account_email'] || ''}
                                                                            name="member_account_email"

                                                                        />

                                                                    </FormControl>

                                                                </div>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>

                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Item>
                                </Grid>
                            </Grid>
                            <Grid container columnSpacing={2} sx={{ paddingLeft: '20px', paddingRight: '20px' }}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Item>
                                        <Grid container alignItems="center">
                                            <Grid item xs={12} md={6} sx={{ marginBottom: 2.5 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={6} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="member_account_addl1" className="fontSemiBold">Address Line 1</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Item>
                                                                <div className="input-group">
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            inputProps={{ maxLength: 200 }}
                                                                            size="small"
                                                                            id="member_account_addl1"
                                                                            aria-describedby="member_ooid_error"
                                                                            onChange={(e) => handleInputChange(e)}
                                                                            value={member_fields['member_account_addl1'] || ''}
                                                                            name="member_account_addl1"

                                                                        />

                                                                    </FormControl>

                                                                </div>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} md={6} sx={{ marginBottom: 2.5 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={2.5} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="member_account_addl2" className="fontSemiBold">Address Line 2</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Item>
                                                                <div className="input-group">
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            inputProps={{ maxLength: 200 }}
                                                                            size="small"
                                                                            id="member_account_addl2"
                                                                            aria-describedby="member_account_addl2_error"
                                                                            onChange={(e) => handleInputChange(e)}
                                                                            value={member_fields['member_account_addl2'] || ''}
                                                                            name="member_account_addl2"

                                                                        />

                                                                    </FormControl>

                                                                </div>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>

                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Item>
                                </Grid>
                            </Grid>
                            <Grid container columnSpacing={2} sx={{ paddingLeft: '20px', paddingRight: '20px' }}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Item>
                                        <Grid container alignItems="center">
                                            <Grid item xs={12} md={6} sx={{ marginBottom: 2.5 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={6} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="member_account_addl3" className="fontSemiBold">Address Line 3</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Item>
                                                                <div className="input-group">
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            inputProps={{ maxLength: 200 }}
                                                                            size="small"
                                                                            id="member_account_addl3"
                                                                            aria-describedby="member_account_addl3_error"
                                                                            onChange={(e) => handleInputChange(e)}
                                                                            value={member_fields['member_account_addl3'] || ''}

                                                                            name="member_account_addl3"

                                                                        />

                                                                    </FormControl>

                                                                </div>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} md={6} sx={{ marginBottom: 2.5 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={2.5} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="member_account_town" className="fontSemiBold">Town</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Item>
                                                                <div className="input-group">
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            inputProps={{ maxLength: 200 }}
                                                                            size="small"
                                                                            id="member_account_town"
                                                                            aria-describedby="member_account_town_error"
                                                                            onChange={(e) => handleInputChange(e)}
                                                                            value={member_fields['member_account_town'] || ''}
                                                                            name="member_account_town"

                                                                        />

                                                                    </FormControl>

                                                                </div>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>

                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Item>
                                </Grid>
                            </Grid>
                            <Grid container columnSpacing={2} sx={{ paddingLeft: '20px', paddingRight: '20px' }}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Item>
                                        <Grid container alignItems="center">
                                            <Grid item xs={12} md={6} sx={{ marginBottom: 2.5 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={6} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="member_ooid" className="fontSemiBold">County</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Item>
                                                                <div className="input-group">
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            inputProps={{ maxLength: 200 }}
                                                                            size="small"
                                                                            id="member_account_county"
                                                                            aria-describedby="member_account_county_error"
                                                                            onChange={(e) => handleInputChange(e)}
                                                                            value={member_fields['member_account_county'] || ''}

                                                                            name="member_account_county"

                                                                        />

                                                                    </FormControl>

                                                                </div>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} md={6} sx={{ marginBottom: 2.5 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={2.5} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="member_account_country" className="fontSemiBold">Country</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Item>
                                                                <div className="input-group">
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            inputProps={{ maxLength: 200 }}
                                                                            size="small"
                                                                            id="member_account_country"
                                                                            aria-describedby="member_account_country_error"
                                                                            onChange={(e) => handleInputChange(e)}
                                                                            value={member_fields['member_account_country'] || ''}

                                                                            name="member_account_country"

                                                                        />

                                                                    </FormControl>

                                                                </div>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>

                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Item>
                                </Grid>
                            </Grid>
                            <Grid container columnSpacing={2} sx={{ paddingLeft: '20px', paddingRight: '20px' }}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Item>
                                        <Grid container alignItems="center">
                                            <Grid item xs={12} md={6} sx={{ marginBottom: 2.5 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={6} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="member_account_postcode" className="fontSemiBold">Post Code</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Item>
                                                                <div className="input-group">
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            inputProps={{ maxLength: 200 }}
                                                                            size="small"
                                                                            id="member_account_postcode"

                                                                            onChange={(e) => handleInputChange(e)}
                                                                            value={member_fields['member_account_postcode'] || ''}
                                                                            name="member_account_postcode"

                                                                        />

                                                                    </FormControl>

                                                                </div>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} md={6} sx={{ marginBottom: 2.5 }}>
                                                <Item>

                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Item>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ marginTop: '20px' }}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Item>
                                        <Grid container direction="row" alignItems="center" justifyContent="center">
                                            <LoadingButton
                                                loading={state.loader}
                                                loadingPosition="center"
                                                endIcon=""
                                                variant="contained"
                                                type="submit" sx={{ marginRight: '15px' }}
                                                disabled={state.formValid}
                                            // disabled={state.formValid || state.defaultmode === false}

                                            >{member_fields.editMemberAccountsTab && member_fields.member_accountid !== 0 ? "Update" : "Save"}</LoadingButton>
                                            <Button variant="outlined" onClick={handleCancel}>Go Back</Button>
                                            <Button variant="outlined" sx={{ marginLeft: '15px' }} onClick={handleDashboard}>Go to Dashboard</Button>
                                        </Grid>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Box>
                        {snackBar.show && <SnackBarDialog
                            duration={snackBar.duration}
                            vpositon={snackBar.vertical}
                            hposition={snackBar.horizontal}
                            onShow={snackBar.show}
                            text={snackBar.text}
                            type={snackBar.type}
                            onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                        // vpositon={"top"}
                        // hposition={"center"}
                        // <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} onClick={handleBackdropClose} >
                        />
                        }
                    </form>
                </AccordionDetails>
            </Accordion>
            <Box component="div" className='exportsButtons' sx={{ marginBottom: '20px', columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' } }}>
                <div className='table-actionable'>
                    <Button variant="contained" size='small' disabled={member_fields ? member_fields.disable_moBtn : true} onClick={(e) => goNextPage(e)} startIcon={<AddIcon />}>
                        New Member Outlet
                    </Button>
                </div>
            </Box>
            {state.pageLoader && <PageLoader />}
            <DataTableClientSide defaultGrid={state.defaultGrid} columns={columns} location={state.location} locationLabel={state.locationlabel} rows={rows ? rows : []}></DataTableClientSide>

        </Container>

    );

}



export default MemberAccounts;