import { Container, Box, Modal, Button, Grid, Checkbox, Popper, Grow, Paper, ClickAwayListener, MenuList, ButtonGroup, Stack, TextField, FormControl, FormHelperText, FormLabel, IconButton, requirePropFactory } from '@mui/material';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TableFilters } from "../../components/shared/data-table/TableFilters";
import { useEffect, useState } from "react";
import { InputText } from "../../components/shared/form/InputText";
import { AutoComplete } from "../../components/shared/form/AutoComplete";
import { } from '@mui/material';
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange, setAccountingEntriesFinishDate, filterResetFlagChange } from "../../store/slices/externalFilter";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import moment from "moment";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { ThreeStateCheckbox } from "../../components/shared/form/ThreeStateCheckbox";
import { warningModal, handleViewMoreModal } from "../../store/slices/modals";
import WarningModal from "../../components/shared/modals/WarningModal";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { setRowPage, resetDataGrid, setPageNo } from '../../store/slices/dataGrid';
import { useLayoutEffect } from "react";
import { DataTableAccountingEntries } from "../../components/shared/data-table/DataTableAccountingEntries";
import { hasTabEdit } from "../../store/slices/invoiceSummary";
import { AutoCompleteStatic } from "../../components/shared/form/AutoCompleteStatic";
import DetailAccountingEntry from "./detailAccountingEntries/DetailAccountingEntry";
import { SelectDropDown } from "../../components/shared/form/Select";
import axios_accounting_entries from '../../services/utility/axios-accounting-entries';
import SnackBarDialog from '../../components/shared/SnackBarDialog';
import PageLoader from '../../components/shared/PageLoader';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

let selectedvals = {}

function AccountingEntries() {

    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const uname = accounts[0] && accounts[0].name;

    // eslint-disable-next-line max-len
    const { centralSystems, subLedgers, finishDates, serverProcess, cancelledActive, exportedActive, addProductwarning, showViewModal, filter_flag, reset_filter_flag } = useSelector((state) => {
        return {
            centralSystems: state.externalFilter.ae_central_system,
            subLedgers: state.externalFilter.ae_sub_ledger,
            finishDates: state.externalFilter.ae_finish_date,
            serverProcess: state.externalFilter.ae_server_process,
            exportedActive: state.externalFilter.ae_exported_active,
            cancelledActive: state.externalFilter.ae_cancelled_active,
            addProductwarning: state.modalActions.closeWaringAddProduct,
            showViewModal: state.modalActions.hideViewMoreModal,
            filter_flag: state.externalFilter.filterApplied,
            reset_filter_flag: state.externalFilter.filterReset,
        }
    });

    const [state, setState] = useState({
        ae_exported_active: null,
        ae_cancelled_active: null,
        loader: false,
        serverProcessEntryId: null,
        defaultGrid: {
            pagetitle: "Accounting Entries",
            pdcActionButton: true,
            hasExport: true,
            apiUrl: 'accountingEntryGroups',
            searchUrl: 'accountingEntryGroups/filter'
        }
    });

    const dispatch = useDispatch();

    // const { showViewModal, invoiceSummary_fields } = useSelector((state) => {
    //     return {
    //         showViewModal: state.modalActions.hideViewMoreModal,
    //         //editTabData: state.invoiceSummarys.editTabData,
    //         //invoiceSummary_fields: state.invoiceSummarys
    //     }
    // });

    const IconMenuLink = ({ params }) => {
        const { row } = params;
        const [anchorEl, setAnchorEl] = useState(null);
        const open = Boolean(anchorEl);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = (event, action) => {
            event.preventDefault();
            if (action === 'view_more') {
                dispatch(handleViewMoreModal({
                    showViewMore: !showViewModal
                }))
                selectedvals = row;
            } else if (action === 'generate_report') {
                setState({ ...state, serverProcessEntryId: row.serverProcessEntryId });
                reportExecutable(event, row);
            }
            setAnchorEl(null);
        };

        return (<>
            <IconButton
                aria-label="more"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                id={row.serverProcessEntryId + 1}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id={row.serverProcessEntryId}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                className="actionMenuBx"
            >
                <MenuItem onClick={(e) => handleClose(e, 'view_more')}>View More</MenuItem>
                <MenuItem disabled={row.cancelled === true ? true : false} onClick={(e) => handleClose(e, 'generate_report')}>Generate Report</MenuItem>
            </Menu>
        </>)
    }

    const columns = [

        {
            headerName: "SPID",
            field: "serverProcessEntryId",
            flex: 1,
            minWidth: 200,
        },
        {
            headerName: "Sub Ledger",
            field: "subLedger",
            flex: 1,
            width: 170
        },
        {
            headerName: "Central System",
            field: "centralSystem",
            flex: 1,
            width: 180
        },
        {
            headerName: "Server Process",
            field: "serverProcessEntryClass",
            flex: 1,
            width: 180
        },
        {
            headerName: "Finish Date",
            field: "serverProcessEntryFinishDateString",
            flex: 1,
            width: 180,
            renderCell: (params) => {
            if (params.value === null || params.value === "") {
                return params.value
            }
            return moment(params.value).format('DD/MM/YYYY HH:mm:ss');
        }
        },
        {
            headerName: "Net Total (£)",
            field: "nominalTotal",
            flex: 1,
            minWidth: 200,
            valueFormatter: (params) => { return params.value ? params.value.toFixed(2) : 0; }
        },
        {
            headerName: "Vat Total (£)",
            field: "vatTotal",
            flex: 1,
            width: 180,
            valueFormatter: (params) => { return params.value ? params.value.toFixed(2): 0; }
        },
        {
            headerName: "Gross Total (£)",
            field: "grossTotal",
            flex: 1,
            width: 180,
            valueFormatter: (params) => { return params.value ? params.value.toFixed(2): 0; }
        },
        {
            headerName: "Exported",
            field: "exported",
            flex: 1,
            width: 170,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            )
        },
        {
            headerName: "Cancelled",
            field: "cancelled",
            flex: 1,
            width: 170,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            )
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} />;
            }
        }
    ];

    const ServerProcesses = [
        // { text: 'Disputed Invoice', value: 'DisputedInvoiceExecutable' },
        { text: 'Levy Billing', value: 'LevyBillingExecutable' },
        { text: 'Member Invoice Print', value: 'MemberInvoicePrintExecutable' },
        { text: 'Retro Billing', value: 'RetroBillingExecutable' },
        { text: 'Retro Final', value: 'RetroFinalExecutable' },
        { text: 'Retro Interim', value: 'RetroInterimExecutable' },
        { text: 'Bill Discounts', value: 'billdiscounts' },
        // { text: 'Member Statement', value: 'memberstatement' },
        { text: 'Member Collection', value: 'membercollection' },
        { text: 'Remittance', value: 'remittance' },
        // { text: 'Reconciliation', value: 'reconciliation' },
        // { text: 'Accounting Entry Export', value: 'accountingentryexport' }
    ];



    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const handleFinishDate = (event) => {
        const fDate = (event === null) ? null : moment(event, 'DD/MM/YYYY');
        dispatch(setAccountingEntriesFinishDate(fDate))
    };


    const handleFilterInput = (value, id) => {
        //setState({ ...state, [id]: value });
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    };

    const handleChange = (checked, name) => {
        setState({ ...state, [name]: checked })
        dispatch(setFilter({
            key: name,
            value: checked
        }))
    };

    const selectedAutoComplete = (value, id) => {
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    }

    const filterBtn = () => {

        // console.log(filter_flag, reset_filter_flag)
        dispatch(resetDataGrid());

        var dynamicFilterObjects = {};

        if (centralSystems !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "centralSystem": centralSystems
            }
        }
        if (subLedgers !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "subLedger": subLedgers
            }
        }
        if (finishDates !== '' && finishDates !== null) {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "finishDate": moment(finishDates).format('DD/MM/YYYY')
            }
        }
        if (serverProcess !== '' && serverProcess !== null) {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "serverProcessClass": serverProcess
            }
        }

        dynamicFilterObjects = {
            ...dynamicFilterObjects,
            "cancelled": cancelledActive,
            "exported": exportedActive
        }

        if (finishDates !== "" && finishDates !== null && moment(finishDates).format('DD/MM/YYYY') === 'Invalid date') {
            setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
        }

        else {
            dispatch(applyFilter({
                key: 'filterList',
                value: dynamicFilterObjects
            }))
            dispatch(filterFlagChange(true));
            dispatch(filterResetFlagChange(false));
            dispatch(setRowPage({ pageData: 10 }));
            dispatch(setPageNo({ page: 1 }));
        }


    }
    const resetFilter = () => {
        if ((centralSystems || subLedgers) !== '' || (cancelledActive !== false || !cancelledActive) || (exportedActive !== false || !exportedActive)) {
            dispatch(resetExternalFilter());
            dispatch(resetDataGrid());
        }
        setState({ ...state, ae_exported_active: null, ae_cancelled_active: null });
        dispatch(filterResetFlagChange(true));
        // window.location.reload();
        //setState({ ...state });
    }


    const reportExecutable = async (event, params) => {
        event.preventDefault();
        try {
            setState({ ...state, loader: true })
            let payload = {
                "executable": "accountingentryexport",
                "user": uname,
                "accountingEntrySearchDTO":
                {
                    "serverProcessId": params ? params.serverProcessEntryId : state.serverProcessEntryId
                    // "cancelled": params ? params.cancelled : null,
                    // "centralSystem": params ? params.cancelled : null,
                    // "exported": params ? params.cancelled : null,
                    // "finishDate": params ? params.cancelled : null,
                    // "serverProcessClass": params ? params.cancelled : null,
                    // "serverProcessId": params ? params.cancelled : null,
                    // "subLedger": params ? params.cancelled : null
                }
            };
            const { data, status } = await axios_accounting_entries.post(`sqs/nusslreportexecutable`, payload);
            if (data) {
                setState({ ...state, loader: false })
                setSnackBar({ ...snackBar, show: true, text: data.message, type: "success", })
            }

        } catch (error) {
            const { response: { data, status } } = error;
            setState({ ...state, loader: false })
            if (!data) {
                setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
            } else {
                setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
            }
        }
    }


    return (
        <>
            {state.loader && <PageLoader />}
            {snackBar.show && <SnackBarDialog
                onShow={snackBar.show}
                text={snackBar.text}
                type={snackBar.type}
                onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
            />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Accounting Entries" />
                <Box sx={{ marginBottom: 2.5 }}>
                    <TableFilters
                        filterFields={<>
                            <Box component="div"><InputText onChange={handleFilterInput} maxWidth={50} label="Central System" id="ae_central_system" size="small" /></Box>
                            <Box component="div"><InputText onChange={handleFilterInput} maxWidth={50} label="Sub Ledger" id="ae_sub_ledger" size="small" /></Box>
                            <Box component="div"><SelectDropDown onChange={handleFilterInput} values={ServerProcesses} size="small" id="ae_server_process" label="Server Process" name="ae_server_process" /></Box>
                            <Box component="div">
                                <FormLabel htmlFor="ae_finish_date" className="fontBold">Finish Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker

                                        value={finishDates}
                                        onChange={(e) => { handleFinishDate(e) }}
                                        name="ae_finish_date" label="Finish Date"
                                        inputFormat="DD/MM/YYYY"
                                        // eslint-disable-next-line max-len
                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                            <Box component="div" sx={{ paddingTop: '6px' }} className='date-picker-input'>

                                                <FormControl size="small" >
                                                    <TextField size="small" id="ae_finish_date"
                                                        ref={inputRef} {...inputProps}
                                                        placeholder=""
                                                        aria-describedby="ae_finish_date"
                                                        autoComplete="off"

                                                    />

                                                    <Box className='date-picker-icon'>
                                                        {InputProps?.endAdornment}
                                                    </Box>
                                                </FormControl>
                                            </Box>
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>

                        </>}
                        filterCheckboxes={<>
                            <Box component="div">
                                <ThreeStateCheckbox checked={state.ae_exported_active} onChange={handleChange} name="ae_exported_active" id="ae_exported_active" label="Exported" />
                            </Box>
                            <Box component="div">
                                <ThreeStateCheckbox checked={state.ae_cancelled_active} onChange={handleChange} name="ae_cancelled_active" id="ae_cancelled_active" label="Cancelled" />
                            </Box>

                        </>}
                        filterBtns={
                            <>
                                <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                <Button variant="contained" onClick={filterBtn}>Apply</Button>
                            </>}>
                    </TableFilters>
                </Box>
                <DataTableAccountingEntries defaultGrid={state.defaultGrid} columns={columns}>

                </DataTableAccountingEntries>
                {
                    showViewModal && (
                        <DetailAccountingEntry
                            onShow={showViewModal}
                            // onHide={(e, reason) => hideInvoiceSummaryModal(e, reason)}
                            svalues={selectedvals}

                        />
                    )
                }
                {
                    addProductwarning && (
                        <WarningModal size="small_overlay" onShow={addProductwarning} />
                    )
                }
            </Container>

        </>
    );
}

export default AccountingEntries;
