import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  disable_maBtn: true,
  disable_moBtn: true,
  disable_mpBtn: true,
  memberDetailsTab: false,
  memberAccountsTab: false,
  memberOutletsTab: false,
  memberProgrammesTab: false,
  editMembersTab: false,
  hasMemberEditData: null,
  editMemberAccountsTab: false,
  hasMemberAccountsEditData: null,
  editMemberOutletsTab: false,
  hasMemberOutletsEditData: null,
  editMemberProgrammesTab: false,
  hasMemberProgrammesEditData: null,
  member_id: 0,
  member_name: "",
  member_active: true,
  member_cc: false,
  member_prc: false,
  member_joindate: null,
  member_leavedate: null,
  member_estate_group: {
    estate_group_options: [],
    estate_group_selected: null
  },
  member_retroagg_group: {
    retroagg_group_options: [],
    retroagg_group_selected: null
  },
  member_ooid: "",
  member_oot: "",
  member_accountid: 0,
  member_account_name: '',
  member_account_active: true,
  member_account_master: false,
  member_account_cbi: '',
  member_account_ledger: '',
  member_account_cname: '',
  member_account_email: '',
  member_account_addl1: '',
  member_account_addl2: '',
  member_account_addl3: '',
  member_account_town: '',
  member_account_county: '',
  member_account_country: '',
  member_account_postcode: '',
  member_outletid: 0,
  member_outlet_name: '',
  member_outlet_oo: '',
  member_outlet_ontrade: false,
  member_outlet_enddate: null,
  member_tier: {
    member_tier_options: [],
    member_tier_selected: null
  },
  member_programmeid: 0,
  edit_member_programme: null,
  member_programme: {
    programme_options: [],
    programme_selected: null
  },
  member_programme_name: '',
  member_programme_jd: null,
  member_programme_ld: null,
  hasEditData: null,
  isError: {
    member_name: false,
    member_joindate: false,
    member_active: false,
    estate_group_selected: false,
    retroagg_group_selected: false,
    member_ooid: false,
    member_account_name: false,
    member_account_cbi: false,
    member_account_ledger: false,
    member_outlet_name: false,
    programme_selected: false,
    member_programme_jd: false,
    member_tier_selected: false,
    buttonDisabled: true
  }

}

const memberBclEntry = createSlice({
  name: 'member_breadcrumbs_values',
  initialState,
  reducers: {
    memberBasicInput: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    memberInput: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
      state[key].length === 0 || state[key] === "" || state[key] === null ? state.isError[key] = true : state.isError[key] = false;
    },
    setAutocomplete: (state, action) => {
      const { parent, key, value } = action.payload;
      state[parent][key] = value;
      (state[parent][key] === null || state[parent][key] === "" || state[parent][key]['name'] === null) ? state.isError[key] = true : state.isError[key] = false;
    },
    setMemberDate: (state, action) => {
      const { key, value } = action.payload;

      state[key] = value;
      (state[key] === null || state[key] === "" || state[key] === "Invalid date") ? state.isError[key] = true : state.isError[key] = false;
    },
    setMemberEdit: (state, action) => {
      const { editMembersTab, member_id } = action.payload;
      state.editMembersTab = editMembersTab;
      state.member_id = member_id || 0;
    },
    setMemberAccountEdit: (state, action) => {
      const { editMemberAccountsTab, member_accountid } = action.payload;
      state.editMemberAccountsTab = editMemberAccountsTab;
      state.member_accountid = member_accountid || 0;
    },
    setMemberOutletEdit: (state, action) => {
      const { editMemberOutletsTab, member_outletid } = action.payload;
      state.editMemberOutletsTab = editMemberOutletsTab;
      state.member_outletid = member_outletid || 0;
    },
    setMemberProgrammeEdit: (state, action) => {
      const { editMemberProgrammesTab, member_programmeid } = action.payload;
      state.editMemberProgrammesTab = editMemberProgrammesTab;
      state.member_programmeid = member_programmeid || 0;
    },
    updateNextBtnStatus: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value
    },
    setHasMemberEditData: (state, action) => {
      state.hasMemberEditData = action.payload;
    },
    setHasMemberAccountEditData: (state, action) => {
      state.hasMemberAccountsEditData = action.payload;
    },
    setHasMemberOutletEditData: (state, action) => {
      state.hasMemberOutletsEditData = action.payload;
    },
    setHasMemberProgrammeEditData: (state, action) => {
      state.hasMemberProgrammesEditData = action.payload;
    },
    getIsError: (state, action) => {
      const { key, value } = action.payload;
      state.isError[key] = value
    },
    resetMemberProgramme: (state, action) => {
      state.member_programmeid = 0;
      state.edit_member_programme = null;
      state.member_programme = {
        programme_options: [],
        programme_selected: null,
      };
      state.member_programme_jd = null;
      state.member_programme_ld = null;
      state.isError = {
        // programme_selected: false,
        // member_programme_jd: false
        member_name: false,
        member_joindate: false,
        member_active: false,
        estate_group_selected: false,
        retroagg_group_selected: false,
        member_ooid: false,
        member_account_name: false,
        member_account_cbi: false,
        member_account_ledger: false,
        member_outlet_name: false,
        programme_selected: false,
        member_programme_jd: false,
        member_tier_selected: false
      };
    },
    resetMemberOutlets: (state, action) => {
      state.member_outletid = 0;
      state.member_outlet_name = '';
      state.member_outlet_oo = '';
      state.member_outlet_ontrade = false;
      state.member_outlet_enddate = null;
      state.member_tier = {
        member_tier_options: [],
        member_tier_selected: null
      };
      state.disable_mpBtn = true;
      state.isError = {
        // member_outlet_name: false,
        // member_tier_selected: false
        member_name: false,
        member_joindate: false,
        member_active: false,
        estate_group_selected: false,
        retroagg_group_selected: false,
        member_ooid: false,
        member_account_name: false,
        member_account_cbi: false,
        member_account_ledger: false,
        member_outlet_name: false,
        programme_selected: false,
        member_programme_jd: false,
        member_tier_selected: false
      };
    },
    resetMemberAccounts: (state, action) => {
      state.member_accountid = 0;
      state.member_account_name = '';
      state.member_account_active = true;
      state.member_account_master = false;
      state.member_account_cbi = '';
      state.member_account_ledger = '';
      state.member_account_cname = '';
      state.member_account_email = '';
      state.member_account_addl1 = '';
      state.member_account_addl2 = '';
      state.member_account_addl3 = '';
      state.member_account_town = '';
      state.member_account_county = '';
      state.member_account_country = '';
      state.member_account_postcode = '';
      state.disable_moBtn = true;
      state.isError = {
        // member_account_name: false,
        // member_account_cbi: false,
        // member_account_ledger: false
        member_name: false,
        member_joindate: false,
        member_active: false,
        estate_group_selected: false,
        retroagg_group_selected: false,
        member_ooid: false,
        member_account_name: false,
        member_account_cbi: false,
        member_account_ledger: false,
        member_outlet_name: false,
        programme_selected: false,
        member_programme_jd: false,
        member_tier_selected: false
      };
    },
    resetMember: () => {
      return initialState
    },
  }
});

export const { memberInput, memberBasicInput, hasTabEdit, getIsError, setAutocomplete, setMembersData, setMemberDate, setMemberFormStatus, setMemberEdit,
  updateNextBtnStatus, setHasMemberEditData, setHasMemberAccountEditData, setMemberAccountEdit, setMemberOutletEdit, setMemberProgrammeEdit, resetMember,
  setHasMemberOutletEditData, setHasMemberProgrammeEditData, resetMemberAccounts, resetMemberOutlets, resetMemberProgramme } = memberBclEntry.actions

export default memberBclEntry.reducer