import { Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControl, Autocomplete } from '@mui/material'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import axios from '../../../../../services/utility/axios';
import PageLoader from '../../../../../components/shared/PageLoader';
import { setListOfLevy } from '../../../../../store/slices/generalProduct';
import axiosLevyScheme from '../../../../../services/utility/axios-levy-scheme';
import SnackBarDialog from '../../../../../components/shared/SnackBarDialog';


const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

function AddEditLevyModal({ onShow, size = '', onHide, onSubmitPrice }) {
    const { levyList } = useSelector((state) => {
        return {
            levyList: state.generalProduct
        }
    });
    const dispatch = useDispatch();

    const [state, setState] = useState({
        startDate: null,
        pageLoader: false,
        defaultmode: false,
        formValid: true,
        levyListModal: false,
        selectedLevy: null
    })

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const [isError, setIsError] = useState({
        startDate: false,
        price: false,
        member_tier_selected: false
    })

    const [member_tier_list, setMember_tier] = useState({
        member_tier_options: [],
        member_tier_selected: null
    })

    useEffect(() => {
        const fetchMyAPI = async () => {
            if (member_tier_list.member_tier_options.length === 0) {
                axiosLevyScheme.get('/levySchemes/look-up')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data.resultMap.result
                            setMember_tier({ ...member_tier_list, member_tier_options: unique })
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        const { response: { data, status }, message } = error;
                        console.log(error, status, data);
                    });
            }
        }
        fetchMyAPI();
    }, [])

    const [open, setOpen] = useState(onShow);

    const handleClose = () => {
        if (onHide) {
            onHide(false)
        }
    };

    const handleSelf = () => {
        handleClose()
    }

    useEffect(() => {
        if (member_tier_list.member_tier_selected !== null) {
            setIsError({ ...isError, member_tier_selected: false })
        }
    }, [member_tier_list])

    useEffect(() => {
        if (member_tier_list.member_tier_selected !== null) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true })
        }
    }, [member_tier_list.member_tier_selected])

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {

            const filteredRowsLevy = levyList.listOfLevy.find(levy => levy.levyId === member_tier_list.member_tier_selected.id)
            const filteredRowsMemberTier = levyList.listOfLevy.find(levy => levy.memberTierName === member_tier_list.member_tier_selected.memberTier.name)

            if (filteredRowsLevy) {
                setSnackBar({ ...snackBar, show: true, text: "Levy mapping already present", type: "error" })
            } else if (filteredRowsMemberTier) {
                setSnackBar({ ...snackBar, show: true, text: "Mapping exists for this purchasing tier, please delete the previous mapping and try again", type: "error" })
            } else {
                setState({ ...state, pageLoader: true })
                const payload = [{
                    "levyId": member_tier_list.member_tier_selected.id,
                    // "levySchemeName": state.selectedLevy.name, 
                    // "memberTierId": state.selectedLevy.memberTier.id, 
                    // "memberTierName": state.selectedLevy.memberTier.name, 
                    // "productId": levyList.productId, 
                    // "productName": null
                }]

                const { data } = await axios.post(`products/${levyList.productId}/levy`, payload);

                if (data.success) {
                    setSnackBar({ ...snackBar, show: true, text: data.resultMap.result, type: "success" })
                    getUpdatedLevyList()
                }
            }


        } catch (error) {
            const { response: { data, status } } = error;
            setState({ ...state, pageLoader: false })
            if (!data) {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" })
            } else {
                setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "warning" })
            }
        }
    }

    const getUpdatedLevyList = async () => {
        try {
            const { data } = await axios.get(`/products/${levyList.productId}/levy`);

            const unique = data.resultMap.result.filter((value, index, self) => self.findIndex((m) => m.id === value.id) === index);
            if (data) {

                dispatch(setListOfLevy(unique))
                setState({ ...state, pageLoader: false })
                setTimeout(() => {
                    handleClose()
                }, 500)
            }

        } catch (error) {
            const { response: { data, status } } = error;
            setState({ ...state, pageLoader: false })
            if (!data) {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" })
            } else {
                setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "warning" })
            }
        }
    }

    return (
        <>
            <Modal
                open={open}
                aria-labelledby="add-edit-price-title"
                aria-describedby="add-edit-price-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Box component="div" className="overlay" id="addEditModal">
                        {state.pageLoader && <PageLoader />}
                        <div className={`overlay-box ${size ? size : ''}`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-header text-center"> Add Levy Association
                                        <CancelIcon className="closeBtn" onClick={handleClose} />
                                    </div>
                                    <div className="overlay-content">
                                        <Grid item xs={12} md={12} sx={{padding: '0 10%'}}>
                                            <Box component="div">
                                                <FormLabel htmlFor="supplier_invoice_member_account" className="fontBold" >Select Levy</FormLabel>
                                                <Autocomplete
                                                    id="general_member_tier_search"
                                                    options={member_tier_list.member_tier_options}
                                                    getOptionLabel={(option) => option.name || ""}
                                                    componentsProps={{
                                                        paper: {
                                                            sx: {
                                                                fontSize: '0.85rem'
                                                            }
                                                        }
                                                    }}
                                                    onChange={(event, newValue) => {
                                                        setIsError({ ...isError, member_tier_selected: true })
                                                        if (event.target.value !== "" || event.target.value !== null) {
                                                            setMember_tier({ ...member_tier_list, member_tier_selected: newValue })
                                                            setState({ ...state, levyScheme: null, defaultmode: true })
                                                        }
                                                    }}
                                                    renderInput={(params) =>
                                                        <FormControl size={'small'} fullWidth >
                                                            <div className="input-group">
                                                                <TextField {...params}
                                                                    size="small"
                                                                    id="general_member_tier"
                                                                    aria-describedby="general_member_tier-error"
                                                                    error={isError.member_tier_selected}
                                                                />
                                                                {isError.member_tier_selected &&
                                                                    (<FormHelperText className='errorHelperTxt' id="general_member_tier-error"> This field is mandatory </FormHelperText>)}
                                                            </div>
                                                        </FormControl>
                                                    }
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginTop: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            &nbsp;
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Grid container direction="row" alignItems="center">
                                                                <Button variant="contained" disabled={member_tier_list.member_tier_selected ? false : true} type="submit" sx={{ marginRight: '15px' }}>Save</Button>
                                                                <Button variant="outlined" onClick={handleSelf}>Cancel</Button>
                                                            </Grid>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>

                    {snackBar.show && <SnackBarDialog
                        onShow={snackBar.show}
                        text={snackBar.text}
                        type={snackBar.type}
                        onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                    />}
                </form>

            </Modal>
        </>
    );
}

export default AddEditLevyModal;