import { Box, Paper, Grid, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hasTabEdit, setVatCodeList, setEditSupplierVatCode, setSupplierVatCodeInput, brandSearch, setLatestVatCode, setDeleteList, setSupplierDeleteData, getIsError } from "../../../store/slices/supplierProduct";
import { warningModal, setVatCodeMappingCodeModal } from "../../../store/slices/modals";
import { DataTableClientSide } from '../../../components/shared/data-table/DataTableClientSide';
import AddIcon from '@mui/icons-material/Add';
import AddEditVatCodeMappings from './AddEditVatCodeMappings';
import axiosProductDistribution from '../../../services/utility/axios-product-distribution';
import axios_supplier from '../../../services/utility/axios-supplier';
import SnackBarDialog from '../../../components/shared/SnackBarDialog';
import PageLoader from "../../../components/shared/PageLoader";

const IconMenuLink = ({ params, onDelete }) => {
    const { row } = params;
    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { showAddEditModal, supplier_all_fields } = useSelector((state) => {
        return {
            showAddEditModal: state.modalActions.hideAddEditModal,
            editTabData: state.supplierProducts.editTabData,
            supplier_all_fields: state.supplierProducts
        }
    });
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'edit') {
            editChannelChargeData(row)
        } else if (action === 'delete') {
            if(onDelete){
                onDelete(row)
            }
        }
        setAnchorEl(null);
    };

    const editChannelChargeData = (row) => {
        dispatch(setVatCodeMappingCodeModal(true))
        dispatch(setEditSupplierVatCode(row))
        dispatch(setSupplierVatCodeInput(row.supplierVatCode))
        dispatch(setLatestVatCode(1))
        dispatch(brandSearch({
            parent: 'supplier_vat_code',
            key: 'vat_code_selected',
            value: row.vatCode
        }))
    }

    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'edit')}>Edit</MenuItem>
            <MenuItem onClick={(e) => handleClose(e, 'delete')}>Delete</MenuItem>
        </Menu>
        
    </>)
}

const defaultValues = {
    pageLoader: false,
    defaultGrid: {
        eanActionButtons: true,
        paginationMode: 'client',
    },
    addVatCodeMappings: false
};
export default function VatCodeMappings() {
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })
    const [state, setState] = useState(defaultValues);
    const [columns, setColumns] = useState([]);

    const allColumns = [
        {
            headerName: "Id",
            field: "id",
            flex: 1,
            hide: true
        },
        {
            headerName: "Vat Code",
            field: "vatCode",
            flex: 1,
        },
        {
            headerName: "Rate",
            field: "rate",
            flex: 1,
        },
        {
            headerName: "Supplier Code",
            field: "supplierVatCode",
            flex: 1,
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} onDelete={deleteVatCodeMappings}/>;
            },
        }
    ];
    const { getVatCodeMappings, showVatCodeMappingModal, editTabData, hasEditData, supplier_all_fields, handleWarning} = useSelector((state) => {
        return {
            showVatCodeMappingModal: state.modalActions.vatCodeMappingCodeModal,
            getVatCodeMappings: state.supplierProducts.supplier_vatCode,
            editTabData: state.supplierProducts.editData,
            hasEditData: state.supplierProducts.hasEditData,
            supplier_all_fields: state.supplierProducts,
            handleWarning: state.modalActions.closeWaringAddProduct
        }
    });

    const dispatch = useDispatch();
    const addvatCodeMappingCodeModal = (event) => {
        dispatch(setVatCodeMappingCodeModal(true))
        dispatch(setLatestVatCode(1))
        dispatch(brandSearch({
            parent: 'supplier_vat_code',
            key: 'vat_code_selected',
            value: null
        }))
        dispatch(getIsError({ key: 'vat_code_selected', value: false })) 
    }
    const hideAddEditEanModal = (event) => {
        dispatch(setVatCodeMappingCodeModal(event))
    }

    const allSupplierVatCodelists = async () => {
        try { 
            const id = editTabData ? editTabData.id : hasEditData.id;
            setState({ ...state, pageLoader: true })
            setTimeout(() => {
                setColumns(allColumns)
            }, 100);
            const { data } = await axios_supplier.get(`supplier/${id}/vatCode`);
            if (data) {
                setState({ ...state, pageLoader: false })
                dispatch(setVatCodeList(data))
            }
        } catch (error) {
            setState({ ...state, pageLoader: false })
            const { response: { data, status } } = error;
            if (data.message !== "VAT code details not found") {
                setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: data.message ? "warning" : "error" })
            }
        }
    }
    useEffect(() => {
        allSupplierVatCodelists();
    }, []);

    const deleteVatCodeMappings = (row) => {
        dispatch(setDeleteList(row))
        dispatch(setSupplierDeleteData({
            key: 'supplierVatCodeokDelete',
            value: true
        }))

        dispatch(warningModal({
            yesHide: !handleWarning
        }))
    }

    useEffect(() => {
        if (supplier_all_fields.supplierVatCodeConfirmDelete) {
            deleteSingleRow()
        }
    }, [supplier_all_fields.supplierVatCodeConfirmDelete])

    const deleteSingleRow = async () => {
        if (supplier_all_fields.deleteList !== null) {
            try {
                setState({ ...state, pageLoader: true })
                const id = (supplier_all_fields.hasEditData || supplier_all_fields.editTabData) ? (supplier_all_fields.hasEditData.id || supplier_all_fields.editTabData.id) : null
                const { data } = await axios_supplier.delete(`supplier/${id}/vatCode/${supplier_all_fields.deleteList.id}`);
                if (data.success) {
                        setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
                        const filteredRows = getVatCodeMappings.filter(vatCode => {
                            if (vatCode.id !== supplier_all_fields.deleteList.id) {
                                return vatCode;
                            }
                        })
                        dispatch(setVatCodeList(filteredRows))
                        dispatch(setDeleteList(null))
                        supplierDeleteRowDispatch()
                        setState({ ...state, pageLoader: false })
                }

            } catch (error) {
                const { response: { data, status } } = error;
                if (status === 500 && data.errorType === "Business") {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" });
                } else {
                    setSnackBar({ ...snackBar, show: true, text: "Something went wrong!", type: "error", })
                }
                setState({ ...state, pageLoader: false })
                supplierDeleteRowDispatch()
            }
        }
    }

    const supplierDeleteRowDispatch = () => {
        dispatch(setSupplierDeleteData({
            key: 'supplierVatCodeokDelete',
            value: false
        }))
        dispatch(setSupplierDeleteData({
            key: 'supplierVatCodeConfirmDelete',
            value: false
        }))
    }


    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Box sx={{ flexGrow: 1, paddingX: 2 }} >
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5 } }}>
                    <div className='table-actionable'>
                        <Button variant="contained" onClick={(e) => addvatCodeMappingCodeModal(e)} startIcon={<AddIcon />}>
                            Add New Vat Code
                        </Button>
                    </div>
                </Box>
                <DataTableClientSide
                    columns={columns ? columns : []}
                    defaultGrid={state.defaultGrid}
                    rows={getVatCodeMappings ? getVatCodeMappings : []}
                >
                </DataTableClientSide>
                {showVatCodeMappingModal && (
                    <>
                        <AddEditVatCodeMappings size="medium_overlay"
                            onShow={showVatCodeMappingModal}
                            onHide={(e, reason) => hideAddEditEanModal(e, reason)}
                        />
                    </>
                )}
            </Box>
            {snackBar.show && <SnackBarDialog
            onShow={snackBar.show}
            text={snackBar.text}
            type={snackBar.type}
            onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
        />}
        </>
    );
}
