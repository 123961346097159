import { useEffect, useState } from "react";
import {
    Grid, Paper, Box, FormLabel, Button, Autocomplete, Typography, TextField, FormHelperText, Checkbox, FormControlLabel, FormControl, Alert, Snackbar, Modal
} from "@mui/material";
import PropTypes from "prop-types";
import CancelIcon from '@mui/icons-material/Cancel';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { styled } from '@mui/material/styles';
import { warningModal, handleAddEditModal } from "../../../store/slices/modals";
import { hasEdit, cbsvatcodeSearch, cbsProductInput, getIsError, setAutocomplete, hasEditData } from "../../../store/slices/cbsProduct";
import { DataTableAccountingEntriesDetails } from '../../../components/shared/data-table/DataTableAccountingEntriesDetails';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { useCallback } from "react";
import moment from "moment";
import { handleViewMoreModal } from "../../../store/slices/modals";

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

function DetailAccountingEntry({ tabs, onShow, onHide, svalues, size = '' }) {

    const [state, setState] = useState({
        loader: false,
        formValid: true,
        newcbsData: [],
        defaultGrid: {
            pagetitle: "Detailed Accounting Entries of #" + svalues.serverProcessEntryId,
            pdcActionButton: true,
            hasExport: true,
            addEditButton: true,
            apiUrl: 'accountingEntryGroups/' + svalues.serverProcessEntryId + '/accountingEntries',
            searchUrl: 'accountingEntryGroups/' + svalues.serverProcessEntryId + '/accountingEntries'
        }
    });

    const columns = [

        {
            headerName: "Entry Date",
            field: "entryDate",

            width: 100
        },
        {
            headerName: "Member Name",
            field: "memberName",
            minWidth: 150,
            flex: 1,
            renderCell: (params) => (
                params.value ? <Typography title={params.value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px' }} >{params.value}</Typography> : ""
            )
        },
        {
            headerName: "Supplier Name",
            field: "supplierName",
            minWidth: 120,
            flex: 1,
            renderCell: (params) => (
                params.value ? <Typography title={params.value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px' }} >{params.value}</Typography> : ""
            )
        },
        {
            headerName: "Amount (£)",
            field: "amount",
            width: 100,
            valueFormatter: (params) => { return params.value.toFixed(2);
                // if (params.value == null) {
                //     return '';
                // }

                // const valueFormatted = Number(params.value * 100).toLocaleString();
                // return `${valueFormatted} %`;
            }
            // renderCell: (params) => (moment(params.value).format('MM-DD-YYYY'))
        },
        {
            headerName: "Account",
            field: "account",

            width: 90
            // renderCell: (params) => (moment(params.value).format('MM-DD-YYYY'))
        },
        {
            headerName: "Cost Center",
            field: "costCentre",
            width: 100,
            renderCell: (params) => (
                params.value ? <Typography title={params.value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px' }} >{params.value}</Typography> : ""
            )
        },
        {
            headerName: "Run By",
            field: "user",
            width: 100,
            renderCell: (params) => (
                params.value ? <Typography title={params.value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px' }} >{params.value}</Typography> : ""
            )
        },
        {
            headerName: "Department",
            field: "department",

            width: 100
        },
        {
            headerName: "Sub Ledger Ref",
            field: "subLedgerRef",

            width: 120
        },
        {
            headerName: "Transaction Ref",
            field: "transactionRef",

            minWidth: 130,
            renderCell: (params) => (
                params.value ? <Typography title={params.value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px' }} >{params.value}</Typography> : ""
            )
        },
        {
            headerName: "Server Process",
            field: "serverProcessEntry",

            width: 130,
            renderCell: (params) => (
                params.value ? <Typography title={params.value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px' }} >{params.value}</Typography> : ""
            )
        },
        {
            headerName: "Exported",
            field: "exported",
            align: "center",
            headerAlign: "center",
            width: 100,
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
        },
        {
            headerName: "Cancelled",
            field: "cancelled",
            align: "center",
            headerAlign: "center",
            width: 100,
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
        }
    ];



    const handleClose = () => {
        dispatch(handleViewMoreModal({
            showViewMore: false
        }))
    };

    const dispatch = useDispatch();

    return (
        <>
            <Modal
                open={onShow || false}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <Box component="div" className="overlay" id="addEditModal">
                    <div className={`overlay-box large-size`}>
                        <div className="content">
                            <div className="overlayBody">
                                <div className="overlay-header text-center"> Accounting Entry details for #{svalues.serverProcessEntryId}
                                    <CancelIcon className="closeBtn" onClick={handleClose} />
                                </div>
                                <div className="overlay-content height3">
                                    <Grid item xs={12} md={12} sm={12} sx={{ padding: '10px' }}>
                                        <DataTableAccountingEntriesDetails defaultGrid={state.defaultGrid} columns={columns}>
                                        </DataTableAccountingEntriesDetails>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}

export default DetailAccountingEntry;