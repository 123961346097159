import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editTab: true,
  hasEditPdc: null,
  editTabData: null,
  hasEditData: null,
  organisation_type_name: '',
  organisationTypeFormValid: false,
  deleteList: null,
  organisationTypeStartDelete: false,
  organisationTypeConfirmDelete: false,
  isError: {
    organisation_type_name: false
  }
}

const organisationType = createSlice({
  name: 'organisation_type',
  initialState,
  reducers: {
    brandSearch: (state, action) => {
      const { parent, key, value } = action.payload;
      state[parent][key] = value;
    },
    organisationTypeInput: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
      state[key].length === 0 ? state.isError[key] = true : state.isError[key] = false
    },
    getIsError: (state, action) => {
      const { key, value } = action.payload;
      state.isError[key] = value
    },
    setAutocomplete: (state, action) => {
      const { parent, key, value } =  action.payload;
      state[parent][key] = value
    },
    hasTabEdit: (state, action) => {
      const { editTab } =  action.payload;
      state.editTab = editTab
    },
    hasTabEditData: (state, action) => {
      const { editData } =  action.payload;
      state.editTabData = editData
    },
    setHasEditData: (state, action) => {
      state.hasEditData = action.payload;
    },
    setorganisationTypeFormStatus: (state, action) => {
      state.organisationTypeFormValid = action.payload;
    },
    setDeleteList: (state, action) => {
      state.deleteList = action.payload
    },
    setOrganisationTypeDeleteData: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    resetorganisationType: () => {
      return initialState
    },
  }
})

export const { brandSearch, hasTabEditData, setAutocomplete, getIsError, organisationTypeInput, hasTabEdit, 
  resetorganisationType, setHasEditData, setorganisationTypeFormStatus, setDeleteList, setOrganisationTypeDeleteData } = organisationType.actions

export default organisationType.reducer