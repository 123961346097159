import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selected_supplier: null,
  selectedIds: [],
  selectedIdsRows: [],
  startDelete: false,
  confirmDelete: false,
}

const failedImports = createSlice({
  name: 'failedImports_product',
  initialState,
  reducers: {
    setSelectedIds: (state, action) => {
      state.selectedIds = action.payload
    },
    setSelectedIdsRows: (state, action) => {
      state.selectedIdsRows = action.payload
    },
    failedImportDeleteYes: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value
    },
    resetfailedImports: () => {
      return initialState
    },
  }
})

export const { setSelectedIds, setSelectedIdsRows, resetfailedImports, failedImportDeleteYes } = failedImports.actions

export default failedImports.reducer