import { Container, Box, Modal, Button, Grid, Checkbox } from '@mui/material'
import { TableFilters } from "../../components/shared/data-table/TableFilters";
import { useEffect, useState } from "react";
import { InputText } from "../../components/shared/form/InputText";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { } from '@mui/material';
import { ThreeStateCheckbox } from "../../components/shared/form/ThreeStateCheckbox";
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange, filterResetFlagChange } from "../../store/slices/externalFilter";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import AddIcon from '@mui/icons-material/Add';
import SnackBarDialog from "../../components/shared/SnackBarDialog";
import { warningModal, handleAddEditDC } from "../../store/slices/modals";
import WarningModal from "../../components/shared/modals/WarningModal";
import PageLoader from "../../components/shared/PageLoader";
import { setRowPage, resetDataGrid, setPageNo } from '../../store/slices/dataGrid';
import axios_product_distribution from '../../services/utility/axios-product-distribution';
import { useLayoutEffect } from "react";
import { DataTableProductDistributionService } from "../../components/shared/data-table/DataTableProductDistributionService";
import { brandSearch, hasEdit, setHasEditData, dcProductInput, setDCList, setDeleteList, setDCSingleDeleteData, resetDC } from "../../store/slices/distributionChannel";
import AddEditDistributionChannelDetail from "./AddEditDistributionChannel/AddEditDistributionChannelDetail";

const IconMenuLink = ({ params, sendSnackBar, startLoading, onDelete }) => {
    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const [pageLoader, setPageLoader] = useState(false);
    const open = Boolean(anchorEl);

    const { showAddEditModal, distributionChannel_container } = useSelector((state) => {
        return {
            showAddEditModal: state.modalActions.hideAddEditModal,
            editTabData: state.dcProducts.editTabData,
            distributionChannel_container: state.dcProducts
        }
    });
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'edit') {
            // dispatch(handleAddEditDC({
            //     showAddEdit: !showAddEditModal
            // }))
            // dispatch(hasEdit({ editTab: false }))

            setEditDCData(row);
        } else if (action === 'delete') {
            if (onDelete) {
                onDelete(row)
            }
        }
        setAnchorEl(null);
    };

    const setEditDCData = async (row) => {
        try {
            if (startLoading) {
                startLoading(true)
            }
            const { data } = await axios_product_distribution.get(`distributionChannels/${row.id}`);

            if (data) {
                if (startLoading) {
                    startLoading(false)
                }
                dispatch(handleAddEditDC({
                    showAddEdit: !showAddEditModal
                }))
                dispatch(setHasEditData(data))
                dispatch(brandSearch({
                    parent: 'dc_central_bil_scheme',
                    key: 'dc_central_bil_scheme_selected',
                    value: data.centralBillingScheme ? data.centralBillingScheme : null
                }))
                dispatch(dcProductInput({
                    key: 'dc_name',
                    value: data.name
                }))
                dispatch(dcProductInput({
                    key: 'dc_status',
                    value: data.active
                }))
            }
        }
        catch (error) {
            if (sendSnackBar) {
                sendSnackBar(error)
            }
        }
    }

    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'edit')}>Edit</MenuItem>
            <MenuItem onClick={(e) => handleClose(e, 'delete')}>Delete</MenuItem>
        </Menu>
    </>)
}


function DistributionChannel() {

    const columns = [
        {
            headerName: "ID",
            field: "id",
            flex: 1,
            minWidth: 200,
            hide: true
        },
        {
            headerName: "Name",
            field: "name",
            flex: 1,
            width: 180
        },
        {
            headerName: "Active",
            field: "active",
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
            align: "center",
            headerAlign: "center",
        },
        {
            headerName: "Action",
            field: "action",
            resizable: false,
            sortable: false,
            width: 65,
            renderCell: (params) => {
                return <IconMenuLink params={params} startLoading={pageLoader} onDelete={deleteDC} />;
            }
        }
    ];

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })
    const [state, setState] = useState({
        active_dc_product: null,
        pageLoader: false,
        openDCModal: false,
        defaultGrid: {
            pagetitle: "Distribution Channel",
            brandActionButton: true,
            hasExport: true,
            addEditButton: true,
            apiUrl: 'distributionChannels',
            searchUrl: 'distributionChannels/filter'
        }
    });

    const pageLoader = (loader) => {
        setState({ ...state, pageLoader: loader })
    }

    const { addProductwarning, setAddEditDistributionChannel, distributionChannel_container, handleWarning, getDCLists } = useSelector((state) => {
        return {
            addProductwarning: state.modalActions.closeWaringAddProduct,
            setAddEditDistributionChannel: state.modalActions.hideAddEditDC,
            distributionChannel_container: state.dcProducts,
            handleWarning: state.modalActions.closeWaringAddProduct,
            getDCLists: state.dcProducts.dc_list
        }
    });

    const dispatch = useDispatch();

    const handleFilterInput = (value, id) => {
        //setState({ ...state, [id]: value });
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    };

    const handleChange = (checked, name) => {
        // setState({ ...state, [name]: checked });
        state.active_dc_product = checked;
        dispatch(setFilter({
            key: name,
            value: checked
        }));
        setTimeout(() => {
            filterBtn();
        }, 500);
    };

    // const handleChange = (checked, name) => {
    //     setState({ ...state, [name]: checked })
    //     dispatch(setFilter({
    //         key: name,
    //         value: checked
    //     }))
    // };

    // eslint-disable-next-line max-len
    const { dc_name, active_dc_product } = useSelector((state) => {
        return {
            dc_name: state.externalFilter.dc_name,
            active_dc_product: state.externalFilter.active_dc_product,
        }
    });
    const filterBtn = () => {
        dispatch(resetDataGrid());
        dispatch(applyFilter({
            key: 'filterList',
            value: {
                "name": dc_name,
                "active": state.active_dc_product
                // "active": active_dc_product
            }
        }))
        dispatch(filterFlagChange(true));
        dispatch(filterResetFlagChange(false));
        dispatch(setRowPage({ pageData: 10 }));
        dispatch(setPageNo({ page: 1 }));
    }
    const resetFilter = () => {
        if ((dc_name) !== '') {
            dispatch(resetExternalFilter())
            dispatch(resetDataGrid());
        }
        setState({ ...state, active_dc_product: null })
        dispatch(filterResetFlagChange(true));
    }
    const addDistributionChannelModal = () => {
        dispatch(resetDC())
        dispatch(handleAddEditDC({
            showAddEdit: !setAddEditDistributionChannel
        }))
    }
    const handleDistributionChannelModal = (event) => {
        setState({ ...state, openDCModal: false })
    }


    const deleteDC = (row) => {
        dispatch(setDeleteList(row))
        dispatch(setDCSingleDeleteData({
            key: 'dcStartSingleDelete',
            value: true
        }))
        dispatch(warningModal({
            yesHide: !handleWarning
        }))
    }

    useEffect(() => {
        if (distributionChannel_container.dcConfirmSingleDelete) {
            deleteSingleRow()
        }
    }, [distributionChannel_container.dcConfirmSingleDelete])

    const deleteSingleRow = async () => {
        if (distributionChannel_container.deleteList !== null) {
            try {
                setState({ ...state, pageLoader: true })
                const { data } = await axios_product_distribution.delete(`distributionChannels/${distributionChannel_container.deleteList.id}`);
                if (data.success) {
                    setState({ ...state, pageLoader: false });
                    dispatch(setDeleteList(null));
                    dcSingleDeleteRowDispatch();
                    setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Distribution Channel Delete Successfully", type: "success" });
                    setTimeout(() => {
                        // window.location.reload();
                        dispatch(filterFlagChange(true));
                        dispatch(filterResetFlagChange(false));
                    }, 1500);
                    // try {
                    //     const { data } = await axios_product_distribution.get(`distributionChannels`);
                    //     if (data !== null || data !== undefined) {
                    //         dispatch(setDCList(data.items))
                    //         setSnackBar({ ...snackBar, show: true, text: "Distribution Channel Delete Successfully", type: "success" })
                    //         setState({ ...state, pageLoader: false })
                    //         dispatch(setDeleteList(null))
                    //         dcSingleDeleteRowDispatch()
                    //         setTimeout(() => {
                    //             // window.location.reload();
                    //             dispatch(filterFlagChange(true));
                    //             dispatch(filterResetFlagChange(false));
                    //         }, 1500);
                    //     }
                    // }
                    // catch (error) {
                    //     const { response: { data, status } } = error;
                    //     if (status === 500 && data.errorType === "Business") {
                    //         setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" });
                    //     } else {
                    //         setSnackBar({ ...snackBar, show: true, text: "Something went wrong!", type: "error", })
                    //     }
                    //     setState({ ...state, pageLoader: false })
                    //     dcSingleDeleteRowDispatch()
                    // }
                }

            } catch (error) {
                const { response: { data, status } } = error;
                if (status === 500 && data.errorType === "Business") {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" });
                } else {
                    // setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
                    setSnackBar({ ...snackBar, show: true, text: "The ECS services are down at the moment.", type: "error", });
                }
                setState({ ...state, pageLoader: false })
                dcSingleDeleteRowDispatch()
            }
        }
    }

    const dcSingleDeleteRowDispatch = () => {
        dispatch(setDCSingleDeleteData({
            key: 'dcStartSingleDelete',
            value: false
        }))
        dispatch(setDCSingleDeleteData({
            key: 'dcConfirmSingleDelete',
            value: false
        }))
    }

    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Distribution Channel" />
                <Box sx={{ marginBottom: 2.5 }}>
                    <TableFilters
                        filterFields={<>
                            <Box component="div"><InputText onChange={handleFilterInput} maxWidth={50} label="Name" id="dc_name" size="small" /></Box>
                        </>}
                        filterCheckboxes={<>
                            <Box component="div">
                                <ThreeStateCheckbox checked={state.active_dc_product} onChange={handleChange} name="active_dc_product" id="active" label="Active" />
                            </Box>
                        </>}
                        filterBtns={
                            <>
                                <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                <Button variant="contained" onClick={filterBtn}>Apply</Button>
                            </>}>
                    </TableFilters>
                </Box>
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -2.4 } }}>
                    {/* <div className='table-actionable'>
                        <Button variant="contained" color="secondary" size='small' startIcon={<SendIcon />}>
                            Export as Excel
                        </Button>
                    </div> */}
                    <div className='table-actionable'>
                        <Button variant="contained" size='small' onClick={(e) => addDistributionChannelModal(e)} startIcon={<AddIcon />}>New Distribution Channel</Button>
                    </div>
                </Box>


                <DataTableProductDistributionService defaultGrid={state.defaultGrid} columns={columns} rows={getDCLists}>

                </DataTableProductDistributionService>
                {
                    setAddEditDistributionChannel && (
                        <AddEditDistributionChannelDetail
                            onShow={setAddEditDistributionChannel} size="medium_overlay"
                            onHide={(e, reason) => handleDistributionChannelModal(e, reason)}

                        />
                    )
                }
                {
                    addProductwarning && (
                        <WarningModal size="small_overlay" onShow={addProductwarning} />
                    )
                }
                {snackBar.show && <SnackBarDialog
                    onShow={snackBar.show}
                    text={snackBar.text}
                    type={snackBar.type}
                    onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                />}

            </Container>

        </>
    );
}

export default DistributionChannel;
