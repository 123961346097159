import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    reprocessIds: [],
    reprocessIdsRows: [],
    deleteList: null,
    reprocessStartDelete: false,
    reprocessConfirmDelete: false,
    processList: null,
    processName: '',
    processLabel: '',
    processStart: false,
    processConfirm: false,
}

const invoiceReprocess = createSlice({
    name: 'invoice_reprocess',
    initialState,
    reducers: {
        setReprocessIds: (state, action) => {
            state.reprocessIds = action.payload
        },
        setReprocessIdsRows: (state, action) => {
            state.reprocessIdsRows = action.payload
        },
        setDeleteList: (state, action) => {
            state.deleteList = action.payload
        },
        setReprocessDeleteData: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value;
        },
        setProcessList: (state, action) => {
            state.processList = action.payload
        },
        setProcessData: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value;
        },
        resetProcessData: (state, action) => {
            state.processList = null;
            state.processName = '';
            state.processLabel = '';
            state.processStart = false;
            state.processConfirm = false;
        },
        resetinvoiceReprocess: () => {
            return initialState
        }
    }
})

export const { setReprocessIds, setReprocessIdsRows, setDeleteList, setReprocessDeleteData, setProcessList, setProcessData, resetProcessData, resetinvoiceReprocess } = invoiceReprocess.actions

export default invoiceReprocess.reducer