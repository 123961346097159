import * as React from 'react';
import {
    DataGridPremium,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
    gridPaginationRowRangeSelector,
    gridPaginatedVisibleSortedGridRowIdsSelector,
    gridSortedRowIdsSelector,
    gridVisibleSortedRowIdsSelector,
    GridToolbarContainer
} from '@mui/x-data-grid-premium';
import { Box, Button, Stack } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useLocation } from 'react-router-dom';
import PageLoader from "../../../components/shared/PageLoader";
import { Pagination, PaginationItem, Select, FormControl, MenuItem } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import axiosDiscountSchemes from '../../../services/utility/axios-discount-schemes';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { setRowPage, setPageNo, resetDataGrid } from '../../../store/slices/dataGrid'
import { resetExternalFilter, filterFlagChange, filterResetFlagChange } from "../../../store/slices/externalFilter";
import { useLayoutEffect } from 'react';
import SnackBarDialog from '../SnackBarDialog';
import axios from 'axios';

var filflag = 0;
var applybtnflag = 0;

export const DataTableDiscountSchemes = (props) => {
const [state, setState] = useState({
        isLoading: false,
        firstload: 0,
        data: [],
        total: 0,
        page: 1,
        pageSize: 10,
        applyFilterCount: null
    });
    const [loadingTable, setLoadingTable] = useState(true)
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    });
    function CustomPagination() {

        const dispatch = useDispatch();
        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        const rowDataList = [10, 25, 50, 100, 200, 300]

        const handleChange = (event) => {
            // dispatch(setRowPage({ pageData: event.target.value }));
            setState({ ...state, pageSize: event.target.value, page: 1 })
            // if (filflag !== 0) {
            //     dispatch(filterFlagChange(true));
            // }
            applybtnflag = 1;
            dispatch(filterFlagChange(true));
            dispatch(filterResetFlagChange(false));
        };

        const paginationChange = (event, value) => {
            apiRef.current.setPage(value - 1);
            //dispatch(setPageNo({ page: value }));
            setState({ ...state, page: value })
        }

        return (
            <>
                <div className='grid-pagiation'>
                    <Box className='customRowPerPageBox' component="div">
                        <div>
                            View
                        </div>
                        <Box component="div" sx={{ marginX: '10px' }}>
                            <FormControl>
                                <Select
                                    size="small"
                                    id="row-per-page"
                                    defaultValue="choose"
                                    onChange={handleChange}
                                    value={state.pageSize}
                                    aria-describedby={'row-per-page'} >
                                    {rowDataList.map((value) => {
                                        return (
                                            <MenuItem value={value} key={value}>{value}</MenuItem>
                                        )
                                    }

                                    )}
                                </Select>

                            </FormControl>
                        </Box>
                        <div>
                            rows per page
                        </div>
                    </Box>
                    <Pagination
                        variant="outlined"
                        shape="rounded"
                        count={pageCount}
                        page={page + 1}
                        onChange={(event, value) => paginationChange(event, value)}
                        renderItem={(item) => (
                            <PaginationItem
                                components={{ previous: KeyboardArrowLeftIcon, next: KeyboardArrowRightIcon }}
                                {...item}
                            />
                        )}
                    />
                </div>
            </>
        );
    }
    const { columns, defaultGrid } = props;

    const { applyFilter } = useSelector((state) => {
        return {
            applyFilter: state.externalFilter,
        }
    });
    const dispatch = useDispatch();

    useEffect(() => {
        /* load first first time */
        return () => {
            setTimeout(() => {
                setState({ ...state, isLoading: true })
                dispatch(resetExternalFilter())
                dispatch(filterFlagChange(false));                
                //dispatch(setRowPage({ pageData: 10 }));
                //dispatch(setPageNo({ page: 1 }));
            }, 1500);
        }
    }, [])

    useEffect(() => {
        const fetchMyAPI = async () => {
            try {
                if (state.firstload === 0 && filflag === 0) {
                    // console.log("First Load", filflag, state.firstload, applyFilter.filterApplied, applyFilter.filterReset, localStorage.getItem("pageload"));
                    setState({ ...state, isLoading: true, data: [] })
                    // eslint-disable-next-line max-len
                    const { data } = await axiosDiscountSchemes.post(`${defaultGrid.apiUrl}?page=${state.page}&pageSize=${state.pageSize}`, {"active": true});
                    // eslint-disable-next-line max-len
                    setState({ ...state, isLoading: false, data: data.items, totalRows: data.totalCount, total: data.totalCount, applyFilterCount: null });
                    
                }
            }
            catch (error) {
                const { response: { data, status }, message } = error;
                if (status === 500) {
                    setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
                } else {
                    setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
                }
                setState({ ...state, isLoading: false, data: [] });
            }
            setTimeout(() => {
                setLoadingTable(false)
            }, 250);

        }
        fetchMyAPI();
    }, [state.page, state.pageSize, state.firstload])

    useEffect(() => {

        const ourRequest = axios.CancelToken.source();

        const fetchMyAPI = async () => {
            if (!applyFilter.filterApplied && filflag !== 0 && applyFilter.filterReset) {
                try {
                    setLoadingTable(true)
                    setState({ ...state, isLoading: true, data: [], page: 1, pageSize: 10 })
                    // eslint-disable-next-line max-len
                    const { data } = await axiosDiscountSchemes.post(`${defaultGrid.apiUrl}?page=${state.page}&pageSize=${state.pageSize}`,{},{
                        cancelToken: ourRequest.token,
                    });
                    // eslint-disable-next-line max-len
                    setState({ ...state, isLoading: false, data: data.items, totalRows: data.totalCount, total: data.totalCount, applyFilterCount: null });
                    
                }
                catch (error) {
                    if (error.response) {
                        const { response: { data, status }, message } = error;
                        if (status === 500) {
                            setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
                        } else {
                            setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
                        }
                        setState({ ...state, isLoading: false, data: [] });
                    }

                }
                filflag = 0;
                applybtnflag = 0;

            }
        }
        fetchMyAPI();
        return () => {
            ourRequest.cancel()
        }
    }, [state.page, state.pageSize, applyFilter.filterReset])

    useEffect(() => {

        const ourRequest = axios.CancelToken.source();

        const fetchMyAPI = async () => {
            if (applyFilter.filterApplied) {
                setState({ ...state, isLoading: true, data: [] })
                try {
                    if (applybtnflag === 0) {
                        const { data } = await axiosDiscountSchemes.post(`${defaultGrid.searchUrl}?page=1&pageSize=${state.pageSize}`, Object.keys(applyFilter.filterList).length === 0 ? {"active": true} : applyFilter.filterList, {
                            cancelToken: ourRequest.token,
                        });
                        // eslint-disable-next-line max-len
                        setState({ ...state, isLoading: false, data: data.items, totalRows: data.totalCount, total: data.totalCount, applyFilterCount: data.totalCount, page: 1 });
                    }
                    else {
                        const { data } = await axiosDiscountSchemes.post(`${defaultGrid.searchUrl}?page=${state.page}&pageSize=${state.pageSize}`, Object.keys(applyFilter.filterList).length === 0 ? {"name": true} : applyFilter.filterList, {
                            cancelToken: ourRequest.token,
                        });
                        // eslint-disable-next-line max-len
                        setState({ ...state, isLoading: false, data: data.items, totalRows: data.totalCount, total: data.totalCount, applyFilterCount: data.totalCount });
                        applybtnflag = 0;
                    }
                    

                } catch (error) {
                    // eslint-disable-next-line max-len
                    const { response: { data, status }, message } = error;
                    setState({ ...state, isLoading: false, data: [], totalRows: 0, total: 0 });
                    setLoadingTable(false)
                    if (status === 500) {
                        setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
                    } else {
                        setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
                    }
                    setState({ ...state, isLoading: false, data: [], totalRows: 0, total: 0 });
                    applybtnflag = 0;
                }
                dispatch(filterFlagChange(false));
                filflag = 1;
            }
        }
        fetchMyAPI();
        return () => {
            ourRequest.cancel()
        }
    }, [state.page, state.pageSize, applyFilter.filterApplied])

    const onPageChange = (newPage) => {
        // dispatch(setPageNo({ page: newPage + 1 }));
        setState({ ...state, page: newPage + 1 });
        if (filflag !== 0) {
            applybtnflag = 1;
            dispatch(filterFlagChange(true));
        }
        dispatch(filterResetFlagChange(false));
    };

    const onPageSizeChange = (newPageSize) => {
        // dispatch(setRowPage({ pageData: newPageSize }));
        setState({ ...state, pageSize: newPageSize });
    };

    // const handleTableAction = () => {
    //     if (addProductModal) {
    //         addProductModal();
    //     }
    //     if(addBrandModal){
    //         addBrandModal()
    //     }
    // }

    const getFilteredRows = ({ apiRef }) => gridVisibleSortedRowIdsSelector(apiRef);
    const CustomToolbar = () => {
        const apiRef = useGridApiContext();

        const handleExport = (options) => apiRef.current.exportDataAsExcel(options);

        return (
            <GridToolbarContainer sx={{ float: 'right', margin: '10px' }}>

                <Button variant="contained" disabled={state.data.length > 0 ? false : true} color="secondary" size='small' onClick={() => handleExport({ getRowsToExport: getFilteredRows, fileName: defaultGrid.pagetitle })} startIcon={<SendIcon />}>
                    Export as Excel
                </Button>
            </GridToolbarContainer>
        );
    };


    return (

        <>
            <Box component="div" className='table-actions-buttons' sx={{ columnGap: 1.75, display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2.5 }}>
                {/* <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center' }, textAlign: { xs: "center" } }}>
                    
                    {defaultGrid.brandActionButton && (
                        <>
                            <div className='table-actionable'>
                                <Button variant="contained" color="secondary" size='small' startIcon={<SendIcon />}>
                                    Export as Excel
                                </Button>
                            </div>
                            {addBrandModal && (
                                <div className='table-actionable'>
                                    <Button variant="contained" size='small' onClick={(e) => handleTableAction(e)} startIcon={<AddIcon />}>
                                        Add New Brand
                                    </Button>
                                </div>
                            )}
                        </>)
                    }

                </Box> */}
                <Box component="div" className='data-per-row-message'>
                    Showing {state.pageSize * (state.page - 1) + 1} to {((state.total < (state.pageSize * state.page)) ? state.total : (state.pageSize * state.page))} out of {state.total} entries
                </Box>
            </Box>
            <div style={{ width: '100%' }}>
                {state.isLoading && <PageLoader />}
                {snackBar.show &&
                    <SnackBarDialog
                        onShow={snackBar.show}
                        text={snackBar.text}
                        type={snackBar.type}
                        onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                    />
                }
                <div style={{ height: '468px', width: '100%' }}>
                    <DataGridPremium
                        rows={state.data}
                        columns={columns ? columns : []}
                        // autoHeight={true}
                        // disableColumnMenu={true}
                        rowCount={state.total}
                        //loading={state.isLoading}
                        pagination={true}
                        page={state.page - 1}
                        pageSize={state.pageSize}
                        paginationMode="server"
                        onPageChange={(newPage) => onPageChange(newPage)}
                        onPageSizeChange={(newPageSize) => onPageSizeChange(newPageSize)}
                        components={{
                            Pagination: CustomPagination,
                            Toolbar: CustomToolbar,
                            NoRowsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    {loadingTable ? 'Fetching records' : 'Search result not found'}
                                </Stack>
                            ),
                            NoResultsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    Fetching records
                                </Stack>
                            )
                        }}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? '' : 'oddRow'
                        }
                        initialState={{ pinnedColumns: { right: ['action'] } }}
                        sx={{
                            "& .MuiDataGrid-columnHeader": {
                                backgroundColor: "#F0F0F0"
                                // color: "red"
                            },
                            "& .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle": {
                                fontWeight: "600",
                                fontSize: '14px',
                                color: "#15191E"
                            },
                            "& .MuiDataGrid-row.oddRow": {
                                backgroundColor: "#FBFBFB"
                                // color: "red"
                            },
                            "& .MuiDataGrid-row.Mui-hovered, .MuiDataGrid-row.Mui-selected, .MuiDataGrid-row.Mui-selected.Mui-hovered": {
                                backgroundColor: "#FFF5E1"
                                // color: "red"
                            },
                            '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
                                borderRight: `1px solid #E6E6E6`,
                                borderBottom: `1px solid #E6E6E6`,
                            },
                            '& .MuiDataGrid-cell': {
                                fontWeight: '500',
                            },
                        }}
                    />
                </div>

            </div>
        </>
    );
}