import { Box, Modal, Paper, FormControl, Grid, TextField, FormHelperText, FormLabel, Button, FormGroup, FormControlLabel, Checkbox, Autocomplete } from '@mui/material';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { warningModal } from "../../../store/slices/modals";
import { AutoCompleteStatic } from "../../../components/shared/form/AutoCompleteStatic";
import { handleFileUploadModal, setNewPriceModal } from '../../../store/slices/modals';
import CommonPopUp from '../addEditPdc/pdc-tabs/CommonPopUp';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { smaInput, setMemberCode } from '../../../store/slices/supplierMemberAccounts';
import { setFilter } from '../../../store/slices/externalFilter';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

var supplierDropDown = "blank";
var distributionChannelDropDown = "blank";
var sup_id = "na";
var sup_name = "na";
var dc_id = "na";
var dc_name = "na";

export default function UploadFileSelectModal({ tabs, onShow, onHide, existingUploads }) {

    const fileStatus = useRef("Select Supplier and Distribution Channel to proceed further.");

    const existingSuppliers = existingUploads.map(items => {
        return items.supplier.name;
    });

    const existingDC = existingUploads.map(items => {
        return items.distributionChannel.name;
    });

    const [state, setState] = useState({
        defaultstatus: "Select Supplier and Distribution Channel to proceed further.",
        selectFileBtn: true,
        uploadFileBtn: true,
        promoflag: false,
        supplier_id: 'na',
        supplier_name: 'na',
        dc_id: 'na',
        dc_name: 'na',
        handleSupplierMemberModal: '',
        sp_name: '',
        sp_id: '',
        pageLoader: false,
        rawfile: {}

    });

    const { handleWarning, showNewPriceoneModal, sma_container } = useSelector((state) => {
        return {
            handleWarning: state.modalActions.closeWaringAddProduct,
            showNewPriceoneModal: state.modalActions.priceModal,
            sma_container: state.smas
        }
    });

    const dispatch = useDispatch();

    const handleClose = () => {
        // dispatch(warningModal({
        //     yesHide: !handleWarning
        // }))
        dispatch(handleFileUploadModal({
            showFileUpload: false
        }));
    };

    const handleCancel = () => {
        dispatch(warningModal({
            yesHide: !handleWarning
        }))
    };

    const handleFileClose = () => {
        if (onHide) {
            onHide(state)
        }
    };

    const handleSDropDownSelect = (value, id) => {
        // console.log(value, id);
        if (value !== null && value !== "") {

            var found_s = existingSuppliers.find(x => { return x === value.name });
            if (found_s === value.name) {
                supplierDropDown = "match";
                setState({ ...state, supplier_id: 'value.id', supplier_name: 'value.name' });
                sup_id = value.id;
                sup_name = value.name;
            }
            else if (found_s !== "") {
                supplierDropDown = "notmatch";
                setState({ ...state, supplier_id: 'value.id', supplier_name: 'value.name' });
                sup_id = value.id;
                sup_name = value.name;
            }
            else {
                supplierDropDown = "blank";
                setState({ ...state, defaultstatus: "Select Supplier and Distribution Channel to proceed further.", selectFileBtn: true, uploadFileBtn: true }); // , supplier_id: 'na', supplier_name: 'na'
                sup_id = 'na';
                sup_name = 'na';
            }
        }
        else {
            supplierDropDown = "blank";
            setState({ ...state, defaultstatus: "Select Supplier and Distribution Channel to proceed further.", selectFileBtn: true, uploadFileBtn: true }); // , supplier_id: 'na', supplier_name: 'na' 
            sup_id = 'na';
            sup_name = 'na';
        }
        combineDropdowncheck(supplierDropDown, distributionChannelDropDown);
    }

    const handleDcDropDownSelect = (value, id) => {
        if (value !== null && value !== "") {

            let found_dc = existingDC.find(x => { return x === value.name });

            if (found_dc === value.name) {
                distributionChannelDropDown = "match";
                setState({ ...state, dc_id: value.id, dc_name: value.name });
                dc_id = value.id;
                dc_name = value.name;
            }
            else if (found_dc !== "") {
                distributionChannelDropDown = "notmatch";
                setState({ ...state, dc_id: value.id, dc_name: value.name });
                dc_id = value.id;
                dc_name = value.name;
            }
            else {
                distributionChannelDropDown = "blank";
                setState({ ...state, defaultstatus: "Select Supplier and Distribution Channel to proceed further.", selectFileBtn: true, uploadFileBtn: true });
                dc_id = 'na';
                dc_name = 'na';
            }
        }
        else {
            distributionChannelDropDown = "blank";
            setState({ ...state, defaultstatus: "Select Supplier and Distribution Channel to proceed further.", selectFileBtn: true, uploadFileBtn: true });
            dc_id = 'na';
            dc_name = 'na';
        }
        combineDropdowncheck(supplierDropDown, distributionChannelDropDown);
    }

    const combineDropdowncheck = (supplier, distributionchannel) => {
        // console.log(supplier, distributionchannel);
        if (supplier === "blank" || distributionchannel === 'blank') {
            setState({ ...state, defaultstatus: "Select Supplier and Distribution Channel to proceed further.", selectFileBtn: true, uploadFileBtn: true });
        }
        if (supplier === "match" && distributionchannel === 'match') {
            setState({ ...state, defaultstatus: "The combination already exists, please abandon previous entries and try again.", selectFileBtn: true, uploadFileBtn: true });
        }
        if ((supplier === "match" && distributionchannel === 'notmatch') || (supplier === "notmatch" && distributionchannel === 'match') || (supplier === "notmatch" && distributionchannel === 'notmatch')) {
            setState({ ...state, defaultstatus: "Please Select File to proceed further.", selectFileBtn: false, uploadFileBtn: true });
        }
    }

    const selectedAutoComplete = (value, id) => {
        // dispatch(setFilter({
        //     key: id,
        //     value: value ? value : ''
        // }))
    }



    const handleCheckboxChange = (event) => {
        const { checked, name } = event.target;
        setState({ ...state, [name]: checked })
    };

    const handlEupload = (file) => {
        var input = file.target;
        setState({ ...state, defaultstatus: input.files[0].name + " File is Selected.", uploadFileBtn: false, rawfile: file, supplier_name: sup_name, supplier_id: sup_id, distchan_id: dc_id, distchan_name: dc_name });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            supplierDropDown = "blank";
            distributionChannelDropDown = "blank";
            handleFileClose()

        } catch (error) {
            const { response: { data, status } } = error;
            setState({ ...state, pageLoader: false })
            // 
            //     if(!data) {
            //         if(onUpdate){
            //             onUpdate({ text: error.message, type: "error" })
            //             handleClose()
            //         }
            //     } else {
            //         if(onUpdate){
            //             onUpdate({ text: data.message, type: "error" })
            //             handleClose()
            //         }
            //     }
            // 
        }
    }

    const CommonPopUpModal = (params, event) => {
        if (event === 'supplier') {
            setState({ ...state, handleSupplierMemberModal: event });
            dispatch(setNewPriceModal(true));
        }
    }

    useEffect(() => {
        // This Use Effect is used to clear the Lookups when the page loads.
        dispatch(setMemberCode({
            key: 'smaSupplierNameOnModal',
            value: ""
        }));
        dispatch(smaInput({
            key: 'sma_supplier_name',
            value: ""
        }));
        // dispatch(setMemberCode({
        //     key: 'smaMemberCodeOnModal',
        //     value: ""
        // }));
        // dispatch(smaInput({
        //     key: 'sma_member_name',
        //     value: ""
        // }));

    }, [])

    const hidePriceModal = (event, reason, type) => {
         if (type === 'supplier') {
            if (reason) {
                // console.log(reason);
                handleSDropDownSelect(reason, 'pdc_supplier')
                // dispatch(setFilter({
                //     key: 'invrpc_supplier_name',
                //     value: reason ? reason : null
                // }))
            }
            dispatch(setNewPriceModal(event));
        }
    }

    const resetLookups = (params, event) => {
        if (event === 'supplier') {
            dispatch(setMemberCode({
                key: 'smaSupplierNameOnModal',
                value: ""
            }))
            dispatch(smaInput({
                key: 'sma_supplier_name',
                value: ""
            }))
            supplierDropDown = "blank";
            setState({ ...state, defaultstatus: "Select Supplier and Distribution Channel to proceed further.", selectFileBtn: true, uploadFileBtn: true }); // , supplier_id: 'na', supplier_name: 'na' 
            sup_id = 'na';
            sup_name = 'na';
            // dispatch(setFilter({
            //     key: 'invrpc_supplier_name',
            //     value: null
            // }))
        }

    }

    return (
        <>
            <Modal
                open={onShow || false}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <Box component="div" className="overlay" id="addEditModal">
                    <div className="overlay-box">
                        <div className="content">
                            <div className="overlayBody">
                                <div className="overlay-header text-center">PDC File Upload
                                    <CancelIcon className="closeBtn" onClick={handleClose} />
                                </div>
                                <div className="overlay-content">
                                    {
                                        showNewPriceoneModal && (
                                            <CommonPopUp
                                                onShow={showNewPriceoneModal} size="medium_overlay"
                                                supplierMemberCode={{
                                                    title: (state.handleSupplierMemberModal && state.handleSupplierMemberModal === 'members') ? 'Member Details' : 'Supplier Details',
                                                    type: state.handleSupplierMemberModal
                                                }}
                                                // memberaccount={loadMemberAccountWithMemberId}
                                                onHide={(e, reason) => hidePriceModal(e, reason, state.handleSupplierMemberModal)}
                                            />
                                        )
                                    }
                                    <Box sx={{ flexGrow: 1, paddingX: 2 }} >
                                        <Grid container columnSpacing={2}>
                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={2} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="general_product_name" className="fontSemiBold">Supplier</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={10}>
                                                            <Item>
                                                            <AutoCompleteStatic endPoint="/supplier/look-up" onChange={handleSDropDownSelect} onSelectedAutoComplete={selectedAutoComplete} id="pdc_supplier" name="pdc_supplier" />
                                                                {/* <Grid container alignItems="center">

                                                                    <Grid item xs={12} md={11}>
                                                                        <Item>
                                                                            <Box component="div" sx={{ paddingTop: '6px' }}>
                                                                                <FormControl size={'small'} fullWidth>
                                                                                    <TextField
                                                                                        inputProps={{ maxLength: 100 }}
                                                                                        size="small"
                                                                                        id="pdc_supplier"
                                                                                        value={(sma_container.smaSupplierNameOnModal ? sma_container.smaSupplierNameOnModal.name : '')}
                                                                                        aria-describedby="pdc_supplier_error"

                                                                                        name="pdc_supplier"
                                                                                        disabled={true}
                                                                                    />

                                                                                </FormControl>
                                                                            </Box>
                                                                        </Item>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={1}>
                                                                        <Item>
                                                                            <Button variant="text" size='small' sx={{ minWidth: 0, padding: 0, marginRight: '5px' }} onClick={(e) => resetLookups(e, 'supplier')} startIcon={<ClearIcon />}></Button>
                                                                            <Button variant="text" size='small' sx={{ minWidth: 0, padding: 0 }} onClick={(e) => CommonPopUpModal(e, 'supplier')} startIcon={<EditIcon />}></Button>
                                                                        </Item>
                                                                    </Grid>

                                                                </Grid> */}
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={3} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="product_case_code" className="fontSemiBold">Distribution Channel</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Item>
                                                                <div className="input-group">
                                                                    <AutoCompleteStatic endPoint="distributionChannels/drop-down" onChange={handleDcDropDownSelect} onSelectedAutoComplete={selectedAutoComplete} id="pdc_distribution_channel" name="pdc_distribution_channel" />
                                                                </div>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        <Grid container columnSpacing={2}>

                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5, alignSelf: 'center' }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={2} className="formLabelCntr">
                                                            <Item>

                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={10}>
                                                            <Item>
                                                                <Grid container direction="row" alignItems="center" sx={{ paddingLeft: '5px' }}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox size="medium"
                                                                                id="promoflag"
                                                                                checked={state.promoflag}
                                                                                onChange={(e) => handleCheckboxChange(e)}
                                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                                name="promoflag"
                                                                            />}
                                                                        label="Promo Price" />

                                                                </Grid>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        <Grid container columnSpacing={2}>
                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                                                <Item>
                                                    <Grid container alignItems="top">
                                                        <Grid item xs={12} md={2} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="general_ean_code" className="fontSemiBold">Select File</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={10}>
                                                            <Item>

                                                                <Button variant="contained" component="label" size='small' disabled={state.selectFileBtn} >
                                                                    Choose File for Upload
                                                                    <input hidden accept=".xls,.xlsx" multiple type="file" onChange={handlEupload} />
                                                                </Button>

                                                                <Typography variant="subtitle2" sx={{ paddingTop: '10px' }}>
                                                                    <p>{state.defaultstatus}</p>
                                                                </Typography>

                                                            </Item>

                                                        </Grid>

                                                    </Grid>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                                                <Item>

                                                </Item>
                                            </Grid>
                                        </Grid>

                                        <Grid container sx={{ marginTop: '20px' }}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Item>
                                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                                        <LoadingButton
                                                            onClick={(e) => { handleSubmit(e) }}
                                                            loading={state.loader}
                                                            loadingPosition="center"
                                                            endIcon=""
                                                            variant="contained"
                                                            type="submit" sx={{ marginRight: '15px' }}
                                                            disabled={state.uploadFileBtn}
                                                        //className={`${state.formValid ? 'Mui-disabled' : ''}`}
                                                        >
                                                            Upload File
                                                        </LoadingButton>
                                                        <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}
