import { Checkbox, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl, Alert, Select, MenuItem } from '@mui/material'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import { handleModalState } from '../../store/slices/modals';
import { uploadFile } from 'react-s3';
import { checkSpacer } from '../../services/utility/common-utils';
import SnackBarDialog from '../../components/shared/SnackBarDialog';
import { Link } from 'react-router-dom';
window.Buffer = window.Buffer || require("buffer").Buffer;

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

const MAX_COUNT = 100;
let loopStr = [];

const config = {
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    dirName: 'unprocessed',
}

function UploadInvoiceModal({ onShow, size = '', }) {
    const dispatch = useDispatch();
    const [state, setState] = useState({
        msType: '',
        btnDisabled: true
    })
    const msTypes = [{ text: 'Neptune', value: 'neptune' }, { text: 'Coop', value: 'coop' }, { text: 'CXML', value: 'cxml' }];
    const [fileNameList, setFileNameList] = useState([])
    const [fileSuccessNameList, setFileSuccessNameList] = useState([])
    const [open, setOpen] = useState(onShow);

    const [selectedFile, setSelectedFile] = useState([]);
    const [fileLimit, setFileLimit] = useState(false);
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    // const handleUploadFiles = files => {
    //     const uploaded = [...selectedFile];
    //     let limitExceeded = false;
    //     files.some((file) => {
    //         if (uploaded.findIndex((f) => f.name === file.name) === -1) {
    //             console.log(uploaded, 'up')
    //             uploaded.push(file);
    //             if (uploaded.length === MAX_COUNT) setFileLimit(true);
    //             if (uploaded.length > MAX_COUNT) {
    //                 alert(`You can only add a maximum of ${MAX_COUNT} files`);
    //                 setFileLimit(false);
    //                 limitExceeded = true;
    //                 setSelectedFile([])
    //                 setFileNameList([])
    //                 return true;
    //             }
    //         }
    //     })
    //     if (!limitExceeded) {
    //         setSelectedFile(uploaded)

    //     }

    // }

    const handleFileInput = (e) => {
        setFileLimit(false)
        setSelectedFile([])
        setFileNameList([])
        setFileSuccessNameList([])
        loopStr = []
        setState({ ...state, btnDisabled: false })
        if (Array.from(e.target.files).length > MAX_COUNT) {
            e.preventDefault();
            alert(`Cannot upload files more than ${MAX_COUNT}`);
            return;
        } else {
            const chosenFiles = Array.prototype.slice.call(e.target.files)
            
            if (chosenFiles.length > 0) {
                const newList = chosenFiles.map(x => {
                    if (x.name) {
                        x.name.substring(x.name.lastIndexOf('.')); 
                        return new File([x], checkSpacer(x.name), { type: x.type });
                    }
                });
                
                setSelectedFile(newList)
                setState({ ...state, btnDisabled: false })
            } else {
                setState({ ...state, btnDisabled: true })
            }

        }
    }

    const handleUpload = (file) => {

        const listName = [];
        const listSuccessName = []
        setState({ ...state, btnDisabled: false });
        if (file.length === 0) {
            setSnackBar({ ...snackBar, show: true, text: 'Please select invoice', type: "error" })
        } else {
            for (let i = 0; i < file.length; i++) {

                //listSuccessName.push(file[i].name);
                //sendFile(file[i]);

                //const hasSpace = checkSpacer(file[i].name)
                if (file[i].size > (5 * 1024 * 1024)) {
                    listName.push(file[i].name);
                    //setState({ ...state, btnDisabled: true });
                } else {
                    listSuccessName.push(file[i].name)
                    sendFile(file[i]);
                }
            }
            setFileNameList(listName)
            setFileSuccessNameList(listSuccessName)
        }



        // uploadFile(file, config)
        //     .then(data => console.log(data))
        //     .catch(err => console.error(err))
    }

    const sendFile = async (file) => {
        const config_S3 = { ...config, bucketName: state.msType === 'neptune' ? process.env.REACT_APP_NEPTUNE_S3_BUCKET : state.msType === 'coop' ? process.env.REACT_APP_COOP_S3_BUCKET : process.env.REACT_APP_CXML_S3_BUCKET }
        // uploadFile(file, config_S3).then((data) => {
        //     console.log(data, ' dd')
        //     if (data.result.status === 204) {
        //         loopStr.push(file.name)
        //         setFileSuccessNameList([...fileSuccessNameList, ...loopStr])
        //     } else if (data.result.status === 400) {
        //         console.log(400)
        //     } else {
        //         console.log("fail");
        //     }
        // });

        uploadFile(file, config_S3).then(data => {
            if (data.result.status === 204) {
                loopStr.push(file.name)
                setFileSuccessNameList([...fileSuccessNameList, ...loopStr])
                setState({ ...state, btnDisabled: true })
            }
        })
            .catch(error => {
                const { status, statusText } = error;
                setSnackBar({ ...snackBar, show: true, text: statusText, type: "error" })
                setFileLimit(false)
                setSelectedFile([])
                setFileNameList([])
                setFileSuccessNameList([])
            })
    };

    const handleClose = () => {
        dispatch(handleModalState({
            key: 'handleUploadInvoice',
            value: false
        }))
    };

    const handleChange = (event) => {
        const { value, name } = event.target;
        event.preventDefault()
        setState({ ...state, [name]: value, btnDisabled: true })
        setFileLimit(false)
        setSelectedFile([])
        setFileNameList([])
        setFileSuccessNameList([])
    };
    return (
        <>
            <Modal
                open={open}
                aria-labelledby="add-edit-price-title"
                aria-describedby="add-edit-price-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <Box component="div" className="overlay" id="addEditModal">
                    <div className={`overlay-box medium_overlay`}>
                        <div className="content">
                            <div className="overlayBody">
                                <div className="overlay-header text-center"> Upload Invoice
                                    <CancelIcon className="closeBtn" onClick={handleClose} />
                                </div>
                                <div className="overlay-content">
                                    <Grid item xs={12} md={12}>
                                        <Item sx={{ marginBottom: 2.5, padding: '0 0' }}>
                                            <FormControl fullWidth>
                                                <FormLabel htmlFor="msType">Select Reader</FormLabel>
                                                <Select
                                                    size="small"
                                                    id="msType"
                                                    defaultValue=""
                                                    onChange={(e) => handleChange(e)}
                                                    value={state.msType}
                                                    aria-describedby="msTypeError"
                                                    name="msType"
                                                >
                                                    {msTypes.map((option) => (
                                                        <MenuItem value={option.value} key={option.value} sx={{ fontSize: '0.85rem' }}>{option.text}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Item>

                                        <Item sx={{ marginBottom: 2.5, padding: '0 0' }}>
                                            {
                                                (state.msType === 'neptune' || state.msType === 'coop') && (<Box sx={{ textAlign: 'center' }}>
                                                    <FormControl>
                                                        <Button variant="contained" component="label" sx={{ margin: '0 25px' }}>
                                                            Select Files
                                                            <input hidden accept=".edi, .cxml" multiple type="file" onChange={handleFileInput} />
                                                        </Button>
                                                        <span style={{ fontSize: '12px', fontWeight: '400', color: 'red' }}>File name should not contain spaces</span>
                                                    </FormControl>
                                                </Box>
                                                )
                                            }
                                            {
                                                (state.msType === 'cxml') && (<Box sx={{ textAlign: 'center' }}>
                                                    <FormControl>
                                                        <Button variant="contained" component="label" sx={{ margin: '0 25px' }}>
                                                            Select Files
                                                            <input hidden accept=".xml, .cxml" multiple type="file" onChange={handleFileInput} />
                                                        </Button>
                                                        <span style={{ fontSize: '12px', fontWeight: '400', color: 'red' }}>File name should not contain spaces</span>
                                                    </FormControl>
                                                </Box>
                                                )
                                            }
                                            {
                                                fileSuccessNameList.length > 0 && (<div className={`uploaded-files-list`}>
                                                    <Alert severity="success" className='invoiceListAlert'> File uploaded successfully. Files will be available once system finish processing them <Link to="/logs/server-process-history" target='_blank' style={{color: 'rgb(44, 76, 46)', fontStyle: 'italic', textDecoration: 'underline'}}>View file upload progress</Link>
                                                        <ol className={`${fileSuccessNameList.length > 6 ? 'invoiceScroll' : ''}`}>
                                                            {fileSuccessNameList.map(name => (
                                                                <li key={name}>
                                                                    {name}
                                                                </li>
                                                            ))}
                                                        </ol>
                                                    </Alert>
                                                </div>)
                                            }
                                            {
                                                fileNameList.length > 0 && (<div className={`uploaded-files-list`}>
                                                    <Alert severity="error" className='invoiceListAlert'> Following files are greater than 5 MB and cannot be uploaded.
                                                        <ol className={`${fileNameList.length > 6 ? 'invoiceScroll' : ''}`}>
                                                            {fileNameList.map(name => (
                                                                <li key={name}>
                                                                    {name}
                                                                </li>
                                                            ))}
                                                        </ol>
                                                    </Alert>
                                                </div>)
                                            }
                                        </Item>
                                    </Grid>
                                    {
                                        state.msType !== '' && (
                                            <Grid container sx={{ marginTop: '20px' }}>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <Item>
                                                        <Grid container direction="row" alignItems="center" justifyContent="center">
                                                            <Button variant="contained" onClick={() => handleUpload(selectedFile)} type="submit" sx={{ marginRight: '15px' }} disabled={state.btnDisabled}>Upload</Button>
                                                            <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                                                        </Grid>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                    {snackBar.show && <SnackBarDialog
                        onShow={snackBar.show}
                        text={snackBar.text}
                        type={snackBar.type}
                        onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                    />}
                </Box>

            </Modal>
        </>
    );
}

export default UploadInvoiceModal;