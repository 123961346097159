import { Container, Box, Modal, Button, Grid, Checkbox, Popper, Grow, Paper, ClickAwayListener, MenuList, ButtonGroup } from '@mui/material'
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TableFilters } from "../../components/shared/data-table/TableFilters";
import { useEffect, useState } from "react";
import { InputText } from "../../components/shared/form/InputText";
import { } from '@mui/material';
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange, filterResetFlagChange } from "../../store/slices/externalFilter";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import AddIcon from '@mui/icons-material/Add';
import { warningModal, handleAddEditModal } from "../../store/slices/modals";
import WarningModal from "../../components/shared/modals/WarningModal";
import { setRowPage, resetDataGrid, setPageNo } from '../../store/slices/dataGrid'
import { useLayoutEffect } from "react";
import { hasTabEdit, setHasEditData, generalProductInput, brandSearch, setSpecification, setEanCodeList } from "../../store/slices/generalProduct";
import PageLoader from "../../components/shared/PageLoader";
import SnackBarDialog from "../../components/shared/SnackBarDialog";
import { DataTableMember } from "../../components/shared/data-table/DataTableMember";
import { DataTableMemberTier } from '../../components/shared/data-table/DataTableMemberTier';
import AddEditMemberTier from './addEditMemberTier/addEditMemberTier';
import { resetMemberTier, memberTierInput, sethasEditMemberTierData, hasMemberTierEdit, setMemberTierDate } from '../../store/slices/memberTierData';
import moment from 'moment';

const IconMenuLink = ({ params }) => {
    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { showAddEditModal, general_product_fields, membertier_fields } = useSelector((state) => {
        return {
            showAddEditModal: state.modalActions.hideAddEditModal,
            editTabData: state.generalProduct.editTabData,
            general_product_fields: state.generalProduct,
            membertier_fields: state.memberTierData
        }
    });
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'edit_membertier') {
            // dispatch(handleAddEditModal({
            //     showAddEdit: !showAddEditModal
            // }))
            // dispatch(hasTabEdit({ editTab: false }))
            
            setEditMemberTierData(row);
        } else if (action === 'delete_membertier') {
            // handle delete
        }
        setAnchorEl(null);
    };

    const setEditMemberTierData = async (row) => {

        // console.log(row, row.endDate);

        dispatch(resetMemberTier());
        dispatch(sethasEditMemberTierData(null));
        dispatch(sethasEditMemberTierData(row));
        dispatch(hasMemberTierEdit({ editMemberTier: true, tier_id: row.id }));

        dispatch(memberTierInput({
            key: 'tier_name',
            value: row.name
        }));

        dispatch(memberTierInput({
            key: 'tier_description',
            value: row.description
        }));

        dispatch(setMemberTierDate({
            key: 'tier_startDate',
            value: moment(row.startDate, 'DD/MM/YYYY')
        }));

        if(row.endDate && row.endDate !== "" && row.endDate !== null)
        {            
            dispatch(setMemberTierDate({
                key: 'tier_endDate',
                value:  moment(row.endDate,'DD/MM/YYYY')
            }));
        }

        dispatch(handleAddEditModal({
            showAddEdit: !showAddEditModal
        }));
        


    }

    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'edit_membertier')}>Edit Tier</MenuItem>
            {/* <MenuItem onClick={(e) => handleClose(e, 'delete_membertier')}>End Tier</MenuItem> */}
        </Menu>
    </>)
}
function MemberTiers() {

    const { memberTierName, addProductwarning, showAddEditModal } = useSelector((state) => {
        return {
            memberTierName: state.externalFilter.memberTier_name,
            resetTable: state.dataGridTable,
            filterFlag: state.externalFilter.filterApplied,
            addProductwarning: state.modalActions.closeWaringAddProduct,
            showAddEditModal: state.modalActions.hideAddEditModal,
        }
    });

    const [state, setState] = useState({
        active_member: false,
        openMemberTierModal: false,
        pageLoader: false,
        defaultGrid: {
            pagetitle: "Purchasing Tiers",
            productActionButton: true,
            hasExport: true,
            addEditButton: true,
            apiUrl: 'memberTier',
            searchUrl: 'memberTier'
        }
    });
    const columns = [
        {
            headerName: "Id",
            field: "id",
            width: 100,
        },
        {
            headerName: "Tier Name",
            field: "name",
            width: 350,
        },
        {
            headerName: "Description",
            field: "description",
            flex: 1.5,
            minWidth: 300,
        },
        {
            headerName: "Start Date",
            field: "startDate",
            width: 110
        },
        {
            headerName: "End Date",
            field: "endDate",
            width: 110
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} />;
            },
        }
    ];



    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })




    const dispatch = useDispatch();

    const handleChange = (checked, name) => {
        setState({ ...state, [name]: checked })
        dispatch(setFilter({
            key: name,
            value: checked
        }))
    };

    const handleFilterInput = (value, id) => {
        //setState({ ...state, [id]: value });
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    };

    const selectedAutoComplete = (value, id) => {
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    }

    const filterBtn = () => {
        dispatch(resetDataGrid());
        dispatch(applyFilter({
            key: 'filterList',
            value: {
                "name": memberTierName
            }
        }))
        dispatch(filterFlagChange(true));
        dispatch(filterResetFlagChange(false));
        dispatch(setRowPage({ pageData: 10 }));
        dispatch(setPageNo({ page: 1 }));
    }
    const resetFilter = () => {
        if (memberTierName !== '') {
            dispatch(resetExternalFilter())
            dispatch(resetDataGrid());
        }
        dispatch(filterResetFlagChange(true));
    }
    const addProductModal = () => {
        dispatch(handleAddEditModal({
            showAddEdit: !showAddEditModal
        }))
    }
    const hideNewProductModal = (event) => {
        setState({ ...state, openNewProductModal: false })
    }

    const addMemberTierModal = () => {
        dispatch(resetMemberTier());
        dispatch(handleAddEditModal({
            showAddEdit: !showAddEditModal
        }))
    }
    const hideMemberTierModal = (event, reason) => {
        setState({ ...state, openMemberTierModal: false })
    }

    useEffect(() => {
        /* load first first time */
        filterBtn();
    }, []);

    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Purchasing Tiers" />
                <Box sx={{ marginBottom: 2.5 }}>
                    <TableFilters
                        filterFields={<>
                            <Box component="div"><InputText onChange={handleFilterInput} maxWidth={100} label="Tier Name" id="memberTier_name" size="small" /></Box>

                        </>}
                        filterCheckboxes={<>

                        </>}
                        filterBtns={
                            <>
                                <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                <Button variant="contained" onClick={filterBtn}>Apply</Button>
                            </>}>
                    </TableFilters>
                </Box>
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -3 } }}>

                    <div className='table-actionable'>
                        <Button variant="contained" size='small' onClick={(e) => addMemberTierModal(e)} startIcon={<AddIcon />}>
                            New Purchasing Tier
                        </Button>
                    </div>
                </Box>

                <DataTableMemberTier defaultGrid={state.defaultGrid} columns={columns}></DataTableMemberTier>
                {
                    showAddEditModal && (
                        <AddEditMemberTier
                            onShow={showAddEditModal}
                            onHide={(e, reason) => hideMemberTierModal(e, reason)}

                        />
                    )
                }
                {
                    addProductwarning && (
                        <WarningModal size="small_overlay" onShow={addProductwarning} />
                    )
                }
                {snackBar.show && <SnackBarDialog
                    onShow={snackBar.show}
                    text={snackBar.text}
                    type={snackBar.type}
                    onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                />}
            </Container>

        </>
    );
}

export default MemberTiers;
