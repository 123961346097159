import { Box, Modal, Paper, FormControl, Grid, TextField, FormHelperText, FormLabel, Button, Autocomplete } from '@mui/material';
import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageLoader from '../../components/shared/PageLoader';
import moment from 'moment';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import axiosProductDistribution from '../../services/utility/axios-product-distribution';
import { LoadingButton } from '@mui/lab';
import SnackBarDialog from '../../components/shared/SnackBarDialog';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

export default function StartStatementCycleModal({ onShow, onHide, size = '', startDiscountBilling, hasBillDiscount }) {
    const [state, setState] = useState({
        loader: false,
        pageLoader: false,
        rowData: null,
        defaultGrid: {
            paginationMode: 'client',
        },
        cutOffDate: hasBillDiscount ? moment(hasBillDiscount.cutOffDate, 'DD/MM/YYYY') : null,
        additionalTerms: hasBillDiscount ? hasBillDiscount.additionalTerms : '',
        formValid: true,
        defaultmode: false,
        hasBillDiscount
    });
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })
    const [isError, setIsError] = useState({
        distribution_channel_selected: false,
        cutOffDate: false
    })
    const [distribution_channel, setDistribution_channel] = useState({
        distribution_channel_selected: null,
        distribution_channel_options: [],
    })
    useEffect(() => {
        const fetchMyAPI = async () => {
            if (distribution_channel.distribution_channel_options.length === 0) {
                axiosProductDistribution.get('distributionChannels/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            setDistribution_channel({ ...distribution_channel, distribution_channel_options: unique })
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
        }
        fetchMyAPI();
    }, [])

    useEffect(() => {
        if(hasBillDiscount) {
            if ((state.cutOffDate === null || state.cutOffDate === '')) {
                setState({ ...state, formValid: true, defaultmode: false })
            } else {
                setState({ ...state, formValid: false, defaultmode: true })
            }
        } else {
            if ((distribution_channel.distribution_channel_selected === null || distribution_channel.distribution_channel_selected === '') || (state.cutOffDate === null || state.cutOffDate === '')) {
                setState({ ...state, formValid: true, defaultmode: false })
            } else {
                setState({ ...state, formValid: false, defaultmode: true })
            }
        }
        
    }, [distribution_channel.distribution_channel_selected, state.cutOffDate])

    // useEffect(() => {
    //     const { distribution_channel_selected, cutOffDate
    //     } = isError
    //     if (!distribution_channel_selected && !cutOffDate) {
    //         console.log(3)
    //         setState({ ...state, formValid: false })
    //     } else {
    //         console.log(4)
    //         setState({ ...state, formValid: true })
    //     }
    // }, [isError])

    const handleClose = () => {
        if (onHide) {
            onHide(false)
        }
    };

    const handleInputChange = (event) => {
        event.preventDefault();
        const { value, name } = event.target;
        setState({
            ...state,
            [name]: value,
        });
    }

    const handleCutOffDate = (event) => {
        if (event === null) {
            setIsError({ ...isError, cutOffDate: true })
            setState({ ...state, cutOffDate: null })
        } else {
            setState({ ...state, cutOffDate: moment(event, 'DD/MM/YYYY') })
            setIsError({ ...isError, cutOffDate: false })
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if(hasBillDiscount){
           if (isError.cutOffDate === null || isError.cutOffDate === 'Invalid date' || isError.cutOffDate === "") {
                setIsError({ ...isError, cutOffDate: true })
            } else {
                if (startDiscountBilling && (state.cutOffDate !== 'Invalid date' && state.cutOffDate !== null)) {
                    if (moment(state.cutOffDate).format('DD/MM/YYYY') === 'Invalid date') {
                        setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
                    }
                    else {
                        setState({ ...state, loader: true })
                        startDiscountBilling({
                            cutOffDate: moment(state.cutOffDate).format('DD/MM/YYYY'),
                            additionalTerms: state.additionalTerms,
                            modificationStartDate: true
                        });
                    }
    
                }
            }
        } else {
            if (distribution_channel.distribution_channel_selected === null && isError.distribution_channel_selected === false) {
                setIsError({ ...isError, distribution_channel_selected: true })
            } else if (isError.cutOffDate === null || isError.cutOffDate === 'Invalid date' || isError.cutOffDate === "") {
                setIsError({ ...isError, cutOffDate: true })
            } else {
                if (startDiscountBilling && distribution_channel.distribution_channel_selected && (state.cutOffDate !== 'Invalid date' && state.cutOffDate !== null)) {
                    if (moment(state.cutOffDate).format('DD/MM/YYYY') === 'Invalid date') {
                        setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
                    }
                    else {
                        setState({ ...state, loader: true })
                        startDiscountBilling({
                            distribution_channel: distribution_channel.distribution_channel_selected,
                            cutOffDate: moment(state.cutOffDate).format('DD/MM/YYYY'),
                            additionalTerms: state.additionalTerms
                        });
                    }
    
                }
            }
        }
        

    }

    return (
        <>
            <Modal
                open={onShow || false}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <Box component="div" className="overlay" id="addEditModal">
                    {state.pageLoader && <PageLoader />}
                    {snackBar.show && <SnackBarDialog
                        onShow={snackBar.show}
                        text={snackBar.text}
                        type={snackBar.type}
                        onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                    />}
                    <div className={`overlay-box ${size ? size : ''}`}>
                        <div className="content">
                            <div className="overlayBody">
                                <div className="overlay-header text-center"> Start Statement Cycle
                                    <CancelIcon className="closeBtn" onClick={handleClose} />
                                </div>
                                <div className="overlay-content">
                                    <Box sx={{ flexGrow: 1, paddingX: 2 }} >
                                        {
                                            !state.hasBillDiscount && (<Grid container columnSpacing={2}>
                                                <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: 2.5 }}>
                                                    <Item>
                                                        <Grid container alignItems="center">
                                                            <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                                <Item>
                                                                    <FormLabel htmlFor="general_product_name" className="fontSemiBold mandatory">Distribution Channel</FormLabel>
                                                                </Item>
                                                            </Grid>
                                                            <Grid item xs={12} md={7.5}>
                                                                <Item>
                                                                    <div className="input-group">
                                                                        <Autocomplete
                                                                            defaultValue={distribution_channel.distribution_channel_selected ? distribution_channel.distribution_channel_selected : null}
                                                                            id="distribution_channel_search"
                                                                            options={distribution_channel.distribution_channel_options}
                                                                            getOptionLabel={(option) => option.name || ""}
                                                                            componentsProps={{
                                                                                paper: {
                                                                                    sx: {
                                                                                        fontSize: '0.85rem'
                                                                                    }
                                                                                }
                                                                            }}
                                                                            onChange={(event, newValue) => {
                                                                                (distribution_channel.distribution_channel_selected === null || distribution_channel.distribution_channel_selected === "") ? setIsError({ ...isError, distribution_channel_selected: false }) : setIsError({ ...isError, distribution_channel_selected: true })
                                                                                if (event.target.value !== "" || event.target.value !== null) {
                                                                                    setDistribution_channel({ ...distribution_channel, distribution_channel_selected: newValue })
                                                                                }
                                                                            }}
                                                                            renderInput={(params) =>
                                                                                <FormControl size={'small'} fullWidth>
                                                                                    <Box component="div" >
                                                                                        <TextField {...params}
                                                                                            size="small"
                                                                                            id="mie_distribution_channel"
                                                                                            aria-describedby="mie_distribution_channel-error"
                                                                                            error={isError.distribution_channel_selected}
                                                                                        />
                                                                                        {isError.distribution_channel_selected &&
                                                                                            (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)}
                                                                                    </Box>
                                                                                </FormControl>
                                                                            }

                                                                        />
                                                                    </div>
                                                                </Item>
                                                            </Grid>
                                                        </Grid>
                                                    </Item>
                                                </Grid>
                                            </Grid>)
                                        }
                                        <Grid container columnSpacing={2}>
                                            <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: 2.5 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="brandOwnerProductId" className="fontSemiBold mandatory">Cut off Date</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={7.5}>
                                                            <Item>
                                                                <div className="input-group">
                                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                        <DesktopDatePicker
                                                                            value={state.cutOffDate}
                                                                            onChange={(e) => { handleCutOffDate(e) }}
                                                                            name="cutOffDate"
                                                                            inputFormat="DD/MM/YYYY"
                                                                            // eslint-disable-next-line max-len
                                                                            renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                                <Box component="div" className='date-picker-input'>
                                                                                    <FormControl size={'small'} fullWidth>
                                                                                        <TextField size="small" id="cutOffDate"
                                                                                            ref={inputRef} {...inputProps}
                                                                                            placeholder=""
                                                                                            aria-describedby="cutOffDate_error"
                                                                                            error={isError.cutOffDate}
                                                                                            autoComplete="off"
                                                                                        />
                                                                                        {isError.cutOffDate && (<FormHelperText className='errorHelperTxt' id="cutOffDate_error">Cut off Date is missing</FormHelperText>)}
                                                                                        <Box className='date-picker-icon'>
                                                                                            {InputProps?.endAdornment}
                                                                                        </Box>
                                                                                    </FormControl>
                                                                                </Box>
                                                                            )}
                                                                        />
                                                                    </LocalizationProvider>
                                                                </div>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        <Grid container columnSpacing={2}>
                                            <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: 2.5 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="general_outer_case_barcode" className="fontSemiBold">Additional terms conditions</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={7.5}>
                                                            <Item>
                                                                <div className="input-group">
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            multiline
                                                                            rows={5}
                                                                            size="small"
                                                                            id="additionalTerms"
                                                                            aria-describedby="additionalTerms"
                                                                            onChange={(e) => handleInputChange(e)}
                                                                            value={state.additionalTerms}
                                                                            name="additionalTerms"
                                                                        />
                                                                    </FormControl>
                                                                </div>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        <Grid container sx={{ marginTop: '20px' }}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Item>
                                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                                        <LoadingButton
                                                            loading={state.loader}
                                                            loadingPosition="center"
                                                            endIcon=""
                                                            variant="contained"
                                                            type="submit" sx={{ marginRight: '15px' }}
                                                            onClick={(e) => { handleSubmit(e) }}
                                                            disabled={state.formValid || state.defaultmode === false}
                                                        >Save</LoadingButton>
                                                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}
