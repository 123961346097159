import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hasEditData: null,
  processManager_cut_off_date: null,
  levyBilling_cut_off_date: null,
  retroBilling_cut_off_date: null,
  sma_supplier_name_error: '',
  levyBilling_terms: '',
  memberInvoiceConfirmProcess: false,
  memberInvoiceStartProcess: false,
  centralBillingScheme: {
    billingScheme_options: [],
    billingScheme_selected: null
  },
  isError: {
    processManager_cut_off_date: false,
    levyBilling_cut_off_date: false,
    billingScheme_selected: false,
    retroBilling_cut_off_date: false,
    supplier_selected: false
  }
}

const processManager = createSlice({
  name: 'processManager',
  initialState,
  reducers: {
    brandSearch: (state, action) => {
      const { parent, key, value } = action.payload;
      state[parent][key] = value;
      (state[parent][key] === null || state[parent][key] === "") ? state.isError[key] = true : state.isError[key] = false
    },
    setSelectedFilter: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value
    },
    setAutocomplete: (state, action) => {
      const { parent, key, value } = action.payload;
      state[parent][key] = value
    },
    processManagerInput: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
      state[key].length === 0 ? state.isError[key] = true : state.isError[key] = false
    },
    getIsError: (state, action) => {
      const { key, value } = action.payload;
      state.isError[key] = value
    },
    setprocessManagerEffectiveDate: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
      state.isError[key] ? (state[key] === null || state[key] === ""  || state[key] === 'Invalid date') ? state.isError[key] = true : state.isError[key] = false : '';
    },
    setHasEditData: (state, action) => {
      state.hasEditData = action.payload;
    },
    memberInvoiceProcessData: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    resetprocessManager: () => {
      return initialState
    },
  }
})

export const { setSelectedFilter, setAutocomplete, processManagerInput, getIsError, 
  setprocessManagerEffectiveDate, setHasEditData, resetprocessManager, brandSearch, memberInvoiceProcessData
 } = processManager.actions

export default processManager.reducer