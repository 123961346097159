import { Checkbox, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl } from '@mui/material'
import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { warningModal, handleAddEditDisputedInvoiceModal } from "../../../store/slices/modals";
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";
import LoadingButton from '@mui/lab/LoadingButton';
import axios_process_manager from '../../../services/utility/axios-process-manager';
import CommonPopUp from '../../products/addEditPdc/pdc-tabs/CommonPopUp';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import { getIsError, setprocessManagerEffectiveDate, resetprocessManager } from "../../../store/slices/processManager";
import SnackBarDialog from "../../../components/shared/SnackBarDialog";
import { setMemberCode } from '../../../store/slices/supplierMemberAccounts';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import axios_supplier from '../../../services/utility/axios-supplier';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

const defaultValues = {
    loader: false,
    formValid: true,
    defaultmode: false,
    processManagerSupplierModal: false,
    newData: [],
};

function DisputedInvoiceModal({ onShow, size = '', onHide }) {
    
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const [state, setState] = useState(defaultValues);

    const [supplier, setSupplier] = useState({
        supplier_options: [],
        supplier_selected: null
    })

    const [isError, setIsError] = useState({
        supplier_selected: false
    })
    
    const [open, setOpen] = useState(onShow);
    const { handleWarning, processManager_container, sma_container, showAddEditDisputedInvoiceModal } = useSelector((state) => {
        return {
            handleWarning: state.modalActions.closeWaringAddProduct,
            processManager_container: state.processManagers,
            sma_container: state.smas,
            showAddEditDisputedInvoiceModal: state.modalActions.showAddEditDisputedInvoiceModal
        }
    });

    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const uname = accounts[0] && accounts[0].name;
    
    const dispatch = useDispatch();

    const handleStartDate = (event) => {
        if (event === null) {
            dispatch(setprocessManagerEffectiveDate({
                key: 'processManager_cut_off_date',
                value: null
            }))
            dispatch(getIsError({ key: 'processManager_cut_off_date', value: true }))
        } else {
            dispatch(setprocessManagerEffectiveDate({
                key: 'processManager_cut_off_date',
                value: moment(event, 'DD/MM/YYYY')
            }))
        }
    }

    const handleClose = () => {
        dispatch(handleAddEditDisputedInvoiceModal({
            showAddEditDisputedInvoiceModal: false
        }))
    };

    const handleCancel = () => {
        dispatch(resetprocessManager())
        dispatch(warningModal({
            yesHide: !handleWarning
        }))
    };
    useEffect(() => {
        const fetchMyAPI = async () => {
            if (supplier.supplier_options.length === 0) {
                axios_supplier.get('/supplier/look-up')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data.filter((value, index, self) => self.findIndex((m) => m.name === value.name) === index);
                            setSupplier({ ...supplier, supplier_options: unique })
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
        }
        fetchMyAPI();
    }, [])
    
    // const resetFilter = () => {
    //     dispatch(setMemberCode({
    //         key: 'smaSupplierNameOnModal',
    //         value: ""
    //     }))
    // }
    // const CommonPopUpModal = () => {
    //     setState({ ...state, processManagerSupplierModal: true })
    // }
    const hideprocessManagerSupplierMemberModal = (params) => {
        setState({ ...state, processManagerSupplierModal: params === false ? params : true })
    }
    const getprocessManagersupplierDetail = (supplier) => {
        if (supplier !== '') {
            setState({ ...state, processManagerSupplierDetail: supplier, processManagerSupplierModal: false })
        }
    }

    useEffect(() => {
        if ((processManager_container.processManager_cut_off_date === "" || processManager_container.processManager_cut_off_date === null)) {
            setState({ ...state, formValid: true })
        } else {
            setState({ ...state, formValid: false })
        }
    }, [processManager_container.processManager_cut_off_date])

    // useEffect(() => {
    //     if ((supplier.supplier_selected === null || supplier.supplier_selected === "")) {
    //         setIsError({ ...isError, supplier_selected: true })
    //     } else {
    //         setIsError({ ...isError, supplier_selected: false })
    //     }
    // }, [supplier.supplier_selected])

    useEffect(() => {
        const {
            processManager_cut_off_date
        } = processManager_container.isError
        if (!processManager_cut_off_date && !isError.supplier_selected) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true})
        }
    }, [processManager_container.isError, isError])


    const handleSubmit = async (event) => {
        event.preventDefault();
        let executableData = null;
        if (!state.formValid) {
            if (supplier.supplier_selected === null && isError.supplier_selected === false) {
                setIsError({ ...isError, supplier_selected: true })
            } else if (moment(processManager_container.processManager_cut_off_date).format('DD/MM/YYYY') === 'Invalid date') {
                setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
            } 
            else {
                executableData = {
                    "executable": "DisputedInvoiceExecutable",
                    "user": uname,
                    "processExecutionDTO": {
                        "cutOffDate": processManager_container.processManager_cut_off_date === null ? null : moment(processManager_container.processManager_cut_off_date).format('DD/MM/YYYY'),
                        "supplierId": supplier.supplier_selected ? supplier.supplier_selected.id : null
                    }
                }
                
                try {
                    setState({ ...state, loader: true })
                    const { data, status } =  await axios_process_manager.post(`sqs/processmanager`, { ...executableData });
                    if (data.isSuccess === true ) {
                        setState({ ...state, loader: false }) 
                        setSnackBar({...snackBar, show:true, text: data.message, type: "success", })
                        setTimeout(() => {
                            dispatch(handleAddEditDisputedInvoiceModal({
                                showAddEditDisputedInvoiceModal: false
                            }))
                        }, 1500);

                    }
                } catch (error) {
                    setState({ ...state, loader: false }) 
                    const { response: { data, status } } = error;
                    setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error", })
                }
            }

        }
    };


    return (
        <>
            {snackBar.show && <SnackBarDialog
                onShow={snackBar.show}
                text={snackBar.text}
                type={snackBar.type}
                onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
            />}

            <Modal
                open={open}
                aria-labelledby="add-edit-price-title"
                aria-describedby="add-edit-price-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Box component="div" className="overlay" id="addEditModal">
                        <div className={`overlay-box ${size ? size : ''}`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-header text-center">Disputed Invoice Details
                                        <CancelIcon className="closeBtn" onClick={handleClose} />
                                    </div>
                                    <div className="overlay-content">
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={3} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            <FormLabel htmlFor="type" className="fontSemiBold mandatory">Supplier</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7}>
                                                        <Item>
                                                            <Autocomplete
                                                                defaultValue={supplier.supplier_selected ? supplier.supplier_selected : null}
                                                                id="supplier_search"
                                                                options={supplier.supplier_options}
                                                                getOptionLabel={(option) => option.name || ""}
                                                                componentsProps={{
                                                                    paper: {
                                                                        sx: {
                                                                            fontSize: '0.85rem'
                                                                        }
                                                                    }
                                                                }}
                                                                onChange={(event, newValue) => {
                                                                    (isError['supplier_selected'] === null || isError['supplier_selected'] === "") ? setIsError({ ...isError, supplier_selected: true }) : setIsError({ ...isError, supplier_selected: false })
                                                                    if (event.target.value !== undefined) {
                                                                        setSupplier({ ...supplier, supplier_selected: newValue })
                                                                        setIsError({ ...isError, supplier_selected: false })
                                                                    } else {
                                                                        setIsError({ ...isError, supplier_selected: true })
                                                                        setState({ ...state, formValid: true })
                                                                    }
                                                                }}
                                                                renderInput={(params) =>
                                                                    <FormControl size={'small'} fullWidth>
                                                                        <div className="input-group">
                                                                            <TextField {...params}
                                                                                size="small"
                                                                                id="supplier"
                                                                                aria-describedby="supplier-error"
                                                                                error={isError.supplier_selected}
                                                                            />
                                                                            {isError.supplier_selected && (<FormHelperText className='errorHelperTxt' id="supplier-error">
                                                                                Please select supplier
                                                                            </FormHelperText>)}
                                                                        </div>
                                                                    </FormControl>
                                                                }
                                                            />
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={3} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="price_start_date" className="fontSemiBold mandatory">Cut-off Date</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7}>
                                                        <Item>
                                                            <Box component="div" className=''>
                                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                    <DesktopDatePicker
                                                                        value={processManager_container.processManager_cut_off_date}
                                                                        onChange={(e) => { handleStartDate(e) }}
                                                                        name="price_start_date"
                                                                        inputFormat="DD/MM/YYYY"
                                                                        // eslint-disable-next-line max-len
                                                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                            <Box component="div" className='date-picker-input'>
                                                                                <FormControl size={'small'} fullWidth>
                                                                                    <TextField size="small" id="price_start_date"
                                                                                        ref={inputRef} {...inputProps}
                                                                                        placeholder=""
                                                                                        aria-describedby="price_start_date_error"
                                                                                        error={processManager_container.isError.processManager_cut_off_date}
                                                                                        autoComplete="off"
                                                                                    />
                                                                                    {processManager_container.isError.processManager_cut_off_date && (<FormHelperText className='errorHelperTxt' id="price_start_date_error">Date is missing</FormHelperText>)}
                                                                                    <Box className='date-picker-icon'>
                                                                                        {InputProps?.endAdornment}
                                                                                    </Box>
                                                                                </FormControl>
                                                                            </Box>
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                            </Box>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                        <Grid container sx={{ marginTop: '30px' }}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Item>
                                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                                        <LoadingButton
                                                            loading={state.loader}
                                                            loadingPosition="center"
                                                            endIcon=""
                                                            variant="contained"
                                                            type="submit" sx={{ marginRight: '15px' }}
                                                            disabled={state.formValid}
                                                        >Start</LoadingButton>
                                                        <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            state.processManagerSupplierModal && <CommonPopUp
                                onShow={state.processManagerSupplierModal}
                                size="large_overlay"
                                supplierMemberCode={{
                                    title: 'Supplier Details',
                                    type: 'supplier'
                                }}
                                onHide={hideprocessManagerSupplierMemberModal}
                                memberaccount={getprocessManagersupplierDetail}
                            />
                        }
                    </Box>
                </form>
            </Modal>
        </>
    );
}

export default DisputedInvoiceModal;