import { Container, Box, Modal, Button, Grid, Checkbox, Popper, Grow, Paper, ClickAwayListener, MenuList, ButtonGroup, FormLabel, FormControl, TextField, FormControlLabel, Autocomplete, FormHelperText, Link, FormGroup, Switch } from '@mui/material';
import PageLoader from '../components/shared/PageLoader';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import moment from 'moment';
import axiosInvoiceSummaries from '../services/utility/axios-invoice-summaries';
import axiosNewInvoiceSummaries from '../services/utility/axios-new-invoice-summaries';
import axiosStatementCycle from '../services/utility/axios-statement-cycle';
import SnackBarDialog from '../components/shared/SnackBarDialog';
import axios from '../services/utility/axios';
import axiosMember from '../services/utility/axios-member';
import axiosSupplier from '../services/utility/axios-supplier';
import axiosProductDistribution from '../services/utility/axios-product-distribution';
import axiosBrand from '../services/utility/axios-brand';
import axiosEcsStatus from '../services/utility/axios-ecs-status';
import axiosEcsTrigger from '../services/utility/axios-ecs-trigger';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import refreshLambda from '../services/utility/refresh-lambda';
import PageLoaderStyle2 from '../components/shared/PageLoaderStyle2';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

function Home() {

    // const { rowPerPage, resetTable, applExternalFilter } = useSelector((state) => {
    //     return {
    //         rowPerPage: state.dataGridTable.pageData,
    //         resetTable: state.dataGridTable,
    //         applExternalFilter: state.externalFilter,
    //     }
    // });

    const [state, setState] = useState({
        pageLoader: false,
        showLoader: false,
        pageLoader2: false
    });

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
        vertical: '',
        horizontal: '',
        duration: 0,
    })

    //Getting User Name from MSAL

    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const name = accounts[0] && accounts[0].name;

    //Getting Current Date

    const themoment = new Date();

    // Getting all the Values
    const [processRunning, setProcessRunning] = useState(0);
    const [failedInvoiceProcessing, setFailedInvoiceProcessing] = useState(0);
    const [failedInvoiceImport, setFailedInvoiceImport] = useState(0);
    const [products, setProducts] = useState(0);
    const [brands, setBrands] = useState(0);
    const [pdcs, setPdcs] = useState(0);
    const [suppliers, setSuppliers] = useState(0);
    const [members, setMembers] = useState(0);

    //ECS 

    const triggerEcsOn = async () => {
        try {
            setState({ ...state, pageLoader: true });
            const payload = {
                "action": "Start",
            };
            const { data } = await axiosEcsTrigger.post('', payload);
            if (data) {
                // const parsedBody = JSON.parse(data.body);
                // setSnackBar({ ...snackBar, vertical: 'bottom', duration: 6000, horizontal: 'right', show: true, text: parsedBody.message ? parsedBody.message : 'All ECS services started successfully', type: "success" });
                setSnackBar({ ...snackBar, vertical: 'bottom', duration: 6000, horizontal: 'right', show: true, text: 'All ECS services started successfully, please check after 10 minutes.', type: "success" });
                localStorage.setItem('ecsStatus', "ACTIVE");
            }
            setState({ ...state, pageLoader: false });
        } catch (error) {
            const { response: { data, status } } = error;
            //setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" })
            setState({ ...state, pageLoader: false });
            localStorage.setItem('ecsStatus', "UNKOWN");
        }
    }

    const triggerEcsOff = async () => {
        try {
            setState({ ...state, pageLoader: true });
            const payload = {
                "action": "Stop",
            };
            const { data } = await axiosEcsTrigger.post('', payload);
            if (data) {
                // const parsedBody = JSON.parse(data.body);
                // setSnackBar({ ...snackBar, vertical: 'bottom', duration: 6000, horizontal: 'right', show: true, text: parsedBody.message ? parsedBody.message : 'All ECS services started successfully', type: "success" });
                setSnackBar({ ...snackBar, vertical: 'bottom', duration: 6000, horizontal: 'right', show: true, text: 'All ECS services stopped successfully!', type: "warning" });
                localStorage.setItem('ecsStatus', "INACTIVE");
            }
            setState({ ...state, pageLoader: false });
        } catch (error) {
            const { response: { data, status } } = error;
            //setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" })
            setState({ ...state, pageLoader: false });
            localStorage.setItem('ecsStatus', "UNKOWN");
        }
    }

    useEffect(() => {
        getEcsStatus();
        importedInvoiceFailed();
        invoiceProcessingFailed();
        checkProcessRunning();
        totalProducts();
        totalBrands();
        totalPdcs();
        totalSuppliers();
        totalMembers();
    }, [])



    const getEcsStatus = async () => {
        try {
            setState({ ...state, pageLoader: true });
            const payload = {
                "service": process.env.REACT_APP_BASE_URL_ECS_SERVICE,
                "cluster": process.env.REACT_APP_BASE_URL_ECS_CLUSTER
            };
            const { data } = await axiosEcsStatus.post('getEcsStatus', payload);
            if (data) {
                const parsedBody = JSON.parse(data.body);
                let currentStatus = parsedBody.service_status;
                localStorage.setItem('ecsStatus', currentStatus);
            }
            setState({ ...state, pageLoader: false });
        } catch (error) {
            const { response: { data, status } } = error;
            setSnackBar({ ...snackBar, vertical: 'bottom', horizontal: 'right', show: true, text: data ? data.message ? data.message : data.error ? data.error + ", ECS is Offline!" : "ECS Status unknown!" : error.message ? error.message + ", ECS is Offline!" : "ECS Status unknown!", type: "error" });
            // setSnackBar({ ...snackBar, vertical: 'bottom', horizontal: 'right', show: true, text: data ? data.message ? data.message : "Something went wrong!" : error.message ? error.message + " for ECS" : "Something went wrong!", type: "error" });
            //setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" })
            setState({ ...state, pageLoader: false });
            localStorage.setItem('ecsStatus', "Offline");
        }
    }

    const importedInvoiceFailed = async (url) => {
        try {
            setState({ ...state, pageLoader: true });
            const { data } = await axiosNewInvoiceSummaries.get('/deadImportedInvoices/countDeadImportedInvoice')
            if (data) {
                setFailedInvoiceImport(data.resultMap.countDeadImportedInvoice);

            }
            setState({ ...state, pageLoader: false });
        } catch (error) {
            const { response: { data, status } } = error;
            //setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" })
            setState({ ...state, pageLoader: false })
        }
    }
    const invoiceProcessingFailed = async (url) => {
        try {
            setState({ ...state, pageLoader: true });
            const { data } = await axiosNewInvoiceSummaries.get('/deadInvoiceData/countDeadInvoiceData')
            if (data) {
                setFailedInvoiceProcessing(data.resultMap.countDeadInvoiceData);
            }
            setState({ ...state, pageLoader: false });
        } catch (error) {
            const { response: { data, status } } = error;
            //setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" })
            setState({ ...state, pageLoader: false });
        }
    }
    const checkProcessRunning = async (url) => {
        try {
            setState({ ...state, pageLoader: true });
            const { data } = await axiosStatementCycle.get('/statementcycle/getAllRunningProcess')
            if (data) {
                setProcessRunning(data.resultMap.RunningProcessCount);
            }
            setState({ ...state, pageLoader: false });
        } catch (error) {
            const { response: { data, status } } = error;
            //setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" })
            setState({ ...state, pageLoader: false });
        }
    }
    const totalProducts = async (url) => {
        try {
            if (localStorage.getItem("totalProducts")) {
                setProducts(localStorage.getItem("totalProducts"));
            }
            setState({ ...state, pageLoader: true });
            const { data } = await axios.get('/products')
            if (data) {
                setProducts(data.totalCount);
                localStorage.setItem('totalProducts', data.totalCount);
            }
            setState({ ...state, pageLoader: false });
        } catch (error) {
            const { response: { data, status } } = error;
            //setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" })
            setState({ ...state, pageLoader: false });
        }
    }
    const totalBrands = async (url) => {
        try {
            if (localStorage.getItem("totalBrands")) {
                setBrands(localStorage.getItem("totalBrands"));
            }
            setState({ ...state, pageLoader: true });
            const { data } = await axiosBrand.post('/brands/filter', {})
            if (data) {
                setBrands(data.totalCount);
                localStorage.setItem('totalBrands', data.totalCount);
            }
            setState({ ...state, pageLoader: false });
        } catch (error) {
            const { response: { data, status } } = error;
            //setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" })
            setState({ ...state, pageLoader: false })
        }
    }
    const totalPdcs = async (url) => {
        try {
            if (localStorage.getItem("totalPdcs")) {
                setPdcs(localStorage.getItem("totalPdcs"));
            }
            setState({ ...state, pageLoader: true });
            const { data } = await axiosProductDistribution.post('/pdcs/filter?page=1&pageSize=10', {})
            if (data) {
                setPdcs(data.totalCount);
                localStorage.setItem('totalPdcs', data.totalCount);
            }
            setState({ ...state, pageLoader: false });
        } catch (error) {
            const { response: { data, status } } = error;
            //setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" })
            setState({ ...state, pageLoader: false });
        }
    }
    const totalSuppliers = async (url) => {
        try {
            if (localStorage.getItem("totalSuppliers")) {
                setSuppliers(localStorage.getItem("totalSuppliers"));
            }
            setState({ ...state, pageLoader: true });
            const { data } = await axiosSupplier.post('/supplier/filter?page=1&pageSize=10', { "name": "" })
            if (data) {
                setSuppliers(data.totalCount);
                localStorage.setItem('totalSuppliers', data.totalCount);
            }
            setState({ ...state, pageLoader: false });
        } catch (error) {
            const { response: { data, status } } = error;
            //setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" })
            setState({ ...state, pageLoader: false });
        }
    }
    const totalMembers = async (url) => {
        try {
            if (localStorage.getItem("totalMembers")) {
                setMembers(localStorage.getItem("totalMembers"));
            }
            setState({ ...state, pageLoader: true });
            const { data } = await axiosMember.post('/members/filter?page=1&pageSize=10', {})
            if (data) {
                setMembers(data.totalCount);
                localStorage.setItem('totalMembers', data.totalCount);
            }
            setState({ ...state, pageLoader: false });
        } catch (error) {
            const { response: { data, status } } = error;
            //setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" })
            setState({ ...state, pageLoader: false });
        }
    }
    const lambdaWarmer = () => {
        onReprocessReverse()
    }
    const onReprocessReverse = async () => {
        try {
            setState({ ...state, pageLoader2: true });
            const { data } = await refreshLambda.get('/warmup');

            if (data) {
                setSnackBar({ ...snackBar, vertical: 'top', duration: 6000, horizontal: 'center', show: true, text: "System is warming up, please check after 9 minutes", type: "info" })
            }
            setState({ ...state, pageLoader2: false });
        } catch (error) {
            const { response: { data, status } } = error;
            if (status === 500) {
                setSnackBar({ ...snackBar, vertical: 'bottom', horizontal: 'right', show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
            } else {
                // setSnackBar({ ...snackBar, vertical: 'bottom', horizontal: 'right', show: true, text: "System took long to respond, please retry!", type: "error", })
                setSnackBar({ ...snackBar, vertical: 'bottom', horizontal: 'right', show: true, text: "The ECS services are down at the moment.", type: "error", })
            }
            setState({ ...state, pageLoader2: false });
        }
    }
    return (
        <>
            {state.pageLoader && <PageLoader />}
            {state.pageLoader2 && <PageLoaderStyle2 />}
            <Box sx={{ flexGrow: 1, paddingX: 2, paddingY: 2, minHeight: '79vh' }} >
                <Grid container columnSpacing={2} sx={{ paddingLeft: '40px', paddingRight: '0px', marginBottom: '40px' }}>
                    <Grid item xs={12} sm={8} md={8} >
                        <Item>
                            <Typography sx={{ fontWeight: "600", fontSize: '20px', color: "#15191E" }}>Welcome, <span style={{ fontWeight: '800' }}>{name}</span></Typography>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'end' }}>
                            <Box className='lambdaRefreshBtn'>
                                <Box component={'a'} sx={{ display: 'flex', alignItems: 'center' }} onClick={lambdaWarmer}>
                                    <LocalFireDepartmentIcon color="primary" /> <span style={{ marginLeft: '5px', color: '#B253AE' }}>Warm System</span>
                                </Box>
                            </Box>
                            <Item  >
                                <Typography sx={{ fontWeight: "400", textAlign: 'right', fontSize: '14px', color: "#15191E" }}>{moment(themoment).format('dddd')}, <br></br> {moment(Date().toLocaleString()).format('MMMM Do YYYY')}</Typography>
                            </Item>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container columnSpacing={2} sx={{ paddingLeft: '30px', paddingRight: '20px', marginBottom: 1 }}>
                    <Grid item xs={12} sm={12} md={12} >
                        <Item>
                            {/* <Typography sx={{ fontWeight: "600", fontSize: '20px', color: "#15191E" }}>Welcome, <span style={{ fontWeight: '800' }}>{name}</span></Typography> */}
                        </Item>
                    </Grid>
                </Grid>
                <Grid container columnSpacing={2} sx={{ paddingLeft: '30px', paddingRight: '20px', marginBottom: '40px' }}>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2 }}>
                        <Item>
                            <Grid container alignItems="center">

                                <Grid item xs={12} md={4}>
                                    <Item>
                                        <Box component="div" sx={{ width: '100%', backgroundColor: '#ffefe7', borderRadius: '10px', minHeight: '150px' }}>
                                            <Grid container alignItems={'center'} sx={{ display: 'block', width: '100%' }}>
                                                <Grid sx={{ padding: '20px', textAlign: 'center' }}>
                                                    <Item sx={{ backgroundColor: 'transparent' }}>
                                                        <Typography sx={{ fontWeight: "800", fontSize: '34px', color: "#15191E", lineHeight: '60px' }}>{failedInvoiceProcessing ? failedInvoiceProcessing : 0}</Typography>
                                                        <Typography sx={{ fontWeight: "600", fontSize: '15px', color: "#15191E", lineHeight: '22px' }}>Invoice processing failed</Typography>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={4} className="formLabelCntr">
                                    <Item>
                                        <Box component="div" sx={{ width: '100%', backgroundColor: '#e8f0fb', borderRadius: '10px', minHeight: '150px' }}>
                                            <Grid container alignItems={'center'} sx={{ display: 'block', width: '100%' }}>
                                                <Grid sx={{ padding: '20px', textAlign: 'center' }}>
                                                    <Item sx={{ backgroundColor: 'transparent' }}>
                                                        <Typography sx={{ fontWeight: "800", fontSize: '34px', color: "#15191E", lineHeight: '60px' }}>{processRunning ? processRunning : 0}</Typography>
                                                        <Typography sx={{ fontWeight: "600", fontSize: '15px', color: "#15191E", lineHeight: '20px' }}>Process running</Typography>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={4} className="formLabelCntr">
                                    <Item>
                                        <Box component="div" sx={{ width: '100%', backgroundColor: '#ffefe7', borderRadius: '10px', minHeight: '150px' }}>
                                            <Grid container alignItems={'center'} sx={{ display: 'block', width: '100%' }}>
                                                <Grid sx={{ padding: '20px', textAlign: 'center' }}>
                                                    <Item sx={{ backgroundColor: 'transparent' }}>
                                                        <Typography sx={{ fontWeight: "800", fontSize: '34px', color: "#15191E", lineHeight: '60px' }}>{failedInvoiceImport ? failedInvoiceImport : 0}</Typography>
                                                        <Typography sx={{ fontWeight: "600", fontSize: '15px', color: "#15191E", lineHeight: '22px' }}>Invoice file import failed</Typography>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>

                </Grid>
                <Grid container columnSpacing={2} sx={{ paddingLeft: '40px', paddingRight: '20px', marginBottom: 2 }}>
                    <Grid item xs={12} sm={12} md={12} >
                        <Item>
                            <Typography sx={{ fontWeight: "600", fontSize: '26px', color: "#15191E" }}>System Overview</Typography>
                        </Item>
                    </Grid>
                </Grid>
                <Grid container columnSpacing={2} sx={{ paddingLeft: '30px', paddingRight: '20px' }}>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 4 }}>
                        <Item>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={4} >
                                    <Item>
                                        <Box component="div" sx={{ width: '100%', backgroundColor: '#e9effb', borderRadius: '10px' }}>
                                            <Grid container alignItems={'center'} sx={{ display: 'block', width: '100%' }}>
                                                <Grid sx={{ padding: '20px', textAlign: 'center' }}>
                                                    <Item sx={{ backgroundColor: 'transparent' }}>
                                                        <Typography sx={{ fontWeight: "800", fontSize: '34px', color: "#15191E", lineHeight: '50px' }}>{suppliers ? suppliers : <CircularProgress color="inherit" />}</Typography>
                                                        <Typography sx={{ fontWeight: "600", fontSize: '15px', color: "#15191E", lineHeight: '40px' }}>Total Suppliers</Typography>
                                                        <Typography sx={{ fontWeight: "600", fontSize: '12px', color: "#15191E", marginTop: '20px' }}><Link href="/suppliers-and-members/suppliers" sx={{ color: "#15191E" }}>view more</Link></Typography>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Item>
                                        <Box component="div" sx={{ width: '100%', backgroundColor: '#faf4be', borderRadius: '10px' }}>
                                            <Grid container alignItems={'center'} sx={{ display: 'block', width: '100%' }}>
                                                <Grid sx={{ padding: '20px', textAlign: 'center' }}>
                                                    <Item sx={{ backgroundColor: 'transparent' }}>
                                                        <Typography sx={{ fontWeight: "800", fontSize: '34px', color: "#15191E", lineHeight: '50px' }}>{members ? members : <CircularProgress color="inherit" />}</Typography>
                                                        <Typography sx={{ fontWeight: "600", fontSize: '15px', color: "#15191E", lineHeight: '40px' }}>Total Members</Typography>
                                                        <Typography sx={{ fontWeight: "600", fontSize: '12px', color: "#15191E", marginTop: '20px' }}><Link href="/suppliers-and-members/members" sx={{ color: "#15191E" }}>view more</Link></Typography>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Item>
                                        <Box component="div" sx={{ width: '100%', backgroundColor: '#d6ecef', borderRadius: '10px' }}>
                                            <Grid container alignItems={'center'} sx={{ display: 'block', width: '100%' }}>
                                                <Grid sx={{ padding: '20px', textAlign: 'center' }}>
                                                    <Item sx={{ backgroundColor: 'transparent' }}>
                                                        <Typography sx={{ fontWeight: "800", fontSize: '34px', color: "#15191E", lineHeight: '50px' }}>{brands ? brands : <CircularProgress color="inherit" />}</Typography>
                                                        <Typography sx={{ fontWeight: "600", fontSize: '15px', color: "#15191E", lineHeight: '40px' }}>Total Brands</Typography>
                                                        <Typography sx={{ fontWeight: "600", fontSize: '12px', color: "#15191E", marginTop: '20px' }}><Link href="/products/brands" sx={{ color: "#15191E" }}>view more</Link></Typography>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Item>
                                </Grid>
                            </Grid>

                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 4, paddingLeft: '0px !important', marginLeft: '-15px' }}>
                        <Item>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={6} className="formLabelCntr">
                                    <Item>
                                        <Box component="div" sx={{ width: '100%', backgroundColor: '#fff0fe', borderRadius: '10px' }}>
                                            <Grid container alignItems={'center'} sx={{ display: 'block', width: '100%' }}>
                                                <Grid sx={{ padding: '20px', textAlign: 'center' }}>
                                                    <Item sx={{ backgroundColor: 'transparent' }}>

                                                        <Typography sx={{ fontWeight: "800", fontSize: '34px', color: "#15191E", lineHeight: '50px' }}>{products ? products : <CircularProgress color="inherit" />}</Typography>
                                                        <Typography sx={{ fontWeight: "600", fontSize: '15px', color: "#15191E", lineHeight: '40px' }}>Total Products</Typography>
                                                        <Typography sx={{ fontWeight: "600", fontSize: '12px', color: "#15191E", marginTop: '20px' }}><Link href="/products/products" sx={{ color: "#15191E" }}>view more</Link></Typography>

                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Item>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Item>
                                        <Box component="div" sx={{ width: '100%', backgroundColor: '#e7f8e7', borderRadius: '10px' }}>
                                            <Grid container alignItems={'center'} sx={{ display: 'block', width: '100%' }}>
                                                <Grid sx={{ padding: '20px', textAlign: 'center' }}>
                                                    <Item sx={{ backgroundColor: 'transparent' }}>
                                                        <Typography sx={{ fontWeight: "800", fontSize: '34px', color: "#15191E", lineHeight: '50px' }}>{pdcs ? pdcs : <CircularProgress color="inherit" />}</Typography>
                                                        <Typography sx={{ fontWeight: "600", fontSize: '15px', color: "#15191E", lineHeight: '40px' }}>Total PDCs</Typography>
                                                        <Typography sx={{ fontWeight: "600", fontSize: '12px', color: "#15191E", marginTop: '20px' }}><Link href="/products/product-distribution-channel" sx={{ color: "#15191E" }}>view more</Link></Typography>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                </Grid>
                <Grid container columnSpacing={2} sx={{ paddingLeft: '45px', paddingRight: '20px' }}>
                    <Grid item xs={12} sm={12} md={12} sx={{ paddingLeft: '50px', paddingRight: '30px' }} >
                        <Item sx={{ backgroundColor: '#f9f9f9', padding: '30px', border: '1px solid #ddd', borderRadius: '10px' }}>
                            <Typography sx={{ fontWeight: "600", fontSize: '26px', color: "#15191E" }}>Guidelines</Typography>
                            <Typography sx={{ fontWeight: "400", fontSize: '15px', color: "#15191E", marginTop: '15px' }}>1. If you are getting <span style={{ color: 'red' }}>"Something went wrong"</span> message then wait for <span style={{ fontWeight: '700' }}>9 minutes</span> and try again. The system is <span style={{ fontWeight: '700' }}>currently cold</span> and will take <span style={{ fontWeight: '700' }}>9 minutes to warm up.</span></Typography>
                            <Typography sx={{ fontWeight: "400", fontSize: '15px', color: "#15191E", marginTop: '15px' }}>2. Please click on <LocalFireDepartmentIcon color="primary" /> <span style={{ color: '#B253AE' }}>Warm System</span> link present on top of page whenever you <span style={{ fontWeight: '700' }}>login for the day, especially on Mondays and after holidays.</span></Typography>
                            <Typography sx={{ fontWeight: "400", fontSize: '15px', color: "#15191E", marginTop: '15px' }}>3. Process Manager, Statement Cycle, Reports, Products, PDCs, Product and PDC price file uploads features will be available between <span style={{ fontWeight: '700' }}>7:30 AM BST to 9 PM BST.</span></Typography>
                            <Typography sx={{ fontWeight: "400", fontSize: '15px', color: "#15191E", marginTop: '15px' }}>4. If you want to use it off shours or weekends then please Turn ON ECS using below option.</Typography>
                            <Box>
                                <Typography sx={{ fontWeight: "700", fontSize: '15px', color: "#15191E", marginTop: '15px' }}>ECS Services Status: <span style={{ padding: '10px 20px', borderRadius: '10px', letterSpacing: '2px', marginLeft: '10px', fontSize: '12px', color: '#fff',  backgroundColor : localStorage.getItem("ecsStatus") === 'ACTIVE' ? '#84bd00' : '#930'}}>{localStorage.getItem("ecsStatus")}</span> </Typography>
                                <Typography sx={{ fontWeight: "600", fontSize: '15px', color: "#15191E", marginTop: '15px', marginBottom: '5px' }}>Turn ECS Services</Typography>
                                <ButtonGroup disableElevation>
                                    <Button variant="contained" color="secondary" style={{ color: "#fff", marginRight: '10px'}} onClick={triggerEcsOn} disabled={localStorage.getItem("ecsStatus") === "ACTIVE" ? true : false}>On</Button>
                                    <Button variant="contained" className="home-ecs-off-btn" onClick={triggerEcsOff} disabled={localStorage.getItem("ecsStatus") !== "ACTIVE" ? true : false}>Off</Button>
                                </ButtonGroup>
                            </Box>
                            <Typography sx={{ fontWeight: "400", fontSize: '15px', color: "#15191E", marginTop: '15px' }}>NOTE: Please Turn OFF ECS once you are done or system will automatically turn it off at 9 PM BST</Typography>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
            {snackBar.show && <SnackBarDialog
                duration={snackBar.duration}
                vpositon={snackBar.vertical}
                hposition={snackBar.horizontal}
                onShow={snackBar.show}
                text={snackBar.text}
                type={snackBar.type}
                onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
            />}
            {
                state.showLoader && (
                    <div className="loadingWarmer">
                        <Box className='loadingBx'>
                            <div className="bounceball"></div>
                            <div className="text">NOW LOADING</div>
                        </Box>
                    </div>
                )
            }
        </>
    );
}

export default Home;
