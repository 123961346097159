import { Box, Modal, Paper, FormControl, Grid, TextField, FormHelperText, FormLabel, Button } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBrandDetailModal, warningModal, handleAddEditUnitOfMeasures } from "../../../store/slices/modals";
import { addNewUOMData, hasUOMName, setAutocomplete, setUOMEdit, setUOMName, setUOMRate, setUOPList, uomInput, resetUOM, setDeleteList, setUOMDeleteData } from "../../../store/slices/uomProduct";
import { DataTableClientSide } from '../../../components/shared/data-table/DataTableClientSide';
import AddEditUnitOfMeasuresDetails from '../AddEditUnitOfMeasures/AddEditUnitOfMeasuresDetails';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import axios_utilities from '../../../services/utility/axios-utilities';
import SnackBarDialog from '../../../components/shared/SnackBarDialog';
import PageLoader from '../../../components/shared/PageLoader';
import { filterFlagChange, filterResetFlagChange } from '../../../store/slices/externalFilter';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));
const IconMenuLink = ({ params, onDelete }) => {
    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { uom_container, setAddEditUnitOfMeasures } = useSelector((state) => {
        return {
            uom_container: state.uomProducts,
            setAddEditUnitOfMeasures: state.modalActions.hideAddEditUnitOfMeasures
        }
    });
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'edit') {
            handleEdit(row)
        } else if (action === 'delete') {
            if (onDelete) {
                onDelete(row)
            }
        }
        setAnchorEl(null);
    };

    const handleEdit = async (row) => {
        dispatch(setUOMEdit(row))
        dispatch(uomInput({
            key: 'rate_value',
            value: row.rate
        }))
        dispatch(setAutocomplete({
            parent: 'uom_from_to',
            key: 'uom_from_to_selected',
            value: row.toUOM
        }))
        dispatch(setBrandDetailModal(true))
    }

    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'edit')}>Edit </MenuItem>
            {/* <MenuItem onClick={(e) => handleClose(e, 'delete')}>Delete</MenuItem> */}
        </Menu>
    </>)
}
const defaultValues = {
    loader: false,
    formValid: false,
    defaultmode: false,
    defaultGrid: {
        paginationMode: 'client',
    },
    addEditPriceModal: false,
    pageLoader: false,
};
export default function AddEditUnitOfMeasures({ onShow }) {
    const [state, setState] = useState(defaultValues);
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })
    const [brandList, setBrandList] = useState([])
    const [columns, setColumns] = useState([]);
    const [isError, setIsError] = useState({
        uom_name: false,
    });

    const addEditUOMModal = (event) => {
        //setState({ ...state, addEditPriceModal: !state.addEditPriceModal })
        dispatch(setBrandDetailModal(true))
    }
    const hideAddEditUOMModal = (event) => {
        //setState({ ...state, addEditPriceModal: event })
        dispatch(setBrandDetailModal(event))
    }
    const allColumns = [
        {
            headerName: "Id",
            field: "id",
            flex: 1,
            hide: true
        },
        {
            headerName: "Rate",
            field: "rate",
            flex: 1,
            minWidth: 200
        },
        {
            headerName: "To",
            field: "toUOM",
            flex: 1,
            minWidth: 200,
            valueFormatter: ({ value }) => value.name,
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} onDelete={deleteUOMRate} />;
            },
        }
    ];

    const { handleWarning, uom_container, brandDetailModal } = useSelector((state) => {
        return {
            handleWarning: state.modalActions.closeWaringAddProduct,
            uom_container: state.uomProducts,
            brandDetailModal: state.modalActions.brandDetailModal
        }
    });
    const dispatch = useDispatch();

    const allUOMrates = async () => {
        try { 
            setState({ ...state, pageLoader: true })
            setTimeout(() => {
                setColumns(allColumns)
            }, 100);
            const { data } = await axios_utilities.get(`unitofmeasures/${uom_container.addNewUOMData.id}/conversions`);
            if (data) {
                setState({ ...state, pageLoader: false })
                dispatch(setUOPList(data))
            }
        } catch (error) {
            setState({ ...state, pageLoader: false })
            const { response: { data, status } } = error;
            if (data.message !== "Failed to find Unit Of Measure ") {
                setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: data.message ? "warning" : "error" })
            }
        }
    }
    useEffect(() => {
        allUOMrates();
    }, []);

    const handleClose = () => {
        //setState({ ...state, defaultmode: false })
        dispatch(handleAddEditUnitOfMeasures({
            showAddEdit: false
        }));
        dispatch(filterFlagChange(true));
        dispatch(filterResetFlagChange(false));
        dispatch(resetUOM())
    };

    const handleCancel = () => {
        setState({ ...state, defaultmode: false })
		dispatch(warningModal({
            yesHide: !handleWarning
        }))
    }

    const onBrandAdded = (value) => {
        setBrandList(list => [...list, { ...value, id: brandList.length }]);
    }


    const deleteUOMRate = (row) => {
        dispatch(setDeleteList(row))
        dispatch(setUOMDeleteData({
            key: 'uomRateStartDelete',
            value: true
        }))
        dispatch(warningModal({
            yesHide: !handleWarning
        }))
    }

    useEffect(() => {
        if (uom_container.uomRateConfirmDelete) {
            deleteSingleRow()
        }
    }, [uom_container.uomRateConfirmDelete])

    const deleteSingleRow = async () => {
        if (uom_container.deleteList !== null) {   
            try {
                setState({ ...state, pageLoader: true })
                const { data } = await axios_utilities.delete(`unitofmeasures/${uom_container.addNewUOMData.id}/conversions/${uom_container.deleteList.id}`);
                if (data.success) {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
                    const filteredRows = (uom_container.uom_list).filter(uniqueId => {
                        if (uniqueId.id !== uom_container.deleteList.id) {
                            return uniqueId;
                        }
                    })
                    setState({ ...state, pageLoader: false })
                    dispatch(setUOPList(filteredRows))
                    dispatch(setDeleteList(null))
                    resetSingleDeleteRowDispatch()
                }
                
            } catch (error) {
                const { response: { data, status } } = error;
                setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error" })
                setState({ ...state, pageLoader: false })
                resetSingleDeleteRowDispatch()
                if(data.message.includes("No Unit Of Measure Conversion was not found for")) {
                    setTimeout(() => {
                        dispatch(setUOPList([]))
                    }, 100);
                }
            }
        }
    }

    const resetSingleDeleteRowDispatch = () => {
        dispatch(setUOMDeleteData({
            key: 'uomRateStartDelete',
            value: false
        }))
        dispatch(setUOMDeleteData({
            key: 'uomRateConfirmDelete',
            value: false
        }))
    }

    const handleInputChange = (event) => {
        const { value, name } = event.target;
        dispatch(uomInput({
            key: name,
            value
        }))
        setState({ ...state, defaultmode: true })
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        let uom_name = {
            "name": uom_container.uom_name
        }
        try {
            if (uom_container.uom_name !== '') {
                setState({ ...state, loader: true })
                const { data } = uom_container.addNewUOMData ? await axios_utilities.put(`unitofmeasures/${uom_container.addNewUOMData.id}`, uom_name) : await axios_utilities.post(`unitofmeasures`, uom_name);
                // eslint-disable-next-line max-len
                if (data.success) {
                    setState({ ...state, loader: false, formValid: true, defaultmode: false})
                    dispatch(hasUOMName(true))
                    dispatch(addNewUOMData(data.resultMap.result))
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "success", })
                    // setTimeout(() => {
                    //     window.location.reload();
                    // }, 1500);
                }
            }

        } catch (error) {
            setState({ ...state, loader: false, formValid: false, defaultmode: true })
            const { response: { data, status } } = error;
            setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error" })
        }
    }

    return (
        <>
            <Modal
                open={onShow || false}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <Box component="div" className="overlay" id="addEditModal">
                    <div className="overlay-box large-size">
                        <div className="content">
                            <div className="overlayBody">
                                <div className="overlay-header text-center"> Unit Of Measure Details
                                    <CancelIcon className="closeBtn" onClick={handleClose} />
                                </div>
                                <div className="overlay-content">
                                    <Box sx={{ flexGrow: 1, paddingX: 2 }} >
                                        <div className='BrandOwnerNameBx'>
                                            <Grid container alignItems="center">
                                                <Grid item>
                                                    <Item sx={{ paddingLeft: 0 }}>
                                                        <FormLabel htmlFor="uom_name" className="fontSemiBold mandatory">Name</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item>
                                                    <Item>
                                                        <div className="input-group">
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    inputProps={{ maxLength: 100 }}
                                                                    size="small"
                                                                    id="uom_name"
                                                                    aria-describedby="uom_name_error"
                                                                    onChange={(e) => handleInputChange(e)}
                                                                    value={uom_container.uom_name}
                                                                    error={uom_container.isError.uom_name}
                                                                    name="uom_name"
                                                                />
                                                                {uom_container.isError.uom_name && (<FormHelperText className='errorHelperTxt' id="uom_name_error">
                                                                    Please enter unique value
                                                                </FormHelperText>)}
                                                            </FormControl>

                                                        </div>
                                                    </Item>
                                                </Grid>
                                                {
                                                    uom_container.hasUOMName && (<Grid item sx={{ marginLeft: 'auto', marginRight: { xs: 'auto', sm: '0' } }}>
                                                        <Item sx={{ paddingRight: 0 }}>
                                                            <div className='table-actionable'>
                                                                <Button variant="contained" onClick={(e) => addEditUOMModal(e)} startIcon={<AddIcon />}>
                                                                    Add Rate
                                                                </Button>
                                                            </div>
                                                        </Item>
                                                    </Grid>)
                                                }

                                            </Grid>
                                        </div>

                                        <DataTableClientSide
                                            columns={columns ? columns : []}
                                            defaultGrid={state.defaultGrid}
                                            rows={uom_container.uom_list ? uom_container.uom_list : []}
                                        >

                                        </DataTableClientSide>
                                        {brandDetailModal && (
                                            <>
                                                <AddEditUnitOfMeasuresDetails size="medium_overlay"
                                                    onShow={brandDetailModal}
                                                    onHide={(e, reason) => hideAddEditUOMModal(e, reason)}
                                                    onBrandAdded={onBrandAdded}
                                                />
                                            </>
                                        )}
                                        <Grid container sx={{ marginTop: '20px' }}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Item>
                                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                                        <LoadingButton
                                                            onClick={(e) => { handleSubmit(e) }}
                                                            loading={state.loader}
                                                            loadingPosition="center"
                                                            endIcon=""
                                                            variant="contained"
                                                            type="submit" sx={{ marginRight: '15px' }}
                                                            //disabled={(!uom_container.uom_name && !uom_container.uom_name > 0) || (state.defaultmode === false)}
                                                            disabled={(!uom_container.uom_name && !uom_container.uom_name > 0) ? true : false}
                                                            //className={`${state.formValid ? 'Mui-disabled' : ''}`}
                                                        >
                                                            Save
                                                        </LoadingButton>
                                                        <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        {state.pageLoader && <PageLoader />}
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div>
                    {snackBar.show && <SnackBarDialog
                        onShow={snackBar.show}
                        text={snackBar.text}
                        type={snackBar.type}
                        onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                    />}
                </Box>
            </Modal>
        </>
    );
}
