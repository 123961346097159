import { Checkbox, MenuItem, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl } from '@mui/material'
import { useEffect, useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { warningModal, handleAddEditModal } from "../../../store/slices/modals";
import { styled } from '@mui/material/styles';
import moment from "moment";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import axios_levy_scheme from '../../../services/utility/axios-levy-scheme';
import axios_utilities from "../../../services/utility/axios-utilities";
import { setSelectedFilter, setlevySchemeEffectiveDate, levySchemeInput, getIsError, setAutocomplete, brandSearch, setLevySchemeDeleteData, setHasEditData } from "../../../store/slices/levyScheme";
import SnackBarDialog from "../../../components/shared/SnackBarDialog";
import axiosMember from '../../../services/utility/axios-member';
import { filterFlagChange, filterResetFlagChange } from '../../../store/slices/externalFilter';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

const defaultValues = {
    loader: false,
    formValid: true,
    defaultmode: false,
    statusCount: 0,
    chargeTypeCondition: false,
    newlevySchemesGenData: [],
};

function LevySchemeGeneral({ onShow, size = '', onHide }) {

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const [isError, isSetError] = useState({
        bopaError: false,
    })

    const [state, setState] = useState(defaultValues);
    const [otherTabsOpen, setOtherTabsOpen] = useState(false);
    const [latestEndDate, setLatestEndDate] = useState();
    const [levyschemeLevyType, setlevyschemeLevyType] = useState(false);
    const [levyschemeRaiseType, setlevyschemeRaiseType] = useState(false);
    const [loader, setLoader] = useState(false)
    const [open, setOpen] = useState(onShow);
    const { handleWarning, levyscheme_container } = useSelector((state) => {
        return {
            handleWarning: state.modalActions.closeWaringAddProduct,
            levyscheme_container: state.levySchemes
        }
    });

    useEffect(() => {
        const fetchMyAPI = async () => {
            if (levyscheme_container.levyscheme_general_vat_code.vat_code_options.length === 0) {
                axios_utilities.get('vatcodes/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            dispatch(setAutocomplete({
                                parent: 'levyscheme_general_vat_code',
                                key: 'vat_code_options',
                                value: unique
                            }));
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
            if (levyscheme_container.levyscheme_general_unit_measure.measure_options.length === 0) {
                axios_utilities.get('unitofmeasures/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            dispatch(setAutocomplete({
                                parent: 'levyscheme_general_unit_measure',
                                key: 'measure_options',
                                value: unique
                            }));
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
            if (levyscheme_container.member_tier.member_tier_options.length === 0) {
                axiosMember.get('/memberTier/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            dispatch(setAutocomplete({
                                parent: 'member_tier',
                                key: 'member_tier_options',
                                value: unique
                            }));
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
        }
        fetchMyAPI();
    }, [])

    const dispatch = useDispatch();

    const handleClose = () => {
        //setState({ ...state, defaultmode: false })
        if (onHide) {
            dispatch(setlevySchemeEffectiveDate({
                key: 'levyScheme_start_date',
                value: null
            }))
            dispatch(setlevySchemeEffectiveDate({
                key: 'levyScheme_end_date',
                value: null
            }))
            onHide(false)
        }
        setLatestEndDate('')
        dispatch(warningModal({
            yesHide: !handleWarning
        }))
    };

    const handleStartDate = (event) => {
        dispatch(setlevySchemeEffectiveDate({
            key: 'levyScheme_start_date',
            value: (event === null) ? null : moment(event, 'DD/MM/YYYY')
        }))
        dispatch(setlevySchemeEffectiveDate({
            key: 'levyScheme_end_date',
            value: null
        }))
        //setState({ ...state, defaultmode: true })
        setLatestEndDate((event === null) ? null : moment(event).add(1, 'days').format('DD/MM/YYYY'))
        if (event === null) {
            dispatch(getIsError({ key: 'levyScheme_start_date', value: true }))
        }
    }
    const handleEndDate = (event) => {
        dispatch(setlevySchemeEffectiveDate({
            key: 'levyScheme_end_date',
            value: (event === null) ? null : moment(event, 'DD/MM/YYYY')
        }))
        if (levyscheme_container.hasEditData) {
            setState({ ...state, defaultmode: true, formValid: false })
        }
    }
    const handleInputChange = (event) => {
        const { value, name } = event.target;
        if(name === 'levyScheme_rate'){
            dispatch(levySchemeInput({
                key: name,
                value: value.slice(0, 16)
            }))
        } else {
            dispatch(levySchemeInput({
                key: name,
                value
            }))
        }
        
        setState({ ...state, defaultmode: true })
    }

    const handleSelection = (event, id) => {
        let val = event.target.value;
        if (val !== "" || val !== null) {
            //setState({ ...state, defaultmode: true })
            dispatch(setSelectedFilter({
                key: id,
                value: val
            }))

            if (id === 'levyscheme_levy_type') {
                if (levyscheme_container.hasEditData) {
                    setState({ ...state, defaultmode: true })
                }
                if (val === 'BrandOwner') {
                    setState({ ...state, statusCount: 0 })
                    setlevyschemeLevyType(true)
                } else {
                    setlevyschemeLevyType(false)
                }
            }

            if (id === 'levyscheme_raise_type') {
                if (levyscheme_container.hasEditData) {
                    setState({ ...state, defaultmode: true })
                }
                if (val === 'DistributionCharge') {
                    setState({ ...state, statusCount: 0 })
                    setlevyschemeRaiseType(true)
                } else {
                    setlevyschemeRaiseType(false)
                }
            }

            if (id === 'levyscheme_charge_type' && val === 'UnitOfMeasure') {
                if (levyscheme_container.hasEditData) {
                    setState({ ...state, chargeTypeCondition: true, defaultmode: true })
                } else {
                    setState({ ...state, chargeTypeCondition: true })
                }
            }
            if (id === 'levyscheme_charge_type' && val === 'Percentage') {
                if (levyscheme_container.hasEditData) {
                    setState({ ...state, chargeTypeCondition: false, defaultmode: true })
                } else {
                    setState({ ...state, chargeTypeCondition: false })
                }
                dispatch(brandSearch({
                    parent: 'levyscheme_general_unit_measure',
                    key: 'measure_selected',
                    value: ''
                }))
                dispatch(brandSearch({
                    parent: 'levyscheme_general_unit_measure',
                    key: 'measure_selected',
                    value: null
                }))
            }
        }
    }

    const autoCompletehandleSelection = (event, newValue, parentname, name) => {
        if (levyscheme_container.hasEditData) {
            setState({ ...state, defaultmode: true, formValid: false })
        }
        if (event.target.value !== "" || event.target.value !== null) {
            dispatch(brandSearch({
                parent: parentname,
                key: name,
                value: newValue
            }))
        }
    }

    useEffect(() => {
        const inputNumber = document.querySelectorAll('.inputNumber');
        inputNumber.forEach(el => el.addEventListener('keypress', evt => {
            if (evt.which === 8) {
                return;
            }
            if (evt.which < 42 || evt.which > 60) {
                evt.preventDefault();
            }
        }));
    }, []);

    useEffect(() => {
        if (moment(levyscheme_container.levyScheme_start_date).isBefore(moment(), "day")) {
            isSetError({ ...isError, bopaError: true })
        } else {
            isSetError({ ...isError, bopaError: false })
        }
    }, [levyscheme_container.levyScheme_start_date])

    useEffect(() => {
        const { add_edit_levyScheme_name,
            levyScheme_start_date,
            levyscheme_levy_type,
            levyScheme_rate,
            levyscheme_charge_type,
            levyscheme_raise_type
        } = levyscheme_container;
        if ((add_edit_levyScheme_name === "") || (levyScheme_start_date === "" || levyScheme_start_date === null || levyScheme_start_date === 'Invalid Date') || (levyscheme_levy_type === "" || levyscheme_levy_type === null) || (levyScheme_rate === "" || levyScheme_rate === null) || (levyscheme_charge_type === "" || levyscheme_charge_type === null) || (levyscheme_raise_type === "" || levyscheme_raise_type === null) || (levyscheme_container.levyscheme_general_vat_code.vat_code_selected === "" || levyscheme_container.levyscheme_general_vat_code.vat_code_selected === null) || (levyscheme_container.member_tier.member_tier_selected === "" || levyscheme_container.member_tier.member_tier_selected === null) || (levyscheme_container.levyscheme_levy_type === 'BrandOwner' && levyscheme_container.levyscheme_raise_type === 'DistributionCharge')) {
            setState({ ...state, formValid: true, defaultmode: false })
            console.log("a")
        }
        else {
            setState({ ...state, formValid: false, defaultmode: true })
            console.log("b")
        }
    }, [levyscheme_container.add_edit_levyScheme_name,
    levyscheme_container.levyScheme_start_date,
    levyscheme_container.levyscheme_levy_type,
    levyscheme_container.levyScheme_rate,
    levyscheme_container.levyscheme_charge_type,
    levyscheme_container.levyscheme_raise_type,
    levyscheme_container.member_tier.member_tier_selected,
    levyscheme_container.levyscheme_general_vat_code.vat_code_selected
    ]);

    useEffect(() => {
        const {
            add_edit_levyScheme_name,
            levyScheme_start_date,
            levyScheme_rate,
            levyscheme_levy_type,
            levyscheme_charge_type,
            levyscheme_raise_type,
            levyscheme_levy_type_raise_type,
            vat_code_selected,
            member_tier_selected
        } = levyscheme_container.isError
        // eslint-disable-next-line max-len
        if (!add_edit_levyScheme_name && !levyScheme_start_date && !levyScheme_rate && !levyscheme_levy_type && !levyscheme_charge_type && !levyscheme_raise_type && !levyscheme_levy_type_raise_type && !vat_code_selected && !member_tier_selected) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true })
        }
    }, [levyscheme_container.isError])


    const handleSubmit = async (event) => {
        event.preventDefault();
        let levySchemesGenData = null;
        if (!state.formValid) {

            if (moment(levyscheme_container.levyScheme_start_date).format('DD/MM/YYYY') === 'Invalid date' || (levyscheme_container.levyScheme_end_date !== "" && levyscheme_container.levyScheme_end_date !== null && moment(levyscheme_container.levyScheme_end_date).format('DD/MM/YYYY') === 'Invalid date')) {
                setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
            }
            else if (levyscheme_container.levyScheme_end_date !== null && levyscheme_container.levyScheme_end_date !== "" && (levyscheme_container.levyScheme_start_date > levyscheme_container.levyScheme_end_date)) {
                setSnackBar({ ...snackBar, show: true, text: "End date is less than Start Date!", type: "error", });
            }
            else {
                levySchemesGenData = {
                    "id": levyscheme_container.hasEditData ? levyscheme_container.hasEditData.id : null,
                    "chargeType": levyscheme_container.levyscheme_charge_type,
                    "endDate": levyscheme_container.levyScheme_end_date ? moment(levyscheme_container.levyScheme_end_date).format('DD/MM/YYYY') : null,
                    "levyRate": Number(levyscheme_container.levyScheme_rate).toFixed(2),
                    "levyType": levyscheme_container.levyscheme_levy_type,
                    "name": levyscheme_container.add_edit_levyScheme_name,
                    "raiseType": levyscheme_container.levyscheme_raise_type,
                    "startDate": levyscheme_container.levyScheme_start_date ? moment(levyscheme_container.levyScheme_start_date).format('DD/MM/YYYY') : null,
                    "unitOfMeasure": levyscheme_container.levyscheme_charge_type === 'UnitOfMeasure' ? levyscheme_container.levyscheme_general_unit_measure.measure_selected : null,
                    "vatCode": levyscheme_container.levyscheme_general_vat_code.vat_code_selected,
                    "memberTier": levyscheme_container.member_tier.member_tier_selected
                }
                try {
                    setLoader(true)
                    setState({ ...state, loader: true })
                    const { data, status } = levyscheme_container.hasEditData ? await axios_levy_scheme.put(`levySchemes/${levyscheme_container.hasEditData.id}`, { ...levySchemesGenData }) : await axios_levy_scheme.post(`levySchemes`, { ...levySchemesGenData });
                    if (data.success === true && data.resultMap) {
                        setLoader(false)
                        setSnackBar({ ...snackBar, show: true, text: data.message, type: "success", })
                        setState({ ...state, loader: false, formValid: true, newlevySchemesGenData: data.resultMap.result })
                        dispatch(setHasEditData(data.resultMap.result))
                        // if (!levyscheme_container.hasEditData) {
                        //     setOtherTabsOpen(true)
                        // } else {
                        //     setTimeout(() => {
                        //         window.location.reload();
                        //     }, 1500);
                        // }
                        setTimeout(() => {
                            dispatch(handleAddEditModal({
                                showAddEdit: true
                            }));
                            //dispatch(filterFlagChange(true));
                            //dispatch(filterResetFlagChange(false));
                        }, 1500);
                    }
                } catch (error) {
                    setLoader(false)
                    setState({ ...state, loader: false })
                    const { response: { data, status } } = error;
                    setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error", })
                }
            }
        }
    };

    const handleOtherTabsOpen = () => {
        dispatch(setHasEditData(state.newlevySchemesGenData))
        setOtherTabsOpen(false)
    }
    const handleOtherTabsClose = () => {
        setOtherTabsOpen(false)
        setTimeout(() => {
            dispatch(handleAddEditModal({
                showAddEdit: false
            }))
            window.location.reload();
        }, 1500);
    }

    useEffect(() => {
        if ((levyschemeLevyType === true) && (levyschemeRaiseType === true)) {
            setSnackBar({ ...snackBar, show: true, text: "The combination of Levy Type and Raise Type is incorrect", type: "error" });
        }
    }, [levyschemeLevyType === true && levyschemeRaiseType === true])

    useEffect(() => {
        if (levyscheme_container && state.statusCount === 0) {
            if (levyscheme_container.levyscheme_levy_type === 'BrandOwner' && levyscheme_container.levyscheme_raise_type === 'DistributionCharge') {
                setState({ ...state, statusCount: 1, formValid: true })
                setSnackBar({ ...snackBar, show: true, text: "The combination of Levy Type and Raise Type is incorrect", type: "error" });
            }
        }
    }, [levyscheme_container])

    const handleDecimal = (event) => {
        const valueDecimal = event.target.value === '' ? "" : (event.target.value == 0 ? 0 : Number(event.target.value).toFixed(2))
        
        dispatch(levySchemeInput({
            key: event.target.name,
            value: valueDecimal
        }))
    }
    
    return (
        <>
            <form onSubmit={(e) => handleSubmit(e)}>
                <Box sx={{ flexGrow: 1, paddingX: 2 }}>
                    <Grid container columnSpacing={2} sx={{ paddingLeft: '20px', paddingRight: '20px', marginBottom: 2 }}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Item>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={6} sx={{ textAlign: 'right', display: { xs: 'none', md: 'block' } }}>
                                        <Item>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={3} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="add_edit_levyScheme_name" className="fontSemiBold mandatory">Name</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={9}>
                                                    <Item>
                                                        <Box component="div" className=''>
                                                            <FormControl size="small" fullWidth>
                                                                <TextField
                                                                    inputProps={{ maxLength: 50 }}
                                                                    type="text"
                                                                    size="small"
                                                                    id="add_edit_levyScheme_name"
                                                                    aria-describedby="add_edit_levyScheme_name_error"
                                                                    onChange={(e) => handleInputChange(e)}
                                                                    value={levyscheme_container.add_edit_levyScheme_name}
                                                                    error={levyscheme_container.isError.add_edit_levyScheme_name}
                                                                    min={1}
                                                                    name="add_edit_levyScheme_name"
                                                                //onBlur={(e) => checkQunique(e, 'isAggregationGroupNameValid?name')}
                                                                />
                                                                {levyscheme_container.isError.add_edit_levyScheme_name && (<FormHelperText className='errorHelperTxt' id="add_edit_levyScheme_name_error">This field is mandatory</FormHelperText>)}
                                                            </FormControl>
                                                        </Box>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Item>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={3} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="levyScheme_start_date" className="fontSemiBold mandatory">Start Date</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={9}>
                                                    <Item>
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <DesktopDatePicker
                                                                value={levyscheme_container.levyScheme_start_date}
                                                                disabled={levyscheme_container.hasEditData !== null ? true : false}
                                                                onChange={(e) => { handleStartDate(e) }}
                                                                name="levyScheme_start_date"
                                                                inputFormat="DD/MM/YYYY"
                                                                // eslint-disable-next-line max-len
                                                                renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                    <Box component="div" className='date-picker-input'>
                                                                        <FormControl size={'small'} fullWidth>
                                                                            <TextField size="small" id="levyScheme_start_date"
                                                                                ref={inputRef} {...inputProps}
                                                                                placeholder=""
                                                                                aria-describedby="levyScheme_start_date_error"
                                                                                error={levyscheme_container.isError.levyScheme_start_date}
                                                                                autoComplete="off"
                                                                            />
                                                                            {levyscheme_container.isError.levyScheme_start_date && (<FormHelperText className='errorHelperTxt' id="levyScheme_start_date_error">Start date is missing</FormHelperText>)}
                                                                            <Box className='date-picker-icon'>
                                                                                {InputProps?.endAdornment}
                                                                            </Box>
                                                                        </FormControl>
                                                                    </Box>
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    </Item>
                                                </Grid>
                                            </Grid>

                                        </Item>
                                    </Grid>
                                </Grid>
                            </Item>
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={2} sx={{ paddingLeft: '20px', paddingRight: '20px', marginBottom: 2 }}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Item>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={6} sx={{ textAlign: 'right', display: { xs: 'none', md: 'block' } }}>
                                        <Item>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={3} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="levyScheme_end_date" className="fontSemiBold">End Date</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={9}>
                                                    <Item>
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <DesktopDatePicker
                                                                value={levyscheme_container.levyScheme_end_date}
                                                                disabled={(levyscheme_container.levyScheme_start_date === null || levyscheme_container.levyScheme_start_date === '') ? true : false}
                                                                onChange={(e) => { handleEndDate(e) }}
                                                                name="levyScheme_end_date"
                                                                minDate={latestEndDate ? moment(latestEndDate, 'DD/MM/YYYY') : moment(levyscheme_container.levyScheme_start_date, 'DD/MM/YYYY').add(1, 'days')}
                                                                inputFormat="DD/MM/YYYY"
                                                                // eslint-disable-next-line max-len
                                                                renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                    <Box component="div" className='date-picker-input'>
                                                                        <FormControl size={'small'} fullWidth>
                                                                            <TextField size="small" id="levyScheme_end_date"
                                                                                ref={inputRef} {...inputProps}
                                                                                placeholder=""
                                                                                aria-describedby="levyScheme_end_date_error"
                                                                                autoComplete="off"
                                                                            />
                                                                            {isError.endDate && (<FormHelperText id="levyScheme_end_date_error">Error Here</FormHelperText>)}
                                                                            <Box className='date-picker-icon'>
                                                                                {InputProps?.endAdornment}
                                                                            </Box>
                                                                        </FormControl>
                                                                    </Box>
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Item>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={3} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="levyscheme_levy_type" className="fontSemiBold mandatory">Levy Type</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={9}>
                                                    <Item >
                                                        <Box component="div" className='' >
                                                            <FormControl size={'small'} fullWidth>
                                                                <Select value={levyscheme_container.levyscheme_levy_type} onChange={(event) => handleSelection(event, "levyscheme_levy_type")} id="levyscheme_levy_type">
                                                                    <MenuItem style={{ fontSize: 14 }} value={"BrandOwner"}>Brand Owner</MenuItem>
                                                                    <MenuItem style={{ fontSize: 14 }} value={"Supplier"}>Supplier</MenuItem>
                                                                </Select>
                                                                {levyscheme_container.isError.levyscheme_levy_type && (<FormHelperText className='errorHelperTxt' id="levyscheme_levy_type_error">This field is mandatory</FormHelperText>)}
                                                            </FormControl>
                                                        </Box>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Item>
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={2} sx={{ paddingLeft: '20px', paddingRight: '20px', marginBottom: 2 }}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Item>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={6} sx={{ textAlign: 'left', display: { xs: 'none', md: 'block' } }}>
                                        <Item>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={3} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="levyscheme_charge_type" className="fontSemiBold mandatory">Charge Type</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={9}>
                                                    <Item >
                                                        <Box component="div" className=''>
                                                            <FormControl size={'small'} fullWidth>
                                                                <Select value={levyscheme_container.levyscheme_charge_type} onChange={(event) => handleSelection(event, "levyscheme_charge_type")} id="levyscheme_charge_type">
                                                                    <MenuItem style={{ fontSize: 14 }} value={"UnitOfMeasure"}>UnitOfMeasure</MenuItem>
                                                                    <MenuItem style={{ fontSize: 14 }} value={"Percentage"}>Percentage</MenuItem>
                                                                </Select>
                                                                {levyscheme_container.isError.levyscheme_charge_type && (<FormHelperText className='errorHelperTxt' id="levyscheme_charge_type_error">This field is mandatory</FormHelperText>)}
                                                            </FormControl>
                                                        </Box>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Item>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={3} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="levyscheme_raise_type" className="fontSemiBold mandatory">Raise Type</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={9}>
                                                    <Item >
                                                        <Box component="div" className='' >
                                                            <FormControl size={'small'} fullWidth>
                                                                <Select value={levyscheme_container.levyscheme_raise_type} onChange={(event) => handleSelection(event, "levyscheme_raise_type")} id="levyscheme_raise_type">
                                                                    <MenuItem style={{ fontSize: 14 }} value={"DistributionCharge"}>DistributionCharge</MenuItem>
                                                                    <MenuItem style={{ fontSize: 14 }} value={"ProductValue"}>ProductValue</MenuItem>
                                                                </Select>
                                                                {levyscheme_container.isError.levyscheme_raise_type && (<FormHelperText className='errorHelperTxt' id="levyscheme_raise_type_error">This field is mandatory</FormHelperText>)}
                                                            </FormControl>
                                                        </Box>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Item>
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={2} sx={{ paddingLeft: '20px', paddingRight: '20px', marginBottom: 2 }}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Item>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={6} sx={{ textAlign: 'right', display: { xs: 'none', md: 'block' } }}>
                                        <Item>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={3} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="levyScheme_rate" className="fontSemiBold mandatory">Rate</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={9}>
                                                    <Item>
                                                        <Box component="div" className=''>
                                                            <FormControl size="small" fullWidth>
                                                                <TextField
                                                                    inputProps={{ maxLength: 50 }}
                                                                    type="number"
                                                                    size="small"
                                                                    id="levyScheme_rate"
                                                                    aria-describedby="levyScheme_rate_error"
                                                                    onChange={(e) => handleInputChange(e)}
                                                                    value={levyscheme_container.levyScheme_rate || (levyscheme_container.hasEditData ? levyscheme_container.hasEditData.code : '')}
                                                                    error={levyscheme_container.isError.levyScheme_rate}
                                                                    name="levyScheme_rate"
                                                                    className="inputNumber"
                                                                    step="any"
                                                                    onBlur={(e) => handleDecimal(e)}
                                                                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                                                //onBlur={(e) => checkQunique(e, 'isCodeNameValid?code')}
                                                                />
                                                                {levyscheme_container.isError.levyScheme_rate && (<FormHelperText className='errorHelperTxt' id="levyScheme_rate_error">Please enter unique value</FormHelperText>)}
                                                            </FormControl>
                                                        </Box>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Item>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={3} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="levyscheme_general_vat_code" className="fontSemiBold mandatory">Vat Code</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={9}>
                                                    <Item>
                                                        <Autocomplete
                                                            defaultValue={levyscheme_container.levyscheme_general_vat_code.vat_code_selected || (levyscheme_container.hasEditData ? levyscheme_container.hasEditData.vatCode : null)}
                                                            id="levyscheme_general_vat_code_search"
                                                            options={levyscheme_container.levyscheme_general_vat_code.vat_code_options}
                                                            getOptionLabel={(option) => option.code || ""}
                                                            componentsProps={{
                                                                paper: {
                                                                    sx: {
                                                                        fontSize: '0.85rem'
                                                                    }
                                                                }
                                                            }}
                                                            onChange={(event, newValue) => autoCompletehandleSelection(event, newValue, 'levyscheme_general_vat_code', 'vat_code_selected')}
                                                            renderInput={(params) =>
                                                                <FormControl size={'small'} fullWidth>
                                                                    <div className="input-group">
                                                                        <TextField {...params}
                                                                            size="small"
                                                                            id="levyscheme_general_vat_code"
                                                                            aria-describedby="levyscheme_general_vat_code-error"
                                                                            error={levyscheme_container.isError.vat_code_selected}
                                                                        />
                                                                        {levyscheme_container.isError.vat_code_selected &&
                                                                            (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)}
                                                                    </div>
                                                                </FormControl>
                                                            }
                                                        />
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Item>
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={2} sx={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Item>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={6} sx={{ textAlign: 'right', display: { xs: 'none', md: 'block' } }}>
                                        <Item>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={3} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="levyscheme_general_unit_measure" className="fontSemiBold">Unit of Measure</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={9}>
                                                    <Item>
                                                        <Autocomplete
                                                            value={levyscheme_container.levyscheme_general_unit_measure.measure_selected !== null ? levyscheme_container.levyscheme_general_unit_measure.measure_selected : null}
                                                            id="levyscheme_general_unit_measure_search"
                                                            options={levyscheme_container.levyscheme_general_unit_measure.measure_options}
                                                            getOptionLabel={(option) => option.name || ""}
                                                            // disabled={state.chargeTypeCondition === false}
                                                            disabled={levyscheme_container.levyscheme_charge_type === 'UnitOfMeasure' ? false : true}
                                                            componentsProps={{
                                                                paper: {
                                                                    sx: {
                                                                        fontSize: '0.85rem'
                                                                    }
                                                                }
                                                            }}
                                                            onChange={(event, newValue) => autoCompletehandleSelection(event, newValue, 'levyscheme_general_unit_measure', 'measure_selected')}
                                                            renderInput={(params) =>
                                                                <FormControl size={'small'} fullWidth>
                                                                    <div className="input-group">
                                                                        <TextField {...params}
                                                                            size="small"
                                                                            id="levyscheme_general_unit_measure"
                                                                            aria-describedby="levyscheme_general_unit_measure-error"
                                                                        //error={levyscheme_container.isError.measure_selected}
                                                                        />
                                                                        {/* {(levyscheme_container.levyscheme_charge_type === 'UnitOfMeasure') && levyscheme_container.isError.measure_selected &&
                                                                            (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)} */}
                                                                    </div>
                                                                </FormControl>
                                                            }
                                                        />
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Item>
                                            <Grid container alignItems="center">
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={3} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="general_member_tier" className="fontSemiBold mandatory">Purchasing Tier</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={9}>
                                                        <Item>
                                                            <Autocomplete
                                                                defaultValue={levyscheme_container.member_tier.member_tier_selected}
                                                                id="general_member_tier_search"
                                                                options={levyscheme_container.member_tier.member_tier_options}
                                                                getOptionLabel={(option) => option.name || ""}
                                                                componentsProps={{
                                                                    paper: {
                                                                        sx: {
                                                                            fontSize: '0.85rem'
                                                                        }
                                                                    }
                                                                }}
                                                                onChange={(event, newValue) => autoCompletehandleSelection(event, newValue, 'member_tier', 'member_tier_selected')}
                                                                renderInput={(params) =>
                                                                    <FormControl size={'small'} fullWidth>
                                                                        <div className="input-group">
                                                                            <TextField {...params}
                                                                                size="small"
                                                                                id="general_member_tier"
                                                                                aria-describedby="general_member_tier-error"
                                                                                error={levyscheme_container.isError.member_tier_selected}
                                                                            />
                                                                            {levyscheme_container.isError.member_tier_selected &&
                                                                                (<FormHelperText className='errorHelperTxt' id="general_member_tier-error"> This field is mandatory </FormHelperText>)}
                                                                        </div>
                                                                    </FormControl>
                                                                }
                                                            />
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Item>
                        </Grid>
                    </Grid>

                </Box>

                <Grid container sx={{ marginTop: '20px' }}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Item>
                            <Grid container direction="row" alignItems="center" justifyContent="center">
                                <LoadingButton
                                    loading={state.loader}
                                    loadingPosition="center"
                                    endIcon=""
                                    variant="contained"
                                    type="submit" sx={{ marginRight: '15px' }}
                                    //disabled={state.formValid || state.defaultmode === false}
                                    // eslint-disable-next-line max-len
                                    disabled={levyscheme_container.hasEditData ? (levyscheme_container.isError.add_edit_levyScheme_name || levyscheme_container.isError.levyScheme_start_date || levyscheme_container.isError.levyscheme_levy_type || levyscheme_container.isError.levyScheme_rate || levyscheme_container.isError.levyscheme_charge_type || levyscheme_container.isError.levyscheme_raise_type || levyscheme_container.isError.member_tier_selected) : (state.formValid || state.defaultmode === false)}
                                >Save General Details</LoadingButton>
                                <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                            </Grid>
                        </Item>
                    </Grid>
                </Grid>

                <Modal
                    open={otherTabsOpen}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                    keepMounted
                    onClose={(_, reason) => {
                        if (reason !== "backdropClick") {
                            handleOtherTabsClose();
                        }
                    }}
                >
                    <Box component="div" className="overlay" id="addEditModal">
                        <div className={`overlay-box small_overlay`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-content">
                                        <Box component="div" sx={{ textAlign: 'center', padding: 1.5 }}>
                                            <p className="fontMedium">Would you like to add other details ?</p>
                                            <Grid sx={{ paddingTop: 2 }} container direction="row" alignItems="center" justifyContent="center">
                                                <Button variant="contained" type="submit" sx={{ marginRight: '15px' }} onClick={handleOtherTabsOpen}>Yes</Button>
                                                <Button variant="outlined" onClick={handleOtherTabsClose}>No</Button>
                                            </Grid>
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </form>
            {snackBar.show && <SnackBarDialog
                onShow={snackBar.show}
                text={snackBar.text}
                type={snackBar.type}
                onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
            />}
        </>
    );
}

export default LevySchemeGeneral;