import { Checkbox, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl, RadioGroup, Radio, Autocomplete } from '@mui/material'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import axiosDiscountSchemes from '../../../../services/utility/axios-discount-schemes';
import PageLoader from '../../../../components/shared/PageLoader';
import { setDiscountSchemeState, getIsError } from '../../../../store/slices/discountScheme';
import { handleModalState } from '../../../../store/slices/modals';
import axiosProductDistribution from '../../../../services/utility/axios-product-distribution';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

function AddEditDcDistributionChannel({ onShow, size = '', onHide, onSubmitDate }) {
    const [state, setState] = useState({
        dueDate: '',
        payDateDescription: '',
        onTrade: false,
        discountabl_bopa: false,
        defaultmode: false,
        pageLoader: false,
        formValid: true
    })
    
    const { discountScheme_container } = useSelector((state) => {
        return {
            discountScheme_container: state.discountSchemes,
        }
    });


    let distributionChannelData = null;
    if(discountScheme_container.hasEditDC !== null) {
        distributionChannelData = {"id": discountScheme_container.hasEditDC.distributionChannelId, "name": discountScheme_container.hasEditDC.distributionChannelName}
    }

    const [included, setIncluded] = useState(true);
    const [distributionChannel, setDistributionChannel] = useState({
        dc_options: [],
        dc_selected: discountScheme_container.hasEditDC ? distributionChannelData: null
    })
    const [isError, setIsError] = useState({
        dc_selected: false
    })

    const handleChange = (event) => {
        if(discountScheme_container.hasEditDC) {
            setState({ ...state, defaultmode: true })
        } 
        setIncluded(event.target.checked);
    };

    useEffect(() => {
        const fetchMyAPI = async () => {
            if (distributionChannel.dc_options.length === 0) {
                axiosProductDistribution.get('distributionChannels/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data.filter((value, index, self) => self.findIndex((m) => m.name === value.name) === index);
                            setDistributionChannel({ ...distributionChannel, dc_options: unique })
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
        }
        fetchMyAPI();
    }, [])
    
    const dispatch = useDispatch();

    const [open, setOpen] = useState(onShow);

    const handleClose = () => {
        //setState({ ...state, defaultmode: false })
        dispatch(handleModalState({
            key: 'addEditDCModal',
            value: false
        }))
        dispatch(setDiscountSchemeState({
            key: 'hasEditDC',
            value: null
        }))
        dispatch(getIsError({ key: 'dc_selected', value: false }))
    };

    const handleSelf = () => {
        handleClose()
    }

    useEffect(() => {
        if (distributionChannel.dc_selected === null || distributionChannel.dc_selected === "") {
            setState({ ...state, formValid: true, defaultmode: false})
        } else {
            setState({ ...state, formValid: false, defaultmode: true})
        }
    }, [distributionChannel.dc_selected])

    useEffect(() => {
        const {
            dc_selected
        } = discountScheme_container.isError
        if (!dc_selected) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true })
        }
    }, [discountScheme_container.isError])

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (distributionChannel.dc_selected === null && isError.dc_selected === false) {
            setIsError({ ...isError, dc_selected: true })
        } else {
            addPayDate()
        }
    }

    const addPayDate = async () => {
        let payload = null;
        if ((discountScheme_container.hasEditData !== null || discountScheme_container.editTabData !== null) && discountScheme_container.hasEditDC === null) {
            payload = {
                "distributionChannelId": distributionChannel.dc_selected ? distributionChannel.dc_selected.id : "",
                included,
                "distributionChannelName": distributionChannel.dc_selected ? distributionChannel.dc_selected.name : "",
            }
        } else if (discountScheme_container.hasEditDC !== null) {
            payload = {
                "id": discountScheme_container.hasEditDC.id,
                "distributionChannelId": distributionChannel.dc_selected ? distributionChannel.dc_selected.id : discountScheme_container.hasEditDC.distributionChannelId,
                included,
                "distributionChannelName": distributionChannel.dc_selected ? distributionChannel.dc_selected.name : discountScheme_container.hasEditDC.distributionChannelName
            }
        }

        try {
            setState({ ...state, pageLoader: true })
           // const url = ((discountScheme_container.hasEditData !== null || discountScheme_container.editTabData !== null) && discountScheme_container.hasEditDC === null) ? 'adddistributionchannels' : 'updatedistributionchannels';

            const { data } =  ((discountScheme_container.hasEditData !== null || discountScheme_container.editTabData !== null) && discountScheme_container.hasEditDC === null) ? await axiosDiscountSchemes.post(`/discountSchemes/${discountScheme_container.hasEditData.id || discountScheme_container.editTabData.id}/distributionChannels`, payload) : await axiosDiscountSchemes.put(`/discountSchemes/${discountScheme_container.hasEditData.id || discountScheme_container.editTabData.id}/distributionChannels/${discountScheme_container.hasEditDC.id}`, payload)

            if (data) {
                onSubmitDate({ text: data.message, type: "success" })
                setState({ ...state, pageLoader: false })
                handleClose()
            }

        } catch (error) {
            const { response: { data, status } } = error;
            if (!data) {
                onSubmitDate({ text: error.message, type: "error" })
            } else {
                onSubmitDate({ text: data.message, type: "error" })
            }
            setState({ ...state, pageLoader: false })
            handleClose()
        }
    }

    useEffect(() => {
        if (discountScheme_container.hasEditDC !== null) {
            setDistributionChannel({ ...distributionChannel, dc_selected: discountScheme_container.hasEditDC.distributionChannel })
            setIncluded(discountScheme_container.hasEditDC.included)
        }
    }, [])


    useEffect(() => {
        const inputNumber = document.querySelectorAll('.inputNumber');
        inputNumber.forEach(el => el.addEventListener('keypress', evt => {
            if (evt.which === 8) {
                return;
            }
            if (evt.which < 42 || evt.which > 60) {
                evt.preventDefault();
            }
        }));
    }, []);

    return (
        <>
            <Modal
                open={open}
                aria-labelledby="add-edit-price-title"
                aria-describedby="add-edit-price-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Box component="div" className="overlay" id="addEditModal">
                        {state.pageLoader && <PageLoader />}
                        <div className={`overlay-box ${size ? size : ''}`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-header text-center"> Distribution Channel
                                        <CancelIcon className="closeBtn" onClick={handleClose} />
                                    </div>
                                    <div className="overlay-content">
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            <FormLabel htmlFor="type" className="fontSemiBold mandatory">Distribution Channel</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Autocomplete
                                                                defaultValue={distributionChannel.dc_selected ? distributionChannel.dc_selected : null}
                                                                id="distributionChannel_search"
                                                                options={distributionChannel.dc_options}
                                                                getOptionLabel={(option) => option.name || ""}
                                                                componentsProps={{
                                                                    paper: {
                                                                        sx: {
                                                                            fontSize: '0.85rem'
                                                                        }
                                                                    }
                                                                }}
                                                                onChange={(event, newValue) => {
                                                                    (isError['dc_selected'] === null || isError['dc_selected'] === "") ? setIsError({ ...isError, dc_selected: true }) : setIsError({ ...isError, dc_selected: false })
                                                                    if (event.target.value !== undefined) {
                                                                        setState({ ...state, defaultmode: true })
                                                                        setDistributionChannel({ ...distributionChannel, dc_selected: newValue })
                                                                        dispatch(getIsError({ key: 'dc_selected', value: false }))
                                                                    } else {
                                                                        dispatch(getIsError({ key: 'dc_selected', value: true }))
                                                                    }
                                                                }}
                                                                renderInput={(params) =>
                                                                    <FormControl size={'small'} fullWidth>
                                                                        <div className="input-group">
                                                                            <TextField {...params}
                                                                                size="small"
                                                                                id="distributionChannel"
                                                                                aria-describedby="distributionChannel-error"
                                                                                error={isError.dc_selected}
                                                                            />
                                                                            {discountScheme_container.isError.dc_selected && (<FormHelperText className='errorHelperTxt' id="distributionChannel-error">
                                                                                Please select Distribution Channel
                                                                            </FormHelperText>)}
                                                                        </div>
                                                                    </FormControl>
                                                                }
                                                            />
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            <FormLabel htmlFor="included" className="fontSemiBold">Include/Exclude</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <div className="input-group">
                                                                <Checkbox
                                                                    checked={included}
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                />
                                                            </div>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            &nbsp;
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Grid container direction="row" alignItems="center">
                                                                <Button 
                                                                    variant="contained" 
                                                                    type="submit" 
                                                                    sx={{ marginRight: '15px' }} 
                                                                    //disabled={((discountScheme_container.hasEditDC && discountScheme_container.hasEditDC.confirmed === true) ? true : false) || state.defaultmode === false}
                                                                    // eslint-disable-next-line max-len
                                                                    disabled={((discountScheme_container.hasEditData !== null || discountScheme_container.editTabData !== null) && discountScheme_container.hasEditDC) ? discountScheme_container.isError.dc_selected : (state.formValid || state.defaultmode === false)}
                                                                >Save</Button>
                                                                <Button variant="outlined" onClick={handleSelf}>Cancel</Button>
                                                            </Grid>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </form>

            </Modal>
        </>
    );
}

export default AddEditDcDistributionChannel;