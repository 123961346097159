import { Box, Modal, Paper, FormControl, Grid, TextField, FormHelperText, FormLabel, Button, FormControlLabel, Checkbox, Autocomplete, Menu, MenuItem, DialogActions, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SnackBarDialog from '../../../components/shared/SnackBarDialog';
import axios_pdc_file_upload from "../../../services/utility/axios-product-distribution";
import axios_utilities from "../../../services/utility/axios-utilities";
import axios_levy_scheme from "../../../services/utility/axios-levy-scheme";
import PageLoader from '../../../components/shared/PageLoader';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import CommonPopUp from "../addEditPdc/pdc-tabs/CommonPopUp";

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

export default function PdcFileUploadModal({ onShow, onHide, fileName, onUpdate, fileNameId, currentUploadSelection }) {
    const { prdcFileUpload } = useSelector((state) => {
        return {
            prdcFileUpload: state.pdcsUpload
        }
    });

    const predata = JSON.parse(currentUploadSelection);

    const dispatch = useDispatch();
    const [state, setState] = useState({
        productFileName: fileName,
        fileId: fileNameId,
        supplier_product_code: prdcFileUpload.editPdcUpload.supplierProductCode || "",
        outer_case_barcode: prdcFileUpload.editPdcUpload.outerCaseBarcode || "",
        product_name: prdcFileUpload.editPdcUpload.productName || "",
        price: prdcFileUpload.editPdcUpload.price ? Number(prdcFileUpload.editPdcUpload.price).toFixed(2) : null,
        pstartdate: prdcFileUpload.editPdcUpload.priceStartDate ? moment(prdcFileUpload.editPdcUpload.priceStartDate, 'DD/MM/YYYY') : null,
        penddate: prdcFileUpload.editPdcUpload.priceEndDate ? moment(prdcFileUpload.editPdcUpload.priceEndDate, 'DD/MM/YYYY') : null,
        charge: prdcFileUpload.editPdcUpload.charge ? Number(prdcFileUpload.editPdcUpload.charge).toFixed(2) : null,
        cstartdate: prdcFileUpload.editPdcUpload.chargeStartDate ? moment(prdcFileUpload.editPdcUpload.chargeStartDate, 'DD/MM/YYYY') : null,
        cenddate: prdcFileUpload.editPdcUpload.chargeEndDate ? moment(prdcFileUpload.editPdcUpload.chargeEndDate, 'DD/MM/YYYY') : null,
        unit_measure_id: prdcFileUpload.editPdcUpload.unitOfMeasureId || null,
        unit_measure_name: prdcFileUpload.editPdcUpload.unitOfMeasureName || null,
        validunitofmeasure: prdcFileUpload.editPdcUpload.validUnitOfMeasure || null,
        unitofmeasurenullvalidated: prdcFileUpload.editPdcUpload.unitOfMeasureNullValidated || false,
        product_value_levy_scheme: prdcFileUpload.editPdcUpload.productValueLevyScheme || null,
        productvaluelevyschemenullvalidated: prdcFileUpload.editPdcUpload.productValueLevySchemeNullValidated || false,
        dc_levy_scheme: prdcFileUpload.editPdcUpload.distributionChargeLevyScheme || null,
        distributionchargelevyschemenullvalidated: prdcFileUpload.editPdcUpload.distributionChargeLevySchemeNullValidated || false,
        validdistributionchargelevyscheme: prdcFileUpload.editPdcUpload.validDistributionChargeLevyScheme || null,
        id: prdcFileUpload.editPdcUpload.id || "",
        version: prdcFileUpload.editPdcUpload.version || "",
        result: prdcFileUpload.editPdcUpload.result || "",
        errormessage: prdcFileUpload.editPdcUpload.errorMessage || "",
        unitofwork: prdcFileUpload.editPdcUpload.unitOfWork || "",
        pricenullvalidated: prdcFileUpload.editPdcUpload.priceNullValidated || false,
        pricestartdatenullvalidated: prdcFileUpload.editPdcUpload.priceStartDateNullValidated || false,
        priceenddatenullvalidated: prdcFileUpload.editPdcUpload.priceEndDateNullValidated || false,
        validpricestartdate: prdcFileUpload.editPdcUpload.validPriceStartDate ? moment(prdcFileUpload.editPdcUpload.validPriceStartDate, 'DD/MM/YYYY') : null,
        validprice: prdcFileUpload.editPdcUpload.validPrice || null,
        validpriceenddate: prdcFileUpload.editPdcUpload.validPriceEndDate ? moment(prdcFileUpload.editPdcUpload.validPriceEndDate, 'DD/MM/YYYY') : null,
        alreadyexists: prdcFileUpload.editPdcUpload.alreadyExists || null,
        chargenullvalidated: prdcFileUpload.editPdcUpload.chargeNullValidated || false,
        chargestartdatenullvalidated: prdcFileUpload.editPdcUpload.chargeStartDateNullValidated || false,
        chargeenddatenullvalidated: prdcFileUpload.editPdcUpload.chargeEndDateNullValidated || false,
        validcstartdate: prdcFileUpload.editPdcUpload.validChargeStartDate ? moment(prdcFileUpload.editPdcUpload.validChargeStartDate, 'DD/MM/YYYY') : null,
        validcharge: prdcFileUpload.editPdcUpload.validCharge || null,
        validcenddate: prdcFileUpload.editPdcUpload.validChargeEndDate ? moment(prdcFileUpload.editPdcUpload.validChargeEndDate, 'DD/MM/YYYY') : null,
        formValid: false,
        handleOCBC: false,
        defaultbutton: false,
        priceimportflag: false,
        chargeimportflag: false
    });

    const [isError, setIsError] = useState({
        supplier_product_code: false,
        outer_case_barcode: false,
        price: false,
        pstartdate: false,
        charge: false,
        cstartdate: false
    })

    const [unit_measure, setUnit_measure] = useState({
        measure_selected: (prdcFileUpload.editPdcUpload.unitOfMeasureName || prdcFileUpload.editPdcUpload.unit_measure_id) ? { id: prdcFileUpload.editPdcUpload.unit_measure_id, name: prdcFileUpload.editPdcUpload.unitOfMeasureName } : null,
        measure_options: []
    })

    const [productvls, setProductvls] = useState({

        pvls_selected: (prdcFileUpload.editPdcUpload.productValueLevyScheme) ? { id: Number(prdcFileUpload.editPdcUpload.productValueLevyScheme), name: 'Invalid Data' } : null,
        pvls_options: []
    })

    const [dcls, setDCls] = useState({

        dcls_selected: (prdcFileUpload.editPdcUpload.distributionChargeLevyScheme) ? { id: Number(prdcFileUpload.editPdcUpload.distributionChargeLevyScheme), name: 'Invalid Data' } : null,
        dcls_options: []
    })

    // useEffect(() => {
    //     const inputNumber = document.querySelectorAll('.inputNumber');
    //     inputNumber.forEach(el => el.addEventListener('keypress', evt => {
    //         if (evt.which === 8) {
    //             return;
    //         }
    //         if (evt.which < 42 || evt.which > 60) {
    //             evt.preventDefault();
    //         }
    //     }));
    // }, []);

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const handleClose = () => {
        if (onHide) {
            onHide()
            //dispatch(editPdcUpload(null))
        }
    };

    // const handleInputChange = (event) => {
    //     const { value, name } = event.target;
    //     setState({ ...state, [name]: value })
    // }

    const handleInputChange = (event) => {
        const { value, name } = event.target;
        // console.log(value, name, isError, isError.name, isError[name]);
        if (value.length === 0 || value === null || value === "") {
            // value.length === 0 || value === null || value === "" ? setIsError({ ...isError, [name]: true }) : setIsError({ ...isError, [name]: false })
            if (isError[name] && name !== 'charge') {
                setIsError({ ...isError, [name]: true });
                setState({ ...state, [name]: '', defaultbutton: false });
            }
            else {
                //setIsError({ ...isError, [name]: false });
                value.length === 0 ? setIsError({ ...isError, [name]: true }) : setIsError({ ...isError, [name]: false })
                setState({ ...state, [name]: '', defaultbutton: true });
            }
        }
        else {
            if (name === 'charge' || name === 'price') {
                setState({ ...state, [name]: value.slice(0, 16), defaultbutton: true });
            } else {
                setState({ ...state, [name]: value, defaultbutton: true });
            }
            setIsError({ ...isError, [name]: false });
        }
    }

    const handleOCBCInputChange = (event) => {
        const { value, name } = event.target;
        if (value.length === 0 || value === null || value === "") {
            value.length === 0 ? setIsError({ ...isError, [name]: true }) : setIsError({ ...isError, [name]: false })
            setState({ ...state, [name]: '', defaultbutton: true });
        }
        else {
            setState({ ...state, [name]: value ? value.toUpperCase() : '', defaultbutton: true });
            setIsError({ ...isError, [name]: false });
        }
    }

    // console.log(isError.supplier_product_code)

    useEffect(() => {
        // console.log("UseEffect One Time");
        const fetchMyAPI = async () => {

            if (unit_measure.measure_options.length === 0) {
                axios_utilities.get('unitofmeasures/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data;

                            let a = unique.find((x) => {
                                if (x.name == prdcFileUpload.editPdcUpload.unitOfMeasureName) {
                                    return (x);
                                }

                            });
                            //setDCls({ dcls_options: unique, dcls_selected: { id: a.id, name: a.name } })
                            if (a) {
                                setUnit_measure({ ...unit_measure, measure_options: unique, measure_selected: { id: a.id, name: a.name } });
                                setState({ ...state, unit_measure_id: a.id, unit_measure_name: a.name });
                            }
                            else {
                                setUnit_measure({ ...unit_measure, measure_options: unique });
                            }
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        const { response: { data, status }, message } = error;
                        // console.log(message, status, data);
                        if (status === 500) {
                            setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
                        } else {
                            // setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
                            setSnackBar({ ...snackBar, show: true, text: "The ECS services are down at the moment.", type: "error", });
                        }
                    });
            }
            if (productvls.pvls_options.length === 0) {
                axios_levy_scheme.get('levySchemes/productValueLevyScheme/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data;
                            let a = unique.find((x) => {
                                if (x.id == prdcFileUpload.editPdcUpload.productValueLevyScheme) {
                                    return (x);
                                }

                            });
                            //setProductvls({ pvls_options: unique, pvls_selected: { id: a.id, name: a.name } })
                            //setProductvls({ ...productvls, })

                            if (a) {
                                setProductvls({ ...productvls, pvls_options: unique, pvls_selected: { id: a.id, name: a.name } })
                            }
                            else {
                                setProductvls({ ...productvls, pvls_options: unique })
                            }
                        }

                        //setProductvls({ ...productvls, pvls_selected: prdcFileUpload.editPdcUpload.productValueLevyScheme })
                    })
                    .catch(function (error) {
                        // handle error
                        const { response: { data, status }, message } = error;
                        // console.log(message, status, data);
                        if (status === 500) {
                            setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
                        } else {
                            // setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
                            setSnackBar({ ...snackBar, show: true, text: "The ECS services are down at the moment.", type: "error", });
                        }
                    });
            }
            if (dcls.dcls_options.length === 0) {
                axios_levy_scheme.get('levySchemes/distributionChargeLevyScheme/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data;
                            let a = unique.find((x) => {
                                if (x.id == prdcFileUpload.editPdcUpload.distributionChargeLevyScheme) {
                                    return (x);
                                }

                            });
                            //setProductvls({ ...productvls, })

                            if (a) {
                                setDCls({ ...dcls, dcls_options: unique, dcls_selected: { id: a.id, name: a.name } })
                            }
                            else {
                                setDCls({ ...dcls, dcls_options: unique })
                            }
                        }

                        //setProductvls({ ...productvls, pvls_selected: prdcFileUpload.editPdcUpload.productValueLevyScheme })
                    })
                    .catch(function (error) {
                        // handle error
                        const { response: { data, status }, message } = error;
                        // console.log(message, status, data);
                        if (status === 500) {
                            setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
                        } else {
                            // setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
                            setSnackBar({ ...snackBar, show: true, text: "The ECS services are down at the moment.", type: "error", });
                        }
                    });
            }

        }
        fetchMyAPI();
    }, [])

    useEffect(() => {

        // console.log("UseEffect Price and Price Date");
        if (state.price === "" || state.price === null) {
            setState({
                ...state, charge: null, pstartdate: null, penddate: null, validprice: null, validpricestartdate: null,
                validpriceenddate: null, pricestartdatenullvalidated: true, priceenddatenullvalidated: true, pricenullvalidated: true, priceimportflag: true
            });
            setIsError({ ...isError, charge: false, cstartdate: false });
            state.pstartdate = null;
            state.penddate = null;
            state.priceimportflag = true;
        }
        else {
            if (state.pstartdate === "" || state.pstartdate === null || state.pstartdate === 'Invalid date') {
                setIsError({ ...isError, pstartdate: true });
            }
        }

        // if ((state.price === "" || state.price === null) && isError.price === false && state.priceimportflag === false) {
        //     console.log("if");
        //     setIsError({ ...isError, price: true });
        // }
        // else
        // {
        //     console.log("else");
        //     if ((state.pstartdate === "" || state.pstartdate === null || state.pstartdate === 'Invalid date') && isError.pstartdate === false && state.priceimportflag === false) {
        //         console.log("else > if");
        //         setIsError({ ...isError, pstartdate: true });
        //     }
        //     // setState({ ...state, ppriceimportflag: true });
        //     // state.priceimportflag = true;
        // }


        // if ((state.charge === "" || state.charge === null) && isError.charge === false) {
        //     setIsError({ ...isError, charge: true });
        // }
        // if ((state.cstartdate === "" || state.cstartdate === null || state.cstartdate === 'Invalid date') && isError.cstartdate === false) {
        //     setIsError({ ...isError, cstartdate: true });
        // }

        // const { supplier_product_code,
        //     outer_case_barcode,
        //     price,
        //     pstartdate,
        //     charge,
        //     cstartdate
        // } = isError
        // // && !charge && !cstartdate state.charge, state.cstartdate,
        // if (!supplier_product_code && !outer_case_barcode && !price && !pstartdate ) {
        //     setState({ ...state, formValid: false })
        // } else {
        //     setState({ ...state, formValid: true })
        // }

    }, [state.price, state.pstartdate])

    useEffect(() => {
        // console.log("UseEffect Price Don't Import Check")
        if (state.priceimportflag === true) {
            setState({
                ...state, price: '', pstartdate: null, penddate: null, validprice: null, validpricestartdate: null,
                validpriceenddate: null, pricestartdatenullvalidated: true, priceenddatenullvalidated: true, pricenullvalidated: true
            });
            setIsError({ ...isError, pstartdate: false, price: false });
        }
        else {
            if ((state.price === "" || state.price === null) && isError.price === false && state.priceimportflag === false) {
                setIsError({ ...isError, price: true });
            }
            // if ((state.pstartdate === "" || state.pstartdate === null || state.pstartdate === 'Invalid date') && isError.pstartdate === false && state.priceimportflag === false) {
            //     setIsError({ ...isError, pstartdate: true });
            // }
        }

    }, [state.priceimportflag])

    useEffect(() => {
        if (state.chargeimportflag === true) {
            setState({
                ...state, charge: null, cstartdate: null, cenddate: null, validcharge: null, validcstartdate: null,
                validcenddate: null, chargestartdatenullvalidated: true, chargeenddatenullvalidated: true, chargenullvalidated: true
            });
            setIsError({ ...isError, cstartdate: false, charge: false });
        }
        else {
            if ((state.charge === "" || state.charge === null) && isError.charge === false && state.chargeimportflag === false) {
                setIsError({ ...isError, charge: false });
            }
            if ((state.cstartdate === "" || state.cstartdate === null || state.cstartdate === 'Invalid date') && isError.cstartdate === false && state.chargeimportflag === false) {
                setIsError({ ...isError, cstartdate: false });
            }
        }

    }, [state.chargeimportflag])

    useEffect(() => {

        // console.log("UseEffect Charge and Charge Date");
        if (state.charge === "" || state.charge === null) {
            setState({
                ...state, charge: null, cstartdate: null, cenddate: null, validcharge: null, validcstartdate: null,
                validcenddate: null, chargestartdatenullvalidated: true, chargeenddatenullvalidated: true, chargenullvalidated: true, chargeimportflag: true
            });
            setIsError({ ...isError, charge: false, cstartdate: false });
            state.cstartdate = null;
            state.cenddate = null;
            state.chargeimportflag = true;
        }
        else {
            if (state.cstartdate === "" || state.cstartdate === null || state.cstartdate === 'Invalid date') {
                setIsError({ ...isError, cstartdate: true });
            }
        }

        // if ((state.charge === "" || state.charge === null)) {
        //     state.cstartdate = null;
        //     state.cenddate = null;
        //     state.validcstartdate = null;
        //     state.validcenddate = null;
        //     //setState({...state, cstartdate: null, cenddate: null, validcstartdate: null, validcenddate: null })
        // }

        // if ((state.charge === "" || state.charge === null) && isError.charge === false && state.chargeimportflag === false) {
        //     setIsError({ ...isError, charge: true });
        // }
        // if ((state.cstartdate === "" || state.cstartdate === null || state.cstartdate === 'Invalid date') && isError.cstartdate === false && state.chargeimportflag === false) {
        //     setIsError({ ...isError, cstartdate: true });
        // }




    }, [state.charge, state.cstartdate])


    useEffect(() => {
        const { supplier_product_code, outer_case_barcode, price, pstartdate, charge, cstartdate } = isError;
        // console.log("UseEffect Error", supplier_product_code, outer_case_barcode, price, pstartdate, charge, cstartdate)
        if (!supplier_product_code && !outer_case_barcode && !price && !pstartdate && !charge && !cstartdate) {
            // console.log("If");
            setState({ ...state, formValid: false })
        } else {
            // console.log("Else");
            setState({ ...state, formValid: true })
        }
    }, [isError])



    const handleSubmit = async (event) => {

        event.preventDefault();
        if (state.price !== null && state.price !== "") {
            state.validprice = parseFloat(state.price).toFixed(4);
        }

        if (state.charge !== null && state.charge !== "") {
            state.validcharge = parseFloat(state.charge).toFixed(4);
        }

        if ((state.supplier_product_code === "" || state.supplier_product_code === null) && isError.supplier_product_code === false) {
            setIsError({ ...isError, supplier_product_code: true });
        } else if ((state.outer_case_barcode === "" || state.outer_case_barcode === null) && isError.outer_case_barcode === false) {
            setIsError({ ...isError, outer_case_barcode: true });
        } else {
            if ((state.pstartdate !== "" && state.pstartdate !== null && moment(state.pstartdate).format('DD/MM/YYYY') === 'Invalid date') || (state.penddate !== "" && state.penddate !== null && moment(state.penddate).format('DD/MM/YYYY') === 'Invalid date') || (state.cstartdate !== "" && state.cstartdate !== null && moment(state.cstartdate).format('DD/MM/YYYY') === 'Invalid date') || (state.cenddate !== "" && state.cenddate !== null && moment(state.cenddate).format('DD/MM/YYYY') === 'Invalid date')) {
                setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
            }
            else if (state.penddate !== null && state.penddate !== "" && (state.pstartdate > state.penddate)) {
                setSnackBar({ ...snackBar, show: true, text: "Price End date is less than Start Date!", type: "error", });
            }
            else if (state.cenddate !== null && state.cenddate !== "" && (state.cstartdate > state.cenddate)) {
                setSnackBar({ ...snackBar, show: true, text: "Charge End date is less than Start Date!", type: "error", });
            }
            else {
                try {
                    setState({ ...state, pageLoader: true });
                    const productUpdate = {

                        "id": state.id,
                        "version": state.version,
                        "result": "Success",
                        "errorMessage": "",
                        "unitOfWork": state.unitofwork,
                        "supplierProductCode": state.supplier_product_code,
                        "outerCaseBarcode": state.outer_case_barcode,
                        "productName": state.product_name,
                        "price": state.price ? Number(state.price).toFixed(2) : null,
                        "priceNullValidated": state.pricenullvalidated,
                        "priceStartDate": state.pstartdate ? moment(state.pstartdate).format('DD/MM/YYYY') : null,
                        "priceEndDate": state.penddate ? moment(state.penddate).format('DD/MM/YYYY') : null,
                        "priceStartDateNullValidated": state.pricestartdatenullvalidated,
                        "priceEndDateNullValidated": state.priceenddatenullvalidated,
                        "validPriceStartDate": state.validpricestartdate ? moment(state.validpricestartdate).format('DD/MM/YYYY') : null,
                        "validPrice": state.validprice || null,
                        "validPriceEndDate": state.validpriceenddate ? moment(state.validpriceenddate).format('DD/MM/YYYY') : null,
                        "alreadyExists": state.alreadyexists || true,
                        "charge": state.charge ? Number(state.charge).toFixed(2) : null,
                        "chargeNullValidated": state.chargenullvalidated,
                        "chargeStartDate": state.cstartdate ? moment(state.cstartdate).format('DD/MM/YYYY') : null,
                        "chargeEndDate": state.cenddate ? moment(state.cenddate).format('DD/MM/YYYY') : null,
                        "chargeStartDateNullValidated": state.chargestartdatenullvalidated,
                        "chargeEndDateNullValidated": state.chargeenddatenullvalidated,
                        "validChargeStartDate": state.validcstartdate ? moment(state.validcstartdate).format('DD/MM/YYYY') : null,
                        "validCharge": state.validcharge || null,
                        "validChargeEndDate": state.validcenddate ? moment(state.validcenddate).format('DD/MM/YYYY') : null,
                        "productValueLevyScheme": productvls.pvls_selected ? productvls.pvls_selected.id : null,
                        "productValueLevySchemeNullValidated": productvls.pvls_selected ? true : false,
                        "validProductValueLevyScheme": productvls.pvls_selected ? productvls.pvls_selected.id : null,
                        "distributionChargeLevyScheme": dcls.dcls_selected ? dcls.dcls_selected.id : null,
                        "distributionChargeLevySchemeNullValidated": dcls.dcls_selected ? true : false,
                        "validDistributionChargeLevyScheme": dcls.dcls_selected ? dcls.dcls_selected.id : null,
                        "unitOfMeasureId": unit_measure.measure_selected ? unit_measure.measure_selected.id : null,
                        "unitOfMeasureName": unit_measure.measure_selected ? unit_measure.measure_selected.name : null,
                        "validUnitOfMeasure": unit_measure.measure_selected ? unit_measure.measure_selected.id : null,
                        "unitOfMeasureNullValidated": unit_measure.measure_selected ? true : false
                    }


                    const { data } = await axios_pdc_file_upload.put(`pdcs/importedPdc/${state.id}`, productUpdate); // v2/products/importedProduct fileNameId
                    if (data) {
                        setState({ ...state, pageLoader: false })
                        if (onUpdate) {
                            onUpdate({ text: "PDC updated!", type: "success" })
                            handleClose()
                        }
                    }

                } catch (error) {
                    const { response: { data, status } } = error;
                    console.log(error);
                    if (!data) {
                        if (onUpdate) {
                            // onUpdate({ text: "System took long to respond, please retry!", type: "error" })
                            onUpdate({ text: "The ECS services are down at the moment.", type: "error" })                            
                            handleClose()
                        }
                    } else {
                        if (onUpdate) {
                            onUpdate({ text: data.message ? data.message : "Something went wrong!", type: "error" })
                            handleClose()
                        }
                    }
                    setState({ ...state, pageLoader: false })
                }
            }
        }
    }

    const handleSelection = (event, newValue, name) => {
        if (event.target.value !== "" || event.target.value !== null) {
            setState({ ...state, [name]: newValue })
        }
    }

    const handleCheckboxChange = (event) => {
        const { checked, name } = event.target;
        // console.log(event, checked, name);
        setState({ ...state, [name]: checked, defaultbutton: true })
    };

    const handlepStartDate = (event) => {
        if (event === null) {
            setIsError({ ...isError, pstartdate: true });
            setState({ ...state, pstartdate: null, validpricestartdate: null, defaultbutton: true });
        }
        else {
            setIsError({ ...isError, pstartdate: false });
            setState({ ...state, pstartdate: moment(event, 'DD/MM/YYYY'), validpricestartdate: moment(event, 'DD/MM/YYYY'), penddate: null, validpriceenddate: null, defaultbutton: true });
        }

    }
    const handlepEndDate = (event) => {
        if (event === null) {
            setState({ ...state, penddate: null, validpriceenddate: null, defaultbutton: true })
        }
        else {
            setState({ ...state, penddate: moment(event, 'DD/MM/YYYY'), validpriceenddate: moment(event, 'DD/MM/YYYY'), defaultbutton: true });
        }

    }

    const handlecStartDate = (event) => {
        if (event === null) {
            setIsError({ ...isError, cstartdate: true });
            setState({ ...state, cstartdate: null, validcstartdate: null, defaultbutton: true });
        }
        else {
            setIsError({ ...isError, cstartdate: false });
            setState({ ...state, cstartdate: moment(event, 'DD/MM/YYYY'), validcstartdate: moment(event, 'DD/MM/YYYY'), cenddate: null, validcenddate: null, defaultbutton: true });
        }
    }
    const handlecEndDate = (event) => {
        if (event === null) {
            setState({ ...state, cenddate: null, validcenddate: null, defaultbutton: true })
        }
        else {
            setState({ ...state, cenddate: moment(event, 'DD/MM/YYYY'), validcenddate: moment(event, 'DD/MM/YYYY'), defaultbutton: true })
        }

    }


    const addOCBC = (e) => {
        e.preventDefault()
        setState({ ...state, handleOCBC: true, defaultbutton: true })

    }

    const hideOCBCModal = (params, row) => {
        if (row) {
            setState({ ...state, handleOCBC: params === false ? params : true, product_name: row.name, outer_case_barcode: row.outerCaseBarcode });
        }
        else {
            setState({ ...state, handleOCBC: params === false ? params : true });
        }
    }


    const handleDecimal = (event) => {
        const { value, name } = event.target;
        const valueDecimal = value === '' ? "" : (value == 0 ? 0 : Number(value).toFixed(2))
        value.length === 0 ? setIsError({ ...isError, [name]: true }) : setIsError({ ...isError, [name]: false })
        setState({ ...state, [name]: valueDecimal })
    }

    const goTodaypsd = (event) => {        
        setState({ ...state, pstartdate: moment() });
    }

    const goTodayped = (event) => {        
        setState({ ...state, penddate: moment() });
    }

    const goTodaycsd = (event) => {        
        setState({ ...state, cstartdate: moment() });
    }

    const goTodayced = (event) => {        
        setState({ ...state, cenddate: moment() });
    }

    const CustomActionbarpsd = () => {

        return (
            <Box sx={{ marginTop: '-15px', padding: '0 0 10px 0', textAlign: 'center' }}>
                <Button sx={{ fontSize: '16px' }} onClick={(e) => goTodaypsd(e)}>Today</Button>
            </Box>
        );
    };

    const CustomActionbarped = () => {

        return (
            <Box sx={{ marginTop: '-15px', padding: '0 0 10px 0', textAlign: 'center' }}>
                <Button sx={{ fontSize: '16px' }} onClick={(e) => goTodayped(e)}>Today</Button>
            </Box>
        );
    };

    const CustomActionbarcsd = () => {

        return (
            <Box sx={{ marginTop: '-15px', padding: '0 0 10px 0', textAlign: 'center' }}>
                <Button sx={{ fontSize: '16px' }} onClick={(e) => goTodaycsd(e)}>Today</Button>
            </Box>
        );
    };

    const CustomActionbarced = () => {

        return (
            <Box sx={{ marginTop: '-15px', padding: '0 0 10px 0', textAlign: 'center' }}>
                <Button sx={{ fontSize: '16px' }} onClick={(e) => goTodayced(e)}>Today</Button>
            </Box>
        );
    };

    // const CommonActionbar = (props) => {
    //     const { objectLocation } = props;
    //     console.log(props, parent);
    //     return (
    //         <>
    //             <Box sx={{ marginTop: '-15px', padding: '0 0 10px 0', textAlign: 'center' }}>
    //                 <Button sx={{ fontSize: '16px' }} onClick={(e) => goToday(e)}>Today</Button>
    //             </Box>
    //         </>
    //     );
    // };

    return (
        <>
            <Modal
                open={onShow || false}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <Box component="div" className="overlay" id="addEditModal">
                    {state.pageLoader && <PageLoader />}
                    <div className="overlay-box">
                        <div className="content">
                            <div className="overlayBody">
                                <div className="overlay-header text-center"> Edit PDC Details
                                    <CancelIcon className="closeBtn" onClick={handleClose} />
                                </div>
                                <div className="overlay-content">
                                    <Box sx={{ flexGrow: 1, paddingX: 2 }} >
                                        <Grid container columnSpacing={2}>
                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 0.8 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="general_outer_case_barcode" className="fontSemiBold">Supplier</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Item>
                                                                <div className="input-group">
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            inputProps={{ maxLength: 100 }}
                                                                            size="small"
                                                                            // id="general_outer_case_barcode"
                                                                            // aria-describedby="general_outer_case_barcode_error"
                                                                            // onChange={(e) => handleInputChange(e)}
                                                                            value={predata.supplier.name}
                                                                            // name="general_outer_case_barcode"
                                                                            disabled={true}
                                                                        />
                                                                    </FormControl>
                                                                </div>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 0.8 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={3} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="specification" className="fontSemiBold">Distribution Channel</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Item>
                                                                <div className="input-group">
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            inputProps={{ maxLength: 100 }}
                                                                            size="small"
                                                                            // id="specification"
                                                                            aria-describedby="specification_error"
                                                                            // onChange={(e) => handleInputChange(e)}
                                                                            // name="specification"
                                                                            value={predata.distributionChannel.name}
                                                                            disabled={true}

                                                                        />
                                                                    </FormControl>
                                                                </div>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        <Grid container columnSpacing={2}>

                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 1.5, alignSelf: 'center' }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.1} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="general_product_name" className="fontSemiBold">&nbsp;</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={7.4}>
                                                            <Item>
                                                                <Grid container direction="row" alignItems="center">
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox size="medium"
                                                                                // id="general_export_td"
                                                                                checked={predata.promo}
                                                                                // onChange={(e) => handleCheckboxChange(e)}
                                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                                // name="general_export_td"
                                                                                disabled={true}
                                                                            />}
                                                                        label="Promo" />
                                                                </Grid>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        <Grid container columnSpacing={2}>
                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 3 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="supplier_product_code" className="fontSemiBold mandatory">Supplier Product Code</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Item>
                                                                <div className="input-group">
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            inputProps={{ maxLength: 100 }}
                                                                            size="small"
                                                                            id="supplier_product_code"
                                                                            aria-describedby="supplier_product_code_error"
                                                                            onChange={(e) => handleInputChange(e)}
                                                                            value={state.supplier_product_code}
                                                                            name="supplier_product_code"
                                                                            error={isError.supplier_product_code}
                                                                        />
                                                                        {isError.supplier_product_code && (<FormHelperText className='errorHelperTxt' id="supplier_product_code_error">
                                                                            Please Enter Supplier Product Code
                                                                        </FormHelperText>)}
                                                                    </FormControl>

                                                                </div>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 3 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={3} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="outer_case_barcode" className="fontSemiBold mandatory">Outer case barcode</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Item>
                                                                <Box component="div" className=''>
                                                                    <div className="input-group">
                                                                        <FormControl fullWidth>
                                                                            <TextField
                                                                                inputProps={{ maxLength: 100 }}
                                                                                size="small"
                                                                                id="outer_case_barcode"
                                                                                aria-describedby="outer_case_barcode_error"
                                                                                onChange={(e) => handleOCBCInputChange(e)}
                                                                                value={state.outer_case_barcode}
                                                                                name="outer_case_barcode"
                                                                                error={isError.outer_case_barcode}
                                                                                disabled={true}
                                                                            />
                                                                            {isError.outer_case_barcode && (<FormHelperText className='errorHelperTxt' id="outer_case_barcode_error">
                                                                                Please Enter Outercase Barcode
                                                                            </FormHelperText>)}
                                                                        </FormControl>
                                                                    </div>
                                                                </Box>


                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={1}>
                                                            <Item>
                                                                {/* <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0, marginRight: '5px' }} startIcon={<ClearIcon />}></Button> */}
                                                                <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={(e) => { addOCBC(e) }} startIcon={<EditIcon />}></Button>
                                                            </Item>
                                                        </Grid>
                                                        {/* onClick={resetFilter} onClick={(e) => CommonPopUpModal(e)}  */}
                                                        {
                                                            state.handleOCBC && <CommonPopUp
                                                                onShow={state.handleOCBC}
                                                                size="large_overlay"
                                                                outercaseBarCode={{
                                                                    title: 'Outercase Barcode',
                                                                    ocbc: state.outer_case_barcode || null
                                                                }}
                                                                onHide={hideOCBCModal}
                                                            />
                                                        }

                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        <Grid container columnSpacing={2}>
                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 3 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="product_name" className="fontSemiBold">Produt Name</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Item>
                                                                <div className="input-group">
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            inputProps={{ maxLength: 100 }}
                                                                            size="small"
                                                                            id="product_name"
                                                                            aria-describedby="product_name_error"
                                                                            onChange={(e) => handleInputChange(e)}
                                                                            value={state.product_name}
                                                                            name="product_name"
                                                                            disabled={true}
                                                                        />
                                                                    </FormControl>

                                                                </div>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 3 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={3} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="price" className="fontSemiBold mandatory">Price</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Item>
                                                                <div className="input-group">
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            inputProps={{ maxLength: 100 }}
                                                                            size="small"
                                                                            id="price"
                                                                            aria-describedby="casePrice_error"
                                                                            onChange={(e) => handleInputChange(e)}
                                                                            name="price"
                                                                            value={state.price}
                                                                            error={isError.price}
                                                                            disabled={state.priceimportflag}
                                                                            type="number"
                                                                            step="any"
                                                                            onBlur={(e) => handleDecimal(e)}
                                                                            onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                                                        />
                                                                        {isError.price && (<FormHelperText className='errorHelperTxt' id="casePrice_error">
                                                                            Please Enter Price
                                                                        </FormHelperText>)}
                                                                    </FormControl>
                                                                </div>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        <Grid container columnSpacing={2}>
                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="pstartDate" className="fontSemiBold mandatory">Price From</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Item>
                                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                    <DesktopDatePicker
                                                                        value={state.pstartdate}
                                                                        components={{ ActionBar: CustomActionbarpsd }}
                                                                        // components={{
                                                                        //     ActionBar: CommonActionbar
                                                                        // }}
                                                                        onChange={(e) => { handlepStartDate(e) }}
                                                                        name="pstartDate"
                                                                        inputFormat="DD/MM/YYYY"
                                                                        disabled={state.priceimportflag}
                                                                        disablePast
                                                                        shouldDisableDate={(dateParam) => {
                                                                            //your_condition here
                                                                            //return true to disabled and false to enable
                                                                            const fromFormatted = moment().format('DD/MM/YYYY');
                                                                            const currentDate = moment(dateParam).format('DD/MM/YYYY');
                                                                            return fromFormatted === currentDate ? true : false;
                                                                        }}
                                                                        // eslint-disable-next-line max-len
                                                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                            <Box component="div" className='date-picker-input'>
                                                                                <FormControl size={'small'} fullWidth>
                                                                                    <TextField size="small" id="pstartDate"
                                                                                        ref={inputRef} {...inputProps}
                                                                                        placeholder=""
                                                                                        aria-describedby="pstartDate_error"
                                                                                        error={isError.pstartdate}
                                                                                        autoComplete="off"
                                                                                    />
                                                                                    {isError.pstartdate && (<FormHelperText className='errorHelperTxt' id="pstartDate_error">
                                                                                        Select a valid Price Start Date
                                                                                    </FormHelperText>)}
                                                                                    <Box className='date-picker-icon'>
                                                                                        {InputProps?.endAdornment}
                                                                                    </Box>
                                                                                </FormControl>
                                                                            </Box>
                                                                        )}

                                                                    />

                                                                </LocalizationProvider>

                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={3} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="pendDate" className="fontSemiBold">End Date</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Item>
                                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                    <DesktopDatePicker
                                                                        value={state.penddate}
                                                                        components={{ ActionBar: CustomActionbarped }}
                                                                        onChange={(e) => { handlepEndDate(e) }}
                                                                        name="pendDate"
                                                                        inputFormat="DD/MM/YYYY"
                                                                        disabled={state.priceimportflag}
                                                                        minDate={moment(state.pstartdate, 'DD/MM/YYYY').add(1, 'days')}
                                                                        // eslint-disable-next-line max-len
                                                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                            <Box component="div" className='date-picker-input'>
                                                                                <FormControl size={'small'} fullWidth>
                                                                                    <TextField size="small" id="pendDate"
                                                                                        ref={inputRef} {...inputProps}
                                                                                        placeholder=""
                                                                                        aria-describedby="endDate_error"
                                                                                        autoComplete="off"
                                                                                    />
                                                                                    <Box className='date-picker-icon'>
                                                                                        {InputProps?.endAdornment}
                                                                                    </Box>
                                                                                </FormControl>
                                                                            </Box>
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        <Grid container columnSpacing={2}>

                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 1, alignSelf: 'center' }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.1} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="priceimportflag" className="fontSemiBold">&nbsp;</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={7.5}>
                                                            <Item>
                                                                <Grid container direction="row" alignItems="center">
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox size="medium"
                                                                                id="priceimportflag"
                                                                                checked={state.priceimportflag}
                                                                                onChange={(e) => handleCheckboxChange(e)}
                                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                                name="priceimportflag"

                                                                            />}
                                                                        label="Don't import price" />
                                                                </Grid>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        <Grid container columnSpacing={2}>

                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5, alignSelf: 'center' }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="charge" className="fontSemiBold">Charge</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Item>
                                                                <div className="input-group">
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            inputProps={{ maxLength: 100 }}
                                                                            size="small"
                                                                            id="charge"
                                                                            aria-describedby="charge_error"
                                                                            onChange={(e) => handleInputChange(e)}
                                                                            name="charge"
                                                                            value={state.charge || ''}
                                                                            disabled={state.chargeimportflag}
                                                                            error={isError.charge}
                                                                            type="number"
                                                                            step="any"
                                                                            onBlur={(e) => handleDecimal(e)}
                                                                            onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                                                        />
                                                                        {isError.charge && (<FormHelperText className='errorHelperTxt' id="charge_error">
                                                                            Please enter Charge value
                                                                        </FormHelperText>)}
                                                                    </FormControl>
                                                                </div>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        <Grid container columnSpacing={2}>
                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="cstartDate" className="fontSemiBold">Charge From</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Item>
                                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                    <DesktopDatePicker
                                                                        value={state.cstartdate}
                                                                        components={{ ActionBar: CustomActionbarcsd }}
                                                                        onChange={(e) => { handlecStartDate(e) }}
                                                                        disabled={state.chargeimportflag}
                                                                        name="cstartDate"
                                                                        inputFormat="DD/MM/YYYY"
                                                                        disablePast
                                                                        shouldDisableDate={(dateParam) => {
                                                                            //your_condition here
                                                                            //return true to disabled and false to enable
                                                                            const fromFormatted = moment().format('DD/MM/YYYY');
                                                                            const currentDate = moment(dateParam).format('DD/MM/YYYY');

                                                                            return fromFormatted === currentDate ? true : false;
                                                                        }}
                                                                        // eslint-disable-next-line max-len
                                                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                            <Box component="div" className='date-picker-input'>
                                                                                <FormControl size={'small'} fullWidth>
                                                                                    <TextField size="small" id="cstartDate"
                                                                                        ref={inputRef} {...inputProps}
                                                                                        placeholder=""
                                                                                        aria-describedby="cstartDate_error"
                                                                                        error={isError.cstartdate}
                                                                                        autoComplete="off"
                                                                                    />
                                                                                    {isError.cstartdate && (<FormHelperText className='errorHelperTxt' id="cstartDate_error">
                                                                                        Select a valid Charge Start Date
                                                                                    </FormHelperText>)}
                                                                                    <Box className='date-picker-icon'>
                                                                                        {InputProps?.endAdornment}
                                                                                    </Box>
                                                                                </FormControl>
                                                                            </Box>
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={3} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="cendDate" className="fontSemiBold">End Date</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Item>
                                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                    <DesktopDatePicker
                                                                        value={state.cenddate}
                                                                        onChange={(e) => { handlecEndDate(e) }}
                                                                        components={{ ActionBar: CustomActionbarced }}
                                                                        name="cendDate"
                                                                        inputFormat="DD/MM/YYYY"
                                                                        minDate={moment(state.cstartdate, 'DD/MM/YYYY').add(1, 'days')}
                                                                        disabled={state.chargeimportflag}
                                                                        // eslint-disable-next-line max-len
                                                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                            <Box component="div" className='date-picker-input'>
                                                                                <FormControl size={'small'} fullWidth>
                                                                                    <TextField size="small" id="cendDate"
                                                                                        ref={inputRef} {...inputProps}
                                                                                        placeholder=""
                                                                                        aria-describedby="endDate_error"
                                                                                        autoComplete="off"

                                                                                    />
                                                                                    <Box className='date-picker-icon'>
                                                                                        {InputProps?.endAdornment}
                                                                                    </Box>
                                                                                </FormControl>
                                                                            </Box>
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        <Grid container columnSpacing={2}>

                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 1, alignSelf: 'center' }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.1} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="chargeimportflag" className="fontSemiBold">&nbsp;</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={7.5}>
                                                            <Item>
                                                                <Grid container direction="row" alignItems="center">
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox size="medium"
                                                                                id="chargeimportflag"
                                                                                checked={state.chargeimportflag}
                                                                                onChange={(e) => handleCheckboxChange(e)}
                                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                                name="chargeimportflag"

                                                                            />}
                                                                        label="Don't import charge price" />
                                                                </Grid>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>

                                        <Grid container columnSpacing={2}>

                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2, alignSelf: 'center' }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="general_unit_measure" className="fontSemiBold">Unit of Measure</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Item>
                                                                <Autocomplete
                                                                    value={unit_measure.measure_selected ? unit_measure.measure_selected : null}
                                                                    id="general_unit_measure_search"
                                                                    options={unit_measure.measure_options}
                                                                    getOptionLabel={(option) => option.name || ""}
                                                                    componentsProps={{
                                                                        paper: {
                                                                            sx: {
                                                                                fontSize: '0.85rem'
                                                                            }
                                                                        }
                                                                    }}
                                                                    onChange={(event, newValue) => {
                                                                        setState({ ...state, defaultbutton: true, formValid: false });
                                                                        if (event.target.value !== "" || event.target.value !== null) {
                                                                            setUnit_measure({ ...unit_measure, measure_selected: newValue })
                                                                        }
                                                                    }}
                                                                    renderInput={(params) =>
                                                                        <FormControl size={'small'} fullWidth>
                                                                            <div className="input-group">
                                                                                <TextField {...params}
                                                                                    size="small"
                                                                                    id="general_unit_measure"
                                                                                    aria-describedby="general_unit_measure-error"
                                                                                />
                                                                            </div>
                                                                        </FormControl>
                                                                    }
                                                                />
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>





                                        <Grid container columnSpacing={2}>
                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="productvalue_levy_scheme" className="fontSemiBold">Product Value Levy Scheme</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Item>
                                                                <Autocomplete
                                                                    value={productvls.pvls_selected ? productvls.pvls_selected : null}
                                                                    id="productvalue_levy_scheme_search"
                                                                    options={productvls.pvls_options}
                                                                    getOptionLabel={(option) => option.name || ""}
                                                                    componentsProps={{
                                                                        paper: {
                                                                            sx: {
                                                                                fontSize: '0.85rem'
                                                                            }
                                                                        }
                                                                    }}
                                                                    onChange={(event, newValue) => {
                                                                        setState({ ...state, defaultbutton: true, formValid: false });
                                                                        if (event.target.value !== "" || event.target.value !== null) {
                                                                            setProductvls({ ...productvls, pvls_selected: newValue })
                                                                        }
                                                                    }}
                                                                    renderInput={(params) =>
                                                                        <FormControl size={'small'} fullWidth>
                                                                            <div className="input-group">
                                                                                <TextField {...params}
                                                                                    size="small"
                                                                                    id="product_value_levy_scheme"
                                                                                    aria-describedby="product_value_levy_scheme-error"
                                                                                />
                                                                            </div>
                                                                        </FormControl>
                                                                    }
                                                                />
                                                            </Item>


                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={3} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="dc_levy_scheme_search" className="fontSemiBold">Distribution Charge Levy Scheme</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <Item>
                                                                <Autocomplete
                                                                    value={dcls.dcls_selected ? dcls.dcls_selected : null}
                                                                    id="dc_levy_scheme"
                                                                    options={dcls.dcls_options}
                                                                    getOptionLabel={(option) => option.name || ""}
                                                                    componentsProps={{
                                                                        paper: {
                                                                            sx: {
                                                                                fontSize: '0.85rem'
                                                                            }
                                                                        }
                                                                    }}
                                                                    onChange={(event, newValue) => {
                                                                        setState({ ...state, defaultbutton: true, formValid: false });
                                                                        if (event.target.value !== "" || event.target.value !== null) {
                                                                            setDCls({ ...dcls, dcls_selected: newValue })
                                                                        }
                                                                    }}
                                                                    renderInput={(params) =>
                                                                        <FormControl size={'small'} fullWidth>
                                                                            <div className="input-group">
                                                                                <TextField {...params}
                                                                                    size="small"
                                                                                    id="dc_levy_scheme_search"
                                                                                    aria-describedby="dc_levy_scheme-error"
                                                                                />
                                                                            </div>
                                                                        </FormControl>
                                                                    }
                                                                />
                                                            </Item>


                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>



                                        <Grid container sx={{ marginTop: '10px' }}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Item>
                                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                                        <LoadingButton
                                                            onClick={(e) => { handleSubmit(e) }}
                                                            loading={state.loader}
                                                            loadingPosition="center"
                                                            endIcon=""
                                                            variant="contained"
                                                            type="submit" sx={{ marginRight: '15px' }}
                                                            //disabled={state.formValid || state.defaultbutton === false}
                                                            disabled={state.formValid}
                                                        >
                                                            Save &amp; Validate
                                                        </LoadingButton>
                                                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div>
                    {snackBar.show && <SnackBarDialog
                        onShow={snackBar.show}
                        text={snackBar.text}
                        type={snackBar.type}
                        onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                    />}
                </Box>
            </Modal>
        </>
    );
}
