import { Container, Box, Modal, Button, Grid, Checkbox, Popper, Grow, Paper, ClickAwayListener, MenuList, ButtonGroup } from '@mui/material'
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TableFilters } from "../../components/shared/data-table/TableFilters";
import { useEffect, useState } from "react";
import { InputText } from "../../components/shared/form/InputText";
import { } from '@mui/material';
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange, filterResetFlagChange } from "../../store/slices/externalFilter";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import AddIcon from '@mui/icons-material/Add';
import { warningModal, handleAddEditModal } from "../../store/slices/modals";
import WarningModal from "../../components/shared/modals/WarningModal";
import axiosRetroDiscountSchemes from '../../services/utility/axios-retro-discount-schemes';
import { setRowPage, resetDataGrid, setPageNo } from '../../store/slices/dataGrid';
import { useLayoutEffect } from "react";
import SnackBarDialog from "../../components/shared/SnackBarDialog";
import PageLoader from "../../components/shared/PageLoader";
import { DataTableRetroDiscountSchemes } from "../../components/shared/data-table/DataTableRetroDiscountSchemes";
import { setHasEditData, promotionTypeInput, setDeleteList, setpromotionTypeDeleteData } from "../../store/slices/promotionType";
import AddEditPromotionTypeDetail from "./AddEditPromotionType/AddEditPromotionTypeDetail";

const IconMenuLink = ({ params, onDelete }) => {
    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { showAddEditModal, promotionType_container } = useSelector((state) => {
        return {
            showAddEditModal: state.modalActions.hideAddEditModal,
            editTabData: state.promotionTypes.hasEditData,
            promotionType_container: state.promotionTypes
        }
    });
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'edit_promotion_type') {
            dispatch(handleAddEditModal({
                showAddEdit: !showAddEditModal
            }))
            setEditPDClData(row);
        } else if (action === 'delete_promotion_type') {
            onDelete(row)
        }
        setAnchorEl(null);
    };
    const setEditPDClData = async (row) => {
        
        dispatch(setHasEditData(row))
        dispatch(promotionTypeInput({
            key: 'add_edit_promotion_type',
            value: row.name
        }))
    }
    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'edit_promotion_type')}>Edit</MenuItem>
            <MenuItem onClick={(e) => handleClose(e, 'delete_promotion_type')}>Delete</MenuItem>

        </Menu>
    </>)
}
function PromotionType() {
    
    const columns = [
        {
            headerName: "ID",
            field: "id",
            flex: 1,
            minWidth: 200
        },
        {
            headerName: "Promotion Type",
            field: "name",
            flex: 1,
            width: 170
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} onDelete={deletePromotionTypeData} />;
            }
        }
    ];

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })
   
    const [state, setState] = useState({
        openpromotionTypeModal: false,
        pageLoader: false,
        defaultGrid: {
            pagetitle: "Promotion Type",
            pdcActionButton: true,
            hasExport: true,
            addEditButton: true,
            apiUrl: 'retrodiscount/promotionTypes/filter',
            searchUrl: 'retrodiscount/promotionTypes/filter'
        }
    });


    const { promotionType_container, promotion_type_name, addProductwarning, showAddEditModal } = useSelector((state) => {
        return {
            promotionType_container: state.promotionTypes,
            promotion_type_name: state.externalFilter.promotion_type_name,
            resetTable: state.dataGridTable,
            addProductwarning: state.modalActions.closeWaringAddProduct,
            showAddEditModal: state.modalActions.hideAddEditModal,
        }
    });

    const dispatch = useDispatch();

    const handleFilterInput = (value, id) => {
        //setState({ ...state, [id]: value });
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    };

    const filterBtn = () => {
        dispatch(resetDataGrid());
        dispatch(applyFilter({
            key: 'filterList',
            value: {
                "name": promotion_type_name
            }
        }))
        dispatch(filterFlagChange(true));
        dispatch(filterResetFlagChange(false));
        dispatch(setRowPage({ pageData: 10 }));
        dispatch(setPageNo({ page: 1 }));
    }
    const resetFilter = () => {
        if (promotion_type_name !== '') {
            dispatch(resetExternalFilter())
            dispatch(resetDataGrid());
        }
            dispatch(filterResetFlagChange(true));
    }
    const addeditPromotionTypeModal = () => {
        dispatch(handleAddEditModal({
            showAddEdit: !showAddEditModal
        }))
    }
    const hideNewPromotionTypeModal = (event) => {
        setState({ ...state, openpromotionTypeModal: false })
    } 

    const deletePromotionTypeData = (row) => {
        dispatch(setDeleteList(row))
        dispatch(setpromotionTypeDeleteData({
            key: 'promotion_typeStartSingleDelete',
            value: true
        }))
        dispatch(warningModal({
            yesHide: !addProductwarning
        }))
    }

    useEffect(() => {
        if (promotionType_container.promotion_typeConfirmSingleDelete) {
            deleteSingleRow()
        }
    }, [promotionType_container.promotion_typeConfirmSingleDelete])


    const deleteSingleRow = async () => {
        if (promotionType_container.deleteList !== null) {
            try {
                setState({ ...state, pageLoader: true })
                const { data } = await axiosRetroDiscountSchemes.delete(`retrodiscount/promotionTypes/${promotionType_container.deleteList.id}`);
                if (data.success) {
                    resetSingleDeleteRowDispatch();
                    setState({ ...state, pageLoader: false });
                    setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Promotion Type Delete Successfully", type: "success" });
                    setTimeout(() => {
                        // window.location.reload();
                        dispatch(filterFlagChange(true));
                        dispatch(filterResetFlagChange(false));
                    }, 1500);
                    // try {
                    //     const { data } = await axiosRetroDiscountSchemes.get(`retrodiscount/promotionTypes`);
                    //     if (data !== null || data !== undefined) {
                    //         setState({ ...state, pageLoader: false })
                    //         setSnackBar({ ...snackBar, show: true, text: "Promotion Type Delete Successfully", type: "success" })
                    //         dispatch(setDeleteList(null))
                    //         resetSingleDeleteRowDispatch()
                    //         setTimeout(() => {
                    //             window.location.reload();
                    //         }, 2000);
                    //     }
                    // } catch (error) {
                    //     const { response: { data, status } } = error;
                    //     if (status === 500 && data.errorType === "Business") {
                    //         setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" });
                    //     } else {
                    //         setSnackBar({ ...snackBar, show: true, text: "Something went wrong!", type: "error", })
                    //     }
                    //     setState({ ...state, pageLoader: false })
                    //     resetSingleDeleteRowDispatch()
                    // }
                }
            } catch (error) {
                const { response: { data, status } } = error;
                if (status === 500 && data.errorType === "Business") {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" });
                } else {
                    setSnackBar({ ...snackBar, show: true, text: "Something went wrong!", type: "error", })
                }
                setState({ ...state, pageLoader: false })
                resetSingleDeleteRowDispatch()
            }
        }
    }

    const resetSingleDeleteRowDispatch = () => {
        dispatch(setpromotionTypeDeleteData({
            key: 'promotion_typeStartSingleDelete',
            value: false
        }))
        dispatch(setpromotionTypeDeleteData({
            key: 'promotion_typeConfirmSingleDelete',
            value: false
        }))
    }

    useEffect(() => {
        /* load first first time */
        filterBtn();
    }, []);

    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Promotion Type" />
                <Box sx={{ marginBottom: 2.5 }}>
                    <TableFilters
                        filterFields={<>
                            <Box component="div"><InputText onChange={handleFilterInput} maxWidth={50} label="Name" id="promotion_type_name" size="small" /></Box>
                        </>}
                        filterBtns={
                            <>
                                <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                <Button variant="contained" onClick={filterBtn}>Apply</Button>
                            </>}>
                    </TableFilters>
                </Box>
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -2.4 }  }}>
                    {/* <div className='table-actionable'>
                        <Button variant="contained" color="secondary" size='small' startIcon={<SendIcon />}>
                            Export as Excel
                        </Button>
                    </div> */}
                    <div className='table-actionable'>
                        <Button variant="contained" size='small' onClick={(e) => addeditPromotionTypeModal(e)} startIcon={<AddIcon />}>New Promotion Type</Button>
                    </div>
                </Box>
                <DataTableRetroDiscountSchemes defaultGrid={state.defaultGrid} columns={columns}>

                </DataTableRetroDiscountSchemes>
                {
                    showAddEditModal && (
                        <AddEditPromotionTypeDetail
                            onShow={showAddEditModal} size="medium_overlay"
                            onHide={(e, reason) => hideNewPromotionTypeModal(e, reason)}

                        />
                    )
                }
                {
                    addProductwarning && (
                        <WarningModal size="small_overlay" onShow={addProductwarning} />
                    )
                }
                {snackBar.show && <SnackBarDialog
                    onShow={snackBar.show}
                    text={snackBar.text}
                    type={snackBar.type}
                    onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                />}
            </Container>

        </>
    );
}

export default PromotionType;
