import { Container, Box, Modal, Button, Grid, Checkbox, Popper, Grow, Paper, ClickAwayListener, MenuList, ButtonGroup, FormLabel, FormControl, TextField, FormControlLabel, Autocomplete, FormHelperText } from '@mui/material'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';

function EditMember() {
    const [state, setState] = useState({
        pageLoader: false,
        active_product: false,
        openNewProductModal: false,
        defaultGrid: {
            productActionButton: true,
            hasExport: true,
            addEditButton: true,
            apiUrl: 'products',
            searchUrl: 'products'
        }
    });

    const { memberName, activeMember, addProductwarning, showAddEditModal, general_product_fields, handleWarning, member_fields } = useSelector((state) => {
        return {
            activeMember: state.externalFilter.active_member,
            resetTable: state.dataGridTable,
            filterFlag: state.externalFilter.filterApplied,
            addProductwarning: state.modalActions.closeWaringAddProduct,
            showAddEditModal: state.modalActions.hideAddEditModal,
            handleWarning: state.modalActions.closeWaringAddProduct,
            editTabData: state.generalProduct.editTabData,
            general_product_fields: state.generalProduct,
            member_fields: state.memberBclValues
        }
    });

    // (
    //     <>

    //         {/* <MemberDetails /> */}
    //     </>

    // );


    return <Container disableGutters={true} maxWidth={false} >
        <TextField

key="member_name"
inputProps={{ maxLength: 200 }}
size="small"
id="member_name"
aria-describedby="member_name_error"
// onChange={(e) => handleInputChange(e)}
defaultvalue={member_fields.member_name}
// error={member_fields.isError.member_name}
name="member_name"
// onBlur={(e) => checkQunique(e, 'isProductNameValid?productName')}
/>
        <FormControl fullWidth>
            
            {/* {member_fields.isError.member_name && (<FormHelperText className='errorHelperTxt' id="member_name_error">
                Please enter unique value
            </FormHelperText>)} */}
        </FormControl>

    </Container >
    //  (
    //     <>

    //         {state.pageLoader && <PageLoader />}
    //         <Container disableGutters={true} maxWidth={false}>
    //             <BreadcrumbNavs pageTitle="Products" />

    //             <MemberDetails />
    //             <DataTable defaultGrid={state.defaultGrid} columns={columns}>

    //             </DataTable>

    //         </Container>

    //     </>
    // );
}

export default EditMember;
