import { Box, Paper, Grid, Button } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setEditSupplierProductCode, setSupplierProductCodeList, setSupplierProductCodeInput, setDeleteList, setPdcSpcDeleteData } from "../../../../store/slices/generalPdcProduct";
import { warningModal, setSupplierProductCodeModal, handleAddEditPdcPriceModal } from "../../../../store/slices/modals";
import { DataTableClientSide } from '../../../../components/shared/data-table/DataTableClientSide';
import AddIcon from '@mui/icons-material/Add';
import AddEditSupplierProductCode from './supplierproductcode/AddEditSupplierProductCode';
import axiosProductDistribution from '../../../../services/utility/axios-product-distribution';
import SnackBarDialog from '../../../../components/shared/SnackBarDialog';
import PageLoader from "../../../../components/shared/PageLoader";

const IconMenuLink = ({ params, onDelete }) => {
    const { row } = params;
    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { showAddEditModal, pdc_general_product_fields } = useSelector((state) => {
        return {
            showAddEditModal: state.modalActions.hideAddEditModal,
            editTabData: state.generalPdcProducts.editTabData,
            pdc_general_product_fields: state.generalPdcProducts
        }
    });
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'edit') {
            dispatch(setEditSupplierProductCode(row))
            dispatch(setSupplierProductCodeInput(row.code))
            dispatch(setSupplierProductCodeModal(true))
        } else if (action === 'delete') {
            if(onDelete){
                onDelete(row)
            }
        }
        setAnchorEl(null);
    };

    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'edit')}>Edit</MenuItem>
            <MenuItem onClick={(e) => handleClose(e, 'delete')}>Delete</MenuItem>
        </Menu>
        
    </>)
}

const defaultValues = {
    pageLoader: false,
    defaultGrid: {
        pagehead: 'PDC',
        eanActionButtons: true,
        paginationMode: 'client',
    },
    addSupplierProductCode: false
};
export default function SupplierProductCode() {
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })
    const [state, setState] = useState(defaultValues);
    const [columns, setColumns] = useState([]);

    const allColumns = [
        {
            headerName: "Id",
            field: "id",
            flex: 1,
            hide: true
        },
        {
            headerName: "Code",
            field: "code",
            flex: 1,
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} onDelete={deleteSupplierProductCode} />;
            },
        }
    ];
    const { getSupplierProductCode, showSupplierProductCodeModal, editTabData, hasEditData, pdc_general_product_fields, handleWarning} = useSelector((state) => {
        return {
            showSupplierProductCodeModal: state.modalActions.supplierProductCodeModal,
            getSupplierProductCode: state.generalPdcProducts.pdc_supplierCode,
            editTabData: state.generalPdcProducts.editTabData,
            hasEditData: state.generalPdcProducts.hasEditData,
            pdc_general_product_fields: state.generalPdcProducts,
            handleWarning: state.modalActions.closeWaringAddProduct
        }
    });
    const dispatch = useDispatch();
    const addSupplierProductCodeModal = (event) => {
        dispatch(setSupplierProductCodeModal(true))
    }
    const hideAddEditEanModal = (event) => {
        dispatch(setSupplierProductCodeModal(event))
    }

    const allSupplierCodelists = async () => {
        try { 
            setState({ ...state, pageLoader: true })
            setTimeout(() => {
                setColumns(allColumns)
            }, 100);
            const id = editTabData ? editTabData.id : hasEditData.id;
            const { data } = await axiosProductDistribution.get(`pdcs/${id}/supplierProductCodes`);
            if (data) {
                setState({ ...state, pageLoader: false })
                dispatch(setSupplierProductCodeList(data.items))
            }
        } catch (error) {
            setState({ ...state, pageLoader: false })
            const { response: { data, status } } = error;
            if (data.message !== "No SupplierProductCodes found") {
                setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: data.message ? "warning" : "error" })
            }
        }
    }
    useEffect(() => {
        allSupplierCodelists();
    }, []);


    const deleteSupplierProductCode = (row) => {
        dispatch(setDeleteList(row))
        dispatch(setPdcSpcDeleteData({
            key: 'spcStartSingleDelete',
            value: true
        }))
        dispatch(warningModal({
            yesHide: !handleWarning
        }))
    }

    useEffect(() => {
        if (pdc_general_product_fields.spcConfirmSingleDelete) {
            deletePdcSingleRow()
        }
    }, [pdc_general_product_fields.spcConfirmSingleDelete])


    const deletePdcSingleRow = async () => {
        if (pdc_general_product_fields.deleteList !== null) {
            try {
                setState({ ...state, pageLoader: true })
                const id = editTabData ? editTabData.id : hasEditData.id;
                const { data } = await axiosProductDistribution.delete(`pdcs/${id}/supplierProductCodes/${pdc_general_product_fields.deleteList.id}`);
                if (data.success) {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
                    const filteredRows = getSupplierProductCode.filter(uniqueId => {
                        if (uniqueId.id !== pdc_general_product_fields.deleteList.id) {
                            return uniqueId;
                        }
                    })
                    setState({ ...state, pageLoader: false })
                    dispatch(setSupplierProductCodeList(filteredRows))
                    dispatch(setDeleteList(null))
                    spcSingleDeleteRowDispatch()
                }

            } catch (error) {
                const { response: { data, status } } = error;
                if (status === 500 && data.errorType === "Business") {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" });
                } else {
                    setSnackBar({ ...snackBar, show: true, text: "Something went wrong!", type: "error", })
                }
                setState({ ...state, pageLoader: false })
                spcSingleDeleteRowDispatch()
            }
        }
    }

    const spcSingleDeleteRowDispatch = () => {
        dispatch(setPdcSpcDeleteData({
            key: 'spcStartSingleDelete',
            value: false
        }))
        dispatch(setPdcSpcDeleteData({
            key: 'spcConfirmSingleDelete',
            value: false
        }))
    }

    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Box sx={{ flexGrow: 1, paddingX: 2 }} >
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5 } }}>
                    <div className='table-actionable'>
                        <Button variant="contained" onClick={(e) => addSupplierProductCodeModal(e)} startIcon={<AddIcon />}>
                            Add New Supplier Product Code
                        </Button>
                    </div>
                </Box>
                <DataTableClientSide
                    columns={columns ? columns : []}
                    defaultGrid={state.defaultGrid}
                    rows={getSupplierProductCode ? getSupplierProductCode : []}
                >
                </DataTableClientSide>
                {showSupplierProductCodeModal && (
                    <>
                        <AddEditSupplierProductCode size="medium_overlay"
                            onShow={showSupplierProductCodeModal}
                            onHide={(e, reason) => hideAddEditEanModal(e, reason)}
                        />
                    </>
                )}
            </Box>
            {snackBar.show && <SnackBarDialog
            onShow={snackBar.show}
            text={snackBar.text}
            type={snackBar.type}
            onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
        />}
        </>
    );
}
