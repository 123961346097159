import { DataTable } from "../../components/shared/data-table/DataTable";
import { Container, Paper, Box, Button, Alert, IconButton, Menu, MenuItem, Checkbox, FormControlLabel, Grid, FormHelperText, TextField, FormControl, FormLabel, Modal, Select } from '@mui/material'

import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import SendIcon from '@mui/icons-material/Send';
import { useEffect, useState } from "react";
import { read, utils } from "xlsx";
import moment from "moment"
import { hasDuplicate, isEmpty, replaceKeys } from "../../services/utility/common-utils";
import { DataTableClientSide } from "../../components/shared/data-table/DataTableClientSide";
import { GridCheckCircleIcon, GridMoreVertIcon } from "@mui/x-data-grid";
import CircleIcon from '@mui/icons-material/Circle';
import { useDispatch, useSelector } from 'react-redux';
import { editProductUpload, editProductUploadModal, fileDeleteYes, singleDeleteRow, singleDeleteYes, wrongFile } from "../../store/slices/retroFileUpload";
import RetrospectiveFileUploadModal from "./fileupload/RetrospectiveFileUploadModal";
import axiosRetroDiscountSchemes from "../../services/utility/axios-retro-discount-schemes";
import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningModal from "../../components/shared/modals/WarningModal";
import { warningModal, handleRDSchemeModal } from "../../store/slices/modals";
import axios_product from "../../services/utility/axios";
import SnackBarDialog from "../../components/shared/SnackBarDialog";
import PageLoader from "../../components/shared/PageLoader";
import downLoadTemplate from "../../assets/images/retroDiscountSchemeTemplate-v2.xlsx";
import AddEditRetrospective from "./retro-fileupload/AddEditRetrospective";
import RetroGeneral from './retro-fileupload/tabs/RetroGeneral';
import RetroProgrammes from './retro-fileupload/tabs/RetroProgrammes';
import RetroProduct from './retro-fileupload/tabs/RetroProduct';
import RetroSuppliers from "./retro-fileupload/tabs/RetroSuppliers";
import RetroVolumeThreshold from "./retro-fileupload/tabs/RetroVolumeThreshold";
import RetroPayMemberDates from "./retro-fileupload/tabs/RetroPayMemberDates";
import { hasTabEdit } from "../../store/slices/generalProduct";
import { setHasEditData, retrodiscountschemesInput, setRetroDates, autoCompleteSearch, setRetroState, setDeleteList } from "../../store/slices/retrodiscountschemes";


const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

const IconMenuLink = ({ params, onEdit, onDelete }) => {

    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'edit') {
            if (onEdit) {
                // onEdit(row)
                dispatch(handleRDSchemeModal({
                    addEditRDSchemeModal: true
                }))
                dispatch(hasTabEdit({ editTab: false }))
                // console.log(row);
                setEditRDSData(row);
            }
        } else if (action === 'delete') {
            if (onDelete) {
                onDelete(row)
            }
        }
        setAnchorEl(null);
    };

    const setEditRDSData = (row) => {
        // console.log(row.alreadyExist, row);
        dispatch(setHasEditData(row));
        dispatch(retrodiscountschemesInput({
            key: 'retro_name',
            value: row.name
        }));
        dispatch(retrodiscountschemesInput({
            key: 'retroUploadModal',
            value: 'open'
        }))
        if (row.alreadyExist === false) {
            dispatch(setRetroDates({
                key: 'startDate',
                value: row.startDate ? moment(row.startDate, 'DD/MM/YYYY') : ""
            }));
            dispatch(setRetroDates({
                key: 'endDate',
                value: row.endDate ? moment(row.endDate, 'DD/MM/YYYY') : ""
            }));
            dispatch(retrodiscountschemesInput({
                key: 'retro_interim_unit',
                value: row.interimValuePerUnit
            }))
            dispatch(retrodiscountschemesInput({
                key: 'retro_final_unit',
                value: row.finalValuePerUnit
            }))

            dispatch(autoCompleteSearch({
                parent: 'general_vat_code',
                key: 'vat_code_selected',
                // value: row.vatCode
                value: { "id": row.vatCode, "name": row.vatCodeName, "label": row.vatCodeName }
            }));
            dispatch(autoCompleteSearch({
                parent: 'general_unit_measure',
                key: 'measure_selected',
                // value: row.unitOfMeasure
                value: {
                    "id": row.unitOfMeasure, "name": row.unitOfMeasureName
                    , "label": row.unitOfMeasureName
                }
            }));
            dispatch(autoCompleteSearch({
                parent: 'centralBillingScheme',
                key: 'billingScheme_selected',
                // value: row.centralBillingScheme
                value: { "id": row.centralBillingScheme, "name": row.centralBillingSchemeName, "label": row.centralBillingSchemeName }
            }));
            if (row.promotionalType) {
                dispatch(autoCompleteSearch({
                    parent: 'promotionalType',
                    key: 'promotionalType_selected',
                    value: { "id": row.promotionalType, "name": row.promotionalTypeName, "label": row.promotionalTypeName }
                }));
            }
            else {
                dispatch(autoCompleteSearch({
                    parent: 'promotionalType',
                    key: 'promotionalType_selected',
                    value: null
                }));
            }
            dispatch(setRetroState({
                key: 'retro_promotional_period',
                value: row.promotionalPeriod
            }))
            dispatch(setRetroState({
                key: 'retro_promotional_year',
                value: row.promotionalYear
            }))
        }
    }

    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <GridMoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'edit')}>Edit</MenuItem>
            <MenuItem onClick={(e) => handleClose(e, 'delete')}>Delete</MenuItem>
        </Menu>
    </>)
}

function RetrospectiveFileUpload() {
    const tabs = [
        {
            label: "General",
            Component: <RetroGeneral />
        },
        {
            label: "Programmes",
            Component: <RetroProgrammes />
        },
        {
            label: "Product",
            Component: <RetroProduct />
        },
        {
            label: "Suppliers",
            Component: <RetroSuppliers />
        },
        {
            label: "Volume Thresholds",
            Component: <RetroVolumeThreshold />
        },
        {
            label: "Pay Member Dates",
            Component: <RetroPayMemberDates />
        },
    ];
    // Default Table on page load.
    const [columns, setColumns] = useState([
        {
            "headerName": "Name",
            "field": "name",
            "minWidth": 200
        },
        {
            "headerName": "Start Date",
            "field": "startDate",
            "width": 120
        },
        {
            "headerName": "End Date",
            "field": "endDate",
            "width": 120
        },
        {
            "headerName": "Vat Code",
            "field": "vatCode",
            "width": 120
        },
        {
            "headerName": "Unit of Measure",
            "field": "unitOfMeasure",
            "width": 200
        },
        {
            "headerName": "Interim Value Per Unit",
            "field": "interimValuePerUnit",
            "width": 200
        },
        {
            "headerName": "Final Value Per Unit",
            "field": "finalValuePerUnit",
            "width": 200
        },
        {
            "headerName": "Cental Billing Scheme",
            "field": "centralBillingSchemeName",
            "width": 200
        },
        {
            "headerName": "Promotional Type",
            "field": "promotionalTypeName",
            "width": 200
        },
        {
            "headerName": "Mechanic Type",
            "field": "mechanicType",
            "width": 200,
        },
        {
            "headerName": "Promotional Year",
            "field": "promotionalYear",
            "width": 200
        },
        {
            "headerName": "Promotional Period",
            "field": "promotionalPeriod",
            "width": 200
        },
        // {
        //     "headerName": "Programmes",
        //     "field": "retroDiscountSchemeProgrammes",
        //     "width": 120
        // },
        // {
        //     "headerName": "OCBC",
        //     "field": "retroDiscountSchemeOuterCaseBarcodes",
        //     "width": 120
        // },
        // {
        //     "headerName": "Supplier",
        //     "field": "retroDiscountSchemeSuppliers",
        //     "width": 120
        // },
        // {
        //     "headerName": "Start Quantity",
        //     "field": "retroVolumeThresholdStartQuantity",
        //     "width": 120
        // },
        // {
        //     "headerName": "End Quantity",
        //     "field": "retroVolumeThresholdEndQuantity",
        //     "width": 120
        // },
        // {
        //     "headerName": "Points",
        //     "field": "retroVolumeThresholdPoints",
        //     "width": 120
        // },
        // {
        //     "headerName": "Type",
        //     "field": "retroPayMemberType",
        //     "width": 120
        // },
        // {
        //     "headerName": "Due Date",
        //     "field": "retroPayMemberDueDate",
        //     "width": 120
        // },
        // {
        //     "headerName": "Description",
        //     "field": "description",
        //     "width": 120
        // }
    ]);
    //This table appears on Selection from dropdown.
    const [allColumns, setAllColumns] = useState([
        {
            headerName: "Result",
            field: "result",
            width: 80
        },
        {
            headerName: "Error Message",
            field: "errorMessage",
            width: 400,
            // Using renderCell to remove brackets and display the error message
            renderCell: ({ value }) => {
                return value.replace(/\[|\]/g, "");
            }
        },
        {
            "headerName": "Name",
            "field": "name",
            "minWidth": 200
        },
        {
            "headerName": "Start Date",
            "field": "startDate",
            "width": 120
        },
        {
            "headerName": "End Date",
            "field": "endDate",
            "width": 120
        },
        {
            "headerName": "Vat Code",
            "field": "vatCodeName",
            "width": 100
        },
        {
            "headerName": "Unit of Measure",
            "field": "unitOfMeasureName",
            "width": 200
        },
        {
            "headerName": "Interim Value Per Unit",
            "field": "interimValuePerUnit",
            "width": 200
        },
        {
            "headerName": "Final Value Per Unit",
            "field": "finalValuePerUnit",
            "width": 200
        },
        {
            "headerName": "Cental Billing Scheme",
            "field": "centralBillingSchemeName",
            "width": 220
        },
        {
            "headerName": "Promotional Type",
            "field": "promotionalTypeName",
            "width": 200
        },
        {
            "headerName": "Mechanic Type",
            "field": "mechanicType",
            "width": 200,
        },
        {
            "headerName": "Promotional Year",
            "field": "promotionalYear",
            "width": 200,
        },
        {
            "headerName": "Promotional Period",
            "field": "promotionalPeriod",
            "width": 200
        },
        // {
        //     "headerName": "Programmes",
        //     "field": "programmes",
        //     "width": 120
        // },
        // {
        //     "headerName": "OCBC",
        //     "field": "outerCaseBarcode",
        //     "width": 120
        // },
        // {
        //     "headerName": "Supplier",
        //     "field": "supplier",
        //     "width": 120
        // },
        // {
        //     "headerName": "Start Quantity",
        //     "field": "retroVolumeThresholdStartQuantity",
        //     "width": 120
        // },
        // {
        //     "headerName": "End Quantity",
        //     "field": "endQuantity",
        //     "width": 120
        // },
        // {
        //     "headerName": "Points",
        //     "field": "points",
        //     "width": 120
        // },
        // {
        //     "headerName": "Type",
        //     "field": "type",
        //     "width": 120
        // },
        // {
        //     "headerName": "Due Date",
        //     "field": "dueDate",
        //     "width": 120
        // },
        // {
        //     "headerName": "Description",
        //     "field": "description",
        //     "width": 120
        // },        
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} onEdit={editRow} onDelete={deleteRow} />;
            }
        },
    ]);
    const [rows, setRows] = useState([]);
    //This table appears when the Excel file is read and displayed before it goes for upload.
    const [state, setState] = useState({
        deleteRow: false,
        deleteAllProductUpload: false,
        fileName: '',
        duplicateColumns: [
            {
                "headerName": "Name",
                "field": "name",
                "minWidth": 200
            },
            {
                "headerName": "Start Date",
                "field": "startDate",
                "width": 120
            },
            {
                "headerName": "End Date",
                "field": "endDate",
                "width": 120
            },
            {
                "headerName": "Vat Code",
                "field": "vatCodeName",
                "width": 120
            },
            {
                "headerName": "Unit of Measure",
                "field": "unitOfMeasureName",
                "width": 200
            },
            {
                "headerName": "Interim Value Per Unit",
                "field": "interimValuePerUnit",
                "width": 200
            },
            {
                "headerName": "Final Value Per Unit",
                "field": "finalValuePerUnit",
                "width": 200
            },
            {
                "headerName": "Cental Billing Scheme",
                "field": "centralBillingSchemeName",
                "width": 200
            },
            {
                "headerName": "Promotional Type",
                "field": "promotionalTypeName",
                "width": 200
            },
            {
                "headerName": "Mechanic Type",
                "field": "mechanicType",
                "width": 200
            },
            {
                "headerName": "Promotional Year",
                "field": "promotionalYear",
                "width": 200
            },
            {
                "headerName": "Promotional Period",
                "field": "promotionalPeriod",
                "width": 200
            },
            // {
            //     "headerName": "Programmes",
            //     "field": "retroDiscountSchemeProgrammes",
            //     "width": 120
            // },
            // {
            //     "headerName": "OCBC",
            //     "field": "retroDiscountSchemeOuterCaseBarcodes",
            //     "width": 120
            // },
            // {
            //     "headerName": "Supplier",
            //     "field": "retroDiscountSchemeSuppliers",
            //     "width": 120
            // },
            // {
            //     "headerName": "Start Quantity",
            //     "field": "retroVolumeThresholdStartQuantity",
            //     "width": 120
            // },
            // {
            //     "headerName": "End Quantity",
            //     "field": "retroVolumeThresholdEndQuantity",
            //     "width": 120
            // },
            // {
            //     "headerName": "Points",
            //     "field": "retroVolumeThresholdPoints",
            //     "width": 120
            // },
            // {
            //     "headerName": "Type",
            //     "field": "retroPayMemberType",
            //     "width": 120
            // },
            // {
            //     "headerName": "Due Date",
            //     "field": "retroPayMemberDueDate",
            //     "width": 120
            // },
            // {
            //     "headerName": "Description",
            //     "field": "description",
            //     "width": 120
            // },
            // {
            //     "headerName": "Name",
            //     "field": "name",
            //     "flex": 1,
            //     "minWidth": 200
            // },
            // {
            //     "headerName": "Start Date",
            //     "field": "startDate",
            //     "width": 120,
            //     "flex": 1
            // },
            // {
            //     "headerName": "End Date",
            //     "field": "endDate",
            //     "width": 120,
            //     "flex": 1
            // },
            // {
            //     "headerName": "Vat Code",
            //     "field": "vatCode",
            //     "width": 120,
            //     "hide": true
            // },
            // {
            //     "headerName": "Unit of Measure",
            //     "field": "unitOfMeasure",
            //     "width": 120,
            //     "hide": true
            // },
            // {
            //     "headerName": "Interim Value Per Unit",
            //     "field": "interimValuePerUnit",
            //     "width": 120,
            //     "hide": true
            // },
            // {
            //     "headerName": "Final Value Per Unit",
            //     "field": "finalValuePerUnit",
            //     "width": 120,
            //     "hide": true
            // },
            // {
            //     "headerName": "Cental Billing Scheme",
            //     "field": "centalBillingScheme",
            //     "width": 120,
            //     "flex": 1
            // },
            // {
            //     "headerName": "Promotional Type",
            //     "field": "promotionalType",
            //     "width": 120,
            //     "hide": true
            // },
            // {
            //     "headerName": "Mechanic Type",
            //     "field": "mechanicType",
            //     "width": 120,
            //     "hide": true
            // },
            // {
            //     "headerName": "Promotional Year",
            //     "field": "promotionalYear",
            //     "width": 120,
            //     "flex": 1
            // },
            // {
            //     "headerName": "Promotional Period",
            //     "field": "promotionalPeriod",
            //     "width": 120,
            //     "hide": true
            // },
            // {
            //     "headerName": "Close for Billing",
            //     "field": "closeForBilling",
            //     "width": 120,
            //     "hide": true
            // },
            // {
            //     "headerName": "Close for Invoices",
            //     "field": "closeForInvoices",
            //     "width": 120,
            //     "hide": true
            // },
            // {
            //     "headerName": "Generate Detailed Report",
            //     "field": "generateDetailedReport",
            //     "width": 120,
            //     "hide": true
            // },
            // {
            //     "headerName": "Apply Aggregation Groups",
            //     "field": "applyAggregationGroups",
            //     "width": 120,
            //     "hide": true
            // },
            // {
            //     "headerName": "Category",
            //     "field": "category",
            //     "width": 120,
            //     "hide": true
            // },
            // {
            //     "headerName": "Distribution Channel",
            //     "field": "distributionChannel",
            //     "width": 120,
            //     "hide": true
            // },
            // {
            //     "headerName": "Members",
            //     "field": "members",
            //     "width": 120,
            //     "hide": true
            // },
            // {
            //     "headerName": "Programmes",
            //     "field": "programmes",
            //     "width": 120,
            //     "hide": true
            // },
            // {
            //     "headerName": "OCBC",
            //     "field": "ocbc",
            //     "width": 120,
            //     "hide": true
            // },
            // {
            //     "headerName": "Supplier",
            //     "field": "supplier",
            //     "width": 120,
            //     "hide": true
            // },
            // {
            //     "headerName": "Start Quantity",
            //     "field": "retroVolumeThresholdStartQuantity",
            //     "width": 120,
            //     "hide": true
            // },
            // {
            //     "headerName": "End Quantity",
            //     "field": "endQuantity",
            //     "width": 120,
            //     "hide": true
            // },
            // {
            //     "headerName": "Points",
            //     "field": "points",
            //     "width": 120,
            //     "hide": true
            // },
            // {
            //     "headerName": "Type",
            //     "field": "type",
            //     "width": 120,
            //     "hide": true
            // },
            // {
            //     "headerName": "Due Date",
            //     "field": "dueDate",
            //     "width": 120,
            //     "hide": true
            // },
            // {
            //     "headerName": "Description",
            //     "field": "description",
            //     "width": 120,
            //     "hide": true
            // },
        ],
        defaultGrid: {
            paginationMode: 'client',
            rowHeight: 'auto',
            pageCountSize: true,
            pagehead: 'retrospectiveFileUpload'
        },
        fileNameId: '',
        fuSelectedName: '',
        pageLoader: false,
        freshUpload: false,
        disableFileUpload: false
    });
    const [isError, setIsError] = useState({
        // isPNameDuplicate: false,
        isPNameEmpty: false,

        // isBOPIdDuplicate: false,
        isBOPIdEmpty: false,

        isOcBcDuplicate: false,
        isOcBcEmpty: false,
    });
    const [notUploded, setUploadFlag] = useState(true)
    const [uploadDropdown, setUploadDropdown] = useState([]);
    const [handleEditRowModal, setHandleEditRowModal] = useState(false)
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })
    const { handleWarning, fileUpload, addEditRDSchemeModal, retro_fields } = useSelector((state) => {
        return {
            fileUpload: state.retrospectiveFileUpload,
            handleWarning: state.modalActions.closeWaringAddProduct,
            addEditRDSchemeModal: state.modalActions.addEditRDSchemeModal,
            retro_fields: state.retrodiscountscheme
        }
    });
    const dispatch = useDispatch();



    const handleUpload = async (e) => {
        setRows([]);
        var input = e.target;
        var reader = new FileReader();

        reader.onload = function () {
            var fileData = reader.result;
            var wb = read(fileData, { type: 'binary', cellDates: true });
            const data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { header: 1, defval: "" });
            let finalRows = data.slice(1).map((r) => r.reduce((acc, x, i) => {
                acc[data[0][i]] = x;
                return { ...acc, id: Math.random().toString(16).slice(2) };
            }, {}))
            const defaultValues = insertDefaultValues(finalRows);
            if (defaultValues !== 0) {
                setIsError({
                    ...isError,
                    isOcBcDuplicate: hasDuplicate(defaultValues, "outerCaseBarCode"),
                    isOcBcEmpty: isEmpty(defaultValues, "outerCaseBarCode"),
                    // isPNameDuplicate: hasDuplicate(defaultValues, "productDescription"),
                    isPNameEmpty: isEmpty(defaultValues, "productDescription"),
                    // isBOPIdDuplicate: hasDuplicate(defaultValues, "brandOwnerProductId"),
                    isBOPIdEmpty: isEmpty(defaultValues, "brandOwnerProductId")
                });
                const arrayList = [hasDuplicate(defaultValues, "outerCaseBarCode"),
                isEmpty(defaultValues, "outerCaseBarCode"),
                //hasDuplicate(defaultValues, "productDescription"),
                isEmpty(defaultValues, "productDescription"),
                // hasDuplicate(defaultValues, "brandOwnerProductId"),
                isEmpty(defaultValues, "brandOwnerProductId")
                ]
                setUploadFlag(arrayList.includes(true));
                setRows(defaultValues);

            }
            else {
                setState({ ...state, disableFileUpload: false });
                dispatch(wrongFile({
                    key: 'wrongFileUpload',
                    value: true
                }));
                dispatch(warningModal({
                    yesHide: true
                }));

            }

        }

        reader.readAsBinaryString(input.files[0]);
        const fname = input.files[0].name.split('.');
        const fExtension = fname.pop()
        setState({ ...state, fileName: fname.join() + "_" + Date.parse(new Date) + '.' + fExtension, disableFileUpload: true })
        setColumns(state.duplicateColumns)
    }
    // useEffect(() => {
    //     if (isError.isOcBcDuplicate && isError.isOcBcEmpty &&
    //         isError.isPNameDuplicate && isError.isPNameEmpty && isError.isBOPIdDuplicate &&
    //         isError.isBOPIdEmpty
    //     ) {
    //         //excelSuccessUpload('noErrors')
    //     } else {
    //         // eslint-disable-next-line no-empty
    //         if (notUploded) {
    //         } else {
    //             //fetchMyAPI()
    //         }
    //     }

    // }, [isError])

    useEffect(() => {
        // eslint-disable-next-line no-empty
        if (notUploded) {
        } else {
            fetchMyAPI()
        }

    }, [notUploded])

    const insertDefaultValues = (rows) => {
        // console.log(rows);
        let defaultValues = rows;
        replaceKeys(defaultValues);
        try {
            defaultValues.forEach(function (args) {
                //  console.log(args);
                if (args.name !== "") {
                    args.name = args.name.toString()
                    if (args.name.length > 200) {
                        args.name = (args.name.length > 200) ? args.name.slice(0, 200) : args.name;
                    }
                }
                if (args.centralBillingScheme !== "") {
                    args.centralBillingScheme.toString();
                }

                // if (args.centalBillingScheme !== "") {
                //     if (args.centalBillingScheme.length > 100) {
                //         args.outerCaseBarCode = (args.outerCaseBarCode.length > 100) ? args.outerCaseBarCode.slice(0, 100) : args.outerCaseBarCode;
                //     }
                // }
                // if (args.specification !== "") {
                //     args.specification = args.specification.toString()
                //     if (args.specification.length > 255) {
                //         args.specification = (args.specification.length > 255) ? args.specification.slice(0, 255) : args.specification;
                //     }
                // }
                // if (args.eanCode !== "") {
                //     args.eanCode = args.eanCode.toString()
                //     if (args.eanCode.length > 50) {
                //         args.eanCode = (args.eanCode.length > 50) ? args.eanCode.slice(0, 50) : args.eanCode;
                //     }
                // }
                // if (args.uomName !== "") {
                //     args.uomName = args.uomName.toString()
                //     if (args.uomName.length > 200) {
                //         args.uomName = (args.uomName.length > 200) ? args.uomName.slice(0, 200) : args.uomName;
                //     }
                // }
                // if (args.brandName !== "") {
                //     args.brandName = args.brandName.toString()
                //     if (args.brandName.length > 200) {
                //         args.brandName = (args.brandName.length > 200) ? args.brandName.slice(0, 200) : args.brandName;
                //     }
                // }
                // if (args.catName !== "") {
                //     args.catName = args.catName.toString()
                //     if (args.catName.length > 200) {
                //         args.catName = (args.catName.length > 200) ? args.catName.slice(0, 200) : args.catName;
                //     }
                // }
                // if (args.lsBrandOwnerId !== "") {
                //     args.lsBrandOwnerId = args.lsBrandOwnerId.toString()
                //     if (args.lsBrandOwnerId.length > 200) {
                //         args.lsBrandOwnerId = (args.lsBrandOwnerId.length > 200) ? args.lsBrandOwnerId.slice(0, 200) : args.lsBrandOwnerId;
                //     }
                // }
                // if (args.exportToTd) {
                //     args.exportToTd = args.exportToTd.toUpperCase()
                // }

                if (args.vatCode) {
                    args.vatCode = args.vatCode.toUpperCase()
                }
                // if (args.fairTrade) {
                //     args.fairTrade = args.fairTrade.toUpperCase()
                // }
                // if (args.organic) {
                //     args.organic = args.organic.toUpperCase()
                // }
                // if (args.discountableForBopa) {
                //     args.discountableForBopa = args.discountableForBopa.toUpperCase()
                // }
                // if (args.ethical) {
                //     args.ethical = args.ethical.toUpperCase()
                // }

                /* Outer case Zero Padding */
                if (args.retroDiscountSchemeOuterCaseBarcodes) {
                    args.retroDiscountSchemeOuterCaseBarcodes = args.retroDiscountSchemeOuterCaseBarcodes.toString().toUpperCase();
                    // if (args.outerCaseBarCode && args.outerCaseBarCode.length <= 13) {
                    //     args.outerCaseBarCode = args.outerCaseBarCode.padStart(13, '0')
                    // }
                }

                if ((args.startDate) !== "") {
                    args.startDate = moment(args.startDate).format('DD/MM/YYYY') === 'Invalid date' ? null : moment(args.startDate).format('DD/MM/YYYY');
                }
                if ((args.endDate) !== "") {
                    args.endDate = moment(args.endDate).format('DD/MM/YYYY') === 'Invalid date' ? null : moment(args.endDate).format('DD/MM/YYYY');
                }
                if ((args.retroPayMemberDueDate) !== "") {
                    args.retroPayMemberDueDate = moment(args.retroPayMemberDueDate).format('DD/MM/YYYY') === 'Invalid date' ? null : moment(args.retroPayMemberDueDate).format('DD/MM/YYYY');
                }
                // if (((!args.casePrice || args.casePrice === 0) && !args.startDate && !args.endDate)) {
                //     args.startDate = null
                //     args.endDate = null
                //     args.casePrice = null
                // }
                // if ((args.endDate && (!args.casePrice && !args.casePrice === 0))) {
                //     args.startDate = null
                //     args.endDate = null
                //     args.casePrice = null
                // }
                // if ((args.startDate && (!args.casePrice && !args.casePrice == 0))) {
                //     args.startDate = null
                //     args.endDate = null
                //     args.casePrice = null
                // }
                // if ((args.casePrice || args.casePrice === 0)  && (!args.endDate)) {
                //     args.endDate = null
                // }
                // if ((args.casePrice || args.casePrice === 0) && (!args.startDate)) {
                //     args.startDate = null
                // }
                // if (args.caseQuantity === "") {
                //     args.caseQuantity = null;
                // }
                // if (!args.eanCode) {
                //     args.eanCode = 'N'
                // }
                // if (!args.exportToTd) {
                //     args.exportToTd = 'N'
                // }
                // if (!args.memberTier) {
                //     args.memberTier = 'Off-trade Member Tier'
                // }
                // if (!args.fairTrade) {
                //     args.fairTrade = "N"
                // }
                // if (!args.organic) {
                //     args.organic = "N"
                // }
                // if (!args.discountableForBopa) {
                //     args.discountableForBopa = "N"
                // }
                // if (!args.ethical) {
                //     args.ethical = "N"
                // }
                // if (!args.uomId) {
                //     args.uomId = null
                // }
                // if (!args.brandId) {
                //     args.brandId = null
                // }
                // if (!args.catId) {
                //     args.catId = null
                // }
                // if (!args.lsBrandOwnerId) {
                //     args.lsBrandOwnerId = null
                // }

                // if (args.countryOfOrigin) {
                //     args.countryOfOrigin = args.countryOfOrigin.toString()                    
                // }

                // if (args.rt) {
                //     args.rt = args.rt.toUpperCase()
                // }

                // if (!args.rt) {
                //     args.rt = "N"
                // }

                // if (args.msc) {
                //     args.msc = args.msc.toUpperCase()
                // }

                // if (!args.msc) {
                //     args.msc = "N"
                // }    

                // if (args.rspca) {
                //     args.rspca = args.rspca.toUpperCase()
                // }

                // if (!args.rspca) {
                //     args.rspca = "N"
                // }

                // if (args.fsc) {
                //     args.fsc = args.fsc.toUpperCase()
                // }

                // if (!args.fsc) {
                //     args.fsc = "N"
                // }

                // if (args.rspo) {
                //     args.rspo = args.rspo.toUpperCase()
                // }

                // if (!args.rspo) {
                //     args.rspo = "N"
                // }

            });
            return defaultValues;
        }
        catch (error) {
            // const { response: { data, status } } = error;
            console.log(error);
            return 0;

        }
    }

    const fetchMyAPI = async () => {

        if (rows.length === 0) {
            setIsError({
                ...isError,
                isOcBcEmpty: true,
                // isPNameDuplicate: hasDuplicate(defaultValues, "productDescription"),
                isPNameEmpty: true,
                isBOPIdEmpty: true
            })
        } else {
            try {

                setState({ ...state, pageLoader: true })
                const fileUploadDetails = {
                    "fileName": state.fileName,
                    // eslint-disable-next-line no-unused-vars
                    "retroDiscountSchemeUploadList": rows ? rows.map(({ id, ...rest }) => rest) : ''
                }

                const { data } = await axiosRetroDiscountSchemes.post(`/importedRetroDiscount/upload`, fileUploadDetails);
                if (data) {
                    setState({
                        ...state,
                        pageLoader: false,
                        fileNameId: "",
                        fileName: "",
                        fuSelectedName: "",
                        // fileNameId: data.resultMap.result.id,
                        // fileName: data.resultMap.result.fileName,

                        // fuSelectedName: JSON.stringify({
                        //     id: data.resultMap.result.id,
                        //     fileName: data.resultMap.result.fileName
                        // }),
                        freshUpload: true
                    })
                    // setUploadDropdown([...uploadDropdown, {
                    //     id: data.resultMap.result.id,
                    //     fileName: data.resultMap.result.fileName
                    // }])

                    const productActionColumns = [
                        {
                            headerName: "Result",
                            field: "result",
                            width: 100
                        },
                        {
                            headerName: "Error Message",
                            field: "errorMessage",
                            width: 400,
                            // Using renderCell to remove brackets and display the error message
                            renderCell: ({ value }) => {
                                return value ? value.replace(/\[|\]/g, "") : '';
                            }
                        },
                        ...columns,
                        // ...allColumns,
                        {
                            headerName: "Action",
                            field: "action",
                            sortable: false,
                            width: 90,
                            renderCell: (params) => {
                                return <IconMenuLink params={params} onEdit={editRow} onDelete={deleteRow} />;
                            }
                        }];
                    // new array need to update
                    setColumns(productActionColumns)
                    // setRows(data.resultMap.result.retroDiscountSchemeUploadList)
                    setIsError({
                        ...isError,
                        isOcBcDuplicate: false,
                        isOcBcEmpty: false,
                        // isPNameDuplicate: false,
                        isPNameEmpty: false,

                        // isBOPIdDuplicate: false,
                        isBOPIdEmpty: false
                    })
                    // setSnackBar({ ...snackBar, show: true, text: "File uploaded Successfully and available in existing upload", type: "success" })
                    setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "File uploaded Successfully!", type: "success" })
                    setTimeout(() => {
                        setRows([])
                        window.location.reload();
                    }, 250);
                }

            } catch (error) {
                const { response: { data, status } } = error;
                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                }
                setState({ ...state, pageLoader: false })
            }
        }
    }
    useEffect(() => {
        fetchDropDownUpload();
    }, [])
    const fetchDropDownUpload = async () => {
        try {
            const { data } = await axiosRetroDiscountSchemes.get(`/importedRetroDiscount/upload/drop-down`);
            if (data) {
                setUploadDropdown(data.resultMap.result)
            }

        } catch (error) {
            //handle error
        }
    }

    useEffect(() => {
        if (state.freshUpload === false) {
            fetchGetFileUpload();
        }
    }, [state.fuSelectedName])

    useEffect(() => {
        setState({ ...state, freshUpload: false })
    }, [state.freshUpload])

    const fetchGetFileUpload = async () => {
        setIsError({
            ...isError,
            isOcBcDuplicate: false,
            isOcBcEmpty: false,
            // isPNameDuplicate: false,
            isPNameEmpty: false,
            // isBOPIdDuplicate: false,
            isBOPIdEmpty: false
        })
        const selectedFile = state.fuSelectedName ? JSON.parse(state.fuSelectedName) : '';

        if (selectedFile !== '') {
            try {
                setState({ ...state, pageLoader: true })
                const { data } = await axiosRetroDiscountSchemes.get(`/importedRetroDiscount/upload/${selectedFile.id ? selectedFile.id : '-1'}`);

                setColumns([])
                if (data) {
                    // new array need to update
                    setState({ ...state, pageLoader: false })
                    setColumns(allColumns)
                    setTimeout(() => {
                        setRows(data.resultMap.result)
                    }, 100);

                }

            } catch (error) {
                const { response: { data, status } } = error;
                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                }
                setState({ ...state, pageLoader: false })

            }
        }

    }

    const editRow = (row) => {
        dispatch(editProductUploadModal(true))
        dispatch(editProductUpload(row))
    }

    const deleteRow = (row) => {

        dispatch(singleDeleteRow(row))
        dispatch(singleDeleteYes({
            key: 'startSingleDelete',
            value: true
        }))
        dispatch(warningModal({
            yesHide: !handleWarning
        }))
    }

    useEffect(() => {
        if (fileUpload.confirmSingleDelete) {
            deleteSingleRow()
        }
    }, [fileUpload.confirmSingleDelete])

    const deleteSingleRow = async () => {
        if (fileUpload.singleDeleteRow !== null) {
            try {
                setState({ ...state, pageLoader: true })
                const { data } = await axiosRetroDiscountSchemes.delete(`ImportedRetroDiscount/${fileUpload.singleDeleteRow.id}`);
                //setColumns([])
                if (data) {
                    setState({ ...state, pageLoader: false })
                    const filteredRows = rows.filter(product => {
                        if (product.id !== fileUpload.singleDeleteRow.id) {
                            return product;
                        }
                    })
                    setRows(filteredRows)
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
                    singleDeleteRowDispatch()
                }

            } catch (error) {
                const { response: { data, status } } = error;
                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                }
                singleDeleteRowDispatch()
                setState({ ...state, pageLoader: false })
            }
        }

    }

    const handleEditRow = (event) => {
        setHandleEditRowModal(event)
    };

    useEffect(() => {
        if (fileUpload.confirmFileDelete) {
            deleteUploadedData()
        }
    }, [fileUpload.confirmFileDelete])

    const abandonDeleteFile = async () => {
        if (state.fuSelectedName !== "") {
            dispatch(fileDeleteYes({
                key: 'startFileDelete',
                value: true
            }))
            dispatch(warningModal({
                yesHide: !handleWarning
            }))
        } else {
            setSnackBar({ ...snackBar, show: true, text: "Select from existing or upload new file!", type: "error" })
        }
    }

    const deleteUploadedData = async () => {
        const selectedFile = state.fuSelectedName ? JSON.parse(state.fuSelectedName) : '';
        try {
            setState({ ...state, pageLoader: true })
            const { data } = await axiosRetroDiscountSchemes.delete(`/importedRetroDiscount/abandonUpload/${selectedFile.id}`);
            //setColumns([])
            if (data) {
                setState({ ...state, pageLoader: false, disableFileUpload: false })
                setUploadFlag(true)
                const filteredRows = uploadDropdown.filter(product => {
                    if (product.id !== selectedFile.id) {
                        return product;
                    }
                })
                setUploadDropdown(filteredRows)
                setColumns(state.duplicateColumns)
                setTimeout(() => {
                    setRows([])
                }, 100);
                setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
                FileDeleteDispatch()
            }

        } catch (error) {
            // eslint-disable-next-line no-unused-vars
            const { response: { data, status } } = error;
            if (!data) {
                setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
            } else {
                setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
            }
            FileDeleteDispatch()
            setState({ ...state, pageLoader: false })
        }
    }

    const singleDeleteRowDispatch = () => {
        dispatch(singleDeleteYes({
            key: 'startSingleDelete',
            value: false
        }))
        dispatch(singleDeleteYes({
            key: 'confirmSingleDelete',
            value: false
        }))
    }
    const FileDeleteDispatch = () => {
        dispatch(fileDeleteYes({
            key: 'startFileDelete',
            value: false
        }))
        dispatch(fileDeleteYes({
            key: 'confirmFileDelete',
            value: false
        }))
    }
    const handleChange = (event) => {
        const { value, name } = event.target;
        event.preventDefault()
        setState({ ...state, [name]: value })
    }
    const reValidateFile = async () => {
        if (state.fuSelectedName !== "") {
            const selectedFile = state.fuSelectedName ? JSON.parse(state.fuSelectedName) : '';
            try {
                setState({ ...state, pageLoader: true })
                const fileUploadDetails = {
                    "id": selectedFile.id,
                    "fileName": selectedFile.fileName
                }
                // const { data } = await axiosRetroDiscountSchemes.post(`retrodiscount-service/importedRetroDiscount/validate`, fileUploadDetails);
                const { data } = await axiosRetroDiscountSchemes.post(`/importedRetroDiscount/validate/${selectedFile.id}`);
                if (data.resultMap) {
                    setState({ ...state, pageLoader: false });
                    // new array need to update
                    setRows(data.resultMap.result.retroDiscountSchemeUploadList);
                    setSnackBar({ ...snackBar, show: true, text: "File revalidated!", type: "success" });
                }
                else {
                    setState({ ...state, pageLoader: false });
                    if (data.success) {
                        setSnackBar({ ...snackBar, show: true, text: "File revalidated!", type: "success" });
                    }
                    else {

                        setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" });
                    }

                }

            } catch (error) {
                const { response: { data, status } } = error;
                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                }
                setState({ ...state, pageLoader: false })
            }
        } else {
            setSnackBar({ ...snackBar, show: true, text: "Select from existing or upload new file!", type: "error" })
        }
    }
    const productUpdated = (data) => {
        setSnackBar({ ...snackBar, show: true, text: data.text, type: data.type });
        setTimeout(() => {
            reValidateFile()
        }, 1500);
    }
    const executeFileUpload = async () => {
        const { id, fileName } = state.fuSelectedName !== "" ? JSON.parse(state.fuSelectedName) : "";
        if (id && fileName) {
            try {
                setState({ ...state, pageLoader: true })
                const { data } = await axiosRetroDiscountSchemes.post(`/importedRetroDiscount/executeUpload/${id}`);
                if (data) {
                    setState({ ...state, pageLoader: false })
                    const filteredRows = uploadDropdown.filter(product => {
                        if (product.id !== id) {
                            return product;
                        }
                    })
                    setUploadDropdown(filteredRows)
                    setColumns(state.duplicateColumns)
                    setTimeout(() => {
                        setRows([])
                    }, 100);
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
                }

            } catch (error) {
                const { response: { data, status } } = error;
                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                }
                setState({ ...state, pageLoader: false })
            }
        } else {
            setSnackBar({ ...snackBar, show: true, text: "Select from existing or upload a new file!", type: "error" })
        }
    }
    const onDownload = () => {
        const link = document.createElement("a");
        link.download = `retroDiscountSchemeTemplate-v2.xlsx`;
        link.href = downLoadTemplate;
        link.click();
    };
    const resetALL = (e) => {
        // console.log('has')
        e.preventDefault();
        setIsError({
            ...isError,
            isOcBcDuplicate: false,
            isOcBcEmpty: false,
            // isPNameDuplicate: false,
            isPNameEmpty: false,
            // isBOPIdDuplicate: false,
            isBOPIdEmpty: false
        })
        setState({
            ...state,
            fileNameId: '',
            fileName: '',
            fuSelectedName: "",
            disableFileUpload: false
        })
        setUploadFlag(true);
        setRows([]);
        setTimeout(() => {
            setColumns([
                {
                    "headerName": "Name",
                    "field": "name",
                    "minWidth": 200
                },
                {
                    "headerName": "Start Date",
                    "field": "startDate",
                    "width": 120
                },
                {
                    "headerName": "End Date",
                    "field": "endDate",
                    "width": 120
                },
                {
                    "headerName": "Vat Code",
                    "field": "vatCode",
                    "width": 120
                },
                {
                    "headerName": "Unit of Measure",
                    "field": "unitOfMeasure",
                    "width": 200
                },
                {
                    "headerName": "Interim Value Per Unit",
                    "field": "interimValuePerUnit",
                    "width": 200
                },
                {
                    "headerName": "Final Value Per Unit",
                    "field": "finalValuePerUnit",
                    "width": 200
                },
                {
                    "headerName": "Cental Billing Scheme",
                    "field": "centralBillingSchemeName",
                    "width": 200
                },
                {
                    "headerName": "Promotional Type",
                    "field": "promotionalTypeName",
                    "width": 200
                },
                {
                    "headerName": "Mechanic Type",
                    "field": "mechanicType",
                    "width": 120,
                },
                {
                    "headerName": "Promotional Year",
                    "field": "promotionalYear",
                    "width": 200
                },
                {
                    "headerName": "Promotional Period",
                    "field": "promotionalPeriod",
                    "width": 200
                },
                // {
                //     "headerName": "Programmes",
                //     "field": "retroDiscountSchemeProgrammes",
                //     "width": 120
                // },
                // {
                //     "headerName": "OCBC",
                //     "field": "retroDiscountSchemeOuterCaseBarcodes",
                //     "width": 120
                // },
                // {
                //     "headerName": "Supplier",
                //     "field": "retroDiscountSchemeSuppliers",
                //     "width": 120
                // },
                // {
                //     "headerName": "Start Quantity",
                //     "field": "retroVolumeThresholdStartQuantity",
                //     "width": 120
                // },
                // {
                //     "headerName": "End Quantity",
                //     "field": "retroVolumeThresholdEndQuantity",
                //     "width": 120
                // },
                // {
                //     "headerName": "Points",
                //     "field": "retroVolumeThresholdPoints",
                //     "width": 120
                // },
                // {
                //     "headerName": "Type",
                //     "field": "retroPayMemberType",
                //     "width": 120
                // },
                // {
                //     "headerName": "Due Date",
                //     "field": "retroPayMemberDueDate",
                //     "width": 120
                // },
                // {
                //     "headerName": "Description",
                //     "field": "description",
                //     "width": 120
                // }
            ])
        }, 100);
        setTimeout(() => {
            setRows([]);
            window.location.reload();
        }, 250);
    }
    const addRDSchemeModal = () => {
        dispatch(hasTabEdit({ editTab: true }))
        dispatch(handleRDSchemeModal({
            addEditRDSchemeModal: true
        }))
    }
    useEffect(() => {
        if(retro_fields.retroUploadModal !== '' && retro_fields.retroUploadModal === 'close') {
            reloadFileData();
        }
    }, [addEditRDSchemeModal]);

    const reloadFileData = async () => {
        const selectedFile = state.fuSelectedName ? JSON.parse(state.fuSelectedName) : '';
        if (selectedFile !== '') {
            try {
                setState({ ...state, pageLoader: true })
                const { data } = await axiosRetroDiscountSchemes.get(`/importedRetroDiscount/upload/${selectedFile.id ? selectedFile.id : '-1'}`);

                setColumns([])
                if (data) {
                    // new array need to update
                    setState({ ...state, pageLoader: false })
                    setColumns(allColumns)
                    setTimeout(() => {
                        setRows(data.resultMap.result)
                    }, 100);

                }

            } catch (error) {
                const { response: { data, status } } = error;
                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                }
                setState({ ...state, pageLoader: false })

            }
        }
    }

    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Imports Retros" />
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -3 } }}>
                    <div className="importButtons">
                        <div className='table-actionable'>
                            <Button variant="contained" component="label" size='small' disabled={state.disableFileUpload}>
                                Upload
                                <input hidden accept=".xls,.xlsx" multiple type="file" onChange={handleUpload} />
                            </Button>
                        </div>
                        <div className="excelList">
                            <FormControl fullWidth sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: { xs: 'center', sm: 'end' } }}>
                                <FormLabel htmlFor="msType" sx={{ textAlign: 'left', paddingRight: '15px' }}>Existing uploads</FormLabel>
                                <Select
                                    size="small"
                                    id="fuSelectedName"
                                    value={state.fuSelectedName}
                                    aria-describedby="fuNameError"
                                    sx={{ width: '200px' }}
                                    onChange={(e) => handleChange(e)}
                                    name="fuSelectedName"
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 48 * 4.5 + 8
                                            },
                                        },
                                    }}
                                >
                                    {uploadDropdown.map((option) => (
                                        <MenuItem value={JSON.stringify({
                                            id: option.id,
                                            fileName: option.fileName
                                        })}
                                            key={option.id}
                                            sx={{ fontSize: '0.85rem' }}>{option.fileName}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <Button onClick={(e) => resetALL(e)}>Clear Table</Button>
                        </div>
                    </div>
                    <div className='table-actionable'>
                        <Button variant="contained" color="secondary" size='small' onClick={onDownload}>
                            Download Template
                        </Button>
                    </div>
                    <div className='table-actionable'>
                        <Button variant="contained" color="tertiary" size='small' onClick={reValidateFile}>
                            Revalidate
                        </Button>
                    </div>
                    <div className='table-actionable'>
                        <Button variant="contained" color="success" size='small' onClick={executeFileUpload}>
                            Execute
                        </Button>
                    </div>
                    <div className='table-actionable'>
                        <Button variant="contained" color="warning" size='small' onClick={abandonDeleteFile}>
                            Abandon File
                        </Button>
                    </div>

                </Box>
                <br />
                <br />
                {
                    isError.isOcBcDuplicate && (<><Alert severity="error">Outer Case Bar Code has duplicate values kindly remove entry and upload file again...!</Alert></>)
                }
                {
                    isError.isOcBcEmpty && (<><Alert severity="error">Outer Case Bar Code has empty values kindly remove entry and upload file again...!</Alert></>)
                }
                {/* {
                    isError.isPNameDuplicate && (<><Alert severity="error">Product Name has duplicate values kindly remove entry and upload file again...!</Alert></>)
                } */}
                {
                    isError.isPNameEmpty && (<><Alert severity="error">Product Name has empty values kindly remove entry and upload file again...!</Alert></>)
                }
                {
                    // isError.isBOPIdDuplicate && (<><Alert severity="error">Brand owner product ID has duplicate values kindly remove entry and upload file again...!</Alert></>)
                }
                {
                    isError.isBOPIdEmpty && (<><Alert severity="error">Brand owner product ID has empty values kindly remove entry and upload file again...!</Alert></>)
                }
                <br />
                <DataTableClientSide defaultGrid={state.defaultGrid} columns={columns ? columns : []} rows={rows ? rows : []}>

                </DataTableClientSide>
                {
                    addEditRDSchemeModal && (
                        <AddEditRetrospective tabs={tabs}
                            onShow={addEditRDSchemeModal}

                        />
                    )
                }
                {
                    fileUpload.editProductUploadModal && (
                        <AddEditRetrospective
                            onShow={fileUpload.editProductUploadModal}
                            fileName={JSON.parse(state.fuSelectedName).fileName}
                            fileNameId={JSON.parse(state.fuSelectedName).id}
                            onUpdate={productUpdated}
                        />
                    )
                }
            </Container>
            {
                handleWarning && (
                    <WarningModal
                        size="small_overlay"
                        deleteRowUpload={state.deleteRowUpload}
                        deleteAllProductUpload={state.deleteAllProductUpload}
                        onShow={handleWarning} />
                )
            }
            {snackBar.show && <SnackBarDialog
                onShow={snackBar.show}
                text={snackBar.text}
                type={snackBar.type}
                onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
            />}
        </>
    );
}

export default RetrospectiveFileUpload;
