import { Checkbox, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl, Autocomplete } from '@mui/material'
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment"
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import axios from '../../../../../services/utility/axios';
import PageLoader from '../../../../../components/shared/PageLoader';
import { setHasEditPrice, setListOfPrice, setAutocomplete, brandSearch } from '../../../../../store/slices/generalProduct';
import axiosMember from '../../../../../services/utility/axios-member';
import axiosLevyScheme from '../../../../../services/utility/axios-levy-scheme';
import SnackBarDialog from '../../../../../components/shared/SnackBarDialog';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

function AddEditPriceModal({ onShow, size = '', onHide, onSubmitPrice }) {
    const { priceList } = useSelector((state) => {
        return {
            priceList: state.generalProduct
        }
    });
    const dispatch = useDispatch();

    const [state, setState] = useState({
        startDate: null,
        endDate: null,
        price: '',
        onTrade: false,
        discountabl_bopa: false,
        pageLoader: false,
        defaultmode: false,
        formValid: true,
        minDates: null
    })

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    });


    const [member_tier_list, setMember_tier] = useState({
        member_tier_options: [],
        member_tier_selected: priceList.hasEditPrice ? priceList.hasEditPrice.memberTier : null
    })
    // const [levy_list, setLevy_tier] = useState({
    //     levy_options: [],
    //     levy_selected: null
    // })

    const [isError, setIsError] = useState({
        startDate: false,
        price: false,
        member_tier_selected: false
    })


    const [open, setOpen] = useState(onShow);

    const handleClose = () => {
        if (onHide) {
            dispatch(setHasEditPrice(null))
            onHide(false)
        }
        //setState({ ...state, defaultmode: false })
        setMember_tier({ ...member_tier_list, member_tier_options: [], member_tier_selected: null })
    };

    const handleSelf = () => {
        handleClose()
    }
    useEffect(() => {
        const fetchMyAPI = async () => {
            if (member_tier_list.member_tier_options.length === 0) {
                axiosMember.get('/memberTier/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            setMember_tier({ ...member_tier_list, member_tier_options: unique })
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        const { response: { data, status }, message } = error;
                        console.log(error, status, data);
                    });
            }
        }
        fetchMyAPI();
    }, [])
    const handleInputChange = (event) => {
        const { value, name } = event.target;
        if (value === "") {
            setIsError({ ...isError, [name]: true })
            setState({ ...state, [name]: value, defaultmode: false });
        } else {
            setIsError({ ...isError, [name]: false })
            if(name === 'price'){
                setState({ ...state, [name]: value.slice(0,16), defaultmode: true });
            } else {
                setState({ ...state, [name]: value, defaultmode: true });
            }
        }
    }
    const handleCheckboxChange = (event) => {
        const { checked, name } = event.target;
        if (priceList.hasEditPrice !== null) {
            setState({
                ...state,
                [name]: checked,
                defaultmode: true
            });
        } else {
            setState({
                ...state,
                [name]: checked,
                defaultmode: false
            });
        }
    };
    const handleStartDate = (event) => {
        if (event === null || event === 'Invalid date' || event === "") {
            setIsError({ ...isError, startDate: true })
            setState({ ...state, startDate: event === null ? null : moment(event, 'DD/MM/YYYY'), defaultmode: true })
        } else {
            setIsError({ ...isError, startDate: false })
            setState({ ...state, startDate: event === null ? null : moment(event, 'DD/MM/YYYY'), defaultmode: false })
        }


    }
    const handleEndDate = (event) => {
        //setState({ ...state, endDate: moment(event).format('YYYY-MM-DD'), defaultmode: true })
        if (priceList.hasEditPrice !== null) {
            setState({ ...state, endDate: event === null ? null : moment(event, 'DD/MM/YYYY'), defaultmode: true })
        } else {
            setState({ ...state, endDate: event === null ? null : moment(event, 'DD/MM/YYYY'), defaultmode: false })
        }
    }

    useEffect(() => {
        if (member_tier_list.member_tier_selected !== null) {
            setIsError({ ...isError, member_tier_selected: false })
            //handleLevy()
        }
    }, [member_tier_list])

    // const handleLevy = async () => {
    //     if (member_tier_list.member_tier_selected !== null) {
    //         axiosLevyScheme.get(`/levySchemes/memberTier/${member_tier_list.member_tier_selected.id}/drop-down`)
    //             .then(function (response) {
    //                 // handle success
    //                 const { status, data } = response;
    //                 if (data) {
    //                     const unique = data
    //                     setLevy_tier({ ...levy_list, levy_options: unique })
    //                 }
    //             })
    //             .catch(function (error) {
    //                 // handle error
    //                 const { response: { data, status }, message } = error;
    //                 console.log(error, status, data);
    //                 setLevy_tier({ ...levy_list, levy_options: [] })
    //             });
    //     }
    // }
    useEffect(() => {
        if (priceList.hasEditPrice !== null) {
            setState({
                ...state,
                startDate: moment(priceList.hasEditPrice.startDate, 'DD/MM/YYYY'),
                endDate: priceList.hasEditPrice.endDate ? moment(priceList.hasEditPrice.endDate, 'DD/MM/YYYY') : null,
                price: Number(priceList.hasEditPrice.price),
                discountabl_bopa: priceList.hasEditPrice.discountableForBopa
            })

            //setMember_tier({ ...member_tier_list, member_tier_selected: priceList.hasEditPrice.memberTier ? priceList.hasEditPrice.memberTier : member_tier_list })
            //setLevy_tier({ ...levy_list, levy_selected: priceList.hasEditPrice.levyScheme ? { id: priceList.hasEditPrice.levyScheme.levySchemeId, name: priceList.hasEditPrice.levyScheme.levySchemeName } : null })

        } else if ((priceList.listOfPrice.length > 0)) {
            if (priceList.listOfPrice[0].endDate === "" || priceList.listOfPrice[0].endDate === null || priceList.listOfPrice[0].endDate === undefined) {
                setState({ ...state, startDate: moment(priceList.listOfPrice[0].startDate, 'DD/MM/YYYY').add(2, 'days') })
                state.minDates = moment(priceList.listOfPrice[0].startDate, 'DD/MM/YYYY').add(2, 'days')
            } else {
                setState({ ...state, startDate: moment(priceList.listOfPrice[0].endDate, 'DD/MM/YYYY').add(1, 'days') })
                state.minDates = moment(priceList.listOfPrice[0].endDate, 'DD/MM/YYYY').add(1, 'days')
            }
        }
    }, [])

    useEffect(() => {
        if (priceList.hasEditPrice === null) {
            if (state.startDate !== null && state.price !== "" && member_tier_list.member_tier_selected !== null) {
                setState({ ...state, formValid: false, defaultmode: false })
            } else {
                setState({ ...state, formValid: true, defaultmode: true })
            }
        }
    }, [state.startDate, state.price, member_tier_list.member_tier_selected])

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (state.startDate === "" || state.startDate === null || state.startDate === "Invalid date") {
            setIsError({ ...isError, startDate: true })
        } else if (state.price === "") {
            setIsError({ ...isError, price: true })
        } else if (member_tier_list.member_tier_selected === null || member_tier_list.member_tier_selected === "") {
            setIsError({ ...isError, member_tier_selected: true })
        } else if ((state.startDate !== "Invalid date" || state.startDate !== "") && state.price !== "" && member_tier_list.member_tier_selected !== null) {

            if (priceList.hasEditPrice !== null) {
                addingPrice(priceList.hasEditPrice)
            } else {
                addingPrice('newDate')
            }
            // if (priceList.listOfPrice !== null && priceList.listOfPrice.length > 0 && priceList.hasEditPrice === null) {
            //     if (priceList.listOfPrice[0].endDate === "" || priceList.listOfPrice[0].endDate === null) {
            //         let payload = {
            //             "id": priceList.listOfPrice[0].id,
            //             "endDate": moment(state.startDate).subtract(1, 'days').format('YYYY-MM-DD'),
            //             "startDate": priceList.listOfPrice[0].startDate,
            //             "onTrade": priceList.listOfPrice[0].onTrade,
            //             "discountableForBopa": priceList.listOfPrice[0].discountableForBopa
            //         }
            //         try {
            //             const { data } = await axios.post(`prices/${priceList.productId}/updatePrice`, payload)
            //             if (data) {
            //                 onSubmitPrice({ text: "Price Updated...!!!", type: "success" })
            //                 setState({ ...state, pageLoader: false })
            //             }

            //         } catch (error) {
            //             const { response: { data, status } } = error;
            //             if (!data) {
            //                 onSubmitPrice({ text: error.message, type: "error" })
            //             } else {
            //                 onSubmitPrice({ text: data.message, type: "error" })
            //             }
            //             setState({ ...state, pageLoader: false })
            //         }
            //     }
            // } 

        }

    }
    const addingPrice = async (params) => {
        if (moment(state.startDate).format('DD/MM/YYYY') === 'Invalid date' || (state.endDate !== "" && state.endDate !== null && moment(state.endDate).format('DD/MM/YYYY') === 'Invalid date')) {
            setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
        }
        else if (state.endDate !== null && state.endDate !== "" && (state.startDate > state.endDate)) {
            setSnackBar({ ...snackBar, show: true, text: "End date is less than Start Date!", type: "error", });
        }
        else {
            let payload = null;
            if (params === 'newDate') {
                payload = {
                    "price": Number(state.price).toFixed(2),
                    "startDate": moment(state.startDate).format('DD/MM/YYYY'),
                    "endDate": state.endDate ? moment(state.endDate).format('DD/MM/YYYY') : null,
                    "memberTier": member_tier_list.member_tier_selected,
                    "discountableForBopa": state.discountabl_bopa
                }
            } else {
                payload = {
                    "id": params.id,
                    "price": Number(state.price).toFixed(2),
                    "startDate": moment(state.startDate).format('DD/MM/YYYY'),
                    "endDate": state.endDate ? moment(state.endDate).format('DD/MM/YYYY') : null,
                    "memberTier": member_tier_list.member_tier_selected,
                    "discountableForBopa": state.discountabl_bopa
                }
            }

                dateOvelapping(params, payload)

                // if (priceList.listOfPrice.length > 0 && (priceList.listOfPrice[0].endDate !== "" || priceList.listOfPrice[0].endDate !== null || priceList.listOfPrice[0].endDate !== undefined)) {
                //     const startDatePayload = moment(state.startDate).format('DD/MM/YYYY')
                //     const endDatePrevious = priceList.listOfPrice[0].endDate
                //     console.log(endDatePrevious, startDatePayload)

                //     const filteredRowsMemberTier = priceList.listOfPrice.find(levy => levy.memberTier.name === member_tier_list.member_tier_selected.name)
                //     console.log(filteredRowsMemberTier)
                //     if ((startDatePayload === endDatePrevious) && filteredRowsMemberTier) {
                //         setSnackBar({ ...snackBar, show: true, text: "Previous End date overlapping with Current Start Date", type: "error", });
                //     } else {
                //         console.log('hi')
                //         dateOvelapping(params, payload)
                //     }
                // } else {
                //     dateOvelapping(params, payload)
                // }


            
        }
    }
    const dateOvelapping = async (params, payload) => {
        try {
            setState({ ...state, pageLoader: true })
            const url = params === 'newDate' ? 'addPrice' : 'updatePrice'
            const { data } = await axios.post(`prices/${priceList.productId}/${url}`, payload)
            if (data.success) {
                getUpdatedPriceList()
            } else {
                // onSubmitPrice({ text: data.message, type: "error" });
                setSnackBar({ ...snackBar, show: true, text: data.message, type: "error", });
                setState({ ...state, pageLoader: false });
                // handleClose()
            }
        }
        catch (error) {
            const { response: { data, status } } = error;
            if (!data) {
                onSubmitPrice({ text: "System took long to respond, please retry!", type: "error" })
                // onSubmitPrice({ text: error.message, type: "error" })
            } else {
                onSubmitPrice({ text: data.message ? data.message : "Something went wrong!", type: "error" })
            }
            setState({ ...state, pageLoader: false })
            //handleClose()
        }
    }
    const getUpdatedPriceList = async () => {
        try {
            const { data } = await axios.get(`prices/${priceList.productId}/getPrices`)
            const unique = data.resultMap.result.filter((value, index, self) => self.findIndex((m) => m.id === value.id) === index);
            if (data) {
                dispatch(setListOfPrice([]))
                setTimeout(() => {
                    onSubmitPrice({ text: `Price ${priceList.hasEditPrice ? 'Updated' : 'Added'} Successfully!`, type: "success" })
                    setState({ ...state, pageLoader: false })
                    dispatch(setListOfPrice(unique))
                    handleClose()
                }, 500);
            }

        } catch (error) {
            const { response: { data, status } } = error;
            if (!data) {
                onSubmitPrice({ text: "System took long to respond, please retry!", type: "error" })
            } else {
                onSubmitPrice({ text: data.message ? data.message : "Something went wrong!", type: "error" })
            }
            setState({ ...state, pageLoader: false })
            //handleClose()
        }
    }
    useEffect(() => {
        if (priceList.hasEditPrice !== null) {
            setState({
                ...state,
                startDate: moment(priceList.hasEditPrice.startDate, 'DD/MM/YYYY'),
                endDate: priceList.hasEditPrice.endDate ? moment(priceList.hasEditPrice.endDate, 'DD/MM/YYYY') : null,
                price: Number(priceList.hasEditPrice.price).toFixed(2),
                memberTier: priceList.hasEditPrice.memberTier ? priceList.hasEditPrice.memberTier : null,
                discountabl_bopa: priceList.hasEditPrice.discountableForBopa,
                //levyScheme: priceList.levyScheme ? priceList.levyScheme : null
            })

            setMember_tier({ ...member_tier_list, member_tier_selected: priceList.hasEditPrice.memberTier ? priceList.hasEditPrice.memberTier : null })
            //setLevy_tier({ ...levy_list, levy_selected: priceList.hasEditPrice.levyScheme ? { id: priceList.hasEditPrice.levyScheme.levySchemeId, name: priceList.hasEditPrice.levyScheme.levySchemeName } : null })

        }
        else if ((priceList.listOfPrice.length > 0)) {
            if (priceList.listOfPrice[0].endDate === "" || priceList.listOfPrice[0].endDate === null || priceList.listOfPrice[0].endDate === undefined) {
                //setState({ ...state, startDate: moment(priceList.listOfPrice[0].startDate, 'DD/MM/YYYY').add(1, 'days') })
            } else {
                //setState({ ...state, startDate: moment(priceList.listOfPrice[0].endDate, 'DD/MM/YYYY').add(1, 'days') })
            }
        }

    }, [])


    useEffect(() => {
        const inputNumber = document.querySelectorAll('.inputNumber');
        inputNumber.forEach(el => el.addEventListener('keypress', evt => {
            if (evt.which === 8) {
                return;
            }
            if (evt.which < 42 || evt.which > 60) {
                evt.preventDefault();
            }
        }));
    }, []);

    const autoC = useRef(null);

    const goTodaypsd = (event) => {        
        setState({ ...state, startDate: moment() });
    }

    const goTodayped = (event) => {        
        setState({ ...state, endDate: moment() });
    }

    const CustomActionbarpsd = () => {

        return (
            <Box sx={{ marginTop: '-15px', padding: '0 0 10px 0', textAlign: 'center' }}>
                <Button sx={{ fontSize: '16px' }} onClick={(e) => goTodaypsd(e)}>Today</Button>
            </Box>
        );
    };

    const CustomActionbarped = () => {

        return (
            <Box sx={{ marginTop: '-15px', padding: '0 0 10px 0', textAlign: 'center' }}>
                <Button sx={{ fontSize: '16px' }} onClick={(e) => goTodayped(e)}>Today</Button>
            </Box>
        );
    };

    return (
        <>


            <Modal
                open={open}
                aria-labelledby="add-edit-price-title"
                aria-describedby="add-edit-price-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Box component="div" className="overlay" id="addEditModal">
                        {state.pageLoader && <PageLoader />}
                        <div className={`overlay-box ${size ? size : ''}`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-header text-center"> Add New Price
                                        <CancelIcon className="closeBtn" onClick={handleClose} />
                                    </div>
                                    <div className="overlay-content">
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={3} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            <FormLabel htmlFor="price_start_date" className="fontSemiBold mandatory">Start Date</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={8}>
                                                        <Item>
                                                            <div className="input-group">
                                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                    <DatePicker
                                                                        value={state.startDate}
                                                                        components={{ ActionBar: CustomActionbarpsd }}
                                                                        onChange={(e) => {
                                                                            handleStartDate(e);
                                                                        }}
                                                                        name="price_start_date"
                                                                        inputFormat="DD/MM/YYYY"
                                                                        disabled={priceList.hasEditPrice !== null}
                                                                        //defaultCalendarMonth={state.startDate ? state.startDate : null}
                                                                        //minDate={priceList.listOfPrice.length > 0 ? moment(priceList.listOfPrice[0].endDate, 'DD/MM/YYYY').add(1, 'days') : null}
                                                                        // eslint-disable-next-line max-len 
                                                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                            <Box component="div" className='date-picker-input'>
                                                                                <FormControl size="small" fullWidth>
                                                                                    <TextField size="small" id="price_start_date"
                                                                                        ref={inputRef} {...inputProps}
                                                                                        placeholder=""
                                                                                        aria-describedby="price_start_date_error"
                                                                                        error={isError.startDate}
                                                                                        autoComplete="off"
                                                                                    />
                                                                                    {isError.startDate && (<FormHelperText id="price_start_date_error" className='errorHelperTxt'>Please select start date</FormHelperText>)}
                                                                                    <Box className='date-picker-icon'>
                                                                                        {InputProps?.endAdornment}
                                                                                    </Box>
                                                                                </FormControl>
                                                                            </Box>
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                            </div>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={3} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            <FormLabel htmlFor="price_end_date" className="fontSemiBold">End Date</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={8}>
                                                        <Item>
                                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                <DatePicker
                                                                    defaultCalendarMonth={state.startDate ? moment(state.startDate, "DD/MM/YYYY").add(1, 'days') : null}
                                                                    components={{ ActionBar: CustomActionbarped }}
                                                                    value={state.endDate}
                                                                    onChange={(e) => {
                                                                        handleEndDate(e);
                                                                    }}
                                                                    name="price_end_date"
                                                                    inputFormat="DD/MM/YYYY"
                                                                    minDate={moment(state.startDate, "DD/MM/YYYY").add(1, 'days')}
                                                                    disabled={(state.startDate === null || state.startDate === '') ? true : false}
                                                                    // eslint-disable-next-line max-len
                                                                    renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                        <Box component="div" className='date-picker-input'>
                                                                            <FormControl size="small" fullWidth>
                                                                                <TextField size="small" id="price_end_date"
                                                                                    ref={inputRef} {...inputProps}
                                                                                    placeholder=""
                                                                                    aria-describedby="price_end_date_error"
                                                                                    autoComplete="off"
                                                                                />
                                                                                {isError.endDate && (<FormHelperText id="price_end_date_error">Error Here</FormHelperText>)}
                                                                                <Box className='date-picker-icon'>
                                                                                    {InputProps?.endAdornment}
                                                                                </Box>
                                                                            </FormControl>
                                                                        </Box>
                                                                    )}
                                                                />
                                                            </LocalizationProvider>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={3} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            <FormLabel htmlFor="price" className="fontSemiBold mandatory">Price</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={8}>
                                                        <Item >
                                                            <div className="input-group" >
                                                                <TextField
                                                                    inputProps={{ maxLength: 100 }}
                                                                    size="small"
                                                                    id="product_price"
                                                                    aria-describedby="price_error"
                                                                    onChange={(e) => handleInputChange(e)}
                                                                    value={state.price}
                                                                    name="price"
                                                                    type={'number'}
                                                                    className="inputNumber"
                                                                    error={isError.price}
                                                                    fullWidth
                                                                    step="any"
                                                                    onBlur={(e) => setState({ ...state, [e.target.name]:  e.target.value === '' ? "" : (e.target.value == 0 ? 0 : Number(e.target.value).toFixed(2)) })}
                                                                />
                                                                {isError.price && (<FormHelperText className='errorHelperTxt' id="price_error">
                                                                    Please enter price
                                                                </FormHelperText>)}
                                                            </div>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={3} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="general_member_tier" className="fontSemiBold mandatory">Purchasing Tier</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={8}>
                                                        <Item>
                                                            <Autocomplete
                                                                defaultValue={priceList.hasEditPrice ? priceList.hasEditPrice.memberTier : member_tier_list.member_tier_selected ? member_tier_list.member_tier_selected : null}
                                                                id="general_member_tier_search"
                                                                options={member_tier_list.member_tier_options}
                                                                getOptionLabel={(option) => option.name || ""}
                                                                componentsProps={{
                                                                    paper: {
                                                                        sx: {
                                                                            fontSize: '0.85rem'
                                                                        }
                                                                    }
                                                                }}
                                                                onChange={(event, newValue) => {
                                                                    setIsError({ ...isError, member_tier_selected: true })
                                                                    if (event.target.value !== "" || event.target.value !== null) {
                                                                        setMember_tier({ ...member_tier_list, member_tier_selected: newValue })
                                                                        setState({ ...state, levyScheme: null, defaultmode: true })
                                                                        if (!(member_tier_list.member_tier_selected && newValue && (member_tier_list.member_tier_selected.name === newValue.name))) {
                                                                            if (autoC.current) {
                                                                                // eslint-disable-next-line prefer-destructuring
                                                                                const ele = autoC.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
                                                                                // eslint-disable-next-line prefer-destructuring
                                                                                const ele2 = autoC.current.getElementsByClassName('MuiOutlinedInput-input')[0];
                                                                                if (ele) {
                                                                                    ele.click();
                                                                                    ele2.blur()

                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }}
                                                                renderInput={(params) =>
                                                                    <FormControl size={'small'} fullWidth >
                                                                        <div className="input-group">
                                                                            <TextField {...params}
                                                                                size="small"
                                                                                id="general_member_tier"
                                                                                aria-describedby="general_member_tier-error"
                                                                                error={isError.member_tier_selected}
                                                                            />
                                                                            {isError.member_tier_selected &&
                                                                                (<FormHelperText className='errorHelperTxt' id="general_member_tier-error"> This field is mandatory </FormHelperText>)}
                                                                        </div>
                                                                    </FormControl>
                                                                }
                                                            />
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            {/* <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={3} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="general_levy_search" className="fontSemiBold">Associated Levy</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={8}>
                                                        <Item>
                                                            <Autocomplete
                                                                ref={autoC}
                                                                disabled={member_tier_list.member_tier_selected === null}
                                                                defaultValue={priceList.hasEditPrice && priceList.hasEditPrice.levyScheme ? state.levyScheme : null}
                                                                id="general_levy_search"
                                                                options={levy_list.levy_options}
                                                                getOptionLabel={(option) => option.name || ""}
                                                                componentsProps={{
                                                                    paper: {
                                                                        sx: {
                                                                            fontSize: '0.85rem'
                                                                        }
                                                                    }
                                                                }}
                                                                onChange={(event, newValue) => {
                                                                    setState({ ...state, defaultmode: true })
                                                                    if (event.target.value !== "" || event.target.value !== null) {
                                                                        setLevy_tier({ ...levy_list, levy_selected: newValue })
                                                                    }
                                                                }}
                                                                renderInput={(params) =>
                                                                    <FormControl size={'small'} fullWidth >
                                                                        <div className="input-group">
                                                                            <TextField {...params}
                                                                                size="small"
                                                                                id="general_levy"
                                                                                aria-describedby="general_levy-error"
                                                                            />
                                                                        </div>
                                                                    </FormControl>
                                                                }
                                                            />
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item> */}
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            &nbsp;
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Grid container direction="row" alignItems="center">
                                                                {/* <FormControlLabel
                                                                    control={
                                                                        <Checkbox size="medium"
                                                                            id="onTrade"
                                                                            checked={state.onTrade}
                                                                            onChange={(e) => handleCheckboxChange(e)}
                                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                                            name="onTrade"
                                                                        />}
                                                                    label="On Trade" /> */}
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox size="medium"
                                                                            id="discountabl_bopa"
                                                                            checked={state.discountabl_bopa}
                                                                            onChange={(e) => handleCheckboxChange(e)}
                                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                                            name="discountabl_bopa"
                                                                        />}
                                                                    label="Discountable for BOPA" />
                                                            </Grid>
                                                        </Item>
                                                    </Grid>
                                                </Grid>

                                            </Item>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            &nbsp;
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Grid container direction="row" alignItems="center">
                                                            <Button 
                                                                    variant="contained" 
                                                                    type="submit" 
                                                                    sx={{ marginRight: '15px' }}
                                                                    //disabled={!priceList.hasEditPrice ? state.formValid : state.defaultmode === false} 
                                                                    disabled={priceList.hasEditPrice ? (isError.startDate || isError.price || isError.member_tier_selected) : (state.formValid || state.defaultmode)} 
                                                                >Save</Button>
                                                                <Button variant="outlined" onClick={handleSelf}>Cancel</Button>
                                                            </Grid>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                    {snackBar.show && <SnackBarDialog
                        onShow={snackBar.show}
                        text={snackBar.text}
                        type={snackBar.type}
                        onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                    />}
                </form>

            </Modal>
        </>
    );
}

export default AddEditPriceModal;