import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Box } from '@mui/material';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import { useState } from 'react';
import SnackBarDialog from '../SnackBarDialog';
import refreshLambda from '../../../services/utility/refresh-lambda';
import PageLoader from '../PageLoader';
import PageLoaderStyle2 from '../PageLoaderStyle2';

function handleClick(event) {
    event.preventDefault();
}

export default function BreadcrumbNavs({ pageTitle }) {

    const [state, setState] = useState({
        showLoader: false,
        pageLoader2: false
    })
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
        vertical: '',
        horizontal: '',
        duration: 0,

    })
    const lambdaWarmer = () => {
        onReprocessReverse()
    }
    const onReprocessReverse = async () => {
        try {
            setState({ ...state, pageLoader2: true });
            const { data } = await refreshLambda.get('/warmup');

            if (data) {
                setSnackBar({ ...snackBar, vertical: 'top', duration: 6000, horizontal: 'center', show: true, text: "System is warming up, please check after 9 minutes", type: "info" })
            }
            setState({ ...state, pageLoader2: false });
        } catch (error) {
            const { response: { data, status } } = error;
            if (status === 500) {
                setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
            } else {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
            }
            setState({ ...state, pageLoader2: false });
        }
    }
    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/" onClick={handleClick}>
            {pageTitle}
        </Link>,
        <Typography key="3">
            {pageTitle}
        </Typography>,
    ];

    return (
        <>
            {state.pageLoader && <PageLoader />}
            {state.pageLoader2 && <PageLoaderStyle2 />}
            <Box component="div" className='breadcrumbNavs'>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <Breadcrumbs component="div" separator="››" aria-label="breadcrumb">
                            {breadcrumbs}
                        </Breadcrumbs>
                        <div className='pageHeading'>
                            <h1>{pageTitle}</h1>
                        </div>
                    </div>
                    <Box className='lambdaRefreshBtn'>
                        <Box component={'a'} sx={{ display: 'flex', alignItems: 'center' }} onClick={lambdaWarmer}>
                            <LocalFireDepartmentIcon color="primary" /> <span style={{ marginLeft: '5px', color: '#B253AE' }}>Warm System</span>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {
                state.showLoader && (
                    <div className="loadingWarmer">
                        <Box className='loadingBx'>
                            <div className="bounceball"></div>
                            <div className="text">NOW LOADING</div>
                        </Box>
                    </div>
                )
            }
            {snackBar.show && <SnackBarDialog
                duration={snackBar.duration}
                vpositon={snackBar.vertical}
                hposition={snackBar.horizontal}
                onShow={snackBar.show}
                text={snackBar.text}
                type={snackBar.type}
                onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
            />}
        </>
    );
}
