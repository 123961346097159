import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editTab: true,
  hasEditPdc: null,
  editTabData: null,
  hasEditData: null,
  edi_file_log_supplier_type: '',
  PdcFormValid: false,
  isError: {
    edi_file_log_supplier_type: true
  }
}

const ediFileLog = createSlice({
  name: 'ediFileLog_product',
  initialState,
  reducers: {
    brandSearch: (state, action) => {
      const { parent, key, value } = action.payload;
      state[parent][key] = value;
    },
    ediFileLogInput: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
      state[key].length === 0 ? state.isError[key] = true : state.isError[key] = false
    },
    getIsError: (state, action) => {
      const { key, value } = action.payload;
      state.isError[key] = value
    },
    setAutocomplete: (state, action) => {
      const { parent, key, value } =  action.payload;
      state[parent][key] = value
    },
    hasTabEdit: (state, action) => {
      const { editTab } =  action.payload;
      state.editTab = editTab
    },
    hasTabEditData: (state, action) => {
      const { editData } =  action.payload;
      state.editTabData = editData
    },
    setHasEditData: (state, action) => {
      state.hasEditData = action.payload;
    },
    setediFileLogFormStatus: (state, action) => {
      state.PdcFormValid = action.payload;
    },
    resetediFileLog: () => {
      return initialState
    },
  }
})

export const { brandSearch, hasTabEditData, setAutocomplete, getIsError, ediFileLogInput, hasTabEdit, resetediFileLog, setHasEditData, setediFileLogFormStatus } = ediFileLog.actions

export default ediFileLog.reducer