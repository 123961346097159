import { Container, Box, Modal, Button, Grid, Checkbox, Popper, Grow, Paper, ClickAwayListener, MenuList, ButtonGroup, Stack, TextField, FormControl, FormHelperText, FormLabel } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TableFilters } from "../../components/shared/data-table/TableFilters";
import { useEffect, useState } from "react";
import { InputText } from "../../components/shared/form/InputText";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AutoComplete } from "../../components/shared/form/AutoComplete";
import { SelectDropDown } from "../../components/shared/form/Select";
import { } from '@mui/material';
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange, setSupplierReportFromDate, filterResetFlagChange } from "../../store/slices/externalFilter";
import { DataTableMemberSupplierReports } from "../../components/shared/data-table/DataTableMemberSupplierReports";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import moment from "moment";
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import SendIcon from '@mui/icons-material/Send';
import { warningModal, handleAddEditModal, handleModalState, setNewPriceModal } from "../../store/slices/modals";
import WarningModal from "../../components/shared/modals/WarningModal";
import { setRowPage, resetDataGrid, setPageNo } from '../../store/slices/dataGrid';
import { useLayoutEffect } from "react";
import { AutoCompleteStatic } from "../../components/shared/form/AutoCompleteStatic";
import { saveSomeData } from '../../services/utility/common-utils';
import axiosNusslReports from '../../services/utility/axios-nussl-reports';
import SnackBarDialog from '../../components/shared/SnackBarDialog';
import PageLoader from '../../components/shared/PageLoader';
import EmailSendModal from './EmailSendModal';
import { setEmailInfo } from '../../store/slices/supplierReports';
import { smaInput, setMemberCode, setSupplierName } from '../../store/slices/supplierMemberAccounts';
import CommonPopUp from '../products/addEditPdc/pdc-tabs/CommonPopUp';
import pako from 'pako';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import axiosEmailService from "../../services/utility/axios-email-service";
import { setEmailStatus } from '../../store/slices/membersupplierReports';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(2),
    boxShadow: "none",
    background: "none"
}));

const IconMenuLink = ({ params, onDownload, onEmailSend }) => {
    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'download') {
            if (onDownload) {
                onDownload(row)
            }
        }
        if (action === 'email') {
            if (onEmailSend) {
                onEmailSend(row)
            }
        }
        setAnchorEl(null);
    };

    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'download')}>Download</MenuItem>
            {params.row.emailReportStatus === 'SentAndSucceeded' ? "" : (<MenuItem onClick={(e) => handleClose(e, 'email')}>Send Email</MenuItem>)}
        </Menu>
    </>)
}
function SupplierReports() {
    const columns = [
        {
            headerName: "#",
            field: "uid",
            minWidth: 50,
        },
        {
            headerName: "Report Id",
            field: "id",
            minWidth: 50,
        },
        {
            headerName: "PID",
            field: "processId",
            minWidth: 80,
            flex: 0.6
        },
        {
            headerName: "Created on",
            field: "creationDate",
            minWidth: 100,
            flex: 1
        },
        {
            headerName: "File Name",
            field: "fileName",
            minWidth: 120,
            flex: 1.5
        },
        {
            headerName: "SC Id",
            field: "statementCycleId",
            minWidth: 100,
            flex: 1
        },
        {
            headerName: "SC Stage",
            field: "statementCycleStage",
            minWidth: 90,
            flex: 1
        },
        {
            headerName: "Email Status",
            field: "emailReportStatusLabel",
            minWidth: 90,
            flex: 1

        },
        {
            headerName: "Email Date",
            field: "emailSentDate",
            minWidth: 90,
            flex: 1,
            valueFormatter: ({ value }) => {
                if (value === null || value === "" || value === undefined) {
                    return ""
                } else {
                    return value
                }
            }
        },
        {
            headerName: "Report Type",
            field: "typeLabel",
            minWidth: 90,
            flex: 1
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            minWidth: 90,
            renderCell: (params) => <IconMenuLink params={params} onDownload={onDownload} onEmailSend={onEmailSend} />
        }
    ];

    const [state, setState] = useState({

        openNewProductModal: false,
        disableBulkEmail: true,
        defaultGrid: {
            pagetitle: "Supplier Group Reports",
            productActionButton: true,
            hasExport: true,
            addEditButton: true,
            apiUrl: 'supplierGroupReports',
            searchUrl: 'supplierGroupReports/filter'
        },
        pageLoader: false
    });

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const handleStartDate = (event) => {
        const sDate = (event === null) ? null : moment(event, 'DD/MM/YYYY');
        dispatch(setSupplierReportFromDate(sDate))
    };

    const { supplier_report_file_name, fromDate, supplier_report_output_type, supplier_report_email_status,
        supplier_report_supplier_type, addProductwarning, showAddEditModal, handleEmailModal, sma_container, showNewPriceoneModal, emailrowList } = useSelector((state) => {
            return {
                supplier_report_file_name: state.externalFilter.supplier_report_file_name,
                fromDate: state.externalFilter.from_date,
                supplier_report_output_type: state.externalFilter.supplier_report_output_type,
                supplier_report_email_status: state.externalFilter.supplier_report_email_status,
                supplier_report_supplier_type: state.externalFilter.supplier_report_supplier_type,
                resetTable: state.dataGridTable,
                addProductwarning: state.modalActions.closeWaringAddProduct,
                showAddEditModal: state.modalActions.hideAddEditModal,
                handleEmailModal: state.modalActions.handleEmailModal,
                sma_container: state.smas,
                showNewPriceoneModal: state.modalActions.priceModal,
                emailrowList: state.membersupplierReports
            }
        });

    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const uname = accounts[0] && accounts[0].name;

    const dispatch = useDispatch();

    const handleFilterInput = (value, id) => {
        //setState({ ...state, [id]: value });
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    };

    const EmailStatus = [{ text: 'Not sent', value: 'NotSent' }, { text: 'Sent and Failed', value: 'SentAndFailed' }, { text: 'Sent and Succeeded', value: 'SentAndSucceeded' }];
    const outputTypes = [{ text: 'LevyBill', value: 'LevyBill' }, { text: 'RetroBill', value: 'RetroBill' }, { text: 'BillDiscounts', value: 'BillDiscounts' }, { text: 'Remittance', value: 'Remittance' }];

    const selectedAutoComplete = (value, id) => {
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    }

    const filterBtn = () => {
        dispatch(resetDataGrid());
        var dynamicFilterObjects = {};
        var nofilter = true;
        if (supplier_report_output_type !== '' && supplier_report_output_type !== null) {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "type": supplier_report_output_type
            }
            nofilter = false;
        }
        if (fromDate !== '' && fromDate !== null) {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "fromDate": moment(fromDate).format('DD/MM/YYYY')
            }
            nofilter = false;
        }
        if (supplier_report_file_name !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "fileName": supplier_report_file_name
            }
            nofilter = false;
        }
        if (supplier_report_email_status !== '' && supplier_report_email_status !== null) {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "emailReportStatus": supplier_report_email_status
            }
            nofilter = false;
        }
        if (supplier_report_supplier_type !== '' && supplier_report_supplier_type !== null) {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "supplierId": supplier_report_supplier_type.id
            }
            nofilter = false;
        }

        if (fromDate !== "" && fromDate !== null && moment(fromDate).format('DD/MM/YYYY') === 'Invalid date') {
            setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
            nofilter = true;
        }
        else {
            
            dispatch(applyFilter({
                key: 'filterList',
                value: dynamicFilterObjects
            }));
            if (nofilter === false) {
                setState({ ...state, disableBulkEmail: false });                
            }
            else {
                setState({ ...state, disableBulkEmail: true });                
            }
            dispatch(filterFlagChange(true));
            dispatch(filterResetFlagChange(false));
            dispatch(setRowPage({ pageData: 10 }));
            dispatch(setPageNo({ page: 1 }));

        }


    }
    const resetFilter = () => {
        if ((supplier_report_output_type || fromDate || supplier_report_file_name || supplier_report_email_status || supplier_report_supplier_type) !== '') {
            dispatch(resetExternalFilter())
            dispatch(resetDataGrid());
            dispatch(setMemberCode({
                key: 'smaSupplierNameOnModal',
                value: ""
            }))
            dispatch(smaInput({
                key: 'sma_supplier_name',
                value: ""
            }))
        }

        dispatch(filterResetFlagChange(true));
        setState({ ...state, disableBulkEmail: true });
    }
    const addProductModal = () => {
        dispatch(handleAddEditModal({
            showAddEdit: !showAddEditModal
        }))
    }
    const hideNewProductModal = (event) => {
        setState({ ...state, openNewProductModal: false })
    }
    const onDownload = async (params) => {

        try {
            setState({ ...state, pageLoader: true })
            const fileDetails = {
                id: params.id,
                fileName: params.fileName
            }
            const { data } = await axiosNusslReports.post('/download/report', fileDetails);
            if (data) {

                setState({
                    ...state,
                    pageLoader: false,
                });

                // console.log(data.content, pako);
                // const gzipedData = Buffer.from(data.content, "base64");
                // console.log(gzipedData);
                // const ungzipedData = pako.ungzip(gzipedData, { to: 'string' });
                // console.log('ungziped data', new TextDecoder().decode(ungzipedData));
                // console.log(ungzipedData);

                // let decompressedData = pako.inflate(data.content);
                // let decompressedData = pako.ungzip(data.content);
                // let decompressedData = JSON.parse(pako.inflate(data.content, { raw: true }));
                // let decompressedData = pako.inflateRaw(data.content, { to: 'string', raw: true });
                // let decompressedData = pako.inflate(data.content, { to: 'string', raw: true, chunkSize: '128K' });




                saveSomeData(data.content, params.mimeType, data.fileName);
                // saveSomeData(decompressedData, params.mimeType, data.fileName);
                setSnackBar({ ...snackBar, show: true, text: "File downloaded!", type: "success" })
            }

        } catch (error) {
            console.log(error);
            const { response: { data, status } } = error;
            if (!data) {
                setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
            } else {
                setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
            }
            setState({ ...state, pageLoader: false })
        }
    }

    const onEmailSend = (row) => {
        dispatch(setEmailInfo({ ...row, supplierORmember: 'supplier' }))
        dispatch(handleModalState({
            key: 'handleEmailModal',
            value: true
        }))
    }

    const CommonPopUpModal = () => {
        dispatch(setNewPriceModal(true))
    }

    const resetLookups = () => {
        dispatch(setMemberCode({
            key: 'smaSupplierNameOnModal',
            value: ""
        }))
        dispatch(smaInput({
            key: 'sma_supplier_name',
            value: ""
        }))
    }

    const hidePriceModal = (event, reason) => {
        if (reason) {
            dispatch(setFilter({
                key: 'supplier_report_supplier_type',
                value: reason ? reason : null
            }))
        }
        dispatch(setNewPriceModal(event));
    }

    useEffect(() => {
        dispatch(setSupplierName({
            key: 'smaSupplierNameOnModal',
            value: null
        }))
        /* load first first time */
        filterBtn();
    }, []);

    
    const sendBulkEmailsDispatch = () => {
        
        dispatch(setEmailStatus({
            key: 'supplierStartBulkEmail',
            value: true
        }))
        dispatch(warningModal({
            yesHide: !addProductwarning
        }));
    }

    useEffect(() => {
        if (emailrowList.supplierConfirmBulkEmail) {
            sendBulkEmails();
        }
    }, [emailrowList.supplierConfirmBulkEmail]); 

    const sendBulkEmails = async (event, params) => {
        if(event) { event.preventDefault(); }

        let processId = emailrowList.sendEmailRows;
        let payload = null;

        if (processId.length > 0) {
            try {
                 console.log(processId);
                setState({ ...state, pageLoader: true })
                const selectedRowsData = processId.map((items) => {
                    return {
                        "emailType": "supplieremail",
                        "user": uname,
                        "supplierGroupReportSearchArguments": {
                            "fileName": items.fileName,
                            "fromDate": items.creationDate,
                            "supplierId": items.supplierId,
                            "type": items.type,
                            "emailReportStatus": items.emailReportStatus !== 'Sent and succeeded' ? 'NotSent' : '',
                            "email": (items.email !== '' ? items.email : null)
                        }                    
                    }
                });
                // console.log(selectedRowsData);           

                const { data } = await axiosEmailService.post('/sqs/email', selectedRowsData);
                if (data) {
                    resetBulkEmailsDispatch();
                    setState({ ...state, pageLoader: false })
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" });
                    dispatch(filterFlagChange(true));
                    dispatch(filterResetFlagChange(false));
                }
                else {
                    setSnackBar({ ...snackBar, show: true, text: "Bulk email process failed!", type: "error" })
                }


            } catch (error) {
                console.log(error);
                // const { response: { data, status } } = error;
                // if (!data) {
                //     setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                // } else {
                //     setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                // }
                setState({ ...state, pageLoader: false });
                resetBulkEmailsDispatch();
            }
        } else {
            setSnackBar({ ...snackBar, show: true, text: "No reports available for mail!", type: "error" });
            resetBulkEmailsDispatch();
        }
    }

    const resetBulkEmailsDispatch = () => {
        dispatch(setEmailStatus({
            key: 'supplierStartBulkEmail',
            value: false
        }));
        dispatch(setEmailStatus({
            key: 'supplierConfirmBulkEmail',
            value: false
        }));
    }

    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Supplier Group Reports" />
                <Box sx={{ marginBottom: 2.5 }}>
                    <TableFilters
                        filterFields={<>
                            <Box component="div"><SelectDropDown onChange={handleFilterInput} values={outputTypes} size="small" id="supplier_report_output_type" label="Output Type" name="supplier_report_output_type" /></Box>
                            <Box component="div">
                                <FormLabel htmlFor="price_start_date" className="fontBold">From Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        value={fromDate}
                                        onChange={(e) => { handleStartDate(e) }}
                                        name="from_date" label="From Date"
                                        inputFormat="DD/MM/YYYY"
                                        // eslint-disable-next-line max-len
                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                            <Box component="div" sx={{ paddingTop: '6px' }} className='date-picker-input'>
                                                <FormControl size="small" fullWidth>
                                                    <TextField size="small" id="from_date"
                                                        ref={inputRef} {...inputProps}
                                                        placeholder=""
                                                        aria-describedby="from_date_error"
                                                        autoComplete="off"
                                                    />
                                                    <Box className='date-picker-icon'>
                                                        {InputProps?.endAdornment}
                                                    </Box>
                                                </FormControl>
                                            </Box>
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>
                            <Box component="div"><InputText onChange={handleFilterInput} maxWidth={50} label="File Name" id="supplier_report_file_name" size="small" /></Box>
                            <Box component="div"><SelectDropDown onChange={handleFilterInput} values={EmailStatus} size="small" id="supplier_report_email_status" label="Email Status" name="supplier_report_email_status" /></Box>
                            <Box component="div"><AutoCompleteStatic endPoint="/supplier/look-up" onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} id="supplier_report_supplier_type" label="Supplier" name="supplier_report_supplier_type" /></Box>
                            {/* <Box component="div">
                                <FormLabel htmlFor="supplier_invoice_member_account"  className="fontBold">Supplier</FormLabel>
                                <Grid container alignItems="center" sx={{marginTop: '6px'}}>

                                    <Grid item xs={12} md={11}>
                                        <Item>
                                            <Box component="div">
                                                <FormControl size={'small'} fullWidth>
                                                    <TextField
                                                        inputProps={{ maxLength: 100 }}
                                                        size="small"
                                                        id="supplier_report_supplier_type"
                                                        value={(sma_container.smaSupplierNameOnModal ? sma_container.smaSupplierNameOnModal.name : '')}
                                                        aria-describedby="supplier_report_supplier_type_error"
                                                        
                                                        name="supplier_report_supplier_type"
                                                        disabled={true}
                                                    />

                                                </FormControl>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        <Item>
                                            <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0, marginRight: '5px' }} onClick={resetLookups} startIcon={<ClearIcon />}></Button>
                                            <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={CommonPopUpModal} startIcon={<EditIcon />}></Button>
                                        </Item>
                                    </Grid>

                                </Grid>
                            </Box> */}
                        </>}
                        filterCheckboxes={<>

                        </>}
                        filterBtns={
                            <>
                                <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                <Button variant="contained" onClick={filterBtn}>Apply</Button>
                            </>}>
                    </TableFilters>
                </Box>
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -3 } }}>
                    {/* <div className='table-actionable'>
                        <Button variant="contained" color="secondary" size='small' startIcon={<SendIcon />}>
                            Export as Excel 
                        </Button>
                    </div> */}
                    <div className='table-actionable'>
                        <Button variant="contained" size='small' disabled={state.disableBulkEmail} onClick={(e) => { sendBulkEmailsDispatch(e) }} startIcon={<SendIcon />}>
                            Send Email
                        </Button>
                    </div>
                </Box>
                <DataTableMemberSupplierReports defaultGrid={state.defaultGrid} columns={columns}>

                </DataTableMemberSupplierReports>
                {
                    handleEmailModal && (
                        <EmailSendModal
                            onShow={handleEmailModal}
                        />
                    )
                }
                {
                    addProductwarning && (
                        <WarningModal size="small_overlay" onShow={addProductwarning} />
                    )
                }
                {snackBar.show && <SnackBarDialog
                    onShow={snackBar.show}
                    text={snackBar.text}
                    type={snackBar.type}
                    onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                />}

                {
                    showNewPriceoneModal && (
                        <CommonPopUp
                            onShow={showNewPriceoneModal} size="medium_overlay"
                            supplierMemberCode={{
                                title: 'Supplier Details',
                                type: 'supplier'
                            }}
                            onHide={(e, reason) => hidePriceModal(e, reason)}
                        />
                    )
                }
            </Container>

        </>
    );
}

export default SupplierReports;
