import { Container, Box, Modal, Button, Grid, Checkbox, Popper, Grow, Paper, ClickAwayListener, MenuList, ButtonGroup, FormLabel, FormControl, TextField, FormControlLabel, Autocomplete, FormHelperText } from '@mui/material'
import IconButton from "@mui/material/IconButton";
import LoadingButton from '@mui/lab/LoadingButton';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from '@mui/icons-material/Edit';
import { TableFilters } from "../../../components/shared/data-table/TableFilters";
import { useEffect, useState } from "react";
import { InputText } from "../../../components/shared/form/InputText";
import { AutoComplete } from "../../../components/shared/form/AutoComplete";
import { SelectDropDown } from "../../../components/shared/form/Select";
import { } from '@mui/material';
import { ThreeStateCheckbox } from "../../../components/shared/form/ThreeStateCheckbox";
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange } from "../../../store/slices/externalFilter";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import BreadcrumbNavs from "../../../components/shared/breadcrumbs/Breadcrumbs";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { warningModal, handleAddEditModal } from "../../../store/slices/modals";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { setRowPage, resetDataGrid, setPageNo } from '../../../store/slices/dataGrid'
import { useLayoutEffect } from "react";
import {
    hasTabEdit, setHasEditData, memberInput, setMemberDate, setAutocomplete, setMembersData, getIsError, setMemberFormStatus, setMemberEdit, updateNextBtnStatus,
    resetMemberAccounts, setHasMemberAccountEditData, memberBasicInput, setMemberAccountEdit, resetMember
} from "../../../store/slices/memberBclValues";
import PageLoader from "../../../components/shared/PageLoader";
import Typography from '@mui/material/Typography';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import axiosMember from '../../../services/utility/axios-member';
import SnackBarDialog from "../../../components/shared/SnackBarDialog";
import { Routes, Route, useNavigate } from 'react-router-dom';
import { DataTableClientSide } from '../../../components/shared/data-table/DataTableClientSide';
import refreshLambda from '../../../services/utility/refresh-lambda';
import PageLoaderStyle2 from '../../../components/shared/PageLoaderStyle2';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';


const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: '2px',
    paddingRight: '15px',
    boxShadow: "none"
}));

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    marginBottom: '50px'
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'revert',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {

    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(4),
    borderTop: '1px solid rgba(0, 0, 0, .125)',

}));

var c_newmemberid = 0;

const MemberDetails = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { memberName, activeMember, addProductwarning, showAddEditModal, general_product_fields, handleWarning, member_fields } = useSelector((state) => {
        return {
            activeMember: state.externalFilter.active_member,
            resetTable: state.dataGridTable,
            filterFlag: state.externalFilter.filterApplied,
            addProductwarning: state.modalActions.closeWaringAddProduct,
            showAddEditModal: state.modalActions.hideAddEditModal,
            handleWarning: state.modalActions.closeWaringAddProduct,
            editTabData: state.generalProduct.editTabData,
            general_product_fields: state.generalProduct,
            member_fields: state.memberBclEntry
        }
    });

    const [state, setState] = useState({
        active_member: true,
        loader: false,
        openNewProductModal: false,
        pageLoader: false, // member_fields.editMembersTab ? true 
        location: 'Member Details',
        locationlabel: "Member Account",
        formValid: false,
        newmemberid: 0,
        defaultmode: false,
        mmaMaster: false,
        pageLoader2: false,
        defaultGrid: {
            productActionButton: true,
            hasExport: true,
            addEditButton: true,
            apiUrl: `members/7/memberAccounts`,
            searchUrl: `members/7/memberAccounts`
            // apiUrl: `members/${member_fields.member_id ? member_fields.member_id : 0}/memberAccounts`, member_fields.member_id ? false : 
            // searchUrl: `members/${member_fields.member_id ? member_fields.member_id : 0}/memberAccounts`
        }
    });

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
        vertical: '',
        horizontal: '',
        duration: 0
    });

    const IconMenuLink = ({ params }) => {
        const { row } = params;
        const [anchorEl, setAnchorEl] = useState(null);
        const open = Boolean(anchorEl);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = (event, action) => {
            event.preventDefault();
            if (action === 'edit_member') {
                setEditMemberAccountData(row);
                // dispatch(handleAddEditModal({
                //     showAddEdit: !showAddEditModal
                // }))
                // dispatch(hasTabEdit({ editTab: false }))

                // 

            }
            setAnchorEl(null);
        };
        const setEditMemberAccountData = async (row) => {

            setState({ ...state, pageLoader: true });
            dispatch(resetMemberAccounts());
            dispatch(setHasMemberAccountEditData(null));


            dispatch(memberBasicInput({
                key: 'member_accountid',
                value: row.id
            }));

            dispatch(memberBasicInput({
                key: 'member_account_name',
                value: row.name
            }));

            dispatch(memberBasicInput({
                key: 'member_account_active',
                value: row.active
            }));

            dispatch(memberBasicInput({
                key: 'member_account_master',
                value: row.master
            }));

            dispatch(memberBasicInput({
                key: 'member_account_cbi',
                value: row.centralBillingId
            }));

            dispatch(memberBasicInput({
                key: 'member_account_ledger',
                value: row.ledgerCode
            }));

            dispatch(memberBasicInput({
                key: 'member_account_cname',
                value: row.contactName
            }));

            dispatch(memberBasicInput({
                key: 'member_account_email',
                value: row.email
            }));

            dispatch(memberBasicInput({
                key: 'member_account_addl1',
                value: row.address1
            }));

            dispatch(memberBasicInput({
                key: 'member_account_addl2',
                value: row.address2
            }));

            dispatch(memberBasicInput({
                key: 'member_account_addl3',
                value: row.address3
            }));

            dispatch(memberBasicInput({
                key: 'member_account_town',
                value: row.town
            }));

            dispatch(memberBasicInput({
                key: 'member_account_county',
                value: row.county
            }));

            dispatch(memberBasicInput({
                key: 'member_account_country',
                value: row.country
            }));

            dispatch(memberBasicInput({
                key: 'member_account_postcode',
                value: row.postCode
            }));

            dispatch(setHasMemberAccountEditData(row));
            dispatch(setMemberAccountEdit({ editMemberAccountsTab: true, member_accountid: row.id }));
            dispatch(updateNextBtnStatus({ key: 'disable_moBtn', value: false }));


            try {

                const { data } = await axiosMember.get(`members/${member_fields.member_id}`);

                if (data) {
                    dispatch(memberBasicInput({
                        key: 'member_name',
                        value: data.name
                    }));

                    dispatch(memberBasicInput({
                        key: 'member_active',
                        value: data.active
                    }));

                    dispatch(memberBasicInput({
                        key: 'member_cc',
                        value: data.collectCentrally
                    }));

                    dispatch(memberBasicInput({
                        key: 'member_prc',
                        value: data.payRetrosCentrally
                    }));

                    dispatch(memberBasicInput({
                        key: 'member_joindate',
                        value: data.joinDate ? moment(data.joinDate, 'DD/MM/YYYY') : null
                    }));

                    dispatch(memberBasicInput({
                        key: 'member_leavedate',
                        value: data.leaveDate ? moment(data.leaveDate, 'DD/MM/YYYY') : null
                    }));

                    dispatch(memberBasicInput({
                        key: 'member_ooid',
                        value: data.orwellOrganisationId
                    }));

                    dispatch(memberBasicInput({
                        key: 'member_oot',
                        value: data.orwellOrganisationType
                    }));

                    dispatch(setAutocomplete({
                        parent: 'member_estate_group',
                        key: 'estate_group_selected',
                        value: data.estateGroup
                    }));

                    dispatch(setAutocomplete({
                        parent: 'member_retroagg_group',
                        key: 'retroagg_group_selected',
                        value: data.retroAggregationGroup
                    }));

                    dispatch(filterFlagChange(true));
                    navigate('/suppliers-and-members/members/member-accounts');
                }
                else {
                    setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" });
                    setState({ ...state, pageLoader: false });
                }

            }
            catch (error) {
                const { response: { data, status }, message } = error;
                if (status === 500) {
                    setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
                } else {
                    setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
                }
                setState({ ...state, pageLoader: false });
            }

            // navigate('/suppliers-and-members/members/member-accounts');

        }
        return (<>
            <IconButton
                aria-label="more"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                id={row.id + 1}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id={row.id}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                className="actionMenuBx"
            >
                <MenuItem onClick={(e) => handleClose(e, 'edit_member')}>Edit</MenuItem>
            </Menu>
        </>)
    }


    const [rows, setRows] = useState([]);
    const columns = [
        {
            headerName: "Member Accounts",
            field: "name",
            flex: 1.5,
            minWidth: 300,
        },
        {
            headerName: "CBID",
            field: "centralBillingId",
            width: 250,

        },
        {
            headerName: "Master",
            field: "master",
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
            align: 'center',
        },
        {
            headerName: "Active",
            field: "active",
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
            align: 'center',
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} />;
            },
        }
    ];

    // const pageLoader = (loader) => {
    //     setState({ ...state, pageLoader: loader })
    // }



    useEffect(() => {

        const fetchMyAPI = async () => {
            if (member_fields.member_estate_group.estate_group_options.length === 0) {
                axiosMember.get('members/memberEstateGroups/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            // const unique = data['items'].filter((value, index, self) => self.findIndex((m) => m.name === value.name) === index);
                            const unique = data.filter((value, index, self) => self.findIndex((m) => m.name === value.name) === index);
                            dispatch(setAutocomplete({
                                parent: 'member_estate_group',
                                key: 'estate_group_options',
                                value: unique
                            }));

                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }

            if (member_fields.member_retroagg_group.retroagg_group_options.length === 0) {
                axiosMember.get('retroAggregationGroups/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            // const unique = data['items'].filter((value, index, self) => self.findIndex((m) => m.name === value.name) === index);
                            const unique = data.filter((value, index, self) => self.findIndex((m) => m.name === value.name) === index);
                            dispatch(setAutocomplete({
                                parent: 'member_retroagg_group',
                                key: 'retroagg_group_options',
                                value: unique
                            }));

                        }
                    })
                    .catch(function (error) {
                        // handle error 
                        console.log(error)
                    });
            }

            if (member_fields.editMembersTab && member_fields.member_id !== 0) {
                // setState({ ...state, pageLoader: true })
                axiosMember.get(`members/${member_fields.member_id}/memberAccounts`)
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            setRows(data);
                            // const unique = data['items'].filter((value, index, self) => self.findIndex((m) => m.name === value.name) === index);
                            setState({ ...state, pageLoader: false });
                            // dispatch(setAutocomplete({
                            //     parent: 'member_retroagg_group',
                            //     key: 'retroagg_group_options',
                            //     value: unique
                            // }));

                        }
                    })
                    .catch(function (error) {
                        // handle error 
                        const { response: { data, status }, message } = error;
                        // console.log(message, status, data);
                        if (data.message !== "No Member Accounts found") {
                            setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "No member account records found!", type: "error", });
                        }
                        setRows([]);
                        setState({ ...state, pageLoader: false });
                    });
            }
            else {
                setState({ ...state, pageLoader: false });
                setRows([]);
                c_newmemberid = 0;
            }


        }
        fetchMyAPI();
        // console.log("Page Load: ",member_fields);

    }, []);

    useEffect(() => {

        const { member_name, estate_group_selected, member_joindate, member_ooid } = member_fields.isError;
        if (!member_name && !estate_group_selected && !member_joindate && !member_ooid) {
            setState({ ...state, formValid: false });
        } else {
            setState({ ...state, formValid: true });
        }
    }, [member_fields.isError]);


    const [expanded, setExpanded] = useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        // event.preventDefault();
        // event.stopPropagation();
        console.log('Acc Change');
        setExpanded(newExpanded ? panel : false);

    };

    // const handleAccChange = (panel) => (event, newExpanded) => {
    //     setExpanded(newExpanded ? panel : false);
    // };

    // const handleChange = (checked, name) => {
    //     setState({ ...state, [name]: checked })
    //     dispatch(setFilter({
    //         key: name,
    //         value: checked
    //     }))
    // };

    const handleFilterInput = (value, id) => {
        //setState({ ...state, [id]: value });
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    };

    const selectedAutoComplete = (value, id) => {
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    }

    const addProductModal = () => {
        dispatch(handleAddEditModal({
            showAddEdit: !showAddEditModal
        }))
    }
    const hideNewProductModal = (event) => {
        setState({ ...state, openNewProductModal: false })
    }

    const handleJoinDate = (event) => {
        // setState({ ...state, defaultmode: true });

        if (event !== null) {
            dispatch(setMemberDate({
                key: 'member_joindate',
                value: moment(event, 'DD/MM/YYYY')
            }))
            dispatch(setMemberDate({
                key: 'member_leavedate',
                value: null
            }))
        }
        else {
            dispatch(setMemberDate({
                key: 'member_joindate',
                value: null
            }))
        }

    }

    const handleLeaveDate = (event) => {
        // setState({ ...state, defaultmode: true })
        if (event !== null) {
            dispatch(setMemberDate({
                key: 'member_leavedate',
                value: moment(event, 'DD/MM/YYYY')
            }))
        }
        else {
            dispatch(setMemberDate({
                key: 'member_leavedate',
                value: null
            }))
        }
    }

    // useEffect(() => {
    //     if (member_fields.member_name === null || member_fields.member_name === "") {
    //         dispatch(getIsError({ key: 'member_name', value: true }))
    //     } else {
    //         dispatch(getIsError({ key: 'member_name', value: false }))
    //     }
    //     if (member_fields.member_estate_group.estate_group_selected === null || member_fields.member_estate_group.estate_group_selected === "") {
    //         dispatch(getIsError({ key: 'estate_group_selected', value: true }))
    //     } else {
    //         dispatch(getIsError({ key: 'estate_group_selected', value: false }))
    //     }
    //     if (member_fields.member_retroagg_group.retroagg_group_selected === null || member_fields.member_retroagg_group.retroagg_group_selected.name === null || member_fields.member_retroagg_group.retroagg_group_selected === "") {
    //         dispatch(getIsError({ key: 'retroagg_group_selected', value: true }))
    //     } else {
    //         dispatch(getIsError({ key: 'retroagg_group_selected', value: false }))
    //     }
    //     if (member_fields.member_joindate === null || member_fields.member_joindate === 'Invalid date' || member_fields.member_joindate === "") {
    //         dispatch(getIsError({ key: 'member_joindate', value: true }))
    //     } else {
    //         dispatch(getIsError({ key: 'member_joindate', value: false }))
    //     }
    // }, [member_fields]);



    // useEffect(() => {
    //     //console.log("change fields");
    //     const { member_name,
    //         member_estate_group,
    //         member_joindate
    //     } = member_fields;

    //     if (member_fields.memberDetailsTab !== true) {
    //         // console.log(member_estate_group['estate_group_selected']);
    //         if (member_name === "" || (member_estate_group['estate_group_selected'] === "" || member_estate_group['estate_group_selected'] === null) || (member_joindate === "" || member_joindate === null || member_joindate === "Invalid date")) {
    //             setState({ ...state, formValid: true, defaultmode: false })
    //         }
    //         else {
    //             setState({ ...state, formValid: false, defaultmode: true })
    //         }
    //     }

    // }, [member_fields.member_name, member_fields.member_estate_group, member_fields.member_joindate]);

    // useEffect(() => {
    //      console.log("change fields");
    //     if (member_fields.member_name === "" && member_fields.isError.member_name === false) {
    //         setState({ ...state, formValid: true })
    //     } else if ((member_fields.member_joindate === "" || member_fields.member_joindate === null) && member_fields.isError.member_joindate === false) {
    //         setState({ ...state, formValid: true })
    //     } else if (member_fields.member_estate_group.estate_group_selected === null && member_fields.isError.estate_group_selected === false) {
    //         setState({ ...state, formValid: true })
    //     } else {
    //         setState({ ...state, formValid: false })
    //     }

    // }, [member_fields]);

    // useEffect(() => {
    //     const {
    //         member_name,
    //         member_joindate,
    //         member_active,
    //         estate_group_selected,
    //         retroagg_group_selected
    //     } = member_fields.isError
    //     if (!member_name && !member_joindate && !member_active && !estate_group_selected && !retroagg_group_selected) {
    //         setState({ ...state, formValid: false })
    //     }
    //     else {
    //         setState({ ...state, formValid: true })
    //     }
    //     if (member_fields.edit_member_programme) {
    //         setState({ ...state, formValid: true })
    //     }

    // }, [member_fields.isError]);


    // const [isError, setIsError] = useState({
    //     supplier_product_code: false,
    //     outer_case_barcode: false,
    //     price: false,
    //     pstartdate: false,
    //     charge: false,
    //     cstartdate: false
    // })

    const handleInputChange = (event) => {
        event.preventDefault();
        // console.log(member_fields)
        // setState({ ...state, defaultmode: true })
        const { value, name } = event.target;
        dispatch(memberInput({
            key: name,
            value
        }))
    }

    const handleInputChangeNumber = (event, type) => {
        event.preventDefault();
        // setState({ ...state, defaultmode: true })
        const { value, name } = event.target;
        if (type === 'oot') {
            if (value.length < 5) {
                dispatch(memberBasicInput({
                    key: name,
                    value
                }))
            }
        }
        if (type === 'ooid') {
            if (value.length < 9) {
                dispatch(memberInput({
                    key: name,
                    value
                }))
            }
        }

    }

    const handleInputChange2 = (event) => {
        event.preventDefault();
        const { value, name } = event.target;
        dispatch(memberInput({
            key: name,
            value
        }))
    }

    const handleCheckboxChange = (event) => {
        const { checked, name } = event.target;
        // setState({ ...state, defaultmode: true })
        dispatch(memberBasicInput({
            key: name,
            value: checked
        }))
    };

    const handleCancel = (event) => {
        localStorage.setItem("memberfilterflag", "true");
        dispatch(filterFlagChange(true));
        navigate('/suppliers-and-members/members');
        // dispatch(warningModal({
        //     yesHide: !handleWarning
        // }))
    }

    const handleMemberSubmit = (event) => {

        // dispatch(warningModal({
        //     yesHide: !handleWarning
        // }))
    }

    const handleClose = () => {
        // if (onHide) {
        //     onHide()
        // }
    };

    const handleSelection = (event, newValue, parentname, name) => {
        // setState({ ...state, defaultmode: true })
        if (event.target.newValue !== "" || event.target.newValue !== null) {
            dispatch(setAutocomplete({
                parent: parentname,
                key: name,
                value: newValue
            }))
        }
        else {
            dispatch(setAutocomplete({
                parent: parentname,
                key: name,
                value: null
            }))
        }

    }



    const handleBClick = (event, location) => {
        event.preventDefault();
        switch (location) {
            case "Member":
                dispatch(resetMember());
                dispatch(filterFlagChange(true));
                navigate('/suppliers-and-members/members');
                break
            case "Member Details":
                navigate('/suppliers-and-members/members/member-details');
                break
            case "Member Accounts":
                navigate('/suppliers-and-members/members/member-accounts');
                break
            case "Member Outlets":
                navigate('/suppliers-and-members/members/member-outlets');
                break
            default:
                navigate('/suppliers-and-members/members');
        }
    }


    const breadcrumbs = [
        <Link underline="hover" key="0" color="inherit" label="Suppliers & Members"  >
            Suppliers & Members
        </Link>,
        <Link underline="hover" key="1" color="inherit" label="Members" onClick={(e) => handleBClick(e, 'Member')} >
            Members
        </Link>,
        <Typography key="2" color="text.primary">
            Account Details
        </Typography>
    ];

    const handleSubmit = async (event) => {
        // console.log(member_fields, c_newmemberid);
        event.preventDefault();
        if (member_fields.member_name === "" && member_fields.isError.member_name === false) {
            dispatch(getIsError({ key: 'member_name', value: true }))
        } else if ((member_fields.member_joindate === "" || member_fields.member_joindate === null) && member_fields.isError.member_joindate === false) {
            dispatch(getIsError({ key: 'member_joindate', value: true }))
        } else if (member_fields.member_estate_group.estate_group_selected === null && member_fields.isError.estate_group_selected === false) {
            dispatch(getIsError({ key: 'estate_group_selected', value: true }))
        } else if (member_fields.member_ooid === "" && member_fields.isError.member_ooid === false) {
            dispatch(getIsError({ key: 'member_ooid', value: true }))
        } else {

            let memberData = null;
            if (!state.formValid) {
                if (moment(member_fields.member_joindate).format('DD/MM/YYYY') === 'Invalid date' || (member_fields.member_leavedate !== "" && member_fields.member_leavedate !== null && moment(member_fields.member_leavedate).format('DD/MM/YYYY') === 'Invalid date')) {
                    setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
                }
                else if (member_fields.member_leavedate !== null && member_fields.member_leavedate !== "" && (member_fields.member_joindate > member_fields.member_leavedate)) {
                    setSnackBar({ ...snackBar, show: true, text: "Leave date is less than Join Date!", type: "error", });
                }
                else {
                    // console.log(member_fields)
                    setState({ ...state, loader: true });
                    memberData = {
                        "active": member_fields.member_active,
                        "collectCentrally": member_fields.member_cc,
                        "joinDate": member_fields.member_joindate ? moment(member_fields.member_joindate).format('DD/MM/YYYY') : null,
                        "leaveDate": member_fields.member_leavedate ? moment(member_fields.member_leavedate).format('DD/MM/YYYY') : null,
                        "name": member_fields.member_name,
                        "orwellOrganisationId": member_fields.member_ooid || null,
                        "orwellOrganisationType": member_fields.member_oot || null,
                        "payRetrosCentrally": member_fields.member_prc,
                        "retroAggregationGroup": member_fields.member_retroagg_group.retroagg_group_selected ? member_fields.member_retroagg_group.retroagg_group_selected : null,
                        "estateGroup": member_fields.member_estate_group.estate_group_selected
                    }
                    try {
                        // console.log(state.loader);

                        // console.log(state.loader);
                        // console.log(c_newmemberid, member_fields.editMembersTab, member_fields.member_id);
                        const { data, status } = member_fields.editMembersTab && member_fields.hasMemberEditData ? await axiosMember.put(`members/${member_fields.hasMemberEditData.id}`, { ...memberData }) : c_newmemberid !== 0 ? await axiosMember.put(`members/${c_newmemberid}`, { ...memberData }) : await axiosMember.post(`members`, { ...memberData });
                        // console.log(data);
                        if (data.success === true && data.resultMap) {

                            setState({ ...state, loader: false, formValid: true, newmemberid: data.resultMap.memberId, defaultmode: false, mmaMaster: false });
                            setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Member Added Successfully!", type: "success" });
                            dispatch(setMemberEdit({ editMembersTab: true, member_id: data.resultMap.memberId }));
                            dispatch(updateNextBtnStatus({ key: 'disable_maBtn', value: false }));
                            c_newmemberid = data.resultMap.memberId;
                            // console.log(memberData, member_fields.hasMemberEditData);
                            // console.log(member_fields, state.member_id, data.resultMap.memberId, c_newmemberid);
                        }
                        else {
                            if (member_fields.hasMemberEditData || data.success === true) {
                                // console.log(state.loader);
                                setState({ ...state, loader: false, formValid: false, defaultmode: false });
                                dispatch(updateNextBtnStatus({ key: 'disable_maBtn', value: false }));
                                setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Member Edited Successfully!", type: "success" });
                                console.log(memberData, member_fields.hasMemberEditData);
                                // console.log(state.loader);
                            }
                        }
                    } catch (error) {
                        const { response: { data, status } } = error;

                        setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error", });
                        setState({ ...state, loader: false, formValid: false });
                    }
                }
            }
            else {
                setSnackBar({ ...snackBar, show: true, text: "Something went wrong! Member Data missing, go one step back", type: "error", });
                setState({ ...state, loader: false, formValid: false });
            }
        }

    }



    const goNextPage = async () => {

        try {
            dispatch(resetMemberAccounts());
            dispatch(setHasMemberAccountEditData(null));
            setState({ ...state, pageLoader: true });
            const { data } = await axiosMember.get(`members/${member_fields.member_id}`);

            if (data) {
                dispatch(memberBasicInput({
                    key: 'member_name',
                    value: data.name
                }));

                dispatch(memberBasicInput({
                    key: 'member_active',
                    value: data.active
                }));

                dispatch(memberBasicInput({
                    key: 'member_cc',
                    value: data.collectCentrally
                }));

                dispatch(memberBasicInput({
                    key: 'member_prc',
                    value: data.payRetrosCentrally
                }));

                dispatch(memberBasicInput({
                    key: 'member_joindate',
                    value: data.joinDate ? moment(data.joinDate, 'DD/MM/YYYY') : null
                }));

                dispatch(memberBasicInput({
                    key: 'member_leavedate',
                    value: data.leaveDate ? moment(data.leaveDate, 'DD/MM/YYYY') : null
                }));

                dispatch(memberBasicInput({
                    key: 'member_ooid',
                    value: data.orwellOrganisationId
                }));

                dispatch(memberBasicInput({
                    key: 'member_oot',
                    value: data.orwellOrganisationType
                }));

                dispatch(setAutocomplete({
                    parent: 'member_estate_group',
                    key: 'estate_group_selected',
                    value: data.estateGroup
                }));

                dispatch(setAutocomplete({
                    parent: 'member_retroagg_group',
                    key: 'retroagg_group_selected',
                    value: data.retroAggregationGroup
                }));
                setState({ ...state, pageLoader: false });
                dispatch(filterFlagChange(true));
                navigate('/suppliers-and-members/members/member-accounts');
            }
            else {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" });
                setState({ ...state, pageLoader: false });
            }

        }
        catch (error) {
            const { response: { data, status }, message } = error;
            if (status === 500) {
                setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
            } else {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
            }
            setState({ ...state, pageLoader: false });
        }

    }

    useEffect(() => {

        const mmaMasterCheque = async () => {
            // console.log(member_fields.member_id);
            if (member_fields.hasMemberEditData !== null || member_fields.member_id !== 0) {
                // console.log("If", member_fields.hasMemberEditData, member_fields.member_id);
                if (member_fields.member_id !== 0) {
                    const { data } = await axiosMember.get(`members/${member_fields.member_id}`);
                    // console.log(data, data.masterAccountExist)
                    if (data.masterAccountExist === true) {
                        setState({ ...state, mmaMaster: true });
                        state.mmaMaster = true;
                    }
                    else {
                        setState({ ...state, mmaMaster: false });
                        state.mmaMaster = false;
                    }
                }
                else {
                    setState({ ...state, mmaMaster: true });
                    state.mmaMaster = true;
                }
            }
            else {
                setState({ ...state, mmaMaster: true });
                state.mmaMaster = true;
            }
        }
        mmaMasterCheque();
    }, [])

    const lambdaWarmer = () => {
        onReprocessReverse()
    }
    const onReprocessReverse = async () => {
        try {
            setState({ ...state, pageLoader2: true });
            const { data } = await refreshLambda.get('/warmup');

            if (data) {
                setSnackBar({ ...snackBar, vertical: 'top', duration: 6000, horizontal: 'center', show: true, text: "System is warming up, please check after 9 minutes", type: "info" })
            }
            setState({ ...state, pageLoader2: false });
        } catch (error) {
            const { response: { data, status } } = error;
            if (status === 500) {
                setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
            } else {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
            }
            setState({ ...state, pageLoader2: false });
        }
    }
    return <> {state.pageLoader && <PageLoader />}
        {state.pageLoader2 && <PageLoaderStyle2 />}
        <Container disableGutters={true} maxWidth={false} >

            <Box component="div" className='breadcrumbNavs'>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <Breadcrumbs component="div" separator="›" aria-label="breadcrumb">
                            {breadcrumbs}
                        </Breadcrumbs>
                        <div className='pageHeading'>
                            <h1>{member_fields.hasMemberEditData ? member_fields.hasMemberEditData.name : "Member Details"}</h1>
                        </div>
                    </div>
                    <Box className='lambdaRefreshBtn'>
                        <Box component={'a'} sx={{ display: 'flex', alignItems: 'center' }} onClick={lambdaWarmer}>
                            <LocalFireDepartmentIcon color="primary" /> <span style={{ marginLeft: '5px', color: '#B253AE' }}>Warm System</span>
                        </Box>
                    </Box>
                </Box>
            </Box>

            {/* <Accordion sx={{ marginBottom: '20px' }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')} onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()}> */}
            <Accordion sx={{ marginBottom: '20px' }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography sx={{ fontWeight: "600", fontSize: '14px', color: "#15191E" }}>{member_fields.hasMemberEditData ? "Edit Member" : "Add New Member"}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <form onSubmit={(e) => { handleSubmit(e) }}>
                        <Box sx={{ flexGrow: 1, paddingX: 0 }} >
                            <Grid container columnSpacing={0} sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 3 }}>
                                    <Item>

                                        <Grid container alignItems="center">
                                            <Grid item xs={12} md={4} sx={{ paddingLeft: '0px', paddingRight: '0px' }} className="formLabelCntr">
                                                <Item>
                                                    <FormLabel htmlFor="member_name" className="fontSemiBold mandatory">Member Name</FormLabel>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} md={8} sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Item sx={{ paddingLeft: '0px', paddingRight: '5px' }}>
                                                    <FormControl fullWidth>
                                                        <TextField

                                                            key="member_name"
                                                            inputProps={{ maxLength: 200 }}
                                                            size="small"
                                                            id="member_name"
                                                            aria-describedby="member_name_error"
                                                            onChange={(e) => handleInputChange(e)}
                                                            value={member_fields.member_name}
                                                            error={member_fields.isError.member_name}
                                                            name="member_name"
                                                        />
                                                        {member_fields.isError.member_name && (<FormHelperText className='errorHelperTxt' id="member_name_error">
                                                            Please enter Member Name
                                                        </FormHelperText>)}
                                                    </FormControl>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 3 }}>
                                    <Item>
                                        <Grid container alignItems="center">
                                            <Grid item xs={12} md={3} sx={{ paddingLeft: '0px', paddingRight: '0px' }} className="formLabelCntr">
                                                <Item>

                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} md={8} sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Item sx={{ paddingLeft: '10px', paddingRight: '0' }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox size="medium"
                                                                id="member_active"
                                                                checked={member_fields.member_active}
                                                                onChange={(e) => handleCheckboxChange(e)}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                name="member_active"
                                                            />}
                                                        label="Active" />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox size="medium"
                                                                id="member_cc"
                                                                checked={member_fields.member_cc}
                                                                onChange={(e) => handleCheckboxChange(e)}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                name="member_cc"
                                                            />}
                                                        label="Collect Centrally" />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox size="medium"
                                                                id="member_prc"
                                                                checked={member_fields.member_prc}
                                                                onChange={(e) => handleCheckboxChange(e)}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                name="member_prc"
                                                            />}
                                                        label="Pay Retros Centrally" />
                                                </Item>
                                            </Grid>
                                        </Grid>

                                    </Item>
                                </Grid>
                            </Grid>
                            <Grid container columnSpacing={0} sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: 3 }}>
                                    <Item>
                                        <Grid container alignItems="center">
                                            <Grid item xs={12} md={6} >
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4} sx={{ paddingLeft: '0px', paddingRight: '0px' }} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="member_joindate" className="fontSemiBold mandatory">Join Date</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={8} sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                            <Item sx={{ paddingLeft: '0px', paddingRight: '0' }}>

                                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                    <DesktopDatePicker
                                                                        disabled={member_fields.hasMemberEditData !== null ? true : false}
                                                                        value={member_fields.member_joindate}
                                                                        onChange={(e) => { handleJoinDate(e) }}
                                                                        name="member_joindate"
                                                                        inputFormat="DD/MM/YYYY"
                                                                        // eslint-disable-next-line max-len
                                                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                            <Box component="div" className='date-picker-input'>
                                                                                <FormControl size={'small'} fullWidth>
                                                                                    <TextField size="small" id="member_joindate"
                                                                                        ref={inputRef} {...inputProps}
                                                                                        placeholder=""
                                                                                        aria-describedby="member_joindate_error"
                                                                                        error={member_fields.isError.member_joindate}
                                                                                        autoComplete="off"

                                                                                    />
                                                                                    {member_fields.isError.member_joindate && (<FormHelperText className='errorHelperTxt' id="member_joindate_error">Join date is missing</FormHelperText>)}
                                                                                    <Box className='date-picker-icon'>
                                                                                        {InputProps?.endAdornment}
                                                                                    </Box>
                                                                                </FormControl>
                                                                            </Box>
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} md={6} sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={3} sx={{ paddingLeft: '0px', paddingRight: '0px' }} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="member_leavedate" className="fontSemiBold">Leave Date</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={8} sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                            <Item sx={{ paddingLeft: '10px', paddingRight: '0' }}>
                                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                    <DesktopDatePicker
                                                                        value={member_fields.member_leavedate}
                                                                        disabled={(member_fields.member_joindate === null || member_fields.member_joindate === '') ? true : false}
                                                                        onChange={(e) => { handleLeaveDate(e) }}
                                                                        name="member_leavedate"
                                                                        inputFormat="DD/MM/YYYY"
                                                                        minDate={moment(member_fields.member_joindate, 'DD/MM/YYYY').add(1, 'days')}
                                                                        // eslint-disable-next-line max-len
                                                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                            <Box component="div" className='date-picker-input'>
                                                                                <FormControl size={'small'} fullWidth>
                                                                                    <TextField size="small" id="member_leavedate"
                                                                                        ref={inputRef} {...inputProps}
                                                                                        placeholder=""
                                                                                        aria-describedby="member_leavedate_error"
                                                                                        autoComplete="off"
                                                                                    />
                                                                                    <Box className='date-picker-icon'>
                                                                                        {InputProps?.endAdornment}
                                                                                    </Box>
                                                                                </FormControl>
                                                                            </Box>
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>

                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Item>
                                </Grid>
                            </Grid>
                            <Grid container columnSpacing={0} sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 3 }}>
                                    <Item>
                                        <Grid container alignItems="center">
                                            <Grid item xs={12} md={4} sx={{ paddingLeft: '0px', paddingRight: '0px' }} className="formLabelCntr">
                                                <Item>
                                                    <FormLabel htmlFor="member_estate_group" className="fontSemiBold mandatory">Type of Organisation</FormLabel>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} md={8} sx={{ paddingLeft: '0px', paddingRight: '5px' }}>
                                                <Item sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                    <Autocomplete
                                                        value={member_fields.member_estate_group.estate_group_selected || (member_fields.hasMemberEditData ? member_fields.hasMemberEditData.estate_group : null)}
                                                        id="member_estate_group_search"
                                                        options={member_fields.member_estate_group.estate_group_options}
                                                        getOptionLabel={(option) => option.name || ""}
                                                        componentsProps={{
                                                            paper: {
                                                                sx: {
                                                                    fontSize: '0.85rem'
                                                                }
                                                            }
                                                        }}
                                                        onChange={(event, newValue) => handleSelection(event, newValue, 'member_estate_group', 'estate_group_selected')}
                                                        renderInput={(params) =>
                                                            <FormControl size={'small'} fullWidth>
                                                                <div className="input-group">
                                                                    <TextField {...params}
                                                                        size="small"
                                                                        id="member_estate_group"
                                                                        aria-describedby="member_estate_group-error"
                                                                        error={member_fields.isError.estate_group_selected}
                                                                    />
                                                                    {member_fields.isError.estate_group_selected &&
                                                                        (<FormHelperText className='errorHelperTxt' id="member_estate_group-error"> Please select an Organisation</FormHelperText>)}
                                                                </div>
                                                            </FormControl>
                                                        }

                                                    />
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 3 }}>
                                    <Item>
                                        <Grid container alignItems="center">
                                            <Grid item xs={12} md={3} sx={{ paddingLeft: '0px', paddingRight: '0px' }} className="formLabelCntr">
                                                <Item sx={{ paddingRight: '25px' }}>
                                                    <FormLabel htmlFor="member_retroagg_group" className="fontSemiBold">Retro Aggregation Group</FormLabel>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <Item>
                                                    <Autocomplete
                                                        // value={member_fields.member_retroagg_group.retroagg_group_selected || (member_fields.hasEditData ? member_fields.hasEditData.retroagg_group : null)}
                                                        value={member_fields.member_retroagg_group.retroagg_group_selected || (member_fields.hasMemberEditData ? member_fields.hasMemberEditData.retroagg_group : null)}
                                                        id="member_retroagg_group_search"
                                                        options={member_fields.member_retroagg_group.retroagg_group_options}
                                                        getOptionLabel={(option) => option.name || ""}
                                                        componentsProps={{
                                                            paper: {
                                                                sx: {
                                                                    fontSize: '0.85rem'
                                                                }
                                                            }
                                                        }}
                                                        onChange={(event, newValue) => handleSelection(event, newValue, 'member_retroagg_group', 'retroagg_group_selected')}
                                                        renderInput={(params) =>
                                                            <FormControl size={'small'} fullWidth>
                                                                <div className="input-group">
                                                                    <TextField {...params}
                                                                        size="small"
                                                                        id="member_retroagg_group"
                                                                        aria-describedby="member_retroagg_group-error"
                                                                    // error={member_fields.isError.retroagg_group_selected}
                                                                    />
                                                                    {/* {member_fields.isError.retroagg_group_selected &&
                                                                        (<FormHelperText className='errorHelperTxt' id="member_retroagg_group-error"> Please select an Retro Aggression Group</FormHelperText>)} */}
                                                                </div>
                                                            </FormControl>
                                                        }

                                                    />
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Item>
                                </Grid>
                            </Grid>
                            <Grid container columnSpacing={0} sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Item>
                                        <Grid container alignItems="center">
                                            <Grid item xs={12} md={6} sx={{ marginBottom: 2.5 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4} sx={{ paddingLeft: '0px', paddingRight: '0px' }} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="member_ooid" className="fontSemiBold mandatory">CRM Organisation ID</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={8} sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                            <Item sx={{ paddingLeft: '0px', paddingRight: '0' }}>
                                                                <div className="input-group">
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            type="number"
                                                                            size="small"
                                                                            id="member_ooid"
                                                                            onChange={(e) => handleInputChangeNumber(e, 'ooid')}
                                                                            value={member_fields.member_ooid ? member_fields.member_ooid : ''}
                                                                            name="member_ooid"
                                                                            className="inputNumber"
                                                                            onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                                                            error={member_fields.isError.member_ooid}
                                                                            aria-describedby="member_ooid_error"
                                                                        />
                                                                        {member_fields.isError.member_ooid && (<FormHelperText className='errorHelperTxt' id="member_ooid_error">
                                                                            Please enter CRM Organisation ID
                                                                        </FormHelperText>)}
                                                                    </FormControl>

                                                                </div>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} md={6} sx={{ marginBottom: 2.5 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={3} sx={{ paddingLeft: '0px', paddingRight: '0px' }} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="member_oot" className="fontSemiBold">CRM Organisation Type</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={8} sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                            <Item sx={{ paddingLeft: '10px', paddingRight: '0' }}>

                                                                <FormControl fullWidth
                                                                >
                                                                    <TextField

                                                                        type="number"
                                                                        size="small"
                                                                        id="member_oot"
                                                                        name="member_oot"
                                                                        onChange={(e) => handleInputChangeNumber(e, 'oot')}
                                                                        value={member_fields.member_oot ? member_fields.member_oot : ''}
                                                                        className="inputNumber"
                                                                        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}


                                                                    />

                                                                </FormControl>


                                                            </Item>
                                                        </Grid>
                                                    </Grid>

                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Item>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ marginTop: '20px' }}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Item>
                                        <Grid container direction="row" alignItems="center" justifyContent="center">
                                            <LoadingButton
                                                loading={state.loader}
                                                loadingPosition="center"
                                                endIcon=""
                                                variant="contained"
                                                type="submit" sx={{ marginRight: '15px' }}
                                                disabled={state.formValid || state.mmaMaster === false}
                                            // disabled={state.formValid || state.defaultmode === false}

                                            >{member_fields.editMembersTab && member_fields.member_id !== 0 ? "Update" : "Save"}</LoadingButton>
                                            <Button variant="outlined" sx={{ marginRight: '15px' }} onClick={handleCancel} disabled={state.mmaMaster === false ? true : false}>Go to Dashboard</Button>
                                            {/* <Button variant="contained" onClick={handleMemberSubmit} disabled={state.formValid}>Submit Member</Button> */}
                                        </Grid>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Box>
                        {snackBar.show &&
                            <SnackBarDialog
                                duration={snackBar.duration}
                                vpositon={snackBar.vertical}
                                hposition={snackBar.horizontal}
                                onShow={snackBar.show}
                                text={snackBar.text}
                                type={snackBar.type}
                                onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                            />
                        }
                    </form>
                </AccordionDetails>
            </Accordion>
            <Box component="div" className='exportsButtons' sx={{ marginBottom: '20px', columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' } }}>
                <div className='table-actionable'>
                    <Typography sx={{ fontSize: '12px', color: 'red', paddingBottom: '10px' }}>*Select Master member account for saving!</Typography>
                    <Button variant="contained" size='small' disabled={member_fields ? member_fields.disable_maBtn : true} onClick={(e) => goNextPage(e)} startIcon={<AddIcon />}>
                        New Member Accounts
                    </Button>
                </div>
            </Box>
            <DataTableClientSide defaultGrid={state.defaultGrid} columns={columns} location={state.location} locationLabel={state.locationlabel} rows={rows ? rows : []}></DataTableClientSide>
        </Container>
    </>






}

export default MemberDetails;
