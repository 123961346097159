import { Container, Box, Modal, Button, Grid, Checkbox } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";
import { TableFilters } from "../../components/shared/data-table/TableFilters";
import { useEffect, useState } from "react";
import { InputText } from "../../components/shared/form/InputText";
import { } from '@mui/material';
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange, filterResetFlagChange } from "../../store/slices/externalFilter";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import AddIcon from '@mui/icons-material/Add';
import { warningModal, handleAddEditSupplier } from "../../store/slices/modals";
import WarningModal from "../../components/shared/modals/WarningModal";
import { setRowPage, resetDataGrid, setPageNo } from '../../store/slices/dataGrid'
import AddEditSupplier from "./addEditSupplier/addEditSupplier";
import { useLayoutEffect } from "react";
import { DataTableSupplierService } from "../../components/shared/data-table/DataTableSupplier";
import axios_supplier from '../../services/utility/axios-supplier';
import { brandSearch, setHasEditData, hasTabEdit, supplierProductInput, setNewSupplierData, setLatestVatCode, setDeleteList, setSupplierDeleteData, setAllSupplierList } from "../../store/slices/supplierProduct";
import SnackBarDialog from "../../components/shared/SnackBarDialog";
import PageLoader from "../../components/shared/PageLoader";

const IconMenuLink = ({ params, sendSnackBar, startLoading, onDelete }) => {
    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { supplier_container, setAddEditSupplier } = useSelector((state) => {
        return {
            supplier_container: state.supplierProducts,
            setAddEditSupplier: state.modalActions.hideAddEditSupplier
        }
    });
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'edit') {
            handleEdit(row)
        } else if (action === 'delete') {
            onDelete(row)
        }
        setAnchorEl(null);
    };

    const handleEdit = async (row) => {
        dispatch(setLatestVatCode(null))
        try {
            if (startLoading) {
                startLoading(true)
            }
            const { data } = await axios_supplier.get(`supplier/${row.id}`);
            if (data) {
                if (startLoading) {
                    startLoading(false)
                }
                dispatch(handleAddEditSupplier({
                    showAddEdit: !setAddEditSupplier
                }))
                dispatch(setHasEditData(data.resultMap.result))
                dispatch(brandSearch({
                    parent: 'supplier_gen_central_bil_scheme',
                    key: 'supplier_gen_central_bil_scheme_selected',
                    value: data.resultMap.result.centralBillingScheme ? data.resultMap.result.centralBillingScheme : null
                }))
                dispatch(brandSearch({
                    parent: 'supplier_gen_defaultDistributionChannel',
                    key: 'supplier_gen_defaultDistributionChannel_selected',
                    value: data.resultMap.result.defaultDistributionChannel ? data.resultMap.result.defaultDistributionChannel : null
                }))

                dispatch(supplierProductInput({
                    key: 'supplier_gen_name',
                    value: data.resultMap.result.name ? data.resultMap.result.name : ""
                }))
                dispatch(supplierProductInput({
                    key: 'supplier_gen_central_billing_id',
                    value: data.resultMap.result.centralBillingId ? data.resultMap.result.centralBillingId : ""
                }))
                dispatch(supplierProductInput({
                    key: 'supplier_gen_orwellCompanyId',
                    value: data.resultMap.result.orwellCompanyId ? data.resultMap.result.orwellCompanyId : ""
                }))
                dispatch(supplierProductInput({
                    key: 'supplier_gen_defaultEmailContact',
                    value: data.resultMap.result.defaultEmailContact ? data.resultMap.result.defaultEmailContact : ""
                }))
                if (data.resultMap.result.anaNumber) {
                    dispatch(supplierProductInput({
                        key: 'supplier_gen_ananumber',
                        value: data.resultMap.result.anaNumber
                    }))
                }
                if (data.resultMap.result.poNumberDiscountBill) {
                    dispatch(supplierProductInput({
                        key: 'supplier_gen_poNumberDiscountBill',
                        value: data.resultMap.result.poNumberDiscountBill
                    }))
                }
                if (data.resultMap.result.poNumberLevyBill) {
                    dispatch(supplierProductInput({
                        key: 'supplier_gen_poNumberLevyBill',
                        value: data.resultMap.result.poNumberLevyBill
                    }))
                }
                if (data.resultMap.result.poNumberRetroBill) {
                    dispatch(supplierProductInput({
                        key: 'supplier_gen_poNumberRetroBill',
                        value: data.resultMap.result.poNumberRetroBill
                    }))
                }
                dispatch(supplierProductInput({
                    key: 'supplier_gen_levyBillDetail',
                    value: data.resultMap.result.levyBillDetail ? data.resultMap.result.levyBillDetail : false
                }))
                dispatch(supplierProductInput({
                    key: 'supplier_gen_discountBillDetail',
                    value: data.resultMap.result.discountBillDetail ? data.resultMap.result.discountBillDetail : false
                }))
                dispatch(supplierProductInput({
                    key: 'supplier_gen_checkPrices',
                    value: data.resultMap.result.checkPrices ? data.resultMap.result.checkPrices : false
                }))
                dispatch(supplierProductInput({
                    key: 'supplier_address_address1',
                    value: data.resultMap.result.address1 ? data.resultMap.result.address1 : ""
                }))
                if (data.resultMap.result.address2) {
                    dispatch(supplierProductInput({
                        key: 'supplier_address_address2',
                        value: data.resultMap.result.address2 ?  data.resultMap.result.address2 : ""
                    }))
                }
                if (data.resultMap.result.address3) {
                    dispatch(supplierProductInput({
                        key: 'supplier_address_address3',
                        value: data.resultMap.result.address3 ? data.resultMap.result.address3 : ""
                    }))
                }
                dispatch(supplierProductInput({
                    key: 'supplier_address_town',
                    value: data.resultMap.result.town ? data.resultMap.result.town : ""
                }))
                dispatch(supplierProductInput({
                    key: 'supplier_address_city',
                    value: data.resultMap.result.county ? data.resultMap.result.county : ""
                }))
                dispatch(supplierProductInput({
                    key: 'supplier_address_country',
                    value: data.resultMap.result.country ? data.resultMap.result.country : ""
                }))
                dispatch(supplierProductInput({
                    key: 'supplier_address_postCode',
                    value: data.resultMap.result.postCode ? data.resultMap.result.postCode : ""
                }))
                dispatch(supplierProductInput({
                    key: 'supplier_contact_remittanceEmailContact',
                    value: data.resultMap.result.remittanceEmailContact ? data.resultMap.result.remittanceEmailContact : ""
                }))
                dispatch(supplierProductInput({
                    key: 'supplier_contact_retroEmailContact',
                    value: data.resultMap.result.retroEmailContact ? data.resultMap.result.retroEmailContact : ""
                }))
                dispatch(supplierProductInput({
                    key: 'supplier_contact_levyEmailContact',
                    value: data.resultMap.result.levyEmailContact ? data.resultMap.result.levyEmailContact : ""
                }))
                dispatch(supplierProductInput({
                    key: 'supplier_contact_discountEmailContact',
                    value: data.resultMap.result.discountEmailContact ? data.resultMap.result.discountEmailContact : ""
                }))

                dispatch(supplierProductInput({
                    key: 'supplier_ledger_remittanceLedgerCode',
                    value: data.resultMap.result.remittanceLedgerCode ? data.resultMap.result.remittanceLedgerCode : ""
                }))
                dispatch(supplierProductInput({
                    key: 'supplier_ledger_retroLedgerCode',
                    value: data.resultMap.result.retroLedgerCode ? data.resultMap.result.retroLedgerCode : ""
                }))
                dispatch(supplierProductInput({
                    key: 'supplier_ledger_levyLedgerCode',
                    value: data.resultMap.result.levyLedgerCode ? data.resultMap.result.levyLedgerCode : ""
                }))
                dispatch(supplierProductInput({
                    key: 'supplier_ledger_discountLedgerCode',
                    value: data.resultMap.result.discountLedgerCode ? data.resultMap.result.discountLedgerCode : ""
                }))
                if (data.resultMap.result.endDate) {
                    dispatch(supplierProductInput({
                        key: 'supplier_gen_active_untill',
                        value: moment(data.resultMap.result.endDate, 'DD/MM/YYYY')
                    }))
                }

            }
        }
        catch (error) {
            if (sendSnackBar) {
                sendSnackBar(error)
            }
        }

    }

    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'edit')}>Edit </MenuItem>
            <MenuItem onClick={(e) => handleClose(e, 'delete')}>Delete</MenuItem>
        </Menu>
    </>)
}

function Suppliers() {
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const columns = [
        {
            headerName: "ID",
            field: "id",
            flex: 1,
            minWidth: 200,
            //hide: true
        },
        {
            headerName: "Central Billing ID",
            field: "centralBillingId",
            flex: 1,
            width: 170
        },
        {
            headerName: "Name",
            field: "name",
            flex: 1,
            width: 180
        },
        {
            headerName: "Central Billing Scheme",
            field: "centralBillingScheme",
            valueFormatter: ({ value }) => value.centralBillingSchemeName,
            flex: 1,
            minWidth: 180
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} startLoading={pageLoader} onDelete={deleteSupplierdata} />;
            },
        }
    ];
    const [state, setState] = useState({
        openSupplierModal: false,
        pageLoader: false,
        defaultGrid: {
            pagetitle: "Suppliers",
            addEditButton: true,
            apiUrl: 'supplier/filter',
            searchUrl: 'supplier/filter'
        }
    });

    const pageLoader = (loader) => {
        setState({ ...state, pageLoader: loader })
    }

    const { addProductwarning, setAddEditSupplier, supplier_container, getSupplierLists } = useSelector((state) => {
        return {
            addProductwarning: state.modalActions.closeWaringAddProduct,
            setAddEditSupplier: state.modalActions.hideAddEditSupplier,
            supplier_container: state.supplierProducts,
            getSupplierLists: state.supplierProducts.all_supplier
        }
    });

    const dispatch = useDispatch();

    const handleFilterInput = (value, id) => {
        //setState({ ...state, [id]: value });
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    };

    // eslint-disable-next-line max-len
    const { supplier_name, supplier_centralBillingId } = useSelector((state) => {
        return {
            supplier_name: state.externalFilter.supplier_name,
            supplier_centralBillingId: state.externalFilter.supplier_centralBillingId
        }
    });
    const filterBtn = () => {
        dispatch(resetDataGrid());
        var obj_filter = {};
        var dynamicFilterObjects = {};

        if (supplier_name !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "name": supplier_name
            }
        }
        else {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "name": ''
            }
        }

        if (supplier_centralBillingId !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "centralBillingId": supplier_centralBillingId
            }
        }

        dispatch(applyFilter({
            key: 'filterList',
            value: {
                ...dynamicFilterObjects
            }
        }));

        dispatch(filterFlagChange(true));
        dispatch(filterResetFlagChange(false));
        dispatch(setRowPage({ pageData: 10 }));
        dispatch(setPageNo({ page: 1 }));
    }
    const resetFilter = () => {
        if ((supplier_name || supplier_centralBillingId) !== '') {
            dispatch(resetExternalFilter())
            dispatch(resetDataGrid());
        }
        dispatch(filterResetFlagChange(true));
    }
    const addSupplierModal = () => {
        dispatch(setLatestVatCode(null))
        dispatch(handleAddEditSupplier({
            showAddEdit: !setAddEditSupplier
        }))
    }
    const handleSupplierModal = (event) => {
        setState({ ...state, openSupplierModal: false })
    }

    const deleteSupplierdata = (row) => {
        dispatch(setDeleteList(row))
        dispatch(setSupplierDeleteData({
            key: 'supplier_StartDelete',
            value: true
        }))
        dispatch(warningModal({
            yesHide: !addProductwarning
        }))
    }

    useEffect(() => {
        if (supplier_container.supplier_ConfirmDelete) {
            deleteSingleRow()
        }
    }, [supplier_container.supplier_ConfirmDelete])


    const deleteSingleRow = async () => {
        if (supplier_container.deleteList !== null) {
            try {
                setState({ ...state, pageLoader: true })
                const { data } = await axios_supplier.delete(`supplier/${supplier_container.deleteList.id}`);
                if (data.success) {
                    setState({ ...state, pageLoader: false });
                    dispatch(setDeleteList(null));
                    resetSingleDeleteRowDispatch();
                    setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Supplier deleted successfully!", type: "success" });
                    setTimeout(() => {
                        // window.location.reload();
                        dispatch(filterFlagChange(true));
                        dispatch(filterResetFlagChange(false));
                    }, 1500);
                    
                    // try {
                    //     const { data } = await axios_supplier.get(`supplier`);
                    //     if (data !== null || data !== undefined) {
                    //         dispatch(setAllSupplierList(data.items))
                    //         setState({ ...state, pageLoader: false })
                    //         setSnackBar({ ...snackBar, show: true, text: "Supplier deleted successfully", type: "success" })
                    //         dispatch(setDeleteList(null))
                    //         resetSingleDeleteRowDispatch()
                    //         setTimeout(() => {
                    //             window.location.reload();
                    //         }, 2000);
                    //     }
                    // } catch (error) {
                    //     const { response: { data, status } } = error;
                    //     if (status === 500 && data.errorType === "Business") {
                    //         setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" });
                    //     } else {
                    //         setSnackBar({ ...snackBar, show: true, text: "Something went wrong!", type: "error", })
                    //     }
                    //     setState({ ...state, pageLoader: false })
                    //     resetSingleDeleteRowDispatch()
                    // }
                }
            } catch (error) {
                const { response: { data, status } } = error;
                if (status === 500 && data.errorType === "Business") {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" });
                } else {
                    setSnackBar({ ...snackBar, show: true, text: "Something went wrong!", type: "error", })
                }
                setState({ ...state, pageLoader: false })
                resetSingleDeleteRowDispatch()
            }
        }
    }

    const resetSingleDeleteRowDispatch = () => {
        dispatch(setSupplierDeleteData({
            key: 'supplier_StartDelete',
            value: false
        }))
        dispatch(setSupplierDeleteData({
            key: 'supplier_ConfirmDelete',
            value: false
        }))
    }


    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Suppliers" />
                <Box sx={{ marginBottom: 2.5 }}>
                    <TableFilters
                        filterFields={<>
                            <Box component="div"><InputText onChange={handleFilterInput} maxWidth={50} label="Name" id="supplier_name" size="small" /></Box>
                            <Box component="div"><InputText onChange={handleFilterInput} maxWidth={50} label="Central Billing ID" id="supplier_centralBillingId" size="small" /></Box>
                        </>}
                        filterBtns={
                            <>
                                <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                <Button variant="contained" onClick={filterBtn}>Apply</Button>
                            </>}>
                    </TableFilters>
                </Box>
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -2.4 } }}>
                    {/* <div className='table-actionable'>
                        <Button variant="contained" color="secondary" size='small' startIcon={<SendIcon />}>
                            Export as Excel
                        </Button>
                    </div> */}
                    <div className='table-actionable'>
                        <Button variant="contained" size='small' onClick={(e) => addSupplierModal(e)} startIcon={<AddIcon />}>New Supplier</Button>
                    </div>
                </Box>


                <DataTableSupplierService defaultGrid={state.defaultGrid} columns={columns} rows={getSupplierLists ? getSupplierLists : []}>

                </DataTableSupplierService>
                {
                    setAddEditSupplier && (
                        <AddEditSupplier
                            onShow={setAddEditSupplier} size="medium_overlay"
                            onHide={(e, reason) => handleSupplierModal(e, reason)}

                        />
                    )
                }
                {
                    addProductwarning && (
                        <WarningModal size="small_overlay" onShow={addProductwarning} />
                    )
                }
                {snackBar.show && <SnackBarDialog
                    onShow={snackBar.show}
                    text={snackBar.text}
                    type={snackBar.type}
                    onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                />}
            </Container>

        </>
    );
}

export default Suppliers; 
