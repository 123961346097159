import { Container, Box, Modal, Button, Grid, Checkbox, Popper, Grow, Paper, ClickAwayListener, MenuList, ButtonGroup, Stack, TextField, FormControl, FormHelperText, FormLabel, IconButton, Chip, Autocomplete } from '@mui/material';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TableFilters } from "../../components/shared/data-table/TableFilters";
import { useEffect, useState, useRef, useReducer } from "react";
import { InputText } from "../../components/shared/form/InputText";
import { } from '@mui/material';
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange, setInvoiceReprocessFromDate, setInvoiceReprocessToDate, filterResetFlagChange } from "../../store/slices/externalFilter";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import moment from "moment";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import { ThreeStateCheckbox } from "../../components/shared/form/ThreeStateCheckbox";
import { warningModal, handleAddEditModal, setNewPriceModal, setInvRepProductModal } from "../../store/slices/modals";
import WarningModal from "../../components/shared/modals/WarningModal";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { setRowPage, resetDataGrid, setPageNo } from '../../store/slices/dataGrid';
import { useLayoutEffect } from "react";
import { hasTabEdit } from "../../store/slices/generalProduct";
import { AutoCompleteStatic } from "../../components/shared/form/AutoCompleteStatic";
import axios_invoice_summaries from '../../services/utility/axios-invoice-summaries';
import axios_new_invoice_summaries from '../../services/utility/axios-new-invoice-summaries';
import PageLoader from '../../components/shared/PageLoader';
import SnackBarDialog from '../../components/shared/SnackBarDialog';
import { brandSearch, hasEdit, hasEditData, smaInput, setAutocomplete, getIsError, setMemberCode } from "../../store/slices/supplierMemberAccounts";
import CommonPopUp from "../products/addEditPdc/pdc-tabs/CommonPopUp";
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/material/styles';
import { setSelectedRow } from "../../store/slices/invoiceViewDetails";
import InvoiceDetails from './detailInvoices/InvoiceDetails';
import LinesandDiscountsTab from './detailInvoices/invoice-details-tabs/lines-discounts';
import LineDisputeLogTab from './detailInvoices/invoice-details-tabs/line-dispute-log';
import SelectedInvoiceTab from './detailInvoices/invoice-details-tabs/selected-invoice';
import { DataTableInvoiceReprocess } from '../../components/shared/data-table/DataTableInvoiceReprocess';
import iconNoProcess from "../../assets/images/icon-no-process.svg"
import iconProcessingFailed from "../../assets/images/icon-processing-failed.svg"
import axiosStatementCycle from "../../services/utility/axios-statement-cycle";
import RefreshIcon from '@mui/icons-material/Refresh';
import InvoiceReprocesHistory from './detailInvoiceReprocesses/InvoiceReprocesHistory';
import { setDeleteList, setReprocessDeleteData, setProcessList, setProcessData, resetProcessData } from '../../store/slices/invoiceReprocess';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    boxShadow: "none",
    background: "transparent"
}));

function InvoiceReprocess() {

    const [query, setQuery] = useState("")

    // eslint-disable-next-line max-len
    const { invRepSupplierInvoiceCode, invRepSupplierInvoiceCodeList, invRepSupplierInvoiceCodeDumpList, invRepSupplierProductCode, invRepSupplierName, invRepBrand, invRepBrandOwner, invRepProduct, invRepMember, invRepDc, fromDate, toDate, invRepSystemDisputed, invRepUserDisputed,
        invRepCollected, addProductwarning, showAddEditModal, invoiceReprocess, searchProductModal, showNewPriceoneModal, sma_container, showNewProductModal } = useSelector((state) => {
            return {
                invRepSupplierInvoiceCode: state.externalFilter.invrpc_supplier_invcode,
                invRepSupplierInvoiceCodeList: state.externalFilter.invrpc_supplier_invcode_list,
                invRepSupplierInvoiceCodeDumpList: state.externalFilter.invrpc_supplier_invcode_list_dump,
                invRepSupplierProductCode: state.externalFilter.invrpc_spc,
                invRepSupplierName: state.externalFilter.invrpc_supplier_name,
                invRepBrand: state.externalFilter.invrpc_brand,
                invRepBrandOwner: state.externalFilter.invrpc_brand_owner,
                invRepProduct: state.externalFilter.invrpc_product,
                invRepMember: state.externalFilter.invrpc_member,
                invRepDc: state.externalFilter.invrpc_dc,
                fromDate: state.externalFilter.invrpc_from_date,
                toDate: state.externalFilter.invrpc_to_date,
                invRepSystemDisputed: state.externalFilter.invrpc_system_disputed_active,
                invRepUserDisputed: state.externalFilter.invrpc_user_disputed_active,
                invRepCollected: state.externalFilter.invrpc_collected_active,
                addProductwarning: state.modalActions.closeWaringAddProduct,
                showAddEditModal: state.modalActions.hideAddEditModal,
                invoiceReprocess: state.invoiceReprocess,
                searchProductModal: state.commonProducts,
                showNewPriceoneModal: state.modalActions.priceModal,
                showNewProductModal: state.modalActions.invrepProductModal,
                sma_container: state.smas
            }
        });

    const [state, setState] = useState({
        pageLoader: false,
        invrpc_system_disputed_active: null,
        invrpc_user_disputed_active: null,
        invrpc_collected_active: false,
        openinvoiceSummarysModal: false,
        handleOCBC: false,
        handleSupplierMemberModal: '',
        handleSPC: false,
        brandListModal: false,
        sp_name: '',
        sp_id: '',
        status_history_modal: false,
        status_history_id: null,
        processEvent: null,

        defaultGrid: {
            pdcActionButton: true,
            hasExport: true,
            addEditButton: true,
            apiUrl: 'invoicereprocess',
            searchUrl: '/invoicereprocess/filter',
            checkBoxSelection: true,
            exportExcelButton: true
        }
    });

    const [processRunning, setProcessRunning] = useState(0);
    const [failedInvoiceProcessing, setFailedInvoiceProcessing] = useState(0);
    const [failedInvoiceImport, setFailedInvoiceImport] = useState(0);
    const [inputMultipleText, setInputMultipleText] = useState("");
    const [chips, setChips] = React.useState([]);

    const handleInputMultipleChange = (event) => {
        event.stopPropagation();
        setInputMultipleText(event.target.value);
    };

    const handleKeyPress = (e) => {
        e.stopPropagation();
        if (e.keyCode == 13) {
            // Trim the input string to remove leading and trailing spaces
            const trimmedInput = inputMultipleText.trim();
            // Split the trimmed string by commas and filter out any empty strings
            const newChips = trimmedInput.split(',').map(chip => chip.trim()).filter(Boolean);
            setChips([...chips, ...newChips]);
            setInputMultipleText("");
        }
    };

    const handleDelete = (chipToDelete) => () => {
        setChips((chips) => chips.filter((chip) => chip !== chipToDelete));
        if (chips.length <= 1) {
            dispatch(setFilter({
                key: 'invrpc_supplier_invcode_list',
                value: null
            }));
            setChips([]);
        }
    };

    const dispatch = useDispatch();

    const IconMenuLink = ({ params, onReprocess_retros, onReprocess, onReverse, onCredit_reprocess, onStatus_history, onDelete }) => {
        const { row } = params;
        const [anchorEl, setAnchorEl] = useState(null);
        const open = Boolean(anchorEl);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = (event, action) => {
            event.preventDefault();
            if (action === 'view_more') {
                dispatch(handleAddEditModal({
                    showAddEdit: !showAddEditModal
                }));
                dispatch(hasTabEdit({ editTab: false }))
                dispatch(setSelectedRow({ selectedrow: row }));
            } else if (action === 'delete') {
                if (onDelete) {
                    onDelete(row);
                }
            } else if (action === 'reprocess_retros') {
                if (onReprocess_retros) {
                    onReprocess_retros(event, row)
                }
            } else if (action === 'reprocess') {
                if (onReprocess) {
                    onReprocess(event, row)
                }
            } else if (action === 'reverse') {
                if (onReverse) {
                    onReverse(event, row)
                }
            } else if (action === 'credit_reprocess') {
                if (onCredit_reprocess) {
                    onCredit_reprocess(event, row)
                }
            }
            else if (action === 'status_history') {
                if (onStatus_history) {
                    onStatus_history(event, row)
                }
            }
            setAnchorEl(null);
        };

        return (<>
            <IconButton
                aria-label="more"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                id={row.invoiceId + 1}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id={row.invoiceId}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                className="actionMenuBx"
            >
                <MenuItem onClick={(e) => handleClose(e, 'view_more')}>View More</MenuItem>
                <MenuItem onClick={(e) => handleClose(e, 'reprocess_retros')}>Reprocess Retros</MenuItem>
                <MenuItem onClick={(e) => handleClose(e, 'reprocess')}>Reprocess</MenuItem>
                <MenuItem onClick={(e) => handleClose(e, 'reverse')}>Reverse</MenuItem>
                <MenuItem onClick={(e) => handleClose(e, 'credit_reprocess')}>Credit and Reprocess</MenuItem>
                <MenuItem onClick={(e) => handleClose(e, 'status_history')}>Status History</MenuItem>
                <MenuItem onClick={(e) => handleClose(e, 'delete')}>Remove Entry</MenuItem>
            </Menu>
        </>)
    }

    //Getting User Name from MSAL

    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const user_name = accounts[0] && accounts[0].name;

    const tabs = [
        {
            label: "Lines & Discounts",
            Component: <LinesandDiscountsTab />
        },
        {
            label: "Line Dispute Log",
            Component: <LineDisputeLogTab />
        },
        {
            label: "Selected Invoice",
            Component: <SelectedInvoiceTab />
        }
    ];

    const columns = [
        {
            headerName: "Supplie Invoice Code",
            field: "supplierInvoiceCode",
            flex: 1,
            minWidth: 100
        },
        {
            headerName: "Import Date",
            field: "importDate",
            flex: 1,
            minWidth: 120
        },

        {
            headerName: "Amount (£)",
            field: "grossTotal",
            flex: 1,
            width: 170
        },
        {
            headerName: "DC",
            field: "distributionChannel",
            flex: 1,
            width: 50
        },
        {
            headerName: "SC",
            field: "statementCycleid",
            flex: 1,
            width: 50
        },
        {
            headerName: "Supplier",
            field: "suppliername",
            flex: 1,
            minWidth: 120
        },
        {
            headerName: "Member Account",
            field: "memberAccountName",
            flex: 1,
            minWidth: 120
        },
        {
            headerName: "SMA Code",
            field: "supplierMemberAccountCode",
            flex: 1,
            minWidth: 100
        },
        {
            headerName: "Member Outlet",
            field: "memberOutlet",
            flex: 1,
            minWidth: 100
        },
        {
            headerName: "Statement Cycle Stage",
            field: "statementCycleStage",
            flex: 1,
            width: 180
        },
        {
            headerName: "Invoice Date",
            field: "invoiceDate",
            flex: 1,
            minWidth: 120
        },
        {
            headerName: "Delivery Date",
            field: "deliveryDate",
            flex: 1,
            minWidth: 120
        },
        {
            headerName: "Status",
            field: "invoiceProcessStatusDTO",
            flex: 1,
            minWidth: 200,
            valueGetter: (params) => params.row.invoiceProcessStatusDTO.length > 0 ? (params.row.invoiceProcessStatusDTO[0].processName + '-' + params.row.invoiceProcessStatusDTO[0].succeeded) : "",
        },
        {
            headerName: "User Dispute",
            field: "userDisputed",
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
            headerAlign: "center",
            align: 'center',
            minWidth: 110
        },
        {
            headerName: "System Dispute",
            field: "systemDisputed",
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
            headerAlign: "center",
            align: 'center',
            minWidth: 130
        },
        {
            headerName: "Closed",
            field: "closed",
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
            headerAlign: "center",
            align: 'center',
            width: 80
        },
        {
            headerName: "Purchasing Tier",
            field: "memberTier",
            valueGetter: (params) => params.row.memberTier ? params.row.memberTier.name : "",
            minWidth: 150
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params}
                    onReprocess_retros={(e, rows) => { onReprocess_retros(e, rows) }}
                    onReprocess={(e, rows) => { onReprocess(e, rows) }}
                    onReverse={(e, rows) => { onReverse(e, rows) }}
                    onCredit_reprocess={(e, rows) => { onCredit_reprocess(e, rows) }}
                    onStatus_history={(e, rows) => { onStatus_history(e, rows) }}
                    onDelete={deleteInvoiceReprocess}
                // onReprocess_retros={(e, rows) => { onReprocess_retros(e, rows) }}
                />;
            },
            disableExport: true
        }

    ];

    useEffect(() => {
        // This Use Effect is used to clear the Lookups when the page loads.
        dispatch(setMemberCode({
            key: 'smaSupplierNameOnModal',
            value: ""
        }));
        dispatch(smaInput({
            key: 'sma_supplier_name',
            value: ""
        }));
        dispatch(setMemberCode({
            key: 'smaMemberCodeOnModal',
            value: ""
        }));
        dispatch(smaInput({
            key: 'sma_member_name',
            value: ""
        }));

        dispatch(setMemberCode({
            key: 'smaSupplierCodeOnModal',
            value: ""
        }));

    }, [])

    const addOCBC = (e) => {
        e.preventDefault()
        setState({ ...state, handleOCBC: true })

    }

    const hideOCBCModal = (params, row) => {
        if (row) {
            setState({ ...state, handleOCBC: params === false ? params : true, product_name: row.name, outer_case_barcode: row.outerCaseBarcode });
        }
        else {
            setState({ ...state, handleOCBC: params === false ? params : true });
        }
    }

    const loadMemberAccountWithMemberId = (e) => {
        if (e !== '') {
            setQuery(e)
        }
    }

    const CommonPopUpModal = (params, event) => {
        if (event === 'supplier') {
            setState({ ...state, handleSupplierMemberModal: event })
            dispatch(setNewPriceModal(true))
        }
        if (event === 'supplier-code') {
            if (invRepSupplierName === null || invRepSupplierName === "") {
                setSnackBar({ ...snackBar, show: true, text: "Please select supplier", type: "error" })
            } else {
                setState({ ...state, handleSPC: true, handleSupplierMemberModal: event })
            }
        }
        if (event === 'members') {
            setState({ ...state, handleSupplierMemberModal: event })
            dispatch(setNewPriceModal(true))
        }
        if (event === 'product') {
            setState({ ...state, handleSupplierMemberModal: '' })
            dispatch(setInvRepProductModal(true))
        }
        // if (event === 'brand') {
        //     setState({ ...state, brandListModal: true })
        // }
    }

    const hidePriceModal = (event, reason, type) => {
        if (type === 'product') {
            if (reason) {
                dispatch(setFilter({
                    key: 'invrpc_product',
                    value: reason.id ? reason : ''
                }))
            }
            dispatch(setInvRepProductModal(event));
        }
        // if (type === 'brand') {
        //     if (reason) {
        //         dispatch(setFilter({
        //             key: 'invrpc_brand',
        //             value: reason ? reason : ''
        //         }))
        //     }
        //     setState({ ...state, brandListModal: event === false ? event : true })
        // }
        if (type === 'supplier') {
            if (reason) {
                dispatch(setFilter({
                    key: 'invrpc_supplier_name',
                    value: reason ? reason : null
                }))
            }
            dispatch(setNewPriceModal(event));
        }
        if (type === 'supplier-code') {
            if (reason) {
                dispatch(setMemberCode({
                    key: 'smaSupplierCodeOnModal',
                    value: reason ? reason.supplierProductCode : null
                }));
                dispatch(setFilter({
                    key: 'invrpc_spc',
                    value: reason ? reason.supplierProductCode : null
                }))
            }
            setState({ ...state, handleSPC: event })
        }
        if (type === 'members') {
            if (reason) {
                dispatch(setFilter({
                    key: 'invrpc_member',
                    value: reason ? reason : null
                }))
            }
            dispatch(setNewPriceModal(event));
        }
    }

    const resetLookups = (params, event) => {
        if (event === 'supplier') {
            dispatch(setMemberCode({
                key: 'smaSupplierNameOnModal',
                value: ""
            }))
            dispatch(smaInput({
                key: 'sma_supplier_name',
                value: ""
            }))
            dispatch(setFilter({
                key: 'invrpc_supplier_name',
                value: null
            }))
        }
        if (event === 'members') {
            dispatch(setMemberCode({
                key: 'smaMemberCodeOnModal',
                value: ""
            }))
            dispatch(smaInput({
                key: 'sma_member_name',
                value: ""
            }))

        }
        if (event === "product") {
            dispatch(setFilter({
                key: 'invrpc_product',
                value: ""
            }))
        }
        // if (event === "brand") {
        //     dispatch(setFilter({
        //         key: 'invrpc_brand',
        //         value: ""
        //     }))
        // }
        if (event === 'supplier-code') {
            dispatch(setMemberCode({
                key: 'smaSupplierCodeOnModal',
                value: ''
            }));
        }

    }

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false

    })

    const handleStartDate = (event) => {
        const sDate = (event === null) ? null : moment(event, 'DD/MM/YYYY')
        dispatch(setInvoiceReprocessFromDate(sDate))
    };

    const handleEndDate = (event) => {
        const eDate = (event === null) ? null : moment(event, 'DD/MM/YYYY')
        dispatch(setInvoiceReprocessToDate(eDate))
    };

    const handleFilterInput = (value, id) => {
        //setState({ ...state, [id]: value });
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    };

    const handleChange = (checked, name) => {
        setState({ ...state, [name]: checked })
        dispatch(setFilter({
            key: name,
            value: checked
        }))
    };

    const selectedAutoComplete = (value, id) => {
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    }

    const filterBtn = () => {
        dispatch(resetDataGrid());
        var dynamicFilterObjects = {};

        if (invRepSupplierInvoiceCode !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "supplierInvoiceCode": invRepSupplierInvoiceCode
            }
        }
        if (invRepSupplierInvoiceCodeList !== null) {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "supplierInvoiceCodeList": invRepSupplierInvoiceCodeList
            }
        }
        if (invRepSupplierProductCode !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "supplierProductCode": invRepSupplierProductCode
            }
        }
        if (invRepSupplierName !== '' && invRepSupplierName) {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "supplierId": invRepSupplierName.id,
                "suppliername": invRepSupplierName.name
            }
        }
        if (invRepDc !== '' && invRepDc) {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "distributionChannelid": invRepDc.id
            }
        }
        if (fromDate !== '' && fromDate !== null) {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "invoiceDate": moment(fromDate).format('DD/MM/YYYY')
            }
        }
        if (toDate !== '' && toDate !== null) {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "invoiceEndDate": moment(toDate).format('DD/MM/YYYY')
            }
        }
        if (invRepMember !== '' && invRepMember) {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "memberId": invRepMember.id,
                "member": invRepMember.name
            }
        }
        if (invRepBrand !== '' && invRepBrand) {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "brandId": invRepBrand.id
            }
        }
        if (invRepBrandOwner !== '' && invRepBrandOwner) {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "brandOwnerId": invRepBrandOwner.id
            }
        }
        if (invRepProduct !== '' && invRepProduct) {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "productId": invRepProduct.id
            }
        }

        if ((fromDate !== "" && fromDate !== null && moment(fromDate).format('DD/MM/YYYY') === 'Invalid date') || (toDate !== "" && toDate !== null && moment(toDate).format('DD/MM/YYYY') === 'Invalid date')) {
            setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
        }
        else {
            dispatch(applyFilter({
                key: 'filterList',
                value: {
                    ...dynamicFilterObjects,
                    "userDisputed": invRepUserDisputed,
                    "systemDisputed": invRepSystemDisputed,
                    "collectedFromMember": invRepCollected
                }
            }));
            dispatch(filterFlagChange(true));
            dispatch(filterResetFlagChange(false));
            dispatch(setRowPage({ pageData: 10 }));
            dispatch(setPageNo({ page: 1 }));
        }


    }
    const resetFilter = () => {
        if ((invRepSupplierName || invRepSupplierInvoiceCode || invRepSupplierProductCode || invRepBrand || invRepBrandOwner || invRepProduct || invRepMember || invRepDc || fromDate || toDate) !== '') {
            // console.log("if")
            dispatch(resetExternalFilter())
            dispatch(resetDataGrid());
            dispatch(setMemberCode({
                key: 'smaSupplierNameOnModal',
                value: ""
            }));
            dispatch(smaInput({
                key: 'sma_supplier_name',
                value: ""
            }));
            dispatch(setMemberCode({
                key: 'smaMemberCodeOnModal',
                value: ""
            }));
            dispatch(smaInput({
                key: 'sma_member_name',
                value: ""
            }));
            dispatch(setMemberCode({
                key: 'smaSupplierCodeOnModal',
                value: ''
            }));
            dispatch(setFilter({
                key: 'invrpc_product',
                value: ""
            }))
            // dispatch(setFilter({
            //     key: 'invrpc_brand',
            //     value: ""
            // }))
        }

        setState({ ...state, invrpc_system_disputed_active: null, invrpc_collected_active: false, invrpc_user_disputed_active: null });
        dispatch(filterResetFlagChange(true));
        dispatch(setFilter({
            key: 'invrpc_collected_active',
            value: false
        }))
        dispatch(setFilter({
            key: 'invrpc_supplier_invcode_list',
            value: null
        }))

        setChips([]);
    }

    const hideInvoiceSummaryModal = (event) => {
        setState({ ...state, openinvoiceSummarysModal: false })
    }

    const onReprocessInvoice = async (event, params) => {
        event.preventDefault();

        let processId = null;
        if (params) {
            processId = params
        } else {
            processId = invoiceReprocess.reprocessIds
        }

        let dFObjects = {};
        dFObjects = {
            "userName": user_name,
            "invoiceIds": processId
        }

        // console.log("reprocess: ", dFObjects);

        if (processId.length > 0) {
            try {
                setState({ ...state, pageLoader: true })
                // const { data } = await axios_invoice_summaries.post(`/invoice/reprocess`, processId);
                const { data } = await axios_invoice_summaries.post(`/invoice/reprocess`, dFObjects);
                if (data.success) {
                    setState({ ...state, pageLoader: false })
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
                    setTimeout(() => {
                        // window.location.reload()
                        dispatch(filterFlagChange(true));
                        dispatch(filterResetFlagChange(false));
                    }, 2500);
                }
                dispatch(resetProcessData());

            } catch (error) {
                const { response: { data, status } } = error;
                dispatch(resetProcessData());
                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                }
                setState({ ...state, pageLoader: false })
            }
        } else {
            setSnackBar({ ...snackBar, show: true, text: "Please select an invoice!", type: "error" })
        }
    }
    const onReprocessRetros = async (event, params) => {
        event.preventDefault();

        let processId = null;
        if (params) {
            processId = params
        } else {
            processId = invoiceReprocess.reprocessIds
        }

        let dFObjects = {};
        dFObjects = {
            "userName": user_name,
            "invoiceIds": processId
        }

        // console.log("reprocessRetroQuantities: ", dFObjects);

        if (processId.length > 0) {
            try {
                setState({ ...state, pageLoader: true })
                // const { data } = await axios_invoice_summaries.post(`/invoice/reprocessRetroQuantities`, processId);
                const { data } = await axios_invoice_summaries.post(`/invoice/reprocessRetroQuantities`, dFObjects);
                if (data.success) {
                    setState({ ...state, pageLoader: false })
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
                    setTimeout(() => {
                        // window.location.reload()
                        dispatch(filterFlagChange(true));
                        dispatch(filterResetFlagChange(false));
                    }, 2500);
                }
                dispatch(resetProcessData());

            } catch (error) {
                const { response: { data, status } } = error;
                dispatch(resetProcessData());
                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                }
                setState({ ...state, pageLoader: false })
            }
        } else {
            setSnackBar({ ...snackBar, show: true, text: "Please select an invoice!", type: "error" })
        }
    }
    const onReprocessReverse = async (event, params) => {
        event.preventDefault();

        let processId = null;
        if (params) {
            processId = params
        } else {
            processId = invoiceReprocess.reprocessIds
        }

        let dFObjects = {};
        dFObjects = {
            "userName": user_name,
            "invoiceIds": processId
        }

        // console.log("reverse: ", dFObjects);

        if (processId.length > 0) {
            try {
                setState({ ...state, pageLoader: true })
                // const { data } = await axios_invoice_summaries.post(`/invoice/reverse`, processId);
                const { data } = await axios_invoice_summaries.post(`/invoice/reverse`, dFObjects);
                if (data.success) {
                    setState({ ...state, pageLoader: false })
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
                    setTimeout(() => {
                        // window.location.reload()
                        dispatch(filterFlagChange(true));
                        dispatch(filterResetFlagChange(false));
                    }, 2500);
                }
                dispatch(resetProcessData());

            } catch (error) {
                const { response: { data, status } } = error;
                dispatch(resetProcessData());
                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                }
                setState({ ...state, pageLoader: false })
            }
        } else {
            setSnackBar({ ...snackBar, show: true, text: "Please select an invoice!", type: "error" })
        }
    }
    const onReprocessCredit = async (event, params) => {
        event.preventDefault();

        let processId = null;
        if (params) {
            processId = params
        } else {
            processId = invoiceReprocess.reprocessIds
        }

        let dFObjects = {};
        dFObjects = {
            "userName": user_name,
            "invoiceIds": processId
        }

        // console.log("reverseAndResubmit: ", dFObjects);

        if (processId.length > 0) {
            try {
                setState({ ...state, pageLoader: true })
                // const { data } = await axios_invoice_summaries.post(`/invoice/reverseAndResubmit`, processId);
                const { data } = await axios_invoice_summaries.post(`/invoice/reverseAndResubmit`, dFObjects);
                if (data.success) {
                    setState({ ...state, pageLoader: false })
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
                    setTimeout(() => {
                        // window.location.reload()
                        dispatch(filterFlagChange(true));
                        dispatch(filterResetFlagChange(false));
                    }, 2500);
                }
                dispatch(resetProcessData());

            } catch (error) {
                const { response: { data, status } } = error;
                dispatch(resetProcessData());
                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                }
                setState({ ...state, pageLoader: false })
            }
        } else {
            setSnackBar({ ...snackBar, show: true, text: "Please select an invoice!", type: "error" })
        }
    }
    const onReprocess_retros = (event, params) => {
        const { invoiceId } = params;
        triggerInvoiceProcess(event, 'reprocess_retros', [invoiceId]);
        // onReprocessRetros(event, [invoiceId])
    }
    const onReprocess = (event, params) => {
        const { invoiceId } = params;
        triggerInvoiceProcess(event, 'reprocess', [invoiceId]);
        // onReprocessInvoice(event, [invoiceId])
    }
    const onReverse = (event, params) => {
        const { invoiceId } = params;
        triggerInvoiceProcess(event, 'reverse', [invoiceId]);
        // onReprocessReverse(event, [invoiceId])
    }
    const onCredit_reprocess = (event, params) => {
        const { invoiceId } = params;
        triggerInvoiceProcess(event, 'credit_response', [invoiceId]);
        // onReprocessCredit(event, [invoiceId])
    }
    useEffect(() => {
        importedInvoiceFailed();
        invoiceProcessingFailed();
        checkProcessRunning();
    }, [])
    const importedInvoiceFailed = async (url) => {
        try {
            const { data } = await axios_new_invoice_summaries.get('/deadImportedInvoices/countDeadImportedInvoice')
            if (data) {
                setFailedInvoiceImport(data.resultMap.countDeadImportedInvoice)

            }
        } catch (error) {
            const { response: { data, status }, message } = error;
            if (status === 500 || status === 501 || status === 502 || status === 503 || status === 504 || status === 505 || status === 0) {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" });
            } else {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
            }
            setState({ ...state, loader: false })
        }
    }
    const invoiceProcessingFailed = async (url) => {
        try {
            const { data } = await axios_new_invoice_summaries.get('/deadInvoiceData/countDeadInvoiceData')
            if (data) {
                setFailedInvoiceProcessing(data.resultMap.countDeadInvoiceData)

            }
        } catch (error) {
            const { response: { data, status }, message } = error;
            if (status === 500 || status === 501 || status === 502 || status === 503 || status === 504 || status === 505 || status === 0) {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" });
            } else {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
            }
            setState({ ...state, loader: false })
        }
    }
    const checkProcessRunning = async (url) => {
        try {
            const { data } = await axiosStatementCycle.get('/statementcycle/getAllRunningProcess')
            if (data) {
                setProcessRunning(data.resultMap.RunningProcessCount)

            }
        } catch (error) {
            const { response: { data, status }, message } = error;
            if (status === 500 || status === 501 || status === 502 || status === 503 || status === 504 || status === 505) {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" });
            }
            else if (error.message === 'Network Error') {
                setSnackBar({ ...snackBar, show: true, text: "The services are down at the moment.", type: "error", });
            }
            else {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
            }
            setState({ ...state, loader: false })
        }
    }

    const onStatus_history = (e, params) => {
        setState({ ...state, status_history_modal: true, status_history_id: params.invoiceId })
    }

    const deleteInvoiceReprocess = (row) => {
        dispatch(setDeleteList(row))
        dispatch(setReprocessDeleteData({
            key: 'reprocessStartDelete',
            value: true
        }))
        dispatch(warningModal({
            yesHide: !addProductwarning
        }))
    }

    const triggerInvoiceProcess = (event, label, data) => {
        // console.log(event, label, data);
        setState({ ...state, processEvent: event });
        let processId = null;
        if (data) {
            processId = data;
        } else {
            processId = invoiceReprocess.reprocessIds;
        }

        if (processId.length > 0) {
            dispatch(setProcessList(data));
            dispatch(setProcessData({
                key: 'processStart',
                value: true
            }));
            dispatch(setProcessData({
                key: 'processLabel',
                value: label
            }));
            switch (label) {
                case "reprocess_retros":
                    dispatch(setProcessData({
                        key: 'processName',
                        value: 'Reprocess Retros'
                    }));
                    break
                case "reprocess":
                    dispatch(setProcessData({
                        key: 'processName',
                        value: 'Reprocess'
                    }));
                    break
                case "reverse":
                    dispatch(setProcessData({
                        key: 'processName',
                        value: 'Reverse'
                    }));
                    break
                case "credit_response":
                    dispatch(setProcessData({
                        key: 'processName',
                        value: 'Credit and Reprocess'
                    }));
                    break
                default:
                    dispatch(setProcessData({
                        key: 'processName',
                        value: ''
                    }));
            }
            dispatch(warningModal({
                yesHide: true
            }))
        } else {
            setSnackBar({ ...snackBar, show: true, text: "Please select an invoice!", type: "error" })
        }
    }

    useEffect(() => {
        // console.log(invoiceReprocess);
        if (invoiceReprocess.processConfirm) {
            // deleteSingleRow()
            // console.log(invoiceReprocess.processName, invoiceReprocess.processList);
            // dispatch(resetinvoiceReprocess());
            switch (invoiceReprocess.processLabel) {
                case "reprocess_retros":
                    onReprocessRetros(state.processEvent, invoiceReprocess.processList)
                    break
                case "reprocess":
                    onReprocessInvoice(state.processEvent, invoiceReprocess.processList)
                    break
                case "reverse":
                    onReprocessReverse(state.processEvent, invoiceReprocess.processList)
                    break
                case "credit_response":
                    onReprocessCredit(state.processEvent, invoiceReprocess.processList)
                    break
                default:

            }
        }
    }, [invoiceReprocess.processConfirm])

    useEffect(() => {
        if (invoiceReprocess.reprocessConfirmDelete) {
            deleteSingleRow()
        }
    }, [invoiceReprocess.reprocessConfirmDelete])


    const deleteSingleRow = async () => {
        if (invoiceReprocess.deleteList !== null) {
            try {
                setState({ ...state, pageLoader: true });
                // console.log(invoiceReprocess.deleteList, invoiceReprocess.deleteList.invoiceId, axios_invoice_summaries);
                const { data } = await axios_new_invoice_summaries.delete(`invoicesummary/${invoiceReprocess.deleteList.invoiceId}`);
                if (data.success) {
                    resetSingleDeleteRowDispatch();
                    setState({ ...state, pageLoader: false });
                    setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Invoice soft deleted successfully!", type: "success" });
                    setTimeout(() => {
                        // window.location.reload();
                        dispatch(filterFlagChange(true));
                        dispatch(filterResetFlagChange(false));
                    }, 1500);
                }
            } catch (error) {
                const { response: { data, status } } = error;
                if (status === 500 && data.errorType === "Business") {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" });
                } else {
                    setSnackBar({ ...snackBar, show: true, text: "Something went wrong!", type: "error", })
                }
                setState({ ...state, pageLoader: false })
                resetSingleDeleteRowDispatch()
            }
        }
    }

    const resetSingleDeleteRowDispatch = () => {
        dispatch(setReprocessDeleteData({
            key: 'reprocessStartDelete',
            value: false
        }))
        dispatch(setReprocessDeleteData({
            key: 'reprocessConfirmDelete',
            value: false
        }))
    }

    useEffect(() => {
        // Access the newest data
        if (chips.length > 0 && chips !== null) {
            dispatch(setFilter({
                key: 'invrpc_supplier_invcode_list',
                value: chips ? chips : null
            }))
        }
    }, [chips]);

    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Invoice Reprocess" />
                <Box sx={{ marginBottom: 2.5 }}>
                    <TableFilters
                        filterFields={<>
                            <Box component="div">
                                {/* <FormLabel htmlFor="supplier_invoice_member_account" className="fontBold">Supplier</FormLabel> */}
                                {/* <Grid container alignItems="center">

                                    <Grid item xs={12} md={11}>
                                        <Item>
                                            <Box component="div" sx={{ paddingTop: '6px' }}>
                                                <FormControl size={'small'} fullWidth>
                                                    <TextField
                                                        inputProps={{ maxLength: 100 }}
                                                        size="small"
                                                        id="invrpc_supplier_name"
                                                        value={(sma_container.smaSupplierNameOnModal ? sma_container.smaSupplierNameOnModal.name : '')}
                                                        aria-describedby="invrpc_supplier_name_error"

                                                        name="invrpc_supplier_name"
                                                        disabled={true}
                                                    />

                                                </FormControl>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        <Item>
                                            <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0, marginRight: '5px' }} onClick={(e) => resetLookups(e, 'supplier')} startIcon={<ClearIcon />}></Button>
                                            <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={(e) => CommonPopUpModal(e, 'supplier')} startIcon={<EditIcon />}></Button>
                                        </Item>
                                    </Grid>

                                </Grid> */}

                                <AutoCompleteStatic endPoint="/supplier/look-up" onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} id="invrpc_supplier_name" label="Supplier" name="invrpc_supplier_name" />
                            </Box>
                            {/* <Box component="div">
                                <FormLabel htmlFor="supplier_invoice_member_account" className="fontBold">Brand</FormLabel>
                                <Grid container alignItems="center" sx={{ paddingTop: '5px' }}>
                                    <Grid item xs={12} md={11}>
                                        <Item>
                                            <Box component="div">
                                                <FormControl size={'small'} fullWidth>
                                                    <TextField
                                                        inputProps={{ maxLength: 100 }}
                                                        size="small"
                                                        id="invrpc_brand"
                                                        value={(invRepBrand ? invRepBrand.name : '')}
                                                        aria-describedby="product_brand_error"
                                                        name="invrpc_brand"
                                                        disabled={true}
                                                    />

                                                </FormControl>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        <Item>
                                            <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0, marginRight: '5px' }} onClick={(e) => resetLookups(e, 'brand')} startIcon={<ClearIcon />}></Button>
                                            <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={(e) => CommonPopUpModal(e, 'brand')} startIcon={<EditIcon />}></Button>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box> */}
                            <Box component="div"><AutoCompleteStatic endPoint="brands/look-up" onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} id="invrpc_brand" label="Brand" name="invrpc_brand" />
                            </Box>
                            <Box component="div"><AutoCompleteStatic endPoint="supplier/drop-down?dcType=7" onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} id="invrpc_brand_owner" label="Brand Owner" name="invrpc_brand_owner" /></Box>
                            <Box component="div">
                                <FormLabel htmlFor="supplier_invoice_member_account" className="fontBold">Product</FormLabel>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={11}>
                                        <Item>
                                            <Box component="div" sx={{ paddingTop: '6px' }} >
                                                <FormControl size={'small'} fullWidth>
                                                    <TextField
                                                        inputProps={{ maxLength: 100 }}
                                                        size="small"
                                                        id="invrpc_product"
                                                        value={(invRepProduct ? invRepProduct.name : '')}
                                                        aria-describedby="invrpc_product_error"
                                                        name="invrpc_product"
                                                        disabled={true}
                                                        onChange={handleFilterInput}
                                                    />

                                                </FormControl>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} md={1} sx={{ padding: '0' }}>
                                        <Item>
                                            <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0, marginRight: '5px' }} onClick={(e) => resetLookups(e, 'product')} startIcon={<ClearIcon />}></Button>
                                            <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={(e) => CommonPopUpModal(e, 'product')} startIcon={<EditIcon />}></Button>
                                        </Item>
                                    </Grid>

                                </Grid>

                            </Box>
                            <Box component="div">
                                <FormLabel htmlFor="supplier_invoice_member_account" className="fontBold">Supplier Product Code</FormLabel>
                                <Grid container alignItems="center">

                                    <Grid item xs={12} md={11}>
                                        <Item>
                                            <Box component="div" sx={{ paddingTop: '6px' }}>
                                                <FormControl size={'small'} fullWidth>
                                                    <TextField
                                                        inputProps={{ maxLength: 100 }}
                                                        size="small"
                                                        id="invrpc_spc"
                                                        value={(sma_container.smaSupplierCodeOnModal ? sma_container.smaSupplierCodeOnModal : '')}
                                                        aria-describedby="invrpc_supplier_name_error"
                                                        name="invrpc_supplier_name"
                                                        disabled={true}
                                                    />
                                                </FormControl>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        <Item>
                                            <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0, marginRight: '5px' }} onClick={(e) => resetLookups(e, 'supplier-code')} startIcon={<ClearIcon />}></Button>
                                            <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={(e) => CommonPopUpModal(e, 'supplier-code')} startIcon={<EditIcon />}></Button>
                                        </Item>
                                    </Grid>

                                </Grid>
                            </Box>
                            <Box component="div">
                                <FormLabel htmlFor="invrpc_from_date" className="fontBold">Invoice Start Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        value={fromDate}
                                        onChange={(e) => { handleStartDate(e) }}
                                        name="invrpc_from_date" label="Start Date"
                                        inputFormat="DD/MM/YYYY"
                                        // eslint-disable-next-line max-len
                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                            <Box component="div" sx={{ paddingTop: '6px' }} className='date-picker-input'>
                                                <FormControl size="small" sx={{ width: '100%' }} >
                                                    <TextField size="small" id="invrpc_from_date"
                                                        ref={inputRef} {...inputProps}
                                                        placeholder=""
                                                        aria-describedby="invrpc_from_date_error"
                                                        autoComplete="off"
                                                    />
                                                    <Box className='date-picker-icon'>
                                                        {InputProps?.endAdornment}
                                                    </Box>
                                                </FormControl>
                                            </Box>
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>
                            <Box component="div">
                                <FormLabel htmlFor="invrpc_to_date" className="fontBold">Invoice End Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        value={toDate}
                                        onChange={(e) => { handleEndDate(e) }}
                                        name="invrpc_to_date" label="End Date"
                                        minDate={moment(fromDate, "DD/MM/YYYY").add(1, 'days')}
                                        inputFormat="DD/MM/YYYY"
                                        // eslint-disable-next-line max-len
                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                            <Box component="div" sx={{ paddingTop: '6px' }} className='date-picker-input'>
                                                <FormControl size="small" sx={{ width: '100%' }} >
                                                    <TextField size="small" id="invrpc_to_date"
                                                        ref={inputRef} {...inputProps}
                                                        placeholder=""
                                                        aria-describedby="to_date_error"
                                                        autoComplete="off"
                                                    />
                                                    <Box className='date-picker-icon'>
                                                        {InputProps?.endAdornment}
                                                    </Box>
                                                </FormControl>
                                            </Box>
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>
                            <Box component="div">
                                <AutoCompleteStatic endPoint="members/look-up?pageSize=500" onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} id="invrpc_member" label="Member" name="invrpc_member" />
                            </Box>
                            {/* <Box component="div">
                                <FormLabel htmlFor="supplier_invoice_member_account" className="fontBold">Member</FormLabel>
                                <Grid container alignItems="center">

                                    <Grid item xs={12} md={11}>
                                        <Item>
                                            <Box component="div" sx={{ paddingTop: '6px' }}>
                                                <FormControl size={'small'} fullWidth>
                                                    <TextField
                                                        inputProps={{ maxLength: 100 }}
                                                        size="small"
                                                        id="invrpc_member"
                                                        value={(sma_container.smaMemberCodeOnModal ? sma_container.smaMemberCodeOnModal.name : '') || (sma_container.sma_member_name ? sma_container.sma_member_name.name : '')}
                                                        aria-describedby="invrpc_member_error"

                                                        name="invrpc_member"
                                                        disabled={true}
                                                    />

                                                </FormControl>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        <Item>
                                            <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0, marginRight: '5px' }} onClick={(e) => resetLookups(e, 'members')} startIcon={<ClearIcon />}></Button>
                                            <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={(e) => CommonPopUpModal(e, 'members')} startIcon={<EditIcon />}></Button>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box> */}
                            <Box component="div">
                                <AutoCompleteStatic endPoint="distributionChannels/drop-down" onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} id="invrpc_dc" label="Distribution Channel" name="invrpc_dc" />
                            </Box>
                            {/* <Box component="div"><AutoCompleteStatic endPoint="brands/drop-down" onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} id="invrpc_brand" label="Brand" name="invrpc_brand" /></Box> */}




                            {/* <Box component="div">
                                    <InputText onChange={handleFilterInput} maxWidth={50} label="Supplier Product Code" id="invrpc_spc" size="small" />
                                </Box> */}
                            {/* <div style={{ width: '94vw', display: 'grid', alignItems: 'center', columnGap: 12, rowGap: 20, gridTemplateColumns: 'repeat(5, 1fr)', marginBottom: '20px', padding: 0 }}></div> */}
                        </>}
                        chips={<>
                            {/* <Box component="div">
                            <AutoCompleteStatic endPoint="/supplier/look-up" onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} id="invrpc_supplier_name" label="Supplier Invoice Code" name="invrpc_supplier_name" />
                            </Box> */}
                            <Box component="div" className="customAutoComplete">
                                <Autocomplete
                                    id="invrpc_supplier_invcode_list"
                                    name="invrpc_supplier_invcode_list"
                                    multiple
                                    freeSolo // Allow free-form input
                                    value={chips} // Controlled value for chips
                                    clearOnBlur // Extra text will be cleared on blur
                                    options={[]} // No predefined options needed here                                   
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                key={option + index} // Unique key combination
                                                variant="outlined"
                                                label={option}
                                                {...getTagProps({ index })}
                                                onDelete={handleDelete(option)}
                                            />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Supplier Invoice Code"
                                            variant="outlined"
                                            value={inputMultipleText}
                                            onChange={handleInputMultipleChange}
                                            onKeyDown={(e) => { handleKeyPress(e) }}
                                        />
                                    )}
                                    disableClearable={true} // hides the clear button
                                />
                            </Box>
                        </>}
                        filterCheckboxes={<>
                            <Box component="div">
                                <ThreeStateCheckbox checked={state.invrpc_collected_active} onChange={handleChange} name="invrpc_collected_active" id="invrpc_collected_active" label="Collected" />
                            </Box>
                            <Box component="div">
                                <ThreeStateCheckbox checked={state.invrpc_system_disputed_active} onChange={handleChange} name="invrpc_system_disputed_active" id="invrpc_system_disputed_active" label="System Disputed" />
                            </Box>
                            <Box component="div">
                                <ThreeStateCheckbox checked={state.invrpc_user_disputed_active} onChange={handleChange} name="invrpc_user_disputed_active" id="invrpc_user_disputed_active" label="User Disputed" />
                            </Box>


                        </>}
                        processRunning={<>
                            <Box component="div">
                                <Grid container alignItems={'center'}>
                                    <Grid>
                                        <Item>
                                            <img src={iconNoProcess} alt="icon" />
                                        </Item>
                                    </Grid>
                                    <Grid sx={{ paddingTop: '3px' }}>
                                        <Item>
                                            {
                                                processRunning ? `Process running (${processRunning})` : 'No process running'
                                            }
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box component="div">
                                <Grid container alignItems={'center'}>
                                    <Grid>
                                        <Item>
                                            <img src={iconProcessingFailed} alt="icon" />
                                        </Item>
                                    </Grid>
                                    <Grid sx={{ paddingTop: '3px' }}>
                                        <Item>
                                            {`Invoice processing failed (${failedInvoiceProcessing})`}
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box component="div">
                                <Grid container alignItems={'center'}>
                                    <Grid>
                                        <Item>
                                            <img src={iconProcessingFailed} alt="icon" />
                                        </Item>
                                    </Grid>
                                    <Grid sx={{ paddingTop: '3px' }}>
                                        <Item>
                                            {`Invoice file import failed (${failedInvoiceImport})`}
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box component="div">
                                <Box component={'div'} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={(e) => window.location.reload()}>
                                    <RefreshIcon color="primary"></RefreshIcon> <span style={{ marginLeft: '5px', color: '#B253AE' }}>Refresh</span>
                                </Box>
                            </Box>
                        </>}
                        filterBtns={
                            <>
                                <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                <Button variant="contained" onClick={filterBtn}>Apply</Button>
                            </>}>

                    </TableFilters>
                </Box>
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', alignItems: 'center', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -3 } }}>
                    <div className='table-actionable'>
                        <Box component={'div'} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={filterBtn}>
                            <RefreshIcon color="primary"></RefreshIcon> <span style={{ marginLeft: '5px', color: '#B253AE' }}>Reload Table</span>
                        </Box>
                    </div>
                    {/* <div className='table-actionable'>
                        <Button variant="contained" color="tertiary" size='small' onClick={(e) => { onReprocessRetros(e) }}>
                            Reprocess Retros
                        </Button>
                    </div> */}
                    <div className='table-actionable'>
                        <Button variant="contained" color="tertiary" size='small' onClick={(e) => { triggerInvoiceProcess(e, 'reprocess_retros') }}>
                            Reprocess Retros
                        </Button>
                    </div>
                    <div className='table-actionable'>
                        <Button variant="contained" size='small' onClick={(e) => { triggerInvoiceProcess(e, 'reprocess') }}>
                            Reprocess
                        </Button>
                    </div>
                    <div className='table-actionable'>
                        <Button variant="contained" color="secondary" size='small' onClick={(e) => { triggerInvoiceProcess(e, 'reverse') }}>
                            Reverse
                        </Button>
                    </div>
                    <div className='table-actionable'>
                        <Button variant="contained" size='small' sx={{
                            backgroundColor: "#888E97",
                            ":hover": {
                                backgroundColor: "#727579"
                            }
                        }} onClick={(e) => { triggerInvoiceProcess(e, 'credit_response') }}>
                            Credit and Reprocess
                        </Button>
                    </div>
                </Box>
                <DataTableInvoiceReprocess defaultGrid={state.defaultGrid} columns={columns}>

                </DataTableInvoiceReprocess>
                {
                    showAddEditModal && (
                        <InvoiceDetails
                            tabs={tabs}
                            onShow={showAddEditModal}
                            onHide={(e, reason) => hideInvoiceSummaryModal(e, reason)}


                        />
                    )
                }
                {/* {
                    addProductwarning && (
                        <WarningModal size="small_overlay" onShow={addProductwarning} />
                    )
                } */}
                {/* {
                    showAddEditModal && (
                        <DetailInvoiceSummary
                            onShow={showAddEditModal}
                            onHide={(e, reason) => hideInvoiceSummaryModal(e, reason)}

                        />
                    )
                } */}
                {
                    addProductwarning && (
                        <WarningModal size="small_overlay" onShow={addProductwarning} />
                    )
                }
                {snackBar.show && <SnackBarDialog
                    onShow={snackBar.show}
                    text={snackBar.text}
                    type={snackBar.type}
                    onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                />}
                {
                    showNewPriceoneModal && (
                        <CommonPopUp
                            onShow={showNewPriceoneModal} size="medium_overlay"
                            supplierMemberCode={{
                                title: (state.handleSupplierMemberModal && state.handleSupplierMemberModal === 'members') ? 'Member Details' : 'Supplier Details',
                                type: state.handleSupplierMemberModal
                            }}
                            // memberaccount={loadMemberAccountWithMemberId}
                            onHide={(e, reason) => hidePriceModal(e, reason, state.handleSupplierMemberModal)}
                        />
                    )
                }
                {
                    state.handleSPC && <CommonPopUp
                        onShow={state.handleSPC}
                        size="large_overlay"
                        supplierMemberCode={{
                            title: 'SPC',
                            supplier: invRepSupplierName || null
                        }}
                        onHide={(e, reason) => hidePriceModal(e, reason, state.handleSupplierMemberModal)}
                    />
                }
                {
                    showNewProductModal && (
                        <CommonPopUp
                            onShow={showNewProductModal} size="medium_overlay"
                            onHide={(e, reason) => hidePriceModal(e, reason, 'product')}
                        />
                    )
                }

                {
                    state.brandListModal && <CommonPopUp
                        onShow={state.brandListModal}
                        size="large_overlay"
                        brandDetails={{
                            title: 'Brand Details'
                        }}
                        onHide={(e, reason) => hidePriceModal(e, reason, 'brand')}
                    />
                }
                {state.status_history_modal && (
                    <>
                        <InvoiceReprocesHistory
                            size="large_overlay"
                            onShow={state.status_history_modal}
                            onHide={() => setState({ ...state, status_history_modal: false, status_history_id: null })}
                            processId={state.status_history_id}
                        />
                    </>
                )}
            </Container>

        </>
    );
}

export default InvoiceReprocess;
