import { Container, Box, Modal, Button, Grid, Checkbox, Popper, Grow, Paper, ClickAwayListener, MenuList, ButtonGroup } from '@mui/material'
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useEffect, useState } from "react";
import { } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import WarningModal from "../../components/shared/modals/WarningModal";
import { DataTableStatementCycle } from "../../components/shared/data-table/DataTableStatementCycle";
import StartStatementCycleModal from '../statement-cycles/StartStatementCycleModal';
import axiosStatementCycle from '../../services/utility/axios-statement-cycle';
import RefreshIcon from '@mui/icons-material/Refresh';
import SelectStatementCycleDatesModal from './SelectStatementCycleDatesModal';
import SnackBarDialog from '../../components/shared/SnackBarDialog';
import PageLoader from '../../components/shared/PageLoader';
import StartMemberCollectionModal from './StartMemberCollectionModal';
import { setHandleReportModal, setStageData } from '../../store/slices/manualInvoice';
import PaymentDateModal from './PaymentDateModal';
import { Link, useNavigate } from 'react-router-dom';
import ReportModal from './ReportModal';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

const IconMenuLink = ({ params, onNextStage, onReports }) => {
    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event, action, rows) => {
        event.preventDefault();

        if (action === 'memeberstatement') {
            onNextStage('memeberstatement', rows)
        } else if (action === 'billdiscounts') {
            onNextStage('billdiscounts-2', rows)
        } else if (action === 'memebercollection') {
            onNextStage('memebercollection', rows)
        } else if (action === 'remittance') {
            onNextStage('remittance', rows)
        } else if (action === 'rollback') {
            onNextStage('rollback', rows)
        } else if (action === 'memberstatement_reconciliation') {
            onNextStage('memberstatement_reconciliation', rows)
        } else if (action === 'membercollection_reconciliation') {
            onNextStage('membercollection_reconciliation', rows)
        } else if (action === 'remittance_reconciliation') {
            onNextStage('remittance_reconciliation', rows)
        } else if (action === 'reports') {
            if (onReports) {
                onReports(rows)
            }
        }
        setAnchorEl(null);

    };


    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >

            {row.statementCycleStage === 'RolledBack' ? <MenuItem onClick={(e) => handleClose(e, 'billdiscounts', row)}>Bill Discounts</MenuItem> : null}
            {row.statementCycleStage === 'DiscountsBilled' ? <MenuItem onClick={(e) => handleClose(e, 'memeberstatement', row)}>Member Statement</MenuItem> : null}
            {row.statementCycleStage === 'DiscountsBilled' ? <MenuItem onClick={(e) => handleClose(e, 'rollback', row)}>Rollback Bill Discounts</MenuItem> : null}
            {row.statementCycleStage === 'MemberStatement' ? <MenuItem onClick={(e) => handleClose(e, 'memebercollection', row)}>Member collection</MenuItem> : null}
            {/* {row.statementCycleStage === 'MemberStatement' ? <MenuItem onClick={(e) => handleClose(e, 'reconciliation', row)}>Member Statement Reconciliation</MenuItem> : null} */}
            {row.statementCycleStage === 'MemberStatement' ? <MenuItem onClick={(e) => handleClose(e, 'memberstatement_reconciliation', row)}>Member Statement Reconciliation</MenuItem> : null}
            {row.statementCycleStage === 'MemberStatement' ? <MenuItem onClick={(e) => handleClose(e, 'rollback', row)}>Rollback Member Statement</MenuItem> : null}
            {row.statementCycleStage === 'MemberCollection' ? <MenuItem onClick={(e) => handleClose(e, 'remittance', row)}>Pay Supplier</MenuItem> : null}
            {/* {row.statementCycleStage === 'MemberCollection' ? <MenuItem onClick={(e) => handleClose(e, 'reconciliation', row)}>Member Collection Reconciliation</MenuItem> : null} */}
            {row.statementCycleStage === 'MemberCollection' ? <MenuItem onClick={(e) => handleClose(e, 'membercollection_reconciliation', row)}>Member Collection Reconciliation</MenuItem> : null}
            {row.statementCycleStage === 'MemberCollection' ? <MenuItem onClick={(e) => handleClose(e, 'rollback', row)}>Rollback Member Collection</MenuItem> : null}
            {/* {row.statementCycleStage === 'Remittance' ? <MenuItem onClick={(e) => handleClose(e, 'reconciliation', row)}>Pay Supplier Reconciliation</MenuItem> : null} */}
            {row.statementCycleStage === 'Remittance' ? <MenuItem onClick={(e) => handleClose(e, 'remittance_reconciliation', row)}>Pay Supplier Reconciliation</MenuItem> : null}
            {row.statementCycleStage === 'Remittance' ? <MenuItem onClick={(e) => handleClose(e, 'rollback', row)}>Rollback Pay Supplier</MenuItem> : null}
            {/* <MenuItem onClick={(e) => handleClose(e, 'reports', row)}>Reports</MenuItem> */}
        </Menu>
    </>)
}
function StatementCycles() {
    const navigate = useNavigate();
    const columns = [
        {
            headerName: "ID",
            field: "id",
            minWidth: 100,
        },
        {
            headerName: "CBS",
            field: "centralBillingScheme",
            width: 200,
            flex: 1
        },
        {
            headerName: "DC",
            field: "distributionChannelName",
            width: 200,
            flex: 1
        },
        {
            headerName: "Stage",
            field: "statementCycleStage",
            width: 200,
            flex: 1,
            valueFormatter: ({ value }) => {
                if (value === 'RolledBack') {
                    return 'Rolled Back from Stage 1'
                } else if (value === 'Remittance') {
                    return 'Supplier Paid'
                }
            }
        },
        {
            headerName: "Cut Off Date",
            field: "cutOffDate",
            width: 200,
            flex: 1
        },
        {
            headerName: "Query Date",
            field: "queryDate",
            width: 200,
            flex: 1
        },
        {
            headerName: "Collection Date",
            field: "collectionDate",
            width: 200,
            flex: 1
        },
        {
            headerName: "Payment Date",
            field: "paymentDate",
            width: 200,
            flex: 1
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} onNextStage={(e, d) => { handleNextStage(e, d) }} onReports={(e) => { onReports(e) }} />;
            },
        }
    ];
    const [state, setState] = useState({
        loader: false,
        handleStartModal: false,
        memberStatementModal: false,
        memberCollectionModal: false,
        paySupplierModal: false,
        defaultGrid: {
            apiUrl: '/statementcycle/getall',
            searchUrl: '/statementcycle/getall'
        },
        reportModal: false,
        reportRow: null,
        hasBillDiscount: null,
        statementCycleDTO: null
    });

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    //Getting User Name from MSAL

    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const uname = accounts[0] && accounts[0].name;


    const { addProductwarning, rowData } = useSelector((state) => {
        return {
            addProductwarning: state.modalActions.closeWaringAddProduct,
            rowData: state.invoiceManualEntry
        }
    });

    const dispatch = useDispatch();

    const openStartModal = () => {
        setState({ ...state, handleStartModal: true, hasBillDiscount: null, statementCycleDTO: null })
    }
    const hideStartModal = (event) => {
        setState({ ...state, handleStartModal: event })
    }
    const startDiscountBilling = (data) => {
        cycleNextStep(data, 'billdiscounts')
    }

    const hideMemberStatementModal = (event) => {
        setState({ ...state, memberStatementModal: event })
    }
    const hidePaymentDateModal = (event) => {
        setState({ ...state, paySupplierModal: event })
    }
    const startMemberStatement = (data) => {
        cycleNextStep(data, 'memeberstatement')
    }
    const paymentDateModal = (data) => {
        cycleNextStep(data, 'remittance')
    }
    const startMemberCollection = (data) => {
        cycleNextStep(data, 'memebercollection')
    }
    const handleNextStage = (nextStage, params) => {
        dispatch(setStageData(params))

        if (nextStage === 'memeberstatement') {
            setState({ ...state, memberStatementModal: true })
        } else if (nextStage === 'memebercollection') {
            setState({ ...state, memberCollectionModal: true })
        } else if (nextStage === 'remittance') {
            setState({ ...state, paySupplierModal: true })
        } else if (nextStage === 'rollback') {
            cycleNextStep(params, 'rollback')
        } else if (nextStage === 'memberstatement_reconciliation') {
            cycleNextStep(params, 'memberstatement_reconciliation')
        } else if (nextStage === 'membercollection_reconciliation') {
            cycleNextStep(params, 'membercollection_reconciliation')
        } else if (nextStage === 'remittance_reconciliation') {
            cycleNextStep(params, 'remittance_reconciliation')
        } else if (nextStage === 'billdiscounts') {
            cycleNextStep(params, 'billdiscounts')
        } else if (nextStage === 'billdiscounts-2') {
            cycleNextStep(params, 'billdiscounts-2')
        }
    }



    const cycleNextStep = async (params, step) => {

        if (step === 'billdiscounts-2') {
            setState({
                ...state, handleStartModal: true, statementCycleDTO: { ...params },
                // eslint-disable-next-line max-len
                hasBillDiscount: { additionalTerms: params ? (params.additionalTerms ? params.additionalTerms : params.additionalInfo) : rowData.stageData.additionalInfo, cutOffDate: params ? (params.cutOffDate || params.cutOffDate) : rowData.stageData.cutOffDate }
            })
        } else {
            try {
                setState({ ...state, loader: true })
                let payload = null;
                if (step === 'billdiscounts') {
                    if (params.modificationStartDate) {
                        payload = {
                            "executable": "billdiscounts",
                            "user": uname,
                            "statementCycleDTO": {
                                "id": state.statementCycleDTO.id || null,
                                "additionalInfo": params ? (params.additionalTerms ? params.additionalTerms : params.additionalInfo) : rowData.stageData.additionalInfo,
                                "distributionChannelid": state.statementCycleDTO.distributionChannelid,
                                "cutOffDate": params ? (params.cutOffDate || params.cutOffDate) : rowData.stageData.cutOffDate
                            }
                        }
                    } else {
                        payload = {
                            "executable": "billdiscounts",
                            "user": uname,
                            "statementCycleDTO": {
                                "id": params ? params.id : null,
                                "additionalInfo": params ? (params.additionalTerms ? params.additionalTerms : params.additionalInfo) : rowData.stageData.additionalInfo,
                                "distributionChannelid": params ? (params.distribution_channel ? params.distribution_channel.id : params.distributionChannelid) : rowData.stageData.distributionChannelid,
                                "cutOffDate": params ? (params.cutOffDate || params.cutOffDate) : rowData.stageData.cutOffDate
                            }
                        }
                    }
                }
                else if (step === 'memeberstatement') {
                    payload = {
                        "executable": "memeberstatement",
                        "user": uname,
                        "statementCycleDTO": {
                            "collectionDate": params.collectionDate || rowData.stageData.collectionDate,
                            "queryDate": params.queryDate || rowData.stageData.queryDate,
                            "id": rowData.stageData.id,
                            "distributionChannelid": params ? (params.distribution_channel ? params.distribution_channel.id : params.distributionChannelid) : rowData.stageData.distributionChannelid
                        }
                    }
                } else if (step === 'memebercollection') {
                    payload = {
                        "executable": "memebercollection",
                        "user": uname,
                        "statementCycleDTO": {
                            "collectionDate": rowData.stageData.collectionDate,
                            "queryDate": rowData.stageData.queryDate,
                            "id": rowData.stageData.id,
                            "distributionChannelid": params ? (params.distribution_channel ? params.distribution_channel.id : params.distributionChannelid) : rowData.stageData.distributionChannelid
                        }
                    }
                } else if (step === 'remittance') {
                    payload = {
                        "executable": "remittance",
                        "user": uname,
                        "statementCycleDTO": {
                            "paymentDate": params.paymentDate,
                            "id": rowData.stageData.id,
                            "distributionChannelid": params ? (params.distribution_channel ? params.distribution_channel.id : params.distributionChannelid) : rowData.stageData.distributionChannelid
                        }
                    }
                } else if (step === 'rollback') {
                    payload = {
                        "executable": "rollback",
                        "user": uname,
                        "statementCycleDTO": {
                            "id": params.id,
                            "distributionChannelid": params ? (params.distribution_channel ? params.distribution_channel.id : params.distributionChannelid) : rowData.stageData.distributionChannelid
                        }
                    }
                } else if (step === 'remittance_reconciliation') {
                    payload = {
                        // "executable": "reconciliation",
                        "executable": "remittance_reconciliation",
                        "user": uname,
                        "statementCycleDTO": {
                            "id": params.id,
                            "distributionChannelid": params.distributionChannelid
                        }
                    }
                } else if (step === 'memberstatement_reconciliation') {
                    payload = {
                        // "executable": "reconciliation",
                        "executable": "memberstatement_reconciliation",
                        "user": uname,
                        "statementCycleDTO": {
                            "id": params.id,
                            "distributionChannelid": params.distributionChannelid
                        }
                    }
                } else if (step === 'membercollection_reconciliation') {
                    payload = {
                        // "executable": "reconciliation",
                        "executable": "membercollection_reconciliation",
                        "user": uname,
                        "statementCycleDTO": {
                            "id": params.id,
                            "distributionChannelid": params.distributionChannelid
                        }
                    }
                }
                const { data } = await axiosStatementCycle.post(`/sqs/executable`, payload);
                if (data) {
                    setState({ ...state, loader: false, handleStartModal: false, memberStatementModal: false, memberCollectionModal: false, paySupplierModal: false })
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "success", })
                }
            } catch (error) {
                const { response: { data, status } } = error;
                setState({ ...state, loader: false, handleStartModal: false, memberStatementModal: false, memberCollectionModal: false, paySupplierModal: false })
                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                }
            }
        }


    }
    const goToReports = () => {
        //navigate('/output/reports')
        window.open('/output/reports', "_blank")
    }
    const onReports = (params) => {
        setState({ ...state, reportRow: params })
        dispatch(setHandleReportModal(true))
    }
    return (
        <>
            {state.loader && <PageLoader />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Statement Cycles" />
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -3 } }}>
                    <div className='table-actionable'>
                        <Button variant="contained" size='small' onClick={openStartModal} startIcon={<AddIcon />}>
                            Start
                        </Button>
                    </div>
                    <div className='table-actionable'>
                        <Button component={Link} to={'/output/reports'} target={'_blank'} variant="contained" color="secondary" size='small' startIcon={<SendIcon />} sx={{
                            ":hover": {
                                color: "#fff"
                            }
                        }}>
                            Go to reports
                        </Button>
                    </div>
                    <div className='table-actionable'>
                        <Button variant="contained" color="tertiary" size='small' startIcon={<RefreshIcon />} onClick={() => { window.location.reload() }}>
                            Refresh
                        </Button>
                    </div>
                </Box>
                <DataTableStatementCycle defaultGrid={state.defaultGrid} columns={columns}>

                </DataTableStatementCycle>
                {
                    state.handleStartModal && (
                        <StartStatementCycleModal
                            onShow={state.handleStartModal}
                            onHide={(e) => hideStartModal(e)}
                            startDiscountBilling={startDiscountBilling}
                            hasBillDiscount={state.hasBillDiscount}
                            size="medium_overlay"
                        />
                    )
                }
                {
                    state.memberStatementModal && (
                        <SelectStatementCycleDatesModal
                            onShow={state.memberStatementModal}
                            onHide={(e) => hideMemberStatementModal(e)}
                            startMemberStatement={startMemberStatement}
                            size="medium_overlay"
                        />
                    )
                }
                {
                    state.memberCollectionModal && (<StartMemberCollectionModal
                        onShow={state.memberCollectionModal}
                        onHide={(e) => setState({ ...state, memberCollectionModal: e })}
                        startMemberCollection={startMemberCollection}
                        size="small_overlay"
                    />)
                }
                {
                    state.paySupplierModal && (
                        <PaymentDateModal
                            onShow={state.paySupplierModal}
                            onHide={(e) => hidePaymentDateModal(e)}
                            onPaymentDateModal={paymentDateModal}
                            size="medium_overlay"
                        />
                    )
                }
                {
                    addProductwarning && (
                        <WarningModal size="small_overlay" onShow={addProductwarning} />
                    )
                }
                {snackBar.show && <SnackBarDialog
                    onShow={snackBar.show}
                    text={snackBar.text}
                    type={snackBar.type}
                    onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                />}
                {
                    rowData.reportModal && <ReportModal
                        onShows={rowData.reportModal}
                        getParams={state.reportRow}
                        size="large_overlay"
                    />
                }
            </Container>

        </>
    );
}

export default StatementCycles;
