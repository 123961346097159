import { Checkbox, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl } from '@mui/material'
import { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch, useSelector } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import axiosProductDistribution from '../../../../../services/utility/axios-product-distribution';
import { setSupplierProductCode, setSupplierProductCodeInput, setSupplierProductCodeList, setEditSupplierProductCode, getIsError } from "../../../../../store/slices/generalPdcProduct";
import SnackBarDialog from '../../../../../components/shared/SnackBarDialog';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

function AddEditSupplierProductCode({ onShow, size = '', onHide }) {
    const [loader, setLoader] = useState(false)
    const [open, setOpen] = useState(onShow);
    const [snackBar, setSnackBar] = useState({ 
        text: "",
        type: "",
        show: false,
    })
    const { pdc_general_product_fields, getSupplierProductCode, supplierInput, editTabData, editSupplierCode, hasEditData } = useSelector((state) => {
        return {
            pdc_general_product_fields: state.generalPdcProducts,
            getSupplierProductCode: state.generalPdcProducts.pdc_supplierCode,
            supplierInput: state.generalPdcProducts.supplierprocodename,
            editTabData: state.generalPdcProducts.editTabData,
            editSupplierCode: state.generalPdcProducts.editSupplierCode,
            hasEditData: state.generalPdcProducts.hasEditData
        }
    });

    const defaultValues = {
        formValid: true,
        defaultmode: false,
        supplierprocodename: supplierInput ? supplierInput : ''
    };

    const [state, setState] = useState(defaultValues);

    const dispatch = useDispatch();

    const handleClose = () => {
        if (onHide) {
            onHide(false)
            dispatch(setSupplierProductCodeInput(''))
            dispatch(setEditSupplierProductCode(null))
            dispatch(getIsError({ key: 'supplierprocodename', value: false }))
            //setState({ ...state, defaultmode: false })
        }
    };

    const handleInputChange = (event) => {
        const { value } = event.target;
        //dispatch(setSupplierProductCodeInput(value))
        setState({ ...state, supplierprocodename:value, defaultmode: true })
        if(value) {
            dispatch(getIsError({ key: 'supplierprocodename', value: false }))
        } else {
            dispatch(getIsError({ key: 'supplierprocodename', value: true }))
        }
    }

    useEffect(() => {
        const { 
            supplierprocodename
        } = pdc_general_product_fields.isError
        if (!supplierprocodename) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true })
        }
    }, [pdc_general_product_fields.isError])

    const handleSubmit = async (event) => {
        event.preventDefault();
        const supplierCode = {
            "code": state.supplierprocodename,
            "supplierId": pdc_general_product_fields.hasEditData ? pdc_general_product_fields.hasEditData.supplier.id : null,
            "distributionChannel": pdc_general_product_fields.hasEditData ? pdc_general_product_fields.hasEditData.distributionChannel.id : null
        }
        if (state.supplierprocodename !== '') {
            try {
                if (!editSupplierCode) {
                    setLoader(true)
                    const id = editTabData ? editTabData.productId : hasEditData.id;
                    const { data } = await axiosProductDistribution.post(`pdcs/${id}/supplierProductCodes`, supplierCode);
                    if (data.success) {
                        const { data } = await axiosProductDistribution.get(`pdcs/${id}/supplierProductCodes`);
                        if (data !== null || data !== undefined) {
                            dispatch(setSupplierProductCodeList(data.items))
                            setSnackBar({ ...snackBar, show: true, text: 'SPC details added to PDC', type: "success", })
                            setTimeout(() => {
                                handleClose()
                            }, 1500);
                        }
                    }
                } else if (editSupplierCode) {
                        setLoader(true)
                        const { data } = await axiosProductDistribution.put(`pdcs/${editSupplierCode.productDistributionChannelDTO.id}/supplierProductCodes/${editSupplierCode.id}`, supplierCode);
                        if (data.success) {
                            const { data } = await axiosProductDistribution.get(`pdcs/${editSupplierCode.productDistributionChannelDTO.id}/supplierProductCodes`);
                            if (data !== null || data !== undefined) {
                                dispatch(setSupplierProductCodeList(data.items))
                                setSnackBar({ ...snackBar, show: true, text: 'SPC details updated for PDC', type: "success"})
                                setTimeout(() => {
                                    handleClose()
                                }, 1500);
                            }
                        }
                }
                
            } catch (error) {
                setLoader(false)
                const { response: { data, status } } = error;
                setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: data.message ? "warning" : "error" })
            }
        }
    }

    return (
        <>
            <Modal
                open={open}
                aria-labelledby="add-edit-price-title"
                aria-describedby="add-edit-price-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Box component="div" className="overlay" id="addEditModal">
                        <div className={`overlay-box ${size ? size : ''}`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-header text-center"> Supplier Product Codes
                                        <CancelIcon className="closeBtn" onClick={handleClose} />
                                    </div>
                                    <div className="overlay-content">
                                        <Grid item xs={12} md={12}>

                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="supplierprocodename" className="fontSemiBold mandatory">Code</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <FormControl>
                                                                <TextField
                                                                    size="small"
                                                                    id="supplierprocodename"
                                                                    aria-describedby="supplierprocodename"
                                                                    onChange={(e) => handleInputChange(e)}
                                                                    value={state.supplierprocodename}
                                                                    name="supplierprocodename"
                                                                    error={pdc_general_product_fields.isError.supplierprocodename}
                                                                />
                                                                {pdc_general_product_fields.isError.supplierprocodename && (<FormHelperText className='errorHelperTxt' id="supplierprocodename_error">This field is mandatory</FormHelperText>)}
                                                            </FormControl>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>

                                        </Grid>
                                        <Grid container sx={{ marginTop: '20px' }}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Item>
                                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                                        <LoadingButton
                                                            loading={loader}
                                                            loadingPosition="center"
                                                            endIcon=""
                                                            variant="contained"
                                                            type="submit" sx={{ marginRight: '15px' }}
                                                            //disabled={state.formValid || state.defaultmode === false}
                                                            disabled={(pdc_general_product_fields.hasEditData && editSupplierCode) ? pdc_general_product_fields.isError.supplierprocodename : (state.formValid || state.defaultmode === false)}
                                                        >Save</LoadingButton>
                                                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                    {snackBar.show && <SnackBarDialog
                        onShow={snackBar.show}
                        text={snackBar.text}
                        type={snackBar.type}
                        onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                    />}
                </form>

            </Modal>

        </>
    );
}

export default AddEditSupplierProductCode;