import { Container, Box, Modal, Checkbox, Button, Grid, Popper, Grow, Paper, ClickAwayListener, MenuList, ButtonGroup, FormGroup, FormControlLabel } from '@mui/material'
import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { TableFilters } from "../../components/shared/data-table/TableFilters";
import { useEffect, useState } from "react";
import { InputText } from "../../components/shared/form/InputText";
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { } from '@mui/material';
import { ThreeStateCheckbox } from "../../components/shared/form/ThreeStateCheckbox";
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange, filterResetFlagChange } from "../../store/slices/externalFilter";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import AddEditCategory from "./addEditCategory/AddEditCategory";
import AddIcon from '@mui/icons-material/Add';
import { warningModal, handleAddEditModal, deleteCategoryModal } from "../../store/slices/modals";
import WarningModal from "../../components/shared/modals/WarningModal";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { setRowPage, resetDataGrid, setPageNo } from '../../store/slices/dataGrid';
import PageLoader from "../../components/shared/PageLoader";
import SnackBarDialog from "../../components/shared/SnackBarDialog";
import { sethasEditCategoryData, hasCategoryEdit, categoryInput, resetCategory, setAutocomplete, deleteCategoryStatus } from "../../store/slices/categoriesData";
import axios_categories from "../../services/utility/axios-categories";
import { DataTableCategories } from "../../components/shared/data-table/DataTableCategories";

var selected_category = 0;

const IconMenuLink = ({ params, level }) => {
    const { row } = params;
    const { editLevel } = level;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();
    

    const { showAddEditModal, general_product_fields, category_fields, delCategory } = useSelector((state) => {
        return {
            showAddEditModal: state.modalActions.hideAddEditModal,
            editTabData: state.generalProduct.editTabData,
            general_product_fields: state.generalProduct,
            category_fields: state.categoriesData,
            delCategory: state.modalActions.deleteCategory,
        }
    });

    const handleClick = (event, reason) => {
        event.preventDefault();


        let catid = 0;

        switch (level) {
            case "ggp":
                catid = row.ggpId;
                break
            case "gp":
                catid = row.gpId;
                break
            case "p":
                catid = row.pid;
                break
            case "c":
                catid = row.cid;
                break
            default:
                catid = row.id
        }

        if (catid !== null && catid !== 0) {
            if (reason === "edit_category") {
                dispatch(resetCategory());
                dispatch(handleAddEditModal({
                    showAddEdit: true
                }));
                dispatch(hasCategoryEdit({ editCategory: true, editCategoryLevel: level, selected_category_id: catid }))
                setEditCategoryData(row, level);
            }

            if(reason === "delete_category"){
                // console.log("Delete Call: ",catid);
                selected_category = catid;
                dispatch(deleteCategoryStatus({
                    key: 'startDeleteCategory',
                    value: true
                }));
                dispatch(deleteCategoryModal({
                    deleteCategory: !delCategory
                }));
            }

        }
    };


    const setEditCategoryData = async (row, level) => {

        dispatch(sethasEditCategoryData(row));

        switch (level) {
            case "ggp":
                dispatch(categoryInput({ key: 'category_name', value: row.ggpName }));
                dispatch(categoryInput({ key: 'category_active', value: row.ggpActive }));
                dispatch(categoryInput({ key: 'category_level', value: row.ggpLevel }));
                dispatch(categoryInput({ key: 'editCategoryLevel', value: row.ggpLevel }));
                dispatch(categoryInput({ key: 'editCategoryLevelName', value: 'Product Family' }));
                break
            case "gp":
                dispatch(categoryInput({ key: 'category_name', value: row.gpName }));
                dispatch(categoryInput({ key: 'category_active', value: row.gpActive }));
                dispatch(categoryInput({ key: 'category_level', value: row.gpLevel }));
                dispatch(categoryInput({ key: 'editCategoryLevel', value: row.gpLevel }));
                dispatch(categoryInput({ key: 'editCategoryLevelName', value: 'Product Class' }));
                dispatch(categoryInput({ key: 'category_parent', value: row.ggpId }));
                dispatch(categoryInput({ key: 'category_parentName', value: row.ggpName }));
                break
            case "p":
                dispatch(categoryInput({ key: 'category_name', value: row.pname }));
                dispatch(categoryInput({ key: 'category_active', value: row.pactive }));
                dispatch(categoryInput({ key: 'category_level', value: row.plevel }));
                dispatch(categoryInput({ key: 'editCategoryLevel', value: row.plevel }));
                dispatch(categoryInput({ key: 'editCategoryLevelName', value: 'Product Line' }));
                dispatch(categoryInput({ key: 'category_parent', value: row.gpId }));
                dispatch(categoryInput({ key: 'category_parentName', value: row.gpName }));
                break
            case "c":
                dispatch(categoryInput({ key: 'category_name', value: row.cname }));
                dispatch(categoryInput({ key: 'category_active', value: row.cactive }));
                dispatch(categoryInput({ key: 'category_level', value: row.clevel }));
                dispatch(categoryInput({ key: 'editCategoryLevel', value: row.clevel }));
                dispatch(categoryInput({ key: 'editCategoryLevelName', value: 'Product Type' }));
                dispatch(categoryInput({ key: 'category_parent', value: row.pid }));
                dispatch(categoryInput({ key: 'category_parentName', value: row.pname }));
                dispatch(categoryInput({ key: 'category_type', value: row.ctype }));
                dispatch(categoryInput({ key: 'setAutocomplete', value: row.ctype }));
                dispatch(setAutocomplete({ parent: 'category_foodtype', key: 'foodtype_selected', value: { 'id': row.ctype, 'name': row.ctypeLabel } }))
                break
            default:

        }

    }
    return (
        <>
            <Grid container spacing={0} sx={{ justifyContent: { xs: 'center', sm: 'center' }, textAlign: { xs: "center", sm: 'center' }, marginBottom: { xs: 0, sm: 0 } }}>
                <Grid item={true} xs={6} md={6} >
                    <IconButton
                        aria-label="more"
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={(e) => handleClick(e, 'edit_category')}                        
                    >
                        <EditIcon sx={{ fontSize: '1.1rem' }} />
                    </IconButton>
                </Grid>
                <Grid item={true} xs={6} md={6} >
                    <IconButton
                        aria-label="more"
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={(e) => handleClick(e, 'delete_category')}                        
                    >
                        <DeleteIcon sx={{ fontSize: '1.1rem' }} />
                    </IconButton>
                </Grid>
            </Grid>

            {/* <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"

        >
            <MenuItem onClick={(e) => handleClose(e, 'edit_category')}>Edit Category</MenuItem>

        </Menu> */}
        </>)
}
function Categories() {

    const [state, setState] = useState({
        active_category: null,
        openNewCategoryModal: false,
        pageLoader: false,
        defaultGrid: {
            pagetitle: "Categories",
            productActionButton: true,
            hasExport: false,
            addEditButton: true,
            apiUrl: 'categories', //categories products
            searchUrl: 'categories', //categories
        }
    });
    const [open, setOpen] = React.useState(true);

    const { cName, activeCategory, addCategorywarning, showAddEditModal, addProductwarning, delCategory, category_fields } = useSelector((state) => {
        return {
            cName: state.externalFilter.cname,
            activeCategory: state.externalFilter.active_category,
            resetTable: state.dataGridTable,
            filterFlag: state.externalFilter.filterApplied,
            addCategorywarning: state.modalActions.closeWaringAddCategory,
            showAddEditModal: state.modalActions.hideAddEditModal,
            addProductwarning: state.modalActions.closeWaringAddProduct,
            delCategory: state.modalActions.deleteCategory,
            category_fields: state.categoriesData
        }
    });

    const columns = [
        {
            headerName: "ID",
            field: "id",
            flex: 1,
            hide: true
        },
        {
            headerName: "Product Family (Great GP)",
            field: "ggpName",
            minWidth: 200,
            flex: 1.5,
            renderCell: (params) => (
                <Grid container spacing={0} sx={{ marginBottom: { xs: 0, sm: 0 } }} >
                    <Grid item={true} xs={10} sx={{ paddingTop: '8px' }}>
                        <Checkbox
                            checked={params.row.ggpActive === true}
                            icon={<CircleIcon />}
                            checkedIcon={<CheckCircleIcon />}
                            color="chk_rounded"
                            size='small'
                            style={{ pointerEvents: 'none', padding: '2px 10px 2px 0px', float: 'left' }}
                            className="categories-dashboard-table-chekbox-icon-block"
                        />
                        <Typography title={params.value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.value}</Typography>
                    </Grid>
                    <Grid item={true} xs={2} sx={{ justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 0, sm: 0 } }}>

                        <IconMenuLink params={params} level="ggp" />
                    </Grid>
                </Grid>
            )
        },
        {
            headerName: "Product Class (GP)",
            field: "gpName",
            minWidth: 200,
            flex: 1.5,
            renderCell: (params) => (

                <Grid container spacing={0}>
                    <Grid item={true} xs={10} sx={{ paddingTop: '8px' }} >
                        <Checkbox
                            checked={params.row.gpActive === true}
                            icon={<CircleIcon />}
                            checkedIcon={<CheckCircleIcon />}
                            color="chk_rounded"
                            size='small'
                            style={{ pointerEvents: 'none', padding: '2px 10px 2px 0px', float: 'left' }}
                            className="categories-dashboard-table-chekbox-icon-block"
                        />
                        <Typography title={params.value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.value}</Typography>
                    </Grid>
                    <Grid item={true} xs={2} sx={{ justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 0, sm: 0 } }}>

                        <IconMenuLink params={params} level="gp" />
                    </Grid>
                </Grid>
            )
        },
        {
            headerName: "Product Line (Parent)",
            field: "pname",
            flex: 1.5,
            minWidth: 200,
            renderCell: (params) => (
                <Grid container spacing={0}>
                    <Grid item={true} xs={10} sx={{ paddingTop: '8px' }} >
                        <Checkbox
                            checked={params.row.pactive === true}
                            icon={<CircleIcon />}
                            checkedIcon={<CheckCircleIcon />}
                            color="chk_rounded"
                            size='small'
                            style={{ pointerEvents: 'none', padding: '2px 10px 2px 0px', float: 'left' }}
                            className="categories-dashboard-table-chekbox-icon-block"
                        />
                        <Typography title={params.value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.value}</Typography>
                    </Grid>
                    <Grid item={true} xs={2} sx={{ justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 0, sm: 0 } }}>

                        <IconMenuLink params={params} level="p" />
                    </Grid>
                </Grid>
            )

        },
        {
            headerName: "Product Type (Category)",
            field: "cname",
            flex: 1.5,
            minWidth: 200,
            renderCell: (params) => (
                <Grid container spacing={0} >
                    <Grid item={true} xs={10} sx={{ paddingTop: '8px' }} >
                        <Checkbox
                            checked={params.row.cactive === true}
                            icon={<CircleIcon />}
                            checkedIcon={<CheckCircleIcon />}
                            color="chk_rounded"
                            size='small'
                            style={{ pointerEvents: 'none', padding: '2px 10px 2px 0px', float: 'left' }}
                            className="categories-dashboard-table-chekbox-icon-block"
                        />
                        <Typography title={params.value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} >{params.value}</Typography>
                    </Grid>
                    <Grid item={true} xs={2} sx={{ justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 0, sm: 0 } }}>

                        <IconMenuLink params={params} level="c" />
                    </Grid>
                </Grid>
            )
        },
        {
            headerName: "Food Type",
            field: "ctypeLabel",
            minWidth: 40
        }
    ];




    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const [cType, setCtype] = useState([])

    const pageLoader = (loader) => {
        setState({ ...state, pageLoader: loader })
    }



    const dispatch = useDispatch();

    // const handleChange = (checked, name) => {
    //     setState({ ...state, [name]: checked })
    //     dispatch(setFilter({
    //         key: name,
    //         value: checked
    //     }))
    // };

    const handleChange = (checked, name) => {
        // console.log(checked, name, activeCategory);
        // setState({ ...state, [name]: checked });        
        state.active_category = checked;
        dispatch(setFilter({
            key: name,
            value: checked
        }));       
        // console.log(state.active_product, activeCategory); 
        setTimeout(() => {
            filterBtn();
        }, 500);
        
    };


    const filterFoodTypes = ['nonfood', 'chilled', 'ambient', 'frozen'];

    const [checked, setChecked] = React.useState({
        active_non_food: false,
        active_chilled: false,
        active_ambient: false,
        active_frozen: false,

    });

    const foodTypehandleChange = (event) => {
        //setChecked(event.target.checked);    
        setChecked({
            ...checked,
            [event.target.name]: event.target.checked
        });
    };

    const handleFilterInput = (value, id) => {
        //setState({ ...state, [id]: value });
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    };

    const selectedAutoComplete = (value, id) => {
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    }

    const filterBtn = () => {
        dispatch(resetDataGrid());
        var cfilterFoodTypes = filterFoodTypes.filter((x) => document.getElementById(x).checked === true).map(filteredType => {
            switch (filteredType) {
                case 'nonfood':
                    return "Non Food";
                case 'chilled':
                    return "Chilled";
                case 'ambient':
                    return "Ambient";
                case 'frozen':
                    return "Frozen";
            }
        });

        dispatch(applyFilter({
            key: 'filterList',
            value: {
                "cname": cName,
                "ctype": cfilterFoodTypes,
                "cactive": state.active_category
                // "cactive": activeCategory
            }
        }))
        dispatch(filterFlagChange(true));
        dispatch(filterResetFlagChange(false));
        dispatch(setRowPage({ pageData: 10 }));
        dispatch(setPageNo({ page: 1 }));

    }
    const resetFilter = () => {
        if ((cName) !== '' || (activeCategory === false || !activeCategory)) {
            dispatch(resetExternalFilter());
            dispatch(resetDataGrid());
        }

        setState({ ...state, active_category: null });
        setChecked({
            active_non_food: false,
            active_chilled: false,
            active_ambient: false,
            active_frozen: false,
        });
        dispatch(filterResetFlagChange(true));
    }

    const addCategoryModal = () => {
        dispatch(resetCategory());
        dispatch(handleAddEditModal({
            showAddEdit: true
        }))
    }
    const hideNewCategoryModal = (event, reason) => {
        setState({ ...state, openNewCategoryModal: false })
    }

    useEffect(() => {
        // console.log("Confirm Delete UseEffect: ", selected_category, category_fields.confirmDeleteCategory);
        const fetchMyAPI = async () => {
            if (category_fields.confirmDeleteCategory && selected_category !== 0) {
                setState({ ...state, pageLoader: true });
                dispatch(deleteCategoryModal({
                    key: 'deleteCategory',
                    value: false
                }));
                try {
                    // console.log("Confirm Delete: ", selected_category);
                    const { data } = await axios_categories.delete(`/v2/categories/${selected_category}`);
                    if (data.success) {
                        setState({ ...state, pageLoader: false });
                        setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Category deleted successfully!", type: "success" });
                        dispatch(deleteCategoryStatus({
                            key: 'confirmDeleteCategory',
                            value: false
                        }));
                        setTimeout(() => {
                            window.location.reload()
                        }, 1500);                       
                        
                    }
                    else
                    {
                        setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" });
                        setState({ ...state, pageLoader: false });
                        // selected_category = 0;
                        dispatch(deleteCategoryStatus({
                            key: 'confirmDeleteCategory',
                            value: false
                        }));
                    }

                }
                catch (error) {
                    const { response: { data, status } } = error;
                    if (!data) {
                        setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                    } else {
                        setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                    }
                    setState({ ...state, pageLoader: false });
                    dispatch(deleteCategoryStatus({
                        key: 'confirmDeleteCategory',
                        value: false
                    }));
                }
            }
        }
        fetchMyAPI();

    }, [category_fields.confirmDeleteCategory])

    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Categories" />
                <Box sx={{ marginBottom: 2.5 }}>
                    <TableFilters
                        filterFields={<>
                            <Box component="div" >
                                <InputText onChange={handleFilterInput} maxWidth={100} label="Name" id="cname" size="small" />
                            </Box>
                        </>}
                        filterCheckboxes={<>
                            <Box component="div" sx={{ paddingTop: '20px' }}>
                                <ThreeStateCheckbox checked={state.active_category} onChange={handleChange} name="active_category" id="active" label="Active" />
                            </Box>

                            <Box component="div" className="multi-filter-checkbox-block" sx={{ padding: '40px 15px 20px 20px', borderRadius: '10px', backgroundColor: '#f7f7f7', border: '1px solid #ccc' }}>
                                <span className="multi-filter-checkbox-title" >Food Type</span>

                                <Box component="div">
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox name="active_non_food" id="nonfood" checked={checked.active_non_food} onChange={foodTypehandleChange} sx={{ '&.MuiCheckbox-root': { background: '#fff', margin: '0px 10px 0px 10px', borderRadius: '0', height: '18px', width: '18px' } }} />} label="Non Food" />
                                    </FormGroup>
                                </Box>
                                <Box component="div">
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox name="active_chilled" id="chilled" checked={checked.active_chilled} onChange={foodTypehandleChange} sx={{ '&.MuiCheckbox-root': { background: '#fff', margin: '0px 10px 0px 10px', borderRadius: '0', height: '18px', width: '18px' } }} />} label="Chilled" />
                                    </FormGroup>
                                </Box>
                                <Box component="div">
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox name="active_ambient" id="ambient" checked={checked.active_ambient} onChange={foodTypehandleChange} sx={{ '&.MuiCheckbox-root': { background: '#fff', margin: '0px 10px 0px 10px', borderRadius: '0', height: '18px', width: '18px' } }} />} label="Ambient" />
                                    </FormGroup>
                                </Box>
                                <Box component="div">
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox name="active_frozen" id="frozen" checked={checked.active_frozen} onChange={foodTypehandleChange} sx={{ '&.MuiCheckbox-root': { background: '#fff', margin: '0px 10px 0px 10px', borderRadius: '0', height: '18px', width: '18px' } }} />} label="Frozen" />
                                    </FormGroup>
                                </Box>
                            </Box>


                            <Box component="div" sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -2.4 } }}>


                            </Box>


                        </>}
                        filterBtns={
                            <>
                                <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                <Button variant="contained" onClick={filterBtn}>Apply</Button>
                            </>}>
                    </TableFilters>
                </Box>
                {/* <Container sx={{ padding: '0px !important' }} maxWidth="xxl" >

                    <Collapse in={open}>
                        <Alert icon={false} variant="filled" className="filters_helper_text_block"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                        >
                            <Box component="div" className="fliter-type-items-container"  >
                                <Box component="div" className="fliter-type-items-info">
                                    <svg className="chk_checked" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="17" height="17" rx="2.5" fill="#26BE8D" stroke="#07A572" />
                                        <path d="M12.6544 5.00025C12.4168 4.99432 12.1642 5.09298 11.9664 5.29219L7.47049 9.82249L6.03317 8.37404C5.68265 8.02066 5.15592 7.98269 4.85189 8.2885L4.20486 8.94037C3.90145 9.24631 3.93943 9.77783 4.28982 10.1311L6.92296 12.7839C7.21909 13.0831 7.74407 13.0608 8.0189 12.7839L13.7099 7.04859C14.0604 6.69535 14.0989 6.16446 13.7948 5.85865L13.1479 5.20615C13.0147 5.07267 12.8393 5.00479 12.6544 5.00025Z" fill="white" />
                                    </svg>
                                    <span>Match the criteria</span>
                                </Box>
                                <Box component="div" className="fliter-type-items-info">
                                    <svg className="chk_notchecked" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="17" height="17" rx="2.5" fill="#FEAE4F" stroke="#E78A1C" />
                                        <rect x="4" y="7" width="10" height="3" rx="1" fill="white" />
                                    </svg>
                                    <span>Ignore the criteria</span>
                                </Box>
                                <Box component="div" className="fliter-type-items-info">
                                    <svg className="chk_interchecked" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="17" height="17" rx="2.5" fill="white" stroke="#868C94" />
                                    </svg>
                                    <span>Do not match</span>
                                </Box>
                            </Box>
                            <Box component="div" className="filter-type-close-info">Don't show this again</Box>

                        </Alert>

                    </Collapse>

                </Container> */}
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -3 } }}>

                    <div className='table-actionable'>
                        <Button variant="contained" size='small' onClick={(e) => addCategoryModal(e)} startIcon={<AddIcon />}>
                            New category
                        </Button>
                    </div>
                </Box>
                <DataTableCategories defaultGrid={state.defaultGrid} columns={columns}>

                </DataTableCategories>
                {
                    showAddEditModal && (
                        <AddEditCategory
                            onShow={showAddEditModal}
                            onHide={(e, reason) => hideNewCategoryModal(e, reason)}

                        />
                    )
                }
                {
                    delCategory && (
                        <WarningModal size="small_overlay" onShow={delCategory} />
                    )
                }
                {/* {
                    addProductwarning === true ? <WarningModal size="small_overlay" onShow={addProductwarning} /> : delCategory === true ? <WarningModal size="small_overlay" onShow={delCategory} /> : ""
                } */}
                {
                    addProductwarning && (
                        <WarningModal size="small_overlay" onShow={addProductwarning} />
                    )
                }
                
                {snackBar.show && <SnackBarDialog
                    onShow={snackBar.show}
                    text={snackBar.text}
                    type={snackBar.type}
                    onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                />}
            </Container>

        </>
    );
}

export default Categories;
