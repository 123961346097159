import { Box, Modal, Paper, FormControl, Grid, TextField, FormHelperText, FormLabel, Button, FormControlLabel, Checkbox, Autocomplete, Divider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SnackBarDialog from '../../../components/shared/SnackBarDialog';
import PageLoader from '../../../components/shared/PageLoader';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import axios_supplier from "../../../services/utility/axios-supplier";
import { handleExistingInvoice, mieSearch, setDateValue, setSupplierCode } from '../../../store/slices/manualInvoice';
import CommonPopUp from '../../products/addEditPdc/pdc-tabs/CommonPopUp';
import { DataTableInvoiceSummaries } from '../../../components/shared/data-table/DataTableInvoiceSummaries';
import { applyFilter, filterFlagChange, resetExternalFilter, setMeinvoice } from '../../../store/slices/externalFilter';
import { resetDataGrid, setPageNo, setRowPage } from '../../../store/slices/dataGrid';

// const Item = styled(Paper)(({ theme }) => ({
//     paddingLeft: theme.spacing(1),
//     paddingRight: theme.spacing(1),
//     boxShadow: "none"
// }));

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(1.5),
    boxShadow: "none",
    background: "none"
}));

const IconMenuLink = ({ params, onSelect }) => {
    const { row } = params;

    const selectInvoice = (row) => {
        if (onSelect) {
            onSelect(row)
        }
    }

    return (<>
        <Typography align='center'>
            <Button variant='text' sx={{ minWidth: '40px', cursor: 'pointer' }} onClick={() => { selectInvoice(row) }} startIcon={<CheckCircleIcon />}>Select </Button>
        </Typography>

    </>)
}

export default function ExistingInvoiceModal({ onShow, onHide, onExitingSelection }) {
    const {
        handleWarning,
        manualInvoiceEntry
    } = useSelector((state) => {
        return {
            handleWarning: state.modalActions.closeWaringAddProduct,
            manualInvoiceEntry: state.invoiceManualEntry
        }
    });
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(resetExternalFilter())
        dispatch(resetDataGrid());
    }, [])
    const [state, setState] = useState({
        invoiceDate: null,
        deliveryDate: null,
        handleSupplierMemberModal: false,
        supplierInvoiceCode: '',
        supplierModalExistingInvoice: false,
        defaultGrid: {
            apiUrl: '',
            searchUrl: '/existing-invoice',
            exportExcelButton: false,
            checkBoxSelection: false
        },
        isError: {

        }
    });
    const [mie_supplier, setMie_supplier] = useState({
        supplier_code_options: [],
        supplier_code_selected: null,
    })
    const [mie_supplierValue, setMie_supplierValue] = useState("")
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })
    const [columns, setColumns] = useState([
        {
            headerName: "Supplier Invoice Code",
            field: "supplierInvoiceCode",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value
        },
        {
            headerName: "Invoice Date",
            field: "invoiceDate",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value
        },
        {
            headerName: "Gross Total",
            field: "grossTotal",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value
        },
        {
            headerName: "DC",
            field: "distributionChannel",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value.name ? value.name : ""
            // valueFormatter: ({ value }) => value.name ? value.name : ""
        },
        {
            headerName: "SC",
            field: "statementCycle",
            flex: 1,
            minWidth: 200,
            valueFormatter: ({ value }) => value !== 0 ? value : '',
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 100,
            renderCell: (params) => {
                return <>
                    <IconMenuLink params={params} onSelect={onSelectName} />
                </>;
            },
        }
        // {
        //     headerName: "Supplier",
        //     field: "productDescription",
        //     flex: 1,
        //     minWidth: 200,
        //     valueFormatter: ({ value }) => value.supplierId,
        // },
        // {
        //     headerName: "Member Account",
        //     field: "productDescription",
        //     flex: 1,
        //     minWidth: 200,
        // },

    ]);

    useEffect(() => {
        const fetchMyAPI = async () => {
            if (mie_supplier.supplier_code_options.length === 0) {
                axios_supplier.get('/supplier/look-up')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data.filter((value, index, self) => self.findIndex((m) => m.name === value.name) === index);
                            setMie_supplier({ ...mie_supplier, supplier_code_options: unique })
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
        }
        fetchMyAPI();
    }, [])

    const [rows, setRows] = useState([]);

    const handleClose = () => {
        dispatch(handleExistingInvoice(false))
        dispatch(setSupplierCode({
            key: 'mieSupplierCodeOnModal',
            value: ""
        }))
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    }

    const handleInvoiceDate = (event) => {
        const invDate = (event === null) ? null : moment(event, 'DD/MM/YYYY')
        setState({ ...state, invoiceDate: invDate })
    }

    const hideSupplierMemberModal = (params) => {
        if (mie_supplier.supplier_code_selected === null) {
            setSnackBar({ ...snackBar, show: true, text: "Please select supplier", type: "error" })
        } else {
            setState({ ...state, handleSupplierMemberModal: params === false ? params : true })
        }
    }
    const hideSupplierModal = (params) => {
        setState({ ...state, supplierModalExistingInvoice: params === false ? params : true })
    }
    const clearSMC = (e, params) => {
        if (params === 'supplier-member-account-code') {
            dispatch(setSupplierCode({
                key: 'mieSupplierCodeOnModal',
                value: ""
            }))
        } else if (params === 'supplier') {
            setMie_supplier({ ...mie_supplier, supplier_code_selected: null })
        }

    }
    const handleInputChange = (event) => {
        const { value, name } = event.target;
        setState({ ...state, [name]: value })
    }
    const filterBtn = () => {
        if (state.invoiceDate !== "" && state.invoiceDate !== null && moment(state.invoiceDate).format('DD/MM/YYYY') === 'Invalid date') {
            setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
        }
        else {
            dispatch(applyFilter({
                key: 'filterList',
                value: {
                    "invoiceDate": state.invoiceDate ? moment(state.invoiceDate).format('DD/MM/YYYY') : null,
                    "supplierInvoiceCode": state.supplierInvoiceCode ? state.supplierInvoiceCode : "",
                    "supplierId": mie_supplier.supplier_code_selected ? mie_supplier.supplier_code_selected.id : "",
                    "supplierMemberAccountCode": manualInvoiceEntry.mieSupplierCodeOnModal.smaCode ? manualInvoiceEntry.mieSupplierCodeOnModal.smaCode : ""
                }
            }))
            dispatch(setMeinvoice({
                key: 'me_sic',
                value: state.supplierInvoiceCode ? state.supplierInvoiceCode : ""
            }))

            dispatch(filterFlagChange(true));
            dispatch(setRowPage({ pageData: 10 }));
            dispatch(setPageNo({ page: 1 }));
        }


    }
    const resetFilter = () => {
        // if ((state.invoiceDate || state.supplierInvoiceCode || manualInvoiceEntry.mieSupplierCodeOnModal.smaCode) !== '') {

        // }
        setState({ ...state, invoiceDate: null, supplierInvoiceCode: '' })
        dispatch(setSupplierCode({
            key: 'mieSupplierCodeOnModal',
            value: ""
        }))
        setMie_supplier({ ...mie_supplier, supplier_code_selected: null })
        dispatch(resetExternalFilter())
        dispatch(resetDataGrid());
        dispatch(filterFlagChange(false));
    }

    const onSelectName = (row) => {
        if (onExitingSelection) {
            onExitingSelection({ ...row, supplier: { id: row.supplier.supplierId, name: row.supplier.name } });
            handleClose()
        }

    }

    const CommonPopUpModal = (event, type) => {
        if (type === 'supplier') {
            setState({ ...state, supplierModalExistingInvoice: true })
        }
    }
    const getSupplierDetail = (e, type) => {
        console.log(e, type)
        if (e !== '') {
            setState({ ...state, supplierModalExistingInvoice: false })
            setMie_supplier({ ...mie_supplier, supplier_code_selected: e })
        }
    }
    return (
        <>
            <Modal
                open={onShow || false}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <Box component="div" className="overlay" id="addEditModal">
                    {state.pageLoader && <PageLoader />}
                    <div className="overlay-box">
                        <div className="content">
                            <div className="overlayBody">
                                <div className="overlay-header text-center"> Existing Invoices
                                    <CancelIcon className="closeBtn" onClick={handleClose} />
                                </div>
                                <div className="overlay-content">
                                    <Box sx={{ marginBottom: 2.5 }}>
                                        <Box component="div" className="filter-panel-box">
                                            <Box component="div" className='filter-fields' sx={{ columnGap: 3, display: 'grid', gridTemplateColumns: { sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(4, 1fr)' } }}>

                                                <Box component="div">
                                                    <FormControl size={'small'} fullWidth>
                                                        <FormLabel htmlFor="config_name" sx={{ paddingBottom: '7px' }}>Supplier Invoice Code</FormLabel>
                                                        <TextField
                                                            inputProps={{ maxLength: 100 }}
                                                            size="small"
                                                            id="supplierInvoiceCode"
                                                            aria-describedby="supplierInvoiceCode_error"
                                                            value={state.supplierInvoiceCode}
                                                            name="supplierInvoiceCode"
                                                            onChange={handleInputChange}
                                                        />
                                                    </FormControl>
                                                </Box>
                                                <Box component="div">
                                                    {/* <FormLabel htmlFor="config_name" sx={{ paddingBottom: '7px' }}>Supplier</FormLabel> */}
                                                    {/* <Grid container alignItems="center">
                                                        <Grid item xs={12} md={11}>
                                                            <Item>
                                                                <Box component="div" sx={{ paddingTop: '6px' }}>
                                                                    <FormControl size={'small'} fullWidth>
                                                                        <TextField
                                                                            inputProps={{ maxLength: 100 }}
                                                                            size="small"
                                                                            id="mie_supplier"
                                                                            aria-describedby="mie_supplier_error"
                                                                            value={(mie_supplier.supplier_code_selected ? mie_supplier.supplier_code_selected.name : '')}
                                                                            name="mie_supplier"
                                                                            sx={{ pointerEvents: 'none' }}
                                                                        />
                                                                    </FormControl>
                                                                </Box>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={1}>
                                                            <Item>
                                                                <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0, marginRight: '5px' }} onClick={(e) => clearSMC(e, 'supplier')} startIcon={<ClearIcon />}></Button>
                                                                <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={(e) => CommonPopUpModal(e, 'supplier')} startIcon={<EditIcon />}></Button>
                                                            </Item>
                                                        </Grid>
                                                    </Grid> */}
                                                    <Autocomplete
                                                        value={mie_supplier.supplier_code_selected || null}
                                                        getOptionLabel={(option) => option.name || ""}
                                                        onChange={(_, newValue) => {
                                                            setMie_supplier({ ...mie_supplier, supplier_code_selected: newValue })
                                                        }}
                                                        inputValue={mie_supplierValue}
                                                        componentsProps={{
                                                            paper: {
                                                                sx: {
                                                                    fontSize: '0.85rem'
                                                                }
                                                            }
                                                        }}
                                                        onInputChange={(_, newInputValue) => {
                                                            setMie_supplierValue(newInputValue)
                                                            //setMie_supplier({ ...mie_supplier, supplier_code_selected: newInputValue })
                                                        }}
                                                        id="mie_supplier_search"
                                                        options={mie_supplier.supplier_code_options}
                                                        renderInput={(params) =>
                                                            <FormControl size={'small'} fullWidth>
                                                                <FormLabel htmlFor="mie_supplier" sx={{ paddingBottom: '7px' }}>Supplier</FormLabel>
                                                                <Box component="div" >
                                                                    <TextField {...params}
                                                                        size="small"
                                                                        id="mie_supplier"
                                                                        aria-describedby="mie_supplier-error"
                                                                    />
                                                                </Box>
                                                            </FormControl>
                                                        }
                                                    />
                                                </Box>
                                                <Box component="div">
                                                    <FormLabel htmlFor="config_name" sx={{ paddingBottom: '7px' }}>Supplier Member Account Code</FormLabel>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={11}>
                                                            <Item>
                                                                <Box component="div" sx={{ paddingTop: '6px' }}>
                                                                    <FormControl size={'small'} fullWidth>
                                                                        <TextField
                                                                            inputProps={{ maxLength: 100 }}
                                                                            size="small"
                                                                            id="pdc_gen_product"
                                                                            aria-describedby="pdc_gen_product_error"
                                                                            value={manualInvoiceEntry.mieSupplierCodeOnModal.smaCode ? manualInvoiceEntry.mieSupplierCodeOnModal.smaCode : ""}
                                                                            name="pdc_gen_product"
                                                                            sx={{ pointerEvents: 'none' }}
                                                                        />
                                                                    </FormControl>
                                                                </Box>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={1}>
                                                            <Item>
                                                                <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0, marginRight: '5px' }} onClick={(e) => clearSMC(e, 'supplier-member-account-code')} startIcon={<ClearIcon />}></Button>
                                                                <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={hideSupplierMemberModal} startIcon={<EditIcon />}></Button>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                <Box component="div">
                                                    <FormControl size={'small'} fullWidth>
                                                        <FormLabel htmlFor="config_name" sx={{ paddingBottom: '7px' }}>Invoice Date</FormLabel>
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <DesktopDatePicker
                                                                value={state.invoiceDate}
                                                                onChange={(e) => { handleInvoiceDate(e) }}
                                                                name="invoiceDate"
                                                                inputFormat="DD/MM/YYYY"
                                                                // eslint-disable-next-line max-len
                                                                renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                    <Box component="div" className='date-picker-input'>
                                                                        <FormControl size={'small'} fullWidth>
                                                                            <TextField size="small" id="invoiceDate"
                                                                                ref={inputRef} {...inputProps}
                                                                                placeholder=""
                                                                                aria-describedby="invoiceDate_error"
                                                                                autoComplete="off"
                                                                            />
                                                                            <Box className='date-picker-icon'>
                                                                                {InputProps?.endAdornment}
                                                                            </Box>
                                                                        </FormControl>
                                                                    </Box>
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    </FormControl>
                                                </Box>
                                            </Box>
                                            <Box component="div" sx={{ columnGap: 2.5, display: 'flex', justifyContent: { xs: 'center', sm: 'end' }, alignItems: 'center' }}>
                                                <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                                <Button variant="contained" onClick={filterBtn}>Apply</Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <DataTableInvoiceSummaries defaultGrid={state.defaultGrid} columns={columns}>

                                    </DataTableInvoiceSummaries>
                                    {/* <Box sx={{ flexGrow: 1, paddingX: 2 }} >
                                        <Grid container sx={{ marginTop: '20px' }}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Item>
                                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                                        <LoadingButton
                                                            onClick={(e) => { handleSubmit(e) }}
                                                            loading={state.loader}
                                                            loadingPosition="center"
                                                            endIcon=""
                                                            variant="contained"
                                                            type="submit" sx={{ marginRight: '15px' }}
                                                            className={`${state.formValid ? 'Mui-disabled' : ''}`}
                                                        >
                                                            Ok
                                                        </LoadingButton>
                                                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Box> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {snackBar.show && <SnackBarDialog
                        onShow={snackBar.show}
                        text={snackBar.text}
                        type={snackBar.type}
                        onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                    />}
                    {
                        state.handleSupplierMemberModal && <CommonPopUp
                            onShow={state.handleSupplierMemberModal}
                            size="large_overlay"
                            supplierMemberCode={{
                                title: 'Supplier Member Account',
                                fromModal: true,
                                supplier: mie_supplier.supplier_code_selected || null
                            }}
                            onHide={hideSupplierMemberModal}
                        />
                    }
                    {
                        state.supplierModalExistingInvoice && <CommonPopUp
                            onShow={state.supplierModalExistingInvoice}
                            size="large_overlay"
                            supplierMemberCode={{
                                title: 'Supplier Details',
                                type: 'supplier'
                            }}
                            memberaccount={(e) => getSupplierDetail(e, state.supplierModalExistingInvoice)}
                            onHide={(e, reason) => hideSupplierModal(e, reason, state.handleSupplierMemberModal)}
                        />
                    }
                </Box>
            </Modal>
        </>
    );
}
