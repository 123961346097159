import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  pageData: 10,
  pageNo: 1,
  aed_pageData: 10,
  aed_pageNo: 1,
  invd_pageData: 300,
  invd_pageDataDispute: 300,
  invd_pageNo: 1,
  invd_pageNoDispute: 1,
}
const dataGridSlice = createSlice({
  name: 'dataGrid',
  initialState,
  reducers: {
    setRowPage: (state, action) => {
      const { pageData} = action.payload;
      state.pageData = pageData;
    },
    setPageNo: (state, action) => {
      const { page } = action.payload;
      state.pageNo = page;
    },
    setAccountEntriesDetailsRowPage: (state, action) => {
      const { aed_pageData} = action.payload;
      state.aed_pageData = aed_pageData;
    },
    setAccountEntriesDetailsPageNo: (state, action) => {
      const { aed_page } = action.payload;
      state.aed_pageNo = aed_page;
    },
    setInvoiceDetailsRowPage: (state, action) => {
      const { invd_pageData } = action.payload;
      state.invd_pageData = invd_pageData;
    },
    setInvoiceDetailsDisputeRowPage: (state, action) => {
      const { invd_pageDataDispute } = action.payload;
      state.invd_pageDataDispute = invd_pageDataDispute;
    },
    setInvoiceDetailsPageNo: (state, action) => {
      const { invd_page } = action.payload;
      state.invd_pageNo = invd_page;
    },
    setInvoiceDetailsPageNoDispute: (state, action) => {
      const { invd_pageNoDispute } = action.payload;
      state.invd_pageNoDispute = invd_pageNoDispute;
    },
    resetDataGrid: () => {
      return initialState
    }
  }
})

export const { setRowPage, setPageNo, resetDataGrid, setAccountEntriesDetailsRowPage, setAccountEntriesDetailsPageNo, setInvoiceDetailsRowPage, setInvoiceDetailsPageNo, setInvoiceDetailsDisputeRowPage,
   setInvoiceDetailsPageNoDispute } = dataGridSlice.actions

export default dataGridSlice.reducer