import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editTab: true,
    hasEditDC: null,
    editData: null,
    hasEditData: null,
    dcStartSingleDelete: false,
    dcConfirmSingleDelete: false,
    deleteList: null,
    dc_list: [],
    dc_name: '',
    dc_status: true,
    dc_central_bil_scheme: {
        dc_central_bil_scheme_options: [],
        dc_central_bil_scheme_selected: null
    },
    isError: {
        dc_name: false,
        dc_central_bil_scheme_selected: false,
        //dc_status: true
      }
   
}

const dcProduct = createSlice({
    name: 'distributionChannel_product',
    initialState,
    reducers: {
        brandSearch: (state, action) => {
            const { parent, key, value } = action.payload;
            state[parent][key] = value;
            //console.log(state[parent][key] === null)
            (state[parent][key] === null || state[parent][key] === "") ? state.isError[key] = true : state.isError[key] = false
        },
        dcProductInput: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value;
            state[key].length === 0 ? state.isError[key] = true : state.isError[key] = false
        },
        getIsError: (state, action) => {
            const { key, value } = action.payload;
            state.isError[key] = value
        },
        hasEdit: (state, action) => {
            const { editTab } =  action.payload;
            state.editTab = editTab
        },
        hasEditData: (state, action) => {
            const { editData } =  action.payload;
            state.editData = editData
        },
        setHasEditData: (state, action) => {
            state.hasEditData = action.payload;
        },
        setDCName: (state, action) => {
            state.dc_name = action.payload;
        },
        setDCList: (state, action) => {
            state.dc_list = action.payload
        },
        setDCSingleDeleteData: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value;
          },
        setAutocomplete: (state, action) => {
            const { parent, key, value } =  action.payload;
            state[parent][key] = value
        },
        setDeleteList: (state, action) => {
            state.deleteList = action.payload
          },
        resetDC: () => {
            return initialState
        },
    }
})

export const { brandSearch, dcProductInput, setDCName, resetDC, setAutocomplete, getIsError, 
    setDCList, hasEdit, hasEditData, setHasEditData, setDeleteList, setDCSingleDeleteData} = dcProduct.actions

export default dcProduct.reducer