import { Box, Modal, Tabs, Tab } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { warningModal, handleAddEditdiscountScheme } from "../../../store/slices/modals";
import { setDiscountSchemeDeleteData, resetdiscountScheme } from "../../../store/slices/discountScheme";
import DcGeneral from "./dcGeneral"; 
import DcBrands from "./dcbrands";
import DcDistributionChannel from "./dcDistributionChannel";
import DcMembers from "./dcMembers";
import DcSupplier from "./dcSupplier";
import DcProducts from "./dcProducts";
import DcProgrammes from "./dcProgrammes";
import { filterFlagChange, filterResetFlagChange } from '../../../store/slices/externalFilter';

function TabPanel(props) {
    const { children, value, index, id, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`${id}-tab-pannel-${index}`}
            aria-labelledby={`${id}-tab-${index}`}
            {...other}
            className="tabs-pannel"
        >
            {value === index && (
                <>{children}</>
            )}

        </div>
    );
}

export default function AddEditDiscountScheme({ onShow, size = '' }) {

    const { handleWarning, discountScheme_container } = useSelector((state) => {
        return {
            handleWarning: state.modalActions.closeWaringAddProduct,
            discountScheme_container: state.discountSchemes
        }
    });
    
    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(setDiscountSchemeDeleteData({
            key: 'okAddMore',
            value: false
        }))
        dispatch(setDiscountSchemeDeleteData({
            key: 'addMoreConfirm',
            value: false
        }))
        dispatch(handleAddEditdiscountScheme({
            showAddEdit: false
        }))
        dispatch(resetdiscountScheme());
        dispatch(filterFlagChange(true));
        dispatch(filterResetFlagChange(false));
    };

    const [value, setValue] = useState(0);
    function handleChange(event, newValue) {
        setValue(newValue);
    }

    return (
        <>
            <Modal
                open={onShow || false}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <Box component="div" className="overlay" id="addEditModal">
                    <div className={`overlay-box ${size ? size : ''} `}>
                        <div className="content">
                            <div className="overlayBody">
                                <div className="overlay-header text-center"> Discount Schemes Details
                                    <CancelIcon className="closeBtn" onClick={handleClose} />
                                </div>
                                <div className="overlay-content">
                                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            variant="scrollable"
                                            scrollButtons={false}
                                            aria-label="scrollable prevent tabs example"
                                        >
                                                <Tab className="tabs-pannel" label="General" sx={{ minWidth: 'auto', paddingX: { xs: '5px', md: '0.85rem' }, marginLeft: '15px', fontWeight: '700', textTransform: 'capitalize' }} />
                                                <Tab className="tabs-pannel" disabled={!discountScheme_container.hasEditData} label="Brands" sx={{ minWidth: 'auto', paddingX: { xs: '5px', md: '0.85rem' }, marginLeft: '15px', fontWeight: '700', textTransform: 'capitalize' }} />
                                                <Tab className="tabs-pannel" disabled={!discountScheme_container.hasEditData} label="Distribution Channel" sx={{ minWidth: 'auto', paddingX: { xs: '5px', md: '0.85rem' }, marginLeft: '15px', fontWeight: '700', textTransform: 'capitalize' }} />
                                                <Tab className="tabs-pannel" disabled={!discountScheme_container.hasEditData} label="Members" sx={{ minWidth: 'auto', paddingX: { xs: '5px', md: '0.85rem' }, marginLeft: '15px', fontWeight: '700', textTransform: 'capitalize' }} />
                                                <Tab className="tabs-pannel" disabled={!discountScheme_container.hasEditData} label="Products" sx={{ minWidth: 'auto', paddingX: { xs: '5px', md: '0.85rem' }, marginLeft: '15px', fontWeight: '700', textTransform: 'capitalize' }} />
                                                <Tab className="tabs-pannel" disabled={!discountScheme_container.hasEditData} label="Programmes" sx={{ minWidth: 'auto', paddingX: { xs: '5px', md: '0.85rem' }, marginLeft: '15px', fontWeight: '700', textTransform: 'capitalize' }} />
                                                <Tab className="tabs-pannel" disabled={!discountScheme_container.hasEditData} label="Suppliers" sx={{ minWidth: 'auto',  paddingX: { xs: '5px', md: '0.85rem' }, marginLeft: '15px', fontWeight: '700', textTransform: 'capitalize' }} />
                                        </Tabs>
                                    </Box>
                                    <TabPanel value={value} index={0}>
                                        <DcGeneral />
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <DcBrands />
                                    </TabPanel>
                                    <TabPanel value={value} index={2}>
                                        <DcDistributionChannel />
                                    </TabPanel>
                                    <TabPanel value={value} index={3}>
                                        <DcMembers />
                                    </TabPanel>
                                    <TabPanel value={value} index={4}>
                                        <DcProducts />
                                    </TabPanel>
                                    <TabPanel value={value} index={5}>
                                        <DcProgrammes />
                                    </TabPanel>
                                    <TabPanel value={value} index={6}>
                                        <DcSupplier />
                                    </TabPanel>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}
