import { Box, Modal, Paper, FormControl, Grid, TextField, FormHelperText, FormLabel, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBrandOwnerModal, warningModal, setNewBrandModal } from "../../../store/slices/modals";
import { brandInput, editBrandOwner, getIsError, hasBrandOwner, setAutocomplete, setBrandDateList, setBrandEffectiveDate, setdeleteBrandOwner, setDeleteList, setNewBrandData, resetBrand } from "../../../store/slices/brandProduct";
import { DataTableClientSide } from '../../../components/shared/data-table/DataTableClientSide';
import AddBrandDate from '../addEditBrand/AddBrandDate';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SnackBarDialog from '../../../components/shared/SnackBarDialog';
import axios_brand from '../../../services/utility/axios-brand';
import PageLoader from '../../../components/shared/PageLoader';
import moment from 'moment';
import { filterFlagChange, filterResetFlagChange } from '../../../store/slices/externalFilter';

const IconMenuLink = ({ params, onDelete }) => {
    const { row } = params;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { brand_container } = useSelector((state) => {
        return {
            brand_container: state.brandProducts
        }
    });
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'edit') {
            editBrandOwnerList(row)
        } else if (action === 'delete') {
            if (onDelete) {
                onDelete(row)
            }
        }
        setAnchorEl(null);
    };

    const editBrandOwnerList = (row) => {
        dispatch(setBrandOwnerModal(true))
        dispatch(editBrandOwner(row))
        dispatch(setBrandEffectiveDate({
            key: 'brand_start_date',
            value: moment(row.brandOwnerEffectiveDatePeriod.startDate, 'DD/MM/YYYY')
        }))
        dispatch(setBrandEffectiveDate({
            key: 'brand_end_date',
            value: row.brandOwnerEffectiveDatePeriod.endDate ? moment(row.brandOwnerEffectiveDatePeriod.endDate, 'DD/MM/YYYY') : null
        }))
        dispatch(setAutocomplete({
            parent: 'brand_owner_name',
            key: 'brand_owner_selected',
            value: row.brandOwner
        }));
    }

    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'edit')}>Edit</MenuItem>
            <MenuItem onClick={(e) => handleClose(e, 'delete')}>Delete</MenuItem>
        </Menu>

    </>)
}
const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

export default function AddEditBrand({ onShow }) {
    const [state, setState] = useState({
        brand_name: "",
        hasBrandName: false,
        loader: false,
        formValid: false,
        pageLoader: false,
        defaultmode: false,
        rowData: null,
        defaultGrid: {
            paginationMode: 'client',
        },
        addEditBrandOwnerModal: false
    });
    const [columns, setColumns] = useState([
        {
            headerName: "Id",
            field: "id",
            flex: 1,
            hide: true,
        },
        {
            headerName: "Brand Owner",
            field: "brandOwner",
            flex: 1,
            minWidth: 200,
            valueFormatter: ({ value }) => value.name
        },
        {
            headerName: "Start Date",
            field: "startDate",
            flex: 1,
            minWidth: 200,
            valueGetter: (params) => {
                const { row: { brandOwnerEffectiveDatePeriod: { startDate } } } = params;
                return startDate
            }
        },
        {
            headerName: "End Date",
            field: "endDate",
            flex: 1,
            minWidth: 200,
            valueGetter: (params) => {
                const { row: { brandOwnerEffectiveDatePeriod: { endDate } } } = params
                return endDate
            }
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => <IconMenuLink params={params} onDelete={deleteBrandOwner} />,
            // renderCell: (params) => {
            //     const { row: { brandOwnerEffectiveDatePeriod: { endDate } } } = params
            //     if (endDate === "" || endDate === null || endDate === undefined || moment(endDate, 'DD/MM/YYYY').isSameOrAfter(moment(), "day")) {
            //         return <IconMenuLink params={params} onDelete={deleteBrandOwner} />;
            //     } else {
            //         return ""
            //     }
            // },
        }
    ]);
    const [deleteRowData, setDeleteRowData] = useState(null)
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const allColumns = [
        {
            headerName: "Id",
            field: "id",
            flex: 1,
            hide: true,
        },
        {
            headerName: "Brand Owner",
            field: "brandOwner",
            flex: 1,
            minWidth: 200,
            valueFormatter: ({ value }) => value.name
        },
        {
            headerName: "Start Date",
            field: "startDate",
            flex: 1,
            minWidth: 200,
            valueGetter: (params) => {
                const { row: { brandOwnerEffectiveDatePeriod: { startDate } } } = params;
                return startDate
            }
        },
        {
            headerName: "End Date",
            field: "endDate",
            flex: 1,
            minWidth: 200,
            valueGetter: (params) => {
                const { row: { brandOwnerEffectiveDatePeriod: { endDate } } } = params
                return endDate
            }
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => <IconMenuLink params={params} onDelete={deleteBrandOwner} />,
            // renderCell: (params) => {
            //     const { row: { brandOwnerEffectiveDatePeriod: { endDate } } } = params
            //     if (endDate === "" || endDate === null || endDate === undefined || moment(endDate, 'DD/MM/YYYY').isSameOrAfter(moment(), "day")) {
            //         return <IconMenuLink params={params} onDelete={deleteBrandOwner} />;
            //     } else {
            //         return ""
            //     }
            // },
        }
    ];

    const { handleBrandOwnerModal, handleWarning, brand_container } = useSelector((state) => {
        return {
            handleBrandOwnerModal: state.modalActions.handleBrandOwnerModal,
            brand_container: state.brandProducts,
            handleWarning: state.modalActions.closeWaringAddProduct,
        }
    });
    const dispatch = useDispatch();

    const allBrandlists = async () => {
        try {
            setColumns(allColumns)
            setTimeout(() => {
                if (brand_container.addNewBrandData) {
                    setState({ ...state, pageLoader: true })
                }
                setColumns(allColumns)
            }, 100);
            if (brand_container.brand_name) {
                dispatch(hasBrandOwner(true))
                const { data } = await axios_brand.get(`brands/${brand_container.addNewBrandData.brandId}/brandOwner`);
                if (data) {
                    setState({ ...state, pageLoader: false })
                    dispatch(setBrandDateList([]))
                    let newBx = data;
                    newBx.forEach((x, index) => {
                        if (x.id) {
                            x.id = x.id + index
                        }
                    });
                    dispatch(setBrandDateList(newBx))
                }
            }

        }
        catch (error) {
            const { response: { data, status } } = error;
            setState({ ...state, pageLoader: false })
            if ((data.message === "Brand Owner not found" || data.message === "BrandOwner Info is missing") && data.errorType === "Business") {
                dispatch(setBrandDateList([]))
            }
            else
            {
                setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error" })
            }
            //if (data.message !== "BrandOwner Info is missing") {
                
            //}
        }
    }
    useEffect(() => {
        allBrandlists()
    }, []);

    const addEditBrandOwner = () => {
        dispatch(setBrandOwnerModal(true))
    }
    const hideAddEditDateModal = (event) => {
        dispatch(setBrandOwnerModal(event))
    }
    const deleteBrandOwner = (row) => {
        setDeleteRowData(row)
        dispatch(warningModal({
            yesHide: true
        }))
        dispatch(setdeleteBrandOwner({
            key: 'deleteProcess',
            value: true
        }))
    }
    useEffect(() => {
        if (brand_container.okDelete === true) {
            let deleteBO = {
                "id": brand_container.addNewBrandData.brandId,
                "brandOwnerId": deleteRowData.brandOwner.id
            }
            const fetchMyAPI = async () => {
                try {
                    setState({ ...state, pageLoader: true })
                    // const response = await axios_brand.delete(`brands/${brand_container.addNewBrandData.id}/brandOwner`, { data: deleteBO });
                    const response = await axios_brand.delete(`brands/${deleteRowData.brandOwnerEffectiveDatePeriod.id}/brandOwner`, { data: deleteBO });
                    if (response.data.success) {

                        dispatch(setdeleteBrandOwner(false))
                        setSnackBar({ ...snackBar, show: true, text: response.data.message, type: "success" });
                        dispatch(setBrandDateList([]));

                        const { data } = await axios_brand.get(`brands/${brand_container.addNewBrandData.brandId}/brandOwner`);
                        
                        if (!data) {
                            setState({ ...state, pageLoader: false })
                            setSnackBar({ ...snackBar, show: true, text: "Technical", type: "error" })
                        }
                        if (data !== null || data !== undefined) {
                             
                            // dispatch(setBrandDateList(data))
                            setState({ ...state, pageLoader: false })
                            setTimeout(() => {
                                let newBx = data;
                                newBx.forEach((x, index) => {
                                    if (x.id) {
                                        x.id = x.id + index
                                    }
                                });
                                dispatch(setBrandDateList(newBx));
                                
                            }, 500);

                        }


                    }

                } catch (error) {
                    const { response: { data, status } } = error;
                    if (!data) {
                        setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                    } else {
                        if (data.message !== "Brand Owner not found" && data.message !== "BrandOwner Info is missing") {
                            setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" });
                        }
                        if ((data.message === "Brand Owner not found" || data.message === "BrandOwner Info is missing") && data.errorType === "Business") {
                            dispatch(setBrandDateList([]))
                        }
                    }
                    setState({ ...state, pageLoader: false })
                    dispatch(setdeleteBrandOwner(false))

                }
            }
            fetchMyAPI()
            dispatch(setdeleteBrandOwner({
                key: 'okDelete',
                value: false
            }))
            dispatch(setdeleteBrandOwner({
                key: 'deleteProcess',
                value: false
            }))
        }
    }, [brand_container.okDelete])

    const handleClose = () => {
        // dispatch(warningModal({
        //     yesHide: !handleWarning
        // }))
        dispatch(setNewBrandModal({
            showAddEdit: false
        }))
        dispatch(resetBrand());
        dispatch(filterFlagChange(true));
        dispatch(filterResetFlagChange(false));
    };

    const handleInputChange = (event) => {
        const { value, name } = event.target;
        dispatch(brandInput({
            key: name,
            value
        }))
        setState({ ...state, defaultmode: true })
    }
    useEffect(() => {
        if (brand_container.brand_name === null || brand_container.brand_name === "") {
            setState({ ...state, formValid: true, defaultmode: false })
        } else {
            setState({ ...state, formValid: false, defaultmode: true })
        }
    }, [brand_container.brand_name])

    useEffect(() => {
        const {
            brand_name
        } = brand_container.isError
        if (!brand_name) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true })
        }
    }, [brand_container.isError])

    const handleSubmit = async (event) => {
        event.preventDefault();
        setState({ ...state, loader: true })
        if (brand_container.brand_name !== "") {
            let brand_name = {
                "name": brand_container.brand_name
            }

            try {
                setState({ ...state, loader: true })
                const { data } = brand_container.addNewBrandData ? await axios_brand.put(`brands/${brand_container.addNewBrandData.brandId}`, brand_name) : await axios_brand.post(`brands`, brand_name);
                if (data && data.success === true) {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "success", })
                    dispatch(setNewBrandData(data.resultMap.result))
                    dispatch(hasBrandOwner(true))
                    setState({ ...state, formValid: true, loader: false })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error", })
                    setState({ ...state, formValid: false, loader: false })
                }
            } catch (error) {
                const { response: { data, status } } = error;
                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                }
                setState({ ...state, loader: false, pageLoader: false, formValid: false, finalSubmitError: '', submitError: [], submitDisable: true })
                dispatch(hasBrandOwner(false))
            }


        }
    }

    const handlBrandOwner = (data) => {
        setSnackBar({ ...snackBar, show: true, text: data.text, type: data.type });
    }
    return (
        <>
            <Modal
                open={onShow || false}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <Box component="div" className="overlay" id="addEditModal">
                    {state.pageLoader && <PageLoader />}
                    <div className="overlay-box large_overlay">
                        <div className="content">
                            <div className="overlayBody">
                                <div className="overlay-header text-center"> Brand Details
                                    <CancelIcon className="closeBtn" onClick={handleClose} />
                                </div>
                                <div className="overlay-content">
                                    <Box sx={{ flexGrow: 1, paddingX: 2 }} >
                                        <div className='BrandOwnerNameBx'>
                                            <Grid container alignItems="center">
                                                <Grid item>
                                                    <Item sx={{ paddingLeft: 0 }}>
                                                        <FormLabel htmlFor="brand_name" className="fontSemiBold mandatory">Brand Name</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item>
                                                    <Item>
                                                        <div className="input-group">
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    inputProps={{ maxLength: 100 }}
                                                                    size="small"
                                                                    id="brand_name"
                                                                    aria-describedby="brand_name_error"
                                                                    onChange={(e) => handleInputChange(e)}
                                                                    value={brand_container.brand_name}
                                                                    error={brand_container.isError.brand_name}
                                                                    name="brand_name"
                                                                />
                                                                {brand_container.isError.brand_name && (<FormHelperText className='errorHelperTxt' id="brand_name_error">
                                                                    Please enter unique value
                                                                </FormHelperText>)}
                                                            </FormControl>

                                                        </div>
                                                    </Item>
                                                </Grid>
                                                {brand_container.brand_name && brand_container.brand_name.length > 0 && (<Grid item>
                                                    <Item>
                                                        <LoadingButton
                                                            onClick={(e) => { handleSubmit(e) }}
                                                            loading={state.loader}
                                                            loadingPosition="center"
                                                            endIcon=""
                                                            variant="contained"
                                                            type="submit"
                                                            sx={{ minWidth: '0', padding: '7px 6px' }}
                                                            //disabled={!brand_container.brand_name && !brand_container.brand_name.length > 0 || (state.defaultmode === false)}
                                                            disabled={brand_container.addNewBrandData ? brand_container.isError.brand_name : (state.formValid || state.defaultmode === false)} 
                                                            //className={`${state.formValid ? 'Mui-disabled' : ''}`}
                                                        >
                                                            <CheckCircleIcon sx={{ marginRight: '7px', }} /> Save
                                                        </LoadingButton>
                                                    </Item>
                                                </Grid>)}
                                                {
                                                    brand_container.hasBrandOwner && (<Grid item sx={{ marginLeft: 'auto', marginRight: { xs: 'auto', sm: '0' } }}>
                                                        <Item sx={{ paddingRight: 0 }}>
                                                            <div className='table-actionable'>
                                                                <Button variant="contained" onClick={addEditBrandOwner}>
                                                                    Assign Brand Owner
                                                                </Button>
                                                            </div>
                                                        </Item>
                                                    </Grid>)
                                                }

                                            </Grid>
                                        </div>

                                        <DataTableClientSide
                                            columns={columns ? columns : []}
                                            defaultGrid={state.defaultGrid}
                                            rows={brand_container.brand_date}
                                        >
                                        </DataTableClientSide>
                                        {/* <Grid container sx={{ marginTop: '20px' }}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Item>
                                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                                        <LoadingButton
                                                            // onClick={(e) => { window.location.reload() }}
                                                            onClick={handleClose} 
                                                            loadingPosition="center"
                                                            endIcon=""
                                                            variant="contained"
                                                            type="submit"
                                                        >
                                                            Close and Refresh Dashboard
                                                        </LoadingButton>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid> */}
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div>
                    {handleBrandOwnerModal && (
                        <>
                            <AddBrandDate size="medium_overlay"
                                onShow={handleBrandOwnerModal}
                                onHide={(e, reason) => hideAddEditDateModal(e, reason)}
                                onBrandAdded={handlBrandOwner}
                            />
                        </>
                    )}
                    {snackBar.show && <SnackBarDialog
                        onShow={snackBar.show}
                        text={snackBar.text}
                        type={snackBar.type}
                        onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                    />}
                </Box>
            </Modal>
        </>
    );
}
