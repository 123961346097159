import { Container, Box, Modal, Button, Grid, Checkbox, Popper, Grow, Paper, ClickAwayListener, MenuList, ButtonGroup, FormLabel, FormControl, TextField, FormControlLabel, Autocomplete, FormHelperText } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect, useState } from "react";
import { } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useLayoutEffect } from "react";
import { hasTabEdit, setHasEditData, generalProductInput, setMembersData, setSpecification, setEanCodeList, setAutocomplete, setMemberDate, getIsError, setMemberProgrammeEdit, resetMember } from "../../../store/slices/memberBclValues";
import PageLoader from "../../../components/shared/PageLoader";
import { DataTableMember } from "../../../components/shared/data-table/DataTableMember";
import Typography from '@mui/material/Typography';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import axiosMember from '../../../services/utility/axios-member';
import SnackBarDialog from "../../../components/shared/SnackBarDialog";
import { Routes, Route, useNavigate } from 'react-router-dom';
import axiosProgrammes from '../../../services/utility/axios-programmes';
import { filterFlagChange } from '../../../store/slices/externalFilter';
import PageLoaderStyle2 from '../../../components/shared/PageLoaderStyle2';
import refreshLambda from '../../../services/utility/refresh-lambda';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';

var c_memberProgrammeId = 0;

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    marginBottom: '50px'
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'revert',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {

    },
}));


const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(4),
    borderTop: '1px solid rgba(0, 0, 0, .125)',

}));



function MemberProgrammes() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { showAddEditModal, general_product_fields, handleWarning, member_fields } = useSelector((state) => {
        return {
            showAddEditModal: state.modalActions.hideAddEditModal,
            editTabData: state.generalProduct.editTabData,
            general_product_fields: state.generalProduct,
            handleWarning: state.modalActions.closeWaringAddProduct,
            member_fields: state.memberBclEntry
        }
    });

    const [state, setState] = useState({
        active_member: false,
        openNewProductModal: false,
        pageLoader: false,
        pageLoader2: false,
        loader: false,
        level2: false,
        level1: true,
        formValid: false,
        defaultmode: false,
        location: 'Member Outlets',
        locationlabel: "Programme",
        member_id: member_fields.member_id || 0,
        member_accountid: member_fields.member_accountid || 0,
        member_outletid: member_fields.member_outletid || 0,
        member_programmeid: member_fields.member_programmeid || 0,
        defaultGrid: {
            productActionButton: true,
            hasExport: true,
            addEditButton: true,
            apiUrl: 'members',
            searchUrl: 'members/filter'
        }
    });

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
        vertical: '',
        horizontal: '',
        duration: 0
    });

    useEffect(() => {
        // console.log(member_fields);
        const fetchMyAPI = async () => {

            if (member_fields.member_programme.programme_options.length === 0) {
                axiosProgrammes.get('programmes/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data.filter((value, index, self) => self.findIndex((m) => m.name === value.name) === index);
                            dispatch(setAutocomplete({
                                parent: 'member_programme',
                                key: 'programme_options',
                                value: unique
                            }));
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
            // console.log("Call", c_memberProgrammeId);
            c_memberProgrammeId = 0;
        }
        if (member_fields.editMembersTab === false && member_fields.hasMemberEditData === null && member_fields.member_outletid === 0) {
            navigate('/suppliers-and-members/members');
            // console.log("Page is Reloaded");
        }
        else {
            fetchMyAPI();
        }
        // console.log("Page Load: ",member_fields);
    }, []);

    useEffect(() => {
        // console.log("Error Values: ",member_fields.isError);
        const { programme_selected, member_programme_jd } = member_fields.isError;
        if (programme_selected === false && (member_programme_jd === false || member_programme_jd === undefined)) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true })
        }

    }, [member_fields.isError]);

    const handleSelection = (event, newValue, parentname, name) => {
        setState({ ...state, defaultmode: true })
        if (newValue !== "" || newValue !== null) {
            dispatch(setAutocomplete({
                parent: parentname,
                key: name,
                value: newValue
            }))
        }
        else {
            dispatch(setAutocomplete({
                parent: parentname,
                key: name,
                value: null
            }))
        }
    }

    const [expanded, setExpanded] = React.useState('panel1');

    const handleAccChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleCancel = (event) => {
        dispatch(filterFlagChange(true));
        navigate('/suppliers-and-members/members/member-outlets');
    }

    const handleDashboard = (event) => {
        localStorage.setItem("memberfilterflag", "true");
        dispatch(filterFlagChange(true));
        navigate('/suppliers-and-members/members');
    }

    // useEffect(() => {
    //     if (member_fields.member_programme.programme_selected === null || member_fields.member_programme.programme_selected === "") {
    //         dispatch(getIsError({ key: 'programme_selected', value: true }))
    //     } else {
    //         dispatch(getIsError({ key: 'programme_selected', value: false }))
    //     }
    //     if (member_fields.member_programme_jd === null || member_fields.member_programme_jd === 'Invalid date' || member_fields.member_programme_jd === "") {
    //         dispatch(getIsError({ key: 'member_programme_jd', value: true }))
    //     } else {
    //         dispatch(getIsError({ key: 'member_programme_jd', value: false }))
    //     }
    // }, [member_fields]);

    // useEffect(() => {
    //     //console.log("change fields");
    //     const { member_programme, member_programme_jd } = member_fields;

    //     if (member_fields.memberDetailsTab !== true) {
    //         // console.log(member_estate_group['estate_group_selected']);
    //         if ((member_programme['member_programme.programme_selected'] === "" || member_programme['member_programme.programme_selected'] === null) || (member_programme_jd === "" || member_programme_jd === null || member_programme_jd === "Invalid date")) {
    //             setState({ ...state, formValid: true })
    //         }
    //         else {
    //             setState({ ...state, formValid: false })
    //         }
    //     }

    // }, [member_fields.member_programme, member_fields.member_programme_jd]);

    const handleJoinDate = (event) => {
        // setState({ ...state, defaultmode: true });
        if (event !== null) {
            dispatch(setMemberDate({
                key: 'member_programme_jd',
                value: moment(event, 'DD/MM/YYYY')
            }))
            dispatch(setMemberDate({
                key: 'member_programme_ld',
                value: null
            }))
        }
        else {
            dispatch(setMemberDate({
                key: 'member_programme_jd',
                value: null
            }))
        }

    }
    const handleLeaveDate = (event) => {
        // setState({ ...state, defaultmode: true });
        if (event !== null) {
            dispatch(setMemberDate({
                key: 'member_programme_ld',
                value: moment(event, 'DD/MM/YYYY')
            }))
        }
        else {
            dispatch(setMemberDate({
                key: 'member_programme_ld',
                value: null
            }))
        }

    }

    function handleClick(event) {
        event.preventDefault();
        // navigate('/suppliers-and-members/members/member-details');
    }


    const handleBClick = (event, location) => {
        event.preventDefault();
        switch (location) {
            case "Member":
                dispatch(resetMember());
                dispatch(filterFlagChange(true));
                navigate('/suppliers-and-members/members');
                break
            case "Member Details":
                navigate('/suppliers-and-members/members/member-details');
                break
            case "Member Accounts":
                navigate('/suppliers-and-members/members/member-accounts');
                break
            case "Member Outlets":
                navigate('/suppliers-and-members/members/member-outlets');
                break
            default:
                navigate('/suppliers-and-members/members');
        }
    }

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" >
            Suppliers & Members
        </Link>,
        <Link underline="hover" key="1" color="inherit" onClick={(e) => handleBClick(e, 'Member')} >
            Members
        </Link>,
        <Link underline="hover" key="1" color="inherit" onClick={(e) => handleBClick(e, 'Member Details')} >
            Account Details
        </Link>,
        <Link underline="hover" key="2" color="inherit" onClick={(e) => handleBClick(e, 'Member Accounts')} >
            Member Accounts
        </Link>,
        <Link underline="hover" key="2" color="inherit" onClick={(e) => handleBClick(e, 'Member Outlets')} >
            Member Outlets
        </Link>,
        <Typography key="3" color="text.primary">
            Member Programme
        </Typography>
    ];

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (member_fields.member_programme.programme_selected === null && member_fields.isError.programme_selected === false) {
            dispatch(getIsError({ key: 'programme_selected', value: true }))
        } else if ((member_fields.member_programme_jd === "" || member_fields.member_programme_jd === null) && member_fields.isError.member_programme_jd === false) {
            dispatch(getIsError({ key: 'member_programme_jd', value: true }))
        } else {
            let memberData = null;
            if (!state.formValid && member_fields.member_id !== 0 && member_fields.member_accountid !== 0 && member_fields.member_outletid !== 0) {
                if (moment(member_fields.member_programme_jd).format('DD/MM/YYYY') === 'Invalid date' || (member_fields.member_programme_ld !== "" && member_fields.member_programme_ld !== null && moment(member_fields.member_programme_ld).format('DD/MM/YYYY') === 'Invalid date')) {
                    setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
                }
                else if (member_fields.member_programme_ld !== null && member_fields.member_programme_ld !== "" && (member_fields.member_programme_jd > member_fields.member_programme_ld)) {
                    setSnackBar({ ...snackBar, show: true, text: "Leave date is less than Join Date!", type: "error", });
                }
                else {
                    memberData = {
                        "joinDate": member_fields.member_programme_jd ? moment(member_fields.member_programme_jd).format('DD/MM/YYYY') : null,
                        "leaveDate": member_fields.member_programme_ld ? moment(member_fields.member_programme_ld).format('DD/MM/YYYY') : null,
                        "programme": {
                            "endDate": member_fields.member_programme_ld ? moment(member_fields.member_programme_ld).format('DD/MM/YYYY') : null,
                            "id": member_fields.member_programme.programme_selected.id,
                            "name": member_fields.member_programme.programme_selected.name,
                            "startDate": member_fields.member_programme_jd ? moment(member_fields.member_programme_jd).format('DD/MM/YYYY') : null
                        }
                    }
                    try {
                        setState({ ...state, loader: true })
                        const { data, status } = member_fields.hasMemberProgrammesEditData ? await axiosMember.put(`members/${member_fields.member_id}/memberAccounts/${member_fields.member_accountid}/memberOutlets/${member_fields.member_outletid}/memberProgrammes/${member_fields.hasMemberProgrammesEditData.id}`, { ...memberData }) :
                            c_memberProgrammeId !== 0 ? await axiosMember.put(`members/${member_fields.member_id}/memberAccounts/${member_fields.member_accountid}/memberOutlets/${member_fields.member_outletid}/memberProgrammes/${c_memberProgrammeId}`, { ...memberData })
                                : await axiosMember.post(`members/${member_fields.member_id}/memberAccounts/${member_fields.member_accountid}/memberOutlets/${member_fields.member_outletid}/memberProgrammes`, { ...memberData });
                        if (data.success === true) {
                            if (data.resultMap) {
                                setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Member Programme Added Successfully!", type: "success", });
                                setState({ ...state, loader: false, formValid: false, member_programmeid: data.resultMap.memberProgrammeId, defaultmode: false });
                                dispatch(setMemberProgrammeEdit({ editMemberProgrammesTab: true, member_programmeid: data.resultMap.memberProgrammeId }));
                                c_memberProgrammeId = data.resultMap.memberProgrammeId;
                            }
                            else {
                                if (data.message) {
                                    setState({ ...state, loader: false, formValid: false, defaultmode: false });
                                    setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Member Programme Edited Successfully!", type: "success", });
                                }
                            }

                        }
                        else {
                            setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry by going one step back", type: "error", });
                        }
                    } catch (error) {
                        const { response: { data, status } } = error;
                        dispatch(setMemberProgrammeEdit({ editMemberProgrammesTab: false, member_programmeid: 0 }))
                        // dispatch(hasTabEdit({ editTab: true }))
                        setSnackBar({ ...snackBar, show: true, text: data ? data.message : "System took long to respond, please retry!", type: "error", })
                        setState({ ...state, loader: false, formValid: false })


                    }
                }
            }
            else {
                setSnackBar({ ...snackBar, show: true, text: "Something went wrong! Member Data missing, go one step back", type: "error", });
            }
        }
    };
    const lambdaWarmer = () => {
        onReprocessReverse()
    }
    const onReprocessReverse = async () => {
        try {
            setState({ ...state, pageLoader2: true });
            const { data } = await refreshLambda.get('/warmup');

            if (data) {
                setSnackBar({ ...snackBar, vertical: 'top', duration: 6000, horizontal: 'center', show: true, text: "System is warming up, please check after 9 minutes", type: "info" })
            }
            setState({ ...state, pageLoader2: false });
        } catch (error) {
            const { response: { data, status } } = error;
            if (status === 500) {
                setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
            } else {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
            }
            setState({ ...state, pageLoader2: false });
        }
    }
    return (
        <>
            {state.pageLoader && <PageLoader />}
            {state.pageLoader2 && <PageLoaderStyle2 />}
            <Container disableGutters={true} maxWidth={false} >
                <Box component="div" className='breadcrumbNavs'>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                            <Breadcrumbs component="div" separator="›" aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                            <div className='pageHeading'>
                                <h1>{member_fields.hasMemberProgrammesEditData && member_fields.hasMemberOutletsEditData && member_fields.hasMemberProgrammesEditData ? member_fields.hasMemberAccountsEditData.name + " > " + member_fields.hasMemberOutletsEditData.name + " > " + member_fields.hasMemberProgrammesEditData.programme.name : "Member Programme"}</h1>
                            </div>
                        </div>
                        <Box className='lambdaRefreshBtn'>
                            <Box component={'a'} sx={{ display: 'flex', alignItems: 'center' }} onClick={lambdaWarmer}>
                                <LocalFireDepartmentIcon color="primary" /> <span style={{ marginLeft: '5px', color: '#B253AE' }}>Warm System</span>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Accordion expanded={expanded === 'panel1'} onChange={handleAccChange('panel1')}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography sx={{ fontWeight: "600", fontSize: '14px', color: "#15191E" }}>{member_fields.hasMemberProgrammesEditData ? member_fields.hasMemberOutletsEditData ? "Edit Programme of " + member_fields.hasMemberOutletsEditData.name : "Edit Programme" : "Add Programme"}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <form onSubmit={handleSubmit}>
                            <Box sx={{ flexGrow: 1, paddingX: 2 }} >
                                <Grid container columnSpacing={2} sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 4 }}>
                                        <Item>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={6} className="formLabelCntr">
                                                    <Item sx={{ paddingRight: '0' }}>
                                                        <FormLabel htmlFor="member_programme" className="fontSemiBold mandatory">Select Programme</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Item sx={{ paddingLeft: '15px', paddingRight: '0' }}>

                                                        <Autocomplete
                                                            // value={member_fields.member_programme.programme_selected || (member_fields.hasMemberProgrammesEditData ? member_fields.hasMemberProgrammesEditData.programme : null)}
                                                            value={member_fields.member_programme.programme_selected}
                                                            id="member_programme_search"
                                                            options={member_fields.member_programme.programme_options}
                                                            getOptionLabel={(option) => option.name || ""}
                                                            componentsProps={{
                                                                paper: {
                                                                    sx: {
                                                                        fontSize: '0.85rem'
                                                                    }
                                                                }
                                                            }}
                                                            onChange={(event, newValue) => handleSelection(event, newValue, 'member_programme', 'programme_selected')}
                                                            renderInput={(params) =>
                                                                <FormControl size={'small'} fullWidth>
                                                                    <div className="input-group">
                                                                        <TextField {...params}
                                                                            size="small"
                                                                            id="member_programme"
                                                                            aria-describedby="member_programme-error"
                                                                            error={member_fields.isError.programme_selected}
                                                                        />
                                                                        {member_fields.isError.programme_selected &&
                                                                            (<FormHelperText className='errorHelperTxt' id="member_programme-error"> Please select a Programme </FormHelperText>)}
                                                                    </div>
                                                                </FormControl>
                                                            }

                                                        />
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 4 }}>
                                        <Item>

                                        </Item>
                                    </Grid>
                                </Grid>
                                <Grid container columnSpacing={2} sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Item>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={6} sx={{ marginBottom: 3 }}>
                                                    <Item>
                                                        <Grid container alignItems="center">
                                                            <Grid item xs={12} md={6} className="formLabelCntr">
                                                                <Item>
                                                                    <FormLabel htmlFor="member_programme_jd" className="fontSemiBold mandatory">Join Date</FormLabel>
                                                                </Item>
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <Item>
                                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                        <DesktopDatePicker
                                                                            // disabled={member_fields.edit_member_programme !== null ? true : false}+
                                                                            disabled={member_fields.hasMemberProgrammesEditData !== null ? true : false}
                                                                            value={member_fields.member_programme_jd}
                                                                            onChange={(e) => { handleJoinDate(e) }}
                                                                            name="member_programme_jd"
                                                                            inputFormat="DD/MM/YYYY"
                                                                            // eslint-disable-next-line max-len
                                                                            renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                                <Box component="div" className='date-picker-input'>
                                                                                    <FormControl size={'small'} fullWidth>
                                                                                        <TextField size="small" id="member_programme_jd"
                                                                                            ref={inputRef} {...inputProps}
                                                                                            placeholder=""
                                                                                            aria-describedby="member_programme_jd_error"
                                                                                            error={member_fields.isError.member_programme_jd}
                                                                                            autoComplete="off"

                                                                                        />
                                                                                        {member_fields.isError.member_programme_jd && (<FormHelperText className='errorHelperTxt' id="member_programme_jd_error">Join date is missing</FormHelperText>)}
                                                                                        <Box className='date-picker-icon'>
                                                                                            {InputProps?.endAdornment}
                                                                                        </Box>
                                                                                    </FormControl>
                                                                                </Box>
                                                                            )}
                                                                        />
                                                                    </LocalizationProvider>
                                                                </Item>
                                                            </Grid>
                                                        </Grid>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={6} sx={{ marginBottom: 3 }}>
                                                    <Item>
                                                        <Grid container alignItems="center">
                                                            <Grid item xs={12} md={2} className="formLabelCntr">
                                                                <Item>
                                                                    <FormLabel htmlFor="member_programme_ld" className="fontSemiBold">Leave Date</FormLabel>
                                                                </Item>
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <Item>
                                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                        <DesktopDatePicker
                                                                            value={member_fields.member_programme_ld}
                                                                            onChange={(e) => { handleLeaveDate(e) }}
                                                                            name="member_programme_ld"
                                                                            inputFormat="DD/MM/YYYY"
                                                                            minDate={moment(member_fields.member_programme_jd, 'DD/MM/YYYY').add(1, 'days')}
                                                                            // eslint-disable-next-line max-len
                                                                            renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                                <Box component="div" className='date-picker-input'>
                                                                                    <FormControl size={'small'} fullWidth>
                                                                                        <TextField size="small" id="member_programme_ld"
                                                                                            ref={inputRef} {...inputProps}
                                                                                            placeholder=""
                                                                                            aria-describedby="member_programme_ld_error"
                                                                                            autoComplete="off"
                                                                                        />
                                                                                        <Box className='date-picker-icon'>
                                                                                            {InputProps?.endAdornment}
                                                                                        </Box>
                                                                                    </FormControl>
                                                                                </Box>
                                                                            )}
                                                                        />
                                                                    </LocalizationProvider>
                                                                </Item>
                                                            </Grid>
                                                        </Grid>

                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{ marginTop: '20px' }}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Item>
                                            <Grid container direction="row" alignItems="center" justifyContent="center">
                                                <LoadingButton
                                                    loading={state.loader}
                                                    loadingPosition="center"
                                                    endIcon=""
                                                    variant="contained"
                                                    type="submit" sx={{ marginRight: '15px' }}
                                                    disabled={state.formValid}
                                                // disabled={state.formValid || state.defaultmode === false}

                                                >{member_fields.editMemberProgrammesTab && member_fields.member_programmeid !== 0 ? "Update" : "Save"}</LoadingButton>
                                                <Button variant="outlined" onClick={handleCancel}>Go Back</Button>
                                                <Button variant="outlined" sx={{ marginLeft: '15px' }} onClick={handleDashboard}>Got to Dashboard</Button>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box>
                            {snackBar.show && <SnackBarDialog
                                duration={snackBar.duration}
                                vpositon={snackBar.vertical}
                                hposition={snackBar.horizontal}
                                onShow={snackBar.show}
                                text={snackBar.text}
                                type={snackBar.type}
                                onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                            />}
                        </form>
                    </AccordionDetails>
                </Accordion>

            </Container>
        </>
    );

}

export default MemberProgrammes;