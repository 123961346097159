import { DataTable } from "../../components/shared/data-table/DataTable";
import { Container, Box, Modal, Button, Grid, Checkbox } from '@mui/material'
import { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { } from '@mui/material';
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange } from "../../store/slices/externalFilter";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import { warningModal, handleAddEditVat } from "../../store/slices/modals";
import SnackBarDialog from "../../components/shared/SnackBarDialog";
import PageLoader from "../../components/shared/PageLoader";
import WarningModal from "../../components/shared/modals/WarningModal";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { setRowPage, resetDataGrid, setPageNo } from '../../store/slices/dataGrid'
import { useLayoutEffect } from "react";
import axios_utilities from '../../services/utility/axios-utilities';
import { DataTableUtilitiesService } from "../../components/shared/data-table/DataTableUtilitiesService";
import { DataTableCBS } from "../../components/shared/data-table/DataTableCBS";
import { cbsvatcodeSearch, hasEdit, setHasEditData, cbsProductInput, setcbsList, setDeleteList, setcbsDeleteData } from "../../store/slices/cbsProduct";
import AddEditCbs from "./addEditCbs/AddEditCbs";

const IconMenuLink = ({ params, onDelete, startLoading }) => {
    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { showAddEditModal, cbs_container } = useSelector((state) => {
        return {
            showAddEditModal: state.modalActions.hideAddEditModal,
            editTabData: state.cbsProducts.editTabData,
            cbs_container: state.cbsProducts
        }
    });
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'edit') {
            setEditGeneralData(row);
        } else if (action === 'delete') {
            if(onDelete){
                onDelete(row)
            }
        }
        setAnchorEl(null);
    };

    const setEditGeneralData = async (row) => {
        try {
            if(startLoading){
                startLoading(true)
            }
            const { data } = await axios_utilities.get(`centralBillingScheme/${row.id}`);
             if (data) {
                if(startLoading){
                    startLoading(false)
                }
                dispatch(handleAddEditVat({
                    showAddEdit: !showAddEditModal
                }))
                dispatch(hasEdit({ editTab: false }))
                dispatch(setHasEditData(data.resultMap.result))
                dispatch(cbsProductInput({
                    key: 'scheme_name',
                    value: data.resultMap.result.name
                }))
                dispatch(cbsProductInput({
                    key: 'notification_email',
                    value: data.resultMap.result.notificationEmail
                }))
                dispatch(cbsProductInput({
                    key: 'inv_bal_tol',
                    value: data.resultMap.result.invoiceBalancingTolerance
                }))
                dispatch(cbsProductInput({
                    key: 'bal_vat_invoices',
                    value: data.resultMap.result.invoiceVatSummaryBalancing
                }))
                dispatch(cbsProductInput({
                    key: 'print_invoices',
                    value: data.resultMap.result.printInvoices
                }))
                dispatch(cbsProductInput({
                    key: 'pricing_tolerance',
                    value: data.resultMap.result.tolerance
                }))
                if(data.resultMap.result.invalidProductDTO) {
                    dispatch(cbsProductInput({
                        key: 'cbs_invalid_product',
                        value: data.resultMap.result.invalidProductDTO
                    }))
                }
                if(data.resultMap.result.discountProductDTO) {
                    dispatch(cbsProductInput({
                        key: 'cbs_discount_product',
                        value: data.resultMap.result.discountProductDTO
                    }))
                }
                dispatch(cbsvatcodeSearch({
                    parent: 'cbs_vat_code',
                    key: 'cbs_vat_code_selected',
                    value: data.resultMap.result.vatCodeDTO
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_system',
                    value: data.resultMap.result.memberCollectionSystem
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_department',
                    value: data.resultMap.result.memberCollectionDepartment
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_cost_centre',
                    value: data.resultMap.result.memberCollectionCostCentre
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_ledger',
                    value: data.resultMap.result.memberCollectionLedger
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_account',
                    value: data.resultMap.result.memberCollectionAccount
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_reto_System',
                    value: data.resultMap.result.payMemberRetroSystem
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_reto_department',
                    value: data.resultMap.result.payMemberRetroDepartment
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_reto_cost_centre',
                    value: data.resultMap.result.payMemberRetroCostCentre
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_reto_ledger',
                    value: data.resultMap.result.payMemberRetroLedger
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_reto_account',
                    value: data.resultMap.result.payMemberRetroAccount
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_invoice_system',
                    value: data.resultMap.result.memberInvoiceDepartment
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_invoice_cost_centre',
                    value: data.resultMap.result.memberInvoiceCostCentre
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_invoice_ledger',
                    value: data.resultMap.result.memberInvoiceLedger
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_invoice_account',
                    value: data.resultMap.result.memberInvoiceAccount
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_invoice_department',
                    value: data.resultMap.result.memberInvoiceDepartment
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_pay_supplier_system',
                    value: data.resultMap.result.paySupplierSystem
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_pay_supplier_department',
                    value: data.resultMap.result.paySupplierDepartment
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_pay_supplier_cost_centre',
                    value: data.resultMap.result.paySupplierCostCentre
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_pay_supplier_ledger',
                    value: data.resultMap.result.paySupplierLedger
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_pay_supplier_account',
                    value: data.resultMap.result.paySupplierAccount
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_levy_system',
                    value: data.resultMap.result.billLevySystem
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_levy_department',
                    value: data.resultMap.result.billLevyDepartment
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_levy_cost_centre',
                    value: data.resultMap.result.billLevyCostCentre
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_levy_ledger',
                    value: data.resultMap.result.billLevyLedger
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_levy_account',
                    value: data.resultMap.result.billLevyAccount
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_discounts_System',
                    value: data.resultMap.result.billDiscountSystem
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_discounts_department',
                    value: data.resultMap.result.billDiscountDepartment
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_discounts_cost_centre',
                    value: data.resultMap.result.billDiscountCostCentre
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_discounts_ledger',
                    value: data.resultMap.result.billDiscountLedger
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_discounts_account',
                    value: data.resultMap.result.billDiscountAccount
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_billing_retros_system',
                    value: data.resultMap.result.billRetroSystem
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_billing_retros_department',
                    value: data.resultMap.result.billRetroDepartment
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_billing_retros_cost_centre',
                    value: data.resultMap.result.billRetroCostCentre
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_billing_retros_ledger',
                    value: data.resultMap.result.billRetroLedger
                }))
                dispatch(cbsProductInput({
                    key: 'cbs_billing_retros_account',
                    value: data.resultMap.result.billRetroAccount
                }))
            }
        }
        catch (error) {
            console.log(error)
        }
    }

 
    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'edit')}>Edit</MenuItem>
            <MenuItem onClick={(e) => handleClose(e, 'delete')}>Delete</MenuItem>
        </Menu>
    </>)
}

function Cbs() {
    
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })
    
    const [state, setState] = useState({
        openCbsModal: false,
        pageLoader: false,
        defaultGrid: {
            pagetitle: "Central Billing Schemes",
            cbsActionButton: true,
            hasExport: true,
            addEditButton: true,
            apiUrl: 'centralBillingScheme',
            searchUrl: ''
        }
    });

    const columns = [
        {
            headerName: "ID",
            field: "id",
            flex: 1,
            minWidth: 200,
            hide: true
        },
        {
            headerName: "Name",
            field: "name",
            flex: 1,
            width: 180
        },
        {
            headerName: "Print",
            field: "printInvoices",
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
            align: "center",
            headerAlign: "center"
        },
        {
            headerName: "Balance Vat",
            field: "invoiceVatSummaryBalancing",
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
            align: "center",
            headerAlign: "center"
        },
        {
            headerName: "Action",
            field: "action",
            resizable: false,
            sortable: false,
            width: 65,
            renderCell: (params) => {
                return <IconMenuLink params={params} startLoading={pageLoader} onDelete={deleteCBS} />;
            }
        }
    ];

    const { addProductwarning, cbs_container, setAddEditCbs } = useSelector((state) => {
        return {
            addProductwarning: state.modalActions.closeWaringAddProduct,
            cbs_container: state.cbsProducts,
            setAddEditCbs: state.modalActions.hideAddEditVat
        }
    });

    const dispatch = useDispatch();

    const pageLoader = (loader) => {
        setState({...state, pageLoader: loader})
    }

    const handleFilterInput = (value, id) => {
        //setState({ ...state, [id]: value });
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    };

    const selectedAutoComplete = (value, id) => {
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    }

    // eslint-disable-next-line max-len
    const { cbs_name } = useSelector((state) => {
        return {
            cbs_name: state.externalFilter.cbs_name
        }
    });
    const filterBtn = () => {
       
        dispatch(applyFilter({
            key: 'filterList',
            value: {
                "name": cbs_name
            }
        }))
        dispatch(filterFlagChange(true));
        dispatch(setRowPage({ pageData: 10 }));
        dispatch(setPageNo({ page: 1 }));
    }
    const resetFilter = () => {
        if ((cbs_name) !== '') {
            dispatch(resetExternalFilter())
            dispatch(resetDataGrid());
        }
    }
    const addVatCodesModal = () => {
        dispatch(handleAddEditVat({
            showAddEdit: !setAddEditCbs
        }))
    }
    const hideNewCbsModal = (event) => {
        setState({ ...state, openNewCbsModal: false })
    }

    const deleteCBS = (row) => {
        dispatch(setDeleteList(row))
        dispatch(setcbsDeleteData({
            key: 'cbsStartSingleDelete',
            value: true
        }))
        dispatch(warningModal({
            yesHide: !addProductwarning
        }))
    }

    useEffect(() => {
        if (cbs_container.cbsConfirmSingleDelete) {
            deleteSingleRow()
        }
    }, [cbs_container.cbsConfirmSingleDelete])

    const deleteSingleRow = async () => {
        if (cbs_container.deleteList !== null) {
            try {
                setState({ ...state, pageLoader: true })
                const { data } = await axios_utilities.delete(`centralBillingScheme/${cbs_container.deleteList.id}`);
                if (data.success) {
                    try {
                        const { data } = await axios_utilities.get(`centralBillingScheme`);
                        if (data !== null || data !== undefined) {
                            setState({ ...state, pageLoader: false })
                            setSnackBar({ ...snackBar, show: true, text: "Central Billing Scheme Delete Successfully", type: "success" })
                            dispatch(setcbsList(data.items))
                            dispatch(setDeleteList(null))
                            resetSingleDeleteRowDispatch()
                            setTimeout(() => {
                                window.location.reload();
                            }, 2000);
                        }
                    }
                    catch (error) {
                        const { response: { data, status } } = error;
                        if (status === 500 && data.errorType === "Business") {
                            setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" });
                        } else {
                            setSnackBar({ ...snackBar, show: true, text: "Something went wrong!", type: "error", })
                        }
                        setState({ ...state, pageLoader: false })
                        resetSingleDeleteRowDispatch()
                    }
                }

            } catch (error) {
                const { response: { data, status } } = error;
                if (status === 500 && data.errorType === "Business") {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" });
                } else {
                    setSnackBar({ ...snackBar, show: true, text: "Something went wrong!", type: "error", })
                }
                setState({ ...state, pageLoader: false })
                resetSingleDeleteRowDispatch()
            }
        }
    }

    const resetSingleDeleteRowDispatch = () => {
        dispatch(setcbsDeleteData({
            key: 'cbsStartSingleDelete',
            value: false
        }))
        dispatch(setcbsDeleteData({
            key: 'cbsConfirmSingleDelete',
            value: false
        }))
    }

    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Central Billing Schemes" />
                {/* <Box sx={{ marginBottom: 2.5 }}>
                    <TableFilters
                        filterFields={<>
                            <Box component="div"><InputText onChange={handleFilterInput} maxWidth={50} label="Name" id="cbs_name" size="small" /></Box>
                        </>}
                        filterBtns={
                            <>
                                <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                <Button variant="contained" onClick={filterBtn}>Apply</Button>
                            </>}>
                    </TableFilters>
                </Box> */}
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -2.4 }  }}>
                    {/* <div className='table-actionable'>
                        <Button variant="contained" color="secondary" size='small' startIcon={<SendIcon />}>
                            Export as Excel
                        </Button>
                    </div> */}
                    <div className='table-actionable'>
                        <Button variant="contained" size='small' onClick={(e) => addVatCodesModal(e)} startIcon={<AddIcon />}>New Central Billing Scheme</Button>
                    </div>
                </Box>


            <DataTableCBS defaultGrid={state.defaultGrid} columns={columns}>

                </DataTableCBS>
                {
                    setAddEditCbs && (
                        <AddEditCbs 
                            onShow={setAddEditCbs} size="large_overlay"
                            onHide={(e, reason) => hideNewCbsModal(e, reason)}

                        />
                    )
                }
                {
                    addProductwarning && (
                        <WarningModal size="small_overlay" onShow={addProductwarning} />
                    )
                }
                {snackBar.show && <SnackBarDialog
                    onShow={snackBar.show}
                    text={snackBar.text}
                    type={snackBar.type}
                    onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                />}
            </Container>

        </>
    );
}

export default Cbs;
