import { Checkbox, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl } from '@mui/material'
import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { warningModal, handleAddEditModal } from "../../../store/slices/modals";
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import axios_member from '../../../services/utility/axios-member';
import { hasEdit, hasEditData, organisationTypeInput, setAutocomplete, getIsError, resetorganisationType } from "../../../store/slices/organisationType";
import SnackBarDialog from "../../../components/shared/SnackBarDialog";
import { filterFlagChange, filterResetFlagChange } from '../../../store/slices/externalFilter';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

const defaultValues = {
    loader: false,
    formValid: true,
    defaultmode: false,
    newaggregationgroupData: [],
};

function AddEditOrganisationTypeDetail({ onShow, size = '', onHide }) {
    
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const [state, setState] = useState(defaultValues);
    const [selectedSupplier, setselectedSupplier] = useState(null);
    const [open, setOpen] = useState(onShow);
    const { handleWarning, organisation_type_fields } = useSelector((state) => {
        return {
            handleWarning: state.modalActions.closeWaringAddProduct,
            organisation_type_fields: state.organisationTypes
        }
    });
    
    const dispatch = useDispatch();

    const handleClose = () => {
        //setState({ ...state, defaultmode: false })
        dispatch(handleAddEditModal({
            showAddEdit: false
        }));
        dispatch(resetorganisationType())
    };

    const handleCancel = (event) => {
        setState({ ...state, defaultmode: false })
		dispatch(warningModal({
            yesHide: !handleWarning
        }))
    }

    const handleInputChange = (event) => {
        const { value, name } = event.target;
        dispatch(organisationTypeInput({
            key: name,
            value
        }))
        setState({ ...state, defaultmode: true })
    }

    useEffect(() => {
        if(organisation_type_fields.hasEditData) {
            if (organisation_type_fields.organisation_type_name === null || organisation_type_fields.organisation_type_name === "") {
                dispatch(getIsError({ key: 'organisation_type_name', value: true }))
            } else {
                dispatch(getIsError({ key: 'organisation_type_name', value: false }))
            }
        }
    }, [organisation_type_fields.hasEditData])

    useEffect(() => {
        const { 
            organisation_type_name
        } = organisation_type_fields.isError
        if (!organisation_type_name) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true })
        }


    }, [organisation_type_fields.isError])

    const handleSubmit = async (event) => {
        event.preventDefault();
        let productData = null;
        if (!state.formValid) {
            productData = {
                "name": organisation_type_fields.organisation_type_name
            }
            try {
                setState({ ...state, loader: true })
                const { data, status } = organisation_type_fields.hasEditData ? await axios_member.put(`members/memberEstateGroups/${organisation_type_fields.hasEditData.id}`, { ...productData }) : await axios_member.post(`members/memberEstateGroups`, { ...productData });
                if (data.success === true && data.resultMap) {
                    setSnackBar({...snackBar, show:true, text: `Organisation type ${organisation_type_fields.hasEditData ? 'Edited': 'Added'} Successfully!`, type: "success", })
                    setState({ ...state, loader: false, formValid: true, newaggregationgroupData: data.resultMap})
                    setTimeout(() => {
                        //handleClose()
                        // window.location.reload();
                        dispatch(handleAddEditModal({
                            showAddEdit: false
                        }));
                        dispatch(filterFlagChange(true));
                        dispatch(filterResetFlagChange(false));
                    }, 1500);
                }
            } catch (error) {
                const { response: { data, status } } = error;
                setState({ ...state, loader: false })
                setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error", })
            }
        }
    };

    
    return (
        <>
            { snackBar.show && <SnackBarDialog 
            onShow={snackBar.show} 
            text={snackBar.text} 
            type={snackBar.type} 
            onHide={(e) => {setSnackBar({...snackBar, show: e})}}
            />}

            <Modal
                open={open}
                aria-labelledby="add-edit-price-title"
                aria-describedby="add-edit-price-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Box component="div" className="overlay" id="addEditModal">
                        <div className={`overlay-box ${size ? size : ''}`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-header text-center"> Organisation Type Details
                                        <CancelIcon className="closeBtn" onClick={handleClose} />
                                    </div>
                                    <div className="overlay-content">
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="organisation_type_name" className="fontSemiBold mandatory">Organisation Type</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Box component="div" className=''>
                                                                <FormControl size="small">
                                                                    <TextField
                                                                        inputProps={{ maxLength: 50 }}
                                                                        type="text"
                                                                        size="small"
                                                                        id="organisation_type_name"
                                                                        aria-describedby="organisation_type_name_error"
                                                                        onChange={(e) => handleInputChange(e)}
                                                                        value={organisation_type_fields.organisation_type_name}
                                                                        error={organisation_type_fields.isError.organisation_type_name}
                                                                        min={1}
                                                                        name="organisation_type_name"
                                                                        className="inputNumber"
                                                                        //onBlur={(e) => checkQunique(e, 'isAggregationGroupNameValid?name')}
                                                                    />
                                                                    {organisation_type_fields.isError.organisation_type_name && (<FormHelperText className='errorHelperTxt' id="organisation_type_name_error">This field is mandatory</FormHelperText>)}
                                                                </FormControl>
                                                            </Box>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item> 
                                        </Grid>
                                        <Grid container sx={{ marginTop: '20px' }}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Item>
                                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                                        <LoadingButton
                                                                loading={state.loader}
                                                                loadingPosition="center"
                                                                endIcon=""
                                                                variant="contained"
                                                                type="submit" sx={{ marginRight: '15px' }}
                                                                //disabled={state.formValid || state.defaultmode === false}
                                                                disabled={organisation_type_fields.hasEditData ? organisation_type_fields.isError.organisation_type_name : (state.formValid || state.defaultmode === false)}
                                                            >Save</LoadingButton>
                                                        <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </form>
            </Modal>
        </>
    );
}

export default AddEditOrganisationTypeDetail;