import { Box, Modal, Tabs, Tab, Button, Grid, TextField, FormControl, FormHelperText, FormLabel, IconButton, FormControlLabel, Paper, Checkbox, Autocomplete, Select, MenuItem } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CancelIcon from '@mui/icons-material/Cancel';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { warningModal } from "../../../store/slices/modals";
import SnackBarDialog from "../../../components/shared/SnackBarDialog";
import { styled } from '@mui/material/styles';
import { categoryInput, setAutocomplete, getIsError, setcategoryLevel, setcategoryParent, resetCategory, categorBasicInput } from '../../../store/slices/categoriesData';
import axios from '../../../services/utility/axios';
import axios_categories from '../../../services/utility/axios-categories';
import { handleAddEditModal, handleUserDisputeModal } from '../../../store/slices/modals';
import axiosInvoiceSummaries from '../../../services/utility/axios-invoice-summaries';
import PageLoader from '../../../components/shared/PageLoader';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { filterFlagChange,filterResetFlagChange } from '../../../store/slices/externalFilter';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

var seloptions = [];
let apiURL = ""

export default function UserDisputeModal({ values, onShow, onHide, selectedrow }) {
    const [state, setState] = useState({
        release_type: 1,
        dispute_reason: "",
        loader: false,
        openNewProductModal: false,
        pageLoader: false,
        formValid: true,
        isError_release_type: false,
        isError_dispute_reason: false
    });

    useEffect(() => {

        if (category_fields.editCategory) {
            setState({ ...state, formValid: false });
        }

        if (values === "setuserdispute") {
            apiURL = "invoicesummary/linedisputelog"
            seloptions = [{ "name": "User", "label": "User" }, { "name": "Member", "label": "Member" }];
        }
        else {
            apiURL = "invoicesummary/linedisputelog"
            seloptions = [{ "name": "NotInDispute", "label": "Not In Dispute" }];
        }
    }, []);

    const { handleWarning, category_fields, reprocessIds } = useSelector((state) => {
        return {
            handleWarning: state.modalActions.closeWaringAddProduct,
            category_fields: state.categoriesData,
            reprocessIds: state.invoiceReprocess.reprocessIds
        }
    });

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    });

    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const uname = accounts[0] && accounts[0].name;

    const dispatch = useDispatch();

    const handleInputChange = (event) => {
        const { value, name } = event.target;
        if (value !== "") {
            setState({ ...state, dispute_reason: value, isError_dispute_reason: false });
        }
        else {
            setState({ ...state, isError_dispute_reason: true, dispute_reason: '' });
        }
    }

    const handleSelectChange = (event) => {
        const { value, name } = event.target;
        if (value !== "") {
            setState({ ...state, release_type: value, isError_release_type: false });
        }
        else {
            setState({ ...state, isError_release_type: true });
        }
    }

    const handleCheckboxChange = (event) => {
        const { checked, name } = event.target;
        dispatch(categorBasicInput({
            key: name,
            value: checked
        }))
    };


    const [categorylevel, setCategoryLevel] = useState({
        categorylevel_selected: category_fields.editCategory ? { id: category_fields.editCategoryLevel, name: category_fields.editCategoryLevelName } : null,
        categorylevel_options: [
            { id: 4, name: "Product Family (Great GP)" },
            { id: 3, name: "Product Class (GP)" },
            { id: 2, name: "Product Line (Parent)" },
            { id: 1, name: "Product Type (Category)" },

        ]
    });

    const [categoryparent, setCategoryParent] = useState({
        categoryparent_selected: category_fields.editCategory ? { id: category_fields.category_parent, name: category_fields.category_parentName } : null,
        categoryparent_options: []
    })



    useEffect(() => {

        const fetchMyAPI = async () => {

            if (category_fields.category_level !== 4 && category_fields.category_level !== null) {
                let level = category_fields.category_level;
                axios_categories.get(`/v2/categories/parent-dropdown?level=${level}`)
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            setCategoryParent({ ...categoryparent, categoryparent_options: data });
                            const unique = data.filter((value, index, self) => self.findIndex((m) => m.id === category_fields.category_parent) === index);
                            // dispatch(setAutocomplete({
                            //     parent: 'member_estate_group',
                            //     key: 'estate_group_options',
                            //     value: unique
                            // }));

                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }


        }
        fetchMyAPI();
    }, [category_fields.category_level])


    const handleSelection = (event, newValue, parentname, name) => {
        if (event.target.value !== "" || event.target.value !== null) {
            dispatch(setAutocomplete({
                parent: parentname,
                key: name,
                value: newValue
            }))
        }

    }


    const handleClose = () => {
        // dispatch(resetCategory());
        // dispatch(warningModal({
        //     yesHide: !handleWarning
        // }))
        // setTimeout(() => {
        //     // dispatch(warningModal({
        //     //     yesHide: false
        //     // }))
        //     dispatch(handleUserDisputeModal({
        //         showAddEditUserDispute: false
        //     }));
        // }, 500);
        dispatch(handleUserDisputeModal({
            showAddEditUserDispute: false
        }));
    };

    const handleCancel = (event) => {
        // dispatch(resetCategory());
        // dispatch(warningModal({
        //     yesHide: !handleWarning
        // }))
        dispatch(handleUserDisputeModal({
            showAddEditUserDispute: false
        }));
    }

    useEffect(() => {

        if (!state.isError_release_type && !state.isError_dispute_reason) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true })
        }
    }, [state.isError_release_type, state.isError_dispute_reason]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (state.release_type === "" && state.isError_release_type === false) {
            setState({ ...state, isError_release_type: true })
        } else if (state.dispute_reason === "" && state.isError_dispute_reason === false) {
            setState({ ...state, isError_dispute_reason: true })
        } else {

            let payLoad = null;
            if (!state.formValid) {
                
                // const invoiceIds = reprocessIds.map((ele) => {
                //     return ele.invoiceId
                // })
                payLoad = {
                    "type": values && values === 'setuserdispute' ? 1 : 0,
                    "reason": state.dispute_reason,
                    "user": uname,
                    "invoiceId": reprocessIds.length > 0 ? reprocessIds : [selectedrow.invoiceId]
                }
                try {
                    setState({ ...state, loader: true, pageLoader: true })
                    const { data, status } = await axiosInvoiceSummaries.post(`${apiURL}`, { ...payLoad });
                    if (data.success === true) {
                        if (values === "releaseuserdispute") {
                            setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : `Dispute released successfully!`, type: "success", });
                        }
                        else {
                            setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : `Dispute added successfully!`, type: "success", });
                        }
                        setState({ ...state, loader: false, formValid: true, pageLoader: false });

                        setTimeout(() => {
                            dispatch(warningModal({
                                yesHide: false
                            }))
                            dispatch(handleUserDisputeModal({
                                showAddEditUserDispute: false
                            }));
                            dispatch(filterFlagChange(true));
                            dispatch(filterResetFlagChange(false));
                            dispatch(handleUserDisputeModal({
                                showAddEditUserDispute: false
                            }));
                            // window.location.reload()
                        }, 1500);



                    }

                } catch (error) {
                    const { response: { data, status } } = error;
                    setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error", })
                    setState({ ...state, loader: false, formValid: false })
                }


            }
        }

    }

    return (
        <>
            <Modal
                open={onShow || false}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <Box component="div" className="overlay" id="addEditModal">
                    <div className="overlay-box category-addeditbox" >
                        <div className="content">
                            <div className="overlayBody">
                                <div className="overlay-header text-center"> {values === "releaseuserdispute" ? "Release User Dispute" : "Set User Dispute"}
                                    <CancelIcon className="closeBtn" onClick={handleClose} />
                                </div>
                                <div className="overlay-content">
                                {state.pageLoader && <PageLoader />}
                                    <form onSubmit={handleSubmit}>
                                        <Box sx={{ flexGrow: 1, paddingX: 2 }} >
                                            <Grid container columnSpacing={2} sx={{ paddingLeft: '20px', paddingRight: '20px', display: 'none' }}>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <Item>
                                                        <Grid container alignItems="center">
                                                            <Grid item xs={12} md={12} sm={12} sx={{ marginBottom: 4 }}>
                                                                <Item>
                                                                    <Grid container alignItems="center">
                                                                        <Grid item xs={12} md={2.5} className="formLabelCntr">
                                                                            <Item>
                                                                                <FormLabel htmlFor="release_type" className="fontSemiBold mandatory">Type</FormLabel>
                                                                            </Item>
                                                                        </Grid>
                                                                        <Grid item xs={12} md={9}>
                                                                            <Item>
                                                                                <FormControl fullWidth>
                                                                                    {/* {label && (<FormLabel htmlFor={id}>{label}</FormLabel>)} */}
                                                                                    <Select
                                                                                        size="small"
                                                                                        id="release_type"
                                                                                        defaultValue=""
                                                                                        onChange={(e) => handleSelectChange(e)}
                                                                                        value={state.release_type}
                                                                                        error={state.isError_release_type}
                                                                                        aria-describedby={'error'} >

                                                                                        {seloptions.map((option) => (
                                                                                            <MenuItem value={option.name} key={option.name} sx={{ fontSize: '0.85rem' }}>{option.label}</MenuItem>
                                                                                        ))}

                                                                                    </Select>
                                                                                    {state.isError_release_type && (<FormHelperText className='errorHelperTxt' id="release_type_error">
                                                                                        Please Select Type
                                                                                    </FormHelperText>)}
                                                                                </FormControl>


                                                                            </Item>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Item>
                                                            </Grid>

                                                        </Grid>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                            <Grid container columnSpacing={2} sx={{ paddingLeft: '20px', paddingRight: '20px' }}>
                                                <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: 4 }}>
                                                    <Item>
                                                        <Grid container alignItems="center">
                                                            <Grid item xs={12} md={2.5} className="formLabelCntr">
                                                                <Item>
                                                                    <FormLabel htmlFor="dispute_reason" className="fontSemiBold mandatory">Reason</FormLabel>
                                                                </Item>
                                                            </Grid>
                                                            <Grid item xs={12} md={9}>
                                                                <Item>
                                                                    <div className="input-group">
                                                                        <FormControl fullWidth>
                                                                            <TextField
                                                                                inputProps={{ maxLength: 200 }}
                                                                                size="small"
                                                                                id="dispute_reason"
                                                                                aria-describedby="dispute_reason"
                                                                                onChange={(e) => handleInputChange(e)}
                                                                                value={state.dispute_reason}
                                                                                error={state.isError_dispute_reason}
                                                                                name="dispute_reason"

                                                                            />
                                                                            {state.isError_dispute_reason && (<FormHelperText className='errorHelperTxt' id="dispute_reason_error">
                                                                                Please enter Reason
                                                                            </FormHelperText>)}
                                                                        </FormControl>

                                                                    </div>
                                                                </Item>
                                                            </Grid>
                                                        </Grid>
                                                    </Item>
                                                </Grid>

                                            </Grid>



                                            <Grid container sx={{ marginTop: '20px' }}>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <Item>
                                                        <Grid container alignItems="center">
                                                            <Grid item xs={12} md={1} className="formLabelCntr">
                                                                <Item>

                                                                </Item>
                                                            </Grid>
                                                            <Grid item xs={12} md={11}>
                                                                <Item>
                                                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                                                        <LoadingButton
                                                                            loading={state.loader}
                                                                            loadingPosition="center"
                                                                            endIcon=""
                                                                            variant="contained"
                                                                            type="submit" sx={{ marginRight: '15px' }}
                                                                            disabled={state.formValid}

                                                                        >Save</LoadingButton>
                                                                        <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
                                                                    </Grid>


                                                                </Item>
                                                            </Grid>
                                                        </Grid>

                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        {snackBar.show && <SnackBarDialog
                                            onShow={snackBar.show}
                                            text={snackBar.text}
                                            type={snackBar.type}
                                            onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                                        />}
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}
