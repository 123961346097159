import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from 'react-redux';

function TabPanel(props) {
    const { children, value, index, id, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`${id}-tab-pannel-${index}`}
            aria-labelledby={`${id}-tab-${index}`}
            {...other}
            className="tabs-pannel"
        >
            {value === index && (
                <>{children}</>
            )}
        </div>
    );
}

export default function Tabination({ tabs }) {
    const [value, setValue] = React.useState(0);
    const {
        editTab
    } = useSelector((state) => {
        return {
            editTab: state.generalProduct.editTab
        }
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {

    }, [value])

    return (
        <Box sx={{ maxWidth: { xs: '320', sm: '100%', md: '100%' } }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons={false}
                    aria-label="scrollable prevent tabs example"
                >
                    {tabs.map(({ label }, i) => (
                        <Tab disabled={value !== i && editTab ? true : false} label={label} key={i} sx={{ minWidth: 'auto', paddingX: { xs: '5px', md: '0.85rem' }, marginLeft: '15px', fontWeight: '700', textTransform: 'capitalize' }} />
                    ))}
                </Tabs>
            </Box>
            {tabs.map(({ Component }, i) => (
                <TabPanel value={value} index={i} key={i} id="productTabs">
                    {Component}
                </TabPanel>
            ))}
        </Box>
    );
}
