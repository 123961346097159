import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editTab: true,
  hasEditPdc: null,
  editTabData: null,
  hasEditData: null,
  supplier_report_file_name: '',
  PdcFormValid: false,
  sendEmailInfo: null,
  isError: {
    supplier_report_file_name: true
  }
}

const supplierReports = createSlice({
  name: 'pdc_product',
  initialState,
  reducers: {
    brandSearch: (state, action) => {
      const { parent, key, value } = action.payload;
      state[parent][key] = value;
    },
    supplierReportsInput: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
      state[key].length === 0 ? state.isError[key] = true : state.isError[key] = false
    },
    getIsError: (state, action) => {
      const { key, value } = action.payload;
      state.isError[key] = value
    },
    setAutocomplete: (state, action) => {
      const { parent, key, value } =  action.payload;
      state[parent][key] = value
    },
    hasTabEdit: (state, action) => {
      const { editTab } =  action.payload;
      state.editTab = editTab
    },
    hasTabEditData: (state, action) => {
      const { editData } =  action.payload;
      state.editTabData = editData
    },
    setHasEditData: (state, action) => {
      state.hasEditData = action.payload;
    },
    setsupplierReportsFormStatus: (state, action) => {
      state.PdcFormValid = action.payload;
    },
    setEmailInfo: (state, action) => {
      state.sendEmailInfo = action.payload;
    },
    resetsupplierReports: () => {
      return initialState
    },
  }
})

export const { brandSearch, hasTabEditData, setAutocomplete, getIsError, supplierReportsInput, hasTabEdit, resetsupplierReports, setHasEditData, setsupplierReportsFormStatus, setEmailInfo } = supplierReports.actions

export default supplierReports.reducer