import { Box } from '@mui/material'
import { Divider } from '@mui/material';

export const TableFilters = (props) => {
    
    
    return (
        <>
            {
                props.children
            }

            <Box component="div" className="filter-panel-box">
            <Box component="div" className='filter-fields'>
                    {
                        props.chips && props.chips
                    }
                </Box>
                <Box component="div" className='filter-fields' sx={{ columnGap: 3, display: 'grid', gridTemplateColumns: { sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(6, 1fr)' } }}>
                    {
                        props.filterFields && props.filterFields
                    }
                </Box>
                
                <Divider sx={{ marginY: { xs: 1.5, sm: 0, md: 0 }, borderStyle: 'dashed', borderColor: "#919396", borderWidth: '1px' }} />
                <Box component="div" className='filter-checkboxes' sx={{ display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex' }, justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box component="div" className='filterChkBx' sx={{ columnGap: 3, display: 'flex', alignItems: 'center' }}>
                        {
                            props.filterCheckboxes && props.filterCheckboxes
                        }
                        {/* <Box component="div">
                            <ThreeStateCheckbox checked={state.active_price} onChange={handleChange} name="active_price" id="active-price" label="Active Price" />
                        </Box> */}
                    </Box>
                    <Box component="div" sx={{ columnGap: 2.5, display: 'flex', justifyContent: { xs: 'center', sm: 'end' }, alignItems: 'center' }}>
                        {
                            props.filterBtns && props.filterBtns
                        }
                    </Box>
                </Box>
                {
                    props.processRunning && (
                        <Box component="div" className='filter-checkboxes' sx={{ display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex' }, justifyContent: 'space-between', alignItems: 'center', marginTop: '0.5rem !important' }}>
                            <Box component="div" className='filterChkBx' sx={{ columnGap: 3, display: 'flex', alignItems: 'center' }}>
                                {
                                     props.processRunning && props.processRunning
                                }
                            </Box>

                        </Box>
                    )
                }
            </Box>
        </>
    );
}
