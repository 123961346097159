import { Box, Paper, Grid, Button } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PageLoader from "../../../../components/shared/PageLoader";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLatestlatestEndChargeDate, setChannelChargeInput, setchannel_chargeEffectiveDate, setEditChannelCharge, setchannel_chargeDateList, setNullChargeStartDate, setDeleteChargeList, setSingleDeleteData } from "../../../../store/slices/pricePdcProduct";
import { setPdcPriceModal, warningModal, handleAddEditPdcPriceModal } from "../../../../store/slices/modals";
import { DataTableClientSide } from '../../../../components/shared/data-table/DataTableClientSide';
import AddIcon from '@mui/icons-material/Add';
import AddEditChargeModal from './charges/AddEditChargeModal';
import axiosProductDistribution from '../../../../services/utility/axios-product-distribution';
import SnackBarDialog from '../../../../components/shared/SnackBarDialog';
import moment from 'moment';
import { uniqueId } from 'lodash';

const IconMenuLink = ({ params, onChargeDelete }) => {
    const { row } = params;
    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { showAddEditChargeModal, pdc_general_product_fields, channel_charge_container, showAddEditPriceModal } = useSelector((state) => {
        return {
            showAddEditChargeModal: state.modalActions.hideAddEditPdcModal,
            editTabData: state.pricePdcProducts.editTabData,
            pdc_general_product_fields: state.pricePdcProducts,
            channel_charge_container: state.pricePdcProducts,
            showAddEditPriceModal: state.modalActions.hideAddEditPdcModal
        }
    });
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'edit') {
            editChannelChargeData(row)
        } else if (action === 'delete') {
            if(onChargeDelete){
                onChargeDelete(row)
            }
        }
        setAnchorEl(null);
    };

    // // find latest start date from array
    // const maxDate = new Date(
    //     Math.max(
    //       ...channel_charge_container.channel_charge_date.map(element => {
    //         return new Date(element.startDate);
    //       }),
    //     ),
    //   ); 
    // var  datelast = moment(maxDate).format('DD/MM/YYYY');
    //   // Find a value in an array of object (start date) 
    // let obj = channel_charge_container.channel_charge_date.find(o => o.startDate === datelast);
    // let latestId = obj.id;

    // let latestId = '';
    // let obj = channel_charge_container.channel_charge_date.find(o => o.endDate === null);
    // if(obj !== undefined) {
    //     latestId = obj.id;
    // } else {
    //     latestId = '';
    // }

    //let curDate = moment().format('DD/MM/YYYY');
    //let checkinBetweendates = moment(curDate).isBetween(row.startDate, row.endDate);

    const editChannelChargeData = (row) => {
        dispatch(setPdcPriceModal(true))
        dispatch(setChannelChargeInput(Number(row.charge).toFixed(2)))
        dispatch(setEditChannelCharge(row))
        //dispatch(setLatestPdcData(''))
        dispatch(setchannel_chargeEffectiveDate({
            key: 'channel_charge_start_date',
            value: row.startDate ? moment(row.startDate,'DD/MM/YYYY') : "" 
        }))
        dispatch(setchannel_chargeEffectiveDate({
            key: 'channel_charge_end_date',
            value: row.endDate ? moment(row.endDate,'DD/MM/YYYY') : ""
        }))
    }

    return (<>
            <IconButton
                aria-label="more"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                id={row.id + 1}
            >
                <MoreVertIcon />
            </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'edit')}>Edit Charges</MenuItem>
            <MenuItem onClick={(e) => handleClose(e, 'delete')}>Delete Charges</MenuItem>
        </Menu>
        
    </>)
}

const defaultValues = {
    charge: '',
    defaultGrid: {
        pagehead: 'PDC',
        eanActionButtons: true,
        paginationMode: 'client',
    },
    addSupplierProductCode: false
};
export default function PdcCharge({ onShow }) {
    
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })
    const [state, setState] = useState(defaultValues);
    const [pageLoader, setPageLoader] = useState(false);
    const [columns, setColumns] = useState([]);

    const allColumns = [
        {
            headerName: "Id",
            field: "id",
            flex: 1,
            hide: true
        },
        {
            headerName: "Charge",
            field: "charge",
            flex: 1,
        },
        {
            headerName: "Start Date",
            field: "startDate",
            flex: 1,
        },
        {
            headerName: "End Date",
            field: "endDate",
            flex: 1,
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} onChargeDelete={deleteChannelCharge} />;
            },
        } 
    ];
    const { getPdcCharge, channel_charge_container, showChannelChargeModal,editTabData,hasEditData, editChannelCharge, handleWarning, showAddEditPriceModal } = useSelector((state) => {
        return {
            showChannelChargeModal: state.modalActions.pdcPriceModal,
            channel_charge_container: state.pricePdcProducts,
            getPdcCharge: state.pricePdcProducts.channel_charge_date,
            editTabData: state.pricePdcProducts.editTabData,
            editChannelCharge: state.pricePdcProducts.editChannelCharge,
            hasEditData: state.pricePdcProducts.hasEditData,
            handleWarning: state.modalActions.closeWaringAddProduct,
            showAddEditPriceModal: state.modalActions.hideAddEditPdcModal
        }
        
    });
   
    const dispatch = useDispatch();
    const addChargeModal = (event) => {
        dispatch(setPdcPriceModal(true))
        // let latestendDate = '';
        // let nullSetStartDate = '';
        //  nullSetStartDate = channel_charge_container.channel_charge_date.find(o => o.endDate === null) ? channel_charge_container.channel_charge_date.find(o => o.endDate === null) : null;
        //  if(nullSetStartDate !== null) {
        //      let lateststartDate = '';
        //      lateststartDate = moment(nullSetStartDate.startDate, 'DD/MM/YYYY').add(2, 'days');
        //     dispatch(setNullChargeStartDate(lateststartDate))
        //     dispatch(setLatestlatestEndChargeDate(''))
        //     dispatch(setchannel_chargeEffectiveDate({
        //         key: 'channel_charge_start_date',
        //         value: lateststartDate
        //     }))
        // } else {
        //     let keys = Object.keys(channel_charge_container.channel_charge_date);
        //     let last = keys[keys.length - 1];
        //     let latest_entry = channel_charge_container.channel_charge_date[last];
        //      latestendDate = moment(latest_entry.endDate, 'DD/MM/YYYY').add(1, 'days');
        //      dispatch(setLatestlatestEndChargeDate(latestendDate))
        //      dispatch(setNullChargeStartDate(''))
        //      dispatch(setchannel_chargeEffectiveDate({
        //         key: 'channel_charge_start_date',
        //         value: latestendDate
        //     }))
        // }
        // if ((channel_charge_container.channel_charge_date.length > 0)) {  
        //     if (channel_charge_container.channel_charge_date[0].endDate === "" || channel_charge_container.channel_charge_date[0].endDate === null || channel_charge_container.channel_charge_date[0].endDate === undefined) {
        //         dispatch(setchannel_chargeEffectiveDate({
        //             key: 'default_channel_charge_start_date',
        //             value: moment(channel_charge_container.channel_charge_date[0].startDate,'DD/MM/YYYY').add(2,'days')
        //         }))
        //     } else {
        //         let keys = Object.keys(channel_charge_container.channel_charge_date);
        //         let last = keys[keys.length - 1];
        //         let latest_entry = channel_charge_container.channel_charge_date[last];
        //         dispatch(setchannel_chargeEffectiveDate({
        //             key: 'default_channel_charge_start_date',
        //             value: moment(latest_entry.endDate, 'DD/MM/YYYY').add(1, 'days')
        //         }))
        //     }
        // }
    }
    const hideAddEditChargeModal = (event) => {
        dispatch(setPdcPriceModal(event))
    }

    const allChargelists = async () => {
        try { 
            setPageLoader(true)
            setTimeout(() => {
                setColumns(allColumns)
            }, 100);
            const id = editTabData ? editTabData.id : hasEditData.id;
            const { data } = await axiosProductDistribution.get(`pdcs/${id}/pdcChannelCharges`);
            if (data) {
                setPageLoader(false)
                dispatch(setchannel_chargeDateList(data.items))
            }
        } catch (error) {
            setPageLoader(false)
            const { response: { data, status } } = error;
            if (data.message !== "No Product Distribution Channel Charges found") {
                setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: data.message ? "warning" : "error" })
            }
        }
    }
    useEffect(() => {
        allChargelists();
    }, []);

    
    const deleteChannelCharge = (row) => {
        dispatch(setDeleteChargeList(row))
        dispatch(setSingleDeleteData({
            key: 'pdcChargestartSingleDelete',
            value: true
        }))
        dispatch(handleAddEditPdcPriceModal({
            showAddEditPrice: true
        }))
        dispatch(warningModal({
            yesHide: !handleWarning
        }))
    }

    useEffect(() => {
        if (channel_charge_container.pdcChargeconfirmSingleDelete) {
            deletePdcChargeSingleRow()
        }
    }, [channel_charge_container.pdcChargeconfirmSingleDelete])
    
    const deletePdcChargeSingleRow = async () => {
        if (channel_charge_container.deleteChargeList !== null) {
            const id = editTabData ? editTabData.id : hasEditData.id;
            try {
                setPageLoader(true)
                const { data } = await axiosProductDistribution.delete(`pdcs/${id}/pdcChannelCharges/${channel_charge_container.deleteChargeList.id}`);
                if (data.success) {
                    // try {
                    //     const { data } = await axiosProductDistribution.get(`pdcs/${id}/pdcChannelCharges`);
                    //     if (data !== null || data !== undefined) {
                    //         setSnackBar({ ...snackBar, show: true, text: "Channel charge Delete Successfully!", type: "success" })
                    //         dispatch(setchannel_chargeDateList(data.items))
                    //         pdcsingleDeleteRowDispatch()
                    //     }
                    // }
                    // catch (error) {
                    //     setSnackBar({ ...snackBar, show: true, text: "Something went wrong!", type: "error", })
                    //     pdcsingleDeleteRowDispatch()
                    // }
                    if (data.success) {
                        setPageLoader(false)
                        setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
                        const filteredRows = getPdcCharge.filter(uniqueRow => {
                            if (uniqueRow.id !== channel_charge_container.deleteChargeList.id) {
                                return uniqueRow;
                            }
                        })
                        dispatch(setchannel_chargeDateList(filteredRows))
                        dispatch(setDeleteChargeList(null))
                        pdcsingleDeleteRowDispatch()
                        setState({ ...state, loader: false })
                    }
                }

            } catch (error) {
                setPageLoader(false)
                const { response: { data, status } } = error;
                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                }
                dispatch(setDeleteChargeList(null))
                pdcsingleDeleteRowDispatch()
            }
        }
    }

    const pdcsingleDeleteRowDispatch = () => {
        dispatch(setSingleDeleteData({
            key: 'pdcChargestartSingleDelete',
            value: false
        }))
        dispatch(setSingleDeleteData({
            key: 'pdcChargeconfirmSingleDelete',
            value: false
        }))
    }


    return (
        <>
            {pageLoader && <PageLoader />}
            <Box sx={{ flexGrow: 1, paddingX: 2 }} >
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5 } }}>
                    <div className='table-actionable'>
                        <Button variant="contained" onClick={(e) => addChargeModal(e)} startIcon={<AddIcon />}>
                            Add New
                        </Button>
                    </div>
                </Box>
                <DataTableClientSide
                    columns={columns ? columns : []}
                    defaultGrid={state.defaultGrid}
                    rows={getPdcCharge ? getPdcCharge : []}
                >
                </DataTableClientSide>
                {showChannelChargeModal && (
                    <>
                        <AddEditChargeModal size="medium_overlay"
                            onShow={showChannelChargeModal}
                            onHide={(e, reason) => hideAddEditChargeModal(e, reason)}
                        />
                    </>
                )}
            </Box>
            {snackBar.show && <SnackBarDialog
            onShow={snackBar.show}
            text={snackBar.text}
            type={snackBar.type}
            onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
        />}
        </>
    );
}
