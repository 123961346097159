import { Box, Modal, Tabs, Tab, Button } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import Tabination from "../../../components/shared/tabs/Tabination";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { warningModal, handleAddEditModal } from "../../../store/slices/modals";
import { resetProduct } from "../../../store/slices/generalProduct";
import { filterFlagChange, filterResetFlagChange } from '../../../store/slices/externalFilter';

export default function AddEditProduct({ tabs, onShow, onHide }) {

    const { handleWarning } = useSelector((state) => {
        return {
            handleWarning: state.modalActions.closeWaringAddProduct,
        }
    });
    const dispatch = useDispatch();
    
    const handleClose = () => {
        // dispatch(warningModal({
        //     yesHide: !handleWarning
        // }))
        // console.log("Handle Close");
        dispatch(resetProduct());
        setTimeout(() => {
            // window.location.reload();
            dispatch(handleAddEditModal({
                showAddEdit: false
            }));
            dispatch(filterFlagChange(true));
            dispatch(filterResetFlagChange(false));
        }, 500);   
    };

    return (
        <>
            <Modal
                open={onShow || false}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
                
            >
                <Box component="div" className="overlay" id="addEditModal">
                    <div className="overlay-box large-size">
                        <div className="content">
                            <div className="overlayBody">
                                <div className="overlay-header text-center"> Product Details
                                    <CancelIcon className="closeBtn" onClick={handleClose} />
                                </div>
                                <div className="overlay-content">
                                    {tabs && <Tabination tabs={tabs} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}
