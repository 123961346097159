import { Checkbox, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl, RadioGroup, Radio, Autocomplete } from '@mui/material'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import axios_levy_scheme from '../../../../services/utility/axios-levy-scheme';
import PageLoader from '../../../../components/shared/PageLoader';
import { setLevySchemeState } from '../../../../store/slices/levyScheme';
import { handleModalState, setNewPriceModal } from '../../../../store/slices/modals';
import CommonPopUp from '../../../products/addEditPdc/pdc-tabs/CommonPopUp';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import { setCommonInput } from "../../../../store/slices/commonProduct";

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

function AddEditLevyProduct({ onShow, size = '', onHide, onSubmitDate }) {
    
    const { levyScheme_container, searchProductModal } = useSelector((state) => {
        return {
            levyScheme_container: state.levySchemes,
            searchProductModal: state.commonProducts,
        }
    });
    const dispatch = useDispatch();
    
    const [state, setState] = useState({
        LevyProductListModal: false,
        pageLoader: false,
        defaultmode: false,
        formValid: true,
        ProductDetail: levyScheme_container.hasEditLevyProduct ? levyScheme_container.hasEditLevyProduct.product : null
    })

    const [included, setIncluded] = useState(true);

    const [product, setproduct] = useState({
        product_options: [],
        ProductDetail: levyScheme_container.hasEditLevyProduct ? levyScheme_container.hasEditLevyProduct.product : null
    })
    const [isError, setIsError] = useState({
        ProductDetail: false
    })

    const handleChange = (event) => {
        if(levyScheme_container.hasEditLevyProduct) {
            setState({ ...state, defaultmode: true })
        }
        setIncluded(event.target.checked);
    };

    const [open, setOpen] = useState(onShow);

    const handleClose = () => {
        //setState({ ...state, defaultmode: false })
        dispatch(handleModalState({
            key: 'addEditLevyProductModal',
            value: false
        }))
        dispatch(setLevySchemeState({
            key: 'hasEditLevyProduct',
            value: null
        }))
        dispatch(setCommonInput(''));
    };

    const handleSelf = () => {
        handleClose()
    }

    useEffect(() => {
        if (searchProductModal.query === null || searchProductModal.query === "") {
            setState({ ...state, formValid: true, defaultmode: false})
        } else {
            setState({ ...state, formValid: false, defaultmode: true})
        }
    }, [searchProductModal.query])

    useEffect(() => {
        const {
            discountScheme_product_name
        } = levyScheme_container.isError
        if (!discountScheme_product_name) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true })
        }
    }, [levyScheme_container.isError])

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (state.ProductDetail === null && isError.ProductDetail === false) {
            setIsError({ ...isError, ProductDetail: true })
        } else {
            addPayDate()

        }

    }

    const addPayDate = async () => {
        let payload = null;
        if ((levyScheme_container.hasEditData !== null || levyScheme_container.editTabData !== null) && levyScheme_container.hasEditLevyProduct === null) {
            payload = {
                "productId": state ? state.ProductDetail.id : "",
                included,
                "productName": state.ProductDetail ? state.ProductDetail.name : "",
            }
        } else if (levyScheme_container.hasEditLevyProduct !== null) {
            payload = {
                "id": levyScheme_container.hasEditLevyProduct.id,
                "productId": state.ProductDetail ? state.ProductDetail.id : levyScheme_container.hasEditLevyProduct.productId,
                included,
                "productName": state.ProductDetail ? state.ProductDetail.name : levyScheme_container.hasEditLevyProduct.productName,
            }
        }

        try {
            setState({ ...state, pageLoader: true })
            const { data } =  ((levyScheme_container.hasEditData !== null || levyScheme_container.editTabData !== null) && levyScheme_container.hasEditLevyProduct === null) ? await axios_levy_scheme.post(`/levySchemes/${levyScheme_container.hasEditData.id || levyScheme_container.editTabData.id}/products`, payload) : await axios_levy_scheme.put(`/levySchemes/${levyScheme_container.hasEditData.id || levyScheme_container.editTabData.id}/products/${levyScheme_container.hasEditLevyProduct.id}`, payload)

            if (data) {
                onSubmitDate({ text: data.message, type: "success" })
                setState({ ...state, pageLoader: false })
                handleClose()
            }

        } catch (error) {
            const { response: { data, status } } = error;
            if (!data) {
                onSubmitDate({ text: error.message, type: "error" })
            } else {
                onSubmitDate({ text: data.message, type: "error" })
            }
            setState({ ...state, pageLoader: false })
            handleClose()
        }
    }

    useEffect(() => {
        if (levyScheme_container.hasEditLevyProduct !== null) {
            dispatch(setCommonInput(levyScheme_container.hasEditLevyProduct.product))
            setIncluded(levyScheme_container.hasEditLevyProduct.included)
            setState({...state, ProductDetail: levyScheme_container.hasEditLevyProduct.product})
        }
    }, [])


    const hideSupplierproductModal = (params) => {
        setState({ ...state, LevyProductListModal: params === false ? params : true })
    }
    // const resetFilter = () => {
    //     dispatch(setCommonInput(''));
    //     dispatch(setLevySchemeState({
    //         key: 'hasEditLevyProduct',
    //         value: null
    //     }))
    // }
    const CommonPopUpModal = () => {
        setState({...state, LevyProductListModal: true})
    }
    const getProductDetail = (product) => {  
        if (product !== '') {
            setState({...state, defaultmode: true, ProductDetail: product, LevyProductListModal: false})
        } 
    }
    return (
        <>


            <Modal
                open={open}
                aria-labelledby="add-edit-price-title"
                aria-describedby="add-edit-price-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Box component="div" className="overlay" id="addEditModal">
                        {state.pageLoader && <PageLoader />}
                        <div className={`overlay-box ${size ? size : ''}`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-header text-center"> Product
                                        <CancelIcon className="closeBtn" onClick={handleClose} />
                                    </div>
                                    <div className="overlay-content">
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            <FormLabel htmlFor="type" className="fontSemiBold mandatory">Product</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Grid container alignItems="center">
                                                                <Grid item xs={12} md={8}>
                                                                    <Item>
                                                                        <Box component="div" className=''>
                                                                            <FormControl size={'small'} fullWidth>
                                                                                <TextField
                                                                                    inputProps={{ maxLength: 100 }}
                                                                                    size="small"
                                                                                    id="discountScheme_product_name"
                                                                                    value={(searchProductModal.query ? searchProductModal.query.name : '') || (levyScheme_container.hasEditLevyProduct ? levyScheme_container.hasEditLevyProduct.productName : '')}
                                                                                    aria-describedby="discountScheme_product_name_error"
                                                                                    error={levyScheme_container.isError.discountScheme_product_name_error}
                                                                                    name="discountScheme_product_name"
                                                                                    disabled={true}
                                                                                />
                                                                            </FormControl>
                                                                        </Box>
                                                                    </Item>
                                                                </Grid>
                                                                <Grid item xs={12} md={4}>
                                                                    <Item>
                                                                        {/* <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0, marginRight: '5px' }} onClick={resetFilter} startIcon={<ClearIcon />}></Button> */}
                                                                        <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={(e) => CommonPopUpModal(e)} startIcon={<EditIcon />}></Button>
                                                                    </Item>
                                                                </Grid>
                                                            </Grid>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            <FormLabel htmlFor="included" className="fontSemiBold">Include/Exclude</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <div className="input-group">
                                                                <Checkbox
                                                                    checked={included}
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                />
                                                            </div>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            &nbsp;
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Grid container direction="row" alignItems="center">
                                                                <Button 
                                                                    variant="contained" 
                                                                    type="submit" 
                                                                    sx={{ marginRight: '15px' }} 
                                                                    //disabled={((levyScheme_container.hasEditLevyProduct && levyScheme_container.hasEditLevyProduct.confirmed === true) ? true : false) || state.defaultmode === false}
                                                                    // eslint-disable-next-line max-len
                                                                    disabled={((levyScheme_container.hasEditData !== null || levyScheme_container.editTabData !== null) && levyScheme_container.hasEditLevyProduct) ? levyScheme_container.isError.discountScheme_product_name : (state.formValid || state.defaultmode === false)}
                                                                >Save</Button>
                                                                <Button variant="outlined" onClick={handleSelf}>Cancel</Button>
                                                            </Grid>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            state.LevyProductListModal && <CommonPopUp
                                onShow={state.LevyProductListModal}
                                size="large_overlay"
                                multipleFilter={true}
                                onHide={hideSupplierproductModal}
                                memberaccount={getProductDetail}
                            />
                        }
                    </Box>
                </form>

            </Modal>
        </>
    );
}

export default AddEditLevyProduct;