/* eslint-disable max-len */
import * as React from 'react';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import {
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
    gridPaginationRowRangeSelector
} from '@mui/x-data-grid';

import { Box, Button, Stack } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import { Pagination, PaginationItem, Select, FormControl, MenuItem } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import axios from '../../../services/utility/axios';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { setRowPage, resetDataGrid } from '../../../store/slices/dataGrid'




export const DataTableClientSide = (props) => {

    
    const apiRef = useGridApiRef()
    const { addPriceModal, columns, defaultGrid, rows } = props;

    const [state, setState] = useState({
        isLoading: false,
        data: rows && rows.length > 0 ? rows : [],
        total: defaultGrid.pageCountSize ? 1 : 1,
        page: defaultGrid.pageCountSize ? 1 : 1,
        pageSize: (defaultGrid.pagehead === 'PDC') ? 20 : (defaultGrid.pagehead === 'pdcfileupload' || defaultGrid.pagehead === 'productfileupload') ? 300 : 10,
        columns
    });

    // const { rowPerPage, resetTable } = useSelector((state) => {
    //     return {
    //         rowPerPage: state.dataGridTable.pageData,
    //         resetTable: state.dataGridTable
    //     }
    // });

    const dispatch = useDispatch();
    
    function CustomPagination() {

        // const { rowPerPage } = useSelector((state) => {
        //     return {
        //         rowPerPage: state.dataGridTable.pageData
        //     }
        // });
    
        //const dispatch = useDispatch();
        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    
        const rowDataList = [10, 25, 50, 75, 100, 200, 300];
    
        const handleChange = (event) => {
            //dispatch(setRowPage({ pageData: event.target.value }));
            setState({ ...state, pageSize: event.target.value, page: 1 });
            apiRef.current.setPage(0);
        };
    
    
        return (
            <>
                <div className='grid-pagiation'>
                    {defaultGrid && defaultGrid.pageCountSize ? <Box className='customRowPerPageBox' component="div">
                        <div>
                            View
                        </div>
                        <Box component="div" sx={{ marginX: '10px' }}>
                            <FormControl>
                                <Select
                                    size="small"
                                    id="row-per-page"
                                    defaultValue="choose"
                                    onChange={handleChange}
                                    value={state.pageSize}
                                    aria-describedby={'row-per-page'} >
                                    {rowDataList.map((value) => {
                                        return (
                                            <MenuItem value={value} key={value}>{value}</MenuItem>
                                        )
                                    }
    
                                    )}
                                </Select>
    
                            </FormControl>
                        </Box>
                        <div>
                            rows per page
                        </div>
                    </Box> : <Box className='customRowPerPageBox' component="div"></Box> }
                    <Pagination
                        variant="outlined"
                        shape="rounded"
                        count={pageCount}
                        page={page + 1}
                        onChange={(event, value) => { setState({...state, page: value }); return apiRef.current.setPage(value - 1); }}
                        renderItem={(item) => (
                            <PaginationItem
                                components={{ previous: KeyboardArrowLeftIcon, next: KeyboardArrowRightIcon }}
                                {...item}
                            />
                        )}
                    />
                </div>
            </>
        );
    }

    useEffect(() => {
        apiRef.current.updateRows(rows);
        if(defaultGrid && defaultGrid.pageCountSize) {
            setState({ ...state, pageSize: (defaultGrid.pagehead === 'pdcfileupload' || defaultGrid.pagehead === 'productfileupload') ? 300 : 10, data: rows, total: rows.length, page: 1 })
            apiRef.current.setPage(0);
        } else {
            setState({ ...state, data: rows, total: rows.length })
        }
    }, [rows])

    useEffect(() => {
        setState({ ...state, data: rows, columns })
    }, [columns])

    const onPageChange = (newPage) => {
        setState({ ...state, page: newPage + 1 });
    };

    const onPageSizeChange = (newPageSize) => {
        dispatch(setRowPage({ pageData: newPageSize }));
    };

    const handleTableAction = () => {
        if (addPriceModal) {
            addPriceModal(apiRef);
        }
    }

    return (

        <>
            {/* <Box component="div" className='table-actions-buttons' sx={{ columnGap: 1.75, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 2.5 }}>
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center' }, textAlign: { xs: "center" } }}>
                    {defaultGrid.priceActionButtons && (
                        <>
                            {addPriceModal && (
                                <div className='table-actionable'>
                                    <Button variant="contained" onClick={(e) => handleTableAction(e)} startIcon={<AddIcon />}>
                                        Add New Price
                                    </Button>
                                </div>
                            )}
                        </>)
                    }
                    {defaultGrid.brandActionButtons && (
                        <>
                            {addPriceModal && (
                                <div className='table-actionable'>
                                    <Button variant="contained" onClick={(e) => handleTableAction(e)}>
                                        Assign Brand Owner
                                    </Button>
                                </div>
                            )}
                        </>)
                    }
                    {defaultGrid.eanActionButtons && (
                        <>
                            {addPriceModal && (
                                <div className='table-actionable'>
                                    <Button variant="contained" onClick={(e) => handleTableAction(e)} startIcon={<AddIcon />}>
                                        Add EAN Code
                                    </Button>
                                </div>
                            )}
                        </>)
                    }

                </Box>
                <Box component="div" className='data-per-row-message'>
                    Showing 0 to 0 out of 0 entries
                </Box>
            </Box> */}
             {defaultGrid && defaultGrid.pageCountSize && <Box component="div" className='data-per-row-message' sx={{paddingBottom: '15px'}}>
                    Showing {state.data.length > 0 ? (state.pageSize * (state.page - 1) + 1) : 0} to {state.data.length > 0 ? ((state.total < (state.pageSize * state.page)) ? state.total : (state.pageSize * state.page)) : 0 } out of {state.data.length > 0 ? state.total : 0} entries
            </Box>}
            

            <div style={{ height: '468px', width: '100%' }}>
                <DataGridPro
                    apiRef={apiRef}
                    rows={state.data}
                    columns={state.columns ? state.columns : []}
                    //autoHeight={true}
                    disableColumnMenu={true}

                    rowCount={state.total}
                    // loading={state.isLoading}

                    pagination
                    pageSize={state.pageSize}
                    rowsPerPageOptions={[Number(state.pageSize)]}
                    //page={state.page - 1}
                    //pageSize={state.pageSize}
                    //paginationMode={defaultGrid.paginationMode}
                    //onPageChange={(newPage) => onPageChange(newPage)}
                    //onPageSizeChange={(newPageSize) => onPageSizeChange(newPageSize)}
                    components={{
                        Pagination: CustomPagination,
                        NoRowsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                                No Result
                            </Stack>
                        ),
                        NoResultsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                               {
                                (defaultGrid.keyPressSearch &&  defaultGrid.keyPressSearch ) ? 'No results found. Please select search criteria to see results' : 'No Result'
                               }
                            </Stack>
                        )
                    }}
                //rowsPerPageOptions={[10, 25, 50, 100, 200, 300]}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? '' : 'oddRow'
                }
                initialState={{ pinnedColumns: { right: ['action'] } }}
                getRowHeight={() => {
                    if (defaultGrid.rowHeight) {
                        return 'auto'
                    }
                }}
                getRowId={(row) => row.supplierProductCode ? defaultGrid.pagehead === 'pdcfileupload' ? row.id : row.supplierProductCode : row.id} 
                // getRowId={(row) => row.supplierProductCode ? row.supplierProductCode : row.id} 
                sx={{
                    "& .MuiDataGrid-columnHeader": {
                        backgroundColor: "#F0F0F0"
                        // color: "red"
                    },
                    "& .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: "600",
                        fontSize: '14px',
                        color: "#15191E"
                    },
                    "& .MuiDataGrid-row.oddRow": {
                        backgroundColor: "#FBFBFB"
                        // color: "red"
                    },
                    "& .MuiDataGrid-row.Mui-hovered, .MuiDataGrid-row.Mui-selected, .MuiDataGrid-row.Mui-selected.Mui-hovered": {
                        backgroundColor: "#FFF5E1"
                        // color: "red"
                    },
                    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
                        borderRight: `1px solid #E6E6E6`,
                        borderBottom: `1px solid #E6E6E6`,
                    },
                    '& .MuiDataGrid-cell': {
                        fontWeight: '500',
                    },
                }}
                />
            </div>
        </>
    );
}