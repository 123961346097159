import { Checkbox, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl, RadioGroup, Radio } from '@mui/material'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment"
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import axios from '../../../../services/utility/axios-retro-discount-schemes';
import PageLoader from '../../../../components/shared/PageLoader';
import { setRetroState } from '../../../../store/slices/retrodiscountschemes';
import { handleModalState } from '../../../../store/slices/modals';
import SnackBarDialog from "../../../../components/shared/SnackBarDialog";

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

function AddEditPayDate({ onShow, size = '', onHide, onSubmitDate, tableData }) {

    const [type, setType] = useState('Interim');
    const [state, setState] = useState({
        dueDate: '',
        payDateDescription: type ? type : '',
        onTrade: false,
        discountabl_bopa: false,
        formValid: true,
        defaultmode: false,
        pageLoader: false,
        tableData
    })
    const [isError, setIsError] = useState({
        dueDate: false,
        payDateDescription: false,
    })

    const handleChange = (event) => {
        setType(event.target.value);
        setState({ ...state, defaultmode: true, payDateDescription: event.target.value })
    };

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })


    const { retro_fields } = useSelector((state) => {
        return {
            retro_fields: state.retrodiscountscheme,
        }
    });
    const dispatch = useDispatch();

    const [open, setOpen] = useState(onShow);

    const handleClose = () => {
        //setState({ ...state, defaultmode: false })
        dispatch(handleModalState({
            key: 'addEditPayDateModal',
            value: false
        }))
        dispatch(setRetroState({
            key: 'hasEditPayDate',
            value: null
        }))
    };

    const handleSelf = () => {
        handleClose()
    }

    const handleInputChange = (event) => {
        const { value, name } = event.target;
        if (value === "") {
            setIsError({ ...isError, [name]: true })
        } else {
            setIsError({ ...isError, [name]: false })
        }

        setState({
            ...state,
            [name]: value,
            defaultmode: true
        });
    }

    const handledueDate = (event) => {
        if (event === null || event === 'Invalid date' || event === "") {
            setIsError({ ...isError, dueDate: true })
            setState({ ...state, dueDate: '', defaultmode: false })
        } else {
            setIsError({ ...isError, dueDate: false })
            setState({ ...state, dueDate: moment(event, 'DD/MM/YYYY'), defaultmode: true })
        }
    }

    useEffect(() => {
        if (retro_fields.hasEditPayDate === null) {
            if (state.dueDate !== "" && state.payDateDescription !== "") {
                setState({ ...state, formValid: false, defaultmode: true })
            } else {
                setState({ ...state, formValid: true, defaultmode: false })
            }
        }
    }, [state.dueDate, state.payDateDescription])


    const handleSubmit = async (event) => {
        event.preventDefault();
        if (state.dueDate === "" || state.dueDate === null) {
            setIsError({ ...isError, dueDate: true })
        } else if (state.payDateDescription === "" || state.payDateDescription === null) {
            setIsError({ ...isError, payDateDescription: true })
        } else if ((state.dueDate !== "Invalid date" || state.dueDate !== "") && state.payDateDescription !== "") {

            addPayDate()

        }

    }
    const addPayDate = async () => {
        let payload = null;
        let dateInterim
        const id = (retro_fields.hasEditData) ? (retro_fields.hasEditData.id) : retro_fields.editTabData.id;
        const filteredRowsType = retro_fields.payMemberDateData.find(value => value.type === 'Final');
        const addEdit = ((retro_fields.hasEditData !== null || retro_fields.editTabData !== null) && retro_fields.hasEditPayDate === null) ? 'addpaymemberdates' : 'updatepaymemberdates'
        if (filteredRowsType) {
            // dateInterim = moment(state.dueDate).isSameOrAfter(moment(filteredRowsType.dueDate, 'DD/MM/YYYY'), "day");
            dateInterim = moment(state.dueDate).isAfter(moment(filteredRowsType.dueDate, 'DD/MM/YYYY'), "day");
        }

        if (moment(state.dueDate).format('DD/MM/YYYY') === 'Invalid date') {
            setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
        } else if (filteredRowsType && type === 'Final' && moment(state.dueDate).isSameOrBefore(moment(filteredRowsType.dueDate, 'DD/MM/YYYY'), "day") && addEdit === 'updatepaymemberdates') {
            setSnackBar({ ...snackBar, show: true, text: "You have already entered Final Value", type: "error", });
        } else if (filteredRowsType && type === 'Final' && addEdit === 'addpaymemberdates') {
            setSnackBar({ ...snackBar, show: true, text: "You have already entered Final Value", type: "error", });
        } else if (dateInterim && type === 'Interim') {
            setSnackBar({ ...snackBar, show: true, text: "Interim date should be less than the final date", type: "error", });
        }
        else {
            if (id && retro_fields.hasEditPayDate === null) {
                payload = {
                    "dueDate": state.dueDate ? moment(state.dueDate).format('DD/MM/YYYY') : "",
                    "type": state.payDateDescription
                    // "description": state.payDateDescription,
                    // "type": type === 'Interim' ? 0 : 1,
                    // "runDate": "",
                    // "confirmed": false,
                    // "retroDiscountScheme": id
                }
            } else if (retro_fields.hasEditPayDate !== null) {
                payload = {
                    // "id": retro_fields.hasEditPayDate.id,
                    "dueDate": state.dueDate ? moment(state.dueDate).format('DD/MM/YYYY') : null,
                    "type": state.payDateDescription
                    // "description": state.payDateDescription,
                    // "type": type === 'Interim' ? 0 : 1,
                    // "runDate": retro_fields.hasEditPayDate.runDate,
                    // "confirmed": retro_fields.hasEditPayDate.confirmed,
                    // "retroDiscountScheme": retro_fields.hasEditPayDate.retroDiscountScheme
                }
            }

            try {
                setState({ ...state, pageLoader: true })
                // const url = ((retro_fields.hasEditData !== null || retro_fields.editTabData !== null) && retro_fields.hasEditPayDate === null) ? 'addpaymemberdates' : 'updatepaymemberdates';

                const { data } = await axios.post(`/ImportedRetroDiscount/addPayMemberDate/${id}`, payload);
                // const { data } = url === 'addpaymemberdates' ? await axios.post(`/retrodiscount/${id}/${url}`, payload) : await axios.put(`/retrodiscount/${id}/${url}`, payload);

                if (data) {
                    onSubmitDate({ text: data.message, type: "success" })
                    setState({ ...state, pageLoader: false })
                    handleClose()
                }

            } catch (error) {
                const { response: { data, status } } = error;
                if (!data) {
                    onSubmitDate({ text: error.message, type: "error" })
                } else {
                    onSubmitDate({ text: data.message, type: "error" })
                }
                setState({ ...state, pageLoader: false })
                handleClose()
            }
        }
    }

    useEffect(() => {
        if (retro_fields.hasEditPayDate !== null) {
            setState({
                ...state,
                dueDate: retro_fields.hasEditPayDate.dueDate ? moment(retro_fields.hasEditPayDate.dueDate, 'DD/MM/YYYY') : null,
                payDateDescription: retro_fields.hasEditPayDate.description
            })
            setType(retro_fields.hasEditPayDate.type)
        }
    }, [])


    useEffect(() => {
        const inputNumber = document.querySelectorAll('.inputNumber');
        inputNumber.forEach(el => el.addEventListener('keypress', evt => {
            if (evt.which === 8) {
                return;
            }
            if (evt.which < 42 || evt.which > 60) {
                evt.preventDefault();
            }
        }));
    }, []);

    return (
        <>


            <Modal
                open={open}
                aria-labelledby="add-edit-price-title"
                aria-describedby="add-edit-price-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Box component="div" className="overlay" id="addEditModal">
                        {state.pageLoader && <PageLoader />}
                        <div className={`overlay-box ${size ? size : ''}`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-header text-center"> Pay Member Dates
                                        <CancelIcon className="closeBtn" onClick={handleClose} />
                                    </div>
                                    <div className="overlay-content">
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            <FormLabel htmlFor="type" className="fontSemiBold">Type</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <div className="input-group">
                                                                <RadioGroup
                                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                                    name="radio-buttons-group"
                                                                    value={type}
                                                                    onChange={handleChange}
                                                                    sx={{ display: 'flex', flexDirection: 'row' }}
                                                                >
                                                                    <FormControlLabel value="Interim" control={<Radio />} label="Interim" />
                                                                    <FormControlLabel value="Final" control={<Radio />} label="Final" />
                                                                </RadioGroup>
                                                            </div>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            <FormLabel htmlFor="dueDate" className="fontSemiBold mandatory">Due Date</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <div className="input-group">
                                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                    <DatePicker
                                                                        value={state.dueDate}
                                                                        onChange={(e) => {
                                                                            handledueDate(e);
                                                                        }}
                                                                        name="dueDate"
                                                                        inputFormat="DD/MM/YYYY"
                                                                        minDate={moment(state.dueDate, 'DD/MM/YYYY')}
                                                                        disablePast={type === 'Interim' ? true : false}
                                                                        // eslint-disable-next-line max-len
                                                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                            <Box component="div" className='date-picker-input'>
                                                                                <FormControl size="small">
                                                                                    <TextField size="small" id="dueDate"
                                                                                        ref={inputRef} {...inputProps}
                                                                                        placeholder=""
                                                                                        aria-describedby="dueDate_error"
                                                                                        error={isError.dueDate}
                                                                                        autoComplete="off"
                                                                                    />
                                                                                    {isError.dueDate && (<FormHelperText id="dueDate_error" className='errorHelperTxt'>Please select Due date</FormHelperText>)}
                                                                                    <Box className='date-picker-icon'>
                                                                                        {InputProps?.endAdornment}
                                                                                    </Box>
                                                                                </FormControl>
                                                                            </Box>
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                            </div>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            <FormLabel htmlFor="Description" className="fontSemiBold mandatory">Description</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <div className="input-group">
                                                                <TextField
                                                                    multiline
                                                                    rows={3}
                                                                    size="small"
                                                                    id="payDateDescription"
                                                                    aria-describedby="payDateDescription_error"
                                                                    onChange={(e) => handleInputChange(e)}
                                                                    value={state.payDateDescription}
                                                                    name="payDateDescription"
                                                                    error={isError.payDateDescription}
                                                                />
                                                                {isError.payDateDescription && (<FormHelperText className='errorHelperTxt' id="payDateDescription_error">
                                                                    Please enter description
                                                                </FormHelperText>)}
                                                            </div>
                                                            {
                                                                retro_fields.hasEditPayDate !== null && (
                                                                    <FormHelperText className='errorHelperTxt' sx={{ position: 'relative !important' }}>
                                                                        During Edit, you cannot select previous date in ' Due Date' field . However, you can delete this entry and add new Final Record with desired date
                                                                    </FormHelperText>
                                                                )
                                                            }

                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>

                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            &nbsp;
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Grid container direction="row" alignItems="center">
                                                                <Button
                                                                    variant="contained"
                                                                    type="submit"
                                                                    sx={{ marginRight: '15px' }}
                                                                    //disabled={!retro_fields.hasEditPayDate ? state.formValid : state.defaultmode === false}
                                                                    // eslint-disable-next-line max-len
                                                                    disabled={((retro_fields.hasEditData !== null || retro_fields.editTabData !== null) && retro_fields.hasEditPayDate) ? (isError.dueDate || isError.payDateDescription) : (state.formValid || state.defaultmode === false)}
                                                                >Save</Button>
                                                                <Button variant="outlined" onClick={handleSelf}>Cancel</Button>
                                                            </Grid>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                    {snackBar.show && <SnackBarDialog
                        onShow={snackBar.show}
                        text={snackBar.text}
                        type={snackBar.type}
                        onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                    />}
                </form>

            </Modal>
        </>
    );
}

export default AddEditPayDate;