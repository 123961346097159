import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editTab: true,
    hasEditVC: null,
    editData: null,
    hasEditData: null,
    vc_code: '',
    rate_value: '',
    externalcode_value: '',
    desctiption_value: '',
    isError: {
        vc_code: false,
        rate_value: false,
        externalcode_value: true,
        desctiption_value: true
      }
   
}

const vcProduct = createSlice({
    name: 'vc_product',
    initialState,
    reducers: {
       vcProductInput: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value;
            state[key].length === 0 ? state.isError[key] = true : state.isError[key] = false
        },
        getIsError: (state, action) => {
            const { key, value } = action.payload;
            state.isError[key] = value
        },
        hasEdit: (state, action) => {
            const { editTab } =  action.payload;
            state.editTab = editTab
        },
        hasEditData: (state, action) => {
            const { editData } =  action.payload;
            state.editData = editData
        },
        setHasEditData: (state, action) => {
            state.hasEditData = action.payload;
        },
        setAutocomplete: (state, action) => {
            const { parent, key, value } =  action.payload;
            state[parent][key] = value
        },
        resetVC: () => {
            return initialState
        },
    }
})

export const { vcProductInput, resetVC, setAutocomplete, getIsError, hasEdit, hasEditData, setHasEditData} = vcProduct.actions

export default vcProduct.reducer