import { Box, Modal, Paper, FormControl, Grid, TextField, FormHelperText, FormLabel, Button, Autocomplete } from '@mui/material';
import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageLoader from '../../components/shared/PageLoader';
import moment from 'moment';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import axiosProductDistribution from '../../services/utility/axios-product-distribution';
import SnackBarDialog from '../../components/shared/SnackBarDialog';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

export default function SelectStatementCycleDatesModal({ onShow, onHide, size = '', startMemberStatement }) {
    const [state, setState] = useState({
        loader: false,
        pageLoader: false,
        defaultGrid: {
            paginationMode: 'client',
        },
        queryDate: null,
        collectionDate: null,
        formValid: true,
        defaultmode: false
    });

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const [isError, setIsError] = useState({
        queryDate: false,
        collectionDate: false
    })

    const handleClose = () => {
        if (onHide) {
            onHide(false)
        }
    };

    const handlequeryDate = (event) => {
        if (event === null) {
            setIsError({ ...isError, queryDate: true })
            setState({ ...state, queryDate: null })
        } else {
            setState({ ...state, queryDate: moment(event,'DD/MM/YYYY') })
            setIsError({ ...isError, queryDate: false })
        }
    }
    const handleCollectionDate = (event) => {
        if (event === null) {
            setIsError({ ...isError, collectionDate: true })
            setState({ ...state, collectionDate: null })
        } else {
            setState({ ...state, collectionDate: moment(event,'DD/MM/YYYY') })
            setIsError({ ...isError, collectionDate: false })
        }
    }

    useEffect(() => { 
        if ((state.queryDate === null || state.queryDate === '') || (state.collectionDate === null || state.collectionDate === '')) {
            setState({ ...state, formValid: true, defaultmode: false })
        } else {
            setState({ ...state, formValid: false, defaultmode: true })
        }
    }, [state.queryDate, state.collectionDate])

    const handleSubmit = (event) => {
        event.preventDefault();
        if (state.queryDate === null || state.queryDate === 'Invalid date' || state.queryDate === "") {
            setIsError({ ...isError, queryDate: true })
        } else if (state.collectionDate === null || state.collectionDate === 'Invalid date' || state.collectionDate === "") {
            setIsError({ ...isError, collectionDate: true })
        } else {
            if (startMemberStatement && (state.queryDate !== 'Invalid date' && state.queryDate !== null) && (state.collectionDate !== 'Invalid date' && state.collectionDate !== null)) {
                if (moment(state.queryDate).format('DD/MM/YYYY') === 'Invalid date' || moment(state.collectionDate).format('DD/MM/YYYY') === 'Invalid date') {
                    setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
                }
                else
                {
                    startMemberStatement({
                        queryDate: moment(state.queryDate).format('DD/MM/YYYY'),
                        collectionDate: moment(state.collectionDate).format('DD/MM/YYYY')
                    });
                }
                
            }
        }

    }
    // var dateFrom = moment('2022-12-07 ').subtract(1, 'months').endOf('month').format('DD/MM/YYYY');
    // alert(dateFrom);
    return (
        <>
            <Modal
                open={onShow || false}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <Box component="div" className="overlay" id="addEditModal">
                    {state.pageLoader && <PageLoader />}
                    {snackBar.show && <SnackBarDialog
                        onShow={snackBar.show}
                        text={snackBar.text}
                        type={snackBar.type}
                        onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                    />}
                    <div className={`overlay-box ${size ? size : ''}`}>
                        <div className="content">
                            <div className="overlayBody">
                                <div className="overlay-header text-center"> Start Statement Cycle
                                    <CancelIcon className="closeBtn" onClick={handleClose} />
                                </div>
                                <div className="overlay-content">
                                    <Box sx={{ flexGrow: 1, paddingX: 2 }} >
                                        <Grid container columnSpacing={2}>
                                            <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: 2.5 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="brandOwnerProductId" className="fontSemiBold mandatory">Query Date</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={7.5}>
                                                            <Item>
                                                                <div className="input-group">
                                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                        <DesktopDatePicker
                                                                            value={state.queryDate}
                                                                            onChange={(e) => { handlequeryDate(e) }}
                                                                            name="queryDate"
                                                                            inputFormat="DD/MM/YYYY"
                                                                            // eslint-disable-next-line max-len
                                                                            renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                                <Box component="div" className='date-picker-input'>
                                                                                    <FormControl size={'small'} fullWidth>
                                                                                        <TextField size="small" id="queryDate"
                                                                                            ref={inputRef} {...inputProps}
                                                                                            placeholder=""
                                                                                            aria-describedby="queryDate_error"
                                                                                            error={isError.queryDate}
                                                                                            autoComplete="off"
                                                                                        />
                                                                                        {isError.queryDate && (<FormHelperText className='errorHelperTxt' id="queryDate_error">Query Date is missing</FormHelperText>)}
                                                                                        <Box className='date-picker-icon'>
                                                                                            {InputProps?.endAdornment}
                                                                                        </Box>
                                                                                    </FormControl>
                                                                                </Box>
                                                                            )}
                                                                        />
                                                                    </LocalizationProvider>
                                                                </div>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        <Grid container columnSpacing={2}>
                                            <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: 2.5 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="brandOwnerProductId" className="fontSemiBold mandatory">Collection Date</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={7.5}>
                                                            <Item>
                                                                <div className="input-group">
                                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                        <DesktopDatePicker
                                                                            value={state.collectionDate}
                                                                            onChange={(e) => { handleCollectionDate(e) }}
                                                                            name="collectionDate"
                                                                            inputFormat="DD/MM/YYYY"
                                                                            // eslint-disable-next-line max-len
                                                                            renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                                <Box component="div" className='date-picker-input'>
                                                                                    <FormControl size={'small'} fullWidth>
                                                                                        <TextField size="small" id="collectionDate"
                                                                                            ref={inputRef} {...inputProps}
                                                                                            placeholder=""
                                                                                            aria-describedby="collectionDate_error"
                                                                                            error={isError.collectionDate}
                                                                                            autoComplete="off"
                                                                                        />
                                                                                        {isError.collectionDate && (<FormHelperText className='errorHelperTxt' id="collectionDate_error">Collection Date is missing</FormHelperText>)}
                                                                                        <Box className='date-picker-icon'>
                                                                                            {InputProps?.endAdornment}
                                                                                        </Box>
                                                                                    </FormControl>
                                                                                </Box>
                                                                            )}
                                                                        />
                                                                    </LocalizationProvider>
                                                                </div>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        <Grid container sx={{ marginTop: '20px' }}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Item>
                                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                                        <Button variant="contained" onClick={(e) => { handleSubmit(e) }} type="submit" disabled={state.formValid || state.defaultmode === false} sx={{ marginRight: '15px' }}>Save</Button>
                                                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}
