import { Container, Box, Modal, Button, Grid, Checkbox, Popper, Grow, Paper, ClickAwayListener, MenuList, ButtonGroup, FormLabel, TextField, FormControl } from '@mui/material'
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TableFilters } from "../../components/shared/data-table/TableFilters";
import { useEffect, useState } from "react";
import { InputText } from "../../components/shared/form/InputText";
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { AutoComplete } from "../../components/shared/form/AutoComplete";
import { } from '@mui/material';
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange, filterResetFlagChange } from "../../store/slices/externalFilter";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import { styled } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import PageLoader from "../../components/shared/PageLoader";
import CancelIcon from '@mui/icons-material/Cancel';
import { ThreeStateCheckbox } from "../../components/shared/form/ThreeStateCheckbox";
import { warningModal, handleAddEditModal } from "../../store/slices/modals";
import WarningModal from "../../components/shared/modals/WarningModal";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SnackBarDialog from '../../components/shared/SnackBarDialog';
import CircleIcon from '@mui/icons-material/Circle';
import { setRowPage, resetDataGrid, setPageNo } from '../../store/slices/dataGrid';
import { useLayoutEffect } from "react";
import axios_member from '../../services/utility/axios-member';
import { DataTableMember } from "../../components/shared/data-table/DataTableMember";
import { DataTableSupplierMember } from "../../components/shared/data-table/DataTableSupplierMember";
import { hasTabEdit, setHasEditData, smaInput, brandSearch, setSmaList, setSmaDeleteData, setDeleteList, setMemberCode, resetSMA } from "../../store/slices/supplierMemberAccounts";
import { AutoCompleteStatic } from "../../components/shared/form/AutoCompleteStatic";
import AddEditSupplierMemberAccountsDetails from "./AddEditSupplierMemberAccounts/AddEditSupplierMemberAccountsDetails";
import CommonPopUp from "../products/addEditPdc/pdc-tabs/CommonPopUp";

const IconMenuLink = ({ params, sendSnackBar, startLoading, onDelete }) => {
    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const [pageLoader, setPageLoader] = useState(false);
    const open = Boolean(anchorEl);

    const { showAddEditModal, sma_container } = useSelector((state) => {
        return {
            showAddEditModal: state.modalActions.hideAddEditModal,
            editTabData: state.supplierAccount.editTabData,
            sma_container: state.smas,
        }
    });
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'edit_accounts') {
            // dispatch(handleAddEditModal({
            //     showAddEdit: !showAddEditModal
            // }))
            // dispatch(hasTabEdit({ editTab: false }))

            setEditPDClData(row);
        } else if (action === 'delete') {
            onDelete(row)
        }
        setAnchorEl(null);
    };
    const setEditPDClData = async (row) => {
        try {
            if (startLoading) {
                startLoading(true)
            }
            const { data } = await axios_member.get(`supplierMemberAccounts/${row.id}`);

            if (data) {
                if (startLoading) {
                    startLoading(false)
                }
                dispatch(handleAddEditModal({
                    showAddEdit: !showAddEditModal
                }))
                dispatch(setHasEditData(data))
                dispatch(brandSearch({
                    parent: 'sma_supplier',
                    key: 'sma_supplier_name',
                    value: data.supplierDTO ? data.supplierDTO : null
                }))
                dispatch(brandSearch({
                    parent: 'sma_member',
                    key: 'sma_member_name',
                    value: data.memberOutletDTO.memberAccount.member ? data.memberOutletDTO.memberAccount.member : null
                }))
                dispatch(brandSearch({
                    parent: 'sma_member_outlet',
                    key: 'sma_member_outlet_selected',
                    value: data.memberOutletDTO ? data.memberOutletDTO : null
                }))
                dispatch(smaInput({
                    key: 'sma_code',
                    value: data.code
                }))
                dispatch(smaInput({
                    key: 'sma_status',
                    value: data.active
                }))
                dispatch(brandSearch({
                    parent: 'sma_member_account',
                    key: 'sma_member_account_selected',
                    value: data.memberOutletDTO.memberAccount ? data.memberOutletDTO.memberAccount : null
                }))
                // dispatch(dcProductInput({
                //     key: 'dc_status',
                //     value: data.active
                // }))
            }
        }
        catch (error) {
            if (sendSnackBar) {
                sendSnackBar(error)
            }
        }
    }
    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'edit_accounts')}>Edit</MenuItem>
            <MenuItem onClick={(e) => handleClose(e, 'delete')}>Delete</MenuItem>
        </Menu>
    </>)
}
function SupplierMemberAccounts() {

    const Item = styled(Paper)(({ theme }) => ({
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(2),
        boxShadow: "none",
        background: "none"
    }));

    const columns = [
        {
            headerName: "ID",
            field: "id",
            flex: 1,
            minWidth: 200,
            //hide: true
        },
        {
            headerName: "Code",
            field: "code",
            flex: 1,
            width: 170
        },
        {
            headerName: "Supplier",
            field: "supplierDTO",
            flex: 1,
            width: 180,
            valueFormatter: ({ value }) => value.name,
        },
        {
            headerName: "Member Outlet",
            field: "memberOutletDTO",
            flex: 1,
            width: 170,
            valueFormatter: ({ value }) => value.name,
        },
        {
            headerName: "Member Account",
            field: "memberAccountDTO",
            flex: 1,
            width: 180,
            valueGetter: (params) => { return params.row.memberOutletDTO.memberAccount.name },
        },
        {
            headerName: "Purchasing Tier",
            field: "memberTier",
            valueGetter: (params) => params.row.memberOutletDTO.memberTier ? params.row.memberOutletDTO.memberTier.name : "",
            minWidth: 150
        },
        {
            headerName: "Active",
            field: "active",
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
            align: "center",
            headerAlign: "center",
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} startLoading={pageLoader} onDelete={deleteSMAdata} />;
            }
        }
    ];

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const [state, setState] = useState({
        accounts_supplier_status: null,
        opensupplierMemberAccountModal: false,
        supplierMemberDasboadModal: false,
        handleSupplierMemberModal: '',
        pageLoader: false,
        defaultGrid: {
            pagetitle: "Supplier Member Accounts",
            pdcActionButton: true,
            hasExport: true,
            addEditButton: true,
            apiUrl: 'supplierMemberAccounts/filter',
            searchUrl: 'supplierMemberAccounts/filter'
        }
    });

    const pageLoader = (loader) => {
        setState({ ...state, pageLoader: loader })
    }

    const { sma_container, accountsMember, pdcproductBrand, accountsSupplier, accountsSupplierCode, pdcDistributionChannel, accountssupplierstatus, addProductwarning, showAddEditModal } = useSelector((state) => {
        return {
            sma_container: state.smas,
            accountsMember: state.externalFilter.accounts_member,
            accountsSupplier: state.externalFilter.accounts_supplier,
            accountsSupplierCode: state.externalFilter.accounts_supplier_code,
            accountssupplierstatus: state.externalFilter.accounts_supplier_status,
            resetTable: state.dataGridTable,
            addProductwarning: state.modalActions.closeWaringAddProduct,
            showAddEditModal: state.modalActions.hideAddEditModal
        }
    });

    const dispatch = useDispatch();

    const handleFilterInput = (value, id) => {
        //setState({ ...state, [id]: value });
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    };

    // const handleChange = (checked, name) => {
    //     setState({ ...state, [name]: checked })
    //     dispatch(setFilter({
    //         key: name,
    //         value: checked
    //     }))
    // };

    const handleChange = (checked, name) => {
        // setState({ ...state, [name]: checked });
        state.accounts_supplier_status = checked;
        dispatch(setFilter({
            key: name,
            value: checked
        }));
        setTimeout(() => {
            filterBtn();
        }, 500);
    };

    const selectedAutoComplete = (value, id) => {
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    }

    const filterBtn = () => {
        dispatch(resetDataGrid());
        var dynamicFilterObjects = {};

        if (accountsSupplierCode !== '' && accountsSupplierCode !== null) {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "code": accountsSupplierCode
            }
        }
        if (accountsSupplier !== '' && accountsSupplier !== null) {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "supplierDTO": {
                    "id": accountsSupplier.id,
                    "name": accountsSupplier.name,
                }
            }
        }
        if (accountsMember !== '' && accountsMember !== null) {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "memberOutletDTO": {
                    "id": accountsMember.id
                }
            }
        }
        dynamicFilterObjects = {
            ...dynamicFilterObjects,
            "active": state.accounts_supplier_status
            // "active": accountssupplierstatus
        }

        dispatch(applyFilter({
            key: 'filterList',
            value: dynamicFilterObjects
        }))
        dispatch(filterFlagChange(true));
        dispatch(filterResetFlagChange(false));
        dispatch(setRowPage({ pageData: 10 }));
        dispatch(setPageNo({ page: 1 }));
    }
    const resetFilter = () => {
        if ((accountsSupplierCode || accountsMember || accountsSupplier) !== '') {
            dispatch(resetExternalFilter())
            dispatch(resetDataGrid());
            dispatch(setMemberCode({
                key: 'smaSupplierNameOnModal',
                value: ""
            }));
            dispatch(setFilter({
                key: 'accounts_supplier',
                value: null
            }))
            dispatch(setFilter({
                key: 'accounts_member',
                value: null
            }))
        }
        setState({ ...state, accounts_supplier_status: null })
        dispatch(filterResetFlagChange(true));
    }
    const addSmaModel = () => {
        dispatch(resetSMA());
        dispatch(handleAddEditModal({
            showAddEdit: !showAddEditModal
        }))
    }
    const hideNewSmaModal = (event) => {
        setState({ ...state, opensupplierMemberAccountModal: false })
    }

    const deleteSMAdata = (row) => {
        dispatch(setDeleteList(row))
        dispatch(setSmaDeleteData({
            key: 'sma_StartSingleDelete',
            value: true
        }))
        dispatch(warningModal({
            yesHide: !addProductwarning
        }))
    }

    useEffect(() => {
        if (sma_container.sma_ConfirmSingleDelete) {
            deleteSMASingleRow()
        }
    }, [sma_container.sma_ConfirmSingleDelete])

    const deleteSMASingleRow = async () => {
        if (sma_container.deleteList !== null) {
            try {
                setState({ ...state, pageLoader: true })
                const { data } = await axios_member.delete(`supplierMemberAccounts/${sma_container.deleteList.id}`);
                if (data.success) {
                    SmaDeleteRowDispatch();
                    setState({ ...state, pageLoader: false });
                    setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Supplier member account Delete Successfully!", type: "success" });
                    setTimeout(() => {
                        // window.location.reload();
                        dispatch(filterFlagChange(true));
                        dispatch(filterResetFlagChange(false));
                    }, 1500);
                    // try {
                    //     const { data } = await axios_member.get(`supplierMemberAccounts`);
                    //     if (data !== null || data !== undefined) {
                    //         dispatch(setSmaList(data.items))
                    //         setSnackBar({ ...snackBar, show: true, text: "Supplier member account Delete Successfully!", type: "success" })
                    //         setState({ ...state, pageLoader: false })
                    //         SmaDeleteRowDispatch()
                    //         setTimeout(() => {
                    //             // window.location.reload();
                    //             dispatch(filterFlagChange(true));
                    //             dispatch(filterResetFlagChange(false));
                    //         }, 1500);
                    //     }
                    // }
                    // catch (error) {
                    //     setSnackBar({ ...snackBar, show: true, text: "Something went wrong!", type: "error", })
                    //     SmaDeleteRowDispatch()
                    //     setState({ ...state, pageLoader: false })
                    // }
                }

            } catch (error) {
                const { response: { data, status }, message } = error;
                setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error", });
                SmaDeleteRowDispatch()
                setState({ ...state, pageLoader: false })
            }
        }
    }

    const SmaDeleteRowDispatch = () => {
        dispatch(setSmaDeleteData({
            key: 'sma_StartSingleDelete',
            value: false
        }))
        dispatch(setSmaDeleteData({
            key: 'sma_ConfirmSingleDelete',
            value: false
        }))
    }

    const resetLookups = (params, event) => {
        if (event === 'supplier') {
            dispatch(setFilter({
                key: 'accounts_supplier',
                value: null
            }))
        }
        if (event === 'members') {
            dispatch(setFilter({
                key: 'accounts_member',
                value: null
            }))

        }
    }

    const CommonPopUpModal = (e, type) => {
        setState({ ...state, supplierMemberDasboadModal: true, handleSupplierMemberModal: type })
    }

    const hidePriceModal = (event, reason, type) => {
        if (type === 'supplier') {
            if (reason) {
                dispatch(setFilter({
                    key: 'accounts_supplier',
                    value: reason ? reason : null
                }))
            }
            setState({ ...state, supplierMemberDasboadModal: event === false ? event : true })
        }
        if (type === 'members') {
            if (reason) {
                dispatch(setFilter({
                    key: 'accounts_member',
                    value: reason ? reason : null
                }))
            }
            setState({ ...state, supplierMemberDasboadModal: event === false ? event : true })
        }

    }

    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Supplier Member Accounts" />
                <Box sx={{ marginBottom: 2.5 }}>
                    <TableFilters
                        filterFields={<>
                            <Box component="div"><InputText onChange={handleFilterInput} maxWidth={50} label="Code" id="accounts_supplier_code" size="small" /></Box>
                            <Box component="div"><AutoCompleteStatic endPoint="/supplier/look-up" onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} id="accounts_supplier" label="Supplier" name="accounts_supplier" /> 
                            </Box>
                            <Box component="div"><AutoCompleteStatic endPoint="members/look-up?pageSize=500" onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} id="accounts_member" label="Member" name="accounts_member" /></Box>
                            {/* <Box component="div">
                                <FormLabel htmlFor="supplier_invoice_member_account" className="fontBold">Supplier</FormLabel>
                                <Grid container alignItems="center" sx={{ paddingTop: '5px' }}>
                                    <Grid item xs={12} md={11}>
                                        <Item>
                                            <Box component="div">
                                                <FormControl size={'small'} fullWidth>
                                                    <TextField
                                                        inputProps={{ maxLength: 100 }}
                                                        size="small"
                                                        id="accounts_supplier"
                                                        value={(accountsSupplier ? accountsSupplier.name : '')}
                                                        aria-describedby="accounts_supplier_error"
                                                        name="accounts_supplier"
                                                        disabled={true}
                                                    />

                                                </FormControl>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        <Item>
                                            <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0, marginRight: '5px' }} onClick={(e) => resetLookups(e, 'supplier')} startIcon={<ClearIcon />}></Button>
                                            <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={(e) => CommonPopUpModal(e, 'supplier')} startIcon={<EditIcon />}></Button>
                                        </Item>
                                    </Grid>

                                </Grid>
                            </Box> */}
                            {/* <Box component="div">
                                <FormLabel htmlFor="supplier_invoice_member_account" className="fontBold">Member</FormLabel>
                                <Grid container alignItems="center" sx={{ paddingTop: '5px' }}>
                                    <Grid item xs={12} md={11}>
                                        <Item>
                                            <Box component="div">
                                                <FormControl size={'small'} fullWidth>
                                                    <TextField
                                                        inputProps={{ maxLength: 100 }}
                                                        size="small"
                                                        id="accounts_member"
                                                        value={(accountsMember ? accountsMember.name : '')}
                                                        aria-describedby="accounts_member_error"
                                                        name="accounts_member"
                                                        disabled={true}
                                                    />

                                                </FormControl>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        <Item>
                                            <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0, marginRight: '5px' }} onClick={(e) => resetLookups(e, 'members')} startIcon={<ClearIcon />}></Button>
                                            <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={(e) => CommonPopUpModal(e, 'members')} startIcon={<EditIcon />}></Button>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box> */}

                        </>}
                        filterCheckboxes={<>
                            <Box component="div">
                                <ThreeStateCheckbox checked={state.accounts_supplier_status} onChange={handleChange} name="accounts_supplier_status" id="accounts_supplier_status" label="Active" />
                            </Box>
                        </>}
                        filterBtns={
                            <>
                                <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                <Button variant="contained" onClick={filterBtn}>Apply</Button>
                            </>}>
                    </TableFilters>
                </Box>

                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -2.4 } }}>
                    {/* <div className='table-actionable'>
                        <Button variant="contained" color="secondary" size='small' startIcon={<SendIcon />}>
                            Export as Excel
                        </Button>
                    </div> */}
                    <div className='table-actionable'>
                        <Button variant="contained" size='small' onClick={(e) => addSmaModel(e)} startIcon={<AddIcon />}>Add New Supplier Member Account</Button>
                    </div>
                </Box>

                <DataTableSupplierMember defaultGrid={state.defaultGrid} columns={columns} rows={sma_container.sma_lists}>

                </DataTableSupplierMember>
                {
                    showAddEditModal && (
                        <AddEditSupplierMemberAccountsDetails
                            onShow={showAddEditModal} size="medium_overlay"
                            onHide={(e, reason) => hideNewSmaModal(e, reason)}

                        />
                    )
                }
                {
                    addProductwarning && (
                        <WarningModal size="small_overlay" onShow={addProductwarning} />
                    )
                }
            </Container>
            {snackBar.show && <SnackBarDialog
                onShow={snackBar.show}
                text={snackBar.text}
                type={snackBar.type}
                onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
            />}


            {
                state.supplierMemberDasboadModal && (
                    <CommonPopUp
                        onShow={state.supplierMemberDasboadModal} size="medium_overlay"
                        supplierMemberCode={{
                            title: (state.handleSupplierMemberModal && state.handleSupplierMemberModal === 'members') ? 'Member Details' : 'Supplier Details',
                            type: state.handleSupplierMemberModal
                        }}
                        onHide={(e, reason) => hidePriceModal(e, reason, state.handleSupplierMemberModal)}
                    />
                )
            }

        </>
    );
}

export default SupplierMemberAccounts;
