import { Checkbox, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl } from '@mui/material'
import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { setBrandDate, resetBrand, setBrandEffectiveDate, setAutocomplete, getIsError, setBrandDateList, editBrandOwner } from "../../../store/slices/brandProduct";
import { useDispatch, useSelector } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import axios_brand from '../../../services/utility/axios-brand';
import axios_supplier from '../../../services/utility/axios-supplier';
import LoadingButton from '@mui/lab/LoadingButton';
import SnackBarDialog from '../../../components/shared/SnackBarDialog';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

const defaultValues = {
    startDate: null,
    endDate: null,
    loadSupplier: false,
    formValid: true,
    defaultmode: false,
    id: 0
};

function AddBrandDate({ onShow, size = '', onHide, onBrandAdded }) {

    const [state, setState] = useState(defaultValues);

    const [isError, isSetError] = useState({
        bopaError: false,
    })
    const [loader, setLoader] = useState(false)
    const [open, setOpen] = useState(onShow);
    const [latestEndDate, setLatestEndDate] = useState();
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const {
        brand_container
    } = useSelector((state) => {
        return {
            brand_container: state.brandProducts,
        }
    })

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchMyAPI = async () => {
            if (brand_container.brand_owner_name.brand_owner_options.length === 0) {
                axios_supplier.get('/supplier/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data.filter((value, index, self) => self.findIndex((m) => m.name === value.name) === index);
                            dispatch(setAutocomplete({
                                parent: 'brand_owner_name',
                                key: 'brand_owner_options',
                                value: unique
                            }));
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
        }
        fetchMyAPI();
    }, [])


    const handleClose = () => {
        if (onHide) {
            dispatch(setBrandEffectiveDate({
                key: 'brand_start_date',
                value: null
            }))
            dispatch(setBrandEffectiveDate({
                key: 'brand_end_date',
                value: null
            }))
            dispatch(setAutocomplete({
                parent: 'brand_owner_name',
                key: 'brand_owner_selected',
                value: null
            }));
            dispatch(editBrandOwner(null))
            dispatch(getIsError({ key: 'brand_owner_selected', value: false }))
            onHide(false)
        }
    };

    const handleStartDate = (event) => {
        if (event === null) {
            dispatch(setBrandEffectiveDate({
                key: 'brand_start_date',
                value: null
            }))
            setState({ ...state, defaultmode: false })
            dispatch(getIsError({ key: 'brand_start_date', value: true }))
        } else {
            dispatch(setBrandEffectiveDate({
                key: 'brand_start_date',
                value: moment(event, 'DD/MM/YYYY')
            }))
            dispatch(setBrandEffectiveDate({
                key: 'brand_end_date',
                value: null
            }))
            setState({ ...state, defaultmode: true })
            setLatestEndDate(moment(event).add(1, 'days').format('DD/MM/YYYY'))
        }
    }
    const handleEndDate = (event) => {
        dispatch(setBrandEffectiveDate({
            key: 'brand_end_date',
            value: (event === null) ? null : moment(event, 'DD/MM/YYYY')
        }))
        setState({ ...state, defaultmode: true })
    }

    const handleSelection = (event, newValue, parentname, name) => {
        setState({ ...state, defaultmode: true })
        if (event.target.value !== "" || event.target.value !== null) {
            dispatch(setAutocomplete({
                parent: parentname,
                key: name,
                value: newValue
            }))
        }
    }

    useEffect(() => {
        if (moment(brand_container.brand_start_date).isBefore(moment(), "day")) {
            isSetError({ ...isError, bopaError: true })
        } else {
            isSetError({ ...isError, bopaError: false })
        }
    }, [brand_container.brand_start_date])

    useEffect(() => {
        if ((brand_container.brand_owner_name.brand_owner_selected === null || brand_container.brand_owner_name.brand_owner_selected === "") || (brand_container.brand_start_date === null || brand_container.brand_start_date === 'Invalid date' || brand_container.brand_start_date === "")) {
            setState({ ...state, formValid: true, defaultmode: false })
        } else {
            setState({ ...state, formValid: false, defaultmode: true })
        }
    }, [brand_container])

    useEffect(() => {
        const {
            brand_owner_selected,
            brand_start_date
        } = brand_container.isError
        if (!brand_owner_selected && !brand_start_date) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true })
        }
        if (brand_container.edit_brand_owner) {
            setState({ ...state, formValid: true })
        }

    }, [brand_container.isError])

    useEffect(() => {
        const {
            brand_owner_name,
            brand_start_date
        } = brand_container.isError

        if (brand_container.edit_brand_owner && (brand_container.brand_end_date !== "" && brand_container.brand_end_date !== "Invalid date")) {
            setState({ ...state, formValid: false })
        } else if (brand_container.edit_brand_owner && (brand_container.brand_end_date === "" || brand_container.brand_end_date === "Invalid date")) {
            setState({ ...state, formValid: true })
        }
    }, [brand_container.brand_end_date])


    const handleSubmit = async (event) => {
        event.preventDefault();
        let addBrandOwner = null;
        if (!state.formValid) {
            addBrandOwner = {
                "id": brand_container.addNewBrandData.brandId,
                "brandOwnerId": brand_container.brand_owner_name.brand_owner_selected.id,
                "brandOwnerEffectiveDatePeriod": {
                    "startDate": brand_container.brand_start_date ? moment(brand_container.brand_start_date).format('DD/MM/YYYY') : null,
                    "endDate": brand_container.brand_end_date === null ? null : moment(brand_container.brand_end_date).format('DD/MM/YYYY')
                }
            }

            if (moment(brand_container.brand_start_date).format('DD/MM/YYYY') === 'Invalid date' || (brand_container.brand_end_date !== "" && brand_container.brand_end_date !== null && moment(brand_container.brand_end_date).format('DD/MM/YYYY') === 'Invalid date')) {
                setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
            }
            else if (brand_container.brand_end_date !== null && brand_container.brand_end_date !== "" && (brand_container.brand_start_date > brand_container.brand_end_date)) {
                setSnackBar({ ...snackBar, show: true, text: "End date is less than Start Date!", type: "error", });
            }
            else {
                try {
                    if (brand_container.addNewBrandData && !brand_container.edit_brand_owner) {
     
                        if (brand_container.brand_date.length > 0 && (brand_container.brand_date[0].brandOwnerEffectiveDatePeriod.endDate !== "" || brand_container.brand_date[0].brandOwnerEffectiveDatePeriod.endDate !== null || brand_container.brand_date[0].brandOwnerEffectiveDatePeriod.endDate !== undefined)) {
                            
                            const startDatePayload = moment(brand_container.brand_start_date).format('DD/MM/YYYY')
                            const endDatePrevious = brand_container.brand_date[0].brandOwnerEffectiveDatePeriod.endDate
                            if (startDatePayload === endDatePrevious) {
                                setSnackBar({ ...snackBar, show: true, text: "Previous Brand Owner End date overlapping with Current Brand Owner Start Date", type: "error", });
                            } else {
                                dateOvelapping(addBrandOwner)
                            }
                        } else {
                            dateOvelapping(addBrandOwner)
                        }
                    }
                    else if (brand_container.edit_brand_owner) {
                        setLoader(true)
                        let editBrandOwner = {
                            "id": brand_container.addNewBrandData.brandId,
                            "brandOwnerId": brand_container.edit_brand_owner.brandOwner.id,
                            "brandOwnerEffectiveDatePeriod": {
                                "id": brand_container.edit_brand_owner.brandOwnerEffectiveDatePeriod.id,
                                "startDate": brand_container.edit_brand_owner.brandOwnerEffectiveDatePeriod.startDate,
                                "endDate": brand_container.brand_end_date ? moment(brand_container.brand_end_date).format('DD/MM/YYYY') : null
                            }
                        }
                        const { data } = await axios_brand.put(`brands/${brand_container.addNewBrandData.brandId}/brandOwner`, editBrandOwner);
                        
                        if (data.success) {
                            const { data } = await axios_brand.get(`brands/${brand_container.addNewBrandData.brandId}/brandOwner`);
                            if (!data) {
                                onBrandAdded({ text: "Technical", type: "error" })
                                handleClose()
                            }
                            if (data !== null || data !== undefined) {
                                setLoader(false)
                                dispatch(setBrandDateList([]))
                                setTimeout(() => {
                                    let newBx = data;
                                    newBx.forEach((x, index) => {
                                        if (x.id) {
                                            x.id = x.id + index
                                        }
                                    });
                                    dispatch(setBrandDateList(newBx))
                                    if (onBrandAdded) {
                                        onBrandAdded({ text: "Brand owner updated successfully!", type: "success" })
                                        handleClose()
                                    }
                                }, 500);
                            }
                        }
                        else
                        {
                            setLoader(false);
                            setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" })
                        }
                    }


                } catch (error) {
                    const { response: { data, status } } = error;
                    setLoader(false)
                    if (data.errorType === "Technical") {
                        setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" })
                    } else if (data.errorType === "Business") {
                        setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                    } else {
                        setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                    }
                    //handleClose()
                }
            }

        }
    }
    const dateOvelapping = async (addBrandOwner) => {
        try {
            setLoader(true)
            const { id, brandId } = brand_container.addNewBrandData;
            const { data } = await axios_brand.post(`brands/${brandId}/brandOwner`, addBrandOwner);
            if (data.success) {
                const { data } = await axios_brand.get(`brands/${brandId}/brandOwner`);
                if (!data) {
                    onBrandAdded({ text: "Technical", type: "error" })
                    handleClose()
                }
                if (data !== null || data !== undefined) {
                    setLoader(false)
                    dispatch(setBrandDateList([]))
                    setTimeout(() => {
                        let newBx = data;
                        newBx.forEach((x, index) => {
                            if (x.id) {
                                x.id = x.id + index
                            }
                        });
                        dispatch(setBrandDateList(newBx))
                        if (onBrandAdded) {
                            onBrandAdded({ text: "Brand owner added successfully!", type: "success" })
                        }
                        handleClose()
                    }, 500);

                }
            } else {
                onBrandAdded({ text: data.message, type: "error" })
                handleClose()
            }
        }
        catch (error) {
            const { response: { data, status } } = error;
            setLoader(false)
            if (data.errorType === "Technical") {
                setSnackBar({ ...snackBar, show: true, text: "Technical", type: "error" })
            } else if (data.errorType === "Business") {
                setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
            } else {
                setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
            }
            //handleClose()
        }
    }
    // useEffect(() => {
    //     if (brand_container.edit_brand_owner === null) {
    //         if ((brand_container.brand_date.length > 0)) {
    //             if (brand_container.brand_date[0].brandOwnerEffectiveDatePeriod.endDate === "" || brand_container.brand_date[0].brandOwnerEffectiveDatePeriod.endDate === null || brand_container.brand_date[0].brandOwnerEffectiveDatePeriod.endDate === undefined) {
    //                 dispatch(setBrandEffectiveDate({
    //                     key: 'brand_start_date',
    //                     value: moment(brand_container.brand_date[0].brandOwnerEffectiveDatePeriod.startDate, 'DD/MM/YYYY').add(2, 'days')
    //                 }))
    //             } else {
    //                 dispatch(setBrandEffectiveDate({
    //                     key: 'brand_start_date',
    //                     value: moment(brand_container.brand_date[0].brandOwnerEffectiveDatePeriod.endDate, 'DD/MM/YYYY').add(1, 'days')
    //                 }))
    //             }
    //         }
    //     }
    // }, [])

    return (
        <>
            <Modal
                open={open}
                aria-labelledby="add-edit-price-title"
                aria-describedby="add-edit-price-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Box component="div" className="overlay" id="addEditModal">
                        <div className={`overlay-box ${size ? size : ''}`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-header text-center"> Assign Brand Owner
                                        <CancelIcon className="closeBtn" onClick={handleClose} />
                                    </div>
                                    <div className="overlay-content">
                                        <Grid item xs={12} md={12}>

                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="brands_owner_search" className="fontSemiBold mandatory">Brand Owner</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={5.15}>
                                                        <Item>

                                                            <Autocomplete
                                                                disabled={brand_container.edit_brand_owner !== null ? true : false}
                                                                defaultValue={brand_container.brand_owner_name.brand_owner_selected}
                                                                id="brands_owner_search"
                                                                options={brand_container.brand_owner_name.brand_owner_options}
                                                                getOptionLabel={(option) => option.name || ""}
                                                                componentsProps={{
                                                                    paper: {
                                                                        sx: {
                                                                            fontSize: '0.85rem'
                                                                        }
                                                                    }
                                                                }}
                                                                onChange={(event, newValue) => handleSelection(event, newValue, 'brand_owner_name', 'brand_owner_selected')}
                                                                renderInput={(params) =>
                                                                    <FormControl size={'small'} fullWidth>
                                                                        <div className="input-group">
                                                                            <TextField
                                                                                {...params}
                                                                                size="small" id="supplierName"
                                                                                aria-describedby="supplierName_error"
                                                                                error={brand_container.isError.brand_owner_selected}
                                                                            />
                                                                            {brand_container.isError.brand_owner_selected &&
                                                                                (<FormHelperText className='errorHelperTxt' id="brandName_error">Please select brand owner</FormHelperText>)}
                                                                        </div>
                                                                    </FormControl>
                                                                }

                                                            />
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="price_start_date" className="fontSemiBold mandatory">Start Date</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={5.15}>
                                                        <Item>
                                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                <DesktopDatePicker
                                                                    //defaultCalendarMonth={state.startDate ? state.startDate : null}
                                                                    disabled={brand_container.edit_brand_owner !== null ? true : false}
                                                                    value={brand_container.brand_start_date}
                                                                    onChange={(e) => { handleStartDate(e) }}
                                                                    name="price_start_date"
                                                                    inputFormat="DD/MM/YYYY"
                                                                    //minDate={brand_container.brand_date.length > 0 ? moment(brand_container.brand_date[0].brandOwnerEffectiveDatePeriod.endDate, 'DD/MM/YYYY').add(1, 'days') : null }
                                                                    // eslint-disable-next-line max-len
                                                                    renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                        <Box component="div" className='date-picker-input'>
                                                                            <FormControl size={'small'} fullWidth>
                                                                                <TextField size="small" id="price_start_date"
                                                                                    ref={inputRef} {...inputProps}
                                                                                    placeholder=""
                                                                                    aria-describedby="price_start_date_error"
                                                                                    error={brand_container.isError.brand_start_date}
                                                                                    autoComplete="off"
                                                                                />
                                                                                {brand_container.isError.brand_start_date && (<FormHelperText className='errorHelperTxt' id="price_start_date_error">Start date is missing</FormHelperText>)}
                                                                                <Box className='date-picker-icon'>
                                                                                    {InputProps?.endAdornment}
                                                                                </Box>
                                                                            </FormControl>
                                                                        </Box>
                                                                    )}
                                                                />
                                                            </LocalizationProvider>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="price_end_date" className="fontSemiBold">End Date</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={5.15}>
                                                        <Item>
                                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                <DesktopDatePicker
                                                                    defaultCalendarMonth={brand_container.brand_start_date ? moment(brand_container.brand_start_date, "DD/MM/YYYY").add(1, 'days') : null}
                                                                    value={brand_container.brand_end_date}
                                                                    disabled={(brand_container.brand_start_date === null || brand_container.brand_start_date === '') ? true : false}
                                                                    onChange={(e) => { handleEndDate(e) }}
                                                                    name="price_end_date"
                                                                    minDate={moment(brand_container.brand_start_date, 'DD/MM/YYYY').add(1, 'days')}
                                                                    inputFormat="DD/MM/YYYY"
                                                                    // eslint-disable-next-line max-len
                                                                    renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                        <Box component="div" className='date-picker-input'>
                                                                            <FormControl size={'small'} fullWidth>
                                                                                <TextField size="small" id="price_end_date"
                                                                                    ref={inputRef} {...inputProps}
                                                                                    placeholder=""
                                                                                    aria-describedby="price_end_date_error"
                                                                                    autoComplete="off"
                                                                                />
                                                                                {isError.endDate && (<FormHelperText id="price_end_date_error">Error Here</FormHelperText>)}
                                                                                <Box className='date-picker-icon'>
                                                                                    {InputProps?.endAdornment}
                                                                                </Box>
                                                                            </FormControl>
                                                                        </Box>
                                                                    )}
                                                                />
                                                            </LocalizationProvider>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                                {isError.bopaError && (<FormHelperText className='errorHelperTxt' id="bopa_date_error" sx={{ position: 'static !important' }}>The start date you have selected is in the past. If any invoices have been collected or paid then applicable BOPA, Levy or Retro invoices will have to be credited to the old Brand Owner</FormHelperText>)}
                                            </Item>

                                        </Grid>
                                        <Grid container sx={{ marginTop: '20px' }}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Item>
                                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                                        <LoadingButton
                                                            loading={loader}
                                                            loadingPosition="center"
                                                            endIcon=""
                                                            variant="contained"
                                                            type="submit" sx={{ marginRight: '15px' }}
                                                            //disabled={state.formValid || state.defaultmode === false}
                                                            // eslint-disable-next-line max-len
                                                            disabled={brand_container.edit_brand_owner ? (brand_container.isError.brand_owner_selected || brand_container.isError.brand_start_date) : (state.formValid || state.defaultmode === false)}
                                                        >Save</LoadingButton>
                                                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                    {snackBar.show && <SnackBarDialog
                        onShow={snackBar.show}
                        text={snackBar.text}
                        type={snackBar.type}
                        onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                    />}
                </form>

            </Modal>
        </>
    );
}

export default AddBrandDate;