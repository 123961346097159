import { Container, Box, Modal, Button, Grid, Checkbox, Popper, Grow, Paper, ClickAwayListener, MenuList, ButtonGroup, TextField, FormControl, FormHelperText, FormLabel } from '@mui/material'
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TableFilters } from "../../components/shared/data-table/TableFilters";
import { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { InputText } from "../../components/shared/form/InputText";
import { AutoComplete } from "../../components/shared/form/AutoComplete";
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange, filterResetFlagChange } from "../../store/slices/externalFilter";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import { warningModal, handleAddEditModal, handleAddEditPdcPriceModal, setNewPriceModal, setInvRepProductModal } from "../../store/slices/modals";
import WarningModal from "../../components/shared/modals/WarningModal";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { setRowPage, resetDataGrid, setPageNo } from '../../store/slices/dataGrid';
import { smaInput, setMemberCode } from "../../store/slices/supplierMemberAccounts";
import { hasRowData, setHasEditData, generalPdcProductInput, brandSearch, setSupplierProductCodeList, setLatestPdcData, resetPdcProduct } from "../../store/slices/generalPdcProduct";
import { hasTabEditPrice, setHasEditPriceData, setchannel_priceDateList, setLatestlatestEndPriceDate, setLatestlatestEndChargeDate, setLatestlatestEndPromoPriceDate, setNullPriceStartDate, setNullChargeStartDate, setNullPromoPriceStartDate, setDeleteList, setDeleteChargeList, setDeletePromoPriceList } from "../../store/slices/pricePdcProduct";
import { setCommonInput } from "../../store/slices/commonProduct";
import axiosProductDistribution from '../../services/utility/axios-product-distribution';
import { AutoCompleteStatic } from "../../components/shared/form/AutoCompleteStatic";
import AddEditPdc from "./addEditPdc/AddEditPdc";
import AddEditPdcPrice from "./addEditPdc/AddEditPdcPrice";
import PageLoader from "../../components/shared/PageLoader";
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import CommonPopUp from "./addEditPdc/pdc-tabs/CommonPopUp";
import { DataTablePDCService } from '../../components/shared/data-table/DataTablePDCService';

const IconMenuLink = ({ params, sendSnackBar, startLoading }) => {
    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [pageLoader, setPageLoader] = useState(false);

    const { showAddEditModal, showAddEditPriceModal, pdc_general_product_fields } = useSelector((state) => {
        return {
            showAddEditModal: state.modalActions.hideAddEditModal,
            showAddEditPriceModal: state.modalActions.hideAddEditPdcModal,
            editTabData: state.generalPdcProducts.editTabData,
            pdc_general_product_fields: state.generalPdcProducts
        }
    });
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'edit_general_pdc') {
            setEditGeneralPdcData(row);
        } else if (action === 'edit_price_pdc') {
            setEditPricePdcData(row);
        } else if (action === 'goto_product') {
            localStorage.setItem('gotoproduct_id', row.productId);
            localStorage.setItem('gotoproduct_name', row.pname);
            localStorage.setItem('gotoproduct_ocbc',row.outerCaseBarcode);
            localStorage.setItem('gotoproduct_flag', true);
            window.open("/products/products", "_blank");
        }
        setAnchorEl(null);
    };
    const setEditGeneralPdcData = async (row) => {
        dispatch(hasRowData(row))
        dispatch(setCommonInput(''))
        try {
            if (startLoading) {
                startLoading(true)
            }
            const { data } = await axiosProductDistribution.get(`pdcs/${row.id}`);
            if (data) {
                if (startLoading) {
                    startLoading(false)
                }
                dispatch(handleAddEditModal({
                    showAddEdit: !showAddEditModal
                }))
                dispatch(setHasEditData(data.resultMap.result))
                dispatch(brandSearch({
                    parent: 'pdc_supplier',
                    key: 'pdc_general_supplier',
                    value: data.resultMap.result.supplier ? data.resultMap.result.supplier : null
                }))
                dispatch(brandSearch({
                    parent: 'pdc_general_distribution_channel',
                    key: 'distribution_channel_selected',
                    value: data.resultMap.result.distributionChannel ? data.resultMap.result.distributionChannel : null
                }))
                dispatch(brandSearch({
                    parent: 'pdc_general_unit_measure',
                    key: 'unit_measure_selected',
                    value: data.resultMap.result.unitOfMeasure ? data.resultMap.result.unitOfMeasure : null
                }))
                dispatch(brandSearch({
                    parent: 'pdc_general_levy_scheme',
                    key: 'levy_scheme_selected',
                    value: data.resultMap.result.productValueLevyScheme ? data.resultMap.result.productValueLevyScheme : null
                }))
                dispatch(brandSearch({
                    parent: 'pdc_general_distribution_charge',
                    key: 'distribution_charge_selected',
                    value: data.resultMap.result.distributionChargeLevyScheme ? data.resultMap.result.distributionChargeLevyScheme : null
                }))
                dispatch(generalPdcProductInput({
                    key: 'pdc_gen_product',
                    value: data.resultMap.result.product
                }))
                dispatch(generalPdcProductInput({
                    key: 'pdc_general_active_p',
                    value: data.resultMap.result.checkPrices ? data.resultMap.result.checkPrices : false
                }))
                dispatch(handleAddEditPdcPriceModal({
                    showAddEditPrice: false
                }))
            }
        }
        catch (error) {
            if (sendSnackBar) {
                sendSnackBar(error)
            }
        }
    }

    const setEditPricePdcData = async (row) => {
        dispatch(setHasEditPriceData(row))
        dispatch(setLatestPdcData(''))
        dispatch(setNullPriceStartDate(''))
        dispatch(setLatestlatestEndPriceDate(''))
        dispatch(setLatestlatestEndChargeDate(''))
        dispatch(setLatestlatestEndPromoPriceDate(''))
        dispatch(setNullChargeStartDate(''))
        dispatch(setNullPromoPriceStartDate(''))
        dispatch(setDeleteList(''))
        dispatch(setDeleteChargeList(''))
        dispatch(setDeletePromoPriceList(''))
        dispatch(handleAddEditPdcPriceModal({
            showAddEditPrice: !showAddEditPriceModal
        }))
        // try {
        //     if(startLoading){
        //         startLoading(true)
        //     }
        //     const { data } =await axiosProductDistribution.get(`pdcs/${row.id}/pdcChannelPrices`);
        //      if (data) {
        //         if(startLoading){
        //             startLoading(false)
        //         }
        //         dispatch(handleAddEditPdcPriceModal({
        //             showAddEditPrice: !showAddEditPriceModal
        //         }))
        //         dispatch(setchannel_priceDateList(data.items)) 
        //     }
        // }
        // catch (error) {
        //     if (sendSnackBar) {
        //         startLoading(false)
        //         sendSnackBar(error)
        //     }
        // }
    }

    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'edit_general_pdc')}>Edit General Details</MenuItem>
            <MenuItem onClick={(e) => handleClose(e, 'edit_price_pdc')}>Edit Price Details</MenuItem>
            <MenuItem onClick={(e) => handleClose(e, 'goto_product')}>Go to Product</MenuItem>
        </Menu>
    </>)
}
function Pdc() {

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const columns = [
        {
            headerName: "ID",
            field: "spcid",
            flex: 1,
            minWidth: 200,
            hide: true
        },
        {
            headerName: "SPC",
            field: "code",
            flex: 1,
            width: 170
        },
        {
            headerName: "DC name",
            field: "dcName",
            flex: 1,
            width: 180
        },
        {
            headerName: "Supplier",
            field: "sname",
            flex: 1,
            width: 170
        },
        {
            headerName: "Product",
            field: "pname",
            flex: 1,
            width: 180
        },
        {
            headerName: "Brand",
            field: "bname",
            flex: 1,
            width: 180
        },
        {
            headerName: "O/C Barcode",
            field: "outerCaseBarcode",
            flex: 1,
            width: 170
        },
        {
            headerName: "Check Prices",
            field: "checkPrices",
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
            align: 'center',
        },
        {
            headerName: "Price (£)",
            field: "channelPrice",
            flex: 1,
            width: 170
        },
        {
            headerName: "Promo Price (£)",
            field: "promoPrice",
            flex: 1,
            width: 170
        },
        {
            headerName: "Charge",
            field: "channelCharge",
            flex: 1,
            width: 180
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} startLoading={pageLoader} />;
            }
        }
    ];

    const Item = styled(Paper)(({ theme }) => ({
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(2),
        boxShadow: "none",
        background: "none"
    }));

    const [state, setState] = useState({
        active_product: false,
        opengeneralPdcProductsModal: false,
        pageLoader: false,
        opengeneralPdcProductsPriceModal: false,
        handleSupplierMemberModal: '',
        pdcFilterBrandModal: false,
        defaultGrid: {
            pagetitle: "Product Distribution Channel",
            pdcActionButton: true,
            hasExport: true,
            addEditButton: true,
            apiUrl: 'pdcs',
            searchUrl: 'pdcs/filter'
        }
    });

    const pageLoader = (loader) => {
        setState({ ...state, pageLoader: loader })
    }

    // eslint-disable-next-line max-len
    const { pdcproductCategory, pdcproductBrand, pdcproduct, pdcsupplier, pdcsupplierCode, pdcDistributionChannel, addProductwarning, showAddEditModal, showAddEditPriceModal, sma_container, showNewPriceoneModal, searchProductModal, showNewProductModal } = useSelector((state) => {
        return {
            pdcproductCategory: state.externalFilter.pdc_product_category,
            pdcproductBrand: state.externalFilter.pdc_product_brand,
            pdcproduct: state.externalFilter.pdc_product,
            pdcsupplier: state.externalFilter.pdc_supplier,
            pdcsupplierCode: state.externalFilter.pdc_supplier_product_code,
            pdcDistributionChannel: state.externalFilter.pdc_distribution_channel,
            resetTable: state.dataGridTable,
            addProductwarning: state.modalActions.closeWaringAddProduct,
            showAddEditModal: state.modalActions.hideAddEditModal,
            showAddEditPriceModal: state.modalActions.hideAddEditPdcModal,
            sma_container: state.smas,
            showNewPriceoneModal: state.modalActions.priceModal,
            searchProductModal: state.commonProducts,
            showNewProductModal: state.modalActions.invrepProductModal
        }
    });

    const dispatch = useDispatch();

    const handleFilterInput = (value, id) => {
        //setState({ ...state, [id]: value });
        dispatch(setFilter({
            key: id,
            value: value ? value : null
        }))
    };

    const selectedAutoComplete = (value, id) => {
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    }

    const filterBtn = () => {
        dispatch(resetDataGrid());
        var dynamicFilterObjects = {};
        if (pdcsupplierCode !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "code": pdcsupplierCode
            }
        }
        if (pdcsupplier !== null && pdcsupplier !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "supplier": pdcsupplier
            }
        }
        if (pdcproduct !== null && pdcproduct !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "product": pdcproduct
            }
        }
        if (pdcproductCategory !== null && pdcproductCategory !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "category": pdcproductCategory
            }
        }
        if (pdcDistributionChannel !== null && pdcDistributionChannel !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "distributionChannel": pdcDistributionChannel
            }
        }
        if (pdcproductBrand !== null && pdcproductBrand !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "brand": pdcproductBrand
            }
        }
        dispatch(applyFilter({
            key: 'filterList',
            value: dynamicFilterObjects
        }))
        dispatch(filterFlagChange(true));
        dispatch(filterResetFlagChange(false));
        dispatch(setRowPage({ pageData: 10 }));
        dispatch(setPageNo({ page: 1 }));
    }
    const resetFilter = () => {
        if ((pdcsupplierCode || pdcproductCategory || pdcsupplier || pdcproductBrand || pdcDistributionChannel) != '') {
            dispatch(resetExternalFilter())
            dispatch(resetDataGrid());
            dispatch(setFilter({
                key: 'pdc_supplier',
                value: null
            }))
            dispatch(setFilter({
                key: 'pdc_product',
                value: null
            }))
            // dispatch(setFilter({
            //     key: 'pdc_product_brand',
            //     value: null
            // }))
            dispatch(filterResetFlagChange(true));
        }
    }
    const addPdcProductModal = () => {
        dispatch(resetPdcProduct())
        dispatch(handleAddEditModal({
            showAddEdit: !showAddEditModal
        }))
    }
    const hideNewPdcProductModal = (event) => {
        setState({ ...state, opengeneralPdcProductsModal: false })
    }
    const hideNewPdcProductPriceModal = (event) => {
        setState({ ...state, opengeneralPdcProductsPriceModal: false })
    }

    const getpricetabID = (param) => {
    }

    const CommonPopUpModal = (params, event) => {
        if (event === 'supplier') {
            setState({ ...state, handleSupplierMemberModal: event })
            dispatch(setNewPriceModal(true))
        }
        if (event === 'product') {
            setState({ ...state, handleSupplierMemberModal: '' })
            dispatch(setInvRepProductModal(true))
        }
        // if(event === 'brand') {
        //     setState({...state, pdcFilterBrandModal: true, handleSupplierMemberModal: event})
        // }
    }

    const hidePriceModal = (event, reason, type) => {
        if (type === 'supplier') {
            if (reason) {
                dispatch(setFilter({
                    key: 'pdc_supplier',
                    value: reason ? reason : null
                }))
            }
            dispatch(setNewPriceModal(event));
        }
        if (type === 'product') {
            if (reason) {
                console.log(reason);
                dispatch(setFilter({
                    key: 'pdc_product',
                    value: reason.id ? reason : null
                }))
            }
            dispatch(setInvRepProductModal(event));
        }
        // if(type === 'brand')
        // {
        //     if (reason) {
        //         dispatch(setFilter({
        //             key: 'pdc_product_brand',
        //             value: reason ? reason : null
        //         }))
        //     }
        //     setState({ ...state, pdcFilterBrandModal: event === false ? event : true })
        // }
    }

    const resetLookups = (params, event) => {
        if (event === 'supplier') {
            dispatch(setFilter({
                key: 'pdc_supplier',
                value: null
            }))
        }
        if (event === "product") {
            dispatch(setFilter({
                key: 'pdc_product',
                value: null
            }))
        }
        // if (event === 'brand') {
        //     dispatch(setFilter({
        //         key: 'pdc_product_brand',
        //         value: null
        //     }))
        // }
    }

    if (localStorage.getItem("gotopdc_flag") === "true")
    {
        dispatch(resetDataGrid());
        let pid = localStorage.getItem("gotopdc_id");
        let pname = localStorage.getItem("gotopdc_name");
        let ocbc = localStorage.getItem("gotopdc_ocbc");
        let bopid = localStorage.getItem("gotopdc_bopid");
        localStorage.setItem('gotopdc_flag', false);

        let dFObjects = {};
        dFObjects = {                            
            "product": {
                "id": pid,
                "name": pname,
                "outerCaseBarcode": ocbc,
                "brandOwnerProductId": bopid
            }
        }
        
        dispatch(setFilter({
            key: 'pdc_product',
            value: {
                "id": pid,
                "name": pname,
                "outerCaseBarcode": ocbc,
                "brandOwnerProductId": bopid
            }
        }));

        dispatch(applyFilter({
            key: 'filterList',
            value: dFObjects
        }))
        dispatch(filterFlagChange(true));
        dispatch(filterResetFlagChange(false));
        dispatch(setRowPage({ pageData: 10 }));
        dispatch(setPageNo({ page: 1 }));
    }

    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Product Distribution Channel" />
                <Box sx={{ marginBottom: 2.5 }}>
                    <TableFilters
                        filterFields={<>
                            {/* <Box component="div"><AutoCompleteStatic endPoint="v2/products/look-up" onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} id="pdc_product" label="Product" name="pdc_product" /></Box> */}
                            <Box component="div">
                                <FormLabel htmlFor="pdc_product" className="fontBold">Product</FormLabel>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={11}>
                                        <Item>
                                            <Box component="div" sx={{ paddingTop: '6px' }} >
                                                <FormControl size={'small'} fullWidth>
                                                    <TextField
                                                        inputProps={{ maxLength: 100 }}
                                                        size="small"
                                                        id="pdc_product"
                                                        value={(pdcproduct ? pdcproduct.name : '')}
                                                        // value={(invRepProduct ? invRepProduct.name : '')}
                                                        aria-describedby="pdc_product_error"
                                                        name="pdc_product"
                                                        disabled={true}
                                                        onChange={handleFilterInput}
                                                    />

                                                </FormControl>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} md={1} sx={{ padding: '0' }}>
                                        <Item>
                                            <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0, marginRight: '5px' }} onClick={(e) => resetLookups(e, 'product')} startIcon={<ClearIcon />}></Button>
                                            <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={(e) => CommonPopUpModal(e, 'product')} startIcon={<EditIcon />}></Button>
                                        </Item>
                                    </Grid>

                                </Grid>

                            </Box>
                            {/* <Box component="div"><AutoCompleteStatic endPoint="supplier/drop-down?dcType=7" onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} id="pdc_supplier" label="Supplier" name="pdc_supplier" /></Box> */}
                            <Box component="div">
                                {/* <FormLabel htmlFor="supplier_invoice_member_account" className="fontBold">Supplier</FormLabel>
                                    <Grid container alignItems="center" sx={{ paddingTop: '5px' }}>

                                        <Grid item xs={12} md={11}>
                                            <Item>
                                                <Box component="div">
                                                    <FormControl size={'small'} fullWidth>
                                                        <TextField
                                                            inputProps={{ maxLength: 100 }}
                                                            size="small"
                                                            id="invrpc_supplier_name"
                                                            value={(pdcsupplier ? pdcsupplier.name : '')}
                                                            aria-describedby="invrpc_supplier_name_error"
                                                            name="invrpc_supplier_name"
                                                            disabled={true}
                                                        />

                                                    </FormControl>
                                                </Box>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                            <Item>
                                                <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0, marginRight: '5px' }} onClick={(e) => resetLookups(e, 'supplier')} startIcon={<ClearIcon />}></Button>
                                                <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0 }}  onClick={(e) => CommonPopUpModal(e, 'supplier')}  startIcon={<EditIcon />}></Button>
                                            </Item>
                                        </Grid>

                                    </Grid> */}

                                <AutoCompleteStatic endPoint="/supplier/look-up" onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} id="pdc_supplier" label="Supplier" name="pdc_supplier" />
                            </Box>
                            <Box component="div"><AutoCompleteStatic endPoint="/v2/categories/parent-dropdown?level=0" onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} id="pdc_product_category" label="Category" name="pdc_product_category" /></Box>
                            <Box component="div"><AutoCompleteStatic endPoint="distributionChannels/drop-down" onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} id="pdc_distribution_channel" label="Distribution Channel" name="pdc_distribution_channel" /></Box>
                            <Box component="div"><AutoCompleteStatic endPoint="brands/look-up" onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} id="pdc_product_brand" label="Brand" name="pdc_product_brand" />
                            </Box>
                            {/* <Box component="div">
                                    <FormLabel htmlFor="supplier_invoice_member_account" className="fontBold">Brand</FormLabel>
                                    <Grid container alignItems="center" sx={{ paddingTop: '5px' }}>
                                        <Grid item xs={12} md={11}>
                                            <Item>
                                                <Box component="div">
                                                    <FormControl size={'small'} fullWidth>
                                                        <TextField
                                                            inputProps={{ maxLength: 100 }}
                                                            size="small"
                                                            id="pdc_product_brand"
                                                            value={(pdcproductBrand ? pdcproductBrand.name : '')}
                                                            aria-describedby="product_brand_error"
                                                            name="pdc_product_brand"
                                                            disabled={true}
                                                        />

                                                    </FormControl>
                                                </Box>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                            <Item>
                                                <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0, marginRight: '5px' }} onClick={(e) => resetLookups(e, 'brand')} startIcon={<ClearIcon />}></Button>
                                                <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={(e) => CommonPopUpModal(e, 'brand')} startIcon={<EditIcon />}></Button>
                                            </Item>
                                        </Grid>
                                    </Grid>
                                </Box> */}
                            <Box component="div"><InputText onChange={handleFilterInput} maxWidth={50} label="Supplier Product Code" id="pdc_supplier_product_code" size="small" /></Box>
                        </>}
                        filterBtns={
                            <>
                                <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                <Button variant="contained" onClick={filterBtn}>Apply</Button>
                            </>}>
                    </TableFilters>
                </Box>
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -3 } }}>
                    {/* <div className='table-actionable'>
                        <Button variant="contained" color="secondary" size='small' startIcon={<SendIcon />}>
                            Export as Excel
                        </Button>
                    </div> */}
                    <div className='table-actionable'>
                        <Button variant="contained" size='small' onClick={(e) => addPdcProductModal(e)} startIcon={<AddIcon />}>
                            New Product Distribution Channel
                        </Button>
                    </div>
                    {/* onClick={(e) => addProductModal(e)} */}
                </Box>
                <DataTablePDCService defaultGrid={state.defaultGrid} columns={columns}>

                </DataTablePDCService>
                {
                    showAddEditModal && (
                        <AddEditPdc size="medium_overlay"
                            onShow={showAddEditModal}
                            onHide={(e, reason) => hideNewPdcProductModal(e, reason)}

                        />
                    )
                }
                {
                    showAddEditPriceModal && (
                        <AddEditPdcPrice getpricetabID={getpricetabID}
                            onPriceShow={showAddEditPriceModal}
                            onHide={(e, reason) => hideNewPdcProductPriceModal(e, reason)}

                        />
                    )
                }
                {
                    addProductwarning && (
                        <WarningModal size="small_overlay" onShow={addProductwarning} />
                    )
                }

                {
                    showNewPriceoneModal && (
                        <CommonPopUp
                            onShow={showNewPriceoneModal} size="medium_overlay"
                            supplierMemberCode={{
                                title: 'Supplier Details',
                                type: 'supplier'
                            }}
                            // memberaccount={loadMemberAccountWithMemberId}
                            onHide={(e, reason) => hidePriceModal(e, reason, state.handleSupplierMemberModal)}
                        />
                    )
                }

                {
                    showNewProductModal && (
                        <CommonPopUp
                            onShow={showNewProductModal} size="medium_overlay"
                            onHide={(e, reason) => hidePriceModal(e, reason, 'product')}
                        />
                    )
                }

                {/* {
                    state.pdcFilterBrandModal && <CommonPopUp
                        onShow={state.pdcFilterBrandModal}
                        size="large_overlay"
                        brandDetails={{
                            title: 'Brand Details'
                        }}
                        onHide={(e, reason) => hidePriceModal(e, reason, state.handleSupplierMemberModal)}
                    />
                } */}

            </Container>

        </>
    );
}

export default Pdc;
