import { DataTable } from "../../components/shared/data-table/DataTable";
import { Container, Box, Modal, Button, Grid, Checkbox } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TableFilters } from "../../components/shared/data-table/TableFilters";
import { useEffect, useState } from "react";
import { InputText } from "../../components/shared/form/InputText";
import { } from '@mui/material';
import { ThreeStateCheckbox } from "../../components/shared/form/ThreeStateCheckbox";
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange, filterResetFlagChange } from "../../store/slices/externalFilter";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import AddEditProduct from "./addEditProduct/AddEditProduct";
import AddIcon from '@mui/icons-material/Add';
import { warningModal, handleAddEditUnitOfMeasures } from "../../store/slices/modals";
import WarningModal from "../../components/shared/modals/WarningModal";
import { setRowPage, resetDataGrid, setPageNo } from '../../store/slices/dataGrid'
import AddEditUnitOfMeasures from "./AddEditUnitOfMeasures/AddEditUnitOfMeasures";
import { useLayoutEffect } from "react";
import { DataTableUtilitiesService } from "../../components/shared/data-table/DataTableUtilitiesService";
import { addNewUOMData, hasUOMName, setUOPList, uomInput, setDeleteList, setUOMDeleteData } from '../../store/slices/uomProduct'
import axios_utilities from '../../services/utility/axios-utilities';
import SnackBarDialog from "../../components/shared/SnackBarDialog";
import PageLoader from "../../components/shared/PageLoader";

const IconMenuLink = ({ params, sendSnackBar, startLoading, onDelete }) => {
    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const { uom_container, setAddEditUnitOfMeasures } = useSelector((state) => {
        return {
            uom_container: state.uomProducts,
            setAddEditUnitOfMeasures: state.modalActions.hideAddEditUnitOfMeasures
        }
    });
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'edit') {
            handleEdit(row)
        } else if (action === 'delete') {
            if(onDelete){
                onDelete(row)
            }
        }
        setAnchorEl(null);
    };

    const handleEdit = async (row) => {
        dispatch(handleAddEditUnitOfMeasures({
            showAddEdit: !setAddEditUnitOfMeasures
        }))
        dispatch(addNewUOMData(row))
        dispatch(hasUOMName(true))
        dispatch(uomInput({
            key: 'uom_name',
            value: row.name
        }))
    }

    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'edit')}>Edit </MenuItem>
            {/* <MenuItem onClick={(e) => handleClose(e, 'delete')}>Delete</MenuItem> */}
        </Menu>
    </>)
}

function UnitOfMeasure() {

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const columns = [
        {
            headerName: "ID",
            field: "id",
            flex: 1,
            minWidth: 200,
        },
        {
            headerName: "Name",
            field: "name",
            flex: 1,
            width: 170
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} onDelete={deleteUOM} />;
            },
        }
    ];
    const [state, setState] = useState({
        openBrandModal: false,
        pageLoader: false,
        defaultGrid: {
            pagetitle: "Unit of measure",
            addEditButton: true,
            apiUrl: 'unitofmeasures/filter',
            searchUrl: 'unitofmeasures/filter'
        }
    });
    
    const { addProductwarning, setAddEditUnitOfMeasures, uom_container } = useSelector((state) => {
        return {
            addProductwarning: state.modalActions.closeWaringAddProduct,
            setAddEditUnitOfMeasures: state.modalActions.hideAddEditUnitOfMeasures,
            uom_container: state.uomProducts
        }
    });

    const dispatch = useDispatch();

    const handleFilterInput = (value, id) => {
        //setState({ ...state, [id]: value });
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    };

    // eslint-disable-next-line max-len
    const { measure_name } = useSelector((state) => {
        return {
            measure_name: state.externalFilter.measure_name
        }
    });
    const filterBtn = () => {
        dispatch(resetDataGrid());
        dispatch(applyFilter({
            key: 'filterList',
            value: {
                "name": measure_name
            }
        }))
        dispatch(filterFlagChange(true));
        dispatch(filterResetFlagChange(false));
        dispatch(setRowPage({ pageData: 10 }));
        dispatch(setPageNo({ page: 1 }));
    }
    const resetFilter = () => {
        if (measure_name !== '') {
            dispatch(resetExternalFilter())
            dispatch(resetDataGrid());
        }
            dispatch(filterResetFlagChange(true));
    }
    const addUnitOfMeasuresModal = () => {
        dispatch(handleAddEditUnitOfMeasures({
            showAddEdit: !setAddEditUnitOfMeasures
        }))
    }
    const handleUnitOfMeasuresModal = (event) => {
        setState({ ...state, openBrandModal: false })
    }

    const deleteUOM = (row) => {
        dispatch(setDeleteList(row))
        dispatch(setUOMDeleteData({
            key: 'uomStartDelete',
            value: true
        }))
        dispatch(warningModal({
            yesHide: !addProductwarning
        }))
    }

    useEffect(() => {
        if (uom_container.uomConfirmDelete) {
            deleteSingleRow()
        }
    }, [uom_container.uomConfirmDelete])

    const deleteSingleRow = async () => {
        if (uom_container.deleteList !== null) {
            try {
                setState({...state, pageLoader: true})
                const { data } = await axios_utilities.delete(`unitofmeasures/${uom_container.deleteList.id}`);
                if (data.success) {
                    try {
                        const { data } = await axios_utilities.get(`unitofmeasures`);
                        if (data !== null || data !== undefined) {
                            dispatch(setUOPList(data))
                            dispatch(setDeleteList(null))
                            setState({...state, pageLoader: false})
                            setSnackBar({ ...snackBar, show: true, text: "Unit of measure Delete Successfully", type: "success" })
                            resetSingleDeleteRowDispatch()
                            setTimeout(() => {
                                window.location.reload();
                            }, 1500);
                        }
                    }
                    catch (error) {
                        const { response: { data, status } } = error;
                        setState({...state, pageLoader: false})
                        resetSingleDeleteRowDispatch()
                        setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error", })
                    }
                }

            } catch (error) {
                const { response: { data, status } } = error;
                setState({...state, pageLoader: false})
                resetSingleDeleteRowDispatch()
                setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error", })
            }
        }
    }

    const resetSingleDeleteRowDispatch = () => {
        dispatch(setUOMDeleteData({
            key: 'uomStartDelete',
            value: false
        }))
        dispatch(setUOMDeleteData({
            key: 'uomConfirmDelete',
            value: false
        }))
    }

    useEffect(() => {
        /* load first first time */
        filterBtn();
    }, []);

    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Unit of measure" />
                <Box sx={{ marginBottom: 2.5 }}>
                    <TableFilters
                        filterFields={<>
                            <Box component="div"><InputText onChange={handleFilterInput} maxWidth={50} label="Name" id="measure_name" size="small" /></Box>
                        </>}
                        filterBtns={
                            <>
                                <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                <Button variant="contained" onClick={filterBtn}>Apply</Button>
                            </>}>
                    </TableFilters>
                </Box>
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -2.4 } }}>
                    {/* <div className='table-actionable'>
                        <Button variant="contained" color="secondary" size='small' startIcon={<SendIcon />}>
                            Export as Excel
                        </Button>
                    </div> */}
                    <div className='table-actionable'>
                        <Button variant="contained" size='small' onClick={(e) => addUnitOfMeasuresModal(e)} startIcon={<AddIcon />}>New Unit of Measure</Button>
                    </div>
                </Box>


                <DataTableUtilitiesService defaultGrid={state.defaultGrid} columns={columns}>

                </DataTableUtilitiesService>
                {
                    setAddEditUnitOfMeasures && (
                        <AddEditUnitOfMeasures
                            onShow={setAddEditUnitOfMeasures}
                            onHide={(e, reason) => handleUnitOfMeasuresModal(e, reason)}

                        />
                    )
                }
                {
                    addProductwarning && (
                        <WarningModal size="small_overlay" onShow={addProductwarning} />
                    )
                }
                {snackBar.show && <SnackBarDialog
                    onShow={snackBar.show}
                    text={snackBar.text}
                    type={snackBar.type}
                    onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                />}
            </Container>

        </>
    );
}

export default UnitOfMeasure; 
