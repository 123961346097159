import { Checkbox, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl } from '@mui/material'
import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { warningModal, handleAddEditModal } from "../../../store/slices/modals";
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import moment from "moment";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import axios_programmes from '../../../services/utility/axios-programmes';
import { setprogrammeEffectiveDate, programmeInput, getIsError, setprogrammeDateList, resetProgramme } from "../../../store/slices/programme";
import SnackBarDialog from "../../../components/shared/SnackBarDialog";
import { filterFlagChange, filterResetFlagChange, applyFilter } from '../../../store/slices/externalFilter';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

const defaultValues = {
    loader: false,
    formValid: true,
    defaultmode: false,
    newProgrammesData: [],
};

function AddEditProgrammesDetail({ onShow, size = '', onHide }) {
    
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const [isError, isSetError] = useState({
        bopaError: false,
    })

    const [state, setState] = useState(defaultValues);
    const [latestEndDate, setLatestEndDate] = useState();
    const [loader, setLoader] = useState(false)
    const [selectedSupplier, setselectedSupplier] = useState(null);
    const [open, setOpen] = useState(onShow);
    const { handleWarning, programme_container, filter_data } = useSelector((state) => {
        return {
            handleWarning: state.modalActions.closeWaringAddProduct,
            programme_container: state.programmes,
            filter_data: state.externalFilter
        }
    });
    
    const dispatch = useDispatch();

    const handleClose = () => {
        //setState({ ...state, defaultmode: false })
        // if (onHide) {
        //     dispatch(setprogrammeEffectiveDate({
        //         key: 'programme_start_date',
        //         value: ""
        //     }))
        //     dispatch(setprogrammeEffectiveDate({
        //         key: 'programme_end_date',
        //         value: ""
        //     }))
        //     onHide(false)
        // }
        setLatestEndDate('')
        // dispatch(warningModal({
        //     yesHide: !handleWarning
        // }))
        dispatch(handleAddEditModal({
            showAddEdit: false
        }));
        dispatch(resetProgramme());

    };

    const handleCancel = () => {
        setState({ ...state, defaultmode: false })
		dispatch(warningModal({
            yesHide: !handleWarning
        }))
    }

    const handleStartDate = (event) => {
        dispatch(setprogrammeEffectiveDate({
            key: 'programme_start_date',
            value: (event === null) ? null : moment(event,'DD/MM/YYYY') 
        }))
        dispatch(setprogrammeEffectiveDate({
            key: 'programme_end_date',
            value: null
        }))
        setLatestEndDate(moment(event).add(1, 'days').format('DD/MM/YYYY'))
        if (event === null) {
            dispatch(getIsError({ key: 'programme_start_date', value: true }))
        }
        setState({ ...state, defaultmode: true })
        //setLatestEndDate(event)
    }
    const handleEndDate = (event) => {
        if(programme_container.addNewprogrammeData) {
            setState({ ...state, defaultmode: true })
        }
        dispatch(setprogrammeEffectiveDate({
            key: 'programme_end_date',
            value: (event === null) ? null : moment(event,'DD/MM/YYYY') 
        }))
    }
    const handleInputChange = (event) => {
        const { value, name } = event.target;
        dispatch(programmeInput({
            key: name,
            value
        }))
        setState({ ...state, defaultmode: true })
    }

    useEffect(() => {
        if (moment(programme_container.programme_start_date).isBefore(moment(), "day")) {
            isSetError({ ...isError, bopaError: true })
        } else {
            isSetError({ ...isError, bopaError: false })
        }
    }, [programme_container.programme_start_date])

    useEffect(() => {
        if ((programme_container.add_edit_programme_name === null || programme_container.add_edit_programme_name === "") || (programme_container.programme_start_date === null || programme_container.programme_start_date === 'Invalid date' || programme_container.programme_start_date === "")) {
            setState({ ...state, formValid: true, defaultmode: false })
        } else {
            setState({ ...state, formValid: false, defaultmode: true })
        }
    }, [programme_container.add_edit_programme_name, programme_container.programme_start_date])

    useEffect(() => {
        const { 
            add_edit_programme_name,
            programme_start_date
        } = programme_container.isError
        if (!add_edit_programme_name && !programme_start_date) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true })
        }
    }, [programme_container.isError])

    // useEffect(() => {
    //     const {
    //         add_edit_programme_name,
    //         programme_start_date
    //     } = programme_container.isError

    //     if (programme_container.programme_end_date !== "" && programme_container.programme_end_date !== "Invalid date") {
    //         setState({ ...state, formValid: false })
    //     } else if (programme_container.programme_end_date === "" || programme_container.programme_end_date === "Invalid date") {
    //         setState({ ...state, formValid: true })
    //     }
    // }, [programme_container.programme_end_date])


    const handleSubmit = async (event) => {
        event.preventDefault();
        let programmesData = null;
        if (!state.formValid) {
            if (moment(programme_container.programme_start_date).format('DD/MM/YYYY') === 'Invalid date' || (programme_container.programme_end_date !== "" && programme_container.programme_end_date !== null && moment(programme_container.programme_end_date).format('DD/MM/YYYY') === 'Invalid date')) {
                setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
            }
            else if (programme_container.programme_end_date !== null && programme_container.programme_end_date !== "" && (programme_container.programme_start_date > programme_container.programme_end_date)) {
                setSnackBar({ ...snackBar, show: true, text: "End date is less than Start Date!", type: "error", });
            }
            else
            {
                programmesData = {
                    "id": programme_container.addNewprogrammeData ? programme_container.addNewprogrammeData.id : null,
                    "name": programme_container.add_edit_programme_name,
                    "startDate": programme_container.programme_start_date ? moment(programme_container.programme_start_date).format('DD/MM/YYYY') : null,
                    "endDate": programme_container.programme_end_date ? moment(programme_container.programme_end_date).format('DD/MM/YYYY') : null
                }
                try {
                    setLoader(true)
                    setState({ ...state, loader: true })
                    const { data, status } = programme_container.addNewprogrammeData ? await axios_programmes.put(`programmes/${programme_container.addNewprogrammeData.id}`, { ...programmesData }) : await axios_programmes.post(`programmes`, { ...programmesData });
                    if (data.success === true && data.resultMap) {
                        setLoader(false)
                        dispatch(setprogrammeDateList(data.resultMap.result))
                        setSnackBar({...snackBar, show:true, text: `Programme ${programme_container.addNewprogrammeData ? 'updated': 'added'} successfully`, type: "success", })
                        setState({ ...state, loader: false, formValid: true, newProgrammesData: data.resultMap});
                        setTimeout(() => {
                            //handleClose()
                            // window.location.reload();
                            if (filter_data.filterList.name === "" || filter_data.filterList.name === undefined) {
                                dispatch(applyFilter({
                                    key: 'filterList',
                                    value: { "name": '' }
                                }));
                            }                            
                            dispatch(handleAddEditModal({
                                showAddEdit: false
                            }));
                            dispatch(filterFlagChange(true));
                            dispatch(filterResetFlagChange(false));
                        }, 1500);
                    }
                } catch (error) {
                    setLoader(false)
                    const { response: { data, status } } = error;
                    setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error", });
                    setState({ ...state, loader: false });
                }
            }
            
        }
    };
   
    return (
        <>
            { snackBar.show && <SnackBarDialog 
            onShow={snackBar.show} 
            text={snackBar.text} 
            type={snackBar.type} 
            onHide={(e) => {setSnackBar({...snackBar, show: e})}}
            />}

            <Modal
                open={open}
                aria-labelledby="add-edit-price-title"
                aria-describedby="add-edit-price-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Box component="div" className="overlay" id="addEditModal">
                        <div className={`overlay-box ${size ? size : ''}`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-header text-center"> Programme Details
                                        <CancelIcon className="closeBtn" onClick={handleClose} />
                                    </div>
                                    <div className="overlay-content">
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={3} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="add_edit_programme_name" className="fontSemiBold mandatory">Name</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7}>
                                                        <Item >
                                                            <Box component="div" className=''>
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        inputProps={{ maxLength: 50 }}
                                                                        type="text"
                                                                        size="small"
                                                                        id="add_edit_programme_name"
                                                                        aria-describedby="add_edit_programme_name_error"
                                                                        onChange={(e) => handleInputChange(e)}
                                                                        value={programme_container.add_edit_programme_name}
                                                                        error={programme_container.isError.add_edit_programme_name}
                                                                        min={1}
                                                                        name="add_edit_programme_name"
                                                                        className="inputNumber"
                                                                        //onBlur={(e) => checkQunique(e, 'isAggregationGroupNameValid?name')}
                                                                    />
                                                                    {programme_container.isError.add_edit_programme_name && (<FormHelperText className='errorHelperTxt' id="add_edit_programme_name_error">This field is mandatory</FormHelperText>)}
                                                                </FormControl>
                                                            </Box>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={3} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="price_start_date" className="fontSemiBold mandatory">Start Date</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7}>
                                                        <Item>
                                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                <DesktopDatePicker
                                                                    value={programme_container.programme_start_date}
                                                                    onChange={(e) => { handleStartDate(e) }}
                                                                    disabled={programme_container.addNewprogrammeData ? true : false}
                                                                    name="price_start_date"
                                                                    inputFormat="DD/MM/YYYY"
                                                                    // eslint-disable-next-line max-len
                                                                    renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                        <Box component="div" className='date-picker-input'>
                                                                            <FormControl size={'small'} fullWidth>
                                                                                <TextField size="small" id="price_start_date"
                                                                                    ref={inputRef} {...inputProps}
                                                                                    placeholder=""
                                                                                    aria-describedby="price_start_date_error"
                                                                                    error={programme_container.isError.programme_start_date}
                                                                                    autoComplete="off"
                                                                                />
                                                                                {programme_container.isError.programme_start_date && (<FormHelperText className='errorHelperTxt' id="price_start_date_error">Start date is missing</FormHelperText>)}
                                                                                <Box className='date-picker-icon'>
                                                                                    {InputProps?.endAdornment}
                                                                                </Box>
                                                                            </FormControl>
                                                                        </Box>
                                                                    )}
                                                                />
                                                            </LocalizationProvider>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={3} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="price_end_date" className="fontSemiBold">End Date</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7}>
                                                        <Item>
                                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                <DesktopDatePicker
                                                                    value={programme_container.programme_end_date}
                                                                    disabled={(programme_container.programme_start_date === null || programme_container.programme_start_date === '') ? true : false}
                                                                    onChange={(e) => { handleEndDate(e) }}
                                                                    name="price_end_date"
                                                                    minDate={latestEndDate ? moment(latestEndDate, 'DD/MM/YYYY') : moment(programme_container.programme_start_date, 'DD/MM/YYYY').add(1, 'days')}
                                                                    inputFormat="DD/MM/YYYY"
                                                                    // eslint-disable-next-line max-len
                                                                    renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                        <Box component="div" className='date-picker-input'>
                                                                            <FormControl size={'small'} fullWidth>
                                                                                <TextField size="small" id="price_end_date"
                                                                                    ref={inputRef} {...inputProps}
                                                                                    placeholder=""
                                                                                    aria-describedby="price_end_date_error"
                                                                                    autoComplete="off"
                                                                                />
                                                                                {isError.endDate && (<FormHelperText id="price_end_date_error">Error Here</FormHelperText>)}
                                                                                <Box className='date-picker-icon'>
                                                                                    {InputProps?.endAdornment}
                                                                                </Box>
                                                                            </FormControl>
                                                                        </Box>
                                                                    )}
                                                                />
                                                            </LocalizationProvider>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                        <Grid container sx={{ marginTop: '20px' }}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Item>
                                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                                        <LoadingButton
                                                                loading={state.loader}
                                                                loadingPosition="center"
                                                                endIcon=""
                                                                variant="contained"
                                                                type="submit" sx={{ marginRight: '15px' }}
                                                                //disabled={state.formValid || state.defaultmode === false}
                                                                // eslint-disable-next-line max-len
                                                                disabled={programme_container.addNewprogrammeData ? (programme_container.isError.add_edit_programme_name || programme_container.isError.programme_start_date) : (state.formValid || state.defaultmode === false)}
                                                            >Save</LoadingButton>
                                                        <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </form>
            </Modal>
        </>
    );
}

export default AddEditProgrammesDetail;