import { Checkbox, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl } from '@mui/material'
import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { warningModal, handleAddEditModal } from "../../../store/slices/modals";
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import moment from "moment";
import LoadingButton from '@mui/lab/LoadingButton';
import axiosRetroDiscountSchemes from '../../../services/utility/axios-retro-discount-schemes';
import { hasEdit, hasEditData, aggregationGroupInput, setAutocomplete, getIsError, resetaggregationGroup } from "../../../store/slices/aggregationGroup";
import SnackBarDialog from "../../../components/shared/SnackBarDialog";
import { filterFlagChange, filterResetFlagChange } from '../../../store/slices/externalFilter';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

const defaultValues = {
    loader: false,
    formValid: true,
    defaultmode: false,
    newaggregationgroupData: [],
};

function AddEditAggregationDetail({ onShow, size = '', onHide }) {
    
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const [state, setState] = useState(defaultValues);
    const [selectedSupplier, setselectedSupplier] = useState(null);
    const [open, setOpen] = useState(onShow);
    const { handleWarning, aggregation_group_fields } = useSelector((state) => {
        return {
            handleWarning: state.modalActions.closeWaringAddProduct,
            aggregation_group_fields: state.aggregationGroups
        }
    });
    
    const dispatch = useDispatch();

    const handleClose = () => {
        //setState({ ...state, defaultmode: false })
        dispatch(handleAddEditModal({
            showAddEdit: false
        }))
        dispatch(resetaggregationGroup())
    };

    const handleCancel = (event) => {
        setState({ ...state, defaultmode: false })
		dispatch(warningModal({
            yesHide: !handleWarning
        }))
    }

    // const checkQunique = async (event, columnType) => {
    //     const { value, name } = event.target;
    //     axiosRetroDiscountSchemes.get(`retroAggregationGroups/${columnType}=${value}`).then(function (response) {
    //         // handle success
    //         const { status, data } = response;
    //         if (status === 200) {
    //             if (data.isUnique !== true) {
    //                 dispatch(getIsError({ key: name, value: true }))
    //             } else {
    //                 dispatch(getIsError({ key: name, value: false }))
    //             }
    //         }
    //     }).catch(function (error) {
    //         // handle error
                //console.log(error)
    //     });
    // }


    const handleInputChange = (event) => {
        const { value, name } = event.target;
        dispatch(aggregationGroupInput({
            key: name,
            value
        }))
        setState({ ...state, defaultmode: true })
    }

    useEffect(() => {
        if(aggregation_group_fields.hasEditData) {
            if (aggregation_group_fields.aggregation_group_name === null || aggregation_group_fields.aggregation_group_name === "") {
                dispatch(getIsError({ key: 'aggregation_group_name', value: true }))
            } else {
                dispatch(getIsError({ key: 'aggregation_group_name', value: false }))
            }
        }
    }, [aggregation_group_fields.hasEditData])

    useEffect(() => {
        const { 
            aggregation_group_name
        } = aggregation_group_fields.isError
        if (!aggregation_group_name) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true })
        }


    }, [aggregation_group_fields.isError])

    const handleSubmit = async (event) => {
        event.preventDefault();
        let productData = null;
        if (!state.formValid) {
            productData = {
                "name": aggregation_group_fields.aggregation_group_name
            }
            try {
                setState({ ...state, loader: true })
                const { data, status } = aggregation_group_fields.hasEditData ? await axiosRetroDiscountSchemes.put(`retroAggregationGroups/${aggregation_group_fields.hasEditData.id}`, { ...productData }) : await axiosRetroDiscountSchemes.post(`retroAggregationGroups`, { ...productData });
                if (data.success === true && data.resultMap) {
                    setSnackBar({...snackBar, show:true, text: `Aggregation group ${aggregation_group_fields.hasEditData ? 'Edited': 'Added'} Successfully!`, type: "success", })
                    setState({ ...state, loader: false, formValid: true, newaggregationgroupData: data.resultMap})
                    setTimeout(() => {
                        //handleClose()
                        // window.location.reload();
                        dispatch(handleAddEditModal({
                            showAddEdit: false
                        }))
                        dispatch(filterFlagChange(true));
                        dispatch(filterResetFlagChange(false));
                    }, 1500);
                }
            } catch (error) {
                const { response: { data, status } } = error;
                setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error", })
                setState({ ...state, loader: false })
            }
        }
    };

    
    return (
        <>
            { snackBar.show && <SnackBarDialog 
            onShow={snackBar.show} 
            text={snackBar.text} 
            type={snackBar.type} 
            onHide={(e) => {setSnackBar({...snackBar, show: e})}}
            />}

            <Modal
                open={open}
                aria-labelledby="add-edit-price-title"
                aria-describedby="add-edit-price-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Box component="div" className="overlay" id="addEditModal">
                        <div className={`overlay-box ${size ? size : ''}`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-header text-center"> Aggregation Group Details
                                        <CancelIcon className="closeBtn" onClick={handleClose} />
                                    </div>
                                    <div className="overlay-content">
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="aggregation_group_name" className="fontSemiBold mandatory">Name</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Box component="div" className=''>
                                                                <FormControl size="small">
                                                                    <TextField
                                                                        inputProps={{ maxLength: 50 }}
                                                                        type="text"
                                                                        size="small"
                                                                        id="aggregation_group_name"
                                                                        aria-describedby="aggregation_group_name_error"
                                                                        onChange={(e) => handleInputChange(e)}
                                                                        value={aggregation_group_fields.aggregation_group_name}
                                                                        error={aggregation_group_fields.isError.aggregation_group_name}
                                                                        min={1}
                                                                        name="aggregation_group_name"
                                                                        className="inputNumber"
                                                                        //onBlur={(e) => checkQunique(e, 'isAggregationGroupNameValid?name')}
                                                                    />
                                                                    {aggregation_group_fields.isError.aggregation_group_name && (<FormHelperText className='errorHelperTxt' id="aggregation_group_name_error">This field is mandatory</FormHelperText>)}
                                                                </FormControl>
                                                            </Box>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item> 
                                        </Grid>
                                        <Grid container sx={{ marginTop: '20px' }}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Item>
                                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                                        <LoadingButton
                                                                loading={state.loader}
                                                                loadingPosition="center"
                                                                endIcon=""
                                                                variant="contained"
                                                                type="submit" sx={{ marginRight: '15px' }}
                                                                //disabled={state.formValid || state.defaultmode === false}
                                                                disabled={aggregation_group_fields.hasEditData ? aggregation_group_fields.isError.aggregation_group_name : (state.formValid || state.defaultmode === false)}
                                                            >Save</LoadingButton>
                                                        <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </form>
            </Modal>
        </>
    );
}

export default AddEditAggregationDetail;