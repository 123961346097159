import { DataTable } from "../../components/shared/data-table/DataTable";
import { Container, Box, Modal, Button, Grid, Checkbox } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TableFilters } from "../../components/shared/data-table/TableFilters";
import { useEffect, useState } from "react";
import { InputText } from "../../components/shared/form/InputText";
import { } from '@mui/material';
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange, filterResetFlagChange } from "../../store/slices/externalFilter";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import AddIcon from '@mui/icons-material/Add';
import { warningModal, handleAddEditModal } from "../../store/slices/modals";
import WarningModal from "../../components/shared/modals/WarningModal";
import { setRowPage, resetDataGrid, setPageNo } from '../../store/slices/dataGrid'
import { AutoCompleteStatic } from "../../components/shared/form/AutoCompleteStatic";
import { useLayoutEffect } from "react";
import moment from 'moment';
import { DataTableProgrammes } from "../../components/shared/data-table/DataTableProgrammes";
import { setNewprogrammeData, programmeInput, setprogrammeEffectiveDate, resetProgramme } from "../../store/slices/programme";
import SnackBarDialog from "../../components/shared/SnackBarDialog";
import PageLoader from "../../components/shared/PageLoader";
import AddEditProgrammesDetail from "./AddEditProgrammes/AddEditProgrammesDetail";

const IconMenuLink = ({ params }) => {
    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { programme_container, showAddEditModal } = useSelector((state) => {
        return {
            programme_container: state.programmes,
            showAddEditModal: state.modalActions.hideAddEditModal
        }
    });
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'edit') {
            dispatch(handleAddEditModal({
                showAddEdit: !showAddEditModal
            }))
            handleEdit(row);
        } 
        setAnchorEl(null);
    };

    const handleEdit = async (row) => {
        dispatch(setNewprogrammeData(row))
        dispatch(programmeInput({
            key: 'add_edit_programme_name',
            value: row.name
        }))
        dispatch(setprogrammeEffectiveDate({
            key: 'programme_start_date',
            value: row.startDate ? moment(row.startDate,'DD/MM/YYYY') : ""
        }))
        dispatch(setprogrammeEffectiveDate({
            key: 'programme_end_date',
            value: row.endDate ? moment(row.endDate,'DD/MM/YYYY') : ""
        }))

    }

    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'edit')}>Edit </MenuItem>

        </Menu>
    </>)
}

function Programmes() {
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const columns = [
        {
            headerName: "ID",
            field: "id",
            flex: 1,
            minWidth: 200,
            hide: true
        },
        {
            headerName: "Name",
            field: "name",
            flex: 2,
            width: 200
        },
        {
            headerName: "Start Date",
            field: "startDate",
            flex: 1,
            width: 100
        },
        {
            headerName: "End Date",
            field: "endDate",
            flex: 1,
            minWidth: 100
        },         
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} />;
            },
        }
    ];
    const [state, setState] = useState({
        
        openProgrammesModal: false,
        pageLoader: false,
        defaultGrid: {
            pagetitle: "Programmes",
            addEditButton: true,
            apiUrl: 'programmes',
            searchUrl: 'programmes/filter'
        }
    });
    
    const pageLoader = (loader) => {
        setState({...state, pageLoader: loader})
    }

    const { addProductwarning, showAddEditModal } = useSelector((state) => {
        return {
            addProductwarning: state.modalActions.closeWaringAddProduct,
            showAddEditModal: state.modalActions.hideAddEditModal
        }
    });

    const dispatch = useDispatch();

    const handleChange = (checked, name) => {
        setState({ ...state, [name]: checked })
        dispatch(setFilter({
            key: name,
            value: checked
        }))
    };

    const handleFilterInput = (value, id) => {
        //setState({ ...state, [id]: value });
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    };

    // eslint-disable-next-line max-len
    const { programmeName } = useSelector((state) => {
        return {
            programmeName: state.externalFilter.programme_name,
            
        }
    });
    const filterBtn = () => {
        
        dispatch(resetDataGrid());
        dispatch(applyFilter({
            key: 'filterList',
            value: {
                "name": programmeName ? programmeName : ""            
                
            }
        }));
       
        dispatch(filterFlagChange(true));
        dispatch(filterResetFlagChange(false));
        dispatch(setRowPage({ pageData: 10 }));
        dispatch(setPageNo({ page: 1 }));
    }
    const resetFilter = () => {
            if ((programmeName) !== '') {    
            dispatch(resetExternalFilter())
            dispatch(resetDataGrid());
        }
            dispatch(filterResetFlagChange(true));
    }
    const addProgrammesModal = () => {
        dispatch(resetProgramme())
        dispatch(handleAddEditModal({
            showAddEdit: !showAddEditModal
        }))
    }
    const handleProgrammesModal = (event) => {
        setState({ ...state, openProgrammesModal: false })
    }

    

    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Programmes" />
                <Box sx={{ marginBottom: 2.5 }}>
                    <TableFilters
                        filterFields={<>
                            <Box component="div"><InputText onChange={handleFilterInput} maxWidth={50} label="Name" id="programme_name" size="small" /></Box>
                        </>}
                        filterCheckboxes={<>
                            
                        </>}
                        filterBtns={
                            <>
                                <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                <Button variant="contained" onClick={filterBtn}>Apply</Button>
                            </>}>
                    </TableFilters>
                </Box>
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -2.4 } }}>
                    {/* <div className='table-actionable'>
                        <Button variant="contained" color="secondary" size='small' startIcon={<SendIcon />}>
                            Export as Excel
                        </Button>
                    </div> */}
                    <div className='table-actionable'>
                        <Button variant="contained" size='small' onClick={(e) => addProgrammesModal(e)} startIcon={<AddIcon />}>New Programme</Button>
                    </div>
                </Box>


                <DataTableProgrammes defaultGrid={state.defaultGrid} columns={columns}>

                </DataTableProgrammes>
                {
                    showAddEditModal && (
                        <AddEditProgrammesDetail
                            onShow={showAddEditModal} size="medium_overlay"
                            onHide={(e, reason) => handleProgrammesModal(e, reason)}

                        />
                    )
                }
                {
                    addProductwarning && (
                        <WarningModal size="small_overlay" onShow={addProductwarning} />
                    )
                }
                {snackBar.show && <SnackBarDialog
                    onShow={snackBar.show}
                    text={snackBar.text}
                    type={snackBar.type}
                    onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                />}
            </Container>

        </>
    );
}

export default Programmes; 
