import { Checkbox, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl, RadioGroup, Radio, Autocomplete } from '@mui/material'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import axios from '../../../../services/utility/axios-retro-discount-schemes';
import PageLoader from '../../../../components/shared/PageLoader';
import { setRetroState, getIsError } from '../../../../store/slices/retrodiscountschemes';
import { handleModalState } from '../../../../store/slices/modals';
import axios_programmes from '../../../../services/utility/axios-programmes';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

function AddEditProgrammes({ onShow, size = '', onHide, onSubmitDate }) {
    const [state, setState] = useState({
        dueDate: '',
        payDateDescription: '',
        onTrade: false,
        defaultmode: false,
        formValid: true,
        discountabl_bopa: false,
        pageLoader: false
    })

    const [included, setIncluded] = useState(true);
    
    const { retro_fields } = useSelector((state) => {
        return {
            retro_fields: state.retrodiscountscheme,
        }
    });
    const dispatch = useDispatch();

    const [programmes, setProgrammes] = useState({
        programmes_options: [],
        programme_selected: retro_fields.hasEditProgrammes ? retro_fields.hasEditProgrammes.programme : null
    })
    const [isError, setIsError] = useState({
        programme_selected: false
    })

    const handleChange = (event) => {
        if(retro_fields.hasEditProgrammes) {
            setState({ ...state, defaultmode: true })
        }
        setIncluded(event.target.checked);
    };

    useEffect(() => {
        const fetchMyAPI = async () => {
            if (programmes.programmes_options.length === 0) {
                axios_programmes.get('programmes/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data.filter((value, index, self) => self.findIndex((m) => m.name === value.name) === index);
                            setProgrammes({ ...programmes, programmes_options: unique })
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
        }
        fetchMyAPI();
    }, [])
    

    const [open, setOpen] = useState(onShow);

    const handleClose = () => {
        //setState({ ...state, defaultmode: false })
        dispatch(handleModalState({
            key: 'AddEditProgrammesModal',
            value: false
        }))
        dispatch(setRetroState({
            key: 'hasEditProgrammes',
            value: null
        }))
        dispatch(getIsError({ key: 'programme_selected', value: false }))
    };

    const handleSelf = () => {
        handleClose()
    }

    useEffect(() => {
        if (programmes.programme_selected === null || programmes.programme_selected === "") {
            setState({ ...state, formValid: true, defaultmode: false})
        } else {
            setState({ ...state, formValid: false, defaultmode: true})
        }
    }, [programmes.programme_selected])

    useEffect(() => {
        const {
            programme_selected
        } = retro_fields.isError
        if (!programme_selected) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true })
        }
    }, [retro_fields.isError])

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (programmes.programme_selected === null && isError.programme_selected === false) {
            setIsError({ ...isError, programme_selected: true })
        } else {
            addPayDate()

        }

    }
    const addPayDate = async () => {
        let payload = null;
        const id = (retro_fields.hasEditData) ? (retro_fields.hasEditData.id) : retro_fields.editTabData.id
        if (id && retro_fields.hasEditProgrammes === null) {
            // payload = {
            //     "programme": programmes.programme_selected ? {id: programmes.programme_selected.id} : "",
            //     included,
            //     "retroDiscountScheme": id
            // }
            payload = {
                // "id": retro_fields.hasEditProgrammes.id,
                "programName": programmes.programme_selected ? programmes.programme_selected.name  : "",
                // included,
                // "retroDiscountScheme": retro_fields.hasEditProgrammes.retroDiscountScheme
            }
        } else if (retro_fields.hasEditProgrammes !== null) {
            payload = {
                // "id": retro_fields.hasEditProgrammes.id,
                "programName": programmes.programme_selected ? programmes.programme_selected.name  : "",
                // included,
                // "retroDiscountScheme": retro_fields.hasEditProgrammes.retroDiscountScheme
            }
            // payload = {
            //     "id": retro_fields.hasEditProgrammes.id,
            //     "programme": programmes.programme_selected ? {id: programmes.programme_selected.id}  : "",
            //     included,
            //     "retroDiscountScheme": retro_fields.hasEditProgrammes.retroDiscountScheme
            // }
        }

        try {
            setState({ ...state, pageLoader: true })
            // const url = ((retro_fields.hasEditData !== null || retro_fields.editTabData !== null) && retro_fields.hasEditProgrammes === null) ? 'addprogrammes' : 'updateprogrammes';

            const { data } = await axios.post(`/ImportedRetroDiscount/addProgram/${id}`, payload);
            // const { data } = url === 'addprogrammes' ? await axios.post(`/retrodiscount/${id}/${url}`, payload) : await axios.put(`/retrodiscount/${id}/${url}`, payload);

            if (data) {
                onSubmitDate({ text: data.message, type: "success" })
                setState({ ...state, pageLoader: false })
                handleClose()
            }

        } catch (error) {
            const { response: { data, status } } = error;
            if (!data) {
                onSubmitDate({ text: error.message, type: "error" })
            } else {
                onSubmitDate({ text: data.message, type: "error" })
            }
            setState({ ...state, pageLoader: false })
            handleClose()
        }
    }

    useEffect(() => {
        if (retro_fields.hasEditProgrammes !== null) {
            setProgrammes({ ...programmes, programme_selected: retro_fields.hasEditProgrammes.programme })
            // setIncluded(retro_fields.hasEditProgrammes.included)
        }
    }, [])

    return (
        <>


            <Modal
                open={open}
                aria-labelledby="add-edit-price-title"
                aria-describedby="add-edit-price-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Box component="div" className="overlay" id="addEditModal">
                        {state.pageLoader && <PageLoader />}
                        <div className={`overlay-box ${size ? size : ''}`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-header text-center"> programmes
                                        <CancelIcon className="closeBtn" onClick={handleClose} />
                                    </div>
                                    <div className="overlay-content">
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            <FormLabel htmlFor="type" className="fontSemiBold mandatory">Programmes</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Autocomplete
                                                                defaultValue={programmes.programme_selected ? programmes.programme_selected : null}
                                                                id="programmes_search"
                                                                options={programmes.programmes_options}
                                                                getOptionLabel={(option) => option.name || ""}
                                                                componentsProps={{
                                                                    paper: {
                                                                        sx: {
                                                                            fontSize: '0.85rem'
                                                                        }
                                                                    }
                                                                }}
                                                                onChange={(event, newValue) => {
                                                                    (isError['programme_selected'] === null || isError['programme_selected'] === "") ? setIsError({ ...isError, programme_selected: true }) : setIsError({ ...isError, programme_selected: false })
                                                                    if (event.target.value !== undefined) {
                                                                        setState({ ...state, defaultmode: true })
                                                                        setProgrammes({ ...programmes, programme_selected: newValue })
                                                                        dispatch(getIsError({ key: 'programme_selected', value: false }))
                                                                    } else {
                                                                        dispatch(getIsError({ key: 'programme_selected', value: true }))
                                                                    }
                                                                }}
                                                                renderInput={(params) =>
                                                                    <FormControl size={'small'} fullWidth>
                                                                        <div className="input-group">
                                                                            <TextField {...params}
                                                                                size="small"
                                                                                id="programmes"
                                                                                aria-describedby="programmes-error"
                                                                                error={isError.programme_selected}
                                                                            />
                                                                            {retro_fields.isError.programme_selected && (<FormHelperText className='errorHelperTxt' id="programmes-error">
                                                                                Please select programmes
                                                                            </FormHelperText>)}
                                                                        </div>
                                                                    </FormControl>
                                                                }
                                                            />
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            {/* <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            <FormLabel htmlFor="included" className="fontSemiBold">Include/Exclude</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <div className="input-group">
                                                                <Checkbox
                                                                    checked={included}
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                />
                                                            </div>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item> */}
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            &nbsp;
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Grid container direction="row" alignItems="center">
                                                                <Button 
                                                                    variant="contained" 
                                                                    type="submit" 
                                                                    sx={{ marginRight: '15px' }} 
                                                                    //disabled={((retro_fields.hasEditProgrammes && retro_fields.hasEditProgrammes.confirmed === true) ? true : false) || state.defaultmode === false}
                                                                    // eslint-disable-next-line max-len
                                                                    disabled={((retro_fields.hasEditData !== null || retro_fields.editTabData !== null) && retro_fields.hasEditProgrammes) ? retro_fields.isError.programme_selected : (state.formValid || state.defaultmode === false)}
                                                                >Save</Button>
                                                                <Button variant="outlined" onClick={handleSelf}>Cancel</Button>
                                                            </Grid>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </form>

            </Modal>
        </>
    );
}

export default AddEditProgrammes;