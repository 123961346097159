
function PageNotFound() {

    return (
        <>
            Page Not Found
        </>
    );
}

export default PageNotFound;
