import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { Button } from "@mui/material";
// import { useEffect, useState } from "react";
// import SnackBarDialog from "./shared/SnackBarDialog";
// import refreshLambda from "../services/utility/refresh-lambda";
// import PageLoader from "./shared/PageLoader";


/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = () => {
    const { instance } = useMsal();
    // const [snackBar, setSnackBar] = useState({
    //     text: "",
    //     type: "",
    //     show: false,
    // });
    // const [state, setState] = useState({
    //     pageLoader: false,
    //     showLoader: false
    // });

    const handleLogin = (loginType) => {
        if (loginType === "popup") {
            localStorage.setItem("userLogin", "true");
            instance.loginPopup(loginRequest).catch(e => {
                localStorage.setItem("userLogin", "fail");
                console.log(e);
            });

            // try {
            //     setState({ ...state, pageLoader: true });
            //     const { data } = await refreshLambda.get('/warmup');

            //     if (data) {
            //         setSnackBar({ ...snackBar, show: true, text: "Labda Warmer Started Successfully...!", type: "success" });

            //     }
            //     setState({ ...state, pageLoader: false });
            // } catch (error) {
            //     const { response: { data, status } } = error;
            //     if (status === 500) {
            //         setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
            //     } else {
            //         setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
            //     }
            //     setState({ ...state, pageLoader: false });
            // }
            // instance.loginPopup(loginRequest).catch(e => {
            //     localStorage.setItem("userLogin", "fail");
            //     console.log(e);
            // });
        }
    }
    return (
        <>
            {/* {state.pageLoader && <PageLoader />} */}
            <Button variant="contained" size="large" className="user-login-btn" onClick={() => handleLogin("popup")}>Sign in with Office 365</Button>
            {/* {snackBar.show &&
                <SnackBarDialog
                    onShow={snackBar.show}
                    text={snackBar.text}
                    type={snackBar.type}
                    onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                />
            } */}
        </>
    );
}