import React, { useCallback, useEffect, useState } from "react";
import {
    Grid, Paper, Box, FormLabel, Button, Autocomplete,
    TextField, FormHelperText, Checkbox, FormControlLabel, FormControl, InputAdornment, MenuItem, Menu, IconButton, Modal
} from "@mui/material";

import { useDispatch, useSelector } from 'react-redux';
import { DataTableClientSide } from "../../../../components/shared/data-table/DataTableClientSide";
import AddEditPriceModal from "./prices/AddEditPriceModal";
import AddIcon from '@mui/icons-material/Add';
import AddEditLevyModal from "./prices/AddEditLevyModal";
import { deletePrice, setListOfLevy } from "../../../../store/slices/generalProduct";
import SnackBarDialog from "../../../../components/shared/SnackBarDialog";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "../../../../services/utility/axios";
import PageLoader from "../../../../components/shared/PageLoader";
import { LoadingButton } from "@mui/lab";


const IconMenuLink = ({ params, onDelete }) => {
    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'delete') {
            if (onDelete) {
                onDelete(row)
            }
        }
        setAnchorEl(null);
    };

    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'delete')}>Delete</MenuItem>
        </Menu>
    </>)
}
const Leavy = () => {

    const [state, setState] = useState({
        price: '',
        defaultGrid: {
            paginationMode: 'client',
        },
        handleAddEditLevyModal: false,
        pageLoader: false,
        warningLevyModal: false,
        deleteLevy: null,
        warningPriceModal: false
    });

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const [deletConfirmation, setDeleteConfirmation] = useState(false)
    const [dLoading, setDLoading] = useState(false)

    const { levyList } = useSelector((state) => {
        return {
            levyList: state.generalProduct
        }
    });

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchMyAPI = async () => {
            try {
                dispatch(setListOfLevy([]))
                setState({ ...state, pageLoader: true })
                const { data } = await axios.get(`/products/${levyList.productId}/levy`);
                const unique = data.resultMap.result.filter((value, index, self) => self.findIndex((m) => m.id === value.id) === index);
                if (data) {

                    dispatch(setListOfLevy(unique))
                    setState({ ...state, pageLoader: false })
                }

            } catch (error) {
                const { response: { data, status } } = error;
                setState({ ...state, pageLoader: false })
                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" })
                } else {
                    if (data.message !== "No Levies found") {
                        setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "warning" })
                    }
                }
            }
        }
        fetchMyAPI()
    }, [])
    const addPriceModal = (event) => {

        setState({ ...state, handleAddEditLevyModal: !state.handleAddEditLevyModal })

    }
    const hideNewLevyModal = (event, reason) => {
        setState({ ...state, handleAddEditLevyModal: event })
    }
    const columns = [
        {
            headerName: "Purchasing Tier",
            field: "memberTierName",
            flex: 1,
        },
        {
            headerName: "Levy",
            field: "levyName",
            flex: 1
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} onDelete={onDelete} />;
            },
        }
    ];

    const onDelete = async (row) => {
        dispatch(deletePrice(row))
        setState({ ...state, warningPriceModal: true })

    }
    useEffect(() => {
        if (deletConfirmation) {
            deletePriceRow()
        }
    }, [deletConfirmation])

    const deletePriceRow = async () => {
        try {
            setState({ ...state, pageLoader: true })
            const { data } = await axios.delete(`/products/${levyList.productId}/levy/${levyList.deletePrice.id}`);
            
            if (data.success) {
                setDeleteConfirmation(false)
                setState({ ...state, pageLoader: false })
                const filteredRows = levyList.listOfLevy.filter(levy => {
                    if (levy.id !== levyList.deletePrice.id) {
                        return levy;
                    }
                })
                
                setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Levy deleted!", type: "success" })
                dispatch(setListOfLevy(filteredRows))
                setState({ ...state, pageLoader: false, warningPriceModal: false })
                dispatch(deletePrice(null))
                setDLoading(false)
            }

        } catch (error) {
            const { response: { data, status } } = error;
                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" })
                }
                setDeleteConfirmation(false)
                setState({ ...state, pageLoader: false, warningPriceModal: false })
                dispatch(deletePrice(null))
                setDLoading(false)

        }

    }

    const handleDeletePrice = () => {
        setDeleteConfirmation(true)
        setDLoading(true)
    }
    const handlePriceDeleteWaring = () => {
        setDeleteConfirmation(false)
        dispatch(deletePrice(null))
        setState({ ...state, warningPriceModal: false })
        setDLoading(false)
    }
    return (
        <>
            <Box sx={{ flexGrow: 1, paddingX: 2 }} >
                {state.pageLoader && <PageLoader />}
                <Box sx={{ textAlign: 'right', marginBottom: '15px' }}>
                    <Button variant="contained" onClick={(e) => addPriceModal(e)} startIcon={<AddIcon />}>
                        Levy
                    </Button>
                </Box>
                <DataTableClientSide
                    columns={columns}
                    defaultGrid={state.defaultGrid}
                    rows={levyList.listOfLevy}
                >
                </DataTableClientSide>
                {state.handleAddEditLevyModal && (
                    <>
                        <AddEditLevyModal size="medium_overlay" onShow={state.handleAddEditLevyModal}
                            onHide={(e, reason) => hideNewLevyModal(e, reason)} />
                    </>
                )}
                {snackBar.show && <SnackBarDialog
                    onShow={snackBar.show}
                    text={snackBar.text}
                    type={snackBar.type}
                    onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                />}
            </Box>
            <Modal
                open={state.warningPriceModal}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handlePriceDeleteWaring();
                    }
                }}
            >
                <Box component="div" className="overlay" id="addEditModal">
                    <div className={`overlay-box small_overlay`}>
                        <div className="content">
                            <div className="overlayBody">
                                <div className="overlay-content">
                                    <Box component="div" sx={{ textAlign: 'center', padding: 1.5 }}>
                                        <p className="fontMedium">Are you sure want to delete levy?</p>
                                        <Grid sx={{ paddingTop: 2 }} container direction="row" alignItems="center" justifyContent="center">
                                            <LoadingButton
                                                onClick={handleDeletePrice}
                                                loading={dLoading}
                                                loadingPosition="center"
                                                endIcon=""
                                                variant="contained"
                                                type="submit" sx={{ marginRight: '15px' }}
                                            >
                                                Yes
                                            </LoadingButton>
                                            <Button variant="outlined" onClick={handlePriceDeleteWaring}>No</Button>
                                        </Grid>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
};
export default Leavy;