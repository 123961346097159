import { useEffect, useState } from "react";
import { Container, Box, Grid, Checkbox, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { useDispatch, useSelector } from 'react-redux';
import { DataTableInvoiceDetails } from "../../../../components/shared/data-table/DataTableInvoiceDetails";


const LinesandDiscountsTab = () => {

    const Item = styled(Paper)(({ theme }) => ({
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        boxShadow: "none"
    }));

    const { selected_invoice } = useSelector((state) => {
        return {

            selected_invoice: state.invoiceViewDetails
        }
    });

    const [state, setState] = useState(
        {
            loader: false,
            defaultGrid: {
                pdcActionButton: true,
                exportExcelButton: true,
                addEditButton: true,
                apiUrl: `invoicesummary/${selected_invoice.selectedrow.invoiceId}/linesadndiscounts`,
                searchUrl: `invoicesummary/${selected_invoice.selectedrow.invoiceId}/linesadndiscounts`
            }
        }
    );

    const columns = [
        {
            headerName: "Id",
            // field: "uniqueId",
            field: "lineNumber",
            width: 90
        },
        {
            headerName: "SPC",
            field: "supplierProductCode",
            minWidth: 100,
            renderCell: (params) => (
                <Typography title={params.value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: 'inherit', fontWeight: 'inherit' }}>{params.value}</Typography>
            )
        },
        {
            headerName: "Description",
            field: "description",
            minWidth: 150,
            flex: 1,
            renderCell: (params) => (
                <Typography title={params.value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: 'inherit', fontWeight: 'inherit' }}>{params.value}</Typography>
            )
        },
        {
            headerName: "OCBC",
            field: "outerCaseBarcode",
            minWidth: 150,
            flex: 1,

        },
        {
            headerName: "CQ",
            field: "convertedQuantity",
            align: "center",
            headerAlign: "center",
            minWidth: 30
        },
        {
            headerName: "Quantity",
            field: "supplierQuantity",
            align: "center",
            headerAlign: "center",
            minWidth: 70
        },
        {
            headerName: "Unit Price (£)",
            field: "supplierUnitPrice",
            align: "center",
            headerAlign: "center",
            minWidth: 100,
            valueGetter: (params) => Number(params.value).toFixed(2)
        },
        {
            headerName: "Supplier Line Value (£)",
            field: "supplierLineValue",
            align: "center",
            headerAlign: "center",
            minWidth: 180,
            valueGetter: (params) => Number(params.value).toFixed(2)
        },
        {
            headerName: "VAT amount (£)",
            field: "vatAmount",
            align: "center",
            headerAlign: "center",
            minWidth: 130,
            valueGetter: (params) => Number(params.value).toFixed(2)

        },
        {
            headerName: "Line amount (£)",
            field: "lineAmount",
            align: "center",
            headerAlign: "center",
            minWidth: 130,
            valueGetter: (params) => Number(params.value).toFixed(2)
        },
        {
            headerName: "VAT Code",
            field: "vatCode",
            align: "center",
            headerAlign: "center",
            minWidth: 100
        },
        {
            headerName: "System Dispute",
            field: "systemDispute",
            minWidth: 140,
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
            align: "center",
            headerAlign: "center"
        },
        {
            headerName: "User Dispute",
            field: "userDispute",
            minWidth: 120,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            )
        },
        {
            headerName: "Levied BO",
            field: "leviedBO",
            align: "center",
            headerAlign: "center",
            minWidth: 100,
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            )
        },
        {
            headerName: "Levied DC",
            field: "leviedDC",
            align: "center",
            headerAlign: "center",
            minWidth: 100,
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            )
        },
        {
            headerName: "Levied PV",
            field: "leviedPV",
            align: "center",
            headerAlign: "center",
            minWidth: 100,
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            )
        },
        {
            headerName: "Dispute Reason",
            field: "disputeReason",
            align: "center",
            headerAlign: "center",
            minWidth: 150,
            flex: 1,
            renderCell: (params) => (
                params.value ? <Typography title={params.value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px' }} >{params.value}</Typography> : ""
            )
        },
        {
            headerName: "Discount Scheme",
            field: "discountScheme",
            align: "center",
            headerAlign: "center",
            minWidth: 150,
            headerClassName: 'summary-discount-headers',
            flex: 1,
            renderCell: (params) => (
                params.value ? <Typography title={params.value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px' }} >{params.value}</Typography> : ""
            )
        },
        {
            headerName: "Brand Owner",
            field: "brandOwner",
            align: "center",
            headerAlign: "center",
            minWidth: 150,
            headerClassName: 'summary-discount-headers',
            flex: 1,
            renderCell: (params) => (
                params.value ? <Typography title={params.value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px' }} >{params.value}</Typography> : ""
            )
        },
        {
            headerName: "Amount (£)",
            field: "discountamount",
            minWidth: 100,
            align: "center",
            headerAlign: "center",
            headerClassName: 'summary-discount-headers',
        },
        {
            headerName: "VAT Amount (£)",
            field: "discountvatamount",
            minWidth: 130,
            align: "center",
            headerAlign: "center",
            headerClassName: 'summary-discount-headers',
            valueFormatter: (params) => { return params.value ? params.value.toFixed(2) : ""; }
        },
        {
            headerName: "VAT Code",
            field: "discountvatCode",
            align: "center",
            headerAlign: "center",
            minWidth: 100,
            headerClassName: 'summary-discount-headers',
        },
        {
            headerName: "VAT Rate",
            field: "discountvatRate",
            align: "center",
            headerAlign: "center",
            minWidth: 110,
            headerClassName: 'summary-discount-headers',
            valueFormatter: (params) => { return params.value ? (params.value*100)+"%" : ""; }
        },
    ];



    return (
        <>
            <Container disableGutters={true} maxWidth={false}>
                <DataTableInvoiceDetails defaultGrid={state.defaultGrid} loc="linediscount" columns={columns ? columns : []} ></DataTableInvoiceDetails>
            </Container>
        </>

    );
};
export default LinesandDiscountsTab;