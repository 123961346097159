import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editTab: true,
  hasEditProduct: null,
  editTabData: null,
  hasEditData: null,
  general_product_name: '',
  general_active_p: true,
  general_export_td: false,
  general_brand_owner_pid: "",
  general_outer_case_barcode: "",
  general_ean_code: "",
  general_rsp: "",
  general_coo: "",
  general_case_quantity: "",
  general_brand: null,
  general_brands: {
    brand_options: [],
    brand_selected: null,
  },
  general_vat_code: {
    vat_code_options: [],
    vat_code_selected: null
  },
  general_unit_measure: {
    measure_options: [],
    measure_selected: null
  },
  general_category: {
    category_options: [],
    category_selected: null
  },
  general_on_trade: {
    trade_on_options: [],
    leavy_on_trade_selected: null
  },
  general_off_trade: {
    trade_off_options: [],
    leavy_off_trade_selected: null
  },
  general_member_tier: {
    member_tier_options: [],
    member_tier_selected: null
  },
  general_fair_trade: false,
  general_organic: false,
  general_ethical: false,
  general_rt: false,
  general_msc: false,
  general_rspca: false,
  general_fsc: false,
  general_rspo: false,
  product_specification: '',
  product_eancode: [],
  eancodename: '',
  editEanCode: null,
  productFormValid: false,
  hasEditPrice: null,
  listOfPrice: [],
  productId: null,
  deletePrice: null,
  eancodeOkDelete: false,
  eancodeConfirmDelete: false,
  deleteEanCodeList: null,
  listOfLevy: [],
  isError: {
    general_product_name: false,
    general_brand_owner_pid: false,
    general_outer_case_barcode: false,
    general_case_quantity: false,
    general_brand: false,
    brand_selected: false,
    vat_code_selected: false,
    measure_selected: false,
    category_selected: false,
    buttonDisabled: true,
    member_tier_selected: false,
    eancodename: false,
    specificationInput: false
  }
}

const generalProduct = createSlice({
  name: 'general_product',
  initialState,
  reducers: {
    brandSearch: (state, action) => {
      const { parent, key, value } = action.payload;
      state[parent][key] = value;
      (state[parent][key] === null || state[parent][key] === "") ? state.isError[key] = true : state.isError[key] = false
    },
    generalProductInput: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
      state[key].length === 0 ? state.isError[key] = true : state.isError[key] = false
    },
    generalProductCheckbox: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value
    },
    setSpecification: (state, action) => {
      state.product_specification = action.payload;
    },
    setEanCode: (state, action) => {
      state.product_eancode.push(action.payload)
    },
    setEanCodeList: (state, action) => {
      state.product_eancode = action.payload
    },
    setEanCodeInput: (state, action) => {
      state.eancodename = action.payload;
      state.eancodename.length === 0 ? state.isError.eancodename = true : state.isError.eancodename = false
    },
    getIsError: (state, action) => {
      const { key, value } = action.payload;
      state.isError[key] = value
    },
    setAutocomplete: (state, action) => {
      const { parent, key, value } = action.payload;
      state[parent][key] = value
    },
    hasTabEdit: (state, action) => {
      const { editTab } = action.payload;
      state.editTab = editTab
    },
    hasTabEditData: (state, action) => {
      const { editData } = action.payload;
      state.editTabData = editData
    },
    setHasEditData: (state, action) => {
      state.hasEditData = action.payload;
    },
    setEditEanCode: (state, action) => {
      state.editEanCode = action.payload
    },
    setUOMName: (state, action) => {
      state.uom_container.uom_name = action.payload;
    },
    setProductFormStatus: (state, action) => {
      state.productFormValid = action.payload;
    },
    setHasEditPrice: (state, action) => {
      state.hasEditPrice = action.payload;
    },
    setListOfPrice: (state, action) => {
      state.listOfPrice = action.payload;
    },
    setProductId: (state, action) => {
      state.productId = action.payload;
    },
    deletePrice: (state, action) => {
      state.deletePrice = action.payload
    },
    deleteEanCodeList: (state, action) => {
      state.deleteEanCodeList = action.payload
    },
    setEanCodeDeleteData: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    setListOfLevy: (state, action) => {
      state.listOfLevy = action.payload;
    },
    resetProduct: () => {
      return initialState
    },
  }
})

export const { brandSearch, hasTabEditData, setAutocomplete, getIsError, generalProductInput, generalProductCheckbox, setSpecification, setEanCode,
  setEanCodeInput, hasTabEdit, resetProduct, setHasEditData,
  setEanCodeList, setEditEanCode, setUOMName, setProductFormStatus, setHasEditPrice, setListOfPrice, setProductId, priceDeleteYes, deletePrice, deleteEanCodeList, setEanCodeDeleteData, setListOfLevy } = generalProduct.actions

export default generalProduct.reducer