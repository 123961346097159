import { Checkbox, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl } from '@mui/material'
import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { warningModal, handleAddEditVat } from "../../../store/slices/modals";
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import moment from "moment";
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import axios_utilities from '../../../services/utility/axios-utilities';
import { hasEdit, hasEditData, vcProductInput, setAutocomplete, getIsError, resetVC } from "../../../store/slices/vcProduct";
import SnackBarDialog from "../../../components/shared/SnackBarDialog";
import { filterFlagChange, filterResetFlagChange } from '../../../store/slices/externalFilter';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

const defaultValues = {
    loader: false,
    formValid: true,
    defaultmode: false,
    newvcProductData: [],
};

function AddEditVatCodesDetails({ onShow, size = '' }) {
    
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const [state, setState] = useState(defaultValues);
    const [selectedSupplier, setselectedSupplier] = useState(null);
    const [open, setOpen] = useState(onShow);
    const { handleWarning, vc_container } = useSelector((state) => {
        return {
            handleWarning: state.modalActions.closeWaringAddProduct,
            vc_container: state.vcProducts
        }
    });
    useEffect(() => {
        const inputNumber = document.querySelectorAll('.inputNumber');
        inputNumber.forEach(el => el.addEventListener('keypress', evt => {
            if (evt.which === 8) {
                return;
            }
            if (evt.which < 42 || evt.which > 60) {
                evt.preventDefault();
            }
        }));
    }, []);
    const dispatch = useDispatch();

    const handleClose = () => {
        //setState({ ...state, defaultmode: false })
        dispatch(handleAddEditVat({
            showAddEdit: false
        }));
        dispatch(resetVC());
    };

    const handleCancel = () => {
        setState({ ...state, defaultmode: false })
        dispatch(warningModal({
            yesHide: !handleWarning
        }))
    };

    const handleInputChange = (event) => {
        if(vc_container.hasEditData) {
            setState({ ...state, defaultmode: true })
        }
        const { value, name } = event.target;
        dispatch(vcProductInput({
            key: name,
            value
        }))
    }

    useEffect(() => {
        if ((vc_container.vc_code === null || vc_container.vc_code === "") || (vc_container.rate_value === null || vc_container.rate_value === "")) {
            setState({ ...state, formValid: true, defaultmode: false })
        } else {
            setState({ ...state, formValid: false, defaultmode: true })
        }
    }, [vc_container.vc_code, vc_container.rate_value])

    useEffect(() => {
        const { 
            vc_code,
            rate_value
        } = vc_container.isError
        if (!vc_code && !rate_value) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true })
        }


    }, [vc_container.isError])

    const handleSubmit = async (event) => {
        event.preventDefault();
        let productData = null;
        if (!state.formValid) {
            productData = {
                "code": vc_container.vc_code,
                "rate": vc_container.rate_value,
                "externalCode": vc_container.externalcode_value,
                "description": vc_container.desctiption_value
            }
            try {
                setState({ ...state, loader: true })
                const { data, status } = vc_container.hasEditData ? await axios_utilities.put(`vatcodes/${vc_container.hasEditData.id}`, { ...productData }) : await axios_utilities.post(`vatcodes`, { ...productData });
                if (data.success === true && data.resultMap) {
                    setSnackBar({...snackBar, show:true, text: data.message, type: "success", })
                    setState({ ...state, loader: false, formValid: true, newvcProductData: data.resultMap})
                    //setOtherTabsOpen(true)
                    // dispatch(handleAddEditModal({
                    //     showAddEdit: false,
                    // }))
                    // window.location.reload();
                    setTimeout(() => {
                        // window.location.reload();
                        dispatch(handleAddEditVat({
                            showAddEdit: false
                        }));
                        dispatch(filterFlagChange(true));
                        dispatch(filterResetFlagChange(false));
                    }, 1500);
                }
            } catch (error) {
                setState({ ...state, loader: false })
                const { response: { data, status } } = error;
                setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error", })
            }
        }
    };

    
    return (
        <>
            { snackBar.show && <SnackBarDialog 
            onShow={snackBar.show} 
            text={snackBar.text} 
            type={snackBar.type} 
            onHide={(e) => {setSnackBar({...snackBar, show: e})}}
            />}

            <Modal
                open={open}
                aria-labelledby="add-edit-price-title"
                aria-describedby="add-edit-price-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Box component="div" className="overlay" id="addEditModal">
                        <div className={`overlay-box small-size ${size ? size : ''}`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-header text-center"> Vat Code Details
                                        <CancelIcon className="closeBtn" onClick={handleClose} />
                                    </div>
                                    <div className="overlay-content">
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="vc_code" className="fontSemiBold mandatory">Code</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Box component="div" className=''>
                                                                <FormControl size="small">
                                                                    <TextField
                                                                        inputProps={{ maxLength: 50 }}
                                                                        type="text"
                                                                        size="small"
                                                                        id="vc_code"
                                                                        aria-describedby="vc_code_error"
                                                                        onChange={(e) => handleInputChange(e)}
                                                                        value={vc_container.vc_code}
                                                                        error={vc_container.isError.vc_code}
                                                                        min={1}
                                                                        name="vc_code"
                                                                        className="inputNumber"
                                                                    />
                                                                    {vc_container.isError.vc_code && (<FormHelperText className='errorHelperTxt' id="vc_code_error">Please enter unique value</FormHelperText>)}
                                                                </FormControl>
                                                            </Box>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="rate" className="fontSemiBold mandatory">Rate</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Box component="div" className=''>
                                                                <FormControl size="small">
                                                                    <TextField
                                                                        inputProps={{ maxLength: 50 }}
                                                                        type="number"
                                                                        size="small"
                                                                        id="rate_value"
                                                                        aria-describedby="rate_value_error"
                                                                        onChange={(e) => handleInputChange(e)}
                                                                        value={vc_container.rate_value}
                                                                        error={vc_container.isError.rate_value}
                                                                        min={1}
                                                                        name="rate_value"
                                                                        className="inputNumber"
                                                                        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                                                    />
                                                                    {vc_container.isError.rate_value && (<FormHelperText className='errorHelperTxt' id="rate_value_error">Please enter value</FormHelperText>)}
                                                                </FormControl>
                                                            </Box>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="externalcode" className="fontSemiBold">External Code</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Box component="div" className=''>
                                                                <FormControl size="small">
                                                                    <TextField
                                                                        inputProps={{ maxLength: 50 }}
                                                                        type="text"
                                                                        size="small"
                                                                        id="externalcode_value"
                                                                        aria-describedby="externalcode_value_error"
                                                                        onChange={(e) => handleInputChange(e)}
                                                                        value={vc_container.externalcode_value}
                                                                        name="externalcode_value"
                                                                        className="inputNumber"
                                                                    />
                                                                </FormControl>
                                                            </Box>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="description" className="fontSemiBold">Description</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Box component="div" className=''>
                                                                <FormControl size="small">
                                                                <TextField
                                                                    multiline
                                                                    rows={3}
                                                                    size="small"
                                                                    id="desctiption_value"
                                                                    aria-describedby="desctiption_value"
                                                                    onChange={(e) => handleInputChange(e)}
                                                                    value={vc_container.desctiption_value}
                                                                    name="desctiption_value"
                                                                />
                                                                </FormControl>
                                                            </Box>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                        <Grid container sx={{ marginTop: '20px' }}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Item>
                                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                                        <LoadingButton
                                                                loading={state.loader}
                                                                loadingPosition="center"
                                                                endIcon=""
                                                                variant="contained"
                                                                type="submit" sx={{ marginRight: '15px' }}
                                                                //disabled={state.formValid || state.defaultmode === false}
                                                                disabled={vc_container.hasEditData ? (vc_container.isError.vc_code || vc_container.isError.rate_value) : (state.formValid || state.defaultmode === false)}
                                                            >Save</LoadingButton>
                                                        <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </form>
            </Modal>
        </>
    );
}

export default AddEditVatCodesDetails;