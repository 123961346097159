import { useEffect, useState } from "react";
import { Container, Box, Grid, Checkbox, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import axios_invoice_summaries from '../../../../services/utility/axios-new-invoice-summaries';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PageLoader from "../../../../components/shared/PageLoader";

const SelectedInvoiceTab = () => {

    const { selected_invoice } = useSelector((state) => {
        return {
            selected_invoice: state.invoiceViewDetails
        }
    });

    const [inv, setInv] = useState([]);
    const [state, setState] = useState({
        pageLoader: true
    }); 

    useEffect(() => {
        const fetchMyAPI = async () => {
            if (selected_invoice.selectedrow.invoiceId) {
                axios_invoice_summaries.get(`invoicesummary/${selected_invoice.selectedrow.invoiceId}/selectedinvoice`)
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            const result = Object.values(data);
                            setInv(data);
                            setState({pageLoader: false});
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
        }
        fetchMyAPI();
    }, [])

    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Grid container spacing={2} >
                <Grid item xs={12} md={5} component={Paper} sx={{ mt: 4, mb: 0, ml: '40px' }}>
                    {/* <Typography  variant="h6" component="div">
                        Invoice Details
                    </Typography> */}
                    <TableContainer >
                        <Table sx={{ width: 450 }} aria-label="simple table">
                            <TableBody>                                
                                <TableRow>
                                    <TableCell align="left">Order Reference</TableCell>
                                    <TableCell align="left">N/A</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left">Order Date</TableCell>
                                    <TableCell align="left">N/A</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left">Delivery Reference</TableCell>
                                    <TableCell align="left">N/A</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left">Purchase Order Number</TableCell>
                                    <TableCell align="left">{inv['id'] ? inv['id'] : 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left">Net Total</TableCell>
                                    <TableCell align="left">{inv['netTotal'] ? inv['netTotal'] : 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left">VAT Total</TableCell>
                                    <TableCell align="left">{inv['vatTotal'] ? inv['vatTotal'] : 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left">Source URI</TableCell>
                                    <TableCell align="left">{inv['sourceUri'] ? inv['sourceUri'] : 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left">Import Date</TableCell>
                                    <TableCell align="left">{inv['importDate'] ? inv['importDate'] : 'N/A'}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

        </>

    );
};
export default SelectedInvoiceTab;