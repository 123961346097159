import React, { useCallback, useEffect, useState, useRef } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField, FormControl, FormHelperText, FormLabel } from '@mui/material';
import axios from '../../../services/utility/axios';
import axios_categories from '../../../services/utility/axios-categories';
import { useDispatch, useSelector } from 'react-redux';
import axiosProductDistribution from '../../../services/utility/axios-product-distribution';
import axiosSupplier from '../../../services/utility/axios-supplier';
import axiosNusslReports from '../../../services/utility/axios-nussl-reports';
import axiosMember from '../../../services/utility/axios-member';
import axios_utilities from "../../../services/utility/axios-utilities";
import axiosBrand from '../../../services/utility/axios-brand';
import SnackBarDialog from '../SnackBarDialog';

export const AutoCompleteStatic = ({ id, label, onChange, endPoint, onSelectedAutoComplete, onClear }) => {
    const [state, setState] = useState({
        error: false
    })
    const [options, setOptions] = useState([]);

    const [inputValue, setInputValue] = React.useState('');

    const { applProductFilter } = useSelector((state) => {
        return {
            applProductFilter: state.externalFilter
        }
    });

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    });

    useEffect(() => {
        const url = `${endPoint ? endPoint : ''}`;
        const axiosType = endPoint === 'distributionChannels/drop-down' ? axiosProductDistribution : (endPoint === 'supplier/drop-down?dcType=7' || endPoint === '/supplier/look-up' || endPoint === '/supplier/drop-down') ? axiosSupplier : endPoint === 'nusslReports/drop-down' ? axiosNusslReports : endPoint === 'members/look-up?pageSize=500' ? axiosMember : endPoint === '/members/memberEstateGroups' ? axiosMember : ((endPoint === 'vatcodes/drop-down') || (endPoint === 'unitofmeasures/drop-down')) ? axios_utilities : endPoint === '/v2/categories/parent-dropdown?level=0' ? axios_categories : endPoint === 'brands/look-up' ? axiosBrand : axios
        if (endPoint !== "") {
            const fetchMyAPI = async () => {
                if (endPoint === 'members/look-up' || endPoint === 'members/look-up?pageSize=500') {
                    if (options.length === 0) {
                        axiosType.get(url)
                            .then(function (response) {
                                // handle success
                                const { status, data } = response;
                                if (data) {
                                    const unique = data.items.filter((value, index, self) => self.findIndex((m) => m.name === value.name) === index);
                                    setOptions(unique);
                                }
                            })
                            .catch(function (error) {
                                // handle error
                                const { response: { data, status }, message } = error;
                                console.log(message, status, data);
                                // if (status === 500 || status === 501 || status === 502 || status === 503 || status === 504 || status === 505 || status === 0) {
                                //     setSnackBar({ ...snackBar, show: true, text: url+" took long to respond, please retry!", type: "error" });
                                // } else {
                                //     setSnackBar({ ...snackBar, show: true, text: url+" took long to respond, please retry!", type: "error", })
                                // }
                            });
                    }
                }
                else if (endPoint === 'vatcodes/drop-down') {
                    if (options.length === 0) {
                        axiosType.get(url)
                            .then(function (response) {
                                // handle success
                                const { status, data } = response;
                                if (data) {
                                    const unique = data.filter((value, index, self) => self.findIndex((m) => m.code === value.code) === index);
                                    setOptions(unique);
                                }
                            })
                            .catch(function (error) {
                                // handle error
                                const { response: { data, status }, message } = error;
                                console.log(message, status, data);
                                // if (status === 500 || status === 501 || status === 502 || status === 503 || status === 504 || status === 505 || status === 0) {
                                //     setSnackBar({ ...snackBar, show: true, text: url+" took long to respond, please retry!", type: "error" });
                                // } else {
                                //     setSnackBar({ ...snackBar, show: true, text: url+" took long to respond, please retry!", type: "error", })
                                // }
                            });
                    }
                }
                else if (endPoint === 'brands/look-up') {
                    if (options.length === 0) {
                        axiosType.get(url)
                            .then(function (response) {
                                // handle success
                                const { status, data } = response;
                                if (data) {
                                    const unique = data.resultMap.result.filter((value, index, self) => self.findIndex((m) => m.name === value.name) === index);
                                    setOptions(unique);
                                }
                            })
                            .catch(function (error) {
                                // handle error
                                const { response: { data, status }, message } = error;
                                console.log(message, status, data);
                                // if (status === 500 || status === 501 || status === 502 || status === 503 || status === 504 || status === 505 || status === 0) {
                                //     setSnackBar({ ...snackBar, show: true, text: url+" took long to respond, please retry!", type: "error" });
                                // } else {
                                //     setSnackBar({ ...snackBar, show: true, text: url+" took long to respond, please retry!", type: "error", })
                                // }
                            });
                    }
                }
                else {
                    if (options.length === 0) {
                        axiosType.get(url)
                            .then(function (response) {
                                // handle success
                                const { status, data } = response;
                                if (data) {
                                    const unique = data.filter((value, index, self) => self.findIndex((m) => m.name === value.name) === index);
                                    setOptions(unique);
                                }
                            })
                            .catch(function (error) {
                                // handle error
                                const { response: { data, status }, message } = error;
                                console.log(message, status, data);
                                // if (status === 500 || status === 501 || status === 502 || status === 503 || status === 504 || status === 505 || status === 0) {
                                //     setSnackBar({ ...snackBar, show: true, text: url+" took long to respond, please retry!", type: "error" });
                                // } else {
                                //     setSnackBar({ ...snackBar, show: true, text: url+" took long to respond, please retry!", type: "error", })
                                // }
                            });
                    }
                }
            }
            fetchMyAPI();
        }

    }, [])

    const handleChange = (newInputValue, id) => {
        if (onChange) {
            onChange(newInputValue, id);
            setInputValue(newInputValue)
        }
        if(newInputValue === '') {
            if(onClear) {
                onClear(newInputValue, id)
            }
        }
    }
    const seelectAutoComplete = event => {
        if (onSelectedAutoComplete) {
            onChange(event, id);
        }
    }
    const handleAutoChange = event => {

        setInputValue(event.target.value);
    };

    useEffect(() => {
        if (applProductFilter[id] === null || applProductFilter[id] === "") {
            // eslint-disable-next-line prefer-destructuring
            const ele = autoC.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
            // eslint-disable-next-line prefer-destructuring
            const ele2 = autoC.current.getElementsByClassName('MuiOutlinedInput-input')[0];
            if (ele) {
                ele.click();
                ele2.blur()

            }
            //if (ele) ele.click();
        }
    }, [applProductFilter]);

    const autoC = useRef(null);

    if (endPoint === 'vatcodes/drop-down') {
        return (
            <Autocomplete
                ref={autoC}
                key={id}
                options={options}

                getOptionLabel={(option) => option.code}
                onInputChange={(e, newInputValue) => handleChange(newInputValue, id)}
                componentsProps={{
                    paper: {
                        sx: {
                            fontSize: '0.85rem'
                        }
                    }
                }}
                onChange={(event, newValue) => {
                    if (event.target.value !== "" || event.target.value !== null) {
                        seelectAutoComplete(newValue)
                    }

                }}
                renderInput={(params) =>
                    <FormControl size={'small'} fullWidth>
                        <FormLabel htmlFor={id}>{label}</FormLabel>
                        {snackBar.show &&
                            <SnackBarDialog
                                onShow={snackBar.show}
                                text={snackBar.text}
                                type={snackBar.type}
                                onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                            />
                        }
                        <TextField
                            {...params}
                            size="small" id={id}
                            aria-describedby={id + 'error'}
                            onChange={(e) => handleAutoChange(e)}
                        />
                        {state.error && (<FormHelperText id={id + 'error'}>Error Here</FormHelperText>)}
                    </FormControl>
                }
            />
        );

    } else {
        return (
            <Autocomplete
                ref={autoC}
                key={id}
                options={options}
                getOptionLabel={(option) => option.name}
                onInputChange={(e, newInputValue) => handleChange(newInputValue, id)}
                componentsProps={{
                    paper: {
                        sx: {
                            fontSize: '0.85rem'
                        }
                    }
                }}
                onChange={(event, newValue) => {
                    if (event.target.value !== "" || event.target.value !== null) {
                        seelectAutoComplete(newValue)
                    }

                }}
                renderInput={(params) =>
                    <FormControl size={'small'} fullWidth>
                        <FormLabel htmlFor={id}>{label}</FormLabel>
                        {snackBar.show &&
                            <SnackBarDialog
                                onShow={snackBar.show}
                                text={snackBar.text}
                                type={snackBar.type}
                                onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                            />
                        }
                        <TextField
                            {...params}
                            size="small" id={id}
                            aria-describedby={id + 'error'}
                            onChange={(e) => handleAutoChange(e)}
                        />
                        {state.error && (<FormHelperText id={id + 'error'}>Error Here</FormHelperText>)}
                    </FormControl>
                }
            />
        );
    }
}