import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  programme_name: '',
  add_edit_programme_name: '',
  addNewprogrammeData: null,
  programme_date: [],
  programme_start_date: null,
  programme_end_date: null,
  deleteList: null,
  okDelete: false,
  deleteProcess: false,
  isError: {
    programme_name: true,
    add_edit_programme_name: false,
    programme_owner_name: true,
    programme_start_date: false
  }
}

const programme = createSlice({
  name: 'programme',
  initialState,
  reducers: {
    setNewprogrammeData: (state, action) => {
      state.addNewprogrammeData = action.payload;
    },
    setprogrammeDate: (state, action) => {
      state.programme_date.push(action.payload)
    },
    setprogrammeDateList: (state, action) => {
      //state.programme_date = action.payload
      //return {...state, programme_date: action.payload}
      state.programme_date = action.payload
    },
    setDeleteList: (state, action) => {
      //state.programme_date = action.payload
      return {...state, deleteList: action.payload}
    },
    setAutocomplete: (state, action) => {
      const { parent, key, value } = action.payload;
      state[parent][key] = value
    },
    programmeInput: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
      state[key].length === 0 ? state.isError[key] = true : state.isError[key] = false
    },
    getIsError: (state, action) => {
      const { key, value } = action.payload;
      state.isError[key] = value
    },
    setprogrammeEffectiveDate: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
      state.isError[key] ? (state[key] === null || state[key] === ""  || state[key] === 'Invalid date') ? state.isError[key] = true : state.isError[key] = false : '';
    },
    resetProgramme: () => {
      return initialState
    },
  }
})

export const { resetProgramme, setNewprogrammeData, setprogrammeDate, 
  getIsError, programmeInput, setAutocomplete, setprogrammeEffectiveDate, 
  setprogrammeDateList, setDeleteList } = programme.actions

export default programme.reducer