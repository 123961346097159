import { Box, Modal, Tabs, Tab } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { warningModal, handleAddEditPdcPriceModal, handleAddEditModal } from "../../../store/slices/modals";
import { setCommonInput } from "../../../store/slices/commonProduct";
import General from "./pdc-tabs/General";
import SupplierProductCode from "./pdc-tabs/SupplierProductCode";
import { resetPdcProduct } from "../../../store/slices/generalPdcProduct";
import { filterFlagChange, filterResetFlagChange } from '../../../store/slices/externalFilter';

function TabPanel(props) {
    const { children, value, index, id, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`${id}-tab-pannel-${index}`}
            aria-labelledby={`${id}-tab-${index}`}
            {...other}
            className="tabs-pannel"
        >
            {value === index && (
                <>{children}</>
            )}
        </div>
    );
}

const defaultValues = {
    defaultmode: false
}

export default function AddEditPdc({ onShow, size = '' }) {

    const [state, setState] = useState(defaultValues);
    
    const { handleWarning, hasEditData, pdc_general_product_fields, showAddEditPriceModal } = useSelector((state) => {
        return {
            handleWarning: state.modalActions.closeWaringAddProduct,
            hasEditData: state.pricePdcProducts.hasEditData,
            pdc_general_product_fields: state.generalPdcProducts,
            showAddEditPriceModal: state.modalActions.hideAddEditPdcModal
        }
    });
   
    const dispatch = useDispatch();
    
    const handleClose = () => {
        //setState({ ...state, defaultmode: false }) 
        dispatch(handleAddEditModal({
            showAddEdit: false
        }))
        dispatch(resetPdcProduct());
        dispatch(setCommonInput(''));
        dispatch(filterFlagChange(true));
        dispatch(filterResetFlagChange(false));
        // dispatch(warningModal({
        //     yesHide: !handleWarning
        // }))
    };

    const [value, setValue] = useState(0);
    function handleChange(event, newValue) {
        setValue(newValue);
      }

    return (
        <>
            <Modal
                open={onShow || false}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <Box component="div" className="overlay" id="addEditModal">
                    <div className={`overlay-box `}>
                        <div className="content">
                            <div className="overlayBody">
                                <div className="overlay-header text-center"> PDC Details
                                    <CancelIcon className="closeBtn" onClick={handleClose} />
                                </div>
                                <div className="overlay-content height2">
                                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            variant="scrollable"
                                            scrollButtons={false}
                                            aria-label="scrollable prevent tabs example"
                                        >
                                        
                                                <Tab className="tabs-pannel" label="General" sx={{ minWidth: 'auto', paddingX: { xs: '5px', md: '0.85rem' }, marginLeft: '15px', fontWeight: '700', textTransform: 'capitalize' }} />
                                                <Tab className="tabs-pannel" label="Supplier Product Codes" sx={{ minWidth: 'auto', paddingX: { xs: '5px', md: '0.85rem' }, marginLeft: '15px', fontWeight: '700', textTransform: 'capitalize', lineHeight: '24px' }} disabled={!pdc_general_product_fields.hasEditData} />
                                        </Tabs>
                                    </Box>
                                    <TabPanel value={value} index={0}>
                                        <General size="medium_overlay" />
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <SupplierProductCode />
                                    </TabPanel>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}
