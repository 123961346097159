import { Box, CircularProgress, LinearProgress } from '@mui/material'
import loader from "../../assets/images/loader-2.gif"
import { useEffect, useState } from 'react';
function PageLoaderStyle2() {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);
    return (
        <>
            <Box component={'div'} sx={{ display: 'flex', textAlign: 'center', backgroundColor: 'rgba(255,255,255,0.9)', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100%', zIndex: '11', position: 'fixed', left: 0, top: '0' }}>
                <div className='loaderBx loaderBx2' style={{ width: '75%', margin: '-70px auto 0 auto' }}>
                    <div style={{ marginBottom: '50px' }}>
                        <h3>Please wait!!! <br />System is getting ready for you</h3>
                    </div>
                    <LinearProgress variant="determinate" value={progress} thickness={4} />

                </div>
            </Box>
        </>
    );
}

export default PageLoaderStyle2;