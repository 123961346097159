import { DataTable } from "../../components/shared/data-table/DataTable";
import { Container, Paper, Box, Button, Alert, IconButton, Menu, MenuItem, Checkbox, FormControlLabel, Grid, FormHelperText, TextField, FormControl, FormLabel, Modal, Select } from '@mui/material'

import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import SendIcon from '@mui/icons-material/Send';
import { useEffect, useState } from "react";
import { read, utils } from "xlsx";
import moment from "moment"
import { hasDuplicate, isEmpty, replaceKeys } from "../../services/utility/common-utils";
import { DataTableClientSide } from "../../components/shared/data-table/DataTableClientSide";
import { GridCheckCircleIcon, GridMoreVertIcon } from "@mui/x-data-grid";
import CircleIcon from '@mui/icons-material/Circle';
import { useDispatch, useSelector } from 'react-redux';
import { editProductUpload, editProductUploadModal, fileDeleteYes, singleDeleteRow, singleDeleteYes, wrongFile } from "../../store/slices/fileUpload";
import ProductFileUploadModal from "./fileUpload/ProductFileUploadModal";
import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningModal from "../../components/shared/modals/WarningModal";
import { warningModal } from "../../store/slices/modals";
import axios_product from "../../services/utility/axios";
import SnackBarDialog from "../../components/shared/SnackBarDialog";
import PageLoader from "../../components/shared/PageLoader";
 import downLoadTemplate from "../../assets/images/sample-product-file-upload.xls"
//Post GO Live --
//import downLoadTemplate from "../../assets/images/sample-product-file-upload-postgolive.xls" 

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

const IconMenuLink = ({ params, onEdit, onDelete }) => {

    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'edit') {
            if (onEdit) {
                onEdit(row)
            }
        } else if (action === 'delete') {
            if (onDelete) {
                onDelete(row)
            }
        }
        setAnchorEl(null);
    };

    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <GridMoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'edit')}>Edit</MenuItem>
            <MenuItem onClick={(e) => handleClose(e, 'delete')}>Delete</MenuItem>
        </Menu>
    </>)
}

function ProductFileUpload() {
    const [columns, setColumns] = useState([
        {
            headerName: "Name",
            field: "productDescription",
            flex: 1,
            minWidth: 200,
        },
        {
            headerName: "Brand Owner Product Id",
            field: "brandOwnerProductId",
            hide: true,
            width: 120
        },
        {
            headerName: "Outer Case Bar Code",
            field: "outerCaseBarCode",
            flex: 1,
            width: 120
        },
        {
            headerName: "Specification",
            field: "specification",
            width: 120,
            hide: true
        },
        {
            headerName: "Case Quantity",
            field: "caseQuantity",
            width: 120,
            hide: true
        },
        {
            headerName: "EAN Code",
            field: "eanCode",
            width: 120,
            hide: true
        },
        {
            headerName: "RSP",
            field: "rsp",
            width: 120,
            hide: true
        },
        {
            headerName: "Vat Code",
            field: "vatCode",
            width: 120,
            hide: true
        },
        {
            headerName: "Export to TD",
            field: "exportToTd",
            width: 120,
            hide: true
        },
        {
            headerName: "UOM ID",
            field: "uomId",
            width: 120,
            hide: true
        },
        {
            headerName: "UOM Name",
            field: "uomName",
            width: 120,
            hide: true
        },
        {
            headerName: "Brand ID",
            field: "brandId",
            width: 120,
            hide: true
        },
        {
            headerName: "Brand name",
            field: "brandName",
            width: 120,
            flex: 1
        },
        {
            headerName: "Cat ID",
            field: "catId",
            width: 120,
            hide: true
        },
        {
            headerName: "Category Name",
            field: "catName",
            width: 120,
            flex: 1
        },
        {
            headerName: "Price (£)",
            field: "casePrice",
            width: 120,
            flex: 1
        },
        {
            headerName: "Start Date",
            field: "startDate",
            width: 120,
            hide: true
        },
        {
            headerName: "End Date",
            field: "endDate",
            width: 120,
            hide: true
        },
        {
            headerName: "Member Tier",
            field: "memberTier",
            minWidth: 120,
            flex: 1
        },
        {
            headerName: "Associated Levy",
            field: "lsBrandOwnerId",
            width: 120,
            hide: true
        },
        {
            headerName: "Fair Trade",
            field: "fairTrade",
            width: 120,
            hide: true
        },
        {
            headerName: "Organic",
            field: "organic",
            width: 120,
            hide: true
        },
        {
            headerName: "Discountable for BOPA",
            field: "discountableForBopa",
            width: 120,
            hide: true
        },
        {
            headerName: "Ethical",
            field: "ethical",
            width: 120,
            hide: true
        },
        {
            headerName: "Country Of Origin",
            field: "countryOfOrigin",
            width: 120,
            hide: true
        },
        {
            headerName: "RT",
            field: "rt",
            width: 120,
            hide: true
        },
        {
            headerName: "MSC",
            field: "msc",
            width: 120,
            hide: true
        },
        {
            headerName: "RSPCA",
            field: "rspca",
            width: 120,
            hide: true
        },
        {
            headerName: "FSC",
            field: "fsc",
            width: 120,
            hide: true
        },
        {
            headerName: "RSPO",
            field: "rspo",
            width: 120,
            hide: true
        }
    ]);
    const [allColumns, setAllColumns] = useState([
        {
            headerName: "Result",
            field: "result",
            width: 100
        },
        {
            headerName: "Error Message",
            field: "errorMessage",
            width: 350
        },
        {
            headerName: "New",
            field: "newProduct",
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<GridCheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
            headerAlign: "center",
            align: 'center'
        },
        {
            headerName: "Name",
            field: "productDescription",
            flex: 1,
            minWidth: 200,
        },
        {
            headerName: "Brand Owner Product Id",
            field: "brandOwnerProductId",
            hide: true,
            minWidth: 120,
        },
        {
            headerName: "Outer Case Bar Code",
            field: "outerCaseBarCode",
            width: 120
        },
        {
            headerName: "Specification",
            field: "specification",
            width: 120,
            hide: true
        },
        {
            headerName: "Case Quantity",
            field: "caseQuantity",
            width: 120,
            hide: true
        },
        {
            headerName: "EAN Code",
            field: "eanCode",
            width: 120,
            hide: true
        },
        {
            headerName: "RSP",
            field: "rsp",
            width: 120,
            hide: true
        },
        {
            headerName: "Vat Code",
            field: "vatCode",
            width: 120,
            hide: true
        },
        {
            headerName: "Export to TD",
            field: "exportToTd",
            width: 120,
            hide: true
        },
        {
            headerName: "UOM ID",
            field: "uomId",
            width: 120,
            hide: true
        },
        {
            headerName: "UOM Name",
            field: "uomName",
            width: 120,
            hide: true
        },
        {
            headerName: "Brand ID",
            field: "brandId",
            width: 120,
            hide: true
        },
        {
            headerName: "Brand name",
            field: "brandName",
            width: 120
        },
        {
            headerName: "Cat ID",
            field: "catId",
            width: 120,
            hide: true
        },
        {
            headerName: "Category Name",
            field: "catName",
            width: 120
        },
        {
            headerName: "Price (£)",
            field: "casePrice",
            width: 80
        },
        {
            headerName: "Start Date",
            field: "startDate",
            width: 120,
            hide: true
        },
        {
            headerName: "End Date",
            field: "endDate",
            width: 120,
            hide: true
        },
        {
            headerName: "Member Tier",
            field: "memberTier",
            minWidth: 120
        },
        {
            headerName: "Associated Levy",
            field: "lsBrandOwnerId",
            width: 120,
            hide: true
        },
        {
            headerName: "Fair Trade",
            field: "fairTrade",
            width: 120,
            hide: true
        },
        {
            headerName: "Organic",
            field: "organic",
            width: 120,
            hide: true
        },
        {
            headerName: "Discountable for BOPA",
            field: "discountableForBopa",
            width: 120,
            hide: true
        },
        {
            headerName: "Ethical",
            field: "ethical",
            width: 120,
            hide: true
        },
        {
            headerName: "Country Of Origin",
            field: "countryOfOrigin",
            width: 120,
            hide: true
        },
        {
            headerName: "RT",
            field: "rt",
            width: 120,
            hide: true
        },
        {
            headerName: "MSC",
            field: "msc",
            width: 120,
            hide: true
        },
        {
            headerName: "RSPCA",
            field: "rspca",
            width: 120,
            hide: true
        },
        {
            headerName: "FSC",
            field: "fsc",
            width: 120,
            hide: true
        },
        {
            headerName: "RSPO",
            field: "rspo",
            width: 120,
            hide: true
        },
        
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} onEdit={editRow} onDelete={deleteRow} />;
            }
        },
    ]);
    const [rows, setRows] = useState([]);
    const [state, setState] = useState({
        deleteRow: false,
        deleteAllProductUpload: false,
        fileName: '',
        duplicateColumns: [
            {
                headerName: "Name",
                field: "productDescription",
                flex: 1,
                minWidth: 200,
            },
            {
                headerName: "Brand Owner Product Id",
                field: "brandOwnerProductId",
                hide: true,
                minWidth: 120,
            },
            {
                headerName: "Outer Case Bar Code",
                field: "outerCaseBarCode",
                minWidth: 120
            },
            {
                headerName: "Specification",
                field: "specification",
                width: 120,
                hide: true
            },
            {
                headerName: "Case Quantity",
                field: "caseQuantity",
                width: 120,
                hide: true
            },
            {
                headerName: "EAN Code",
                field: "eanCode",
                width: 120,
                hide: true
            },
            {
                headerName: "RSP",
                field: "rsp",
                width: 120,
                hide: true
            },
            {
                headerName: "Vat Code",
                field: "vatCode",
                width: 120,
                hide: true
            },
            {
                headerName: "Export to TD",
                field: "exportToTd",
                width: 120,
                hide: true
            },
            {
                headerName: "UOM ID",
                field: "uomId",
                width: 120,
                hide: true
            },
            {
                headerName: "UOM Name",
                field: "uomName",
                width: 120,
                hide: true
            },
            {
                headerName: "Brand ID",
                field: "brandId",
                width: 120,
                hide: true
            },
            {
                headerName: "Brand name",
                field: "brandName",
                width: 120
            },
            {
                headerName: "Cat ID",
                field: "catId",
                width: 120,
                hide: true
            },
            {
                headerName: "Category Name",
                field: "catName",
                width: 120
            },
            {
                headerName: "Price (£)",
                field: "casePrice",
                width: 120
            },
            {
                headerName: "Start Date",
                field: "startDate",
                width: 120,
                hide: true
            },
            {
                headerName: "End Date",
                field: "endDate",
                width: 120,
                hide: true
            },
            {
                headerName: "Member Tier",
                field: "memberTier",
                minWidth: 120
            },
            {
                headerName: "Associated Levy",
                field: "lsBrandOwnerId",
                width: 120,
                hide: true
            },
            {
                headerName: "Fair Trade",
                field: "fairTrade",
                width: 120,
                hide: true
            },
            {
                headerName: "Organic",
                field: "organic",
                width: 120,
                hide: true
            },
            {
                headerName: "Discountable for BOPA",
                field: "discountableForBopa",
                width: 120,
                hide: true
            },
            {
                headerName: "Ethical",
                field: "ethical",
                width: 120,
                hide: true
            },
            {
                headerName: "Country Of Origin",
                field: "countryOfOrigin",
                width: 120,
                hide: true
            },
            {
                headerName: "RT",
                field: "rt",
                width: 120,
                hide: true
            },
            {
                headerName: "MSC",
                field: "msc",
                width: 120,
                hide: true
            },
            {
                headerName: "RSPCA",
                field: "rspca",
                width: 120,
                hide: true
            },
            {
                headerName: "FSC",
                field: "fsc",
                width: 120,
                hide: true
            },
            {
                headerName: "RSPO",
                field: "rspo",
                width: 120,
                hide: true
            },
        ],
        defaultGrid: {
            paginationMode: 'client',
            rowHeight: 'auto',
            pageCountSize: true,
            pagehead: 'productfileupload'
        },
        fileNameId: '',
        fuSelectedName: '',
        productType: '',
        pageLoader: false,
        freshUpload: false,
        disableFileUpload: false
    });
    const [isError, setIsError] = useState({
        // isPNameDuplicate: false,
        isPNameEmpty: false,

        // isBOPIdDuplicate: false,
        isBOPIdEmpty: false,

        isOcBcDuplicate: false,
        isOcBcEmpty: false,
    });
    const [notUploded, setUploadFlag] = useState(true)
    const [uploadDropdown, setUploadDropdown] = useState([]);
    const [handleEditRowModal, setHandleEditRowModal] = useState(false)
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })
    const { handleWarning, fileUpload } = useSelector((state) => {
        return {
            fileUpload: state.productFileUpload,
            handleWarning: state.modalActions.closeWaringAddProduct
        }
    });
    const dispatch = useDispatch();



    const handleUpload = async (e) => {
        
        setRows([]);
        var input = e.target;
        var reader = new FileReader();      
        
        reader.onload = function () {
            var fileData = reader.result;
            var wb = read(fileData, { type: 'binary', cellDates: true });
            const data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { header: 1, defval: "" });
            let finalRows = data.slice(1).map((r) => r.reduce((acc, x, i) => {
                acc[data[0][i]] = x;
                return { ...acc, id: Math.random().toString(16).slice(2) };
            }, {}))
            const defaultValues = insertDefaultValues(finalRows);
            if(defaultValues !== 0)
            {
                setIsError({
                    ...isError,
                    isOcBcDuplicate: hasDuplicate(defaultValues, "outerCaseBarCode"),
                    isOcBcEmpty: isEmpty(defaultValues, "outerCaseBarCode"),
                    // isPNameDuplicate: hasDuplicate(defaultValues, "productDescription"),
                    isPNameEmpty: isEmpty(defaultValues, "productDescription"),
                    // isBOPIdDuplicate: hasDuplicate(defaultValues, "brandOwnerProductId"),
                    isBOPIdEmpty: isEmpty(defaultValues, "brandOwnerProductId")
                });
                const arrayList = [hasDuplicate(defaultValues, "outerCaseBarCode"),
                isEmpty(defaultValues, "outerCaseBarCode"),
                //hasDuplicate(defaultValues, "productDescription"),
                isEmpty(defaultValues, "productDescription"),
                // hasDuplicate(defaultValues, "brandOwnerProductId"),
                isEmpty(defaultValues, "brandOwnerProductId")
                ]
                setUploadFlag(arrayList.includes(true));
                setRows(defaultValues);
                
            }
            else
            {
                 console.log("Wrong File");
                 //alert("Wrong File");
                setState({ ...state, disableFileUpload: false });
                dispatch(wrongFile({
                    key: 'wrongFileUpload',
                    value: true
                }));
                dispatch(warningModal({
                    yesHide: true
                }));
                
            }

        }

        reader.readAsBinaryString(input.files[0]);
        const fname = input.files[0].name.split('.');
        const fExtension = fname.pop()
        setState({ ...state, fileName: fname.join() + "_" + Date.parse(new Date) + '.' + fExtension, disableFileUpload: true })
        setColumns(state.duplicateColumns)
    }
    // useEffect(() => {
    //     if (isError.isOcBcDuplicate && isError.isOcBcEmpty &&
    //         isError.isPNameDuplicate && isError.isPNameEmpty && isError.isBOPIdDuplicate &&
    //         isError.isBOPIdEmpty
    //     ) {
    //         //excelSuccessUpload('noErrors')
    //     } else {
    //         // eslint-disable-next-line no-empty
    //         if (notUploded) {
    //         } else {
    //             //fetchMyAPI()
    //         }
    //     }

    // }, [isError])

    useEffect(() => {
        // eslint-disable-next-line no-empty
        if (notUploded) {
        } else {
            fetchMyAPI()
        }

    }, [notUploded])

    const insertDefaultValues = (rows) => {
        let defaultValues = rows;
        replaceKeys(defaultValues);
        try
        {
            defaultValues.forEach(function (args) {
                // console.log(args)    
                if (args.productDescription !== "") {
                    args.productDescription = args.productDescription.toString()
                    if (args.productDescription.length > 200) {
                        args.productDescription = (args.productDescription.length > 200) ? args.productDescription.slice(0, 200) : args.productDescription;
                    }
                }
                if (args.brandOwnerProductId !== "") {
                    args.brandOwnerProductId = args.brandOwnerProductId.toString()
                    if (args.brandOwnerProductId.length > 100) {
                        args.brandOwnerProductId = (args.brandOwnerProductId.length > 100) ? args.brandOwnerProductId.slice(0, 100) : args.brandOwnerProductId;
                    }
                }
                if (args.outerCaseBarCode !== "") {
                    args.outerCaseBarCode = args.outerCaseBarCode.toString().toUpperCase()
                    if (args.outerCaseBarCode.length > 100) {
                        args.outerCaseBarCode = (args.outerCaseBarCode.length > 100) ? args.outerCaseBarCode.slice(0, 100) : args.outerCaseBarCode;
                    }
                }
                if (args.specification !== "") {
                    args.specification = args.specification.toString()
                    if (args.specification.length > 255) {
                        args.specification = (args.specification.length > 255) ? args.specification.slice(0, 255) : args.specification;
                    }
                }
                if (args.eanCode !== "") {
                    args.eanCode = args.eanCode.toString()
                    if (args.eanCode.length > 50) {
                        args.eanCode = (args.eanCode.length > 50) ? args.eanCode.slice(0, 50) : args.eanCode;
                    }
                }
                if (args.uomName !== "") {
                    args.uomName = args.uomName.toString()
                    if (args.uomName.length > 200) {
                        args.uomName = (args.uomName.length > 200) ? args.uomName.slice(0, 200) : args.uomName;
                    }
                }
                if (args.brandName !== "") {
                    args.brandName = args.brandName.toString()
                    if (args.brandName.length > 200) {
                        args.brandName = (args.brandName.length > 200) ? args.brandName.slice(0, 200) : args.brandName;
                    }
                }
                if (args.catName !== "") {
                    args.catName = args.catName.toString()
                    if (args.catName.length > 200) {
                        args.catName = (args.catName.length > 200) ? args.catName.slice(0, 200) : args.catName;
                    }
                }
                if (args.lsBrandOwnerId !== "") {
                    args.lsBrandOwnerId = args.lsBrandOwnerId.toString()
                    if (args.lsBrandOwnerId.length > 200) {
                        args.lsBrandOwnerId = (args.lsBrandOwnerId.length > 200) ? args.lsBrandOwnerId.slice(0, 200) : args.lsBrandOwnerId;
                    }
                }
                if (args.exportToTd) {
                    args.exportToTd = args.exportToTd.toUpperCase()
                }
    
                if (args.vatCode) {
                    args.vatCode = args.vatCode.toUpperCase()
                }
                if (args.fairTrade) {
                    args.fairTrade = args.fairTrade.toUpperCase()
                }
                if (args.organic) {
                    args.organic = args.organic.toUpperCase()
                }
                if (args.discountableForBopa) {
                    args.discountableForBopa = args.discountableForBopa.toUpperCase()
                }
                if (args.ethical) {
                    args.ethical = args.ethical.toUpperCase()
                }
    
                /* Outer case Zero Padding */
                if (args.outerCaseBarCode) {
                    args.outerCaseBarCode = args.outerCaseBarCode.toString().toUpperCase();
                    // if (args.outerCaseBarCode && args.outerCaseBarCode.length <= 13) {
                    //     args.outerCaseBarCode = args.outerCaseBarCode.padStart(13, '0')
                    // }
                }
                /* Outer case Zero Padding */
                if (args.eanCode) {
                    args.eanCode = args.eanCode.toString()
                    if (args.eanCode && args.eanCode.length <= 13) {
                        args.eanCode = args.eanCode.padStart(13, '0')
                    }
                }
                if ((args.startDate) !== "") {
                    args.startDate = moment(args.startDate).format('DD/MM/YYYY') === 'Invalid date' ? null : moment(args.startDate).format('DD/MM/YYYY');
                }
                if ((args.endDate) !== "") {
                    args.endDate = moment(args.endDate).format('DD/MM/YYYY') === 'Invalid date' ? null : moment(args.endDate).format('DD/MM/YYYY');
                }
                if (((!args.casePrice || args.casePrice === 0) && !args.startDate && !args.endDate)) {
                    args.startDate = null
                    args.endDate = null
                    args.casePrice = null
                }
                if ((args.endDate && (!args.casePrice && !args.casePrice === 0))) {
                    args.startDate = null
                    args.endDate = null
                    args.casePrice = null
                }
                if ((args.startDate && (!args.casePrice && !args.casePrice == 0))) {
                    args.startDate = null
                    args.endDate = null
                    args.casePrice = null
                }
                if ((args.casePrice || args.casePrice === 0)  && (!args.endDate)) {
                    args.endDate = null
                }
                if ((args.casePrice || args.casePrice === 0) && (!args.startDate)) {
                    args.startDate = null
                }
                if (args.caseQuantity === "") {
                    args.caseQuantity = null;
                }
                if (!args.eanCode) {
                    args.eanCode = 'N'
                }
                if (!args.exportToTd) {
                    args.exportToTd = 'N'
                }
                if (!args.memberTier) {
                    args.memberTier = 'Off-trade Member Tier'
                }
                if (!args.fairTrade) {
                    args.fairTrade = "N"
                }
                if (!args.organic) {
                    args.organic = "N"
                }
                if (!args.discountableForBopa) {
                    args.discountableForBopa = "N"
                }
                if (!args.ethical) {
                    args.ethical = "N"
                }
                if (!args.uomId) {
                    args.uomId = null
                }
                if (!args.brandId) {
                    args.brandId = null
                }
                if (!args.catId) {
                    args.catId = null
                }
                if (!args.lsBrandOwnerId) {
                    args.lsBrandOwnerId = null
                }

                if (args.countryOfOrigin) {
                    args.countryOfOrigin = args.countryOfOrigin.toString()                    
                }

                if (args.rt) {
                    args.rt = args.rt.toUpperCase()
                }

                if (!args.rt) {
                    args.rt = "N"
                }

                if (args.msc) {
                    args.msc = args.msc.toUpperCase()
                }

                if (!args.msc) {
                    args.msc = "N"
                }    
                
                if (args.rspca) {
                    args.rspca = args.rspca.toUpperCase()
                }

                if (!args.rspca) {
                    args.rspca = "N"
                }

                if (args.fsc) {
                    args.fsc = args.fsc.toUpperCase()
                }

                if (!args.fsc) {
                    args.fsc = "N"
                }

                if (args.rspo) {
                    args.rspo = args.rspo.toUpperCase()
                }

                if (!args.rspo) {
                    args.rspo = "N"
                }
    
            });
            return defaultValues;
        }
        catch (error) {
            // const { response: { data, status } } = error;
            console.log(error);
            return 0;
           
        }            
    }

    const fetchMyAPI = async () => {

        if (rows.length === 0) {
            setIsError({
                ...isError,
                isOcBcEmpty: true,
                // isPNameDuplicate: hasDuplicate(defaultValues, "productDescription"),
                isPNameEmpty: true,
                isBOPIdEmpty: true
            })
        } else {
            try {

                setState({ ...state, pageLoader: true })
                const fileUploadDetails = {
                    "fileName": state.fileName,
                    "productUploadList": rows
                }

                // console.log(fileUploadDetails)
                const { data } = await axios_product.post(`v2/products/upload`, fileUploadDetails);
                if (data) {
                    setState({
                        ...state,
                        pageLoader: false,
                        fileNameId: data.resultMap.result.id,
                        fileName: data.resultMap.result.fileName,

                        fuSelectedName: JSON.stringify({
                            id: data.resultMap.result.id,
                            fileName: data.resultMap.result.fileName
                        }),
                        freshUpload: true
                    })
                    setUploadDropdown([...uploadDropdown, {
                        id: data.resultMap.result.id,
                        fileName: data.resultMap.result.fileName
                    }])

                    const productActionColumns = [
                        {
                            headerName: "Result",
                            field: "result",
                            width: 100
                        },
                        {
                            headerName: "Error Message",
                            field: "errorMessage",
                            width: 350
                        },
                        {
                            headerName: "New",
                            field: "newProduct",
                            renderCell: (params) => (
                                <Checkbox
                                    checked={params.value === true}
                                    icon={<CircleIcon />}
                                    checkedIcon={<GridCheckCircleIcon />}
                                    color="chk_rounded"
                                    size='small'
                                    style={{ pointerEvents: 'none' }}
                                />
                            ),
                            headerAlign: "center",
                            align: 'center'
                        },
                        ...columns, 
                        {
                            headerName: "Action",
                            field: "action",
                            sortable: false,
                            width: 90,
                            renderCell: (params) => {
                                return <IconMenuLink params={params} onEdit={editRow} onDelete={deleteRow} />;
                            }
                        }];
                    // new array need to update
                    setColumns(productActionColumns)
                    setRows(data.resultMap.result.productUploadList)
                    setIsError({
                        ...isError,
                        isOcBcDuplicate: false,
                        isOcBcEmpty: false,
                        // isPNameDuplicate: false,
                        isPNameEmpty: false,

                        // isBOPIdDuplicate: false,
                        isBOPIdEmpty: false
                    })
                    setSnackBar({ ...snackBar, show: true, text: "File uploaded Successfully and available in existing upload", type: "success" })
                }

            } catch (error) {
                const { response: { data, status } } = error;
                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                }
                setState({ ...state, pageLoader: false })
            }
        }
    }
    useEffect(() => {
        fetchDropDownUpload();
    }, [])
    const fetchDropDownUpload = async () => {
        try {
            const { data } = await axios_product.get(`v2/products/upload/drop-down`);
            if (data) {
                setUploadDropdown(data.resultMap.result)
            }

        } catch (error) {
            //handle error
        }
    }

    useEffect(() => {
        console.log("drop-down useeffect");
        if (state.freshUpload === false) {
            fetchGetFileUpload();
        }
    }, [state.fuSelectedName])

    useEffect(() => {
        setState({ ...state, freshUpload: false })
    }, [state.freshUpload])

    const fetchGetFileUpload = async () => {
        setIsError({
            ...isError,
            isOcBcDuplicate: false,
            isOcBcEmpty: false,
            // isPNameDuplicate: false,
            isPNameEmpty: false,
            // isBOPIdDuplicate: false,
            isBOPIdEmpty: false
        })
        const selectedFile = state.fuSelectedName ? JSON.parse(state.fuSelectedName) : '';
        const selectedProdType= state.productType;
        console.log("selectedProdType from fetchGetFileUpload", selectedProdType);

        if (selectedFile !== '') {
            try {
                setState({ ...state, pageLoader: true })
                const { data } = await axios_product.get(`v2/products/upload/${selectedFile.id ? selectedFile.id : '-1'}?newProduct=${selectedProdType}`);


                setColumns([])
                if (data) {
                    // new array need to update
                    setState({ ...state, pageLoader: false })
                    setColumns(allColumns)
                    setTimeout(() => {
                        setRows(data.resultMap.result)
                    }, 100);

                }

            } catch (error) {
                const { response: { data, status } } = error;
                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                }
                setState({ ...state, pageLoader: false })

            }
        }

    }

    const editRow = (row) => {
        dispatch(editProductUploadModal(true))
        dispatch(editProductUpload(row))
    }

    const deleteRow = (row) => {

        dispatch(singleDeleteRow(row))
        dispatch(singleDeleteYes({
            key: 'startSingleDelete',
            value: true
        }))
        dispatch(warningModal({
            yesHide: !handleWarning
        }))
    }

    useEffect(() => {
        if (fileUpload.confirmSingleDelete) {
            deleteSingleRow()
        }
    }, [fileUpload.confirmSingleDelete])

    const deleteSingleRow = async () => {
        if (fileUpload.singleDeleteRow !== null) {
            try {
                setState({ ...state, pageLoader: true })
                const { data } = await axios_product.delete(`v2/products/importedProduct/${fileUpload.singleDeleteRow.id}`);
                //setColumns([])
                if (data) {
                    setState({ ...state, pageLoader: false })
                    const filteredRows = rows.filter(product => {
                        if (product.id !== fileUpload.singleDeleteRow.id) {
                            return product;
                        }
                    })
                    setRows(filteredRows)
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
                    singleDeleteRowDispatch()
                }

            } catch (error) {
                const { response: { data, status } } = error;
                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                }
                singleDeleteRowDispatch()
                setState({ ...state, pageLoader: false })
            }
        }

    }

    const handleEditRow = (event) => {
        setHandleEditRowModal(event)
    };

    useEffect(() => {
        if (fileUpload.confirmFileDelete) {
            deleteUploadedData()
        }
    }, [fileUpload.confirmFileDelete])

    const abandonDeleteFile = async () => {
        if (state.fuSelectedName !== "") {
            dispatch(fileDeleteYes({
                key: 'startFileDelete',
                value: true
            }))
            dispatch(warningModal({
                yesHide: !handleWarning
            }))
        } else {
            setSnackBar({ ...snackBar, show: true, text: "Select from existing or upload new file!", type: "error" })
        }
    }

    const deleteUploadedData = async () => {
        const selectedFile = state.fuSelectedName ? JSON.parse(state.fuSelectedName) : '';
        try {
            setState({ ...state, pageLoader: true })
            const { data } = await axios_product.delete(`v2/products/abandonUpload/${selectedFile.id}`);
            //setColumns([])
            if (data) {
                setState({ ...state, pageLoader: false, disableFileUpload: false })
                setUploadFlag(true)
                const filteredRows = uploadDropdown.filter(product => {
                    if (product.id !== selectedFile.id) {
                        return product;
                    }
                })
                setUploadDropdown(filteredRows)
                setColumns(state.duplicateColumns)
                setTimeout(() => {
                    setRows([])
                }, 100);
                setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
                FileDeleteDispatch()
            }

        } catch (error) {
            const { response: { data, status } } = error;
            if (!data) {
                setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
            } else {
                setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
            }
            FileDeleteDispatch()
            setState({ ...state, pageLoader: false })
        }
    }

    const singleDeleteRowDispatch = () => {
        dispatch(singleDeleteYes({
            key: 'startSingleDelete',
            value: false
        }))
        dispatch(singleDeleteYes({
            key: 'confirmSingleDelete',
            value: false
        }))
    }
    const FileDeleteDispatch = () => {
        dispatch(fileDeleteYes({
            key: 'startFileDelete',
            value: false
        }))
        dispatch(fileDeleteYes({
            key: 'confirmFileDelete',
            value: false
        }))
    }
    const handleChange = (event) => {
        const { value, name } = event.target;
        event.preventDefault();
    
        // Update the state based on the field name
        setState((prevState) => ({
            ...prevState,
            [name]: value,  // Update the field dynamically by its name
            ...(name === 'fuSelectedName' && { productType: 'all' })  // Reset productType to 'All' (null) when fuSelectedName is selected
        }));
        console.log("State after update:", state.fuSelectedName);
        console.log("handleChange : value ",value);
        console.log("handleChange : name ",name);
    };
    useEffect(() => {
        console.log("prodType useeffect");
        if (state.freshUpload === false) {
            fetchGetFileUpload();
        }
    }, [state.productType]);

    const handleProductTypeChange = (event) => {
        const { value, name } = event.target;
        event.preventDefault();
    
        // Update the state based on the field name
        setState((prevState) => ({
            ...prevState,
            [name]: value,  // Update the field dynamically by its name
            ...(name === 'productType')  // Reset productType to 'All' (null) when fuSelectedName is selected
        }));
        console.log("State after update:", state.fuSelectedName);
        console.log("handleChange : value ",value);
        console.log("handleChange : name ",name);
    };
    
    

    const reValidateFile = async () => {
        if (state.fuSelectedName !== "") {
            const selectedFile = state.fuSelectedName ? JSON.parse(state.fuSelectedName) : '';
            const prodType = state.productType;
            console.log("prodType", prodType);
            try {
                setState({ ...state, pageLoader: true })
                const fileUploadDetails = {
                    "id": selectedFile.id,
                    "fileName": selectedFile.fileName,
                    "newProduct": prodType
                }
                const { data } = await axios_product.post(`v2/products/validate`, fileUploadDetails);
                if (data) {
                    setState({ ...state, pageLoader: false })
                    // new array need to update
                    setRows(data.resultMap.result.productUploadList)
                    setSnackBar({ ...snackBar, show: true, text: "File revalidated!", type: "success" })
                }

            } catch (error) {
                const { response: { data, status } } = error;
                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                }
                setState({ ...state, pageLoader: false })
            }
        } else {
            setSnackBar({ ...snackBar, show: true, text: "Select from existing or upload new file!", type: "error" })
        }
    }
    const productUpdated = (data) => {
        setSnackBar({ ...snackBar, show: true, text: data.text, type: data.type });
        setTimeout(() => {
            reValidateFile()
        }, 1500);
    }
    const executeFileUpload = async () => {
        const { id, fileName } = state.fuSelectedName !== "" ? JSON.parse(state.fuSelectedName) : "";
        const prodType = state.productType
        console.log("executeFileUpload : prodType ", prodType);


        if (id && fileName) {
            try {
                setState({ ...state, pageLoader: true })

                const importedProductFilter = {
                    "newProduct": prodType
                }

                const { data } = await axios_product.post(`/v2/products/executeUpload/${id}`,importedProductFilter);
                if (data) {
                     setState({ ...state, pageLoader: false })
                    // const filteredRows = uploadDropdown.filter(product => {
                    //     if (product.id !== id) {
                    //         return product;
                    //     }
                    // })
                   // setUploadDropdown(filteredRows)
                    setColumns(state.duplicateColumns)
                    setTimeout(() => {
                        setRows([])
                    }, 100);
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
                }

            } catch (error) {
                const { response: { data, status } } = error;
                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                }
                setState({ ...state, pageLoader: false })
            }
        } else {
            setSnackBar({ ...snackBar, show: true, text: "Select from existing or upload a new file!", type: "error" })
        }
    }
    const onDownload = () => {
        const link = document.createElement("a");
        link.download = `sample-product-file-upload.xls`;
        link.href = downLoadTemplate;
        link.click();
    };
    const resetALL = (e) => {
        // console.log('has')
        e.preventDefault();
        setIsError({
            ...isError,
            isOcBcDuplicate: false,
            isOcBcEmpty: false,
            // isPNameDuplicate: false,
            isPNameEmpty: false,
            // isBOPIdDuplicate: false,
            isBOPIdEmpty: false
        })
        setState({
            ...state,
            fileNameId: '',
            fileName: '',
            fuSelectedName: "",
            productType: '',
            disableFileUpload: false
        })
        setUploadFlag(true);
        setTimeout(() => {
            setColumns([
                {
                    headerName: "Name",
                    field: "productDescription",
                    flex: 1,
                    minWidth: 200,
                },
                {
                    headerName: "Brand Owner Product Id",
                    field: "brandOwnerProductId",
                    hide: true,
                    minWidth: 120,
                },
                {
                    headerName: "Outer Case Bar Code",
                    field: "outerCaseBarCode",
                    minWidth: 120
                },
                {
                    headerName: "Specification",
                    field: "specification",
                    width: 120,
                    hide: true
                },
                {
                    headerName: "Case Quantity",
                    field: "caseQuantity",
                    width: 120,
                    hide: true
                },
                {
                    headerName: "EAN Code",
                    field: "eanCode",
                    width: 120,
                    hide: true
                },
                {
                    headerName: "RSP",
                    field: "rsp",
                    width: 120,
                    hide: true
                },
                {
                    headerName: "Vat Code",
                    field: "vatCode",
                    width: 120,
                    hide: true
                },
                {
                    headerName: "Export to TD",
                    field: "exportToTd",
                    width: 120,
                    hide: true
                },
                {
                    headerName: "UOM ID",
                    field: "uomId",
                    width: 120,
                    hide: true
                },
                {
                    headerName: "UOM Name",
                    field: "uomName",
                    width: 120,
                    hide: true
                },
                {
                    headerName: "Brand ID",
                    field: "brandId",
                    width: 120,
                    hide: true
                },
                {
                    headerName: "Brand name",
                    field: "brandName",
                    width: 120
                },
                {
                    headerName: "Cat ID",
                    field: "catId",
                    width: 120,
                    hide: true
                },
                {
                    headerName: "Category Name",
                    field: "catName",
                    width: 120
                },
                {
                    headerName: "Price (£)",
                    field: "casePrice",
                    width: 120
                },
                {
                    headerName: "Start Date",
                    field: "startDate",
                    width: 120,
                    hide: true
                },
                {
                    headerName: "End Date",
                    field: "endDate",
                    width: 120,
                    hide: true
                },
                {
                    headerName: "Member Tier",
                    field: "memberTier",
                    minWidth: 120
                },
                {
                    headerName: "Associated Levy",
                    field: "lsBrandOwnerId",
                    width: 120,
                    hide: true
                },
                {
                    headerName: "Fair Trade",
                    field: "fairTrade",
                    width: 120,
                    hide: true
                },
                {
                    headerName: "Organic",
                    field: "organic",
                    width: 120,
                    hide: true
                },
                {
                    headerName: "Discountable for BOPA",
                    field: "discountableForBopa",
                    width: 120,
                    hide: true
                },
                {
                    headerName: "Ethical",
                    field: "ethical",
                    width: 120,
                    hide: true
                }
            ])
        }, 100);
        setTimeout(() => {
            setRows([]);
            window.location.reload();
        }, 250);
    }
    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Imports Products" />
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -3 } }}>
                    <div className="importButtons">
                        <div className='table-actionable'>
                            <Button variant="contained" component="label" size='small' disabled={state.disableFileUpload}>
                                Upload
                                <input hidden accept=".xls,.xlsx" multiple type="file" onChange={handleUpload} />
                            </Button>
                        </div>
                        <div className="excelList">
                            <FormControl fullWidth sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: { xs: 'center', sm: 'end' } }}>
                                <FormLabel htmlFor="msType" sx={{ textAlign: 'left', paddingRight: '15px' }}>Existing uploads</FormLabel>
                                <Select
                                    size="small"
                                    id="fuSelectedName"
                                    value={state.fuSelectedName}
                                    aria-describedby="fuNameError"
                                    sx={{ width: '200px' }}
                                    onChange={(e) => handleChange(e)}
                                    name="fuSelectedName"
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 48 * 4.5 + 8
                                            },
                                        },
                                    }}
                                >
                                    {uploadDropdown.map((option) => (
                                        <MenuItem value={JSON.stringify({
                                            id: option.id,
                                            fileName: option.fileName
                                        })}
                                            key={option.id}
                                            sx={{ fontSize: '0.85rem' }}>{option.fileName}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: { xs: 'center', sm: 'end' }, mt: 2 }}>
                            <FormLabel htmlFor="productType" sx={{ textAlign: 'left', paddingRight: '15px' }}>Product Type</FormLabel>
                            <Select
                                size="small"
                                id="productType"
                                value={state.productType || 'all'} 
                                aria-describedby="productTypeError"
                                sx={{ width: '200px' }}
                                onChange={handleProductTypeChange}// Handle changes in Product Type dropdown
                                name="productType"
                                disabled={!state.fuSelectedName}// Disable if 'fuSelectedName' is not selected
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 48 * 4.5 + 8
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="all">All</MenuItem> {/* Ensure 'All' label is visible when value is null */}
                                <MenuItem value="true">New</MenuItem>
                                <MenuItem value="false">Existing</MenuItem>
                            </Select>
                        </FormControl>
                        </div> 
                        <div>
                            <Button onClick={(e) => resetALL(e)}>Clear Table</Button>
                        </div>
                    </div>
                    <div className='table-actionable'>
                        <Button variant="contained" color="secondary" size='small' onClick={onDownload}>
                            Download Template
                        </Button>
                    </div>
                    <div className='table-actionable'>
                        <Button variant="contained" color="tertiary" size='small' onClick={reValidateFile}>
                            Revalidate
                        </Button>
                    </div>
                    <div className='table-actionable'>
                        <Button variant="contained" color="success" size='small' onClick={executeFileUpload}>
                            Execute
                        </Button>
                    </div>
                    <div className='table-actionable'>
                        <Button variant="contained" color="warning" size='small' onClick={abandonDeleteFile}>
                            Abandon File
                        </Button>
                    </div>

                </Box>
                <br />
                <br />
                {
                    isError.isOcBcDuplicate && (<><Alert severity="error">Outer Case Bar Code has duplicate values kindly remove entry and upload file again...!</Alert></>)
                }
                {
                    isError.isOcBcEmpty && (<><Alert severity="error">Outer Case Bar Code has empty values kindly remove entry and upload file again...!</Alert></>)
                }
                {/* {
                    isError.isPNameDuplicate && (<><Alert severity="error">Product Name has duplicate values kindly remove entry and upload file again...!</Alert></>)
                } */}
                {
                    isError.isPNameEmpty && (<><Alert severity="error">Product Name has empty values kindly remove entry and upload file again...!</Alert></>)
                }
                {
                    // isError.isBOPIdDuplicate && (<><Alert severity="error">Brand owner product ID has duplicate values kindly remove entry and upload file again...!</Alert></>)
                }
                {
                    isError.isBOPIdEmpty && (<><Alert severity="error">Brand owner product ID has empty values kindly remove entry and upload file again...!</Alert></>)
                }
                <br />
                <DataTableClientSide defaultGrid={state.defaultGrid} columns={columns ? columns : []} rows={rows ? rows : []}>

                </DataTableClientSide>
                {
                    fileUpload.editProductUploadModal && (
                        <ProductFileUploadModal
                            onShow={fileUpload.editProductUploadModal}
                            fileName={JSON.parse(state.fuSelectedName).fileName}
                            fileNameId={JSON.parse(state.fuSelectedName).id}
                            onUpdate={productUpdated}
                        />
                    )
                }
            </Container>
            {
                handleWarning && (
                    <WarningModal
                        size="small_overlay"
                        deleteRowUpload={state.deleteRowUpload}
                        deleteAllProductUpload={state.deleteAllProductUpload}
                        onShow={handleWarning} />
                )
            }
            {snackBar.show && <SnackBarDialog
                onShow={snackBar.show}
                text={snackBar.text}
                type={snackBar.type}
                onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
            />}
        </>
    );
}

export default ProductFileUpload;
