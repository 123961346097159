import { configureStore } from "@reduxjs/toolkit"
import filter from "./slices/externalFilter"
import dataGrid from "./slices/dataGrid"
import modalSetting from "./slices/modals"
import generalProducts from "./slices/generalProduct"
import brandProduct from "./slices/brandProduct"
import uomProduct from "./slices/uomProduct"
import vcProduct from "./slices/vcProduct"
import cbsProduct from "./slices/cbsProduct"
import pdcProduct from "./slices/pdcProduct"
import supplierProduct from "./slices/supplierProduct"
import dcProduct from "./slices/distributionChannel"
import supplierAccounts from "./slices/supplierAccounts"
import aggregationGroup from "./slices/aggregationGroup"
import rdsoe from "./slices/rdsoe"
import retrodiscountschemes from "./slices/retrodiscountschemes"
import invoiceSummary from "./slices/invoiceSummary"
import fileUpload from "./slices/fileUpload"
import retroFileUpload from "./slices/retroFileUpload"
import supplierReports from "./slices/supplierReports"
import ediFileLog from "./slices/ediFileLog"
import failedFile from "./slices/failedFiles"
import failedImports from "./slices/failedImports"
import generalPdcProduct from "./slices/generalPdcProduct"
import pricePdcProduct from "./slices/pricePdcProduct"
import commonProduct from "./slices/commonProduct"
import manualInvoice from "./slices/manualInvoice"
import sma from "./slices/supplierMemberAccounts"
import pdcsFileUpload from "./slices/pdcsFileUpload"
import organisationType from "./slices/organisationType"
import programme from "./slices/programme"
import discountScheme from "./slices/discountScheme"
import levyScheme from "./slices/levyScheme"
import invoiceReprocess from "./slices/invoiceReprocess"
import promotionType from "./slices/promotionType"
import invoiceViewDetails from "./slices/invoiceViewDetails"
import memberBclValues from "./slices/memberBclValues"
import processManager from "./slices/processManager"
import categoriesData from "./slices/categoriesData"
import invoiceDisputes from "./slices/invoiceDisputes"
import memberTierData from "./slices/memberTierData"
import membersupplierReports from "./slices/membersupplierReports"

export default configureStore({
  reducer: {
    externalFilter: filter,
    dataGridTable: dataGrid,
    modalActions: modalSetting,
    generalProduct: generalProducts,
    brandProducts: brandProduct,
    uomProducts: uomProduct,
    vcProducts: vcProduct,
    cbsProducts: cbsProduct,
    pdcProducts: pdcProduct,
    supplierProducts: supplierProduct,
    dcProducts: dcProduct,
    supplierAccount: supplierAccounts,
    aggregationGroups: aggregationGroup,
    rdsoes: rdsoe,
    retrodiscountscheme: retrodiscountschemes,
    invoiceSummarys: invoiceSummary,
    productFileUpload: fileUpload,
    retrospectiveFileUpload: retroFileUpload,
    supplierReport: supplierReports,
    ediFileLogs: ediFileLog,
    failedFiles: failedFile,
    failedImports,
    generalPdcProducts: generalPdcProduct,
    pricePdcProducts: pricePdcProduct,
    commonProducts: commonProduct,
    invoiceManualEntry: manualInvoice,
    smas: sma,
    pdcsUpload: pdcsFileUpload,
    organisationTypes: organisationType,
    programmes: programme,
    discountSchemes: discountScheme,
    levySchemes: levyScheme,
    invoiceReprocess,
    promotionTypes: promotionType,
    invoiceViewDetails,
    memberBclEntry: memberBclValues,
    processManagers: processManager,
    categoriesData,
    invoiceDisputes,
    memberTierData,
    membersupplierReports
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})