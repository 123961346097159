import { Alert, Snackbar } from '@mui/material'
import { useState } from 'react';

function SnackBarDialog({ onShow, type, text, onHide, vpositon, hposition, duration }) {
    const [open, setOpen] = useState(onShow);

    var vertical = vpositon;
    var horizontal = hposition;
    var hideDuration = duration;

    const handleClose = () => {
        if(onHide){
            onHide(false)
            setOpen(false)
        }
    };

    return (
        <>
            <Snackbar open={open} autoHideDuration={hideDuration ? hideDuration : 4000} onClose={handleClose} anchorOrigin={{ vertical: vertical ? vertical : "bottom", horizontal: horizontal ? horizontal : "right"}}>
                <Alert onClose={handleClose} severity={type ? type : "success"} sx={{ width: '100%', fontSize: '16px' }}>
                    {text}
                </Alert>
            </Snackbar>
        </>
    );
}

export default SnackBarDialog;