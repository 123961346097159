import React, { useCallback, useEffect, useState } from "react";
import {
    Grid, Paper, Box, FormLabel, Button, Autocomplete,
    TextField, FormHelperText, Checkbox, FormControlLabel, FormControl, InputAdornment, IconButton, Menu, MenuItem, Modal
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from 'react-redux';
import axios from "../../../../services/utility/axios";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { DataTableClientSide } from "../../../../components/shared/data-table/DataTableClientSide";
import AddEditPriceModal from "./prices/AddEditPriceModal";
import AddIcon from '@mui/icons-material/Add';
import SnackBarDialog from "../../../../components/shared/SnackBarDialog";
import { deletePrice, priceDeleteYes, setHasEditPrice, setListOfPrice, hasTabEdit } from "../../../../store/slices/generalProduct";
import PageLoader from "../../../../components/shared/PageLoader";
import moment from "moment";
import { LoadingButton } from "@mui/lab";

const IconMenuLink = ({ params, onEdit, onDelete }) => {
    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'edit') {
            if (onEdit) {
                onEdit(row)
            }
        } else if (action === 'delete') {
            if (onDelete) {
                onDelete(row)
            }
        }
        setAnchorEl(null);
    };


    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'edit')}>Edit Price</MenuItem>
            <MenuItem onClick={(e) => handleClose(e, 'delete')}>Delete Price</MenuItem>
        </Menu>
    </>)
}

const Price = () => {
    const [state, setState] = useState({
        price: '',
        pageLoader: false,
        defaultGrid: {
            paginationMode: 'client',
        },
        handleAddEditPriceModal: false,
        listPrice: [],
        warningPriceModal: false
    });
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const [columns, setColumns] = useState([]);
    const [deletConfirmation, setDeleteConfirmation] = useState(false)
    const { priceList, handleWarning } = useSelector((state) => {
        return {
            priceList: state.generalProduct,
            handleWarning: state.modalActions.closeWaringAddProduct
        }
    });
    const [dLoading, setDLoading] = useState(false)

    const dispatch = useDispatch();

    const addPriceModal = (event) => {

        setState({ ...state, handleAddEditPriceModal: true })

    }
    const hideNewProductModal = (event, reason) => {
        setState({ ...state, handleAddEditPriceModal: event })
    }
    const allColumns = [
        {
            headerName: "Price (£)",
            field: "price",
            minWidth: 150
        },
        {
            headerName: "Purchasing Tier",
            field: "memberTier",
            valueGetter: (params) => params.row.memberTier ? params.row.memberTier.name : "",
            minWidth: 200
        },
        {
            headerName: "BOPA",
            field: "discountableForBopa",
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
            align: "center",
            headerAlign: "center",
            minWidth: 150
        },
        {
            headerName: "Start Date",
            field: "startDate",
            minWidth: 165
        },
        {
            headerName: "End Date",
            field: "endDate",
            minWidth: 165
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                const { row } = params;
                if (row.endDate === "" || row.endDate === null || row.endDate === undefined || moment(row.endDate, 'DD/MM/YYYY').isSameOrAfter(moment(), "day")) {
                    return <IconMenuLink params={params} onEdit={onEdit} onDelete={onDelete} />
                } else {
                    return ""
                }
            },
        }
    ];

    const allProductPricelists = async () => {
        try {
            setState({ ...state, pageLoader: true })
            setTimeout(() => {
                setColumns(allColumns)
            }, 100);
            dispatch(setListOfPrice([]))
            const id = priceList.productId;
            const { data } = await axios.get(`prices/${id}/getPrices`)
            const unique = data.resultMap.result.filter((value, index, self) => self.findIndex((m) => m.id === value.id) === index);
            if (data) {
                dispatch(hasTabEdit({ editTab: false }))
                dispatch(setListOfPrice(unique))
                setState({ ...state, pageLoader: false })
            }

        } catch (error) {
            const { response: { data, status } } = error;
            if (!data) {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" })
            } else {
                if (data.message !== "No Prices found") {
                    setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "warning" })
                }
            }
            dispatch(hasTabEdit({ editTab: false }))
            setState({ ...state, pageLoader: false })
        }
    }
    useEffect(() => {
        allProductPricelists();
    }, []);

    const onSubmitPrice = (params) => {
        setSnackBar({ ...snackBar, show: true, text: params.text, type: params.type })
    }
    const onEdit = (row) => {
        setState({ ...state, handleAddEditPriceModal: true });
        dispatch(setHasEditPrice(row))
    }
    const onDelete = async (row) => {
        dispatch(deletePrice(row))
        setState({ ...state, warningPriceModal: true })

    }
    useEffect(() => {
        if (deletConfirmation) {
            deletePriceRow()
        }
    }, [deletConfirmation])

    const deletePriceRow = async () => {
        if (priceList.deletePrice !== null) {
            try {
                const payload = {
                    "startDate": priceList.deletePrice.startDate,
                    "endDate": priceList.deletePrice.endDate ? priceList.deletePrice.endDate : null,
                    "price": priceList.deletePrice.price,
                    "memberTier": priceList.deletePrice.memberTier ? priceList.deletePrice.memberTier : null,
                    "levyScheme": priceList.deletePrice.levyScheme ? { "levySchemeId": priceList.deletePrice.levyScheme.levySchemeId } : null,
                    "discountableForBopa": priceList.deletePrice.discountableForBopa
                }
                setState({ ...state, pageLoader: true })
                const { data } = await axios.delete(`prices/${priceList.productId}/deletePrice/${priceList.deletePrice.id}`, { data: payload });

                if (data.success) {

                    setDeleteConfirmation(false)
                    const filteredRows = priceList.listOfPrice.filter(price => {
                        if (price.id !== priceList.deletePrice.id) {
                            return price;
                        }
                    })
                    dispatch(setListOfPrice(filteredRows))
                    setSnackBar({ ...snackBar, show: true, text: 'Price Deleted Successfully', type: "success" })
                    setState({ ...state, pageLoader: false, warningPriceModal: false })
                    dispatch(deletePrice(null))
                    setDLoading(false)
                }

            } catch (error) {
                const { response: { data, status } } = error;
                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" })
                }
                setDeleteConfirmation(false)
                setState({ ...state, pageLoader: false, warningPriceModal: false })
                dispatch(deletePrice(null))
                setDLoading(false)
            }
        }

    }

    const handleDeletePrice = () => {
        setDeleteConfirmation(true)
        setDLoading(true)
    }
    const handlePriceDeleteWaring = () => {
        setDeleteConfirmation(false)
        dispatch(deletePrice(null))
        setState({ ...state, warningPriceModal: false })
        setDLoading(false)
    }
    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Box sx={{ flexGrow: 1, paddingX: 2 }} >
                <Box sx={{ textAlign: 'right', marginBottom: '15px' }}>
                    <Button variant="contained" onClick={(e) => addPriceModal(e)} startIcon={<AddIcon />}>
                        Add Price
                    </Button>
                </Box>
                <DataTableClientSide
                    columns={columns ? columns : []}
                    defaultGrid={state.defaultGrid}
                    rows={priceList.listOfPrice ? priceList.listOfPrice : []}
                >

                </DataTableClientSide>
                {state.handleAddEditPriceModal && (
                    <>
                        <AddEditPriceModal size="medium_overlay"
                            onShow={state.handleAddEditPriceModal}
                            onHide={(e, reason) => hideNewProductModal(e, reason)}
                            onSubmitPrice={onSubmitPrice}
                        />
                    </>
                )}
                {snackBar.show && <SnackBarDialog
                    onShow={snackBar.show}
                    text={snackBar.text}
                    type={snackBar.type}
                    onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                />}
            </Box>
            <Modal
                open={state.warningPriceModal}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handlePriceDeleteWaring();
                    }
                }}
            >
                <Box component="div" className="overlay" id="addEditModal">
                    <div className={`overlay-box small_overlay`}>
                        <div className="content">
                            <div className="overlayBody">
                                <div className="overlay-content">
                                    <Box component="div" sx={{ textAlign: 'center', padding: 1.5 }}>
                                        <p className="fontMedium">Are you sure want to delete price ?</p>
                                        <Grid sx={{ paddingTop: 2 }} container direction="row" alignItems="center" justifyContent="center">
                                            <LoadingButton
                                                onClick={handleDeletePrice}
                                                loading={dLoading}
                                                loadingPosition="center"
                                                endIcon=""
                                                variant="contained"
                                                type="submit" sx={{ marginRight: '15px' }}
                                            >
                                                Yes
                                            </LoadingButton>
                                            <Button variant="outlined" onClick={handlePriceDeleteWaring}>No</Button>
                                        </Grid>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
};
export default Price;