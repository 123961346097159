import { useEffect, useRef } from "react";
import { Checkbox } from '@mui/material';

const updateInput = (ref, checked) => {
    const input = ref.current;
    if (input) {
        input.checked = checked;
        input.indeterminate = checked == null; // null
    }
};

export const ThreeStateCheckbox = ({ name, checked, onChange, id, label }) => {
    const inputRef = useRef(null);
    const checkedRef = useRef(checked);
    useEffect(() => {
        checkedRef.current = checked;
        updateInput(inputRef, checked);
    }, [checked]);
    const handleClick = () => {
        switch (checkedRef.current) {

            case true:
                checkedRef.current = false; // false
                break;
            case false:
                checkedRef.current = null; // null
                break;
            default: // null
                checkedRef.current = true; // true
                break;
        }
        updateInput(inputRef, checkedRef.current);
        if (onChange) {
            onChange(checkedRef.current, name);
        }
    };
    return (
        <div className="triStateCheckBox">
            <input ref={inputRef} type="checkbox" name={name} onClick={handleClick} id={id} />
            <label htmlFor={id}>
                <i>
                    <svg className="chk_checked" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.5" width="17" height="17" rx="2.5" fill="#26BE8D" stroke="#07A572" />
                        <path d="M12.6544 5.00025C12.4168 4.99432 12.1642 5.09298 11.9664 5.29219L7.47049 9.82249L6.03317 8.37404C5.68265 8.02066 5.15592 7.98269 4.85189 8.2885L4.20486 8.94037C3.90145 9.24631 3.93943 9.77783 4.28982 10.1311L6.92296 12.7839C7.21909 13.0831 7.74407 13.0608 8.0189 12.7839L13.7099 7.04859C14.0604 6.69535 14.0989 6.16446 13.7948 5.85865L13.1479 5.20615C13.0147 5.07267 12.8393 5.00479 12.6544 5.00025Z" fill="white" />
                    </svg>

                    <svg className="chk_interchecked" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.5" width="17" height="17" rx="2.5" fill="white" stroke="#868C94" />
                    </svg>

                    <svg className="chk_notchecked" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.5" width="17" height="17" rx="2.5" fill="#FEAE4F" stroke="#E78A1C" />
                        <path d="M5.11101 10.7677C4.72048 11.1583 4.72048 11.7914 5.11101 12.182L5.81812 12.8891C6.20864 13.2796 6.8418 13.2796 7.23233 12.8891L9.00009 11.1213L10.7679 12.8891C11.1584 13.2796 11.7916 13.2796 12.1821 12.8891L12.8892 12.182C13.2797 11.7915 13.2797 11.1583 12.8892 10.7678L11.1214 8.99998L12.8892 7.23221C13.2797 6.84168 13.2797 6.20852 12.8892 5.81799L12.1821 5.11089C11.7916 4.72036 11.1584 4.72036 10.7679 5.11089L9.00009 6.87866L7.23235 5.11091C6.84182 4.72038 6.20866 4.72038 5.81813 5.11091L5.11103 5.81802C4.7205 6.20854 4.7205 6.84171 5.11103 7.23223L6.87877 8.99998L5.11101 10.7677Z" fill="white" />
                    </svg>

                </i>
                {
                    label && <span> {label} </span>
                }
            </label>
        </div>
    );
};