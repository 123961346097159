import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedrow: []
}

const invoiceViewDetails = createSlice({
    name: 'invoice_details',
    initialState,
    reducers: {
        setSelectedRow: (state, action) => {
            //state.selectedrow = action.payload
            const { selectedrow } =  action.payload;
            state.selectedrow = selectedrow
        }
    }
})

export const { setSelectedRow } = invoiceViewDetails.actions

export default invoiceViewDetails.reducer