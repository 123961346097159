import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    tier_name: '',
    tier_description: '',
    tier_startDate: null,
    tier_endDate: null,
    editMemberTier: false,
    hasMemberTierEditData: null,
    tier_id: 0,
    isError: {
        tier_name: false,
        tier_description: false,
        tier_startDate: false
    }
}

const memberTierData = createSlice({
    name: 'member_tier_data',
    initialState,
    reducers: {
        memberTierInput: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value;
            state[key].length === 0 ? state.isError[key] = true : state.isError[key] = false;
        },
        setMemberTierDate: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value;
            state.isError[key] ? (state[key] === null || state[key] === ""  || state[key] === 'Invalid date') ? state.isError[key] = true : state.isError[key] = false : '';
        },
        sethasEditMemberTierData: (state, action) => {
            state.hasMemberTierEditData = action.payload;
        },        
        hasMemberTierEdit: (state, action) => {
            const { editMemberTier, tier_id } = action.payload;
            state.editMemberTier = editMemberTier;
            state.tier_id = tier_id;
        },        
        getIsError: (state, action) => {
            const { key, value } = action.payload;
            state.isError[key] = value
        },
        resetMemberTier: () => {
            return initialState
        },
    }
})

export const { resetMemberTier, getIsError, memberTierInput, setMemberTierDate, hasMemberTierEdit, sethasEditMemberTierData, hasCategoryEdit, sethasEditCategoryData, 
    categoryInput, setcategoryLevel, setcategoryParent, setAutocomplete, categorBasicInput } = memberTierData.actions

export default memberTierData.reducer