import Footer from "../core/Footer";
import Header from "../core/Header";

import { useLocation } from 'react-router-dom';
import { useEffect } from "react";

import { useDispatch, useSelector } from 'react-redux';
import { setRowPage, setPageNo} from "../../store/slices/dataGrid";
import { resetExternalFilter } from "../../store/slices/externalFilter";

const DefaultLayout = (props) => {
    const { children } = props;
    let location = useLocation();

    const { rowPerPage, resetTable, applExternalFilter } = useSelector((state) => {
        return {
            rowPerPage: state.dataGridTable.pageData,
            resetTable: state.dataGridTable,
            applExternalFilter: state.externalFilter,
        }
    });


    
    return (
        <>
            <Header />
            <div className="main-content">
                {children}
            </div>
            <Footer />
        </>
    );
}
export default DefaultLayout;
