import { Checkbox, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl } from '@mui/material'
import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { setAutocomplete, setEditSupplierVatCode, brandSearch, getIsError, setVatCodeList, setSupplierVatCodeInput, setLatestVatCode, resetSupplier } from "../../../store/slices/supplierProduct";
import { useDispatch, useSelector } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import axios_utilities from "../../../services/utility/axios-utilities";
import axios_supplier from '../../../services/utility/axios-supplier';
import LoadingButton from '@mui/lab/LoadingButton';
import SnackBarDialog from '../../../components/shared/SnackBarDialog';
import { ExitToApp } from '@mui/icons-material';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));


function AddEditVatCodeMappings({ onShow, size = '', onHide }) {

    const [state, setState] = useState({
        loadSupplier: false,
        formValid: true,
        defaultmode: false,
        id: 0
    });
    const [dropDownId, setDropDownId] = useState(null);

    const [isError, isSetError] = useState({
        bopaError: false,
    })
    const [loader, setLoader] = useState(false)
    const [open, setOpen] = useState(onShow);
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const {
        supplier_vat_code_container,
        editData,
        editSupplierVatCode,
        hasEditData
    } = useSelector((state) => {
        return {
            supplier_vat_code_container: state.supplierProducts,
            editData: state.supplierProducts.editData,
            editSupplierVatCode: state.supplierProducts.editSupplierVatCode,
            hasEditData: state.supplierProducts.hasEditData
        }
    })

    useEffect(() => {
        const fetchMyAPI = async () => {
            if (supplier_vat_code_container.supplier_vat_code.vat_code_options.length === 0) {
                axios_utilities.get('vatcodes/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            dispatch(setAutocomplete({
                                parent: 'supplier_vat_code',
                                key: 'vat_code_options',
                                value: unique
                            }));
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
        }
        fetchMyAPI();
    }, [])

    const dispatch = useDispatch();

    const handleClose = () => {
        //setState({ ...state, defaultmode: false }) 
        dispatch(setLatestVatCode(null))
        if (onHide) {
            onHide(false)
        }
        dispatch(setSupplierVatCodeInput(''))
        dispatch(setEditSupplierVatCode(null))
        dispatch(getIsError({ key: 'suppliervatcodename', value: false }))
        dispatch(getIsError({ key: 'vat_code_selected', value: false }))
    };

    const handleSelection = (event, newValue, parentname, name) => {
        if(editSupplierVatCode) { 
            setState({ ...state, defaultmode: true })
        }
        if (event.target.value !== "" || event.target.value !== null) {
            dispatch(brandSearch({
                parent: parentname,
                key: name,
                value: newValue
            }))
        }
    }

    const handleInputChange = (event) => {
        if(editSupplierVatCode) {
            setState({ ...state, defaultmode: true })
        }
        const { value } = event.target;
        const updatevalue = value.replace(/[^A-Za-z]/ig, '')
        dispatch(setSupplierVatCodeInput(updatevalue))
    }

    useEffect(() => {
        if ((supplier_vat_code_container.suppliervatcodename === null || supplier_vat_code_container.suppliervatcodename === "") || (supplier_vat_code_container.supplier_vat_code.vat_code_selected === null || supplier_vat_code_container.supplier_vat_code.vat_code_selected === "")) {
            setState({ ...state, formValid: true, defaultmode: false })
        } else {
            setState({ ...state, formValid: false, defaultmode: true })
        }
    }, [supplier_vat_code_container.suppliervatcodename, supplier_vat_code_container.supplier_vat_code.vat_code_selected])

    useEffect(() => {
        const {
            suppliervatcodename,
            vat_code_selected
        } = supplier_vat_code_container.isError
        if (!suppliervatcodename && !vat_code_selected) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true })
        }

    }, [supplier_vat_code_container.isError])


    const handleVatCodeSubmit = async (event) => {
        event.preventDefault();
        let addSupplierPrice = null; 
        if (!state.formValid) {
            const id = editData ? editData.id : hasEditData.id;
            addSupplierPrice = {
                "supplierId": id,
                "supplierVatCode": supplier_vat_code_container.suppliervatcodename,
                "vatCodeId": supplier_vat_code_container.supplier_vat_code.vat_code_selected.id
            }
            try {
                if ((editData || hasEditData) && !editSupplierVatCode) {
                    setLoader(true)
                    const { data } = await axios_supplier.post(`supplier/${id}/vatCode`, { ...addSupplierPrice });
                    if (data.success) {
                        setLoader(false)
                        setSnackBar({ ...snackBar, show: true, text: "VAT code added successfully!", type: "success", })
                        const { data } = await axios_supplier.get(`supplier/${id}/vatCode`);
                        if (data !== null || data !== undefined) {
                            dispatch(setVatCodeList(data))
                        }
                        setTimeout(() => {
                            handleClose()
                        }, 1500);
                    }
                }
                else if (editSupplierVatCode) {
                    const id = editData ? editData.id : hasEditData.id;
                    setLoader(true)
                    const { data } = await axios_supplier.put(`supplier/${id}/vatCode/${editSupplierVatCode.id}`, { ...addSupplierPrice })
                    if (data.success) {
                        setLoader(false)
                        const { data } = await axios_supplier.get(`supplier/${id}/vatCode`);
                        if (data !== null || data !== undefined) {
                            dispatch(setVatCodeList(data))
                            setSnackBar({ ...snackBar, show: true, text: "VAT code updated Successfully!", type: "success", })
                            setTimeout(() => {
                                handleClose()
                            }, 1500);
                        }
                    }
                }
            } catch (error) {
                setLoader(false)
                const { response: { data, status } } = error;
                setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error", })
            }
        }
    }

    let defaultVatCode = null;
    if (supplier_vat_code_container.editSupplierVatCode) {
        defaultVatCode = {
            id: supplier_vat_code_container.editSupplierVatCode.vatCodeId,
            code: supplier_vat_code_container.editSupplierVatCode.vatCode
        }
    }

return (
    <>
        <Modal
            open={open}
            aria-labelledby="add-edit-price-title"
            aria-describedby="add-edit-price-description"
            keepMounted
            onClose={(_, reason) => {
                if (reason !== "backdropClick") {
                    handleClose();
                }
            }}
        >
            <form onSubmit={(e) => handleVatCodeSubmit(e)}>
                <Box component="div" className="overlay" id="addEditModal">
                    <div className={`overlay-box ${size ? size : ''}`}>
                        <div className="content">
                            <div className="overlayBody">
                                <div className="overlay-header text-center"> Vat code Details
                                    <CancelIcon className="closeBtn" onClick={handleClose} />
                                </div>
                                <div className="overlay-content">
                                    <Grid item xs={12} md={12}>

                                        <Item sx={{ marginBottom: 2.5 }}>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="brands_owner_search" className="fontSemiBold mandatory">Supplier VAT Code</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={5.15}>
                                                    <Item>
                                                        <div className="input-group">
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    inputProps={{ maxLength: 1 }}
                                                                    size="small"
                                                                    id="suppliervatcodename"
                                                                    aria-describedby="suppliervatcodename_error"
                                                                    onChange={(e) => handleInputChange(e)}
                                                                    value={supplier_vat_code_container['suppliervatcodename']}
                                                                    error={supplier_vat_code_container.isError.suppliervatcodename}
                                                                    name="suppliervatcodename"
                                                                />
                                                                {supplier_vat_code_container.isError.suppliervatcodename && (<FormHelperText className='errorHelperTxt' id="suppliervatcodename_error">
                                                                    Please enter unique value
                                                                </FormHelperText>)}
                                                            </FormControl>
                                                        </div>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                        <Item sx={{ marginBottom: 2.5 }}>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="supplier_vat_code" className="fontSemiBold mandatory">VAT Code</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={5.15}>
                                                    <Item>
                                                        <Autocomplete
                                                            // eslint-disable-next-line max-len
                                                            // defaultValue={supplier_vat_code_container.supplier_vat_code.vat_code_selected || (supplier_vat_code_container.hasEditData ? supplier_vat_code_container.hasEditData.vatCode : null)}
                                                            defaultValue={defaultVatCode ? defaultVatCode : null}
                                                            //defaultValue= {dropDownId}
                                                            id="supplier_vat_code_search"
                                                            options={supplier_vat_code_container.supplier_vat_code.vat_code_options}
                                                            getOptionLabel={(option) => option.code || ""}
                                                            componentsProps={{
                                                                paper: {
                                                                    sx: {
                                                                        fontSize: '0.85rem'
                                                                    }
                                                                }
                                                            }}
                                                            onChange={(event, newValue) => handleSelection(event, newValue, 'supplier_vat_code', 'vat_code_selected')}
                                                            renderInput={(params) =>
                                                                <FormControl size="small" fullWidth>
                                                                    <div className="input-group">
                                                                        <TextField {...params}
                                                                            size="small"
                                                                            id="supplier_vat_code"
                                                                            aria-describedby="supplier_vat_code-error"
                                                                            error={supplier_vat_code_container.isError.vat_code_selected}
                                                                        />
                                                                        {supplier_vat_code_container.isError.vat_code_selected &&
                                                                            (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)}
                                                                    </div>
                                                                </FormControl>
                                                            }

                                                        />
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Item>


                                    </Grid>
                                    <Grid container sx={{ marginTop: '20px' }}>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Item>
                                                <Grid container direction="row" alignItems="center" justifyContent="center">
                                                    <LoadingButton
                                                        loading={loader}
                                                        loadingPosition="center"
                                                        endIcon=""
                                                        variant="contained"
                                                        type="submit" sx={{ marginRight: '15px' }}
                                                        //disabled={state.formValid || state.defaultmode === false}
                                                        // eslint-disable-next-line max-len
                                                        disabled={((editData || hasEditData) && editSupplierVatCode) ? (supplier_vat_code_container.isError.suppliervatcodename || supplier_vat_code_container.isError.vat_code_selected) : (state.formValid || state.defaultmode === false)}
                                                    >Save</LoadingButton>
                                                    <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
                {snackBar.show && <SnackBarDialog
                    onShow={snackBar.show}
                    text={snackBar.text}
                    type={snackBar.type}
                    onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                />}
            </form>

        </Modal>
    </>
);
}

export default AddEditVatCodeMappings;