import {Box, Modal, Button, Grid } from '@mui/material'
import {  useState } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';

function StartMemberCollectionModal({ onShow, onHide, size = '', startMemberCollection }) {

    const handleClose = () => {
        if (onHide) {
            onHide(false)
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        if (startMemberCollection) {
            startMemberCollection();
        }

    }
    return (
        <>
            <Modal
                open={onShow || false}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <Box component="div" className="overlay" id="addEditModal">
                    <div className={`overlay-box ${size ? size : ''}`}>
                        <div className="content">
                            <div className="overlayBody">
                                <div className="overlay-header text-center"> Member Collection
                                    <CancelIcon className="closeBtn" onClick={handleClose} />
                                </div>
                                <div className="overlay-content">
                                    <Box component="div" sx={{ textAlign: 'center', padding: 1.5 }}>
                                        <p className="fontMedium"> Start Member Collection?</p>
                                        <Grid sx={{ paddingTop: 2 }} container direction="row" alignItems="center" justifyContent="center">
                                            <Button variant="contained" type="submit" sx={{ marginRight: '15px' }} onClick={handleSubmit}>Yes</Button>
                                            <Button variant="outlined" onClick={handleClose}>No</Button>
                                        </Grid>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}

export default StartMemberCollectionModal;