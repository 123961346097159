
function PerformanceLogs() {

    return (
        <>
            Performance Logs Page
        </>
    );
}

export default PerformanceLogs;
