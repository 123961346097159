import { Checkbox, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl, RadioGroup, Radio, Autocomplete } from '@mui/material'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import axiosDiscountSchemes from '../../../../services/utility/axios-discount-schemes';
import PageLoader from '../../../../components/shared/PageLoader';
import { setDiscountSchemeState } from '../../../../store/slices/discountScheme';
import { handleModalState, setNewPriceModal } from '../../../../store/slices/modals';
import CommonPopUp from '../../../products/addEditPdc/pdc-tabs/CommonPopUp';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import { setCommonInput } from "../../../../store/slices/commonProduct";

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

function AddEditproduct({ onShow, size = '', onHide, onSubmitDate }) {
    
    const { discountScheme_container, searchProductModal } = useSelector((state) => {
        return {
            discountScheme_container: state.discountSchemes,
            searchProductModal: state.commonProducts,
        }
    });
    const dispatch = useDispatch();
    
    const [state, setState] = useState({
        ProductListModal: false,
        pageLoader: false,
        defaultmode: false,
        formValid: true,
        ProductDetail: discountScheme_container.hasEditProduct ? discountScheme_container.hasEditProduct.product : null
    })

    const [included, setIncluded] = useState(true);

    const [product, setproduct] = useState({
        product_options: [],
        ProductDetail: discountScheme_container.hasEditProduct ? discountScheme_container.hasEditProduct.product : null
    })
    const [isError, setIsError] = useState({
        ProductDetail: false
    })

    const handleChange = (event) => {
        if(discountScheme_container.hasEditProduct) {
            setState({ ...state, defaultmode: true })
        } 
        setIncluded(event.target.checked);
    };

    const [open, setOpen] = useState(onShow);

    const handleClose = () => {
        //setState({ ...state, defaultmode: true })
        dispatch(handleModalState({
            key: 'addEditProductModal',
            value: false
        }))
        dispatch(setDiscountSchemeState({
            key: 'hasEditProduct',
            value: null
        }))
        dispatch(setCommonInput(''));
    };

    const handleSelf = () => {
        handleClose()
    }

    useEffect(() => {
        if (searchProductModal.query === null || searchProductModal.query === "") {
            setState({ ...state, formValid: true, defaultmode: false})
        } else {
            setState({ ...state, formValid: false, defaultmode: true})
        }
    }, [searchProductModal.query])

    useEffect(() => {
        const {
            discountScheme_product_name
        } = discountScheme_container.isError
        if (!discountScheme_product_name) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true })
        }
    }, [discountScheme_container.isError])

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (state.ProductDetail === null && isError.ProductDetail === false) {
            setIsError({ ...isError, ProductDetail: true })
        } else {
            addPayDate()

        }

    }

    const addPayDate = async () => {
        let payload = null;
        if ((discountScheme_container.hasEditData !== null || discountScheme_container.editTabData !== null) && discountScheme_container.hasEditProduct === null) {
            payload = {
                "productId": state ? state.ProductDetail.id : "",
                included,
                "productName": state.ProductDetail ? state.ProductDetail.name : "",
            }
        } else if (discountScheme_container.hasEditProduct !== null) {
            payload = {
                "id": discountScheme_container.hasEditProduct.id,
                "productId": state.ProductDetail ? state.ProductDetail.id : discountScheme_container.hasEditProduct.productId,
                included,
                "productName": state.ProductDetail ? state.ProductDetail.name : discountScheme_container.hasEditProduct.productName,
            }
        }

        try {
            setState({ ...state, pageLoader: true })
            const { data } =  ((discountScheme_container.hasEditData !== null || discountScheme_container.editTabData !== null) && discountScheme_container.hasEditProduct === null) ? await axiosDiscountSchemes.post(`/discountSchemes/${discountScheme_container.hasEditData.id || discountScheme_container.editTabData.id}/products`, payload) : await axiosDiscountSchemes.put(`/discountSchemes/${discountScheme_container.hasEditData.id || discountScheme_container.editTabData.id}/products/${discountScheme_container.hasEditProduct.id}`, payload)

            if (data) {
                onSubmitDate({ text: data.message, type: "success" })
                setState({ ...state, pageLoader: false })
                handleClose()
            }

        } catch (error) {
            const { response: { data, status } } = error;
            if (!data) {
                onSubmitDate({ text: error.message, type: "error" })
            } else {
                onSubmitDate({ text: data.message, type: "error" })
            }
            setState({ ...state, pageLoader: false })
            handleClose()
        }
    }

    useEffect(() => {
        if (discountScheme_container.hasEditProduct !== null) {
            dispatch(setCommonInput(discountScheme_container.hasEditProduct.product))
            setIncluded(discountScheme_container.hasEditProduct.included)
            setState({...state, ProductDetail: discountScheme_container.hasEditProduct.product})
        }
    }, [])


    const hideSupplierproductModal = (params) => {
        setState({ ...state, ProductListModal: params === false ? params : true })
    }
    const CommonPopUpModal = () => {
        setState({...state, ProductListModal: true})
    }
    const getProductDetail = (product) => {  
        if (product !== '') {
            setState({...state, defaultmode: true, ProductDetail: product, ProductListModal: false})
        } 
    }
    return (
        <>


            <Modal
                open={open}
                aria-labelledby="add-edit-price-title"
                aria-describedby="add-edit-price-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Box component="div" className="overlay" id="addEditModal">
                        {state.pageLoader && <PageLoader />}
                        <div className={`overlay-box ${size ? size : ''}`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-header text-center"> Product
                                        <CancelIcon className="closeBtn" onClick={handleClose} />
                                    </div>
                                    <div className="overlay-content">
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            <FormLabel htmlFor="type" className="fontSemiBold mandatory">Product</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Grid container alignItems="center">
                                                                <Grid item xs={12} md={8}>
                                                                    <Item>
                                                                        <Box component="div" className=''>
                                                                            <FormControl size={'small'} fullWidth>
                                                                                <TextField
                                                                                    inputProps={{ maxLength: 100 }}
                                                                                    size="small"
                                                                                    id="discountScheme_product_name"
                                                                                    value={(searchProductModal.query ? searchProductModal.query.name : '') || (discountScheme_container.hasEditProduct ? discountScheme_container.hasEditProduct.productName : '')}
                                                                                    aria-describedby="discountScheme_product_name"
                                                                                    error={discountScheme_container.isError.discountScheme_product_name}
                                                                                    name="discountScheme_product_name"
                                                                                    disabled={true}
                                                                                />
                                                                            </FormControl>
                                                                        </Box>
                                                                    </Item>
                                                                </Grid>
                                                                <Grid item xs={12} md={4}>
                                                                    <Item>
                                                                        <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={(e) => CommonPopUpModal(e)} startIcon={<EditIcon />}></Button>
                                                                    </Item>
                                                                </Grid>
                                                            </Grid>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            <FormLabel htmlFor="included" className="fontSemiBold">Include/Exclude</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <div className="input-group">
                                                                <Checkbox
                                                                    checked={included}
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                />
                                                            </div>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            &nbsp;
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Grid container direction="row" alignItems="center">
                                                                <Button 
                                                                    variant="contained" 
                                                                    type="submit" 
                                                                    sx={{ marginRight: '15px' }} 
                                                                    //disabled={((discountScheme_container.hasEditProduct && discountScheme_container.hasEditProduct.confirmed === true) ? true : false) || state.defaultmode === false}
                                                                    // eslint-disable-next-line max-len
                                                                    disabled={((discountScheme_container.hasEditData !== null || discountScheme_container.editTabData !== null) && discountScheme_container.hasEditProduct) ? discountScheme_container.isError.discountScheme_product_name : (state.formValid || state.defaultmode === false)}
                                                                >Save</Button>
                                                                <Button variant="outlined" onClick={handleSelf}>Cancel</Button>
                                                            </Grid>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            state.ProductListModal && <CommonPopUp
                                onShow={state.ProductListModal}
                                size="large_overlay"
                                multipleFilter={true}
                                onHide={hideSupplierproductModal}
                                memberaccount={getProductDetail}
                            />
                        }
                    </Box>
                </form>

            </Modal>
        </>
    );
}

export default AddEditproduct;