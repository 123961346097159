import { DataTable } from "../../components/shared/data-table/DataTable";
import { Container, Box, Modal, Button, Grid, Checkbox } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TableFilters } from "../../components/shared/data-table/TableFilters";
import { useEffect, useState } from "react";
import { InputText } from "../../components/shared/form/InputText";
import { } from '@mui/material';
import { ThreeStateCheckbox } from "../../components/shared/form/ThreeStateCheckbox";
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange, filterResetFlagChange } from "../../store/slices/externalFilter";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import AddIcon from '@mui/icons-material/Add';
import moment from 'moment';
import { warningModal, handleAddEditdiscountScheme } from "../../store/slices/modals";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import WarningModal from "../../components/shared/modals/WarningModal";
import axiosDiscountSchemes from '../../services/utility/axios-discount-schemes';
import { setRowPage, resetDataGrid, setPageNo } from '../../store/slices/dataGrid'
import { useLayoutEffect } from "react";
import { DataTableDiscountSchemes } from "../../components/shared/data-table/DataTableDiscountSchemes";
import { setHasEditData, setdiscountSchemeEffectiveDate, discountSchemeInput, setSelectedFilter, setDiscountSchemeDeleteData, setDeleteList, setAutocomplete } from "../../store/slices/discountScheme";
import SnackBarDialog from "../../components/shared/SnackBarDialog";
import PageLoader from "../../components/shared/PageLoader";
import AddEditDiscountScheme from "./AddEditDiscountScheme/AddEditDiscountScheme";

const IconMenuLink = ({ params, sendSnackBar, startLoading, onDelete }) => {
    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { discountScheme_container, setAddEditDiscountScheme } = useSelector((state) => {
        return {
            discountScheme_container: state.discountSchemes,
            setAddEditDiscountScheme: state.modalActions.hideAddEditdiscountScheme
        }
    });
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'edit') {
            handleEdit(row)
        } else if (action === 'delete') {
            onDelete(row)
        }
        setAnchorEl(null);
    };

    const handleEdit = async (row) => {
        dispatch(setHasEditData(row))
        dispatch(handleAddEditdiscountScheme({
            showAddEdit: !setAddEditDiscountScheme
        }))
        dispatch(discountSchemeInput({
            key: 'add_edit_discountScheme_name',
            value: row.name
        }))
        // dispatch(discountSchemeInput({
        //     key: 'discountScheme_status',
        //     value: row.onTrade
        // }))
        dispatch(setdiscountSchemeEffectiveDate({
            key: 'discountScheme_start_date',
            value: row.startDate ? moment(row.startDate,'DD/MM/YYYY') : ""
        }))
        dispatch(setdiscountSchemeEffectiveDate({
            key: 'discountScheme_end_date',
            value: row.endDate ? moment(row.endDate,'DD/MM/YYYY') : ""
        }))
        dispatch(setSelectedFilter({
            key: 'dc_bill_to',
            value: row.billTo
        }))
        dispatch(setSelectedFilter({
            key: 'dc_benefit',
            value: row.benefit
        }))
        dispatch(setAutocomplete({
            parent: 'member_tier_list',
            key: 'member_tier_selected',
            value: row.memberTier
        }))
        if(row.specialPrice !== null) {
            dispatch(discountSchemeInput({
                key: 'special_price_value',
                value: Number(row.specialPrice).toFixed(2)
            }))
        }
    }

    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'edit')}>Edit </MenuItem>
            {/* <MenuItem onClick={(e) => handleClose(e, 'delete')}>Delete</MenuItem> */}
        </Menu>
    </>)
}

function DiscountSchemes() {
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const columns = [
        {
            headerName: "ID",
            field: "id",
            flex: 1,
            minWidth: 200,
            hide: true
        },
        {
            headerName: "Name",
            field: "name",
            flex: 2,
            width: 200
        },
        {
            headerName: "Start Date",
            field: "startDate",
            flex: 1,
            width: 100
        },
        {
            headerName: "End Date",
            field: "endDate",
            flex: 1,
            minWidth: 100
        },
        {
            headerName: "Purchasing Tier",
            field: "memberTier",
            valueGetter: (params) => params.row.memberTier ? params.row.memberTier.name : "",
            minWidth: 150
        }, 
        {
            headerName: "Bill To",
            field: "billTo",
            flex: 1,
            width: 180
        },    
        {
            headerName: "Benefit",
            field: "benefit",
            flex: 1,
            width: 180
        },      
        {
            headerName: "Special Price (£)",
            field: "specialPrice",
            flex: 1,
            width: 100
        }, 
        {
            headerName: "Incomplete",
            field: "incompleteOverride",
            width: 100,
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
            align: 'center',
            headerAlign: "center"
        },         
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} onDelete={deleteDiscountSchemeData} />;
            },
        }
    ];
    const [state, setState] = useState({
        ds_active: true,
        openBrandModal: false,
        pageLoader: false,
        defaultGrid: {
            pagetitle: "Discount Schemes",
            addEditButton: true,
            apiUrl: 'discountSchemes/filter',
            searchUrl: 'discountSchemes/filter'
        }
    });
    
    const pageLoader = (loader) => {
        setState({...state, pageLoader: loader})
    }

    const { addProductwarning, setAddEditDiscountScheme, discountScheme_container } = useSelector((state) => {
        return {
            addProductwarning: state.modalActions.closeWaringAddProduct,
            setAddEditDiscountScheme: state.modalActions.hideAddEditdiscountScheme,
            discountScheme_container: state.discountSchemes
        }
    });

    const dispatch = useDispatch();

    const handleChange = (checked, name) => {
        // setState({ ...state, [name]: checked });
        state.ds_active = checked;
        dispatch(setFilter({
            key: name,
            value: checked
        }));
        setTimeout(() => {
            filterBtn();
        }, 500);
    };

    // const handleChange = (checked, name) => {
    //     setState({ ...state, [name]: checked })
    //     dispatch(setFilter({
    //         key: name,
    //         value: checked
    //     }))
    // };

    const handleFilterInput = (value, id) => {
        //setState({ ...state, [id]: value });
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    };

    // eslint-disable-next-line max-len
    const { discountSchemesName, dsActive } = useSelector((state) => {
        return {
            discountSchemesName: state.externalFilter.discount_schemes_name,
            dsActive: state.externalFilter.ds_active
        }
    });
    const filterBtn = () => {
        dispatch(resetDataGrid());
        var dynamicFilterObjects = {};

        if(discountSchemesName !== '')
        {
                dynamicFilterObjects = {
                    ...dynamicFilterObjects,
                    "name": discountSchemesName
                }
        }
        // if(dsActive !== false)
        // { 
        //         dynamicFilterObjects = {
        //             ...dynamicFilterObjects,
        //             "active": dsActive
        //         }
        // }

        dynamicFilterObjects = {
            ...dynamicFilterObjects,
            "active": state.ds_active
            // "active": dsActive
        }

        dispatch(applyFilter({
            key: 'filterList',
            value: dynamicFilterObjects
        }))
        dispatch(filterFlagChange(true));
        dispatch(filterResetFlagChange(false));
        dispatch(setRowPage({ pageData: 10 }));
        dispatch(setPageNo({ page: 1 }));
    }
    const resetFilter = () => {
        if ((discountSchemesName !== '') || (dsActive === false || dsActive)) { 
            dispatch(resetExternalFilter())
            dispatch(resetDataGrid());
        }
        setState({ ...state, ds_active: null })
        dispatch(filterResetFlagChange(true));
    }
    const addSupplierModal = () => {
        dispatch(setDiscountSchemeDeleteData({
            key: 'okAddMore',
            value: false
        }))
        dispatch(setDiscountSchemeDeleteData({
            key: 'addMoreConfirm',
            value: false
        }))
        dispatch(handleAddEditdiscountScheme({
            showAddEdit: !setAddEditDiscountScheme
        }))
    }
    const handleSupplierModal = (event) => {
        setState({ ...state, openBrandModal: false })
    } 

    const deleteDiscountSchemeData = (row) => {
        dispatch(setDeleteList(row))
        dispatch(setDiscountSchemeDeleteData({
            key: 'discountSchemeStartSingleDelete',
            value: true
        }))
        dispatch(warningModal({
            yesHide: !addProductwarning
        }))
    }

    useEffect(() => {
        if (discountScheme_container.discountSchemeConfirmSingleDelete) {
            deleteSingleRow()
        }
    }, [discountScheme_container.discountSchemeConfirmSingleDelete])


    const deleteSingleRow = async () => {
        if (discountScheme_container.deleteList !== null) {
            try {
                setState({ ...state, pageLoader: true })
                const { data } = await axiosDiscountSchemes.delete(`discountSchemes/${discountScheme_container.deleteList.id}`);
                if (data.success) {
                    try {
                        const { data } = await axiosDiscountSchemes.post(`discountSchemes/filter`, {});
                        if (data !== null || data !== undefined) {
                            setState({ ...state, pageLoader: false })
                            setSnackBar({ ...snackBar, show: true, text: "Discount Scheme Delete Successfully", type: "success" })
                            dispatch(setDeleteList(null))
                            resetSingleDeleteRowDispatch()
                            setTimeout(() => {
                                window.location.reload();
                            }, 2000);
                        }
                    } catch (error) {
                        const { response: { data, status } } = error;
                        if (status === 500 && data.errorType === "Business") {
                            setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" });
                        } else {
                            setSnackBar({ ...snackBar, show: true, text: "Something went wrong!", type: "error", })
                        }
                        setState({ ...state, pageLoader: false })
                        resetSingleDeleteRowDispatch()
                    }
                }
            } catch (error) {
                const { response: { data, status } } = error;
                if (status === 500 && data.errorType === "Business") {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" });
                } else {
                    setSnackBar({ ...snackBar, show: true, text: "Something went wrong!", type: "error", })
                }
                setState({ ...state, pageLoader: false })
                resetSingleDeleteRowDispatch()
            }
        }
    }

    const resetSingleDeleteRowDispatch = () => {
        dispatch(setDiscountSchemeDeleteData({
            key: 'discountSchemeStartSingleDelete',
            value: false
        }))
        dispatch(setDiscountSchemeDeleteData({
            key: 'discountSchemeConfirmSingleDelete',
            value: false
        }))
    }

    useEffect(() => {
        /* load first first time */
        filterBtn();
    }, []);

    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Discount Schemes" />
                <Box sx={{ marginBottom: 2.5 }}>
                    <TableFilters
                        filterFields={<>
                            <Box component="div"><InputText onChange={handleFilterInput} maxWidth={50} label="Name" id="discount_schemes_name" size="small" /></Box>
                        </>}
                        filterCheckboxes={<>
                            <ThreeStateCheckbox checked={state.ds_active} onChange={handleChange} name="ds_active" id="ds_active" label="Active" />
                        </>}
                        filterBtns={
                            <>
                                <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                <Button variant="contained" onClick={filterBtn}>Apply</Button>
                            </>}>
                    </TableFilters>
                </Box>
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -2.4 } }}>
                    {/* <div className='table-actionable'>
                        <Button variant="contained" color="secondary" size='small' startIcon={<SendIcon />}>
                            Export as Excel
                        </Button>
                    </div> */}
                    <div className='table-actionable'>
                        <Button variant="contained" size='small' onClick={(e) => addSupplierModal(e)} startIcon={<AddIcon />}>Add New Discount Schemes</Button>
                    </div>
                </Box>


                <DataTableDiscountSchemes defaultGrid={state.defaultGrid} columns={columns} rows={discountScheme_container.addNewdiscountSchemeData}>

                </DataTableDiscountSchemes>
                {
                    setAddEditDiscountScheme && (
                        <AddEditDiscountScheme
                            onShow={setAddEditDiscountScheme}
                            onHide={(e, reason) => handleSupplierModal(e, reason)}

                        />
                    )
                }
                {
                    addProductwarning && (
                        <WarningModal size="small_overlay" onShow={addProductwarning} />
                    )
                }
                {snackBar.show && <SnackBarDialog
                    onShow={snackBar.show}
                    text={snackBar.text}
                    type={snackBar.type}
                    onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                />}
            </Container>

        </>
    );
}

export default DiscountSchemes; 
