import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hasEditData: null,
  rdsoeFormValid: false,
  rdsoe_date: [],
  rdsoe_start_date: null,
  rdsoe_end_date: null,
  deleteList: null,
  rdsoeokDelete: false,
  rdsoedeleteProcess: false,
  rdsoe_brand_owner: {
    rdsoe_brand_owner_options: [],
    rdsoe_brand_owner_selected: null
  },
  rdsoe_member_account: {
    rdsoe_member_account_options: [],
    rdsoe_member_account_selected: null
},
  rdsoe_retro_discount: {
    rdsoe_retro_discount_options: [],
    rdsoe_retro_discount_selected: null
  },
  rdsoe_member_outlet: {
    rdsoe_member_outlet_options: [],
    rdsoe_member_outlet_selected: null
  },
  rdsoe_member_name: '',
  rdsoe_member: {
    member_code_options: [],
    rdsoe_member_name: null,
  },  
  isError: {
    rdsoe_retro_discount_selected: false,
    rdsoe_brand_owner_selected: false,
    rdsoe_member_name: false,
    rdsoe_member_account_selected: false,
    rdsoe_member_outlet_selected: false,
    rdsoe_start_date: false
  }
}

const rdsoe = createSlice({
  name: 'rdsoe_accounts',
  initialState,
  reducers: {
    brandSearch: (state, action) => {
      const { parent, key, value } = action.payload;
      state[parent][key] = value;
      (state[parent][key] === null || state[parent][key] === "") ? state.isError[key] = true : state.isError[key] = false
    },
    rdsoeInput: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
      state[key].length === 0 ? state.isError[key] = true : state.isError[key] = false
    },
    getIsError: (state, action) => {
      const { key, value } = action.payload;
      state.isError[key] = value
    },
    setAutocomplete: (state, action) => {
      const { parent, key, value } =  action.payload;
      state[parent][key] = value
    },
    setHasEditData: (state, action) => {
      state.hasEditData = action.payload;
    },
    setrdsoeFormStatus: (state, action) => {
      state.rdsoeFormValid = action.payload;
    },
    setrdsoeDate: (state, action) => {
      state.rdsoe_date.push(action.payload)
    },
    setrdsoeDateList: (state, action) => {
      state.rdsoe_date = action.payload
    },
    setrdsoeEffectiveDate: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
      state.isError[key] ? (state[key] === null || state[key] === ""  || state[key] === 'Invalid date') ? state.isError[key] = true : state.isError[key] = false : '';
    },
    setrdsoeDeleteData: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    setDeleteList: (state, action) => {
      state.deleteList = action.payload
    },
    resetrdsoe: () => {
      return initialState
    },
  }
})

export const { brandSearch, setAutocomplete, getIsError, rdsoeInput, resetrdsoe, 
  setHasEditData, setrdsoeFormStatus, setrdsoeDate, setrdsoeDateList, 
  setrdsoeEffectiveDate, setrdsoeDeleteData, setDeleteList } = rdsoe.actions

export default rdsoe.reducer