import { useEffect, useLayoutEffect, useState } from "react";
import {
    Box, FormControl, Modal, MenuItem, Stack, Select, Pagination, PaginationItem
} from "@mui/material";

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { DataGridPremium, gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector } from "@mui/x-data-grid-premium";
import axiosInvoiceSummaries from '../../../services/utility/axios-invoice-summaries';
import axiosNewInvoiceSummaries from '../../../services/utility/axios-new-invoice-summaries';
import PageLoader from "../../../components/shared/PageLoader";
import SnackBarDialog from "../../../components/shared/SnackBarDialog";
import CancelIcon from '@mui/icons-material/Cancel';
import { DataTableClientSide } from "../../../components/shared/data-table/DataTableClientSide";

const InvoiceReprocesHistory = ({ onShow, onHide, processId, size }) => {
    const [state, setState] = useState({
        price: '',
        pageLoader: false,
        isLoading: false,
        data: [],
        total: 0,
        page: 1,
        pageSize: 10,
        applyFilterCount: null,
        defaultGrid: {
            priceActionButtons: false,
            paginationMode: 'client',
        },
    });
    const [open, setOpen] = useState(onShow);
    const handleClose = () => {
        if (onHide) {
            onHide(false)
        }
    };
    function CustomPagination() {
        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        const rowDataList = [10, 25, 50]

        const handleChange = (event) => {
            setState({ ...state, pageSize: event.target.value })
        };

        const paginationChange = (event, value) => {
            apiRef.current.setPage(value - 1);
            setState({ ...state, page: value })
        }

        return (
            <>
                <div className='grid-pagiation'>
                    <Box className='customRowPerPageBox' component="div">
                        <div>
                            View
                        </div>
                        <Box component="div" sx={{ marginX: '10px' }}>
                            <FormControl>
                                <Select
                                    size="small"
                                    id="row-per-page"
                                    defaultValue="choose"
                                    onChange={handleChange}
                                    value={state.pageSize}
                                    aria-describedby={'row-per-page'} >
                                    {rowDataList.map((value) => {
                                        return (
                                            <MenuItem value={value} key={value}>{value}</MenuItem>
                                        )
                                    }

                                    )}
                                </Select>

                            </FormControl>
                        </Box>
                        <div>
                            rows per page
                        </div>
                    </Box>
                    <Pagination
                        variant="outlined"
                        shape="rounded"
                        count={pageCount}
                        page={page + 1}
                        onChange={(event, value) => paginationChange(event, value)}
                        renderItem={(item) => (
                            <PaginationItem
                                components={{ previous: KeyboardArrowLeftIcon, next: KeyboardArrowRightIcon }}
                                {...item}
                            />
                        )}
                    />
                </div>
            </>
        );
    }

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })
    const columns = [
        {
            headerName: "Id",
            field: "id",
            minWidth: 225,
            flex: 1,
            hide: true
        },
        {
            headerName: "Invoice Id",
            field: "invoiceId",
            minWidth: 225
        },
        {
            headerName: "Process Name",
            field: "processName",
            minWidth: 225,
            flex: 1,
        },
        {
            headerName: "Date & Time",
            field: "dateTime",
            minWidth: 225
        },
        {
            headerName: "Success",
            field: "succeeded",
            minWidth: 225
        }
    ];

    const onPageChange = (newPage) => {
        setState({ ...state, page: newPage + 1 });
    };

    const onPageSizeChange = (newPageSize) => {
        setState({ ...state, pageSize: newPageSize });
    };

    useLayoutEffect(() => {
        getPayMemberDatesList(processId)
    }, [state.page, state.pageSize])

    const getPayMemberDatesList = async (id) => {
        try {

            const { data } = await axiosNewInvoiceSummaries.get(`invoicesummary/${id}/processstatushistory`);
            if (data) {
                console.log(data)
                setState({ ...state, isLoading: false, data: data.resultMap.result, totalRows: data.totalCount, total: data.totalCount, applyFilterCount: null });
            }
        } catch (error) {
            const { response: { data, status }, message } = error;
            if (status === 500) {
                setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
            } else {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
            }
            setState({ ...state, loader: false })
        }
    }

    return (
        <>
            <Modal
                open={open || false}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}

            >
                <div>
                    <Box component="div" className="overlay" id="addEditModal">
                        <div className={`overlay-box ${size ? size : ''}`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-header text-center"> Status History
                                        <CancelIcon className="closeBtn" onClick={handleClose} />
                                    </div>
                                    <div className="overlay-content">
                                        {state.pageLoader && <PageLoader />}

                                        <DataTableClientSide defaultGrid={state.defaultGrid} columns={columns ? columns : []} rows={state.data.length > 0 ? state.data : []}>

                                        </DataTableClientSide>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                    {snackBar.show && <SnackBarDialog
                        onShow={snackBar.show}
                        text={snackBar.text}
                        type={snackBar.type}
                        onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                    />}
                </div>
            </Modal>

        </>
    );
};
export default InvoiceReprocesHistory;