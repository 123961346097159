import { Box, Modal, Paper, FormControl, Grid, TextField, FormHelperText, FormLabel, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageLoader from '../../../../components/shared/PageLoader';
import moment from 'moment';
import axios from 'axios';
import KeyPressSearchComponent from "../../../../components/shared/form/KeyPressSearch";

const IconMenuLink = ({ params }) => {
    const { row } = params;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
}

const defaultValues = {
    loader: false,
    pageLoader: false,
    rowData: null,
    defaultGrid: {
        paginationMode: 'client',
    }
};
export default function CommonPopUp({ onShow, onHide, size = '', supplierMemberCode, memberaccount, outercaseBarCode, supplierMemberAccount, brandDetails, multipleFilter, serverProcessFilter, levyDetails }) {
    const [state, setState] = useState(defaultValues);
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const {
        searchProductModal, sma_container
    } = useSelector((state) => {
        return {
            searchProductModal: state.commonProducts,
            sma_container: state.smas
        }
    });

    const handleClose = () => {
        if (onHide) {
            onHide(false)
        }        
    };
    const hidePriceModalAfterProduct = (row) => {
        if (onHide) {
            onHide(false, row)
        }
        if(supplierMemberAccount) {
            supplierMemberAccount(row, supplierMemberCode.type)
        }
        if(memberaccount) {
            memberaccount(row)
        }

    }

   const apiUrl = supplierMemberCode ? supplierMemberCode.title === 'SPC' ? {apiUrl: `spc/supplier/${supplierMemberCode.supplier.id}/lookup`} : supplierMemberCode.title === 'Supplier Member Account' ? {apiUrl: `supplierMemberAccounts/supplier/${supplierMemberCode.supplier.id}/lookup`} : (supplierMemberCode.title === 'Supplier Details' || supplierMemberCode.title === 'Member Details') ? {apiUrl: `${supplierMemberCode.type}/look-up${supplierMemberCode.title === 'Member Details' ? '?pageSize=500' : '' }`} : null : outercaseBarCode ? {apiUrl: `v2/products/look-up`} : brandDetails ? {apiUrl: `brands/look-up`} : levyDetails ? { apiUrl: `/levySchemes/look-up` } : null;

   return (
        <>
            <Modal
                open={onShow || false}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <Box component="div" className="overlay" id="addEditModal">
                    {state.pageLoader && <PageLoader />}
                    <div className={`overlay-box ${size ? size : ''}`}>
                        <div className="content">
                            <div className="overlayBody">
                                <div className="overlay-header text-center"> 
                                { supplierMemberCode ? supplierMemberCode.title : outercaseBarCode ? outercaseBarCode.title : brandDetails ? brandDetails.title : serverProcessFilter === true ? 'Server Process' : levyDetails ? levyDetails.title : 'Product Details'}

                                    <CancelIcon className="closeBtn" onClick={handleClose} />
                                </div>
                                <div>
                                    {/* <Search xyz={handleSearchValue}/> */}
                                    
                                    <KeyPressSearchComponent 
                                    hidePriceModalAfterProduct={hidePriceModalAfterProduct} 
                                    supplierMemberCode={ supplierMemberCode ? {...supplierMemberCode, ...apiUrl}  : null} 
                                    outercaseBarCode={ outercaseBarCode ? {...outercaseBarCode, ...apiUrl}  : null} 
                                    brandDetails ={brandDetails ? {...brandDetails, ...apiUrl}  : null}
                                    multipleFilter = {multipleFilter ? multipleFilter : false}
                                    serverProcessFilter = {serverProcessFilter ? serverProcessFilter : false}
                                    levyDetails={levyDetails ? {...levyDetails, ...apiUrl}  : null}
                                    />
                               </div>
                            </div>
                        </div>
                    </div> 
                </Box>
            </Modal>
        </>
    );
}
