import { Checkbox, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl, RadioGroup, Radio, Autocomplete } from '@mui/material'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import axios from '../../../../services/utility/axios-retro-discount-schemes';
import PageLoader from '../../../../components/shared/PageLoader';
import { setRetroState, getIsError } from '../../../../store/slices/retrodiscountschemes';
import { handleModalState, setNewPriceModal } from '../../../../store/slices/modals';
import CommonPopUp from '../../../products/addEditPdc/pdc-tabs/CommonPopUp';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import { setCommonInput } from '../../../../store/slices/commonProduct';
import axios_product from '../../../../services/utility/axios';
import SnackBarDialog from '../../../../components/shared/SnackBarDialog';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

function AddEditProduct({ onShow, size = '', onHide, onSubmitDate }) {

    const { retro_fields, searchProductModal } = useSelector((state) => {
        return {
            retro_fields: state.retrodiscountscheme,
            searchProductModal: state.commonProducts
        }
    });

    const [state, setState] = useState({
        supplierListModal: false,
        pageLoader: false,
        defaultmode: false,
        formValid: true,
        productDetail: null,
        productOC: retro_fields.hasEditProduct && retro_fields.hasEditProduct.product.outerCaseBarcode ? retro_fields.hasEditProduct.product.outerCaseBarcode : ""
    })

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const [included, setIncluded] = useState(true);


    const dispatch = useDispatch();

    const [isError, setIsError] = useState({
        productDetail: false
    })

    const handleChange = (event) => {
        if(retro_fields.hasEditProduct) {
            setState({ ...state, defaultmode: true })
        }
        setIncluded(event.target.checked);
    };

    const [open, setOpen] = useState(onShow);

    const handleClose = () => {
        //setState({ ...state, defaultmode: false })
        dispatch(handleModalState({
            key: 'AddEditProductModal',
            value: false
        }))
        dispatch(setRetroState({
            key: 'hasEditProduct',
            value: null
        }))
        dispatch(setCommonInput(''));
        dispatch(getIsError({ key: 'productDetail', value: false }))
        setState({ ...state, productOC: "" });
    };

    const handleSelf = () => {
        handleClose()
    }

    useEffect(() => {
        if (state.productDetail === null || state.productDetail === "") {
            setState({ ...state, formValid: true, defaultmode: false})
        } else {
            setState({ ...state, formValid: false, defaultmode: true})
        }
    }, [state.productDetail])

    useEffect(() => {
        const {
            productDetail
        } = retro_fields.isError
        if (!productDetail) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true })
        }
    }, [retro_fields.isError])

    const handleSubmit = (event) => {
        event.preventDefault();
        if (state.productDetail === "" || state.productDetail === null) {
            setIsError({ ...isError, productDetail: true })
        } else {
            addPayDate()
        }

    }
    const addPayDate = async () => {
        let payload = null;
        const id = (retro_fields.hasEditData) ? (retro_fields.hasEditData.id) : retro_fields.editTabData.id
        if (id && retro_fields.hasEditProduct === null) {
            payload = {
                "product": state.productDetail ? state.productDetail : "",
                included,
                "retroDiscountScheme": id
            }
        } else if (retro_fields.hasEditProduct !== null) {
            payload = {
                "id": retro_fields.hasEditProduct.id,
                "product": state.productDetail ? state.productDetail : "",
                included,
                "retroDiscountScheme": retro_fields.hasEditProduct.retroDiscountScheme
            }
        }

        try {
            setState({ ...state, pageLoader: true })
            const url = ((retro_fields.hasEditData !== null || retro_fields.editTabData !== null) && retro_fields.hasEditProduct === null) ? 'addproducts' : 'updateproducts';

            const { data } = url === 'addproducts' ? await axios.post(`/retrodiscount/${id}/${url}`, payload) : await axios.put(`/retrodiscount/${id}/${url}`, payload)

            if (data) {
                onSubmitDate({ text: data.message, type: "success" })
                setState({ ...state, pageLoader: false })
                handleClose()
            }

        } catch (error) {
            const { response: { data, status } } = error;
            if (!data) {
                onSubmitDate({ text: error.message, type: "error" })
            } else {
                onSubmitDate({ text: data.message, type: "error" })
            }
            setState({ ...state, pageLoader: false })
            handleClose()
        }
    }

    useEffect(() => {
        if (retro_fields.hasEditProduct !== null) {
            dispatch(setCommonInput(retro_fields.hasEditProduct.product))
            setIncluded(retro_fields.hasEditProduct.included)
            setState({ ...state, productDetail: retro_fields.hasEditProduct.product })
        }
    }, [])


    const hideSupplierModal = (params) => {
        setState({ ...state, supplierListModal: params === false ? params : true })
    }
    const resetFilter = () => {
        dispatch(setCommonInput(''));
        setState({ ...state, productDetail: null })
    }
    const CommonPopUpModal = () => {
        setState({ ...state, supplierListModal: true })
    }
    const getproductDetail = (supplier) => {
        if (supplier !== '') {
            setState({ ...state, defaultmode: true, productDetail: supplier, supplierListModal: false })
            setIsError({ ...isError, productDetail: false })
        }
    }
    const handleProductOC = (event) => {
        event.preventDefault();
        const { name, value } = event.target
        setState({ ...state, [name]: value ? value.toUpperCase() : '' });
    }
    const handleProductOCLookUp = async (event) => {
        event.preventDefault();
        const { name, value } = event.target
        try {
            if (value.length > 0) {
                setState({ ...state, pageLoader: true })
                let baseURL = axios_product;
                const url = 'v2/products/look-up';
                const { data } = await baseURL.get(`${url}?outerCaseBarcode=${value ? value : ''}`)

                if (data.resultMap.result && data.resultMap.result.length === 1) {
                    setState({ ...state, pageLoader: false, productDetail: data.resultMap.result[0] })
                    setIsError({ ...isError, productDetail: false })
                } else {
                    setState({ ...state, pageLoader: false, productDetail: null })
                    setIsError({ ...isError, productDetail: true })
                }
            }
            else
            {
                setState({ ...state, pageLoader: false, productDetail: null });
                setIsError({ ...isError, productDetail: true });
            }
        } catch (error) {
            const { response: { data, status }, message } = error;
            setState({ ...state, pageLoader: false, productDetail: null })
            if (status === 500) {
                setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
            } else {
                setSnackBar({ ...snackBar, show: true, text: data ? data.message : "System took long to respond, please retry!", type: "error", })
            }
        }

    }

    return (
        <>
            <Modal
                open={open}
                aria-labelledby="add-edit-price-title"
                aria-describedby="add-edit-price-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Box component="div" className="overlay" id="addEditModal">
                        {state.pageLoader && <PageLoader />}
                        <div className={`overlay-box ${size ? size : ''}`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-header text-center"> Product
                                        <CancelIcon className="closeBtn" onClick={handleClose} />
                                    </div>
                                    <div className="overlay-content">
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            <FormLabel htmlFor="type" className="fontSemiBold mandatory">Outercase Barcode</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Grid container alignItems="center">
                                                                <Grid item xs={12} md={8}>
                                                                    <Item>
                                                                        <Box component="div" className=''>
                                                                            <div className="input-group">
                                                                                <FormControl size={'small'} fullWidth>
                                                                                    <TextField
                                                                                        inputProps={{ maxLength: 100 }}
                                                                                        size="small"
                                                                                        id="productOC"
                                                                                        value={state.productOC}
                                                                                        aria-describedby="prod_error"
                                                                                        error={isError.productDetail}
                                                                                        name="productOC"
                                                                                        onChange={(e) => handleProductOC(e)}
                                                                                        onBlur={handleProductOCLookUp}
                                                                                        disabled={retro_fields.hasEditProduct}
                                                                                    />
                                                                                    {isError.productDetail && (<FormHelperText className='errorHelperTxt' id="productDetail_error">
                                                                                        Invalid Product OC
                                                                                    </FormHelperText>)}
                                                                                </FormControl>
                                                                            </div>
                                                                        </Box>
                                                                    </Item>
                                                                </Grid>
                                                                {/* <Grid item xs={12} md={4}>
                                                                <Item>
                                                                    <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0, marginRight: '5px' }} onClick={resetFilter} startIcon={<ClearIcon />}></Button>
                                                                    <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={(e) => CommonPopUpModal(e)} startIcon={<EditIcon />}></Button>
                                                                </Item>
                                                            </Grid> */}
                                                            </Grid>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                        <Grid container alignItems="center">
                                                            <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                                <Item>
                                                                    <FormLabel htmlFor="type" className="fontSemiBold">Product Name</FormLabel>
                                                                </Item>
                                                            </Grid>
                                                            <Grid item xs={12} md={7.5}>
                                                                <Item>
                                                                    <Grid container alignItems="center">
                                                                        <Grid item xs={12} md={8}>
                                                                            <Item>
                                                                                <Box>
                                                                                    {retro_fields.hasEditProduct ? retro_fields.hasEditProduct.product.name : state.productDetail ? state.productDetail.name : ""}
                                                                                </Box>
                                                                            </Item>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Item>
                                                            </Grid>
                                                        </Grid>
                                                    </Item>
                                            {/* {
                                                (retro_fields.hasEditProduct || state.productDetail) && (
                                                    <Item sx={{ marginBottom: 2.5 }}>
                                                        <Grid container alignItems="center">
                                                            <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                                <Item>
                                                                    <FormLabel htmlFor="type" className="fontSemiBold">Product Name</FormLabel>
                                                                </Item>
                                                            </Grid>
                                                            <Grid item xs={12} md={7.5}>
                                                                <Item>
                                                                    <Grid container alignItems="center">
                                                                        <Grid item xs={12} md={8}>
                                                                            <Item>
                                                                                <Box>
                                                                                    {retro_fields.hasEditProduct ? retro_fields.hasEditProduct.product.name : state.productDetail ? state.productDetail.name : ""}
                                                                                </Box>
                                                                            </Item>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Item>
                                                            </Grid>
                                                        </Grid>
                                                    </Item>
                                                )
                                            } */}
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            <FormLabel htmlFor="included" className="fontSemiBold">Include/Exclude</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <div className="input-group">
                                                                <Checkbox
                                                                    checked={included}
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                />
                                                            </div>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            &nbsp;
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Grid container direction="row" alignItems="center">
                                                                <Button 
                                                                    variant="contained" 
                                                                    type="submit" 
                                                                    sx={{ marginRight: '15px' }} 
                                                                    //disabled={((state.productDetail === null || state.productOC === "") ? true : false)}
                                                                    // eslint-disable-next-line max-len
                                                                    disabled={((retro_fields.hasEditData !== null || retro_fields.editTabData !== null) && retro_fields.hasEditProduct) ? retro_fields.isError.productDetail : (state.formValid || state.defaultmode === false)}
                                                                >Save</Button>
                                                                <Button variant="outlined" onClick={handleSelf}>Cancel</Button>
                                                            </Grid>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {snackBar.show && <SnackBarDialog
                            onShow={snackBar.show}
                            text={snackBar.text}
                            type={snackBar.type}
                            onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                        />}
                        {
                            state.supplierListModal &&
                            <CommonPopUp
                                onShow={state.supplierListModal}
                                size="large_overlay"
                                onHide={hideSupplierModal}
                                memberaccount={getproductDetail}
                                multipleFilter={true}
                            />
                        }
                    </Box>
                </form>

            </Modal>
        </>
    );
}

export default AddEditProduct;