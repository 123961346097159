import { Container, Box } from '@mui/material';
import color from '../../styles/themes/_colors.scss'

function Footer() {
    return (
        <>
            <footer className="footer" style={{ backgroundColor: color.footerBg }}>
                <Container disableGutters={true} maxWidth={false}>
                    <Box component="p" sx={{ textAlign: 'center', paddingY: 1.5 }}>
                        Copyright ©{new Date().getFullYear()} National Union of Students UK
                    </Box>
                </Container>
            </footer>
        </>
    );
}
export default Footer;