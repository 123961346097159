import { Checkbox, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl } from '@mui/material'
import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { warningModal, handleAddEditDC } from "../../../store/slices/modals";
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import axios_utilities from "../../../services/utility/axios-utilities";
import axios_product_distribution from '../../../services/utility/axios-product-distribution';
import { setDCList, brandSearch, hasEdit, hasEditData, dcProductInput, setAutocomplete, getIsError, resetDC } from "../../../store/slices/distributionChannel";
import SnackBarDialog from "../../../components/shared/SnackBarDialog";
import { filterFlagChange, filterResetFlagChange } from '../../../store/slices/externalFilter';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

const defaultValues = {
    loader: false,
    formValid: true,
    defaultmode: false,
    distributionChannelData: [],
};

function AddEditDistributionChannelDetail({ onShow, size = '', onHide }) {
    
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const [state, setState] = useState(defaultValues);
    const [selectedSupplier, setselectedSupplier] = useState(null);
    const [open, setOpen] = useState(onShow);
    const { handleWarning, distributionChannel_container } = useSelector((state) => {
        return {
            handleWarning: state.modalActions.closeWaringAddProduct,
            distributionChannel_container: state.dcProducts
        }
    });

    useEffect(() => {
        // console.log(distributionChannel_container.hasEditData);
        const fetchMyAPI = async () => { 
            if (distributionChannel_container.dc_central_bil_scheme.dc_central_bil_scheme_options.length === 0) {
                axios_utilities.get('centralBillingScheme/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            dispatch(setAutocomplete({
                                parent: 'dc_central_bil_scheme',
                                key: 'dc_central_bil_scheme_options',
                                value: unique
                            }));
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
        }
        fetchMyAPI();
    }, [])
    
    const dispatch = useDispatch();

    const handleClose = () => {
        //setState({ ...state, defaultmode: false })
        dispatch(handleAddEditDC({
            showAddEdit: false
        }));
        dispatch(resetDC())
    };
    const handleCancel = () => {
        setState({ ...state, defaultmode: false })
		dispatch(warningModal({
            yesHide: !handleWarning
        }))
    }

    const handleSelection = (event, newValue, parentname, name) => {
        if (event.target.value !== "" || event.target.value !== null) {
            setState({ ...state, defaultmode: true })
            dispatch(brandSearch({
                parent: parentname,
                key: name,
                value: newValue
            }))
        }

    }

    const handleInputChange = (event) => {
        setState({ ...state, defaultmode: true })
        const { value, name } = event.target;
        dispatch(dcProductInput({
            key: name,
            value
        }))
    }

    const handleCheckboxChange = (event) => {
        if(distributionChannel_container.hasEditData) {
            setState({ ...state, defaultmode: true })
        }
        const { checked, name } = event.target;
        dispatch(dcProductInput({
            key: name,
            value: checked
        }))
    };

    useEffect(() => {
        if ((distributionChannel_container.dc_name === null || distributionChannel_container.dc_name === "") || (distributionChannel_container.dc_central_bil_scheme.dc_central_bil_scheme_selected === null || distributionChannel_container.dc_central_bil_scheme.dc_central_bil_scheme_selected === "")) {
            setState({ ...state, formValid: true, defaultmode: false })
        } else {
            setState({ ...state, formValid: false, defaultmode: true })
        }
    }, [distributionChannel_container.dc_name, distributionChannel_container.dc_central_bil_scheme.dc_central_bil_scheme_selected])

    useEffect(() => {
        const { 
            dc_name, dc_central_bil_scheme_selected
        } = distributionChannel_container.isError
        if (!dc_name && !dc_central_bil_scheme_selected) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true })
        }


    }, [distributionChannel_container.isError])

    const handleSubmit = async (event) => {
        event.preventDefault();
        let productData = null;
        if (!state.formValid) {
            productData = {
                "id": distributionChannel_container.hasEditData ? distributionChannel_container.hasEditData.id : '',
                "name": distributionChannel_container.dc_name,
                "active": distributionChannel_container.dc_status,
                "centralBillingScheme": {
                    "id": distributionChannel_container.dc_central_bil_scheme.dc_central_bil_scheme_selected.id,
                    "name": distributionChannel_container.dc_central_bil_scheme.dc_central_bil_scheme_selected.name
                },
            }
            try {
                setState({ ...state, loader: true })
                const { data, status } = distributionChannel_container.hasEditData ? await axios_product_distribution.put(`distributionChannels/${distributionChannel_container.hasEditData.id}`, { ...productData }) : await axios_product_distribution.post(`distributionChannels`, { ...productData });
                if (data.success === true && data.resultMap) {
                    setSnackBar({...snackBar, show:true, text: `Distribution Channel ${distributionChannel_container.hasEditData ? 'updated': 'added'} successfully`, type: "success", })
                    setState({ ...state, loader: false, formValid: true, distributionChannelData: data})
                    dispatch(setDCList(data))
                    setTimeout(() => {
                        //handleClose()
                        // window.location.reload();
                        dispatch(handleAddEditDC({
                            showAddEdit: false
                        }));
                        dispatch(filterFlagChange(true));
                        dispatch(filterResetFlagChange(false));
                    }, 1500);
                }
            } catch (error) {
                const { response: { data, status } } = error;
                setState({ ...state, loader: false })
                setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error", })
            }
        }
    };

    return (
        <>
            { snackBar.show && <SnackBarDialog 
            onShow={snackBar.show} 
            text={snackBar.text} 
            type={snackBar.type} 
            onHide={(e) => {setSnackBar({...snackBar, show: e})}}
            />}

            <Modal
                open={open}
                aria-labelledby="add-edit-price-title"
                aria-describedby="add-edit-price-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Box component="div" className="overlay" id="addEditModal">
                        <div className={`overlay-box ${size ? size : ''}`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-header text-center"> Distribution Channel Details
                                        <CancelIcon className="closeBtn" onClick={handleClose} />
                                    </div>
                                    <div className="overlay-content">
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="dc_name" className="fontSemiBold mandatory">Name</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Box component="div" className=''>
                                                                <FormControl size="small" fullWidth>
                                                                    <TextField
                                                                        inputProps={{ maxLength: 50 }}
                                                                        type="text"
                                                                        size="small"
                                                                        id="dc_name"
                                                                        aria-describedby="dc_name_error"
                                                                        onChange={(e) => handleInputChange(e)}
                                                                        value={distributionChannel_container.dc_name}
                                                                        error={distributionChannel_container.isError.dc_name}
                                                                        name="dc_name"
                                                                        className="inputNumber"
                                                                        //onBlur={(e) => checkQunique(e, 'isDCNameValid?name')}
                                                                    />
                                                                    {distributionChannel_container.isError.dc_name && (<FormHelperText className='errorHelperTxt' id="dc_name_error">This field is mandatory</FormHelperText>)}
                                                                </FormControl>
                                                            </Box>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="dc_central_bil_scheme" className="fontSemiBold mandatory">Central billing scheme</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                        <Box component="div" className=''>
                                                            <Autocomplete
                                                                // eslint-disable-next-line max-len
                                                                defaultValue={distributionChannel_container.dc_central_bil_scheme.dc_central_bil_scheme_selected ? distributionChannel_container.dc_central_bil_scheme.dc_central_bil_scheme_selected : null}
                                                                id="dc_central_bil_scheme_search"
                                                                options={distributionChannel_container.dc_central_bil_scheme.dc_central_bil_scheme_options}
                                                                getOptionLabel={(option) => option.name || ""}
                                                                componentsProps={{
                                                                    paper: {
                                                                        sx: {
                                                                            fontSize: '0.85rem'
                                                                        }
                                                                    }
                                                                }}
                                                                disabled={ distributionChannel_container.hasEditData ? distributionChannel_container.hasEditData.enableCBS === true ? false : true : false }
                                                                onChange={(event, newValue) => handleSelection(event, newValue, 'dc_central_bil_scheme', 'dc_central_bil_scheme_selected')}
                                                                renderInput={(params) =>
                                                                    <FormControl size={'small'} fullWidth>
                                                                        <Box component="div" className=''>
                                                                            <TextField {...params}
                                                                                size="small"
                                                                                id="dc_central_bil_scheme"
                                                                                aria-describedby="dc_central_bil_scheme-error"
                                                                                error={distributionChannel_container.isError.dc_central_bil_scheme_selected}
                                                                                disabled={ distributionChannel_container.hasEditData ? distributionChannel_container.hasEditData.enableCBS === true ? false : true : false }
                                                                            />
                                                                            {distributionChannel_container.isError.dc_central_bil_scheme_selected &&
                                                                                (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)}
                                                                        </Box>
                                                                    </FormControl>
                                                                }
                                                            />
                                                            </Box>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="rate" className="fontSemiBold">Active</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}> 
                                                        <Item>
                                                            <Box component="div" sx={{ paddingLeft:'3px' }}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox size="medium"
                                                                            id="dc_status"
                                                                            checked={distributionChannel_container.dc_status}
                                                                            onChange={(e) => handleCheckboxChange(e)}
                                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                                            name="dc_status"
                                                                        />}
                                                                    label="" />
                                                            </Box>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                        <Grid container sx={{ marginTop: '20px' }}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Item>
                                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                                        <LoadingButton
                                                                loading={state.loader}
                                                                loadingPosition="center"
                                                                endIcon=""
                                                                variant="contained"
                                                                type="submit" sx={{ marginRight: '15px' }}
                                                                //disabled={state.formValid || state.defaultmode === false}
                                                                // eslint-disable-next-line max-len
                                                                disabled={distributionChannel_container.hasEditData ? (distributionChannel_container.isError.dc_name || distributionChannel_container.isError.dc_central_bil_scheme_selected) : (state.formValid || state.defaultmode === false)}
                                                            >Save</LoadingButton>
                                                        <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </form>
            </Modal>
        </>
    );
}

export default AddEditDistributionChannelDetail;