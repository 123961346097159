import { useEffect, useState } from "react";
import {
    Grid, Paper, Box, FormLabel, Button, Autocomplete,
    TextField, FormHelperText, Checkbox, FormControlLabel, FormControl, Alert, Snackbar, Modal
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/material/styles';
import { warningModal, handleAddEditModal } from "../../../../store/slices/modals";
import { brandSearch, generalProductInput, getIsError, setAutocomplete, hasTabEditData, setProductFormStatus, setHasEditData } from "../../../../store/slices/generalProduct";
import { useDispatch, useSelector } from 'react-redux';
import { hasTabEdit } from "../../../../store/slices/generalProduct";
import axios_product from "../../../../services/utility/axios";
import axios_utilities from "../../../../services/utility/axios-utilities";
import axiosBrand from "../../../../services/utility/axios-brand";
import axiosMember from '../../../../services/utility/axios-member';
import { useCallback } from "react";
import SnackBarDialog from "../../../../components/shared/SnackBarDialog";
import CommonPopUp from "../../addEditPdc/pdc-tabs/CommonPopUp";
import axios_categories from '../../../../services/utility/axios-categories';
import { filterFlagChange, filterResetFlagChange } from "../../../../store/slices/externalFilter";

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

const defaultValues = {
    loader: false,
    formValid: true,
    defaultmode: false,
    productGeneralBrandModal: false,
    newProductData: [],
}
const General = () => {
    const [state, setState] = useState(defaultValues);
    const [otherTabsOpen, setOtherTabsOpen] = useState(false);
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })
    const {
        handleWarning,
        general_product_fields
    } = useSelector((state) => {
        return {
            handleWarning: state.modalActions.closeWaringAddProduct,
            general_product_fields: state.generalProduct,
        }
    });
    const [goaheadFlag, setGoaheadFlag] = useState(false);

    useEffect(() => {
        const inputNumber = document.querySelectorAll('.inputNumber');
        inputNumber.forEach(el => el.addEventListener('keypress', evt => {
            if (evt.which === 8) {
                return;
            }
            if (evt.which < 42 || evt.which > 60) {
                evt.preventDefault();
            }
        }));
    }, []);

    useEffect(() => {
        const fetchMyAPI = async () => {
            if (general_product_fields.general_brands.brand_options.length === 0) {
                axiosBrand.get('brands/look-up')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data.resultMap.result.filter((value, index, self) => self.findIndex((m) => m.name === value.name) === index);
                            dispatch(setAutocomplete({
                                parent: 'general_brands',
                                key: 'brand_options',
                                value: unique
                            }));
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
            if (general_product_fields.general_vat_code.vat_code_options.length === 0) {
                axios_utilities.get('vatcodes/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            dispatch(setAutocomplete({
                                parent: 'general_vat_code',
                                key: 'vat_code_options',
                                value: unique
                            }));
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        const { response: { data, status }, message } = error;
                        console.log(error, status, data);
                    });
            }
            if (general_product_fields.general_unit_measure.measure_options.length === 0) {
                axios_utilities.get('unitofmeasures/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            dispatch(setAutocomplete({
                                parent: 'general_unit_measure',
                                key: 'measure_options',
                                value: unique
                            }));
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        const { response: { data, status }, message } = error;
                        console.log(error, status, data);
                    });
            }
            if (general_product_fields.general_category.category_options.length === 0) {
                axios_categories.get('/v2/categories/parent-dropdown?level=0')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            dispatch(setAutocomplete({
                                parent: 'general_category',
                                key: 'category_options',
                                value: unique
                            }));
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        const { response: { data, status }, message } = error;
                        console.log(error, status, data);
                    });
            }
        }
        fetchMyAPI();
    }, [])

    const dispatch = useDispatch();

    const handleInputChange = (event) => {
        if (general_product_fields.hasEditData) {
            setState({ ...state, defaultmode: true })
        }
        const { value, name } = event.target;
        dispatch(generalProductInput({
            key: name,
            value
        }))

    }

    const handleOCBCInputChange = (event) => {
        if (general_product_fields.hasEditData) {
            setState({ ...state, defaultmode: true })
        }
        const { value, name } = event.target;
        dispatch(generalProductInput({
            key: name,
            value: value ? value.toUpperCase() : ''
        }))

    }

    const handleCheckboxChange = (event) => {
        if (general_product_fields.hasEditData) {
            setState({ ...state, defaultmode: true })
        }
        const { checked, name } = event.target;
        dispatch(generalProductInput({
            key: name,
            value: checked
        }))
    };

    const handleSelection = (event, newValue, parentname, name) => {
        setState({ ...state, defaultmode: true })
        if (event.target.value !== "" || event.target.value !== null) {
            dispatch(brandSearch({
                parent: parentname,
                key: name,
                value: newValue
            }))
        }


    }

    const handleAddProduct = (event) => {
        dispatch(warningModal({
            yesHide: !handleWarning
        }))
    }

    const checkQunique = async (event, columnType) => {
        const { value, name } = event.target;
        // console.log(general_product_fields);
        // setState({ ...state, loader: true });
        if (general_product_fields.hasEditData === null && general_product_fields.editTabData === null) {
            axios_product.get(`products/${columnType}=${value}`).then(function (response) {
                // handle success
                const { status, data } = response;
                if (status === 200) {
                    if (data.resultMap.isUnique !== true) {
                        dispatch(getIsError({ key: name, value: true }))
                    } else {
                        dispatch(getIsError({ key: name, value: false }))
                    }
                    // setState({ ...state, loader: false });
                }
            }).catch(function (error) {
                // handle error
                const { response: { data, status }, message } = error;
                // console.log(error, status, data, name);
                if (status === 500) {
                    setSnackBar({ ...snackBar, show: true, text: data.resultMap ? data.resultMap.message : data.message ? data.message : "Something went wrong!", type: "error" });
                    dispatch(getIsError({ key: name, value: true }));
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data ? data.message : "System took long to respond, please retry!", type: "error", })
                }
                // setState({ ...state, loader: false });
            });
        }
        else {
            // console.log(general_product_fields.hasEditData, general_product_fields.hasEditData[name], general_product_fields[name]);
            let api_flag = true;
            if (name == 'general_outer_case_barcode' && general_product_fields[name] == general_product_fields.hasEditData['outerCaseBarcode']) {
                api_flag = false;
            }
            else {
                api_flag = true;
            }

            if (api_flag === true) {
                axios_product.get(`products/${columnType}=${value}`).then(function (response) {
                    // handle success
                    const { status, data } = response;
                    if (status === 200) {
                        if (data.resultMap.isUnique !== true) {
                            dispatch(getIsError({ key: name, value: true }))
                        } else {
                            dispatch(getIsError({ key: name, value: false }))
                        }
                        // setState({ ...state, loader: false });
                    }
                }).catch(function (error) {
                    // handle error
                    const { response: { data, status }, message } = error;
                    // console.log(error, status, data, name);
                    if (status === 500) {
                        setSnackBar({ ...snackBar, show: true, text: data.resultMap ? data.resultMap.message : data.message ? data.message : "Something went wrong!", type: "error" });
                        dispatch(getIsError({ key: name, value: true }));
                    } else {
                        setSnackBar({ ...snackBar, show: true, text: data ? data.message : "System took long to respond, please retry!", type: "error", })
                    }
                    // setState({ ...state, loader: false });
                });
            }
            else {
                setState({ ...state, loader: false });
            }


        }

    }

    useEffect(() => {
        if ((general_product_fields.general_product_name === "" || general_product_fields.general_product_name === null) || (general_product_fields.general_brand_owner_pid === "" || general_product_fields.general_brand_owner_pid === null) || (general_product_fields.general_outer_case_barcode === "" || general_product_fields.general_outer_case_barcode === null) || (general_product_fields.general_case_quantity === "" || general_product_fields.general_case_quantity === null) || (general_product_fields.general_brands.brand_selected === null || general_product_fields.general_brands.brand_selected === '') || (general_product_fields.general_vat_code.vat_code_selected === null || general_product_fields.general_vat_code.vat_code_selected === '') || (general_product_fields.general_unit_measure.measure_selected === null || general_product_fields.general_unit_measure.measure_selected === '') || (general_product_fields.general_category.category_selected === null || general_product_fields.general_category.category_selected === '')) {
            setState({ ...state, formValid: true, defaultmode: false })
        } else {
            setState({ ...state, formValid: false, defaultmode: true })
        }
        // eslint-disable-next-line max-len
    }, [general_product_fields.general_product_name, general_product_fields.general_brand_owner_pid, general_product_fields.general_outer_case_barcode, general_product_fields.general_case_quantity, general_product_fields.general_brands.brand_selected, general_product_fields.general_vat_code.vat_code_selected, general_product_fields.general_unit_measure.measure_selected, general_product_fields.general_category.category_selected])


    useEffect(() => {
        const { general_product_name,
            general_brand_owner_pid,
            general_outer_case_barcode,
            general_case_quantity,
            brand_selected,
            vat_code_selected,
            measure_selected,
            category_selected,
            member_tier_selected
        } = general_product_fields.isError
        if (!general_product_name && !general_brand_owner_pid &&
            !general_outer_case_barcode && !general_case_quantity &&
            !brand_selected && !vat_code_selected && !measure_selected && !category_selected && !member_tier_selected) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true })
        }
    }, [general_product_fields.isError])

    // const hideproductgeneralbrandModal = (params, reason) => {
    //     if (reason) {
    //         dispatch(generalProductInput({
    //             key: 'general_brand',
    //             value: reason ? reason : null
    //         }))
    //     }
    //     setState({ ...state, productGeneralBrandModal: params === false ? params : true })
    // }
    // const resetFilter = () => {
    //     dispatch(generalProductInput({
    //         key: 'general_brand',
    //         value: ''
    //     }))
    // }
    // const CommonPopUpModal = () => {
    //     setState({ ...state, productGeneralBrandModal: true })
    // }


    const handleSubmit = async (event) => {
        // console.log("Submit Click")
        event.preventDefault();
        if (general_product_fields.isError.general_outer_case_barcode === true) {
            setState({ ...state, formValid: true, loader: false });

        } else {
            if (general_product_fields.hasEditData === null && general_product_fields.editTabData === null) {
                setState({ ...state, loader: true });
                axios_product.get(`products/isOuterCaseValid?outerCaseBarcode=${general_product_fields.general_outer_case_barcode}`).then(function (response) {
                    // handle success
                    const { status, data } = response;
                    if (status === 200) {
                        if (data.resultMap.isUnique !== true) {
                            dispatch(getIsError({ key: 'general_outer_case_barcode', value: true }));
                            setState({ ...state, formValid: true, loader: false });
                        } else {
                            dispatch(getIsError({ key: 'general_outer_case_barcode', value: false }));
                            final_submit();
                        }
                        // setState({ ...state, loader: false });
                    }
                }).catch(function (error) {
                    // handle error
                    const { response: { data, status }, message } = error;
                    // console.log(error, status, data, name);
                    if (status === 500) {
                        setSnackBar({ ...snackBar, show: true, text: data.resultMap ? data.resultMap.message : data.message ? data.message : "Something went wrong!", type: "error" });
                        dispatch(getIsError({ key: 'general_outer_case_barcode', value: true }));
                        setState({ ...state, formValid: true, loader: false });
                    } else {
                        setSnackBar({ ...snackBar, show: true, text: data ? data.message : "System took long to respond, please retry!", type: "error", })
                    }
                    // setState({ ...state, loader: false });
                });
            }
            else {
                let api_flag = true;
                if (general_product_fields['general_outer_case_barcode'] == general_product_fields.hasEditData['outerCaseBarcode']) {
                    api_flag = false;
                }
                else {
                    api_flag = true;
                }

                if (api_flag === true) {
                    setState({ ...state, loader: true });
                    axios_product.get(`products/isOuterCaseValid?outerCaseBarcode=${general_product_fields.general_outer_case_barcode}`).then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (status === 200) {
                            if (data.resultMap.isUnique !== true) {
                                dispatch(getIsError({ key: 'general_outer_case_barcode', value: true }));
                                setState({ ...state, formValid: true, loader: false });
                            } else {
                                dispatch(getIsError({ key: 'general_outer_case_barcode', value: false }));
                                final_submit();
                            }
                            // setState({ ...state, loader: false });
                        }
                    }).catch(function (error) {
                        // handle error
                        const { response: { data, status }, message } = error;
                        // console.log(error, status, data, name);
                        if (status === 500) {
                            setSnackBar({ ...snackBar, show: true, text: data.resultMap ? data.resultMap.message : data.message ? data.message : "Something went wrong!", type: "error" });
                            dispatch(getIsError({ key: 'general_outer_case_barcode', value: true }));
                            setState({ ...state, formValid: true, loader: false });
                        } else {
                            setSnackBar({ ...snackBar, show: true, text: data ? data.message : "System took long to respond, please retry!", type: "error", })
                        }
                        // setState({ ...state, loader: false });
                    });
                }
                else {
                    setState({ ...state, loader: false });
                    final_submit();
                }
            }




        }
    };

    const final_submit = async () => {
        let productData = null;
        if (!state.formValid) {
            // console.log("Submit Click - d")
            productData = {
                "name": general_product_fields.general_product_name,
                "brandOwnerProductId": general_product_fields.general_brand_owner_pid,
                "outerCaseBarcode": general_product_fields.general_outer_case_barcode,
                "vatCode": general_product_fields.general_vat_code.vat_code_selected,
                "brand": general_product_fields.general_brands.brand_selected,
                "unitOfMeasure": general_product_fields.general_unit_measure.measure_selected,
                "category": general_product_fields.general_category.category_selected,
                "active": general_product_fields.general_active_p,
                "eanCode": general_product_fields.general_ean_code || "",
                "rsp": parseInt(general_product_fields.general_rsp) || "",
                "tdExport": general_product_fields.general_export_td,
                "caseQuantity": parseInt(general_product_fields.general_case_quantity),
                "fairTrade": general_product_fields.general_fair_trade,
                "organic": general_product_fields.general_organic,
                "ethical": general_product_fields.general_ethical,
                //Post GO Live --
                "rt": general_product_fields.general_rt,
                "msc": general_product_fields.general_msc,
                "rspca": general_product_fields.rspca,
                "fsc": general_product_fields.general_fsc,
                "rspo": general_product_fields.general_rspo,
                "countryOfOrigin": general_product_fields.general_coo
                //Post GO Live --

                // "onTradeLevySchemeId": general_product_fields.general_on_trade.leavy_on_trade_selected ?  general_product_fields.general_on_trade.leavy_on_trade_selected : "",
                // "offTradeLevySchemeId": general_product_fields.general_off_trade.leavy_off_trade_selected ? general_product_fields.general_on_trade.leavy_on_trade_selected  : ""
            }


            try {
                setState({ ...state, loader: true });
                const { data, status } = general_product_fields.editTabData ? await axios_product.put(`v2/products/product/${(general_product_fields.editTabData.productId || general_product_fields.editTabData.result.id)}`, { ...productData }) : general_product_fields.hasEditData ? await axios_product.put(`v2/products/product/${general_product_fields.hasEditData.id}`, { ...productData }) : await axios_product.post(`products/product`, { ...productData });
                if (data.success === true && data.resultMap) {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "success", })
                    // setSnackBar({ ...snackBar, show: true, text: `Product ${general_product_fields.hasEditData ? 'Edited' : 'Added'} Successfully!`, type: "success", })
                    // setState({ ...state, loader: false, formValid: true, newProductData: data.resultMap })
                    setState({ ...state, loader: false, newProductData: data.resultMap });
                    dispatch(hasTabEditData({ editData: data.resultMap }));
                    dispatch(setHasEditData(productData));
                    dispatch(setProductFormStatus(true));
                    dispatch(hasTabEdit({ editTab: false }));
                    //general_product_fields.hasEditData ? "" : setOtherTabsOpen(true)
                    // if (!general_product_fields.hasEditData) {
                    //     setOtherTabsOpen(true)
                    // } else {
                    //     setTimeout(() => {
                    //         // window.location.reload();
                    //         dispatch(handleAddEditModal({
                    //             showAddEdit: false
                    //         }));
                    //         dispatch(filterFlagChange(true));
                    //     }, 1500);                        
                    // }
                    // if (!general_product_fields.hasEditData) { 
                    // } else {

                    // }
                    setTimeout(() => {
                        // window.location.reload();
                        dispatch(handleAddEditModal({
                            showAddEdit: true
                        }));
                        // dispatch(filterFlagChange(true));
                    }, 1500);
                }
            } catch (error) {
                const { response: { data, status } } = error;
                // console.log(error, status, data);
                //dispatch(hasTabEdit({ editTab: true }))
                if (status === 500 && data.errorType === "Business") {
                    setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data ? data.message : "System took long to respond, please retry!", type: "error", })
                }
                setState({ ...state, loader: false, formValid: false })
            }
        }
    }

    const handleOtherTabsOpen = () => {
        dispatch(hasTabEdit({ editTab: false }))
        dispatch(hasTabEditData({ editData: state.newProductData }))
        setOtherTabsOpen(false)
    }
    const handleOtherTabsClose = () => {
        setOtherTabsOpen(false)
        setTimeout(() => {
            dispatch(handleAddEditModal({
                showAddEdit: false,
            }))
            // window.location.reload();
            dispatch(filterFlagChange(true));
            dispatch(filterResetFlagChange(false));
        }, 750);
    }

    return (
        <form onSubmit={handleSubmit}>
            <Box sx={{ flexGrow: 1, paddingX: 2 }} >
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                        <Item>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={4} className="formLabelCntr">
                                    <Item>
                                        <FormLabel htmlFor="general_product_name" className="fontSemiBold mandatory">Product Name</FormLabel>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={7.5}>
                                    <Item>
                                        <div className="input-group">
                                            <FormControl fullWidth>
                                                <TextField
                                                    inputProps={{ maxLength: 200 }}
                                                    size="small"
                                                    id="general_product_name"
                                                    aria-describedby="general_product_name_error"
                                                    onChange={(e) => handleInputChange(e)}
                                                    value={general_product_fields['general_product_name']}
                                                    error={general_product_fields.isError.general_product_name}
                                                    name="general_product_name"

                                                />
                                                {general_product_fields.isError.general_product_name && (<FormHelperText className='errorHelperTxt' id="general_product_name_error">
                                                    Value should not be blank
                                                </FormHelperText>)}
                                            </FormControl>

                                        </div>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                        <Item>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={3.1} sx={{ textAlign: 'right', display: { xs: 'none', md: 'block' } }}>
                                    <Item>
                                        &nbsp;
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Item>
                                        <Grid container direction="row" alignItems="center">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox size="medium"
                                                        id="general_active_p"
                                                        checked={general_product_fields.general_active_p}
                                                        onChange={(e) => handleCheckboxChange(e)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        name="general_active_p"
                                                    />}
                                                label="Active" />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox size="medium"
                                                        id="general_export_td"
                                                        checked={general_product_fields.general_export_td}
                                                        onChange={(e) => handleCheckboxChange(e)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        name="general_export_td"
                                                    />}
                                                label="Export To TD" />
                                        </Grid>

                                    </Item>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                </Grid>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                        <Item>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={4} className="formLabelCntr">
                                    <Item>
                                        <FormLabel htmlFor="general_brand_owner_pid" className="fontSemiBold mandatory">Brand owner product ID</FormLabel>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={7.5}>
                                    <Item>
                                        <div className="input-group">
                                            <FormControl fullWidth>
                                                <TextField
                                                    inputProps={{ maxLength: 100 }}
                                                    size="small"
                                                    id="general_brand_owner_pid"
                                                    aria-describedby="general_brand_owner_pid_error"
                                                    onChange={(e) => handleInputChange(e)}
                                                    value={general_product_fields.general_brand_owner_pid}
                                                    error={general_product_fields.isError.general_brand_owner_pid}
                                                    name="general_brand_owner_pid"

                                                />
                                                {general_product_fields.isError.general_brand_owner_pid && (<FormHelperText className='errorHelperTxt' id="general_brand_owner_pid_error">
                                                    Value should not be blank
                                                </FormHelperText>)}
                                            </FormControl>
                                        </div>
                                    </Item>

                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                        <Item>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={3} className="formLabelCntr">
                                    <Item>
                                        <FormLabel htmlFor="general_outer_case_barcode" className="fontSemiBold mandatory">Outer case barcode</FormLabel>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Item>
                                        <div className="input-group">
                                            <FormControl fullWidth>
                                                <TextField
                                                    inputProps={{ maxLength: 100 }}
                                                    size="small"
                                                    id="general_outer_case_barcode"
                                                    aria-describedby="general_outer_case_barcode_error"
                                                    onChange={(e) => handleOCBCInputChange(e)}
                                                    value={general_product_fields.general_outer_case_barcode}
                                                    error={general_product_fields.isError.general_outer_case_barcode}
                                                    name="general_outer_case_barcode"
                                                    onBlur={(e) => checkQunique(e, 'isOuterCaseValid?outerCaseBarcode')}
                                                />
                                                {general_product_fields.isError.general_outer_case_barcode && (<FormHelperText className='errorHelperTxt' id="general_outer_case_barcode_error">
                                                    Please enter unique value
                                                </FormHelperText>)}
                                            </FormControl>
                                        </div>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                </Grid>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                        <Item>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={4} className="formLabelCntr">
                                    <Item>
                                        <FormLabel htmlFor="general_ean_code" className="fontSemiBold">EAN Code</FormLabel>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={7.5}>
                                    <Item>
                                        <FormControl fullWidth>
                                            <TextField
                                                inputProps={{ maxLength: 50 }}
                                                size="small"
                                                id="general_ean_code"
                                                aria-describedby="general_ean_code_error"
                                                onChange={(e) => handleInputChange(e)}
                                                value={general_product_fields.general_ean_code}
                                                name="general_ean_code"
                                            />
                                        </FormControl>
                                    </Item>

                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                        <Item>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={3} className="formLabelCntr">
                                    <Item>
                                        <FormLabel htmlFor="general_rsp" className="fontSemiBold">RSP</FormLabel>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Item>
                                        <FormControl fullWidth>
                                            <TextField
                                                inputProps={{ maxLength: 50 }}
                                                type="number"
                                                size="small"
                                                id="general_rsp"
                                                aria-describedby="general_rsp_error"
                                                onChange={(e) => handleInputChange(e)}
                                                value={general_product_fields.general_rsp}
                                                name="general_rsp"
                                                className="inputNumber"
                                                onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                            />
                                        </FormControl>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                </Grid>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                        <Item>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={4} className="formLabelCntr">
                                    <Item>
                                        <FormLabel htmlFor="general_case_quantity" className="fontSemiBold mandatory">Case Quantity</FormLabel>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={7.5}>
                                    <Item>
                                        <div className="input-group">
                                            <FormControl fullWidth>
                                                <TextField
                                                    inputProps={{ maxLength: 50 }}
                                                    type="number"
                                                    size="small"
                                                    id="general_case_quantity"
                                                    aria-describedby="general_case_quantity_error"
                                                    onChange={(e) => handleInputChange(e)}
                                                    value={general_product_fields['general_case_quantity']}
                                                    error={general_product_fields.isError.general_case_quantity}
                                                    name="general_case_quantity"
                                                    className="inputNumber"
                                                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                                />
                                                {general_product_fields.isError.general_case_quantity && (<FormHelperText className='errorHelperTxt' id="general_case_quantity_error">
                                                    Please enter decimal value
                                                </FormHelperText>)}
                                            </FormControl>
                                        </div>
                                    </Item>

                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                        <Item>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={3} className="formLabelCntr">
                                    <Item>
                                        <FormLabel htmlFor="general_brand" className="fontSemiBold mandatory">Brand</FormLabel>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Item>
                                        <Autocomplete
                                            defaultValue={general_product_fields.general_brands.brand_selected || (general_product_fields.hasEditData ? general_product_fields.hasEditData.brand : null)}
                                            id="general_brands_search"
                                            options={general_product_fields.general_brands.brand_options}
                                            getOptionLabel={(option) => option.name || ""}
                                            componentsProps={{
                                                paper: {
                                                    sx: {
                                                        fontSize: '0.85rem'
                                                    }
                                                }
                                            }}
                                            onChange={(event, newValue) => handleSelection(event, newValue, 'general_brands', 'brand_selected')}
                                            renderInput={(params) =>
                                                <FormControl size={'small'} fullWidth>
                                                    <div className="input-group">
                                                        <TextField {...params}
                                                            size="small"
                                                            id="general_brand"
                                                            aria-describedby="general_brand-error"
                                                            error={general_product_fields.isError.brand_selected}
                                                        />
                                                        {general_product_fields.isError.brand_selected &&
                                                            (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)}
                                                    </div>
                                                </FormControl>
                                            }

                                        />
                                    </Item>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                        <Item sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={3} className="formLabelCntr">
                                    <Item>
                                        <FormLabel htmlFor="general_brand" className="fontSemiBold mandatory">Brand</FormLabel>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Item sx={{ paddingLeft: '12px', paddingRight: '12px' }}>
                                        <Box component="div" className=''>
                                            <FormControl size={'small'} fullWidth>
                                                <TextField
                                                    inputProps={{ maxLength: 100 }}
                                                    size="small"
                                                    id="general_brand"
                                                    value={general_product_fields.general_brand ? general_product_fields.general_brand.name : ''}
                                                    aria-describedby="general_brand_error"
                                                    name="general_brand"
                                                    disabled={true}
                                                    error={general_product_fields.isError.general_brand}
                                                />
                                                {general_product_fields.isError.general_brand &&
                                                    (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)}
                                            </FormControl>
                                        </Box>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={1}>
                                    <Item>
                                        <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0, marginRight: '5px' }} onClick={resetFilter} startIcon={<ClearIcon />}></Button>
                                        <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={(e) => CommonPopUpModal(e)} startIcon={<EditIcon />}></Button>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid> */}
                </Grid>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                        <Item>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={4} className="formLabelCntr">
                                    <Item>
                                        <FormLabel htmlFor="general_vat_code" className="fontSemiBold mandatory">Vat Code</FormLabel>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={7.5}>
                                    <Item>
                                        <Autocomplete
                                            defaultValue={general_product_fields.general_vat_code.vat_code_selected || (general_product_fields.hasEditData ? general_product_fields.hasEditData.vatCode : null)}
                                            id="general_vat_code_search"
                                            options={general_product_fields.general_vat_code.vat_code_options}
                                            getOptionLabel={(option) => option.code || ""}
                                            componentsProps={{
                                                paper: {
                                                    sx: {
                                                        fontSize: '0.85rem'
                                                    }
                                                }
                                            }}
                                            onChange={(event, newValue) => handleSelection(event, newValue, 'general_vat_code', 'vat_code_selected')}
                                            renderInput={(params) =>
                                                <FormControl size={'small'} fullWidth>
                                                    <div className="input-group">
                                                        <TextField {...params}
                                                            size="small"
                                                            id="general_vat_code"
                                                            aria-describedby="general_vat_code-error"
                                                            error={general_product_fields.isError.vat_code_selected}
                                                        />
                                                        {general_product_fields.isError.vat_code_selected &&
                                                            (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)}
                                                    </div>
                                                </FormControl>
                                            }

                                        />
                                    </Item>

                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                        <Item>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={3} className="formLabelCntr">
                                    <Item>
                                        <FormLabel htmlFor="general_unit_measure" className="fontSemiBold mandatory">Unit of Measure</FormLabel>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Item>
                                        <Autocomplete
                                            defaultValue={general_product_fields.general_unit_measure.measure_selected || (general_product_fields.hasEditData ? general_product_fields.hasEditData.unitOfMeasure : null)}
                                            id="general_unit_measure_search"
                                            options={general_product_fields.general_unit_measure.measure_options}
                                            getOptionLabel={(option) => option.name || ""}
                                            componentsProps={{
                                                paper: {
                                                    sx: {
                                                        fontSize: '0.85rem'
                                                    }
                                                }
                                            }}
                                            onChange={(event, newValue) => handleSelection(event, newValue, 'general_unit_measure', 'measure_selected')}
                                            renderInput={(params) =>
                                                <FormControl size={'small'} fullWidth>
                                                    <div className="input-group">
                                                        <TextField {...params}
                                                            size="small"
                                                            id="general_unit_measure"
                                                            aria-describedby="general_unit_measure-error"
                                                            error={general_product_fields.isError.measure_selected}
                                                        />
                                                        {general_product_fields.isError.measure_selected &&
                                                            (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)}
                                                    </div>
                                                </FormControl>
                                            }
                                        />
                                    </Item>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                </Grid>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                        <Item>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={4} className="formLabelCntr">
                                    <Item>
                                        <FormLabel htmlFor="general_category" className="fontSemiBold mandatory">Category</FormLabel>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={7.5}>
                                    <Item>
                                        <Autocomplete
                                            defaultValue={general_product_fields.general_category.category_selected || (general_product_fields.hasEditData ? general_product_fields.hasEditData.category : null)}
                                            id="general_category_search"
                                            options={general_product_fields.general_category.category_options}
                                            getOptionLabel={(option) => option.name || ""}
                                            componentsProps={{
                                                paper: {
                                                    sx: {
                                                        fontSize: '0.85rem'
                                                    }
                                                }
                                            }}
                                            onChange={(event, newValue) => handleSelection(event, newValue, 'general_category', 'category_selected')}
                                            renderInput={(params) =>
                                                <FormControl size={'small'} fullWidth>
                                                    <div className="input-group">
                                                        <TextField {...params}
                                                            size="small"
                                                            id="general_category"
                                                            aria-describedby="general_category-error"
                                                            error={general_product_fields.isError.category_selected}
                                                        />
                                                        {general_product_fields.isError.category_selected &&
                                                            (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)}
                                                    </div>
                                                </FormControl>
                                            }
                                        />
                                    </Item>

                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} sx={{ marginBottom: 0.5 }}>
                        <Item>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={3.1} sx={{ textAlign: 'right', display: { xs: 'none', md: 'block' } }}>
                                    <Item>
                                        &nbsp;
                                    </Item>
                                </Grid>
                                <Grid item xs={12} sm={12} md={7.5}>
                                    <Item sx={{ paddingRight: '0' }}>
                                        <Grid container direction="row" alignItems="center" >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox size="medium"
                                                        id="general_fair_trade"
                                                        checked={general_product_fields.general_fair_trade}
                                                        onChange={(e) => handleCheckboxChange(e)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        name="general_fair_trade"
                                                    />}
                                                label="Fair Trade" />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox size="medium"
                                                        id="general_organic"
                                                        checked={general_product_fields.general_organic}
                                                        onChange={(e) => handleCheckboxChange(e)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        name="general_organic"
                                                    />}
                                                label="Organic" />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox size="medium"
                                                        id="general_ethical"
                                                        checked={general_product_fields.general_ethical}
                                                        onChange={(e) => handleCheckboxChange(e)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        name="general_ethical"
                                                    />}
                                                label="Ethical" />
                                            {/* //Post GO Live -- */}
                                            <FormControlLabel
                                                control={
                                                    <Checkbox size="medium"
                                                        id="general_fsc"
                                                        checked={general_product_fields.general_fsc}
                                                        onChange={(e) => handleCheckboxChange(e)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        name="general_fsc"
                                                    />}
                                                label="FSC" />
                                        </Grid>

                                    </Item>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                </Grid>
                {/* //Post GO Live -- */}
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                        <Item>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={4} className="formLabelCntr">
                                    <Item>
                                        <FormLabel htmlFor="general_coo" className="fontSemiBold">Country of Origin</FormLabel>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={7.5}>
                                    <Item>
                                        <FormControl fullWidth>
                                            <TextField

                                                size="small"
                                                id="general_coo"
                                                aria-describedby="general_coo_error"
                                                onChange={(e) => handleInputChange(e)}
                                                value={general_product_fields.general_coo}
                                                name="general_coo"

                                            />
                                        </FormControl>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5, marginTop: -2 }}>
                        <Item>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={3.1} sx={{ textAlign: 'right', display: { xs: 'none', md: 'block' } }}>
                                    <Item>

                                    </Item>
                                </Grid>
                                <Grid item xs={12} sm={12} md={7.5}>
                                    <Item sx={{ padding: '0' }}>
                                        <Grid container alignItems="center" sx={{ padding: '0' }}>
                                            <Grid item xs={12} sm={7} md={7} sx={{ textAlign: 'left', borderBottom: '2px solid #ccc', paddingBottom: '5px', marginRight: '20px', paddingLeft: '0' }} >
                                                <Item sx={{ padding: '0' }}>
                                                    <FormLabel htmlFor="general_coo" className="fontSemiBold">UK Assured</FormLabel>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4} sx={{ textAlign: 'left', borderBottom: '2px solid #ccc', paddingBottom: '5px' }} >
                                                <Item sx={{ padding: '0' }}>
                                                    <FormLabel htmlFor="general_coo" className="fontSemiBold">Palm Oil</FormLabel>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Item>
                                    <Item>
                                        <Grid container sx={{ padding: '0' }}>
                                            <Grid item xs={12} sm={7} md={7} sx={{ textAlign: 'center', padding: '5px 1px' }} >
                                                <Item sx={{ padding: '0', textAlign: 'center' }} >
                                                    <Box sx={{ padding: '0' }} >
                                                        <Grid container direction="row" alignItems="center" sx={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'left', display: 'block' }} >
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox size="medium"
                                                                        id="general_rt"
                                                                        checked={general_product_fields.general_rt}
                                                                        onChange={(e) => handleCheckboxChange(e)}
                                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                                        name="general_rt"
                                                                    />}
                                                                label="RT" sx={{ marginRight: '12px' }} />
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox size="medium"
                                                                        id="general_msc"
                                                                        checked={general_product_fields.general_msc}
                                                                        onChange={(e) => handleCheckboxChange(e)}
                                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                                        name="general_msc"
                                                                    />}
                                                                label="MSC" sx={{ marginRight: '12px' }} />
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox size="medium"
                                                                        id="general_rspca"
                                                                        checked={general_product_fields.general_rspca}
                                                                        onChange={(e) => handleCheckboxChange(e)}
                                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                                        name="general_rspca"
                                                                    />}
                                                                label="RSPCA" sx={{ marginRight: '5px' }} />
                                                        </Grid>

                                                    </Box>

                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} sm={5} md={5} sx={{ textAlign: 'center', padding: '5px 4px' }} >
                                                <Item sx={{ textAlign: 'center', padding: '0' }}>
                                                    <Box >
                                                        <Grid container direction="row" alignItems="left" sx={{ marginLeft: '20px' }} >
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox size="medium"
                                                                        id="general_rspo"
                                                                        checked={general_product_fields.general_rspo}
                                                                        onChange={(e) => handleCheckboxChange(e)}
                                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                                        name="general_rspo"
                                                                    />}
                                                                label="RSPO" />
                                                        </Grid>
                                                    </Box>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Item>
                                    <Item>


                                    </Item>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                </Grid>
                <Grid container sx={{ marginTop: '20px' }}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Item>
                            <Grid container direction="row" alignItems="center" justifyContent="center">
                                <LoadingButton
                                    loading={state.loader}
                                    loadingPosition="center"
                                    endIcon=""
                                    variant="contained"
                                    type="submit" sx={{ marginRight: '15px' }}
                                    //disabled={state.formValid || state.defaultmode === false}
                                    // eslint-disable-next-line max-len
                                    disabled={(general_product_fields.hasEditData || general_product_fields.editTabData) ? (general_product_fields.isError.general_product_name || general_product_fields.isError.general_brand_owner_pid || general_product_fields.isError.general_outer_case_barcode || general_product_fields.isError.general_case_quantity || general_product_fields.isError.general_brand || general_product_fields.isError.vat_code_selected || general_product_fields.isError.measure_selected || general_product_fields.isError.category_selected) : (general_product_fields.isError.general_outer_case_barcode) ? true : (state.formValid || state.defaultmode === false)}
                                //className={`${general_product_fields.productFormValid ? 'Mui-disabled' : ''}`}
                                >Save General Details</LoadingButton>
                                <Button variant="outlined" onClick={handleAddProduct}>Cancel</Button>
                            </Grid>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
            {snackBar.show && <SnackBarDialog
                onShow={snackBar.show}
                text={snackBar.text}
                type={snackBar.type}
                onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
            />}
            <Modal
                open={otherTabsOpen}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleOtherTabsClose();
                    }
                }}
            >
                <Box component="div" className="overlay" id="addEditModal">
                    <div className={`overlay-box small_overlay`}>
                        <div className="content">
                            <div className="overlayBody">
                                <div className="overlay-content">
                                    <Box component="div" sx={{ textAlign: 'center', padding: 1.5 }}>
                                        <p className="fontMedium">Would you like to add other details ?</p>
                                        <Grid sx={{ paddingTop: 2 }} container direction="row" alignItems="center" justifyContent="center">
                                            <Button variant="contained" type="submit" sx={{ marginRight: '15px' }} onClick={handleOtherTabsOpen}>Yes</Button>
                                            <Button variant="outlined" onClick={handleOtherTabsClose}>No</Button>
                                        </Grid>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
            {/* {
                state.productGeneralBrandModal && <CommonPopUp
                    onShow={state.productGeneralBrandModal}
                    size="large_overlay"
                    brandDetails={{
                        title: 'Brand Details'
                    }}
                    onHide={(e, reason) => hideproductgeneralbrandModal(e, reason)}
                />
            } */}
        </form>
    );
};
export default General;