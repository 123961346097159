import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hasEditData: null,
  promotion_type_name: '',
  promotionTypeFormValid: false,
  add_edit_promotion_type: '',
  deleteList: null,
  promotion_typeStartSingleDelete: false,
  promotion_typeConfirmSingleDelete: false,
  isError: {
    promotion_type_name: false
  }
}

const promotionType = createSlice({
  name: 'promotionType',
  initialState,
  reducers: {
    brandSearch: (state, action) => {
      const { parent, key, value } = action.payload;
      state[parent][key] = value;
    },
    promotionTypeInput: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
      state[key].length === 0 ? state.isError[key] = true : state.isError[key] = false
    },
    getIsError: (state, action) => {
      const { key, value } = action.payload;
      state.isError[key] = value
    },
    setAutocomplete: (state, action) => {
      const { parent, key, value } =  action.payload;
      state[parent][key] = value
    },
    setHasEditData: (state, action) => {
      state.hasEditData = action.payload;
    },
    setpromotionTypeFormStatus: (state, action) => {
      state.promotionTypeFormValid = action.payload;
    },
    setDeleteList: (state, action) => {
      state.deleteList = action.payload
    },
    setpromotionTypeDeleteData: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    resetpromotionType: () => {
      return initialState
    },
  }
})

export const { brandSearch, setAutocomplete, getIsError, promotionTypeInput, hasTabEdit, resetpromotionType, setHasEditData, setpromotionTypeFormStatus, setDeleteList, setpromotionTypeDeleteData } = promotionType.actions

export default promotionType.reducer