

import { Select, FormControl, FormLabel, FormHelperText, MenuItem } from '@mui/material'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { setFilter } from '../../../store/slices/externalFilter';

export const SelectDropDown = (props) => {
    const { label, id, size, values } = props;
    const [value, setValue] = useState("");
    const [state, setState] = useState({
        error: false
    })
    const { selectState } = useSelector((state) => {
        return {
            selectState: state.externalFilter,
        }
    });
    
    const dispatch = useDispatch();

    const handleChange = (event) => {
        event.preventDefault()
        dispatch(setFilter({
            key: id,
            value: event.target.value 
        }))
    }

    return (
        <>
            <FormControl fullWidth>
                {label && (<FormLabel htmlFor={id}>{label}</FormLabel>)}
                <Select
                    size={size}
                    id={id}
                    defaultValue=""
                    onChange={(e) => handleChange(e)}
                    value={selectState[id]}
                    aria-describedby={id + 'error'} >
                    <MenuItem disabled value="" sx={{fontSize: '0.85rem'}}>Choose Option</MenuItem>
                    {values.map((option) => (
                        <MenuItem value={option.value} key={option.value} sx={{fontSize: '0.85rem'}}>{option.text}</MenuItem>
                    ))}
                </Select>
                {state.error && (<FormHelperText id={id + 'error'}>Error Here</FormHelperText>)}
            </FormControl>
        </>
    );
}
