import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  channel_price_name: '',
  channel_charge_p_c: '',
  channel_promo_price_name: '',
  defaultTab: "",
  editTabData: null,
  hasEditData: null,
  editChannelPrice: null,
  editChannelCharge: null,
  editChannelPromoPrice: null,
  latestEndPriceDate: null,
  latestEndChargeDate: null,
  latestEndPromoPriceDate: null,
  nullPriceStartDate: null,
  nullChargeStartDate : null,
  nullPromoPriceStartDate : null,
  channel_price_date: [],
  channel_charge_date: [],
  channel_promo_price_date: [],
  channel_price_start_date: null,
  channel_price_end_date: null,
  channel_charge_start_date: null,
  channel_charge_end_date: null,
  channel_promo_price_start_date: null,
  channel_promo_price_end_date: null,
  default_channel_price_start_date: null,
  default_channel_charge_start_date: null,
  default_channel_promo_price_start_date: null,
  pdcstartSingleDelete: false,
  pdcconfirmSingleDelete: false,
  pdcChargeconfirmSingleDelete: false,
  pdcChargestartSingleDelete: false,
  pdcPromoPriceconfirmSingleDelete: false,
  pdcPromoPricestartSingleDelete: false,
  deleteList: null,
  deleteChargeList: null,
  deletePromoPriceList: null,
  isError: {
    channel_price_name: false,
    channel_price_start_date: false,
    channel_charge_start_date: false,
    channel_charge_p_c: false,
    channel_promo_price_name: false,
    channel_promo_price_start_date: false,
    channel_promo_price_end_date: false
  }
}

const pricePdcProduct = createSlice({
  name: 'price_pdc_product',
  initialState,
  reducers: {
    setEditChannelPrice: (state, action) => {
      state.editChannelPrice = action.payload;
    },
    setEditChannelCharge: (state, action) => {
      state.editChannelCharge = action.payload;
    },
    setEditChannelPromoPrice: (state, action) => {
      state.editChannelPromoPrice = action.payload;
    },
    setchannel_priceDate: (state, action) => {
      state.channel_price_date.push(action.payload)
    },
    setchannel_priceDateList: (state, action) => {
      state.channel_price_date = action.payload
    },
    setchannel_chargeDate: (state, action) => {
      state.channel_charge_date.push(action.payload)
    },
    setchannel_chargeDateList: (state, action) => {
      state.channel_charge_date = action.payload
    },
    setchannel_promo_priceDate: (state, action) => {
      state.channel_promo_price_date.push(action.payload)
    },
    setchannel_promo_priceDateList: (state, action) => {
      state.channel_promo_price_date = action.payload
    },
    setDeleteList: (state, action) => {
      state.deleteList = action.payload
    },
    setDeleteChargeList: (state, action) => {
      state.deleteChargeList = action.payload
    },
    setDeletePromoPriceList: (state, action) => {
      state.deletePromoPriceList = action.payload
    },
    setSingleDeleteData: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    setAutocomplete: (state, action) => {
      const { parent, key, value } = action.payload;
      state[parent][key] = value
    },
    channel_priceInput: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
      state[key].length === 0 ? state.isError[key] = true : state.isError[key] = false
    },
    setChannelPriceInput: (state, action) => {
      state.channel_price_name = action.payload;
      (state.channel_price_name === '' || state.channel_price_name === null) ? state.isError.channel_price_name = true : state.isError.channel_price_name = false
    },
    setChannelChargeInput: (state, action) => {
      state.channel_charge_p_c = action.payload;
      (state.channel_charge_p_c === '' || state.channel_charge_p_c === null) ? state.isError.channel_charge_p_c = true : state.isError.channel_charge_p_c = false
    },
    setChannelPromoPriceInput: (state, action) => {
      state.channel_promo_price_name = action.payload;
      (state.channel_promo_price_name === '' || state.channel_promo_price_name === null) ? state.isError.channel_promo_price_name = true : state.isError.channel_promo_price_name = false
    },
    getIsError: (state, action) => {
      const { key, value } = action.payload;
      state.isError[key] = value
    },
    setchannel_priceEffectiveDate: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
      state.isError[key] ? (state[key] === null || state[key] === "" || state[key] === 'Invalid date') ? state.isError[key] = true : state.isError[key] = false : '';
    },
    setchannel_chargeEffectiveDate: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
      state.isError[key] ? (state[key] === null || state[key] === "" || state[key] === 'Invalid date') ? state.isError[key] = true : state.isError[key] = false : '';
    },
    setchannel_promo_priceEffectiveDate: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
      state.isError[key] ? (state[key] === null || state[key] === ""  || state[key] === 'Invalid date') ? state.isError[key] = true : state.isError[key] = false : '';
    },
    hasTabEditPrice: (state, action) => {
      const { editTab } =  action.payload;
      state.editTab = editTab
    },
    hasTabEditPriceData: (state, action) => {
      const { editData } =  action.payload;
      state.editTabData = editData
    },
    setHasEditPriceData: (state, action) => {
      state.hasEditData = action.payload;
    },
    setLatestlatestEndPriceDate: (state, action) => {
      state.latestEndPriceDate = action.payload
    },
    setLatestlatestEndChargeDate: (state, action) => {
      state.latestEndChargeDate = action.payload
    },
    setLatestlatestEndPromoPriceDate: (state, action) => {
      state.latestEndPromoPriceDate = action.payload
    },
    setNullPriceStartDate: (state, action) => {
      state.nullPriceStartDate = action.payload
    },
    setNullChargeStartDate: (state, action) => {
      state.nullChargeStartDate = action.payload
    },
    setNullPromoPriceStartDate: (state, action) => {
      state.nullPromoPriceStartDate = action.payload
    },
    resetchannel_price: () => {
      return initialState
    },
  }
})

export const { resetchannel_price, setEditChannelPrice, setchannel_priceDate, 
  getIsError, channel_priceInput, setAutocomplete, setchannel_priceEffectiveDate, 
  setchannel_priceDateList, setDeleteList, setDeleteChargeList, hasTabEditPriceData, setHasEditPriceData, hasTabEditPrice, 
  setChannelPriceInput, setChannelChargeInput, setchannel_chargeDate, setchannel_chargeDateList, setEditChannelCharge, 
  setchannel_chargeEffectiveDate, setEditChannelPromoPrice, setChannelPromoPriceInput, 
  setchannel_promo_priceEffectiveDate, setchannel_promo_priceDate, setchannel_promo_priceDateList, 
  setSingleDeleteData, setLatestlatestEndPriceDate, setLatestlatestEndChargeDate, setLatestlatestEndPromoPriceDate, 
  setNullPriceStartDate, setNullChargeStartDate, setNullPromoPriceStartDate, setDeletePromoPriceList } = pricePdcProduct.actions

export default pricePdcProduct.reducer