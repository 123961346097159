import { Checkbox, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl, RadioGroup, Radio, Autocomplete } from '@mui/material'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import axios_supplier from '../../../../services/utility/axios-supplier';
import axios from '../../../../services/utility/axios-retro-discount-schemes';
import PageLoader from '../../../../components/shared/PageLoader';
import { setRetroState, getIsError } from '../../../../store/slices/retrodiscountschemes';
import { handleModalState, setNewPriceModal } from '../../../../store/slices/modals';
import CommonPopUp from '../../../products/addEditPdc/pdc-tabs/CommonPopUp';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import { setMemberCode } from '../../../../store/slices/supplierMemberAccounts';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

function AddEditSupplier({ onShow, size = '', onHide, onSubmitDate }) {

    const { retro_fields } = useSelector((state) => {
        return {
            retro_fields: state.retrodiscountscheme,
            sma_container: state.smas,
        }
    });
    const dispatch = useDispatch();

    const [state, setState] = useState({
        supplierListModal: false,
        pageLoader: false,
        defaultmode: false,
        formValid: true
    })

    const [included, setIncluded] = useState(true);


    const [open, setOpen] = useState(onShow);

    const handleClose = () => {
        //setState({ ...state, defaultmode: false })
        dispatch(handleModalState({
            key: 'addEditSupplierModal',
            value: false
        }))
        dispatch(setRetroState({
            key: 'hasEditSupplier',
            value: null
        }))
        dispatch(setMemberCode({
            key: 'smaSupplierNameOnModal',
            value: ""
        }))
        setIsError({ ...isError, supplier_selected: false })
        //dispatch(getIsError({ key: 'supplierDetail', value: false }))
    };

    const handleSelf = () => {
        handleClose()
    }
    const [supplier, setSupplier] = useState({
        supplier_options: [],
        supplier_selected: retro_fields.hasEditSupplier ? retro_fields.hasEditSupplier.supplier : null
    })
    const [isError, setIsError] = useState({
        supplier_selected: false
    })

    const handleChange = (event) => {
        if (retro_fields.hasEditSupplier) {
            setState({ ...state, defaultmode: true })
        }
        setIncluded(event.target.checked);
    };

    useEffect(() => {
        const fetchMyAPI = async () => {
            if (supplier.supplier_options.length === 0) {
                axios_supplier.get('/supplier/look-up')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data.filter((value, index, self) => self.findIndex((m) => m.name === value.name) === index);
                            setSupplier({ ...supplier, supplier_options: unique })
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
        }
        fetchMyAPI();
    }, [])

    useEffect(() => {
        if (supplier.supplier_selected === null || supplier.supplier_selected === "") {
            setState({ ...state, formValid: true, defaultmode: false })
        } else {
            setState({ ...state, formValid: false, defaultmode: true })
        }
    }, [supplier.supplier_selected])

    useEffect(() => {
        const {
            supplier_selected
        } = isError
        if (!supplier_selected) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true })
        }
    }, [isError])


    const handleSubmit = (event) => {
        event.preventDefault();
        if (supplier.supplier_selected === null && isError.supplier_selected === false) {
            setIsError({ ...isError, supplier_selected: true })
        } else {
            addPayDate()
        }

    }
    const addPayDate = async () => {
        let payload = null;
        const id = (retro_fields.hasEditData) ? (retro_fields.hasEditData.id) : retro_fields.editTabData.id
        if (id && retro_fields.hasEditSupplier === null) {
            payload = {
                "supplierName": supplier.supplier_selected ? supplier.supplier_selected.name : ""
            }
            // payload = {
            //     "supplier": supplier.supplier_selected ? {
            //         id: supplier.supplier_selected ? supplier.supplier_selected.id : ""
            //     } : "",
            //     included,
            //     "retroDiscountScheme": id
            // }
        } else if (retro_fields.hasEditSupplier !== null) {
            payload = {
                "supplierName": supplier.supplier_selected ? supplier.supplier_selected.name : ""
            }
            // payload = {
            //     "id": retro_fields.hasEditSupplier.id,
            //     "supplier": supplier.supplier_selected ? {
            //         id: supplier.supplier_selected ? supplier.supplier_selected.id : ""
            //     } : "",
            //     included,
            //     "retroDiscountScheme": retro_fields.hasEditSupplier.retroDiscountScheme
            // }
        }

        try {
            setState({ ...state, pageLoader: true })
            // const url = ((retro_fields.hasEditData !== null || retro_fields.editTabData !== null) && retro_fields.hasEditSupplier === null) ? 'addsuppliers' : 'updatesuppliers';

            const { data } = await axios.post(`/ImportedRetroDiscount/addSupplier/${id}`, payload);
            // const { data } = url === 'addsuppliers' ? await axios.post(`/retrodiscount/${id}/${url}`, payload) : await axios.put(`/retrodiscount/${id}/${url}`, payload);
            // console.log(data);
            if (data) {
                onSubmitDate({ text: data.message, type: "success" })
                setState({ ...state, pageLoader: false })
                handleClose()
            }

        } catch (error) {
            const { response: { data, status } } = error;
            if (!data) {
                onSubmitDate({ text: error.message, type: "error" })
            } else {
                onSubmitDate({ text: data.message, type: "error" })
            }
            setState({ ...state, pageLoader: false })
            handleClose()
        }
    }

    useEffect(() => {
        if (retro_fields.hasEditSupplier !== null) {
            dispatch(setMemberCode({
                key: 'smaSupplierNameOnModal',
                value: retro_fields.hasEditSupplier.supplier
            }))
            // setIncluded(retro_fields.hasEditSupplier.included)
            setState({ ...state, supplierDetail: retro_fields.hasEditSupplier.supplier })
        }
    }, [])


    // const hideSupplierModal = (params) => {
    //     setState({ ...state, supplierListModal: params === false ? params : true })
    // }
    // const resetFilter = () => {
    //     dispatch(setMemberCode({
    //         key: 'smaSupplierNameOnModal',
    //         value: ""
    //     }))
    //     dispatch(getIsError({ key: 'supplierDetail', value: true }))
    //     setState({...state, supplierDetail: null})
    // }
    // const CommonPopUpModal = () => {
    //     setState({ ...state, supplierListModal: true })
    // }
    // const getSupplierDetail = (supplier) => {
    //     if (supplier !== '') {
    //         setState({ ...state, defaultmode: true, supplierDetail: supplier, supplierListModal: false })
    //         setIsError({...isError, supplierDetail: false})
    //         dispatch(getIsError({ key: 'supplierDetail', value: false }))
    //     }
    // }
    return (
        <>


            <Modal
                open={open}
                aria-labelledby="add-edit-price-title"
                aria-describedby="add-edit-price-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Box component="div" className="overlay" id="addEditModal">
                        {state.pageLoader && <PageLoader />}
                        <div className={`overlay-box ${size ? size : ''}`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-header text-center"> Supplier
                                        <CancelIcon className="closeBtn" onClick={handleClose} />
                                    </div>
                                    <div className="overlay-content">
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            <FormLabel htmlFor="type" className="fontSemiBold mandatory">Supplier</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Autocomplete
                                                                defaultValue={supplier.supplier_selected ? supplier.supplier_selected : null}
                                                                id="supplier_search"
                                                                options={supplier.supplier_options}
                                                                getOptionLabel={(option) => option.name || ""}
                                                                componentsProps={{
                                                                    paper: {
                                                                        sx: {
                                                                            fontSize: '0.85rem'
                                                                        }
                                                                    }
                                                                }}
                                                                onChange={(event, newValue) => {
                                                                    (isError['supplier_selected'] === null || isError['supplier_selected'] === "") ? setIsError({ ...isError, supplier_selected: true }) : setIsError({ ...isError, supplier_selected: false })
                                                                    if (event.target.value !== undefined) {
                                                                        setState({ ...state, defaultmode: true })
                                                                        setSupplier({ ...supplier, supplier_selected: newValue })
                                                                        setIsError({ ...isError, supplier_selected: false })
                                                                    } else {
                                                                        setIsError({ ...isError, supplier_selected: true })
                                                                    }
                                                                }}
                                                                renderInput={(params) =>
                                                                    <FormControl size={'small'} fullWidth>
                                                                        <div className="input-group">
                                                                            <TextField {...params}
                                                                                size="small"
                                                                                id="supplier"
                                                                                aria-describedby="supplier-error"
                                                                                error={isError.supplier_selected}
                                                                            />
                                                                            {isError.supplier_selected && (<FormHelperText className='errorHelperTxt' id="supplier-error">
                                                                                Please select supplier
                                                                            </FormHelperText>)}
                                                                        </div>
                                                                    </FormControl>
                                                                }
                                                            />
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            {/* <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            <FormLabel htmlFor="included" className="fontSemiBold">Include/Exclude</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <div className="input-group">
                                                                <Checkbox
                                                                    checked={included}
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                />
                                                            </div>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item> */}
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            &nbsp;
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Grid container direction="row" alignItems="center">
                                                                <Button
                                                                    variant="contained"
                                                                    type="submit"
                                                                    sx={{ marginRight: '15px' }}
                                                                    //disabled={((retro_fields.hasEditSupplier && retro_fields.hasEditSupplier.confirmed === true) ? true : false) || state.defaultmode === false}
                                                                    // eslint-disable-next-line max-len
                                                                    disabled={((retro_fields.hasEditData !== null || retro_fields.editTabData !== null) && retro_fields.hasEditSupplier) ? isError.supplier_selected : (state.formValid || state.defaultmode === false)}
                                                                >Save</Button>
                                                                <Button variant="outlined" onClick={handleSelf}>Cancel</Button>
                                                            </Grid>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* {
                            state.supplierListModal && <CommonPopUp
                                onShow={state.supplierListModal}
                                size="large_overlay"
                                supplierMemberCode={{
                                    title: 'Supplier Details',
                                    type: 'supplier'
                                }}
                                onHide={hideSupplierModal}
                                memberaccount={getSupplierDetail}
                            />
                        } */}
                    </Box>
                </form>

            </Modal>
        </>
    );
}

export default AddEditSupplier;