import { Container, Box, Modal, Button, Grid, Checkbox, Popper, Grow, Paper, ClickAwayListener, MenuList, ButtonGroup } from '@mui/material'
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TableFilters } from "../../components/shared/data-table/TableFilters";
import { useEffect, useState } from "react";
import { InputText } from "../../components/shared/form/InputText";
import { } from '@mui/material';
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange, filterResetFlagChange } from "../../store/slices/externalFilter";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import AddIcon from '@mui/icons-material/Add';
import { warningModal, handleAddEditModal } from "../../store/slices/modals";
import WarningModal from "../../components/shared/modals/WarningModal";
import { setRowPage, resetDataGrid, setPageNo } from '../../store/slices/dataGrid';
import { useLayoutEffect } from "react";
import { DataTableAggregationGroup } from "../../components/shared/data-table/DataTableAggregationGroup";
import { setHasEditData, hasTabEdit, aggregationGroupInput } from "../../store/slices/aggregationGroup";
import AddEditAggregationDetail from "./AddEditAggregationGroup/AddEditAggregationDetail";

const IconMenuLink = ({ params }) => {
    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { showAddEditModal, aggregation_group_fields } = useSelector((state) => {
        return {
            showAddEditModal: state.modalActions.hideAddEditModal,
            editTabData: state.aggregationGroups.editTabData,
            aggregation_group_fields: state.aggregationGroups
        }
    });
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'edit_aggregation_group') {
            dispatch(handleAddEditModal({
                showAddEdit: !showAddEditModal
            }))
            dispatch(hasTabEdit({ editTab: false }))
            setEditPDClData(row);
        } 
        setAnchorEl(null);
    };
    const setEditPDClData = async (row) => {
        
        dispatch(setHasEditData(row))
        dispatch(aggregationGroupInput({
            key: 'aggregation_group_name',
            value: row.name
        }))
    }
    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'edit_aggregation_group')}>Edit</MenuItem>

        </Menu>
    </>)
}
function AggregationGroup() {
    
    const columns = [
        {
            headerName: "ID",
            field: "id",
            flex: 1,
            minWidth: 200
        },
        {
            headerName: "Name",
            field: "name",
            flex: 1,
            width: 170
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} />;
            }
        }
    ];
   
    const [state, setState] = useState({
        //active_product: false,
        openaggregationGroupsModal: false,
        defaultGrid: {
            pagetitle: "Retro Aggregation Groups",
            pdcActionButton: true,
            hasExport: true,
            addEditButton: true,
            apiUrl: 'retroAggregationGroups/filter',
            searchUrl: 'retroAggregationGroups/filter'
        }
    });


    const { aggregation_group_name, addProductwarning, showAddEditModal } = useSelector((state) => {
        return {
            aggregation_group_name: state.externalFilter.aggregation_group_name,
            resetTable: state.dataGridTable,
            addProductwarning: state.modalActions.closeWaringAddProduct,
            showAddEditModal: state.modalActions.hideAddEditModal,
        }
    });

    const dispatch = useDispatch();

    const handleFilterInput = (value, id) => {
        //setState({ ...state, [id]: value });
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    };

    const selectedAutoComplete = (value, id) => {
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    }

    const filterBtn = () => {
        dispatch(resetDataGrid());
        dispatch(applyFilter({
            key: 'filterList',
            value: {
                "name": aggregation_group_name
            }
        }))
        dispatch(filterFlagChange(true));
        dispatch(filterResetFlagChange(false));
        dispatch(setRowPage({ pageData: 10 }));
        dispatch(setPageNo({ page: 1 }));
    }
    const resetFilter = () => {
        if (aggregation_group_name !== '') {
            dispatch(resetExternalFilter())
            dispatch(resetDataGrid());
        }
        dispatch(filterResetFlagChange(true));
    }
    const addeditAggregationGroupModal = () => {
        dispatch(handleAddEditModal({
            showAddEdit: !showAddEditModal
        }))
    }
    const hideNewAggregationGroupModal = (event) => {
        setState({ ...state, openaggregationGroupsModal: false })
    }

    useEffect(() => {
        /* load first first time */
        filterBtn();
    }, []);

    return (
        <>
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Retro Aggregation Groups" />
                <Box sx={{ marginBottom: 2.5 }}>
                    <TableFilters
                        filterFields={<>
                            <Box component="div"><InputText onChange={handleFilterInput} maxWidth={50} label="Name" id="aggregation_group_name" size="small" /></Box>
                        </>}
                        filterBtns={
                            <>
                                <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                <Button variant="contained" onClick={filterBtn}>Apply</Button>
                            </>}>
                    </TableFilters>
                </Box>
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -2.4 }  }}>
                    {/* <div className='table-actionable'>
                        <Button variant="contained" color="secondary" size='small' startIcon={<SendIcon />}>
                            Export as Excel
                        </Button>
                    </div> */}
                    <div className='table-actionable'>
                        <Button variant="contained" size='small' onClick={(e) => addeditAggregationGroupModal(e)} startIcon={<AddIcon />}>Add New Aggregation Group</Button>
                    </div>
                </Box>
                <DataTableAggregationGroup defaultGrid={state.defaultGrid} columns={columns}>

                </DataTableAggregationGroup>
                {
                    showAddEditModal && (
                        <AddEditAggregationDetail
                            onShow={showAddEditModal} size="medium_overlay"
                            onHide={(e, reason) => hideNewAggregationGroupModal(e, reason)}

                        />
                    )
                }
                {
                    addProductwarning && (
                        <WarningModal size="small_overlay" onShow={addProductwarning} />
                    )
                }
            </Container>

        </>
    );
}

export default AggregationGroup;
