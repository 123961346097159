import { Box, CircularProgress } from '@mui/material'

function PageLoader() {

    return (
        <>
            <Box component={'div'} sx={{ display: 'flex', backgroundColor: 'rgba(255,255,255,0.25)', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100%', zIndex: '9', position: 'fixed', left: 0, top: '0' }}>
                <div className='loaderBx'>
                    <CircularProgress />
                </div>
            </Box>
        </>
    );
}

export default PageLoader;