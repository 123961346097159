import { createTheme } from '@mui/material/styles';
import color from "../../styles/themes/_colors.scss"


export const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 767,
            md: 980,
            lg: 1200,
            xl: 1536,
        },
    },
    components: {
        // Name of the component
        MuiLink: {
            defaultProps: {
                underline: 'none'
            }
        },
        MuiList: {
            styleOverrides: {
                // Name of the slot
                root: {
                    padding: '0'
                },
            },
        },
        MuiListItem: {
            defaultProps: {
                disableGutters: true
            },
            styleOverrides: {
                // Name of the slot
                root: {
                    display: 'list-item',
                    padding: '0',
                    width: 'auto'
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                // Name of the slot
                root: {
                    background: "#fff",
                    fontSize: '0.85rem'
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                // Name of the slot
                root: {
                    background: "#fff",
                    fontSize: '0.85rem'
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                // Name of the slot
                root: {
                    color: color.textColor,
                    fontWeight: "700",
                    fontSize: '0.85rem',
                    '&.fontSemiBold': {
                        fontWeight: '600'
                    }
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    borderRadius: '5px',
                    boxShadow: 'none',
                    textTransform: 'none',
                    '&:hover': {
                        boxShadow: 'none'
                    },
                    minWidth: '85px',
                },
            },
            variants: [
                {
                    props: { variant: 'outlined' },
                    style: {
                        backgroundColor: color.white
                    },
                },
                {
                    props: { color: 'secondary', size: 'small' },
                    style: {
                        backgroundColor: "#84BD00",
                        color: '#fff'
                    },
                },
                {
                    props: { color: 'tertiary', size: 'small' },
                    style: {
                        backgroundColor: "#6193E0",
                        color: '#fff'
                    },
                },
                {
                    props: { color: 'success'},
                    style: {
                        color: '#fff'
                    },
                }
            ],
        },
        MuiCheckbox: {
            styleOverrides: {
                // Name of the slot
                root: {
                    color: "#868C94",
                    fontSize: '14px',
                    padding: '4px',
                    "&.Mui-checked": {
                        color: color.checkbox,
                    },
                    "& + .MuiTypography-root": {
                        fontSize: '14px',
                    }
                },
            },
            variants: [
                {
                    props: { color: 'chk_rounded', size: 'small' },
                    style: {
                        color: "#D4D2D2"
                    },
                }
            ],
        }
    },
    palette: {
        primary: {
            main: color.primary,
            textColor: color.textColor
        },
        secondary: {
            main: "#84BD00"
        },
        tertiary: {
            main: "#6193E0"
        },
        success: {
            main: '#4caf50'
        },
        background: {
            paper: '#fff',
        },
        common: {
            black: color.black,
            red: ''
        },
        useful: {
            white: "#fff"
        },
        chk_rounded: {
            main: color.checkbox
        }
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '"Nunito"',
            'sans-serif'
        ].join(','),
        fontSize: 14,
        h3: {
            fontWeight: 700,
            fontSize: '2.2rem'
        },
        h4: {
            fontWeight: 700,
            fontSize: '1.75rem'
        },
        h5: {
            fontWeight: 700
        },
        h6: {
            fontWeight: 500
        },
        button: {
            fontSize: 14,
            fontWeight: 600
        },
    }
});
