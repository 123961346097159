import { Container, Box, Modal, Button, Grid, Checkbox } from '@mui/material'
import { TableFilters } from "../../components/shared/data-table/TableFilters";
import { useEffect, useState } from "react";
import { InputText } from "../../components/shared/form/InputText";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { } from '@mui/material';
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange, filterResetFlagChange } from "../../store/slices/externalFilter";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import AddIcon from '@mui/icons-material/Add';
import { warningModal, handleAddEditVat } from "../../store/slices/modals";
import WarningModal from "../../components/shared/modals/WarningModal";
import { setRowPage, resetDataGrid, setPageNo } from '../../store/slices/dataGrid'
import AddEditVatCodesDetails from "./AddEditVatCodes/AddEditVatCodesDetails";
import { useLayoutEffect } from "react";
import { DataTableUtilitiesService } from "../../components/shared/data-table/DataTableUtilitiesService";
import { hasEdit, setHasEditData, vcProductInput, resetVC } from "../../store/slices/vcProduct";

const IconMenuLink = ({ params }) => {
    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { showAddEditModal, vc_container } = useSelector((state) => {
        return {
            showAddEditModal: state.modalActions.hideAddEditModal,
            editData: state.vcProducts.editData,
            vc_container: state.vcProducts
        }
    });
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'edit') {
            dispatch(handleAddEditVat({
                showAddEdit: !showAddEditModal
            }))
            dispatch(hasEdit({ editTab: false }))

            setEditVatCodeData(row);
        } 
        setAnchorEl(null);
    };

    const setEditVatCodeData = async (row) => {
        dispatch(setHasEditData(row))
        dispatch(vcProductInput({
            key: 'vc_code',
            value: row.code
        }))
        dispatch(vcProductInput({
            key: 'rate_value',
            value: row.rate
        }))
        dispatch(vcProductInput({
            key: 'externalcode_value',
            value: row.externalCode
        }))
        dispatch(vcProductInput({
            key: 'desctiption_value',
            value: row.description
        }))
    }
    
    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'edit')}>Edit</MenuItem>

        </Menu>
    </>)
}

const columns = [
    {
        headerName: "ID",
        field: "id",
        flex: 1,
        minWidth: 200,
    },
    {
        headerName: "Code",
        field: "code",
        flex: 1,
        width: 180
    },
    {
        headerName: "External Code",
        field: "externalCode",
        flex: 1,
        width: 170
    },
    {
        headerName: "Description",
        field: "description",
        flex: 1,
        width: 180
    },
    {
        headerName: "Rate",
        field: "rate",
        flex: 1,
        width: 170
    },
    {
        headerName: "Action",
        field: "action",
        resizable: false,
        sortable: false,
        width: 65,
        renderCell: (params) => {
            return <IconMenuLink params={params} />;
        }
    }
];
function VatCode() {
    const [state, setState] = useState({
        openVatCodeModal: false,
        defaultGrid: {
            pagetitle: "Vat Codes",
            brandActionButton: true,
            hasExport: true,
            addEditButton: true,
            apiUrl: 'vatcodes/filter',
            searchUrl: 'vatcodes/filter'
        }
    });


    const { addProductwarning, setAddEditVatCodes } = useSelector((state) => {
        return {
            addProductwarning: state.modalActions.closeWaringAddProduct,
            setAddEditVatCodes: state.modalActions.hideAddEditVat
        }
    });

    const dispatch = useDispatch();

    const handleFilterInput = (value, id) => {
        //setState({ ...state, [id]: value });
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    };


    // eslint-disable-next-line max-len
    const { vat_name } = useSelector((state) => {
        return {
            vat_name: state.externalFilter.vat_name
        }
    });
    const filterBtn = () => {
        dispatch(resetDataGrid());
        dispatch(applyFilter({
            key: 'filterList',
            value: {
                "description": vat_name
            }
        }))
        dispatch(filterFlagChange(true));
        dispatch(filterResetFlagChange(false));
        dispatch(setRowPage({ pageData: 10 }));
        dispatch(setPageNo({ page: 1 }));
    }
    const resetFilter = () => {
        if ((vat_name) !== '') {
            dispatch(resetExternalFilter())
            dispatch(resetDataGrid());
        }
            dispatch(filterResetFlagChange(true));
    }
    const addVatCodesModal = () => {
        dispatch(resetVC())
        dispatch(handleAddEditVat({
            showAddEdit: !setAddEditVatCodes
        }))
    }
    const handleVatCodesModal = (event) => {
        setState({ ...state, openVatCodeModal: false })
    }

    useEffect(() => {
        /* load first first time */
        filterBtn();
    }, []);

    return (
        <>
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Vat Codes" />
                <Box sx={{ marginBottom: 2.5 }}>
                    <TableFilters
                        filterFields={<>
                            <Box component="div"><InputText onChange={handleFilterInput} maxWidth={50} label="Description" id="vat_name" size="small" /></Box>
                        </>}
                        filterBtns={
                            <>
                                <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                <Button variant="contained" onClick={filterBtn}>Apply</Button>
                            </>}>
                    </TableFilters>
                </Box>
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -2.4 }  }}>
                    {/* <div className='table-actionable'>
                        <Button variant="contained" color="secondary" size='small' startIcon={<SendIcon />}>
                            Export as Excel
                        </Button>
                    </div> */}
                    <div className='table-actionable'>
                        <Button variant="contained" size='small' onClick={(e) => addVatCodesModal(e)} startIcon={<AddIcon />}>New Vat Code</Button>
                    </div>
                </Box>


            <DataTableUtilitiesService defaultGrid={state.defaultGrid} columns={columns}>

                </DataTableUtilitiesService>
                {
                    setAddEditVatCodes && (
                        <AddEditVatCodesDetails
                            onShow={setAddEditVatCodes} size="medium_overlay"
                            onHide={(e, reason) => handleVatCodesModal(e, reason)}

                        />
                    )
                }
                {
                    addProductwarning && (
                        <WarningModal size="small_overlay" onShow={addProductwarning} />
                    )
                }

            </Container>

        </>
    );
}

export default VatCode;
