import { Container, Box, Modal, Button, Grid, Checkbox, Popper, Grow, Paper, ClickAwayListener, MenuList, ButtonGroup, FormLabel, FormControl, TextField, FormControlLabel, Autocomplete, FormHelperText, getTableRowUtilityClass } from '@mui/material'
import IconButton from "@mui/material/IconButton";
import LoadingButton from '@mui/lab/LoadingButton';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TableFilters } from "../../../components/shared/data-table/TableFilters";
import { useEffect, useState } from "react";
import { } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useLayoutEffect } from "react";
import { hasTabEdit, memberInput, getIsError, setMemberOutletEdit, updateNextBtnStatus, resetMemberProgramme, setHasMemberProgrammeEditData, memberBasicInput, setAutocomplete, setMemberProgrammeEdit, resetMember, setMemberDate } from "../../../store/slices/memberBclValues";
import PageLoader from "../../../components/shared/PageLoader";
import { DataTableMember } from "../../../components/shared/data-table/DataTableMember";
import Typography from '@mui/material/Typography';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import axiosMember from '../../../services/utility/axios-member';
import SnackBarDialog from "../../../components/shared/SnackBarDialog";
import { Routes, Route, useNavigate } from 'react-router-dom';
import { DataTableClientSide } from '../../../components/shared/data-table/DataTableClientSide';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { filterFlagChange } from '../../../store/slices/externalFilter';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import refreshLambda from '../../../services/utility/refresh-lambda';
import PageLoaderStyle2 from '../../../components/shared/PageLoaderStyle2';

var c_memberOutletId = 0;
var outletendDate = null;

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    marginBottom: '50px'
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'revert',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {

    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(4),
    borderTop: '1px solid rgba(0, 0, 0, .125)',

}));



const MemberOutlets = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { showAddEditModal, general_product_fields, member_fields, handleWarning } = useSelector((state) => {
        return {
            handleWarning: state.modalActions.closeWaringAddProduct,
            showAddEditModal: state.modalActions.hideAddEditModal,
            editTabData: state.generalProduct.editTabData,
            general_product_fields: state.generalProduct,
            member_fields: state.memberBclEntry

        }
    });

    const [state, setState] = useState({
        active_member: false,
        openNewProductModal: false,
        pageLoader: false,
        loader: false,
        level2: false,
        level1: true,
        formValid: false,
        defaultmode: false,
        location: 'Member Outlets',
        locationlabel: "Programme",
        member_id: member_fields.member_id || 0,
        member_accountid: member_fields.member_accountid || 0,
        member_outletid: member_fields.member_outletid || 0,
        outlet_endDate: null,
        pageLoader2: false,
        defaultGrid: {
            productActionButton: true,
            hasExport: true,
            addEditButton: true,
            // apiUrl: `/members/${member_fields.member_id ? member_fields.member_id : 0}/memberAccounts/${member_fields.member_accountid ? member_fields.member_accountid : 0}/memberOutlets/${member_fields.member_outletid ? member_fields.member_outletid : 0}/memberProgrammes`,
            // searchUrl: 'members/filter'
        }
    });

    useEffect(() => {

        // console.log(outletendDate)
        const fetchMyAPI = async () => {
            if (member_fields.editMemberOutletsTab && member_fields.member_accountid !== 0 && member_fields.member_outletid !== 0) {
                setState({ ...state, pageLoader: true })
                axiosMember.get(`members/${member_fields.member_id}/memberAccounts/${member_fields.member_accountid}/memberOutlets/${member_fields.member_outletid}/memberProgrammes`)
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            setRows(data);
                            setState({ ...state, pageLoader: false });
                        }
                    })
                    .catch(function (error) {
                        // handle error 
                        const { response: { data, status }, message } = error;
                        if (data.message !== "No Member Programme data found") {
                            setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "No member programme records found!", type: "error", });
                        }
                        setState({ ...state, pageLoader: false })
                    });
            }
            else {
                setRows([]);
                setState({ ...state, pageLoader: false });
                c_memberOutletId = 0;
                outletendDate = null;
            }
            if (member_fields.member_tier.member_tier_options.length === 0) {
                axiosMember.get('/memberTier/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            dispatch(setAutocomplete({
                                parent: 'member_tier',
                                key: 'member_tier_options',
                                value: unique
                            }));
                        }
                        setState({ ...state, pageLoader: false })
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                        setState({ ...state, pageLoader: false })
                    });
            }

        }
        if (member_fields.editMembersTab === false && member_fields.hasMemberEditData === null && member_fields.member_accountid === 0) {
            // console.log(member_fields);
            navigate('/suppliers-and-members/members');
            // console.log("Page is Reloaded");
        }
        else {
            fetchMyAPI();
        }
        setState({ ...state, pageLoader: false });

    }, [])

    const IconMenuLink = ({ params }) => {
        const { row } = params;
        const [anchorEl, setAnchorEl] = useState(null);
        const open = Boolean(anchorEl);




        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = (event, action) => {
            event.preventDefault();
            if (action === 'edit_member') {
                // dispatch(handleAddEditModal({
                //     showAddEdit: !showAddEditModal
                // }))
                // dispatch(hasTabEdit({ editTab: false }))

                // setEditGeneralData(row);
                setEditMemberProgrammeData(row);
            }
            setAnchorEl(null);
        };
        const setEditMemberProgrammeData = async (row) => {

            setState({ ...state, pageLoader: true });

            dispatch(setHasMemberProgrammeEditData(row));

            dispatch(memberBasicInput({
                key: 'member_programmeid',
                value: row.id
            }));

            dispatch(memberBasicInput({
                key: 'member_programme_jd',
                value: row.joinDate ? moment(row.joinDate, 'DD/MM/YYYY') : ""
            }));

            dispatch(memberBasicInput({
                key: 'member_programme_ld',
                value: row.leaveDate ? moment(row.leaveDate, 'DD/MM/YYYY') : ""
            }));


            dispatch(setAutocomplete({
                parent: 'member_programme',
                key: 'programme_selected',
                value: row.programme
            }))

            dispatch(setMemberProgrammeEdit({ editMemberProgrammesTab: true, member_programmeid: row.id }));


            try {


                const { data } = await axiosMember.get(`members/${member_fields.member_id}/memberAccounts/${member_fields.member_accountid}/memberOutlets/${member_fields.member_outletid}`);

                if (data) {

                    dispatch(memberBasicInput({
                        key: 'member_outletid',
                        value: data.id
                    }));

                    dispatch(memberBasicInput({
                        key: 'member_outlet_name',
                        value: data.name
                    }));

                    dispatch(memberBasicInput({
                        key: 'member_outlet_oo',
                        value: data.orwellOutlet
                    }));

                    dispatch(setAutocomplete({
                        parent: 'member_tier',
                        key: 'member_tier_selected',
                        value: data.memberTier ? data.memberTier : null
                    }))


                    dispatch(filterFlagChange(true));
                    navigate('/suppliers-and-members/members/member-programmes');

                }
                else {
                    setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" });
                    setState({ ...state, pageLoader: false });
                }

            }
            catch (error) {
                const { response: { data, status }, message } = error;
                if (status === 500) {
                    setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
                } else {
                    setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
                }
                setState({ ...state, pageLoader: false });
            }



        }
        return (<>
            <IconButton
                aria-label="more"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                id={row.id + 1}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id={row.id}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                className="actionMenuBx"
            >
                <MenuItem onClick={(e) => handleClose(e, 'edit_member')}>Edit</MenuItem>
            </Menu>
        </>)
    }

    const [expanded, setExpanded] = React.useState('panel1');

    const handleAccChange = (panel) => (event, newExpanded) => {
        event.preventDefault()
        setExpanded(newExpanded ? panel : false);
    };
    const handleChange = (panel) => (event, newExpanded) => {
        event.stopPropagation();
        setExpanded(newExpanded ? panel : false);
    };

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
        vertical: '',
        horizontal: '',
        duration: 0
    });

    const [rows, setRows] = useState([]);
    const columns = [
        {
            headerName: "Programmes",
            field: "programme",
            flex: 1.5,
            minWidth: 200,
            valueGetter: (params) => {
                return params.row.programme.name
            }
        },
        {
            headerName: "Join Date",
            field: "joinDate",
            flex: 1.5,
            minWidth: 200,
        },
        {
            headerName: "Leave Date",
            field: "leaveDate",
            flex: 1.5,
            minWidth: 200,
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} />;
            },
        }
    ];


    const handleBClick = (event, location) => {
        event.preventDefault();
        switch (location) {
            case "Member":
                dispatch(resetMember());
                dispatch(filterFlagChange(true));
                navigate('/suppliers-and-members/members');
                break
            case "Member Details":
                navigate('/suppliers-and-members/members/member-details');
                break
            case "Member Accounts":
                navigate('/suppliers-and-members/members/member-accounts');
                break
            case "Member Outlets":
                navigate('/suppliers-and-members/members/member-outlets');
                break
            default:
                navigate('/suppliers-and-members/members');
        }
    }


    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit"  >
            Suppliers & Members
        </Link>,
        <Link underline="hover" key="1" color="inherit" onClick={(e) => handleBClick(e, 'Member')} >
            Members
        </Link>,
        <Link underline="hover" key="1" color="inherit" onClick={(e) => handleBClick(e, 'Member Details')} >
            Account Details
        </Link>,
        <Link underline="hover" key="2" color="inherit" onClick={(e) => handleBClick(e, 'Member Accounts')} >
            Member Accounts
        </Link>,
        <Typography key="3" color="text.primary">
            Member Outlets
        </Typography>
    ];

    const handleInputChange = (event) => {
        event.preventDefault();
        // setState({ ...state, defaultmode: true }) 
        const { value, name } = event.target;

        if (name === 'member_outlet_oo') {
            dispatch(memberBasicInput({
                key: name,
                value
            }))
        }
        else {
            dispatch(memberInput({
                key: name,
                value
            }))
        }

        // dispatch(generalProductInput({
        //     key: name,
        //     value
        // }))
    }

    const handleLeaveDate = (event) => {
        // setState({ ...state, defaultmode: true })
        if (event !== null) {
            dispatch(setMemberDate({
                key: 'member_outlet_enddate',
                value: moment(event, 'DD/MM/YYYY')
            }))
        }
        else {
            dispatch(setMemberDate({
                key: 'member_outlet_enddate',
                value: null
            }))
        }
    }



    const handleCancel = (event) => {
        dispatch(filterFlagChange(true));
        navigate('/suppliers-and-members/members/member-accounts');
    }

    const handleDashboard = (event) => {
        localStorage.setItem("memberfilterflag", "true");
        dispatch(filterFlagChange(true));
        navigate('/suppliers-and-members/members');
    }

    useEffect(() => {
        // console.log("Error Values: ",member_fields.isError);
        const { member_outlet_name, member_tier_selected } = member_fields.isError;
        // if (member_outlet_name === false && member_tier_selected === false && member_fields.member_outlet_name !== "" && member_fields.member_tier.member_tier_selected !== "" && member_fields.member_tier.member_tier_selected !== null) 
        if ((member_outlet_name === false || member_outlet_name === undefined) && member_tier_selected === false) {
            // console.log("error if", member_outlet_name, member_tier_selected)

            setState({ ...state, formValid: false })
        } else {
            // console.log("error else", member_outlet_name, member_tier_selected)
            setState({ ...state, formValid: true })
        }
    }, [member_fields.isError]);

    // useEffect(() => {
    //     //console.log("change fields");
    //     const { member_outlet_name, member_tier } = member_fields;

    //     if (member_fields.memberOutletsTab !== true) {

    //         if (member_outlet_name === "" || (member_tier['member_tier_selected'] === "" || member_tier['member_tier_selected'] === null)) {
    //             setState({ ...state, formValid: true })
    //         }
    //         else {
    //             setState({ ...state, formValid: false})
    //         }
    //     }

    // }, [member_fields.member_outlet_name, member_fields.member_tier]);

    // useEffect(() => {
    //     // console.log("change fields");
    //     if (member_fields.member_outlet_name === "" && member_fields.isError.member_outlet_name === false) {
    //         setState({ ...state, formValid: true })
    //     } else if (member_fields.member_tier.member_tier_selected === null && member_fields.isError.member_tier_selected === false) {
    //         setState({ ...state, formValid: true })
    //     } else {
    //         setState({ ...state, formValid: false })
    //     }

    // }, [member_fields]);

    const handleSubmit = async (event) => {

        event.preventDefault();

        if (member_fields.member_outlet_name === "" && member_fields.isError.member_outlet_name === false) {
            dispatch(getIsError({ key: 'member_outlet_name', value: true }))
        } else if (member_fields.member_tier.member_tier_selected === null && member_fields.isError.member_tier_selected === false) {
            dispatch(getIsError({ key: 'member_tier_selected', value: true }))
        } else {
            let memberData = null;
            if (!state.formValid && member_fields.member_id !== 0 && member_fields.member_accountid !== 0 && member_fields.member_tier.member_tier_selected !== null) {
                memberData = {
                    "name": member_fields.member_outlet_name,
                    "orwellOutlet": member_fields.member_outlet_oo || 0,
                    "memberTier": member_fields.member_tier.member_tier_selected,
                    "endDate": member_fields.member_outlet_enddate ? moment(member_fields.member_outlet_enddate).format('DD/MM/YYYY') : null
                }
                try {
                    setState({ ...state, loader: true })
                    const { data, status } = member_fields.hasMemberOutletsEditData ? await axiosMember.put(`members/${member_fields.member_id}/memberAccounts/${member_fields.member_accountid}/memberOutlets/${member_fields.hasMemberOutletsEditData.id}`, { ...memberData })
                        : c_memberOutletId !== 0 ? await axiosMember.put(`members/${member_fields.member_id}/memberAccounts/${member_fields.member_accountid}/memberOutlets/${c_memberOutletId}`, { ...memberData })
                            : await axiosMember.post(`members/${member_fields.member_id}/memberAccounts/${member_fields.member_accountid}/memberOutlets`, { ...memberData });
                    if (data.success === true) {
                        if (data.resultMap) {
                            setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Member Outlet Added Successfully!", type: "success", });
                            setState({ ...state, loader: false, formValid: false, member_outletid: data.resultMap.memberOutletId, defaultmode: false, outlet_endDate: member_fields.member_outlet_enddate });
                            dispatch(setMemberOutletEdit({ editMemberOutletsTab: true, member_outletid: data.resultMap.memberOutletId }));
                            dispatch(updateNextBtnStatus({ key: 'disable_mpBtn', value: false }));
                            c_memberOutletId = data.resultMap.memberOutletId;
                            outletendDate = member_fields.member_outlet_enddate ? moment(member_fields.member_outlet_enddate).format('DD/MM/YYYY') : null;
                        }
                        else {
                            if (data.message) {
                                setState({ ...state, loader: false, formValid: false, defaultmode: false });
                                outletendDate = member_fields.member_outlet_enddate ? moment(member_fields.member_outlet_enddate).format('DD/MM/YYYY') : null;
                                dispatch(updateNextBtnStatus({ key: 'disable_mpBtn', value: false }));
                                setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Member Outlet Edited Successfully!", type: "success", });
                            }
                        }
                        console.log(state.outlet_endDate, outletendDate)
                    }
                    else {
                        setSnackBar({ ...snackBar, show: true, text: "Something went wrong! Member Data missing, go one step back", type: "error", });
                    }
                } catch (error) {
                    const { response: { data, status } } = error;
                    dispatch(setMemberOutletEdit({ editMemberOutletsTab: false }))
                    // dispatch(hasTabEdit({ editTab: true }))
                    setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error", })
                    setState({ ...state, loader: false, formValid: false })
                }
            }
            else {
                // setSnackBar({ ...snackBar, show: true, text: "Something went wrong! Member Data missing, go one step back", type: "error", });
                setState({ ...state, loader: false, formValid: true })
            }
        }

    }



    const goNextPage = async () => {

        try {
            dispatch(resetMemberProgramme());
            dispatch(setHasMemberProgrammeEditData(null));
            setState({ ...state, pageLoader: true });
            const { data } = await axiosMember.get(`members/${member_fields.member_id}/memberAccounts/${member_fields.member_accountid}/memberOutlets/${member_fields.member_outletid}`);

            if (data) {

                dispatch(memberBasicInput({
                    key: 'member_outletid',
                    value: data.id
                }));

                dispatch(memberBasicInput({
                    key: 'member_outlet_name',
                    value: data.name
                }));

                dispatch(memberBasicInput({
                    key: 'member_outlet_oo',
                    value: data.orwellOutlet
                }));

                dispatch(setAutocomplete({
                    parent: 'member_tier',
                    key: 'member_tier_selected',
                    value: data.memberTier ? data.memberTier : null
                }))


                dispatch(filterFlagChange(true));
                navigate('/suppliers-and-members/members/member-programmes');
            }
            else {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" });
                setState({ ...state, pageLoader: false });
            }

        }
        catch (error) {
            const { response: { data, status }, message } = error;
            if (status === 500) {
                setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
            } else {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
            }
            setState({ ...state, pageLoader: false });
        }

    }
    const autoCompletehandleSelection = (event, newValue, parentname, name) => {
        // console.log(event.target.value, newValue);
        // setState({ ...state, defaultmode: true })
        if (event.target.newValue !== "" || event.target.newValue !== null) {
            dispatch(setAutocomplete({
                parent: parentname,
                key: name,
                value: newValue
            }))
        }
        else {
            dispatch(setAutocomplete({
                parent: parentname,
                key: name,
                value: null
            }))
        }

    }
    const lambdaWarmer = () => {
        onReprocessReverse()
    }
    const onReprocessReverse = async () => {
        try {
            setState({ ...state, pageLoader2: true });
            const { data } = await refreshLambda.get('/warmup');

            if (data) {
                setSnackBar({ ...snackBar, vertical: 'top', duration: 6000, horizontal: 'center', show: true, text: "System is warming up, please check after 9 minutes", type: "info" })
            }
            setState({ ...state, pageLoader2: false });
        } catch (error) {
            const { response: { data, status } } = error;
            if (status === 500) {
                setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
            } else {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
            }
            setState({ ...state, pageLoader2: false });
        }
    }
    return <>

        <Container disableGutters={true} maxWidth={false} >
            {state.pageLoader && <PageLoader />}
            {state.pageLoader2 && <PageLoaderStyle2 />}
            <Box component="div" className='breadcrumbNavs'>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <Breadcrumbs component="div" separator="›" aria-label="breadcrumb">
                            {breadcrumbs}
                        </Breadcrumbs>
                        <div className='pageHeading'>
                            <h1>{member_fields.hasMemberOutletsEditData ? member_fields.hasMemberOutletsEditData.name : "Member Outlets"}</h1>
                        </div>
                    </div>
                    <Box className='lambdaRefreshBtn'>
                        <Box component={'a'} sx={{ display: 'flex', alignItems: 'center' }} onClick={lambdaWarmer}>
                            <LocalFireDepartmentIcon color="primary" /> <span style={{ marginLeft: '5px', color: '#B253AE' }}>Warm System</span>
                        </Box>
                    </Box>
                </Box>

            </Box>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()} >
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography sx={{ fontWeight: "600", fontSize: '14px', color: "#15191E" }}>{member_fields.hasMemberOutletsEditData ? member_fields.hasMemberAccountsEditData ? "Edit Member Outlet of " + member_fields.hasMemberAccountsEditData.name : "Edit Member Outlet" : "Add Member Outlet"}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <form onSubmit={(e) => { handleSubmit(e) }} >
                        <Box sx={{ flexGrow: 1, paddingX: 2 }} >
                            <Grid container columnSpacing={2} sx={{ paddingLeft: '20px', paddingRight: '20px' }}>
                                <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                                    <Item>
                                        <Grid container alignItems="center">
                                            <Grid item xs={12} md={3} className="formLabelCntr">
                                                <Item>
                                                    <FormLabel htmlFor="member_outlet_name" className="fontSemiBold mandatory">Outlet Name</FormLabel>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} md={9}>
                                                <Item>
                                                    <div className="input-group">
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                inputProps={{ maxLength: 200 }}
                                                                size="small"
                                                                id="member_outlet_name"
                                                                aria-describedby="member_outlet_name_error"
                                                                onChange={(e) => handleInputChange(e)}
                                                                value={member_fields.member_outlet_name ? member_fields.member_outlet_name : ''}
                                                                error={member_fields.isError.member_outlet_name}
                                                                name="member_outlet_name"

                                                            />
                                                            {member_fields.isError.member_outlet_name && (<FormHelperText className='errorHelperTxt' id="member_outlet_name_error">
                                                                Please enter Outlet Name
                                                            </FormHelperText>)}
                                                        </FormControl>

                                                    </div>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                                    <Item>
                                        <Grid container alignItems="center">
                                            <Grid item xs={12} md={2} className="formLabelCntr">
                                                <Item>
                                                    <FormLabel htmlFor="member_outlet_oo" className="fontSemiBold">CRM Outlet</FormLabel>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} md={9}>
                                                <Item>
                                                    <div className="input-group">
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                inputProps={{ maxLength: 100 }}
                                                                size="small"
                                                                id="member_outlet_oo"
                                                                aria-describedby="memberbcl_onwell_outlet_error"
                                                                onChange={(e) => handleInputChange(e)}
                                                                value={member_fields.member_outlet_oo}
                                                                name="member_outlet_oo"
                                                                type="number"
                                                                onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}

                                                            />

                                                        </FormControl>
                                                    </div>
                                                </Item>

                                            </Grid>
                                        </Grid>
                                    </Item>
                                </Grid>
                            </Grid>
                            <Grid container columnSpacing={2} sx={{ paddingLeft: '20px', paddingRight: '20px', marginBottom: 4 }}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Item>
                                        <Grid container alignItems="center">
                                            <Grid item xs={12} md={3} sx={{ textAlign: 'right', display: { xs: 'none', md: 'block' } }}>
                                                <Item>
                                                    <FormLabel htmlFor="general_member_tier" className="fontSemiBold mandatory">Purchasing Tier</FormLabel>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={9}>
                                                <Item>
                                                    <Autocomplete
                                                        disabled={member_fields.hasMemberOutletsEditData ? member_fields.hasMemberOutletsEditData.invoiceProcessed : false}
                                                        defaultValue={member_fields.member_tier.member_tier_selected}
                                                        id="general_member_tier_search"
                                                        options={member_fields.member_tier.member_tier_options}
                                                        getOptionLabel={(option) => option.name || ""}
                                                        componentsProps={{
                                                            paper: {
                                                                sx: {
                                                                    fontSize: '0.85rem'
                                                                }
                                                            }
                                                        }}
                                                        onChange={(event, newValue) => autoCompletehandleSelection(event, newValue, 'member_tier', 'member_tier_selected')}
                                                        renderInput={(params) =>
                                                            <FormControl size={'small'} fullWidth>
                                                                <div className="input-group">
                                                                    <TextField {...params}
                                                                        size="small"
                                                                        id="general_member_tier"
                                                                        aria-describedby="general_member_tier-error"
                                                                        error={member_fields.isError.member_tier_selected}
                                                                    />
                                                                    <FormHelperText className='errorHelperTxt' id="member_leavedate_error">*{ member_fields.isError.member_tier_selected ? 'Please select Purchasing Tier' : 'Cannot be edited once invoice is processed.' }</FormHelperText>
                                                                </div>
                                                            </FormControl>
                                                        }
                                                    />
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} md={2} className="formLabelCntr">
                                            <Item>
                                                <FormLabel htmlFor="member_leavedate" className="fontSemiBold">End Date</FormLabel>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={12} md={9}>
                                            <Item sx={{ paddingLeft: '10px', paddingRight: '15px' }}>
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <DesktopDatePicker
                                                        value={member_fields.member_outlet_enddate}
                                                        onChange={(e) => { handleLeaveDate(e) }}
                                                        name="member_leavedate"
                                                        inputFormat="DD/MM/YYYY"
                                                        disabled={member_fields.hasMemberOutletsEditData ? member_fields.hasMemberOutletsEditData.endDate && member_fields.hasMemberOutletsEditData.endDate !== null ? true
                                                            : outletendDate !== null ? true : false : outletendDate !== null ? true : false}

                                                        // eslint-disable-next-line max-len
                                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                                            <Box component="div" className='date-picker-input'>
                                                                <FormControl size={'small'} fullWidth>
                                                                    <TextField size="small" id="member_leavedate"
                                                                        ref={inputRef} {...inputProps}
                                                                        placeholder=""
                                                                        aria-describedby="member_leavedate_error"
                                                                        autoComplete="off"
                                                                    />
                                                                    <FormHelperText className='errorHelperTxt' id="member_leavedate_error">*End Date cannot be edited after saving</FormHelperText>                                                                    
                                                                    <Box className='date-picker-icon'>
                                                                        {InputProps?.endAdornment}
                                                                    </Box>
                                                                </FormControl>
                                                            </Box>
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </Item>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ marginTop: '20px' }}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Item>
                                        <Grid container direction="row" alignItems="center" justifyContent="center">
                                            <LoadingButton
                                                loading={state.loader}
                                                loadingPosition="center"
                                                endIcon=""
                                                variant="contained"
                                                type="submit" sx={{ marginRight: '15px' }}
                                                disabled={state.formValid}

                                            >{member_fields.editMemberOutletsTab && member_fields.member_outletid !== 0 ? "Update" : "Save"}</LoadingButton>
                                            <Button variant="outlined" onClick={handleCancel}>Go Back</Button>
                                            <Button variant="outlined" sx={{ marginLeft: '15px' }} onClick={handleDashboard}>Got to Dashboard</Button>
                                        </Grid>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Box>
                        {snackBar.show && <SnackBarDialog
                            duration={snackBar.duration}
                            vpositon={snackBar.vertical}
                            hposition={snackBar.horizontal}
                            onShow={snackBar.show}
                            text={snackBar.text}
                            type={snackBar.type}
                            onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                        />}
                    </form>
                </AccordionDetails>
            </Accordion>
            <Box component="div" className='exportsButtons' sx={{ marginBottom: '20px', columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' } }}>
                <div className='table-actionable'>
                    <Button variant="contained" size='small' disabled={member_fields ? member_fields.disable_mpBtn : true} onClick={(e) => goNextPage(e)} startIcon={<AddIcon />}>
                        New Member Programme
                    </Button>
                </div>
            </Box>

            <DataTableClientSide defaultGrid={state.defaultGrid} columns={columns} location={state.location} locationLabel={state.locationlabel} rows={rows ? rows : []}></DataTableClientSide>
        </Container>
    </>
}

export default MemberOutlets;