import { Checkbox, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl, RadioGroup, Radio, Autocomplete } from '@mui/material'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import axiosDiscountSchemes from '../../../../services/utility/axios-discount-schemes';
import PageLoader from '../../../../components/shared/PageLoader';
import { setDiscountSchemeState, getIsError } from '../../../../store/slices/discountScheme';
import { handleModalState, setNewPriceModal } from '../../../../store/slices/modals';
import CommonPopUp from '../../../products/addEditPdc/pdc-tabs/CommonPopUp';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import { setMemberCode } from '../../../../store/slices/supplierMemberAccounts';
import axios_member from '../../../../services/utility/axios-member';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

function AddEditmember({ onShow, size = '', onHide, onSubmitDate }) {
    
    const { discountScheme_container, sma_container } = useSelector((state) => {
        return {
            discountScheme_container: state.discountSchemes,
            sma_container: state.smas,
        }
    });
    const dispatch = useDispatch();
    
    const [state, setState] = useState({
        memberListModal: false,
        pageLoader: false,
        defaultmode: false,
        formValid: true,
        memberDetail: discountScheme_container.hasEditMember ? discountScheme_container.hasEditMember.member : null
    })

    const [included, setIncluded] = useState(true);

    const [member, setMember] = useState({
        member_options: [],
        memberDetail: discountScheme_container.hasEditMember ? {id: discountScheme_container.hasEditMember.memberId, name: discountScheme_container.hasEditMember.memberName} : null
    })
    const [isError, setIsError] = useState({
        memberDetail: false
    })

    const handleChange = (event) => {
        if(discountScheme_container.hasEditMember) {
            setState({ ...state, defaultmode: true })
        } 
        setIncluded(event.target.checked);
    };

    const [open, setOpen] = useState(onShow);

    const handleClose = () => {
        //setState({ ...state, defaultmode: false })
        dispatch(handleModalState({
            key: 'addEditMemberModal',
            value: false
        }))
        dispatch(setDiscountSchemeState({
            key: 'hasEditMember',
            value: null
        }))
        dispatch(setMemberCode({
            key: 'smaMemberCodeOnModal',
            value: ""
        }))
        //dispatch(getIsError({ key: 'dc_selected', value: false }))
    };

    useEffect(() => {
        const fetchMyAPI = async () => {
            if (member.member_options.length === 0) {
                axios_member.get('/members/look-up?pageSize=500')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data.items.filter((value, index, self) => self.findIndex((m) => m.name === value.name) === index);
                            setMember({ ...member, member_options: unique })
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
        }
        fetchMyAPI();
    }, [])

    // useEffect(() => {
    //     if (sma_container.smaMemberCodeOnModal === null || sma_container.smaMemberCodeOnModal === "") {
    //         setState({ ...state, formValid: true, defaultmode: false})
    //     } else {
    //         setState({ ...state, formValid: false, defaultmode: true})
    //     }
    // }, [sma_container.smaMemberCodeOnModal])

    // useEffect(() => {
    //     const {
    //         discountScheme_member_name
    //     } = discountScheme_container.isError
    //     if (!discountScheme_member_name) {
    //         setState({ ...state, formValid: false })
    //     } else {
    //         setState({ ...state, formValid: true })
    //     }
    // }, [discountScheme_container.isError])

    useEffect(() => {
        if (member.memberDetail === null || member.memberDetail === "") {
            setState({ ...state, formValid: true, defaultmode: false })
        } else {
            setState({ ...state, formValid: false, defaultmode: true })
        }
    }, [member.memberDetail])

    useEffect(() => {
        const {
            memberDetail
        } = isError
        if (!memberDetail) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true })
        }
    }, [isError])


    const handleSelf = () => {
        handleClose()
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (member.memberDetail === null && isError.memberDetail === false) {
            setIsError({ ...isError, memberDetail: true })
        } else {
            addPayDate()
        }

    }

    const addPayDate = async () => {
        let payload = null;
        if ((discountScheme_container.hasEditData !== null || discountScheme_container.editTabData !== null) && discountScheme_container.hasEditMember === null) {
            payload = {
                "memberId": member.memberDetail ? member.memberDetail.id : "",
                included,
                "memberName": member.memberDetail ? member.memberDetail.name : "",
            }
        } else if (discountScheme_container.hasEditMember !== null) {
            payload = {
                "id": discountScheme_container.hasEditMember.id,
                "memberId": member.memberDetail ? member.memberDetail.id : discountScheme_container.hasEditMember.memberId,
                included,
                "memberName": member.memberDetail ? member.memberDetail.name : discountScheme_container.hasEditMember.memberName,
            }
        }

        try {
            setState({ ...state, pageLoader: true })
            const { data } =  ((discountScheme_container.hasEditData !== null || discountScheme_container.editTabData !== null) && discountScheme_container.hasEditMember === null) ? await axiosDiscountSchemes.post(`/discountSchemes/${discountScheme_container.hasEditData.id || discountScheme_container.editTabData.id}/members`, payload) : await axiosDiscountSchemes.put(`/discountSchemes/${discountScheme_container.hasEditData.id || discountScheme_container.editTabData.id}/members/${discountScheme_container.hasEditMember.id}`, payload)

            if (data) {
                onSubmitDate({ text: data.message, type: "success" })
                setState({ ...state, pageLoader: false })
                handleClose()
            }

        } catch (error) {
            const { response: { data, status } } = error;
            if (!data) {
                onSubmitDate({ text: error.message, type: "error" })
            } else {
                onSubmitDate({ text: data.message, type: "error" })
            }
            setState({ ...state, pageLoader: false })
            handleClose()
        }
    }

    useEffect(() => {
        if (discountScheme_container.hasEditMember !== null) {
            dispatch(setMemberCode({
                key: 'smaMemberCodeOnModal',
                value: discountScheme_container.hasEditMember.member
            }))
            setIncluded(discountScheme_container.hasEditMember.included)
            setState({...state, memberDetail: discountScheme_container.hasEditMember.member})
        }
    }, [])


    const hideSupplierMemberModal = (params) => {
        setState({ ...state, memberListModal: params === false ? params : true })
    }
    const CommonPopUpModal = () => {
        setState({...state, memberListModal: true})
    }
    const getMemberDetail = (member) => {  
        if (member !== '') {
            setState({...state, defaultmode: true, memberDetail: member, memberListModal: false})
        } 
    }
    return (
        <>


            <Modal
                open={open}
                aria-labelledby="add-edit-price-title"
                aria-describedby="add-edit-price-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Box component="div" className="overlay" id="addEditModal">
                        {state.pageLoader && <PageLoader />}
                        <div className={`overlay-box ${size ? size : ''}`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-header text-center"> Member
                                        <CancelIcon className="closeBtn" onClick={handleClose} />
                                    </div>
                                    <div className="overlay-content">
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            <FormLabel htmlFor="type" className="fontSemiBold mandatory">Member</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                        {/* <Grid container alignItems="center">
                                                                <Grid item xs={12} md={8}>
                                                                    <Item>
                                                                        <Box component="div" className=''>
                                                                            <FormControl size={'small'} fullWidth>
                                                                                <TextField
                                                                                    inputProps={{ maxLength: 100 }}
                                                                                    size="small"
                                                                                    id="discountScheme_member_name"
                                                                                    value={(sma_container.smaMemberCodeOnModal ? sma_container.smaMemberCodeOnModal.name : '') || (discountScheme_container.hasEditMember ? discountScheme_container.hasEditMember.memberName : '')}
                                                                                    aria-describedby="discountScheme_member_name_error"
                                                                                    error={discountScheme_container.isError.discountScheme_member_name}
                                                                                    name="discountScheme_member_name"
                                                                                    disabled={true}
                                                                                />
                                                                            </FormControl>
                                                                        </Box>
                                                                    </Item>
                                                                </Grid>
                                                                <Grid item xs={12} md={4}>
                                                                    <Item>
                                                                        <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={(e) => CommonPopUpModal(e)} startIcon={<EditIcon />}></Button>
                                                                    </Item>
                                                                </Grid>
                                                            </Grid> */}
                                                        <Autocomplete
                                                                defaultValue={member.memberDetail ? member.memberDetail : null}
                                                                id="member_search"
                                                                options={member.member_options}
                                                                getOptionLabel={(option) => option.name || ""}
                                                                componentsProps={{
                                                                    paper: {
                                                                        sx: {
                                                                            fontSize: '0.85rem'
                                                                        }
                                                                    }
                                                                }}
                                                                onChange={(event, newValue) => {
                                                                    (isError['memberDetail'] === null || isError['memberDetail'] === "") ? setIsError({ ...isError, memberDetail: true }) : setIsError({ ...isError, memberDetail: false })
                                                                    if (event.target.value !== undefined) {
                                                                        setState({ ...state, defaultmode: true })
                                                                        setMember({ ...member, memberDetail: newValue })
                                                                        setIsError({ ...isError, memberDetail: false })
                                                                    } else {
                                                                        setIsError({ ...isError, memberDetail: true })
                                                                    }
                                                                }}
                                                                renderInput={(params) =>
                                                                    <FormControl size={'small'} fullWidth>
                                                                        <div className="input-group">
                                                                            <TextField {...params}
                                                                                size="small"
                                                                                id="member"
                                                                                aria-describedby="member-error"
                                                                                error={isError.memberDetail}
                                                                            />
                                                                            {isError.memberDetail && (<FormHelperText className='errorHelperTxt' id="member-error">
                                                                                Please select Member
                                                                            </FormHelperText>)}
                                                                        </div>
                                                                    </FormControl>
                                                                }
                                                            />
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            <FormLabel htmlFor="included" className="fontSemiBold">Include/Exclude</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <div className="input-group">
                                                                <Checkbox
                                                                    checked={included}
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                />
                                                            </div>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            &nbsp;
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Grid container direction="row" alignItems="center">
                                                                <Button 
                                                                    variant="contained" 
                                                                    type="submit" 
                                                                    sx={{ marginRight: '15px' }} 
                                                                    //disabled={((discountScheme_container.hasEditMember && discountScheme_container.hasEditMember.confirmed === true) ? true : false) || state.defaultmode === false}
                                                                    // eslint-disable-next-line max-len
                                                                    disabled={((discountScheme_container.hasEditData !== null || discountScheme_container.editTabData !== null) && discountScheme_container.hasEditMember) ? isError.memberDetail : (state.formValid || state.defaultmode === false)}
                                                                >Save</Button>
                                                                <Button variant="outlined" onClick={handleSelf}>Cancel</Button>
                                                            </Grid>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            state.memberListModal && <CommonPopUp
                                onShow={state.memberListModal}
                                size="large_overlay"
                                supplierMemberCode={{
                                    title: 'Member Details',
                                    type: 'members'
                                }}
                                onHide={hideSupplierMemberModal}
                                memberaccount={getMemberDetail}
                            />
                        }
                    </Box>
                </form>

            </Modal>
        </>
    );
}

export default AddEditmember;