import { TextField, FormControl, FormLabel, FormHelperText } from '@mui/material'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'

export const InputText = (props) => {
    const { label, id, size, maxWidth, regex_pattern, onChange } = props;
    // eslint-disable-next-line no-useless-escape

    const [state, setState] = useState({
        error: false
    });

    const { inputTextState } = useSelector((state) => {
        return {
            inputTextState: state.externalFilter[id],
        }
    });


    const handleChange = (event) => {
        event.preventDefault();
        let newValue = null;
        switch (regex_pattern) {
            case "hasHyphen":
                newValue = event.target.value.replace(/[^a-zA-Z0-9- ]/g, '');
                break;
            default:
                newValue = event.target.value;
                break;
        }
        if(onChange) {
            onChange(newValue, id)
        }
    }
    
    return (
        <>
            <FormControl size={'small'} fullWidth>
                <FormLabel htmlFor={id}>{label}</FormLabel>
                <TextField inputProps={{ maxLength: maxWidth ? maxWidth : '' }} size={size}
                    id={id} aria-describedby={id + 'error'}
                    onChange={(e) => handleChange(e)}
                    value={inputTextState}
                    error={state.error}
                    name={id}
                />
                {state.error && (<FormHelperText className='errorHelperTxt' id={id + 'error'}>
                    Please enter valid text
                </FormHelperText>)}
            </FormControl>
        </>
    );
}
