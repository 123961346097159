import { Container, Box, Modal, Button, Grid, Checkbox, Popper, Grow, Paper, ClickAwayListener, MenuList, ButtonGroup, Stack, TextField, FormControl, FormHelperText, FormLabel, Typography } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TableFilters } from "../../components/shared/data-table/TableFilters";
import { useEffect, useState } from "react";
import { InputText } from "../../components/shared/form/InputText";
import { styled } from '@mui/material/styles';
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange, setSupplierReportFromDate, filterResetFlagChange } from "../../store/slices/externalFilter";
import { DataTableFailedImports } from "../../components/shared/data-table/DataTableFailedImports";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { warningModal, handleAddEditModal, setNewPriceModal } from "../../store/slices/modals";
import WarningModal from "../../components/shared/modals/WarningModal";
import { setRowPage, resetDataGrid, setPageNo } from '../../store/slices/dataGrid';
import { useLayoutEffect } from "react";
import SnackBarDialog from '../../components/shared/SnackBarDialog';
import axiosInvoiceSummaries from '../../services/utility/axios-invoice-summaries';
import axiosNewInvoiceSummaries from '../../services/utility/axios-new-invoice-summaries';
import { smaInput, setMemberCode } from '../../store/slices/supplierMemberAccounts';
import CommonPopUp from '../products/addEditPdc/pdc-tabs/CommonPopUp';
import { failedImportDeleteYes } from '../../store/slices/failedImports';
import PageLoader from '../../components/shared/PageLoader';
import { AutoCompleteStatic } from '../../components/shared/form/AutoCompleteStatic';
import iconNoProcess from "../../assets/images/icon-no-process.svg"
import iconProcessingFailed from "../../assets/images/icon-processing-failed.svg"
import axiosStatementCycle from "../../services/utility/axios-statement-cycle";
import RefreshIcon from '@mui/icons-material/Refresh';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    boxShadow: "none",
    background: "transparent"
}));

var selectedidfordeled = [];

const IconMenuLink = ({ params, onReprocess, onDelete }) => {
    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { showAddEditModal, failedImport_fields } = useSelector((state) => {
        return {
            showAddEditModal: state.modalActions.hideAddEditModal,
            failedImport_fields: state.failedImports
        }
    });
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'reprocess') {
            if (onReprocess) {
                onReprocess(event, row)
            }


        } else if (action === 'delete') {
            selectedidfordeled.push(row.id);
            dispatch(failedImportDeleteYes({
                key: 'startDelete',
                value: true
            }))
            dispatch(warningModal({
                yesHide: true
            }))

        }
        setAnchorEl(null);
    };
    const setEditGeneralData = async (row) => {

    }
    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'reprocess')}>Reprocess</MenuItem>
            <MenuItem onClick={(e) => handleClose(e, 'delete')}>Delete</MenuItem>
        </Menu>
    </>)
}
function FailedImports() {

    const { supplier_id, supplier_name, supplier_ana, addProductwarning, showAddEditModal, selectedInvoices, sma_container, showNewPriceoneModal, failedInvoiceImports } = useSelector((state) => {
        return {
            supplier_id: state.externalFilter.failed_imports_supplier_id,
            supplier_name: state.externalFilter.failed_imports_supplier_name,
            supplier_ana: state.externalFilter.failed_imports_supplier_ana,
            resetTable: state.dataGridTable,
            addProductwarning: state.modalActions.closeWaringAddProduct,
            showAddEditModal: state.modalActions.hideAddEditModal,
            selectedInvoices: state.failedImports,
            sma_container: state.smas,
            showNewPriceoneModal: state.modalActions.priceModal,
            failedInvoiceImports: state.failedImports
        }
    });

    const [state, setState] = useState({
        active_product: false,
        openNewProductModal: false,

        defaultGrid: {
            pagetitle: "Failed Imports",
            productActionButton: true,
            hasExport: true,
            addEditButton: true,
            apiUrl: 'deadImportedInvoices',
            searchUrl: 'deadImportedInvoices/filter',
            checkBoxSelection: true
        }
    });

    const [processRunning, setProcessRunning] = useState(0)
    const [failedInvoiceProcessing, setFailedInvoiceProcessing] = useState(0)
    const [failedInvoiceImport, setFailedInvoiceImport] = useState(0)

    const columns = [
        {
            headerName: "Id",
            field: "id",
            width: 80,
        },
        {
            headerName: "Source",
            field: "sourceUri",
            minWidth: 300,
            flex: 1,
            renderCell: (params) => (
                <Typography title={params.value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px' }}>{params.value}</Typography>
            )
        },
        {
            headerName: "Error Log",
            field: "errorLog",
            minWidth: 250,
            flex: 1,
            //valueFormatter: ({ value }) => value ? value : "Error Message Details will appear here."
            renderCell: (params) => (
                <Typography title={params.value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px' }}>{params.value}</Typography>
            )
        },
        {
            headerName: "Supplier Invoice Code",
            field: "supplierInvoiceCode",
            minWidth: 156,

        },
        {
            headerName: "Supplier Member Account",
            field: "supplierMemberAccountCode",
            minWidth: 185,

        },
        // {
        //     headerName: "Reprocess",
        //     field: "reprocess",
        //     width: 40,
        //     flex: 1,
        //     renderCell: (params) => (
        //         <Checkbox
        //             checked={params.value === true}
        //             icon={<CircleIcon />}
        //             checkedIcon={<CheckCircleIcon />}
        //             color="chk_rounded"
        //             size='small'
        //             style={{ pointerEvents: 'none' }}
        //         />
        //     ),
        // },
        {
            headerName: "CLO1",
            field: "clo1",
            width: 100,
            renderCell: (params) => (
                <Typography title={params.value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px' }}>{params.value ? params.value : "Null"}</Typography>
            )
            // valueFormatter: ({ value }) => value ? value : "Null"
        },
        {
            headerName: "CLO2",
            field: "clo2",
            width: 100,
            renderCell: (params) => (
                <Typography title={params.value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px' }}>{params.value ? params.value : "Null"}</Typography>
            )
            // valueFormatter: ({ value }) => value ? value : "Null"

        },
        {
            headerName: "CLO3",
            field: "clo3",
            width: 100,
            renderCell: (params) => (
                <Typography title={params.value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px' }}>{params.value ? params.value : "Null"}</Typography>
            )
            // valueFormatter: ({ value }) => value ? value : "Null"
        },

        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 70,
            renderCell: (params) => {
                return <IconMenuLink params={params} onReprocess={(e, rows) => { onReprocess(e, rows) }} onDelete={(e, rows) => { onDelete(e, rows) }} />;
            },
        }
    ];

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false

    })

    useEffect(() => {
        // This Use Effect is used to clear the Lookups when the page loads.
        dispatch(setMemberCode({
            key: 'smaSupplierNameOnModal',
            value: ""
        }));
        dispatch(smaInput({
            key: 'sma_supplier_name',
            value: ""
        }));

    }, [])

    const onReprocess = (event, params) => {
        const { id } = params;
        onReprocessInvoice(event, [id])
    }

    const onDelete = (event, params) => {
        const { id } = params;
        onDeleteInvoice(event, [id])
    }

    const CommonPopUpModal = (params, event) => {
        if (event) {
            setState({ ...state, handleSupplierMemberModal: event })
            dispatch(setNewPriceModal(true))
        }
        else {
            // setState({ ...state, handleSupplierMemberModal: '' })
            // dispatch(setInvRepProductModal(true))
        }

    }

    const resetLookups = (params, event) => {
        if (event === 'supplier') {
            dispatch(setMemberCode({
                key: 'smaSupplierNameOnModal',
                value: ""
            }))
            dispatch(smaInput({
                key: 'sma_supplier_name',
                value: ""
            }))
        }

    }

    const hidePriceModal = (event, reason, type) => {
        //setState({ ...state, ['invRepProduct']: reason.id}); 
        if (type === 'supplier') {
            if (reason) {
                dispatch(setFilter({
                    key: 'failed_imports_supplier_id',
                    value: reason ? reason.id : null
                }))
                dispatch(setFilter({
                    key: 'failed_imports_supplier_name',
                    value: reason ? reason.name : null
                }))
            }
            dispatch(setNewPriceModal(event));
        }



    }


    const dispatch = useDispatch();

    const filterBtn = () => {
        dispatch(resetDataGrid());
        var dynamicFilterObjects = {};
        if (supplier_ana !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "anaNumber": supplier_ana
            }
        }
        if (supplier_name !== '' && supplier_name !== null) {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "supplierId": supplier_name.id,
                "name": supplier_name.name,
                "anaNumber": supplier_name.anaNumber || null
            }
        } else {
            resetFilter()
            return
        }


        dispatch(applyFilter({
            key: 'filterList',
            value: dynamicFilterObjects
        }))

        dispatch(filterFlagChange(true));
        dispatch(filterResetFlagChange(false));
        dispatch(setRowPage({ pageData: 10 }));
        dispatch(setPageNo({ page: 1 }));
    }

    const resetFilter = () => {
        if (supplier_name !== "" || supplier_ana != "") {
            dispatch(resetExternalFilter())
            dispatch(resetDataGrid());
            dispatch(setMemberCode({
                key: 'smaSupplierNameOnModal',
                value: ""
            }));
            dispatch(smaInput({
                key: 'sma_supplier_name',
                value: ""
            }));
        }
        setState({ ...state, active_product: false })
        dispatch(filterResetFlagChange(true));
    }

    const handleFilterInput = (value, id) => {
        //setState({ ...state, [id]: value });
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    };

    const selectedAutoComplete = (value, id) => {
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    }

    useEffect(() => {
        const fetchMyAPI = async () => {
            if (failedInvoiceImports.confirmDelete) {
                setState({ ...state, pageLoader: true })
                let processId = null;
                if (selectedidfordeled) {
                    processId = selectedidfordeled
                } else {
                    processId = selectedInvoices.selectedIds
                }

                if (processId.length > 0) {

                    let payload = processId.map((x) => {
                        return { id: x };
                    })

                    try {
                        const { data } = await axiosNewInvoiceSummaries.delete(`deadImportedInvoices/deleteSelection`, { data: payload });
                        if (data.success) {
                            setState({ ...state, pageLoader: false })
                            setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Invoice deleted!", type: "success" })
                            setTimeout(() => {
                                window.location.reload()
                            }, 2500);
                        }

                    } catch (error) {
                        const { response: { data, status } } = error;
                        if (!data) {
                            setSnackBar({ ...snackBar, show: true, text: error.message ? error.message : "Something went wrong!", type: "error" })
                        } else {
                            setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                        }
                        setState({ ...state, pageLoader: false });

                    }
                } else {
                    // setSnackBar({ ...snackBar, show: true, text: "Please Select an Invoice!", type: "error" })
                }
            }
            else {
                selectedidfordeled = [];
            }
        }
        fetchMyAPI();

    }, [failedInvoiceImports.confirmDelete])

    const onReprocessInvoice = async (event, params) => {
        event.preventDefault();

        var dynamicFilterObjects = {};

        let processId = null;
        if (params) {
            processId = params
        } else {
            processId = selectedInvoices.selectedIds
        }

        if (processId.length > 0) {

            let payload = processId.map((x) => {
                return { id: x };
            })

            try {
                setState({ ...state, pageLoader: true })
                const { data } = await axiosInvoiceSummaries.post(`deadImportedInvoices/resubmitSelection`, payload);
                if (data.success) {
                    setState({ ...state, pageLoader: false })
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
                    setTimeout(() => {
                        window.location.reload()
                    }, 2500);
                }

            } catch (error) {
                const { response: { data, status } } = error;
                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                }
                setState({ ...state, pageLoader: false })
            }
        } else {
            setSnackBar({ ...snackBar, show: true, text: "Please select an invoice!", type: "error" })
        }
    }

    const onDeleteInvoice = async (event, params) => {
        if (event) {
            event.preventDefault();
        }


        let processId = null;
        if (params) {
            processId = params
        } else {
            processId = selectedInvoices.selectedIds
        }

        selectedidfordeled = processId;

        if (processId.length > 0) {

            let payload = processId.map((x) => {
                return { id: x };
            })

            dispatch(failedImportDeleteYes({
                key: 'startDelete',
                value: true
            }))
            dispatch(warningModal({
                yesHide: true
            }))


            try {
                // setState({ ...state, pageLoader: true })
                // const { data } = await axiosInvoiceSummaries.post(`deadImportedInvoices/deleteSelection`, payload);
                // if (data.success) {
                //     setState({ ...state, pageLoader: false })
                //     setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
                //     setTimeout(() => {
                //         window.location.reload()
                //     }, 2500);
                // }

            } catch (error) {
                // const { response: { data, status } } = error;
                // if (!data) {
                //     setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                // } else {
                //     setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                // }
                // setState({ ...state, pageLoader: false })
            }
        } else {
            setSnackBar({ ...snackBar, show: true, text: "Please select an invoice!", type: "error" })
        }
    }


    const addProductModal = () => {
        dispatch(handleAddEditModal({
            showAddEdit: !showAddEditModal
        }))
    }
    const hideNewProductModal = (event) => {
        setState({ ...state, openNewProductModal: false })
    }
    const onClearAutoComplete = (value, id) => {
        dispatch(setFilter({
            key: id,
            value: null
        }))
    }
    useEffect(() => {
        importedInvoiceFailed();
        invoiceProcessingFailed();
        checkProcessRunning();
    }, [])

    const importedInvoiceFailed = async (url) => {
        try {
            const { data } = await axiosNewInvoiceSummaries.get('/deadImportedInvoices/countDeadImportedInvoice')
            if (data) {
                setFailedInvoiceImport(data.resultMap.countDeadImportedInvoice)

            }
        } catch (error) {
            const { response: { data, status }, message } = error;
            if (status === 500 || status === 501 || status === 502 || status === 503 || status === 504 || status === 505 || status === 0) {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" });
            } else {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
            }
            setState({ ...state, loader: false })
        }
    }
    const invoiceProcessingFailed = async (url) => {
        try {
            const { data } = await axiosNewInvoiceSummaries.get('/deadInvoiceData/countDeadInvoiceData')
            if (data) {
                setFailedInvoiceProcessing(data.resultMap.countDeadInvoiceData)

            }
        } catch (error) {
            const { response: { data, status }, message } = error;
            if (status === 500 || status === 501 || status === 502 || status === 503 || status === 504 || status === 505 || status === 0) {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" });
            } else {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
            }
            setState({ ...state, loader: false })
        }
    }
    const checkProcessRunning = async (url) => {
        try {
            const { data } = await axiosStatementCycle.get('/statementcycle/getAllRunningProcess')
            if (data) {
                setProcessRunning(data.resultMap.RunningProcessCount)

            }
        } catch (error) {
            const { response: { data, status }, message } = error;
            if (status === 500 || status === 501 || status === 502 || status === 503 || status === 504 || status === 505) {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" });
            }
            else if (error.message === 'Network Error') {
                setSnackBar({ ...snackBar, show: true, text: "The services are down at the moment.", type: "error", });
            }
            else {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
            }
            setState({ ...state, loader: false })
        }
    }
    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Failed Imports" />
                <Box sx={{ marginBottom: 2.5 }}>
                    <TableFilters
                        filterFields={<>
                            {/* <Box component="div">
                                <InputText  onChange={handleFilterInput} maxWidth={50} label="Supplier Ana Number" id="failed_imports_supplier_ana" name="failed_imports_supplier_ana" size="small" />

                            </Box> */}
                            <Box component="div"><AutoCompleteStatic endPoint="/supplier/look-up" onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} onClear={onClearAutoComplete} id="failed_imports_supplier_name" label="Supplier Name" name="failed_imports_supplier_name" /></Box>
                        </>}


                        filterCheckboxes={<>
                            <Box component="div">
                                <Grid container alignItems={'center'}>
                                    <Grid>
                                        <Item>
                                            <img src={iconNoProcess} alt="icon" />
                                        </Item>
                                    </Grid>
                                    <Grid sx={{ paddingTop: '3px' }}>
                                        <Item>
                                            {
                                                processRunning ? `Process running (${processRunning})` : 'No process running'
                                            }
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box component="div">
                                <Grid container alignItems={'center'}>
                                    <Grid>
                                        <Item>
                                            <img src={iconProcessingFailed} alt="icon" />
                                        </Item>
                                    </Grid>
                                    <Grid sx={{ paddingTop: '3px' }}>
                                        <Item>
                                            {`Invoice processing failed (${failedInvoiceProcessing})`}
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box component="div">
                                <Grid container alignItems={'center'}>
                                    <Grid>
                                        <Item>
                                            <img src={iconProcessingFailed} alt="icon" />
                                        </Item>
                                    </Grid>
                                    <Grid sx={{ paddingTop: '3px' }}>
                                        <Item>
                                            {`Invoice file import failed (${failedInvoiceImport})`}
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box component="div">
                                <Box component={'div'} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={(e) => window.location.reload()}>
                                    <RefreshIcon color="primary"></RefreshIcon> <span style={{ marginLeft: '5px', color: '#B253AE' }}>Refresh</span>
                                </Box>
                            </Box>
                        </>}
                        filterBtns={
                            <>
                                <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                <Button variant="contained" onClick={filterBtn}>Apply</Button>
                            </>}
                    >
                    </TableFilters>
                </Box>
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -3 } }}>
                    <div className='table-actionable'>
                        <Button variant="contained" color="tertiary" size='small' onClick={(e) => { onReprocessInvoice(e) }} >
                            Reprocess Selection
                        </Button>
                        {/*  */}
                    </div>
                    <div className='table-actionable'>
                        <Button variant="contained" size='small' onClick={(e) => { onDeleteInvoice(e) }} >
                            Delete Selection
                        </Button>
                        {/* onClick={(e) => { onReprocessInvoice(e) }} */}
                    </div>

                </Box>
                <DataTableFailedImports defaultGrid={state.defaultGrid} columns={columns}>

                </DataTableFailedImports>
                {
                    showNewPriceoneModal && (
                        <CommonPopUp
                            onShow={showNewPriceoneModal} size="medium_overlay"
                            supplierMemberCode={{
                                title: (state.handleSupplierMemberModal && state.handleSupplierMemberModal === 'members') ? 'Member Details' : 'Supplier Details',
                                type: state.handleSupplierMemberModal
                            }}
                            // memberaccount={loadMemberAccountWithMemberId}
                            onHide={(e, reason) => hidePriceModal(e, reason, state.handleSupplierMemberModal)}
                        />
                    )
                }

                {
                    addProductwarning && (
                        <WarningModal size="small_overlay" onShow={addProductwarning} />
                    )
                }
                {snackBar.show && <SnackBarDialog
                    onShow={snackBar.show}
                    text={snackBar.text}
                    type={snackBar.type}
                    onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                />}
            </Container>

        </>
    );
}

export default FailedImports;
