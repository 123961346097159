export const replaceKeys = (object) => {
    Object.keys(object).forEach(function (key) {
        var newKey = key.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase()).replace(/\s+/g, '');
        if (object[key] && typeof object[key] === 'object') {
            replaceKeys(object[key]);
        }
        if (key !== newKey) {
            object[newKey] = object[key];
            delete object[key];
        }
    });
}

export const hasDuplicate = (arrayObj, colName) => {
    var hash = Object.create(null);
    return arrayObj.some((arr) => {
        return arr[colName] && (hash[arr[colName]] || !(hash[arr[colName]] = true));
    });
};

export const isEmpty = (arrayObj, colName) => {
    return arrayObj.some((arr) => {
        return arr[colName] === ""
    })
}

export const saveFile = (blob, filename) => {
    if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }, 0)
    }
}

export const saveSomeData = (base64, fileType, fileName) => {

    const contentType = fileType === 'CSV' ? 'text/csv' : fileType === 'Excel' ? 'application/vnd.ms-excel' : 'application/pdf';

    var blob = b64toBlob(base64, contentType);

    function b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    saveFile(blob, fileName)
}
export const negativeNumber = (number) => {
    return -Math.abs(number)
}
export const negativeremove = (number) => {
    return Math.abs(number)
}
export const checkSpacer = (str) => {
    return str.replace(/[^A-Z0-9-.]/ig, "_");
}