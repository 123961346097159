import * as React from 'react';
// import { DataGridPro } from '@mui/x-data-grid-pro';
import {
    DataGridPremium,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
    gridPaginationRowRangeSelector,
    gridPaginatedVisibleSortedGridRowIdsSelector,
    gridSortedRowIdsSelector,
    gridVisibleSortedRowIdsSelector,
    GridToolbarContainer
} from '@mui/x-data-grid-premium';
import { Box, Button, Stack } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { Pagination, PaginationItem, Select, FormControl, MenuItem } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import axios_invoice_summaries from '../../../services/utility/axios-new-invoice-summaries';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setInvoiceDetailsDisputeRowPage, setInvoiceDetailsPageNoDispute, resetDataGrid } from '../../../store/slices/dataGrid';
import { resetExternalFilter, filterFlagChange } from "../../../store/slices/externalFilter";
import { useLayoutEffect } from 'react';
import { setReprocessIds, setReprocessIdsRows } from '../../../store/slices/invoiceReprocess';
import PageLoader from '../PageLoader';
import SnackBarDialog from '../SnackBarDialog';

export const DataTableInvoiceDetailsDispute = (props) => {

    const { columns, defaultGrid, loc } = props;
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [selectionRowModel, setSelectionRowModel] = React.useState([]);

    const { rowPerPage, resetTable, applyFilter, pageNo } = useSelector((state) => {
        return {
            rowPerPage: state.dataGridTable.invd_pageDataDispute,
            resetTable: state.dataGridTable,
            applyFilter: state.externalFilter,
            pageNo: state.dataGridTable.invd_pageNoDispute,
        }
    });
    const dispatch = useDispatch();

    const [state, setState] = useState({
        isLoading: false,
        data: [],
        total: 0,
        page: 1,
        pageSize: rowPerPage,
        applyFilterCount: null
    });

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    });

    function CustomPagination() {
        const { rowPerPage, applyFilter } = useSelector((state) => {
            return {
                rowPerPage: state.dataGridTable.invd_pageDataDispute,
                applyFilter: state.externalFilter,
            }
        });
    
        const dispatch = useDispatch();
        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    
        const rowDataList = [10, 25, 50, 100, 200, 300];
    
        const handleChange = (event) => {
            dispatch(setInvoiceDetailsPageNoDispute({ invd_pageNoDispute: 1 }));
            dispatch(setInvoiceDetailsDisputeRowPage({ invd_pageDataDispute: event.target.value }));
        };
    
        const paginationChange = (event, value) => {
            apiRef.current.setPage(value - 1);
            dispatch(setInvoiceDetailsPageNoDispute({ invd_pageNoDispute: value }));
        }
    
        return (
            <>
                <div className='grid-pagiation'>
                    <Box className='customRowPerPageBox' component="div">
                        <div>
                            View
                        </div>
                        <Box component="div" sx={{ marginX: '10px' }}>
                            <FormControl>
                                <Select
                                    size="small"
                                    id="row-per-pages"
                                    defaultValue="choose"
                                    onChange={handleChange}
                                    value={rowPerPage}
                                    aria-describedby={'row-per-pages'} >
                                    {rowDataList.map((value) => {
                                        return (
                                            <MenuItem value={value} key={value}>{value}</MenuItem>
                                        )
                                    }
    
                                    )}
                                </Select>
    
                            </FormControl>
                        </Box>
                        <div>
                            rows per page
                        </div>
                    </Box>
                    <Pagination
                        variant="outlined"
                        shape="rounded"
                        count={pageCount}
                        page={page + 1}
                        onChange={(event, value) => paginationChange(event, value)}
                        renderItem={(item) => (
                            <PaginationItem
                                components={{ previous: KeyboardArrowLeftIcon, next: KeyboardArrowRightIcon }}
                                {...item}
                            />
                        )}
                    />
                </div>
            </>
        );
    }

    useEffect(() => {
        /* load first first time */
        return () => {
            setTimeout(() => {
                setState({ ...state, isLoading: true })
                // dispatch(resetExternalFilter())
                // dispatch(filterFlagChange(false));
                dispatch(setInvoiceDetailsPageNoDispute({ invd_pageNoDispute: 1 }));
                //moved from settime out to outside for 300 record
                dispatch(setInvoiceDetailsDisputeRowPage({ invd_pageDataDispute: 300 }));
            }, 1500);
            
        }
    }, [])

    useEffect(() => {
        const fetchMyAPI = async () => {
            if (applyFilter.filterApplied) {
                setState({ ...state, isLoading: true, data: [] })
                try {
                    // removed till bug ?pageSize=${rowPerPage}
                    const { data } = await axios_invoice_summaries.post(`${defaultGrid.searchUrl}?page=${pageNo}&pageSize=${rowPerPage}`, applyFilter.filterList);
                    // eslint-disable-next-line max-len
                    setState({ ...state, isLoading: false, data: data.items, totalRows: data.totalCount, total: data.totalCount, applyFilterCount: data.totalCount });
                } catch (error) {
                    // eslint-disable-next-line max-len
                    const { response: { data, status }, message } = error;
                    if (status === 500) {
                        setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
                    } else {
                        setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
                    }
                    setState({ ...state, isLoading: false, data: [], totalRows: 0, total: 0 });
                }
            }
        }
        fetchMyAPI();
    }, [pageNo, rowPerPage, applyFilter.filterApplied])

    useEffect(() => {
        if (defaultGrid.apiUrl !== "") {
            setState({ ...state, isLoading: true, data: [] })
            const fetchMyAPI = async () => {
                if (!applyFilter.filterApplied) {
                    try {
                        // eslint-disable-next-line max-len
                        const { data } = await axios_invoice_summaries.get(`${defaultGrid.apiUrl}?page=${pageNo}&pageSize=${rowPerPage}`);
                        // eslint-disable-next-line max-len
                        setState({ ...state, isLoading: false, data: data.items, totalRows: data.totalCount, total: data.totalCount, applyFilterCount: null });
                    }
                    catch (error) {
                        const { response: { data, status }, message } = error;
                        if (status === 500) {
                            setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
                        } else {
                            setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
                        }
                        setState({ ...state, isLoading: false, data: [], totalRows: 0, total: 0 });
                    }

                }
            }
            fetchMyAPI()
        }
    }, [pageNo, rowPerPage, applyFilter.filterApplied])

    const onPageChange = (newPage) => {
        dispatch(setInvoiceDetailsPageNoDispute({ invd_pageNoDispute: newPage + 1 }));
        //setState({ ...state, page: newPage + 1 });
    };

    const onPageSizeChange = (newPageSize) => {
        dispatch(setInvoiceDetailsDisputeRowPage({ invd_pageDataDispute: newPageSize }));
    };

    //Export to Excel Block

    const getFilteredRows = ({ apiRef }) => gridVisibleSortedRowIdsSelector(apiRef);

    const CustomToolbar = () => {
        const apiRef = useGridApiContext();

        const handleExport = (options) => apiRef.current.exportDataAsExcel(options);

        return (
            <GridToolbarContainer sx={{ float: 'right', margin: '10px' }}>
                {
                    defaultGrid.exportExcelButton && (
                        <Button variant="contained" color="secondary" size='small' disabled={state.data.length > 0 ? false : true} onClick={() => handleExport({ getRowsToExport: getFilteredRows })} startIcon={<SendIcon />}>
                            Export as Excel
                        </Button>
                    )
                }

            </GridToolbarContainer>
        );
    };


    return (

        <>
            <Box component="div" className='table-actions-buttons' sx={{ columnGap: 1.75, display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2.5 }}>

                {/* <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center' }, textAlign: { xs: "center" } }}>
                    
                    {defaultGrid.brandActionButton && (
                        <>
                            <div className='table-actionable'>
                                <Button variant="contained" color="secondary" size='small' startIcon={<SendIcon />}>
                                    Export as Excel
                                </Button>
                            </div>
                            {addBrandModal && (
                                <div className='table-actionable'>
                                    <Button variant="contained" size='small' onClick={(e) => handleTableAction(e)} startIcon={<AddIcon />}>
                                        Add New Brand
                                    </Button>
                                </div>
                            )}
                        </>)
                    }

                </Box> */}
                <Box component="div" className='data-per-row-message'>
                    Showing {rowPerPage * (pageNo - 1) + 1} to {((state.total < (rowPerPage * pageNo)) ? state.total : (rowPerPage * pageNo))} out of {state.total} entries
                </Box>
            </Box>
            <div style={{ width: '100%' }}>
                {state.isLoading && <PageLoader />}
                {snackBar.show &&
                    <SnackBarDialog
                        onShow={snackBar.show}
                        text={snackBar.text}
                        type={snackBar.type}
                        onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                    />
                }
                
                <div style={{ height: '468px', width: '100%' }}>
                    <DataGridPremium
                        rows={state.data}
                        columns={columns ? columns : []}
                        //autoHeight={true}
                        // disableColumnMenu={true}
                        getRowId={(loc === "linedispute") ? (row) => row.invoiceDisputeLogId : (row) => row.uniqueId}
                        rowCount={state.total}
                        // loading={state.isLoading}

                        pagination={true}
                        page={pageNo - 1}
                        pageSize={rowPerPage}
                        paginationMode="server"
                        onPageChange={(newPage) => onPageChange(newPage)}
                        onPageSizeChange={(newPageSize) => onPageSizeChange(newPageSize)}
                        // checkboxSelection={defaultGrid.checkBoxSelection}
                        // disableSelectionOnClick
                        // onSelectionModelChange={(newSelectionModel) => {

                        //     if (newSelectionModel.length < 5) {
                        //         setSelectionModel(newSelectionModel);
                        //         const selectedRowsData = newSelectionModel.map((id) => state.data.find((row) => row.invoiceId === id));
                        //         setSelectionRowModel(selectedRowsData)
                        //         dispatch(setReprocessIdsRows(selectedRowsData))
                        //     } else {
                        //         return false
                        //     }
                        //     dispatch(setReprocessIds(newSelectionModel))
                        // }}
                        // selectionModel={selectionModel}
                        // selectRowRange={(newSelectionModel) => {
                        // }}
                        components={{
                            Pagination: CustomPagination,
                            Toolbar: CustomToolbar,
                            NoRowsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    No search results were found
                                </Stack>
                            ),
                            NoResultsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    Local filter returns no result
                                </Stack>
                            )
                        }}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? '' : 'oddRow'
                        }
                        initialState={{ pinnedColumns: { right: ['action'] } }}
                        sx={{
                            "& .MuiDataGrid-columnHeader": {
                                backgroundColor: "#F0F0F0"
                                // color: "red"
                            },
                            "& .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle": {
                                fontWeight: "600",
                                fontSize: '14px',
                                color: "#15191E"
                            },
                            "& .MuiDataGrid-row.oddRow": {
                                backgroundColor: "#FBFBFB"
                                // color: "red"
                            },
                            "& .MuiDataGrid-row.Mui-hovered, .MuiDataGrid-row.Mui-selected, .MuiDataGrid-row.Mui-selected.Mui-hovered": {
                                backgroundColor: "#FFF5E1"
                                // color: "red"
                            },
                            '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
                                borderRight: `1px solid #E6E6E6`,
                                borderBottom: `1px solid #E6E6E6`,
                            },
                            '& .MuiDataGrid-cell': {
                                fontWeight: '500',
                            },
                        }}
                    />
                </div>
            </div>
        </>
    );
}