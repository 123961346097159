import { Container, Box, Modal, Button, Grid, Tabs, Tab, Paper } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useEffect, useState } from "react";
import { } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import { warningModal, handleAddEditDisputedInvoiceModal, handleAddEditLevyBillingModal, handleAddEditRetroBillingModal, handleAddEditRetroFinalModal, handleAddEditRetroInterimModal } from "../../store/slices/modals";
import WarningModal from "../../components/shared/modals/WarningModal";
import { useLayoutEffect } from "react";
import { DataTableClientSide } from '../../components/shared/data-table/DataTableClientSide';
import { memberInvoiceProcessData, resetprocessManager } from "../../store/slices/processManager";
import axios_process_manager from '../../services/utility/axios-process-manager';
import SnackBarDialog from "../../components/shared/SnackBarDialog";
import PageLoader from "../../components/shared/PageLoader";
import { setMemberCode } from '../../store/slices/supplierMemberAccounts';
import DisputedInvoiceModal from "./startProcessManagerDetail/DisputedInvoiceModal";
import LevyBillingModal from "./startProcessManagerDetail/LevyBillingModal";
import RetroBillingModal from "./startProcessManagerDetail/RetroBillingModal";
import RetroFinalModal from "./startProcessManagerDetail/RetroFinalModal";
import RetroInterimModal from "./startProcessManagerDetail/RetroInterimModal";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import RefreshIcon from '@mui/icons-material/Refresh';
import { styled } from '@mui/material/styles';
import { TableFilters } from '../../components/shared/data-table/TableFilters';
import iconNoProcess from "../../assets/images/icon-no-process.svg"

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    boxShadow: "none",
    background: "transparent"
}));

const IconMenuLink = ({ params, startMemberInvoiceProcess }) => {
    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    // eslint-disable-next-line max-len
    const { processManager_container, showAddEditModal, showAddEditDisputedInvoiceModal, showAddEditLevyBillingModal,
        showAddEdiRetroBillingModal, showAddEdiRetroFinalModal, showAddEdiRetroInterimModal } = useSelector((state) => {
            return {
                processManager_container: state.processManagers,
                showAddEditModal: state.modalActions.hideAddEditModal,
                showAddEditDisputedInvoiceModal: state.modalActions.showAddEditDisputedInvoiceModal,
                showAddEditLevyBillingModal: state.modalActions.showAddEditLevyBillingModal,
                showAddEdiRetroBillingModal: state.modalActions.showAddEdiRetroBillingModal,
                showAddEdiRetroFinalModal: state.modalActions.showAddEdiRetroFinalModal,
                showAddEdiRetroInterimModal: state.modalActions.showAddEdiRetroInterimModal
            }
        });
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'start') {
            handleEdit(row)
        }
        setAnchorEl(null);
    };

    const handleEdit = async (row) => {
        if (row.id == 1) {
            dispatch(resetprocessManager())
            // dispatch(setMemberCode({
            //     key: 'smaSupplierNameOnModal',
            //     value: ""
            // }))
            dispatch(handleAddEditDisputedInvoiceModal({
                showAddEditDisputedInvoiceModal: !showAddEditDisputedInvoiceModal
            }))
        }
        if (row.id == 2) {
            dispatch(resetprocessManager())
            dispatch(handleAddEditLevyBillingModal({
                showAddEditLevyBillingModal: !showAddEditLevyBillingModal
            }))
        }
        if (row.id == 3) {
            if (startMemberInvoiceProcess) {
                startMemberInvoiceProcess(row)
            }
        }
        if (row.id == 4) {
            dispatch(resetprocessManager())
            dispatch(handleAddEditRetroBillingModal({
                showAddEdiRetroBillingModal: !showAddEdiRetroBillingModal
            }))
        }
        if (row.id == 5) {
            dispatch(handleAddEditRetroFinalModal({
                showAddEdiRetroFinalModal: !showAddEdiRetroFinalModal
            }))
        }
        if (row.id == 6) {
            dispatch(handleAddEditRetroInterimModal({
                showAddEdiRetroInterimModal: !showAddEdiRetroInterimModal
            }))
        }
    }



    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'start')}>Start</MenuItem>
        </Menu>
    </>)
}

function TabPanel(props) {
    const { children, value, index, id, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`${id}-tab-pannel-${index}`}
            aria-labelledby={`${id}-tab-${index}`}
            {...other}
            className="tabs-pannel"
        >
            {value === index && (
                <>{children}</>
            )}
        </div>
    );
}

function ProcessManager() {
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const columns = [
        {
            headerName: "ID",
            field: "id",
            flex: 1,
            minWidth: 200,
            hide: true
        },
        {
            headerName: "Name",
            field: "name",
            flex: 2,
            width: 200
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} startMemberInvoiceProcess={MemberInvoiceProcessStart} />;
            },
        }
    ];
    const [state, setState] = useState({
        openDisputedInvoiceModal: false,
        pageLoader: false,
        defaultGrid: {
            paginationMode: 'client',
        }
    });

    const [processRunning, setProcessRunning] = useState(0)

    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const uname = accounts[0] && accounts[0].name;

    const rowData = [{ "id": 1, "name": "Disputed Invoice Executable" }, { "id": 2, "name": "Levy Billing Executable" }, { "id": 3, "name": "Member Invoice Print Executable" }, { "id": 4, "name": "Retro Monthly billing executable" }, { "id": 5, "name": "Retro Final Executable" }, { "id": 6, "name": "Retro Interim Executable" }]

    const pageLoader = (loader) => {
        setState({ ...state, pageLoader: loader })
    }

    const { processManager_container, addProductwarning, showAddEditDisputedInvoiceModal, showAddEditLevyBillingModal, showAddEdiRetroBillingModal,
        showAddEdiRetroFinalModal, showAddEdiRetroInterimModal, handleWarning } = useSelector((state) => {
            return {
                processManager_container: state.processManagers,
                addProductwarning: state.modalActions.closeWaringAddProduct,
                showAddEditDisputedInvoiceModal: state.modalActions.showAddEditDisputedInvoiceModal,
                showAddEditLevyBillingModal: state.modalActions.showAddEditLevyBillingModal,
                showAddEdiRetroBillingModal: state.modalActions.showAddEdiRetroBillingModal,
                showAddEdiRetroFinalModal: state.modalActions.showAddEdiRetroFinalModal,
                showAddEdiRetroInterimModal: state.modalActions.showAddEdiRetroInterimModal,
                handleWarning: state.modalActions.closeWaringAddProduct
            }
        });

    const dispatch = useDispatch();

    const hideNewDisputedInvoiceModal = (event) => {
        setState({ ...state, openDisputedInvoiceModal: false })
    }

    const [value, setValue] = useState(0);
    function handleChange(event, newValue) {
        setValue(newValue);
    }

    const MemberInvoiceProcessStart = (row) => {
        dispatch(memberInvoiceProcessData({
            key: 'memberInvoiceStartProcess',
            value: true
        }))
        dispatch(warningModal({
            yesHide: !handleWarning
        }))
    }

    useEffect(() => {
        if (processManager_container.memberInvoiceConfirmProcess) {
            memberInvoiceExecute()
        }
    }, [processManager_container.memberInvoiceConfirmProcess])

    const memberInvoiceExecute = async () => {
        try {
            let executableData = null;
            executableData = {
                "executable": "MemberInvoicePrintExecutable",
                "user": uname,
                "processExecutionDTO": {
                }
            }
            const { data } = await axios_process_manager.post(`sqs/processmanager`, { ...executableData });
            if (data.isSuccess === true) {
                setSnackBar({ ...snackBar, show: true, text: data.message, type: "success", })
                memberInvoiceProcessDefaultState()
                // setTimeout(() => {
                //     window.location.reload();
                // }, 1500);
            }
        } catch (error) {
            const { response: { data, status } } = error;
            if (status === 500 && data.errorType === "Business") {
                setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" });
            }
            else if (error.message === 'Network Error') {
                setSnackBar({ ...snackBar, show: true, text: "The services are down at the moment.", type: "error", });
            }
            else {
                setSnackBar({ ...snackBar, show: true, text: "Something went wrong!", type: "error", })
            }
            memberInvoiceProcessDefaultState()
        }
    }

    const memberInvoiceProcessDefaultState = () => {
        dispatch(memberInvoiceProcessData({
            key: 'memberInvoiceStartProcess',
            value: false
        }))
        dispatch(memberInvoiceProcessData({
            key: 'memberInvoiceConfirmProcess',
            value: false
        }))
    }

    useEffect(() => {
        checkProcessRunning();
    }, [])
    const checkProcessRunning = async (url) => {
        try {
            const { data } = await axios_process_manager.get('/processmanager/getAllRunningProcess')
            if (data) {
                setProcessRunning(data.resultMap.RunningProcessCount)

            }
        } catch (error) {
            const { response: { data, status }, message } = error;
            if (status === 500 || status === 501 || status === 502 || status === 503 || status === 504 || status === 505) {
                // setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" });
                setSnackBar({ ...snackBar, show: true, text: "The ECS services are down at the moment.", type: "error", });
            }
            else if(error.message === 'Network Error')
            {
                setSnackBar({ ...snackBar, show: true, text: "The ECS services are down at the moment.", type: "error", });
            }
            else {
                // setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
                setSnackBar({ ...snackBar, show: true, text: "The ECS services are down at the moment.", type: "error", });
            }
            setState({ ...state, loader: false })
        }
    }

    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Process Manager" />
                <Box className='processManagerFltrBx' sx={{ marginBottom: 2.5 }}>
                    <TableFilters
                        filterCheckboxes={<>
                            <Box component="div">
                                <Grid container alignItems={'center'}>
                                    <Grid>
                                        <Item>
                                            <img src={iconNoProcess} alt="icon" />
                                        </Item>
                                    </Grid>
                                    <Grid sx={{ paddingTop: '3px' }}>
                                        <Item>
                                            {
                                                processRunning ? `Process running (${processRunning})` : 'No process running'
                                            }
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box component="div">
                                <Box component={'div'} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={(e) => window.location.reload()}>
                                    <RefreshIcon color="primary"></RefreshIcon> <span style={{ marginLeft: '5px', color: '#B253AE' }}>Refresh</span>
                                </Box>
                            </Box>
                        </>}
                    >

                    </TableFilters>
                </Box>
                <div>
                    <div className="content">
                        <div className="overlayBody">
                            <div className="overlay-content">
                                <DataTableClientSide defaultGrid={state.defaultGrid} columns={columns} rows={rowData}>
                                </DataTableClientSide>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    showAddEditDisputedInvoiceModal && (
                        <DisputedInvoiceModal
                            onShow={showAddEditDisputedInvoiceModal} size="medium_overlay"
                            onHide={(e, reason) => hideNewDisputedInvoiceModal(e, reason)}

                        />
                    )
                }

                {
                    showAddEditLevyBillingModal && (
                        <LevyBillingModal
                            onShow={showAddEditLevyBillingModal} size="medium_overlay"
                            onHide={(e, reason) => hideNewDisputedInvoiceModal(e, reason)}

                        />
                    )
                }

                {
                    showAddEdiRetroBillingModal && (
                        <RetroBillingModal
                            onShow={showAddEdiRetroBillingModal} size="medium_overlay"
                            onHide={(e, reason) => hideNewDisputedInvoiceModal(e, reason)}

                        />
                    )
                }

                {
                    showAddEdiRetroFinalModal && (
                        <RetroFinalModal
                            onShow={showAddEdiRetroFinalModal}
                            onHide={(e, reason) => hideNewDisputedInvoiceModal(e, reason)}

                        />
                    )
                }

                {
                    showAddEdiRetroInterimModal && (
                        <RetroInterimModal
                            onShow={showAddEdiRetroInterimModal}
                            onHide={(e, reason) => hideNewDisputedInvoiceModal(e, reason)}

                        />
                    )
                }


                {
                    addProductwarning && (
                        <WarningModal size="small_overlay" onShow={addProductwarning} />
                    )
                }
                {snackBar.show && <SnackBarDialog
                    onShow={snackBar.show}
                    text={snackBar.text}
                    type={snackBar.type}
                    onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                />}
            </Container>

        </>
    );
}

export default ProcessManager; 
