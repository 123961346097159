import { useEffect, useState } from "react";
import {
    Grid, Paper, Box, FormLabel, Button, Autocomplete, Typography, TextField, FormHelperText, Checkbox, FormControlLabel, FormControl, Alert, Snackbar, Modal
} from "@mui/material";
import PropTypes from "prop-types";
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import { warningModal, handleAddEditModal, setPdcPriceModal, handleAddEditPdcPriceModal } from "../../../../store/slices/modals";
import { setHasEditData, hasTabEdit, brandSearch, generalPdcProductInput, getIsError, setAutocomplete, hasTabEditData, generalPdcProductCheckbox, setLatestPdcData } from "../../../../store/slices/generalPdcProduct";
import { setCommonInput } from "../../../../store/slices/commonProduct";
import { useDispatch, useSelector } from 'react-redux';
import axios_utilities from "../../../../services/utility/axios-utilities";
import axios_supplier from "../../../../services/utility/axios-supplier";
import axiosProductDistribution from '../../../../services/utility/axios-product-distribution';
import axios_levy_scheme from "../../../../services/utility/axios-levy-scheme";
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { useCallback } from "react";
import SnackBarDialog from "../../../../components/shared/SnackBarDialog";
import CommonPopUp from "./CommonPopUp";
import { filterFlagChange, filterResetFlagChange } from "../../../../store/slices/externalFilter";

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

const defaultValues = {
    loader: false,
    formValid: true,
    //productError: true,
    defaultmode: false,
    pdcGeneralProductModal: false,
    pdcGeneralSupplierModal: false,
    newProductData: [],
}

const General = ({ size = '' }) => {
    const [pdc_supplier, setPdc_supplier] = useState("")
    const [pdc_supplierValue, setPdc_supplierValue]= useState("")
    const [state, setState] = useState(defaultValues);
    const [otherTabsOpen, setOtherTabsOpen] = useState(false);
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })
    const {
        handleWarning,
        pdc_general_product_fields,
        showNewPriceModal,
        searchProductModal,
        showAddEditPriceModal,
        hasEditData,
        showAddEditModal
    } = useSelector((state) => {
        return {
            handleWarning: state.modalActions.closeWaringAddProduct,
            pdc_general_product_fields: state.generalPdcProducts,
            showNewPriceModal: state.modalActions.priceModal,
            searchProductModal: state.commonProducts,
            showAddEditPriceModal: state.modalActions.hideAddEditPdcModal,
            hasEditData: state.generalPdcProducts.hasEditData,
            showAddEditModal: state.modalActions.hideAddEditModal
        }
    });

    useEffect(() => {
        const fetchMyAPI = async () => {
            if (pdc_general_product_fields.pdc_general_distribution_channel.distribution_channel_options.length === 0) {
                axiosProductDistribution.get('distributionChannels/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            dispatch(setAutocomplete({
                                parent: 'pdc_general_distribution_channel',
                                key: 'distribution_channel_options',
                                value: unique
                            }));
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
            if (pdc_general_product_fields.pdc_general_unit_measure.unit_measure_options.length === 0) {
                axios_utilities.get('unitofmeasures/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            dispatch(setAutocomplete({
                                parent: 'pdc_general_unit_measure',
                                key: 'unit_measure_options',
                                value: unique
                            }));
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
            if (pdc_general_product_fields.pdc_general_levy_scheme.levy_scheme_options.length === 0) {
                axios_levy_scheme.get('levySchemes/productValueLevyScheme/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            dispatch(setAutocomplete({
                                parent: 'pdc_general_levy_scheme',
                                key: 'levy_scheme_options',
                                value: unique
                            }));
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
            if (pdc_general_product_fields.pdc_general_distribution_charge.distribution_charge_options.length === 0) {
                axios_levy_scheme.get('levySchemes/distributionChargeLevyScheme/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            dispatch(setAutocomplete({
                                parent: 'pdc_general_distribution_charge',
                                key: 'distribution_charge_options',
                                value: unique
                            }));
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
            if (pdc_general_product_fields.pdc_supplier.supplier_code_options.length === 0) {
                axios_supplier.get('/supplier/look-up')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data.filter((value, index, self) => self.findIndex((m) => m.name === value.name) === index);
                            dispatch(setAutocomplete({
                                parent: 'pdc_supplier',
                                key: 'supplier_code_options',
                                value: unique
                            }))
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
        }
        fetchMyAPI();
    }, [])


    const dispatch = useDispatch();

    const CommonPopUpModal = (params, event) => {
        if (event === 'product') {
            setState({ ...state, pdcGeneralProductModal: true })
        }
        if (event === 'supplier') {
            setState({ ...state, pdcGeneralSupplierModal: true })
        }
    }

    const [channelprice, setChannelPrice] = useState([]);
    const [channelcharge, setChannelCharge] = useState([]);
    const [channelpromoprice, setChannelPromoPrice] = useState([]);


    useEffect(() => {
        async function fetchMyAPI() {
            if (pdc_general_product_fields.rowData) {
                setChannelPrice(pdc_general_product_fields.rowData.channelPrice);
                setChannelCharge(pdc_general_product_fields.rowData.channelCharge);
                setChannelPromoPrice(pdc_general_product_fields.rowData.promoPrice);
            }
        }

        fetchMyAPI()
    }, [pdc_general_product_fields.rowData])

    const handleCheckboxChange = async (event) => {
        if (pdc_general_product_fields.hasEditData) {
            setState({ ...state, defaultmode: true })
        }
        const { checked, name } = event.target;
        dispatch(generalPdcProductInput({
            key: name,
            value: checked
        }))
    };

    const handleSelection = (event, newValue, parentname, name) => {
        if (pdc_general_product_fields.hasEditData) {
            setState({ ...state, defaultmode: true })
        }
        if (event.target.value !== "" || event.target.value !== null) {
            dispatch(brandSearch({
                parent: parentname,
                key: name,
                value: newValue
            }))
        }
    }

    const handleAddProduct = (event) => {
        dispatch(warningModal({
            yesHide: !handleWarning
        }))
    }

    useEffect(() => {
        if ((pdc_general_product_fields.pdc_supplier.pdc_general_supplier === null || pdc_general_product_fields.pdc_supplier.pdc_general_supplier === "") || (pdc_general_product_fields.pdc_general_distribution_channel.distribution_channel_selected === null || pdc_general_product_fields.pdc_general_distribution_channel.distribution_channel_selected === "") || (pdc_general_product_fields.pdc_gen_product === null || pdc_general_product_fields.pdc_gen_product === "")) {
            setState({ ...state, formValid: true, defaultmode: false })
        } else {
            setState({ ...state, formValid: false, defaultmode: true })
        }
        // eslint-disable-next-line max-len
    }, [pdc_general_product_fields.pdc_supplier.pdc_general_supplier, pdc_general_product_fields.pdc_general_distribution_channel.distribution_channel_selected, pdc_general_product_fields.pdc_gen_product])

    useEffect(() => {
        const {
            // eslint-disable-next-line max-len
            pdc_general_supplier, distribution_channel_selected, pdc_gen_product
        } = pdc_general_product_fields.isError
        // eslint-disable-next-line max-len
        if (!pdc_general_supplier && !distribution_channel_selected && !pdc_gen_product) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true })
        }


    }, [pdc_general_product_fields.isError])

    const resetFilter = (params, event) => {
        if (event === 'product') {
            dispatch(generalPdcProductInput({
                key: 'pdc_gen_product',
                value: ''
            }))
        }
        if (event === 'supplier') {
            dispatch(generalPdcProductInput({
                key: 'pdc_general_supplier',
                value: ''
            }))
        }
    }

    const getProductDetail = (event, type) => {
        if (type === 'product') {
            if (event) {
                if (pdc_general_product_fields.hasEditData) {
                    setState({ ...state, pdcGeneralProductModal: false, defaultmode: true })
                } else {
                    setState({ ...state, pdcGeneralProductModal: false })
                }
                dispatch(generalPdcProductInput({
                    key: 'pdc_gen_product',
                    value: event
                }))
            }
        }
        if (type === 'supplier') {
            if (event) {
                if (pdc_general_product_fields.hasEditData) {
                    setState({ ...state, pdcGeneralSupplierModal: false, defaultmode: true })
                } else {
                    setState({ ...state, pdcGeneralSupplierModal: false })
                }
                dispatch(generalPdcProductInput({
                    key: 'pdc_general_supplier',
                    value: event
                }))
            }
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        let productData = null;
        if (!state.formValid) {
            productData = {
                "distributionChannel": pdc_general_product_fields.pdc_general_distribution_channel.distribution_channel_selected,
                "product": pdc_general_product_fields.pdc_gen_product,
                "checkPrices": pdc_general_product_fields.pdc_general_active_p,
                "supplier": pdc_supplier,
                "unitOfMeasure": pdc_general_product_fields.pdc_general_unit_measure.unit_measure_selected ? pdc_general_product_fields.pdc_general_unit_measure.unit_measure_selected : null,
                "distributionChargeLevyScheme": pdc_general_product_fields.pdc_general_distribution_charge.distribution_charge_selected ? pdc_general_product_fields.pdc_general_distribution_charge.distribution_charge_selected : null,
                "productValueLevyScheme": pdc_general_product_fields.pdc_general_levy_scheme.levy_scheme_selected ? pdc_general_product_fields.pdc_general_levy_scheme.levy_scheme_selected : null,
            }
            try {
                setState({ ...state, loader: true })
                const { data, status } = pdc_general_product_fields.hasEditData ? await axiosProductDistribution.put(`pdcs/${pdc_general_product_fields.hasEditData.id}`, { ...productData }) : await axiosProductDistribution.post(`pdcs`, { ...productData });
                if (data.success === true && data.resultMap) {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "success", })
                    setState({ ...state, loader: false, newProductData: data.resultMap.result })
                    dispatch(setLatestPdcData(data.resultMap.result.id))
                    dispatch(setHasEditData(data.resultMap.result))
                    // if(!pdc_general_product_fields.hasEditData) {
                    //     setOtherTabsOpen(true)
                    // } else {
                    //     setTimeout(() => {
                    //         window.location.reload();
                    //     }, 1500);
                    // }
                    setTimeout(() => {
                        // window.location.reload();
                        // dispatch(handleAddEditModal({
                        //     showAddEdit: true
                        // }));
                        //dispatch(filterFlagChange(true));
                        //dispatch(filterResetFlagChange(false));
                    }, 1500);
                }
            } catch (error) {
                setState({ ...state, loader: false })
                const { response: { data, status } } = error;
                setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error", })
                setTimeout(() => {
                    dispatch(handleAddEditModal({
                        showAddEdit: true
                    }))
                }, 3000);
            }
        }
    };

    const handleOtherTabsOpen = () => {
        // setTimeout(() => {
        //     dispatch(handleAddEditPdcPriceModal({
        //         showAddEditPrice: true
        //     }))
        //     dispatch(handleAddEditModal({
        //         showAddEdit: false
        //     }))
        // }, 1500);
        dispatch(setHasEditData(state.newProductData))
        setOtherTabsOpen(false)
    }
    const handleOtherTabsClose = () => {
        setOtherTabsOpen(false)
        setTimeout(() => {
            dispatch(handleAddEditModal({
                showAddEdit: false
            }))
            // window.location.reload();
            dispatch(filterFlagChange(true));
            dispatch(filterResetFlagChange(false));
        }, 1500);
    }

    const hidePriceModal = (event, reason, type) => {
        if (type === 'product') {
            setState({ ...state, pdcGeneralProductModal: event === false ? event : true })
        }
        if (type === 'supplier') {
            setState({ ...state, pdcGeneralSupplierModal: event === false ? event : true })
        }
    }

    useEffect(() => {
        if(pdc_general_product_fields.hasEditData) {
            setPdc_supplier(pdc_general_product_fields.hasEditData.supplier ? pdc_general_product_fields.hasEditData.supplier : "")
        }
    }, [pdc_general_product_fields.hasEditData !== null])

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Box sx={{ flexGrow: 1, paddingX: 2 }}>
                    <Grid container columnSpacing={2} sx={{ paddingLeft: '10px', paddingRight: '10px', marginBottom: 2 }}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Item sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} sm={12} md={6} >
                                        <Item sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={2} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="pdc_general_supplier" className="fontSemiBold mandatory">Supplier</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={9}>
                                                    <Item>
                                                        {/* <Box component="div" className=''>
                                                            <FormControl size={'small'} fullWidth>
                                                                <TextField
                                                                    inputProps={{ maxLength: 100 }}
                                                                    size="small"
                                                                    id="pdc_general_supplier"
                                                                    value={pdc_general_product_fields.pdc_general_supplier ? pdc_general_product_fields.pdc_general_supplier.name : ''}
                                                                    aria-describedby="pdc_general_supplier_error"
                                                                    name="pdc_general_supplier"
                                                                    disabled={true}
                                                                    error={pdc_general_product_fields.isError.pdc_general_supplier}
                                                                />
                                                            {pdc_general_product_fields.isError.pdc_general_supplier &&
                                                                                (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)}
                                                            </FormControl>
                                                        </Box> */}
                                                        <Autocomplete
                                                            value={pdc_supplier || null}
                                                            getOptionLabel={(option) => option.name || ""}
                                                            onChange={(_, newValue) => {
                                                                setPdc_supplier(newValue)
                                                                dispatch(brandSearch({
                                                                    parent: 'pdc_supplier',
                                                                    key: 'pdc_general_supplier',
                                                                    value: newValue
                                                                }))
                                                            }}
                                                            inputValue={pdc_supplierValue}
                                                            componentsProps={{
                                                                paper: {
                                                                    sx: {
                                                                        fontSize: '0.85rem'
                                                                    }
                                                                }
                                                            }}
                                                            onInputChange={(_, newInputValue) => {
                                                                setPdc_supplierValue(newInputValue)
                                                                dispatch(brandSearch({
                                                                    parent: 'pdc_supplier',
                                                                    key: 'pdc_general_supplier',
                                                                    value: newInputValue
                                                                }))
                                                            }}
                                                            id="mie_supplier_search"
                                                            options={pdc_general_product_fields.pdc_supplier.supplier_code_options}
                                                            renderInput={(params) =>
                                                                <FormControl size={'small'} fullWidth>
                                                                    <Box component="div" >
                                                                        <TextField {...params}
                                                                            size="small"
                                                                            id="pdc_supplier"
                                                                            aria-describedby="general_brand"
                                                                            error={pdc_general_product_fields.isError.pdc_general_supplier}
                                                                            />
                                                                        {pdc_general_product_fields.isError.pdc_general_supplier &&
                                                                                            (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)}
                                                                    </Box>
                                                                </FormControl>
                                                            }
                                                        />
                                                    </Item>
                                                </Grid>
                                                {/* <Grid item xs={12} md={1}>
                                                    <Item>
                                                        <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0, marginRight: '5px' }} onClick={(e) => resetFilter(e, 'supplier')} startIcon={<ClearIcon />}></Button>
                                                        <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={(e) => CommonPopUpModal(e, 'supplier')} startIcon={<EditIcon />}></Button>
                                                    </Item>
                                                </Grid> */}
                                            </Grid>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Item sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={3} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="pdc_general_distribution_channel" className="fontSemiBold mandatory">Distribution Channel</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={9}>
                                                    <Item>
                                                        <Box component="div" className=''>
                                                            <Autocomplete
                                                                // eslint-disable-next-line max-len
                                                                defaultValue={pdc_general_product_fields.pdc_general_distribution_channel.distribution_channel_selected || (pdc_general_product_fields.hasEditData ? pdc_general_product_fields.hasEditData.distributionChannel : null)}
                                                                id="pdc_general_distribution_channel_search"
                                                                // eslint-disable-next-line max-len
                                                                options={pdc_general_product_fields.pdc_general_distribution_channel.distribution_channel_options}
                                                                getOptionLabel={(option) => option.name || ""}
                                                                componentsProps={{
                                                                    paper: {
                                                                        sx: {
                                                                            fontSize: '0.85rem'
                                                                        }
                                                                    }
                                                                }}
                                                                onChange={(event, newValue) => handleSelection(event, newValue, 'pdc_general_distribution_channel', 'distribution_channel_selected')}
                                                                renderInput={(params) =>
                                                                    <FormControl size={'small'} fullWidth>
                                                                        <Box component="div" className=''>
                                                                            <TextField {...params}
                                                                                size="small"
                                                                                id="pdc_general_distribution_channel"
                                                                                aria-describedby="pdc_general_distribution_channel-error"
                                                                                error={pdc_general_product_fields.isError.distribution_channel_selected}
                                                                            />
                                                                            {pdc_general_product_fields.isError.distribution_channel_selected &&
                                                                                (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)}
                                                                        </Box>
                                                                    </FormControl>
                                                                }

                                                            />
                                                        </Box>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Item>
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={2} sx={{ paddingLeft: '10px', paddingRight: '10px', marginBottom: 2 }}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Item sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} sm={12} md={6} >
                                        <Item sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={2} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="pdc_gen_product" className="fontSemiBold mandatory">Product</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={9}>
                                                    <Item>
                                                        <Box component="div" className=''>
                                                            <FormControl size={'small'} fullWidth>
                                                                <TextField
                                                                    inputProps={{ maxLength: 100 }}
                                                                    size="small"
                                                                    id="pdc_gen_product"
                                                                    //value={pdc_general_product_fields['pdc_gen_product'] ? pdc_general_product_fields['pdc_gen_product'] : searchProductModal.query.name}
                                                                    value={pdc_general_product_fields.pdc_gen_product ? pdc_general_product_fields.pdc_gen_product.name : ''}
                                                                    aria-describedby="pdc_gen_product_error"
                                                                    name="pdc_gen_product"
                                                                    disabled={true}
                                                                    error={pdc_general_product_fields.isError.pdc_gen_product}
                                                                />
                                                                {pdc_general_product_fields.isError.pdc_gen_product &&
                                                                    (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)}
                                                            </FormControl>
                                                        </Box>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={1}>
                                                    <Item>
                                                        <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0, marginRight: '5px' }} onClick={(e) => resetFilter(e, 'product')} startIcon={<ClearIcon />}></Button>
                                                        <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={(e) => CommonPopUpModal(e, 'product')} startIcon={<EditIcon />}></Button>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Item sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={3} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="pdc_general_unit_measure" className="fontSemiBold">Unit of Measure</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={9}>
                                                    <Item>
                                                        <Box component="div" className=''>
                                                            <Autocomplete
                                                                // eslint-disable-next-line max-len
                                                                defaultValue={pdc_general_product_fields.pdc_general_unit_measure.unit_measure_selected || (pdc_general_product_fields.hasEditData ? pdc_general_product_fields.hasEditData.unitOfMeasure : null)}
                                                                id="pdc_general_unit_measure_search"
                                                                options={pdc_general_product_fields.pdc_general_unit_measure.unit_measure_options}
                                                                getOptionLabel={(option) => option.name || ""}
                                                                componentsProps={{
                                                                    paper: {
                                                                        sx: {
                                                                            fontSize: '0.85rem'
                                                                        }
                                                                    }
                                                                }}
                                                                onChange={(event, newValue) => handleSelection(event, newValue, 'pdc_general_unit_measure', 'unit_measure_selected')}
                                                                renderInput={(params) =>
                                                                    <FormControl size={'small'} fullWidth>
                                                                        <Box component="div" className=''>
                                                                            <TextField {...params}
                                                                                size="small"
                                                                                id="pdc_general_unit_measure"
                                                                                aria-describedby="pdc_general_unit_measure-error"
                                                                            />
                                                                        </Box>
                                                                    </FormControl>
                                                                }

                                                            />
                                                        </Box>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Item>
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={2} sx={{ paddingLeft: '10px', paddingRight: '10px', marginBottom: 2 }}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Item sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} sm={12} md={6} >
                                        <Item sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={2} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="pdc_general_levy_scheme" className="fontSemiBold">Product Value Levy Scheme</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={10}>
                                                    <Item>
                                                        <Box component="div" className=''>
                                                            <Autocomplete
                                                                // eslint-disable-next-line max-len
                                                                defaultValue={pdc_general_product_fields.pdc_general_levy_scheme.levy_scheme_selected || (pdc_general_product_fields.hasEditData ? pdc_general_product_fields.hasEditData.productValueLevyScheme : null)}
                                                                id="pdc_general_levy_scheme_search"
                                                                options={pdc_general_product_fields.pdc_general_levy_scheme.levy_scheme_options}
                                                                getOptionLabel={(option) => option.name || ""}
                                                                componentsProps={{
                                                                    paper: {
                                                                        sx: {
                                                                            fontSize: '0.85rem'
                                                                        }
                                                                    }
                                                                }}
                                                                onChange={(event, newValue) => handleSelection(event, newValue, 'pdc_general_levy_scheme', 'levy_scheme_selected')}
                                                                renderInput={(params) =>
                                                                    <FormControl size={'small'} fullWidth>
                                                                        <Box component="div" className=''>
                                                                            <TextField {...params}
                                                                                size="small"
                                                                                id="pdc_general_levy_scheme"
                                                                                aria-describedby="pdc_general_levy_scheme-error"
                                                                            />
                                                                        </Box>
                                                                    </FormControl>
                                                                }
                                                            />
                                                        </Box>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Item sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={3} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="pdc_general_distribution_charge" className="fontSemiBold">Distribution Charge Levy Scheme</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={9}>
                                                    <Item>
                                                        <Box component="div" className=''>
                                                            <Autocomplete
                                                                //eslint-disable-next-line max-len
                                                                defaultValue={pdc_general_product_fields.pdc_general_distribution_charge.distribution_charge_selected || (pdc_general_product_fields.hasEditData ? pdc_general_product_fields.hasEditData.distributionChargeLevyScheme : null)}
                                                                // eslint-disable-next-line max-len
                                                                id="pdc_general_distribution_charge_search"
                                                                options={pdc_general_product_fields.pdc_general_distribution_charge.distribution_charge_options}
                                                                getOptionLabel={(option) => option.name || ""}
                                                                componentsProps={{
                                                                    paper: {
                                                                        sx: {
                                                                            fontSize: '0.85rem'
                                                                        }
                                                                    }
                                                                }}
                                                                onChange={(event, newValue) => handleSelection(event, newValue, 'pdc_general_distribution_charge', 'distribution_charge_selected')}
                                                                renderInput={(params) =>
                                                                    <FormControl size={'small'} fullWidth>
                                                                        <Box component="div" className=''>
                                                                            <TextField {...params}
                                                                                size="small"
                                                                                id="pdc_general_distribution_charge"
                                                                                aria-describedby="pdc_general_distribution_charge-error"
                                                                            />
                                                                        </Box>
                                                                    </FormControl>
                                                                }
                                                            />
                                                        </Box>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Item>
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={2} sx={{ paddingLeft: '10px', paddingRight: '10px', marginBottom: 2 }}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Item sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} sm={12} md={6} >
                                        <Item sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={2} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="rate" className="fontSemiBold">Check Price</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={10}>
                                                    <Item>
                                                        <Box component="div" sx={{ paddingLeft: '5px' }}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox size="medium"
                                                                        id="pdc_general_active_p"
                                                                        checked={pdc_general_product_fields.pdc_general_active_p}
                                                                        onChange={(e) => handleCheckboxChange(e)}
                                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                                        name="pdc_general_active_p"
                                                                    />}
                                                                label="" />
                                                        </Box>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Item sx={{ paddingLeft: '0px', paddingRight: '0px' }}>

                                        </Item>
                                    </Grid>
                                </Grid>
                            </Item>
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={2} sx={{ paddingLeft: '10px', paddingRight: '10px', marginBottom: 2 }}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Item sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} sm={12} md={6} >
                                        <Item sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={2} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="rate" className="fontSemiBold">Current Price</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={10}>
                                                    <Item>
                                                        <Box component="div" className=''>
                                                            <FormControl size={'small'} fullWidth>
                                                                <TextField
                                                                    inputProps={{ maxLength: 100 }}
                                                                    size="small"
                                                                    id="pdc_cur_price"
                                                                    aria-describedby="pdc_cur_price_error"
                                                                    value={channelprice ? channelprice : 'No active price'}
                                                                    error={pdc_general_product_fields.isError.pdc_cur_price}
                                                                    name="pdc_cur_price"
                                                                    disabled={true}
                                                                />
                                                            </FormControl>
                                                        </Box>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Item sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={3} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="rate" className="fontSemiBold">Current Charge</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={9}>
                                                    <Item>
                                                        <Box component="div" className=''>
                                                            <FormControl size={'small'} fullWidth>
                                                                <TextField
                                                                    inputProps={{ maxLength: 50 }}
                                                                    size="small"
                                                                    id="pdc_cur_charge"
                                                                    aria-describedby="pdc_cur_charge_error"
                                                                    value={channelcharge ? channelcharge : 'No active charge'}
                                                                    name="pdc_cur_charge"
                                                                    className="inputNumber"
                                                                    disabled={true}
                                                                />
                                                            </FormControl>
                                                        </Box>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Item>
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={2} sx={{ paddingLeft: '10px', paddingRight: '10px', marginBottom: 3 }}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Item sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} sm={12} md={6} >
                                        <Item sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={2} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="rate" className="fontSemiBold">Current Promo Price</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={10}>
                                                    <Item>
                                                        <Box component="div" className=''>
                                                            <FormControl size={'small'} fullWidth>
                                                                <TextField
                                                                    inputProps={{ maxLength: 50 }}
                                                                    size="small"
                                                                    id="pdc_cur_promo"
                                                                    aria-describedby="pdc_cur_promo_error"
                                                                    value={channelpromoprice ? channelpromoprice : 'No active promotional price'}
                                                                    name="pdc_cur_promo"
                                                                    className="inputNumber"
                                                                    disabled={true}
                                                                />
                                                            </FormControl>
                                                        </Box>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Item sx={{ paddingLeft: '0px', paddingRight: '0px' }}>

                                        </Item>
                                    </Grid>
                                </Grid>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>

                <Grid container sx={{ marginTop: '20px' }}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Item>
                            <Grid container direction="row" alignItems="center" justifyContent="center">
                                <LoadingButton
                                    loading={state.loader}
                                    loadingPosition="center"
                                    endIcon=""
                                    variant="contained"
                                    type="submit" sx={{ marginRight: '15px' }}
                                    //disabled={state.formValid || state.defaultmode === false}
                                    // eslint-disable-next-line max-len
                                    disabled={pdc_general_product_fields.hasEditData ? (pdc_general_product_fields.isError.pdc_general_supplier || pdc_general_product_fields.isError.distribution_channel_selected || pdc_general_product_fields.isError.pdc_gen_product) : (state.formValid || state.defaultmode === false)}
                                //className={`${pdc_general_product_fields.pdcgeneralFormValid ? 'Mui-disabled' : ''}`}
                                >Save General Details</LoadingButton>
                                <Button variant="outlined" onClick={handleAddProduct}>Cancel</Button>
                            </Grid>
                        </Item>
                    </Grid>
                </Grid>
                <Modal
                    open={otherTabsOpen}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                    keepMounted
                    onClose={(_, reason) => {
                        if (reason !== "backdropClick") {
                            handleOtherTabsClose();
                        }
                    }}
                >
                    <Box component="div" className="overlay" id="addEditModal">
                        <div className={`overlay-box small_overlay`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-content">
                                        <Box component="div" sx={{ textAlign: 'center', padding: 1.5 }}>
                                            <p className="fontMedium">Would you like to add other details ?</p>
                                            <Grid sx={{ paddingTop: 2 }} container direction="row" alignItems="center" justifyContent="center">
                                                <Button variant="contained" type="submit" sx={{ marginRight: '15px' }} onClick={handleOtherTabsOpen}>Yes</Button>
                                                <Button variant="outlined" onClick={handleOtherTabsClose}>No</Button>
                                            </Grid>
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </form>
            {snackBar.show && <SnackBarDialog
                onShow={snackBar.show}
                text={snackBar.text}
                type={snackBar.type}
                onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
            />}
            {
                state.pdcGeneralProductModal && (
                    <CommonPopUp
                        onShow={state.pdcGeneralProductModal}
                        size="large_overlay"
                        multipleFilter={true}
                        memberaccount={(e, reason) => getProductDetail(e, 'product')}
                        onHide={(e, reason) => hidePriceModal(e, reason, 'product')}
                    />
                )
            }
            {
                state.pdcGeneralSupplierModal && (
                    <CommonPopUp
                        onShow={state.pdcGeneralSupplierModal} size="medium_overlay"
                        supplierMemberCode={{
                            title: 'Supplier Details',
                            type: 'supplier'
                        }}
                        memberaccount={(e, reason) => getProductDetail(e, 'supplier')}
                        onHide={(e, reason) => hidePriceModal(e, reason, 'supplier')}
                    />
                )
            }
        </>
    );
};
export default General;