import {
    DataGridPremium,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
    gridPaginationRowRangeSelector,
    gridPaginatedVisibleSortedGridRowIdsSelector,
    gridSortedRowIdsSelector,
    gridVisibleSortedRowIdsSelector,
    GridToolbarContainer
} from '@mui/x-data-grid-premium';
import { Box, Button, Checkbox, Container, FormControl, FormLabel, Grid, IconButton, Menu, MenuItem, Pagination, PaginationItem, Paper, Select, Stack, TextField } from "@mui/material";
import { useEffect, useLayoutEffect, useState } from "react";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SendIcon from '@mui/icons-material/Send';
import axios_accounting_entries from '../../../services/utility/axios-accounting-entries';
import PageLoader from "../PageLoader";


export const DataTableAccountingEntriesDetails = (props) => {
    const { columns, defaultGrid } = props;

    const [tableState, setTableState] = useState({
        isLoading: false,
        data: [],
        total: 0,
        page: 1,
        pageSize: 10,
        applyFilterCount: null
    });
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    function CustomPagination() {
        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        const rowDataList = [10, 20]

        const handleChange = (event) => {
            setTableState({ ...tableState, pageSize: event.target.value, page: 1 })
        };

        const paginationChange = (event, value) => {
            apiRef.current.setPage(value - 1);
            setTableState({ ...tableState, page: value })
        }
        return (
            <>
                <div className='grid-pagiation'>
                    <Box className='customRowPerPageBox' component="div">
                        <div>
                            View
                        </div>
                        <Box component="div" sx={{ marginX: '10px' }}>
                            <FormControl>
                                <Select
                                    size="small"
                                    id="row-per-page"
                                    defaultValue="choose"
                                    onChange={handleChange}
                                    value={tableState.pageSize}
                                    aria-describedby={'row-per-page'} >
                                    {rowDataList.map((value) => {
                                        return (
                                            <MenuItem value={value} key={value}>{value}</MenuItem>
                                        )
                                    }

                                    )}
                                </Select>

                            </FormControl>
                        </Box>
                        <div>
                            rows per page
                        </div>
                    </Box>
                    <Pagination
                        variant="outlined"
                        shape="rounded"
                        count={pageCount}
                        page={page + 1}
                        onChange={(event, value) => paginationChange(event, value)}
                        renderItem={(item) => (
                            <PaginationItem
                                components={{ previous: KeyboardArrowLeftIcon, next: KeyboardArrowRightIcon }}
                                {...item}
                            />
                        )}
                    />
                </div>
            </>
        );
    }
    const onPageChange = (newPage) => {
        setTableState({ ...tableState, page: newPage + 1 });
    };

    const onPageSizeChange = (newPageSize) => {
        setTableState({ ...tableState, pageSize: newPageSize });
    };

    useEffect(() => {
        getPayMemberDatesList(defaultGrid.apiUrl)
    }, [tableState.page, tableState.pageSize])

    const getPayMemberDatesList = async () => {
        try {
            setTableState({ ...tableState, isLoading: true, data: [], total: 0 })
            const { data } = await axios_accounting_entries.get(`${defaultGrid.apiUrl}?page=${tableState.page}&pageSize=${tableState.pageSize}`)
            if (data) {
                setTableState({ ...tableState, isLoading: false, data: data.items, totalRows: data.totalCount, total: data.totalCount, applyFilterCount: null });
            }
        } catch (error) {
            const { response: { data, status }, message } = error;
            if (status === 500) {
                setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Something went wrong!", type: "error" });
            } else {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
            }
            setTableState({ ...tableState, isLoading: false, data: [], total: 0, page: 1, pageSize: 10 })
        }
    }

    const getFilteredRows = ({ apiRef }) => gridVisibleSortedRowIdsSelector(apiRef);
    const CustomToolbar = () => {
        const apiRef = useGridApiContext();
        const handleExport = (options) => apiRef.current.exportDataAsExcel(options);

        return (
            <GridToolbarContainer sx={{ float: 'right', margin: '10px' }}>

                <Button variant="contained" disabled={tableState.data.length > 0 ? false : true} color="secondary" size='small' onClick={() => handleExport({ getRowsToExport: getFilteredRows, fileName: defaultGrid.pagetitle })} startIcon={<SendIcon />}>
                    Export as Excel
                </Button>
            </GridToolbarContainer>
        );
    };

    return (
        <>
            <Container disableGutters={true} maxWidth={false}>
                <Box component="div" className='table-actions-buttons' sx={{ columnGap: 1.75, display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2.5 }}>
                    <Box component="div" className='data-per-row-message'>
                        Showing {tableState.pageSize *
                            (tableState.page - 1) + 1} to {((tableState.total < (tableState.pageSize * tableState.page)) ? tableState.total : (tableState.pageSize * tableState.page))} out of {tableState.total} entries
                    </Box>
                </Box>
                <div style={{ width: '100%' }}>
                    {tableState.isLoading && <PageLoader />}
                    <div style={{ height: '468px', width: '100%' }}>
                        <DataGridPremium
                            rows={tableState.data}
                            columns={columns ? columns : []}
                            // autoHeight={true}
                            // disableColumnMenu={true}
                            rowCount={tableState.total}
                            //loading={tableState.isLoading}
                            pagination={true}
                            page={tableState.page - 1}
                            pageSize={tableState.pageSize}
                            paginationMode="server"
                            onPageChange={(newPage) => onPageChange(newPage)}
                            onPageSizeChange={(newPageSize) => onPageSizeChange(newPageSize)}
                            components={{
                                Pagination: CustomPagination,
                                Toolbar: CustomToolbar,
                                NoRowsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        No search results were found
                                    </Stack>
                                ),
                                NoResultsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        Local filter returns no result
                                    </Stack>
                                )
                            }}
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? '' : 'oddRow'
                            }
                            initialState={{ pinnedColumns: { right: ['action'] } }}
                            sx={{
                                "& .MuiDataGrid-columnHeader": {
                                    backgroundColor: "#F0F0F0"
                                    // color: "red"
                                },
                                "& .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle": {
                                    fontWeight: "600",
                                    fontSize: '14px',
                                    color: "#15191E"
                                },
                                "& .MuiDataGrid-row.oddRow": {
                                    backgroundColor: "#FBFBFB"
                                    // color: "red"
                                },
                                "& .MuiDataGrid-row.Mui-hovered, .MuiDataGrid-row.Mui-selected, .MuiDataGrid-row.Mui-selected.Mui-hovered": {
                                    backgroundColor: "#FFF5E1"
                                    // color: "red"
                                },
                                '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
                                    borderRight: `1px solid #E6E6E6`,
                                    borderBottom: `1px solid #E6E6E6`,
                                },
                                '& .MuiDataGrid-cell': {
                                    fontWeight: '500',
                                },
                            }}
                        />
                    </div>

                </div>
            </Container>

        </>
    );
}
