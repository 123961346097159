export const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_MS_CLIENT_ID, // "f2bf4ef9-9c88-4399-a6e4-514159cc0f64", // process.env.REACT_APP_MS_CLIENT_ID
      authority: process.env.REACT_APP_MS_TENENT_URL, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})  "https://login.microsoftonline.com/19c14640-100f-4ce7-b578-2cf1b2ea3973"
      redirectUri: process.env.REACT_APP_MS_REDIRECT_URL , //   "http://localhost:3000"
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read"]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
  };