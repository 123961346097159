import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selected_supplier: null,
  selectedIds: [],
  selectedIdsRows: [],
  startReleaseSystemDispute: false,
  confirmReleaseSystemDispute: false,
}

const invoiceDisputes = createSlice({
  name: 'invoice_disputes',
  initialState,
  reducers: {
    setSelectedIds: (state, action) => {
      state.selectedIds = action.payload
    },
    setSelectedIdsRows: (state, action) => {
      state.selectedIdsRows = action.payload
    },
    releaseSystemDisputeStatus: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value
    },
    resetfailedImports: () => {
      return initialState
    },
  }
})

export const { setSelectedIds, setSelectedIdsRows, resetfailedImports, failedImportDeleteYes, releaseSystemDisputeStatus } = invoiceDisputes.actions

export default invoiceDisputes.reducer