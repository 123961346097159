import { DataTableMemberSupplierReports } from "../../components/shared/data-table/DataTableMemberSupplierReports";
import { Container, Box, Modal, Button, Grid, Checkbox, Popper, Grow, Paper, ClickAwayListener, MenuList, ButtonGroup, Stack, TextField, FormControl, FormHelperText, FormLabel, Typography, Autocomplete } from '@mui/material'
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TableFilters } from "../../components/shared/data-table/TableFilters";
import { useEffect, useState } from "react";
import { InputText } from "../../components/shared/form/InputText";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from "moment";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AutoComplete } from "../../components/shared/form/AutoComplete";
import { SelectDropDown } from "../../components/shared/form/Select";
import { } from '@mui/material';
import { ThreeStateCheckbox } from "../../components/shared/form/ThreeStateCheckbox";
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange, setMemberGroupReportFromDate, filterResetFlagChange } from "../../store/slices/externalFilter";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/material/styles';
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import SendIcon from '@mui/icons-material/Send';
import { warningModal, handleAddEditModal, handleModalState } from "../../store/slices/modals";
import { smaInput, setMemberCode } from "../../store/slices/supplierMemberAccounts";
import WarningModal from "../../components/shared/modals/WarningModal";
import { setRowPage, resetDataGrid, setPageNo } from '../../store/slices/dataGrid';
import { useLayoutEffect } from "react";
import { AutoCompleteStatic } from "../../components/shared/form/AutoCompleteStatic";
import { saveSomeData } from "../../services/utility/common-utils";
import axiosNusslReports from "../../services/utility/axios-nussl-reports";
import axiosMember from '../../services/utility/axios-member';
import SnackBarDialog from "../../components/shared/SnackBarDialog";
import PageLoader from "../../components/shared/PageLoader";
import EmailSendModal from "./EmailSendModal";
import { setEmailInfo } from "../../store/slices/supplierReports";
import CommonPopUp from "../products/addEditPdc/pdc-tabs/CommonPopUp";
import { useRef } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import axiosEmailService from "../../services/utility/axios-email-service";
import { setEmailStatus } from "../../store/slices/membersupplierReports";


const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(2),
    boxShadow: "none",
    background: "none"
}));

const IconMenuLink = ({ params, onDownload, onEmailSend }) => {
    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { showAddEditModal, general_product_fields } = useSelector((state) => {
        return {
            showAddEditModal: state.modalActions.hideAddEditModal,
            editTabData: state.generalProduct.editTabData,
            general_product_fields: state.generalProduct
        }
    });
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'download') {
            if (onDownload) {
                onDownload(row)
            }
        }
        if (action === 'email') {
            if (onEmailSend) {
                onEmailSend(row)
            }
        }
        setAnchorEl(null);
    };
    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >

            <MenuItem onClick={(e) => handleClose(e, 'download')}>Download</MenuItem>
            {params.row.emailReportStatus === 'SentAndSucceeded' ? (<MenuItem onClick={(e) => handleClose(e, 'email')}>Resend Email</MenuItem>) : (<MenuItem onClick={(e) => handleClose(e, 'email')}>Send Email</MenuItem>)}

        </Menu>
    </>)
}
function MemberReport() {
    const columns = [
        {
            headerName: "#",
            field: "uid",
            minWidth: 50,
            
        },
        {
            headerName: "Email log id",
            field: "emailLogId",
            minWidth: 50,
            hide: true
        },
        {
            headerName: "Report Id",
            field: "id",
            minWidth: 50,
        },
        {
            headerName: "PID",
            field: "processId",
            minWidth: 100,
        },
        {
            headerName: "Created on",
            field: "creationDate",
            minWidth: 120,

        },
        {
            headerName: "File Name",
            field: "fileName",
            minWidth: 200,
            flex: 1.5,
        },
        {
            headerName: "SC Id",
            field: "statementCycleId",
            minWidth: 50,

        },
        {
            headerName: "SC Stage",
            field: "statementCycleStage",
            minWidth: 60,
            flex: 1.5,
        },
        {
            headerName: "Email Status",
            field: "emailReportStatusLabel",
            minWidth: 90,
            flex: 1.5,
        },
        {
            headerName: "Email Date",
            field: "emailSentDate",
            minWidth: 50,

        },
        {
            headerName: "Report Type",
            field: "typeLabel",
            minWidth: 90,
            flex: 1.5,
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            minWidth: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} onDownload={onDownload} onEmailSend={onEmailSend} />;
            },
        }
    ];

    const outputTypes = [{ text: 'Statement Cycle', value: 'StatementCycle' }, { text: 'Member Invoices', value: 'MemberInvoices' }];
    const EmailStatus = [{ text: 'Not Sent', value: 'NotSent' }, { text: 'Sent and Failed', value: 'SentAndFailed' }, { text: 'Sent and Succeeded', value: 'SentAndSucceeded' }];

    const [state, setState] = useState({
        openNewProductModal: false,
        selectMemberflag: true,
        memberAccountEndpoint: '',
        selectMemberstatus: 'Please Select Member First',
        disableBulkEmail: true,
        defaultGrid: {
            pagetitle: "Member Group Reports",
            productActionButton: true,
            hasExport: true,
            addEditButton: true,
            apiUrl: 'memberGroupReports',
            searchUrl: 'memberGroupReports/filter'
        },
        pageLoader: false
    });

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const [mbacs, setMbAcs] = useState({

        mbac_selected: null,
        mbac_options: []
    })

    const { fileNames, fromDatev, outputType, emailReportstatus, memberAccount, addProductwarning, showAddEditModal, handleEmailModal, sma_container, reportMember, emailrowList } = useSelector((state) => {
        return {
            fileNames: state.externalFilter.mgr_file_name,
            fromDatev: state.externalFilter.mgr_from_date,
            outputType: state.externalFilter.mgr_output_type,
            emailReportstatus: state.externalFilter.mgr_email_status,
            reportMember: state.externalFilter.report_member,
            memberAccount: state.externalFilter.mgr_member_account,
            resetTable: state.dataGridTable,
            filterFlag: state.externalFilter.filterApplied,
            addProductwarning: state.modalActions.closeWaringAddProduct,
            showAddEditModal: state.modalActions.hideAddEditModal,
            handleEmailModal: state.modalActions.handleEmailModal,
            sma_container: state.smas,
            emailrowList: state.membersupplierReports
        }
    });

    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const uname = accounts[0] && accounts[0].name;

    const dispatch = useDispatch();

    const handleChange = (checked, name) => {
        setState({ ...state, [name]: checked })
        dispatch(setFilter({
            key: name,
            value: checked
        }))
    };

    const handleFilterInput = (value, id) => {
        //setState({ ...state, [id]: value });
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))

        if (value !== null) {
            if (id === 'report_member') {
                if (value.id) {
                    axiosMember.get(`members/${value.id}/memberAccounts`)
                        .then(function (response) {
                            // handle success
                            const { status, data } = response;
                            if (data) {
                                const unique = data;
                                setMbAcs({ ...mbacs, mbac_options: unique });
                                if (unique.length > 0) {
                                    state.memberAccountEndpoint = "members/" + value.id + "/memberAccounts";
                                    state.selectMemberflag = false;
                                }
                                else {
                                    state.selectMemberstatus = 'No Member Account present for this Member'
                                    state.selectMemberflag = true;
                                }

                            }
                        })
                        .catch(function (error) {
                            // handle error
                            console.log(error);
                        });

                }


            }

        }
        if (id === 'report_member' && value !== null) {
            setState({ ...state, selectMemberflag: false })

        } else {
            if (id === 'report_member') {
                setState({ ...state, selectMemberflag: true })
                // eslint-disable-next-line prefer-destructuring
                const ele = autoC.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
                // eslint-disable-next-line prefer-destructuring
                const ele2 = autoC.current.getElementsByClassName('MuiOutlinedInput-input')[0];
                if (ele) {
                    ele.click();
                    ele2.blur()

                }
                setTimeout(() => {
                    setMbAcs({ ...mbacs, mbac_selected: null })
                }, 250);
            }
        }

    };

    const handleStartDate = (event) => {
        const sDate = (event === null) ? null : moment(event, 'DD/MM/YYYY')
        dispatch(setMemberGroupReportFromDate(sDate))
    };

    const selectedAutoComplete = (value, id) => {
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    }

    const filterBtn = () => {
        dispatch(resetDataGrid());
        var dynamicFilterObjects = {};
        let otypeCheck = false;

        if (fileNames !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "fileName": fileNames
            }
        }
        if (fromDatev !== null && fromDatev !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "fromDate": moment(fromDatev).format('DD/MM/YYYY')
            }
        }
        if (outputType !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "type": outputType
            }
            if (outputType === "StatementCycle") {
                otypeCheck = true;
            }
            else {
                otypeCheck = false;
            }
        }
        else {
            otypeCheck = true;
        }
        if (emailReportstatus !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "emailReportStatus": emailReportstatus
            }
        }
        if (reportMember !== '' || reportMember !== null) {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "member": reportMember.id
            }
        }
        if (memberAccount && memberAccount !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "memberAccountId": memberAccount.id
            }
        }

        if (fromDatev !== "" && fromDatev !== null && moment(fromDatev).format('DD/MM/YYYY') === 'Invalid date') {
            setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
        }
        else {
            dispatch(applyFilter({
                key: 'filterList',
                value: dynamicFilterObjects
            }));
            dispatch(filterFlagChange(true));
            dispatch(filterResetFlagChange(false));
            dispatch(setRowPage({ pageData: 10 }));
            dispatch(setPageNo({ page: 1 }));

            if (otypeCheck === false) {
                setState({ ...state, disableBulkEmail: false })
            }
            else {
                setState({ ...state, disableBulkEmail: true })
            }
        }


    }
    const resetFilter = () => {
        if ((fileNames || outputType || fromDatev || emailReportstatus || memberAccount || reportMember) !== '') {
            dispatch(resetExternalFilter())
            dispatch(resetDataGrid());
            dispatch(smaInput({
                key: 'sma_member_name',
                value: ""
            }))
            setMbAcs({ ...mbacs, mbac_options: [], mbac_selected: null })
        }
        dispatch(setMemberCode({
            key: 'smaMemberCodeOnModal',
            value: ""
        }))
        dispatch(filterResetFlagChange(true));
        setState({ ...state, disableBulkEmail: true });
    }
    const addProductModal = () => {
        dispatch(handleAddEditModal({
            showAddEdit: !showAddEditModal
        }))
    }
    const hideNewProductModal = (event) => {
        setState({ ...state, openNewProductModal: false })
    }
    const onDownload = async (params) => {

        try {
            setState({ ...state, pageLoader: true })
            const fileDetails = {
                id: params.id,
                fileName: params.fileName
            }
            const { data } = await axiosNusslReports.post('/download/report', fileDetails);
            if (data) {

                setState({
                    ...state,
                    pageLoader: false,
                })

                saveSomeData(data.content, params.mimeType, data.fileName)
                setSnackBar({ ...snackBar, show: true, text: "File downloaded!", type: "success" })
            }

        } catch (error) {
            const { response: { data, status } } = error;
            if (!data) {
                setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
            } else {
                setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
            }
            setState({ ...state, pageLoader: false })
        }
    }
    const onEmailSend = (row) => {
        dispatch(setEmailInfo({ ...row, supplierORmember: 'member' }))
        dispatch(handleModalState({
            key: 'handleEmailModal',
            value: true
        }))
    }

    const resetLookups = () => {
        dispatch(setMemberCode({
            key: 'smaMemberCodeOnModal',
            value: ""
        }))
        dispatch(smaInput({
            key: 'sma_member_name',
            value: ""
        }))
        setMbAcs({ ...mbacs, mbac_options: [], mbac_selected: null });
        setState({ ...state, selectMemberflag: true });
    }

    const CommonPopUpModal = (e, type) => {
        setState({ ...state, supplierMemberDasboadModal: true, handleSupplierMemberModal: type })
    }

    const hidePriceModal = (event, reason, type) => {
        if (reason) {
            dispatch(setFilter({
                key: 'report_member',
                value: reason ? { "id": reason.id, "name": reason.name, "active": reason.active } : null
            }))

            axiosMember.get(`members/${reason.id}/memberAccounts`)
                .then(function (response) {
                    // handle success
                    const { status, data } = response;
                    if (data) {
                        const unique = data;
                        setMbAcs({ ...mbacs, mbac_options: unique });
                        if (unique.length > 0) {
                            state.memberAccountEndpoint = "members/" + reason.id + "/memberAccounts";
                            //state.selectMemberflag = false;
                            setState({ ...state, selectMemberflag: false, supplierMemberDasboadModal: event === false ? event : true })
                        }
                        else {
                            //state.selectMemberstatus = 'No Member Account present for this Member'
                            setState({ ...state, selectMemberflag: true, selectMemberstatus: 'No Member Account present for this Member', supplierMemberDasboadModal: event === false ? event : true })
                        }

                    }
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                    setState({ ...state, supplierMemberDasboadModal: event === false ? event : true })
                });

        } else {
            setState({ ...state, supplierMemberDasboadModal: event === false ? event : true })
        }
    }

    useEffect(() => {
        dispatch(setMemberCode({
            key: 'smaMemberCodeOnModal',
            value: ""
        }))
        /* load first first time */
        filterBtn();
    }, []);

    useEffect(() => {

        // eslint-disable-next-line prefer-destructuring
        const ele = autoC.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
        // eslint-disable-next-line prefer-destructuring
        const ele2 = autoC.current.getElementsByClassName('MuiOutlinedInput-input')[0];
        if (ele) {
            ele.click();
            ele2.blur()

        }

    }, [reportMember]);

    const autoC = useRef(null);

    const sendBulkEmailsDispatch = () => {
        
        dispatch(setEmailStatus({
            key: 'memberStartBulkEmail',
            value: true
        }))
        dispatch(warningModal({
            yesHide: !addProductwarning
        }));
    }

    useEffect(() => {
        if (emailrowList.memberConfirmBulkEmail) {
            sendBulkEmails();
        }
    }, [emailrowList.memberConfirmBulkEmail]);    

    const sendBulkEmails = async (event, params) => {        
        if(event) { event.preventDefault(); }

        let processId = emailrowList.sendEmailRows;
        let payload = null;

        if (processId.length > 0) {
            try {
                // console.log(processId);
                setState({ ...state, pageLoader: true })
                const selectedRowsData = processId.map((items) => {
                    return {
                        "emailType": "memberemail",
                        "user": uname,
                        "manualEmailArgument": {
                            "report": {
                                "fileName": items.fileName,
                                "mimeType": items.mimeType,
                                "id": items.id
                            },
                            "emailto": (items.email !== '' ? items.email : null ),
                            "memberAccount": items.memberAccountID ? Number(items.memberAccountID) : ""
                        }
                    }
                });
                // console.log(selectedRowsData);             

                const { data } = await axiosEmailService.post('/sqs/email', selectedRowsData);
                if (data) {
                    resetBulkEmailsDispatch();            
                    setState({ ...state, pageLoader: false })
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" });
                    dispatch(filterFlagChange(true));
                    dispatch(filterResetFlagChange(false));              
                }
                else
                {
                    setSnackBar({ ...snackBar, show: true, text: "Bulk email process failed!", type: "error" })
                }


            } catch (error) {
                console.log(error);
                // const { response: { data, status } } = error;
                // if (!data) {
                //     setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                // } else {
                //     setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                // }
                setState({ ...state, pageLoader: false });
                resetBulkEmailsDispatch();
            }
        } else {
            setSnackBar({ ...snackBar, show: true, text: "No reports available for mail!", type: "error" });
            resetBulkEmailsDispatch();
        }
    }

    const resetBulkEmailsDispatch = () => {
        dispatch(setEmailStatus({
            key: 'memberStartBulkEmail',
            value: false
        }));
        dispatch(setEmailStatus({
            key: 'memberConfirmBulkEmail',
            value: false
        }));
    }

    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Member Group Reports" />
                <Box sx={{ marginBottom: 2.5 }}>
                    <TableFilters
                        filterFields={<>

                            <Box component="div"><SelectDropDown onChange={handleFilterInput} values={outputTypes} size="small" id="mgr_output_type" label="Output Type" name="mgr_output_type" /></Box>
                            <Box component="div">
                                <FormLabel htmlFor="price_start_date" className="fontBold">From Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        value={fromDatev}
                                        onChange={(e) => { handleStartDate(e) }}
                                        name="mgr_from_date" label="From Date"
                                        // eslint-disable-next-line max-len
                                        inputFormat="DD/MM/YYYY"
                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                            <Box component="div" sx={{ paddingTop: '6px' }} className='date-picker-input'>

                                                <FormControl size="small" fullWidth>
                                                    <TextField size="small" id="mgr_from_date"
                                                        ref={inputRef} {...inputProps}
                                                        placeholder=""
                                                        aria-describedby="mgr_from_date"
                                                        autoComplete="off"

                                                    />

                                                    <Box className='date-picker-icon'>
                                                        {InputProps?.endAdornment}
                                                    </Box>
                                                </FormControl>
                                            </Box>
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>
                            <Box component="div"><InputText onChange={handleFilterInput} maxWidth={50} label="File Name" id="mgr_file_name" size="small" /></Box>
                            <Box component="div"><SelectDropDown onChange={handleFilterInput} values={EmailStatus} size="small" id="mgr_email_status" label="Email Status" name="mgr_email_status" /></Box>
                            <Box component="div"><AutoCompleteStatic endPoint="members/look-up?pageSize=500" onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} id="report_member" label="Member" name="report_member" /></Box>
                            {/* <Box component="div">
                                <FormLabel htmlFor="report_member" className="fontBold">Member</FormLabel>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={11}>
                                        <Item>
                                            <Box component="div" sx={{ paddingTop: '6px' }}>
                                                <FormControl size={'small'} fullWidth>
                                                    <TextField
                                                        inputProps={{ maxLength: 100 }}
                                                        size="small"
                                                        id="report_member"
                                                        value={(sma_container.smaMemberCodeOnModal ? sma_container.smaMemberCodeOnModal.name : '')}
                                                        aria-describedby="report_member_error"
                                                        name="report_member"
                                                        disabled={true}
                                                    />

                                                </FormControl>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        <Item>
                                            <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0, marginRight: '5px' }} onClick={resetLookups} startIcon={<ClearIcon />}></Button>
                                            <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={CommonPopUpModal} startIcon={<EditIcon />}></Button>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box> */}
                            <Box component="div">
                                {/* <AutoCompleteStatic endPoint={state.memberAccountEndpoint} onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} id="mgr_member_account" label="Member Account" name="mgr_member_account" /> */}
                                <FormLabel htmlFor="mgr_member_account" className="fontBold">Member Account</FormLabel>
                                <Autocomplete
                                    ref={autoC}
                                    id="mgr_member_account"
                                    disabled={state.selectMemberflag}
                                    options={mbacs.mbac_options}
                                    getOptionLabel={(option) => option.name || ""}
                                    componentsProps={{
                                        paper: {
                                            sx: {
                                                fontSize: '0.85rem'
                                            }
                                        }
                                    }}
                                    onChange={(event, newValue) => {
                                        if ((event.target.value !== "" || event.target.value !== undefined) && newValue !== null) {
                                            // setProductvls({ ...productvls, pvls_selected: newValue })
                                            setMbAcs({ ...mbacs, mbac_selected: { id: newValue.id, name: newValue.name } })
                                            dispatch(setFilter({
                                                key: 'mgr_member_account',
                                                value: newValue ? newValue : null
                                            }))
                                        } else {
                                            setMbAcs({ ...mbacs, mbac_selected: null })
                                            dispatch(setFilter({
                                                key: 'mgr_member_account',
                                                value: null
                                            }))
                                        }
                                    }}
                                    // onChange={handleFilterInput}
                                    renderInput={(params) =>
                                        <FormControl size={'small'} sx={{ paddingTop: '6px' }} fullWidth>
                                            <div className="input-group">
                                                <TextField {...params}

                                                    size="small"
                                                    id="mgr_member_account_search"
                                                    aria-describedby="mgr_member_account-error"
                                                />
                                            </div>
                                        </FormControl>

                                    }
                                />
                                {/* {
                                        state.selectMemberflag && <Typography sx={{ position: 'absolute', padding: '5px 2px', fontSize: '14px' }}>{state.selectMemberstatus}</Typography>

                                    } */}
                            </Box>
                        </>}
                        filterCheckboxes={<>

                        </>}
                        filterBtns={
                            <>
                                <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                <Button variant="contained" onClick={filterBtn}>Apply</Button>
                            </>}>
                    </TableFilters>
                </Box>
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -3 } }}>
                    <div className='table-actionable'>
                        <Button variant="contained" size='small' disabled={state.disableBulkEmail} onClick={(e) => { sendBulkEmailsDispatch(e) }} startIcon={<SendIcon />}>
                            Send Email
                        </Button>
                    </div>
                </Box>
                <DataTableMemberSupplierReports defaultGrid={state.defaultGrid} columns={columns}>

                </DataTableMemberSupplierReports>
                {
                    handleEmailModal && (
                        <EmailSendModal
                            onShow={handleEmailModal}
                            onHide={(e, reason) => hideNewProductModal(e, reason)}

                        />
                    )
                }
                {
                    addProductwarning && (
                        <WarningModal size="small_overlay" onShow={addProductwarning} />
                    )
                }
                {snackBar.show && <SnackBarDialog
                    onShow={snackBar.show}
                    text={snackBar.text}
                    type={snackBar.type}
                    onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                />}
            </Container>

            {
                state.supplierMemberDasboadModal && (
                    <CommonPopUp
                        onShow={state.supplierMemberDasboadModal} size="medium_overlay"
                        supplierMemberCode={{
                            title: 'Member Details',
                            type: 'members'
                        }}
                        onHide={(e, reason) => hidePriceModal(e, reason, state.handleSupplierMemberModal)}
                    />
                )
            }

        </>
    );
}

export default MemberReport;
