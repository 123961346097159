import { Box, Modal, Paper, FormControl, Grid, TextField, FormHelperText, FormLabel, Button, FormControlLabel, Checkbox, Autocomplete, RadioGroup, Radio } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleModalState } from "../../store/slices/modals";
import SnackBarDialog from '../../components/shared/SnackBarDialog';
import PageLoader from '../../components/shared/PageLoader';
import { setEmailInfo } from '../../store/slices/supplierReports';
import axiosEmailService from '../../services/utility/axios-email-service';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { filterFlagChange, filterResetFlagChange } from '../../store/slices/externalFilter';


const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

export default function EmailSendModal({ onShow }) {
    const { handleEmailModal, emailDetail } = useSelector((state) => {
        return {
            handleEmailModal: state.modalActions.handleEmailModal,
            emailDetail: state.supplierReport.sendEmailInfo
        }
    });


    const dispatch = useDispatch();

    const [state, setState] = useState({
        pageLoader: false,
        supplierEmailList: [],
        memberEmailList: [],
        emailField: '',
        formValid: false
    });

    useEffect(() => {
        
        if(emailDetail.email !== null) {
            const list = emailDetail.email.split(';');
            if (emailDetail.supplierORmember === 'supplier') {
                setState({ ...state, supplierEmailList: list });
            } else if (emailDetail.supplierORmember === 'member') {
                setState({ ...state, memberEmailList: list });
            }
        }
        
    }, [])
    const [isError, setIsError] = useState({
        emailField: false
    });

    const [type, setType] = useState('');
    const [memberEmail, setMemberEmail] = useState('');

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const uname = accounts[0] && accounts[0].name;

    const handleClose = () => {
        dispatch(handleModalState({
            key: 'handleEmailModal',
            value: false
        }))
        dispatch(setEmailInfo(null));
        dispatch(filterFlagChange(true));
        dispatch(filterResetFlagChange(false));
    };

    const handleInputChange = (event) => {
        const { value, name } = event.target;
        if (!(/.+@.+\.[A-Za-z]+$/.test(value))) {
            setIsError({ ...isError, emailField: true })
            setState({ ...state, [name]: value, formValid: true })
        } else {
            setIsError({ ...isError, emailField: false })
            setState({ ...state, [name]: value, formValid: false })
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            let payload = null;

            if (memberEmail !== '' || state.emailField !== '') {
                setState({
                    ...state,
                    pageLoader: true,
                })
                if (emailDetail.supplierORmember === 'supplier') {
                    payload = [{
                        "emailType": "supplieremail",
                        "user": uname,
                        "supplierGroupReportSearchArguments": {
                            "fileName": emailDetail.fileName,
                            "fromDate": emailDetail.creationDate,
                            "supplierId": emailDetail.supplierId,
                            "type": emailDetail.type,
                            "emailReportStatus": emailDetail.emailReportStatus !== 'Sent and succeeded' ? 'NotSent' : '',
                            "email": (memberEmail !== '' ? memberEmail : state.emailField !== '' ? state.emailField : null )
                        }
                    }]
                } else {
                    payload = [{
                        "emailType": "memberemail",
                        "user": uname,
                        "manualEmailArgument": {
                            "report": {
                                "fileName": emailDetail.fileName,
                                "mimeType": emailDetail.mimeType,
                                "id": emailDetail.id
                            },
                            "emailto": (memberEmail !== '' ? memberEmail : state.emailField !== '' ? state.emailField : null ),
                            "memberAccount": emailDetail.memberAccountID ? Number(emailDetail.memberAccountID) : ""
                        }
                    }]
                }
                const { data } = await axiosEmailService.post('/sqs/email', payload);
                if (data) {
                    setState({
                        ...state,
                        pageLoader: false,
                    })
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
                    setTimeout(() => {
                        handleClose()
                    }, 1500);
                }
            } else {
                setSnackBar({ ...snackBar, show: true, text: "Please enter/select email id", type: "error" })
            }


        } catch (error) {
            const { response: { data, status } } = error;
            if (!data) {
                setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
            } else {
                setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
            }
            setState({ ...state, pageLoader: false })
            setTimeout(() => {
                handleClose()
            }, 1500);
        }

    }

    const onChangeType = (event) => {
        setType(event.target.value);
        setMemberEmail('')
        setState({ ...state, emailField: '', })
        setIsError({ ...isError, emailField: false })
    };
    const onChangeEmail = (event) => {
        setMemberEmail(event.target.value)
        setState({ ...state, emailField: '', formValid: false })
        setIsError({ ...isError, emailField: false })
        if (emailDetail.supplierORmember === 'supplier') {
            setType('Supplier');
        } else if (emailDetail.supplierORmember === 'member') {
            setType('Member');
        }
    }
    return (
        <>
            <Modal
                open={onShow || false}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <Box component="div" className="overlay" id="addEditModal">
                    {state.pageLoader && <PageLoader />}
                    <div className="overlay-box medium_overlay">
                        <div className="content">
                            <div className="overlayBody">
                                <div className="overlay-header text-center"> Email
                                    <CancelIcon className="closeBtn" onClick={handleClose} />
                                </div>
                                <div className="overlay-content">
                                    <Box sx={{ flexGrow: 1, paddingX: 2 }} >
                                        <Grid container columnSpacing={2}>
                                            <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: 2.5, alignSelf: 'center' }}>
                                                <Item>
                                                    <Grid container alignItems="center" justifyContent={'center'}>
                                                        <Grid item>
                                                            <Item>
                                                                <Grid container direction="row" alignItems="center">
                                                                    <div className="input-group">
                                                                        <RadioGroup
                                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                                            name="radio-buttons-group"
                                                                            value={type}
                                                                            onChange={onChangeType}
                                                                            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
                                                                        >
                                                                            {emailDetail && emailDetail.supplierORmember === 'member' && (<FormControlLabel disabled={state.memberEmailList.length === 0} value="Member" sx={{ alignItems: 'start' }} control={<Radio sx={{ padding: '1px 9px' }} />} label={
                                                                                <>
                                                                                    <div>
                                                                                        Member Account <Box component={'span'} sx={{ fontSize: '13px', fontWeight: '700' }}> - {emailDetail?.memberAccountName}</Box>
                                                                                    </div>
                                                                                    <div className="input-group">
                                                                                        <RadioGroup
                                                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                                                            name="radio-buttons-group"
                                                                                            value={memberEmail}
                                                                                            onChange={onChangeEmail}
                                                                                            sx={{ display: 'flex', flexDirection: 'column' }}

                                                                                        >
                                                                                            {state.memberEmailList.length > 0 ? (state.memberEmailList.map((email) => (
                                                                                                <FormControlLabel value={email} key={email} control={<Radio />} label={email} />
                                                                                            ))) : <Box sx={{ paddingTop: '10px', fontSize: '12px' }}>No email associates with member</Box>}

                                                                                        </RadioGroup>
                                                                                    </div>
                                                                                </>
                                                                            } />)}
                                                                            {emailDetail && emailDetail.supplierORmember === 'supplier' && (<FormControlLabel disabled={state.supplierEmailList.length === 0} value="Supplier" sx={{ alignItems: 'start', marginTop: 1.5 }} control={<Radio sx={{ padding: '1px 9px' }} />} label={
                                                                                <>
                                                                                    <div>
                                                                                        Supplier <Box component={'span'} sx={{ fontSize: '13px', fontWeight: '700' }}> - {emailDetail?.supplierName}</Box>
                                                                                    </div>
                                                                                    <div className="input-group">
                                                                                        <RadioGroup
                                                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                                                            name="radio-buttons-group"
                                                                                            value={memberEmail}
                                                                                            onChange={onChangeEmail}
                                                                                            sx={{ display: 'flex', flexDirection: 'column' }}
                                                                                        >
                                                                                            {state.supplierEmailList.length > 0 ? (state.supplierEmailList.map((email) => (
                                                                                                <FormControlLabel value={email} key={email} control={<Radio />} label={email} />
                                                                                            ))) : <Box sx={{ paddingTop: '10px', fontSize: '12px' }}>No email associates with Supplier</Box>}
                                                                                        </RadioGroup>
                                                                                    </div>
                                                                                </>
                                                                            } />)}

                                                                            <FormControlLabel value="Manual" sx={{ alignItems: 'start', marginTop: 1.5 }} control={<Radio sx={{ padding: '1px 9px' }} />} label={
                                                                                <>
                                                                                    <div>
                                                                                        Enter Email Address
                                                                                    </div>
                                                                                    <Box component={'div'} sx={{ marginTop: 1.5 }} className="input-group">
                                                                                        <FormControl fullWidth>
                                                                                            <TextField
                                                                                                inputProps={{ maxLength: 200 }}
                                                                                                size="small"
                                                                                                id="emailField"
                                                                                                aria-describedby="emailField_error"
                                                                                                onChange={(e) => handleInputChange(e)}
                                                                                                value={state.emailField}
                                                                                                name="emailField"
                                                                                                disabled={type === 'Manual' ? false : true}
                                                                                                error={isError.emailField}
                                                                                            />
                                                                                            {isError.emailField && (<FormHelperText className='errorHelperTxt' id="emailField_error">
                                                                                                Please enter valid email
                                                                                            </FormHelperText>)}
                                                                                        </FormControl>
                                                                                    </Box>
                                                                                </>
                                                                            } />
                                                                        </RadioGroup>
                                                                    </div>
                                                                </Grid>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>

                                        <Grid container sx={{ marginTop: '20px' }}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Item>
                                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                                        <LoadingButton
                                                            onClick={(e) => { handleSubmit(e) }}
                                                            loading={state.loader}
                                                            loadingPosition="center"
                                                            endIcon=""
                                                            variant="contained"
                                                            type="submit" sx={{ marginRight: '15px' }}
                                                            disabled={state.formValid}
                                                        >
                                                            Send Email
                                                        </LoadingButton>
                                                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div>
                    {snackBar.show && <SnackBarDialog
                        onShow={snackBar.show}
                        text={snackBar.text}
                        type={snackBar.type}
                        onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                    />}
                </Box>
            </Modal>
        </>
    );
}
