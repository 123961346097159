import { Box, Modal, Tabs, Tab, Button } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { warningModal, handleAddEditPdcPriceModal } from "../../../store/slices/modals";
import { resetchannel_price } from "../../../store/slices/pricePdcProduct";
import PdcPrice from "./pdc-tabs/Price";
import PdcCharge from "./pdc-tabs/Charge";
import PdcPromotionalCharge from "./pdc-tabs/PromotionalCharge";
import { filterFlagChange, filterResetFlagChange } from '../../../store/slices/externalFilter';

function TabPanel(props) {
    const { children, value, index, id, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`${id}-tab-pannel-${index}`}
            aria-labelledby={`${id}-tab-${index}`}
            {...other}
            className="tabs-pannel"
        >
            {value === index && (
                <>{children}</>
            )}
        </div>
    );
}

export default function AddEditPdcPrice({ pricetabs, onPriceShow }) {

    const { handleWarning, pdc_all_price_container, pdc_general_product_fields } = useSelector((state) => {
        return {
            handleWarning: state.modalActions.closeWaringAddProduct,
            pdc_all_price_container: state.pricePdcProducts,
            pdc_general_product_fields: state.generalPdcProducts
        }
    });
    const dispatch = useDispatch();
    
    const handleClose = () => {
        // dispatch(warningModal({
        //     yesHide: !handleWarning
        // }))
        dispatch(handleAddEditPdcPriceModal({
            showAddEditPrice: false
        }))
        dispatch(resetchannel_price());
        dispatch(filterFlagChange(true));
        dispatch(filterResetFlagChange(false));
    };

    const [value, setValue] = useState(0);
    function handleChange(event, newValue) {
        setValue(newValue);
      }

    return (
        <>
            <Modal
                open={(pdc_all_price_container.hasEditData) || (pdc_general_product_fields.latestGeneralData !==null) ? onPriceShow : false}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <Box component="div" className="overlay" id="addEditModal1">
                    <div className="overlay-box">
                        <div className="content">
                            <div className="overlayBody">
                                <div className="overlay-header text-center"> PDC Price Details
                                    <CancelIcon className="closeBtn" onClick={handleClose} />
                                </div>
                                <div className="overlay-content">
                                    {/* {pricetabs && <Tabination tabs={pricetabs} />} */}
                                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            variant="scrollable"
                                            scrollButtons={false}
                                            aria-label="scrollable prevent tabs example"
                                        >
                                        
                                                <Tab className="tabs-pannel" label="Prices" sx={{ minWidth: 'auto', paddingX: { xs: '5px', md: '0.85rem' }, marginLeft: '15px', fontWeight: '700', textTransform: 'capitalize' }} />
                                                <Tab className="tabs-pannel" label="Charges" sx={{ minWidth: 'auto', paddingX: { xs: '5px', md: '0.85rem' }, marginLeft: '15px', fontWeight: '700', textTransform: 'capitalize' }} />
                                                <Tab className="tabs-pannel" label="Promotional Prices" sx={{ minWidth: 'auto', paddingX: { xs: '5px', md: '0.85rem' }, marginLeft: '15px', fontWeight: '700', textTransform: 'capitalize' }} />
                                        </Tabs>
                                    </Box>
                                    <TabPanel value={value} index={0}>
                                    {value === 0 && (
                                        <PdcPrice />
                                    )}
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                    {value === 1 && (   
                                        <PdcCharge />
                                        )}
                                    </TabPanel>
                                    <TabPanel value={value} index={2}>
                                      {value === 2 && (
                                        <PdcPromotionalCharge />
                                        )}
                                    </TabPanel>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}
