import { DataTableLevyScheme } from "../../components/shared/data-table/DataTableLevyScheme";
import { Container, Box, Modal, Button, Grid, Checkbox, Popper, Grow, Paper, ClickAwayListener, MenuList, ButtonGroup, Stack, TextField, FormControl, FormHelperText, FormLabel } from '@mui/material'
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TableFilters } from "../../components/shared/data-table/TableFilters";
import { useEffect, useState } from "react";
import { InputText } from "../../components/shared/form/InputText";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from "moment";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { } from '@mui/material';
import { ThreeStateCheckbox } from "../../components/shared/form/ThreeStateCheckbox";
import axios_levy_scheme from '../../services/utility/axios-levy-scheme';
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange, setLevySchemeStartDate, setLevySchemeEndDate, filterResetFlagChange } from "../../store/slices/externalFilter";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import { useLayoutEffect } from "react";
import { SelectDropDown } from "../../components/shared/form/Select";
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import AddIcon from '@mui/icons-material/Add';
import { warningModal, handleAddEditModal } from "../../store/slices/modals";
import WarningModal from "../../components/shared/modals/WarningModal";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PageLoader from "../../components/shared/PageLoader";
import CircleIcon from '@mui/icons-material/Circle';
import SnackBarDialog from "../../components/shared/SnackBarDialog";
import { setRowPage, resetDataGrid, setPageNo } from '../../store/slices/dataGrid';
import { setHasEditData, levySchemeInput, setSelectedFilter, setlevySchemeEffectiveDate, brandSearch, setDeleteList, setLevySchemeDeleteData } from "../../store/slices/levyScheme";
import { AutoCompleteStatic } from "../../components/shared/form/AutoCompleteStatic";
import AddEditLevyScheme from "./AddEditLevyScheme/AddEditLevyScheme";

const IconMenuLink = ({ params, onDelete }) => {
    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { showAddEditModal, levyscheme_container } = useSelector((state) => {
        return {
            showAddEditModal: state.modalActions.hideAddEditModal,
            editTabData: state.generalProduct.editTabData,
            levyscheme_container: state.levySchemes
        }
    });
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'edit') {
            setEditGeneralData(row);
        } else if (action === 'delete') {
            onDelete(row)
        }
        setAnchorEl(null);
    };
    const setEditGeneralData = async (row) => {
        dispatch(setHasEditData(row))
        dispatch(handleAddEditModal({
            showAddEdit: !showAddEditModal
        }))
        dispatch(levySchemeInput({
            key: 'add_edit_levyScheme_name',
            value: row.name
        }))
        dispatch(levySchemeInput({
            key: 'levyScheme_rate',
            value: Number(row.levyRate).toFixed(2)
        }))
        dispatch(setlevySchemeEffectiveDate({
            key: 'levyScheme_start_date',
            value: row.startDate ? moment(row.startDate, 'DD/MM/YYYY') : ""
        }))
        dispatch(setlevySchemeEffectiveDate({
            key: 'levyScheme_end_date',
            value: row.endDate ? moment(row.endDate, 'DD/MM/YYYY') : ""
        }))
        dispatch(setSelectedFilter({
            key: 'levyscheme_levy_type',
            value: row.levyType
        }))
        dispatch(setSelectedFilter({
            key: 'levyscheme_charge_type',
            value: row.chargeType
        }))
        dispatch(setSelectedFilter({
            key: 'levyscheme_raise_type',
            value: row.raiseType
        }))
        dispatch(brandSearch({
            parent: 'levyscheme_general_vat_code',
            key: 'vat_code_selected',
            value: row.vatCode
        }))
        dispatch(brandSearch({
            parent: 'levyscheme_general_unit_measure',
            key: 'measure_selected',
            value: row.unitOfMeasure
        }))
        dispatch(brandSearch({
            parent: 'member_tier',
            key: 'member_tier_selected',
            value: row.memberTier ? row.memberTier : null
        }))
    }
    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'edit')}>Edit</MenuItem>
            {/* <MenuItem onClick={(e) => handleClose(e, 'delete')}>Delete</MenuItem> */}
        </Menu>
    </>)
}
function LevySchemes() {

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const tabs = [
        // {
        //     label: "General",
        //     Component: <General />
        // },
        // {
        //     label: "Prices",
        //     Component: <Price />
        // },
        // {
        //     label: "Specification",
        //     Component: <Specification />
        // },
        // {
        //     label: "Additional EAN Codes",
        //     Component: <Eancode />
        // }
    ];
    const columns = [
        {
            headerName: "Id",
            field: "id",
            width: 50,

        },
        {
            headerName: "Name",
            field: "name",
            flex: 1.5,
            minWidth: 200,
        },
        {
            headerName: "Rate",
            field: "levyRate",
            minWidth: 90,
            flex: 1
        },
        {
            headerName: "Start Date",
            field: "startDate",
            minWidth: 90,
            flex: 1


        },
        {
            headerName: "End Date",
            field: "endDate",
            minWidth: 90,
            flex: 1


        },
        {
            headerName: "Levy Type",
            field: "levyType",
            minWidth: 60,
            flex: 1
        },
        {
            headerName: "Purchasing Tier",
            field: "memberTier",
            valueGetter: (params) => params.row.memberTier ? params.row.memberTier.name : "",
            align: 'left',
            minWidth: 150
        },
        {
            headerName: "Charge Type",
            field: "chargeType",
            minWidth: 60,
            flex: 1
        },
        {
            headerName: "Raise Type",
            field: "raiseType",
            minWidth: 60,
            flex: 1
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            minWidth: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} onDelete={deleteLevySchemeData} />;
            },
        }
    ];

    const levyType = [{ text: 'Brand Owner', value: 'BrandOwner' }, { text: 'Supplier', value: 'Supplier' }];
    const raiseType = [{ text: 'DistributionCharge', value: 'DistributionCharge' }, { text: 'ProductValue', value: 'ProductValue' }];
    const chargeType = [{ text: 'UnitOfMeasure', value: 'UnitOfMeasure' }, { text: 'Percentage', value: 'Percentage' }];

    const [state, setState] = useState({
        openNewProductModal: false,
        pageLoader: false,
        defaultGrid: {
            pagetitle: "Levy Schemes",
            productActionButton: true,
            hasExport: true,
            addEditButton: true,
            apiUrl: 'levySchemes',
            searchUrl: '/levySchemes/filter'
        }
    });

    const { levyscheme_container, lvyName, lvyRate, lvyType, lvyChargeType, lvyRaiseType, lvyUnitOfMeasure, lvyVatCode,
        lvyStartDate, lvyEndDate, addProductwarning, showAddEditModal } = useSelector((state) => {
            return {
                levyscheme_container: state.levySchemes,
                lvyName: state.externalFilter.lvysc_name,
                lvyRate: state.externalFilter.lvysc_rate,
                lvyType: state.externalFilter.lvysc_type,
                lvyChargeType: state.externalFilter.lvysc_charge_type,
                lvyRaiseType: state.externalFilter.lvysc_raise_type,
                lvyUnitOfMeasure: state.externalFilter.lvysc_unitofmeasure,
                lvyVatCode: state.externalFilter.lvysc_vatcode,
                lvyStartDate: state.externalFilter.lvysc_start_date,
                lvyEndDate: state.externalFilter.lvysc_end_date,
                resetTable: state.dataGridTable,
                filterFlag: state.externalFilter.filterApplied,
                addProductwarning: state.modalActions.closeWaringAddProduct,
                showAddEditModal: state.modalActions.hideAddEditModal,
            }
        });

    const dispatch = useDispatch();

    const handleChange = (checked, name) => {
        setState({ ...state, [name]: checked })
        dispatch(setFilter({
            key: name,
            value: checked
        }))
    };

    const handleFilterInput = (value, id) => {
        //setState({ ...state, [id]: value });
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    };

    const selectedAutoComplete = (value, id) => {
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    }

    const handleStartDate = (event) => {
        const sDate = (event === null) ? null : moment(event, 'DD/MM/YYYY')
        dispatch(setLevySchemeStartDate(sDate))
    };

    const handleEndDate = (event) => {
        const eDate = (event === null) ? null : moment(event, 'DD/MM/YYYY')
        dispatch(setLevySchemeEndDate(eDate))
    };

    const filterBtn = () => {
        dispatch(resetDataGrid());
        var dynamicFilterObjects = {};

        if (lvyName !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "name": lvyName
            }
        }
        if (lvyRate !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "levyRate": lvyRate
            }
        }
        if (lvyStartDate !== null && lvyStartDate !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "startDate": moment(lvyStartDate).format('DD/MM/YYYY')
            }
        }
        if (lvyEndDate !== null && lvyEndDate !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "endDate": moment(lvyEndDate).format('DD/MM/YYYY')
            }
        }
        if (lvyType !== null && lvyType !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "levyType": lvyType
            }
        }
        if (lvyChargeType !== null && lvyChargeType !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "chargeType": lvyChargeType
            }
        }
        if (lvyRaiseType !== null && lvyRaiseType !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "raiseType": lvyRaiseType
            }
        }
        if (lvyUnitOfMeasure !== null && lvyUnitOfMeasure !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "unitOfMeasure": lvyUnitOfMeasure
            }
        }
        if (lvyVatCode !== null && lvyVatCode !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "vatCode": lvyVatCode
            }
        }

        if ((lvyStartDate !== "" && lvyStartDate !== null && moment(lvyStartDate).format('DD/MM/YYYY') === 'Invalid date') || (lvyEndDate !== "" && lvyEndDate !== null && moment(lvyEndDate).format('DD/MM/YYYY') === 'Invalid date')) {
            setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
        }
        else
        {
            dispatch(applyFilter({
                key: 'filterList',
                value: dynamicFilterObjects
            }));

            dispatch(filterFlagChange(true));
            dispatch(filterResetFlagChange(false));
            dispatch(setRowPage({ pageData: 10 }));
            dispatch(setPageNo({ page: 1 }));
        }



        // dispatch(applyFilter({
        //     key: 'filterList',
        //     value: {
        //         "name": productName,
        //         "brandOwnerProductId": bOProductId,
        //         "outerCaseBarcode": oCBarcode,
        //         "category": productCategory,
        //         // "levytype": levyType,
        //         "brand": productBrand,
        //         "active": activeProduct
        //     }
        // }))

    }

    // lvyName, lvyRate, lvyType, lvyChargeType, lvyRaiseType, lvyUnitOfMeasure, lvyVatCode,

    const resetFilter = () => {
        if ((lvyStartDate || lvyEndDate || lvyName || lvyRate || lvyType || lvyChargeType || lvyRaiseType || lvyUnitOfMeasure || lvyVatCode) !== '') {
            // || (lvyStartDate !== '' || lvyStartDate !== null)  || (lvyEndDate !== '' || lvyEndDate !== null)
            dispatch(resetExternalFilter())
            dispatch(resetDataGrid());
        }
        dispatch(filterResetFlagChange(true));
    }
    const addProductModal = () => {
        dispatch(handleAddEditModal({
            showAddEdit: !showAddEditModal
        }))
    }
    const hideNewProductModal = (event) => {
        setState({ ...state, openNewProductModal: false })
    }

    const deleteLevySchemeData = (row) => {
        dispatch(setDeleteList(row))
        dispatch(setLevySchemeDeleteData({
            key: 'levyschemeStartSingleDelete',
            value: true
        }))
        dispatch(warningModal({
            yesHide: !addProductwarning
        }))
    }

    useEffect(() => {
        if (levyscheme_container.levyschemeConfirmSingleDelete) {
            deleteSingleRow()
        }
    }, [levyscheme_container.levyschemeConfirmSingleDelete])


    const deleteSingleRow = async () => {
        if (levyscheme_container.deleteList !== null) {
            try {
                setState({ ...state, pageLoader: true })
                const { data } = await axios_levy_scheme.delete(`levySchemes/${levyscheme_container.deleteList.id}`);
                if (data.success) {
                    try {
                        const { data } = await axios_levy_scheme.get(`levySchemes`);
                        if (data !== null || data !== undefined) {
                            setState({ ...state, pageLoader: false })
                            setSnackBar({ ...snackBar, show: true, text: "Levy Scheme Delete Successfully", type: "success" })
                            dispatch(setDeleteList(null))
                            resetSingleDeleteRowDispatch()
                            setTimeout(() => {
                                window.location.reload();
                            }, 2000);
                        }
                    } catch (error) {
                        const { response: { data, status } } = error;
                        if (status === 500 && data.errorType === "Business") {
                            setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" });
                        } else {
                            setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
                        }
                        setState({ ...state, pageLoader: false })
                        resetSingleDeleteRowDispatch()
                    }
                }
            } catch (error) {
                const { response: { data, status } } = error;
                if (status === 500 && data.errorType === "Business") {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" });
                } else {
                    setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
                }
                setState({ ...state, pageLoader: false })
                resetSingleDeleteRowDispatch()
            }
        }
    }

    const resetSingleDeleteRowDispatch = () => {
        dispatch(setLevySchemeDeleteData({
            key: 'levyschemeStartSingleDelete',
            value: false
        }))
        dispatch(setLevySchemeDeleteData({
            key: 'levyschemeConfirmSingleDelete',
            value: false
        }))
    }

    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Levy Schemes" />
                <Box sx={{ marginBottom: 2.5 }}>
                    <TableFilters
                        filterFields={<>
                            <Box component="div"><InputText onChange={handleFilterInput} maxWidth={50} label="Name" id="lvysc_name" size="small" /></Box>
                            <Box component="div"><InputText onChange={handleFilterInput} maxWidth={50} label="Rate" id="lvysc_rate" size="small" name="lvysc_rate" /></Box>
                            <Box component="div">
                                <FormLabel htmlFor="lvysc_start_date" className="fontBold">Start Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        value={lvyStartDate}
                                        onChange={(e) => { handleStartDate(e) }}
                                        name="lvysc_start_date" label="Start Date"
                                        inputFormat="DD/MM/YYYY"
                                        // eslint-disable-next-line max-len
                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                            <Box component="div" sx={{ paddingTop: '6px' }} className='date-picker-input'>

                                                <FormControl size="small" fullWidth>
                                                    <TextField size="small" id="lvysc_start_date"
                                                        ref={inputRef} {...inputProps}
                                                        placeholder=""
                                                        aria-describedby="lvysc_start_date_error"

                                                    />

                                                    <Box className='date-picker-icon'>
                                                        {InputProps?.endAdornment}
                                                    </Box>
                                                </FormControl>
                                            </Box>
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>
                            <Box component="div">
                                <FormLabel htmlFor="lvysc_end_date" className="fontBold">End Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        value={lvyEndDate}
                                        onChange={(e) => { handleEndDate(e) }}
                                        name="lvysc_end_date" label="End Date"
                                        inputFormat="DD/MM/YYYY"
                                        // eslint-disable-next-line max-len
                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                            <Box component="div" sx={{ paddingTop: '6px' }} className='date-picker-input'>

                                                <FormControl size="small" fullWidth>
                                                    <TextField size="small" id="lvysc_end_date"
                                                        ref={inputRef} {...inputProps}
                                                        placeholder=""
                                                        aria-describedby="lvysc_end_date_error"

                                                    />

                                                    <Box className='date-picker-icon'>
                                                        {InputProps?.endAdornment}
                                                    </Box>
                                                </FormControl>
                                            </Box>
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>
                            <Box component="div"><SelectDropDown onChange={handleFilterInput} values={levyType} size="small" id="lvysc_type" label="Levy Type" name="lvysc_type" /></Box>
                            <Box component="div"><SelectDropDown onChange={handleFilterInput} values={raiseType} size="small" id="lvysc_raise_type" label="Raise Type" name="lvysc_raise_type" /></Box>
                            <Box component="div"><SelectDropDown onChange={handleFilterInput} values={chargeType} size="small" id="lvysc_charge_type" label="Charge Type" name="lvysc_charge_type" /></Box>
                            <Box component="div"><AutoCompleteStatic endPoint="vatcodes/drop-down" onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} id="lvysc_vatcode" label="Vat Code" name="lvysc_vatcode" /></Box>
                            <Box component="div"><AutoCompleteStatic endPoint="unitofmeasures/drop-down" onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} id="lvysc_unitofmeasure" label="Unit of Measure" name="lvysc_unitofmeasure" /></Box>

                        </>}
                        filterCheckboxes={<>

                        </>}
                        filterBtns={
                            <>
                                <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                <Button variant="contained" onClick={filterBtn}>Apply</Button>
                            </>}>
                    </TableFilters>
                </Box>
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -3 } }}>
                    {/* <div className='table-actionable'>
                        <Button variant="contained" color="secondary" size='small' startIcon={<SendIcon />}>
                            Export as Excel
                        </Button>
                    </div> */}
                    <div className='table-actionable'>
                        <Button variant="contained" size='small' onClick={(e) => addProductModal(e)} startIcon={<AddIcon />}>
                            New Levy Schemes
                        </Button>
                    </div>
                </Box>
                <DataTableLevyScheme defaultGrid={state.defaultGrid} columns={columns}>

                </DataTableLevyScheme>
                {
                    showAddEditModal && (
                        <AddEditLevyScheme size="medium_overlay"
                            onShow={showAddEditModal}
                            onHide={(e, reason) => hideNewProductModal(e, reason)}

                        />
                    )
                }
                {
                    addProductwarning && (
                        <WarningModal size="small_overlay" onShow={addProductwarning} />
                    )
                }
                {snackBar.show && <SnackBarDialog
                    onShow={snackBar.show}
                    text={snackBar.text}
                    type={snackBar.type}
                    onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                />}
            </Container>

        </>
    );
}

export default LevySchemes;
