import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    sendEmailIds: [],
    sendEmailRows: [],
    memberStartBulkEmail: false,
    memberConfirmBulkEmail: false,
    supplierStartBulkEmail: false,
    supplierConfirmBulkEmail: false
}

const membersupplierReports = createSlice({
    name: 'member_supplier_reports',
    initialState,
    reducers: {
        setEmailIds: (state, action) => {
            state.sendEmailIds = action.payload
        },
        setEmailRows: (state, action) => {
            state.sendEmailRows = action.payload
        },
        setEmailStatus: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value;
        },
        resetsendEmails: () => {
            return initialState
          }
    }
})

export const { setEmailIds, setEmailRows, setEmailStatus, resetsendEmails } = membersupplierReports.actions

export default membersupplierReports.reducer