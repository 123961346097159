import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    uom_name: '',
    uom_list: [],
    uom_from_to: {
        uom_from_to_options: [],
        uom_from_to_selected: null
    },
    uof_edit_data: null,
    hasUOMName: false,
    addNewUOMData: null,
    rate_value: '',
    deleteList: null,
    uomStartDelete: false,
    uomConfirmDelete: false,
    uomRateStartDelete: false,
    uomRateConfirmDelete: false,
    isError: {
        uom_name: false,
        uom_from_to_selected: false,
        rate_value: false
    }
}

const uomProduct = createSlice({
    name: 'uom_product',
    initialState,
    reducers: {
        setUOMName: (state, action) => {
            state.uom_name = action.payload;
        },
        setSingleUOM: (state, action) => {
            state.uom_list.push(action.payload)
        },
        setUOPList: (state, action) => {
            state.uom_list = action.payload
        },
        setAutocomplete: (state, action) => {
            const { parent, key, value } =  action.payload;
            state[parent][key] = value;
        },
        brandSearch: (state, action) => {
            const { parent, key, value } = action.payload;
            state[parent][key] = value;
            (state[parent][key] === null || state[parent][key] === '') ? state.isError[key] = true : state.isError[key] = false
        },
        setUOMEdit: (state, action) => {
            state.uof_edit_data =  action.payload;
        },
        uomInput: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value;
            state[key].length === 0 ? state.isError[key] = true : state.isError[key] = false
        },
        hasUOMName: (state, action) => {
            state.hasUOMName = action.payload;
        },
        addNewUOMData: (state, action) => {
            state.addNewUOMData = action.payload;
        },
        setUOMRate: (state, action) => {
            state.rate_value = action.payload;
        },
        getIsError: (state, action) => {
            const { key, value } = action.payload;
            state.isError[key] = value
        },
        setDeleteList: (state, action) => {
            state.deleteList = action.payload
        },
        setUOMDeleteData: (state, action) => {
        const { key, value } = action.payload;
        state[key] = value;
        },
        resetUOM: () => {
            return initialState
        },
    }
})

export const { resetBrand, setUOMName, setSingleUOM, setUOPList, 
    resetUOM, setAutocomplete, setUOMEdit, uomInput, hasUOMName, addNewUOMData, setUOMRate, getIsError, brandSearch, setDeleteList, setUOMDeleteData } = uomProduct.actions

export default uomProduct.reducer