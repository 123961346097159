import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addCategory: false,
  editCategory: false,
  hasEditCategoryData: null,
  editCategoryLevel: null,
  editCategoryLevelName: '',
  showType: true,
  selected_category_id: 0,
  category_name: '',
  category_active: true,
  category_level: null,
  category_parent: null,
  category_parentName: '',
  category_type: null,
  category_foodtype: {
    foodtype_options: [
      { id: 0, name: 'Non Food' },
      { id: 1, name: 'Chilled' },
      { id: 2, name: 'Ambient' },
      { id: 3, name: 'Frozen' }
    ],
    foodtype_selected: null,
  },
  category_children: null,
  isError: {
    category_name: false,
    category_level: false,
    category_parent: false,
    category_type: false,
    category_foodtype_selected: false,
  },
  startDeleteCategory: false,
  confirmDeleteCategory: false,
}

const categoryData = createSlice({
  name: 'category_data',
  initialState,
  reducers: {
    categoryInput: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
      state[key].length === 0 ? state.isError[key] = true : state.isError[key] = false;
    },
    setAutocomplete: (state, action) => {
      const { parent, key, value } = action.payload;
      state[parent][key] = value;
      (state[parent][key] === null || state[parent][key] === "" || state[parent][key]['name'] === null) ? state.isError[key] = true : state.isError[key] = false;
    },
    setcategoryLevel: (state, action) => {
      const { category_level } = action.payload;
      state.category_level = category_level;
      state['category_level'] === null || state['category_level'].length === 0 ? state.isError['category_level'] = true : state.isError['category_level'] = false;
    },
    setcategoryParent: (state, action) => {
      const { category_parent } = action.payload;
      state.category_parent = category_parent;
      state['category_parent'] === null || state['category_parent'].length === 0 ? state.isError['category_parent'] = true : state.isError['category_parent'] = false;
    },
    categorBasicInput: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
      // state[key].length === 0 ? state.isError[key] = true : state.isError[key] = false
    },
    hasCategoryEdit: (state, action) => {
      const { editCategory, editCategoryLevel, selected_category_id } = action.payload;
      state.editCategory = editCategory
      state.editCategoryLevel = editCategoryLevel
      state.selected_category_id = selected_category_id
    },
    sethasEditCategoryData: (state, action) => {
      state.hasEditCategoryData = action.payload;
    },
    getIsError: (state, action) => {
      const { key, value } = action.payload;
      state.isError[key] = value
    },
    deleteCategoryStatus: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value
    },
    resetCategory: () => {
      return initialState
    },
  }
})

export const { resetCategory, getIsError, hasCategoryEdit, sethasEditCategoryData, categoryInput, setcategoryLevel, setcategoryParent, setAutocomplete, categorBasicInput, deleteCategoryStatus } = categoryData.actions

export default categoryData.reducer