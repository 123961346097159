import { Box, Modal, Paper, FormControl, Grid, TextField, FormHelperText, FormLabel, Button, Autocomplete } from '@mui/material';
import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageLoader from '../../components/shared/PageLoader';
import moment from 'moment';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import SnackBarDialog from '../../components/shared/SnackBarDialog';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

export default function PaymentDateModal({ onShow, onHide, size = '', onPaymentDateModal }) {
    const [state, setState] = useState({
        loader: false,
        pageLoader: false,
        defaultGrid: {
            paginationMode: 'client',
        },
        paymentDate: null,
        collectionDate: null,
        formValid: true
    });

    const [isError, setIsError] = useState({
        paymentDate: false,
        collectionDate: false
    })

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const handleClose = () => {
        if (onHide) {
            onHide(false)
        }
    };

    const handlepaymentDate = (event) => {
        if (event === null) {
            setIsError({ ...isError, paymentDate: true })
            setState({ ...state, paymentDate: null })
        } else {
            setState({ ...state, paymentDate: moment(event,'DD/MM/YYYY') })
            setIsError({ ...isError, paymentDate: false })
        }
    }
    const handleCollectionDate = (event) => {
        if (event === null) {
            setIsError({ ...isError, collectionDate: true })
            setState({ ...state, collectionDate: moment(event,'DD/MM/YYYY') })
        } else {
            setState({ ...state, collectionDate: moment(event,'DD/MM/YYYY') })
            setIsError({ ...isError, collectionDate: false })
        }
    }

    useEffect(() => { 
        if (state.paymentDate === null || state.paymentDate === '') {
            setState({ ...state, formValid: true, defaultmode: false })
        } else {
            setState({ ...state, formValid: false, defaultmode: true })
        }
    }, [state.paymentDate])

    const handleSubmit = (event) => {
        event.preventDefault();
        if (state.paymentDate === null || state.paymentDate === 'Invalid date' || state.paymentDate === "") {
            setIsError({ ...isError, paymentDate: true })
        }  else {
            if (onPaymentDateModal && (state.paymentDate !== 'Invalid date' && state.paymentDate !== null)) {
                if (moment(state.paymentDate).format('DD/MM/YYYY') === 'Invalid date') {
                    setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
                }
                else
                {
                    onPaymentDateModal({
                        paymentDate: moment(state.paymentDate).format('DD/MM/YYYY'),
                    });
                }
                
            }
        }

    }

    return (
        <>
            <Modal
                open={onShow || false}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <Box component="div" className="overlay" id="addEditModal">
                    {state.pageLoader && <PageLoader />}
                    {snackBar.show && <SnackBarDialog
                        onShow={snackBar.show}
                        text={snackBar.text}
                        type={snackBar.type}
                        onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                    />}
                    <div className={`overlay-box ${size ? size : ''}`}>
                        <div className="content">
                            <div className="overlayBody">
                                <div className="overlay-header text-center"> Select Payment Date
                                    <CancelIcon className="closeBtn" onClick={handleClose} />
                                </div>
                                <div className="overlay-content">
                                    <Box sx={{ flexGrow: 1, paddingX: 2 }} >
                                        <Grid container columnSpacing={2}>
                                            <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: 2.5 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel htmlFor="brandOwnerProductId" className="fontSemiBold mandatory">Payment Date</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={7.5}>
                                                            <Item>
                                                                <div className="input-group">
                                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                        <DesktopDatePicker
                                                                            value={state.paymentDate}
                                                                            onChange={(e) => { handlepaymentDate(e) }}
                                                                            name="paymentDate"
                                                                            inputFormat="DD/MM/YYYY"
                                                                            // eslint-disable-next-line max-len
                                                                            renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                                <Box component="div" className='date-picker-input'>
                                                                                    <FormControl size={'small'} fullWidth>
                                                                                        <TextField size="small" id="paymentDate"
                                                                                            ref={inputRef} {...inputProps}
                                                                                            placeholder=""
                                                                                            aria-describedby="paymentDate_error"
                                                                                            error={isError.paymentDate}
                                                                                            autoComplete="off"
                                                                                        />
                                                                                        {isError.paymentDate && (<FormHelperText className='errorHelperTxt' id="paymentDate_error">Payment Date is missing</FormHelperText>)}
                                                                                        <Box className='date-picker-icon'>
                                                                                            {InputProps?.endAdornment}
                                                                                        </Box>
                                                                                    </FormControl>
                                                                                </Box>
                                                                            )}
                                                                        />
                                                                    </LocalizationProvider>
                                                                </div>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        <Grid container sx={{ marginTop: '20px' }}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Item>
                                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                                        <Button variant="contained" onClick={(e) => { handleSubmit(e) }} type="submit" disabled={state.formValid || state.defaultmode === false} sx={{ marginRight: '15px' }}>Save</Button>
                                                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}
