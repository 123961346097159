import { Box, Modal, Paper, FormControl, Grid, TextField, FormLabel, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect, useState } from 'react';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

export default function AddManualEntryModal({ onShow, onHide, onAddManualEntry }) {
    const [state, setState] = useState({
        loader: false,
        pageLoader: false,
        defaultGrid: {
            paginationMode: 'client',
        },
        netTotal: '',
        vatTotal: '',
        grossTotal: '',
        collectionDate: null,
        formValid: true,
        defaultmode: true
    });

    const handleClose = () => {
        if (onHide) {
            onHide(false)
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (onAddManualEntry) {
            onAddManualEntry({
                netTotal: Number((Math.round((state.netTotal) * 100) / 100).toFixed(2)),
                vatTotal: Number((Math.round((state.vatTotal) * 100) / 100).toFixed(2)),
                grossTotal: Number((Math.round((Number(state.netTotal) + Number(state.vatTotal)) * 100) / 100).toFixed(2)),
                "lines": [
                    {

                        "supplierProductCode": "MAN INV",
                        "supplierQuantity": 1,
                        "supplierUnitPrice": Number((Math.round((state.netTotal) * 100) / 100).toFixed(2)),
                        "vatCode": {
                            "id": 3,
                            "code": "Z",
                            "rate": 0,
                            "description": "Zero",
                            "externalCode": "0"
                        },
                        "vatAmount": Number((Math.round((state.vatTotal) * 100) / 100).toFixed(2)),
                        "supplierLineValue": Number((Math.round((state.netTotal) * 100) / 100).toFixed(2))
                    }

                ]
            }
            )
        }

    }
    const handleInputChange = (event) => {
        const { value, name } = event.target;
        setState({ ...state, [name]: value, defaultmode: false })
    }

    useEffect(() => {
        const inputNumber = document.querySelectorAll('.inputNumber');
        inputNumber.forEach(el => el.addEventListener('keypress', evt => {
            if (evt.which === 8) {
                return;
            }
            if (evt.which < 42 || evt.which > 60) {
                evt.preventDefault();
            }
        }));
    }, []);
    
    return (
        <>
            <Modal
                open={onShow || false}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <Box component="div" className="overlay" id="addEditModal">
                    <div className={`overlay-box small_overlay`}>
                        <div className="content">
                            <div className="overlayBody">
                                <div className="overlay-header text-center"> Add Manual Entry
                                    <CancelIcon className="closeBtn" onClick={handleClose} />
                                </div>
                                <div className="overlay-content">
                                    <Box sx={{ flexGrow: 1, paddingX: 2 }} >
                                        <Grid container columnSpacing={2}>
                                            <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: 2.5 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel className="fontSemiBold">Net Total</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={7.5}>
                                                            <Item>
                                                                <div className="input-group">
                                                                    <FormControl size={'small'} fullWidth>
                                                                        <TextField
                                                                            inputProps={{ maxLength: 100 }}
                                                                            size="small"
                                                                            id="netTotal"
                                                                            aria-describedby="netTotal_error"
                                                                            name="netTotal"
                                                                            onChange={(e) => handleInputChange(e)}
                                                                            value={state.netTotal}
                                                                            type="number"
                                                                            className="inputNumber"
                                                                            onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                                                        />
                                                                    </FormControl>
                                                                </div>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        <Grid container columnSpacing={2}>
                                            <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: 2.5 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel className="fontSemiBold">Vat Total</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={7.5}>
                                                            <Item>
                                                                <div className="input-group">
                                                                    <FormControl size={'small'} fullWidth>
                                                                        <TextField
                                                                            inputProps={{ maxLength: 100 }}
                                                                            size="small"
                                                                            id="vatTotal"
                                                                            aria-describedby="vatTotal_error"
                                                                            name="vatTotal"
                                                                            onChange={(e) => handleInputChange(e)}
                                                                            value={state.vatTotal}
                                                                            type="number"
                                                                            className="inputNumber"
                                                                            onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                                                        />
                                                                    </FormControl>
                                                                </div>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        {/* <Grid container columnSpacing={2}>
                                            <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: 2.5 }}>
                                                <Item>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                            <Item>
                                                                <FormLabel className="fontSemiBold">Gross Total</FormLabel>
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={12} md={7.5}>
                                                            <Item>
                                                                <div className="input-group">
                                                                    <FormControl size={'small'} fullWidth>
                                                                        <TextField
                                                                            inputProps={{ maxLength: 100 }}
                                                                            size="small"
                                                                            id="grossTotal"
                                                                            aria-describedby="grossTotal_error"
                                                                            name="grossTotal"
                                                                            onChange={(e) => handleInputChange(e)}
                                                                            value={state.grossTotal}
                                                                        />
                                                                    </FormControl>
                                                                </div>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid> */}
                                        <Grid container sx={{ marginTop: '20px' }}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Item>
                                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                                        <Button variant="contained" onClick={(e) => { handleSubmit(e) }} type="submit" disabled={state.defaultmode} sx={{ marginRight: '15px' }}>Save</Button>
                                                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}
