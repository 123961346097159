import { useLayoutEffect, useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect } from 'react';
import { DataTableNusslReports } from '../../components/shared/data-table/DataTableNusslReports';
import { Container, Box, Modal, Button, Grid, Checkbox, Popper, Grow, Paper, ClickAwayListener, MenuList, ButtonGroup, Stack, TextField, FormControl, FormHelperText, FormLabel, Select, Pagination, PaginationItem } from '@mui/material'
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from 'react-redux';
import axiosNusslReports from '../../services/utility/axios-nussl-reports';
import { saveSomeData } from '../../services/utility/common-utils';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { DataGridPro } from '@mui/x-data-grid-pro';
import {
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector
} from '@mui/x-data-grid';
import { setReportModalWarning, warningModal } from '../../store/slices/modals';
import SnackBarDialog from '../../components/shared/SnackBarDialog';

const IconMenuLink = ({ params, onDownload }) => {
    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'download') {
            if (onDownload) {
                onDownload(row)
            }
        }
        setAnchorEl(null);
    };
    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >

            <MenuItem onClick={(e) => handleClose(e, 'download')}>Download</MenuItem>
        </Menu>
    </>)
}

function ReportModal({ onShows, onHide, size = '', getParams }) {


    const { handleWarning, rowData } = useSelector((state) => {
        return {
            handleWarning: state.modalActions.closeWaringAddProduct,
            rowData: state.invoiceManualEntry
        }
    });
    const dispatch = useDispatch();

    const { id, statementCycleStage } = getParams;

    function CustomPagination() {

        const dispatch = useDispatch();
        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        const rowDataList = [10, 25, 50, 75, 100]

        const handleChange = (event) => {
            //dispatch(setRowPage({ pageData: event.target.value }));
            setState({ ...state, pageSize: event.target.value })
        };

        const paginationChange = (event, value) => {
            apiRef.current.setPage(value - 1);
            //dispatch(setPageNo({ page: value }));
            setState({ ...state, page: value })
        }

        return (
            <>
                <div className='grid-pagiation'>
                    <Box className='customRowPerPageBox' component="div">
                        <div>
                            View
                        </div>
                        <Box component="div" sx={{ marginX: '10px' }}>
                            <FormControl>
                                <Select
                                    size="small"
                                    id="row-per-page"
                                    defaultValue="choose"
                                    onChange={handleChange}
                                    value={state.pageSize}
                                    aria-describedby={'row-per-page'} >
                                    {rowDataList.map((value) => {
                                        return (
                                            <MenuItem value={value} key={value}>{value}</MenuItem>
                                        )
                                    }

                                    )}
                                </Select>

                            </FormControl>
                        </Box>
                        <div>
                            rows per page
                        </div>
                    </Box>
                    <Pagination
                        variant="outlined"
                        shape="rounded"
                        count={pageCount}
                        page={page + 1}
                        onChange={(event, value) => paginationChange(event, value)}
                        renderItem={(item) => (
                            <PaginationItem
                                components={{ previous: KeyboardArrowLeftIcon, next: KeyboardArrowRightIcon }}
                                {...item}
                            />
                        )}
                    />
                </div>
            </>
        );
    }

    const [state, setState] = useState({
        defaultGrid: {
            searchUrl: 'reports/filter'
        },
        isLoading: false,
        data: [],
        total: 0,
        page: 1,
        pageSize: 10,
        applyFilterCount: null
    })
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })
    const columns = [
        {
            headerName: "Pid",
            field: "processId",
            minWidth: 50,
        },
        {
            headerName: "File Name",
            field: "fileName",
            minWidth: 200,
            flex: 1.5,
        },
        {
            headerName: "Creation Date",
            field: "creationDate",
            minWidth: 120,

        },
        {
            headerName: "Mime Type",
            field: "mimeType",
            minWidth: 100,
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            minWidth: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} onDownload={onDownload} />;
            },
        }
    ];
    const handleClose = () => {
        dispatch(warningModal({
            yesHide: !handleWarning
        }))
    };
    useEffect(() => {
        setState({ ...state, isLoading: true, data: [] })
        const fetchMyAPI = async () => {
            try {
                // removed till bug ?pageSize=${rowPerPage}
                const loadFilter = { statementCycleStage, 'statementCycleId': id }
                const { data } = await axiosNusslReports.post(`${state.defaultGrid.searchUrl}?page=${state.page}&pageSize=${state.pageSize}`, loadFilter);
                // eslint-disable-next-line max-len
                setState({ ...state, isLoading: false, data: data.items, totalRows: data.totalCount, total: data.totalCount, applyFilterCount: data.totalCount });
            } catch (error) {
                const { response: { data, status } } = error;
                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                }
                setState({ ...state, isLoading: false, data: [], totalRows: 0, total: 0 });
            }
        }
        fetchMyAPI();
    }, [state.page, state.pageSize])

    const onPageChange = (newPage) => {
        //dispatch(setPageNo({ page: newPage + 1 }));
        setState({ ...state, page: newPage + 1 });
    };

    const onPageSizeChange = (newPageSize) => {
        //dispatch(setRowPage({ pageData: newPageSize }));
        setState({ ...state, pageSize: newPageSize });
    };

    const onDownload = async (params) => {

        try {
            setState({ ...state, pageLoader: true })
            const fileDetails = {
                id: params.id,
                fileName: params.fileName
            }
            const { data } = await axiosNusslReports.post('/download/report', fileDetails);
            if (data) {

                setState({
                    ...state,
                    pageLoader: false,
                })

                saveSomeData(data.content, params.mimeType, data.fileName)
                setSnackBar({ ...snackBar, show: true, text: "File uploaded!", type: "success" })
            }

        } catch (error) {
            const { response: { data, status } } = error;
            if (!data) {
                setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
            } else {
                setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
            }
            setState({ ...state, pageLoader: false })
        }
    }
    return (
        <>
            <Modal
                open={rowData.reportModal || false}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <Box component="div" className="overlay" id="addEditModal">
                    <div className={`overlay-box ${size ? size : ''}`}>
                        <div className="content">
                            <div className="overlayBody">
                                <div className="overlay-header text-center"> Reports
                                    <CancelIcon className="closeBtn" onClick={handleClose} />
                                </div>
                                <div className="overlay-content">
                                    <Box component="div" sx={{ textAlign: 'center', padding: 1.5 }}>
                                        <Box component="div" className='table-actions-buttons' sx={{ columnGap: 1.75, display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2.5 }}>
                                            <Box component="div" className='data-per-row-message'>
                                                Showing {state.pageSize * (state.page - 1) + 1} to {((state.total < (state.pageSize * state.page)) ? state.total : (state.pageSize * state.page))} out of {state.total} entries
                                            </Box>
                                        </Box>
                                        <div style={{ height: '468px', width: '100%' }}>
                                            <DataGridPro
                                                rows={state.data}
                                                columns={columns ? columns : []}
                                                //autoHeight={true}
                                                disableColumnMenu={true}

                                                rowCount={state.total}
                                                loading={state.isLoading}

                                                pagination={true}
                                                page={state.page - 1}
                                                pageSize={state.pageSize}
                                                paginationMode="server"
                                                onPageChange={(newPage) => onPageChange(newPage)}
                                                onPageSizeChange={(newPageSize) => onPageSizeChange(newPageSize)}
                                                components={{
                                                    Pagination: CustomPagination,
                                                    NoRowsOverlay: () => (
                                                        <Stack height="100%" alignItems="center" justifyContent="center">
                                                            No search results were found
                                                        </Stack>
                                                    ),
                                                    NoResultsOverlay: () => (
                                                        <Stack height="100%" alignItems="center" justifyContent="center">
                                                            Local filter returns no result
                                                        </Stack>
                                                    )
                                                }}
                                                getRowClassName={(params) =>
                                                    params.indexRelativeToCurrentPage % 2 === 0 ? '' : 'oddRow'
                                                }
                                                initialState={{ pinnedColumns: { right: ['action'] } }}
                                                sx={{
                                                    "& .MuiDataGrid-columnHeader": {
                                                        backgroundColor: "#F0F0F0"
                                                        // color: "red"
                                                    },
                                                    "& .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle": {
                                                        fontWeight: "600",
                                                        fontSize: '14px',
                                                        color: "#15191E"
                                                    },
                                                    "& .MuiDataGrid-row.oddRow": {
                                                        backgroundColor: "#FBFBFB"
                                                        // color: "red"
                                                    },
                                                    "& .MuiDataGrid-row.Mui-hovered, .MuiDataGrid-row.Mui-selected, .MuiDataGrid-row.Mui-selected.Mui-hovered": {
                                                        backgroundColor: "#FFF5E1"
                                                        // color: "red"
                                                    },
                                                    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
                                                        borderRight: `1px solid #E6E6E6`,
                                                        borderBottom: `1px solid #E6E6E6`,
                                                    },
                                                    '& .MuiDataGrid-cell': {
                                                        fontWeight: '500',
                                                    },
                                                }}
                                            />
                                        </div>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div>
                    {snackBar.show && <SnackBarDialog
                        onShow={snackBar.show}
                        text={snackBar.text}
                        type={snackBar.type}
                        onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                    />}
                </Box>

            </Modal>
        </>
    );
}

export default ReportModal;