import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editTab: true,
  hasEditPdc: null,
  editTabData: null,
  hasEditData: null,
  retro_discount_scheme_name: '',
  retrodiscountschemesFormValid: false,
  retro_name: '',
  retro_interim_unit: '',
  retro_final_unit: '',
  closeForInvoice: false,
  closeForBilling: false,
  generateDetailedReport: true,
  aggregationGroups: false,
  general_vat_code: {
    vat_code_options: [],
    vat_code_selected: null
  },
  general_unit_measure: {
    measure_options: [],
    measure_selected: null
  },
  centralBillingScheme: {
    billingScheme_options: [],
    billingScheme_selected: null
  },
  promotionalType: {
    promotionalType_options: [],
    promotionalType_selected: null
  },
  startQuantity: '',
  points: '',
  startDate: null,
  endDate: null,
  retroFormValid: false,
  payDateList: [],
  hasEditPayDate: null,
  hasEditThreshold: null,
  hasEditDC: null,
  hasEditCategories: null,
  hasEditMember: null,
  hasEditSupplier: null,
  hasEditProgrammes: null,
  hasEditProduct: null,
  retroDiscountSchemeStartSingleDelete: false,
  retroDiscountSchemeConfirmSingleDelete: false,
  deleteList: null,
  payMemberDateData: [],
  rowDelete: null,
  starteDelete: false,
  confirmDelete: false,
  startFetchAll: false,
  confirmFetchAll: false,
  retro_promotional_period: '',
  retro_promotional_year: '',
  retroUploadModal: '',
  isError: {
    retro_discount_scheme_name: true,
    retro_name: false,
    vat_code_selected: false,
    measure_selected: false,
    startDate: false,
    endDate: false,
    billingScheme_selected: false,
    retro_interim_unit: false,
    retro_final_unit: false,
    categories_selected: false,
    dc_selected: false,
    programme_selected: false,
    memberDetail: false,
    supplierDetail: false,
    productDetail: false,
    promotionalType_selected: false
  }
}

const retrodiscountschemes = createSlice({
  name: 'retrodiscountschemes_accounts',
  initialState,
  reducers: {
    retrodiscountschemesInput: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
      state[key].length === 0 ? state.isError[key] = true : state.isError[key] = false
    },
    getIsError: (state, action) => {
      const { key, value } = action.payload;
      state.isError[key] = value
    },
    setAutocomplete: (state, action) => {
      const { parent, key, value } = action.payload;
      state[parent][key] = value
    },
    hasTabEdit: (state, action) => {
      const { editTab } = action.payload;
      state.editTab = editTab
    },
    hasTabEditData: (state, action) => {
      const { editData } = action.payload;
      state.editTabData = editData
    },
    setHasEditData: (state, action) => {
      state.hasEditData = action.payload;
    },
    setretrodiscountschemesFormStatus: (state, action) => {
      state.retrodiscountschemesFormValid = action.payload;
    },
    autoCompleteSearch: (state, action) => {
      const { parent, key, value } = action.payload;
      state[parent][key] = value;
      (state[parent][key] === null || state[parent][key] === "") ? state.isError[key] = true : state.isError[key] = false
    },
    setRetroDates: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
      state.isError[key] ? (state[key] === null || state[key] === "" || state[key] === 'Invalid date') ? state.isError[key] = true : state.isError[key] = false : '';
    },
    resetretrodiscountschemes: () => {
      return initialState
    },
    setRetroFormStatus: (state, action) => {
      state.retroFormValid = action.payload;
    },
    setDeleteList: (state, action) => {
      state.deleteList = action.payload
    },
    setRetroState: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value
    },
    rowDeleteData: (state, action) => {
      state.rowDelete = action.payload
    },
    rowDeleteYes: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value
    },
    memberFetchAll: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value
    },
  }
})

export const { autoCompleteSearch, hasTabEditData, setAutocomplete,
  getIsError, retrodiscountschemesInput, hasTabEdit, resetretrodiscountschemes,
  setHasEditData, setretrodiscountschemesFormStatus, setRetroDates, setRetroFormStatus, setRetroState, setDeleteList, rowDeleteData, rowDeleteYes, memberFetchAll } = retrodiscountschemes.actions

export default retrodiscountschemes.reducer