import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editProductUpload: null,
    tableColumn: [],
    startSingleDelete: false,
    confirmSingleDelete: false,
    singleDeleteRow: null,
    startFileDelete: false,
    confirmFileDelete: false,
    editProductUploadModal: false,
    wrongFileUpload: false
}

const fileUpload = createSlice({
    name: 'file_upload',
    initialState,
    reducers: {
        editProductUpload: (state, action) => {
            state.editProductUpload = action.payload
        },
        singleDeleteYes: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value
        },
        fileDeleteYes: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value
        },
        wrongFile: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value
        },
        singleDeleteRow: (state, action) => {
            state.singleDeleteRow = action.payload
        },
        editProductUploadModal: (state, action) => {
            state.editProductUploadModal = action.payload;
        },
    }
})

export const { editProductUpload, singleDeleteYes, singleDeleteRow, fileDeleteYes, editProductUploadModal, wrongFile } = fileUpload.actions

export default fileUpload.reducer