import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  query: ""

}
const commonProduct = createSlice({
  name: 'commonProduct',
  initialState,
  reducers: {
    setCommonInput: (state, action) => {
      state.query = action.payload;
    },
    resetcommonProduct: () => {
      return initialState
    }
  }
})

export const { resetcommonProduct, setCommonInput} = commonProduct.actions

export default commonProduct.reducer