import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Container, FormControl, FormHelperText, FormLabel, Grid, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import { TableFilters } from "../../components/shared/data-table/TableFilters";
import PageLoader from "../../components/shared/PageLoader";
import axio_utilities from "../../services/utility/axios-utilities";
import SnackBarDialog from "../../components/shared/SnackBarDialog";
const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));
function StandingData() {
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })
    const [state, setState] = useState({
        msType: 'member',
        config_name: '',
        sd_name: '',
        sd_address1: '',
        sd_address2: '',
        sd_address3: '',
        sd_town: '',
        sd_county: '',
        sd_postcode: '',
        sd_country: '',
        sd_contactname: '',
        sd_telephone: '',
        //sd_fax: '',
        sd_vatnumber: '',
        sd_email: '',
        sd_customerservicetelephone: '',
        sd_discount_term_1: '',
        sd_discount_term_2: '',
        sd_retro_dis_term_1: '',
        sd_retro_dis_term_2: '',
        sd_levy_term_1: '',
        sd_levy_term_2: '',
        loader: false,
        active: true,
        id: '1',
        pageLoader: false
    })
    const msTypes = [{ text: 'Member', value: 'member' }, { text: 'Supplier', value: 'supplier' }];

    const handleChange = (event) => {
        const { value, name } = event.target;
        event.preventDefault()
        setState({ ...state, [name]: value })
    };
    const fetchMyAPI = async () => {
        try {
            setState({ ...state, pageLoader: true })
            const { data } = await axio_utilities.get(`standingdatas/${state.msType}`);
            if (data) {
                setState({
                    ...state,
                    config_name: data.configurationName,
                    sd_name: data.name,
                    sd_address1: data.address1,
                    sd_address2: data.address2,
                    sd_address3: data.address3,
                    sd_town: data.town,
                    sd_county: data.county,
                    sd_postcode: data.postCode,
                    sd_country: data.country,
                    sd_contactname: data.contactName,
                    sd_telephone: data.telephone,
                    //sd_fax: data.fax,
                    sd_vatnumber: data.vatNumber,
                    sd_email: data.email,
                    sd_customerservicetelephone: data.customerServiceTelephone,
                    sd_discount_term_1: data.discountInvoiceItem,
                    sd_discount_term_2: data.discountInvoiceTerms,
                    sd_retro_dis_term_1: data.retroDiscountInvoiceItem,
                    sd_retro_dis_term_2: data.retroDiscountInvoiceTerms,
                    sd_levy_term_1: data.leviesInvoiceItem,
                    sd_levy_term_2: data.leviesInvoiceTerms,
                    active: true,
                    id: data.type === 'member' ? '1' : '2',
                    pageLoader: false
                })
            }

        } catch (error) {
            //handle error
        }
    }
    useEffect(() => {
        fetchMyAPI();
    }, [state.msType])

    const handleSubmit = async (event) => {
        event.preventDefault()
        let standingData = {
            "configurationName": state.config_name,
            "name": state.sd_name,
            "address1": state.sd_address1,
            "address2": state.sd_address2,
            "address3": state.sd_address3,
            "town": state.sd_town,
            "county": state.sd_county,
            "postCode": state.sd_postcode,
            "country": state.sd_country,
            "contactName": state.sd_contactname,
            "telephone": state.sd_telephone,
            //"fax": state.sd_fax,
            "vatNumber": state.sd_vatnumber,
            "email": state.sd_email,
            "customerServiceTelephone": state.sd_customerservicetelephone,
            "discountInvoiceItem": state.sd_discount_term_1,
            "discountInvoiceTerms": state.sd_discount_term_2,
            "retroDiscountInvoiceItem": state.sd_retro_dis_term_1,
            "retroDiscountInvoiceTerms": state.sd_retro_dis_term_2,
            "leviesInvoiceItem": state.sd_levy_term_1,
            "leviesInvoiceTerms": state.sd_levy_term_2,
            active: true,
            id: state.msType === 'member' ? '1' : '2'
        }
        try {
            setState({ ...state, loader: true })
            const { data, status } = state.msType === 'member' ? await axio_utilities.put(`standingdatas/member`, standingData) : await axio_utilities.put(`standingdatas/supplier`, standingData);
            if (data.success === true && data.resultMap) {
                setSnackBar({ ...snackBar, show: true, text: data.message, type: "success", })
                setState({ ...state, loader: false, formValid: true, newProductData: data.resultMap })
            }
        } catch (error) {
            const { response: { data, status } } = error;
                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                }
            setState({ ...state, loader: false })
        }
    }
    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Standing Data" />
                <Box sx={{ marginBottom: 2.5 }}>
                    <TableFilters
                        filterFields={<>
                            <Box component="div">
                                <FormControl fullWidth>
                                    <FormLabel htmlFor="msType">Types</FormLabel>
                                    <Select
                                        size="small"
                                        id="msType"
                                        defaultValue=""
                                        onChange={(e) => handleChange(e)}
                                        value={state.msType}
                                        aria-describedby="msTypeError"
                                        name="msType"
                                    >
                                        {msTypes.map((option) => (
                                            <MenuItem value={option.value} key={option.value} sx={{ fontSize: '0.85rem' }}>{option.text}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box component="div">
                                <FormControl fullWidth>
                                    <FormLabel htmlFor="config_name">Types</FormLabel>
                                    <TextField
                                        inputProps={{ maxLength: 100 }}
                                        size="small"
                                        id="config_name"
                                        aria-describedby="config_name_error"
                                        onChange={(e) => handleChange(e)}
                                        value={state.config_name}
                                        name="config_name"
                                    />
                                </FormControl>
                            </Box>
                        </>}>
                    </TableFilters>
                    <form onSubmit={handleSubmit}>
                        <Box component={'div'} sx={{ marginTop: 2.5 }}>
                            <Typography variant="h5" component="h5" sx={{ fontSize: '18px' }}>
                                General &amp; Contact Information
                            </Typography>
                            <Box component={'div'} sx={{ maxWidth: {xs: '100%', sm: '75%', md: '75%'}, paddingTop: 2.5, margin: '0 auto' }}>
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                                        <Item>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="sd_name" className="fontSemiBold">Name</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={7.5}>
                                                    <Item>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                size="small"
                                                                id="sd_name"
                                                                aria-describedby="sd_name_error"
                                                                onChange={(e) => handleChange(e)}
                                                                value={state.sd_name}
                                                                name="sd_name"
                                                            />
                                                        </FormControl>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                                        <Item>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="sd_address1" className="fontSemiBold">Address 1</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={7.5}>
                                                    <Item>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                inputProps={{ maxLength: 100 }}
                                                                size="small"
                                                                id="sd_address1"
                                                                aria-describedby="sd_address1_error"
                                                                onChange={(e) => handleChange(e)}
                                                                value={state.sd_address1}
                                                                name="sd_address1"
                                                            />
                                                        </FormControl>
                                                    </Item>

                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                </Grid>
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                                        <Item>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="sd_address2" className="fontSemiBold">Address 2</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={7.5}>
                                                    <Item>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                size="small"
                                                                id="sd_address2"
                                                                aria-describedby="sd_address2_error"
                                                                onChange={(e) => handleChange(e)}
                                                                value={state.sd_address2}
                                                                name="sd_address2"
                                                            />
                                                        </FormControl>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                                        <Item>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="sd_address3" className="fontSemiBold">Address 3</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={7.5}>
                                                    <Item>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                inputProps={{ maxLength: 100 }}
                                                                size="small"
                                                                id="sd_address3"
                                                                aria-describedby="sd_address3_error"
                                                                onChange={(e) => handleChange(e)}
                                                                value={state.sd_address3}
                                                                name="sd_address3"
                                                            />
                                                        </FormControl>
                                                    </Item>

                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                </Grid>
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                                        <Item>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="sd_town" className="fontSemiBold">Town</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={7.5}>
                                                    <Item>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                size="small"
                                                                id="sd_town"
                                                                aria-describedby="sd_town_error"
                                                                onChange={(e) => handleChange(e)}
                                                                value={state.sd_town}
                                                                name="sd_town"
                                                            />
                                                        </FormControl>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                                        <Item>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="sd_county" className="fontSemiBold">County</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={7.5}>
                                                    <Item>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                inputProps={{ maxLength: 100 }}
                                                                size="small"
                                                                id="sd_county"
                                                                aria-describedby="sd_county_error"
                                                                onChange={(e) => handleChange(e)}
                                                                value={state.sd_county}
                                                                name="sd_county"
                                                            />
                                                        </FormControl>
                                                    </Item>

                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                </Grid>
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                                        <Item>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="sd_postcode" className="fontSemiBold">Post Code</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={7.5}>
                                                    <Item>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                size="small"
                                                                id="sd_postcode"
                                                                aria-describedby="sd_postcode_error"
                                                                onChange={(e) => handleChange(e)}
                                                                value={state.sd_postcode}
                                                                name="sd_postcode"
                                                            />
                                                        </FormControl>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                                        <Item>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="sd_country" className="fontSemiBold">Country</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={7.5}>
                                                    <Item>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                inputProps={{ maxLength: 100 }}
                                                                size="small"
                                                                id="sd_country"
                                                                aria-describedby="sd_country_error"
                                                                onChange={(e) => handleChange(e)}
                                                                value={state.sd_country}
                                                                name="sd_country"
                                                            />
                                                        </FormControl>
                                                    </Item>

                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                </Grid>
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                                        <Item>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="sd_contactname" className="fontSemiBold">Contact Name</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={7.5}>
                                                    <Item>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                size="small"
                                                                id="sd_contactname"
                                                                aria-describedby="sd_contactname_error"
                                                                onChange={(e) => handleChange(e)}
                                                                value={state.sd_contactname}
                                                                name="sd_contactname"
                                                            />
                                                        </FormControl>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                                        <Item>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="sd_telephone" className="fontSemiBold">Telephone</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={7.5}>
                                                    <Item>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                inputProps={{ maxLength: 100 }}
                                                                size="small"
                                                                id="sd_telephone"
                                                                aria-describedby="sd_telephone_error"
                                                                onChange={(e) => handleChange(e)}
                                                                value={state.sd_telephone}
                                                                name="sd_telephone"
                                                            />
                                                        </FormControl>
                                                    </Item>

                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                </Grid>
                                <Grid container columnSpacing={2}>
                                    {/* <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                                        <Item>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="sd_fax" className="fontSemiBold">Fax</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={7.5}>
                                                    <Item>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                size="small"
                                                                id="sd_fax"
                                                                aria-describedby="sd_fax_error"
                                                                onChange={(e) => handleChange(e)}
                                                                value={state.sd_fax}
                                                                name="sd_fax"
                                                            />
                                                        </FormControl>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid> */}
                                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                                        <Item>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="sd_vatnumber" className="fontSemiBold">Vat Number</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={7.5}>
                                                    <Item>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                inputProps={{ maxLength: 100 }}
                                                                size="small"
                                                                id="sd_vatnumber"
                                                                aria-describedby="sd_vatnumber_error"
                                                                onChange={(e) => handleChange(e)}
                                                                value={state.sd_vatnumber}
                                                                name="sd_vatnumber"
                                                            />
                                                        </FormControl>
                                                    </Item>

                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                                        <Item>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="sd_email" className="fontSemiBold">Email</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={7.5}>
                                                    <Item>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                size="small"
                                                                id="sd_email"
                                                                aria-describedby="sd_email_error"
                                                                onChange={(e) => handleChange(e)}
                                                                value={state.sd_email}
                                                                name="sd_email"
                                                            />
                                                        </FormControl>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                </Grid>
                                <Grid container columnSpacing={2}>
                                    
                                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                                        <Item>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="sd_customerservicetelephone" className="fontSemiBold">Customer Service Telephone</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={7.5}>
                                                    <Item>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                inputProps={{ maxLength: 100 }}
                                                                size="small"
                                                                id="sd_customerservicetelephone"
                                                                aria-describedby="sd_customerservicetelephone_error"
                                                                onChange={(e) => handleChange(e)}
                                                                value={state.sd_customerservicetelephone}
                                                                name="sd_customerservicetelephone"
                                                            />
                                                        </FormControl>
                                                    </Item>

                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        <Box component={'div'} sx={{ marginTop: 2.5, paddingTop: 2.5, borderTop: '1px dashed #919396' }}>
                            <Typography variant="h5" component="h5" sx={{ fontSize: '18px' }}>
                                Invoice &amp; Billing Terms
                            </Typography>
                            <Box component={'div'} sx={{ maxWidth: '75%', paddingTop: 2.5, margin: '0 auto' }}>
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: 2.5 }}>
                                        <Item>
                                            <Grid container alignItems="top">
                                                <Grid item xs={12} md={2.2} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="sd_discount_term_1" className="fontSemiBold">Discount Terms</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={9.8}>
                                                    <Item sx={{ marginBottom: 2.5 }}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                size="small"
                                                                id="sd_discount_term_1"
                                                                aria-describedby="sd_discount_term_1_error"
                                                                onChange={(e) => handleChange(e)}
                                                                value={state.sd_discount_term_1}
                                                                name="sd_discount_term_1"
                                                            />
                                                        </FormControl>
                                                    </Item>
                                                    <Item>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                multiline
                                                                rows={3}
                                                                size="small"
                                                                id="sd_discount_term_2"
                                                                aria-describedby="sd_discount_term_2"
                                                                onChange={(e) => handleChange(e)}
                                                                value={state.sd_discount_term_2}
                                                                name="sd_discount_term_2"
                                                            />
                                                        </FormControl>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                </Grid>
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: 2.5 }}>
                                        <Item>
                                            <Grid container alignItems="top">
                                                <Grid item xs={12} md={2.2} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="sd_retro_dis_term_1" className="fontSemiBold">Retrospective Discount Term</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={9.8}>
                                                    <Item sx={{ marginBottom: 2.5 }}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                size="small"
                                                                id="sd_retro_dis_term_1"
                                                                aria-describedby="sd_retro_dis_term_1_error"
                                                                onChange={(e) => handleChange(e)}
                                                                value={state.sd_retro_dis_term_1}
                                                                name="sd_retro_dis_term_1"
                                                            />
                                                        </FormControl>
                                                    </Item>
                                                    <Item>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                multiline
                                                                rows={3}
                                                                size="small"
                                                                id="sd_retro_dis_term_2"
                                                                aria-describedby="sd_retro_dis_term_2"
                                                                onChange={(e) => handleChange(e)}
                                                                value={state.sd_retro_dis_term_2}
                                                                name="sd_retro_dis_term_2"
                                                            />
                                                        </FormControl>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                </Grid>
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: 2.5 }}>
                                        <Item>
                                            <Grid container alignItems="top">
                                                <Grid item xs={12} md={2.2} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="sd_levy_term_1" className="fontSemiBold">Leavy Terms</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={9.8}>
                                                    <Item sx={{ marginBottom: 2.5 }}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                size="small"
                                                                id="sd_levy_term_1"
                                                                aria-describedby="sd_levy_term_1_error"
                                                                onChange={(e) => handleChange(e)}
                                                                value={state.sd_levy_term_1}
                                                                name="sd_levy_term_1"
                                                            />
                                                        </FormControl>
                                                    </Item>
                                                    <Item>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                multiline
                                                                rows={3}
                                                                size="small"
                                                                id="sd_levy_term_2"
                                                                aria-describedby="sd_levy_term_2"
                                                                onChange={(e) => handleChange(e)}
                                                                value={state.sd_levy_term_2}
                                                                name="sd_levy_term_2"
                                                            />
                                                        </FormControl>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{ marginTop: '20px' }}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Item>
                                            <Grid container alignItems="top">
                                                <Grid item xs={12} md={2.2} className="formLabelCntr">
                                                    <Item>
                                                        <FormLabel htmlFor="sd_levy_term_1" className="fontSemiBold">&nbsp;</FormLabel>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12} md={9.8}>
                                                    <Item sx={{ textAlign: 'center' }}>
                                                        <LoadingButton
                                                            loading={state.loader}
                                                            loadingPosition="center"
                                                            endIcon=""
                                                            variant="contained"
                                                            type="submit" sx={{ marginRight: '15px' }}
                                                        >Save</LoadingButton>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box>

                        </Box>
                    </form>
                </Box>
                {snackBar.show && <SnackBarDialog
                    onShow={snackBar.show}
                    text={snackBar.text}
                    type={snackBar.type}
                    onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                />}
            </Container>
        </>
    );
}

export default StandingData;
