import { Box, Paper, Grid, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CancelIcon from '@mui/icons-material/Cancel';
import PageLoader from "../../../../components/shared/PageLoader";
import { useEffect, useState } from 'react';
import { warningModal, handleAddEditPdcPriceModal } from "../../../../store/slices/modals";
import { useDispatch, useSelector } from 'react-redux';
import { setChannelPriceInput, setchannel_priceEffectiveDate, setEditChannelPrice, setDeleteList, setSingleDeleteData, setchannel_priceDateList, setLatestlatestEndPriceDate, setNullPriceStartDate } from "../../../../store/slices/pricePdcProduct";
import { setPdcPriceModal } from "../../../../store/slices/modals";
import { DataTableClientSide } from '../../../../components/shared/data-table/DataTableClientSide';
import AddIcon from '@mui/icons-material/Add';
import AddEditPriceModal from './prices/AddEditPriceModal';
import axiosProductDistribution from '../../../../services/utility/axios-product-distribution';
import SnackBarDialog from '../../../../components/shared/SnackBarDialog';
import moment from 'moment';

const IconMenuLink = ({ params, onDelete }) => {
    const { row } = params;
    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { pdc_general_product_fields, channel_price_container } = useSelector((state) => {
        return {
            editTabData: state.pricePdcProducts.editTabData,
            pdc_general_product_fields: state.pricePdcProducts,
            channel_price_container: state.pricePdcProducts
        }
    });
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'edit') {
            editChannelPriceData(row)
        } else if (action === 'delete') {
            if(onDelete){
                onDelete(row)
            }
        }
        setAnchorEl(null);
    };

    // find latest start date from array
    // const maxDate = new Date(
    //     Math.max(
    //       ...channel_price_container.channel_price_date.map(element => {
    //         return new Date(element.startDate);
    //       }),
    //     ),
    //   ); 
    // var  datelast = moment(maxDate).format('DD/MM/YYYY');
      // Find a value in an array of object (start date) 
    // let latestId = '';
    // let obj = channel_price_container.channel_price_date.find(o => o.endDate === null);
    // if(obj !== undefined) {
    //     latestId = obj.id;
    // } else {
    //     latestId = '';
    // }
   
    //let curDate = moment().format('DD/MM/YYYY');
    //let checkinBetweendates = moment(curDate).isBetween(row.startDate, row.endDate);

    const editChannelPriceData = (row) => {
        dispatch(setPdcPriceModal(true))
        dispatch(setChannelPriceInput(Number(row.price).toFixed(2)))
        dispatch(setEditChannelPrice(row))
        //dispatch(setLatestPdcData(''))
        dispatch(setchannel_priceEffectiveDate({
            key: 'channel_price_start_date',
            value: row.startDate ? moment(row.startDate,'DD/MM/YYYY') : ""
        }))
        dispatch(setchannel_priceEffectiveDate({
            key: 'channel_price_end_date',
            value: row.endDate ? moment(row.endDate,'DD/MM/YYYY'): ""
        }))
    }

    
    return (
       
    <>
            <IconButton
                aria-label="more"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                id={row.id + 1}
            >
                <MoreVertIcon />
            </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'edit')}>Edit Prices</MenuItem>
            <MenuItem onClick={(e) => handleClose(e, 'delete')}>Delete Prices</MenuItem>
        </Menu>
        
    </>
    
    )
}

export default function PdcPrice({ onShow }) {
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })
    const defaultValues = {
        price: '',
        defaultGrid: {
            pagehead: 'PDC',
            eanActionButtons: true,
            paginationMode: 'client',
        },
        addSupplierProductCode: false 
    };
    const [state, setState] = useState(defaultValues);
    const [pageLoader, setPageLoader] = useState(false);
    const [columns, setColumns] = useState([]);

    const allColumns = [
        {
            headerName: "Id",
            field: "id",
            flex: 1,
            hide: true
        },
        {
            headerName: "Price (£)",
            field: "price",
            flex: 1,
        },
        {
            headerName: "Start Date",
            field: "startDate",
            flex: 1,
        },
        {
            headerName: "End Date",
            field: "endDate",
            flex: 1,
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} onDelete={deleteChannelPrice}/>;
            },
        }
    ];
    const { getPdcPrice, showPdcPriceModal,editTabData,hasEditData, editChannelPrice, channel_price_container, handleWarning, showAddEditPriceModal} = useSelector((state) => {
        return {
            showPdcPriceModal: state.modalActions.pdcPriceModal,
            getPdcPrice: state.pricePdcProducts.channel_price_date,
            editTabData: state.pricePdcProducts.editTabData,
            editChannelPrice: state.pricePdcProducts.editChannelPrice,
            hasEditData: state.pricePdcProducts.hasEditData,
            channel_price_container: state.pricePdcProducts,
            handleWarning: state.modalActions.closeWaringAddProduct,
            showAddEditPriceModal: state.modalActions.hideAddEditPdcModal
        }
    });

    const dispatch = useDispatch();

    const allPricelists = async () => {
        try { 
            setPageLoader(true)
            setTimeout(() => {
                setColumns(allColumns)
            }, 100);
            const id = editTabData ? editTabData.id : hasEditData.id;
            const { data } = await axiosProductDistribution.get(`pdcs/${id}/pdcChannelPrices`);
            if (data) {
                setPageLoader(false)
                dispatch(setchannel_priceDateList(data.items))
            }
        } catch (error) {
            setPageLoader(false)
            const { response: { data, status } } = error;
            if (data.message !== "No Product Distribution Channel Prices found") {
                setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: data.message ? "warning" : "error" })
            }
        }
    }
    useEffect(() => {
        allPricelists();
    }, []); 


    const addPriceModal = (event) => {
        dispatch(setPdcPriceModal(true))
        //let latestendDate = '';
        //let nullSetStartDate = '';
         //nullSetStartDate = channel_price_container.channel_price_date.find(o => o.endDate === null) ? channel_price_container.channel_price_date.find(o => o.endDate === null) : null;
        //  if(nullSetStartDate !== null) {
        //     console.log(1)
        //     let lateststartDate = '';
        //     lateststartDate = moment(nullSetStartDate.startDate, 'DD/MM/YYYY').add(2, 'days');
        //     dispatch(setNullPriceStartDate(lateststartDate))
        //     dispatch(setLatestlatestEndPriceDate(''))
        //     dispatch(setchannel_priceEffectiveDate({
        //         key: 'channel_price_start_date',
        //         value: lateststartDate
        //     }))
        // } else {
        //     console.log(2)
        //     let keys = Object.keys(channel_price_container.channel_price_date);
        //     let last = keys[keys.length - 1];
        //     let latest_entry = channel_price_container.channel_price_date[last];
        //      latestendDate = moment(latest_entry.endDate, 'DD/MM/YYYY').add(1, 'days');
        //      dispatch(setLatestlatestEndPriceDate(latestendDate))
        //      dispatch(setNullPriceStartDate(''))
        //      dispatch(setchannel_priceEffectiveDate({
        //         key: 'channel_price_start_date',
        //         value: latestendDate
        //     }))
        // }  
        // if ((channel_price_container.channel_price_date.length > 0)) {  
        //     if (channel_price_container.channel_price_date[0].endDate === "" || channel_price_container.channel_price_date[0].endDate === null || channel_price_container.channel_price_date[0].endDate === undefined) {
        //         dispatch(setchannel_priceEffectiveDate({
        //             key: 'default_channel_price_start_date',
        //             value: moment(channel_price_container.channel_price_date[0].startDate,'DD/MM/YYYY').add(2,'days')
        //         }))
        //     } else {
        //         let keys = Object.keys(channel_price_container.channel_price_date);
        //         let last = keys[keys.length - 1];
        //         let latest_entry = channel_price_container.channel_price_date[last];
        //         dispatch(setchannel_priceEffectiveDate({
        //             key: 'default_channel_price_start_date',
        //             value: moment(latest_entry.endDate, 'DD/MM/YYYY').add(1, 'days')
        //         }))
        //     }
        // }
    }
    const hideAddEditPriceModal = (event) => {
        dispatch(setPdcPriceModal(event))
    }

    const deleteChannelPrice = (row) => {
        dispatch(setDeleteList(row))
        dispatch(setSingleDeleteData({
            key: 'pdcstartSingleDelete',
            value: true
        }))
        dispatch(handleAddEditPdcPriceModal({
            showAddEditPrice: true
        }))
        dispatch(warningModal({
            yesHide: !handleWarning
        }))
    }

    useEffect(() => {
        if (channel_price_container.pdcconfirmSingleDelete) {
            deletePdcSingleRow()
        }
    }, [channel_price_container.pdcconfirmSingleDelete])


    const deletePdcSingleRow = async () => {
        if (channel_price_container.deleteList !== null) {
            const id = editTabData ? editTabData.id : hasEditData.id;
            try {
                setPageLoader(true)
                const { data } = await axiosProductDistribution.delete(`pdcs/${id}/pdcChannelPrices/${channel_price_container.deleteList.id}`);
                if (data.success) {
                    setPageLoader(false)
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
                    const filteredRows = getPdcPrice.filter(uniqueRow => {
                        if (uniqueRow.id !== channel_price_container.deleteList.id) {
                            return uniqueRow;
                        }
                    })
                    dispatch(setchannel_priceDateList(filteredRows))
                    dispatch(setDeleteList(null))
                    pdcsingleDeleteRowDispatch()
                    setState({ ...state, loader: false })
                }

            } catch (error) {
                setPageLoader(false)
                const { response: { data, status } } = error;
                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                }
                dispatch(setDeleteList(null))
                pdcsingleDeleteRowDispatch()
            }
        }
    }

    const pdcsingleDeleteRowDispatch = () => {
        dispatch(setSingleDeleteData({
            key: 'pdcstartSingleDelete',
            value: false
        }))
        dispatch(setSingleDeleteData({
            key: 'pdcconfirmSingleDelete',
            value: false
        }))
    }

    return (
        <>
            {pageLoader && <PageLoader />}
            <Box sx={{ flexGrow: 1, paddingX: 2 }} >
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5 } }}>
                    <div className='table-actionable'>
                        <Button variant="contained" onClick={(e) => addPriceModal(e)} startIcon={<AddIcon />}>
                            Add New
                        </Button>
                    </div>
                </Box>
                <DataTableClientSide
                    columns={columns ? columns : []}
                    defaultGrid={state.defaultGrid}
                    rows={getPdcPrice ? getPdcPrice : []}
                >
                </DataTableClientSide>
                {showPdcPriceModal && (
                    <>
                        <AddEditPriceModal size="medium_overlay"
                            onShow={showPdcPriceModal}
                            onHide={(e, reason) => hideAddEditPriceModal(e, reason)}
                        />
                    </>
                )}
                
            </Box>
            {snackBar.show && <SnackBarDialog
            onShow={snackBar.show}
            text={snackBar.text}
            type={snackBar.type}
            onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
        />}
        </>
    );
}
