import { Container, Box, Modal, Button, Grid, Checkbox, Popper, Grow, Paper, ClickAwayListener, MenuList, ButtonGroup, Stack, TextField, FormControl, FormHelperText, FormLabel, Typography } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TableFilters } from "../../components/shared/data-table/TableFilters";
import { useEffect, useState } from "react";
import { InputText } from "../../components/shared/form/InputText";
import { } from '@mui/material';
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange, filterResetFlagChange } from "../../store/slices/externalFilter";
import { DataTableFailedInvoiceFiles } from "../../components/shared/data-table/DataTableFailedInvoiceFiles";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import { warningModal, handleAddEditModal } from "../../store/slices/modals";
import WarningModal from "../../components/shared/modals/WarningModal";
import { setRowPage, resetDataGrid, setPageNo } from '../../store/slices/dataGrid';
import { useLayoutEffect } from "react";
import axiosInvoiceReader from '../../services/utility/axios-invoice-reader';
import PageLoader from '../../components/shared/PageLoader';
import SnackBarDialog from '../../components/shared/SnackBarDialog';
import { styled } from '@mui/material/styles';
import iconNoProcess from "../../assets/images/icon-no-process.svg"
import iconProcessingFailed from "../../assets/images/icon-processing-failed.svg"
import axiosStatementCycle from "../../services/utility/axios-statement-cycle";
import RefreshIcon from '@mui/icons-material/Refresh';
import axiosInvoiceSummaries from '../../services/utility/axios-invoice-summaries';
import axiosNewInvoiceSummaries from '../../services/utility/axios-new-invoice-summaries';
import { failedImportDeleteYes } from '../../store/slices/failedImports';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    boxShadow: "none",
    background: "transparent"
}));

var selectedidfordeleted = [];

function FailedFiles() {

    const { failed_files_file_name, addProductwarning, showAddEditModal, selectedInvoices, failedInvoiceImports } = useSelector((state) => {
        return {
            failed_files_file_name: state.externalFilter.failed_files_file_name,
            resetTable: state.dataGridTable,
            addProductwarning: state.modalActions.closeWaringAddProduct,
            showAddEditModal: state.modalActions.hideAddEditModal,
            selectedInvoices: state.failedFiles,
            failedInvoiceImports: state.failedImports
        }
    });

    const [state, setState] = useState({
        active_product: false,
        openNewProductModal: false,
        pageLoader: false,
        defaultGrid: {
            pagetitle: "Invoice Processing - Failed Files",
            productActionButton: true,
            hasExport: true,
            addEditButton: true,
            apiUrl: 'deadInvoiceData',
            searchUrl: 'deadInvoiceData/filter',
            checkBoxSelection: true
        }
    });

    const [processRunning, setProcessRunning] = useState(0)
    const [failedInvoiceProcessing, setFailedInvoiceProcessing] = useState(0)
    const [failedInvoiceImport, setFailedInvoiceImport] = useState(0)

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false

    })

    const IconMenuLink = ({ params }) => {
        const { row } = params;
        const [anchorEl, setAnchorEl] = useState(null);
        const open = Boolean(anchorEl);

        const { showAddEditModal, failedFiles_fields } = useSelector((state) => {
            return {
                showAddEditModal: state.modalActions.hideAddEditModal,
                editTabData: state.failedFiles.editTabData,
                failedFiles_fields: state.failedFiles
            }
        });
        const dispatch = useDispatch();

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = (event, action) => {
            event.preventDefault();
            if (action === 'reprocess') {
                if (onReprocess) {

                    onReprocess(event, row);
                }
                // dispatch(handleAddEditModal({
                //     showAddEdit: !showAddEditModal
                // }))
                // dispatch(hasTabEdit({ editTab: false }))
                // setEditGeneralData(row);

            }
            else if (action === 'delete') {
                selectedidfordeleted.push(row.id);
                dispatch(failedImportDeleteYes({
                    key: 'startDelete',
                    value: true
                }))
                dispatch(warningModal({
                    yesHide: true
                }))

            }
            setAnchorEl(null);
        };
        const setEditGeneralData = async (row) => {


        }
        return (<>
            <IconButton
                aria-label="more"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                id={row.id + 1}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id={row.id}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                className="actionMenuBx"
            >
                <MenuItem onClick={(e) => handleClose(e, 'reprocess')}>Reprocess</MenuItem>
                <MenuItem onClick={(e) => handleClose(e, 'delete')}>Delete</MenuItem>
            </Menu>
        </>)
    }

    const columns = [
        {
            headerName: "Id",
            field: "id",
            width: 80
        },
        {
            headerName: "Source",
            field: "source",
            width: 400,
            renderCell: (params) => (
                <Typography title={params.value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px' }}>{params.value ? params.value : "Null"}</Typography>
            )
        },
        {
            headerName: "Error Log",
            description: "Consisting of all invoices associated to EDI file",
            renderHeader: () => (
                <strong style={{ fontWeight: 600, position: 'relative', paddingRight: '40px' }}>
                    {'Error Log '}
                    <span style={{ padding: '0 5px 0 0', position: 'absolute', top: '7px', left: '65px' }} title="Consisting of all invoices associated to EDI file.">
                        <InfoIcon color="action" />
                    </span>
                </strong>
            ),
            field: "errorLog",
            width: 600,
            flex: 1,
            //valueFormatter: ({ value }) => value ? value : "Error Message Details will appear here."
            renderCell: (params) => (
                <Typography title={params.value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px' }}>{params.value}</Typography>
            )
        },

        {
            headerName: "MIME Type",
            field: "mimeType",
            width: 180,
            renderCell: (params) => (
                <Typography title={params.value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px' }}>{params.value ? params.value : "Null"}</Typography>
            )
        },
        {
            headerName: "URI",
            field: "uri",
            width: 400,
            renderCell: (params) => (
                <Typography title={params.value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px' }}>{params.value ? params.value : "Null"}</Typography>
            )
        },

        {
            headerName: "Action",
            field: "action",
            sortable: false,
            minWidth: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} onReprocess={(e, rows) => { onReprocess(e, rows) }} />;
            },
        }
    ];


    const dispatch = useDispatch();

    const handleFilterInput = (value, id) => {
        //setState({ ...state, [id]: value });
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    };



    const filterBtn = () => {
        dispatch(resetDataGrid());
        dispatch(applyFilter({
            key: 'filterList',
            value: {
                "source": failed_files_file_name
            }
        }))
        dispatch(filterFlagChange(true));
        dispatch(filterResetFlagChange(false));
        dispatch(setRowPage({ pageData: 10 }));
        dispatch(setPageNo({ page: 1 }));
    }
    const resetFilter = () => {
        if (failed_files_file_name !== '') {
            dispatch(resetExternalFilter())
            dispatch(resetDataGrid());
        }
        setState({ ...state, active_product: false })
        dispatch(filterResetFlagChange(true));
    }


    const onReprocess = (event, params) => {
        const { id } = params;
        onReprocessInvoice(event, [id])
    }

    const onReprocessInvoice = async (event, params) => {
        event.preventDefault();
        // console.log(selectedInvoices.selectedIds)
        let processId = null;

        if (params) {
            processId = params;
        } else {
            processId = selectedInvoices.selectedIds;
        }

        console.log(processId);

        if (processId.length > 0) {
            let payload = processId.map((x) => {
                return { id: x };
            });
            try {
                setState({ ...state, pageLoader: true })
                const { data } = await axiosInvoiceReader.post(`deadInvoiceData/markReprocessDeadInvoiceData`, payload);

                if (data.success) {
                    setState({ ...state, pageLoader: false })
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
                    setTimeout(() => {
                        window.location.reload()
                    }, 2500);
                }
                else {
                    let errormessage = String(data.message);
                    setSnackBar({ ...snackBar, show: true, text: errormessage ? errormessage : "Something went wrong!", type: "error" });
                    setState({ ...state, pageLoader: false });
                }

            } catch (error) {
                const { response: { data, status } } = error;
                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                }
                setState({ ...state, pageLoader: false })
            }
        }
        else {
            setSnackBar({ ...snackBar, show: true, text: "Please select an invoice to reprocess!", type: "error" });
        }

        // if (params) {
        //     processId = params;
        //     let payload = processId.map((x) => {
        //         return { id: x };
        //     });

        //     try {
        //         setState({ ...state, pageLoader: true })
        //         const { data } = await axiosInvoiceReader.post(`deadInvoiceData/markReprocessDeadInvoiceData`, payload);

        //         if (data.success) {
        //             setState({ ...state, pageLoader: false })
        //             setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
        //             setTimeout(() => {
        //                 window.location.reload()
        //             }, 2500);
        //         }
        //         else {

        //             let errormessage = String(data.message);
        //             setSnackBar({ ...snackBar, show: true, text: errormessage ? errormessage : "Something went wrong!", type: "error" });
        //             setState({ ...state, pageLoader: false });

        //         }
        //         // setTimeout(() => {
        //         //     window.location.reload()
        //         // }, 2500);
        //     } catch (error) {
        //         const { response: { data, status } } = error;
        //         if (!data) {
        //             setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
        //         } else {
        //             setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
        //         }
        //         setState({ ...state, pageLoader: false })
        //     }
        // }
        // else {
        //     setSnackBar({ ...snackBar, show: true, text: "Please select an invoice to reprocess!", type: "error" })
        // }

    }
    useEffect(() => {
        importedInvoiceFailed();
        invoiceProcessingFailed();
        checkProcessRunning();
    }, [])

    const importedInvoiceFailed = async (url) => {
        try {
            const { data } = await axiosNewInvoiceSummaries.get('/deadImportedInvoices/countDeadImportedInvoice')
            if (data) {
                setFailedInvoiceImport(data.resultMap.countDeadImportedInvoice)

            }
        } catch (error) {
            const { response: { data, status }, message } = error;
            if (status === 500 || status === 501 || status === 502 || status === 503 || status === 504 || status === 505 || status === 0) {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" });
            } else {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
            }
            setState({ ...state, loader: false })
        }
    }
    const invoiceProcessingFailed = async (url) => {
        try {
            const { data } = await axiosNewInvoiceSummaries.get('/deadInvoiceData/countDeadInvoiceData')
            if (data) {
                setFailedInvoiceProcessing(data.resultMap.countDeadInvoiceData)

            }
        } catch (error) {
            const { response: { data, status }, message } = error;
            if (status === 500 || status === 501 || status === 502 || status === 503 || status === 504 || status === 505 || status === 0) {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" });
            } else {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
            }
            setState({ ...state, loader: false })
        }
    }
    const checkProcessRunning = async (url) => {
        try {
            const { data } = await axiosStatementCycle.get('/statementcycle/getAllRunningProcess')
            if (data) {
                setProcessRunning(data.resultMap.RunningProcessCount)

            }
        } catch (error) {
            const { response: { data, status }, message } = error;
            if (status === 500 || status === 501 || status === 502 || status === 503 || status === 504 || status === 505) {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" });
            }
            else if (error.message === 'Network Error') {
                setSnackBar({ ...snackBar, show: true, text: "The services are down at the moment.", type: "error", });
            }
            else {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
            }
            setState({ ...state, loader: false })
        }
    }

    useEffect(() => {
        const fetchMyAPI = async () => {
            if (failedInvoiceImports.confirmDelete) {
                setState({ ...state, pageLoader: true })
                let processId = null;
                if (selectedidfordeleted) {
                    processId = selectedidfordeleted
                } else {
                    processId = selectedInvoices.selectedIds
                }

                if (processId.length > 0) {

                    let payload = processId.map((x) => {
                        return { id: x };
                    })

                    try {
                        const { data } = await axiosNewInvoiceSummaries.delete(`deadInvoiceData/${processId}`, {});
                        if (data.success) {
                            setState({ ...state, pageLoader: false })
                            setSnackBar({ ...snackBar, show: true, text: data.message ? data.message : "Invoice deleted!", type: "success" })
                            setTimeout(() => {
                                window.location.reload()
                            }, 2500);
                        }

                    } catch (error) {
                        const { response: { data, status } } = error;
                        if (!data) {
                            setSnackBar({ ...snackBar, show: true, text: error.message ? error.message : "Something went wrong!", type: "error" })
                        } else {
                            setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                        }
                        setState({ ...state, pageLoader: false });

                    }
                } else {
                    // setSnackBar({ ...snackBar, show: true, text: "Please Select an Invoice!", type: "error" })
                }
            }
            else {
                selectedidfordeleted = [];
            }
        }
        fetchMyAPI();

    }, [failedInvoiceImports.confirmDelete])

    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Invoice Processing - Failed Files" />
                <Box sx={{ marginBottom: 2.5 }}>
                    <TableFilters
                        filterFields={<>
                            <Box component="div"><InputText onChange={handleFilterInput} maxWidth={50} label="Source" id="failed_files_file_name" size="small" /></Box>
                        </>}
                        filterCheckboxes={<>
                            <Box component="div">
                                <Grid container alignItems={'center'}>
                                    <Grid>
                                        <Item>
                                            <img src={iconNoProcess} alt="icon" />
                                        </Item>
                                    </Grid>
                                    <Grid sx={{ paddingTop: '3px' }}>
                                        <Item>
                                            {
                                                processRunning ? `Process running (${processRunning})` : 'No process running'
                                            }
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box component="div">
                                <Grid container alignItems={'center'}>
                                    <Grid>
                                        <Item>
                                            <img src={iconProcessingFailed} alt="icon" />
                                        </Item>
                                    </Grid>
                                    <Grid sx={{ paddingTop: '3px' }}>
                                        <Item>
                                            {`Invoice processing failed (${failedInvoiceProcessing})`}
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box component="div">
                                <Grid container alignItems={'center'}>
                                    <Grid>
                                        <Item>
                                            <img src={iconProcessingFailed} alt="icon" />
                                        </Item>
                                    </Grid>
                                    <Grid sx={{ paddingTop: '3px' }}>
                                        <Item>
                                            {`Invoice file import failed (${failedInvoiceImport})`}
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box component="div">
                                <Box component={'div'} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={(e) => window.location.reload()}>
                                    <RefreshIcon color="primary"></RefreshIcon> <span style={{ marginLeft: '5px', color: '#B253AE' }}>Refresh</span>
                                </Box>
                            </Box>
                        </>}
                        filterBtns={
                            <>
                                <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                <Button variant="contained" onClick={filterBtn}>Apply</Button>
                            </>}>
                    </TableFilters>
                </Box>
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -3 } }}>
                    <div className='table-actionable'>
                        <Box component={'div'} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={filterBtn}>
                            <RefreshIcon color="primary"></RefreshIcon> <span style={{ marginLeft: '5px', color: '#B253AE' }}>Reload Table</span>
                        </Box>
                    </div>
                    <div className='table-actionable'>
                        <Button variant="contained" color="tertiary" size='small' onClick={(e) => { onReprocessInvoice(e) }} >
                            Reprocess Selection
                        </Button>
                    </div>
                </Box>

                <DataTableFailedInvoiceFiles defaultGrid={state.defaultGrid} columns={columns}></DataTableFailedInvoiceFiles>
                {
                    // showAddEditModal && (
                    //     <AddEditProduct tabs={tabs}
                    //         onShow={showAddEditModal}
                    //         onHide={(e, reason) => hideNewProductModal(e, reason)}

                    //     />
                    // )
                }
                {
                    addProductwarning && (
                        <WarningModal size="small_overlay" onShow={addProductwarning} />
                    )
                }
                {snackBar.show && <SnackBarDialog
                    onShow={snackBar.show}
                    text={snackBar.text}
                    type={snackBar.type}
                    onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                />}
            </Container>

        </>
    );
}

export default FailedFiles;
