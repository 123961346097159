import { Container, Box, Modal, Button, Grid, Checkbox, Popper, Grow, Paper, ClickAwayListener, MenuList, ButtonGroup, Stack, TextField, FormControl, FormHelperText, FormLabel, IconButton, Select } from '@mui/material';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TableFilters } from "../../components/shared/data-table/TableFilters";
import { useEffect, useState } from "react";
import { InputText } from "../../components/shared/form/InputText";
import { } from '@mui/material';
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange, setInvoiceReprocessFromDate, setInvoiceReprocessToDate, filterResetFlagChange } from "../../store/slices/externalFilter";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import moment from "moment";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import { ThreeStateCheckbox } from "../../components/shared/form/ThreeStateCheckbox";
import { warningModal, handleAddEditModal, setNewPriceModal, setInvRepProductModal } from "../../store/slices/modals";
import WarningModal from "../../components/shared/modals/WarningModal";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { setRowPage, resetDataGrid, setPageNo } from '../../store/slices/dataGrid';
import { useLayoutEffect } from "react";
import { hasTabEdit } from "../../store/slices/generalProduct";
import { AutoCompleteStatic } from "../../components/shared/form/AutoCompleteStatic";
import axios_invoice_summaries from '../../services/utility/axios-invoice-summaries';
import PageLoader from '../../components/shared/PageLoader';
import SnackBarDialog from '../../components/shared/SnackBarDialog';
import { brandSearch, hasEdit, hasEditData, smaInput, setAutocomplete, getIsError, setMemberCode } from "../../store/slices/supplierMemberAccounts";
import CommonPopUp from "../products/addEditPdc/pdc-tabs/CommonPopUp";
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/material/styles';
import { setSelectedRow } from "../../store/slices/invoiceViewDetails";
import InvoiceDetails from '../invoices/detailInvoices/InvoiceDetails';
import LinesandDiscountsTab from '../invoices/detailInvoices/invoice-details-tabs/lines-discounts';
import LineDisputeLogTab from '../invoices/detailInvoices/invoice-details-tabs/line-dispute-log';
import SelectedInvoiceTab from '../invoices/detailInvoices/invoice-details-tabs/selected-invoice';
import { DataTableInvoiceReprocess } from '../../components/shared/data-table/DataTableInvoiceReprocess';
import { DataTableProcessHistory } from '../../components/shared/data-table/DataTableProcessHistory';
import iconNoProcess from "../../assets/images/icon-no-process.svg"
import iconProcessingFailed from "../../assets/images/icon-processing-failed.svg"
import axiosStatementCycle from "../../services/utility/axios-statement-cycle";
import RefreshIcon from '@mui/icons-material/Refresh';
import axiosInvoiceSummaries from '../../services/utility/axios-invoice-summaries';
import axios_new_invoice_summaries from '../../services/utility/axios-new-invoice-summaries';
import InvoiceReprocesHistory from '../invoices/detailInvoiceReprocesses/InvoiceReprocesHistory';
import { Link } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    boxShadow: "none",
    background: "transparent"
}));

function ServerProcessHistory() {

    const [query, setQuery] = useState("")

    // eslint-disable-next-line max-len
    const { invRepSupplierInvoiceCode, invRepSupplierProductCode, invRepSupplierName, invRepBrand, invRepBrandOwner, invRepProduct, invRepMember, invRepDc, fromDate, toDate, invRepSystemDisputed, invRepUserDisputed,
        invRepCollected, addProductwarning, showAddEditModal, invoiceReprocess, searchProductModal, showNewPriceoneModal, sma_container, showNewProductModal } = useSelector((state) => {
            return {
                invRepSupplierInvoiceCode: state.externalFilter.invrpc_supplier_invcode,
                invRepSupplierProductCode: state.externalFilter.invrpc_spc,
                invRepSupplierName: state.externalFilter.invrpc_supplier_name,
                invRepBrand: state.externalFilter.invrpc_brand,
                invRepBrandOwner: state.externalFilter.invrpc_brand_owner,
                invRepProduct: state.externalFilter.invrpc_product,
                invRepMember: state.externalFilter.invrpc_member,
                invRepDc: state.externalFilter.invrpc_dc,
                fromDate: state.externalFilter.invrpc_from_date,
                toDate: state.externalFilter.invrpc_to_date,
                invRepSystemDisputed: state.externalFilter.invrpc_system_disputed_active,
                invRepUserDisputed: state.externalFilter.invrpc_user_disputed_active,
                invRepCollected: state.externalFilter.invrpc_collected_active,
                addProductwarning: state.modalActions.closeWaringAddProduct,
                showAddEditModal: state.modalActions.hideAddEditModal,
                invoiceReprocess: state.invoiceReprocess,
                searchProductModal: state.commonProducts,
                showNewPriceoneModal: state.modalActions.priceModal,
                showNewProductModal: state.modalActions.invrepProductModal,
                sma_container: state.smas
            }
        });

    const [state, setState] = useState({
        pageLoader: false,
        invrpc_system_disputed_active: null,
        invrpc_user_disputed_active: null,
        invrpc_collected_active: null,
        openinvoiceSummarysModal: false,
        handleOCBC: false,
        handleSupplierMemberModal: '',
        handleSPC: false,
        brandListModal: false,
        sp_name: '',
        sp_id: '',
        status_history_modal: false,
        status_history_id: null,
        msType: '',
        recivedTime: null,
        processStatus: '',
        defaultGrid: {
            pdcActionButton: true,
            hasExport: true,
            addEditButton: true,
            apiUrl: '/serverprocess',
            searchUrl: '/serverprocess/filter',
            checkBoxSelection: true
        }
    });

    const [processRunning, setProcessRunning] = useState(0)
    const [failedInvoiceProcessing, setFailedInvoiceProcessing] = useState(0)
    const [failedInvoiceImport, setFailedInvoiceImport] = useState(0)

    const dispatch = useDispatch();

    // const IconMenuLink = ({ params, onReprocess_retros, onReprocess, onReverse, onCredit_reprocess, onStatus_history }) => {
    //     const { row } = params;
    //     const [anchorEl, setAnchorEl] = useState(null);
    //     const open = Boolean(anchorEl);

    //     const handleClick = (event) => {
    //         setAnchorEl(event.currentTarget);
    //     };
    //     const handleClose = (event, action) => {
    //         event.preventDefault();
    //         if (action === 'view_more') {
    //             dispatch(handleAddEditModal({
    //                 showAddEdit: !showAddEditModal
    //             }));
    //             dispatch(hasTabEdit({ editTab: false }))
    //             dispatch(setSelectedRow({ selectedrow: row }));
    //         } else if (action === 'delete') {
    //             //handle delete
    //         } else if (action === 'reprocess_retros') {
    //             if (onReprocess_retros) {
    //                 onReprocess_retros(event, row)
    //             }
    //         } else if (action === 'reprocess') {
    //             if (onReprocess) {
    //                 onReprocess(event, row)
    //             }
    //         } else if (action === 'reverse') {
    //             if (onReverse) {
    //                 onReverse(event, row)
    //             }
    //         } else if (action === 'credit_reprocess') {
    //             if (onCredit_reprocess) {
    //                 onCredit_reprocess(event, row)
    //             }
    //         }
    //         else if (action === 'status_history') {
    //             if (onStatus_history) {
    //                 onStatus_history(event, row)
    //             }
    //         }
    //         setAnchorEl(null);
    //     };

    //     return (<>
    //         <IconButton
    //             aria-label="more"
    //             aria-controls={open ? 'long-menu' : undefined}
    //             aria-expanded={open ? 'true' : undefined}
    //             aria-haspopup="true"
    //             onClick={handleClick}
    //             id={row.invoiceId + 1}
    //         >
    //             <MoreVertIcon />
    //         </IconButton>
    //         <Menu
    //             id={row.invoiceId}
    //             anchorEl={anchorEl}
    //             open={open}
    //             onClose={handleClose}
    //             MenuListProps={{
    //                 'aria-labelledby': 'basic-button',
    //             }}
    //             className="actionMenuBx"
    //         >
    //             <MenuItem onClick={(e) => handleClose(e, 'view_more')}>View More</MenuItem>
    //             <MenuItem onClick={(e) => handleClose(e, 'reprocess_retros')}>Reprocess Retros</MenuItem>
    //             <MenuItem onClick={(e) => handleClose(e, 'reprocess')}>Reprocess</MenuItem>
    //             <MenuItem onClick={(e) => handleClose(e, 'reverse')}>Reverse</MenuItem>
    //             <MenuItem onClick={(e) => handleClose(e, 'credit_reprocess')}>Credit and Reprocess</MenuItem>
    //             <MenuItem onClick={(e) => handleClose(e, 'status_history')}>Status History</MenuItem>
    //         </Menu>
    //     </>)
    // }

    const IconMenuLink = ({ params }) => {
        const { row } = params;
        const [anchorEl, setAnchorEl] = useState(null);
        const open = Boolean(anchorEl);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = (event) => {
            event.preventDefault();
            setAnchorEl(null);
        };


        return (<>
            <IconButton
                aria-label="more"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                id={row.id + 1}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id={row.id}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                className="actionMenuBx"
            >
                <MenuItem>
                    <Link to={'/invoices/summary'} target={'_blank'}>Invoice Summary</Link>
                </MenuItem>
                <MenuItem >
                    <Link to={'/invoices/edi-file-log'} target={'_blank'}>EDI Dashboard</Link>
                </MenuItem>
            </Menu>
        </>)
    }

    const tabs = [
        {
            label: "Lines & Discounts",
            Component: <LinesandDiscountsTab />
        },
        {
            label: "Line Dispute Log",
            Component: <LineDisputeLogTab />
        },
        {
            label: "Selected Invoice",
            Component: <SelectedInvoiceTab />
        }
    ];

    const columns = [
        {
            headerName: "Id",
            field: "id",
            minWidth: 100,
        },
        {
            headerName: "Process Name",
            field: "clazz",
            minWidth: 200,
            flex: 1
        },
        {
            headerName: "Initiated By",
            field: "loggedBy",
            minWidth: 200,
            flex: 1
        },
        {
            headerName: "Process status",
            field: "succeeded",
            minWidth: 200,
            flex: 1
        },
        // {
        //     headerName: "Start Date",
        //     field: "sendTime",
        //     minWidth: 200,
        //     renderCell: (params) => {
        //         if (params.value === null || params.value === "") {
        //             return params.value
        //         }
        //         return moment(params.value).format('DD/MM/YYYY');
        //     }
        // },
        {
            headerName: "Start Date & Time",
            field: "sendTimeString",
            minWidth: 200,
            renderCell: (params) => {
                if (params.value === null || params.value === "") {
                    return params.value
                }
                return moment(params.value).format('DD/MM/YYYY HH:mm:ss');
            }
        },
        {
            headerName: "Recieved Date & Time",
            field: "recievedTimeString",
            minWidth: 200,
            renderCell: (params) => {
                if (params.value === null || params.value === "") {
                    return params.value
                }
                return moment(params.value).format('DD/MM/YYYY HH:mm:ss');
            }
        },
        {
            headerName: "End Date & Time",
            field: "finishTimeString",
            minWidth: 200,
            renderCell: (params) => {
                if (params.value === null || params.value === "") {
                    return params.value
                }
                return moment(params.value).format('DD/MM/YYYY HH:mm:ss');
            }
        },
        {
            headerName: "Cut Off Date",
            field: "cutOffDate",
            minWidth: 200,
            renderCell: (params) => { return params.value ? params.value : "" }
        },
        {
            headerName: "Invoice ID",
            field: "invoiceId",
            minWidth: 200,
            flex: 1
        },
        {
            headerName: "Imported Invoice ID",
            field: "importedInvoiceId",
            minWidth: 200,
            flex: 1
        },
        {
            headerName: "Deadinvoice ID",
            field: "deadInvoiceDataId",
            minWidth: 200,
            flex: 1
        },
        {
            headerName: "Error Message",
            field: "errorLog",
            minWidth: 200,
            flex: 1
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} />;
            },
        }
    ];


    const msTypes = [
        { text: 'Disputed Invoice Executable', value: 'DisputedInvoiceExecutable' },
        { text: 'Levy Billing Executable', value: 'LevyBillingExecutable' },
        { text: 'Member Invoice Print Executable', value: 'MemberInvoicePrintExecutable' },
        { text: 'Retro Billing Executable', value: 'RetroBillingExecutable' },
        { text: 'Retro Final Executable', value: 'RetroFinalExecutable' },
        { text: 'Retro Interim Executable', value: 'RetroInterimExecutable' },
        { text: 'Bill Discounts', value: 'billdiscounts' },
        { text: 'Member Statement', value: 'memeberstatement' },
        { text: 'Member Statement Reconciliation', value: 'memberstatement_reconciliation' },
        { text: 'Member Collection', value: 'memebercollection' },
        { text: 'Member Collection Reconciliation', value: 'membercollection_reconciliation' },
        { text: 'Remittance', value: 'remittance' },
        { text: 'Remittance Reconciliation', value: 'remittance_reconciliation' },
        // { text: 'Reconciliation', value: 'reconciliation' },
        // { text: 'Rollback', value: 'rollback' },
        { text: 'Accounting Entry Export', value: 'accountingentryexport' },
        { text: 'Invoice Reprocess', value: 'InvoiceReprocess' },
        { text: 'Invoice Reverse Resubmit', value: 'InvoiceReverseResubmit' },
        { text: 'Invoice Reverse', value: 'InvoiceReverse' },
        { text: 'Invoice Reprocess Retro', value: 'InvoiceReprocessRetro' },
        { text: 'Invoice Release Dispute', value: 'InvoiceReleaseDispute' },
        { text: 'Invoice Set Dispute', value: 'InvoiceSetDispute' }
    ];
    const processStatus = [
        { text: 'Started', value: 'Started' },
        { text: 'Success', value: 'Success' },
        { text: 'Failed', value: 'Failed' },
        { text: 'Skipped', value: 'Skipped' },
        // { text: 'Rolledback', value: 'Rolledback' }
    ];

    const handleChange = (event) => {
        const { value, name } = event.target;
        event.preventDefault()
        setState({ ...state, [name]: value })
    };

    const handleReceivedTime = (event) => {
        setState({ ...state, recivedTime: event === null ? null : moment(event, 'DD/MM/YYYY') })
    }


    useEffect(() => {
        // This Use Effect is used to clear the Lookups when the page loads.
        dispatch(setMemberCode({
            key: 'smaSupplierNameOnModal',
            value: ""
        }));
        dispatch(smaInput({
            key: 'sma_supplier_name',
            value: ""
        }));
        dispatch(setMemberCode({
            key: 'smaMemberCodeOnModal',
            value: ""
        }));
        dispatch(smaInput({
            key: 'sma_member_name',
            value: ""
        }));

        dispatch(setMemberCode({
            key: 'smaSupplierCodeOnModal',
            value: ""
        }));

    }, [])

    const addOCBC = (e) => {
        e.preventDefault()
        setState({ ...state, handleOCBC: true })

    }

    const hideOCBCModal = (params, row) => {
        if (row) {
            setState({ ...state, handleOCBC: params === false ? params : true, product_name: row.name, outer_case_barcode: row.outerCaseBarcode });
        }
        else {
            setState({ ...state, handleOCBC: params === false ? params : true });
        }
    }

    const loadMemberAccountWithMemberId = (e) => {
        if (e !== '') {
            setQuery(e)
        }
    }

    const CommonPopUpModal = (params, event) => {
        if (event === 'supplier') {
            setState({ ...state, handleSupplierMemberModal: event })
            dispatch(setNewPriceModal(true))
        }
        if (event === 'supplier-code') {
            if (invRepSupplierName === null || invRepSupplierName === "") {
                setSnackBar({ ...snackBar, show: true, text: "Please select supplier", type: "error" })
            } else {
                setState({ ...state, handleSPC: true, handleSupplierMemberModal: event })
            }
        }
        if (event === 'members') {
            setState({ ...state, handleSupplierMemberModal: event })
            dispatch(setNewPriceModal(true))
        }
        if (event === 'product') {
            setState({ ...state, handleSupplierMemberModal: '' })
            dispatch(setInvRepProductModal(true))
        }
        // if (event === 'brand') {
        //     setState({ ...state, brandListModal: true })
        // }
    }

    const hidePriceModal = (event, reason, type) => {
        if (type === 'product') {
            if (reason) {
                dispatch(setFilter({
                    key: 'invrpc_product',
                    value: reason.id ? reason : ''
                }))
            }
            dispatch(setInvRepProductModal(event));
        }
        // if (type === 'brand') {
        //     if (reason) {
        //         dispatch(setFilter({
        //             key: 'invrpc_brand',
        //             value: reason ? reason : ''
        //         }))
        //     }
        //     setState({ ...state, brandListModal: event === false ? event : true })
        // }
        if (type === 'supplier') {
            if (reason) {
                dispatch(setFilter({
                    key: 'invrpc_supplier_name',
                    value: reason ? reason : null
                }))
            }
            dispatch(setNewPriceModal(event));
        }
        if (type === 'supplier-code') {
            if (reason) {
                dispatch(setMemberCode({
                    key: 'smaSupplierCodeOnModal',
                    value: reason ? reason.supplierProductCode : null
                }));
                dispatch(setFilter({
                    key: 'invrpc_spc',
                    value: reason ? reason.supplierProductCode : null
                }))
            }
            setState({ ...state, handleSPC: event })
        }
        if (type === 'members') {
            if (reason) {
                dispatch(setFilter({
                    key: 'invrpc_member',
                    value: reason ? reason : null
                }))
            }
            dispatch(setNewPriceModal(event));
        }
    }

    const resetLookups = (params, event) => {
        if (event === 'supplier') {
            dispatch(setMemberCode({
                key: 'smaSupplierNameOnModal',
                value: ""
            }))
            dispatch(smaInput({
                key: 'sma_supplier_name',
                value: ""
            }))
            dispatch(setFilter({
                key: 'invrpc_supplier_name',
                value: null
            }))
        }
        if (event === 'members') {
            dispatch(setMemberCode({
                key: 'smaMemberCodeOnModal',
                value: ""
            }))
            dispatch(smaInput({
                key: 'sma_member_name',
                value: ""
            }))

        }
        if (event === "product") {
            dispatch(setFilter({
                key: 'invrpc_product',
                value: ""
            }))
        }
        // if (event === "brand") {
        //     dispatch(setFilter({
        //         key: 'invrpc_brand',
        //         value: ""
        //     }))
        // }
        if (event === 'supplier-code') {
            dispatch(setMemberCode({
                key: 'smaSupplierCodeOnModal',
                value: ''
            }));
        }

    }

    // const handleClose = () => {
    //     if (onHide) {
    //         onHide()
    //     }
    // };


    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false

    })

    const handleStartDate = (event) => {
        const sDate = (event === null) ? null : moment(event, 'DD/MM/YYYY')
        dispatch(setInvoiceReprocessFromDate(sDate))
    };

    const handleEndDate = (event) => {
        const eDate = (event === null) ? null : moment(event, 'DD/MM/YYYY')
        dispatch(setInvoiceReprocessToDate(eDate))
    };





    const handleFilterInput = (value, id) => {
        //setState({ ...state, [id]: value });
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    };

    // const handleChange = (checked, name) => {
    //     setState({ ...state, [name]: checked })
    //     dispatch(setFilter({
    //         key: name,
    //         value: checked
    //     }))
    // };

    const selectedAutoComplete = (value, id) => {
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    }

    const filterBtn = () => {
        dispatch(resetDataGrid());
        var dynamicFilterObjects = {};

        if (state.recivedTime !== '' && state.recivedTime !== null && moment(state.recivedTime).format('DD/MM/YYYY') !== 'Invalid date') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "recievedTime": state.recivedTime ? moment(state.recivedTime).format('DD/MM/YYYY HH:mm:ss') : null
            }
        }
        if (state.msType !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "clazz": state.msType === "" ? null : state.msType
            }
        }
        if (state.processStatus !== '') {
            dynamicFilterObjects = {
                ...dynamicFilterObjects,
                "succeeded": state.processStatus === "" ? null : state.processStatus
            }
        }


        if (state.recivedTime !== "" && state.recivedTime !== null && moment(state.recivedTime).format('DD/MM/YYYY') === 'Invalid date') {
            setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
        }
        else {
            dispatch(applyFilter({
                key: 'filterList',
                value: dynamicFilterObjects
            }));
            dispatch(filterFlagChange(true));
            dispatch(filterResetFlagChange(false));
            dispatch(setRowPage({ pageData: 10 }));
            dispatch(setPageNo({ page: 1 }));
        }


    }
    const resetFilter = () => {
        dispatch(resetExternalFilter())
        dispatch(resetDataGrid());
        setState({ ...state, msType: '', recivedTime: null, processStatus: '' })
        dispatch(filterResetFlagChange(true));
    }

    const hideInvoiceSummaryModal = (event) => {
        setState({ ...state, openinvoiceSummarysModal: false })
    }

    const onReprocessInvoice = async (event, params) => {
        event.preventDefault();

        let processId = null;
        if (params) {
            processId = params
        } else {
            processId = invoiceReprocess.reprocessIds
        }

        if (processId.length > 0) {
            try {
                setState({ ...state, pageLoader: true })
                const { data } = await axios_invoice_summaries.post(`/invoice/reprocess`, processId);
                if (data.success) {
                    setState({ ...state, pageLoader: false })
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
                    setTimeout(() => {
                        // window.location.reload()
                        dispatch(filterFlagChange(true));
                        dispatch(filterResetFlagChange(false));
                    }, 2500);
                }

            } catch (error) {
                const { response: { data, status } } = error;
                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                }
                setState({ ...state, pageLoader: false })
            }
        } else {
            setSnackBar({ ...snackBar, show: true, text: "Please select an invoice!", type: "error" })
        }
    }
    const onReprocessRetros = async (event, params) => {
        event.preventDefault();

        let processId = null;
        if (params) {
            processId = params
        } else {
            processId = invoiceReprocess.reprocessIds
        }

        if (processId.length > 0) {
            try {
                setState({ ...state, pageLoader: true })
                const { data } = await axios_invoice_summaries.post(`/invoice/reprocessRetroQuantities`, processId);
                if (data.success) {
                    setState({ ...state, pageLoader: false })
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
                    setTimeout(() => {
                        // window.location.reload()
                        dispatch(filterFlagChange(true));
                        dispatch(filterResetFlagChange(false));
                    }, 2500);
                }

            } catch (error) {
                const { response: { data, status } } = error;
                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                }
                setState({ ...state, pageLoader: false })
            }
        } else {
            setSnackBar({ ...snackBar, show: true, text: "Please select an invoice!", type: "error" })
        }
    }
    const onReprocessReverse = async (event, params) => {
        event.preventDefault();

        let processId = null;
        if (params) {
            processId = params
        } else {
            processId = invoiceReprocess.reprocessIds
        }

        if (processId.length > 0) {
            try {
                setState({ ...state, pageLoader: true })
                const { data } = await axios_invoice_summaries.post(`/invoice/reverse`, processId);
                if (data.success) {
                    setState({ ...state, pageLoader: false })
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
                    setTimeout(() => {
                        // window.location.reload()
                        dispatch(filterFlagChange(true));
                        dispatch(filterResetFlagChange(false));
                    }, 2500);
                }

            } catch (error) {
                const { response: { data, status } } = error;
                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                }
                setState({ ...state, pageLoader: false })
            }
        } else {
            setSnackBar({ ...snackBar, show: true, text: "Please select an invoice!", type: "error" })
        }
    }
    const onReprocessCredit = async (event, params) => {
        event.preventDefault();

        let processId = null;
        if (params) {
            processId = params
        } else {
            processId = invoiceReprocess.reprocessIds
        }

        if (processId.length > 0) {
            try {
                setState({ ...state, pageLoader: true })
                const { data } = await axios_invoice_summaries.post(`/invoice/reverseAndResubmit`, processId);
                if (data.success) {
                    setState({ ...state, pageLoader: false })
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
                    setTimeout(() => {
                        // window.location.reload()
                        dispatch(filterFlagChange(true));
                        dispatch(filterResetFlagChange(false));
                    }, 2500);
                }

            } catch (error) {
                const { response: { data, status } } = error;
                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                }
                setState({ ...state, pageLoader: false })
            }
        } else {
            setSnackBar({ ...snackBar, show: true, text: "Please select an invoice!", type: "error" })
        }
    }
    const onReprocess_retros = (event, params) => {
        const { invoiceId } = params;
        onReprocessRetros(event, [invoiceId])
    }
    const onReprocess = (event, params) => {
        const { invoiceId } = params;
        onReprocessInvoice(event, [invoiceId])
    }
    const onReverse = (event, params) => {
        const { invoiceId } = params;
        onReprocessReverse(event, [invoiceId])
    }
    const onCredit_reprocess = (event, params) => {
        const { invoiceId } = params;
        onReprocessCredit(event, [invoiceId])
    }
    useEffect(() => {
        importedInvoiceFailed();
        invoiceProcessingFailed();
        checkProcessRunning();
    }, [])
    const importedInvoiceFailed = async (url) => {
        try {
            const { data } = await axios_new_invoice_summaries.get('/deadImportedInvoices/countDeadImportedInvoice')
            if (data) {
                setFailedInvoiceImport(data.resultMap.countDeadImportedInvoice)

            }
        } catch (error) {
            const { response: { data, status }, message } = error;
            if (status === 500 || status === 501 || status === 502 || status === 503 || status === 504 || status === 505 || status === 0) {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" });
            } else {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
            }
            setState({ ...state, loader: false })
        }
    }
    const invoiceProcessingFailed = async (url) => {
        try {
            const { data } = await axios_new_invoice_summaries.get('/deadInvoiceData/countDeadInvoiceData')
            if (data) {
                setFailedInvoiceProcessing(data.resultMap.countDeadInvoiceData)

            }
        } catch (error) {
            const { response: { data, status }, message } = error;
            if (status === 500 || status === 501 || status === 502 || status === 503 || status === 504 || status === 505 || status === 0) {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" });
            } else {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
            }
            setState({ ...state, loader: false })
        }
    }
    const checkProcessRunning = async (url) => {
        try {
            const { data } = await axiosStatementCycle.get('/statementcycle/getAllRunningProcess')
            if (data) {
                setProcessRunning(data.resultMap.RunningProcessCount)

            }
        } catch (error) {
            const { response: { data, status }, message } = error;
            if (status === 500 || status === 501 || status === 502 || status === 503 || status === 504 || status === 505) {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" });
            }
            else if (error.message === 'Network Error') {
                setSnackBar({ ...snackBar, show: true, text: "The services are down at the moment.", type: "error", });
            }
            else {
                setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
            }
            setState({ ...state, loader: false })
        }
    }

    const onStatus_history = (e, params) => {
        setState({ ...state, status_history_modal: true, status_history_id: params.invoiceId })
    }
    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Process History" />
                <Box sx={{ marginBottom: 2.5 }}>
                    <TableFilters
                        filterFields={<>
                            <Box component="div">
                                <FormControl fullWidth>
                                    <FormLabel htmlFor="msType">Process Name</FormLabel>
                                    <Select
                                        size="small"
                                        id="msType"
                                        defaultValue=""
                                        onChange={(e) => handleChange(e)}
                                        value={state.msType}
                                        aria-describedby="msTypeError"
                                        name="msType"
                                    >
                                        {msTypes.map((option) => (
                                            <MenuItem value={option.value} key={option.value} sx={{ fontSize: '0.85rem' }}>{option.text}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box component="div">
                                <FormControl size={'small'} fullWidth>
                                    <FormLabel htmlFor="config_name">Recieved Date</FormLabel>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DesktopDatePicker
                                            value={state.recivedTime}
                                            onChange={(e) => { handleReceivedTime(e) }}
                                            name="recivedTime"
                                            inputFormat="DD/MM/YYYY"
                                            // eslint-disable-next-line max-len
                                            renderInput={({ inputRef, inputProps, InputProps }) => (
                                                <Box component="div" className='date-picker-input'>
                                                    <FormControl size={'small'} fullWidth>
                                                        <TextField size="small" id="recivedTime"
                                                            ref={inputRef} {...inputProps}
                                                            placeholder=""
                                                            aria-describedby="recivedTime_error"
                                                            autoComplete="off"
                                                        />
                                                        <Box className='date-picker-icon'>
                                                            {InputProps?.endAdornment}
                                                        </Box>
                                                    </FormControl>
                                                </Box>
                                            )}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Box>
                            <Box component="div">
                                <FormControl fullWidth>
                                    <FormLabel htmlFor="processStatus">Status</FormLabel>
                                    <Select
                                        size="small"
                                        id="processStatus"
                                        defaultValue=""
                                        onChange={(e) => handleChange(e)}
                                        value={state.processStatus}
                                        aria-describedby="processStatusError"
                                        name="processStatus"
                                    >
                                        {processStatus.map((option) => (
                                            <MenuItem value={option.value} key={option.value} sx={{ fontSize: '0.85rem' }}>{option.text}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </>}
                        filterCheckboxes={<>
                            <Box component="div">
                                <Grid container alignItems={'center'}>
                                    <Grid>
                                        <Item>
                                            <img src={iconNoProcess} alt="icon" />
                                        </Item>
                                    </Grid>
                                    <Grid sx={{ paddingTop: '3px' }}>
                                        <Item>
                                            {
                                                processRunning ? `Process running (${processRunning})` : 'No process running'
                                            }
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box component="div">
                                <Grid container alignItems={'center'}>
                                    <Grid>
                                        <Item>
                                            <img src={iconProcessingFailed} alt="icon" />
                                        </Item>
                                    </Grid>
                                    <Grid sx={{ paddingTop: '3px' }}>
                                        <Item>
                                            {`Invoice processing failed (${failedInvoiceProcessing})`}
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box component="div">
                                <Grid container alignItems={'center'}>
                                    <Grid>
                                        <Item>
                                            <img src={iconProcessingFailed} alt="icon" />
                                        </Item>
                                    </Grid>
                                    <Grid sx={{ paddingTop: '3px' }}>
                                        <Item>
                                            {`Invoice file import failed (${failedInvoiceImport})`}
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box component="div">
                                <Box component={'div'} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={(e) => window.location.reload()}>
                                    <RefreshIcon color="primary"></RefreshIcon> <span style={{ marginLeft: '5px', color: '#B253AE' }}>Refresh</span>
                                </Box>
                            </Box>
                        </>}
                        // processRunning={<>
                        //     <Box component="div">
                        //         <Grid container alignItems={'center'}>
                        //             <Grid>
                        //                 <Item>
                        //                     <img src={iconNoProcess} alt="icon" />
                        //                 </Item>
                        //             </Grid>
                        //             <Grid sx={{ paddingTop: '3px' }}>
                        //                 <Item>
                        //                     {
                        //                         processRunning ? `Process running (${processRunning})` : 'No process running'
                        //                     }
                        //                 </Item>
                        //             </Grid>
                        //         </Grid>
                        //     </Box>
                        //     <Box component="div">
                        //         <Grid container alignItems={'center'}>
                        //             <Grid>
                        //                 <Item>
                        //                     <img src={iconProcessingFailed} alt="icon" />
                        //                 </Item>
                        //             </Grid>
                        //             <Grid sx={{ paddingTop: '3px' }}>
                        //                 <Item>
                        //                     {`Invoice processing failed (${failedInvoiceProcessing})`}
                        //                 </Item>
                        //             </Grid>
                        //         </Grid>
                        //     </Box>
                        //     <Box component="div">
                        //         <Grid container alignItems={'center'}>
                        //             <Grid>
                        //                 <Item>
                        //                     <img src={iconProcessingFailed} alt="icon" />
                        //                 </Item>
                        //             </Grid>
                        //             <Grid sx={{ paddingTop: '3px' }}>
                        //                 <Item>
                        //                     {`Invoice file import failed (${failedInvoiceImport})`}
                        //                 </Item>
                        //             </Grid>
                        //         </Grid>
                        //     </Box>
                        //     <Box component="div">
                        //         <Box component={'div'} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={(e) => window.location.reload()}>
                        //             <RefreshIcon color="primary"></RefreshIcon> <span style={{ marginLeft: '5px', color: '#B253AE' }}>Refresh</span>
                        //         </Box>
                        //     </Box>
                        // </>}
                        filterBtns={
                            <>
                                <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                <Button variant="contained" onClick={filterBtn}>Apply</Button>
                            </>}>

                    </TableFilters>
                </Box>
                {/* <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', alignItems: 'center', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -3 } }}>
                    <div className='table-actionable'>
                        <Box component={'div'} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={filterBtn}>
                            <RefreshIcon color="primary"></RefreshIcon> <span style={{ marginLeft: '5px', color: '#B253AE' }}>Reload Table</span>
                        </Box>
                    </div>
                    <div className='table-actionable'>
                        <Button variant="contained" color="tertiary" size='small' onClick={(e) => { onReprocessRetros(e) }}>
                            Reprocess Retros
                        </Button>
                    </div>
                    <div className='table-actionable'>
                        <Button variant="contained" size='small' onClick={(e) => { onReprocessInvoice(e) }}>
                            Reprocess
                        </Button>
                    </div>
                    <div className='table-actionable'>
                        <Button variant="contained" color="secondary" size='small' onClick={(e) => { onReprocessReverse(e) }}>
                            Reverse
                        </Button>
                    </div>
                    <div className='table-actionable'>
                        <Button variant="contained" size='small' sx={{
                            backgroundColor: "#888E97",
                            ":hover": {
                                backgroundColor: "#727579"
                            }
                        }} onClick={(e) => { onReprocessCredit(e) }}>
                            Credit and Reprocess
                        </Button>
                    </div>
                </Box> */}
                <DataTableProcessHistory defaultGrid={state.defaultGrid} columns={columns}></DataTableProcessHistory>
                {
                    showAddEditModal && (
                        <InvoiceDetails
                            tabs={tabs}
                            onShow={showAddEditModal}
                            onHide={(e, reason) => hideInvoiceSummaryModal(e, reason)}


                        />
                    )
                }
                {/* {
                    addProductwarning && (
                        <WarningModal size="small_overlay" onShow={addProductwarning} />
                    )
                } */}
                {/* {
                    showAddEditModal && (
                        <DetailInvoiceSummary
                            onShow={showAddEditModal}
                            onHide={(e, reason) => hideInvoiceSummaryModal(e, reason)}

                        />
                    )
                } */}
                {
                    addProductwarning && (
                        <WarningModal size="small_overlay" onShow={addProductwarning} />
                    )
                }
                {snackBar.show && <SnackBarDialog
                    onShow={snackBar.show}
                    text={snackBar.text}
                    type={snackBar.type}
                    onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                />}
                {
                    showNewPriceoneModal && (
                        <CommonPopUp
                            onShow={showNewPriceoneModal} size="medium_overlay"
                            supplierMemberCode={{
                                title: (state.handleSupplierMemberModal && state.handleSupplierMemberModal === 'members') ? 'Member Details' : 'Supplier Details',
                                type: state.handleSupplierMemberModal
                            }}
                            // memberaccount={loadMemberAccountWithMemberId}
                            onHide={(e, reason) => hidePriceModal(e, reason, state.handleSupplierMemberModal)}
                        />
                    )
                }
                {
                    state.handleSPC && <CommonPopUp
                        onShow={state.handleSPC}
                        size="large_overlay"
                        supplierMemberCode={{
                            title: 'SPC',
                            supplier: invRepSupplierName || null
                        }}
                        onHide={(e, reason) => hidePriceModal(e, reason, state.handleSupplierMemberModal)}
                    />
                }
                {
                    showNewProductModal && (
                        <CommonPopUp
                            onShow={showNewProductModal} size="medium_overlay"
                            onHide={(e, reason) => hidePriceModal(e, reason, 'product')}
                        />
                    )
                }

                {
                    state.brandListModal && <CommonPopUp
                        onShow={state.brandListModal}
                        size="large_overlay"
                        brandDetails={{
                            title: 'Brand Details'
                        }}
                        onHide={(e, reason) => hidePriceModal(e, reason, 'brand')}
                    />
                }
                {state.status_history_modal && (
                    <>
                        <InvoiceReprocesHistory
                            size="large_overlay"
                            onShow={state.status_history_modal}
                            onHide={() => setState({ ...state, status_history_modal: false, status_history_id: null })}
                            processId={state.status_history_id}
                        />
                    </>
                )}
            </Container>

        </>
    );
}

export default ServerProcessHistory;
