import { Container, Box, Modal, Button, Grid, Checkbox, Popper, Grow, Paper, ClickAwayListener, MenuList, ButtonGroup } from '@mui/material'
import IconButton from "@mui/material/IconButton";
import AddIcon from '@mui/icons-material/Add';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TableFilters } from "../../components/shared/data-table/TableFilters";
import { useEffect, useState } from "react";
import { InputText } from "../../components/shared/form/InputText";
import { } from '@mui/material';
import { ThreeStateCheckbox } from "../../components/shared/form/ThreeStateCheckbox";
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange, filterResetFlagChange } from "../../store/slices/externalFilter";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { setRowPage, resetDataGrid, setPageNo } from '../../store/slices/dataGrid'
import { useLayoutEffect } from "react";
import { setHasMemberEditData, memberInput, setAutocomplete, memberBasicInput, setMemberEdit, updateNextBtnStatus, resetMember } from '../../store/slices/memberBclValues';
import PageLoader from "../../components/shared/PageLoader";
import { DataTableMember } from "../../components/shared/data-table/DataTableMember";
import { Routes, Route, useNavigate } from 'react-router-dom';
import axiosMember from '../../services/utility/axios-member';
import moment from 'moment';
import SnackBarDialog from '../../components/shared/SnackBarDialog';
import { DataTableMemberDashboard } from '../../components/shared/data-table/DataTableMemberDashboard';



function Members() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { memberName, activeMember, addProductwarning, showAddEditModal, filter_data } = useSelector((state) => {
        return {
            memberName: state.externalFilter.member_name,
            activeMember: state.externalFilter.active_member,
            resetTable: state.dataGridTable,
            filterFlag: state.externalFilter.filterApplied,
            addProductwarning: state.modalActions.closeWaringAddProduct,
            showAddEditModal: state.modalActions.hideAddEditModal,
            filter_data: state.externalFilter
        }
    });

    const [state, setState] = useState({
        active_member: filter_data.filterList ? filter_data.filterList.active ? filter_data.filterList.active : null : null,
        openNewProductModal: false,
        pageLoader: false,
        location: "Member",
        locationlabel: "Member",
        defaultGrid: {
            pagetitle: "Members",
            productActionButton: true,
            hasExport: true,
            addEditButton: true,
            apiUrl: 'members/filter',
            searchUrl: 'members/filter'
        }
    });

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    });

    const columns = [
        {
            headerName: "Member Name",
            field: "name",
            flex: 1.5,
            minWidth: 200,
        },
        {
            headerName: "Member Account Master",
            field: "masterAccountName",
            flex: 1.5,
            minWidth: 200,
        },
        {
            headerName: "Member Definition",
            field: "masterAccountExist",
            flex: 1.5,
            minWidth: 200,
            valueGetter: (params) => params.row.masterAccountExist ? "" : "Pending",
        },
        {
            headerName: "Active",
            field: "active",
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
            headerAlign: "center",
            align: 'center',
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} />;
            },
        }
    ];



    const pageLoader = (loader) => {
        setState({ ...state, pageLoader: loader })
    }

    const IconMenuLink = ({ params }) => {
        const { row } = params;
        const [anchorEl, setAnchorEl] = useState(null);
        const open = Boolean(anchorEl);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = (event, action) => {
            event.preventDefault();
            if (action === 'edit_member') {
                // console.log(row)       
                setEditMemberData(row);
            }
            setAnchorEl(null);
        };
        const setEditMemberData = async (row) => {

            dispatch(setHasMemberEditData(row));

            dispatch(memberBasicInput({
                key: 'member_id',
                value: row.id
            }));
            dispatch(setMemberEdit({ editMembersTab: true, member_id: row.id }));
            dispatch(updateNextBtnStatus({ key: 'disable_maBtn', value: false }));

            try {

                setState({ ...state, pageLoader: true });
                const { data } = await axiosMember.get(`members/${row.id}`);

                if (data) {
                    dispatch(memberBasicInput({
                        key: 'member_name',
                        value: data.name
                    }));

                    dispatch(memberBasicInput({
                        key: 'member_active',
                        value: data.active
                    }));

                    dispatch(memberBasicInput({
                        key: 'member_cc',
                        value: data.collectCentrally
                    }));

                    dispatch(memberBasicInput({
                        key: 'member_prc',
                        value: data.payRetrosCentrally
                    }));

                    dispatch(memberBasicInput({
                        key: 'member_joindate',
                        value: data.joinDate ? moment(data.joinDate, 'DD/MM/YYYY') : null
                    }));

                    dispatch(memberBasicInput({
                        key: 'member_leavedate',
                        value: data.leaveDate ? moment(data.leaveDate, 'DD/MM/YYYY') : null
                    }));

                    dispatch(memberBasicInput({
                        key: 'member_ooid',
                        value: data.orwellOrganisationId
                    }));

                    dispatch(memberBasicInput({
                        key: 'member_oot',
                        value: data.orwellOrganisationType
                    }));

                    dispatch(setAutocomplete({
                        parent: 'member_estate_group',
                        key: 'estate_group_selected',
                        value: data.estateGroup
                    }))
                    dispatch(setAutocomplete({
                        parent: 'member_retroagg_group',
                        key: 'retroagg_group_selected',
                        value: data.retroAggregationGroup
                    }))
                    localStorage.setItem('memberfilterflag', true);

                    dispatch(filterFlagChange(true));
                    navigate('/suppliers-and-members/members/member-details');
                    setState({ ...state, pageLoader: false });
                }
                else {
                    setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" });
                    setState({ ...state, pageLoader: false });
                }
            }
            catch (error) {
                const { response: { data, status }, message } = error;
                if (status === 500 || status === 501 || status === 502 || status === 503 || status === 504 || status === 505 || status === 0) {
                    setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error" });
                } else {
                    setSnackBar({ ...snackBar, show: true, text: "System took long to respond, please retry!", type: "error", })
                }
                setState({ ...state, pageLoader: false });
            }

            // console.log(data);

        }
        return (<>
            <IconButton
                aria-label="more"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                id={row.id + 1}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id={row.id}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                className="actionMenuBx"
            >
                <MenuItem onClick={(e) => handleClose(e, 'edit_member')}>Edit</MenuItem>
            </Menu>
        </>)
    }






    // const handleChange = (checked, name) => {
    //     setState({ ...state, [name]: checked })
    //     dispatch(setFilter({
    //         key: name,
    //         value: checked
    //     }))
    // };

    const handleChange = (checked, name) => {
        // setState({ ...state, [name]: checked });
        state.active_member = checked;
        dispatch(setFilter({
            key: name,
            value: checked
        }));
        setTimeout(() => {
            filterBtn();
        }, 500);
    };

    const handleFilterInput = (value, id) => {
        //setState({ ...state, [id]: value });
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    };

    const filterBtn = () => {
        dispatch(resetDataGrid());
        dispatch(applyFilter({
            key: 'filterList',
            value: {
                "name": memberName,
                "active": state.active_member
                // "active": activeMember
            }
        }));
        localStorage.setItem('memberfilterflag', true);
        dispatch(filterFlagChange(true));
        dispatch(filterResetFlagChange(false));
        dispatch(setRowPage({ pageData: 10 }));
        dispatch(setPageNo({ page: 1 }));
        // console.log(filter_data.filterList);
    }

    const resetFilter = () => {
        if (memberName !== '' || (activeMember === false || activeMember)) {
            dispatch(resetExternalFilter())

            dispatch(resetDataGrid());
        }
        setState({ ...state, active_member: null })
        dispatch(filterResetFlagChange(true));
    }

    const goNextPage = () => {

        dispatch(resetMember());
        dispatch(filterFlagChange(true));
        navigate('/suppliers-and-members/members/member-details');

    }

    useEffect(() => {
        /* load first first time */
        if (localStorage.getItem("memberfilterflag") === "true") {
            localStorage.setItem('memberfilterflag', "false");
        } else {
            dispatch(resetExternalFilter());
            setState({ ...state, active_member: null });
        }

    }, [])


    return (
        <>
            {state.pageLoader && <PageLoader />}
            {snackBar.show &&
                <SnackBarDialog
                    onShow={snackBar.show}
                    text={snackBar.text}
                    type={snackBar.type}
                    onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                />
            }
            {
                <Container disableGutters={true} maxWidth={false}>
                    <BreadcrumbNavs pageTitle="Members" />
                    <Box sx={{ marginBottom: 2.5 }}>
                        <TableFilters
                            filterFields={<>
                                <Box component="div"><InputText onChange={handleFilterInput} maxWidth={100} label="Member Name" id="member_name" size="small" /></Box>

                            </>}
                            filterCheckboxes={<>
                                <Box component="div">
                                    <ThreeStateCheckbox checked={state.active_member} onChange={handleChange} name="active_member" id="active" label="Active" />
                                </Box>
                            </>}
                            filterBtns={
                                <>
                                    <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                    <Button variant="contained" onClick={filterBtn}>Apply</Button>
                                </>}>
                        </TableFilters>
                    </Box>
                    <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -3 } }}>
                        <div className='table-actionable'>
                            <Button variant="contained" size='small' onClick={(e) => goNextPage(e)} startIcon={<AddIcon />}>
                                New Member
                            </Button>
                        </div>
                    </Box>


                    <DataTableMemberDashboard defaultGrid={state.defaultGrid} columns={columns} location={state.location} locationLabel={state.locationlabel}></DataTableMemberDashboard>

                </Container>


            }



        </>
    );
}

export default Members;
