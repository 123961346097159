import { useEffect, useState } from "react";
import {
    Grid, Paper, Box, FormLabel, Button, Autocomplete,
    TextField, FormHelperText, Checkbox, FormControlLabel, FormControl, Alert, Snackbar, Modal
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';

import { warningModal, handleAddEditModal, handleRDSchemeModal } from "../../../../store/slices/modals";
import { useDispatch, useSelector } from 'react-redux';
import { hasTabEdit } from "../../../../store/slices/generalProduct";

import axios_utilities from "../../../../services/utility/axios-utilities";
import axiosRetroDiscountSchemes from "../../../../services/utility/axios-retro-discount-schemes";
import SnackBarDialog from "../../../../components/shared/SnackBarDialog";
import { autoCompleteSearch, retrodiscountschemesInput, setRetroDates, getIsError, hasTabEditData, setRetroFormStatus, setHasEditData } from "../../../../store/slices/retrodiscountschemes";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { filterFlagChange, filterResetFlagChange } from "../../../../store/slices/externalFilter";

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

const RetroGeneral = () => {

    const {
        handleWarning,
        retro_fields
    } = useSelector((state) => {
        return {
            handleWarning: state.modalActions.closeWaringAddProduct,
            retro_fields: state.retrodiscountscheme,
        }
    });


    const [state, setState] = useState(
        {
            loader: false,
            formValid: true,
            defaultmode: false,
            newProductData: [],
            retro_promotional_mechanic: retro_fields.hasEditData ? retro_fields.hasEditData.mechanicType || retro_fields.hasEditData.promotionTypeName : "",
            retro_promotional_period: retro_fields.hasEditData ? retro_fields.hasEditData.promotionPeriod : "",
            retro_promotional_year: retro_fields.hasEditData ? retro_fields.hasEditData.promotionYear : "",
            isError: {
                retro_promotional_mechanic: false,
                retro_promotional_period: false,
                retro_promotional_year: false
            }
        }
    );

    const [otherTabsOpen, setOtherTabsOpen] = useState(false);
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    useEffect(() => {
        const fetchMyAPI = async () => {
            if (retro_fields.general_vat_code.vat_code_options.length === 0) {
                axios_utilities.get('vatcodes/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            dispatch(autoCompleteSearch({
                                parent: 'general_vat_code',
                                key: 'vat_code_options',
                                value: unique
                            }));
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
            if (retro_fields.general_unit_measure.measure_options.length === 0) {
                axios_utilities.get('unitofmeasures/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            dispatch(autoCompleteSearch({
                                parent: 'general_unit_measure',
                                key: 'measure_options',
                                value: unique
                            }));
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
            if (retro_fields.centralBillingScheme.billingScheme_options.length === 0) {
                axios_utilities.get('centralBillingScheme/drop-down').then(function (response) {
                    // handle success
                    const { status, data } = response;
                    if (data) {
                        const unique = data.filter((value, index, self) => self.findIndex((m) => m.name === value.name) === index);
                        dispatch(autoCompleteSearch({
                            parent: 'centralBillingScheme',
                            key: 'billingScheme_options',
                            value: unique
                        }));
                    }
                })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
            if (retro_fields.promotionalType.promotionalType_options.length === 0) {
                axiosRetroDiscountSchemes.get('/retrodiscount/promotionTypes/drop-down').then(function (response) {
                    // handle success
                    const { status, data } = response;
                    if (data) {
                        const unique = data.filter((value, index, self) => self.findIndex((m) => m.name === value.name) === index);
                        dispatch(autoCompleteSearch({
                            parent: 'promotionalType',
                            key: 'promotionalType_options',
                            value: unique
                        }));
                    }
                })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }

        }
        fetchMyAPI();
    }, [])

    const dispatch = useDispatch();

    const handleInputChange = (event) => {
        setState({ ...state, defaultmode: true })
        const { value, name } = event.target;
        if (name === 'retro_interim_unit') {
            dispatch(retrodiscountschemesInput({
                key: name,
                value: value.slice(0, 16)
            }))
        } else {
            dispatch(retrodiscountschemesInput({
                key: name,
                value
            }))
        }

    }

    const handleCheckboxChange = (event) => {
        if (retro_fields.hasEditData) {
            setState({ ...state, defaultmode: true })
        }
        const { checked, name } = event.target;
        dispatch(retrodiscountschemesInput({
            key: name,
            value: checked
        }))
    };
    useEffect(() => {
        if (retro_fields.closeForInvoice === true) {
            dispatch(retrodiscountschemesInput({
                key: 'closeForBilling',
                value: true
            }))
        } else {
            dispatch(retrodiscountschemesInput({
                key: 'closeForBilling',
                value: false
            }))
        }
    }, [retro_fields.closeForInvoice]);
    const handleSelection = (event, newValue, parentname, name) => {
        if (retro_fields.hasEditData) {
            setState({ ...state, defaultmode: true })
        }
        if (parentname === 'promotionalType') {
            // setState({ ...state, retro_promotional_mechanic: "", retro_promotional_period: "", retro_promotional_year: "" })
            setState(prevState => ({
                ...prevState,
                retro_promotional_mechanic: "",
                retro_promotional_period: "",
                retro_promotional_year: "",
                isError: {
                    ...prevState.isError,
                    retro_promotional_mechanic: true,
                    retro_promotional_period: true,
                    retro_promotional_year: true,
                }
            }));
        }
        if (event.target.value !== "" || event.target.value !== null) {
            dispatch(autoCompleteSearch({
                parent: parentname,
                key: name,
                value: newValue
            }))
            if (parentname === 'promotionalType') {
                // setState({ ...state, retro_promotional_mechanic: newValue ? newValue.label : '' });
                setState(prevState => ({
                    ...prevState,
                    retro_promotional_mechanic: newValue ? newValue.label : '',
                    isError: {
                        ...prevState.isError,
                        retro_promotional_mechanic: newValue ? false : true
                    }
                }));
            }
        }
    }

    const handleStartDate = (event) => {
        if (retro_fields.hasEditData) {
            setState({ ...state, defaultmode: true })
        }
        dispatch(setRetroDates({
            key: 'startDate',
            value: (event === null) ? null : moment(event, 'DD/MM/YYYY')
        }))
        dispatch(setRetroDates({
            key: 'endDate',
            value: null
        }))
        if (event === null) {
            dispatch(getIsError({ key: 'startDate', value: true }))
            dispatch(getIsError({ key: 'endDate', value: true }))
        }
    }

    const handleEndDate = (event) => {
        if (retro_fields.hasEditData) {
            setState({ ...state, defaultmode: true })
        }
        dispatch(setRetroDates({
            key: 'endDate',
            value: (event === null) ? null : moment(event, 'DD/MM/YYYY')
        }))
        if (event === null) {
            dispatch(getIsError({ key: 'endDate', value: true }))
        }
    }

    const handleAddProduct = (event) => {
        dispatch(warningModal({
            yesHide: !handleWarning
        }))
    }

    useEffect(() => {
        const inputNumber = document.querySelectorAll('.inputNumber');
        inputNumber.forEach(el => el.addEventListener('keypress', evt => {
            if (evt.which === 8) {
                return;
            }
            if (evt.which < 42 || evt.which > 60) {
                evt.preventDefault();
            }
        }));
    }, []);



    // useEffect(() => {
    //     if (retro_fields.retro_name === "" || retro_fields.retro_name === null) {
    //         dispatch(getIsError({ key: 'retro_name', value: true }))
    //     } else {
    //         dispatch(getIsError({ key: 'retro_name', value: false }))
    //     }
    //     if (retro_fields.startDate === null || retro_fields.startDate === '') {
    //         dispatch(getIsError({ key: 'startDate', value: true }))
    //     } else {
    //         dispatch(getIsError({ key: 'startDate', value: false }))
    //     }
    //     if (retro_fields.endDate === null || retro_fields.endDate === '') {
    //         dispatch(getIsError({ key: 'endDate', value: true }))
    //     } else {
    //         dispatch(getIsError({ key: 'endDate', value: false }))
    //     }
    //     if (retro_fields.general_vat_code.vat_code_selected === null || retro_fields.general_vat_code.vat_code_selected === '') {
    //         dispatch(getIsError({ key: 'vat_code_selected', value: true }))
    //     } else {
    //         dispatch(getIsError({ key: 'vat_code_selected', value: false }))
    //     }
    //     if (retro_fields.general_unit_measure.measure_selected === null || retro_fields.general_unit_measure.measure_selected === '') {
    //         dispatch(getIsError({ key: 'measure_selected', value: true }))
    //     } else {
    //         dispatch(getIsError({ key: 'measure_selected', value: false }))
    //     }
    //     if (retro_fields.retro_interim_unit === "" || retro_fields.retro_interim_unit === '') {
    //         dispatch(getIsError({ key: 'retro_interim_unit', value: true }))
    //     } else {
    //         dispatch(getIsError({ key: 'retro_interim_unit', value: false }))
    //     }
    //     if (retro_fields.retro_final_unit === "" || retro_fields.retro_final_unit === '') {
    //         dispatch(getIsError({ key: 'retro_final_unit', value: true }))
    //     } else {
    //         dispatch(getIsError({ key: 'retro_final_unit', value: false }))
    //     }
    //     if (retro_fields.centralBillingScheme.billingScheme_selected === null || retro_fields.centralBillingScheme.billingScheme_selected === '') {
    //         dispatch(getIsError({ key: 'billingScheme_selected', value: true }))
    //     } else {
    //         dispatch(getIsError({ key: 'billingScheme_selected', value: false }))
    //     }
    // }, [retro_fields])

    useEffect(() => {
        if ((retro_fields.retro_name === "" || retro_fields.retro_name === null) || (retro_fields.startDate === null || retro_fields.startDate === '' || retro_fields.startDate === 'Invalid Date')
            || (retro_fields.endDate === null || retro_fields.endDate === '' || retro_fields.endDate === 'Invalid Date')
            || (retro_fields.general_vat_code.vat_code_selected === null || retro_fields.general_vat_code.vat_code_selected === '')
            || (retro_fields.general_unit_measure.measure_selected === null || retro_fields.general_unit_measure.measure_selected === '')
            || (retro_fields.retro_interim_unit === "" || retro_fields.retro_interim_unit === '')
            || (retro_fields.retro_final_unit === "" || retro_fields.retro_final_unit === '')
            || (retro_fields.centralBillingScheme.billingScheme_selected === null || retro_fields.centralBillingScheme.billingScheme_selected === '')
            || (retro_fields.promotionalType.promotionalType_selected === null || retro_fields.promotionalType.promotionalType_selected === '')
            || (state.retro_promotional_mechanic === "" || state.retro_promotional_mechanic === null || state.retro_promotional_mechanic === undefined)
            || (state.retro_promotional_period === "" || state.retro_promotional_period === null || state.retro_promotional_period === undefined)
            || (state.retro_promotional_year === "" || state.retro_promotional_year === null || state.retro_promotional_period === undefined)
        ) {
            setState({ ...state, formValid: true, defaultmode: false })
        } else {
            setState({ ...state, formValid: false, defaultmode: true })
        }
        // eslint-disable-next-line max-len
    }, [retro_fields.retro_name, retro_fields.startDate, retro_fields.endDate, retro_fields.general_vat_code.vat_code_selected, retro_fields.general_unit_measure.measure_selected, retro_fields.retro_interim_unit, retro_fields.retro_final_unit, retro_fields.centralBillingScheme.billingScheme_selected, retro_fields.promotionalType.promotionalType_selected, state.retro_promotional_mechanic, state.retro_promotional_period, state.retro_promotional_year])

    useEffect(() => {
        const { retro_name,
            vat_code_selected,
            measure_selected,
            startDate,
            endDate,
            retro_interim_unit,
            retro_final_unit,
            billingScheme_selected,
            promotionalType_selected
        } = retro_fields.isError
        if ((retro_fields.retro_name !== "" && retro_fields.retro_name !== null && !retro_name)
            && (retro_fields.general_vat_code.vat_code_selected !== null && retro_fields.general_vat_code.vat_code_selected !== '' && !vat_code_selected)
            && (retro_fields.general_unit_measure.measure_selected !== null && retro_fields.general_unit_measure.measure_selected !== '' && !measure_selected)
            && (retro_fields.startDate !== null && retro_fields.startDate !== '' && retro_fields.startDate !== 'Invalid Date' && !startDate)
            && (retro_fields.endDate !== null && retro_fields.endDate !== '' && retro_fields.endDate !== 'Invalid Date' && !endDate)
            && (retro_fields.retro_interim_unit !== "" && retro_fields.retro_interim_unit !== '' && !retro_interim_unit)
            && (retro_fields.retro_final_unit !== "" && retro_fields.retro_final_unit !== '' && !retro_final_unit)
            && (retro_fields.centralBillingScheme.billingScheme_selected !== null && retro_fields.centralBillingScheme.billingScheme_selected !== '' && !billingScheme_selected)
            && (retro_fields.promotionalType.promotionalType_selected !== null && retro_fields.promotionalType.promotionalType_selected !== '' && !promotionalType_selected)
            && (state.retro_promotional_mechanic !== "" && state.retro_promotional_mechanic !== null && state.retro_promotional_mechanic !== undefined && !state.isError.retro_promotional_mechanic)
            && (state.retro_promotional_year !== "" && state.retro_promotional_year !== null && state.retro_promotional_year !== undefined && !state.isError.retro_promotional_year)
            && (state.retro_promotional_period !== "" && state.retro_promotional_period !== null && state.retro_promotional_period !== undefined && !state.isError.retro_promotional_period)) {
            setState({ ...state, formValid: false, defaultmode: true })
        } else {
            setState({ ...state, formValid: true, defaultmode: false })
        }
    }, [retro_fields.isError, state.isError])

    // useEffect(() => {
    //     const { retro_promotional_mechanic,
    //         retro_promotional_period,
    //         retro_promotional_year,
    //     } = state.isError
    //     if ((state.retro_promotional_mechanic !== "" && state.retro_promotional_mechanic !== null &&  state.retro_promotional_mechanic !== undefined && !retro_promotional_mechanic) 
    //         && (state.retro_promotional_period !== "" && state.retro_promotional_period !== null && state.retro_promotional_period !== undefined && !retro_promotional_period) 
    //         && (state.retro_promotional_year !== "" && state.retro_promotional_year !== null && state.retro_promotional_year !== undefined && !retro_promotional_year)) {
    //         setState({ ...state, formValid: false, defaultmode: true })
    //     } else {
    //         setState({ ...state, formValid: true, defaultmode: false })
    //     }
    // }, [state.isError])

    const handleSubmit = async (event) => {
        event.preventDefault();
        let retroData = null;
        if (!state.formValid) {
            if (moment(retro_fields.startDate).format('DD/MM/YYYY') === 'Invalid date' || (retro_fields.endDate !== "" && retro_fields.endDate !== null && moment(retro_fields.endDate).format('DD/MM/YYYY') === 'Invalid date')) {
                setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
            }
            else if (retro_fields.endDate !== null && retro_fields.endDate !== "" && (retro_fields.startDate > retro_fields.endDate)) {
                setSnackBar({ ...snackBar, show: true, text: "End date is less than Start Date!", type: "error", });
            }
            else if (state.retro_promotional_mechanic === "" || state.retro_promotional_mechanic === null || state.retro_promotional_mechanic === undefined) {
                setState(prevState => ({
                    ...prevState,
                    isError: {
                        ...prevState.isError,
                        retro_promotional_mechanic: true
                    }
                }));
            }
            else if (state.retro_promotional_period === "" || state.retro_promotional_period === null || state.retro_promotional_period === undefined) {
                setState(prevState => ({
                    ...prevState,
                    isError: {
                        ...prevState.isError,
                        retro_promotional_period: true
                    }
                }));
            }
            else if (state.retro_promotional_year === "" || state.retro_promotional_year === null || state.retro_promotional_year === undefined) {
                setState(prevState => ({
                    ...prevState,
                    isError: {
                        ...prevState.isError,
                        retro_promotional_year: true
                    }
                }));
            }
            else {
                retroData = {
                    "name": retro_fields.retro_name,
                    "endDate": retro_fields.endDate ? moment(retro_fields.endDate).format('DD/MM/YYYY') : null,
                    "vatCode": retro_fields.general_vat_code.vat_code_selected,
                    "unitOfMeasure": retro_fields.general_unit_measure.measure_selected,
                    "detailedReport": retro_fields.generateDetailedReport,
                    "interimValuePerUnit": Number(retro_fields.retro_interim_unit).toFixed(2),
                    "finalValuePerUnit": Number(retro_fields.retro_final_unit).toFixed(2),
                    "applyAggregationGroups": retro_fields.aggregationGroups,
                    "closedForInvoices": retro_fields.closeForInvoice,
                    "closedForBilling": retro_fields.closeForBilling,
                    "startDate": retro_fields.startDate ? moment(retro_fields.startDate).format('DD/MM/YYYY') : null,
                    "centralBillingScheme": retro_fields.centralBillingScheme.billingScheme_selected,
                    "promotionTypeId": retro_fields.promotionalType.promotionalType_selected ? retro_fields.promotionalType.promotionalType_selected.id : "",
                    "mechanicType": state.retro_promotional_mechanic,
                    "promotionYear": state.retro_promotional_year,
                    "promotionPeriod": state.retro_promotional_period

                }

                try {
                    setState({ ...state, loader: true })
                    const { data, status } = retro_fields.hasEditData ? await axiosRetroDiscountSchemes.put(`retrodiscount/${retro_fields.hasEditData.id}/updateretrodiscountscheme`, { ...retroData, id: retro_fields.hasEditData.id }) : await axiosRetroDiscountSchemes.post(`retrodiscount/addretrodiscountscheme`, { ...retroData });
                    if (data.success === true && data.resultMap) {
                        setSnackBar({ ...snackBar, show: true, text: data.message, type: "success", })
                        retro_fields.hasEditData ? setState({ ...state, loader: false }) : setState({ ...state, loader: false, formValid: true, newProductData: data.resultMap.retroDiscount })
                        dispatch(setRetroFormStatus(true))
                        dispatch(hasTabEdit({ editTab: false }))
                        dispatch(hasTabEditData({ editData: data.resultMap.retroDiscount }))
                        dispatch(setHasEditData(data.resultMap.retroDiscount))
                        //retro_fields.hasEditData ? "" :  setOtherTabsOpen(true)
                        // if (!retro_fields.hasEditData) {
                        //     setOtherTabsOpen(true)
                        // } else {
                        //     setTimeout(() => {
                        //         window.location.reload();
                        //     }, 1500);
                        // }
                        // setTimeout(() => {
                        //     dispatch(handleAddEditModal({
                        //         showAddEdit: true
                        //     }));
                        // }, 1500);  
                        setTimeout(() => {
                            // window.location.reload();
                            dispatch(handleRDSchemeModal({
                                addEditRDSchemeModal: true
                            }));
                            //dispatch(filterFlagChange(true));
                            //dispatch(filterResetFlagChange(false));
                        }, 1500);
                    }
                } catch (error) {
                    const { response: { data, status } } = error;
                    retro_fields.hasEditData ? "" : dispatch(hasTabEdit({ editTab: true }))
                    setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error", })
                    setState({ ...state, loader: false })
                }
            }
        }
    };
    const handleOtherTabsOpen = () => {
        dispatch(hasTabEdit({ editTab: false }))
        dispatch(hasTabEditData({ editData: state.newProductData }))
        dispatch(setHasEditData(state.newProductData))
        setOtherTabsOpen(false)
    }
    const handleOtherTabsClose = () => {
        setOtherTabsOpen(false)
        setTimeout(() => {
            dispatch(handleAddEditModal({
                showAddEdit: false,
            }))
            // window.location.reload();
            dispatch(handleRDSchemeModal({
                addEditRDSchemeModal: false
            }));
            dispatch(filterFlagChange(true));
            dispatch(filterResetFlagChange(false));
        }, 750);
    }

    const handleDecimal = (event) => {
        const valueDecimal = event.target.value === '' ? "" : (event.target.value == 0 ? 0 : Number(event.target.value).toFixed(2))

        dispatch(retrodiscountschemesInput({
            key: event.target.name,
            value: valueDecimal
        }))
    }

    const promotionalInputChange = (event) => {
        const { value, name } = event.target;
        if (value === '' || value === null) {
            setState(prevState => ({
                ...prevState,
                [name]: value,
                isError: {
                    ...prevState.isError,
                    [name]: true
                }
            }));
        }
        else {
            // setState({ ...state, [name]: value })
            setState(prevState => ({
                ...prevState,
                [name]: value,
                isError: {
                    ...prevState.isError,
                    [name]: false
                }
            }));
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <Box sx={{ flexGrow: 1, paddingX: 2 }} >
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                        <Item>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={4.5} className="formLabelCntr">
                                    <Item>
                                        <FormLabel htmlFor="retro_name" className="fontSemiBold mandatory">Name</FormLabel>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={7.5}>
                                    <Item>
                                        <div className="input-group">
                                            <FormControl fullWidth>
                                                <TextField
                                                    inputProps={{ maxLength: 200 }}
                                                    size="small"
                                                    id="retro_name"
                                                    aria-describedby="retro_name_error"
                                                    onChange={(e) => handleInputChange(e)}
                                                    value={retro_fields['retro_name']}
                                                    error={retro_fields.isError.retro_name}
                                                    name="retro_name"
                                                />
                                                {retro_fields.isError.retro_name && (<FormHelperText className='errorHelperTxt' id="retro_name_error">
                                                    Please enter name
                                                </FormHelperText>)}
                                            </FormControl>

                                        </div>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>

                </Grid>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                        <Item>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={4.5} className="formLabelCntr">
                                    <Item>
                                        <FormLabel htmlFor="startDate" className="fontSemiBold mandatory">Start Date</FormLabel>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={7.5}>
                                    <Item>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DesktopDatePicker
                                                value={retro_fields.startDate}
                                                onChange={(e) => { handleStartDate(e) }}
                                                name="startDate"
                                                inputFormat="DD/MM/YYYY"
                                                // eslint-disable-next-line max-len
                                                renderInput={({ inputRef, inputProps, InputProps }) => (
                                                    <Box component="div" className='date-picker-input'>
                                                        <FormControl size={'small'} fullWidth>
                                                            <TextField size="small" id="startDate"
                                                                ref={inputRef} {...inputProps}
                                                                placeholder=""
                                                                aria-describedby="startDate_error"
                                                                error={retro_fields.isError.startDate}
                                                                autoComplete="off"
                                                            />
                                                            {retro_fields.isError.startDate && (<FormHelperText className='errorHelperTxt' id="price_start_date_error">Start date is missing</FormHelperText>)}
                                                            <Box className='date-picker-icon'>
                                                                {InputProps?.endAdornment}
                                                            </Box>
                                                        </FormControl>
                                                    </Box>
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                        <Item>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={4.5} className="formLabelCntr">
                                    <Item>
                                        <FormLabel htmlFor="endDate" className="fontSemiBold mandatory">End Date</FormLabel>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={7.5}>
                                    <Item>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DesktopDatePicker
                                                defaultCalendarMonth={retro_fields.startDate ? moment(retro_fields.startDate, "DD/MM/YYYY").add(1, 'days') : null}
                                                value={retro_fields.endDate}
                                                disabled={(retro_fields.startDate === null || retro_fields.startDate === '') ? true : false}
                                                onChange={(e) => { handleEndDate(e) }}
                                                name="endDate"
                                                inputFormat="DD/MM/YYYY"
                                                minDate={moment(retro_fields.startDate, 'DD/MM/YYYY').add(1, 'days')}
                                                // eslint-disable-next-line max-len
                                                renderInput={({ inputRef, inputProps, InputProps }) => (
                                                    <Box component="div" className='date-picker-input'>
                                                        <FormControl size={'small'} fullWidth>
                                                            <TextField size="small" id="endDate"
                                                                ref={inputRef} {...inputProps}
                                                                placeholder=""
                                                                aria-describedby="endDate_error"
                                                                autoComplete="off"
                                                                error={retro_fields.isError.endDate}
                                                            />
                                                            {retro_fields.isError.endDate && (<FormHelperText className='errorHelperTxt' id="endDate_error">End date is missing</FormHelperText>)}
                                                            <Box className='date-picker-icon'>
                                                                {InputProps?.endAdornment}
                                                            </Box>
                                                        </FormControl>
                                                    </Box>
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                </Grid>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                        <Item>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={4.5} className="formLabelCntr">
                                    <Item>
                                        <FormLabel htmlFor="general_vat_code" className="fontSemiBold mandatory">Vat Code</FormLabel>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={7.5}>
                                    <Item>
                                        <Autocomplete
                                            defaultValue={retro_fields.general_vat_code.vat_code_selected || (retro_fields.hasEditData ? retro_fields.hasEditData.vatCode : null)}
                                            id="general_vat_code_search"
                                            options={retro_fields.general_vat_code.vat_code_options}
                                            getOptionLabel={(option) => option.code || ""}
                                            componentsProps={{
                                                paper: {
                                                    sx: {
                                                        fontSize: '0.85rem'
                                                    }
                                                }
                                            }}
                                            onChange={(event, newValue) => handleSelection(event, newValue, 'general_vat_code', 'vat_code_selected')}
                                            renderInput={(params) =>
                                                <FormControl size={'small'} fullWidth>
                                                    <div className="input-group">
                                                        <TextField {...params}
                                                            size="small"
                                                            id="general_vat_code"
                                                            aria-describedby="general_vat_code-error"
                                                            error={retro_fields.isError.vat_code_selected}
                                                        />
                                                        {retro_fields.isError.vat_code_selected &&
                                                            (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)}
                                                    </div>
                                                </FormControl>
                                            }

                                        />
                                    </Item>

                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                        <Item>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={4.5} className="formLabelCntr">
                                    <Item>
                                        <FormLabel htmlFor="general_unit_measure" className="fontSemiBold mandatory">Unit of Measure</FormLabel>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={7.5}>
                                    <Item>
                                        <Autocomplete
                                            defaultValue={retro_fields.general_unit_measure.measure_selected || (retro_fields.hasEditData ? retro_fields.hasEditData.unitOfMeasure : null)}
                                            id="general_unit_measure_search"
                                            options={retro_fields.general_unit_measure.measure_options}
                                            getOptionLabel={(option) => option.name || ""}
                                            componentsProps={{
                                                paper: {
                                                    sx: {
                                                        fontSize: '0.85rem'
                                                    }
                                                }
                                            }}
                                            onChange={(event, newValue) => handleSelection(event, newValue, 'general_unit_measure', 'measure_selected')}
                                            renderInput={(params) =>
                                                <FormControl size={'small'} fullWidth>
                                                    <div className="input-group">
                                                        <TextField {...params}
                                                            size="small"
                                                            id="general_unit_measure"
                                                            aria-describedby="general_unit_measure-error"
                                                            error={retro_fields.isError.measure_selected}
                                                        />
                                                        {retro_fields.isError.measure_selected &&
                                                            (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)}
                                                    </div>
                                                </FormControl>
                                            }
                                        />
                                    </Item>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                </Grid>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                        <Item>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={4.5} className="formLabelCntr">
                                    <Item>
                                        <FormLabel htmlFor="retro_interim_unit" className="fontSemiBold mandatory">Interim Value Per Unit</FormLabel>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={7.5}>
                                    <Item>
                                        <FormControl fullWidth>
                                            <TextField
                                                inputProps={{ maxLength: 50 }}
                                                size="small"
                                                id="retro_interim_unit"
                                                aria-describedby="retro_interim_unit_error"
                                                onChange={(e) => handleInputChange(e)}
                                                value={retro_fields.retro_interim_unit}
                                                name="retro_interim_unit"
                                                type={'number'}
                                                className="inputNumber"
                                                error={retro_fields.isError.retro_interim_unit}
                                                step="any"
                                                onBlur={(e) => handleDecimal(e)}
                                                onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                            />
                                            {retro_fields.isError.retro_interim_unit && (<FormHelperText className='errorHelperTxt' id="retro_name_error">
                                                Please enter interim value per unit
                                            </FormHelperText>)}
                                        </FormControl>
                                    </Item>

                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                        <Item>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={4.5} className="formLabelCntr">
                                    <Item>
                                        <FormLabel htmlFor="retro_final_unit" className="fontSemiBold mandatory">Final Value Per Unit</FormLabel>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={7.5}>
                                    <Item>
                                        <FormControl fullWidth>
                                            <TextField
                                                inputProps={{ maxLength: 50 }}
                                                type="number"
                                                size="small"
                                                id="retro_final_unit"
                                                aria-describedby="retro_final_unit_error"
                                                onChange={(e) => handleInputChange(e)}
                                                value={retro_fields.retro_final_unit}
                                                name="retro_final_unit"
                                                className="inputNumber"
                                                error={retro_fields.isError.retro_final_unit}
                                                step="any"
                                                onBlur={(e) => handleDecimal(e)}
                                                onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                            />
                                            {retro_fields.isError.retro_final_unit && (<FormHelperText className='errorHelperTxt' id="retro_name_error">
                                                Please enter final value per unit
                                            </FormHelperText>)}
                                        </FormControl>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                </Grid>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                        <Item>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={4.5} className="formLabelCntr">
                                    <Item>
                                        <FormLabel htmlFor="centralBillingScheme" className="fontSemiBold mandatory">Central Billing Scheme</FormLabel>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={7.5}>
                                    <Item>
                                        <Autocomplete
                                            defaultValue={retro_fields.centralBillingScheme.billingScheme_selected || (retro_fields.hasEditData ? retro_fields.hasEditData.centralBillingScheme : null)}
                                            id="centralBillingScheme_search"
                                            options={retro_fields.centralBillingScheme.billingScheme_options}
                                            getOptionLabel={(option) => option.name || ""}
                                            componentsProps={{
                                                paper: {
                                                    sx: {
                                                        fontSize: '0.85rem'
                                                    }
                                                }
                                            }}
                                            onChange={(event, newValue) => handleSelection(event, newValue, 'centralBillingScheme', 'billingScheme_selected')}
                                            renderInput={(params) =>
                                                <FormControl size={'small'} fullWidth>
                                                    <div className="input-group">
                                                        <TextField {...params}
                                                            size="small"
                                                            id="centralBillingScheme"
                                                            aria-describedby="centralBillingScheme-error"
                                                            error={retro_fields.isError.billingScheme_selected}
                                                        />
                                                        {retro_fields.isError.billingScheme_selected &&
                                                            (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)}
                                                    </div>
                                                </FormControl>
                                            }

                                        />
                                    </Item>

                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                        <Item>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={4.5} className="formLabelCntr">
                                    <Item>
                                        <FormLabel htmlFor="promotionalType" className="fontSemiBold mandatory">Promotional Type</FormLabel>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={7.5}>
                                    <Item>
                                        <Autocomplete
                                            defaultValue={retro_fields.promotionalType.promotionalType_selected || (retro_fields.hasEditData ? retro_fields.hasEditData.promotionalType : null)}
                                            id="promotionalType_search"
                                            options={retro_fields.promotionalType.promotionalType_options}
                                            getOptionLabel={(option) => option.name || ""}
                                            componentsProps={{
                                                paper: {
                                                    sx: {
                                                        fontSize: '0.85rem'
                                                    }
                                                }
                                            }}
                                            onChange={(event, newValue) => handleSelection(event, newValue, 'promotionalType', 'promotionalType_selected')}
                                            renderInput={(params) =>
                                                <FormControl size={'small'} fullWidth>
                                                    <div className="input-group">
                                                        <TextField {...params}
                                                            size="small"
                                                            id="promotionalType"
                                                            aria-describedby="promotionalType_error"
                                                            error={retro_fields.isError.promotionalType_selected}
                                                        />
                                                        {retro_fields.isError.promotionalType_selected && (<FormHelperText className='errorHelperTxt' id="promotionalType_error">Promotional type is missing</FormHelperText>)}

                                                    </div>
                                                </FormControl>
                                            }
                                        />
                                    </Item>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                </Grid>
                {
                    // retro_fields.promotionalType.promotionalType_selected ? (<></>) : ""
                }
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                        <Item>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={4.5} className="formLabelCntr">
                                    <Item>
                                        <FormLabel htmlFor="promotionalType" className="fontSemiBold mandatory">Mechanic Type</FormLabel>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={7.5}>
                                    <Item>
                                        <div className="input-group">
                                            <FormControl fullWidth>
                                                <TextField
                                                    inputProps={{ maxLength: 200 }}
                                                    size="small"
                                                    id="retro_promotional_mechanic"
                                                    aria-describedby="retro_promotional_mechanic_error"
                                                    onChange={(e) => promotionalInputChange(e)}
                                                    value={state.retro_promotional_mechanic}
                                                    name="retro_promotional_mechanic"
                                                    error={state.isError.retro_promotional_mechanic}
                                                />
                                                {state.isError.retro_promotional_mechanic && (<FormHelperText className='errorHelperTxt' id="retro_promotional_mechanic_error">Mechanic Type is missing</FormHelperText>)}
                                            </FormControl>
                                        </div>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                        <Item>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={4.5} className="formLabelCntr">
                                    <Item>
                                        <FormLabel htmlFor="promotionalType" className="fontSemiBold mandatory">Promotional Year ( e.g. 2022-23)</FormLabel>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={7.5}>
                                    <Item>
                                        <div className="input-group">
                                            <FormControl fullWidth>
                                                <TextField
                                                    inputProps={{ maxLength: 200 }}
                                                    size="small"
                                                    id="retro_promotional_year"
                                                    aria-describedby="retro_promotional_year_error"
                                                    onChange={(e) => promotionalInputChange(e)}
                                                    value={state.retro_promotional_year}
                                                    name="retro_promotional_year"
                                                    error={state.isError.retro_promotional_year} />
                                                {state.isError.retro_promotional_year && (<FormHelperText className='errorHelperTxt' id="retro_promotional_year_error">Promotional Year is missing</FormHelperText>)}
                                            </FormControl>
                                        </div>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                </Grid><Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginBottom: 2.5 }}>
                        <Item>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={4.5} className="formLabelCntr">
                                    <Item>
                                        <FormLabel htmlFor="promotionalType" className="fontSemiBold mandatory">Promotional Period</FormLabel>
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={7.5}>
                                    <Item>
                                        <div className="input-group">
                                            <FormControl fullWidth>
                                                <TextField
                                                    inputProps={{ maxLength: 200 }}
                                                    size="small"
                                                    id="retro_promotional_period"
                                                    aria-describedby="retro_promotional_period_error"
                                                    onChange={(e) => promotionalInputChange(e)}
                                                    value={state.retro_promotional_period}
                                                    name="retro_promotional_period"
                                                    type={'number'}
                                                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                                    error={state.isError.retro_promotional_period}
                                                />
                                                {state.isError.retro_promotional_period && (<FormHelperText className='errorHelperTxt' id="retro_promotional_period_error">Promotional Period is missing</FormHelperText>)}
                                            </FormControl>
                                        </div>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                </Grid>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Item>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={2.25} sx={{ textAlign: 'right', display: { xs: 'none', md: 'block' } }}>
                                    <Item>
                                        &nbsp;
                                    </Item>
                                </Grid>
                                <Grid item xs={12} sm={12} md={9.5}>
                                    <Item>
                                        <Grid container direction="row" alignItems="center" >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox size="medium"
                                                        id="closeForInvoice"
                                                        checked={retro_fields.closeForInvoice}
                                                        onChange={(e) => handleCheckboxChange(e)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        name="closeForInvoice"
                                                    />}
                                                label="Close for Invoices" />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox size="medium"
                                                        id="closeForBilling"
                                                        checked={retro_fields.closeForBilling}
                                                        onChange={(e) => handleCheckboxChange(e)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        name="closeForBilling"
                                                        disabled
                                                    />}
                                                label="Close for Billing" />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox size="medium"
                                                        id="generateDetailedReport"
                                                        checked={retro_fields.generateDetailedReport}
                                                        onChange={(e) => handleCheckboxChange(e)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        name="generateDetailedReport"
                                                    />}
                                                label="Generate detailed report" />
                                            <FormControlLabel
                                                control={<Checkbox size="medium"
                                                    id="aggregationGroups"
                                                    checked={retro_fields.aggregationGroups}
                                                    onChange={(e) => handleCheckboxChange(e)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    name="aggregationGroups"
                                                />}
                                                label="Apply Aggregation Groups" />
                                        </Grid>

                                    </Item>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                </Grid>
                <Grid container sx={{ marginTop: '20px' }}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Item>
                            <Grid container direction="row" alignItems="center" justifyContent="center">
                                <LoadingButton
                                    loading={state.loader}
                                    loadingPosition="center"
                                    endIcon=""
                                    variant="contained"
                                    type="submit" sx={{ marginRight: '15px' }}
                                    //disabled={state.formValid || state.defaultmode === false}
                                    // eslint-disable-next-line max-len
                                    disabled={retro_fields.hasEditData ? (retro_fields.isError.retro_name || retro_fields.isError.startDate || retro_fields.isError.endDate || retro_fields.isError.vat_code_selected || retro_fields.isError.measure_selected || retro_fields.isError.retro_interim_unit || retro_fields.isError.retro_final_unit || retro_fields.isError.billingScheme_selected || retro_fields.isError.promotionalType_selected || state.isError.retro_promotional_mechanic || state.isError.retro_promotional_period || state.isError.retro_promotional_year) : (state.formValid || state.defaultmode === false)}
                                //className={`${retro_fields.productFormValid ? 'Mui-disabled' : ''}`}
                                >Save General Details</LoadingButton>
                                <Button variant="outlined" onClick={handleAddProduct}>Cancel</Button>
                            </Grid>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
            {snackBar.show && <SnackBarDialog
                onShow={snackBar.show}
                text={snackBar.text}
                type={snackBar.type}
                onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
            />}
            <Modal
                open={otherTabsOpen}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleOtherTabsClose();
                    }
                }}
            >
                <Box component="div" className="overlay" id="addEditModal">
                    <div className={`overlay-box small_overlay`}>
                        <div className="content">
                            <div className="overlayBody">
                                <div className="overlay-content">
                                    <Box component="div" sx={{ textAlign: 'center', padding: 1.5 }}>
                                        <p className="fontMedium">Would you like to add other details ?</p>
                                        <Grid sx={{ paddingTop: 2 }} container direction="row" alignItems="center" justifyContent="center">
                                            <Button variant="contained" type="submit" sx={{ marginRight: '15px' }} onClick={handleOtherTabsOpen}>Yes</Button>
                                            <Button variant="outlined" onClick={handleOtherTabsClose}>No</Button>
                                        </Grid>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </form>
    );
};
export default RetroGeneral;