import { Box, Modal, Tabs, Tab, Button, Grid, TextField, FormControl, FormHelperText, FormLabel, IconButton, FormControlLabel, Paper, Checkbox, Autocomplete } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CancelIcon from '@mui/icons-material/Cancel';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { warningModal } from "../../../store/slices/modals";
import SnackBarDialog from "../../../components/shared/SnackBarDialog";
import { styled } from '@mui/material/styles';
import { categoryInput, setAutocomplete, setcategoryLevel, setcategoryParent, resetCategory, categorBasicInput } from '../../../store/slices/categoriesData';
import axios from '../../../services/utility/axios';
import { handleAddEditModal } from '../../../store/slices/modals';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { setMemberTierDate, memberTierInput, getIsError } from '../../../store/slices/memberTierData';
import axiosMember from '../../../services/utility/axios-member';
import { filterFlagChange, filterResetFlagChange } from '../../../store/slices/externalFilter';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

export default function AddEditMemberTier({ values, onShow, onHide }) {

    const { handleWarning, membertier_fields } = useSelector((state) => {
        return {
            handleWarning: state.modalActions.closeWaringAddProduct,
            membertier_fields: state.memberTierData
        }
    });

    const [state, setState] = useState({
        start_date: '',
        loader: false,
        openNewProductModal: false,
        pageLoader: false,
        formValid: true,
        defaultmode: false,
        editNoBlankAllow: false
    });

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    });

    const [isError, isSetError] = useState({
        bopaError: false,
    })

    const dispatch = useDispatch();

    const handleInputChange = (event) => {
        setState({ ...state, defaultmode: true })
        const { value, name } = event.target;
        dispatch(memberTierInput({
            key: name,
            value
        }))

    }

    const handleClose = () => {
        // dispatch(resetCategory());
        //setState({ ...state, defaultmode: false })
        dispatch(handleAddEditModal({
            showAddEdit: false
        }));
    };

    const handleCancel = (event) => {
        // dispatch(resetCategory());
        dispatch(warningModal({
            yesHide: !handleWarning
        }))
    }


    useEffect(() => {
        const { tier_name,
            tier_description,
            tier_startDate
        } = membertier_fields.isError
        if (!tier_name && !tier_description &&
            !tier_startDate) {
            setState({ ...state, formValid: false, defaultmode: false })
        } else {
            setState({ ...state, formValid: true, defaultmode: true })
        }
    }, [membertier_fields.isError]);

    useEffect(() => {
        //console.log("change fields");
        const { tier_name,
            tier_description,
            tier_startDate
        } = membertier_fields;
        // console.log("change fields", tier_name, tier_description, tier_startDate);
        // (tier_name === "" && membertier_fields.isError.tier_name === false) && (tier_description === "" && membertier_fields.isError.tier_description === false) &&
        // ((tier_startDate === "" || tier_startDate === null) && membertier_fields.isError.tier_startDate === false)
        if (membertier_fields.editMemberTier !== true) {
            if (tier_name === "" || tier_description === "" || (tier_startDate === "" || tier_startDate === null || tier_startDate === "Invalid date")) {
                setState({ ...state, formValid: true, defaultmode: false })
            }
            else {
                setState({ ...state, formValid: false, defaultmode: true })
            }
        }

    }, [membertier_fields.tier_name, membertier_fields.tier_description, membertier_fields.tier_startDate]);

    useEffect(() => {
        if (moment(membertier_fields.tier_startDate).isBefore(moment(), "day")) {
            isSetError({ ...isError, bopaError: true })
        } else {
            isSetError({ ...isError, bopaError: false })
        }
    }, [membertier_fields.tier_startDate])



    const handleJoinDate = (event) => {
        setState({ ...state, defaultmode: true });
        if (event === null) {

            dispatch(setMemberTierDate({
                key: 'tier_startDate',
                value: null
            }));
            dispatch(getIsError({ key: 'tier_startDate', value: true }))
        }
        else {

            dispatch(setMemberTierDate({
                key: 'tier_startDate',
                value: moment(event, 'DD/MM/YYYY')
            }))
            dispatch(setMemberTierDate({
                key: 'tier_endDate',
                value: null
            }));
        }



    }

    const handleLeaveDate = (event) => {
        setState({ ...state, defaultmode: true })
        if (event === null) {
            dispatch(setMemberTierDate({
                key: 'tier_endDate',
                value: null
            }));
            setState({ ...state, editNoBlankAllow: true })
        }
        else {
            dispatch(setMemberTierDate({
                key: 'tier_endDate',
                value: moment(event, 'DD/MM/YYYY')
            }))
            setState({ ...state, editNoBlankAllow: false })
        }

    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (membertier_fields.tier_name === "" && membertier_fields.isError.tier_name === false) {
            dispatch(getIsError({ key: 'tier_name', value: true }));
        } else if (membertier_fields.tier_description === "" && membertier_fields.isError.tier_description === false) {
            dispatch(getIsError({ key: 'tier_description', value: true }));
        } else if ((membertier_fields.tier_startDate === "" || membertier_fields.tier_startDate === null || membertier_fields.tier_startDate === 'Invalid date') && membertier_fields.isError.tier_startDate === false) {
            dispatch(getIsError({ key: 'tier_startDate', value: true }));
        } else {
            if (state.editNoBlankAllow) {
                setSnackBar({ ...snackBar, show: true, text: "End date should not be blank", type: "error", });
            } else if(moment(membertier_fields.tier_startDate).format('DD/MM/YYYY') === 'Invalid date' || (membertier_fields.tier_endDate !== "" && membertier_fields.tier_endDate !== null && moment(membertier_fields.tier_endDate).format('DD/MM/YYYY') === 'Invalid date'))
            {
                setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
            }
            else if (membertier_fields.tier_endDate !== null && membertier_fields.tier_endDate !== "" && (membertier_fields.tier_startDate > membertier_fields.tier_endDate)) {
                setSnackBar({ ...snackBar, show: true, text: "End date is less than Start Date!", type: "error", });
            }
            else {
                let membertierData = null;
                if (!state.formValid) {
                    membertierData = {
                        "name": membertier_fields.tier_name,
                        "description": membertier_fields.tier_description,
                        "startDate": membertier_fields.tier_startDate ? moment(membertier_fields.tier_startDate).format('DD/MM/YYYY') : null,
                        "endDate": membertier_fields.tier_endDate ? moment(membertier_fields.tier_endDate).format('DD/MM/YYYY') : null
                    }
                    try {
                        setState({ ...state, loader: true });
                        const { data, status } = membertier_fields.editMemberTier ? await axiosMember.put(`memberTier/${membertier_fields.hasMemberTierEditData.id}`, { ...membertierData }) : await axiosMember.post(`memberTier`, { ...membertierData });
                        if (data.success === true) {
                            if (membertier_fields.editMemberTier) {
                                setState({ ...state, loader: false, formValid: false });
                                setSnackBar({ ...snackBar, show: true, text: `Purchasing Tier Edited Successfully!`, type: "success", });
                            }
                            else {
                                setState({ ...state, loader: false, formValid: true });
                                setSnackBar({ ...snackBar, show: true, text: `Purchasing Tier Added Successfully!`, type: "success", });
    
    
                            }
                            setTimeout(() => {
                                dispatch(warningModal({
                                    yesHide: false
                                }))
                                dispatch(handleAddEditModal({
                                    showAddEdit: false
                                }));
                                // window.location.reload();
                                dispatch(filterFlagChange(true));
                                dispatch(filterResetFlagChange(false));
                            }, 1500);
    
    
                        }
    
                    } catch (error) {
                        const { response: { data, status } } = error;
                        setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error", });
                        setState({ ...state, loader: false, formValid: false })
                    }
                }
            }

        }



    }

    return (
        <>
            <Modal
                open={onShow || false}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <Box component="div" className="overlay" id="addEditModal">
                    <div className="overlay-box medium-size" >
                        <div className="content">
                            <div className="overlayBody">
                                <div className="overlay-header text-center"> Purchasing Tier Details
                                    <CancelIcon className="closeBtn" onClick={handleClose} />
                                </div>
                                <div className="overlay-content">
                                    <form onSubmit={handleSubmit}>
                                        <Box sx={{ flexGrow: 1, paddingX: 0 }} >
                                            <Grid container columnSpacing={2} sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: 4 }}>
                                                    <Item sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                        <Grid container alignItems="center">
                                                            <Grid item xs={12} md={3} className="formLabelCntr">
                                                                <Item>
                                                                    <FormLabel htmlFor="tier_name" className="fontSemiBold mandatory">Tier Name</FormLabel>
                                                                </Item>
                                                            </Grid>
                                                            <Grid item xs={12} md={8}>
                                                                <Item>
                                                                    <div className="input-group">
                                                                        <FormControl fullWidth>
                                                                            <TextField
                                                                                inputProps={{ maxLength: 200 }}
                                                                                size="small"
                                                                                id="tier_name"
                                                                                aria-describedby="tier_name_error"
                                                                                onChange={(e) => handleInputChange(e)}
                                                                                value={membertier_fields.tier_name}
                                                                                error={membertier_fields.isError.tier_name}
                                                                                name="tier_name"

                                                                            />
                                                                            {membertier_fields.isError.tier_name && (<FormHelperText className='errorHelperTxt' id="category_name_error">
                                                                                Please enter Purchasing Tier Name
                                                                            </FormHelperText>)}
                                                                        </FormControl>

                                                                    </div>
                                                                </Item>
                                                            </Grid>
                                                        </Grid>
                                                    </Item>
                                                </Grid>


                                            </Grid>
                                            <Grid container columnSpacing={2} sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: 4 }}>
                                                    <Item sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                        <Grid container alignItems="center">
                                                            <Grid item xs={12} md={3} className="formLabelCntr">
                                                                <Item>
                                                                    <FormLabel htmlFor="tier_description" className="fontSemiBold mandatory">Description</FormLabel>
                                                                </Item>
                                                            </Grid>
                                                            <Grid item xs={12} md={8}>
                                                                <Item>
                                                                    <div className="input-group">
                                                                        <FormControl fullWidth>
                                                                            <TextField
                                                                                inputProps={{ maxLength: 200 }}
                                                                                size="small"
                                                                                id="tier_description"
                                                                                aria-describedby="tier_description_error"
                                                                                onChange={(e) => handleInputChange(e)}
                                                                                value={membertier_fields.tier_description}
                                                                                error={membertier_fields.isError.tier_description}
                                                                                name="tier_description"

                                                                            />
                                                                            {membertier_fields.isError.tier_description && (<FormHelperText className='errorHelperTxt' id="tier_description_error">
                                                                                Please enter Tier Name
                                                                            </FormHelperText>)}
                                                                        </FormControl>

                                                                    </div>
                                                                </Item>
                                                            </Grid>
                                                        </Grid>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                            <Grid container columnSpacing={2} sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: 4 }}>
                                                    <Item sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                        <Grid container alignItems="center">
                                                            <Grid item xs={12} md={3} className="formLabelCntr">
                                                                <Item>
                                                                    <FormLabel htmlFor="tier_startDate" className="fontSemiBold mandatory">Start Date</FormLabel>
                                                                </Item>
                                                            </Grid>
                                                            <Grid item xs={12} md={8}>
                                                                <Item>
                                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                        <DesktopDatePicker

                                                                            value={membertier_fields.tier_startDate}
                                                                            onChange={(e) => { handleJoinDate(e) }}
                                                                            disabled={membertier_fields.editMemberTier}
                                                                            name="tier_startDate"
                                                                            inputFormat="DD/MM/YYYY"
                                                                            // eslint-disable-next-line max-len
                                                                            renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                                <Box component="div" className='date-picker-input'>
                                                                                    <FormControl size={'small'} fullWidth>
                                                                                        <TextField size="small" id="tier_startDate"
                                                                                            ref={inputRef} {...inputProps}
                                                                                            placeholder=""
                                                                                            aria-describedby="tier_startDate_error"
                                                                                            error={membertier_fields.isError.tier_startDate}
                                                                                            autoComplete="off"
                                                                                        />
                                                                                        {membertier_fields.isError.tier_startDate && (<FormHelperText className='errorHelperTxt' id="tier_startDate_error">Start date is missing</FormHelperText>)}
                                                                                        <Box className='date-picker-icon'>
                                                                                            {InputProps?.endAdornment}
                                                                                        </Box>
                                                                                    </FormControl>

                                                                                </Box>
                                                                            )}
                                                                        />
                                                                    </LocalizationProvider>
                                                                </Item>
                                                            </Grid>
                                                        </Grid>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                            <Grid container columnSpacing={2} sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: 2 }}>
                                                    <Item sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                        <Grid container alignItems="center">
                                                            <Grid item xs={12} md={3} className="formLabelCntr">
                                                                <Item>
                                                                    <FormLabel htmlFor="tier_endDate" className="fontSemiBold">End Date</FormLabel>
                                                                </Item>
                                                            </Grid>
                                                            <Grid item xs={12} md={8}>
                                                                <Item>
                                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                        <DesktopDatePicker
                                                                            value={membertier_fields.tier_endDate}
                                                                            disabled={(membertier_fields.tier_startDate === null || membertier_fields.tier_startDate === '') ? true : false}
                                                                            onChange={(e) => { handleLeaveDate(e) }}
                                                                            name="tier_endDate"
                                                                            inputFormat="DD/MM/YYYY"
                                                                            minDate={moment(membertier_fields.tier_startDate, 'DD/MM/YYYY').add(1, 'days')}
                                                                            // eslint-disable-next-line max-len
                                                                            renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                                <Box component="div" className='date-picker-input'>
                                                                                    <FormControl size={'small'} fullWidth>
                                                                                        <TextField size="small" id="tier_endDate"
                                                                                            ref={inputRef} {...inputProps}
                                                                                            placeholder=""
                                                                                            aria-describedby="tier_endDate_error"
                                                                                            autoComplete="off"
                                                                                        />
                                                                                        <Box className='date-picker-icon'>
                                                                                            {InputProps?.endAdornment}
                                                                                        </Box>
                                                                                    </FormControl>
                                                                                </Box>
                                                                            )}
                                                                        />
                                                                    </LocalizationProvider>
                                                                </Item>
                                                            </Grid>
                                                        </Grid>
                                                    </Item>
                                                </Grid>
                                            </Grid>                                                                                        
                                            <Grid container sx={{ marginTop: '0px' }}>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <Item>
                                                        <Grid container alignItems="center">
                                                            <Grid item xs={12} md={2} className="formLabelCntr">
                                                                <Item>

                                                                </Item>
                                                            </Grid>
                                                            <Grid item xs={12} md={10} className="formLabelCntr">
                                                                <Item>
                                                                    {isError.bopaError && (<FormHelperText className='errorHelperTxt' id="bopa_date_error" sx={{ position: 'static !important' }}>The start date you have selected is in the past. If any invoices have been collected or paid then applicable BOPA, Levy or discounts may have to be adjusted manually.</FormHelperText>)}
                                                                    {/* <FormHelperText className='errorHelperTxt' id="bopa_date_error" sx={{ position: 'static !important' }}>The start date cannot be selected in the past. If any invoices have been collected or paid then applicable BOPA, Levy or discounts may have to be adjusted.</FormHelperText> */}
                                                                </Item>
                                                            </Grid>
                                                        </Grid>
                                                    </Item>
                                                </Grid>
                                            </Grid>




                                            <Grid container sx={{ marginTop: '20px' }}>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <Item>
                                                        <Grid container alignItems="center">
                                                            <Grid item xs={12} md={1} className="formLabelCntr">
                                                                <Item>

                                                                </Item>
                                                            </Grid>
                                                            <Grid item xs={12} md={11}>
                                                                <Item>
                                                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                                                        <LoadingButton
                                                                            loading={state.loader}
                                                                            loadingPosition="center"
                                                                            endIcon=""
                                                                            variant="contained"
                                                                            type="submit" sx={{ marginRight: '15px' }}
                                                                            //disabled={state.formValid || state.defaultmode === false}
                                                                            // eslint-disable-next-line max-len
                                                                            disabled={membertier_fields.editMemberTier ? (membertier_fields.isError.tier_name || membertier_fields.isError.tier_description || membertier_fields.isError.tier_startDate) : (state.formValid || state.defaultmode === false)}

                                                                        >{membertier_fields.editMemberTier ? 'Update' : 'Save'}</LoadingButton>
                                                                        <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
                                                                    </Grid>


                                                                </Item>
                                                            </Grid>
                                                        </Grid>

                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        {snackBar.show && <SnackBarDialog
                                            onShow={snackBar.show}
                                            text={snackBar.text}
                                            type={snackBar.type}
                                            onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                                        />}
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}
