import { Checkbox, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl, Typography } from '@mui/material'
import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { warningModal, handleAddEditModal } from "../../../store/slices/modals";
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import moment from "moment";
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import axiosRetroDiscountSchemes from '../../../services/utility/axios-retro-discount-schemes';
import axios_member from '../../../services/utility/axios-member';
import { setAutocomplete, brandSearch, setrdsoeEffectiveDate, rdsoeInput, getIsError, setrdsoeDateList, resetrdsoe } from "../../../store/slices/rdsoe";
import { setMemberCode } from '../../../store/slices/supplierMemberAccounts';
import SnackBarDialog from "../../../components/shared/SnackBarDialog";
import CommonPopUp from "../../products/addEditPdc/pdc-tabs/CommonPopUp";
import { filterFlagChange, filterResetFlagChange } from '../../../store/slices/externalFilter';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

function AddEditRetroDiscountSchemesOutletExclusionsDetail({ onShow, size = '', onHide }) {

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const [isError, isSetError] = useState({
        bopaError: false,
    })

    const [state, setState] = useState({
        loader: false,
        formValid: true,
        defaultmode: false,
        supplierMemberAccountsData: '',
        rdsoeMemberModal: false,
        newData: [],
    });
    const [latestEndDate, setLatestEndDate] = useState();
    const [loader, setLoader] = useState(false)
    const [selectedSupplier, setselectedSupplier] = useState(null);
    const [open, setOpen] = useState(onShow);

    const [mie_member, setMie_member] = useState("")
    const [mie_memberValue, setMie_memberValue] = useState("")
    
    const { handleWarning, rdsoe_fields, showNewPriceoneModal } = useSelector((state) => {
        return {
            handleWarning: state.modalActions.closeWaringAddProduct,
            rdsoe_fields: state.rdsoes,
            showNewPriceoneModal: state.modalActions.priceModal
        }
    });

    useEffect(() => {
        const fetchMyAPI = async () => {
            if (rdsoe_fields.rdsoe_brand_owner.rdsoe_brand_owner_options.length === 0) {
                axiosRetroDiscountSchemes.get('retrodiscount/brandOwner/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            dispatch(setAutocomplete({
                                parent: 'rdsoe_brand_owner',
                                key: 'rdsoe_brand_owner_options',
                                value: unique
                            }));
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
            if (rdsoe_fields.rdsoe_retro_discount.rdsoe_retro_discount_options.length === 0) {
                axiosRetroDiscountSchemes.get('retrodiscount/retroDiscountScheme/drop-down')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            dispatch(setAutocomplete({
                                parent: 'rdsoe_retro_discount',
                                key: 'rdsoe_retro_discount_options',
                                value: unique
                            }));
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
        }
        fetchMyAPI();
    }, [])

    useEffect(() => {
        if(rdsoe_fields.hasEditData) {
            setMie_member(rdsoe_fields.hasEditData.id ? {id: rdsoe_fields.hasEditData.id, name: rdsoe_fields.hasEditData.memberName} : "")
        }
        const fetchMyAPI = async () => {
            if (rdsoe_fields.rdsoe_member.member_code_options.length === 0) {
                axios_member.get('/members/look-up?pageSize=500')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data.items) {
                            const unique = data.items.filter((value, index, self) => self.findIndex((m) => m.name === value.name) === index);
                            dispatch(brandSearch({
                                parent: 'rdsoe_member',
                                key: 'member_code_options',
                                value: unique
                            }))
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
        }
        fetchMyAPI();
    }, [rdsoe_fields.hasEditData !== null])

    useEffect(() => {
        // console.log("1")
        const fetchMyAPI = async () => {
            if (rdsoe_fields.rdsoe_member.rdsoe_member_name) {
                axios_member.get(`members/${rdsoe_fields.rdsoe_member.rdsoe_member_name.id}/memberAccounts`)
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            dispatch(setAutocomplete({
                                parent: 'rdsoe_member_account',
                                key: 'rdsoe_member_account_options',
                                value: unique
                            }));
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
            if (rdsoe_fields.rdsoe_member_account.rdsoe_member_account_selected && rdsoe_fields.rdsoe_member.rdsoe_member_name) {
                axios_member.get(`members/${rdsoe_fields.rdsoe_member.rdsoe_member_name.id}/memberAccounts/${rdsoe_fields.rdsoe_member_account.rdsoe_member_account_selected.id}/memberOutlets`)
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            dispatch(setAutocomplete({
                                parent: 'rdsoe_member_outlet',
                                key: 'rdsoe_member_outlet_options',
                                value: unique
                            }));
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
            // console.log("1", state.formValid);
        }
        fetchMyAPI();
    }, [rdsoe_fields.rdsoe_member.rdsoe_member_name])

    const dispatch = useDispatch();

    const handleClose = () => {
        //setState({ ...state, defaultmode: false })
        if (onHide) {
            dispatch(setrdsoeEffectiveDate({
                key: 'rdsoe_start_date',
                value: ""
            }))
            dispatch(setrdsoeEffectiveDate({
                key: 'rdsoe_end_date',
                value: ""
            }))
            onHide(false)
        }
        setLatestEndDate('')
        dispatch(handleAddEditModal({
            showAddEdit: false
        }))
        dispatch(setMemberCode({
            key: 'smaMemberCodeOnModal',
            value: ""
        }))
        dispatch(resetrdsoe())
    };

    const handleCancel = () => {
        setState({ ...state, defaultmode: false })
        dispatch(warningModal({
            yesHide: !handleWarning
        }))
    }

    const handleStartDate = (event) => {
        if (event) {
            dispatch(setrdsoeEffectiveDate({
                key: 'rdsoe_start_date',
                value: moment(event, 'DD/MM/YYYY')
            }))
            dispatch(setrdsoeEffectiveDate({
                key: 'rdsoe_end_date',
                value: null
            }))
        } else {
            dispatch(setrdsoeEffectiveDate({
                key: 'rdsoe_start_date',
                value: null
            }))
            dispatch(getIsError({ key: 'rdsoe_start_date', value: true }))
        }
        setLatestEndDate((event === null) ? null : moment(event).add(1, 'days').format('DD/MM/YYYY'))
        if (rdsoe_fields.hasEditData) {
            setState({ ...state, defaultmode: true })
        }
    }
    const handleEndDate = (event) => {
        if (rdsoe_fields.hasEditData) {
            setState({ ...state, defaultmode: true })
        }
        dispatch(setrdsoeEffectiveDate({
            key: 'rdsoe_end_date',
            value: (event === null) ? null : moment(event, 'DD/MM/YYYY')
        }))
    }

    const handleCommonSelection = (event, newValue, parentname, name) => {
        if (event.target.value !== "" || event.target.value !== null) {
            dispatch(brandSearch({
                parent: parentname,
                key: name,
                value: newValue
            }))
        }
        if (rdsoe_fields.hasEditData) {
            setState({ ...state, defaultmode: true })
        }
    }

    const handleSelection = (event, newValue, parentname, name) => {
        if ((event.target.value !== "" || event.target.value !== null) && newValue !== null) {
            dispatch(brandSearch({
                parent: parentname,
                key: name,
                value: newValue
            }));
            if (rdsoe_fields.hasEditData) {
                setState({ ...state, defaultmode: true, supplierMemberAccountsData: newValue.id });
            } else {
                setState({ ...state, supplierMemberAccountsData: newValue.id });
            }

            if (parentname === 'rdsoe_member_account') {
                dispatch(brandSearch({
                    parent: 'rdsoe_member_outlet',
                    key: 'rdsoe_member_outlet_selected',
                    value: null
                }))
            }
        }
        else {
            if (parentname === 'rdsoe_member_account') {
                dispatch(brandSearch({
                    parent: 'rdsoe_member_outlet',
                    key: 'rdsoe_member_outlet_selected',
                    value: null
                }))
            }
            dispatch(brandSearch({
                parent: parentname,
                key: name,
                value: newValue
            }));
        }
    }

    const getDataMemberOutlet = () => {
        if (state.supplierMemberAccountsData !== '') {
            axios_member.get(`members/${rdsoe_fields.rdsoe_member.rdsoe_member_name.id}/memberAccounts/${rdsoe_fields.rdsoe_member_account.rdsoe_member_account_selected.id}/memberOutlets`)
                .then(function (response) {
                    // handle success
                    const { status, data } = response;
                    if (data) {
                        const unique = data
                        dispatch(setAutocomplete({
                            parent: 'rdsoe_member_outlet',
                            key: 'rdsoe_member_outlet_options',
                            value: unique
                        }));
                    }
                })
                .catch(function (error) {
                    // handle error
                    const { response: { data, status } } = error;
                    if (status === 500 && data.errorType === "Business") {
                        dispatch(setAutocomplete({
                            parent: 'rdsoe_member_outlet',
                            key: 'rdsoe_member_outlet_options',
                            value: []
                        }));
                    } else {
                        console.log(error)
                    }
                });
        }
    }

    useEffect(() => {
        if (state.supplierMemberAccountsData) {
            getDataMemberOutlet()
        }
    }, [state.supplierMemberAccountsData])

    useEffect(() => {
        if (moment(rdsoe_fields.rdsoe_start_date).isBefore(moment(), "day")) {
            isSetError({ ...isError, bopaError: true })
        } else {
            isSetError({ ...isError, bopaError: false })
        }
    }, [rdsoe_fields.rdsoe_start_date])

    useEffect(() => {
        if ((rdsoe_fields.rdsoe_retro_discount.rdsoe_retro_discount_selected === null || rdsoe_fields.rdsoe_retro_discount.rdsoe_retro_discount_selected === "") || (rdsoe_fields.rdsoe_brand_owner.rdsoe_brand_owner_selected === null || rdsoe_fields.rdsoe_brand_owner.rdsoe_brand_owner_selected === "") || (rdsoe_fields.rdsoe_member.rdsoe_member_name === null || rdsoe_fields.rdsoe_member.rdsoe_member_name === "") || (rdsoe_fields.rdsoe_member_account.rdsoe_member_account_selected === null || rdsoe_fields.rdsoe_member_account.rdsoe_member_account_selected === "") || (rdsoe_fields.rdsoe_member_outlet.rdsoe_member_outlet_selected === null || rdsoe_fields.rdsoe_member_outlet.rdsoe_member_outlet_selected === "") || (rdsoe_fields.rdsoe_start_date === null || rdsoe_fields.rdsoe_start_date === 'Invalid date' || rdsoe_fields.rdsoe_start_date === "")) {
            setState({ ...state, formValid: true, defaultmode: false })
        } else {
            setState({ ...state, formValid: false, defaultmode: true })
        }
        // eslint-disable-next-line max-len
    }, [rdsoe_fields.rdsoe_retro_discount.rdsoe_retro_discount_selected, rdsoe_fields.rdsoe_brand_owner.rdsoe_brand_owner_selected, rdsoe_fields.rdsoe_member.rdsoe_member_name, rdsoe_fields.rdsoe_member_account.rdsoe_member_account_selected, rdsoe_fields.rdsoe_member_outlet.rdsoe_member_outlet_selected, rdsoe_fields.rdsoe_start_date])

    useEffect(() => {
        const {
            rdsoe_retro_discount_selected,
            rdsoe_brand_owner_selected,
            rdsoe_member_name,
            rdsoe_member_account_selected,
            rdsoe_member_outlet_selected,
            rdsoe_start_date
        } = rdsoe_fields.isError
        if (!rdsoe_retro_discount_selected && !rdsoe_brand_owner_selected && !rdsoe_member_name && !rdsoe_start_date && !rdsoe_member_account_selected && !rdsoe_member_outlet_selected) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true })
        }
    }, [rdsoe_fields.isError])

    // useEffect(() => {
    //     const {
    //         rdsoe_start_date
    //     } = rdsoe_fields.isError

    //     if (rdsoe_fields.rdsoe_end_date !== "" && rdsoe_fields.rdsoe_end_date !== "Invalid date") {
    //         setState({ ...state, formValid: false })
    //     } else if (rdsoe_fields.rdsoe_end_date === "" || rdsoe_fields.rdsoe_end_date === "Invalid date") {
    //         setState({ ...state, formValid: true })
    //     }
    // }, [rdsoe_fields.rdsoe_end_date])

    const handleSubmit = async (event) => {
        event.preventDefault();
        let rdsoeData = null;
        if (!state.formValid) {
            if (moment(rdsoe_fields.rdsoe_start_date).format('DD/MM/YYYY') === 'Invalid date' || (rdsoe_fields.rdsoe_end_date !== "" && rdsoe_fields.rdsoe_end_date !== null && moment(rdsoe_fields.rdsoe_end_date).format('DD/MM/YYYY') === 'Invalid date')) {
                setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
            }
            else if (rdsoe_fields.rdsoe_end_date !== null && rdsoe_fields.rdsoe_end_date !== "" && (rdsoe_fields.rdsoe_start_date > rdsoe_fields.rdsoe_end_date)) {
                setSnackBar({ ...snackBar, show: true, text: "End date is less than Start Date!", type: "error", });
            }
            else {
                rdsoeData = {
                    "id": rdsoe_fields.hasEditData ? rdsoe_fields.hasEditData.outletId : null,
                    "retroDiscountScheme": {
                        "id": rdsoe_fields.rdsoe_retro_discount.rdsoe_retro_discount_selected.id
                    },
                    "brandOwner": {
                        "id": rdsoe_fields.rdsoe_brand_owner.rdsoe_brand_owner_selected.id
                    },
                    "memberOutlet": {
                        "id": rdsoe_fields.rdsoe_member_outlet.rdsoe_member_outlet_selected.id
                    },
                    "startDate": rdsoe_fields.rdsoe_start_date ? moment(rdsoe_fields.rdsoe_start_date).format('DD/MM/YYYY') : null,
                    "endDate": rdsoe_fields.rdsoe_end_date ? moment(rdsoe_fields.rdsoe_end_date).format('DD/MM/YYYY') : null
                }
                try {
                    // setLoader(true)
                    setState({ ...state, loader: true })
                    const { data, status } = rdsoe_fields.hasEditData ? await axiosRetroDiscountSchemes.put(`retrodiscount/outlet-exclusion/${rdsoe_fields.hasEditData.id}/updateRetroDiscountOutlet`, { ...rdsoeData }) : await axiosRetroDiscountSchemes.post(`retrodiscount/outlet-exclusion/addRetroDiscountOutlet`, { ...rdsoeData });
                    if (data.success === true && data.resultMap) {
                        // setLoader(false)
                        dispatch(setrdsoeDateList(data.resultMap.result))
                        setSnackBar({ ...snackBar, show: true, text: `Retro Discount Schemes Outlet Exclusions ${rdsoe_fields.hasEditData ? 'Edited' : 'Added'} Successfully!`, type: "success", })
                        setState({ ...state, loader: false, formValid: true, newData: data.resultMap })
                        setTimeout(() => {
                            //handleClose()
                            // window.location.reload();
                            dispatch(handleAddEditModal({
                                showAddEdit: false
                            }))
                            dispatch(filterFlagChange(true));
                            dispatch(filterResetFlagChange(false));
                        }, 1500);
                    }
                } catch (error) {
                    // setLoader(false)
                    const { response: { data, status } } = error;
                    setState({ ...state, loader: false })
                    setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error", })
                }
            }
        }
    };

    const CommonPopUpModal = () => {

        setState({ ...state, rdsoeMemberModal: true })
    }

    const hidePriceModal = (event) => {
        setState({ ...state, rdsoeMemberModal: event })
    }

    const resetFilter = () => {
        dispatch(rdsoeInput({
            key: 'rdsoe_member_name',
            value: ""
        }))
        dispatch(brandSearch({
            parent: 'rdsoe_member_account',
            key: 'rdsoe_member_account_selected',
            value: null
        }))
        dispatch(brandSearch({
            parent: 'rdsoe_member_outlet',
            key: 'rdsoe_member_outlet_selected',
            value: null
        }))
    }

    const loadMemberAccountWithMemberId = (e) => {
        if (e !== '') {
            dispatch(brandSearch({
                parent: 'rdsoe_member_account',
                key: 'rdsoe_member_account_selected',
                value: null
            }));
            dispatch(brandSearch({
                parent: 'rdsoe_member_outlet',
                key: 'rdsoe_member_outlet_selected',
                value: null
            }));
            dispatch(rdsoeInput({
                key: 'rdsoe_member_name',
                value: e
            }));
            axios_member.get(`members/${e.id}/memberAccounts`)
                .then(function (response) {
                    // handle success
                    const { status, data } = response;
                    if (data) {
                        const unique = data
                        dispatch(setAutocomplete({
                            parent: 'rdsoe_member_account',
                            key: 'rdsoe_member_account_options',
                            value: unique
                        }));
                    }
                })
                .catch(function (error) {
                    // handle error
                    console.log(error)
                });
        }
        if (rdsoe_fields.hasEditData) {
            setState({ ...state, defaultmode: true, rdsoeMemberModal: false })
        } else {
            setState({ ...state, rdsoeMemberModal: false })
        }
    }

    return (
        <>
            {snackBar.show && <SnackBarDialog
                onShow={snackBar.show}
                text={snackBar.text}
                type={snackBar.type}
                onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
            />}

            <Modal
                open={open}
                aria-labelledby="add-edit-price-title"
                aria-describedby="add-edit-price-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Box component="div" className="overlay" id="addEditModal">
                        <div className={`overlay-box ${size ? size : ''}`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-header text-center"> Retro Discount Schemes Outlet Exclusions Details
                                        <CancelIcon className="closeBtn" onClick={handleClose} />
                                    </div>
                                    <div className="overlay-content">
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="rdsoe_retro_discount" className="fontSemiBold mandatory">Retro Discount Scheme</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Box component="div" className=''>
                                                                <Autocomplete
                                                                    // eslint-disable-next-line max-len
                                                                    defaultValue={rdsoe_fields.rdsoe_retro_discount.rdsoe_retro_discount_selected || (rdsoe_fields.hasEditData ? rdsoe_fields.hasEditData.retroDiscountScheme : null)}
                                                                    id="rdsoe_retro_discount_search"
                                                                    options={rdsoe_fields.rdsoe_retro_discount.rdsoe_retro_discount_options}
                                                                    getOptionLabel={(option) => option.name || ""}
                                                                    componentsProps={{
                                                                        paper: {
                                                                            sx: {
                                                                                fontSize: '0.85rem'
                                                                            }
                                                                        }
                                                                    }}
                                                                    onChange={(event, newValue) => handleCommonSelection(event, newValue, 'rdsoe_retro_discount', 'rdsoe_retro_discount_selected')}
                                                                    renderInput={(params) =>
                                                                        <FormControl size={'small'} fullWidth>
                                                                            <Box component="div" className=''>
                                                                                <TextField {...params}
                                                                                    size="small"
                                                                                    id="rdsoe_retro_discount"
                                                                                    aria-describedby="rdsoe_retro_discount-error"
                                                                                    error={rdsoe_fields.isError.rdsoe_retro_discount_selected}

                                                                                />
                                                                                {rdsoe_fields.isError.rdsoe_retro_discount_selected &&
                                                                                    (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)}
                                                                            </Box>
                                                                        </FormControl>
                                                                    }
                                                                />
                                                            </Box>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="rdsoe_brand_owner" className="fontSemiBold mandatory">Brand Owner</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Box component="div" className=''>
                                                                <Autocomplete
                                                                    // eslint-disable-next-line max-len
                                                                    defaultValue={rdsoe_fields.rdsoe_brand_owner.rdsoe_brand_owner_selected || (rdsoe_fields.hasEditData ? rdsoe_fields.hasEditData.brandOwner : null)}
                                                                    id="rdsoe_brand_owner_search"
                                                                    options={rdsoe_fields.rdsoe_brand_owner.rdsoe_brand_owner_options}
                                                                    getOptionLabel={(option) => option.name || ""}
                                                                    componentsProps={{
                                                                        paper: {
                                                                            sx: {
                                                                                fontSize: '0.85rem'
                                                                            }
                                                                        }
                                                                    }}
                                                                    onChange={(event, newValue) => handleCommonSelection(event, newValue, 'rdsoe_brand_owner', 'rdsoe_brand_owner_selected')}
                                                                    renderInput={(params) =>
                                                                        <FormControl size={'small'} fullWidth>
                                                                            <Box component="div" className=''>
                                                                                <TextField {...params}
                                                                                    size="small"
                                                                                    id="rdsoe_brand_owner"
                                                                                    aria-describedby="rdsoe_brand_owner-error"
                                                                                    error={rdsoe_fields.isError.rdsoe_brand_owner_selected}
                                                                                />
                                                                                {rdsoe_fields.isError.rdsoe_brand_owner_selected &&
                                                                                    (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)}
                                                                            </Box>
                                                                        </FormControl>
                                                                    }
                                                                />
                                                            </Box>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="rdsoe_member_name" className="fontSemiBold mandatory">Member</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            {/* <Box component="div" className=''>
                                                                <FormControl size={'small'} fullWidth>
                                                                    <TextField
                                                                        inputProps={{ maxLength: 100 }}
                                                                        size="small"
                                                                        id="rdsoe_member_name"
                                                                        value={rdsoe_fields.rdsoe_member_name ? rdsoe_fields.rdsoe_member_name.name : ''}
                                                                        aria-describedby="rdsoe_member_name_error"
                                                                        error={rdsoe_fields.isError.rdsoe_member_name}
                                                                        name="rdsoe_member_name"
                                                                        disabled={true}
                                                                    />
                                                                    {rdsoe_fields.isError.rdsoe_member_name &&
                                                                        (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)}
                                                                </FormControl>
                                                            </Box> */}
                                                            <Autocomplete
                                                                    //disabled={rdsoe_fields.hasEditData && rdsoe_fields.hasEditData.id ? true : false }
                                                                    value={mie_member || null}
                                                                    getOptionLabel={(option) => option.name || ""}
                                                                    onChange={(_, newValue) => {
                                                                        setMie_member(newValue ? newValue : "")
                                                                        dispatch(brandSearch({
                                                                            parent: 'rdsoe_member',
                                                                            key: 'rdsoe_member_name',
                                                                            value: newValue
                                                                        }))
                                                                        dispatch(brandSearch({
                                                                            parent: 'rdsoe_member_account',
                                                                            key: 'rdsoe_member_account_selected',
                                                                            value: null
                                                                        }))
                                                                        dispatch(brandSearch({
                                                                            parent: 'rdsoe_member_outlet',
                                                                            key: 'rdsoe_member_outlet_selected',
                                                                            value: null
                                                                        }))
                                                                    }}
                                                                    inputValue={mie_memberValue}
                                                                    componentsProps={{
                                                                        paper: {
                                                                            sx: {
                                                                                fontSize: '0.85rem'
                                                                            }
                                                                        }
                                                                    }}
                                                                    onInputChange={(_, newInputValue) => {
                                                                        setMie_memberValue(newInputValue ? newInputValue : "")
                                                                        dispatch(brandSearch({
                                                                            parent: 'rdsoe_member',
                                                                            key: 'rdsoe_member_name',
                                                                            value: newInputValue
                                                                        }))
                                                                        // dispatch(brandSearch({
                                                                        //     parent: 'rdsoe_member_account',
                                                                        //     key: 'rdsoe_member_account_selected',
                                                                        //     value: null
                                                                        // }))
                                                                        // dispatch(brandSearch({
                                                                        //     parent: 'rdsoe_member_outlet',
                                                                        //     key: 'rdsoe_member_outlet_selected',
                                                                        //     value: null
                                                                        // }))
                                                                    }}
                                                                    id="mie_member_search"
                                                                    options={rdsoe_fields.rdsoe_member.member_code_options}
                                                                    renderInput={(params) =>
                                                                        <FormControl size={'small'} fullWidth>
                                                                            <Box component="div" >
                                                                                <TextField {...params}
                                                                                    size="small"
                                                                                    id="mie_member"
                                                                                    aria-describedby="mie_member-error"
                                                                                    error={rdsoe_fields.isError.rdsoe_member_name}
                                                                                />
                                                                                {rdsoe_fields.isError.rdsoe_member_name &&
                                                                                    (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)}
                                                                            </Box>
                                                                        </FormControl>
                                                                    }
                                                                />
                                                        </Item>
                                                    </Grid>
                                                    {/* <Grid item xs={12} md={0.5}>
                                                        <Item>
                                                            <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0, marginRight: '5px' }} onClick={resetFilter} startIcon={<ClearIcon />}></Button>
                                                            <Button variant="text" size='small' className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={CommonPopUpModal} startIcon={<EditIcon />}></Button>
                                                        </Item>
                                                    </Grid> */}
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="rdsoe_member_account" className="fontSemiBold mandatory">Member account</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Box component="div" className=''>
                                                                <Autocomplete
                                                                    // eslint-disable-next-line max-len
                                                                    value={rdsoe_fields.rdsoe_member_account.rdsoe_member_account_selected ? rdsoe_fields.rdsoe_member_account.rdsoe_member_account_selected : null}
                                                                    //defaultValue={rdsoe_fields.rdsoe_member_account.rdsoe_member_account_selected || (rdsoe_fields.hasEditData ? rdsoe_fields.hasEditData.vatCode : null)}
                                                                    id="rdsoe_member_account_search"
                                                                    disabled={!rdsoe_fields.rdsoe_member.rdsoe_member_name ? true : false}
                                                                    options={rdsoe_fields.rdsoe_member_account.rdsoe_member_account_options}
                                                                    getOptionLabel={(option) => option.name || ""}
                                                                    componentsProps={{
                                                                        paper: {
                                                                            sx: {
                                                                                fontSize: '0.85rem'
                                                                            }
                                                                        }
                                                                    }}
                                                                    onChange={(event, newValue) => handleSelection(event, newValue, 'rdsoe_member_account', 'rdsoe_member_account_selected')}
                                                                    renderInput={(params) =>
                                                                        <FormControl size={'small'} fullWidth>
                                                                            <Box component="div" className=''>
                                                                                <TextField {...params}
                                                                                    size="small"
                                                                                    id="rdsoe_member_account"
                                                                                    aria-describedby="rdsoe_member_account-error"
                                                                                    error={rdsoe_fields.isError.rdsoe_member_account_selected}
                                                                                />
                                                                                {rdsoe_fields.isError.rdsoe_member_account_selected &&
                                                                                    (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)}
                                                                            </Box>
                                                                        </FormControl>
                                                                    }
                                                                />
                                                            </Box>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="rdsoe_member_outlet" className="fontSemiBold mandatory">Member outlet</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Box component="div" className=''>
                                                                <Autocomplete
                                                                    // eslint-disable-next-line max-len
                                                                    //defaultValue={rdsoe_fields.rdsoe_member_outlet.rdsoe_member_outlet_selected || (rdsoe_fields.hasEditData ? rdsoe_fields.hasEditData.memberOutletDTO : null)}
                                                                    value={rdsoe_fields.rdsoe_member_outlet.rdsoe_member_outlet_selected ? rdsoe_fields.rdsoe_member_outlet.rdsoe_member_outlet_selected : null}
                                                                    id="rdsoe_member_outlet_search"
                                                                    options={rdsoe_fields.rdsoe_member_outlet.rdsoe_member_outlet_options}
                                                                    // getOptionLabel={(option) => option.name || ""}
                                                                    getOptionLabel={(option) => (
                                                                        option.active === true ? option.name + " (Active)" : option.name + " (InActive)"
                                                                    ) || ""}
                                                                    renderOption={(props, option) => (
                                                                        <Box sx={{
                                                                            display: 'flex',
                                                                            justifyContent: 'space-between',
                                                                            width: '100%',
                                                                            position: 'relative'
                                                                        }} {...props}>
                                                                            <Box sx={{ paddingRight: '10px' }}>{option.name}</Box>
                                                                            <Box sx={{ position: 'absolute', right: '10px', top: '8px', fontSize: '11px' }} className={option.active === true ? "active-member-outlet" : "inactive-member-outlet"}>({option.active === true ? "Active" : "InActive"})</Box>
                                                                        </Box>)}
                                                                    componentsProps={{
                                                                        paper: {
                                                                            sx: {
                                                                                fontSize: '0.85rem'
                                                                            }
                                                                        }
                                                                    }}
                                                                    disabled={!rdsoe_fields.rdsoe_member_account.rdsoe_member_account_selected ? true : false}
                                                                    onChange={(event, newValue) => handleSelection(event, newValue, 'rdsoe_member_outlet', 'rdsoe_member_outlet_selected')}
                                                                    renderInput={(params) =>
                                                                        <FormControl size={'small'} fullWidth>
                                                                            <Box component="div" className=''>
                                                                                <TextField {...params}
                                                                                    size="small"
                                                                                    id="rdsoe_member_outlet"
                                                                                    aria-describedby="rdsoe_member_outlet-error"
                                                                                    error={rdsoe_fields.isError.rdsoe_member_outlet_selected}
                                                                                />
                                                                                {rdsoe_fields.isError.rdsoe_member_outlet_selected &&
                                                                                    (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)}
                                                                            </Box>
                                                                        </FormControl>
                                                                    }
                                                                />
                                                            </Box>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            {/* <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="rdsoe_member_outlet" className="fontSemiBold mandatory">Member Outlet</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                        <Box component="div" className=''>
                                                            <Autocomplete
                                                                // eslint-disable-next-line max-len
                                                                defaultValue={rdsoe_fields.rdsoe_member_outlet.rdsoe_member_outlet_selected || (rdsoe_fields.hasEditData ? rdsoe_fields.hasEditData.vatCode : null)}
                                                                id="rdsoe_member_outlet_search"
                                                                options={rdsoe_fields.rdsoe_member_outlet.rdsoe_member_outlet_options}
                                                                getOptionLabel={(option) => option.name || ""}
                                                                componentsProps={{
                                                                    paper: {
                                                                        sx: {
                                                                            fontSize: '0.85rem'
                                                                        }
                                                                    }
                                                                }}
                                                                onChange={(event, newValue) => handleSelection(event, newValue, 'rdsoe_member_outlet', 'rdsoe_member_outlet_selected')}
                                                                renderInput={(params) =>
                                                                    <FormControl size={'small'} fullWidth>
                                                                        <Box component="div" className=''>
                                                                            <TextField {...params}
                                                                                size="small"
                                                                                id="rdsoe_member_outlet"
                                                                                aria-describedby="rdsoe_member_outlet-error"
                                                                                error={rdsoe_fields.isError.rdsoe_member_outlet_search}
                                                                            />
                                                                            {rdsoe_fields.isError.rdsoe_member_outlet_search &&
                                                                                (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)}
                                                                        </Box>
                                                                    </FormControl>
                                                                }
                                                            />
                                                            </Box>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item> */}
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="price_start_date" className="fontSemiBold mandatory">Start Date</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                <DesktopDatePicker
                                                                    value={rdsoe_fields.rdsoe_start_date}
                                                                    disabled={rdsoe_fields.hasEditData !== null ? true : false}
                                                                    onChange={(e) => { handleStartDate(e) }}
                                                                    name="price_start_date"
                                                                    inputFormat="DD/MM/YYYY"
                                                                    // eslint-disable-next-line max-len
                                                                    renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                        <Box component="div" className='date-picker-input'>
                                                                            <FormControl size={'small'} fullWidth>
                                                                                <TextField size="small" id="price_start_date"
                                                                                    ref={inputRef} {...inputProps}
                                                                                    placeholder=""
                                                                                    aria-describedby="price_start_date_error"
                                                                                    error={rdsoe_fields.isError.rdsoe_start_date}
                                                                                    autoComplete="off"
                                                                                />
                                                                                {rdsoe_fields.isError.rdsoe_start_date && (<FormHelperText className='errorHelperTxt' id="price_start_date_error">Start date is missing</FormHelperText>)}
                                                                                <Box className='date-picker-icon'>
                                                                                    {InputProps?.endAdornment}
                                                                                </Box>
                                                                            </FormControl>
                                                                        </Box>
                                                                    )}
                                                                />
                                                            </LocalizationProvider>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="price_end_date" className="fontSemiBold">End Date</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                <DesktopDatePicker
                                                                    value={rdsoe_fields.rdsoe_end_date}
                                                                    disabled={(rdsoe_fields.rdsoe_start_date === null || rdsoe_fields.rdsoe_start_date === '') ? true : false}
                                                                    onChange={(e) => { handleEndDate(e) }}
                                                                    name="price_end_date"
                                                                    minDate={latestEndDate ? moment(latestEndDate, 'DD/MM/YYYY') : moment(rdsoe_fields.rdsoe_start_date, 'DD/MM/YYYY').add(1, 'days')}
                                                                    inputFormat="DD/MM/YYYY"
                                                                    // eslint-disable-next-line max-len
                                                                    renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                        <Box component="div" className='date-picker-input'>
                                                                            <FormControl size={'small'} fullWidth>
                                                                                <TextField size="small" id="price_end_date"
                                                                                    ref={inputRef} {...inputProps}
                                                                                    placeholder=""
                                                                                    aria-describedby="price_end_date_error"
                                                                                    autoComplete="off"
                                                                                />
                                                                                {isError.endDate && (<FormHelperText id="price_end_date_error">Error Here</FormHelperText>)}
                                                                                <Box className='date-picker-icon'>
                                                                                    {InputProps?.endAdornment}
                                                                                </Box>
                                                                            </FormControl>
                                                                        </Box>
                                                                    )}
                                                                />
                                                            </LocalizationProvider>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                        <Grid container sx={{ marginTop: '20px' }}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Item>
                                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                                        <LoadingButton
                                                            loading={state.loader}
                                                            loadingPosition="center"
                                                            endIcon=""
                                                            variant="contained"
                                                            type="submit" sx={{ marginRight: '15px' }}
                                                            //disabled={state.formValid || state.defaultmode === false}
                                                            // eslint-disable-next-line max-len
                                                            disabled={rdsoe_fields.hasEditData ? (rdsoe_fields.isError.rdsoe_retro_discount_selected || rdsoe_fields.isError.rdsoe_brand_owner_selected || rdsoe_fields.isError.rdsoe_member_name || rdsoe_fields.isError.rdsoe_member_account_selected || rdsoe_fields.isError.rdsoe_member_outlet_selected || rdsoe_fields.isError.rdsoe_start_date) : (state.formValid || state.defaultmode === false)}
                                                        >Save</LoadingButton>
                                                        <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </form>
            </Modal>
            {
                state.rdsoeMemberModal && (
                    <CommonPopUp
                        onShow={state.rdsoeMemberModal} size="medium_overlay"
                        supplierMemberCode={{
                            title: 'Member Details',
                            type: 'members'
                        }}
                        memberaccount={loadMemberAccountWithMemberId}
                        onHide={(e, reason) => hidePriceModal(e, reason)}
                    />
                )
            }
        </>
    );
}

export default AddEditRetroDiscountSchemesOutletExclusionsDetail;