import { useEffect, useState } from "react";
import { Container, Box, Grid, Checkbox, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { useDispatch, useSelector } from 'react-redux';
import { DataTableInvoiceDetailsDispute } from "../../../../components/shared/data-table/DataTableInvoiceDetailsDispute";


const LineDisputeLogTab = () => {

    const Item = styled(Paper)(({ theme }) => ({
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        boxShadow: "none"
    }));

    const { selected_invoice } = useSelector((state) => {
        return {
            
            selected_invoice: state.invoiceViewDetails
        }
    });   

    const [state, setState] = useState(
        {
            loader: false,
            defaultGrid: {
                pdcActionButton: true,
                exportExcelButton: true,
                addEditButton: true,
                apiUrl: `invoicesummary/${selected_invoice.selectedrow.invoiceId}/linedisputelog`,
                searchUrl: `invoicesummary/${selected_invoice.selectedrow.invoiceId}/linedisputelog`
            }
        }
    );

    const columns = [
        {
            headerName: "Id",
            // headerName: "Line Id",
            // field: "invoiceLineId",
            field: "lineNumber",
            align: "center",
            headerAlign: "center",
            width: 100
        },
        {
            headerName: "Date",
            field: "disputeDate",
            align: "center",
            headerAlign: "center",
            width: 100
        },
        {
            headerName: "Reason",
            field: "disputeReason",
            width: 200,
            renderCell: (params) => (
                <Typography title={params.value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: 'inherit', fontWeight: 'inherit' }}>{params.value}</Typography>
            )
        },
        {
            headerName: "System Dispute",
            field: "systemDisputed",
            width: 130,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            )            
        },
        {
            headerName: "System Dispute Reason",
            field: "systemDispute",
            flex: 1,
            minWidth: 220,
            renderCell: (params) => (
                <Typography title={params.value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: 'inherit', fontWeight: 'inherit' }}>{params.value}</Typography>
            )
        },   
        {
            headerName: "User Dispute",
            field: "userDisputed",
            width: 130,
            align: "center",
            headerAlign: "center",
             renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ) 
        },     
        {
            headerName: "User Dispute Reason",
            field: "userDispute",
            flex: 1,
            minWidth: 220,
            renderCell: (params) => (
                <Typography title={params.value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: 'inherit', fontWeight: 'inherit' }}>{params.value}</Typography>
            )
        },
        
        {
            headerName: "Set by",
            field: "disputeSetBy",            
            width: 150,
            renderCell: (params) => (
                <Typography title={params.value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: 'inherit', fontWeight: 'inherit' }}>{params.value}</Typography>
            )
        },
        {
            headerName: "Release by",
            field: "disputeReleasedBy",           
            width: 150,
            renderCell: (params) => (
                <Typography title={params.value} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: 'inherit', fontWeight: 'inherit' }}>{params.value}</Typography>
            )
        }
    ];



    return (
        <>
            <Container disableGutters={true} maxWidth={false}>
                <DataTableInvoiceDetailsDispute defaultGrid={state.defaultGrid} loc="linedispute" columns={columns ? columns: []} ></DataTableInvoiceDetailsDispute>
            </Container>
        </>

    );
};
export default LineDisputeLogTab;