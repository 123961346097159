import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  brand_name: '',
  addNewBrandData: null,
  brand_date: [],
  hasBrandOwner: false,
  brand_start_date: null,
  brand_end_date: null,
  edit_brand_owner: null,
  deleteList: null,
  brand_owner_name: {
    brand_owner_options: [],
    brand_owner_selected: null,
  },
  okDelete: false,
  deleteProcess: false,
  isError: {
    brand_name: false, 
    brand_owner_selected: false,
    brand_start_date: false
  }
}

const brandProduct = createSlice({
  name: 'brand_product',
  initialState,
  reducers: {
    setNewBrandData: (state, action) => {
      state.addNewBrandData = action.payload;
    },
    setBrandDate: (state, action) => {
      state.brand_date.push(action.payload)
    },
    setBrandDateList: (state, action) => {
      //state.brand_date = action.payload
      return {...state, brand_date: action.payload}
    },
    setDeleteList: (state, action) => {
      //state.brand_date = action.payload
      return {...state, deleteList: action.payload}
    },
    setAutocomplete: (state, action) => {
      const { parent, key, value } = action.payload;
      state[parent][key] = value;
      (state[parent][key] === null || state[parent][key] === '') ? state.isError[key] = true : state.isError[key] = false
    },
    brandInput: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
      state[key].length === 0 ? state.isError[key] = true : state.isError[key] = false
    },
    getIsError: (state, action) => {
      const { key, value } = action.payload;
      state.isError[key] = value
    },
    hasBrandOwner: (state, action) => {
      state.hasBrandOwner = action.payload;
    },
    editBrandOwner: (state, action) => {
      state.edit_brand_owner = action.payload;
    },
    setBrandEffectiveDate: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
      //state[key]._d.toString() === 'Invalid Date' ? state.isError[key] = true : state.isError[key] = false
      state.isError[key] ? (state[key] === null || state[key] === ""  || state[key] === 'Invalid date') ? state.isError[key] = true : state.isError[key] = false : '';
    },
    setdeleteBrandOwner: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    resetBrand: () => {
      return initialState
    },
  }
})

export const { resetBrand, setNewBrandData, setBrandDate, 
  getIsError, brandInput, setAutocomplete, hasBrandOwner, setBrandEffectiveDate, 
  setBrandDateList, editBrandOwner, setDeleteList, setdeleteBrandOwner } = brandProduct.actions

export default brandProduct.reducer