import { Checkbox, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl, RadioGroup, Radio, Autocomplete } from '@mui/material'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import axios_supplier from '../../../../services/utility/axios-supplier';
import axios_levy_scheme from '../../../../services/utility/axios-levy-scheme';
import PageLoader from '../../../../components/shared/PageLoader';
import { setLevySchemeState } from '../../../../store/slices/levyScheme';
import { handleModalState, setNewPriceModal } from '../../../../store/slices/modals';
import CommonPopUp from '../../../products/addEditPdc/pdc-tabs/CommonPopUp';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import { setMemberCode } from '../../../../store/slices/supplierMemberAccounts';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

function AddEditLevySupplier({ onShow, size = '', onHide, onSubmitDate }) {
    
    const { levyScheme_container } = useSelector((state) => {
        return {
            levyScheme_container: state.levySchemes,
            sma_container: state.smas,
        }
    });
    const dispatch = useDispatch();

    const [state, setState] = useState({
        LevysupplierListModal: false,
        pageLoader: false,
        defaultmode: false,
        formValid: true,
        supplierDetail: levyScheme_container.hasEditLevySupplier ? levyScheme_container.hasEditLevySupplier.supplier : null
    })

    const [included, setIncluded] = useState(true);

    const [supplier, setSupplier] = useState({
        supplier_options: [],
        supplier_selected: levyScheme_container.hasEditLevySupplier ? { id: levyScheme_container.hasEditLevySupplier.supplierId, name: levyScheme_container.hasEditLevySupplier.supplierName } : null
    })
    const [isError, setIsError] = useState({
        supplier_selected: false
    })

    const handleChange = (event) => {
        if (levyScheme_container.hasEditLevySupplier) {
            setState({ ...state, defaultmode: true })
        }
        setIncluded(event.target.checked);
    };

    const [open, setOpen] = useState(onShow);

    const handleClose = () => {
        //setState({ ...state, defaultmode: false })
        dispatch(handleModalState({
            key: 'addEditLevySupplierModal',
            value: false
        }))
        dispatch(setLevySchemeState({
            key: 'hasEditLevySupplier',
            value: null
        }))
        dispatch(setMemberCode({
            key: 'smaSupplierNameOnModal',
            value: ""
        }))
    };

    const handleSelf = () => {
        handleClose()
    }
    useEffect(() => {
        const fetchMyAPI = async () => {
            if (supplier.supplier_options.length === 0) {
                axios_supplier.get('/supplier/look-up')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data.filter((value, index, self) => self.findIndex((m) => m.name === value.name) === index);
                            setSupplier({ ...supplier, supplier_options: unique })
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
        }
        fetchMyAPI();
    }, [])

    useEffect(() => {
        if (supplier.supplier_selected === null || supplier.supplier_selected === "") {
            setState({ ...state, formValid: true, defaultmode: false })
        } else {
            setState({ ...state, formValid: false, defaultmode: true })
        }
    }, [supplier.supplier_selected])

    useEffect(() => {
        const {
            supplier_selected
        } = isError
        if (!supplier_selected) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true })
        }
    }, [isError])

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (supplier.supplier_selected === null && isError.supplier_selected === false) {
            setIsError({ ...isError, supplier_selected: true })
        } else {
            addPayDate()

        }

    }

    const addPayDate = async () => {
        let payload = null;
        if ((levyScheme_container.hasEditData !== null || levyScheme_container.editTabData !== null) && levyScheme_container.hasEditLevySupplier === null) {
            payload = {
                "supplierId": supplier.supplier_selected ? supplier.supplier_selected.id : "",
                included,
                "supplierName": supplier.supplier_selected ? supplier.supplier_selected.name : "",
            }
        } else if (levyScheme_container.hasEditLevySupplier !== null) {
            payload = {
                "id": levyScheme_container.hasEditLevySupplier.id,
                "supplierId": supplier.supplier_selected ? supplier.supplier_selected.id : levyScheme_container.hasEditLevySupplier.supplierId,
                included,
                "supplierName": supplier.supplier_selected ? supplier.supplier_selected.name : levyScheme_container.hasEditLevySupplier.supplierName,
            }
        }

        try {
            setState({ ...state, pageLoader: true })
            const { data } = ((levyScheme_container.hasEditData !== null || levyScheme_container.editTabData !== null) && levyScheme_container.hasEditLevySupplier === null) ? await axios_levy_scheme.post(`/levySchemes/${levyScheme_container.hasEditData.id || levyScheme_container.editTabData.id}/suppliers`, payload) : await axios_levy_scheme.put(`/levySchemes/${levyScheme_container.hasEditData.id || levyScheme_container.editTabData.id}/suppliers/${levyScheme_container.hasEditLevySupplier.id}`, payload)

            if (data) {
                onSubmitDate({ text: data.message, type: "success" })
                setState({ ...state, pageLoader: false })
                handleClose()
            }

        } catch (error) {
            const { response: { data, status } } = error;
            if (!data) {
                onSubmitDate({ text: error.message, type: "error" })
            } else {
                onSubmitDate({ text: data.message, type: "error" })
            }
            setState({ ...state, pageLoader: false })
            handleClose()
        }
    }

    useEffect(() => {
        if (levyScheme_container.hasEditLevySupplier !== null) {
            dispatch(setMemberCode({
                key: 'smaSupplierNameOnModal',
                value: levyScheme_container.hasEditLevySupplier.supplier
            }))
            setIncluded(levyScheme_container.hasEditLevySupplier.included)
            setState({ ...state, supplierDetail: levyScheme_container.hasEditLevySupplier.supplier })
        }
    }, [])


    // const hideSupplierMemberModal = (params) => {
    //     setState({ ...state, LevysupplierListModal: params === false ? params : true })
    // }
    // const resetFilter = () => {
    //     dispatch(setMemberCode({
    //         key: 'smaSupplierNameOnModal',
    //         value: ""
    //     }))
    //     dispatch(setLevySchemeState({
    //         key: 'hasEditLevySupplier',
    //         value: null
    //     }))
    // }
    // const CommonPopUpModal = () => {
    //     setState({...state, LevysupplierListModal: true})
    // }
    // const getsupplierDetail = (supplier) => {  
    //     if (supplier !== '') {
    //         setState({...state, defaultmode: true, supplierDetail: supplier, LevysupplierListModal: false})
    //     } 
    // }

    return (
        <>


            <Modal
                open={open}
                aria-labelledby="add-edit-price-title"
                aria-describedby="add-edit-price-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Box component="div" className="overlay" id="addEditModal">
                        {state.pageLoader && <PageLoader />}
                        <div className={`overlay-box ${size ? size : ''}`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-header text-center"> Supplier
                                        <CancelIcon className="closeBtn" onClick={handleClose} />
                                    </div>
                                    <div className="overlay-content">
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            <FormLabel htmlFor="type" className="fontSemiBold mandatory">Supplier</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Autocomplete
                                                                defaultValue={supplier.supplier_selected ? supplier.supplier_selected : null}
                                                                id="supplier_search"
                                                                options={supplier.supplier_options}
                                                                getOptionLabel={(option) => option.name || ""}
                                                                componentsProps={{
                                                                    paper: {
                                                                        sx: {
                                                                            fontSize: '0.85rem'
                                                                        }
                                                                    }
                                                                }}
                                                                onChange={(event, newValue) => {
                                                                    (isError['supplier_selected'] === null || isError['supplier_selected'] === "") ? setIsError({ ...isError, supplier_selected: true }) : setIsError({ ...isError, supplier_selected: false })
                                                                    if (event.target.value !== undefined) {
                                                                        setState({ ...state, defaultmode: true })
                                                                        setSupplier({ ...supplier, supplier_selected: newValue })
                                                                        setIsError({ ...isError, supplier_selected: false })
                                                                    } else {
                                                                        setIsError({ ...isError, supplier_selected: true })
                                                                    }
                                                                }}
                                                                renderInput={(params) =>
                                                                    <FormControl size={'small'} fullWidth>
                                                                        <div className="input-group">
                                                                            <TextField {...params}
                                                                                size="small"
                                                                                id="supplier"
                                                                                aria-describedby="supplier-error"
                                                                                error={isError.supplier_selected}
                                                                            />
                                                                            {isError.supplier_selected && (<FormHelperText className='errorHelperTxt' id="supplier-error">
                                                                                Please select supplier
                                                                            </FormHelperText>)}
                                                                        </div>
                                                                    </FormControl>
                                                                }
                                                            />
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            <FormLabel htmlFor="included" className="fontSemiBold">Include/Exclude</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <div className="input-group">
                                                                <Checkbox
                                                                    checked={included}
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                />
                                                            </div>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} sx={{ textAlign: 'right' }}>
                                                        <Item>
                                                            &nbsp;
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Grid container direction="row" alignItems="center">
                                                                <Button
                                                                    variant="contained"
                                                                    type="submit"
                                                                    sx={{ marginRight: '15px' }}
                                                                    //disabled={((levyScheme_container.hasEditLevySupplier && levyScheme_container.hasEditLevySupplier.confirmed === true) ? true : false) || state.defaultmode === false}
                                                                    // eslint-disable-next-line max-len
                                                                    disabled={((levyScheme_container.hasEditData !== null || levyScheme_container.editTabData !== null) && levyScheme_container.hasEditLevySupplier) ? isError.supplier_selected : (state.formValid || state.defaultmode === false)}
                                                                >Save</Button>
                                                                <Button variant="outlined" onClick={handleSelf}>Cancel</Button>
                                                            </Grid>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* {
                            state.LevysupplierListModal && <CommonPopUp
                                onShow={state.LevysupplierListModal}
                                size="large_overlay"
                                supplierMemberCode={{
                                    title: 'Supplier Details',
                                    type: 'supplier'
                                }}
                                onHide={hideSupplierMemberModal}
                                memberaccount={getsupplierDetail}
                            />
                        } */}
                    </Box>
                </form>

            </Modal>
        </>
    );
}

export default AddEditLevySupplier;