import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  discountScheme_name: '',
  add_edit_discountScheme_name: '',
  addNewdiscountSchemeData: null,
  hasEditData: null,
  hasEditMember: null,
  hasEditProgramme: null,
  hasEditSupplier: null,
  hasEditBrand: null,
  hasEditProduct: null,
  discountScheme_start_date: null,
  discountScheme_end_date: null,
  dc_bill_to: '',
  dc_benefit: '',
  special_price_value: '',
  deleteList: null,
  hasEditDC: null,
  discountScheme_general_brand: {
    brand_options: [],
    brand_selected: null,
  },
  discountScheme_distribution_channel: {
    distribution_channel_options: [],
    distribution_channel_selected: null,
  },
  discountScheme_general_programme: {
    programme_options: [],
    programme_selected: null,
  },
  discountSchemeStartSingleDelete: false,
  discountSchemeConfirmSingleDelete: false,
  discountSchemeDcStartDelete: false,
  discountSchemeDcConfirmDelete: false,
  discountSchemeProductStartDelete: false,
  discountSchemeProductConfirmDelete: false,
  discountSchemeSupplierStartDelete: false,
  discountSchemeSupplierConfirmDelete: false,
  discountSchemeBrandStartDelete: false,
  discountSchemeBrandConfirmDelete: false,
  discountSchemeMemberStartDelete: false,
  discountSchemeMemberConfirmDelete: false,
  discountSchemeProgrammeStartDelete: false,
  discountSchemeProgrammeConfirmDelete: false,
  member_tier_list: {
    member_tier_options: [],
    member_tier_selected: null
  },
  isError: {
    discountScheme_name: true,
    special_price_value: false,
    add_edit_discountScheme_name: false,
    dc_bill_to: false,
    dc_benefit: false,
    discountScheme_owner_name: true,
    discountScheme_start_date: false,
    member_tier_selected: false,
    dc_selected: false,
    programme_selected: false,
    brand_selected: false,
    discountScheme_product_name: false,
    discountScheme_member_name: false,
    discountScheme_supplier_name: false
  }
}

const discountScheme = createSlice({
  name: 'discountScheme',
  initialState,
  reducers: {
    setSelectedFilter: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value
    },
    setNewdiscountSchemeData: (state, action) => {
      state.addNewdiscountSchemeData = action.payload;
    },
    setDeleteList: (state, action) => {
      //return {...state, deleteList: action.payload}
      state.deleteList = action.payload
    },
    setAutocomplete: (state, action) => {
     const { parent, key, value } = action.payload;
      state[parent][key] = value;
      (state[parent][key] === null || state[parent][key] === "") ? state.isError[key] = true : state.isError[key] = false
    },
    discountSchemeInput: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
      state[key].length === 0 ? state.isError[key] = true : state.isError[key] = false
    },
    getIsError: (state, action) => {
      const { key, value } = action.payload;
      state.isError[key] = value
    },
    setdiscountSchemeEffectiveDate: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
      state.isError[key] ? (state[key] === null || state[key] === ""  || state[key] === 'Invalid date') ? state.isError[key] = true : state.isError[key] = false : '';
    },
    setHasEditData: (state, action) => {
      state.hasEditData = action.payload;
    },
    setDiscountSchemeState: (state, action) => {
      const {key, value} = action.payload;
      state[key] = value
    },
    setDiscountSchemeDeleteData: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    resetdiscountScheme: () => {
      return initialState
    },
  }
})

export const { setSelectedFilter, resetdiscountScheme, setNewdiscountSchemeData, 
  getIsError, discountSchemeInput, setAutocomplete, setdiscountSchemeEffectiveDate, 
  setDeleteList, setHasEditData, setDiscountSchemeState, setDiscountSchemeDeleteData
 } = discountScheme.actions

export default discountScheme.reducer