import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editPdcUpload: null,
    tableColumn: [],
    startSingleDelete: false,
    confirmSingleDelete: false,
    singleDeleteRow: null,
    startFileDelete: false,
    confirmFileDelete: false,
    editPdcUploadModal: false,
    wrongFileUpload: false
}

const pdcsFileUpload = createSlice({
    name: 'file_upload',
    initialState,
    reducers: {
        editPdcUpload: (state, action) => {
            state.editPdcUpload = action.payload
        },
        singleDeleteYes: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value
        },
        fileDeleteYes: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value
        },
        pdcwrongFile: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value
        },
        singleDeleteRow: (state, action) => {
            state.singleDeleteRow = action.payload
        },
        editPdcUploadModal: (state, action) => {
            state.editPdcUploadModal = action.payload;
        },
    }
})

export const { editPdcUpload, singleDeleteYes, singleDeleteRow, fileDeleteYes, editPdcUploadModal, pdcwrongFile } = pdcsFileUpload.actions

export default pdcsFileUpload.reducer