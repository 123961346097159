import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mieSupplier: false,
    existingInvoiceModal: false,
    mie_supplier: {
        supplier_code_options: [],
        supplier_code_selected: null,
    },
    mie_distribution_channel: {
        distribution_channel_options: [],
        distribution_channel_selected: null
    },
    invoiceDate: "",
    deliveryDate: "",
    mieSupplierCodeOnDashboard: "",
    mieSupplierCodeOnModal: "",
    spcRowParams: {
        id: '',
        value: '', 
        field: '',
        apiRef: null
    },
    setSpcValue: null,
    stageData: null,
    setSpcPdctValue: "",
    setVatAmount: '',
    reportModal: false,
    existingData: null,
    isError: {

    }
}

const manualInvoiceEntry = createSlice({
    name: 'manual_invoice_entry',
    initialState,
    reducers: {
        mieSupplier: (state, action) => {
            state.editProductUpload = action.payload
        },
        mieSearch: (state, action) => {
            const { parent, key, value } = action.payload;
            state[parent][key] = value;
        },
        setAutocomplete: (state, action) => {
            const { parent, key, value } = action.payload;
            state[parent][key] = value
        },
        setSupplierCode: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value
        },
        spcRowParams: (state, action) => {
            const getObj = action.payload;
            state.spcRowParams.id = getObj.id
            state.spcRowParams.value = getObj.value
            state.spcRowParams.field = getObj.field
        },
        setStageData: (state, action) => {
            state.stageData = action.payload
        },
        spcValueSeter: (state, action) => {
            state.setSpcValue = action.payload
        },
        spcProductSeter: (state, action) => {
            state.setSpcPdctValue = action.payload
        },
        setAmountValue: (state, action) => {
            const getObj = action.payload;
            state.setVatAmount = getObj.value
        },
        setHandleReportModal: (state, action) => {
            state.reportModal = action.payload
        },
        setDateValue: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value
        },
        setExistingInvoice: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value
        },
        handleExistingInvoice: (state, action) => {
            state.existingInvoiceModal = action.payload;
        },
    }
})

export const { mieSupplier, mieSearch, setAutocomplete, setSupplierCode, spcRowParams, 
    setStageData, spcValueSeter, spcProductSeter, setAmountValue, setHandleReportModal, setDateValue, setExistingInvoice, handleExistingInvoice} = manualInvoiceEntry.actions

export default manualInvoiceEntry.reducer