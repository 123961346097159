import { Checkbox, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl, Typography } from '@mui/material'
import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { warningModal, setNewPriceModal, handleAddEditModal } from "../../../store/slices/modals";
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import moment from "moment";
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import LoadingButton from '@mui/lab/LoadingButton';
import axios_member from '../../../services/utility/axios-member';
import { brandSearch, hasEdit, hasEditData, smaInput, setAutocomplete, getIsError, setMemberCode, resetSMA } from "../../../store/slices/supplierMemberAccounts";
import SnackBarDialog from "../../../components/shared/SnackBarDialog";
import CommonPopUp from "../../products/addEditPdc/pdc-tabs/CommonPopUp";
import { filterFlagChange, filterResetFlagChange } from '../../../store/slices/externalFilter';
import axios_supplier from "../../../services/utility/axios-supplier";

var editflag = 0;

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

function AddEditSupplierMemberAccountsDetails({ onShow, size = '' }) {

    const dispatch = useDispatch();
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
        handleSupplierMemberModal: '',
    });
    const [state, setState] = useState({
        loader: false,
        formValid: true,
        defaultmode: false,
        newSmaData: [],
        supplierMemberAccountsData: '',
        selectedMemberOutlet: false
    });
    const [mie_supplier, setMie_supplier] = useState("")
    const [mie_supplierValue, setMie_supplierValue] = useState("")
    const [mie_member, setMie_member] = useState("")
    const [mie_memberValue, setMie_memberValue] = useState("")
    const [open, setOpen] = useState(onShow);
    const { handleWarning, sma_container, searchProductModal, showNewPriceoneModal } = useSelector((state) => {
        return {
            handleWarning: state.modalActions.closeWaringAddProduct,
            sma_container: state.smas,
            searchProductModal: state.commonProducts,
            showNewPriceoneModal: state.modalActions.priceModal
        }
    });

    useEffect(() => {
        // console.log("1", sma_container)
        const inputNumber = document.querySelectorAll('.inputNumber');
        inputNumber.forEach(el => el.addEventListener('keypress', evt => {
            if (evt.which === 8) {
                return;
            }
            if (evt.which < 42 || evt.which > 60) {
                evt.preventDefault();
            }
        }));

        if (sma_container.hasEditData === null) {
            editflag = 0;
        }
        else {
            if (sma_container.hasEditData.memberOutletDTO.active === false) {
                editflag = 1;
            }
            else {
                editflag = 0;
            }
        }

    }, []);

    useEffect(() => {
        // console.log("1")
        if(sma_container.hasEditData) {
            setMie_supplier(sma_container.hasEditData.supplierDTO ? sma_container.hasEditData.supplierDTO : "")
            setMie_member(sma_container.hasEditData.memberOutletDTO.memberAccount.member ? sma_container.hasEditData.memberOutletDTO.memberAccount.member : "")
        }
        
        const fetchMyAPI = async () => {
            if (sma_container.sma_supplier.supplier_code_options.length === 0) {
                axios_supplier.get('/supplier/look-up')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data.filter((value, index, self) => self.findIndex((m) => m.name === value.name) === index);
                            dispatch(brandSearch({
                                parent: 'sma_supplier',
                                key: 'supplier_code_options',
                                value: unique
                            }))
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
            if (sma_container.sma_member.member_code_options.length === 0) {
                axios_member.get('/members/look-up?pageSize=500')
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data.items) {
                            const unique = data.items.filter((value, index, self) => self.findIndex((m) => m.name === value.name) === index);
                            dispatch(brandSearch({
                                parent: 'sma_member',
                                key: 'member_code_options',
                                value: unique
                            }))
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
            // console.log("1", state.formValid);
        }
        fetchMyAPI();
    }, [sma_container.hasEditData !== null])

    useEffect(() => {
        // console.log("1")
        const fetchMyAPI = async () => {
            if (sma_container.sma_member.sma_member_name) {
                axios_member.get(`members/${sma_container.sma_member.sma_member_name.id}/memberAccounts`)
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data
                            dispatch(setAutocomplete({
                                parent: 'sma_member_account',
                                key: 'sma_member_account_options',
                                value: unique
                            }));
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
            if (sma_container.sma_member_account.sma_member_account_selected) {
                axios_member.get(`members/${sma_container.sma_member.sma_member_name.id}/memberAccounts/${sma_container.sma_member_account.sma_member_account_selected.id}/memberOutlets`)
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            const unique = data;
                            dispatch(setAutocomplete({
                                parent: 'sma_member_outlet',
                                key: 'sma_member_outlet_options',
                                value: unique
                            }));
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }
            // console.log("1", state.formValid);
        }
        fetchMyAPI();
    }, [sma_container.sma_member.sma_member_name])

    const handleClose = () => {
        //setState({ ...state, defaultmode: false })
        dispatch(handleAddEditModal({
            showAddEdit: false
        }));
        dispatch(resetSMA());
        dispatch(filterFlagChange(true));
        dispatch(filterResetFlagChange(false));
    };

    const handleCancel = (event) => {
        setState({ ...state, defaultmode: false })
        dispatch(warningModal({
            yesHide: !handleWarning
        }))
    }

    const CommonPopUpModal = (params, event) => {

        if (event === 'members') {
            // dispatch(brandSearch({
            //     parent: 'sma_member_account',
            //     key: 'sma_member_account_selected',
            //     value: null
            // }))
            // dispatch(brandSearch({
            //     parent: 'sma_member_outlet',
            //     key: 'sma_member_outlet_selected',
            //     value: null
            // }))
        }

        setState({ ...state, handleSupplierMemberModal: event })
        dispatch(setNewPriceModal(true))
    }

    const hidePriceModal = (event) => {
        dispatch(setNewPriceModal(event))
    }
    const resetFilter = (params, event) => {
        if (event === 'supplier') {
            dispatch(setMemberCode({
                key: 'smaSupplierNameOnModal',
                value: ""
            }))
            dispatch(smaInput({
                key: 'sma_supplier_name',
                value: ""
            }))
            dispatch(getIsError({ key: 'sma_supplier_name_error', value: true }))
        }
        if (event === 'members') {
            dispatch(setMemberCode({
                key: 'smaMemberCodeOnModal',
                value: ""
            }))
            dispatch(smaInput({
                key: 'sma_member_name',
                value: ""
            }))
            dispatch(brandSearch({
                parent: 'sma_member_account',
                key: 'sma_member_account_selected',
                value: null
            }))
            dispatch(brandSearch({
                parent: 'sma_member_outlet',
                key: 'sma_member_outlet_selected',
                value: null
            }))
            dispatch(getIsError({ key: 'sma_member_name_error', value: true }))
            dispatch(getIsError({ key: 'sma_member_account_search', value: true }))
            dispatch(getIsError({ key: 'sma_member_outlet_search', value: true }))
        }
    }



    const handleInputChange = (event) => {
        setState({ ...state, defaultmode: true })
        const { value, name } = event.target;
        dispatch(smaInput({
            key: name,
            value
        }))
    }

    const handleSelection = (event, newValue, parentname, name) => {
        if ((event.target.value !== "" || event.target.value !== null) && newValue !== null) {
            dispatch(brandSearch({
                parent: parentname,
                key: name,
                value: newValue
            }));
            if (sma_container.hasEditData) {
                setState({ ...state, defaultmode: true, supplierMemberAccountsData: newValue.id });
            } else {
                setState({ ...state, supplierMemberAccountsData: newValue.id });
            }
            if (parentname === 'sma_member_account') {
                dispatch(brandSearch({
                    parent: 'sma_member_outlet',
                    key: 'sma_member_outlet_selected',
                    value: null
                }))
            }
            if (parentname === 'sma_member_outlet') {
                if (newValue.active === true) {
                    setState({ ...state, selectedMemberOutlet: false })
                    dispatch(smaInput({
                        key: 'sma_status',
                        value: true
                    }));
                    document.getElementById("sma_status").disabled = false;
                }
                else {
                    setState({ ...state, selectedMemberOutlet: true })
                    dispatch(smaInput({
                        key: 'sma_status',
                        value: false
                    }));
                    document.getElementById("sma_status").disabled = true;
                }
            }
        }
        else {
            if (parentname === 'sma_member_account') {
                dispatch(brandSearch({
                    parent: 'sma_member_outlet',
                    key: 'sma_member_outlet_selected',
                    value: null
                }))
            }
            dispatch(brandSearch({
                parent: parentname,
                key: name,
                value: newValue
            }));
            if (parentname === 'sma_member_outlet') {
                setState({ ...state, selectedMemberOutlet: true });
                dispatch(smaInput({
                    key: 'sma_status',
                    value: false
                }));
                document.getElementById("sma_status").disabled = true;

            }
        }
    }

    const handleCheckboxChange = (event) => {
        // console.log("Checkbox selection")
        if (sma_container.hasEditData) {
            setState({ ...state, defaultmode: true });
        }
        const { checked, name } = event.target;
        dispatch(smaInput({
            key: name,
            value: checked
        }))
    };

    const getDataMemberOutlet = () => {
        if (state.supplierMemberAccountsData !== '') {
            axios_member.get(`members/${sma_container.sma_member.sma_member_name.id}/memberAccounts/${sma_container.sma_member_account.sma_member_account_selected.id}/memberOutlets`)
                .then(function (response) {
                    // handle success
                    const { status, data } = response;
                    if (data) {
                        const unique = data
                        dispatch(setAutocomplete({
                            parent: 'sma_member_outlet',
                            key: 'sma_member_outlet_options',
                            value: unique
                        }));
                    }
                })
                .catch(function (error) {
                    // handle error
                    const { response: { data, status } } = error;
                    if (status === 500 && data.errorType === "Business") {
                        dispatch(setAutocomplete({
                            parent: 'sma_member_outlet',
                            key: 'sma_member_outlet_options',
                            value: []
                        }));
                    } else {
                        console.log(error)
                    }
                });
        }
    }

    useEffect(() => {
        // console.log("3")
        if (state.supplierMemberAccountsData) {
            getDataMemberOutlet()
        }
        // console.log("3", state.formValid);
    }, [state.supplierMemberAccountsData])

    useEffect(() => {
        // console.log("4")
        if ((sma_container.sma_supplier.sma_supplier_name === "" || sma_container.sma_supplier.sma_supplier_name === null) || (sma_container.sma_member.sma_member_name === null || sma_container.sma_member.sma_member_name === "") || (sma_container.sma_member_account.sma_member_account_selected === null || sma_container.sma_member_account.sma_member_account_selected === "") || (sma_container.sma_member_outlet.sma_member_outlet_selected === null || sma_container.sma_member_outlet.sma_member_outlet_selected === "") || (sma_container.sma_code === null || sma_container.sma_code === "")) {
            setState({ ...state, formValid: true, defaultmode: false })
        } else {
            setState({ ...state, formValid: false, defaultmode: true })
        }
        // console.log("4", state.formValid);
    // eslint-disable-next-line max-len
    }, [sma_container.sma_supplier.sma_supplier_name, sma_container.sma_member.sma_member_name, sma_container.sma_member_account.sma_member_account_selected, sma_container.sma_member_outlet.sma_member_outlet_selected, sma_container.sma_code])

    useEffect(() => {
        // console.log("5");

        const {
            sma_code,
            sma_supplier_name,
            sma_member_name,
            sma_member_account_selected,
            sma_member_outlet_selected
        } = sma_container.isError
        if (!sma_code && sma_container.sma_code !== "" && !sma_supplier_name && !sma_member_name && !sma_member_account_selected && !sma_member_outlet_selected) {
            setState({ ...state, formValid: false });
            // console.log("5a", state.formValid);
        } else {
            setState({ ...state, formValid: true });
            // console.log("5b", state.formValid);
        }

    }, [sma_container.isError])

    const handleSubmit = async (event) => {
        event.preventDefault();
        let productData = null;
        if (!state.formValid) {
            productData = {
                //"id": sma_container.hasEditData ? sma_container.hasEditData.id : null,
                "active": sma_container.sma_status,
                "code": sma_container.sma_code,
                "supplierDTO": mie_supplier ? mie_supplier : '',
                "memberOutletDTO": {
                    "id": sma_container.sma_member_outlet.sma_member_outlet_selected.id,
                    "memberAccount": {
                        "id": sma_container.sma_member_account.sma_member_account_selected.id,
                        "member": {
                            "id": sma_container.sma_member.sma_member_name ? sma_container.sma_member.sma_member_name.id : '',
                            "name": sma_container.sma_member.sma_member_name ? sma_container.sma_member.sma_member_name.name : '',
                        },
                        "name": sma_container.sma_member_account.sma_member_account_selected.name,
                    },
                    "name": sma_container.sma_member_outlet.sma_member_outlet_selected.name
                }
            }
            try {
                setState({ ...state, loader: true })
                const { data, status } = sma_container.hasEditData ? await axios_member.put(`supplierMemberAccounts/${sma_container.hasEditData.id}`, { ...productData }) : await axios_member.post(`supplierMemberAccounts`, { ...productData });
                if (data.success === true && data.resultMap) {
                    setSnackBar({ ...snackBar, show: true, text: `Supplier member account ${sma_container.hasEditData ? 'Edited' : 'Added'} Successfully!`, type: "success", })
                    setState({ ...state, loader: false, formValid: true, newSmaData: data.resultMap })
                    setTimeout(() => {
                        // window.location.reload();
                        dispatch(handleAddEditModal({
                            showAddEdit: false
                        }));
                        dispatch(filterFlagChange(true));
                        dispatch(filterResetFlagChange(false));
                    }, 1500);
                }
            } catch (error) {
                setState({ ...state, loader: false })
                const { response: { data, status } } = error;
                setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error", })
            }
        }
    };

    const loadMemberAccountWithMemberId = (e, type) => {

        setState({ ...state, defaultmode: true })
        if (e !== '' && type === 'supplier') {
            dispatch(smaInput({
                key: 'sma_supplier_name',
                value: e
            }))
        }
        if (e !== '' && type === 'members') {
            dispatch(brandSearch({
                parent: 'sma_member_account',
                key: 'sma_member_account_selected',
                value: null
            }))
            dispatch(brandSearch({
                parent: 'sma_member_outlet',
                key: 'sma_member_outlet_selected',
                value: null
            }))
            dispatch(smaInput({
                key: 'sma_member_name',
                value: e
            }))
            axios_member.get(`members/${e.id}/memberAccounts`)
                .then(function (response) {
                    // handle success
                    const { status, data } = response;
                    if (data) {
                        const unique = data
                        dispatch(setAutocomplete({
                            parent: 'sma_member_account',
                            key: 'sma_member_account_options',
                            value: unique
                        }));
                    }
                })
                .catch(function (error) {
                    // handle error
                    console.log(error)
                });
        }
    }

    return (
        <>
            {snackBar.show && <SnackBarDialog
                onShow={snackBar.show}
                text={snackBar.text}
                type={snackBar.type}
                onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
            />}

            <Modal
                open={open}
                aria-labelledby="add-edit-price-title"
                aria-describedby="add-edit-price-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Box component="div" className="overlay" id="addEditModal">
                        <div className={`overlay-box  ${size ? size : ''}`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-header text-center"> Supplier Member Account Details
                                        <CancelIcon className="closeBtn" onClick={handleClose} />
                                    </div>
                                    <div className="overlay-content">
                                        <Grid item xs={12} md={12}>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                {/* <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="sma_supplier_name" className="fontSemiBold mandatory">Supplier</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Item>
                                                            <Box component="div" className=''>
                                                                <FormControl size={'small'} fullWidth>
                                                                    <TextField
                                                                        inputProps={{ maxLength: 100 }}
                                                                        size="small"
                                                                        id="sma_supplier_name"
                                                                        value={sma_container.sma_supplier_name ? sma_container.sma_supplier_name.name : ''}
                                                                        aria-describedby="sma_supplier_name"
                                                                        name="sma_supplier_name"
                                                                        disabled={true}
                                                                        error={sma_container.isError.sma_supplier_name}
                                                                    />
                                                                    {sma_container.isError.sma_supplier_name &&
                                                                        (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)}
                                                                </FormControl>
                                                            </Box>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={1.5}>
                                                        <Item>
                                                            <Button variant="text" size='small' disabled={editflag === 1 ? true : false} className='lookup-btns' sx={{ minWidth: 0, padding: 0, marginRight: '5px' }} onClick={(e) => resetFilter(e, 'supplier')} startIcon={<ClearIcon />}></Button>
                                                            <Button variant="text" size='small' disabled={editflag === 1 ? true : false} className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={(e) => CommonPopUpModal(e, 'supplier')} startIcon={<EditIcon />}></Button>
                                                        </Item>
                                                    </Grid>
                                                </Grid> */}
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="sma_supplier_name" className="fontSemiBold mandatory">Supplier</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Box component="div" className=''>
                                                                <Autocomplete
                                                                    value={mie_supplier || null}
                                                                    disabled={editflag === 1 ? true : false}
                                                                    getOptionLabel={(option) => option.name || ""}
                                                                    onChange={(_, newValue) => {
                                                                        setMie_supplier(newValue)
                                                                        dispatch(brandSearch({
                                                                            parent: 'sma_supplier',
                                                                            key: 'sma_supplier_name',
                                                                            value: newValue
                                                                        }))
                                                                    }}
                                                                    inputValue={mie_supplierValue}
                                                                    componentsProps={{
                                                                        paper: {
                                                                            sx: {
                                                                                fontSize: '0.85rem'
                                                                            }
                                                                        }
                                                                    }}
                                                                    onInputChange={(_, newInputValue) => {
                                                                        setMie_supplierValue(newInputValue)
                                                                        dispatch(brandSearch({
                                                                            parent: 'sma_supplier',
                                                                            key: 'sma_supplier_name',
                                                                            value: newInputValue
                                                                        }))
                                                                    }}
                                                                    id="mie_supplier_search"
                                                                    options={sma_container.sma_supplier.supplier_code_options}
                                                                    renderInput={(params) =>
                                                                        <FormControl size={'small'} fullWidth>
                                                                            <Box component="div" >
                                                                                <TextField {...params}
                                                                                    size="small"
                                                                                    id="mie_supplier"
                                                                                    aria-describedby="mie_supplier-error"
                                                                                    error={sma_container.isError.sma_supplier_name}
                                                                                />
                                                                                {sma_container.isError.sma_supplier_name &&
                                                                                    (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)}
                                                                            </Box>
                                                                        </FormControl>
                                                                    }
                                                                />
                                                            </Box>
                                                        </Item>
                                                    </Grid>
                                                </Grid>

                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="sma_member_name" className="fontSemiBold mandatory">Member</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Box component="div" className=''>
                                                                {/* <FormControl size={'small'} fullWidth>
                                                                    <TextField
                                                                        inputProps={{ maxLength: 100 }}
                                                                        size="small"
                                                                        id="sma_member_name"
                                                                        value={sma_container.sma_member_name ? sma_container.sma_member_name.name : ''}
                                                                        aria-describedby="sma_member_name"
                                                                        error={sma_container.isError.sma_member_name}
                                                                        name="sma_member_name"
                                                                        disabled={true}
                                                                    />
                                                                    {sma_container.isError.sma_member_name &&
                                                                        (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)}
                                                                </FormControl> */}
                                                                <Autocomplete
                                                                    value={mie_member || null}
                                                                    disabled={editflag === 1 ? true : false}
                                                                    getOptionLabel={(option) => option.name || ""}
                                                                    onChange={(_, newValue) => {
                                                                        setMie_member(newValue ? newValue : "")
                                                                        dispatch(brandSearch({
                                                                            parent: 'sma_member',
                                                                            key: 'sma_member_name',
                                                                            value: newValue
                                                                        }))
                                                                        dispatch(brandSearch({
                                                                            parent: 'sma_member_account',
                                                                            key: 'sma_member_account_selected',
                                                                            value: null
                                                                        }))
                                                                        dispatch(brandSearch({
                                                                            parent: 'sma_member_outlet',
                                                                            key: 'sma_member_outlet_selected',
                                                                            value: null
                                                                        }))
                                                                        
                                                                    }}
                                                                    inputValue={mie_memberValue}
                                                                    componentsProps={{
                                                                        paper: {
                                                                            sx: {
                                                                                fontSize: '0.85rem'
                                                                            }
                                                                        }
                                                                    }}
                                                                    onInputChange={(_, newInputValue) => {
                                                                        setMie_memberValue(newInputValue ? newInputValue : "");
                                                                        dispatch(brandSearch({
                                                                            parent: 'sma_member',
                                                                            key: 'sma_member_name',
                                                                            value: newInputValue
                                                                        }))
                                                                        // dispatch(brandSearch({
                                                                        //     parent: 'sma_member_account',
                                                                        //     key: 'sma_member_account_selected',
                                                                        //     value: null
                                                                        // }))
                                                                        // dispatch(brandSearch({
                                                                        //     parent: 'sma_member_outlet',
                                                                        //     key: 'sma_member_outlet_selected',
                                                                        //     value: null
                                                                        // }))
                                                                    }}
                                                                    id="mie_member_search"
                                                                    options={sma_container.sma_member.member_code_options}
                                                                    renderInput={(params) =>
                                                                        <FormControl size={'small'} fullWidth>
                                                                            <Box component="div" >
                                                                                <TextField {...params}
                                                                                    size="small"
                                                                                    id="mie_member"
                                                                                    aria-describedby="mie_member-error"
                                                                                    error={sma_container.isError.sma_member_name}
                                                                                />
                                                                                {sma_container.isError.sma_member_name &&
                                                                                    (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)}
                                                                            </Box>
                                                                        </FormControl>
                                                                    }
                                                                />
                                                            </Box>
                                                        </Item>
                                                    </Grid>
                                                    {/* <Grid item xs={12} md={1.5}>
                                                        <Item>
                                                            <Button variant="text" size='small' disabled={editflag === 1 ? true : false} className='lookup-btns' sx={{ minWidth: 0, padding: 0, marginRight: '5px' }} onClick={(e) => resetFilter(e, 'members')} startIcon={<ClearIcon />}></Button>
                                                            <Button variant="text" size='small' disabled={editflag === 1 ? true : false} className='lookup-btns' sx={{ minWidth: 0, padding: 0 }} onClick={(e) => CommonPopUpModal(e, 'members')} startIcon={<EditIcon />}></Button>
                                                        </Item>
                                                    </Grid> */}
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="sma_member_account" className="fontSemiBold mandatory">Member account</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Box component="div" className=''>
                                                                <Autocomplete
                                                                    // eslint-disable-next-line max-len
                                                                    value={sma_container.sma_member_account.sma_member_account_selected ? sma_container.sma_member_account.sma_member_account_selected : null}
                                                                    //defaultValue={sma_container.sma_member_account.sma_member_account_selected || (sma_container.hasEditData ? sma_container.hasEditData.vatCode : null)}
                                                                    id="sma_member_account_search"
                                                                    disabled={editflag === 1 ? true : !sma_container.sma_member.sma_member_name ? true : false}
                                                                    options={sma_container.sma_member_account.sma_member_account_options}
                                                                    getOptionLabel={(option) => option.name || ""}
                                                                    componentsProps={{
                                                                        paper: {
                                                                            sx: {
                                                                                fontSize: '0.85rem'
                                                                            }
                                                                        }
                                                                    }}
                                                                    onChange={(event, newValue) => handleSelection(event, newValue, 'sma_member_account', 'sma_member_account_selected')}
                                                                    renderInput={(params) =>
                                                                        <FormControl size={'small'} fullWidth>
                                                                            <Box component="div" className=''>
                                                                                <TextField {...params}
                                                                                    size="small"
                                                                                    id="sma_member_account"
                                                                                    aria-describedby="sma_member_account-error"
                                                                                    error={sma_container.isError.sma_member_account_selected}
                                                                                />
                                                                                {sma_container.isError.sma_member_account_selected &&
                                                                                    (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)}
                                                                            </Box>
                                                                        </FormControl>
                                                                    }
                                                                />
                                                            </Box>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="sma_member_outlet" className="fontSemiBold mandatory">Member outlet</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Box component="div" className=''>
                                                                <Autocomplete
                                                                    // eslint-disable-next-line max-len
                                                                    //defaultValue={sma_container.sma_member_outlet.sma_member_outlet_selected || (sma_container.hasEditData ? sma_container.hasEditData.memberOutletDTO : null)}
                                                                    value={sma_container.sma_member_outlet.sma_member_outlet_selected ? sma_container.sma_member_outlet.sma_member_outlet_selected : null}
                                                                    id="sma_member_outlet_search"
                                                                    options={sma_container.sma_member_outlet.sma_member_outlet_options}
                                                                    // getOptionLabel={(option) => option.name || ""}
                                                                    getOptionLabel={(option) => (
                                                                        option.active === true ? option.name + " (Active)" : option.name + " (InActive)"
                                                                    ) || ""}
                                                                    renderOption={(props, option) => (
                                                                        <Box sx={{
                                                                            display: 'flex',
                                                                            justifyContent: 'space-between',
                                                                            width: '100%',
                                                                            position: 'relative'
                                                                        }} {...props}>
                                                                            <Box sx={{ paddingRight: '10px' }}>{option.name}</Box>
                                                                            <Box sx={{ position: 'absolute', right: '10px', top: '8px', fontSize: '11px' }} className={option.active === true ? "active-member-outlet" : "inactive-member-outlet"}>({option.active === true ? "Active" : "InActive"})</Box>
                                                                        </Box>)}
                                                                    componentsProps={{
                                                                        paper: {
                                                                            sx: {
                                                                                fontSize: '0.85rem'
                                                                            }
                                                                        }
                                                                    }}
                                                                    disabled={editflag === 1 ? true : !sma_container.sma_member_account.sma_member_account_selected ? true : false}
                                                                    onChange={(event, newValue) => handleSelection(event, newValue, 'sma_member_outlet', 'sma_member_outlet_selected')}
                                                                    renderInput={(params) =>
                                                                        <FormControl size={'small'} fullWidth>
                                                                            <Box component="div" className=''>
                                                                                <TextField {...params}
                                                                                    size="small"
                                                                                    id="sma_member_outlet"
                                                                                    aria-describedby="sma_member_outlet-error"
                                                                                    error={sma_container.isError.sma_member_outlet_selected}
                                                                                />
                                                                                {sma_container.isError.sma_member_outlet_selected &&
                                                                                    (<FormHelperText className='errorHelperTxt' id="general_brand-error"> This field is mandatory </FormHelperText>)}
                                                                            </Box>
                                                                        </FormControl>
                                                                    }
                                                                />
                                                            </Box>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="sma_code" className="fontSemiBold mandatory">Code</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Box component="div" className=''>
                                                                <FormControl size="small" fullWidth>
                                                                    <TextField
                                                                        inputProps={{ maxLength: 50 }}
                                                                        type="text"
                                                                        size="small"
                                                                        id="sma_code"
                                                                        aria-describedby="sma_code_error"
                                                                        onChange={(e) => handleInputChange(e)}
                                                                        //value={(sma_container.sma_code) || (sma_container.hasEditData ? sma_container.hasEditData.code : '')}
                                                                        value={sma_container.sma_code}
                                                                        error={sma_container.isError.sma_code}
                                                                        name="sma_code"
                                                                        className="inputNumber"
                                                                        disabled={editflag === 1 ? true : false}
                                                                    //onBlur={(e) => checkQunique(e, 'isCodeNameValid?code')}
                                                                    />
                                                                    {sma_container.isError.sma_code && (<FormHelperText className='errorHelperTxt' id="sma_code_error">Please enter unique value</FormHelperText>)}
                                                                </FormControl>
                                                            </Box>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="rate" className="fontSemiBold">Active</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Box component="div" className=''>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox size="medium"
                                                                            id="sma_status"
                                                                            checked={sma_container.sma_status}
                                                                            onChange={(e) => handleCheckboxChange(e)}
                                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                                            name="sma_status"
                                                                            disabled={editflag === 1 ? true : false}
                                                                        />}
                                                                    label="" />

                                                            </Box>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>

                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            {state.selectedMemberOutlet && (<Typography sx={{ fontSize: '12px', color: 'red' }} >*Please select an (Active) Member Outlet to keep this active!</Typography>)}
                                                        </Item>
                                                    </Grid>
                                                </Grid>

                                            </Item>
                                        </Grid>
                                        <Grid container sx={{ marginTop: '20px' }}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Item>
                                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                                        <LoadingButton
                                                            loading={state.loader}
                                                            loadingPosition="center"
                                                            endIcon=""
                                                            variant="contained"
                                                            type="submit" sx={{ marginRight: '15px' }}
                                                            //disabled={state.formValid || state.defaultmode === false} || state.defaultmode === false
                                                            // eslint-disable-next-line max-len
                                                            disabled={editflag === 1 ? true : sma_container.hasEditData ? (sma_container.isError.sma_supplier_name || sma_container.isError.sma_member_name || sma_container.isError.sma_member_account_selected || sma_container.isError.sma_member_outlet_selected || sma_container.isError.sma_code) : (state.formValid)}
                                                        >Save</LoadingButton>
                                                        <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </form>
            </Modal>
            {
                showNewPriceoneModal && (
                    <CommonPopUp
                        onShow={showNewPriceoneModal} size="medium_overlay"
                        supplierMemberCode={{
                            title: (state.handleSupplierMemberModal && state.handleSupplierMemberModal === 'members') ? 'Member Details' : 'Supplier Details',
                            type: state.handleSupplierMemberModal
                        }}
                        supplierMemberAccount={loadMemberAccountWithMemberId}
                        onHide={(e, reason) => hidePriceModal(e, reason)}
                    />
                )
            }
        </>
    );
}

export default AddEditSupplierMemberAccountsDetails;