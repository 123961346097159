import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hasEditData: null,
  deleteList: null,
  okDelete: false,
  deleteProcess: false,
  levyScheme_start_date: null,
  levyScheme_end_date: null,
  add_edit_levyScheme_name: '',
  levyScheme_rate: '',
  levyscheme_levy_type: null,
  levyscheme_charge_type: null,
  levyscheme_raise_type: null,
  hasEditLevyDC: null,
  hasEditLevySupplier: null,
  hasEditLevyProduct: null,
  levyscheme_general_unit_measure: {
    measure_options: [],
    measure_selected: null,
  },
  levyscheme_general_vat_code: {
    vat_code_options: [],
    vat_code_selected: null,
  },
  levyscheme_distribution_channel: {
    levyscheme_channel_options: [],
    levyscheme_channel_selected: null,
  },
  levyschemeStartSingleDelete: false,
  levyschemeConfirmSingleDelete: false,
  levyschemeDcStartDelete: false,
  levyschemeDcConfirmDelete: false,
  levyschemeProductStartDelete: false,
  levyschemeProductConfirmDelete: false,
  levyschemeSupplierStartDelete: false,
  levyschemeSupplierConfirmDelete: false,
  member_tier: {
    member_tier_options: [],
    member_tier_selected: null
  },
  isError: {
    levyScheme_start_date: false,
    add_edit_levyScheme_name: false,
    levyscheme_levy_type: false,
    levyScheme_rate: false,
    member_tier_selected: false,
    levyscheme_charge_type: false,
    levyscheme_raise_type: false,
    vat_code_selected: false,
    measure_selected: false,
    dc_selected: false,
    discountScheme_product_name: false,
    discountScheme_supplier_name: false
  }
}

const levyScheme = createSlice({
  name: 'levyScheme',
  initialState,
  reducers: {
    brandSearch: (state, action) => {
      const { parent, key, value } = action.payload;
      state[parent][key] = value;
      (state[parent][key] === null || state[parent][key] === "") ? state.isError[key] = true : state.isError[key] = false
    },
    setSelectedFilter: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value
    },
    setLevySchemeDeleteData: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    setDeleteList: (state, action) => {
      state.deleteList = action.payload
    },
    setAutocomplete: (state, action) => {
      const { parent, key, value } = action.payload;
      state[parent][key] = value
    },
    levySchemeInput: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
      state[key].length === 0 ? state.isError[key] = true : state.isError[key] = false
    },
    getIsError: (state, action) => {
      const { key, value } = action.payload;
      state.isError[key] = value
    },
    setHasEditData: (state, action) => {
      state.hasEditData = action.payload;
    },
    setlevySchemeEffectiveDate: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
      state.isError[key] ? (state[key] === null || state[key] === ""  || state[key] === 'Invalid date') ? state.isError[key] = true : state.isError[key] = false : '';
    },
    setLevySchemeState: (state, action) => {
      const {key, value} = action.payload;
      state[key] = value
    },
    resetlevyScheme: () => {
      return initialState
    },
  }
})

export const { setSelectedFilter, resetlevyScheme, getIsError, levySchemeInput, setAutocomplete, setDeleteList, 
  setHasEditData, brandSearch, setlevySchemeEffectiveDate, setLevySchemeState, setLevySchemeDeleteData
 } = levyScheme.actions

export default levyScheme.reducer