import { Suspense } from "react";
import { Route, Routes } from 'react-router-dom';
import Home from "../pages/home";
import InvoiceSummary from '../pages/invoices/summary';
import PerformanceLogs from '../pages/logs/performance-logs';
import StandingData from '../pages/management/standing-data';
import PageNotFound from '../pages/page-not-found';
import Products from '../pages/products/products';
import StatementCycles from '../pages/statement-cycles/statement-cycles';
import Suppliers from '../pages/suppliers-and-members/suppliers';
import Members from '../pages/suppliers-and-members/members';
import DiscountSchemes from '../pages/schemes/discount-schemes';
import RetrospectiveFileUpload from '../pages/schemes/retrospective-file-upload';
import LevySchemes from '../pages/schemes/levy-schemes';
import Programmes from '../pages/schemes/programmes';
import NusslReport from '../pages/output/nussl-report';
import SupplierReports from '../pages/output/supplier-reports';
import MemberReports from '../pages/output/member-reports';
import Reports from '../pages/output/reports';
import Brands from "../pages/products/brands";
import UnitOfMeasure from "../pages/products/unit-of-measure";
import Categories from "../pages/products/categories";
import VatCode from "../pages/management/vatcode";
import Cbs from "../pages/management/cbs";
import Pdc from "../pages/products/pdc";
import DistributionChannel from "../pages/statement-cycles/distribution-channel";
import SupplierMemberAccounts from '../pages/suppliers-and-members/supplier-members-accounts';
import AggregationGroup from '../pages/suppliers-and-members/aggregation-group';
import RetrospectiveDiscountSchemesOutletExclusions from '../pages/schemes/retrospective-discount-schemes-outlet-exclusions';
import RetrospectiveDiscountSchemes from '../pages/schemes/retrospective-discount-schemes';
import ProductFileUpload from "../pages/products/product-file-upload";
import AccountingEntries from '../pages/output/accounting-entries';
import MemberTiers from '../pages/suppliers-and-members/purchasing-tiers';
import OrganisationType from '../pages/suppliers-and-members/organisation-type';
import EdiFileLog from '../pages/invoices/edi-file-log';
import FailedFiles from '../pages/invoices/failed-files';
import FailedImports from '../pages/invoices/failed-imports';
import InvoiceReprocess from '../pages/invoices/reprocess';
import ManualEntryInvoice from "../pages/invoices/manual-entry";
import PdcFileUpload from "../pages/products/pdc-file-upload";
import PromotionType from "../pages/schemes/promotion-type";
import MemberDetails from '../pages/suppliers-and-members/members/member-details';
import MemberAccounts from '../pages/suppliers-and-members/members/member-accounts';
import MemberOutlets from '../pages/suppliers-and-members/members/member-outlets';
import MemberProgrammes from '../pages/suppliers-and-members/members/member-programmes';
import EditMember from '../pages/suppliers-and-members/members/edit-member';
import ProcessManager from "../pages/management/process-manager";
import ServerProcessHistory from "../pages/logs/server-process-history";

const MainRouter = () => {

    const PageLoader = () => {
        let loaderStyles = {
            position: "fixed",
            left: 0,
            top: 0,
            height: "100%",
            width: "100%",
            backgroundColor: "rgba(0,0,0,.5)",
        };
        return (
            <div
                className="page-loader d-flex align-items-center  text-white justify-content-center"
                style={loaderStyles}
            >
                <i className="fa fa-sync-alt fa-2x fa-spin"></i>
                <h2 className="ml-3 fs-18 text-white">Loading...</h2>
            </div>
        );
    };

    return (
        <Suspense fallback={<PageLoader />}>
            <Routes>
                <Route path="/">
                    <Route index element={<Home />} />
                    {/* <Route index element={<InvoiceSummary />} /> */}
                    <Route path="products">
                        <Route path="products" element={<Products />} />
                        <Route path="brands" element={<Brands />} />
                        <Route path="unit-of-measure" element={<UnitOfMeasure />} />
                        <Route path="categories" element={<Categories />} />
                        <Route path="product-distribution-channel" element={<Pdc />} />
                        <Route path="file-upload" element={<ProductFileUpload />} />
                        <Route path="pdc-file-upload" element={<PdcFileUpload />} />
                    </Route>
                    <Route path="suppliers-and-members">
                        <Route path="suppliers" element={<Suppliers />} />
                        <Route path="accounts" element={<SupplierMemberAccounts />} />
                        <Route path="aggregation-group" element={<AggregationGroup />} />
                        <Route path="members" element={<Members />} />
                        <Route path="purchasing-tiers" element={<MemberTiers />} />
                        <Route path="organisation-type" element={<OrganisationType />} />
                    </Route>
                    <Route path="suppliers-and-members/members">
                        <Route path="member-details" element={<MemberDetails />} />
                        <Route path="member-accounts" element={<MemberAccounts />} />
                        <Route path="member-outlets" element={<MemberOutlets />} />
                        <Route path="member-programmes" element={<MemberProgrammes />} />
                        <Route path="edit-member" element={<EditMember />} />
                    </Route>
                    <Route path="invoices">
                        <Route path="summary" element={<InvoiceSummary />} />
                        <Route path="reprocess" element={<InvoiceReprocess />} />
                        <Route path="edi-file-log" element={<EdiFileLog />} />
                        <Route path="failed-files" element={<FailedFiles />} />
                        <Route path="failed-imports" element={<FailedImports />} />
                        <Route path="manual-entry" element={<ManualEntryInvoice />} />
                    </Route>
                    <Route path="statement-cycles">
                        <Route path="statement-cycles" element={<StatementCycles />} />
                        <Route path="distribution-channel" element={<DistributionChannel />} />
                    </Route>
                    <Route path="schemes">
                        <Route path="discount-schemes" element={<DiscountSchemes />} />
                        <Route path="retrospective-discount-schemes-outlet-exclusions" element={<RetrospectiveDiscountSchemesOutletExclusions />} />
                        <Route path="retrospective-discount-schemes" element={<RetrospectiveDiscountSchemes />} />
                        <Route path="levy-schemes" element={<LevySchemes />} />
                        <Route path="file-upload" element={<RetrospectiveFileUpload />} />
                        <Route path="programmes" element={<Programmes />} />
                        <Route path="promotion-type" element={<PromotionType />} />
                    </Route>
                    <Route path="output">
                        <Route path="nussl-report" element={<NusslReport />} />
                        <Route path="supplier-reports" element={<SupplierReports />} />
                        <Route path="member-reports" element={<MemberReports />} />
                        <Route path="reports" element={<Reports />} />
                        <Route path="accounting-entries" element={<AccountingEntries />} />
                    </Route>
                    <Route path="management">
                        <Route path="standing-data" element={<StandingData />} />
                        <Route path="vat-codes" element={<VatCode />} />
                        <Route path="central-billing-schemes" element={<Cbs />} />
                        <Route path="process-manager" element={<ProcessManager />} />
                    </Route>
                    <Route path="logs">
                        <Route path="performance-logs" element={<PerformanceLogs />} />
                        <Route path="server-process-history" element={<ServerProcessHistory />} />
                    </Route>
                </Route>
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </Suspense>
    );
};

export default MainRouter;

