import { Box, Modal, Tabs, Tab, Button, Grid, TextField, FormControl, FormHelperText, FormLabel, IconButton, FormControlLabel, Paper, Checkbox, Autocomplete } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CancelIcon from '@mui/icons-material/Cancel';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { warningModal } from "../../../store/slices/modals";
import SnackBarDialog from "../../../components/shared/SnackBarDialog";
import { styled } from '@mui/material/styles';
import { categoryInput, setAutocomplete, getIsError, setcategoryLevel, setcategoryParent, resetCategory, categorBasicInput } from '../../../store/slices/categoriesData';
import axios from '../../../services/utility/axios';
import axios_categories from '../../../services/utility/axios-categories';
import { handleAddEditModal } from '../../../store/slices/modals';
import { filterFlagChange, filterResetFlagChange } from '../../../store/slices/externalFilter';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

export default function AddEditCategory({ values, onShow, onHide }) {

    const { handleWarning, category_fields } = useSelector((state) => {
        return {
            handleWarning: state.modalActions.closeWaringAddProduct,
            category_fields: state.categoriesData
        }
    });

    const [state, setState] = useState({
        active_member: false,
        loader: false,
        openNewProductModal: false,
        pageLoader: false,
        formValid: true,
        defaultmode: false
    });

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    });

    const dispatch = useDispatch();

    const handleInputChange = (event) => {
        setState({ ...state, defaultmode: true })
        const { value, name } = event.target;
        dispatch(categoryInput({
            key: name,
            value
        }))

    }

    const handleCheckboxChange = (event) => {
        setState({ ...state, defaultmode: true })
        const { checked, name } = event.target;
        dispatch(categorBasicInput({
            key: name,
            value: checked
        }))
    };


    const [categorylevel, setCategoryLevel] = useState({
        categorylevel_selected: category_fields.editCategory ? { id: category_fields.editCategoryLevel, name: category_fields.editCategoryLevelName } : null,
        categorylevel_options: [
            { id: 4, name: "Product Family (Great GP)" },
            { id: 3, name: "Product Class (GP)" },
            { id: 2, name: "Product Line (Parent)" },
            { id: 1, name: "Product Type (Category)" },

        ]
    });

    const [categoryparent, setCategoryParent] = useState({
        categoryparent_selected: category_fields.editCategory ? { id: category_fields.category_parent, name: category_fields.category_parentName } : null,
        categoryparent_options: []
    })

    useEffect(() => {

        if (category_fields.editCategory) {
            setState({ ...state, formValid: false });
        }

    }, []);

    useEffect(() => {
        const fetchMyAPI = async () => {
            if (category_fields.category_level === 4) {
                // console.log("level 4");
                // setcategoryParent({ ...categoryparent, categoryparent_selected: "" });
                dispatch(getIsError({ key: 'category_parent', value: false }));
            }
            if (category_fields.category_level !== 4 && category_fields.category_level !== null) {
                let level = category_fields.category_level;
                axios_categories.get(`/v2/categories/parent-dropdown?level=${level}`)
                    .then(function (response) {
                        // handle success
                        const { status, data } = response;
                        if (data) {
                            setCategoryParent({ ...categoryparent, categoryparent_options: data });
                            const unique = data.filter((value, index, self) => self.findIndex((m) => m.id === category_fields.category_parent) === index);
                            // dispatch(setAutocomplete({
                            //     parent: 'member_estate_group',
                            //     key: 'estate_group_options',
                            //     value: unique
                            // }));

                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    });
            }


        }
        fetchMyAPI();
    }, [category_fields.category_level])


    const handleSelection = (event, newValue, parentname, name) => {
        setState({ ...state, defaultmode: true })
        if (event.target.value !== "" || event.target.value !== null) {
            dispatch(setAutocomplete({
                parent: parentname,
                key: name,
                value: newValue
            }));
            dispatch(getIsError({ key: 'category_foodtype_selected', value: false }));
        }
        else {
            //  || state[parent][key]['name'] === null
            // dispatch(getIsError({ key: 'category_foodtype_selected', value: true }));
            dispatch(setAutocomplete({
                parent: parentname,
                key: name,
                value: null
            }));
        }

    }



    const handleClose = () => {
        dispatch(resetCategory());
        //setState({ ...state, defaultmode: false })
        // dispatch(warningModal({
        //     yesHide: !handleWarning
        // }))
        dispatch(filterFlagChange(true));
        dispatch(filterResetFlagChange(false));
        dispatch(handleAddEditModal({
            showAddEdit: false
        }));

    };

    const handleCancel = (event) => {
        //dispatch(resetCategory());
        setState({ ...state, defaultmode: false });
        dispatch(filterFlagChange(true));
        dispatch(filterResetFlagChange(false));
        dispatch(warningModal({
            yesHide: !handleWarning
        }));

    }

    useEffect(() => {
        const { category_name, category_level, category_parent, category_type, category_foodtype_selected } = category_fields.isError;
        console.log(category_name, category_level, category_parent, category_type, category_foodtype_selected);
        if (category_name === false && category_level === false && category_parent === false && category_type === false && category_foodtype_selected === false) {
            setState({ ...state, formValid: false, defaultmode: true });
        } else {
            setState({ ...state, formValid: true, defaultmode: false });
        }
    }, [category_fields.isError]);

    useEffect(() => {

        const { category_name, category_level, category_parent, category_foodtype } = category_fields;

        if (category_fields.editCategory !== true) {
            if (category_name === "" || category_level === null) {
                setState({ ...state, formValid: true, defaultmode: false });
            }
            else if (category_level === 4) {
                setState({ ...state, formValid: false, defaultmode: true })
            }
            else if ((category_level === 3 || category_level === 2 || category_level === 1) && category_parent === null) {
                setState({ ...state, formValid: true, defaultmode: false });
            }
            else if (category_level === 1 && category_foodtype.foodtype_selected === null) {
                setState({ ...state, formValid: true, defaultmode: false });
            }
            else {
                setState({ ...state, formValid: false, defaultmode: true })
            }
        }
        else {
            console.log("change fields: ", category_level, category_name, category_parent, category_foodtype, category_foodtype.foodtype_selected);
            if (category_level === 1 && category_foodtype.foodtype_selected === null) {
                setState({ ...state, formValid: true, defaultmode: false });
            }
            else {
                setState({ ...state, formValid: false, defaultmode: true })
            }
        }

    }, [category_fields.category_name, category_fields.category_level, category_fields.category_parent, category_fields.category_foodtype.foodtype_selected]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (category_fields.category_name === "" && category_fields.isError.category_name === false) {
            dispatch(getIsError({ key: 'category_name', value: true }))
        } else if ((category_fields.category_level === "" || category_fields.category_level === null) && category_fields.isError.category_level === false) {
            dispatch(getIsError({ key: 'category_level', value: true }))
        } else if (category_fields.category_parent === null && category_fields.isError.category_parent === false && category_fields.category_level !== 4) {
            dispatch(getIsError({ key: 'category_parent', value: true }))
        } else if (category_fields.category_foodtype.foodtype_selected === null && category_fields.isError.category_foodtype_selected === false && category_fields.category_level === 1) {
            dispatch(getIsError({ key: 'category_foodtype_selected', value: true }))
        } else {

            let categoryData = null;
            if (!state.formValid) {
                categoryData = {
                    "name": category_fields.category_name,
                    "active": category_fields.category_active,
                    "level": category_fields.category_level,
                    "parent": category_fields.category_parent,
                    "type": category_fields.category_foodtype.foodtype_selected ? category_fields.category_foodtype.foodtype_selected.id : null
                }
                try {
                    setState({ ...state, loader: true })
                    const { data, status } = category_fields.editCategory ? await axios_categories.put(`v2/categories/${category_fields.selected_category_id}`, { ...categoryData }) : await axios_categories.post(`v2/categories`, { ...categoryData });
                    if (data.success === true) {
                        if (category_fields.editCategory) {
                            setState({ ...state, loader: false, formValid: false });
                            setSnackBar({ ...snackBar, show: true, text: `Category updated successfully`, type: "success", });
                        }
                        else {
                            setState({ ...state, loader: false, formValid: true });
                            setSnackBar({ ...snackBar, show: true, text: `Category added successfully`, type: "success", });


                        }
                        setTimeout(() => {
                            dispatch(filterFlagChange(true));
                            dispatch(filterResetFlagChange(false));
                            dispatch(warningModal({
                                yesHide: false
                            }))
                            dispatch(handleAddEditModal({
                                showAddEdit: false
                            }));
                            // window.location.reload()

                        }, 2500);


                    }

                } catch (error) {
                    const { response: { data, status } } = error;
                    setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error", })
                    setState({ ...state, loader: false, formValid: false })
                }
            }
        }



    }

    return (
        <>
            <Modal
                open={onShow || false}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <Box component="div" className="overlay" id="addEditModal">
                    <div className="overlay-box medium-size" >
                        <div className="content">
                            <div className="overlayBody">
                                <div className="overlay-header text-center"> Category Hierarchy Details
                                    <CancelIcon className="closeBtn" onClick={handleClose} />
                                </div>
                                <div className="overlay-content">
                                    <form onSubmit={handleSubmit}>
                                        <Box sx={{ flexGrow: 1, paddingX: 2 }} >
                                            <Grid container columnSpacing={2} sx={{ paddingLeft: '20px', paddingRight: '20px' }}>
                                                <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: 4 }}>
                                                    <Item>
                                                        <Grid container alignItems="center">
                                                            <Grid item xs={12} md={2.5} className="formLabelCntr">
                                                                <Item>
                                                                    <FormLabel htmlFor="category_name" className="fontSemiBold mandatory">Name</FormLabel>
                                                                </Item>
                                                            </Grid>
                                                            <Grid item xs={12} md={9}>
                                                                <Item>
                                                                    <div className="input-group">
                                                                        <FormControl fullWidth>
                                                                            <TextField
                                                                                inputProps={{ maxLength: 200 }}
                                                                                size="small"
                                                                                id="category_name"
                                                                                aria-describedby="category_name_error"
                                                                                onChange={(e) => handleInputChange(e)}
                                                                                value={category_fields.category_name}
                                                                                error={category_fields.isError.category_name}
                                                                                name="category_name"

                                                                            />
                                                                            {category_fields.isError.category_name && (<FormHelperText className='errorHelperTxt' id="category_name_error">
                                                                                Please enter Category Name
                                                                            </FormHelperText>)}
                                                                        </FormControl>

                                                                    </div>
                                                                </Item>
                                                            </Grid>
                                                        </Grid>
                                                    </Item>
                                                </Grid>


                                            </Grid>

                                            <Grid container columnSpacing={2} sx={{ paddingLeft: '20px', paddingRight: '20px' }}>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <Item>
                                                        <Grid container alignItems="center">
                                                            <Grid item xs={12} md={12} sm={12} sx={{ marginBottom: 4 }}>
                                                                <Item>
                                                                    <Grid container alignItems="center">
                                                                        <Grid item xs={12} md={2.5} className="formLabelCntr">
                                                                            <Item>
                                                                                <FormLabel htmlFor="category_level" className="fontSemiBold mandatory">Level</FormLabel>
                                                                            </Item>
                                                                        </Grid>
                                                                        <Grid item xs={12} md={9}>
                                                                            <Item>

                                                                                <Autocomplete
                                                                                    value={categorylevel.categorylevel_selected ? categorylevel.categorylevel_selected : null}
                                                                                    disabled={category_fields.editCategory ? true : false}
                                                                                    id="category_level_search"
                                                                                    options={categorylevel.categorylevel_options}
                                                                                    getOptionLabel={(option) => option.name || ""}
                                                                                    componentsProps={{
                                                                                        paper: {
                                                                                            sx: {
                                                                                                fontSize: '0.85rem'
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                    onChange={(event, newValue) => {
                                                                                        setCategoryLevel({ ...categorylevel, categorylevel_selected: null });
                                                                                        if (event.target.value !== "" || event.target.value !== null) {
                                                                                            setCategoryLevel({ ...categorylevel, categorylevel_selected: newValue });
                                                                                            if (newValue === null) {
                                                                                                dispatch(setcategoryLevel({ category_level: null }));
                                                                                            }
                                                                                            else {
                                                                                                dispatch(setcategoryLevel({ category_level: newValue.id }));
                                                                                            }
                                                                                            dispatch(setAutocomplete({ parent: 'category_foodtype', key: 'foodtype_selected', value: null }))
                                                                                            dispatch(setcategoryParent({ category_parent: null }));
                                                                                            setCategoryParent({ ...categoryparent, categoryparent_selected: null, categoryparent_options: [] });
                                                                                            dispatch(setcategoryParent({ category_parent: null }));

                                                                                        }

                                                                                    }}
                                                                                    renderInput={(params) =>
                                                                                        <FormControl size={'small'} fullWidth>
                                                                                            <div className="input-group">
                                                                                                <TextField {...params}
                                                                                                    size="small"
                                                                                                    id="category_level"
                                                                                                    aria-describedby="category_level-error"
                                                                                                    error={category_fields.isError.category_level}
                                                                                                />
                                                                                                {category_fields.isError.category_level &&
                                                                                                    (<FormHelperText className='errorHelperTxt' id="category_foodtype-error"> Please select Category Level</FormHelperText>)}
                                                                                            </div>
                                                                                        </FormControl>
                                                                                    }
                                                                                />
                                                                            </Item>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Item>
                                                            </Grid>

                                                        </Grid>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                            <Grid container columnSpacing={2} sx={{ paddingLeft: '20px', paddingRight: '20px' }}>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <Item>
                                                        <Grid container alignItems="center">
                                                            <Grid item xs={12} md={12} sm={12} sx={{ marginBottom: 4 }}>
                                                                <Item>
                                                                    <Grid container alignItems="center">
                                                                        <Grid item xs={12} md={2.5} className="formLabelCntr">
                                                                            <Item>
                                                                                <FormLabel htmlFor="category_parent" className="fontSemiBold mandatory">Parent</FormLabel>
                                                                            </Item>
                                                                        </Grid>
                                                                        <Grid item xs={12} md={9}>
                                                                            <Item>

                                                                                <Autocomplete
                                                                                    value={categoryparent.categoryparent_selected ? categoryparent.categoryparent_selected : null}
                                                                                    disabled={category_fields.category_level === 4 ? true : (category_fields.editCategory &&
                                                                                        (category_fields.category_level === 3 || category_fields.category_level === 2)) ? true : false}
                                                                                    id="category_parent_search"
                                                                                    options={categoryparent.categoryparent_options}
                                                                                    getOptionLabel={(option) => option.name || ""}
                                                                                    componentsProps={{
                                                                                        paper: {
                                                                                            sx: {
                                                                                                fontSize: '0.85rem'
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                    onChange={(event, newValue) => {
                                                                                        setState({ ...state, defaultmode: true })
                                                                                        if (event.target.value !== "" || event.target.value !== null) {
                                                                                            setCategoryParent({ ...categoryparent, categoryparent_selected: newValue });
                                                                                            if (newValue === null) {
                                                                                                dispatch(setcategoryParent({ category_parent: null }))
                                                                                            }
                                                                                            else {
                                                                                                dispatch(setcategoryParent({ category_parent: newValue.id }))
                                                                                            }

                                                                                        }

                                                                                    }}
                                                                                    renderInput={(params) =>
                                                                                        <FormControl size={'small'} fullWidth>
                                                                                            <div className="input-group">
                                                                                                <TextField {...params}
                                                                                                    size="small"
                                                                                                    disabled={category_fields.category_level === 4 ? true : (category_fields.editCategory &&
                                                                                                        (category_fields.category_level === 3 || category_fields.category_level === 2)) ? true : false}
                                                                                                    id="category_parent"
                                                                                                    aria-describedby="category_level-error"
                                                                                                    error={category_fields.isError.category_parent}
                                                                                                />
                                                                                                {category_fields.isError.category_parent &&
                                                                                                    (<FormHelperText className='errorHelperTxt' id="category_parent-error"> Please select Category Parent</FormHelperText>)}
                                                                                            </div>
                                                                                        </FormControl>
                                                                                    }
                                                                                />
                                                                            </Item>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Item>
                                                            </Grid>

                                                        </Grid>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                            <Grid container columnSpacing={2} sx={{ paddingLeft: '20px', paddingRight: '20px' }}>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <Item>
                                                        <Grid container alignItems="center">
                                                            <Grid item xs={12} md={12} sm={12} sx={{ marginBottom: 4 }}>
                                                                <Item>
                                                                    <Grid container alignItems="center">
                                                                        <Grid item xs={12} md={2.5} className="formLabelCntr">
                                                                            <Item>
                                                                                <FormLabel htmlFor="category_foodtype" className="fontSemiBold">Food Type</FormLabel>
                                                                            </Item>
                                                                        </Grid>
                                                                        <Grid item xs={12} md={9}>
                                                                            <Item>

                                                                                <Autocomplete
                                                                                    value={category_fields.category_foodtype.foodtype_selected || (category_fields.editCategory ? category_fields.category_foodtype.foodtype_selected : null)}
                                                                                    disabled={category_fields.category_level === 1 ? false : true}
                                                                                    id="category_foodtype_search"
                                                                                    options={category_fields.category_foodtype.foodtype_options}
                                                                                    getOptionLabel={(option) => option.name || ""}
                                                                                    componentsProps={{
                                                                                        paper: {
                                                                                            sx: {
                                                                                                fontSize: '0.85rem'
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                    onChange={(event, newValue) => handleSelection(event, newValue, 'category_foodtype', 'foodtype_selected')}
                                                                                    renderInput={(params) =>
                                                                                        <FormControl size={'small'} fullWidth>
                                                                                            <div className="input-group">
                                                                                                <TextField {...params}
                                                                                                    size="small"
                                                                                                    id="category_foodtype"
                                                                                                    aria-describedby="category_foodtype-error"
                                                                                                    error={category_fields.isError.category_foodtype_selected}
                                                                                                />
                                                                                                {category_fields.isError.category_foodtype_selected &&
                                                                                                    (<FormHelperText className='errorHelperTxt' id="category_foodtype-error"> Please select a Food Type</FormHelperText>)}
                                                                                            </div>
                                                                                        </FormControl>
                                                                                    }

                                                                                />
                                                                            </Item>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Item>
                                                            </Grid>

                                                        </Grid>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                            <Grid container columnSpacing={2} sx={{ paddingLeft: '20px', paddingRight: '20px' }}>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <Item>
                                                        <Grid container alignItems="center">
                                                            <Grid item xs={12} md={12} sm={12} sx={{ marginBottom: 2 }}>
                                                                <Item>
                                                                    <Grid container alignItems="center">
                                                                        <Grid item xs={12} md={2.5} className="formLabelCntr">
                                                                            <Item>
                                                                                <FormLabel htmlFor="member_ooid" className="fontSemiBold">Active</FormLabel>
                                                                            </Item>
                                                                        </Grid>
                                                                        <Grid item xs={12} md={9}>
                                                                            <Item>
                                                                                <FormControlLabel
                                                                                    control={
                                                                                        <Checkbox size="medium"
                                                                                            id="category_active"
                                                                                            checked={category_fields.category_active}
                                                                                            onChange={(e) => handleCheckboxChange(e)}
                                                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                                                            name="category_active"
                                                                                        />}
                                                                                />
                                                                            </Item>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Item>
                                                            </Grid>

                                                        </Grid>
                                                    </Item>
                                                </Grid>
                                            </Grid>

                                            <Grid container sx={{ marginTop: '20px' }}>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <Item>
                                                        <Grid container alignItems="center">
                                                            <Grid item xs={12} md={1} className="formLabelCntr">
                                                                <Item>

                                                                </Item>
                                                            </Grid>
                                                            <Grid item xs={12} md={11}>
                                                                <Item>
                                                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                                                        <LoadingButton
                                                                            loading={state.loader}
                                                                            loadingPosition="center"
                                                                            endIcon=""
                                                                            variant="contained"
                                                                            type="submit" sx={{ marginRight: '15px' }}
                                                                            //disabled={state.formValid || state.defaultmode === false}
                                                                            // eslint-disable-next-line max-len
                                                                            disabled={category_fields.editCategory ? (category_fields.isError.category_name || category_fields.isError.category_level || category_fields.isError.category_parent) : (state.formValid || state.defaultmode === false)}
                                                                        >Save</LoadingButton>
                                                                        <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
                                                                    </Grid>


                                                                </Item>
                                                            </Grid>
                                                        </Grid>

                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        {snackBar.show && <SnackBarDialog
                                            onShow={snackBar.show}
                                            text={snackBar.text}
                                            type={snackBar.type}
                                            onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                                        />}
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}
