import { Checkbox, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl } from '@mui/material'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { warningModal, handleAddEditModal } from "../../../store/slices/modals";
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import moment from "moment";
import LoadingButton from '@mui/lab/LoadingButton';
import axiosRetroDiscountSchemes from '../../../services/utility/axios-retro-discount-schemes';
import { hasEdit, hasEditData, promotionTypeInput, setAutocomplete, getIsError, brandSearch, resetpromotionType } from "../../../store/slices/promotionType";
import SnackBarDialog from "../../../components/shared/SnackBarDialog";
import { filterFlagChange, filterResetFlagChange } from '../../../store/slices/externalFilter';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

const defaultValues = {
    loader: false,
    formValid: true,
    defaultmode: false,
    newaggregationgroupData: [],
};

function AddEditPromotionTypeDetail({ onShow, size = '', onHide }) {
    
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const [state, setState] = useState(defaultValues);
    const [selectedSupplier, setselectedSupplier] = useState(null);
    const [open, setOpen] = useState(onShow);
    const { handleWarning, promotionType_container } = useSelector((state) => {
        return {
            handleWarning: state.modalActions.closeWaringAddProduct,
            promotionType_container: state.promotionTypes
        }
    });

    const dispatch = useDispatch();

    const handleClose = () => {
        //setState({ ...state, defaultmode: false })
        dispatch(handleAddEditModal({
            showAddEdit: false
        }));
        dispatch(resetpromotionType())
    };

    const handleCancel = () => {
        setState({ ...state, defaultmode: false })
		dispatch(warningModal({
            yesHide: !handleWarning
        }))
    }

    const handleInputChange = (event) => {
        const { value, name } = event.target;
        dispatch(promotionTypeInput({
            key: name,
            value
        }))
        setState({ ...state, defaultmode: true })
    }

    useEffect(() => {
        if (promotionType_container.add_edit_promotion_type === null || promotionType_container.add_edit_promotion_type === "") {
            setState({ ...state, formValid: true, defaultmode: false })
        } else {
            setState({ ...state, formValid: false, defaultmode: true })
        }
    }, [promotionType_container.add_edit_promotion_type])

    useEffect(() => {
        const { 
            add_edit_promotion_type
        } = promotionType_container.isError
        if (!add_edit_promotion_type) {
            setState({ ...state, formValid: false })
        } else {
            setState({ ...state, formValid: true })
        }


    }, [promotionType_container.isError])

    const handleSubmit = async (event) => {
        event.preventDefault();
        let productData = null;
        if (!state.formValid) {
            productData = {
                "name": promotionType_container.add_edit_promotion_type,
                "label": promotionType_container.add_edit_promotion_type
            }
            try {
                setState({ ...state, loader: true })
                const { data, status } = promotionType_container.hasEditData ? await axiosRetroDiscountSchemes.put(`retrodiscount/promotionTypes/${promotionType_container.hasEditData.id}`, { ...productData }) : await axiosRetroDiscountSchemes.post(`retrodiscount/promotionTypes`, { ...productData });
                if (data.success === true && data.resultMap) {
                    setSnackBar({...snackBar, show:true, text: `Promotion Type ${promotionType_container.hasEditData ? 'Edited': 'Added'} Successfully!`, type: "success", })
                    setState({ ...state, loader: false, formValid: true, newaggregationgroupData: data.resultMap})
                    setTimeout(() => {
                        //handleClose()
                        // window.location.reload();
                        dispatch(handleAddEditModal({
                            showAddEdit: false
                        }));
                        dispatch(filterFlagChange(true));
                        dispatch(filterResetFlagChange(false));
                    }, 1500);
                }

            } catch (error) {
                const { response: { data, status } } = error;
                setState({ ...state, loader: false })
                setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error", })
            }

        }
    };

    
    return (
        <>
            { snackBar.show && <SnackBarDialog 
            onShow={snackBar.show} 
            text={snackBar.text} 
            type={snackBar.type} 
            onHide={(e) => {setSnackBar({...snackBar, show: e})}}
            />}

            <Modal
                open={open}
                aria-labelledby="add-edit-price-title"
                aria-describedby="add-edit-price-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Box component="div" className="overlay" id="addEditModal">
                        <div className={`overlay-box ${size ? size : ''}`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-header text-center"> Promotion Type Details
                                        <CancelIcon className="closeBtn" onClick={handleClose} />
                                    </div>
                                    <div className="overlay-content">
                                        <Grid item xs={12} md={12}>
                                        <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="add_edit_promotion_type" className="fontSemiBold mandatory">Name</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <Box component="div" className=''>
                                                                <FormControl size="small">
                                                                    <TextField
                                                                        inputProps={{ maxLength: 50 }}
                                                                        type="text"
                                                                        size="small"
                                                                        id="add_edit_promotion_type"
                                                                        aria-describedby="add_edit_promotion_type_error"
                                                                        onChange={(e) => handleInputChange(e)}
                                                                        value={promotionType_container.add_edit_promotion_type}
                                                                        error={promotionType_container.isError.add_edit_promotion_type}
                                                                        min={1}
                                                                        name="add_edit_promotion_type"
                                                                        //onBlur={(e) => checkQunique(e, 'isPromotionTypValid?name')}
                                                                    />
                                                                    {promotionType_container.isError.add_edit_promotion_type && (<FormHelperText className='errorHelperTxt' id="add_edit_promotion_type_error">This field is mandatory</FormHelperText>)}
                                                                </FormControl>
                                                            </Box>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item> 
                                        </Grid>
                                        <Grid container sx={{ marginTop: '20px' }}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Item>
                                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                                        <LoadingButton
                                                                loading={state.loader}
                                                                loadingPosition="center"
                                                                endIcon=""
                                                                variant="contained"
                                                                type="submit" sx={{ marginRight: '15px' }}
                                                                //disabled={state.formValid || state.defaultmode === false}
                                                                disabled={promotionType_container.hasEditData ? (promotionType_container.isError.add_edit_promotion_type) : (state.formValid || state.defaultmode === false)}
                                                            >Save</LoadingButton>
                                                        <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </form>
            </Modal>
        </>
    );
}

export default AddEditPromotionTypeDetail;