import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editTab: true,
    hasEditVC: null,
    editData: null,
    hasEditData: null,
    suppliervatcodename: '',
    editSupplierVatCode: null,
    supplier_gen_name: '',
    supplier_vatCode: [],
    all_supplier: [],
    supplier_gen_poNumberLevyBill: '',
    supplier_gen_ananumber: '',
    supplier_gen_poNumberDiscountBill: '',
    supplier_gen_poNumberRetroBill: '',
    supplier_gen_levyBillDetail: true,
    supplier_gen_discountBillDetail: true,
    supplier_gen_checkPrices: true,
    supplier_gen_central_billing_id: '',
    supplier_gen_orwellCompanyId: '',
    supplier_address_address1: '',
    supplier_address_town: '',
    supplier_address_city: '',
    supplier_address_country: '',
    supplier_address_postCode: '',
    supplier_ledger_remittanceLedgerCode: '',
    supplier_ledger_retroLedgerCode: '',
    supplier_ledger_levyLedgerCode: '',
    supplier_ledger_discountLedgerCode: '',
    supplier_gen_defaultEmailContact: '',
    supplier_contact_remittanceEmailContact: '',
    supplier_contact_retroEmailContact: '',
    supplier_contact_levyEmailContact: '',
    supplier_contact_discountEmailContact: '',
    supplier_gen_active_untill: null,
    latestVatCode: null,
    supplier_gen_central_bil_scheme: {
        supplier_gen_central_bil_scheme_options: [],
        supplier_gen_central_bil_scheme_selected: null
    },
    supplier_gen_defaultDistributionChannel: {
        supplier_gen_defaultDistributionChannel_options: [],
        supplier_gen_defaultDistributionChannel_selected: null
    },
    supplier_vat_code: {
        vat_code_options: [],
        vat_code_selected: null
    },
    deleteList: null,
    supplier_StartDelete: false,
    supplier_ConfirmDelete: false,
    supplierVatCodeokDelete: false,
    supplierVatCodeConfirmDelete: false,
    isError: {
        suppliervatcodename: false,
        vat_code_selected: false,
        supplier_gen_defaultDistributionChannel_selected: false,
        supplier_gen_name: false,
        supplier_gen_central_billing_id: false,
        supplier_gen_orwellCompanyId: false,
        supplier_address_address1: false,
        supplier_address_town: false,
        supplier_address_city: false,
        supplier_address_postCode: false,
        supplier_ledger_remittanceLedgerCode: false,
        supplier_ledger_retroLedgerCode: false,
        supplier_ledger_levyLedgerCode: false,
        supplier_ledger_discountLedgerCode: false,
        supplier_gen_defaultEmailContact: false,
        supplier_contact_remittanceEmailContact: false,
        supplier_contact_retroEmailContact: false,
        supplier_contact_levyEmailContact: false,
        supplier_contact_discountEmailContact: false,
        supplier_gen_central_bil_scheme_selected: false
      }
   
}

const supplierProduct = createSlice({
    name: 'supplier_product',
    initialState,
    reducers: {
        brandSearch: (state, action) => {
            const { parent, key, value } = action.payload;
            state[parent][key] = value;
            (state[parent][key] === null || state[parent][key] === "") ? state.isError[key] = true : state.isError[key] = false
        },
        supplierProductInput: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value;
            state[key].length === 0 ? state.isError[key] = true : state.isError[key] = false
        },
        getIsError: (state, action) => {
            const { key, value } = action.payload;
            state.isError[key] = value
        },
        hasEdit: (state, action) => {
            const { editTab } =  action.payload;
            state.editTab = editTab
        },
        hasEditData: (state, action) => {
            const { editData } =  action.payload;
            state.editData = editData
        },
        setHasEditData: (state, action) => {
            state.hasEditData = action.payload;
        },
        setAutocomplete: (state, action) => {
            const { parent, key, value } =  action.payload;
            state[parent][key] = value
        },
        activeUntillEffectiveDate: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value;
          },
          setVatCode: (state, action) => {
            state.supplier_vatCode.push(action.payload)
          },
          setVatCodeList: (state, action) => {
            state.supplier_vatCode = action.payload
          },
          setAllSupplier: (state, action) => {
            state.all_supplier.push(action.payload)
          },
          setAllSupplierList: (state, action) => {
            state.all_supplier = action.payload
          },
          setSupplierVatCodeInput: (state, action) => {
            state.suppliervatcodename = action.payload;
            state.suppliervatcodename.length === 0 ? state.isError.suppliervatcodename = true : state.isError.suppliervatcodename = false
          },
          setEditSupplierVatCode: (state, action) => {
            state.editSupplierVatCode = action.payload
          },
          setLatestVatCode: (state, action) => {
            state.latestVatCode = action.payload
          },
          setDeleteList: (state, action) => {
            state.deleteList = action.payload
          },
          setSupplierDeleteData: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value;
          },
        resetSupplier: () => {
            return initialState
        },
    }
})

export const { brandSearch, supplierProductInput, resetSupplier, setAutocomplete, getIsError, 
    hasEdit, hasEditData, setHasEditData, activeUntillEffectiveDate, 
    setVatCode, setVatCodeList, setSupplierVatCodeInput, setLatestVatCode, 
    setEditSupplierVatCode, setDeleteList, setSupplierDeleteData, setAllSupplierList, setAllSupplier} = supplierProduct.actions

export default supplierProduct.reducer