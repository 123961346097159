import { Checkbox, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl } from '@mui/material'
import { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch, useSelector } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import moment from "moment"
import axios from '../../../../../services/utility/axios';
import { setEanCode, setEanCodeInput, setEanCodeList, setEditEanCode, getIsError } from "../../../../../store/slices/generalProduct";
import SnackBarDialog from '../../../../../components/shared/SnackBarDialog';

const Item = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "none"
}));

const defaultValues = {
    defaultmode: false
};

function AddEditEanCode({ onShow, size = '', onHide }) {
    const [state, setState] = useState(defaultValues);
    const [loader, setLoader] = useState(false)
    const [open, setOpen] = useState(onShow);
    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })
    const { general_product_fields, getEanCode, eanInput, editTabData, editEanCode, hasEditData } = useSelector((state) => {
        return {
            general_product_fields: state.generalProduct,
            getEanCode: state.generalProduct.product_eancode,
            eanInput: state.generalProduct.eancodename,
            editTabData: state.generalProduct.editTabData,
            editEanCode: state.generalProduct.editEanCode,
            hasEditData: state.generalProduct.hasEditData
        }
    });

    const dispatch = useDispatch();

    const handleClose = () => {
        //setState({ ...state, defaultmode: false })
        if (onHide) {
            onHide(false)
            dispatch(setEanCodeInput(''))
            dispatch(setEditEanCode(null))
            dispatch(getIsError({ key: 'eancodename', value: false }))
        }
    };

    const handleInputChange = (event) => {
        setState({ ...state, defaultmode: true })
        const { value } = event.target;
        dispatch(setEanCodeInput(value))
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const eanCode = {
            "productId": editTabData ? editTabData.productId : hasEditData.id,
            "eanCode": eanInput,
            "createdDate": moment().format('DD/MM/YYYY')
        }
        if (eanInput !== '') {
            try {
                if ((editTabData || hasEditData) && !editEanCode) {
                    setLoader(true)
                    const id = editTabData ? editTabData.productId : hasEditData.id;
                    const { data } = await axios.post(`products/${id}/additionalEAN`, eanCode);
                    if (data.success) {
                        //dispatch(setEanCode({ ...eanCode, "id": getEanCode.length }))
                        setSnackBar({ ...snackBar, show: true, text: data.message, type: "success" })
                        const response = await axios.get(`products/${id}/additionalEAN`);
                        if (response.data !== null || response.data !== undefined) {
                            dispatch(setEanCodeList(response.data))
                            setTimeout(() => {
                                handleClose()
                            }, 1500);
                        }
                    }
                } else if (editEanCode) {
                        setLoader(true)
                        const { data } = await axios.put(`products/${editEanCode.productId}/additionalEAN/${editEanCode.id}`, eanCode);
                        if (data.success) {
                            setSnackBar({ ...snackBar, show: true, text: data.message, type: "success"})
                            const response = await axios.get(`products/${editEanCode.productId}/additionalEAN`);
                            if (response.data !== null || response.data !== undefined) {
                                dispatch(setEanCodeList(response.data))
                                setTimeout(() => {
                                    handleClose()
                                }, 1500);
                            }
                        }
                }
                
            } catch (error) {

                const { response: { data, status } } = error;

                if (!data) {
                    setSnackBar({ ...snackBar, show: true, text: error.message, type: "error" })
                } else {
                    setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" })
                }
                setLoader(false)
                setTimeout(() => {
                    handleClose()
                }, 1500);
            }
        }
    }

    return (
        <>
            <Modal
                open={open}
                aria-labelledby="add-edit-price-title"
                aria-describedby="add-edit-price-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Box component="div" className="overlay" id="addEditModal">
                        <div className={`overlay-box ${size ? size : ''}`}>
                            <div className="content">
                                <div className="overlayBody">
                                    <div className="overlay-header text-center"> Additional EAN Code
                                        <CancelIcon className="closeBtn" onClick={handleClose} />
                                    </div>
                                    <div className="overlay-content">
                                        <Grid item xs={12} md={12}>

                                            <Item sx={{ marginBottom: 2.5 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={4.5} className="formLabelCntr">
                                                        <Item>
                                                            <FormLabel htmlFor="eancodename" className="fontSemiBold mandatory">EAN Code</FormLabel>
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={12} md={7.5}>
                                                        <Item>
                                                            <FormControl>
                                                                <TextField
                                                                    size="small"
                                                                    id="eancodename"
                                                                    aria-describedby="eancodename"
                                                                    onChange={(e) => handleInputChange(e)}
                                                                    value={eanInput}
                                                                    name="eancodename"
                                                                    error={general_product_fields.isError.eancodename}
                                                                />
                                                                {general_product_fields.isError.eancodename && (<FormHelperText className='errorHelperTxt' id="eancodename_error">This field is mandatory</FormHelperText>)}
                                                            </FormControl>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </Item>

                                        </Grid>
                                        <Grid container sx={{ marginTop: '20px' }}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Item>
                                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                                        <LoadingButton
                                                            loading={loader}
                                                            loadingPosition="center"
                                                            endIcon=""
                                                            variant="contained"
                                                            type="submit" sx={{ marginRight: '15px' }}
                                                            //disabled={(!(eanInput && editTabData || eanInput && hasEditData)) || (state.defaultmode === false)}
                                                            disabled={((editTabData || hasEditData) && editEanCode) ? general_product_fields.isError.eancodename : (state.defaultmode === false)}
                                                        >Save</LoadingButton>
                                                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                                                    </Grid>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                    {snackBar.show && <SnackBarDialog
                        onShow={snackBar.show}
                        text={snackBar.text}
                        type={snackBar.type}
                        onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                    />}
                </form>

            </Modal>

        </>
    );
}

export default AddEditEanCode;