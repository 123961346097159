import { Checkbox, Box, Modal, Button, Grid, Paper, FormLabel, TextField, FormHelperText, FormControlLabel, FormControl } from '@mui/material'
import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { InputText } from "../../../components/shared/form/InputText";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from 'react-redux';
import { warningModal, handleAddEditRetroInterimModal } from "../../../store/slices/modals";
import { TableFilters } from "../../../components/shared/data-table/TableFilters";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";
import PageLoader from "../../../components/shared/PageLoader";
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import { setRowPage, resetDataGrid, setPageNo } from '../../../store/slices/dataGrid';
import { DataTableProcessManager } from "../../../components/shared/data-table/DataTableProcessManager";
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange, SetRetroIntermiFromDate, filterResetFlagChange } from "../../../store/slices/externalFilter";
import axios_process_manager from '../../../services/utility/axios-process-manager';
import { setAutocomplete, brandSearch, getIsError, setprocessManagerEffectiveDate, processManagerInput } from "../../../store/slices/processManager";
import SnackBarDialog from "../../../components/shared/SnackBarDialog";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

const IconMenuLink = ({ params, startRetroData, reRunRetroData, startConfirm }) => {
    const { row } = params;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { processManager_container, setAddEditDiscountScheme, handleWarning, showAddEdiRetroFinalModal } = useSelector((state) => {
        return {
            processManager_container: state.processManagers,
            setAddEditDiscountScheme: state.modalActions.hideAddEditdiscountScheme,
            handleWarning: state.modalActions.closeWaringAddProduct,
            showAddEdiRetroFinalModal: state.modalActions.showAddEdiRetroFinalModal
        }
    });
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'start') {
            startRetroData(row)
        } else if (action === 're-run') {
            reRunRetroData(row)
        } else if (action === 'confirm') {
            startConfirm(row)
        }
        setAnchorEl(null);
    };

    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            {!row.runDate && (<MenuItem onClick={(e) => handleClose(e, 'start')}>Start</MenuItem>)}
            {<MenuItem onClick={(e) => handleClose(e, 're-run')}>Re-run</MenuItem>}
            {(row.runDate && !row.confirmed) && (<MenuItem onClick={(e) => handleClose(e, 'confirm')}>Confirm</MenuItem>)}
        </Menu>
    </>)
}

const defaultValues = {
    loader: false,
    defaultmode: false,
    startConfirm: false,
    startConfirmData: null,
    startConfirmModal: false,
    defaultGrid: {
        productActionButton: true,
        addEditButton: true,
        apiUrl: 'processmanager/retroinetrim',
        searchUrl: 'processmanager/retroinetrim/filter'
    }
};

function RetroInterimModal({ onShow, size = '', onHide }) {

    const columns = [
        {
            headerName: "ID",
            field: "id",
            flex: 1,
            minWidth: 100,
            hide: true
        },
        {
            headerName: "Due Date",
            field: "dueDate",
            width: 120
        },
        {
            headerName: "Scheme",
            field: "retroDiscountSchemeName",
            width: 300,
            flex: 1
        },
        {
            headerName: "Description",
            field: "description",
            width: 250
        },
        {
            headerName: "Run Date",
            field: "runDate",
            width: 120
        },
        {
            headerName: "Confirmed",
            field: "confirmed",
            width: 100,
            renderCell: (params) => (
                <Checkbox
                    checked={params.value === true}
                    icon={<CircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="chk_rounded"
                    size='small'
                    style={{ pointerEvents: 'none' }}
                />
            ),
            align: "center",
            headerAlign: "center"
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                if(params.row.runDate && params.row.confirmed) {
                    return ""
                }
                return <IconMenuLink params={params} startRetroData={retroInterimStartExecute} reRunRetroData={retroInterimRerunExecute} startConfirm={startConfirm} />;
            },
        }
    ];


    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const [state, setState] = useState(defaultValues);
    const [open, setOpen] = useState(onShow);
    const { handleWarning, processManager_container, fromDate, retroInterim_search_name } = useSelector((state) => {
        return {
            handleWarning: state.modalActions.closeWaringAddProduct,
            processManager_container: state.processManagers,
            fromDate: state.externalFilter.retroInterim_from_date,
            retroInterim_search_name: state.externalFilter.retroInterim_search_name
        }
    });

    const dispatch = useDispatch();

    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const uname = accounts[0] && accounts[0].name;

    const handleClose = () => {
        //setState({ ...state, defaultmode: false })
        // dispatch(warningModal({
        //     yesHide: !handleWarning
        // }))
        dispatch(handleAddEditRetroInterimModal({
            showAddEdiRetroInterimModal: false
        }));
        setTimeout(() => {
            window.location.reload();
          }, 1000);
    };

    const handleFilterInput = (value, id) => {
        //setState({ ...state, [id]: value });
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }));
    };

    const handleStartDate = (event) => {
        const sDate = (event === null) ? null : moment(event, 'DD/MM/YYYY');
        dispatch(SetRetroIntermiFromDate(sDate))
    };

    const filterBtn = () => {
        dispatch(resetDataGrid());
        var obj_filter = {};

        if (fromDate !== null && retroInterim_search_name !== '') {
            obj_filter = {
                "retroDiscountSchemeName": retroInterim_search_name,
                "dueDate": moment(fromDate).format('DD/MM/YYYY')
            }

        }
        if (retroInterim_search_name !== '' && fromDate === null) {
            obj_filter = {
                "retroDiscountSchemeName": retroInterim_search_name
            }

        }
        if (retroInterim_search_name === '' && fromDate !== null) {
            obj_filter = {
                "dueDate": moment(fromDate).format('DD/MM/YYYY')
            }

        }

        if (fromDate !== "" && fromDate !== null && moment(fromDate).format('DD/MM/YYYY') === 'Invalid date') {
            setSnackBar({ ...snackBar, show: true, text: "Enter date in valid format DD/MM/YYYY and try again!", type: "error", });
        }
        else {
            dispatch(applyFilter({
                key: 'filterList',
                value: obj_filter
            }))
            dispatch(filterFlagChange(true));
            dispatch(filterResetFlagChange(false));
            dispatch(setRowPage({ pageData: 10 }));
            dispatch(setPageNo({ page: 1 }));
        }



    }
    const resetFilter = () => {
        if ((fromDate || retroInterim_search_name) !== '') {
            dispatch(resetExternalFilter())
            dispatch(resetDataGrid());
        }
        dispatch(filterResetFlagChange(true));
    }

    const retroInterimStartExecute = async (row) => {
        try {
            let executableData = null;
            executableData = {
                "executable": "RetroInterimExecutable",
                "user": uname,
                "processExecutionDTO": {
                    "retroPayMemberDateId": row.id,
                    "rerun": false,
                    "confirm": false
                }
            }
            setState({ ...state, loader: true })
            const { data } = await axios_process_manager.post(`sqs/processmanager`, { ...executableData });
            if (data.isSuccess === true) {
                setState({ ...state, loader: false })
                setSnackBar({ ...snackBar, show: true, text: data.message, type: "success", })
                setTimeout(() => {
                    // window.location.reload();
                    dispatch(filterFlagChange(true));
                    dispatch(filterResetFlagChange(false));
                }, 1500);
            }
        } catch (error) {
            setState({ ...state, loader: false })
            const { response: { data, status } } = error;
            if (status === 500 && data.errorType === "Business") {
                setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" });
            } else if (status === 500 && data.errorType === null) {
                setSnackBar({ ...snackBar, show: true, text: data.message, type: "error", })
            } else {
                setSnackBar({ ...snackBar, show: true, text: "Something went wrong!", type: "error", })
            }
        }

    }

    const retroInterimRerunExecute = async (row) => {
        try {
            let executableData = null;
            executableData = {
                "executable": "RetroInterimExecutable",
                "user": uname,
                "processExecutionDTO": {
                    "retroPayMemberDateId": row.id,
                    "rerun": true,
                    "confirm": false
                }
            }
            setState({ ...state, loader: true })
            const { data } = await axios_process_manager.post(`sqs/processmanager`, { ...executableData });
            if (data.isSuccess === true) {
                setState({ ...state, loader: false })
                setSnackBar({ ...snackBar, show: true, text: data.message, type: "success", })
                setTimeout(() => {
                    // window.location.reload();
                    dispatch(filterFlagChange(true));
                    dispatch(filterResetFlagChange(false));
                }, 1500);
            }
        } catch (error) {
            setState({ ...state, loader: false })
            const { response: { data, status } } = error;
            setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error", })
        }

    }

    const startConfirm = (params) => {
        setState({ ...state, startConfirm: false, startConfirmModal: true, startConfirmData: params })
    }
    const handleOtherTabsClose = () => {
        setState({ ...state, startConfirmModal: false, startConfirmData: null, startConfirm: false })
    }
    const handleConfirm = async () => {
        try {
            let executableData = null;
            executableData = {
                "executable": "RetroInterimExecutable",
                "user": uname,
                "processExecutionDTO": {
                    "retroPayMemberDateId": state.startConfirmData.id,
                    "rerun": false,
                    "confirm": true,
                }
            }
            setState({ ...state, loader: true, startConfirmModal: false })
            const { data } = await axios_process_manager.post(`sqs/processmanager`, { ...executableData });
            if (data.isSuccess === true) {
                setState({ ...state, loader: false, startConfirmModal: false })
                setSnackBar({ ...snackBar, show: true, text: data.message, type: "success", })
                setTimeout(() => {
                    // window.location.reload();
                    dispatch(filterFlagChange(true));
                    dispatch(filterResetFlagChange(false));
                }, 1500);
            }
        } catch (error) {
            setState({ ...state, loader: false })
            const { response: { data, status } } = error;
            setSnackBar({ ...snackBar, show: true, text: data ? data.message : "Something went wrong!", type: "error", })
        }

        
    }

    return (
        <>
            {snackBar.show && <SnackBarDialog
                onShow={snackBar.show}
                text={snackBar.text}
                type={snackBar.type}
                onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
            />}

            <Modal
                open={open}
                aria-labelledby="add-edit-price-title"
                aria-describedby="add-edit-price-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
            >
                <Box component="div" className="overlay" id="addEditModal">
                    {state.loader && <PageLoader />}
                    <div className={`overlay-box ${size ? size : ''}`}>
                        <div className="content">
                            <div className="overlayBody">
                                <div className="overlay-header text-center"> Start Retro Interim Executable
                                    <CancelIcon className="closeBtn" onClick={handleClose} />
                                </div>
                                <div className="overlay-content">
                                    <Box sx={{ marginBottom: 5 }}>
                                        <TableFilters
                                            filterFields={<>
                                                <Box component="div"><InputText onChange={handleFilterInput} maxWidth={50} label="Name" id="retroInterim_search_name" size="small" /></Box>
                                                <Box component="div">
                                                    <FormLabel htmlFor="price_start_date" className="fontBold">Due Date</FormLabel>
                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                        <DesktopDatePicker
                                                            value={fromDate}
                                                            onChange={(e) => { handleStartDate(e) }}
                                                            name="retroInterim_from_date" label="From Date"
                                                            inputFormat="DD/MM/YYYY"
                                                            // eslint-disable-next-line max-len
                                                            renderInput={({ inputRef, inputProps, InputProps }) => (
                                                                <Box component="div" sx={{ paddingTop: '6px' }} className='date-picker-input'>
                                                                    <FormControl size="small" >
                                                                        <TextField size="small" id="retroInterim_from_date"
                                                                            ref={inputRef} {...inputProps}
                                                                            placeholder=""
                                                                            aria-describedby="retroInterim_from_date_error"
                                                                            autoComplete="off"
                                                                        />
                                                                        <Box className='date-picker-icon'>
                                                                            {InputProps?.endAdornment}
                                                                        </Box>
                                                                    </FormControl>
                                                                </Box>
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </Box>
                                            </>}
                                            filterCheckboxes={<>

                                            </>}
                                            filterBtns={
                                                <>
                                                    <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                                    <Button variant="contained" onClick={filterBtn}>Apply</Button>
                                                </>}>
                                        </TableFilters>
                                    </Box>
                                    <DataTableProcessManager defaultGrid={state.defaultGrid} columns={columns}>
                                    </DataTableProcessManager>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={state.startConfirmModal}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                keepMounted
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleOtherTabsClose();
                    }
                }}
            >
                <Box component="div" className="overlay" id="addEditModal">
                    <div className={`overlay-box small_overlay`}>
                        <div className="content">
                            <div className="overlayBody">
                                <div className="overlay-content">
                                    <Box component="div" sx={{ textAlign: 'center', padding: 1.5 }}>
                                        <p className="fontMedium">Are you sure you want to confirm the interim payment?</p>
                                        <Grid sx={{ paddingTop: 2 }} container direction="row" alignItems="center" justifyContent="center">
                                            <Button variant="contained" type="submit" sx={{ marginRight: '15px' }} onClick={handleConfirm}>Yes</Button>
                                            <Button variant="outlined" onClick={handleOtherTabsClose}>No</Button>
                                        </Grid>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}

export default RetroInterimModal;