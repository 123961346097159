import { Container, Box, Modal, Button, Grid, Checkbox, Autocomplete, FormControl, FormLabel, TextField } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TableFilters } from "../../components/shared/data-table/TableFilters";
import { useEffect, useState } from "react";
import { InputText } from "../../components/shared/form/InputText";
import { setFilter, applyFilter, resetExternalFilter, filterFlagChange, filterResetFlagChange } from "../../store/slices/externalFilter";
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import BreadcrumbNavs from "../../components/shared/breadcrumbs/Breadcrumbs";
import AddIcon from '@mui/icons-material/Add';
import { warningModal, setNewBrandModal } from "../../store/slices/modals";
import WarningModal from "../../components/shared/modals/WarningModal";
import { setRowPage, resetDataGrid, setPageNo } from '../../store/slices/dataGrid'
import { AutoCompleteStatic } from "../../components/shared/form/AutoCompleteStatic";
import AddEditBrand from "./addEditBrand/AddEditBrand";
import { useLayoutEffect } from "react";
import { brandInput, setNewBrandData, setBrandDateList, hasBrandOwner } from "../../store/slices/brandProduct";
import PageLoader from "../../components/shared/PageLoader";
import SnackBarDialog from "../../components/shared/SnackBarDialog";
import axios_brand from '../../services/utility/axios-brand';
import { mieSearch } from "../../store/slices/manualInvoice";
import { DataTableBrand } from "../../components/shared/data-table/DataTableBrand";

const IconMenuLink = ({ params, onDelete, sendSnackBar, startLoading }) => {
    const { row } = params;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const { setAddEditBrand } = useSelector((state) => {
        return {
            setAddEditBrand: state.modalActions.hideAddEditBrand
        }
    });
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, action) => {
        event.preventDefault();
        if (action === 'edit') {
            editBrandOwner(row)
        }
        setAnchorEl(null);
    };

    const editBrandOwner = async (row) => {
        dispatch(setNewBrandData(row))
        dispatch(brandInput({
            key: "brand_name",
            value: row.name
        }))
        dispatch(setNewBrandModal({
            showAddEdit: !setAddEditBrand
        }))
        // try {
        //     if (startLoading) {
        //         startLoading(true)
        //     }
        //     const { data } = await axios_product.get(`brands/${row.id}/brandOwner`);
        //     if (data) {
        //         dispatch(setBrandDateList([]))
        //         if (startLoading) {
        //             startLoading(false)
        //         }
        //         dispatch(setNewBrandModal({
        //             showAddEdit: !setAddEditBrand
        //         }))
        //         dispatch(hasBrandOwner(true))
        //         let newBx = data;
        //         newBx.forEach((x,index) => {
        //             if (x.id) {
        //                 x.id = x.id + index
        //             }
        //         });
        //         //const unique = data.map((value, index, self) => self.findIndex((m) => m.id === value.id) === index);
        //         dispatch(setBrandDateList(newBx))
        //     }
        // }
        // catch (error) {
        //     if (sendSnackBar) {
        //         sendSnackBar(error)
        //         dispatch(brandInput({
        //             key: "brand_name",
        //             value: row.name
        //         }))
        //     }
        //     if (startLoading) {
        //         startLoading(false)
        //     }
        // }
    }

    return (<>
        <IconButton
            aria-label="more"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            id={row.id + 1}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={row.id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            className="actionMenuBx"
        >
            <MenuItem onClick={(e) => handleClose(e, 'edit')}>Edit</MenuItem>
        </Menu>

    </>)
}


function Brands() {
    const [state, setState] = useState({
        openBrandModal: false,
        pageLoader: false,
        defaultGrid: {
            pagetitle: "Brands",
            brandActionButton: true,
            hasExport: true,
            addEditButton: true,
            apiUrl: 'brands/filter',
            searchUrl: 'brands/filter'
        }
    });

    const [snackBar, setSnackBar] = useState({
        text: "",
        type: "",
        show: false,
    })

    const columns = [
        {
            headerName: "ID",
            field: "id",
            flex: 1,
            minWidth: 200,
            hide: true
        },
        {
            headerName: "Brand ID",
            field: "brandId",
            flex: 1,
            minWidth: 200,
        },
        {
            headerName: "Name",
            field: "name",
            flex: 1,
            width: 170
        },
        {
            headerName: "Current Brand Owner",
            field: "brandOwner",
            flex: 1,
            width: 180
        },
        {
            headerName: "Action",
            field: "action",
            sortable: false,
            width: 90,
            renderCell: (params) => {
                return <IconMenuLink params={params} sendSnackBar={sendSnackBar} startLoading={pageLoader} />;
            },
        }
    ];
    const { addProductwarning, setAddEditBrand } = useSelector((state) => {
        return {
            addProductwarning: state.modalActions.closeWaringAddProduct,
            setAddEditBrand: state.modalActions.hideAddEditBrand
        }
    });

    const dispatch = useDispatch();

    const handleFilterInput = (value, id) => {
        setState({ ...state, [id]: value });
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    };

    const selectedAutoComplete = (value, id) => {
        dispatch(setFilter({
            key: id,
            value: value ? value : ''
        }))
    }

    // eslint-disable-next-line max-len
    const { brand_name, brand_owner_name, manualInvoiceEntry } = useSelector((state) => {
        return {
            brand_name: state.externalFilter.brand_name,
            brand_owner_name: state.externalFilter.brand_owner_name,
            manualInvoiceEntry: state
        }
    });
    const filterBtn = () => {
        dispatch(resetDataGrid());
        dispatch(applyFilter({
            key: 'filterList',
            value: {
                "name": brand_name,
                "brandOwnerId": brand_owner_name.id
            }
        }))
        dispatch(filterFlagChange(true));
        dispatch(filterResetFlagChange(false));       
        dispatch(setRowPage({ pageData: 10 }));
        dispatch(setPageNo({ page: 1 }));
    }
    const resetFilter = () => {
        if ((brand_name || brand_owner_name) !== '') {
            dispatch(resetExternalFilter())
            dispatch(resetDataGrid());
        }
        dispatch(filterResetFlagChange(true));
    }
    const addBrandModal = () => {
        dispatch(setNewBrandModal({
            showAddEdit: !setAddEditBrand
        }))
    }
    const handleBrandModal = (event) => {
        dispatch(setNewBrandModal({
            showAddEdit: event
        }))
    }


    // useLayoutEffect(() => {
    //     setTimeout(() => {
    //         dispatch(setRowPage({ pageData: 10 }));
    //         dispatch(setPageNo({ page: 1 }));
    //         dispatch(resetExternalFilter())
    //     }, 1500);
    // }, []);
    const sendSnackBar = (error) => {
        const { response: { data, status } } = error;
        if (status === 500 && data.errorType === "Business") {
            setSnackBar({ ...snackBar, show: true, text: data.message, type: "error" });
            dispatch(setNewBrandModal({
                showAddEdit: !setAddEditBrand
            }))
            dispatch(hasBrandOwner(true))
            dispatch(setBrandDateList([]))
        }
    }
    const pageLoader = (loader) => {
        setState({ ...state, pageLoader: loader })
    }
    const handleSelection = (event, newValue, parentname, name) => {
        if (event.target.value !== "" || event.target.value !== null) {
            dispatch(mieSearch({
                parent: parentname,
                key: name,
                value: newValue
            }))
        }
    }

    return (
        <>
            {state.pageLoader && <PageLoader />}
            <Container disableGutters={true} maxWidth={false}>
                <BreadcrumbNavs pageTitle="Brands" />
                <Box sx={{ marginBottom: 2.5 }}>
                    <TableFilters
                        filterFields={<>
                            <Box component="div"><InputText onChange={handleFilterInput} maxWidth={50} label="Brand Name" id="brand_name" size="small" /></Box>
                            <Box component="div"><AutoCompleteStatic endPoint="/supplier/drop-down" onChange={handleFilterInput} onSelectedAutoComplete={selectedAutoComplete} id="brand_owner_name" label="Brand Owner" name="brand_owner_name" /></Box>
                        </>}
                        filterBtns={
                            <>
                                <Button variant="outlined" onClick={resetFilter}>Reset Filters</Button>
                                <Button variant="contained" onClick={filterBtn}>Apply</Button>
                            </>}>
                    </TableFilters>
                </Box>
                <Box component="div" className='exportsButtons' sx={{ columnGap: 1.75, display: "flex", flexWrap: 'wrap', justifyContent: { xs: 'center', sm: 'end' }, textAlign: { xs: "center", sm: 'end' }, marginBottom: { xs: 2.5, sm: -3 }  }}>
                    {/* <div className='table-actionable'>
                        <Button variant="contained" color="secondary" size='small' startIcon={<SendIcon />}>
                            Export as Excel
                        </Button>
                    </div> */}
                    <div className='table-actionable'>
                        <Button variant="contained" size='small' onClick={(e) => addBrandModal(e)} startIcon={<AddIcon />}>
                            New Brand
                        </Button>
                    </div>
                </Box>
                <DataTableBrand defaultGrid={state.defaultGrid} columns={columns}>

                </DataTableBrand>
                {
                    setAddEditBrand && (
                        <AddEditBrand
                            onShow={setAddEditBrand}
                            onHide={(e, reason) => handleBrandModal(e, reason)}
                        />
                    )
                }
                {
                    addProductwarning && (
                        <WarningModal size="small_overlay" onShow={addProductwarning} />
                    )
                }
                {snackBar.show && <SnackBarDialog
                    onShow={snackBar.show}
                    text={snackBar.text}
                    type={snackBar.type}
                    onHide={(e) => { setSnackBar({ ...snackBar, show: e }) }}
                />}
            </Container>

        </>
    );
}

export default Brands;
