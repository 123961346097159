import logo from "../../assets/images/logo.png"
import { SignInButton } from "../SignInButton";

const LoginLayout = (props) => {

    return (
        <>
            <div className="container-fluid main-container-block">
                <div className="container">
                    <div className="user-login-block">
                        <div className="nus-logo">
                            <img src={logo} alt="logo" />
                        </div>
                        <div className="user-login">
                            <SignInButton />
                        </div>
                    </div>                    
                </div>
            </div>
        </>
    );
}
export default LoginLayout;
